import { BaseController } from '../base.controller';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { PlaceInfoDto } from './../../dtos/maps/PlaceInfoDto';
import { PlaceSearchItemDto } from './../../dtos/maps/PlaceSearchItemDto';
import { AddressDto } from './AddressDto';
import { HString } from '@shared/src/datatypes/HString';
import { LocatorDto } from './LocatorDto';


/***
 * Serve per encapsular totes les recerques de mapa.
 */
@Injectable({
    providedIn: 'root'
})
export class MapsController extends BaseController {
    private baseUrl = "/maps";

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    search(query: string): Observable<Array<PlaceSearchItemDto>> {
        return super.post(this.baseUrl + "/search", { "search_query": query }, "maps-search");
    }

    getPlaceInfo(query: string): Observable<PlaceInfoDto> {
        return super.post(this.baseUrl + "/get-place-info", { "search_query": query }, "maps-get-place-info");
    }

    /***
     * Donada una adreça en forma de texte, retorna la seva adreça formal. A diferencia de la gran majoria de crides acaba amb / ja que la cadena pot contenir . i això fa que sprint talli
     */
    resolveaddress(addressSearch: string): Observable<AddressDto> {
        addressSearch = HString.toUrlParam(addressSearch);
        return super.get(this.baseUrl + "/resolveaddress/" + addressSearch + "/");
    }

    /***
     * Juga al ping pong amb el locator
     */
    locator(locator: LocatorDto): Observable<LocatorDto> {
        return super.put(this.baseUrl + "/locator", locator, "locator");
    }
    /***
     * Primera crida del locator
     */
    initiateLocator(): Observable<LocatorDto> {
        return super.get(this.baseUrl + "/initiatelocator");
    }
}