import { Injectable } from '@angular/core';

import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerShipperUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController{

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.SHIPPER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/shipper/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}