import { Component, Input, OnInit } from '@angular/core';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes'

@Component({
  selector: 'shared-chat-avatar',
  templateUrl: './shared.chat.avatar.html',
  styleUrls: ['./shared.chat.avatar.scss']
})
export class SharedChatAvatar implements OnInit {

  public padding: number = 0;
  public fontsize: number = 40;

  constructor() {

  }

  ngOnInit() {
    this.calculate();
  }

  public _participant: ParticipantDto;
  @Input()
  public set participant(value: ParticipantDto) {
    if (this._participant === value)
      return;
    this._participant = value;
    this.calculate();
  }
  public get participant(): ParticipantDto {
    return this._participant;
  }

  @Input() public isGroup: boolean;



  getClassIcon() {
    if (this.isGroup)
      return "group fa fa-users";
    
    if (this.participant == null)
      return "";
    if (this.participant.participantType == ChatParticipantTypes.System)
      return "system fa fa-user";
    if (this.participant.participantType == ChatParticipantTypes.Route)
      return "route fa fa-truck";
    if (this.participant.participantType == ChatParticipantTypes.Shipment)
      return "shipment fa fa-archive";
    if (this.participant.participantType == ChatParticipantTypes.Phase)
      return "phase fa fa-location-arrow";
    if (this.participant.participantType == ChatParticipantTypes.User)
      return "user fa fa-user";
    if (this.participant.participantType == ChatParticipantTypes.Support)
      return "support fa fa-user";
  }

  private calculate() {
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
