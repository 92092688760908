import { Injectable } from '@angular/core';

@Injectable()
export class Route_eCMRPaymentDto {
	public payment: string;
	public amount: number;
	public reset: boolean;

	public static build(payment: string, amount: number): Route_eCMRPaymentDto {
		let result: Route_eCMRPaymentDto = new Route_eCMRPaymentDto();
		result.payment = payment;
		result.amount = amount;
		return result;
	}

	public static buildReset(): Route_eCMRPaymentDto {
		let result: Route_eCMRPaymentDto = new Route_eCMRPaymentDto();
		result.reset = true;
		return result;
	}
}