import { HTriState } from "@shared/src/public-api";

export class CompatibleDocksDto {
  public docksSystem: string;
  public docksUser: string;
  public docksUseSystemUser: HTriState;

  public static build(docksSystem: string, docksUser: string, docksUseSystemUser: HTriState): CompatibleDocksDto {
    const result: CompatibleDocksDto = new CompatibleDocksDto();
    result.docksSystem = docksSystem;
    result.docksUser = docksUser;
    result.docksUseSystemUser = docksUseSystemUser;
    return result;
  }
}