<div *ngIf="value && value.routeId" class="card container" [ngClass]="styleClass">
    <div class="title d-block withoutexwork">{{ 'ROUTE.PARTNERS.CAPTION' | translate }}</div>
    <div class="title d-block withexwork">{{ 'SHARED.ROUTE.PARTNERSEXWORKS.COMPONENT.TITLE' | translate }}</div>
    <div class="block-help">
        {{'SHARED.ROUTE.PARTNERSEXWORKS.COMPONENT.HELP' | translate}}
    </div>
    <div class="blocks">
        <div class="block noshadow partner">
            <div class="title-block">
                {{'ROUTE.PARTNERS.CAPTION' | translate }}</div>
            <div class="info-partners-list cards">
                <shared-partner-infocard class="parent small" [routeId]="value.routeId" [isParent]="true"
                    *ngIf="internalShowParentInfoCard"></shared-partner-infocard>

                <shared-partner-infocard class="shipper small" [type]="'Shipper'" [partnerId]="value.partnerShipperId"
                    *ngIf="internalShowShipperInfoCard" [routeId]="value.routeId"
                    [caption]="'ROUTE.SHIPPER.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('Shipper', $event)"
                        class="only-internalShowShipper" [(ngModel)]="partnerShipperId"
                        (valuechangefunction)="setShipper()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysShipper" placeholder="{{ 'ROUTE.SHIPPER.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerShipperReadOnly)"></entity-selector>
                </div>

                <shared-partner-infocard class="carrier small" [type]="'Carrier'" [partnerId]="value.partnerCarrierId"
                    *ngIf="internalShowCarrierInfoCard" [routeId]="value.routeId"
                    [caption]="'ROUTE.CARRIER.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('Carrier',$event)"
                        class="only-internalShowCarrier" [(ngModel)]="partnerCarrierId"
                        (valuechangefunction)="setCarrier()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysCarrier" placeholder="{{ 'ROUTE.CARRIER.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerCarrierReadOnly)"></entity-selector>
                </div>

                <shared-partner-infocard class="logisticOperator small" [type]="'LogisticOperator'"
                    [partnerId]="value.partnerLogisticOperatorId" *ngIf="internalShowLogisticOperatorInfoCard"
                    [routeId]="value.routeId" [caption]="'ROUTE.LOGISTICOPERATOR.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('LogisticOperator',$event)"
                        class="only-internalShowLogisticOperator" [(ngModel)]="partnerLogisticOperatorId"
                        (valuechangefunction)="setLogisticOperator()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysLogisticOperator"
                        placeholder="{{ 'ROUTE.LOGISTICOPERATOR.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerLogisticOperatorReadOnly)"></entity-selector>
                </div>


                <shared-partner-infocard class="inspector small" [type]="'Inspector'"
                    [partnerId]="value.partnerInspectorId" *ngIf="internalShowInspectorInfoCard"
                    [routeId]="value.routeId" [caption]="'ROUTE.INSPECTOR.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('Inspector', $event)"
                        class="only-internalShowInspector" [(ngModel)]="partnerInspectorId"
                        (valuechangefunction)="setInspector()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysInspector"
                        placeholder="{{ 'ROUTE.INSPECTOR.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerInspectorReadOnly)"></entity-selector>
                </div>


                <shared-partner-infocard class="healthcertifier small" [type]="'HealthCertifier'"
                    [partnerId]="value.partnerHealthCertifierId" *ngIf="internalShowHealthCertifierInfoCard"
                    [routeId]="value.routeId" [caption]="'ROUTE.HEALTHCERTIFIER.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)=" setHasResults('HealthCertifier',$event)"
                        class="only-internalShowHealthCertifier" [(ngModel)]="partnerHealthCertifierId"
                        (valuechangefunction)="setHealthCertifier()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysHealthCertifier"
                        placeholder="{{ 'ROUTE.HEALTHCERTIFIER.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerHealthCertifierReadOnly)"></entity-selector>
                </div>


                <shared-partner-infocard class="client small" [type]="'ClientPreferent'"
                    [partnerId]="value.partnerClientPreferentId" *ngIf="internalShowClientPreferentInfoCard"
                    [routeId]="value.routeId" [caption]="'ROUTE.CLIENTPREFERENT.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('ClientPreferent',$event)"
                        class="only-internalShowClientPreferent" [(ngModel)]="partnerClientPreferentId"
                        (valuechangefunction)="setClientPreferent()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysClientPreferent"
                        placeholder="{{ 'ROUTE.CLIENTPREFERENT.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerClientPreferentReadOnly)"></entity-selector>
                </div>


                <shared-partner-infocard class="driver small" [type]="'Driver'" [routeId]="value.routeId"
                    [partnerId]="value.partnerDriverId" *ngIf="internalShowDriverInfoCard"
                    [caption]="'ROUTE.DRIVER.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('Driver',$event)"
                        class="only-internalShowDriver" [(ngModel)]="partnerDriverId"
                        (valuechangefunction)="setDriver()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysDriver" placeholder="{{ 'ROUTE.DRIVER.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerDriverReadOnly)"></entity-selector>
                </div>


                <shared-partner-infocard class="driver secondDriver small" [type]="'SecondDriver'"
                    [routeId]="value.routeId" [partnerId]="value.partnerSecondDriverId"
                    *ngIf="internalShowSecondDriverInfoCard"
                    [caption]="'ROUTE.SECONDDRIVER.CAPTION'"></shared-partner-infocard>
                <div class="selectors only-write">
                    <entity-selector [searchBeforeFocus]="true" (hasResults)="setHasResults('SecondDriver',$event)"
                        class="only-internalShowSecondDriver" [(ngModel)]="partnerSecondDriverId"
                        (valuechangefunction)="setSecondDriver()" [entitySelector]="'Partner'"
                        [fixedFilterKeys]="filterkeysSecondDriver"
                        placeholder="{{ 'ROUTE.SECONDDRIVER.CAPTION' | translate }}"
                        [readonly]="readonly || (dataForcedBlocked != null && dataForcedBlocked.partnerSecondDriverReadOnly)"></entity-selector>
                </div>


                <button class="link d-block" *ngIf="mode=='read' && !readonly" (click)="setMode('write')">{{
                    'BOOKINGPAGE.PARTNERSELECTOR.CHANGE.CAPTION' | translate }}</button>
                <button class="link d-block" (click)="setMode('read')" *ngIf="mode=='write'"><i
                        class="fa fa-times-circle" aria-hidden="true"></i>
                    {{ 'BOOKINGPAGE.PARTNERSELECTOR.CANCEL.CAPTION' | translate }} </button>
            </div>
        </div>
        <div class="block exwork">
            <div class="title-block">
                {{'ROUTE.EXWORKS.CAPTION' | translate }}</div>
            <shared-route-exworks [readonly]="readonly" [value]="value" *ngIf="value.canExwork"
                (valueChange)="valueChanged.next($event)"></shared-route-exworks>
        </div>
        <div class="block externaldriver">
            <div class="title-block">
                {{'ROUTE.ADDITIONALDRIVERS.CAPTION' | translate }}</div>
            <shared-route-external-drivers [readonly]="readonly" [value]="value"
                (valueChanged)="valueChanged.next($event)"></shared-route-external-drivers>
        </div>
    </div>
</div>