import { Injectable } from '@angular/core';

import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerFreightForwarderUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.FREIGHTFORWARDER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/freight-forwarder/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}