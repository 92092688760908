import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedInactivityService {
    private inactivityTime: number = 30000; // tiempo en milisegundos (por ejemplo, 3000 ms = 3 segundos)
    private inactivityTimeout: any;
    private userInActivityEvents: Subscription[] = [];
    private userActivityEvents: Subscription[] = [];

    constructor(private ngZone: NgZone) { }

    public setInactivityTime(value: number) {
        this.inactivityTime = value;
    }

    public startMonitoring(inactivityCallback: () => void): void {
        this.ngZone.runOutsideAngular(() => {
            this.resetTimer(inactivityCallback);

            this.userInActivityEvents = [
                fromEvent(document, 'mousemove').subscribe(() => this.resetTimer(inactivityCallback)),
                fromEvent(document, 'keydown').subscribe(() => this.resetTimer(inactivityCallback)),
                fromEvent(document, 'click').subscribe(() => this.resetTimer(inactivityCallback)),
                fromEvent(document, 'touchstart').subscribe(() => this.resetTimer(inactivityCallback)),
            ];
        });
    }

    public stopMonitoring(): void {
        this.userInActivityEvents.forEach((subscription) => subscription.unsubscribe());
        this.userActivityEvents.forEach((subscription) => subscription.unsubscribe());
        clearTimeout(this.inactivityTimeout);
    }

    private resetTimer(inactivityCallback: () => void): void {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(() => {
            this.ngZone.run(() => inactivityCallback());
        }, this.inactivityTime);
    }

    public haveActivity(haveActivityCallBack: () => void): void {
        this.ngZone.runOutsideAngular(() => {
            this.userActivityEvents = [
                fromEvent(document, 'mousemove').subscribe(() => haveActivityCallBack()),
                fromEvent(document, 'keydown').subscribe(() => haveActivityCallBack()),
                fromEvent(document, 'click').subscribe(() => haveActivityCallBack()),
                fromEvent(document, 'touchstart').subscribe(() => haveActivityCallBack()),
            ];
        });
    }
}
