import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FileUpDownloadController } from "@shared/src/controllers/fileupdownload/fileupdownload.controller";
import { PackageController } from "@shared/src/controllers/route/delivery/package.controller";
import { ShipmentDeliveryController } from "@shared/src/controllers/route/delivery/shipmentdelivery.controller";
import { ESchenkerController } from "@shared/src/controllers/route/shipment/eschenker/eschenker.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { DocumentDetailDto } from "@shared/src/dtos/document/DocumentDetailDto";
import { PackageDto } from "@shared/src/dtos/shipment/delivery/PackageDto";
import { ShipmentDeliveryDto } from "@shared/src/dtos/shipment/delivery/ShipmentDeliveryDto";
import { ShipmentDeliveryLineDto } from "@shared/src/dtos/shipment/delivery/ShipmentDeliveryLineDto";
import { SharedDocumentViewComponent, ShipmentController } from "@shared/src/public-api";
import { Subject } from "rxjs-compat";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: "shared-package-line-component",
    templateUrl: "./shared.package.line.component.html",
    styleUrls: ["./shared.package.line.component.scss"],
})
export class SharedPackageLineComponent implements OnInit {

    @Input()
    public readonly: boolean = false;

    pForm: FormGroup;

    public lineChanged = new Subject<PackageDto>();
    public valueChangedEvent() {
        this.lineChanged.next();
    }

    constructor(
        public shipmentController: ShipmentController,
        public packageController: PackageController,
        public shipmentDeliveryController: ShipmentDeliveryController,
        public eschenkerController: ESchenkerController,
        private fb: FormBuilder,
        @Inject("SecurityController")
        public securityController: SecurityController,
        public dialog: MatDialog,
    ) {

        this.pForm = this.fb.group({
            'packageKey': ['', [Validators.required, Validators.maxLength(255)]],
            'xDimension': [null, Validators.nullValidator],
            'yDimension': [null, Validators.nullValidator],
            'zDimension': [null, Validators.nullValidator],
            'kg': [null, Validators.nullValidator],
            'shipmentDeliveryLine': [null, Validators.required]
        });

    }

    ngOnInit(): void {
        this.lineChanged.pipe(
            debounceTime(1000),
        ).subscribe((payload) => {
            if (this._package.packageId) {
                this.packageController.modifyPackage(this._package).subscribe(e => {
                    if (e) {
                        this._package = e;
                    }
                })
            }
        })
    }

    @Output()
    public packageCreated: EventEmitter<PackageDto> = new EventEmitter<PackageDto>();

    private _package: PackageDto;

    @Input()
    public set package(v: PackageDto) {
        if (this._package === v)
            return;

        this._package = v;
    }

    public get package(): PackageDto {
        return this._package;
    }

    @Input()
    public routeId: number = null;

    @Input()
    public shipmentId: number = null;

    public isExpanded: boolean = false;
    public internalDelete: boolean = false;

    removeShipmentDeliveryLineFromPackage(item: ShipmentDeliveryLineDto, index: number) {
        if (!item || !item.packageId)
            return;

        item.packageId = null;

        this.shipmentDeliveryController.modifyShipmentDeliveryLine(item)
            .subscribe((data: ShipmentDeliveryDto) => {
                if (data) {
                    let backLines = data.lines.filter(l => l.shipmentDeliveryLineId == item.shipmentDeliveryLineId);
                    if (backLines && backLines.length > 0) {
                        if (!backLines[0].packageId)
                            this.package.lines.splice(index, 1)

                        if (this.package.lines.length == 0) {
                            this.internalDelete = true;
                            this.package = null;
                        }
                    }
                }
            });
    }

    public shipmentDeliveryLineByRouteFilterKeys: Array<string> = [];
    public firstShipmentDeliveryLineId: number = null;
    public firstShipmentDeliveryLine: ShipmentDeliveryLineDto = null;

    createPackageObject() {
        this.package = new PackageDto();

        if (this.routeId)
            this.shipmentDeliveryLineByRouteFilterKeys = ["R:" + this.routeId];
        if (this.shipmentId)
            this.shipmentDeliveryLineByRouteFilterKeys = ["S:" + this.shipmentId];
    }

    createPackage() {
        this.packageController.createPackage(this._package).subscribe(e => {
            if (e) {
                this.package = e;
                this.packageCreated.emit(e);
                this.package = null;
            }
        })
    }


    public askingCreateLabel: boolean = false;

    createLabel() {
        if (this.package && !this.package.hasLabel) {
            this.eschenkerController.createLabel(this._package.packageId).subscribe(e => {
                if (e)
                    this.packageController.modifyPackage(this.package).subscribe(e => {
                        this._package = e;
                    })
            })
            this.askingCreateLabel = false;
        }
    }

    public documentLabel: DocumentDetailDto = null;
    getLabel() {
        if (this._package && this._package.labelCandidate && this._package.inESchenker) {
            let dialogRef = null;
            const dialogHeight = '95vh';
            const dialogwidth = '500px';
            if (!this.documentLabel) {
                this.eschenkerController.getDocumentLabelByPackage(this._package.packageId).subscribe(e => {
                    if (e && e.fileBytes && e.fileBytes.length > 0) {
                        this.documentLabel = e;
                        dialogRef = this.dialog.open(SharedDocumentViewComponent, {
                            height: dialogHeight,
                            width: dialogwidth,
                            data: { documentDetail: this.documentLabel },
                            panelClass: 'package-label-frame',
                            backdropClass: 'matdialog-dark-backdrop'
                        });

                        let instance = dialogRef.componentInstance;
                        instance.documentDetail = this.documentLabel;
                    } else {
                        this.documentLabel = null;
                    }
                });
            } else {
                dialogRef = this.dialog.open(SharedDocumentViewComponent, {
                    height: dialogHeight,
                    width: dialogwidth,
                    data: { documentDetail: this.documentLabel },
                    panelClass: 'package-label-frame',
                    backdropClass: 'matdialog-dark-backdrop'
                });

                let instance = dialogRef.componentInstance;
                instance.documentDetail = this.documentLabel;
            }
        }
    }

    downloadLabel() {
        if (this._package && this._package.labelCandidate && this._package.inESchenker) {
            this.eschenkerController.downloadLabelByPackage(this._package.packageId).subscribe(e => {
                if (e && e.fileBytes && e.fileBytes.length > 0)
                    FileUpDownloadController.resultDownLoad(e);
            })
        }
    }

    public emptyValue: string = "-";
    pretty(item: any): string {
        return JSON.stringify(item);
    }
}
