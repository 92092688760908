import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Host, Inject, Optional, SkipSelf } from '@angular/core';
import { AsyncValidatorFn, ControlContainer, FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';

import { HomeController } from '../../../../controllers/home/home.controller';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';

@Component({
  selector: 'input-component',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputComponent, multi: true }
  ]
})

/***
 * Encapsula un input.
 * El type pot ser els types normals pero si posem passwordauto és capaç de fer que el password es pugui mostrar
 */
export class InputComponent extends ValueAccessorValidatedBase<string, string> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormInput = this.fb.group({
      dtpvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return true;
  }

  public rFormInput: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;
  @Input() public placeholder: string;
  @Input() public helpresource: string;
  @Input() public color: string = 'primary';
  @Input() public readonly: boolean;
  @Input() public autocomplete: string = "off";
  @Input() public direction: 'rtl'|'ltr' = 'ltr';
  @Input() public textAlign: 'right'|'center'|'left' = 'left';
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  public identifier = 'input-' + identifier++;

  _showHelpPoint: boolean;
  @Input() set showHelpPoint(bvalue: boolean) {
    if (this._showHelpPoint === bvalue)
      return;
    this._showHelpPoint = bvalue;
  }
  get showHelpPoint(): boolean {
    return this._showHelpPoint;
  }

  public _type: string = "text";
  @Input() set type(value: string) {
    if (this._type === value)
      return;
    this._type = value;
    this.setActingType();
  }
  get type(): string {
    return this._type;
  }

  public actingtype = this._type;
  private setActingType() {
    this.actingtype = this.type;
    if (this.actingtype === "passwordauto")
      this.actingtype = "password";
  }

  public togglePassword() {
    if (this.actingtype === "password")
      this.actingtype = "text";
    else
      this.actingtype = "password";
  }


  externalValue(value: string): string {
    if (value === null || value === undefined)
      return "";

    return value;
  }
  internalValue(value: string): string {
    if (value === null || value === undefined)
      return "";
    return value;
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  @Output() onKeyEnter: EventEmitter<string> = new EventEmitter<string>();
  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onKeyEnter.emit(this.externalValue(this.value));
    return false;
  }
}

let identifier = 0;

