import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Validator, ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { HTime } from '@shared/src/datatypes/HTime';
import { ValueAccessorValidatedBase } from '../../../form/value-accessor-validated';
import { TimeValidator } from '../../../form/validations/TimeValidator';
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
  selector: 'time-caret',
  templateUrl: './time-caret.component.html',
  styleUrls: ['./time-caret.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimeCaretComponent, multi: true }]
})

export class TimeCaretComponent extends ValueAccessorValidatedBase<HTime, HTime> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormTime = this.fb.group({
      'timevalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: HTime): boolean {
    return TimeValidator.isValid(value);
  }
  
  public rFormTime: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if(this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }


  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HTime> = new EventEmitter();

  @Input() public timetosumrest: HTime = HTime.buildByHoursAndMinutes(0,30);

  private _max: HTime;
  @Input() set max(maxvalue: HTime) {
    if(this._max === maxvalue)
      return;
    this._max = maxvalue;
  }
  get max(): HTime {
    return this._max;
  }

  private _min: HTime;
  @Input() set min(minvalue: HTime) {
    if(this._min === minvalue)
      return;
    this._min = minvalue;
  }
  get min(): HTime {
    return this._min;
  }

  public identifier = 'time-picker-' + identifier++;

  externalValue(value: HTime): HTime {
    if (!value)
      return HTime.empty();
    return value;
  }
  internalValue(value: HTime): HTime {
    return value;
  }

  sumTime(){
    let r = this.value.addHours(this.timetosumrest.getHours());
     r = r.addMinutes(this.timetosumrest.getMinutes());
    if(!HTime.isNull(this.max) && HTime.isGreaterThan(r, this.max))
      r = this.max;
    this.value = r;
  }

  minusTime(){
    let r = this.value.addHours(this.timetosumrest.getHours() * -1);
    r = r.addMinutes(this.timetosumrest.getMinutes() * -1);

    if(!HTime.isNull(this.min) && HTime.isMinorThan(r, this.min))
      r = this.min;
    this.value = r;
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  getClass() {
    if (this.readonly)
      return "readonly";
    return "";
  }
  
  keyEnter(event){
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;
