import {Injectable} from '@angular/core';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';

@Injectable()
export class OpenChangeStatusDto {

    public actualPhaseIdOfShipment: number;
    public generalStatus: GeneralStatus;

    public static build(generalStatus: GeneralStatus, actualPhaseIdOfShipment: number) {
        const dto = new OpenChangeStatusDto();
        dto.generalStatus = generalStatus;
        dto.actualPhaseIdOfShipment = actualPhaseIdOfShipment;
        return dto;
    }
}
