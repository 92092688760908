import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { PlateJourneyDto } from './PlateJourneyDto';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { PlateJourneyDetailDto } from './PlateJourneyDetailDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';

@Injectable({
    providedIn: 'root',
})
export class JourneyController extends BaseController {
    private base = '/journey'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getByPlate(plateId: number, momentStart: HDateHour, momentEnd: HDateHour): Observable<PlateJourneyDto> {
        return this.post(this.base + "/plate", PlateJourneyDto.build(plateId, momentStart, momentEnd), "JORUNEYGETBYPLATE");
    }

    public getPlateJourney(plateJourneyId: number): Observable<PlateJourneyDetailDto> {
        return this.get(this.base + "/" + plateJourneyId);
    }

    public getDocumentJourneyPdf(data: PlateJourneyDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/exportpdf", data, "getDocumentJoureyPdf");
    }

    public getDocumentJourneyExcel(data: PlateJourneyDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/exportexcel", data, "getDocumentJourneyExcel");
    }

    public calculateJourneys(data: PlateJourneyDto): Observable<boolean> {
        return this.postBusy(this.base + "/calculate", data, "getDocumentJourneyExcel");
    }
}