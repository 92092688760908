
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { BaseController } from '../base.controller';

@Injectable({
    providedIn: 'root',
})
export class AmbitController extends BaseController {
    private base = "/ambit"

    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getAmbitAddressClassification(classification: string): Observable<AmbitAddressClassificationDTO> {
        return this.get(this.base + "/address/classification/" + classification);
    }
    public setAmbitAddressClassification(classification: string, ambitId: number, addressId: number): Observable<boolean> {
        return this.post(this.base + "/address/classification/" + classification, { 'ambitId': ambitId, 'addressId': addressId }, "setAmbitAddressClassification");
    }


    public compareAmbits(a: AmbitAddressClassificationAmbitDTO, b: AmbitAddressClassificationAmbitDTO) {
        if (a.selected)
            return 1;
        if (a.score < b.score)
            return -1;
        if (a.score > b.score)
            return 1;
        if (a.ambitId < b.ambitId)
            return -1;
        return 1;
    }
    public compareAddress(a: AmbitAddressClassificationAddressDTO, b: AmbitAddressClassificationAddressDTO) {
        if (a.selected)
            return 1;
        if (a.score < b.score)
            return -1;
        if (a.score > b.score)
            return 1;
        if (a.addressId < b.addressId)
            return -1;
        return 1;
    }
}

export class AmbitAddressClassificationDTO {
    public ambitList: Array<AmbitAddressClassificationAmbitDTO>;
    public defaultAmbitId: number;
    public defaultAddressId: number;
}

export class AmbitAddressClassificationAmbitDTO {
    public ambitId: number;
    public ambitKey: string;
    public skin: string;
    public description: string;
    public isGlobal: boolean;
    public score: number;
    public selected: boolean;

    public addressList: Array<AmbitAddressClassificationAddressDTO>
}

export class AmbitAddressClassificationAddressDTO {
    public addressId: number;
    public addressKey: string;
    public skin: string;
    public description: string;
    public score: number;
    public selected: boolean;

}
