import { Component, Inject, Input, OnInit } from '@angular/core';
import { JourneyController } from '@shared/src/controllers/journey/journey.controller';
import { PlateJourneyDetailDto } from '@shared/src/controllers/journey/PlateJourneyDetailDto';
import { TelemetryFilterDto } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryFilterDto';
import { TelemetryMaintenanceController } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryMaintenanceController';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { TelemetryModel } from '@shared/src/enums/TelemetryModel';
import { MapMarcadorDto, MapPuntDto, MapRutaDto } from '@shared/src/services/map/map.service';
import { MultiModelTelemetryDto } from '@shared/src/controllers/maintenance/device/telemetry/MultiModelTelemetryDto';
import { TelemetryD } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryD';
import { PlateJourneyDetailSegmentDto } from '@shared/src/controllers/journey/PlateJourneyDetailSegmentDto';
import { JourneySegmentTypes } from '@shared/src/enums/JourneySegmentTypes';
import { GeofenceDto } from '@shared/src/dtos/gate/GeofenceDto';
import { GeofencePointDto } from '@shared/src/dtos/gate/GeofencePointDto';
import { GeofenceShapes } from '@shared/src/enums/GeofenceShapes';
import { TranslateService } from '@ngx-translate/core';
import { SharedAsideFactory } from '../components.api';

@Component({
    selector: 'shared-journey-detail-component',
    templateUrl: './shared.journey.detail.component.html',
    styleUrls: ['./shared.journey.detail.component.scss'],
})
export class SharedJourneyDetailComponent implements OnInit {
    constructor(protected journeyController: JourneyController,
        protected telemetryController: TelemetryMaintenanceController,
        private translateService: TranslateService,
        @Inject('SharedAsideFactory')
        public sharedAsideFactory: SharedAsideFactory) {

    }

    ngOnInit(): void {
    }

    private __plateJourneyId: number;
    @Input()
    set plateJourneyId(plateJourneyId: number) {
        if (this.__plateJourneyId === plateJourneyId)
            return;
        this.__plateJourneyId = plateJourneyId;
        this.loadData();
    }
    get plateJourneyId(): number {
        return this.__plateJourneyId;
    }

    public value: PlateJourneyDetailDto;
    private loadData() {
        this.latitude = this.longitude = this.generalZoom = this.selectedSegment = null;
        if (this.__plateJourneyId == null || this.__plateJourneyId == 0)
            this.value = null;
        else
            this.journeyController.getPlateJourney(this.__plateJourneyId).subscribe(data => {
                this.value = data;
                this.loadRutaMapa();
            });
    }
    public filter: TelemetryFilterDto;
    public loadingData: boolean = false;
    public dataMapa: MultiModelTelemetryDto;
    public loadRutaMapa() {
        this.filter = TelemetryFilterDto.noDimensionsRequired(TelemetryFilterDto.build(TelemetryModel.Plate, this.value.plateId, this.value.originMoment, this.value.destinationMoment, null, null, null));
        if (this.filter != null) {
            if (this.loadingData)
                return;
            this.loadingData = true;
            this.telemetryController.getTelemetry(this.filter).subscribe((data: MultiModelTelemetryDto) => {
                if (data) {
                    this.loadingData = false;
                    this.filter = TelemetryFilterDto.buildByFilter(data.filter);
                    this.dataMapa = data;
                    this.dataMapa.listTelemetry = this.dataMapa.listTelemetry.reverse();
                }
                this.calculateRutes();
            });
        }
    }
    public selectedSegment: PlateJourneyDetailSegmentDto
    public canviarMarcadorsA(value: PlateJourneyDetailSegmentDto) {

        this.selectedSegment = value;
        var filteredListTelemetryD: TelemetryD = null;
        this.marcadors = new Array<MapMarcadorDto>();

        if (this.dataMapa && this.dataMapa.listTelemetry && this.dataMapa.listTelemetry.length > 0) {
            var point: MapMarcadorDto = null;
            var filtered = this.dataMapa.listTelemetry.filter(function (element) {
                return HDateHour.isGreaterEqualsThan(element.moment, value.moment) && HDateHour.isMinorEqualsThan(element.moment, HDateHour.addMinutes(value.endMoment, 1));
            });

            // Si és un segment de no moviment (parada o parada en fase)
            // pintarem el centre del grup de telemetries
            if (value.journeySegmentType != JourneySegmentTypes.Movement || filtered.length == 1) {
                filteredListTelemetryD = null;
                let maxLat = null;
                let minLat = null;
                let maxLon = null;
                let minLon = null;
                // Calculem les latituds/longituds màximes/mínimes per treure'n el mig
                filtered.forEach(item => {
                    if (!maxLat || item.latitude >= maxLat)
                        maxLat = item.latitude;
                    if (!minLat || item.latitude <= minLat)
                        minLat = item.latitude;
                    if (!maxLon || item.longitude >= maxLon)
                        maxLon = item.longitude;
                    if (!minLon || item.longitude <= minLon)
                        minLon = item.longitude;
                });

                point = MapMarcadorDto.builder((maxLon + minLon) / 2, (maxLat + minLat) / 2);
            } else {
                point = null;
                if (HDateHour.isMinorThan(filtered[0].moment, filtered[filtered.length - 1].moment))
                    filtered = filtered.reverse();
                if (!HDateHour.isMinorThan(this.value.destinationMoment, HDateHour.now())) {
                    filteredListTelemetryD = TelemetryD.build(filtered, 23);
                } else {
                    filteredListTelemetryD = TelemetryD.build(filtered, filtered.length);
                }
            }
        }

        this.buildRutesFrom(TelemetryD.build(this.dataMapa.listTelemetry, this.dataMapa.listTelemetry.length), filteredListTelemetryD, point);
    }

    public rutes: Array<MapRutaDto> = null;
    public marcadors: Array<MapMarcadorDto> = null;
    public geofences: Array<GeofenceDto> = [];
    public calculateRutes() {
        var filteredListTelemetryD: TelemetryD = null;
        this.marcadors = new Array<MapMarcadorDto>();

        if (this.dataMapa && this.dataMapa.listTelemetry && this.dataMapa.listTelemetry.length > 0)
            filteredListTelemetryD = TelemetryD.build(this.dataMapa.listTelemetry, this.dataMapa.listTelemetry.length);

        this.buildRutesFrom(filteredListTelemetryD, null, null);
    }

    buildRutesFrom(filteredListTelemetryD: TelemetryD, filteredListTelemetryDSegment: TelemetryD, point: MapMarcadorDto) {
        var rutes: Array<MapPuntDto> = [];
        var inici: MapPuntDto;
        // Journey tancat
        if (filteredListTelemetryD && filteredListTelemetryD.items.length > 1 && HDateHour.isMinorThan(this.value.destinationMoment, HDateHour.now())) {
            this.geofences = [];
            let fullGeofence = new GeofenceDto();
            fullGeofence.shape = GeofenceShapes.Polyline;
            fullGeofence.color = "#888";
            this.marcadors = new Array<MapMarcadorDto>();
            this.rutes = [];
            filteredListTelemetryD.items.forEach(item => {
                let geofencePoint = new GeofencePointDto();
                geofencePoint.latitude = item.latitude;
                geofencePoint.longitude = item.longitude;
                fullGeofence.points.push(geofencePoint);
            });

            let segomentGeofence = null;
            if (filteredListTelemetryDSegment && filteredListTelemetryDSegment.items.length > 1) {
                segomentGeofence = new GeofenceDto();
                segomentGeofence.shape = GeofenceShapes.Polyline;
                segomentGeofence.color = "#1d3877";
                filteredListTelemetryDSegment.items.forEach(item => {
                    let geofencePoint = new GeofencePointDto();
                    geofencePoint.latitude = item.latitude;
                    geofencePoint.longitude = item.longitude;
                    segomentGeofence.points.push(geofencePoint);
                });
            }
            this.geofences.push(fullGeofence);
            let originLabel = this.translateService.instant("JOURNEYTABLE.ORIGIN.CAPTION");
            let destinationLabel = this.translateService.instant("JOURNEYTABLE.DESTINATION.CAPTION");
            if (segomentGeofence) {
                this.geofences.push(segomentGeofence);
                let origin = MapMarcadorDto.builder(filteredListTelemetryDSegment.items[0].longitude, filteredListTelemetryDSegment.items[0].latitude);
                origin.label = originLabel;
                let destination = MapMarcadorDto.builder(filteredListTelemetryDSegment.items[filteredListTelemetryDSegment.items.length - 1].longitude, filteredListTelemetryDSegment.items[filteredListTelemetryDSegment.items.length - 1].latitude);
                destination.label = destinationLabel;
                this.marcadors.push(origin);
                this.marcadors.push(destination);
                if (point)
                    this.marcadors.push(point);
            } else {
                let origin = MapMarcadorDto.builder(filteredListTelemetryD.items[0].longitude, filteredListTelemetryD.items[0].latitude);
                origin.label = originLabel;
                let destination = MapMarcadorDto.builder(filteredListTelemetryD.items[filteredListTelemetryD.items.length - 1].longitude, filteredListTelemetryD.items[filteredListTelemetryD.items.length - 1].latitude);
                destination.label = destinationLabel;
                this.marcadors.push(origin);
                this.marcadors.push(destination);
                if (point)
                    this.marcadors.push(point);
            }
        } else {
            this.geofences = null;
            if (filteredListTelemetryD && filteredListTelemetryD.items) {
                filteredListTelemetryD.items.forEach(element => {
                    rutes.push(MapPuntDto.builder(element.longitude, element.latitude))
                    inici = MapPuntDto.builder(element.longitude, element.latitude);
                });
                this.rutes = [MapRutaDto.builderWaypoints(rutes)];
                this.marcadors = new Array<MapMarcadorDto>();
            } else {
                this.rutes = null;
                if (point)
                    this.marcadors.push(point);
            }
        }
    }

    private _generalZoom: number;
    public get generalZoom(): number {
        return this._generalZoom;
    }

    public set generalZoom(value: number) {
        this._generalZoom = value;
    }

    public latitude: number;
    public setLatitude(value: number) {
        if (!this.latitude)
            this.latitude = value;
    }

    public longitude: number;
    public setLongitude(value: number) {
        if (!this.longitude)
            this.longitude = value;
    }

    getTelemetryClass(value: PlateJourneyDetailDto) {
        if (!HDateHour.isNullOrNullValue(value.destinationMoment))
            return "acabat";
        return "";
    }

    onIniciMarkerClick() {
        this.sharedAsideFactory.invokeAddressCoordinates(this.value.originAddressId, this.value.originAddressLine1, this.value.originLongitude, this.value.originLatitude);
    }
    onFinalMarkerClick() {
        this.sharedAsideFactory.invokeAddressCoordinates(this.value.destinationAddressId, this.value.destinationAddressLine1, this.value.destinationLongitude, this.value.destinationLatitude);
    }

    public onMarkerClick() {
    }
    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
