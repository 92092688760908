<ng-container *ngIf="totem">
    <div class="page-title">
        {{'TOTEMPAGE.ADDMORE.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container">
        <div class="column-central">
            <span [innerHtml]="'TOTEMPAGE.ADDMORE.HELP'| translate"></span>
        </div>
        <div class="column-dreta">
            <button class="button-cancel" (click)="confirmacioRutaAcceptar(false)">
                <i aria-hidden="true" class="fa fa-times-circle"></i>
                <div class="code">{{'TOTEMPAGE.ADDMORE.BUTTON.FALSE'|translate}}</div>
            </button>
            <button class="button-action" (click)="confirmacioRutaAcceptar(true)">
                <i aria-hidden="true" class="fa fa-check-circle"></i>
                <div class="code">{{'TOTEMPAGE.ADDMORE.BUTTON.TRUE'|translate}}</div>
            </button>
        </div>

    </div>
</ng-container>