<shared-generalstatus-container class="card" [ngClass]="getGeneralClass()" *ngIf="route"
    [generalStatus]="route.generalStatus">
    <div class="internalcard"
        [ngClass]="{'isSlotBlocked':route.isSlotBlocked, 'incompatibleSlot':route.incompatibleSlot}">
        <div class="leftData">
            <!-- TÍTOL -->
            <div class="line route-title ellipsis">
                <shared-copyclipboard [value]="route.title">{{route.title}} </shared-copyclipboard>
                <span class="route-titlesubtitle">{{route.titleSubtitle}}</span>
            </div>

            <!-- SUBTÍTOL -->
            <div class="line route-subtitle ellipsis">{{route.subtitle}}<shared-copyclipboard
                    [value]="route.subtitle"></shared-copyclipboard>
            </div>

            <!-- DATES -->
            <div class="line route-description ellipsis" *ngIf="route.line1">{{ route.line1 }}
            </div>
            <div class="line route-description ellipsis disp" *ngIf="route.line2">{{ route.line2 }}
            </div>
            <div class="line route-description ellipsis" *ngIf="route.line3">{{ route.line3 }}
            </div>

            <!-- RETAÏLA D'ETIQUETES -->
            <shared-tags-strip-component class="only-single" [tags]="allTags"></shared-tags-strip-component>
            <shared-tags-strip-component class="only-llista" [maxTags]="MAX_TAGS_IN_LIST_ITEM"
                [tags]="allTags"></shared-tags-strip-component>

            <!-- MISSATGES PENDENTS -->
            <div *ngIf="isActiveRoutes && route.pendingMessagesRead > 0" class="pending-messages">
                <i class="fa fa-commenting icon" aria-hidden="true"></i>
                <div class="number">{{ route.pendingMessagesRead }}</div>
            </div>
        </div>
        <div class="bottom-zone">
            <shared-route-strip-component [route]="route"
                [isActiveRoutes]="isActiveRoutes"></shared-route-strip-component>
        </div>
        <!-- SEMÀFOR -->
        <span *ngIf="route.showMMResponseStatus && route.responseStatus != null" class="responseStatusContainer"
            [ngClass]="{'withdock' : route.docks != null && route.docks.length > 0 }">
            <div class="responseStatus" [ngClass]="route.responseStatus"></div>
        </span>

        <!-- CHEVRON FILLES -->
        <div *ngIf="route.isDivided && pendingChildsCount > 0 && isActiveRoutes" class="showchilds">
            <div class="contador">{{pendingChildsCount}}</div>
            <i class="ichilds fa fa-angle-right" aria-hidden="true" *ngIf="!route.showChilds"
                (click)="onClickShowChilds($event)"></i>
            <i class="ichilds fa fa-angle-down" aria-hidden="true" *ngIf="route.showChilds"
                (click)="onClickShowChilds($event)"></i>
        </div>

        <!-- ID DE LA RUTA -->
        <div class="routeId">{{route.routeId}}</div>
    </div>
</shared-generalstatus-container>