import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../../_base/BaseMaintenanceController';
import { ListTelemetryMaintenanceDto } from './ListTelemetryMaintenanceDto';
import { ItemTelemetryMaintenanceDto } from './ItemTelemetryMaintenanceDto';
import { TelemetryFilterDto } from './TelemetryFilterDto';

import { Observable } from 'rxjs';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { MultiModelTelemetryDto } from './MultiModelTelemetryDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';


@Injectable()
export class TelemetryMaintenanceController extends BaseMaintenanceController<ListTelemetryMaintenanceDto, ItemTelemetryMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "telemetry");
    }

    public getTelemetry(data: TelemetryFilterDto): Observable<MultiModelTelemetryDto> {
        return this.post(this.getBaseUrl() + "/info", data, "telemetry");
    }

    public getTelemetryBusy(data: TelemetryFilterDto): Observable<MultiModelTelemetryDto> {
        return this.postBusy(this.getBaseUrl() + "/info", data, "telemetry");
    }

    public getDocumentTelemetryPdfBusy(data: TelemetryFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.getBaseUrl() + "/exportpdf", data, "getDocumentTelemetryPdf");
    }

    public getDocumentTelemetryExcelBusy(data: TelemetryFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.getBaseUrl() + "/exportexcel", data, "getDocumentTelemetryExcel");
    }

    public calculateDimensionsBusy(data: TelemetryFilterDto): Observable<FilterStripDto> {
        return this.postBusy(this.getBaseUrl() + "/calculatedimensions", data, "calculateDimensions");
    }
}
