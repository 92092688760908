<div class="container-table grup" [ngClass]="{'ionic' : useIonic}">
   <div class="contingut">
      <table class="hardman">
         <tr class="dark">
            <th class="header modifiedAt">{{'ROUTELOGSTABLE.MODIFIED_AT' | translate}}</th>
            <th class="header message">{{'ROUTELOGSTABLE.MESSAGE' | translate}}</th>
            <th class="header userName">{{'ROUTELOGSTABLE.USER' | translate}}</th>
         </tr>
         <tr *ngFor="let item of logs | sortBy:'-modifiedAt'" [ngClass]="item.action">
            <td class="modifiedAt">
               <datehour-viewer [value]="item.modifiedAt" [showOriginal]="false" [showTimeZone]="false">
               </datehour-viewer>
            </td>
            <td class="message" matTooltip="{{item.message}}">{{ item.message }}</td>
            <td class="userName">{{item.userName}}</td>
         </tr>
      </table>
   </div>
</div>