import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { RouteListMaintenanceDto } from '../route/RouteListMaintenanceDto';
import { RouteItemMaintenanceDto } from '../route/RouteItemMaintenanceDto';

@Injectable()
export class ReturnMaintenanceController extends BaseMaintenanceController<RouteListMaintenanceDto, RouteItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "activity/returns");
    }

    public getResourceRoot(): string {
        return "RETURN";
    }

}