import { Component, Input, Inject } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { AddressDaySettingPlanningHeaderCounterDto } from "@shared/src/dtos/address/planning/AddressDaySettingPlanningHeaderCounterDto";
import { AddressDaySettingPlanningHeaderDto } from "@shared/src/dtos/address/planning/AddressDaySettingPlanningHeaderDto";

@Component({
    selector: 'shared-slots-planning-header',
    templateUrl: "./shared.slots.planning.header.component.html",
    styleUrls: ["./shared.slots.planning.header.component.scss"],
})
export class SharedSlotsPlanningHeaderComponent {
    public useIonic: boolean = false;

    constructor(
        @Inject("HomeController") protected homecontroller: HomeController,
        private sanitizer: DomSanitizer
    ) {
        if (homecontroller.useIonic) this.useIonic = homecontroller.useIonic();
    }

    ngOnInit() {

    }

    private _header: AddressDaySettingPlanningHeaderDto;
    @Input() set header(value: AddressDaySettingPlanningHeaderDto) {
        if (this._header === value)
            return;
        this._header = value;
    }
    get header(): AddressDaySettingPlanningHeaderDto {
        return this._header;
    }
    pretty(value) {
        return JSON.stringify(value);
    }

    public selectedCounter: AddressDaySettingPlanningHeaderCounterDto;
    public switch(counter) {
        this.selectedCounter = counter;
    }
}
