<!-- hourinterval-picker.component.html!-->
<div [formGroup]="rFormHourInterval" style="width:100%">
    <div *ngIf="!useIonic">
        <mat-form-field [ngClass]="{'readonly': readonly}">
            <input matInput [id]="identifier" formControlName="hourintervalvalidator" [(ngModel)]="value"
                [placeholder]="placeholder" type="text" [readonly]="readonly" (change)="onChange($event)" (keydown.enter)="keyEnter($event)">
            <button mat-button *ngIf="showClearButton()" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div *ngIf="useIonic" class="hourinterval-ionic">
        <ion-item [ngClass]="{'readonly': readonly}">
            <input matInput [id]="identifier" formControlName="hourintervalvalidator" [(ngModel)]="value"
                [placeholder]="placeholder" type="text" [readonly]="readonly" (change)="onChange($event)">
            <button mat-button *ngIf="showClearButton()" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <!-- <mat-icon>close</mat-icon> -->
                <i aria-label="Clear" class="clear ionicicona fa fa-remove"></i>
            </button>
        </ion-item>
    </div>
</div>