<div class="adreça">
    <div class="line addressKey" *ngIf="address">
        <span class="prompt">{{'ADDRESS.ADDRESSKEY.CAPTION' | translate}}</span>
        <span class="value addressKey">{{address.skin}}</span>
    </div>
    <div class="line billingCode" *ngIf="address">
        <span class="prompt">{{'ADDRESS.BILLINGCODE.CAPTION' | translate}}</span>
        <span class="value billingCode">{{address.billingCode}}</span>
    </div>
    <div class="line address" *ngIf="address">
        <span class="prompt">{{'ADDRESS.ADDRESS.CAPTION' | translate}}</span>
        <span class="value address">{{address.address}}</span>
    </div>
    <div class="line formattedAddress" *ngIf="formattedAddress">
        <span class="prompt">{{'ADDRESS.FORMATEDADDRESS.CAPTION' | translate}}</span>
        <span class="value formattedAddress">{{formattedAddress}}</span>
    </div>
    <div class="line point" *ngIf="punt">
        <span class="prompt">{{'ADDRESS.LONGITUDE.CAPTION' | translate}}</span>
        <span class="value longitude">{{punt.longitude}}</span>
        <span class="prompt">{{'ADDRESS.LATITUDE.CAPTION' | translate}}</span>
        <span class="value latitude">{{punt.latitude}}</span>
    </div>
</div>
<shared-map class="mapa" [forcedZoom]="zoom" [latitude]="latitude" [longitude]="longitude" [marcadors]="marcadors">
</shared-map>