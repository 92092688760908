import { ChatDto } from "./ChatDto";
import {Injectable} from '@angular/core';
import { ChatParticipantTypes } from "@shared/src/enums/ChatParticipantTypes";

@Injectable()
export class ChatsDto {
	public tenantId: number;
	public ownerUserId: number;
	public ownerUserType: ChatParticipantTypes;
	public countPendingMessagesRead: number;
	public chats: ChatDto[];
	public selectedChatId: number;

	public searchtext: string;
	public nChatsLoaded: number = 0;
	public nextPage: number;
    public primeraLlistaCarregada: boolean = false;
	
	public loadMoreChats: boolean = false;
}