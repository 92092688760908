<div class="container" *ngIf="list!=null && list.length>0"
  [ngClass]="externalClass">
  <!--<i class="fa fa-lg fa-filter" aria-hidden="true"></i>-->
  <div *ngFor="let item of list | orderfilterdto" [ngClass]="getClass(item)" class="chip"
    [ngStyle]="getStyle(item)" (click)="click(item)">
    <div class="caption" [ngClass]="{ 'ionic' : useIonic }" matTooltip="{{item.filterHelp | translate}}">
      <i class="hardman-black-forbidden"></i>
      {{item.filterCaption | translate}}
    </div>
    <div class="cercle-exterior" [ngClass]="{ 'ionic' : useIonic }"></div>
  </div>
</div>