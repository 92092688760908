import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../base.controller';
import { SecurityController } from '../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentDto } from '@shared/src/dtos/document/DocumentDto';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';

@Injectable({
    providedIn: 'root',
})
export class DocumentController extends BaseController {

    private base = '/document'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getDocument(documentId: number): Observable<DocumentDto> {
        return this.get(this.base + "/document/" + documentId);
    }

    public getDocumentDetail(documentDetailId: number): Observable<DocumentDetailDto> {
        return this.get(this.base + "/documentdetail/" + documentDetailId);
    }

    public downloadDocumentDetail(documentDetailId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/documentdetail/" + documentDetailId)
    }

    public importDocumentCustom(body: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.base + "/importcustom", body, "importDocumentCustom");
    }

    public getAllDocumentsByParentObject(parentObject: DocumentParentObjectDto): Observable<Array<DocumentViewerTypeDto>> {
        return this.post(this.base + "/get/byparentobject", parentObject, "getAllDocumentsByParentObject");
    }
    
    public downloadParentObjectDocument(parentObject: DocumentParentObjectDto, documentId: number): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/download/byparentobject/document/" + documentId, parentObject, "downloadParentObjectDocument");
    }
    public downloadParentObjectDocumentDetail(parentObject: DocumentParentObjectDto, documentDetailId: number): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/download/byparentobject/documentdetail/" + documentDetailId, parentObject, "downloadParentObjectDocumentDetail");
    }

    public uploadParentObjectDocument(parentObject: DocumentParentObjectDto, file: DocumentDto): Observable<Boolean> {
        return this.postBusy(this.base + "/upload/document/byparentobject", {'parentObject': parentObject, 'file': file }, 'uploadOther');
    }

}
