import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseController } from "../base.controller";
import { SecurityController } from "../security/security.controller";
import { TenantInfoDto } from "@shared/src/dtos/tenants/TenantInfoDto";
import { FileUpDownLoadDto } from "@shared/src/dtos/base/FileUpDownLoadDto";
import { BillingInfoDto } from "@shared/src/dtos/billing/BillingInfoDto";
import { PaymentMethodDto } from "@shared/src/dtos/billing/PaymentMethodDto";
import { TenantInfoSearchDto } from "@shared/src/dtos/tenants/TenantInfoSearchDto";
import { TenantInfoWithReciprocityDto } from "./TenantInfoWithReciprocityDto";
import { PartnerTypes } from "@shared/src/public-api";
import { PartnerCategories } from "@shared/src/enums/PartnerCategories";
import { TenantInfoPartnerSearchDto } from "@shared/src/dtos/tenants/TenantInfoPartnerSearchDto";

@Injectable({
    providedIn: 'root',
})
export class TenantInfoController extends BaseController {
    private base = '/tenantinfo';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getTenantInfo(): Observable<TenantInfoDto> {
        return this.get(this.base + "/gettenantinfo");
    }

    public getTenantInfoByTenantId(tenantId: number): Observable<TenantInfoDto> {
        return this.get(this.base + "/gettenantinfo/" + tenantId);
    }

    public getBillingInfo(): Observable<BillingInfoDto> {
        return this.get(this.base + "/getbillinginfo");
    }

    public getPaymentMethod(): Observable<PaymentMethodDto> {
        return this.get(this.base + "/getpaymentmethod");
    }

    public modifyTenantInfo(data: TenantInfoDto): Observable<TenantInfoDto> {
        return this.post(this.base + "/modifytenantinfo", data, "modifyTenantInfo");
    }

    public modifyBillingInfo(data: BillingInfoDto): Observable<BillingInfoDto> {
        return this.post(this.base + "/modifybillinginfo", data, "modifyBillingInfo");
    }

    public modifyPaymentMethod(data: PaymentMethodDto): Observable<PaymentMethodDto> {
        return this.postBusy(this.base + "/modifypaymentmethod", data, "modifypaymentmethod");
    }

    public searchTenantInfo(data: TenantInfoSearchDto): Observable<Array<TenantInfoDto>> {
        return this.post(this.base + "/search", data, "searchTenantInfo");
    }
    public searchTenantInfos(searchString: string): Observable<Array<TenantInfoWithReciprocityDto>> {
        return this.get(this.base + "/searchtenantinfos");
    }

    public searchTenantInfoByTenantInfoOrPartnerInfo(data: TenantInfoPartnerSearchDto): Observable<Array<TenantInfoDto>> {
        return this.post(this.base + "/searchtenantinfoparnterinfo", data, "searchTenantInfoByTenantInfoOrPartnerInfo");
    }

    setDefaultPartnerByCategory(partnerCategory: PartnerCategories, partnerId: number) {
        if (partnerCategory == null)
            partnerCategory = PartnerCategories.None;
        if (partnerId == null)
            partnerId = 0;
        return this.putBusy(this.base + "/set/partnerbycategory/" + partnerCategory + "/" + partnerId, "", "setDefaultPartnerByType");
    }

    setExworkEmails(exworkEmails: string) {
        return this.post(this.base + "/set/exworkemails",  exworkEmails, "setExworkEmails");
    }
}