import { Component, Inject } from '@angular/core';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { AlertService } from '@shared/src/public-api';
import { FormBuilder } from '@angular/forms';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { PartnerDriverMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerDriverMaintenanceController';
import { TranslateService } from '@ngx-translate/core';
import { PartnerCarrierMaintenanceController } from '@shared/src/public-api';
import { SharedRouteReturnBaseCreatePage } from './shared.route.return.base.create.page';
import { RouteTypesModelsFuncionament } from './RouteTypesModelsFuncionament';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';


@Component({
	selector: 'shared-route-return-create-page',
	templateUrl: './shared.route.return.base.create.page.html',
	styleUrls: ['./shared.route.return.base.create.page.scss']
})

//Clase que encapsula el funcionament de tot
export class SharedRouteReturnCreatePage extends SharedRouteReturnBaseCreatePage {

	constructor(public routeController: RouteController,
		public shipmentController: ShipmentController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService,
		protected addressController: AddressController,
		protected partnersController: PartnersController,
		protected partnerDriverController: PartnerDriverMaintenanceController,
		protected partnerCarrierController: PartnerCarrierMaintenanceController,
		protected translateService: TranslateService) {

		super(RouteTypesModelsFuncionament.build().setTypeAsReturn(), routeController, shipmentController, fb, homeController, securityController, alertService, addressController, partnersController, partnerDriverController, partnerCarrierController, translateService);
	}
}
