import { Component, OnInit, OnDestroy, Input, Inject, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { HString } from '@shared/src/datatypes/HString';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { EncodingType } from '@ionic-native/camera/ngx';
import { WebcamImage } from 'ngx-webcam';
import { ImageDocument } from '@shared/src/dtos/document/ImageDocument';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { CameraService } from '@shared/src/services/camera/camera.service';


@Component({
  selector: 'shared-images',
  templateUrl: './shared.images.component.html',
  styleUrls: ['./shared.images.component.scss'],
})
export class SharedImagesComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(protected activeroute: ActivatedRoute,
    public cameraService: CameraService,
    @Inject('SecurityController') public securityController: SecurityController,
    @Inject('HomeController') protected homecontroller: HomeController) {

    this.securityController.getStoredUserDto().subscribe(data => {
      if (data)
        this.readonly = data.isReadOnly;
      else
        this.readonly = false;
    });
  }

  public showIonCamera = true;

  @Input()
  public readonly: boolean = false;

  @Input() public miniImages: string = 'small'; //small, medium, large

  @Input() public hideImage: boolean = false;

  @Input() public prefixFileNameImage = "Image";

  @Input() public incident: boolean;

  private _showDefaultCamera: boolean = false;
  @Input() public set showDefaultCamera(value: boolean) {
    if (this._showDefaultCamera === value)
      return;

    this._showDefaultCamera = value;
  }
  public get showDefaultCamera(): boolean {
    return this._showDefaultCamera;
  }


  private _imageSelected: ImageDocument;
  @Input() public set imageSelected(value: ImageDocument) {
    if (this._imageSelected === value)
      return;

    this._imageSelected = value;
    this.loadMiniImg();
  }
  public get imageSelected(): ImageDocument {
    return this._imageSelected;
  }

  public showImage: boolean;
  public formatImage: string;
  loadMiniImg() {

    this.showImage = this.imageSelected != null && this.imageSelected.filebytes != null;
    this.formatImage = this.getDataImageUri(this.imageSelected);

    if (this.imageSelected != null && this.imageSelected.filebytes != null && this.minsImgs == null) {
      this.minsImgs = new Array();
      this.minsImgs.push(this.imageSelected);
    }

  }

  getDataImageUri(value: ImageDocument): string {
    if (value == null)
      return "";
    return 'data:image/' + value.filetypes + ';base64,' + value.filebytes;
  }

  public showWebCam: boolean = false;
  takeAPhoto() {
    this.initTakeImage.next(true);
    if (this.showIonCamera) {
      this.cameraService.takeAPhoto(EncodingType.JPEG).then((imageDataURI) => {
        this.setImageDataUriToDocument(imageDataURI);
      });
    } else {
      this.showWebCam = true;
    }
  }

  onCloseWebCamera(event) {
    this.showWebCam = false;
    this.initTakeImage.next(event);
  }

  takeAPhotoWebCamResolver(webcamImage: WebcamImage) {
    this.showWebCam = false;
    if (webcamImage != null && webcamImage.imageAsDataUrl != null) {
      let imageDataUri = webcamImage.imageAsDataUrl.split(',')[1];
      this.setImageDataUriToDocument(imageDataUri);
    }
  }


  @Output() initTakeImage: EventEmitter<boolean> = new EventEmitter();
  @Output() takeImage: EventEmitter<ImageDocument[]> = new EventEmitter();
  @Output() selectedImage: EventEmitter<ImageDocument> = new EventEmitter();

  public minsImgs: ImageDocument[];

  setImageDataUriToDocument(imageDataURI: any) {
    if (imageDataURI != null) {

      if (this.minsImgs == null)
        this.minsImgs = new Array();

      let fileName: string = HDateHour.toStringSorteableShort(HDateHour.now()) + "." + FileTypes.jpeg;
      if (HString.isNullOrNullString(this.prefixFileNameImage))
        this.prefixFileNameImage = "Image";

      fileName = this.prefixFileNameImage + fileName;

      let newimage: ImageDocument = ImageDocument.build(fileName, imageDataURI, FileTypes.jpeg);

      this.minsImgs.push(newimage);

      this.takeImage.next(this.minsImgs);

      this.onSelectedImage(newimage);
    }
    this.initTakeImage.next(true);
  }

  isSelected(value: ImageDocument): boolean {
    if (value == null || this.imageSelected == null)
      return false;

    return this.imageSelected != null && this.imageSelected.filename == value.filename;

  }

  onSelectedImage(value: ImageDocument) {

    if (this.imageSelected === value)
      return;

    this.imageSelected = value;

    this.selectedImage.next(value);
  }

  removeMiniImg(value: ImageDocument): boolean {
    if (value == null)
      return false;

    if (this.minsImgs == null)
      this.minsImgs = new Array();

    if (this.imageSelected != null && this.imageSelected.filename == value.filename)
      this.onSelectedImage(null);

    const index = this.minsImgs.indexOf(value);
    if (index >= 0) {
      this.minsImgs.splice(index, 1);
      this.takeImage.next(this.minsImgs);
      return true;
    }
    return false;
  }

  getClassItemMiniImg(miniimg: ImageDocument): string {
    let result = this.miniImages;

    if (this.isSelected(miniimg))
      result += " selected";

    return result;
  }

  ngOnInit(): void {

    this.showIonCamera = window.origin.includes('capacitor://');
  }

  ngAfterViewInit() {
    if (this.showDefaultCamera)
      this.takeAPhoto();
  }

  ngOnDestroy() {
  }

}
