import { Component, Inject, Input, OnInit } from '@angular/core';
import { HColor } from '@shared/src/datatypes/HColor';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { TruckingShipmentDto } from '@shared/src/dtos/trucking/TruckingShipmentDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { TruckingShipmentExpandDto } from '@shared/src/dtos/trucking/TruckingShipmentExpandDto';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';

@Component({
    selector: 'shared-trucking-shipment-card',
    templateUrl: './shared.trucking.shipment.card.html',
    styleUrls: ['./shared.trucking.shipment.card.scss']
})
export class SharedTruckingShipmentCard implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController,
    public truckingController: TruckingController) {
        
    }

    private _selectedRouteExpand: TruckingRouteExpandDto;
    @Input()
    public set selectedRouteExpand(value: TruckingRouteExpandDto) {
        if (this._selectedRouteExpand === value)
            return;
        this._selectedRouteExpand = value;
        this.actions = new ActionsStripDto();
        if(value != null)
            this.actions.add(SharedTruckingShipmentCard.TRUCKING);

    }
    public get selectedRouteExpand(): TruckingRouteExpandDto {
        return this._selectedRouteExpand;
    }

    private _shipment: TruckingShipmentDto;
    @Input()
    public set shipment(value: TruckingShipmentDto) {
        if (this._shipment === value)
            return;
        this._shipment = value;
    }
    public get shipment(): TruckingShipmentDto {
        return this._shipment;
    }

	public _shipmentExpand: TruckingShipmentExpandDto;
	public get shipmentExpand(): TruckingShipmentExpandDto {
		return this._shipmentExpand;
	}
	public set shipmentExpand(value: TruckingShipmentExpandDto) {
		if (this._shipmentExpand === value)
			return;
		this._shipmentExpand = value;
		//Si modifiquem el shipment, s'ha d'actualitzar sense el _
		this.shipment = this._shipmentExpand;
	}

    
    public __showPackages: boolean;
    private _showPackages: boolean;
    @Input()
    public set showPackages(value: boolean) {
        if (this._showPackages === value)
            return;
        this._showPackages = value;
        this.__showPackages = this.__showPackages || this._showPackages;
        if(this.__showPackages){
            if(this._shipment != null){
                this.truckingController.getTruckingShipmentExpand(this._shipment.shipmentId, true).subscribe(data => {
                    this._shipmentExpand = data;
                    this._shipment = data;
                });
            }else{
                this._shipmentExpand = null;
            }
        }
    }
    public get showPackages(): boolean {
        return this._showPackages;
    }

    ngOnInit(): void { 
        
    }

    getTagColor(tag: string): string {
        return HColor.getColorFromStringTable(tag, this.homeController);
    }

    dateIsNull(d: HDateHour): Boolean {
        return HDateHour.isNullOrNullValue(d);
    }

    datesAreEqual(a: HDateHour, b: HDateHour): boolean {
        return HDateHour.equals(a, b);
    }

    onClickClosePackages(event){
        if (event && event.stopPropagation)
			event.stopPropagation();
        this.__showPackages = false;
    }

    
    public selectedPackage: TruckingShipmentPackageDto;
    onClickPackage(event, item: TruckingShipmentPackageDto){
        if (event && event.stopPropagation)
			event.stopPropagation();

        this.selectedPackage = item;
    }

    
    public __showDetails: boolean;
    onClickOpenDetails(event){
        if (event && event.stopPropagation)
			event.stopPropagation();
        this.__showDetails = true;
    }
    onClickCloseDetails(event){
        if (event && event.stopPropagation)
			event.stopPropagation();
        this.__showDetails = false;
    }

    onRefreshShipment(value: boolean){
        if(value && this._shipment){
            this.truckingController.getTruckingShipmentExpand(this._shipment.shipmentId, true).subscribe(data => {
                this._shipmentExpand = data;
                this._shipment = data;
                if(this._shipmentExpand != null && (this._shipmentExpand.packages == null || this._shipmentExpand.packages.length == 0))
                    this.truckingController.refreshShipmentList$.next(true);
            });
        }
    }
    
    public actions: ActionsStripDto;
    onClickAction($event: ActionDto) {
        if ($event == null)
          return;
        switch ($event.actionId) {
            case 'TRUCKING':
                this.onTruckingClick();
                break;
        }
    }
    onTruckingClick(){
        if (this.selectedRouteExpand == null || this._shipment == null)
			return;

        this.truckingController.addShipmentInRoute(this.selectedRouteExpand.routeId, this._shipment.shipmentId).subscribe(data => {
			if(data){
                this.truckingController.refreshShipmentList$.next(true);
                this.truckingController.routeChanged$.next(this.selectedRouteExpand.routeId);
            }
		});
    }
    
    public static TRUCKING: ActionDto = ActionDto.build("TRUCKING", "TRUCKING.SHIPMENT.ACTIONS.TRUCKING.CAPTION", "TRUCKING.SHIPMENT.ACTIONS.TRUCKING.HELP", "", 1, 0, 0, "");
    
}
