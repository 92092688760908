<div class="nav">
    <shared-image *ngIf="logoImage != null" [image]="logoImage" [width]="204" [height]="68" [readonly]="true">
    </shared-image>
    <img *ngIf="logoImage == null" src="/assets/images/HardmanLogoBlancNom.png" width="204px" height="68px">
    <div class="shipmentKey" *ngIf="data && data.shipmentKey">{{data.shipmentKey}}</div>
    <div class="shipmentKey" *ngIf="!data || !data.shipmentKey">{{uuid}}</div>
</div>
<div class="body" *ngIf="loaded && !data">
    {{'TRACKING.UNKNOWN.CAPTION' | translate: {uuid: uuid } }}
</div>
<div class="body" *ngIf="loaded  && data" [ngClass]="data.generalClass">
    <div class="infoaddicional">
        <table class="taula" [ngClass]="{'lesswidth': showActions()}">
            <tr>
                <td class="prompt string">
                    {{'TRACKING.ORIGIN.CAPTION' | translate}}
                </td>
                <td class="value string important">
                    {{data.origin}}
                </td>
            </tr>
            <tr>
                <td class="prompt string">
                    {{'TRACKING.DESTINATIONADDRESS.CAPTION' | translate}}
                </td>
                <td class="value string">
                    {{ data.destinationSkinAddress}} {{ data.destinationAddress}}
                </td>
            </tr>
            <tr>
                <td class="prompt string">
                </td>
                <td class="value string">
                    {{ data.destinationFormatedAddress}}
                </td>
            </tr>
            <tr *ngIf="!isNullOrNulldate(data.collectedAt)">
                <td class="prompt string">
                    {{'TRACKING.COLLECTEDON.CAPTION' | translate}}
                </td>
                <td class="value string">
                    <datehour-viewer [value]="data.collectedAt" [showOriginal]="true" [showTimeZone]="false"
                        [reactive]="true">
                    </datehour-viewer>
                </td>
            </tr>
            <tr *ngIf="data.commitmentDelivery && !isNullOrNulldate(data.commitmentDelivery)">
                <td class="prompt string">
                    {{'TRACKING.COMMITMENTDELIVERY.CAPTION' | translate}}
                </td>
                <td class="value string">
                    <datehour-viewer [value]="data.commitmentDelivery" [showOriginal]="true" [showTimeZone]="false"
                        [reactive]="true">
                    </datehour-viewer>
                </td>
            </tr>
            <tr *ngIf="data.showDriverInfo">
                <td class="prompt string">
                    {{'TRACKING.DRIVER.CAPTION' | translate}}
                </td>
                <td class="value string">
                    {{data.driverName}}
                    <span class="driverLegalIdentification" *ngIf="data.driverLegalIdentification">
                        {{data.driverLegalIdentification}}</span>
                    <span class="phone" *ngIf="data.driverPhone"><i class="fa fa-phone-square" aria-hidden="true"
                            (click)="call(data.driverPhone)"></i> <span class="phonenumber"
                            (click)="copy(data.driverPhone)">{{data.driverPhone}}</span></span>

                    <div class="plate" *ngIf="data.plateA">{{data.plateA}}</div>
                    <div class="plate" *ngIf="data.plateB">{{data.plateB}}</div>
                    <div class="plate" *ngIf="data.plateC">{{data.plateC}}</div>
                </td>
            </tr>
            <!--
            <tr
                *ngIf="data.deliveryState && !isNullOrNulldate(data.destinationFastConditionFirstMoment) && !isNullOrNulldate(data.destinationFastConditionLastMoment)">
                <td class="prompt string">
                    {{'TRACKING.DELIVERYFORECAST.CAPTION' | translate}}
                </td>
                <td class="value string">
                    <datehour-viewer [value]="data.destinationFastConditionFirstMoment"></datehour-viewer> -
                    <datehour-viewer [value]="data.destinationFastConditionLastMoment"></datehour-viewer>
                </td>
            </tr>
            -->
            <tr *ngIf="!isNullOrNulldate(data.deliveredAt)">
                <td class="prompt string">
                    {{'TRACKING.TIMEOFDELIVERY.CAPTION' | translate}}
                </td>
                <td class="value string">
                    <datehour-viewer [value]="data.deliveredAt" [showOriginal]="true" [showTimeZone]="false"
                        [reactive]="true"></datehour-viewer>
                </td>
            </tr>
        </table>

        <div *ngIf="showActions()" class="opcionsproveidor">
            <div class="titol">
                {{'TRACKING.ACTIONS.CAPTION' | translate}}
            </div>
            <div *ngIf="data.showContact" class="botoAccio" (click)="openDialogContacte()">
                <i class="fa fa-phone-square" aria-hidden="true"></i> <span class="caption">{{'TRACKING.CONTACT.CAPTION'
                    | translate}}
                </span>
            </div>
            <div *ngIf="data.showChangeDelivery" class="botoAccio" (click)="openDialogEntrega()">
                <i class="fa fa-calendar" aria-hidden="true"></i><span
                    class="caption">{{'TRACKING.CHANGEDELIVERY.CAPTION' | translate}}</span>
            </div>
            <div *ngIf="data.showDocument" class="botoAccio" (click)="openDocument()">
                <i class="fa fa-file" aria-hidden="true"></i><span class="caption">{{'TRACKING.DOCUMENT.CAPTION' |
                    translate}}</span>
            </div>
            <div *ngIf="data.showShipmentReport" class="botoAccio" (click)="downloadShipmentReport()">
                <i class="fa fa-file-pdf-o" aria-hidden="true"></i><span
                    class="caption">{{'TRACKING.SHIPMENTREPORT.CAPTION' |
                    translate}}</span>
            </div>
        </div>


        <table class="fases nohover" [ngClass]="{'lesswidth': showActions()}">
            <tr>
                <td class="fase" *ngFor="let item of data.internalPhases | sortBy:'order'"
                    [ngClass]="{'completada':item.done, 'actual': item.actual }">
                    <div class="segment">
                    </div>
                    <div *ngIf="!item.hidecaption" class="caixaestat">
                        <div *ngIf="item.alldone" class="icona">
                            <i class="fa fa-check-square" aria-hidden="false"></i>
                        </div>
                        <div class="resta">
                            <div class="linia estado">
                                {{item.caption | translate}}
                            </div>
                            <div class="linia moment">
                                <datehour-viewer [value]="item.moment" [showOriginal]="false" [showTimeZone]="false"
                                    [reactive]="true">
                                </datehour-viewer>

                            </div>
                            <div class="linia origen" *ngIf="item.shipmentCard && item.esOrigen">
                                <div class="linia address skin">
                                    {{item.shipmentCard.originSkinAddress}}
                                    {{item.shipmentCard.originDescriptionShort}}
                                </div>
                                <div class="linia address formated">
                                    {{item.shipmentCard.originFormatedAddress}}
                                </div>
                            </div>
                            <div class="linia desti" *ngIf="item.shipmentCard && item.esDesti">
                                <div class="linia address skin">
                                    {{item.shipmentCard.destinationSkinAddress}}
                                    {{item.shipmentCard.destinationDescriptionShort}}
                                </div>
                                <div class="linia address formated">
                                    {{item.shipmentCard.destinationFormatedAddress}}
                                </div>
                            </div>

                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="group">
        <div class="titol">
            {{'TRACKING.GOODS.CAPTION' | translate}}
        </div>
        <div class="data"><span class="pre-tags-strip">@</span>
            <div class="value">
                <shared-tags-strip-component class="tags" [tags]="data.userTags"></shared-tags-strip-component>
                <span class="goods">{{data.goods}}</span>

            </div>
        </div>

    </div>

    <div class="group">
        <div class="titol">
            {{'TRACKING.DELIVERYDETAILS.CAPTION' | translate}}
        </div>
        <div class="data">
            <div class="value" *ngIf="data && data.comments">
                {{data.comments}}
            </div>
        </div>
    </div>
    <div class="group">
        <div class="titol">
            {{'TRACKING.MAP.CAPTION' | translate}}
        </div>
        <shared-map [marcadors]="marcadors"></shared-map>
    </div>
</div>



<div class="signaturahardman">
    <img class="logoimg" src="/assets/images/HardmanLogoBlanc.png" alt="Hardman logo">
    <a href="https:hardman.app" target="_blank" class="powered">{{'GLOBAL.DESENVOLUPATPER' | translate}}</a>
</div>