import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { BaseController } from '../base.controller';
import { SecurityController } from '../security/security.controller';
import { ExternalLinkDto } from './ExternalLinkDto';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class ExternalLinkController extends BaseController {
    private base = '/externallink';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getLink(externalLinkId: string): Observable<ExternalLinkDto> {
        return super.get(this.base + "/" + externalLinkId);
    }
}