import { BaseController } from '../base.controller';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { QRCodeDto } from './QRCodeDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentsDto } from '@shared/src/dtos/document/DocumentsDto';
import { PhaseQrDto } from '@shared/src/dtos/phase/PhaseQrDto';
import { RouteCMRQrDto } from '@shared/src/dtos/route/cmr/qr/RouteCMRQrDto';
import { PhaseCMRQrDto } from '@shared/src/dtos/route/cmr/qr/PhaseCMRQrDto';


/***
 * Serve per encapsular totes les recerques de mapa.
 */
@Injectable({
    providedIn: 'root'
})
export class QRCodeController extends BaseController {
    private baseUrl = "/qrcode";

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    qrcode(qrcde: QRCodeDto): Observable<FileUpDownLoadDto> {
        return super.post(this.baseUrl + "/", qrcde, "qrcode");
    }

    public getQRCodeReadByShipmentId(shipmentid: number): Observable<FileUpDownLoadDto> {
        return this.get(this.baseUrl + "/shipment/qrcode/read/" + shipmentid);
    }

    public getQRCodesReadByPhaseId(phaseid: number): Observable<PhaseQrDto> {
        return this.get(this.baseUrl + "/phase/qrcode/read/" + phaseid);
    }

    public getQRCodeCMRByRouteId(routeid: number): Observable<RouteCMRQrDto> {
        return this.get(this.baseUrl + "/routecmr/qrcode/" + routeid);
    }

    public getQRCodeCMRByRoutePartCMRId(routepartcmrid: number): Observable<FileUpDownLoadDto> {
        return this.get(this.baseUrl + "/routepartcmr/qrcode/" + routepartcmrid);
    }

    public getQRCodePhaseCMRByPhaseUUID(phaseuuid: string): Observable<PhaseCMRQrDto> {
        return this.get(this.baseUrl + "/phasecmr/qrcode/" + phaseuuid);
    }

    
}
