import { AddressTimetableIntervalDto } from "../AddressTimetableIntervalDto";
import { SlotDto } from "../slots/SlotDto";

export class AddressTimetableIntervalPlanningSlotsDto extends AddressTimetableIntervalDto {

    public slots: Array<SlotDto>;

    public static build(source: AddressTimetableIntervalPlanningSlotsDto) {
        let result = new AddressTimetableIntervalPlanningSlotsDto();
        result.copyFrom(source);
        return result;
    }

    public copyFrom(source: AddressTimetableIntervalPlanningSlotsDto) {
        if (source == null)
            return;
        super.copyFrom(source);
        this.slots = [];
        if (source.slots != null && source.slots.length > 0) {
            this.slots = null;
            if (source.slots != null) {
                this.slots = new Array();
                source.slots.forEach(element => {
                    this.slots.push(element);
                });
            }
        }
    }
}