import { Component, Inject, Input, OnInit } from "@angular/core";
import { FileUpDownloadController } from "@shared/src/controllers/fileupdownload/fileupdownload.controller";
import { ESchenkerController } from "@shared/src/controllers/route/shipment/eschenker/eschenker.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { ESchenkerDetailsDto } from "@shared/src/dtos/shipment/dbschenker/ESchenkerDetailsDto";
import { ShipmentController } from "@shared/src/public-api";

@Component({
    selector: "shared-eschenker-component",
    templateUrl: "./shared.eschenker.component.html",
    styleUrls: ["./shared.eschenker.component.scss"],
})
export class SharedESchenkerComponent implements OnInit {
    constructor(
        public shipmentController: ShipmentController,
        public eschenkerController: ESchenkerController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) { }

    ngOnInit(): void { }

    public data: ESchenkerDetailsDto;

    private _packageId: number;
    public get packageId(): number {
        return this._packageId;
    }
    @Input() public set packageId(value: number) {
        this._packageId = value;
        if (this._packageId != null) {
            this.eschenkerController.getESchenkerDetails(this._packageId).subscribe(x => {
                this.data = x;
            });
        }
    }

    private _shipmentId: number;
    public get shipmentId(): number {
        return this._shipmentId;
    }
    @Input() public set shipmentId(value: number) {
        this._shipmentId = value;
        if (this._shipmentId != null) {
            this.eschenkerController.getESchenkerDetailsByShipment(this._shipmentId).subscribe(x => {
                this.data = x;
            });
        }
    }

    getLabel() {
        if (this.data && this.data.hasLabel) {
            this.eschenkerController.downloadLabel(this.data.eschenkerPackageDataId).subscribe(e => {
                if (e)
                    FileUpDownloadController.resultDownLoad(e);
            })
        }
    }

    public askingCreateLabel: boolean = false;

    createLabel() {
        if (this.data && !this.data.hasLabel) {
            this.askingCreateLabel = false;
            if (this.data.packageId) {
                this.eschenkerController.createLabel(this.data.packageId).subscribe(e => {
                    if (e)
                        this.data = e;
                })
            } else if (this.data.shipmentId) {
                this.eschenkerController.createLabelByShipment(this.data.shipmentId).subscribe(e => {
                    if (e)
                        this.data = e;
                })
            }
        }
    }

    pretty(item: any): string {
        return JSON.stringify(item);
    }
}
