import { ChatParticipantTypes } from "../../../enums/ChatParticipantTypes";
import { HDateHour } from "../../../datatypes/HDateHour";
import { HHour } from "../../../datatypes/HHour";
import { HDate } from "../../../datatypes/HDate";
import { ChatDto } from "../ChatDto";
import { ParticipantDto } from "../participant/ParticipantDto";
import { Injectable } from '@angular/core';

@Injectable()
export class ChatMessageDto {
	public chatMessageId: number;
	public chatId: number;
	public fromUserId: number;
	public fromUserType: ChatParticipantTypes;
	public fromUser: ParticipantDto;
	public targetParticipantId: number;
	public targetParticipantType: ChatParticipantTypes;
	public targetParticipant: ParticipantDto;
	public participants: ParticipantDto[];
	public sent: boolean;
	public sentAt: HDateHour;
	public sentAtHour: HHour;
	public sentAtDate: HDate;
	public received: boolean;
	public receivedAt: HDateHour;
	public receivedAtHour: HHour;
	public receivedAtDate: HDate;
	public read: boolean;
	public readAt: HDateHour;
	public readAtHour: HHour;
	public readAtDate: HDate;
	public isFirstAtDate: boolean;
	public message: string;
	public waiting: boolean = false;
	public lost: boolean = false;
	public chatMessageKey: string;

	public static build(owneruseid: number, ownerUserType: ChatParticipantTypes, chat: ChatDto, message: string): ChatMessageDto {

		let result = ChatMessageDto.empty();

		if (chat == null)
			return result;

		result.chatId = chat.chatId;
		result.fromUserId = owneruseid;
		result.fromUserType = ownerUserType;
		result.targetParticipantId = chat.ownerParticipantId != owneruseid ? chat.ownerParticipantId : chat.targetParticipantId;
		result.targetParticipantType = chat.ownerParticipantId != owneruseid ? chat.ownerParticipantType : chat.targetParticipantType;
		result.sent = true;
		result.sentAt = HDateHour.now();
		result.sentAtHour = HHour.now();
		result.message = message;
		result.waiting = true;
		result.chatMessageKey = Date.now().toPrecision();
		return result;
	}

	public static empty(): ChatMessageDto {
		let result = new ChatMessageDto();
		result.chatMessageId = 0;
		result.chatId = 0;
		result.fromUserId = 0;
		result.fromUserType = ChatParticipantTypes.None;
		result.targetParticipantId = 0;
		result.targetParticipantType = ChatParticipantTypes.None;
		result.sent = false;
		result.sentAt = HDateHour.nullDateHour();
		result.sentAtHour = HHour.build(0);
		result.sentAtDate = HDate.nullDate();
		result.received = false;
		result.receivedAt = HDateHour.nullDateHour();
		result.receivedAtHour = HHour.build(0);
		result.receivedAtDate = HDate.nullDate();
		result.read = false;
		result.readAt = HDateHour.nullDateHour();
		result.readAtHour = HHour.build(0);
		result.readAtDate = HDate.nullDate();
		result.message = "";
		result.isFirstAtDate = false;
		return result;
	}

	public copyFrom(value: ChatMessageDto) {
		this.chatMessageId = value.chatMessageId;
		this.chatId = value.chatId;
		this.fromUserId = value.fromUserId;
		this.fromUserType = value.fromUserType;
		this.targetParticipantId = value.targetParticipantId;
		this.targetParticipantType = value.targetParticipantType;
		this.sent = value.sent;
		this.sentAt = value.sentAt;
		this.sentAtHour = value.sentAtHour;
		this.sentAtDate = value.sentAtDate;
		this.received = value.received;
		this.receivedAt = value.receivedAt;
		this.receivedAtHour = value.receivedAtHour;
		this.receivedAtDate = value.receivedAtDate;
		this.read = value.read;
		this.readAt = value.readAt;
		this.readAtHour = value.readAtHour;
		this.readAtDate = value.readAtDate;
		this.message = value.message;
		this.isFirstAtDate = value.isFirstAtDate;
		this.waiting = value.waiting;
		this.lost = value.lost;
	}
}