import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { GeneralStatus, OpenChangeStatusDto } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-changestatus',
  templateUrl: './shared.route.changestatus.component.html',
  styleUrls: ['./shared.route.changestatus.component.scss'],
})
export class SharedRouteChangeStatusComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public readonly: boolean = false;

  public states: StatusTransitionsDto;

  private _fromActiveRoutes: boolean = true;
  @Input() public set fromActiveRoutes(v: boolean) {
    if (this._fromActiveRoutes === v)
      return;

    this._fromActiveRoutes = v;

    if (!this._fromActiveRoutes) {
      this.routeController.getRouteStates(this.routeId).subscribe(data => {
        this.states = data;
      })
    }
  };

  public get fromActiveRoutes(): boolean {
    return this._fromActiveRoutes;
  }

  public __route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null)
      this.__routeId = 0;
    else
      this.__routeId = value.routeId;
  }
  get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public loadRouteData() {
    if (!this.routeId)
      return;

    this.executing = false;
    
    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.routeUpdated.next(this.route);
    });
  }

  onSelectorChangedState(state: GeneralStatus) {
    if (this.route == null)
      return;
    this.openRouteController.routeSetStatus(this.routeId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
      if (data) {
        this.route.generalStatus = state;
        this.onRouteUpdated(this.route);
      }
    });
  }

  public promised = GeneralStatus.promised;
  public done = GeneralStatus.done;
  public canceled = GeneralStatus.canceled;
  public executing: boolean = false;
  public onSetSuperStatus(state: GeneralStatus) {
    if (this.route == null)
      return;

    if(this.executing)
      return;

    this.executing = true;
    this.openRouteController.setRouteSuperStatus(this.routeId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
      this.executing = false;
      if (data) {
        this.route.generalStatus = state;
        this.onRouteUpdated(this.route);
      }
    });
  }


  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;

    this.routeUpdated.next(value);
  }

  save() {

  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
