import { Component, OnInit, Input, Inject } from '@angular/core';
import { HDate } from '../../../datatypes/HDate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DateValidator } from '../../core/form/validations/DateValidator';
import { DateHourValidator } from '../../core/form/validations/DateHourValidator';
import { HDateHour } from '../../../datatypes/HDateHour';
import { HColor } from '../../../datatypes/HColor';
import { Languages } from '@shared/src/enums/Languages';
import { LanguagesUtil } from '@shared/src/services/lang/LanguagesUtil';
import { enumValidator } from '../../core/form/validations/AutoCompleteValidator';
import { TimeZones } from '@shared/src/enums/TimeZones';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HHour } from '@shared/src/datatypes/HHour';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { HHourIntervals } from '@shared/src/datatypes/HHourIntervals';
import { HTime } from '@shared/src/datatypes/HTime';
import { HourValidator } from '../../core/form/validations/HourValidator';
import { HourIntervalsValidator } from '../../core/form/validations/HourIntervalsValidator';
import { TimeValidator } from '../../core/form/validations/TimeValidator';
import { HTriState } from '@shared/src/public-api';
import { HTags } from '@shared/src/datatypes/HTags';

@Component({
  selector: 'test-datatypes-page',
  templateUrl: './test-datatypes-page.component.html',
  styleUrls: ['./test-datatypes-page.component.scss']
})
export class TestDataTypesPageComponent implements OnInit {

  public rFormDatePicker: FormGroup;
  public rFormDateHourPicker: FormGroup;
  public rFormBoolean: FormGroup;
  public rFormInput: FormGroup;
  public rFormTextArea: FormGroup;
  public rFormColor: FormGroup;
  public rFormEnumSelector: FormGroup;
  public rFormHour: FormGroup;
  public rFormHourIntervals: FormGroup;
  public rFormTime: FormGroup;
  public rFormEntitySelector: FormGroup;
  public rFormHTriState: FormGroup;
  public rFormTags: FormGroup;

  constructor(private fb: FormBuilder, @Inject('SecurityController') protected securityController: SecurityController) {

    this.rFormDatePicker = fb.group({
      'dateFrom': ['', DateValidator.validate],
      'dateTo': ['', Validators.nullValidator],
    });

    this.rFormDateHourPicker = fb.group({
      'dateHourFrom': ['', DateHourValidator.validate],
      'dateHourTo': ['', Validators.nullValidator],
      'timezone': [null, enumValidator(this.enumTimeZones, true)],
      'timezoneObserver': [null, enumValidator(this.enumTimeZonesObserver, true)]
    });

    this.rFormBoolean = fb.group({
      'isdriver': ['', Validators.nullValidator]
    });

    this.rFormInput = fb.group({
      'description': ['', [Validators.maxLength(128)]]
    });

    this.rFormTextArea = fb.group({
      'largedescription': ['', [Validators.maxLength(500)]]
    });

    this.rFormColor = fb.group({
      'colordriver': ['', [Validators.nullValidator]]
    });

    this.rFormEnumSelector = fb.group({
      'language': [Languages.en, enumValidator(this.enumLanguages, true)]
    });

    this.rFormHour = fb.group({
      'Hour': ['', HourValidator.validate]
    });

    this.rFormHourIntervals = fb.group({
      'HourIntervals': ['', HourIntervalsValidator.validate]
    });

    this.rFormTime = fb.group({
      'Time': ['', TimeValidator.validate]
    });

    this.rFormEntitySelector = fb.group({
      'userId': ['', Validators.nullValidator]
    });

    this.rFormHTriState = fb.group({
      'noThermostatRunMode': ['', Validators.nullValidator],
      'thermostatRunMode': ['', Validators.nullValidator]
    });
    
    this.rFormTags = fb.group({
      'tags1': ['', Validators.nullValidator],
      'tags2': ['', Validators.nullValidator],
      'tags3': ['', Validators.nullValidator],
      'tags4': ['', Validators.nullValidator]
    });
  }

  ngOnInit(): void {

    this.vdatefrom = HDate.today();
    this.vdateto = this.vdatefrom.addDays(10);

    this.Timezone = SecurityController.TIMEZONE;
    this.TimezoneObserver = TimeZones.Asia_Tokyo;

    this.vdatehourfrom = HDateHour.nowByTZ(this.Timezone, this.TimezoneObserver);
    this.vdatehourto = this.vdatehourfrom.addDays(10);

    this.visdriver = false;

    this.vdescription = "Text fins a 128 caràcters";
    this.vlargedescription = "Text llarg fins a 500 caràcters";

    this.vcolordriver = HColor.getRandomDark();

    this.language = Languages.ca;

    this.vhour = HHour.now();
    this.vhourIntervals = HHourIntervals.build(this.vhour, this.vhour.addHours(1));
    this.vtime = HTime.build('8:40');

    //this.userId = 128; //--> Id d'usuari que tingui access el tenant que està loguejat
  }

  /**
   * DatePicker
   */
  public _vdatefrom: HDate;
  @Input() set vdatefrom(value: HDate) {
    this._vdatefrom = value;
  }
  get vdatefrom(): HDate {
    return this._vdatefrom;
  }

  public _vdateto: HDate;
  @Input() set vdateto(value: HDate) {
    this._vdateto = value;
  }
  get vdateto(): HDate {
    return this._vdateto;
  }

  /**
   * DateHourPicker
   */
  enumTimeZones = TimeZones;
  public _Timezone: TimeZones;
  @Input() set Timezone(value: TimeZones) {
    this._Timezone = value;
    this.vdatehourfrom = HDateHour.buildByHDateHour(this.vdatehourfrom, (value ? this.enumTimeZones[value] : null), (this._TimezoneObserver ? this.enumTimeZonesObserver[this._TimezoneObserver] : null));
    this.vdatehourto = HDateHour.buildByHDateHour(this.vdatehourto, (value ? this.enumTimeZones[value] : null), (this._TimezoneObserver ? this.enumTimeZonesObserver[this._TimezoneObserver] : null));
  }
  get Timezone(): TimeZones {
    return this._Timezone;
  }

  enumTimeZonesObserver = TimeZones;
  public _TimezoneObserver: TimeZones;
  @Input() set TimezoneObserver(value: TimeZones) {
    this._TimezoneObserver = value;
    this.vdatehourfrom = HDateHour.buildByHDateHour(this.vdatehourfrom, (this._Timezone ? this.enumTimeZonesObserver[this._Timezone] : null), (value ? this.enumTimeZones[value] : null));
    this.vdatehourto = HDateHour.buildByHDateHour(this.vdatehourto, (this._Timezone ? this.enumTimeZonesObserver[this._Timezone] : null), (value ? this.enumTimeZones[value] : null));
  }
  get TimezoneObserver(): TimeZones {
    return this._TimezoneObserver;
  }

  public _vdatehourfrom: HDateHour;
  @Input() set vdatehourfrom(value: HDateHour) {
    this._vdatehourfrom = value;
  }
  get vdatehourfrom(): HDateHour {
    return this._vdatehourfrom;
  }

  public _vdatehourto: HDateHour;
  @Input() set vdatehourto(value: HDateHour) {
    this._vdatehourto = value;
  }
  get vdatehourto(): HDateHour {
    return this._vdatehourto;
  }

  /**
   * Boolean
   */
  public _visdriver: boolean;
  @Input() set visdriver(value: boolean) {
    this._visdriver = value;
  }
  get visdriver(): boolean {
    return this._visdriver;
  }

  /**
   * Input
   */
  public _vdescription: string;
  @Input() set vdescription(value: string) {
    this._vdescription = value;
  }
  get vdescription(): string {
    return this._vdescription;
  }

  /**
   * TextArea
   */
  public _vlargedescription: string;
  @Input() set vlargedescription(value: string) {
    this._vlargedescription = value;
  }
  get vlargedescription(): string {
    return this._vlargedescription;
  }

  /**
   * Color
   */
  public _vcolordriver: HColor;
  @Input() set vcolordriver(value: HColor) {
    this._vcolordriver = value;
  }
  get vcolordriver(): HColor {
    return this._vcolordriver;
  }
  getColorPicker() {
    if (this.vcolordriver != null)
      return this.vcolordriver.web;
    return "No definit";
  }

  /**
  * EnumSelector
  */
  enumLanguages = Languages;
  public _language: Languages;
  @Input() set language(value: Languages) {
    this._language = value;
  }
  get language(): Languages {
    return this._language;
  }

  getReadonly() {
    return false;
  }

  /**
   * Hour
   */
  public _vhour: HHour;
  @Input() set vhour(value: HHour) {
    this._vhour = value;
  }
  get vhour(): HHour {
    return this._vhour;
  }

  /**
   * HourInterval
   */
  public _vhourIntervals: HHourIntervals;
  @Input() set vhourIntervals(value: HHourIntervals) {
    this._vhourIntervals = value;
  }
  get vhourIntervals(): HHourIntervals {
    return this._vhourIntervals;
  }

  /**
   * Time
   */
  public _vtime: HTime;
  @Input() set vtime(value: HTime) {
    this._vtime = value;
  }
  get vtime(): HTime {
    return this._vtime;
  }

  /**
   * EntitySelector
   */
  public _userId: number;
  @Input() set userId(value: number) {
    this._userId = value;
  }
  get userId(): number {
    return this._userId;
  }

  /**
  * HTriState
  */
  public _noThermostatRunMode: HTriState;
  @Input() set noThermostatRunMode(value: HTriState) {
    this._noThermostatRunMode = value;
  }
  get noThermostatRunMode(): HTriState {
    return this._noThermostatRunMode;
  }

  public _thermostatRunMode: HTriState;
  @Input() set thermostatRunMode(value: HTriState) {
    this._thermostatRunMode = value;
  }
  get thermostatRunMode(): HTriState {
    return this._thermostatRunMode;
  }

  public tags1: HTags = new HTags();
  public tags2: HTags = new HTags();
  public tags3: HTags = new HTags();
  public tags4: HTags = new HTags();
}
