<div class="container" *ngIf="route">
    <shared-route-item [route]="route"></shared-route-item>
    <div *ngIf="route.isDivided" class="banner-divided-grouped">
        <div class="icona"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
        <div class="texte">
            {{ 'SHARED.ROUTE.ITEM.BANNER.ISDIVIDED.CAPTION' | translate }}
        </div>
    </div>
    <div *ngIf="route.isChildOfGrouped" class="banner-divided-grouped"
        [innerHTML]="'SHARED.ROUTE.ITEM.BANNER.ISCHILDOFGROUPED.CAPTION' | translate:{ agrupationURI: agrupationURI}">
    </div>
    <div *ngIf="route && route.styledBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledBannerHtml | safe: 'html'">
    </div>
    <div *ngIf="route.styledPrivateBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledPrivateBannerHtml | safe: 'html'">
    </div>
    <div class="bar">

        <div class="baroptions">
            <options class="show-label" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <div class="baractions">
            <shared-state-user [ngClass]="{'show':route.showUserGeneralStatus}" [(value)]="route.userGeneralStatus"
                [(bgcolor)]="route.userGeneralStatusBackgroundColor"></shared-state-user>
            <shared-state [ngClass]="{'reduce':route.showUserGeneralStatus}" [(value)]="states"
                (stateChange)="onSelectorChangedState($event)" [readonly]="readonly"></shared-state>
            <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div class="list childs" *ngIf="selectedOption == 'SHIPMENTS'">
        <shared-shipment-item *ngFor="let item of route.shipments" class="shipmentItem" [shipment]="item"
            [isActiveRoutes]="'true'" (click)="selectShipment(item)"
            [ngClass]="{'selected': selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}">
        </shared-shipment-item>
    </div>
    <div class="list childs" *ngIf="selectedOption == 'PHASES'">
        <shared-phase-item *ngFor="let item of route.phases" class="phaseItem" [phase]="item" [phases]="route.phases"
            [isActiveRoutes]="'true'" (click)="selectPhase(item)"
            [ngClass]="{'selected': selectedPhase!=null && item.phaseId==selectedPhase.phaseId}">
        </shared-phase-item>
    </div>
    <div class="list childs reorder" *ngIf="selectedOption == 'REORDER'">
        <div class="item-container" *ngFor="let item of route.phases">
            <shared-phase-item class="phaseItem" [phase]="item" [phases]="route.phases" [isActiveRoutes]="'true'"
                (click)="selectPhase(item)"
                [ngClass]="{'selected': selectedPhase!=null && item.phaseId==selectedPhase.phaseId}">
            </shared-phase-item>
            <div class="buttons">
                <button class="move-phase move-up" (click)="movePhaseUp(item)">
                    <i class="fa fa-chevron-up"></i>
                    <span>
                        {{ "PHASE.ACTIONS.MOVEUP.SLIM" | translate }}
                    </span>
                </button>
                <button class="move-phase move-down" (click)="movePhaseDown(item)">
                    <i class="fa fa-chevron-down"></i>
                    <span>
                        {{ "PHASE.ACTIONS.MOVEDOWN.SLIM" | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>
    <shared-route-item-edit-maintenance *ngIf="selectedOption == 'INFO'" [route]="route"
        (valueChange)="onValueChange($event)"
        (goParentGrouped)="onGoParentGrouped()"></shared-route-item-edit-maintenance>
    <shared-route-item-log-maintenance *ngIf="selectedOption == 'LOG'" [route]="route">
    </shared-route-item-log-maintenance>
    <shared-chat class="chats-body-zonachat chat-without-header" *ngIf="selectedOption == 'CHAT'" [notes]="true"
        [forcedOwnerParticipant]="forcedOwnerParticipant" (clickParticipant)="clickParticipant($event)">
    </shared-chat>

    <shared-route-group-child-list-component *ngIf="selectedOption == 'ROUTEGROUPSHIPMENT'" [routeId]="route.routeId">
    </shared-route-group-child-list-component>

    <shared-route-divided *ngIf="selectedOption == 'DIVISION'" [route]="route"
        (refreshRoute)="onActionDivision($event)">
    </shared-route-divided>

    <shared-telemetry class="maintenance-component fullheight reduced" *ngIf="selectedOption == 'TELEMETRY'"
        [model]="'Route'" [modelId]="routeId" [dataInici]="route.dateHourFrom" [dataFi]="route.dateHourTo"
        [isActiveRoutes]="true"></shared-telemetry>

    <shared-map-route *ngIf="selectedOption == 'MAP'" [waypoints]="waypoints" [marcadorsExtres]="marcadorsExtres"
        [isRutesActives]="true">
    </shared-map-route>

    <shared-route-booking class="booking" *ngIf="selectedOption == 'BOOKING'" [route]="route"
        (refreshRoute)="onValueChange($event)">
    </shared-route-booking>

    <shared-phase-changedates-component *ngIf="selectedOption == 'PHASESCHANGEDATES'" [routeId]="route.routeId"
        class="component">
    </shared-phase-changedates-component>

    <shared-banners *ngIf="selectedOption == 'MODIFYBANNER'" [routeId]="route.routeId" class="component">
    </shared-banners>

    <shared-route-changestatus *ngIf="selectedOption == 'CHANGESTATUS'" [routeId]="route.routeId"
        [fromActiveRoutes]="fromActiveRoutes" class="component">
    </shared-route-changestatus>

    <shared-route-changeconditions *ngIf="selectedOption == 'CHANGECONDITIONS'" [routeId]="route.routeId"
        class="component">
    </shared-route-changeconditions>

    <shared-statustime-component *ngIf="selectedOption == 'TIMES'" [route]="route" class="component">
    </shared-statustime-component>

    <shared-package-component *ngIf="selectedOption == 'PACKAGES'" [route]="route" class="component">
    </shared-package-component>

    <shared-route-part-cmr-list *ngIf="selectedOption == 'CMR' && routeCMR != null" [routeId]="route.routeId"
        [routeCMR]="routeCMR" [selectedRoutePartCMR]="selectedRoutePartCMR" class="component"
        (selectItem)="selectRoutePartCMR($event)">
    </shared-route-part-cmr-list>

</div>