import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ScopeListMaintenanceDto } from './ScopeListMaintenanceDto';
import { ScopeItemMaintenanceDto } from './ScopeItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { ScopeCustomCodeDto } from '@shared/src/dtos/scope/ScopeCustomCodeDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';

@Injectable()
export class ScopeMaintenanceController extends BaseMaintenanceController<ScopeListMaintenanceDto, ScopeItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "system/dataandparameters/scope");
    }


    public getResourceRoot(): string {
        return "SCOPE";
    }

    public getScopeCustomCode(scopeid: number): Observable<ScopeCustomCodeDto> {
        return this.get(this.getBaseUrl() + "/code/" + scopeid);
    }

    public compileCode(data: ScopeCustomCodeDto): Observable<ScopeCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/compilecode", data, "compileCode");
    }

    public saveCode(data: ScopeCustomCodeDto): Observable<ScopeCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/savecode", data, "saveCode");
    }

    public buildUrl(uri: string): string {
        return "system/dataandparameters/scope" + uri;
    }
}
