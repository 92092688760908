import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TenantTaskListMaintenanceDto } from './TenantTaskListMaintenanceDto';
import { TenantTaskItemMaintenanceDto } from './TenantTaskItemMaintenanceDto';
import { BaseMaintenanceController } from '../../_base/BaseMaintenanceController';
import { AlertService } from '../../../../services/alert/alert.service';
import { LoadingService } from '../../../../services/loading/loading.service';
import { Observable } from 'rxjs';
import { TenantTaskCustomCodeDto } from './TenantTaskCustomCodeDto';


@Injectable()
export class TenantTaskMaintenanceController extends
    BaseMaintenanceController<TenantTaskListMaintenanceDto, TenantTaskItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "system/dataandparameters/tenanttask");
    }


    public getResourceRoot(): string {
        return "TENANTTASK";
    }

    public getTenantTaskCustomCode(tenantTaskid: number): Observable<TenantTaskCustomCodeDto> {
        return this.get(this.getBaseUrl() + "/code/" + tenantTaskid);
    }

    public compileCode(data: TenantTaskCustomCodeDto): Observable<TenantTaskCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/compilecode", data, "compileCode");
    }

    public saveCode(data: TenantTaskCustomCodeDto): Observable<TenantTaskCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/savecode", data, "saveCode");
    }

    public buildUrl(uri: string): string {
        return "/system/dataandparameters/tenanttask" + uri;
    }
}
