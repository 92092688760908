<div class="component component-fullpage register" [ngClass]="generalClass">
    <div class="dummy"></div>
    <shared-register-user-info *ngIf="registerStep=='RegisterUserInfo' || registerStep=='RegisterUserInfoInternal'"
        [registerData]="registerData" (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </shared-register-user-info>
    <register-user-type *ngIf="registerStep=='RegisterAskUserType'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-user-type>
    <register-tenant-info *ngIf="registerStep=='RegisterTenantInfo'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-tenant-info>
    <register-want-to-work-with-you *ngIf="registerStep=='RegisterWantToWorkWithYou'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-want-to-work-with-you>
    <register-make-yourself-visible *ngIf="registerStep=='RegisterMakeYourselfVisible'"
        [newLink]="registerData.newPartnerCompany" [countlinks]="registerData.partnerCompanys.length"
        (onClickAdd)="addLinkCompany($event)" (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-make-yourself-visible>
    <register-ask-user-driver *ngIf="registerStep=='RegisterAskUserDriver'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-ask-user-driver>
    <register-drivers *ngIf="registerStep=='RegisterDrivers'" [newLink]="registerData.newPartnerDriver"
        [countlinks]="registerData.partnerDrivers.length" (onClickAdd)="addLinkDriver($event)"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-drivers>
    <register-products *ngIf="registerStep=='RegisterProducts'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </register-products>
    <shared-register-final *ngIf="registerStep=='RegisterFinal'" [registerData]="registerData"
        (onClickNext)="next($event)" [pendigResponse]="pendigResponse">
    </shared-register-final>
</div>