import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { AmbitAddressClassificationAddressDTO, AmbitAddressClassificationAmbitDTO, AmbitAddressClassificationDTO, AmbitController } from '@shared/src/controllers/ambit/ambit.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { UserSettingController } from '@shared/src/controllers/user/usersetting.controller';
import { HColor } from '@shared/src/datatypes/HColor';
import { HString } from '@shared/src/datatypes/HString';

@Component({
  selector: 'shared-address-ambit-selector-component',
  templateUrl: './shared.address.ambit.selector.component.html',
  styleUrls: ['./shared.address.ambit.selector.component.scss']
})

/***
 * Es el componentº que encapsula la visualització d'una adreça
 * origen i destí son dos objectes respectivament del tipus {longitude:2.23432, latitude:2.34323}
 */
export class SharedAddressAmbitSelectorComponent implements OnInit {

  private PARAMETERAMBITIDNAME = "ambitId";
  private PARAMETERADDRESSIDNAME = "addressId";

  constructor(private activatedRoute: ActivatedRoute, private userSettingController: UserSettingController, private ambitController: AmbitController, private addressController: AddressController, private homeController: HomeController) {
  }

  ngOnInit(): void {

    const routeParams = this.activatedRoute.snapshot.paramMap;
    const queryParams = this.activatedRoute.snapshot.queryParamMap;


    let ambit = routeParams.has(this.PARAMETERAMBITIDNAME) ? routeParams.get(this.PARAMETERAMBITIDNAME) : queryParams.get(this.PARAMETERAMBITIDNAME);
    if (ambit) {
      this.ambitId = +ambit;
    } else {
    }
    let address = routeParams.has(this.PARAMETERADDRESSIDNAME) ? routeParams.get(this.PARAMETERADDRESSIDNAME) : queryParams.get(this.PARAMETERADDRESSIDNAME);
    if (address) {
      this.addressId = +address;
    } else {
    }

  }

  public searchTextAddress: string = "";
  public searchTextAmbit: string = "";
  public sliceCount: number = 3;

  @Output() changeValues = new EventEmitter<AddressAmbitSelectorDto>();

  private __value: AddressAmbitSelectorDto;
  @Input()
  public set value(value: AddressAmbitSelectorDto) {
    if (this.__value === value)
      return;
    this.__value = value;

    if (this.__value) {
      if (!HString.isNullOrNullString(this.classification)) {
        this.ambitController.getAmbitAddressClassification(this.classification).subscribe(data => {
          this.data = data;
          this.loadAmbits(data, false, false);
        });
      } else
        this.loadAddressAmbitSelector();
    }
  }
  public get value(): AddressAmbitSelectorDto {
    return this.__value;
  }

  public loadAddressAmbitSelector() {

    if (this.ambitsTots)
      this.selectAmbit(this.ambitsTots.find(x => { return x.ambitId == this.selectedAmbitId }), false);
    else {

      if (this.value && this.value.forcedAddresses && this.value.forcedAddresses.length > 0) {
        const data: AmbitAddressClassificationAmbitDTO = new AmbitAddressClassificationAmbitDTO();
        data.addressList = this.value.forcedAddresses;
        this.loadAddress(data, false, false);
      } else if (this.addressTots)
        this.selectAddress(this.addressTots.find(x => { return x.addressId == this.selectedAddressId }), false, false, false);
      else
        this.changeValues.next(this.value);
    }

  }

  @Input()
  public set addressId(value: number) {
    if (this.value)
      this.value.addressId = value;
  }
  public get addressId() {
    if (this.value)
      return this.value.addressId;
    return 0;
  }

  public get classification() {
    if (this.value)
      return this.value.classification;
    return "";
  }

  @Input()
  public set ambitId(value: number) {
    if (this.value)
      this.value.ambitId = value;
  }
  public get ambitId() {
    if (this.value)
      return this.value.ambitId;
    return 0;
  }

  public get showAmbits() {
    if (this.value)
      return this.value.showAmbits;
    return false;
  }

  public get showAddresses() {
    if (this.value)
      return this.value.showAddresses;
    return false;
  }

  public get maxAmbits() {
    if (this.value)
      return this.value.maxAmbits;
    return 0;
  }

  public get maxAddress() {
    if (this.value)
      return this.value.maxAddress;
    return 0;
  }

  public get canunsetaddress() {
    if (this.value)
      return this.value.canunsetaddress;
    return false;
  }

  public getAddressToolTip(a: AddressSelectorDto): string {
    if (a == null)
      return "";
    return a.addressKey + " " + a.description;
  }

  public getAmbitToolTip(a: AmbitSelectorDto): string {
    if (a == null)
      return "";
    return a.ambitKey + " " + a.description;
  }


  public data: AmbitAddressClassificationDTO;
  public ambitsBarra: Array<AmbitSelectorDto>;
  public ambitsMenu: Array<AmbitSelectorDto>;
  public ambitsTots: Array<AmbitSelectorDto>;
  public addressBarra: Array<AddressSelectorDto>;
  public addressMenu: Array<AddressSelectorDto>;
  public addressTots: Array<AddressSelectorDto>;
  public selectedAddressId: number;
  public selectedAmbitId: number;
  public menuAmbitsObert: boolean = false;
  public menuAddressObert: boolean = false;
  public ambitsAddicionals: number;
  public addressAddicionals: number;
  public seleccionatMenuMesAddress: boolean = false;
  public seleccionatMenuMesAmbit: boolean = false;

  /***
   * Es prepara les dades per tal de mostrar la pantalla. FA la part d0'ambits
   */
  loadAmbits(data: AmbitAddressClassificationDTO, perPantalla: boolean, setLastAddress: boolean) {
    this.ambitsBarra = new Array<AmbitSelectorDto>();
    this.ambitsMenu = new Array<AmbitSelectorDto>();
    this.ambitsTots = new Array<AmbitSelectorDto>();
    if (data == null || data.ambitList == null) {
      this.changeValues.next(this.value);
      return;
    }

    //No cal ordenar-los però si que calculo forecolor i backcolor per ser més ràpid després i sobretot si es mostra a barra o a llista
    let i = 0;
    data.ambitList.sort(this.ambitController.compareAmbits).reverse().forEach(a => {
      let dto = AmbitSelectorDto.build(a, i < this.maxAmbits, this.homeController);
      if (i++ < this.maxAmbits)
        this.ambitsBarra.push(dto);
      else
        this.ambitsMenu.push(dto);
      this.ambitsTots.push(dto);
    });

    if (this.ambitsMenu.length > 0)
      this.ambitsMenu.unshift(this.ambitsBarra.pop());
    this.ambitsAddicionals = this.ambitsMenu.length;

    let propostaAmbit = data.defaultAmbitId;
    if (this.ambitId && this.ambitId != 0)
      propostaAmbit = this.ambitId;
    this.selectAmbit(this.ambitsTots.find(x => { return x.ambitId == propostaAmbit }), perPantalla);

  }

  loadAddress(data: AmbitAddressClassificationAmbitDTO, perPantalla: boolean, changedAmbit: boolean) {
    this.addressBarra = new Array<AddressSelectorDto>();
    this.addressMenu = new Array<AddressSelectorDto>();
    this.addressTots = new Array<AddressSelectorDto>();

    if (data == null || data.addressList == null) {
      this.actualitzarClassification(perPantalla, this.selectedAmbitId, this.selectedAddressId);
      return;
    }


    let propostaAddress = this.data ? this.data.defaultAddressId : 0;
    if (this.addressId && this.addressId != 0)
      propostaAddress = this.addressId;

    data.addressList.forEach(element => {
      element.selected = element.addressId == propostaAddress;
    });

    //No cal ordenar-los però si que calculo forecolor i backcolor per ser més ràpid després i sobretot si es mostra a barra o a llista
    let i = 0;
    data.addressList.sort(this.ambitController.compareAddress).reverse().forEach(a => {
      let dto = AddressSelectorDto.build(a, i < this.maxAddress, this.homeController)
      if (i++ < this.maxAddress)
        this.addressBarra.push(dto);
      else
        this.addressMenu.push(dto);
      this.addressTots.push(dto);
    });

    if (this.addressMenu.length > 0)
      this.addressMenu.unshift(this.addressBarra.pop());
    this.addressAddicionals = this.addressMenu.length;

    if (this.selectedAddressId && this.selectedAddressId != 0)
      this.selectAddress(this.addressTots.find(x => { return x.addressId == this.selectedAddressId }), perPantalla, changedAmbit, true);
    else {

      if (propostaAddress && propostaAddress != 0)
        this.selectAddress(this.addressTots.find(y => { return y.addressId == propostaAddress }), perPantalla, changedAmbit, false);
      else if (this.ambitId && this.ambitId != 0) {
        if (this.value && this.value.classification) {
          this.selectAddress(null, perPantalla, changedAmbit, true);
        } else {
          this.userSettingController.getSetting("LastAddressIdOnAmbitId." + this.ambitId).subscribe(x => {
            this.selectAddress(this.addressTots.find(y => { return y.addressId == x }), perPantalla, changedAmbit, false);
          });
        }
      } else {
        this.selectAddress(null, perPantalla, changedAmbit, true);
      }
    }
  }

  obrirMenuAmbits() {
    this.menuAmbitsObert = !this.menuAmbitsObert;
  }
  obrirMenuAddress() {
    this.menuAddressObert = !this.menuAddressObert;
  }
  selectScreenAmbit(item: AmbitSelectorDto) {
    this.ambitId = item != null ? item.ambitId : 0;
    if (this.value && this.value.classification) {
      this.selectAmbit(item, true);
    } else {
      this.userSettingController.setDefaultAmbitId(this.ambitId).subscribe(data => {
        this.selectAmbit(item, true);
      });
    }
  }
  selectAmbit(item: AmbitSelectorDto, perPantalla: boolean) {
    let proposta = item != null && item != undefined ? item.ambitId : 0;
    if (this.ambitId && this.ambitId != 0)
      proposta = this.ambitId;
    if (proposta === this.selectedAmbitId) {
      if (perPantalla)
        this.changeValues.next(this.value);
      return;
    }
    this.selectedAmbitId = proposta;
    this.menuAmbitsObert = false;
    this.seleccionatMenuMesAmbit = false;

    this.ambitsMenu.forEach(i => { this.seleccionatMenuMesAmbit = this.seleccionatMenuMesAmbit || i.ambitId == this.selectedAmbitId });

    this.__value.ambit = item;
    this.__value.ambitId = item != null ? item.ambitId : 0;

    //Poso les adreces al selector d'adreces
    if (this.data && this.data.ambitList) {
      this.loadAddress(this.data.ambitList.find(i => { return i.ambitId == this.selectedAmbitId }), perPantalla, true);
    } else
      this.changeValues.next(this.value);
  }

  public actualitzarClassification(perPantalla: boolean, ambitId: number, addressId: number) {
    if (!perPantalla) {
      this.changeValues.next(this.value);
      return;
    }
    if (this.value && this.value.classification) {
      this.data.defaultAmbitId = ambitId;
      this.data.defaultAddressId = addressId;
      this.ambitController.setAmbitAddressClassification(this.value.classification, this.data.defaultAmbitId, this.data.defaultAddressId).subscribe(data => {
        this.changeValues.next(this.value);
      });
    }
  }

  selectScreenAddress(item: AddressSelectorDto) {

    if (this.canunsetaddress && item != null && item.addressId === this.addressId) {
      this.addressId = 0;

      if (this.value && this.value.classification) {
        this.selectAddress(null, true, false, true);
      } else {
        this.userSettingController.setDefaultAddressId(this.addressId).subscribe(data => {
          this.selectAddress(null, true, false, true);
        });
      }
      return;
    }

    this.addressId = item != null ? item.addressId : 0;

    if (this.value && this.value.classification) {
      this.selectAddress(item, true, false, true);
    } else {
      this.userSettingController.setDefaultAddressId(this.addressId).subscribe(data => {
        this.selectAddress(item, true, false, true);
      });
    }
  }
  selectAddress(item: AddressSelectorDto, perPantalla: boolean, changedAmbit: boolean, setLastAddress: boolean) {
    let proposta = item != null && item != undefined ? item.addressId : 0;
    if (!perPantalla && this.addressId && this.addressId != 0)
      proposta = this.addressId;
    if (proposta === this.selectedAddressId) {
      if (changedAmbit) {
        this.actualitzarClassification(perPantalla, this.selectedAmbitId, this.selectedAddressId);
      }
      return;
    }

    this.selectedAddressId = proposta;
    this.menuAddressObert = false;
    this.seleccionatMenuMesAddress = false;

    if (this.addressMenu != null)
      this.addressMenu.forEach(i => { this.seleccionatMenuMesAddress = this.seleccionatMenuMesAddress || i.addressId == this.selectedAddressId });

    this.__value.address = item;
    this.__value.addressId = item != null ? item.addressId : 0;

    if (this.ambitId && this.ambitId != 0 && setLastAddress) {
      if (this.value && this.value.classification) {
        this.actualitzarClassification(perPantalla, this.selectedAmbitId, this.selectedAddressId);
      } else {
        this.userSettingController.setSetting("LastAddressIdOnAmbitId." + this.ambitId, this.selectedAddressId + "").subscribe(x => {
          this.changeValues.next(this.value);
        });
      }
    } else {
      this.actualitzarClassification(perPantalla, this.selectedAmbitId, this.selectedAddressId);
    }
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}



export class AddressAmbitSelectorDto {

  public classification: string;

  public addressId: number;
  public address: AddressSelectorDto;
  public ambitId: number;
  public ambit: AmbitSelectorDto;
  public showAddresses: boolean = true;
  public showAmbits: boolean = true;
  public canunsetaddress: boolean;
  public maxAmbits: number = 6;
  public maxAddress: number = 6;

  public forcedAddresses: Array<AmbitAddressClassificationAddressDTO>;

  public static buildDailyDocks(addressId: number, ambitId: number): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "dailyDocks";
    dto.addressId = addressId;
    dto.ambitId = ambitId;
    return dto;
  }

  public static buildDailySlots(addressId: number, ambitId: number): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "dailySlots";
    dto.addressId = addressId;
    dto.ambitId = ambitId;
    return dto;
  }

  public static buildStatisticsBooking(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "statististicsbookingslots";
    return dto;
  }

  public static buildStatisticsSlots(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "statististicsslotspage";
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildStatisticsDelivery(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "statisticsdelivery";
    return dto;
  }

  public static buildPhaseTimesTable(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "desktopphasetimestablepage";
    return dto;
  }

  public static buildSharedStatusTableAddress(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "sharedStatusTableAddress";
    return dto;
  }

  public static buildDocumentaryReview(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "documentaryReview";
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildPanelState(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "panelPage";
    return dto;
  }

  public static buildActiveRoutes(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "activeRoutes";
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildActiveRoutesMobile(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "activeRoutes";
    dto.canunsetaddress = true;
    dto.maxAmbits = 3;
    dto.maxAddress = 3;
    return dto;
  }


  public static buildActiveSlots(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "activeSlots";
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildActiveSlotsMobile(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "activeSlots";
    dto.canunsetaddress = true;
    dto.maxAmbits = 3;
    dto.maxAddress = 3;
    return dto;
  }

  public static buildInternational(addressId: number, ambitId: number): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "international";
    dto.canunsetaddress = true;
    dto.addressId = addressId;
    dto.ambitId = ambitId;
    return dto;
  }

  public static buildDocumentaryReviewRoutes(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "documentaryReviewRoutes";
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildDocumentaryReviewRoutesMobile(): AddressAmbitSelectorDto {
    let dto: AddressAmbitSelectorDto = new AddressAmbitSelectorDto();
    dto.classification = "documentaryReviewRoutes";
    dto.canunsetaddress = true;
    dto.maxAmbits = 3;
    dto.maxAddress = 3;
    return dto;
  }

  public setClassification(classification: string): AddressAmbitSelectorDto {
    this.classification = classification;
    return this;
  }
  public setCanUnsetAddress(value: boolean): AddressAmbitSelectorDto {
    this.canunsetaddress = value;
    return this;
  }
}

export class AddressSelectorDto {
  public addressId: number;
  public addressKey: string;
  public skin: string;
  public description: string;
  public color: string;
  public backgroundColor: string;

  public score: number;

  public barra: boolean;

  public style: any;

  public static build(address: AmbitAddressClassificationAddressDTO, barra: boolean, homeController: HomeController): AddressSelectorDto {
    let result: AddressSelectorDto = new AddressSelectorDto();
    result.addressId = address.addressId;
    result.addressKey = address.addressKey;
    result.skin = address.skin;
    result.description = address.description;

    result.score = address.score;

    result.backgroundColor = HColor.getColorFromStringTable(address.addressKey, homeController);
    result.color = HColor.getWhiteBlackFromColor(result.backgroundColor);

    result.style = { 'color': result.color, 'backgroundColor': result.backgroundColor };

    result.barra = barra;
    return result;
  }
}

export class AmbitSelectorDto {
  public ambitId: number;
  public ambitKey: string;
  public skin: string;
  public description: string;
  public color: string;
  public backgroundColor: string;
  public score: number;

  public barra: boolean;

  public style: any;

  public static build(ambit: AmbitAddressClassificationAmbitDTO, barra: boolean, homeController: HomeController): AmbitSelectorDto {
    let result: AmbitSelectorDto = new AmbitSelectorDto();
    result.ambitId = ambit.ambitId;
    result.ambitKey = ambit.ambitKey;
    result.skin = ambit.skin;
    result.description = ambit.description;
    result.score = ambit.score;

    result.backgroundColor = HColor.getColorFromStringTable(ambit.ambitKey, homeController);
    result.color = HColor.getWhiteBlackFromColor(result.backgroundColor);

    result.style = { 'color': result.color, 'backgroundColor': result.backgroundColor };

    result.barra = barra;
    return result;
  }
}
