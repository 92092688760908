<div class="prompt" *ngIf="hiHaTags"><ng-content></ng-content></div>
<span *ngIf="!maxTags">
    <div class="tag" *ngFor="let tag of tagsString" [ngStyle]="getStyle(tag)" (dblclick)="copy(tag)">{{tag}}</div>
    <div class="tag" *ngFor="let tag of tagsArray" [ngStyle]="getStyle(tag.id)" matTooltip="{{tag.tooltip | translate}}"
        (dblclick)="copy(tag.label)">
        {{tag.label | translate}}
    </div>
</span>
<span *ngIf="maxTags">
    <div class="tag" *ngFor="let tag of tagsString | slice:0:maxTags; let i=index" [ngStyle]="getStyle(tag)"
        (dblclick)="copy(tag)">{{tag}}</div>
    <div class="tag" *ngFor="let tag of tagsArray | slice:0:maxTags; let i=index" [ngStyle]="getStyle(tag.id)"
        matTooltip="{{tag.tooltip | translate}}" (dblclick)="copy(tag.label)">
        {{tag.label | translate}}
    </div>
</span>