import { Pipe, PipeTransform } from '@angular/core';
import { onlyOptionsFilter } from './onlyOptionsFilter';
import { OptionDto } from './OptionDto';

@Pipe({
    name: "avoidOptions"
})
export class avoidOptionsFilter implements PipeTransform {
    public transform(items: OptionDto[], term: string) {
        return avoidOptionsFilter.filter(items, term);
    }

    public static filter(items: OptionDto[], term: string) {
        if (!items) return [];
        if (!term) return items;

        let lowercase = term.toLowerCase();

        return items.filter(value => {
            return !onlyOptionsFilter.doTheFilter(lowercase, value);
        });
    }
}