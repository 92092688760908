<ng-container *ngIf="showNulls || isNotNull">
    <span (click)="onClick()"
        *ngIf="showingReactive && showOriginal">{{ valueOriginal | datehournosecondsreactive }}</span>
    <span (click)="onClick()"
        *ngIf="showingReactive && !showOriginal">{{ valueOriginal | datehournosecondsobserverreactive }}</span>
    <span (click)="onClick()"
        *ngIf="!showingReactive && showOriginal && showingTimeZone">{{ valueOriginal | datehour }}</span>
    <span (click)="onClick()"
        *ngIf="!showingReactive && showOriginal && !showingTimeZone">{{ valueOriginal | datehournotz }}</span>
    <span (click)="onClick()"
        *ngIf="!showingReactive && !showOriginal && showingTimeZone">{{ valueOriginal | datehournosecondsobserver }}</span>
    <span (click)="onClick()"
        *ngIf="!showingReactive && !showOriginal && !showingTimeZone">{{ valueOriginal | datehournosecondsnotzobserver }}</span>
</ng-container>