import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerHealthCertifierUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.HEALTHCERTIFIER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/healthcertifier/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}