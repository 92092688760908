import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class ScopeEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): ScopeEntitySelectorDto {
		let result: ScopeEntitySelectorDto = new ScopeEntitySelectorDto();
		result.loadData(EntitysSelectors.Scope, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/scope";
	}

	public getUrlBack(): string {
		return "/maintenance/scope";
	}

}