<div *ngIf="booking && booking.bookingId && (showFreightForwarderSelector || showShipperSelector)" class="card"
    [ngClass]="mode">
    <span class="title d-block">{{ 'PARTNER.TITLE' | translate }}</span>
    <div class="selectors">
        <entity-selector [searchBeforeFocus]="true" (hasResults)="showFreightForwarderSelector = $event"
            *ngIf="!forcedHidePartnerFreightForwarder && showFreightForwarderSelector" [(ngModel)]="partnerFreightForwarderId"
            (valuechangefunction)="setFreightForwarder()" [entitySelector]="'Partner'"
            [fixedFilterKeys]="filterkeysFreightForwarder"
            placeholder="{{ 'ROUTE.FREIGHTFORWARDER.CAPTION' | translate }}"
            [readonly]="readonly"></entity-selector>
        <entity-selector [searchBeforeFocus]="true" (hasResults)="showShipperSelector = $event"
            *ngIf="!forcedHidePartnerShipper && showShipperSelector" [(ngModel)]="partnerShipperId" (valuechangefunction)="setShipper()"
            [entitySelector]="'Partner'" [fixedFilterKeys]="filterkeysShipper"
            placeholder="{{ 'ROUTE.SHIPPER.CAPTION' | translate }}"
            [readonly]="readonly"></entity-selector>

        <button class="link d-block"
            (click)="mode = 'read'">{{ 'BOOKINGPAGE.PARTNERSELECTOR.CANCEL.CAPTION' | translate }}</button>
    </div>
    <div class="info-partners-list cards">
        <shared-partner-infocard class="freightforwarder" [partnerId]="booking.partnerFreightForwarderId"
            *ngIf="!forcedHidePartnerFreightForwarder && (showFreightForwarderSelector || booking.partnerFreightForwarderId)" [type]="'freightforwarder'"
            [caption]="'ROUTE.FREIGHTFORWARDER.CAPTION'"></shared-partner-infocard>
        <shared-partner-infocard class="shipper" [partnerId]="booking.partnerShipperId"
            *ngIf="!forcedHidePartnerShipper && (showShipperSelector || booking.partnerShipperId)" [type]="'shipper'"
            [caption]="'ROUTE.SHIPPER.CAPTION'"></shared-partner-infocard>
        <button class="link d-block" *ngIf="booking.isModifiable"
            (click)="mode = 'write'">{{ 'BOOKINGPAGE.PARTNERSELECTOR.CHANGE.CAPTION' | translate }}</button>
    </div>
</div>