import { Injectable, Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment-timezone';
import { SecurityController } from '../controllers/security/security.controller';
const moment = moment_;

@Pipe({
    name: 'price'
})
@Injectable()
export class PriceFilter implements PipeTransform {
    transform(value: any): string {

        if (value == null)
            return "0.00";

        if (value == "NaN")
            return "-,--";

        if (isNaN(value))
            return "-,__";

        let negatiu = value < 0;

        let result: string;
        let partsencera = Math.floor(value);
        let partdecimal = Math.floor(Math.round((value - partsencera) * 100));
        // let signe = "";
        // if (negatiu)
        //     signe = "-";

        result = this.pad(partsencera.toString(), 1).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + "," + this.pad(partdecimal.toString(), 2);

        return result;
    }

    pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - Math.max(num.length, size));
    }
}

@Pipe({
    name: 'quantity'
})
@Injectable()
export class QuantityFilter implements PipeTransform {
    transform(value: any): string {

        if (value == null)
            return "0";

        if (value == "NaN")
            return "-";

        if (isNaN(value))
            return "-";

        let negatiu = value < 0;

        let result: string;
        let partsencera = Math.floor(value);
        let partdecimal = Math.floor(Math.round((value - partsencera) * 100));
        // let signe = "";
        // if (negatiu)
        //     signe = "-";
        if (value == 0)
            return "";
        if (partdecimal == 0)
            return partsencera.toString();

        result = this.pad(partsencera.toString(), 1).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + "." + partdecimal.toString();

        return result;
    }

    pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - Math.max(num.length, size));
    }
}

@Pipe({
    name: 'enter'
})
@Injectable()
export class EnterFilter implements PipeTransform {
    transform(value: any): string {
        //Suposem que és un enter, el que volem son els separadors de milers

        if (value == null)
            return "0";

        if (value == "NaN")
            return "-";

        if (isNaN(value))
            return "-";

        let enterValue = value * 1;
        if (enterValue == value) {
            let result = enterValue.toLocaleString(SecurityController.LANGUAGE);
            return result;
        }
        return value;
    }

    pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - Math.max(num.length, size));
    }
}