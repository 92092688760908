
import { Component, Inject, Input, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { BaseLogDto } from '@shared/src/dtos/route/BaseLogDto';
import { BaseCMREventDto } from '@shared/src/dtos/route/cmr/BaseCMREventDto';
import { SharedAsideFactory } from '@shared/src/public-api';


@Component({
    selector: 'shared-cmr-events-list',
    templateUrl: './shared.cmr.events.list.component.html',
    styleUrls: ['./shared.cmr.events.list.component.scss']
})
export class SharedCMREventsListComponent implements OnInit {

    public useIonic: boolean = false;
    public searchText: string = "";

    constructor(@Inject('HomeController') protected homeController: HomeController,
        @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }


    ngOnInit() {

    }

    @Input()
    public events: BaseCMREventDto[];

    public getEvents(){
        if(this.events == null)
            return new Array();
        return this.events;
    }
    
    public showAside: boolean = false;
    onClickUser(item: BaseLogDto) {
        if (item != null && item.userId != null) {
            if (this.showAside)
                return;
            this.showAside = true;
            this.sharedAsideFactory.invokeUserLogInfo(item.userId).then(value => {
                this.showAside = false;
            });
        }
    }

    pretty(data) {
        return JSON.stringify(data);
    }
}
