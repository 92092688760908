import { BaseController } from '../../base.controller';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router, ParamMap } from '@angular/router';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { SecurityController } from '../../security/security.controller';
import { HomeController } from '../../home/home.controller';
import { SituacionsEnLesQueEncaixes } from '@shared/src/enums/SituacionsEnLesQueEncaixes';
import { UuidRegisterDto } from '@shared/src/dtos/register/UuidRegisterDto';
import { HString } from '@shared/src/datatypes/HString';

@Injectable({
    providedIn: 'root'
})
export class UserRegisterController extends BaseController {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        @Inject('SecurityController') protected securityController: SecurityController,
        @Inject('HomeController') protected homeController: HomeController,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    private base = "/userregister";
    start(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/start", data, "register_start");
    }

    refresh(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/refresh", data, "register_refresh");
    }

    join(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/join", data, "register_join");
    }

    next(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.postBusy(this.base + "/wizard/next", data, "register_next");
    }

    addlinkdriver(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/addlinkdriver", data, "register_next");
    }

    addLinkCompany(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/addlinkcompany", data, "register_next");
    }

    back(data: UserRegisterWizardDto): Observable<UserRegisterWizardDto> {
        return super.post(this.base + "/wizard/back", data, "register_back");
    }

    checkEmail(email: string): Observable<boolean> {
        return super.post(this.base + "/checkemail", email, "registerCheckEmail");
    }

    checkPhone(prifix: string, phone: string): Observable<boolean> {
        return super.post(this.base + "/checkphone", Phone.build(prifix, phone), "registerCheckPhone")
    }

    quickRegister(data: UuidRegisterDto): Observable<UuidRegisterDto> {
        return super.postBusy(this.base + "/quickregister", data, "quickRegister");
    }

    startPage(registerData: UserRegisterWizardDto, params: ParamMap): Observable<UserRegisterWizardDto> {

        return new Observable(observer => {
            try {
                let join = params.get("join");
                let linkid = null;
                let value = parseInt(params.get("linkid"));
                if (!isNaN(value))
                    linkid = value;
                if (join == null || join == '' || linkid == null || join == '') {
                    this.start(registerData).subscribe((data: UserRegisterWizardDto) => {
                        observer.next(data);
                        observer.complete();
                        return;
                    });
                } else {
                    if (registerData != null) {
                        registerData.situacioEnLaQueEncaixes = join == 'driver' ? SituacionsEnLesQueEncaixes.Conductor : SituacionsEnLesQueEncaixes.ServeisTransport;
                        registerData.joinlinkid = linkid;
                    }
                    this.join(registerData).subscribe((data: UserRegisterWizardDto) => {
                        observer.next(data);
                        observer.complete();
                        return;
                    });
                }
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    registerEMail(registerData: UserRegisterWizardDto): Observable<boolean> {
        return new Observable(observer => {
            if (registerData == null)
                return;
            //Assignem les dades que falten per poder generar el token a bd
            registerData.platform = this.homeController.getPlatform();
            registerData.solution = this.homeController.getSolution();
            registerData.operatingSystem = this.homeController.getOS();
            registerData.deviceToken = this.securityController.getDeviceToken();
            this.next(registerData).subscribe((data: UserRegisterWizardDto) => {
                if (data.result && data.token) {
                    this.securityController.doTheLogin(data.token, null, data.userId, data.geolocationMode, data.timeZone, "", data.hardmanEntornGeneralClass);
                } else {
                    observer.next(false);
                    observer.complete();
                    return;
                }
            });
        });
    }

    performQuickRegister(registerData: UuidRegisterDto): Observable<boolean> {
        return new Observable(observer => {
            try {
                if (registerData == null) {
                    observer.next(false)
                    observer.complete();
                    return;
                }
                //Assignem les dades que falten per poder generar el token a bd
                registerData.platform = this.homeController.getPlatform();
                registerData.solution = this.homeController.getSolution();
                registerData.operatingSystem = this.homeController.getOS();
                registerData.deviceToken = this.securityController.getDeviceToken();

                this.quickRegister(registerData).subscribe((data: UuidRegisterDto) => {
                    if (data.userId && data.token) {
                        if (HString.isNullOrNullString(data.eMail))
                            this.securityController.loginByPhoneWithRedirect(data.prefix + data.phone, data.password, data.platform, data.solution, data.operatingSystem, "/route/uuid/" + registerData.uuid);
                        else
                            this.securityController.loginByEmailWithRedirect(data.eMail, data.password, data.platform, data.solution, data.operatingSystem, "/route/uuid/" + registerData.uuid);

                        this.securityController.loginStatusChanged$.subscribe(logged => {
                            observer.next(logged);
                            observer.complete();
                        });

                    } else {
                        observer.next(false);
                        observer.complete();
                        return;
                    }
                });
            } catch {
                observer.next(false)
                observer.complete();
                return;
            }
        });
    }

}

export class Phone {
    public prefix: string;
    public phone: string;

    public static build(prefix: string, phone: string): Phone {
        let result = new Phone();
        result.prefix = prefix;
        result.phone = phone;
        return result;
    }
}