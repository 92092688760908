<input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">
<shared-filter class="readonly" [ngClass]="{'chip-filter' : activeInterestedFields.list.length > 0}"
    *ngIf="activeInterestedFields" [filterstrip]="activeInterestedFields" (change)="loadData($event)">
</shared-filter>

<div class="container-table grup" [ngClass]="{'ionic' : useIonic}">
    <div class="contingut">
        <table class="hardman" style="width:100%">
            <tr class="dark">
                <th class="header id">ID</th>
                <th class="header datehour">{{'ROUTELOGSTABLE.MODIFIED_AT' | translate}}</th>
                <th class="header message">{{'ROUTELOGSTABLE.MESSAGE' | translate}}</th>
                <th class="header alias">{{'ROUTELOGSTABLE.USER' | translate}}</th>
            </tr>
            <tr *ngFor="let item of logs | filter:searchText | sortBy:'-modifiedAt'" [ngClass]="item.action">
                <td class="id">{{item.id}}</td>
                <td class="datehour">
                    <datehour-viewer [value]="item.modifiedAt" [showOriginal]="false" [showTimeZone]="false">
                    </datehour-viewer>
                </td>
                <td class="message">{{ item.message }}</td>
                <td class="alias">
                    <span class="only-alias">{{item.alias}}</span>
                    <button-link class="link-alias" variant="action" (linkClick)="onClickUser(item)">
                        {{item.alias}}</button-link>
                </td>
            </tr>
        </table>
    </div>
</div>