<div class="encolumnat">
    <h1 class="titol">{{ 'REGISTERCOMPONENT.USER_TYPE.TITLE' | translate }}</h1>
    <h2 class="texte">{{ 'REGISTERCOMPONENT.USER_TYPE.SUBTITLE' | translate }}</h2>

    <div class="situacions">
        <div class="item" *ngFor="let situacio of situacions"
            [ngClass]="{'selected':registerData.situacioEnLaQueEncaixes==situacio}" (click)="setSituacio(situacio)">
            <div class="titol" [innerHTML]="getTitle(situacio) | translate"></div>
            <div class="help" [innerHTML]="getHelp(situacio) | translate"></div>
        </div>
    </div>

    <div *ngIf="registerData.situacioEnLaQueEncaixes=='Conductor'" class="backgroundPoliciy">
        {{'REGISTERCOMPONENT.USER_TYPE.POLICY.CONDUCTOR' | translate}}
    </div>
    <div class="button-zone">
        <button mat-flat-button color="primary" class="action seguent sol" [disabled]="!canRegister() || pendigResponse"
            (click)="next()">{{'REGISTERCOMPONENT.NEXTBUTTON.LABEL' | translate}}</button>
    </div>
</div>