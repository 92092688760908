import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HDateHour } from '../../../../datatypes/HDateHour';
import { Moment } from 'moment';
import * as moment_ from 'moment-timezone';
const moment = moment_;


@Directive({
  selector: '[validateDateHour][ngModel],[validateDateHour][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateHourValidator), multi: true }
  ]
})
export class DateHourValidator implements Validator {

  public constructor() { }

  public static validate(control: AbstractControl): ValidationErrors | null {
    return DateHourValidator.validateControl(control, true);
  }

  public static validateWithOutNullDateHour(control: AbstractControl): ValidationErrors | null {
    return DateHourValidator.validateControl(control, false);
  }

  public static validateControl(control: AbstractControl, withnulldatehour: boolean): ValidationErrors | null {

    if (!control)
      return DateHourValidator.getInvalidResult();
    let value = control.value;

    if (!withnulldatehour && !value)
      return null;

    if (!value)
      return DateHourValidator.getInvalidResult();

    let hdatehour: HDateHour = value;

    if (value instanceof Date)
      hdatehour = HDateHour.build(value, value.toUTCString(), value.toUTCString());
    else if (moment.isMoment(value)){
      let temp = moment(value);
      hdatehour = HDateHour.buildFromMoment(temp);
    }else {
      const IsoDateTo = moment(value).format('YYYY-MM-DD[T]HH:mm:ss');
      let temp = moment(IsoDateTo);
      hdatehour = HDateHour.buildFromMoment(temp);
    }

    if (withnulldatehour && HDateHour.isNullOrNullValue(hdatehour))
      return DateHourValidator.getInvalidResult();

    if (!HDateHour.isValidDateHour(hdatehour))
      return DateHourValidator.getInvalidResult();

      return null;

  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    let hdatehour: HDateHour = value;

    if (value instanceof Date)
      hdatehour = HDateHour.build(value, value.toUTCString(), value.toUTCString());
    else if (moment.isMoment(value)){
      let temp = moment(value);
      hdatehour = HDateHour.buildFromMoment(temp);
    }else {
      let temp = moment(value.toString());
      hdatehour = HDateHour.buildFromMoment(temp);
    }

    if (HDateHour.isValidDateHour(hdatehour))
      return true;

    return false;
  }

  public validate(c: AbstractControl): ValidationErrors | null {
    return DateHourValidator.validate(c);
  }

  private static getInvalidResult(): ValidationErrors {
    return {
      validateDateHour: false
    };
  }

}