import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { RoutePartnerExworkDto } from "@shared/src/dtos/route/partner/RoutePartnerExworkDto";
import { AlertService, HomeController, RouteController } from "@shared/src/public-api";

@Component({
  selector: 'shared-route-external-drivers',
  templateUrl: './shared.route.externaldrivers.component.html',
  styleUrls: ['./shared.route.externaldrivers.component.scss']
})
export class SharedRouteExternalDriversComponent implements OnInit {



  constructor(@Inject("HomeController") protected homeController: HomeController,
    @Inject("AlertService") protected alertService: AlertService,
    protected openRouteController: OpenRouteController,
    protected routeController: RouteController) { }

  ngOnInit(): void {
    
  }

  @Input() public readonly: boolean;
  @Input() public value: RoutePartnerExworkDto;
  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onRefresh(event){
    this.valueChange.next(true);
  }

}