import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PartnerEntitySelectorDto extends EntitySelectorDto {

    public static new(activeFilterKeys: Array<String>): PartnerEntitySelectorDto{
        let result: PartnerEntitySelectorDto = new PartnerEntitySelectorDto();
		result.loadData(EntitysSelectors.Partner, "", activeFilterKeys, 0, false);
        return result;
    }
    
	public getUrlGoToMaintenance(): string {
		//No hi ha manteniment estricte, poden ser customer, driver i/o provider
		return "";
	}
	
	public getUrlBack(): string {
		return "/maintenance/partner";
	}
	
}