import { TimeFilter } from "@shared/src/filters/TimeFilter";
import { PercentageFilter } from "@shared/src/filters/PercentageFilter";
import { PriceFilter, QuantityFilter } from "@shared/src/filters/PriceFilter";
import { HColor } from "@shared/src/public-api";

/**
 * Funciona una mica raro, Aquesta clase s'utilitza dins de table-component i NO es un trasllat de la TableDTO del backend. Això carrega la TableDTO en una TableDto que si que es representable i ho fa partint d'un any que és el TableDTO que rebem del back
 */
export class TableDto {

    public rows: number;
    public typeName: string;
    public title: string;
    public labels: Map<number, string>;
    public graphIds: Map<number, string>;
    public series: Map<string, TableSerieDTO>;
    public showPercentages: boolean;
    public percentagesTitle: string;
    public percentages: Map<number, number>;

    public indexsOfTableList: Map<number, number>;

    public static buildAndFilter(value: TableDto, graphId: string): TableDto {
        let r = new TableDto();
        r.rows = value.rows;
        r.title = value.title;
        r.typeName = value.typeName;
        r.showPercentages = value.showPercentages;
        r.percentagesTitle = value.percentagesTitle;

        r.labels = new Map<number, string>();
        let i = 1;
        let i2 = 1;
        for (let keyLabel in value.labels) {
            if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                r.labels[i] = value.labels[i2];
                i++;
            }
            i2++;
        }
        r.series = new Map<string, TableSerieDTO>();
        for (let key in value.series)
            r.series[key] = TableSerieDTO.buildAndFilter(value.series[key], value.graphIds, graphId);

        r.percentages = new Map<number, number>();
        i = 1;
        i2 = 1;
        for (let key in value.percentages) {
            if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                r.percentages[i] = value.percentages[i2];
                i++;
            }
            i2++;
        }

        i = 1;
        i2 = 1;
        r.graphIds = new Map<number, string>();
        for (let key in value.graphIds) {
            if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                r.graphIds[i] = value.graphIds[i2];
                i++;
            }
            i2++;
        }

        r.indexsOfTableList = new Map<number, number>();
        i = 1;
        i2 = 1;
        for (let key in value.indexsOfTableList) {
            if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                r.indexsOfTableList[i] = value.indexsOfTableList[i2];
                i++;
            }
            i2++;
        }


        return r;
    }
}
export class TableSerieDTO {


    public serieName: string;
    public serieType: SerieTypes;
    public values: Map<number, any>;

    static buildAndFilter(o: TableSerieDTO, graphIds: Map<number, string>, graphId: string): TableSerieDTO {
        let r = new TableSerieDTO();
        r.serieName = o.serieName;
        r.serieType = o.serieType;
        r.values = new Map<number, any>();

        let i = 1;
        let i2 = 1;
        for (let key in o.values) {
            if (graphId == null || graphId === "" || graphIds[i2] == null || graphIds[i2] === "" || graphIds[i2] == graphId) {
                r.values[i] = o.values[i2];
                i++;
            }
            i2++;
        }
        return r;
    }
}

export enum SerieTypes {
    Number = "Number", Time = "Time"
}

export class TableFrontDto {

    public title: string = "";
    public showTitle: boolean = false;
    public titleColSpan: number = 1;
    public percentageColumn: number = -1;

    public matrixValues: string[][];
    public matrixClass: string[][];

    public static build(value: TableDto): TableFrontDto {
        return TableFrontDto.buildAndFilter(value, "");
    }

    public static buildAndFilter(value: TableDto, graphId: string): TableFrontDto {

        let result: TableFrontDto = new TableFrontDto();

        if (!value) {
            console.log("TableFrontDto.build(null)");
            return result;
        }

        let timeFilter: TimeFilter = new TimeFilter();
        let priceFilter: PriceFilter = new PriceFilter();
        let quantityFilter: QuantityFilter = new QuantityFilter();
        let percentageFilter: PercentageFilter = new PercentageFilter();

        result.title = value.title;
        if (result.title && result.title != "")
            result.showTitle = true;

        let seriesNum = 0;
        for (let key in value.series) {
            result.titleColSpan += 1;
            seriesNum++;
        }

        if (value.showPercentages)
            result.titleColSpan += 1;

        let i = 0;
        result.matrixValues = [[]];
        result.matrixValues[0][0] = "";
        result.matrixClass = [[]];
        result.matrixClass[0][0] = "";

        i = 1;
        for (let keyLabel in value.labels) {
            if (graphId == null || graphId === "" || value.graphIds[keyLabel] == null || value.graphIds[keyLabel] === "" || value.graphIds[keyLabel] == graphId) {
                if (!result.matrixValues[i]) {
                    result.matrixValues[i] = [];
                    result.matrixClass[i] = [];
                }
                result.matrixValues[i][0] = value.labels[keyLabel];
                result.matrixClass[i][0] = "keyLabel table-component-th";
                i++;
            }
        }

        let j = 1;
        for (let serieKey in value.series) {
            result.matrixValues[0][j] = value.series[serieKey].serieName;
            result.matrixClass[0][j] = "serieName table-component-th";
            let values = value.series[serieKey].values;
            i = 1;
            let i2 = 1;
            for (let valueKey in values) {
                if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                    let temp = values[valueKey];
                    if (!result.matrixValues[i])
                        console.log("matrixValues is null");
                    else {
                        if (value.typeName == "HTime")
                            result.matrixValues[i][j] = timeFilter.transform(temp);
                        else if (value.typeName == "Integer")
                            result.matrixValues[i][j] = quantityFilter.transform(temp);
                        else
                            result.matrixValues[i][j] = priceFilter.transform(temp);
                        result.matrixClass[i][j] = value.typeName + " table-component-td";
                    }
                    i++;
                }
                i2++;
            }
            j++
        }

        i = 1;
        let i2 = 1;
        if (value.showPercentages) {
            result.percentageColumn = seriesNum + 1;
            result.matrixValues[0][result.percentageColumn] = value.percentagesTitle;
            result.matrixClass[0][result.percentageColumn] = "percentageColumn percentagesTitle table-component-th table-component-percentage";
            for (let key in value.percentages) {
                if (graphId == null || graphId === "" || value.graphIds[i2] == null || value.graphIds[i2] === "" || value.graphIds[i2] == graphId) {
                    result.matrixValues[i][result.percentageColumn] = percentageFilter.transform(value.percentages[key]);
                    result.matrixClass[i][result.percentageColumn] = "percentageColumn table-component-percentage";
                    i++;
                }
                i2++;
            }
        }
        return result;
    }


    public static transpose(value: TableFrontDto): TableFrontDto {
        let result = new TableFrontDto();

        result.percentageColumn = value.percentageColumn;
        result.showTitle = value.showTitle;
        result.title = value.title;
        result.titleColSpan = value.titleColSpan;

        result.matrixClass = TableFrontDto._transpose(value.matrixClass);
        result.matrixValues = TableFrontDto._transpose(value.matrixValues);
        return result;
    }

    private static _transpose(matrix) {
        return matrix[0].map((col, c) => matrix.map((row, r) => matrix[r][c]));
    }
}