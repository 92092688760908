import {Injectable} from '@angular/core';
import { HDateHour } from '@shared/src/public-api';

@Injectable()
export class PhaseChangeDatesDto {
	public arrivedAt: HDateHour;
	public completedAt: HDateHour;

    public static build(arrivedAt: HDateHour, completedAt: HDateHour): PhaseChangeDatesDto{
        let data: PhaseChangeDatesDto = new PhaseChangeDatesDto();
        data.arrivedAt = arrivedAt;
        data.completedAt = completedAt;
        return data;
    }
}
