import { QuestionTypes } from "@shared/src/enums/QuestionTypes";
import { ListMaintenanceDto } from "../../_base/ListMaintenanceDto";

export class QuestionListMaintenanceDto extends ListMaintenanceDto {

   public questionId: number;
   public title: string;
   public question: string;
   public questionType: QuestionTypes;

   public static isIntegrated(questionType: QuestionTypes){

        if(questionType == QuestionTypes.Image)
            return true;

        if(questionType == QuestionTypes.CMR)
            return true;

        if(questionType == QuestionTypes.Delivery)
            return true;

        if(questionType == QuestionTypes.SignatureForm)
            return true;
        
        if(questionType == QuestionTypes.SignatureCMR)
            return true;
        
        if(questionType == QuestionTypes.SignatureDelivery)
            return true;
        return false;
  }

  public static isIntegratedSignature(questionType: QuestionTypes){

    if(questionType == QuestionTypes.SignatureForm)
        return true;
    
    if(questionType == QuestionTypes.SignatureCMR)
        return true;
    
    if(questionType == QuestionTypes.SignatureDelivery)
        return true;
    return false;
}
}
