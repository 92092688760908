import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HTriState } from '../public-api';

@Pipe({
  name: 'tristate'
})
@Injectable()
export class TriStateFilter implements PipeTransform {
  transform(value): string {
    if (value != null) {
      return value.state.toString();
    } else {
      return "-";
    }
  }
}
