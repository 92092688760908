import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BaseController } from '../../base.controller';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { SendMailDto } from '@shared/src/dtos/test/SendMailDto';

@Injectable({
    providedIn: 'root'
})
export class InternalMailController extends BaseController {

    private baseUrl = '/mail';

    constructor(
        protected http: HttpClient,
        protected cookieService: CookieService,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    testsendemailregister(sendmail: SendMailDto) {
        this.put(this.baseUrl + '/testsendemailregister', sendmail, "testsendemailregister")
            .subscribe(data => {
            });
        }
    testregisterhello(eMailAddress: string) {
        this.put(this.baseUrl + '/testregisterhello', eMailAddress, "testregisterhello")
            .subscribe(data => {
            });
    }
    testregistercontrol(eMailAddress: string) {
        this.put(this.baseUrl + '/testregistercontrol', eMailAddress, "testregistercontrol")
            .subscribe(data => {
            });
    }
}
