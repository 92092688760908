import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HomeController } from '../../../../controllers/home/home.controller';


@Component({
  selector: 'boolean-component',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: BooleanComponent, multi: true }
  ]
})

export class BooleanComponent extends ValueAccessorValidatedBase<boolean, boolean> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormBoolean = this.fb.group({
      dtpvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: boolean): boolean {
    return true;
  }

  public rFormBoolean: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() public placeholder: string;
  @Input() public helpresource: string;
  @Input() public color: string = 'primary';
  @Input() public readonly: boolean;
  @Input() public showHelpPoint: boolean;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter();

  public identifier = 'boolean-' + identifier++;

  externalValue(value: boolean): boolean {
    return value;
  }
  internalValue(value: boolean): boolean {
    return value;
  }

  onChange(e) {
    if (e != undefined && e != null){
      if(this.useIonic){
        if (e.detail != undefined && e.detail != null) {
          if(this.value == e.detail.checked)
            return;
          this.value = e.detail.checked;
          this.valueChanged.next(this.externalValue(this.value));
        }else if(e.checked != undefined && e.checked != null) {
            if(this.value == e.checked)
              return;
            this.value = e.checked;
            this.valueChanged.next(this.externalValue(this.value));
        }
      }else{
        this.valueChanged.next(this.externalValue(this.value));
      }
    }
  }

}

let identifier = 0;