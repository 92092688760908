<ng-container *ngIf="!phaseUUID">
    <div class="block-esquerra">
        <div class="header"></div>
        <div class="nouuid">
            No valid uuid
        </div>
    </div>
</ng-container>
<div class="container">
    <div class="block-esquerra">
        <shared-phase-cmr-qr-component [phaseUUID]="phaseUUID"></shared-phase-cmr-qr-component>
    </div>
</div>
<div class="signaturahardman">
    <img class="logoimg" src="/assets/images/HardmanLogoBlanc.png" alt="Hardman logo">
    <a href="https:hardman.app" target="_blank" class="powered">{{'GLOBAL.DESENVOLUPATPER' | translate}}</a>
</div>