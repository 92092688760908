<div class="datehour">
    <div *ngIf="!showTimeZoneObserver" class="datehour-zone" [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
        <div class="timezone-zone">
            <span class="title-timezone">{{ placeholder }}</span>
            <span class="timezone">{{ dateHour | datehour }}</span>
        </div>
    </div>
    <div *ngIf="showTimeZoneObserver" class="datehour-zone" [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
        <div class="timezone-observer-zone">
            <span class="title-timezone-observer">{{ placeholder }}</span>
            <span class="timezone-observer">{{ getDateHourObserver() | datehour }}</span>
        </div>
    </div>
    <div *ngIf="showLocaleObserver && canShowTimeZoneObserver" class="showtimezoneobserver-icon"
        (click)="onShowTimeZoneObserver()">
        <i *ngIf="showTimeZoneObserver" class="fa fa-minus-square" style="font-size: 18px" aria-hidden="true"></i>
        <i *ngIf="!showTimeZoneObserver" class="fa fa-plus-square" style="font-size: 18px" aria-hidden="true"></i>
    </div>
</div>