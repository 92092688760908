
import { Component, Input } from '@angular/core';
import { TableDto, TableFrontDto } from './TableDto';

@Component({
  selector: 'shared-table-component',
  templateUrl: './shared.table.component.html',
  styleUrls: ['./canvas.scss']
})
export class SharedTableComponent {

  public _value: TableDto;
  @Input() set value(value: TableDto) {
    if (value === this._value)
      return;
    this._value = value;
    this._internalvalue = this.transposeIfNecessary(TableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get value(): TableDto {
    return this._value;
  }

  public _graphGroupId: string = "";
  @Input() set graphGroupId(value: string) {
    if (value === this._graphGroupId)
      return;
    this._graphGroupId = value;
    this._internalvalue = this.transposeIfNecessary(TableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get graphGroupId(): string {
    return this._graphGroupId;
  }

  public _transpose: boolean = false;
  @Input() set transpose(value: boolean) {
    if (value === this._transpose)
      return;
    this._transpose = value;
    this._internalvalue = this.transposeIfNecessary(TableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get transpose(): boolean {
    return this._transpose;
  }

  private transposeIfNecessary(value: TableFrontDto): TableFrontDto {
    if (this._transpose)
      return TableFrontDto.transpose(value);
    return value;
  }


  @Input() showTitle: boolean = false;

  public _internalvalue: TableFrontDto;

  public titlecolspan: number = 1;

  public getClass(i: number, j: number): string {
    return this._internalvalue.matrixClass[i][j];
  }

  public pretty() {
    return JSON.stringify(this._value);
  }


}
