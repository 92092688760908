import { Component, EventEmitter, Inject, OnInit, Output, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { TruckingShipmentExpandDto } from '@shared/src/dtos/trucking/TruckingShipmentExpandDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ChatController } from '@shared/src/public-api';


@Component({
	selector: 'shared-trucking-shipment-detail',
	templateUrl: './shared.trucking.shipment.detail.component.html',
	styleUrls: ['./shared.trucking.shipment.detail.component.scss']
})
export class SharedTruckingShipmentDetailComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	public searchTextGroup: string = "";
	public filterMetadata: any = {};


	@Output() clickBack = new EventEmitter<boolean>();
	@Output() updateRoute = new EventEmitter<TruckingRouteDto>();

	constructor(
		public truckingController: TruckingController,
		public chatController: ChatController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {

		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}


	ngOnInit(): void {
		this.options = this.buildOptions();
	}

	public _shipmentExpand: TruckingShipmentExpandDto;
	@Input()
	public get shipmentExpand(): TruckingShipmentExpandDto {
		return this._shipmentExpand;
	}
	public set shipmentExpand(value: TruckingShipmentExpandDto) {
		if (this._shipmentExpand === value)
			return;
		this._shipmentExpand = value;
	}

	public options: OptionsStripDto;
	public actualOptionId: string = "GENERAL";
	public buildOptions(): OptionsStripDto {
		let result = new OptionsStripDto();
		result.add(OptionDto.build("GENERAL", "SHARED.TRUCKING.SHIPMENT.OPCIONS.GENERAL.CAPTION", "SHARED.TRUCKING.SHIPMENT.OPCIONS.GENERAL.HELP", "", 1, -1, 1, true, false));
		result.add(OptionDto.build("CHAT", "SHARED.TRUCKING.SHIPMENT.OPCIONS.CHAT.CAPTION", "SHARED.TRUCKING.SHIPMENT.OPCIONS.CHAT.HELP", "", 2, -1, 1, false, false));
		result.add(OptionDto.build("PACKAGE", "SHARED.TRUCKING.SHIPMENT.OPCIONS.PACKAGE.CAPTION", "SHARED.TRUCKING.SHIPMENT.OPCIONS.PACKAGE.HELP", "", 3, -1, 1, false, false));
		result.add(OptionDto.build("DELIVERY", "SHARED.TRUCKING.SHIPMENT.OPCIONS.DELIVERY.CAPTION", "SHARED.TRUCKING.SHIPMENT.OPCIONS.DELIVERY.HELP", "", 4, -1, 1, false, false));
		result.add(OptionDto.build("DOCUMENT", "SHARED.TRUCKING.SHIPMENT.OPCIONS.DOCUMENT.CAPTION", "SHARED.TRUCKING.SHIPMENT.OPCIONS.DOCUMENT.HELP", "", 5, -1, 1, false, false));
		this.actualOptionId = result.getActualSelected().optionId;
		return result;
	}

	public forcedOwnerParticipant: ParticipantDto;
	onClickOption(option: OptionDto) {
		this.actualOptionId = option.optionId;
		if (option && option.optionId === "CHAT") {
			this.forcedOwnerParticipant = ParticipantDto.buildByParams(this._shipmentExpand.shipmentId, ChatParticipantTypes.Shipment);
		} 
	}
	clickParticipant(participant: ParticipantDto) {
	  this.chatController.onClickParticipant(participant);
	}


}
