import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { QRCodeController } from '@shared/src/controllers/qrcode/qrcode.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { HString } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-part-cmr-qr-component',
  templateUrl: './shared.route.part.cmr.qr.component.html',
  styleUrls: ['./shared.route.part.cmr.qr.component.scss'],
})
export class SharedRoutePartCMRQRComponent {

  constructor(private qRCodeController: QRCodeController,
    @Inject('HomeController') protected homeController: HomeController) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };

  public useIonic: boolean = false;
  
  @Input()
  public showcount: boolean = false;
  
  @Input()
  public count: number;

  private _routePartCMR: RoutePartCMRDto;
  @Input()
  public set routePartCMR(value: RoutePartCMRDto) {
      if (this._routePartCMR === value)
          return;
      this._routePartCMR = value;
      this.loadQR();
  }
  public get routePartCMR(): RoutePartCMRDto {
      return this._routePartCMR;
  }

  ngOnInit() {

  }

  onClickQR(){
    if (this.routePartCMR != null && !HString.isNullOrNullString(this.routePartCMR.uuidQR))
        this.homeController.routeToExworkCMRUUIDQR(this.routePartCMR.uuidQR);   
  }

  public filedto: FileUpDownLoadDto;
  public formatDocumentQRCode: string;
  loadQR() {
    if (this.routePartCMR != null) {
      this.qRCodeController.getQRCodeCMRByRoutePartCMRId(this.routePartCMR.routePartCMRId).subscribe(data => { 
        this.filedto = data;
        if(data != null && data.fileBytes != null)
          this.formatDocumentQRCode = 'data:image/' + this.filedto.fileType + ';base64,' + this.filedto.fileBytes
      });
    }
  }
}
