import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerFinalCustomerMaintenanceController extends
    PartnerMaintenanceController {

    public getResourceRoot(): string {
        return "PARTNER.FINALCUSTOMER";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/finalcustomer" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.FINALCUSTOMER.LINKPERMISSION.FILTER"];
    }

}
