import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';
import { AddressAmbitItemMaintenanceDto } from './AddressAmbitItemMaintenanceDto';
import { AddressAmbitListMaintenanceDto } from './AddressAmbitListMaintenanceDto';

@Injectable()
export class AddressAmbitSubmaintenanceController extends BaseSubMaintenanceController<AddressAmbitListMaintenanceDto, AddressAmbitItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "address/ambit");
    }

    public getResourceRoot(): string {
        return "ADDRESS.AMBIT";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/activity/address/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}
