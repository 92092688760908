
export class LoadConsolePhaseFilterDto {

    public phaseId: number;
    public loadConsoleId: number;

	public static build(loadConsoleId: number, phaseId: number): LoadConsolePhaseFilterDto{
		let result: LoadConsolePhaseFilterDto = new LoadConsolePhaseFilterDto();
		result.phaseId = phaseId;
		result.loadConsoleId = loadConsoleId;
		return result;
	}
}
