import { DataTypes } from "@shared/src/enums/DataTypes";
import { CustomTableColumnData } from "./CustomTableColumnData";
import { CustomTableFieldData } from "./CustomTableFieldData";
import { CustomTableRowData } from "./CustomTableRowData";
import { HString } from '@shared/src/datatypes/HString';

export class CustomTableData {
    public header: CustomTableColumnData[];
    public rows: CustomTableRowData[];

    public static newColumn(tablaData: CustomTableData) {
        if (tablaData == null)
            tablaData = new CustomTableData();
        if (tablaData.header == null)
            tablaData.header = new Array();

        let columnData = new CustomTableColumnData();
        columnData.column = tablaData.header.length;
        columnData.dataType = DataTypes.String;

        //ens quedem amb el numero més gran
        tablaData.header.forEach(c => {
            if (c.column > columnData.column)
                columnData.column = c.column;
        });
        columnData.column = columnData.column + 1;

        tablaData.header.push(columnData);

        if (tablaData.rows == null)
            tablaData.rows = new Array();

        tablaData.rows.forEach(rowData => {
            if (rowData.fields == null)
                rowData.fields = new Array();

            let field = new CustomTableFieldData();
            field.column = columnData.column;
            field.row = rowData.row;
            field.dataType = DataTypes.String;
            rowData.fields.push(field);
        });
    }

    public static newRow(tablaData: CustomTableData) {
        if (tablaData == null)
            tablaData = new CustomTableData();

        if (tablaData.rows == null)
            tablaData.rows = new Array();

        let rowData = new CustomTableRowData();
        if (rowData.fields == null)
            rowData.fields = new Array();

        rowData.row = tablaData.rows.length;
        //ens quedem amb el numero més gran
        tablaData.rows.forEach(r => {
            if (r.row > rowData.row)
                rowData.row = r.row;
        });
        rowData.row = rowData.row + 1;

        if (tablaData.header == null)
            tablaData.header = new Array();
        tablaData.header.forEach(columnData => {
            let field = new CustomTableFieldData();
            field.column = columnData.column;
            field.row = rowData.row;
            field.dataType = columnData.dataType;
            rowData.fields.push(field);

        });

        tablaData.rows.push(rowData);
    }

    public static removeRow(tablaData: CustomTableData, row: number) {

        if (row == null)
            return;

        if (tablaData == null)
            tablaData = new CustomTableData();

        if (tablaData.rows == null)
            tablaData.rows = new Array();

        //Com a mínim hi ha d'haber una fila
        // if (tablaData.rows.length <= 1)
        //     return;

        let newRows = new Array();
        tablaData.rows.forEach(rowData => {
            if (rowData.row != row) {
                newRows.push(rowData);
            }
        });

        tablaData.rows = newRows;
    }

    public static truncate(tablaData: CustomTableData) {
        if (tablaData == null)
            tablaData = new CustomTableData();

        if (tablaData.rows == null)
            tablaData.rows = new Array();

        tablaData.rows = new Array();
    }

    public static removeColumn(tablaData: CustomTableData, column: number) {

        if (column == null)
            return;

        if (tablaData == null)
            tablaData = new CustomTableData();
        if (tablaData.header == null)
            tablaData.header = new Array();

        //Com a mínim hi ha d'haber una columna
        if (tablaData.header.length <= 1)
            return;

        //Treiem la columna de la capçalera
        let newColumns = new Array();
        tablaData.header.forEach(columnData => {

            if (columnData.column != column) {
                newColumns.push(columnData);
            }

        });
        tablaData.header = newColumns;


        if (tablaData.rows == null)
            tablaData.rows = new Array();

        //Per cada row, treiem la columna
        tablaData.rows.forEach(rowData => {
            if (rowData.fields == null)
                rowData.fields = new Array();

            let newFields = new Array();
            rowData.fields.forEach(fieldData => {

                if (fieldData.column != column) {
                    newFields.push(fieldData);
                }

            });
            rowData.fields = newFields;
        });
    }

    /**
     * Traspassa els tipus de cada columna a cada field de cada row
     */
    public static refreshDataTypes(tablaData: CustomTableData) {

        if (tablaData == null)
            tablaData = new CustomTableData();
        if (tablaData.header == null)
            tablaData.header = new Array();
        if (tablaData.rows == null)
            tablaData.rows = new Array();


        tablaData.rows.forEach(rowData => {
            if (rowData.fields == null)
                rowData.fields = new Array();

            rowData.fields.forEach(fieldData => {

                tablaData.header.forEach(columnData => {
                    if (columnData.dataType == null)
                        columnData.dataType = DataTypes.String;

                    if (fieldData.column == columnData.column && fieldData.dataType != columnData.dataType) {
                        fieldData.dataType = columnData.dataType;
                    }
                });
            });
        });

    }

    public static equals(a: CustomTableData, b: CustomTableData): boolean {

        if (a == null && b == null)
            return true;

        if (a == null || b == null)
            return false;

        const ajson = JSON.stringify(a);
        const bjson = JSON.stringify(b);

        return HString.equals(ajson, bjson);

    }

    /***
     * Arregla els camps de l'ordre dels rows
     */
    static fix(table: CustomTableData): CustomTableData {

        if (table != null && table.rows != null)
            table.rows.forEach(r => {
                CustomTableRowData.fix(r, table.header);
            });
        return table;
    }

}
