<ng-container *ngIf="route!=null">
    <shared-slot-viewer-component [slotInfo]="slotInfo" class="relative element"></shared-slot-viewer-component>

    <!-- MOLL -->
    <span *ngIf="route.docks != null && route.docks.length > 0" class="docksContainer">
        <div class="dockInfo element" *ngFor="let item of route.docks"
            [style.color]="getTextColor(item.backgroundColor)"
            [style.background-color]="getBackColor(item.backgroundColor)">{{ item.skin }}</div>
    </span>

    <!-- REQUADRES -->
    <!-- PARTNERS -->
    <div *ngIf="route.partnerParentTrademark" class="requadre parent element"
        [style.color]="getColor(route.partnerParentTrademark)"
        [style.background-color]="getBackgroundColor(route.partnerParentTrademark)">
        <span class="trademark">{{ route.partnerParentTrademark }}</span>
    </div>
    <div *ngIf="route.partnerShipperId && route.partnerShipperTrademark" class="requadre shipper element">
        <span class="trademark">{{ route.partnerShipperTrademark }}</span>
    </div>
    <div *ngIf="route.partnerCarrierId && route.partnerCarrierTrademark" class="requadre carrier element">
        <span class="trademark">{{ route.partnerCarrierTrademark }}</span>
    </div>
    <div *ngIf="route.partnerLogisticOperatorId && route.partnerLogisticOperatorTrademark"
        class="requadre logistic-operator element"><span class="trademark">{{
            route.partnerLogisticOperatorTrademark
            }}</span></div>
    <div *ngIf="route.partnerDriverId && route.partnerDriverTrademark" class="requadre driver element">
        <span class="trademark">{{ route.partnerDriverTrademark }}</span>
    </div>
    <div *ngIf="route.partnerSecondDriverId && route.partnerSecondDriverTrademark" class="requadre driver element">
        <span class="trademark">{{ route.partnerSecondDriverTrademark }}</span>
    </div>
    <div *ngIf="route.partnerInspectorId && route.partnerInspectorTrademark" class="requadre inspector element">
        <span class="trademark">{{ route.partnerInspectorTrademark }}</span>
    </div>
    <div *ngIf="route.partnerClientPreferentId && route.partnerClientPreferentTrademark"
        class="requadre client element">
        <span class="trademark">{{ route.partnerClientPreferentTrademark }}</span>
    </div>
    <!-- LABEL ENTREGUES -->
    <div *ngIf="!isActiveRoutes" class="requadre deliveries-label element"
        [ngClass]="{'withslot':showAssignedSlots,'show': !isActiveRoutes && route.isDivided && route.childs != null }">
        <span class="deliveries-label-text"> {{ 'ROUTEPAGE.ITEM.DIVIDED.HASCHILDS' | translate
            :{childsNumber:getChildsNumber(route)} }}</span>
    </div>
    <!-- EXWORK -->
    <div *ngIf="route.isExwork" class="requadre exwork element link">
        <a class="white" (click)="goToExwork(route)">
            {{
            'ROUTE.EXWORK.CAPTION'
            | translate }}
        </a>
    </div>
    <!-- MARGE DE TEMPS PER RESERVA DE SLOT -->
    <div *ngIf="route.hasSlotReserveMarginTime" class="requadre slot-reserve-margin-time">
        {{ route.slotReserveMarginTime | time }}
    </div>
    <!-- TIPOLOGIA -->
    <div *ngIf="route.typology" class="requadre typology element">
        {{ route.typology }}
    </div>

    <!-- ICONES -->
    <!-- BOOKING -->
    <div *ngIf="route.bookingSlotId" class="icona booking element"><i class="fa fa-ship"></i></div>
    <!-- CERTIFICAT -->
    <div *ngIf="route.requiredCertificate" class="icona required-certificate element"><i
            class="hardman-certificate"></i></div>
    <!-- INSPECTOR -->
    <div *ngIf="route.requiredInspector" class="icona required-inspector element"><i class="hardman-inspector"></i>
    </div>
    <!-- AGRUPADA -->
    <div *ngIf="route.isGrouped" class="icona grouped element"><i class="fa fa-compress"></i>
        <div class="group-size" *ngIf="routeShipmentDto">{{routeShipmentDto.length}}</div>
    </div>
    <!-- FILLA AGRUPADA -->
    <div *ngIf="route.isChildOfGrouped" class="icona grouped element"><i class="fa fa-expand"></i></div>
    <!-- BANNER HTML -->
    <div *ngIf="route.hasBannerHtml || route.bannerHtml || route.privateBannerHtml" class="icona bell element"><i
            class="fa fa-bell" aria-hidden="true"></i></div>

    <!-- Icones custom -->
    <div *ngIf="route.icons && route.icons.length > 0">
        <div *ngFor="let icona of route.icons" class="icona element">
            <i *ngIf="icona.family == 'fontawesome'" class="{{ icona.icon }}"></i>
            <mat-icon *ngIf="icona.family == 'material'">{{ icona.icon }}</mat-icon>
        </div>
    </div>

    <!-- TIPOLOGIES DE RUTA -->
    <img *ngIf="route.isInbound" class="icona element" src="/assets/images/routetype-inbound.svg" alt="Inbound">
    <img *ngIf="route.isNonacceptance" class="icona element" src="/assets/images/routetype-nonacceptance.svg"
        alt="Non acceptance">
    <img *ngIf="route.isReturn" class="icona element" src="/assets/images/routetype-return.svg" alt="Return">
    <img *ngIf="route.isReverseLogistics" class="icona element" src="/assets/images/logisticaInversa.svg"
        alt="Reverse logistics">
    <img *ngIf="route.isOutbound" class="icona element" src="/assets/images/routetype-outbound.svg" alt="Outbound">
    <img *ngIf="route.isDelegate" class="icona element" src="/assets/images/routetype-delegate.svg" alt="Delegate">


    <!-- EN RETRÀS -->
    <i class="icona isdelayed fa fa-clock-o element" aria-hidden="true" *ngIf="route.isDelayed"></i>

    <!-- SÓC OBSERVADOR -->
    <i class="iamobserver fa fa-eye element" aria-hidden="true" *ngIf="route.iAmObserver"></i>

    <!-- NÚMERO DE PALETS -->
    <div *ngIf="route.showPalletCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PALLETCOUNT.TOOLTIP' | translate: {value: route.palletCount} }}">
        <div class="route-card-info-count pallets">{{ route.palletCount }}</div>
    </div> 

    <!-- QUANTITAT DE PRODUCTE -->
    <div *ngIf="route.showQuantityProductOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.QUANTITYPRODUCT.TOOLTIP' | translate: {value: route.quantityProduct} }}">
        <div class="route-card-info-count quantity">{{ route.quantityProduct }}</div>
    </div> 
    
    <!-- NÚMERO DE PAQUETS -->
    <div *ngIf="route.showPacketCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PACKETCOUNT.TOOLTIP' | translate: {value: route.packetCount} }}">
        <div class="route-card-info-count packets">{{ route.packetCount }}</div>
    </div> 

    <!-- NÚMERO DE PRODUCTES -->
    <div *ngIf="route.showProductCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PRODUCTCOUNT.TOOLTIP' | translate: {value: route.productCount} }}">
        <div class="route-card-info-count products">{{ route.productCount }}</div>
    </div> 

    <!-- NÚMERO DE SHIPMENTS -->
    <div *ngIf="route.showShipmentCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.SHIPMENTCOUNT.TOOLTIP' | translate: {value: route.shipmentCount} }}">
        <div class="route-card-info-count shipments">{{ route.shipmentCount }}</div>
    </div> 

</ng-container>

<ng-container *ngIf="phaseslot!=null">
    <shared-slot-viewer-component *ngIf="slotInfo" class="relative element" [slotInfo]="slotInfo"
        [phaseId]="loadConsoleSlotPhase ? loadConsoleSlotPhase.phaseId : phaseslot.phaseId"
        [lostSlotId]="loadConsoleSlotPhase && loadConsoleSlotPhase.showLostSlotWhenReachedLate ? loadConsoleSlotPhase.lostSlotId : null"></shared-slot-viewer-component>

    <div class="dockInfo element" *ngIf="phaseslot.dockSkin != null && phaseslot.dockSkin.length > 0"
        [style.color]="getTextColor(phaseslot.dockBackgroundColor)"
        [style.background-color]="getBackColor(phaseslot.dockBackgroundColor)">{{ phaseslot.dockSkin }}</div>

    <div *ngIf="phaseslot.partnerParentTrademark" class="requadre parent element"
        [style.color]="getColor(phaseslot.partnerParentTrademark)"
        [style.background-color]="getBackgroundColor(phaseslot.partnerParentTrademark)">
        <span class="trademark ellipsis">{{ phaseslot.partnerParentTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerShipperId && phaseslot.partnerShipperTrademark" class="requadre shipper element">
        <span class="trademark ellipsis">{{ phaseslot.partnerShipperTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerCarrierId && phaseslot.partnerCarrierTrademark" class="requadre carrier element">
        <span class="trademark ellipsis">{{ phaseslot.partnerCarrierTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerLogisticOperatorId && phaseslot.partnerLogisticOperatorTrademark"
        class="requadre logistic-operator element"><span class="trademark ellipsis">{{
            phaseslot.partnerLogisticOperatorTrademark
            }}</span></div>
    <div *ngIf="phaseslot.partnerDriverId && phaseslot.partnerDriverTrademark" class="requadre driver element">
        <span class="trademark ellipsis">{{ phaseslot.partnerDriverTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerSecondDriverId && phaseslot.partnerSecondDriverTrademark"
        class="requadre driver element">
        <span class="trademark ellipsis">{{ phaseslot.partnerSecondDriverTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerInspectorId && phaseslot.partnerInspectorTrademark" class="requadre inspector element">
        <span class="trademark ellipsis">{{ phaseslot.partnerInspectorTrademark }}</span>
    </div>
    <div *ngIf="phaseslot.partnerClientPreferentId && phaseslot.partnerClientPreferentTrademark"
        class="requadre client element">
        <span class="trademark ellipsis">{{ phaseslot.partnerClientPreferentTrademark }}</span>
    </div>

    <div *ngIf="phaseslot.typology" class="requadre typology element">
        {{ phaseslot.typology }}
    </div>

    <!-- AGRUPADA -->
    <div *ngIf="phaseslot.isGrouped" class="icona grouped element"><i class="fa fa-compress"></i>
        <div class="group-size" *ngIf="routeShipmentDto">{{routeShipmentDto.length}}</div>
    </div>
    <!-- FILLA AGRUPADA -->
    <div *ngIf="phaseslot.isChildOfGrouped" class="icona grouped element"><i class="fa fa-expand"></i></div>

    <!-- TIPUS DE RUTA -->
    <img class="icona element" *ngIf="phaseslot.isInbound" src="/assets/images/routetype-inbound.svg" alt="Inbound">
    <img class="icona element" *ngIf="phaseslot.isReturn" src="/assets/images/routetype-return.svg" alt="Return">
    <img class="icona element" *ngIf="phaseslot.isReverseLogistics" src="/assets/images/logisticaInversa.svg"
        alt="Reverse logistics">
    <img class="icona element" *ngIf="phaseslot.isNonacceptance" src="/assets/images/routetype-nonacceptance.svg"
        alt="Nonacceptance">
    <img class="icona element" *ngIf="phaseslot.isOutbound" src="/assets/images/routetype-outbound.svg" alt="Outbound">
    <img class="icona element" *ngIf="phaseslot.isDelegate" src="/assets/images/routetype-delegate.svg" alt="Delegate">
    <!-- BANNER HTML -->
    <div *ngIf="phaseslot.hasBannerHtml" class="icona bell"><i class="fa fa-bell" aria-hidden="true"></i></div>
</ng-container>