import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceSettingsItemDTO } from '@shared/src/controllers/maintenance/_base/MaintenanceSettingsItemDTO';
import { PartnerItemMaintenanceDto } from '@shared/src/controllers/maintenance/partner/PartnerItemMaintenanceDto';
import { AddressManualAddressDto } from '@shared/src/controllers/maps/AddressManualAddressDto';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { TenantInfoController } from '@shared/src/controllers/tenant/tenantinfo.controller';
import { Route_eCMRIncotermDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRIncotermDto';
import { Route_eCMRPartnerDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPartnerDto';
import { TenantInfoDto } from '@shared/src/dtos/tenants/TenantInfoDto';
import { Incoterms, PartnerMaintenanceController, SharedSelectorAddressManualAddressEvent } from '@shared/src/public-api';

export enum DialogPartnerType {
  "carrier" = "carrier",
  "payer" = "payer",
  "applicant" = "applicant",
  "origin" = "origin",
  "destination" = "destination",
  "driver" = "driver",
};

@Component({
  selector: 'updatepartnerdialog-dialog',
  templateUrl: './updatepartnerdialog.dialog.component.html',
  styleUrls: ['../shared.updatepartnerdialog.component.scss']
})
export class UpdatePartnerDialogDialogComponent {

  @Output() public onResolve: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<UpdatePartnerDialogDialogComponent>,
    private mdDialog: MatDialog,
    private ecmrController: RouteCMRController,
    private partnerController: PartnerMaintenanceController,
    private tenantInfoController: TenantInfoController) {

    switch (this.data.partnerType as DialogPartnerType) {
      case "origin":
        break;
      case "destination":
        break;
      case "carrier":
        this.filter.push("PARTNER.CARRIER.LINKPERMISSION.FILTER");
        this.filter.push("PARTNER.FREIGHTFORWARDER.LINKPERMISSION.FILTER");
        break;
      case "driver":
        this.filter.push("PARTNER.DRIVER.LINKPERMISSION.FILTER");
        break;
      case "applicant":
        break;
      case "payer":
        break;
    }
  }


  onClose() {
    this.onResolve.next(false);
    this.mdDialog.closeAll();
  }

  public partnerId: number = null;
  public filter: Array<string> = ["PARTNER.FILTER.REGISTERED"];

  propagatePartner() {
    if (this.partnerId)
      this.partnerController.getById(this.partnerId).subscribe((e: MaintenanceSettingsItemDTO<PartnerItemMaintenanceDto>) => {
        this.tenantInfoController.getTenantInfoByTenantId(e.item.tenantIdTarget).subscribe((ti: TenantInfoDto) => {
          this.data.tenantId = ti.tenantId;
          this.data.name = ti.organizationName;
          this.data.nif = ti.nif;
          this.data.contactName = ti.contactName;
          this.data.contactPhone = ti.phone;
          this.data.formattedAddress = ti.address;
        })
      })
  }

  public address: AddressManualAddressDto = new AddressManualAddressDto();
  onChangedAddress(event: SharedSelectorAddressManualAddressEvent) {
    if (event.address && event.address.formattedAddress) {
      this.data.formattedAddress = event.address.formattedAddress;
    } else if (event.addressManualAddress && event.addressManualAddress.manualAddress && event.addressManualAddress.manualAddress.formattedAddress) {
      this.data.formattedAddress = event.addressManualAddress.manualAddress.formattedAddress;
    } else {
      this.data.formattedAddress = null;
    }
  }

  onConfirm() {
    let result = Route_eCMRPartnerDTO.build(this.data.tenantId, this.data.name, this.data.nif, this.data.formattedAddress, this.data.contactName, this.data.contactPhone);

    switch (this.data.partnerType as DialogPartnerType) {
      case "origin":
        this.ecmrController.updateOriginTenant(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      case "destination":
        this.ecmrController.updateDestinationTenant(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      case "carrier":
        this.ecmrController.updateCarrier(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      case "driver":
        this.ecmrController.updateDriver(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      case "applicant":
        this.ecmrController.updateApplicant(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      case "payer":
        this.ecmrController.updatePayer(this.data.routePartCMRId, result).subscribe(e => {
          if (e) {
            this.onResolve.next(true);
            this.mdDialog.closeAll();
          }
        });
        break;
      default:
        break;
    }

  }
}