import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TagListMaintenanceDto } from './TagListMaintenanceDto';
import { TagItemMaintenanceDto } from './TagItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';

@Injectable()
export class TagMaintenanceController extends BaseMaintenanceController<TagListMaintenanceDto, TagItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "tag");
    }

    public getResourceRoot(): string {
        return "TAG";
    }

    public buildUrl(uri: string): string {
        return "/system/dataandparameters/tag" + uri;
    }
}