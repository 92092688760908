import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleTableRowFrontDto } from '@shared/src/components/core/canvas/SimpleTableDto';
import { HColor } from '@shared/src/datatypes/HColor';
import { HLong } from '@shared/src/datatypes/HLong';
import { MenuOptionDto } from '@shared/src/dtos/home/MenuOptionDto';
import { MenuOptionsDto } from '@shared/src/dtos/home/MenuOptionsDto';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { OpcionsMenu } from '@shared/src/enums/OpcionsMenu';
import { TenantSettings } from '@shared/src/enums/TenantSettings';
import { SharedAsideFactory } from '@shared/src/public-api';
import { Observable, Subscription } from 'rxjs';
import { ChatController } from '../../controllers/chat/chat.controller';
import { SocketNotificationAction } from '../../controllers/socket/SocketNotificationAction';
import { SocketNotificationDto } from '../../controllers/socket/SocketNotificationDto';
import { SocketController } from '../../controllers/socket/socket.controller';
import { HDate } from '../../datatypes/HDate';
import { ChatDto } from '../../dtos/chat/ChatDto';
import { ChatsDto } from '../../dtos/chat/ChatsDto';
import { HomeDto } from '../../dtos/home/HomeDto';
import { environment } from '../../environments/environment';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { OperatingSystems } from '../../services/platform/OperatingSystems';
import { Platforms } from '../../services/platform/Platforms';
import { Solutions } from '../../services/platform/Solutions';
import { BaseController } from '../base.controller';
import { ListOfMaintenanceDto } from '../maintenance/ListOfMaintenanceDto';
import { MaintenanceSearchByTextDto } from '../maintenance/_base/MaintenanceSearchByTextDtos';
import { TagListMaintenanceDto } from '../maintenance/tag/TagListMaintenanceDto';
import { TagMaintenanceController } from '../maintenance/tag/TagMaintenanceController';
import { TenantSettingItemMaintenanceDto } from '../maintenance/tenant/TenantSettingItemMaintenanceDto';
import { TenantSettingMaintenanceController } from "../maintenance/tenant/TenantSettingMaintenanceController";
import { RouteController } from '../route/route.controller';
import { SecurityController } from '../security/security.controller';
import { TenantResourceItemMaintenanceDto } from '../maintenance/tenant/TenantResourceItemMaintenanceDto';

@Injectable({
    providedIn: 'root',
})
export class HomeController extends BaseController {

    public onDateChanged$: EventEmitter<HDate> = new EventEmitter();
    public onColumnDaysChanged$: EventEmitter<number> = new EventEmitter();
    public onNumMonthsChanged$: EventEmitter<number> = new EventEmitter();
    public menuChanged$: EventEmitter<MenuOptionsDto> = new EventEmitter();
    public geolocationRequest$: EventEmitter<boolean> = new EventEmitter();
    public homeChanged$: EventEmitter<HomeDto> = new EventEmitter();
    public chatsChanged$: EventEmitter<ChatsDto> = new EventEmitter();
    public onMakingPhoto$: EventEmitter<boolean> = new EventEmitter();
    public onConditionsUpdated$: EventEmitter<number> = new EventEmitter();
    public onStatusUpdated$: EventEmitter<boolean> = new EventEmitter();
    public onMainOptionClicked$: EventEmitter<boolean> = new EventEmitter();
    public ONDATE: HDate = HDate.today();
    public COLUMN_DAYS: number = 7;
    public LOADBOOKINGS: boolean = false;
    public LOADINTERVALS: boolean = false;
    public NUMMONTHSVISIBLE: number = 12;
    public TAMANY_WIDTH_IPAD: number = 690;
    public TENANDID: number = -1;
    public HOME: HomeDto = new HomeDto();
    public MENU: MenuOptionsDto;

    public static RESOURCES: Array<TenantResourceItemMaintenanceDto>;
    public static LOADING_TENANT_RESOURCES: boolean = false;
    public static LOADED_TENANT_RESOURCES: boolean = false;

    public CHATS: ChatsDto = new ChatsDto();
    public static readonly DEFAULT_BOUNCE_BUTTON_TIME: number = 500;

    private baseUrl = '/home';
    public static FIRSTMENU: boolean = false;
    public static DEFAULTHOME: string = "/";

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService,
        @Inject('SecurityController') protected securityController: SecurityController,
        protected chatController: ChatController,
        protected socketController: SocketController,
        protected tenantSettingController: TenantSettingMaintenanceController,
        protected tagMaintenanceController: TagMaintenanceController,
        @Inject('SharedAsideFactory')
        public sharedAsideFactory: SharedAsideFactory) {
        super(http, alertService, translateService, loadingService, router);

        this.subscribeNotifications();
    }

    public setOnDate(onDate: HDate) {
        if (this.ONDATE === onDate)
            return;
        if (HDate.equals(this.ONDATE, onDate))
            return;

        this.ONDATE = onDate;
        this.onDateChanged$.next(this.ONDATE);
    }

    public setColumnDays(columns: number) {
        if (this.COLUMN_DAYS === columns)
            return;
        if (this.COLUMN_DAYS == columns)
            return;

        this.COLUMN_DAYS = columns;
        this.onColumnDaysChanged$.next(this.COLUMN_DAYS);
    }

    public setNumMonthsVisible(nummonths: number) {
        if (this.NUMMONTHSVISIBLE === nummonths)
            return;

        this.NUMMONTHSVISIBLE = nummonths;
        this.onNumMonthsChanged$.next(this.NUMMONTHSVISIBLE);
    }

    public getOS(): OperatingSystems {
        throw ("You must define getOS");
    }
    public getSolution(): Solutions {
        throw ("You must define getSolution");
    }
    public getPlatform(): Platforms {
        throw ("You must define getPlatform");
    }

    /***
     * Indica al front quan ha d'usar Ionic. Usem ionic fins i tot al browser quan som mobile o tablet
     */
    public useIonic(): boolean {
        return this.getSolution() != Solutions.desktop;
    }

    /***
     * Indica si el home que tenim és correcte
     */
    public validHome(): boolean {
        return this.isValidHome;
    }

    private isValidHome = false;
    private logged = false;
    public home() {
        this.isValidHome = false;
        if (this.securityController.isLogged)
            this.securityController.isLogged().subscribe(islogged => {
                if (!islogged) {
                    this.HOME = new HomeDto();
                    this.CHATS = new ChatsDto();
                    this.ONDATE = HDate.today();
                    this.COLUMN_DAYS = 7;
                    this.TENANDID = -1;

                    this.homeChanged$.next(this.HOME);
                    this.menuChanged$.next(this.MENU);
                    this.onColumnDaysChanged$.next(this.COLUMN_DAYS);
                    this.chatsChanged$.next(this.CHATS);
                    return;
                }
                this.loadResources().subscribe(data => {
                    HomeController.RESOURCES = data;
                })
                this.internalHome().subscribe(data => {
                    this.loadGlobals();
                    this.HOME = data;
                    this.MENU = data != null ? data.menu : null;
                    if (HomeController.FIRSTMENU && this.MENU) {
                        HomeController.DEFAULTHOME = this.MENU.defaultHome;
                        this.router.navigateByUrl(HomeController.DEFAULTHOME);
                        HomeController.FIRSTMENU = false;
                    }
                    this.securityController.getTenant().subscribe(tenant_id => {
                        this.TENANDID = tenant_id != null ? tenant_id : -1;
                    });

                    this.homeChanged$.next(this.HOME);
                    this.menuChanged$.next(this.MENU);

                },
                    error => {
                        this.MENU = MenuOptionsDto.empty();
                        this.menuChanged$.next(this.MENU);
                    });

            });
    }

    private loadResources(): Observable<Array<TenantResourceItemMaintenanceDto>> {
        return this.get(this.baseUrl + '/resources');
    }
    private internalHome(): Observable<HomeDto> {
        return this.get(this.baseUrl + '');
    }

    public menu() {
        if (this.securityController.isLogged)
            this.securityController.isLogged().subscribe(islogged => {
                if (!islogged) {
                    this.menuChanged$.next(this.MENU);
                    return;
                }
                this.internalMenu().subscribe(data => {
                    this.loadGlobals();
                    this.MENU = data;
                    if (this.MENU) {
                        HomeController.DEFAULTHOME = this.MENU.defaultHome;
                    }
                    this.menuChanged$.next(this.MENU);

                },
                    error => {
                        this.MENU = MenuOptionsDto.empty();
                        this.menuChanged$.next(this.MENU);
                    });
            });
    }
    private internalMenu(): Observable<MenuOptionsDto> {
        return this.get(this.baseUrl + '/menu');
    }

    public hasProduct(product: HardmanProducts): boolean {
        if (this.HOME == null || this.HOME.products == null)
            return false;
        return this.HOME.products.filter(p => p.hardmanProduct == product).length == 1;
    }
    /***
     * FUnció que encapsula quan un usuari pot seleccionar un driver. és una "confabulació" de productes
     */
    public potSelectDriver(): boolean {
        if (this.hasProduct(HardmanProducts.carrier))
            return true;
        return false;
    }
    /***
     * FUnció que encapsula quan un usuari pot seleccionar un carrier. és una "confabulació" de productes
     */
    public potSelectCarrier(): boolean {
        if (this.hasProduct(HardmanProducts.towercontrol) || this.hasProduct(HardmanProducts.shipper))
            return true;
        return false;
    }


    /**
     * A partir d'un codi d'opcio de menu, retorna el dto corresponent de la llista de opcions de menu del Menu carregat actualment
     * @param option 
     */
    public getMenuOption(option: OpcionsMenu): MenuOptionDto {
        let optiondto = null;
        if (this.MENU != null) {
            this.MENU.options.forEach(element => {
                if (option == element.name) {
                    optiondto = element;
                    return optiondto;
                }

            });
        }
        return optiondto;
    }

    /**
     * Refresquem els xats de l'usuari
     */
    public refreshChats() {
        this.refreshChatsByChats(this.CHATS);
    }
    public refreshChatsByChats(chats: ChatsDto) {
        this.chatController.refreshChats(chats).subscribe(data => {
            let chatsList = this.CHATS.chats;
            let prevSearch = this.CHATS.searchtext;
            if (!chatsList)
                chatsList = [];
            this.CHATS = data;
            if (data.nextPage == 1) {
                this.CHATS.chats = data.chats;
            } else if (data.chats) {
                data.chats.forEach(item => {
                    let found = false;
                    for (let i = 0; !found && i < chatsList.length; i++) {
                        if (chatsList[i].chatId == item.chatId) {
                            chatsList[i] = item;
                            found = true;
                        }
                    }
                    if (!found)
                        chatsList.push(item);
                });
                this.CHATS.chats = chatsList;
            }

            this.CHATS.primeraLlistaCarregada = true;
            this.chatsChanged$.next(this.CHATS);
        });
    }
    public refreshChatByChat(chatdto: ChatDto) {
        if (chatdto != null && this.CHATS != null && this.CHATS.chats != null) {
            this.CHATS.chats.forEach(chat => {
                if (chatdto.chatId == chat.chatId) {
                    ChatDto.actualizedChatByFrom(chat, chatdto);
                    this.chatsChanged$.next(this.CHATS);
                }
            });
        }
    }

    public resetSelectedChat() {
        if (this.CHATS != null)
            this.CHATS.selectedChatId = 0;
    }

    private notificationSubscription: Subscription;
    public subscribeNotifications() {
        this.notificationSubscription = this.socketController.notificationReceived$.subscribe(value => {
            this.chooseNotifications(value);
        });
    }

    /**
     * Comprovem de quin tipus es la notificació
     */
    chooseNotifications(notification: SocketNotificationDto) {
        this.securityController.isLogged().subscribe(islogged => {
            if (!islogged) {
                return;
            } else {
                //Si es de tipus chat, comprovem si va destinat a mi
                if (notification != null) {
                    if (notification.action == SocketNotificationAction.chatReceived) {
                        if (this.CHATS != null && this.CHATS.ownerUserId == notification.userId && notification.title) {
                            this.getChat(parseInt(notification.title));
                        }
                    }
                }
            }
        });
    }

    getChat(chatId: number) {
        this.chatController.refreshChat(chatId, this.CHATS.selectedChatId).subscribe((data: ChatDto) => {
            if (!data || HLong.isNullOrNullLong(data.chatId))
                return;

            let found = false;
            let previousPendingMessagesRead = 0;
            this.CHATS.chats.forEach((chat, index) => {
                if (!found && chat.chatId == data.chatId) {
                    previousPendingMessagesRead = this.CHATS.chats[index].pendingMessagesRead;
                    this.CHATS.chats[index] = data;
                    found = true;
                }
            });
            if (!found) {
                this.CHATS.chats.push(data);
            }
            this.CHATS.countPendingMessagesRead = this.CHATS.countPendingMessagesRead + (data.pendingMessagesRead - previousPendingMessagesRead);
            this.chatsChanged$.next(this.CHATS);
        })
    }

    loadGlobals() {
        this.loadStatusColors();
        this.loadTags();
    }

    loadStatusColors() {
        this.tenantSettingController.getStatusColors().subscribe((result: Array<TenantSettingItemMaintenanceDto>) => {
            if (result) {
                result.forEach(element => {
                    switch (element.setting) {
                        case TenantSettings.PromisedStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-promised', element.value);
                            document.documentElement.style.setProperty('--color-state-promised', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.CanceledStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-canceled', element.value);
                            document.documentElement.style.setProperty('--color-state-canceled', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.ObsoleteStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-obsolete', element.value);
                            document.documentElement.style.setProperty('--color-state-obsolete', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.InprogressStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-inprogress', element.value);
                            document.documentElement.style.setProperty('--color-state-inprogress', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.ArrivedStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-arrived', element.value);
                            document.documentElement.style.setProperty('--color-state-arrived', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.DoneStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-done', element.value);
                            document.documentElement.style.setProperty('--color-state-done', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.LoadedStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-loaded', element.value);
                            document.documentElement.style.setProperty('--color-state-loaded', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.LoadedunloadedStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-loadedunloaded', element.value);
                            document.documentElement.style.setProperty('--color-state-loadedunloaded', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.UnloadedStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-unloaded', element.value);
                            document.documentElement.style.setProperty('--color-state-unloaded', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        case TenantSettings.IncompleteStatusColor:
                            document.documentElement.style.setProperty('--bg-color-state-incomplete', element.value);
                            document.documentElement.style.setProperty('--color-state-incomplete', HColor.getWhiteBlackFromColor(element.value));
                            break;
                        default:
                            break;
                    }
                });
            }
        });
    }

    public tags: Array<HColor> = [];
    loadTags() {
        this.tags = [];
        this.tagMaintenanceController.search(MaintenanceSearchByTextDto.buildPage("", [], 0, 500)).subscribe((result: ListOfMaintenanceDto<TagListMaintenanceDto>) => {
            result.list.forEach((item: TagListMaintenanceDto) => {
                this.tags[item.tagKey.toUpperCase()] = HColor.buildFromColor(item.color);
            })
        });
    }

    public getRouteToExWork(uuidExwork: string): string {
        return environment.BASE_URI + "/exwork/" + uuidExwork;
    }
    public routeToExWork(uuidExwork: string) {
        window.open(this.getRouteToExWork(uuidExwork), '_blank');
    }
    
    public getRouteToExworkCMRUUIDQR(uuidCMRQR: string): string {
        return environment.BASE_URI + "/exwork/ecmrqr/" + uuidCMRQR;
    }
    public routeToExworkCMRUUIDQR(uuidCMRQR: string) {
        window.open(this.getRouteToExworkCMRUUIDQR(uuidCMRQR), '_blank');
    }
    public getPhaseToExworkCMRsUUIDQR(uuidCMRQR: string): string {
        return environment.BASE_URI + "/exwork/phase/cmrsqr/" + uuidCMRQR;
    }
    public phaseToExworkCMRsUUIDQR(phaseUUID: string) {
        window.open(this.getPhaseToExworkCMRsUUIDQR(phaseUUID), '_blank');
    }

    public getRouteByUuid(uuid: string): string {
        return environment.BASE_APP_URI + "/route/uuid/" + uuid;
    }
    public routeByUuid(uuid: string) {
        window.open(this.getRouteByUuid(uuid), '_blank');
    }

    public createRouteIdLinkToActiveRoutes(routeParentGroupId: number): string {
        return environment.BASE_URI + "/operation/activeroutes?routeId=" + routeParentGroupId;
    }
    public createRouteIdLinkToActiveRoutesMobile(routeParentGroupId: number): string {
        return "/activeroutes/" + routeParentGroupId;
    }
    public createRouteIdLinkToActiveSlots(routeParentGroupId: number): string {
        return environment.BASE_URI + "/operation/activeslots?routeId=" + routeParentGroupId;
    }
    public createRouteIdLinkToActiveSlotsMobile(routeParentGroupId: number): string {
        return "/activeslots/" + routeParentGroupId;
    }

    public goToActiveRoutes(routeId: number) {
        if (routeId != null && routeId != 0)
            if (!this.useIonic())
                window.open(this.createRouteIdLinkToActiveRoutes(routeId), '_blank');
            else
                this.router.navigateByUrl(this.createRouteIdLinkToActiveRoutesMobile(routeId));
    }
    public goToActiveSlots(routeId: number) {
        if (routeId != null && routeId != 0)
            if (!this.useIonic())
                window.open(this.createRouteIdLinkToActiveSlots(routeId), '_blank');
            else
                this.router.navigateByUrl(this.createRouteIdLinkToActiveSlotsMobile(routeId));
    }


    public redirectByRow(row: SimpleTableRowFrontDto, routeController: RouteController, asideType: string = "") {

        if (row == null)
            return;

        if (asideType == null || asideType == "") {
            if (row.routeId != null && row.routeId != 0)
                window.open(this.createRouteIdLinkToActiveRoutes(row.routeId), '_blank');
            else if (row.routeKey != null && row.routeKey != "" && routeController != null)
                routeController.getRouteIdByRouteKey(row.routeKey).subscribe((routeId: number) => {
                    if (routeId > 0)
                        window.open(this.createRouteIdLinkToActiveRoutes(routeId), '_blank');
                });
        }
        else {
            if (asideType === "activeRoutePhase")
                if (row.routeId != null && row.routeId != 0)
                    this.sharedAsideFactory.invokeActiveRoutePhase(row.routeId, row.phaseId).then(value => {
                    });
        }
    }

}