<!--dock-planning.component.html-->
<div (drop)="drop($event, dockSlot)" class="body" [ngClass]="{ 'empty' : (!columnDocks && !isDockSlotPending && !isAssignedDock) || (columnDocks && !isAssignedPhase && isAssignedDock), 'draggable' : isDockSlotDraggable }"
    [draggable]="isDockSlotDraggable">

    <div *ngIf="dockToAssigned && !isAssignedPhase && action == 'ASSIGN_DOCK'" class="dockInfoWithOut"
    [style.color]="getTextColor(dockToAssigned.backgroundColor)"
    [style.background-color]="getTextColor(dockToAssigned.backgroundColor)">{{ dockToAssigned.skin }}</div>

    <shared-phase-slot *ngIf="dockSlot!=null && dockSlot.slotPlanning != null" class="phaseslot noborder"
        [ngClass]="{'dockPendent': dockSlot.slotPlanning.dockSkin != null && dockSlot.slotPlanning.dockSkin.length > 0 }" 
        [canalterelement]="canAlterElement" [action]="action" [phaseslot]="dockSlot.slotPlanning"  [dockToAssigned]="dockToAssigned" (onRefreshData)="refreshData()" [isdailydocks]="true">
    </shared-phase-slot>
</div>