import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class DeviceEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): DeviceEntitySelectorDto {
		let result: DeviceEntitySelectorDto = new DeviceEntitySelectorDto();
		result.loadData(EntitysSelectors.Device, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/device";
	}

	public getUrlBack(): string {
		return "/maintenance/device";
	}

}