import { Pipe } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { iRouteCard } from '../dtos/route/iRouteCard';

@Pipe({
	name: "orderbyroutecarddto"
})
export class OrderByRouteCardDto {
	transform(array: Array<iRouteCard>, asc: boolean): Array<iRouteCard> {
		if (array == null)
			return null;

		array.sort((a: iRouteCard, b: iRouteCard) => {
			if (asc)
				return OrderByRouteCardDto.compareAny(a, b);

			return OrderByRouteCardDto.compareAny(b, a);
		});
		return array;
	}

	public static compareAny(a: iRouteCard, b: iRouteCard): number {

		if (a.pendingMessagesRead != null && a.pendingMessagesRead > 0 && (b.pendingMessagesRead == null || b.pendingMessagesRead == 0))
			return OrderByRouteCardDto.xivato(-1, a, b);
		if (b.pendingMessagesRead != null && b.pendingMessagesRead > 0 && (a.pendingMessagesRead == null || a.pendingMessagesRead == 0))
			return 1;

		//Primer han d'anar els que tenen la marca de callInToDock o callOutToDock
		if (a.incompatibleSlot && !b.incompatibleSlot)
			return OrderByRouteCardDto.xivato(-1, a, b);
		else if (!a.incompatibleSlot && b.incompatibleSlot)
			return OrderByRouteCardDto.xivato(1, a, b);
		else
			return this.validateDateHour(a, b);
	}

	public static validateDateHour(a: iRouteCard, b: iRouteCard): number {

		if (a.pendingMessagesRead != null && a.pendingMessagesRead > 0 && (b.pendingMessagesRead == null || b.pendingMessagesRead == 0))
			return OrderByRouteCardDto.xivato(-1, a, b);
		if (b.pendingMessagesRead != null && b.pendingMessagesRead > 0 && (a.pendingMessagesRead == null || a.pendingMessagesRead == 0))
			return OrderByRouteCardDto.xivato(1, a, b);

		let av = a.slotHDateHour;
		if (HDateHour.isNullOrNullValue(av))
			av = a.dateHourFrom;

		let bv = b.slotHDateHour;
		if (HDateHour.isNullOrNullValue(bv))
			bv = b.dateHourFrom;

		if (HDateHour.isNullOrNullValue(av) && !HDateHour.isNullOrNullValue(bv))
			return OrderByRouteCardDto.xivato(1, a, b);
		else if (!HDateHour.isNullOrNullValue(av) && HDateHour.isNullOrNullValue(bv))
			return OrderByRouteCardDto.xivato(-1, a, b);
		else if (HDateHour.isGreaterThan(av, bv))
			return OrderByRouteCardDto.xivato(1, a, b);
		else if (HDateHour.isMinorThan(av, bv))
			return OrderByRouteCardDto.xivato(-1, a, b);
		else
			return a.routeKey > b.routeKey ? 1 : -1;
	}

	public static xivato(valor, a: iRouteCard, b: iRouteCard) {
		//console.log(a.routeKey + " " + a.pendingMessagesRead + " " + b.routeKey + " " + b.pendingMessagesRead + " -> " + valor)
		return valor;
	}
}
