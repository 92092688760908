import { Injectable, Pipe, PipeTransform, Inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment-timezone';
import { SecurityController } from '../controllers/security/security.controller';
import { HDate } from "../datatypes/HDate";
const moment = moment_;

@Pipe({
    name: 'minidia'
})
@Injectable()
export class MiniDia implements PipeTransform {

    constructor(private translate: TranslateService, @Inject('SecurityController') protected securityController: SecurityController) {
    }

    public transform(value: HDate): string {
        if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
            return "---";

        value = HDate.buildFromHDate(value);
        let date = new Date(value.toStringRequest());
        if (!date)
            return "---";

        let result: string;

        result = this.translate.instant("WEEKDAY." + date.getDay()) + " " + this.pad(value.dayOfMonth, 2);

        return result;

    }

    pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - size);
    }
}