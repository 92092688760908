import { ControlValueAccessor } from '@angular/forms';

export abstract class ValueAccessorBase<T, S> implements ControlValueAccessor {
  public innerValue: T;

  protected changed = new Array<(value: S) => void>();
  protected touched = new Array<() => void>();

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (!this.isValid(value))
      return;
    if (this.innerValue !== value) {
      this.valorCanviat(this.innerValue, value);
      this.innerValue = value;
      let ext: S = this.externalValue(value);
      if (!ext)
        this.innerValue = null;
      this.changed.forEach(f => f(ext));
    }
  }

  isValid(value: T): boolean {
    return true;
  }

  abstract externalValue(value: T): S;
  abstract internalValue(value: S): T;

  writeValue(value: S) {
    this.innerValue = this.internalValue(value);
  }

  registerOnChange(fn: (value: S) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach(f => f());
  }

  valorCanviat(antic: T, nou: T) {
  }
}