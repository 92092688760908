<!--options.component.html!-->
<div class="options" [ngClass]="getGeneralClass()" *ngIf="optionstrip!=null">
    <div *ngIf="!useIonic">
        <div class="options-menu">
            <button mat-icon-button #menuTrigger [matMenuTriggerFor]="menu" (click)="menuToggle($event)">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *ngFor="let item of optionstrip.listAsMenu  | orderoptiondto" class="options-menu-button"
                    [ngClass]="getItemClass(item)" (click)="menuClick(item, $event)">
                    <div class="options-container">
                        <mat-icon class="icon-no-awesome">{{item.optionIcon}}</mat-icon>
                        <i class="icon-awesome fa options-fa-icon" [ngClass]="getIcon(item)" aria-hidden="true"></i>
                        <i class="icon-hardman" [ngClass]="getIcon(item)" aria-hidden="true"></i>
                        <div class="option-caption">{{item.optionCaption | translate}}</div>
                    </div>
                </button>
            </mat-menu>
            <div class="actualOption" *ngIf="selectedOption">
                {{selectedOption.optionCaption | translate}}
            </div>
        </div>
        <div class="options-bar">
            <div #chips1 class="options-list">
                <div class="options-list-item" *ngFor="let item of optionstrip.listAsBar  | orderoptiondto"
                    [ngClass]="getItemClass(item)" matTooltip="{{item.optionHelp | translate}}" selected="true"
                    (click)="chipClick(item, $event)">
                    <div class="options-container">
                        <mat-icon class="icon-no-awesome">{{item.optionIcon}}</mat-icon>
                        <i class="icon-awesome fa options-fa-icon" [ngClass]="item.optionIcon" aria-hidden="true"></i>
                        <i class="icon-hardman" [ngClass]="item.optionIcon" aria-hidden="true"></i>
                        <div class="option-caption span-icon-text">{{item.optionCaption | translate}}</div>
                        <div class="option-caption span-only-text">{{item.optionCaption | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="actionoptions-toolbar">
            <button class="actionoption-toolbar-button"
                *ngFor="let item of optionstrip.listAsBar  | orderoptiondto  | avoidOptions:'only-toolbar'"
                [ngClass]="getItemClass(item)" matTooltip="{{item.optionHelp | translate}}"
                (click)="chipClick(item, $event)">
                <div class="actionoption-container">
                    <mat-icon class="icon-no-awesome">
                        {{item.optionIcon}}
                    </mat-icon>
                    <i class="icon-awesome fa options-fa-icon" [ngClass]="item.optionIcon" aria-hidden="true"></i>
                    <i class="icon-hardmann" [ngClass]="item.optionIcon" aria-hidden="true"></i>
                    <div class="actionoption-caption">{{item.optionCaption | translate}}</div>
                </div>
            </button>
        </div>
    </div>
    <div *ngIf="useIonic" class="toolbar ionic">
        <div *ngIf="optionstrip.listAsBar != null && optionstrip.listAsBar.length > 0" class="actionoptions-toolbar">
            <button class="actionoption-toolbar-button"
                *ngFor="let item of optionstrip.listAsBar  | orderoptiondto  | avoidOptions:'only-toolbar'"
                [ngClass]="getItemClass(item)" (click)="chipClick(item, $event)">
                <div class="actionoption-container">
                    <i class="icon-awesome fa options-fa-icon" [ngClass]="item.optionIcon" aria-hidden="true"></i>
                    <div class="actionoption-caption">{{item.optionCaption | translate}}</div>
                </div>
            </button>
        </div>
        <div *ngIf="optionstrip.listAsMenu != null && optionstrip.listAsMenu.length > 0" class="options-menu-ionic">
            <ion-list>
                <ion-item>
                    <ion-label>
                        <ion-icon name="menu-outline"></ion-icon>
                        <!-- <span *ngIf="value">{{selectedOption.optionCaption | translate}}</span> -->
                    </ion-label>
                    <ion-select (ionChange)="chipClickIon($event)" [value]="selectedOption">
                        <ion-select-option *ngFor="let item of optionstrip.listAsMenu  | orderoptiondto" [value]="item"
                            [ngClass]="getItemClass(item)">
                            <i class="icon-awesome fa options-fa-icon" [ngClass]="getIcon(item)" aria-hidden="true"></i>
                            <i class="icon-hardman" [ngClass]="getIcon(item)" aria-hidden="true"></i>
                            <div class="actionoption-caption">{{item.optionCaption | translate}}</div>
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-list>
        </div>
    </div>
</div>