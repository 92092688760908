<div *ngIf="allLoaded" class="container" [ngClass]="{'ionic': useIonic,  'selectedroute' : routeId > 0 }">
    <div class="cards list">
        <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="esquerra">
                        <div class="title">
                            {{'DESKTOP.ROUTE.PAGE.COLUMN.ROUTES.TITLE' | translate}}
                            <span class="count">{{filterMetadata.count}}/{{routesCount}}</span>
                        </div>
                    </div>
                    <div class="centre">
                        <div *ngIf="addressAmbit!=null && addressAmbit.address!=null" class="address boleta"
                            [ngStyle]="addressAmbit.address.style">
                            <div class="inner-boleta">
                                {{addressAmbit.address.skin}}
                            </div>
                        </div>
                        <div *ngIf="addressAmbit!=null && addressAmbit.ambit!=null" class="ambit boleta"
                            [ngStyle]="addressAmbit.ambit.style">
                            <div class="inner-boleta">
                                {{addressAmbit.ambit.skin}}
                            </div>
                        </div>
                        <shared-filter class="extrapadding" [filterstrip]="filter" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterRouteTypes" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterPeriod" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                        
                    </div>
                    <div class="dreta">
                        <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text
                            [(ngModel)]="searchText" (ngModelChange)="this.searchTextUpdate.next($event)"
                            (focus)="onSearchFocus($event)" (click)="onSearchClick($event)">
                    </div>
                    <div class="createReturnInbound">
                        <button *ngIf="canCreateNonacceptance"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATENONACCEPTANCE' | translate }}"
                            (click)="createNonacceptance($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-routetype-nonacceptance iconaInboundReturnNonacceptance"></i>
                        </button>
                        <button *ngIf="canCreateReturn"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATERETURN' | translate }}"
                            (click)="createReturn($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-routetype-return iconaInboundReturnNonacceptance"></i>
                        </button>
                        <button *ngIf="canCreateOutBound"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATEOUTBOUND' | translate }}"
                            (click)="createOutbound($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-routetype-outbound iconaInboundReturnNonacceptance"></i>
                        </button>
                        <button *ngIf="canCreateInbound"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATEINBOUND' | translate }}"
                            (click)="createInbound($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-routetype-inbound iconaInboundReturnNonacceptance"></i>
                        </button>
                        <button *ngIf="canCreateDelegate"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATEDELEGATE' | translate }}"
                            (click)="createDelegate($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-routetype-delegate iconaInboundReturnNonacceptance"></i>
                        </button>
                        <button *ngIf="canCreateReverseLogistics"
                            title="{{ 'DESKTOP.ROUTE.PAGE.BUTTON.CREATEREVERSELOGISTICS' | translate }}"
                            (click)="createReverseLogistics($event)" class="buttonReturnInbound">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <i class="hardman-logisticainversa iconaInboundReturnNonacceptance"></i>
                        </button>
                    </div>
                    <div class="container-button-routegroup">
                        <button *ngIf="showPanelGroupingRoutes" class="buttonReturnInbound agrupar"
                            (click)="onClickRouteGroup($event)">
                            <i _ngcontent-qpt-c304="" class="fa fa-compress"></i>
                            {{'SHARED.ACTIVE.ROUTE.GROUP.BUTTON.CAPTION' | translate}}</button>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
                <div class="expansion-body">
                    <div class="filter">
                        <shared-address-ambit-selector-component [(value)]="addressAmbit"
                            (changeValues)="onChangeAddressAmbitSelector($event)">
                        </shared-address-ambit-selector-component>
                        <shared-filter class="extrapadding" [filterstrip]="filter" (change)="filterUpdate()">
                        </shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterRouteTypes" (change)="filterUpdate()">
                        </shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterPeriod" (change)="filterUpdate()">
                        </shared-filter>
                    </div>
                </div>
            </mat-expansion-panel-body>
        </mat-expansion-panel>
        <div class="page">
            <div class="column" [ngClass]="{'display-flex' : displayFlex}">
                <div class="llista" [ngClass]="{'selectedroute' : routeId > 0}">
                    <div class="overflow">

                        <shared-route-item-list
                            *ngFor="let item of routesExactKey | filterActiveRoutes:searchText:filterMetadataExactKey  | orderbyroutecarddto:true; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId, 'divided-childs': item.isDivided && item.childsCount > 0, 'draggable': displayFlex}"
                            [route]="item" [isActiveRoutes]="'true'" [selectedRoute]="selectedRoute"
                            (click)="onClickItem(item)" [isDraggable]="displayFlex">
                        </shared-route-item-list>

                        <shared-route-item-list
                            *ngFor="let item of getRoutes() | filterActiveRoutes:searchText:filterMetadata | filterRemoveRepeat:routesExactKey:'routeId' | orderbyroutecarddto:true | slice:0:sliceCount; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId, 'divided-childs': item.isDivided && item.childsCount > 0, 'draggable': displayFlex}"
                            [route]="item" [isActiveRoutes]="'true'" (click)="onClickItem(item)"
                            [isDraggable]="displayFlex">
                        </shared-route-item-list>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>
                </div>
            </div>
            <shared-route-group-panel class="container-routegroup"
                [ngClass]="{'display-flex' : displayFlex, 'display-none' : !displayFlex}" [openPanel]="displayFlex"
                (finish)="onFinishRouteGroup($event)" (clickBack)="onClickBackRouteGroup($event)">
            </shared-route-group-panel>
        </div>
    </div>
    <shared-active-slots-item class="cards one" [routeId]="routeId" (clickBack)="onClickBack($event)">
    </shared-active-slots-item>
</div>