import { Component, Input, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { RouteContainerDataDto } from '@shared/src/dtos/route/RouteContainerDataDto';
import { RouteController, SharedSaveButtonEvent } from '@shared/src/public-api';

@Component({
  selector: 'shared-containernumber',
  templateUrl: './shared.containernumber.component.html',
  styleUrls: ['./shared.containernumber.component.scss'],
})
export class SharedContainerNumberComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    protected routeController: RouteController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input()
  public hideTitle: boolean = false;

  @Input() public readonly: boolean = false;

  @Input() public showInfoContainer: boolean = true;
  @Input() public showArcDre: boolean = true;

  private _routeId: number;
  @Input()
  public set routeId(value: number) {
    if (this._routeId === value)
      return;
    this._routeId = value;
    this.loadContainerData();
  }

  public get routeId(): number {
    return this._routeId;
  }

  public value: RouteContainerDataDto;
  loadContainerData() {
    if (HLong.isNullOrNullLong(this._routeId)) {
      this.value = null;
      return;
    }

    this.routeController.getRouteContainerData(this._routeId).subscribe(data => {
      this.value = data;
    });
  }

  public editMode: boolean = false;


  public data: RouteContainerDataDto;
  startEditmode() {
    this.data = RouteContainerDataDto.build(this.value.routeId, this.value.containerNumber, this.value.ARC, this.value.DRE, this.value.tare, this.value.seal);
    this.editMode = true;
  }

  save($event: SharedSaveButtonEvent) {
    this.routeController.changeRouteContainerData(this.data).subscribe(data => {
      if (data) {
        this.value.containerNumber = this.data.containerNumber;
        this.value.ARC = this.data.ARC;
        this.value.DRE = this.data.DRE;
        this.value.tare = this.data.tare;
        this.value.seal = this.data.seal;
      }
      if ($event)
        $event.callback();
      this.editMode = false;
    });
  }


  cancel() {
    this.editMode = false;
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
