import { Component, Input, OnInit } from '@angular/core';
import { UserInfoCardDto } from '@shared/src/dtos/user/UserInfoCardDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';

@Component({
  selector: 'shared-user-info-card',
  templateUrl: './shared.user.info.card.html',
  styleUrls: ['./shared.user.info.card.scss']
})


export class SharedUserInfoCard implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  
  }

  private _value: UserInfoCardDto;
  @Input() set value(value: UserInfoCardDto) {
    if (this._value === value)
      return;
    this._value = value;
    this.loadAvatar();
  }
  get value(): UserInfoCardDto {
    return this._value;
  }
  
  public userAvatar: ParticipantDto;
  
  loadAvatar(){
    if(this.value)
      this.userAvatar = ParticipantDto.buildByParams(this.value.userId, ChatParticipantTypes.User);
    else
      this.userAvatar = null;
  }

}
