import { HColor } from "@shared/src/datatypes/HColor";
import { HTags } from "@shared/src/datatypes/HTags";
import { DeviceListMaintenanceDto } from "../maintenance/device/DeviceListMaintenanceDto";
import { GlobalTelemetry } from "./GlobalTelemetry";

export class LocalizableObjectDto {

    public id: number;
    public objectType: LocalizableObjectTypes;
    public key: string;
    public description: string;
    public color: HColor;
    public globalTelemetry: GlobalTelemetry;
    public tags: HTags;
    public sTags: string;
    public listLocalizableChilds: Array<LocalizableObjectDto>;

}

export enum LocalizableObjectTypes {
    user = "user",
    address = "address",
    device = "device",
    plate = "plate",
    route = "route",
    shipment = "shipment",
    phase = "phase"
}
