import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { HLong } from "@shared/src/datatypes/HLong";
import { OptionDto } from "@shared/src/dtos/maintenance/option/OptionDto";
import { OptionsStripDto } from "@shared/src/dtos/maintenance/option/OptionsStripDto";
import { OpenRouteExworkInfoDto } from "@shared/src/dtos/open/OpenRouteExworkInfoDto";
import { AlertService, HDateHour, HString, HomeController, NotifiedRouteEmailDto, RouteController, RouteExworkInfoDto, RouteExworkLogDto } from "@shared/src/public-api";
import { Subject } from "rxjs-compat";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'shared-route-exworks-edit',
  templateUrl: './shared.route.exworks.edit.component.html',
  styleUrls: ['./shared.route.exworks.edit.component.scss']
})
export class SharedRouteExworksEditComponent implements OnInit {

  public exworkToggleChangeSubject: Subject<boolean> = new Subject<boolean>();
  public exworkChangeSubject: Subject<string> = new Subject<string>();


  constructor(@Inject("HomeController") protected homeController: HomeController,
    @Inject("AlertService") protected alertService: AlertService,
    protected openRouteController: OpenRouteController,
    protected routeController: RouteController) { }

  public pendingrequest = false;
  ngOnInit(): void {

    this.exworkToggleChangeSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        
        if(this.pendingrequest)
          return;

        this.pendingrequest = true;
        this.openRouteController.setExworkInfo(this.routeExworkInfo.routeId, OpenRouteExworkInfoDto.build(this.routeExworkInfo.isExwork, this.routeExworkInfo.emails)).subscribe(data => {
          this.pendingrequest = false;
          if (data) {
            this.refresh.next();
            this.loadExworkData();
          }
        });
      });

    this.exworkChangeSubject.pipe(
      debounceTime(500))
      .subscribe(value => {
        if (!HString.isNullOrNullString(this.routeExworkInfo.uuidExwork)){
          if(this.pendingrequest)
            return;

          this.pendingrequest = true;
          this.openRouteController.addExworkEmail(this.routeExworkInfo.uuidExwork, value).subscribe(data => {
            this.pendingrequest = false;
            if (data) {
              this.refresh.next();
              this.loadExworkData();
              this.email = "";
            }
          });
        }
      });

  }

  deleteEmail(email: string) {
    if (!this.routeExworkInfo || !this.routeExworkInfo.emails || !this.routeExworkInfo.emails.includes(email))
      return;

    this.routeExworkInfo.emails.splice(this.routeExworkInfo.emails.indexOf(email), 1);

    if(this.pendingrequest)
      return;

    this.pendingrequest = true;
    this.openRouteController.setExworkInfo(this.routeId, OpenRouteExworkInfoDto.build(this.routeExworkInfo.isExwork, this.routeExworkInfo.emails)).subscribe(e => {
      this.pendingrequest = false;
      this.refresh.next();
      this.loadExworkData();
    });
  }

  @Input() public readonly: boolean;

  private _routeId: number;
  @Input() public set routeId(v: number) {
    if (this._routeId === v)
      return;

    this._routeId = v;
    this.buildOptions();
    this.loadExworkData();
  }

  public get routeId(): number {
    return this._routeId;
  }

  @Output() public refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  public changeExwork() {
  }

  goExwork() {
    if (this.routeId > 0 && !HString.isNullOrNullString(this.routeExworkInfo.uuidExwork))
      this.homeController.routeToExWork(this.routeExworkInfo.uuidExwork);
  }
  copyExwork() {
    if (!HString.isNullOrNullString(this.routeExworkInfo.uuidExwork))
      this.alertService.copyToClipboard(this.homeController.getRouteToExWork(this.routeExworkInfo.uuidExwork)).subscribe();
  }
  renewUuidExwork() {
    this.openRouteController.renewUuidExworkRouteId(this.routeExworkInfo.routeId).subscribe(data => {
      if (data) {
        this.loadExworkData();
      }
    })
  }
  resendAllExwork() {
    this.openRouteController.resendAllExworkByRouteId(this.routeExworkInfo.routeId).subscribe(data => {
      if (data) {
        this.loadExworkData();
      }
    })
  }

  public email: string;

  public routeExworkInfo: RouteExworkInfoDto;
  loadExworkData() {
    if (HLong.isNullOrNullLong(this.routeId)) {
      this.routeExworkInfo = null;
      return;
    }

    this.routeController.getRouteExworkInfo(this.routeId).subscribe(e => {
      if (e && e.routeId) {
        this.routeExworkInfo = e;
      } else {
        this.routeExworkInfo = null;
      }
    });

  }

  public routeExworkLog: RouteExworkLogDto;
  loadExworkLogs() {
    if (HLong.isNullOrNullLong(this.routeId)) {
      this.routeExworkLog = null;
      return;
    }

    this.routeController.getRouteExworkLog(this.routeId).subscribe(e => {
      if (e && e.routeId)
        this.routeExworkLog = e;
      else
        this.routeExworkLog = null;
    });
  }


  getLogByEmail(email: string): Array<NotifiedRouteEmailDto> {
    if (!this.routeExworkInfo || !this.routeExworkInfo.notificationsByEmail)
      return [];

    if (!this.routeExworkInfo.notificationsByEmail.has)
      this.routeExworkInfo.notificationsByEmail = new Map<string, Array<NotifiedRouteEmailDto>>(Object.entries(this.routeExworkInfo.notificationsByEmail));

    if (!this.routeExworkInfo.notificationsByEmail.has(email))
      return [];

    return this.routeExworkInfo.notificationsByEmail.get(email).sort((a: NotifiedRouteEmailDto, b: NotifiedRouteEmailDto) => {
      if (HDateHour.isMinorThan(a.moment, b.moment))
        return 1;
      if (HDateHour.isGreaterThan(a.moment, b.moment))
        return -1;
      return 0;
    });
  }

  private mapOpenedLogsByEmail: Map<string, boolean> = new Map<string, boolean>();
  isOpenedLog(email: string): boolean {
    if (!email)
      return false;

    return this.mapOpenedLogsByEmail && this.mapOpenedLogsByEmail.has && this.mapOpenedLogsByEmail.has(email) && this.mapOpenedLogsByEmail.get(email);
  }

  openCloseLog(email: string) {
    if (!email)
      return;

    if (this.mapOpenedLogsByEmail && this.mapOpenedLogsByEmail.has)
      this.mapOpenedLogsByEmail.set(email, !this.isOpenedLog(email));

    this.loadExworkData();
  }

  resendEmail(email: string) {
    if (!this.routeExworkInfo || !this.routeExworkInfo.emails || !this.routeExworkInfo.emails.includes(email))
      return;

    this.openRouteController.resendExworkEmails(this.routeId, OpenRouteExworkInfoDto.build(this.routeExworkInfo.isExwork, [email])).subscribe(e => {
      this.loadExworkData();
    });
  }

  public options: OptionsStripDto;
  public selectedOption: OptionDto = null;
  public buildOptions() {
    if (this.options)
      return;

    this.options = new OptionsStripDto();
    this.options.add(OptionDto.build('GENERAL', 'ROUTE.EXWORKS.OPTIONS.GENERAL.CAPTION', null, null, 1, -1, -1, true, false));
    this.options.add(OptionDto.build('LOGS', 'ROUTE.EXWORKS.OPTIONS.LOGS.CAPTION', null, null, 1, -1, -1, false, false));
    this.selectedOption = this.options.getActualSelected();
  }

  onClickOption(item: OptionDto) {
    if (!item)
      return;

    this.selectedOption = item;
    if (this.selectedOption.optionId == 'GENERAL') {
      this.loadExworkData();
    }
    if (this.selectedOption.optionId == 'LOGS') {
      this.loadExworkLogs();
    }

  }

  pretty(val: any): string {
    return JSON.stringify(val);
  }
}