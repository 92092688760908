import { Injectable } from "@angular/core";
import { PartnerCategories } from "@shared/src/enums/PartnerCategories";
import { PartnerTypes } from "@shared/src/enums/PartnerTypes";
import { HTriState, Languages } from "@shared/src/public-api";

@Injectable()
export class PartnerDetailDto {
    public type: PartnerTypes;

    public tenantId: number;
    public tenantIdTarget: number;
    public partnerKey: string;

    public tradeMarkTarget: string;
    public referenceNameTarget: string;
    public emailTarget: string;
    public organitzationNameTarget: string;
    public nifTarget: string;

    public prefixTarget: string = "+34"; //valor per defecte
    public phoneTarget: string;

    public partnerCategory: PartnerCategories;

    public exworksAllways: boolean = false;
    public exworksEmails: string = "";

    public emailsDocumentationClaimMessages: string = "";

    public setPlateADrivers: boolean = true;
    public setPlateBDrivers: boolean = true;
    public setNameDrivers: boolean = true;
    public setIdentificationDrivers: boolean = true;
    public setPhoneDrivers: boolean = true;

    public sendNotificationWithOutTenantTarget: HTriState;
    public sendNotificationWhenPermissionDisabled: HTriState;

    public reverseLogisticsExpression: string = "";
    public automaticReservesEnabled: boolean = false;
    public automaticReservesExpression: string = "";

    public language: Languages;

    public static build(partnerType: PartnerTypes): PartnerDetailDto {

        let result: PartnerDetailDto = new PartnerDetailDto();
        result.type = partnerType;
        switch (partnerType) {
            case PartnerTypes.carrier:
                result.partnerCategory = PartnerCategories.Carrier;
                break;
            case PartnerTypes.logisticoperator:
                result.partnerCategory = PartnerCategories.LogisticOperator;
                break;
            case PartnerTypes.finalcustomer:
                result.partnerCategory = PartnerCategories.FinalCustomer;
                break;
            case PartnerTypes.driver:
                result.partnerCategory = PartnerCategories.Driver;
                break;
            default:
                result.partnerCategory = PartnerCategories.None;
        }

        return result;
    }
}
