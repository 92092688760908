import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { DateHourValidator } from '../../form/validations/DateHourValidator';
import { HDateHour } from '../../../../datatypes/HDateHour';
import { Moment } from 'moment';
import * as moment_ from 'moment-timezone';
const moment = moment_;
import { ThemePalette } from '@angular/material/core';
import { HomeController } from '../../../../controllers/home/home.controller';
import { TimeZones } from '@shared/src/enums/TimeZones';
import { HDate } from '@shared/src/datatypes/HDate';
import { SecurityController } from '@shared/src/controllers/security/security.controller';


@Component({
  selector: 'datehour-picker',
  templateUrl: './datehour-picker.component.html',
  styleUrls: ['./datehour-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DateHourPickerComponent, multi: true }
  ]
})

export class DateHourPickerComponent extends ValueAccessorValidatedBase<any, HDateHour> {


  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormDateHourPicker = this.fb.group({
      'dtpvalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: any): boolean {
    let temp: Moment = moment(value);
    return DateHourValidator.isValid(temp);
  }

  public rFormDateHourPicker: FormGroup;

  @ViewChild('timepicker', { static: true }) public timepicker: any;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController,
    @Inject('SecurityController') protected securityController: SecurityController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();

    this.securityController.getCurrentTimeZone().then(t => {
      this.currentTimeZoneObserver = t;
    })
  }


  public currentTimeZoneObserver: TimeZones;
  @Input() public configuration: DateHourPickerConfig = new DateHourPickerConfig();
  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HDateHour> = new EventEmitter();

  @Input() public showLocaleObserver: boolean = false;
  public showDateHourTimezone: boolean = false;

  _max: any;
  @Input() set max(selectedvalue: HDateHour) {
    if (HDateHour.isNullOrNullValue(selectedvalue)) {
      this._max = null;
      return;
    }

    if (!this.useIonic) {
      this._max = HDateHour.getMoment(selectedvalue);
    } else {
      this._max = HDateHour.getMoment(selectedvalue).toISOString();
    }
  }
  get max(): HDateHour {
    return HDateHour.buildFromMoment(this._max);
  }

  _min: any;
  @Input() set min(selectedvalue: HDateHour) {
    if (HDateHour.isNullOrNullValue(selectedvalue)) {
      this._min = null;
      return;
    }

    if (!this.useIonic) {
      this._min = HDateHour.getMoment(selectedvalue);
    } else {
      this._min = HDateHour.getMoment(selectedvalue).toISOString();
    }
  }
  get min(): HDateHour {
    return HDateHour.buildFromMoment(this._min);
  }

  /** si es modifica vinculatedate, value es modifica, 
  * s'ha d'avançar el valor tants dies com diferencia hi hagi en l'actual */
  public _from: HDateHour;
  @Input() set from(selectedfrom: HDateHour) {
    // Actualitzem value tenint en compte l'actual vinculatedate
    let actualvalue: HDateHour = HDateHour.buildFromMoment(this.value);
    if (!HDateHour.isNullOrNullValue(actualvalue) && !HDateHour.isNullOrNullValue(selectedfrom) && !HDateHour.isNullOrNullValue(this._from)) {
      let nhdatehour = HDateHour.distance(selectedfrom, this._from);
      if (!HDateHour.isNullOrNullValue(nhdatehour)) {
        let resta = HDateHour.isMinorThan(selectedfrom, this._from);
        if (!this.useIonic) {
          this.value = HDateHour.getMoment(this._hdatehour.addDateHour(nhdatehour, resta));
        } else {
          this.value = HDateHour.getMoment(this._hdatehour.addDateHour(nhdatehour, resta)).toISOString();
        }
      }
    }

    this._from = selectedfrom;
  }
  get from(): HDateHour {
    return this._from;
  }

  public identifier = 'datehour-picker-' + identifier++;

  public _hdatehour: HDateHour;
  externalValue(value: any): HDateHour {
    if (!value)
      return undefined;

    if (this.useIonic)
      value = moment(value);

    if (HDateHour.isNullOrNullValue(this._hdatehour))
      this._hdatehour = HDateHour.buildFromMoment(value);
    else
      this._hdatehour = HDateHour.buildFromMomentByTZ(value, this._hdatehour.Timezone, this._hdatehour.TimezoneObserver);

    if (!this.configuration.showSeconds)
      this._hdatehour = this._hdatehour.setSeconds(0);

    if (this._hdatehour.Timezone === undefined)
      this._hdatehour.Timezone = this.currentTimeZoneObserver;

    if (this._hdatehour.TimezoneObserver === undefined)
      this._hdatehour.TimezoneObserver = this.currentTimeZoneObserver;

    if (HDateHour.isNullOrNullValue(this._hdatehour))
      return null;
    return this._hdatehour;
  }
  internalValue(value: HDateHour): any {
    this._hdatehour = HDateHour.cloneByHDateHour(value);
    if (this._hdatehour)
      this._hdatehour = this._hdatehour.setSeconds(0);

    this.showDateHourTimezone = this._hdatehour && SecurityController.TIMEZONE != this._hdatehour.Timezone;

    this.setCanShowTimeZoneObserver(value);
    if (!value)
      return undefined;
    if (HDateHour.isNullOrNullValue(value))
      return null;

    if (!this.useIonic) {
      return HDateHour.getMoment(value);
    } else {
      return HDateHour.getMoment(value).toISOString();
    }
  }

  getTimeZone() {
    if (this._hdatehour != null && this._hdatehour.Timezone != null)
      return this._hdatehour.Timezone;
    return SecurityController.TIMEZONE;
  }

  public canShowTimeZoneObserver: boolean;
  setCanShowTimeZoneObserver(value: HDateHour) {
    let canshow = false;
    if (!HDateHour.isNullOrNullValue(value)) {
      if (value.Timezone != value.TimezoneObserver)
        canshow = true;
    }
    this.canShowTimeZoneObserver = canshow;
  }


  public showTimeZoneObserver: boolean = false;
  onShowTimeZoneObserver() {
    this.showTimeZoneObserver = !this.showTimeZoneObserver;
  }


  getDateHourObserver(): HDateHour {
    if (!HDateHour.isNullOrNullValue(this._hdatehour)) {
      return HDateHour.dateHourObserver(this._hdatehour);
    }
    return HDateHour.nullDateHour();
  }

  onChange(e) {
    //this.rFormDateHourPicker.markAsDirty();
    this.setCanShowTimeZoneObserver(this.externalValue(this.value));
    this.valueChanged.next(this.externalValue(this.value));
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;

export class DateHourPickerConfig {
  public showSpinners = true;
  public showSeconds = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public touchUi = false;
  public color: ThemePalette = 'primary';
  public enableMeridian = false;
  public disableMinute = false;
  public hideTime = false;
}