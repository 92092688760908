import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { GateItemMaintenanceDto } from './GateItemMaintenanceDto';
import { GateListMaintenanceDto } from './GateListMaintenanceDto';

@Injectable()
export class GateMaintenanceController extends BaseMaintenanceController<GateListMaintenanceDto, GateItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "gate");
    }


    public buildUrl(uri: string): string {
        return "/system/dataandparameters/gate" + uri;
    }
}