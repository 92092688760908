
import { Component, Input, OnInit, Inject } from '@angular/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';
import { HColor } from '@shared/src/datatypes/HColor';

@Component({
    selector: 'shared-state-user',
    templateUrl: './shared.state.user.component.html',
    styleUrls: ['./shared.state.user.component.scss']
})
export class SharedStateUserComponent implements OnInit {

    constructor(@Inject('SecurityController') public securityController: SecurityController) { }

    private _value: GeneralStatus;

    @Input()
    set value(value: GeneralStatus) {
        if (this._value === value)
            return;
        this._value = value;
        this.setResourceState();
    }
    get value(): GeneralStatus {
        return this._value;
    }

    private _bgcolor: HColor;
    @Input()
    set bgcolor(bgcolor: HColor) {
        if (this._bgcolor === bgcolor)
            return;
        this._bgcolor = bgcolor;
        this.setStyleStatus();
    }
    get bgcolor(): HColor {
        return this._bgcolor;
    }

    ngOnInit(): void {
    }

    public resourceState = "";
    setResourceState(){
        if (this.value != null)
            this.resourceState =  'GENERALSTATUS.' + this.value.toUpperCase() + ".CAPTION";
    }
    public styleState = {};
    setStyleStatus(){
        if(!HColor.isNullOrEmpty(this.bgcolor))
           this.styleState = {'background-color':HColor.getColorRGBA(this.bgcolor)};
    }
}
