import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { api } from '../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class OpenDocumentController extends BaseController {

    private base = '/public/document'

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getDocumentDetailPdfById(id: number): Observable<Blob> {
        return this.get(this.base + "/documentdetail/pdf/" + id);
    }

    public getApiDocumentDetailPdfById(id: number): string {
        return  api(this.base + "/documentdetail/pdf/" + id);
    }

}
