import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class RouteEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): RouteEntitySelectorDto{
        let result: RouteEntitySelectorDto = new RouteEntitySelectorDto();
		result.loadData(EntitysSelectors.Route, "", activeFilterKeys, 0, false);
        return result;
    }

	public getUrlGoToMaintenance(): string {
		return "activity/route";
	}

	public getUrlBack(): string {
		return "/maintenance/activity/route";
	}

}