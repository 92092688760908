<div class="container" *ngIf="route">
        <span class="component-title"> {{'ROUTEPAGE.ITEM.DIVISION.CAPTION' | translate}} </span>
        <span class="help"
                [innerHTML]="'ROUTEPAGE.ITEM.DIVIDED.EXPLANATION' | translate: {actualDivision:routeDividedInfo.childNumber, maxDivision:routeDividedInfo.maxDivision}"></span>

        <div class="inputChilds">
                <input-component class="col-numchilds" [(ngModel)]="numChilds" [readonly]="readonly"
                        placeholder="{{'ROUTEPAGE.ITEM.DIVIDED.NUMCHILDS' | translate}}" type="number" [min]="2"
                        [max]="routeDividedInfo.maxDivision"></input-component>
                <button mat-flat-button color="primary" class="action" [disabled]="readonly || numChilds < 2"
                        (click)="onRouteDivision()">{{'ROUTEPAGE.ITEM.DIVIDED.DIVISION' |
                        translate}}</button>
        </div>


        <shared-route-division-info *ngFor="let itemChild of route.childs" [route]=itemChild>
        </shared-route-division-info>
</div>