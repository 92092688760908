<div class="container map-container" [ngClass]="containerClass">
    <div class="position-info">
        long:{{longitude}} lat:{{latitude}} zoom:{{zoom}}
    </div>
    <div class="map" [style.height]="height == null ? null : sheight" fxFlex="1 1 100%" #map>
       <google-map *ngIf="map != null && map.offsetHeight > 0" height="100%"  width="100%" [center]="center" 
            [zoom]="zoom" (mapClick)="clickOnMap($event)"
            (zoomChanged)="zoomChanged($event)">
        </google-map>
    </div>
</div>