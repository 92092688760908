<!-- test-avatar-page.component.html !-->
<div class="linia">
    <div class="title">Nulls</div>
    <avatar class="avatar" [avatar]="usernull" [width]=20 [height]=20></avatar>
    <avatar class="avatar" [avatar]="usernull" [width]=30 [height]=30></avatar>
    <avatar class="avatar" [avatar]="usernull" [width]=50 [height]=50></avatar>
    <avatar class="avatar" [avatar]="usernull" [width]=80 [height]=80></avatar>
</div>
<div class="linia">
    <div class="title">Random</div>
    <avatar class="avatar" [avatar]="user" [width]=20 [height]=20></avatar>
    <avatar class="avatar" [avatar]="user" [width]=30 [height]=30></avatar>
    <avatar class="avatar" [avatar]="user" [width]=50 [height]=50></avatar>
    <avatar class="avatar" [avatar]="user" [width]=80 [height]=80></avatar>
</div>
<div class="linia">
    <div class="title">Extrems</div>
    <avatar class="avatar" [avatar]="buildUser('negre','#000000')" [width]=20 [height]=20></avatar>
    <avatar class="avatar" [avatar]="buildUser('blanc','#ffffff')" [width]=30 [height]=30></avatar>
    <avatar class="avatar" [avatar]="buildUser('.','#88ffff')" [width]=50 [height]=50></avatar>
    <avatar class="avatar" [avatar]="buildUser('cadenamoltllarga','#333333')" [width]=80 [height]=80></avatar>
</div>
<div class="linia">
    <color-picker class="backgroundColor" [(ngModel)]="backgroundColor" name="backgroundColor"
        (ngModelChange)="backgroundColorChanged($event)">
    </color-picker>
    <avatar class="avatar" [avatar]="buildUserC('AAA')" [width]=20 [height]=20></avatar>
    <avatar class="avatar" [avatar]="buildUserC('bbb')" [width]=30 [height]=30></avatar>
    <avatar class="avatar" [avatar]="buildUserC('.')" [width]=50 [height]=50></avatar>
    <avatar class="avatar" [avatar]="buildUserC('cadenamoltllarga')" [width]=80 [height]=80></avatar>
</div>