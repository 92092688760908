import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';

import { FormQuestionListMaintenanceDto } from './FormQuestionListMaintenanceDto';
import { FormQuestionItemMaintenanceDto } from './FormQuestionItemMaintenanceDto';
@Injectable()
export class FormQuestionSubmaintenanceController extends BaseSubMaintenanceController<FormQuestionListMaintenanceDto, FormQuestionItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "form/question");
    }

    public getResourceRoot(): string {
        return "FORM.QUESTION";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/screensandforms/definitionform/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}
