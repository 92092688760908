<div class="block-dades">
    <div class="line generalTitle">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.TITLE'| translate}}</div>
</div>
<div class="block-dades help-block" *ngIf="!booking || booking.empty">
    <div class="line title">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.ADD.TITLE'| translate}}</div>
    <div class="line help">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.ADD.HELP'| translate}}</div>
</div>


<div class="form" *ngIf="formGroup != null && editMode" [formGroup]="formGroup">
    <div class="col-x">
        <input-component class="booking-number" placeholder="{{ 'BOOKINGPAGE.BOOKINGNUMBER.CAPTION' | translate }}"
            [(ngModel)]="booking.bookingNumber" formControlName="bookingNumber" [readonly]="readonly"></input-component>
    </div>
  
      <!-- Adreça origen-->
    <entity-selector class="line" [(ngModel)]="booking.departureAddressId" [entitySelector]="'PortAddress'" [fixedFilterKeys]="filterKeysPortAddressDeparture"
        placeholder="{{'BOOKING.DEPARTUREADDRESS.CAPTION' | translate}}" name="departureAddressId" formControlName="departureAddressId"
        [readonly]="readonly">
    </entity-selector>
    <div class="col-x" *ngIf="booking.departureFormattedAddress && isNullOrNullLong(booking.departureAddressId)">
        <span>{{'BOOKING.DEPARTUREADDRESS.ACTUAL.CAPTION' | translate}} {{ booking.departureFormattedAddress }}</span>
    </div>
    <!-- Adreça destí-->
    <entity-selector class="line" [(ngModel)]="booking.arrivalAddressId" [entitySelector]="'PortAddress'" [fixedFilterKeys]="filterKeysPortAddressArrival"
        placeholder="{{'BOOKING.ARRIVALADDRESS.CAPTION' | translate}}" name="arrivalAddressId" formControlName="arrivalAddressId"
        [readonly]="readonly">
    </entity-selector>
    <div class="col-x" *ngIf="booking.arrivalFormattedAddress && isNullOrNullLong(booking.arrivalAddressId)">
        <span>{{'BOOKING.ARRIVALADDRESS.ACTUAL.CAPTION' | translate}} {{ booking.arrivalFormattedAddress }}</span>
    </div>

    <div class="col-x">
        <datehour-picker [(ngModel)]="booking.datetimeStart"
            placeholder="{{ 'BOOKINGPAGE.DATETIMESTART.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="datetimeStart" [readonly]="readonly" [max]="booking.datetimeEnd"></datehour-picker>
        <datehour-picker [(ngModel)]="booking.datetimeEnd"
            placeholder="{{ 'BOOKINGPAGE.DATETIMEEND.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="datetimeEnd" [readonly]="readonly" [min]="booking.datetimeStart"></datehour-picker>
    </div>

    <div class="col-x">
        <div class="booking-number fake"></div>
        <datehour-picker [(ngModel)]="booking.depositStart"
            placeholder="{{ 'BOOKINGPAGE.DEPOSITSTART.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="depositStart" [readonly]="readonly" [max]="booking.depositEnd"></datehour-picker>
        <datehour-picker class="bold" [(ngModel)]="booking.depositEnd"
            placeholder="{{ 'BOOKINGPAGE.DEPOSITEND.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="depositEnd" [readonly]="readonly" [min]="booking.depositStart"
            [max]="booking.datetimeStart"></datehour-picker>
    </div>

    <div class="block-line botonera">
        <button class="cancel boto-editmode" (click)="endEditmode()">
            <i class="fa fa-lg fa-times" aria-hidden="true"></i>
            {{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.ENDEDITMODE' | translate}}</button>
        <button class="add savebutton save" (click)="save()" *ngIf="formGroup.dirty && !readonly"
            [disabled]="formGroup.invalid">
            <i class="fa fa-lg fa-floppy-o" aria-hidden="true"></i>
            {{ "MAINTENANCE.ACCEPT" | translate }}
        </button>
    </div>
</div>
<div class="block-dades" *ngIf="!editMode && booking">
    <div class="block-line">
        <div class="block-box bookingNumber">
            <div class="toplabel">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.BOOKINGNUMBER' | translate}}</div><span
                class="invisible" *ngIf="!booking.bookingNumber">{{'SHARED.EXWORK.NODRIVERNAME' | translate}}</span>
            {{booking.bookingNumber}}
        </div>
        <div class="block-box dateHour">
            <div class="toplabel">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.DEPOSITSTART' | translate}}</div>
            {{booking.depositStart | datehour}}
        </div>
        <div class="block-box dateHour">
            <div class="toplabel">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.DEPOSITEND' | translate}}</div>
            {{booking.depositEnd | datehour}}
        </div>
    </div>
    <div class="block-line">
        <button class="boto-editmode link"
            (click)="startEditmode()">{{'SHARED.EXWORK.ROUTEBOOKING.COMPONENT.STARTEDITMODE' | translate}}</button>
    </div>
</div>