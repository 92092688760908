import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AddressDto } from "@shared/src/controllers/maps/AddressDto";

@Component({
    selector: "shared-assistedaddress-component",
    templateUrl: "./shared.assistedaddress.component.html",
    styleUrls: ["./shared.assistedaddress.component.scss"],
})
export class SharedAssistedAddressComponent implements OnInit {

    constructor() {
    }

    public input = null;
    ngOnInit(): void {
        this.input = document.querySelector('#autocomplete') as HTMLInputElement;
        if (this.input != null && this.input != undefined)
            this.input.setAttribute("id", this.id);
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

    @Output() public onCreate: EventEmitter<AddressDto> = new EventEmitter<AddressDto>();

    @Input() formattedAddress: string;
    @Input() placeholder: string = 'SHIPMENT.ASSISTEDADDRESS.ENTERAPLACE';

    private _id: string;
    @Input()
    set id(value: string) {
        if (this._id === value)
            return;

        this._id = value;
    }

    get id(): string {
        return this._id;
    }

    public autocomplete: google.maps.places.Autocomplete;
    public inputAutocomplete: HTMLInputElement;
    public autocompleteListener;
    initAutocomplete() {

        var address = new AddressDto;

        var createAddress = this.onCreate;

        this.inputAutocomplete = this.input as HTMLInputElement;

        if (this.inputAutocomplete != null) {
            this.autocomplete = new google.maps.places.Autocomplete(
                this.inputAutocomplete,
                {
                    fields: ['address_components', 'formatted_address', 'place_id', 'geometry', 'name']
                }
            );
        }

        this.autocompleteListener = this.autocomplete.addListener('place_changed', function () {


            if (this != null && this != undefined) {
                const place = this.getPlace();

                var address1 = "";

                if (place != null && place.address_components != null && place.address_components != undefined) {
                    console.log(place);

                    for (const component of place.address_components) {
                        const componentType = component.types[0];

                        switch (componentType) {
                            case "premise": {
                                address1 += `${component.long_name} ${address1}`;
                                break;
                            }

                            case "sublocality_level_1": {
                                address1 += `${component.long_name} ${address1}`;
                                break;
                            }

                            case "street_number": {
                                address1 += `${component.long_name} ${address1}`;
                                break;
                            }

                            case "route":
                                address1 += component.short_name;
                                break;

                            case "postal_code":
                                address.postalCode = component.long_name;
                                break;

                            case "locality":
                                address.city = component.long_name;
                                break;

                            case "administrative_area_level_2":
                                address.province = component.long_name;
                                break;

                            case "administrative_area_level_1":
                                address.state = component.long_name;
                                break;

                            case "country":
                                address.country = component.long_name;
                                break;
                        }
                    }
                    if (place.formatted_address != null) {
                        address.addressLine2 = place.formatted_address;
                        address.formattedAddress = place.formatted_address;
                    }

                    address.latitude = place.geometry.location.lat();
                    address.longitude = place.geometry.location.lng();
                }
                if (address1 != null && address1 != undefined && address1 != "")
                    address.addressLine1 = address1;
                else {
                    address.addressLine1 = place.formatted_address;
                    address.addressLine2 = "";
                }
            }
            createAddress.next(address);
            let fwindow = window as Window;
            fwindow.removeAllListeners("place_changed");
        });
    }

    public inicia() {
        this.initAutocomplete();
    }

}

declare global {
    interface Window {
        removeAllListeners: any;
    }
}