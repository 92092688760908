<div class="body centered-form-600">
    <form [formGroup]="rFormTags" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">Tags</div>

            <tags-chips name="userTags" formControlName="tags1" class="col-sm-12" [value]="tags1" placeholder="tags1">
            </tags-chips>
            <tags-chips name="userTags" formControlName="tags2" class="col-sm-12" [value]="tags2" placeholder="tags2">
            </tags-chips>
            <tags-chips name="userTags" formControlName="tags3" class="col-sm-12 slim" [value]="tags3"
                placeholder="tags3">
            </tags-chips>
            <tags-chips name="userTags" formControlName="tags4" class="col-sm-12 slim" [value]="tags4"
                placeholder="tags4">
            </tags-chips>
        </div>
    </form>
    <form [formGroup]="rFormDatePicker" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">DatePicker</div>
            <div class="maintenance-input-x">
                <div class="maintenance-input-date">
                    <date-picker [(ngModel)]="vdatefrom" [max]="vdateto" [readonly]="getReadonly()"
                        placeholder="{{'DATEFROM' | translate}}" name="dateFrom" formControlName="dateFrom">
                    </date-picker>
                </div>
                <div class="maintenance-input-date">
                    <date-picker [(ngModel)]="vdateto" [min]="vdatefrom" [readonly]="getReadonly()"
                        placeholder="{{'DATETO' | translate}}" name="dateTo" formControlName="dateTo"
                        [from]="vdatefrom">
                    </date-picker>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="rFormDateHourPicker" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">DateHour</div>
            <div class="maintenance-input-x">
                <enum-selector [(ngModel)]="Timezone" [readonly]="getReadonly()" [enumvalues]="enumTimeZones"
                    placeholder="{{'TIMEZONES.CAPTION' | translate}}" name="timezone" formControlName="timezone"
                    class="col col-x" [translategroup]="'TIMEZONES'">
                </enum-selector>
                <enum-selector [(ngModel)]="TimezoneObserver" [readonly]="getReadonly()"
                    [enumvalues]="enumTimeZonesObserver" placeholder="{{'TIMEZONES.OBSERVER.CAPTION' | translate}}"
                    name="timezoneObserver" formControlName="timezoneObserver" class="col col-x"
                    [translategroup]="'TIMEZONES'">
                </enum-selector>
            </div>
            <div class="maintenance-input-x">
                <datehour-picker class="col col-x" [(ngModel)]="vdatehourfrom" [max]="vdatehourto"
                    [readonly]="getReadonly()" placeholder="{{'DATETIMEFROM' | translate}}" name="dateHourFrom"
                    formControlName="dateHourFrom" [showLocaleObserver]=true>
                </datehour-picker>
                <datehour-picker class="col col-x" [(ngModel)]="vdatehourto" [min]="vdatehourfrom"
                    [readonly]="getReadonly()" placeholder="{{'DATETIMETO' | translate}}" name="dateHourTo"
                    formControlName="dateHourTo" [from]="vdatehourfrom" [showLocaleObserver]=true>
                </datehour-picker>
            </div>
            <div class="maintenance-input-x">
                <label-datehour [dateHour]="vdatehourfrom" placeholder="{{'DATEHOUR' | translate}}" name="labeldatehour"
                    [showLocaleObserver]=true>
                </label-datehour>
            </div>
        </div>
    </form>
    <form [formGroup]="rFormBoolean" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">Boolean</div>
            <div class="maintenance-input-x checkbox">
                <boolean-component [(ngModel)]="visdriver" [readonly]="getReadonly()"
                    placeholder="{{'ISDRIVER.CAPTION' | translate}}" helpresource="{{'ISDRIVER.HELP' | translate}}"
                    name="isdriver" formControlName="isdriver" [showHelpPoint]=true>
                </boolean-component>
            </div>
            {{ visdriver }}
        </div>
    </form>
    <form [formGroup]="rFormInput" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">Input</div>
            <div class="maintenance-input-x input">
                <input-component [(ngModel)]="vdescription" [readonly]="getReadonly()"
                    placeholder="{{'DESCRIPTION.CAPTION' | translate}}"
                    helpresource="{{'DESCRIPTION.HELP' | translate}}" name="description" formControlName="description"
                    autocomplete="on" [showHelpPoint]=true>
                </input-component>
            </div>
            {{ vdescription }}
        </div>
    </form>
    <form [formGroup]="rFormTextArea" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">TextArea</div>
            <div class="maintenance-input-x textarea">
                <textarea-component [(ngModel)]="vlargedescription" [readonly]="getReadonly()"
                    placeholder="{{'LARGEDESCRIPTION.CAPTION' | translate}}"
                    helpresource="{{'LARGEDESCRIPTION.HELP' | translate}}" name="largedescription"
                    formControlName="largedescription" [showHelpPoint]=true>
                </textarea-component>
            </div>
            {{ vlargedescription }}
        </div>
    </form>
    <form [formGroup]="rFormColor" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">ColorPicker</div>
            <div class="maintenance-input-x colorpicker">
                <color-picker [(ngModel)]="vcolordriver" [readonly]="getReadonly()"
                    placeholder="{{'COLOR.DRIVER' | translate}}" name="colordriver" formControlName="colordriver"
                    [showavatars]="true">
                </color-picker>
            </div>
            {{ getColorPicker() }}
        </div>
    </form>
    <form [formGroup]="rFormEnumSelector" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">EnumSelector</div>
            <div class="maintenance-input-x">
                <enum-selector [(ngModel)]="language" [readonly]="getReadonly()" [enumvalues]="enumLanguages"
                    placeholder="{{'USER.LANGUAGE' | translate}}" name="language" formControlName="language"
                    class="col col-x" [translategroup]="'LANGUAGE'">
                </enum-selector>
                {{ language }}
            </div>
        </div>
    </form>
    <form [formGroup]="rFormHour" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">Hour</div>
            <div class="maintenance-input-x">
                <hour-picker [(ngModel)]="vhour" [readonly]="getReadonly()" placeholder="{{'HOUR' | translate}}"
                    name="Hour" formControlName="Hour">
                </hour-picker>
            </div>
        </div>
    </form>
    <form [formGroup]="rFormHourIntervals" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">HourInterval</div>
            <div class="maintenance-input-x">
                <hourintervals-picker [(ngModel)]="vhourIntervals" [readonly]="getReadonly()"
                    placeholder="{{'HOURINTERVALS' | translate}}" name="HourIntervals" formControlName="HourIntervals">
                </hourintervals-picker>
            </div>
        </div>
    </form>

    <form [formGroup]="rFormTime" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">Time</div>
            <div class="maintenance-input-x">
                <time-picker [(ngModel)]="vtime" [readonly]="getReadonly()" placeholder="{{'TIME' | translate}}"
                    name="Time" formControlName="Time">
                </time-picker>
            </div>
        </div>
    </form>

    <form [formGroup]="rFormEntitySelector" class="formtest entitySelector">
        <div class="maintenance-internal" group>
            <div class="titletest">EntitySelector</div>
            <div class="maintenance-input-x">
                <entity-selector class="col-x" [(ngModel)]="userId" [entitySelector]="'User'"
                    placeholder="{{'USER.TITLE' | translate}}" name="userId" [showgotomaintenance]="true"
                    [showdeleteitemback]="true" formControlName="userId">
                </entity-selector>
                {{userId}}
            </div>
        </div>
    </form>

    <form [formGroup]="rFormHTriState" class="formtest">
        <div class="maintenance-internal" group>
            <div class="titletest">HTriState</div>
            <div class="maintenance-input-x">
                <tristate class="col-x" [(ngModel)]="noThermostatRunMode"
                    placeholder="{{'SHIPMENT.TEMPERATURE.CAPTION' | translate}}" name="noThermostatRunMode"
                    formControlName="noThermostatRunMode">
                </tristate>
                <tristate class="col-x inputCondition" [(ngModel)]="thermostatRunMode"
                    placeholder="{{'SHIPMENT.THERMOSTATRUNMODE.CAPTION' | translate}}"
                    preplaceholder="{{'SHIPMENT.PRETHERMOSTATRUNMODE.CAPTION' | translate}}" name="thermostatRunMode"
                    class="fridge" formControlName="thermostatRunMode">
                </tristate>
            </div>
        </div>
    </form>

</div>