import { MessageTypes } from "@shared/src/public-api";
import { SocketNotificationAction } from "./SocketNotificationAction";

export class SocketNotificationDto {
    public action: SocketNotificationAction;
    public title: string;
    public message: string;
    public userId: number;
    public interestedOn: string;
    public messageType: MessageTypes;

    public static build(action: SocketNotificationAction, userId: number): SocketNotificationDto {
        let result = new SocketNotificationDto();
        result.action = action;
        result.userId = userId;
        return result;
    }

    public static buildInterested(interestedOn: string): SocketNotificationDto {
        let result = new SocketNotificationDto();
        result.action = SocketNotificationAction.interested;
        result.interestedOn = interestedOn;
        return result;
    }
    
    public static buildMessage(interestedOn: SocketNotificationAction, title: string, message: string): SocketNotificationDto {
        let result = new SocketNotificationDto();
        result.interestedOn = interestedOn;
        result.title = title;
        result.message = message;
        return result;
    }


}