import { ChatParticipantTypes } from "./../../enums/ChatParticipantTypes";
import { HHour } from "./../../datatypes/HHour";
import { HDate } from "./../../datatypes/HDate";
import { ChatsDto } from "./ChatsDto";
import { ChatMessageDto } from "./message/ChatMessageDto";
import { ParticipantDto } from "./participant/ParticipantDto";
import {Injectable} from '@angular/core';
import { HDateHour } from "@shared/src/public-api";

@Injectable()
export class ChatDto {
	public chatId: number;
	public userId: number; // Usuari que esta veien el chat (o siga, el loguejat en principi)
	public user: ParticipantDto; // Usuari que esta veien el chat (o siga, el loguejat en principi)
	public ownerParticipantId: number; // propietari del chat (qui ha iniciat el chat o si es una Ruta, etc)
	public ownerParticipantType: ChatParticipantTypes; // propietari del chat (qui ha iniciat el chat o si es una Ruta, etc)
	public ownerParticipant: ParticipantDto; // propietari del chat (qui ha iniciat el chat)
	public targetParticipantId: number; // Amb qui s'havia iniciat el chat (Usuari, Ruta, etc)
	public targetParticipantType: ChatParticipantTypes; // Amb qui s'havia iniciat el chat (Usuari, Ruta, etc)
	public targetParticipant: ParticipantDto; // Amb qui s'havia iniciat el chat (Usuari, Ruta, etc)
	public participants: ParticipantDto[]; // Qui està dins del chat
	public lastHour: HHour;
	public lastDate: HDate;
    public lastDateHour: HDateHour;
	public chatmessages: ChatMessageDto[];
	public lastMessage: string;
	public pendingMessagesRead: number;
	public nMessagesLoaded: number;
    public tenantId: number;

	public static build(chats: ChatsDto, participant: ParticipantDto): ChatDto {
        let result = this.empty();
        if (chats != null && participant != null) {
            result.userId = chats.ownerUserId;
            result.ownerParticipantId = chats.ownerUserId;
            result.ownerParticipantType = chats.ownerUserType;
            result.targetParticipantId = participant.participantId;
            result.targetParticipantType = participant.participantType;
        }
        return result;
    }

    public static buildByIds(userId: number, ownerParticipantId: number, ownerParticipantType: ChatParticipantTypes,targetParticipantId: number, targetParticipantType: ChatParticipantTypes): ChatDto {
        let result = this.empty();
        result.userId = userId;
        result.user = ParticipantDto.buildByParams(userId, ChatParticipantTypes.User);
        result.ownerParticipantId = ownerParticipantId;
        result.ownerParticipantType = ownerParticipantType;
        result.ownerParticipant = ParticipantDto.buildByParams(ownerParticipantId, ownerParticipantType);
        result.targetParticipantId = targetParticipantId;
        result.targetParticipantType = targetParticipantType;
        result.targetParticipant = ParticipantDto.buildByParams(targetParticipantId, targetParticipantType);
       
        return result;
    }

    public static buildByOwnerAndTargetParticipant(ownerparticipant: ParticipantDto, targetparticipant: ParticipantDto): ChatDto {
        let result = this.empty();
        if (ownerparticipant != null) {
            result.ownerParticipantId = ownerparticipant.participantId;
            result.ownerParticipantType = ownerparticipant.participantType;
        }
        if (targetparticipant != null) {
            result.targetParticipantId = targetparticipant.participantId;
            result.targetParticipantType = targetparticipant.participantType;
        }
        return result;
    }

    public static empty(): ChatDto {
        let result = new ChatDto();
        result.chatId = 0;
        result.userId = 0;
        result.ownerParticipantId = 0;
        result.ownerParticipantType = ChatParticipantTypes.None;
        result.targetParticipantId = 0;
        result.targetParticipantType = ChatParticipantTypes.None;
        result.lastDate = HDate.nullDate();
        result.lastHour = new HHour(0, 0);
        result.chatmessages = new Array();
        result.pendingMessagesRead = 0;
        result.nMessagesLoaded = 0;
        result.lastMessage = "";
        return result;
    }

    public static actualizedChatByFrom(source: ChatDto, from: ChatDto) {
        if(source == null || from == null)
            return;
        source.chatId = from.chatId;
        source.userId = from.userId;
        source.ownerParticipantId = from.ownerParticipantId;
        source.ownerParticipantType = from.ownerParticipantType;
        source.targetParticipantId = from.targetParticipantId;
        source.targetParticipantType = from.targetParticipantType;
        source.user = from.user;
        source.targetParticipant = from.targetParticipant;
        source.ownerParticipant = from.ownerParticipant;
        source.lastDate = from.lastDate;
        source.lastHour = from.lastHour;
        source.chatmessages = from.chatmessages;
        source.pendingMessagesRead = from.pendingMessagesRead;
        source.nMessagesLoaded = from.nMessagesLoaded;
        source.lastMessage = from.lastMessage;
    }
}