import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'shared-iframe-item-dialog',
  templateUrl: 'shared.iframe.item.dialog.html',
  styleUrls: ['./shared.iframe.item.dialog.scss']
})
export class SharedIFrameItemDialog {

  constructor(private mdDialogRef: MatDialogRef<SharedIFrameItemDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string) { }


  ngOnInit(): void {

  }

  @ViewChild('iframe') iframe: ElementRef;

  ngAfterViewInit() {
    const nativeEl = this.iframe.nativeElement;
    if ((nativeEl.contentDocument || nativeEl.contentWindow.document).readyState === 'complete') {
      nativeEl.onload = this.onIframeLoad.bind(this);
    } else {
      if (nativeEl.addEventListener) {
        nativeEl.addEventListener('load', this.onIframeLoad.bind(this), true);
      } else if (nativeEl.attachEvent) {
        nativeEl.attachEvent('onload', this.onIframeLoad.bind(this));
      }
    }
  }
  onIframeLoad() {
    if (this.iframe.nativeElement.contentWindow.document.body != null) {
      let iframebodyimg = this.iframe.nativeElement.contentWindow.document.body.children[0];
      if (iframebodyimg != null) {
        iframebodyimg.style.width = '100%';
        iframebodyimg.style.height = '100%';
        iframebodyimg.style["object-fit"] = 'contain';
      }
    }
  }

  onClose() {
    this.mdDialogRef.close();
  }

}
