<!--directiva: shared.table.component.html!-->
<div class="grup" *ngIf="_internalvalue">
    <table class="hardman text-right table">
        <tbody>
            <tr *ngIf="showTitle && _internalvalue.showTitle">
                <th [colSpan]="_internalvalue.titleColSpan" class="table-component-title">{{value.title}}</th>
            </tr>
            <tr *ngFor="let row of _internalvalue.matrixValues; let i = index" [ngClass]="row.classes">
                <td [ngClass]="_internalvalue.matrixClass[i][j]" *ngFor="let value of row; let j = index">
                    <span class="only-Integer">{{value | enter}}</span>
                    <span class="only-not-Integer">{{value}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>