import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { MaritimeAgencyListMaintenanceDto } from './MaritimeAgencyListMaintenanceDto';
import { MaritimeAgencyItemMaintenanceDto } from './MaritimeAgencyItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';

@Injectable()
export class MaritimeAgencyMaintenanceController extends BaseMaintenanceController<MaritimeAgencyListMaintenanceDto, MaritimeAgencyItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "maritimeagency");
    }


    public getResourceRoot(): string {
        return "MARITIMEAGENCY";
    }

    public buildUrl(uri: string): string {
        return "internal/maritimeagency" + uri;
    }
}