<div class="container">
    <table>
        <tr class="">
            <td class="prompt">
                Distance to destination
            </td>
            <td class="value">
                {{distanceToDestination}} Km
            </td>
        </tr>
        <tr>
            <td class="prompt">
                Speed
            </td>
            <td class="value">
                {{speed}} Km/h
            </td>
        </tr>
        <tr>
            <td class="prompt">
                Direction
            </td>
            <td class="value">
                {{direction}} º
            </td>
        </tr>
        <tr>
            <td class="prompt">
                Temperature
            </td>
            <td class="value">
                {{temperature}} ºC
            </td>
        </tr>
        <tr class="important">
            <td class="prompt">
                ETA
            </td>
            <td class="value">
                {{eta | datehournotz}} h
            </td>
        </tr>
        <tr class="important">
            <td class="prompt">
                Due date
            </td>
            <td class="value">
                {{dueDate | datehournotz}}
            </td>
        </tr>
        <tr class="important">
            <td class="prompt">
                Status
            </td>
            <td class="value">
                On time
            </td>
        </tr>
    </table>


    <shared-shipment-item-maintenance class="noborder" [shipment]="selectedShipment">
    </shared-shipment-item-maintenance>
</div>