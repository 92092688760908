<div class="component component-fullpage register" [ngClass]="generalClass">
    <div class="encolumnat">
        <h1 class="titol">{{ 'REGISTERCOMPONENT.USER_INFO.TITLE' | translate }}</h1>
        <h2 class="texte">{{ 'REGISTERCOMPONENT.USER_INFO.SUBTITLE' | translate }}</h2>

        <form class="form" [formGroup]="registerFormGroup">
            <input-component *ngIf="showEmail" class="col-x" [(ngModel)]="registerData.userregister.eMail"
                (ngModelChange)="emailChangedEvent()" placeholder="{{'REGISTERCOMPONENT.EMAIL.LABEL' | translate}}"
                name="email" formControlName="eMail" autocomplete="on"></input-component>
            <div *ngIf="!useIonic" class="col-x">
                <enum-selector class="col-prefix-phone" [(ngModel)]="prefixModel" [readonly]="false"
                    [enumvalues]="enumPrefixes"
                    placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}" name="prefix"
                    [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
                    formControlName="prefix">
                </enum-selector>
                <input-component class="phone" [(ngModel)]="registerData.userregister.phone"
                    (ngModelChange)="phoneChangedEvent()"
                    placeholder="{{'REGISTERCOMPONENT.USER_PHONE.LABEL' | translate}}" name="phone"
                    formControlName="phone" autocomplete="on"></input-component>
            </div>
            <div *ngIf="useIonic" class="col-x">
                <enum-selector [ngClass]="{'phone-prefix-ios' : isIos}" [(ngModel)]="prefixModel" [readonly]="false"
                    [enumvalues]="enumPrefixes"
                    placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}" name="prefix"
                    [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
                    formControlName="prefix">
                </enum-selector>
                <input-component [(ngModel)]="registerData.userregister.phone" (ngModelChange)="phoneChangedEvent()"
                    placeholder="{{'REGISTERCOMPONENT.USER_PHONE.LABEL' | translate}}" name="phone"
                    formControlName="phone" autocomplete="on"></input-component>
            </div>
            <div class="col-x">
                <input-component class="password" type="passwordauto" [(ngModel)]="registerData.userregister.password"
                    placeholder="{{'REGISTERCOMPONENT.PASSWORD.LABEL' | translate}}" name="password"
                    formControlName="password" autocomplete="on"></input-component>
            </div>
            <div class="col-x">
                <input-component [(ngModel)]="registerData.userregister.fullName"
                    placeholder="{{'REGISTERCOMPONENT.USER_FULL_NAME.LABEL' | translate}}" name="given-name"
                    formControlName="fullName" autocomplete="on"></input-component>
            </div>
            <div class="col-x">
                <enum-selector [(ngModel)]="languagevalue" [readonly]="false" [enumvalues]="enumLanguages"
                    placeholder="{{'USER.LANGUAGE' | translate}}" name="language" class="language-input"
                    [translategroup]="'LANGUAGE'" [showclosebutton]="false" (ngModelChange)="languageChanged()"
                    formControlName="language">
                </enum-selector>
            </div>
            <div class="terms">
                <div class="col-sm-12 checkbox">
                    <checkbox-component [(ngModel)]="registerData.userregister.acceptProductConditions"
                        label="{{'REGISTERCOMPONENT.ACCEPT_TERMS.LABEL' | translate}}"
                        formControlName="acceptProductConditions" class="required"></checkbox-component>
                </div>
                <div class="col-sm-12 link">
                    <a href="https://hardman.app/politica-de-privacidad-de-hardman-app/" target="_blank">{{
                        'REGISTERCOMPONENT.ACCEPT_TERMS.LINK' | translate }}</a>
                </div>
                <div class="col-sm-12 link">
                    <button class="link" (click)="onLoginButtonClick()">{{'REGISTERCOMPONENT.LOGIN.LINK' |
                        translate}}</button>
                </div>
            </div>

            <div class="button-zone">
                <button mat-flat-button color="primary" class="action seguent sol"
                    [disabled]="!canRegister() || pendigResponse"
                    (click)="onClickRegisterButton()">{{'REGISTERCOMPONENT.NEXTBUTTON.LABEL' | translate}}</button>
            </div>
        </form>
        <div class="logo" (click)="goHome()">
            <img _ngcontent-uom-c345="" src="/assets/images/HardmanLogoBlauNom.png" alt="Hardman logo"
                class="logo logoambnom">
        </div>
    </div>
</div>