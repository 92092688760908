import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HomeController } from '../../../../controllers/home/home.controller';
import { HColor } from '../../../../datatypes/HColor';
import { AvatarDto } from '../../../../dtos/core/avatar/AvatarDto';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ColorPickerComponent, multi: true }
  ]
})

export class ColorPickerComponent extends ValueAccessorValidatedBase<string, HColor> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormColor = this.fb.group({
      dtpvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return HColor.validColor(value);
  }

  public rFormColor: FormGroup;

  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() public position: string = "bottom";
  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HColor> = new EventEmitter();
  @Input() public showavatars: boolean;

  public identifier = 'color-picker-' + identifier++;
  private defaultskin = "Aa"

  externalValue(value: string): HColor {
    if (!value)
      return undefined;
    let hcolor: HColor = HColor.build(value);

    if (!HColor.validColor(hcolor.web))
      return null;

    this.loadAvatar(hcolor);
    return hcolor;
  }
  internalValue(value: HColor): string {
    if (!value)
      return undefined;
    if (!HColor.validColor(value.web))
      return null;
    this.loadAvatar(value);
    return value.web;
  }

  public _skin: string;
  get skin():string{
    return this._skin;
  }
  @Input() set skin(value:string){
    if(this._skin === value){
      return;
    }
    this._skin = value;
    this.loadAvatar(this.externalValue(this.value));
  }
  
  public avatarvalue: AvatarDto;
  loadAvatar(value: HColor) {
    if(value == null)
      return;
    let skinresult = this.skin;
    if(skinresult == null || skinresult == "")
      skinresult = this.defaultskin;

    this.avatarvalue = AvatarDto.build(skinresult, HColor.build(HColor.getWhiteBlackFromColor(value.web)), value);
   
  }

  onChange(e) {
    //this.rFormColor.markAsDirty();
    this.valueChanged.next(this.externalValue(this.value));
  }

  public getColor() {
    return HColor.getWhiteBlackFromColor(this.value);
  }
  
  keyEnter(event){
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  colorPickerOpen() {
    let element = document.getElementsByClassName("open")[0];
    if (element)
      element.removeAttribute('style');
  }
}

let identifier = 0;