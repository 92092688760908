import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityController } from './security.controller';

/***
 * Es el filtre de la seguretat. Assegura al cridar-se cada opció, si està protegida per aquest que es pot accedir-hi
 */
@Injectable()
export class OwnerOrHardmanUserGuard implements CanActivate {

  constructor(@Inject('SecurityController') protected securityController: SecurityController) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Observable(observer => {
      try {
        if (this.securityController.isLogged) {
          this.securityController.isLogged().subscribe(value => {
            if (value) {
              this.securityController.isOwnerOrHardManUser().subscribe((value) => {
                observer.next(value);
                observer.complete();
              })
              return;
            } else {
              this.securityController.goToLogin();
              observer.next(false);
              observer.complete();
              return;
            }
          });
        }
      }
      catch {
        observer.next(false);
        observer.complete();
      }
    });
  }

}
