import { AfterViewInit, Component, ElementRef, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { Route_eCMRDto } from '@shared/src/dtos/route/cmr/Route_eCMRDto';
import { Route_eCMRAddressDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRAddressDto';
import { Route_eCMRIncotermDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRIncotermDto';
import { Route_eCMRPartnerDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPartnerDto';
import { Route_eCMRPaymentDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPaymentDto';
import { Incoterms, SharedAsideFactory } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-part-cmr-digitaldocument-component',
  templateUrl: './shared.route.part.cmr.digitaldocument.component.html',
  styleUrls: ['./shared.route.part.cmr.digitaldocument.component.scss'],
})
export class SharedRoutePartCMRDigitalDocumentComponent implements AfterViewInit {

  constructor(private routeCMRController: RouteCMRController,
    private openRouteCMRController: OpenRouteCMRController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
    private el: ElementRef) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };

  ngOnInit() {

  }

  public incotermNone: Incoterms = Incoterms.none;
  public useIonic: boolean = false;

  @Input()
  public showsignatures: boolean = true;
  
  @Input()
  public readonly: boolean = false;

  private _routePartCMRId: number;
  @Input()
  public set routePartCMRId(value: number) {
    if (this._routePartCMRId === value)
      return;
    this._routePartCMRId = value;
    this.loadCMR();
  }
  public get routePartCMRId(): number {
    return this._routePartCMRId;
  }

  public route_eCMR: Route_eCMRDto;
  loadCMR() {
    this.openRouteCMRController.getRoute_eCMR(this._routePartCMRId).subscribe(e => {
      if (e && e.routePartCMRId) {
        this.route_eCMR = e;
        this.wellComeDocument();
      }
      else
        this.route_eCMR = null;

      this.incotermExpanded = false;
      this.updatePayment = false;

      this.updateOriginTenant = false;
      this.updateDestinationTenant = false;

      this.updateCarrier = false;
      this.updateDriver = false;
      this.updateApplicant = false;
      this.updatePayer = false;
      this.updateOriginAddress = false;
      this.updateDestinationAddress = false;

    });
  }


  obrirDireccio(addressId: number, formattedAddress: string) {
    this.sharedAsideFactory.invokeAddress(addressId, formattedAddress);
  }


  public incotermExpanded: boolean = false;
  public updatePayment: boolean = false;

  public updateOriginTenant: boolean = false;
  public updateDestinationTenant: boolean = false;
  public updateDriver: boolean = false;
  public updateCarrier: boolean = false;
  public updateApplicant: boolean = false;
  public updatePayer: boolean = false;

  public updateOriginAddress: boolean = false;
  public updateDestinationAddress: boolean = false;

  resetIncoterm() {
    this.routeCMRController.updateIncoterm(this.routePartCMRId, Route_eCMRIncotermDto.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  incotermBlank(): boolean {
    return !this.route_eCMR || !this.route_eCMR.incoterm || this.route_eCMR.incoterm == Incoterms.none;
  }

  resetPayment() {
    this.routeCMRController.updatePayment(this.routePartCMRId, Route_eCMRPaymentDto.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  paymentBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.payment || this.route_eCMR.amount >= 0);
  }

  resetOriginTenant() {
    this.routeCMRController.updateOriginTenant(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  originTenantBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.originTenantId || this.route_eCMR.originTenantName || this.route_eCMR.originTenantNif || this.route_eCMR.originTenantContactName || this.route_eCMR.originTenantContactPhone || this.route_eCMR.originTenantFormattedAddress);
  }

  resetDestinationTenant() {
    this.routeCMRController.updateDestinationTenant(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  destinationTenantBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.destinationTenantId || this.route_eCMR.destinationTenantName || this.route_eCMR.destinationTenantNif || this.route_eCMR.destinationTenantContactName || this.route_eCMR.destinationTenantContactPhone || this.route_eCMR.destinationTenantFormattedAddress);
  }

  resetCarrier() {
    this.routeCMRController.updateCarrier(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  carrierBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.carrierName || this.route_eCMR.carrierNif || this.route_eCMR.carrierContactName || this.route_eCMR.carrierContactPhone || this.route_eCMR.carrierFormattedAddress);
  }

  resetDriver() {
    this.routeCMRController.updateDriver(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  driverBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.driverName || this.route_eCMR.driverNif || this.route_eCMR.driverPhone);
  }

  resetApplicant() {
    this.routeCMRController.updateApplicant(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  applicantBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.applicantName || this.route_eCMR.applicantNif || this.route_eCMR.applicantContactName || this.route_eCMR.applicantContactPhone || this.route_eCMR.applicantFormattedAddress);
  }

  resetPayer() {
    this.routeCMRController.updatePayer(this.routePartCMRId, Route_eCMRPartnerDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  payerBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.payerName || this.route_eCMR.payerNif || this.route_eCMR.payerContactName || this.route_eCMR.payerContactPhone || this.route_eCMR.payerFormattedAddress);
  }

  resetOriginAddress() {
    this.routeCMRController.updateOriginAddress(this.routePartCMRId, Route_eCMRAddressDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  originAddressBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.originAddressId || this.route_eCMR.originAddressKey || this.route_eCMR.originAddressName || this.route_eCMR.originFormattedAddress || this.route_eCMR.originContactPhone);
  }

  resetDestinationAddress() {
    this.routeCMRController.updateDestinationAddress(this.routePartCMRId, Route_eCMRAddressDTO.buildReset()).subscribe(e => {
      if (e) {
        this.loadCMR();
      }
    })
  }

  destinationAddressBlank(): boolean {
    return !this.route_eCMR || !(this.route_eCMR.destinationAddressId || this.route_eCMR.destinationAddressKey || this.route_eCMR.destinationAddressName || this.route_eCMR.destinationFormattedAddress || this.route_eCMR.destinationContactPhone);
  }


  public visible = false;
  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.visible = true;
          this.wellComeDocument();
        } else {
          this.visible = false;
        }
      });
    });

    observer.observe(this.el.nativeElement);
  }

  public documentDetail: DocumentDetailDto = null;
  wellComeDocument() {
    this.openRouteCMRController.getLastDocumentDetailCMR(this._routePartCMRId).subscribe(d => {
      this.documentDetail = d;
    });
  }
}
