<div class="container-group">
  <div *ngIf="data">
    <div class="grup header headers">
      <div class="value key">{{'PACKAGE.KEY.CAPTION' | translate}}</div>
      <div class="value xDimension">{{'PACKAGE.XDIMENSION.CAPTION' | translate}}</div>
      <div class="value yDimension">{{'PACKAGE.YDIMENSION.CAPTION' | translate}}</div>
      <div class="value zDimension">{{'PACKAGE.ZDIMENSION.CAPTION' | translate}}</div>
      <div class="value kg">{{'PACKAGE.KG.CAPTION' | translate}}</div>
      <div class="value button-sub-line">
        <confirmation-dialog [expanded]="askingCreateLabel" helptitleresource="ESCHENKERCOMPONENT.CREATELABELS.CAPTION"
          helpresource="ESCHENKERCOMPONENT.CREATELABELS.CONFIRM" (onConfirm)="createLabel()"
          (onCancel)="askingCreateLabel = false"></confirmation-dialog>
        <i aria-hidden="true" matTooltip="{{ 'ESCHENKERCOMPONENT.CREATELABELS.CAPTION' | translate }}"
          *ngIf="anyWithoutLabel" class=" cercle fa fa-file-text-o" (click)="askingCreateLabel = true"></i>
        <i aria-hidden="true" matTooltip="{{ 'ESCHENKERCOMPONENT.DOWNLOADLABELSBYDATE.CAPTION' | translate }}"
          class="cercle fa fa-cloud-download" (click)="downloadLabelsByDate()"></i>
      </div>
    </div>

    <shared-package-line-component *ngFor="let item of data" [routeId]="routeId" [shipmentId]="shipmentId"
      [package]="item" [readonly]="readonly" [routeId]="routeId">
    </shared-package-line-component>
    <shared-package-line-component *ngIf="!readonly" [readonly]="readonly" [routeId]="routeId"
      [ngClass]="{'shipment-package': shipmentId}" [shipmentId]="shipmentId" (packageCreated)="data.push($event)">
    </shared-package-line-component>
  </div>
</div>