import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PlateTypologyEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PlateTypologyEntitySelectorDto{
        let result: PlateTypologyEntitySelectorDto = new PlateTypologyEntitySelectorDto();
		result.loadData(EntitysSelectors.PlateTypology, "", activeFilterKeys, 0, false);
        return result;
    }

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/platetypology";
	}

	public getUrlBack(): string {
		return "/maintenance/platetypology";
	}
  
}