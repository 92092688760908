import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HardmanActivityTypes } from '@shared/src/enums/HardmanActivityTypes';
import { BillingInfoDto } from '@shared/src/dtos/billing/BillingInfoDto';
import { TenantInfoController } from '@shared/src/controllers/tenant/tenantinfo.controller';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';

@Component({
  selector: 'shared-billinginfo-component',
  templateUrl: './shared.billinginfo.component.html',
  styleUrls: ['./shared.billinginfo.component.scss']
})
export class SharedBillingInfoComponent implements OnInit {

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {

    this.billingInfoFormGroup = fb.group({
      'organizationName': ['', [Validators.maxLength(128)]],
      'addressLine1': ['', [Validators.maxLength(80)]],
      'addressLine2': ['', [Validators.maxLength(80)]],

      'vatNumber': ['', [Validators.maxLength(45)]],
      'postalCode': ['', [Validators.maxLength(20)]],

      'city': ['', [Validators.maxLength(80)]],
      'province': ['', [Validators.maxLength(45)]],
      'state': ['', [Validators.maxLength(80)]],
      'country': ['', [Validators.maxLength(80)]],
      'contactName': ['', [Validators.maxLength(80)]],

      'contactPhonePrefix': [null, [Validators.nullValidator]],
      'contactPhone': ['', [Validators.maxLength(20)]],
      'contactEmail': ['', [Validators.maxLength(40)]]
    });
    this.useIonic = homecontroller.useIonic();
    this.isIos = this.homecontroller.getOS() == 'ios';
  }

  public useIonic: boolean = false;
  public isIos: boolean = false;

  public billingInfoFormGroup: FormGroup;
  public enumCompanyActivityTypes = HardmanActivityTypes;

  @Input()
  public readonly: boolean = false;

  ngOnInit() { }

  private _billingInfo: BillingInfoDto;
  @Input() set billingInfo(value: BillingInfoDto) {
    if (this._billingInfo === value)
      return;
    this._billingInfo = value;
  }

  get billingInfo(): BillingInfoDto {
    return this._billingInfo;
  }

  @Output()
  public onValidationChange: EventEmitter<boolean> = new EventEmitter();


  ngAfterViewInit() {
    this.billingInfoFormGroup.valueChanges.subscribe((change) => {
      this.onValidationChange.emit(this.billingInfoFormGroup.valid);
    })
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }

  public enumPrefixes = PhonePrefix;
  public translategroup: string = 'PHONEPREFIX.ENUM';

  public _prefixModel: PhonePrefix = PhonePrefix.Spain_34;
  set prefixModel(value: PhonePrefix) {
    if (this._prefixModel === value)
      return;

    this._prefixModel = value;
    this.billingInfo.contactPhonePrefix = PhonePrefix[this._prefixModel];
  }
  get prefixModel(): PhonePrefix {
    return this._prefixModel;
  }

}
