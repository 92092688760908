<div *ngIf="package" class="package-body" [ngClass]="getGeneralClass()" (dragstart)="onDragStart($event)" (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)" [draggable]="isDraggable">
    
    <div class="packageKey">Bulto: {{package.packageKey}}</div>
        
    <div class="packageId">{{package.packageId}}</div>

    <div class="bottom">
        <actions class="baractions" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
    </div>
</div>