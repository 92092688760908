import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { IncidentsStructDto } from '@shared/src/dtos/incident/IncidentsStructDto';
import { IncidentDto } from '@shared/src/dtos/incident/IncidentDto';
import { IStandStillDto } from '@shared/src/dtos/incident/IStandStillDto';


@Injectable({
    providedIn: 'root',
})
export class IncidentController extends BaseController {
    private base = '/incident'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getIncidentStruct(): Observable<IncidentsStructDto> {
        return this.get(this.base + "/struct")
    }

    public getIncidentStructShipmentDocument(): Observable<IncidentsStructDto> {
        return this.get(this.base + "/struct/shipmentdocument")
    }

    public reportIncident(incident: IncidentDto): Observable<Boolean> {
        return this.postBusy(this.base + "/reportincident", incident, "reportIncident");
    }
        
    public reportIStandStill(standStill: IStandStillDto): Observable<Boolean> {
        return this.postBusy(this.base + "/reportistandstill", standStill, "reportIStandStill");
    }

    public resolvedIStandStill(standStill: IStandStillDto): Observable<Boolean> {
        return this.postBusy(this.base + "/resolvedistandstill", standStill, "resolvedIStandStill");
    }
}
