import { Pipe, PipeTransform } from "@angular/core";
import { HString } from "../datatypes/HString";
import { LanguagePoints } from "../dtos/language/LanguagePoints";

@Pipe({ name: 'sortByLanguagePoints' })
export class SortByLanguagePoints implements PipeTransform {

    transform(array: Array<any>): any[] {
        if (!array)
            return array;
        if (array.length <= 1)
            return array;  // array with only one item
        array.sort((a: any, b: any) => {
            return SortByLanguagePoints.compare(a, b);
        });
        return array;
    }


    static compare(a: LanguagePoints, b: LanguagePoints): number {
        if (a == b == null)
            return 0;
        if (a == null)
            return -1;
        if (b == null)
            return 1;

        if (a.points < b.points)
            return +1;
        else if (a.points > b.points)
            return -1;
        return HString.compare(a.language, b.language);
    }

}
