import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRShipmentDeliveryLineDto } from '@shared/src/dtos/route/cmr/RoutePartCMRShipmentDeliveryLineDto';
import { PalletTypes } from '@shared/src/enums/PalletTypes';

@Component({
    selector: 'shared-route-cmr-delivery-line',
    templateUrl: './shared.route.cmr.delivery.line.component.html',
    styleUrls: ['./shared.route.cmr.delivery.line.component.scss']
})
export class SharedRouteCMRDeliveryLineComponent implements OnInit {

    @Output() valueChange: EventEmitter<RoutePartCMRShipmentDeliveryLineDto> = new EventEmitter<RoutePartCMRShipmentDeliveryLineDto>();

    public rFormCMRDeliveryLine: FormGroup;

    @Input() public readonly: boolean;

    enumPalletTypes = PalletTypes;

    public useIonic: boolean = false;

    constructor(private fb: FormBuilder, private routeCMRController: RouteCMRController,
        @Inject("HomeController") protected homeController: HomeController,
        protected translateService: TranslateService) {
        this.rFormCMRDeliveryLine = fb.group({
            'wDimension': ['', Validators.min(0)],
            'yDimension': ['', Validators.min(0)],
            'hDimension': ['', Validators.min(0)],
            'm3': ['', Validators.min(0)],
            'quantity': ['', [Validators.nullValidator]],
            'kg': ['', Validators.min(0)],
            'palletCount': ['', [Validators.nullValidator]]
        });

        if (homeController.useIonic) this.useIonic = homeController.useIonic();
    }

    private _lineObject: RoutePartCMRShipmentDeliveryLineDto;

    public get lineObject(): RoutePartCMRShipmentDeliveryLineDto {
        return this._lineObject;
    }

    @Input()
    public set lineObject(value: RoutePartCMRShipmentDeliveryLineDto) {
        if (this._lineObject === value)
            return;
        this._lineObject = value;
        this.loadDataValues();
    }
    
    getResourcePalletType(type: PalletTypes): string{
        if(type == null || type == PalletTypes.None)
            return "";
        return this.translateService.instant("PALLETTYPES." + type.toUpperCase());
    }

    ngOnInit(): void {

    }

    private __wDimension: number;
    public get _wDimension(): number {
        return this.__wDimension;
    }
    public set _wDimension(value: number) {
        if (this.__wDimension === value)
            return;
        this.__wDimension = value;
        this.valueChangedEvent();
    }

    private __yDimension: number;
    public get _yDimension(): number {
        return this.__yDimension;
    }
    public set _yDimension(value: number) {
        if (this.__yDimension === value)
            return;
        this.__yDimension = value;
        this.valueChangedEvent();
    }
    
    private __hDimension: number;
    public get _hDimension(): number {
        return this.__hDimension;
    }
    public set _hDimension(value: number) {
        if (this.__hDimension === value)
            return;
        this.__hDimension = value;
        this.valueChangedEvent();
    }
    
    private __m3: number;
    public get _m3(): number {
        return this.__m3;
    }
    public set _m3(value: number) {
        if (this.__m3 === value)
            return;
        this.__m3 = value;
        this.valueChangedEvent();
    }
    
    private __quantity: number;
    public get _quantity(): number {
        return this.__quantity;
    }
    public set _quantity(value: number) {
        if (this.__quantity === value)
            return;
        this.__quantity = value;
        this.valueChangedEvent();
    }

    private __kg: number;
    public get _kg(): number {
        return this.__kg;
    }
    public set _kg(value: number) {
        if (this.__kg === value)
            return;
        this.__kg = value;
        this.valueChangedEvent();
    }

    private __palletCount: number;
    public get _palletCount(): number {
        return this.__palletCount;
    }
    public set _palletCount(value: number) {
        if (this.__palletCount === value)
            return;
        this.__palletCount = value;
        this.valueChangedEvent();
    }

    public valueChangedEvent() {
        if (this.readonly)
            return;
        let haschanges = this.lineObject.wDimension != this._wDimension;
        haschanges = this.lineObject.yDimension != this._yDimension || haschanges;
        haschanges = this.lineObject.hDimension != this._hDimension || haschanges;
        haschanges = this.lineObject.m3 != this._m3 || haschanges;
        haschanges = this.lineObject.quantity != this._quantity || haschanges;
        haschanges = this.lineObject.kg != this._kg || haschanges;
        haschanges = this.lineObject.palletCount != this._palletCount || haschanges;
        if(haschanges){
            this.loadFrontToObject();
            RoutePartCMRShipmentDeliveryLineDto.actualizedRestWith(this.lineObject);
            this.valueChange.next(this.lineObject);
        }
    }

    loadFrontToObject(){
        this._lineObject.wDimension = this._wDimension;
        this._lineObject.yDimension = this._yDimension;
        this._lineObject.hDimension = this._hDimension;
        this._lineObject.m3 = this._m3;
        this._lineObject.quantity = this._quantity;
        this._lineObject.kg = this._kg;
        this._lineObject.palletCount = this._palletCount;
    }
    public loadDataValues() {
        if(this.lineObject == null)
            return;
        this.__wDimension = this.lineObject.wDimension;
        this.__yDimension = this.lineObject.yDimension;
        this.__hDimension = this.lineObject.hDimension;
        this.__m3 = this.lineObject.m3;
        this.__quantity = this.lineObject.quantity;
        this.__kg = this.lineObject.kg;
        this.__palletCount = this.lineObject.palletCount;
    }
    onPutAll() {

        if (this.readonly || this.lineObject == null || this.lineObject.restLine == null)
            return;

        this.loadFrontToObject();

        RoutePartCMRShipmentDeliveryLineDto.putAll(this.lineObject);
        
        this.loadDataValues();

        this.valueChange.next(this.lineObject);
    }

    onRemoveAll() {

        if (this.readonly || this.lineObject == null || this.lineObject.restLine == null)
            return;
       
        this.loadFrontToObject();

        RoutePartCMRShipmentDeliveryLineDto.removeAll(this.lineObject);

        this.loadDataValues();

        this.valueChange.next(this.lineObject);
    }

    pretty(data) {
        return JSON.stringify(data);
    }
}
