
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class BookingEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): BookingEntitySelectorDto{
        let result: BookingEntitySelectorDto = new BookingEntitySelectorDto();
		result.loadData(EntitysSelectors.Booking, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "activity/booking";
	}

	public getUrlBack(): string {
		return "/booking";
	}
	
}