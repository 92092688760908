<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="route">
    <div *ngIf="showtitle" class="title">
        {{'SHARED.ROUTE.CHANGETAGS.COMPONENT.TITLE' | translate}}
    </div>
    <div *ngIf="showtitle" class="block-help">
        {{'SHARED.ROUTE.CHANGETAGS.COMPONENT.HELP' | translate}}
    </div>

    
    <div *ngIf="showalltags" class="col-x">
        <span class="super-mini-prompt">i</span>
        <shared-tags-strip-component [tags]="route.internalTags"></shared-tags-strip-component>
    </div>
    <div *ngIf="showalltags" class="col-x">
        <span class="super-mini-prompt">u</span>
        <shared-tags-strip-component [tags]="route.userTags"></shared-tags-strip-component>
    </div>
    <div *ngIf="showalltags" class="col-x">
        <span class="super-mini-prompt">p</span>
        <shared-tags-strip-component [tags]="route.privateTags"></shared-tags-strip-component>
    </div>
    <div class="col-x">
        <span class="super-mini-prompt">m</span>
        <tags-chips id="manualtags" [(ngModel)]="manualTags" name="manualTags"
            placeholder="{{ 'ROUTEPAGE.ITEM.EDIT.MANUALTAGS' | translate }}"
            [readonly]="readonly || (!hascarrier && !hasshipper)">
        </tags-chips>
    </div>
</div>