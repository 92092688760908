<!-- boolean.component.html!-->
<div [formGroup]="rFormBoolean">
    <div *ngIf="!useIonic">
        <div class="placeholder-top only-top">{{placeholder}}</div>
        <mat-slide-toggle [id]="identifier" [(ngModel)]="value" [checked]="value" formControlName="dtpvalidator"
            [disabled]="readonly" [color]="color" (change)="onChange($event)">
            <span class="only-not-top">{{ placeholder }}</span>
        </mat-slide-toggle>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div *ngIf="useIonic">
        <ion-toggle *ngIf="!readonly" [id]="identifier" [(ngModel)]="value" [checked]="value"
            formControlName="dtpvalidator" ngDefaultControl [color]="color" (ionChange)="onChange($event)">
        </ion-toggle>
        <ion-toggle *ngIf="readonly" [id]="identifier" [checked]="value" formControlName="dtpvalidator" ngDefaultControl
            disabled="true" [color]="color">
        </ion-toggle>
        <span class="placeholder">{{ placeholder }}</span>
    </div>
</div>