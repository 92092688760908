import { Injectable } from '@angular/core';
import { HLong } from '@shared/src/datatypes/HLong';
import { DocumentParentTypes } from '@shared/src/enums/DocumentParentTypes';

@Injectable()
export class DocumentParentObjectDto {

  	public parentType: DocumentParentTypes;
	public routeId: number;
	public shipmentId: number;
	public routePartCMRId: number;
	public managementModelRequirementResponseId: number;
	public parentKey: string;

	public static isValid(value: DocumentParentObjectDto):  boolean{
		
		if(value.parentType == null || value.parentType == DocumentParentTypes.None)
			return false;

		if(value.parentType == DocumentParentTypes.Route && HLong.isNullOrNullLong(value.routeId))
			return false;
		if(value.parentType == DocumentParentTypes.Shipment && HLong.isNullOrNullLong(value.shipmentId))
			return false;
		if(value.parentType == DocumentParentTypes.RouteShipment && (HLong.isNullOrNullLong(value.routeId) || HLong.isNullOrNullLong(value.shipmentId)))
			return false;
		if(value.parentType == DocumentParentTypes.RoutePartCMR && HLong.isNullOrNullLong(value.routePartCMRId))
			return false;
		if(value.parentType == DocumentParentTypes.ManagementModelRequirementResponseDocument && HLong.isNullOrNullLong(value.managementModelRequirementResponseId))
			return false;

		return true;
	}

	public static build(parentType: DocumentParentTypes, routeId: number, shipmentId: number, routePartCMRId: number, managementModelRequirementResponseId: number): DocumentParentObjectDto{

		let data = new DocumentParentObjectDto();
		data.parentType = parentType;
		data.routeId = routeId;
		data.shipmentId = shipmentId;
		data.routePartCMRId = routePartCMRId;
		data.managementModelRequirementResponseId = managementModelRequirementResponseId;

		if(parentType == DocumentParentTypes.Route && !HLong.isNullOrNullLong(routeId))
			data.parentKey = routeId.toString();
		else if(parentType == DocumentParentTypes.Shipment && !HLong.isNullOrNullLong(shipmentId))
			data.parentKey = shipmentId.toString();
		else if(parentType == DocumentParentTypes.RouteShipment && !HLong.isNullOrNullLong(routeId) && !HLong.isNullOrNullLong(shipmentId))
			data.parentKey = routeId.toString() + "." + shipmentId.toString();
		else if(parentType == DocumentParentTypes.RoutePartCMR && !HLong.isNullOrNullLong(routePartCMRId))
			data.parentKey = routePartCMRId.toString();
		else if(parentType == DocumentParentTypes.ManagementModelRequirementResponseDocument && !HLong.isNullOrNullLong(managementModelRequirementResponseId))
			data.parentKey = managementModelRequirementResponseId.toString();

		return data;
		
	}
}