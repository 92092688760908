import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
    selector: 'shared-exwork-phase-cmr-qr-component',
    templateUrl: './shared.exwork.phase.cmr.qr.component.html',
    styleUrls: ['./shared.exwork.phase.cmr.qr.component.scss']
})
export class SharedExworkPhaseCMRQRComponent implements OnInit {


    public useIonic: boolean = false;
    constructor(public router: Router, protected activatedRoute: ActivatedRoute,
        @Inject('HomeController') protected homeController: HomeController) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    ngOnInit(): void {
        this.getDades();
    }

    public phaseUUID: string = "";
    public getDades() {
        this.activatedRoute.params.subscribe(a => {
            this.phaseUUID = a.phaseuuid;
        });
    }  

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
