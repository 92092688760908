
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { GeneralStatus, HomeController, HString, PhaseCardDto, PhaseController, PhaseDto, ShipmentCardDto } from '@shared/src/public-api';

@Component({
    selector: 'shared-general-status-viewer',
    templateUrl: './shared.general.status.viewer.component.html',
    styleUrls: ['./shared.general.status.viewer.component.scss']
})
export class SharedGeneralStatusViewerComponent implements OnInit {

    constructor() {

    }
    public _generalStatus: GeneralStatus;
    @Input()
    public set generalStatus(value: GeneralStatus) {
        this._generalStatus = value;
        this.loadInternalProperties();
    }
    public get generalStatus(): GeneralStatus {
        return this._generalStatus;
    }

    public resourceStateCurrentStatus: string;
    loadInternalProperties() {
        if (!this._generalStatus) {
            this.resourceStateCurrentStatus = 'GENERALSTATUS.' + GeneralStatus.none.toUpperCase + "CAPTION"
            return;
        }
        this.resourceStateCurrentStatus = 'GENERALSTATUS.' + this._generalStatus.toUpperCase() + '.CAPTION';
    }
    ngOnInit(): void {
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }
}
