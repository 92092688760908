import { Pipe } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { TruckingShipmentDto } from '../dtos/trucking/TruckingShipmentDto';

@Pipe({
	name: "orderbytruckingshipmentcarddto"
})
export class OrderByTruckingShipmentDto {
	transform(array: Array<TruckingShipmentDto>, asc: boolean): Array<TruckingShipmentDto> {
		if (array == null)
			return null;

		array.sort((a: TruckingShipmentDto, b: TruckingShipmentDto) => {
			if (asc)
				return OrderByTruckingShipmentDto.compareAny(a, b);

			return OrderByTruckingShipmentDto.compareAny(b, a);
		});
		return array;
	}

	public static compareAny(a: TruckingShipmentDto, b: TruckingShipmentDto): number {

		return this.validateDateHour(a, b);
	}

	public static validateDateHour(a: TruckingShipmentDto, b: TruckingShipmentDto): number {

		let av = a.maxDateHourMinToLoadPackage;
		let bv = b.maxDateHourMinToLoadPackage;
		
		if (HDateHour.isNullOrNullValue(av) && !HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingShipmentDto.xivato(1, a, b);
		else if (!HDateHour.isNullOrNullValue(av) && HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingShipmentDto.xivato(-1, a, b);
		else if (HDateHour.isGreaterThan(av, bv))
			return OrderByTruckingShipmentDto.xivato(1, a, b);
		else if (HDateHour.isMinorThan(av, bv))
			return OrderByTruckingShipmentDto.xivato(-1, a, b);
		else
			return a.shipmentKey > b.shipmentKey ? 1 : -1;
	}

	public static xivato(valor, a: TruckingShipmentDto, b: TruckingShipmentDto) {
		return valor;
	}
}
