import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { RouteCardDto } from "@shared/src/public-api";
import { AssignedSlotDto } from "@shared/src/dtos/address/slots/AssignedSlotDto";
import { SlotInfoDto } from "@shared/src/dtos/address/slots/SlotInfoDto";
import { Router } from "@angular/router";

@Component({
    selector: 'shared-route-division-info',
    templateUrl: './shared.route.division.info.component.html',
    styleUrls: ['./shared.route.division.info.component.scss']
})
export class SharedRouteDivisionInfoComponent implements OnInit {

    public readonly: boolean = false;

    constructor(public routeController: RouteController,
        @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;

        this._route = value;
        this.loadData();
    }
    public get route(): RouteCardDto {
        return this._route;
    }

    public numChilds: number = 1;

    ngOnInit(): void {
    }

    @Input() deletemode: boolean;

    @Output() onDelete: EventEmitter<RouteCardDto> = new EventEmitter();

    delete() {
        this.onDelete.next();
    }

    public slotInfo: SlotInfoDto;
    loadData() {
        this.slotInfo = SlotInfoDto.buildFromRouteCardDto(this._route);
    }
    public useIonic: boolean = false;

    public onARClick() {
        if (this.route)
            this.homeController.goToActiveRoutes(this.route.routeId);
    }
    public onASClick() {
        if (this.route)
            this.homeController.goToActiveSlots(this.route.routeId);

    }
}
