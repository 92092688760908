import { Component, Input, Inject } from '@angular/core';
import { AlertService } from '@shared/src/public-api';


@Component({
  selector: 'shared-copyclipboard',
  templateUrl: './shared.copyclipboard.html',
  styleUrls: ['./shared.copyclipboard.scss'],
})
export class SharedCopyClipBoard {


  constructor(@Inject('AlertService') protected alertService: AlertService) {

  }

  @Input() public value: string = "";
  copy() {
    if (this.value != null && this.value != "")
      this.alertService.copyToClipboard(this.value).subscribe(e => { });
  }

  OnInit() {
  }


  public pretty(value) {
    return JSON.stringify(value);
  }
}
