import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AlertService, PhaseController, SharedAsideFactory } from '@shared/src/public-api';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';
import { PhaseChangeDatesDto } from '@shared/src/dtos/phase/PhaseChangeDatesDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'shared-phase-changedates-item',
    templateUrl: './shared.phase.changedates.item.html',
    styleUrls: ['./shared.phase.changedates.item.scss']
})
export class SharedPhaseChangeDatesItem implements OnInit {

    rForm: FormGroup;
    constructor(public phaseController: PhaseController,
        @Inject('AlertService') public alertService: AlertService,
        @Inject('SharedAsideFactory')
        public sharedAsideFactory: SharedAsideFactory,
        private fb: FormBuilder) {
        this.rForm = this.fb.group({
            'arrivedAt': ['', Validators.nullValidator],
            'completedAt': ['', Validators.nullValidator],
        });
    }

    ngOnInit(): void {
    }


    public __phase: PhaseCardItemDto;
    @Input()
    public set phase(value: PhaseCardItemDto) {
        if (this.__phase == value)
            return;
        this.__phase = value;
    }
    public get phase(): PhaseCardItemDto {
        return this.__phase;
    }

    public save() {

        if (this.phase == null)
            return;

        this.phaseController.changeDates(this.phase.phaseId, PhaseChangeDatesDto.build(this.phase.arrivedAt, this.phase.completedAt)).subscribe(e => {
            if (e) {
                this.onRefreshRoute();
                if (this.rForm != null) {
                    this.rForm.markAsPristine();
                    this.rForm.markAsUntouched();
                }
            }
        })
    }

    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();

    onRefreshRoute() {
        this.refreshRoute.next(true);
    }

    public getsavebuttonclass(): string {
        let result = "";
        if (this.rForm != null) {
            if (this.rForm.dirty)
                result += " dirty";
            if (this.rForm.valid)
                result += " valid";
        }
        return result;
    }

    public getDisabled(): boolean {
        if (this.rForm != null)
            if (!this.rForm.dirty || !this.rForm.valid)
                return true;
        return false;
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
