<div *ngIf="allLoaded" class="container" [ngClass]="{'ionic': useIonic,  'selectedroute' : routeId > 0 }">
    <div class="cards list">
        <mat-expansion-panel (keydown.Space)="espai($event)">
            <mat-expansion-panel-header (keydown.Space)="espai($event)">
                <mat-panel-title (keydown.Space)="espai($event)">
                    <div class="esquerra">
                        <div class="title">
                            {{'DESKTOP.ROUTE.PAGE.COLUMN.ROUTES.TITLE' | translate}}
                            <span class="count">{{filterMetadata.count}}/{{routesCount}}</span>
                        </div>
                    </div>
                    <div class="centre">
                        <div *ngIf="addressAmbit!=null && addressAmbit.address!=null" class="address boleta"
                            [ngStyle]="addressAmbit.address.style">
                            <div class="inner-boleta">
                                {{addressAmbit.address.skin}}
                            </div>
                        </div>
                        <div *ngIf="addressAmbit!=null && addressAmbit.ambit!=null" class="ambit boleta"
                            [ngStyle]="addressAmbit.ambit.style">
                            <div class="inner-boleta">
                                {{addressAmbit.ambit.skin}}
                            </div>
                        </div>
                        <shared-filter class="extrapadding" [filterstrip]="filter" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterRouteTypes" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterPeriod" [showonlyactives]="true"
                            [readonly]="true"></shared-filter>
                    </div>
                    <div class="dreta">
                        <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text
                            [(ngModel)]="searchText" (ngModelChange)="this.searchTextUpdate.next($event)"
                            (focus)="onSearchFocus($event)" (click)="onSearchClick($event)"
                            (keypress)="keyPress($event)">
                    </div>
                    
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-body>
                <div class="expansion-body">
                    <div class="filter">
                        <shared-address-ambit-selector-component [(value)]="addressAmbit"
                            (changeValues)="onChangeAddressAmbitSelector($event)">
                        </shared-address-ambit-selector-component>
                        <shared-filter class="extrapadding" [filterstrip]="filter" (change)="filterUpdate()">
                        </shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterRouteTypes" (change)="filterUpdate()">
                        </shared-filter>
                        <shared-filter class="extrapadding" [filterstrip]="filterPeriod" (change)="filterUpdate()">
                        </shared-filter>
                    </div>
                </div>
            </mat-expansion-panel-body>
        </mat-expansion-panel>
        <div class="page">
            <div class="column">
                <div class="llista" [ngClass]="{'selectedRoute':selectedRoute}">
                    <div class="overflow">

                        <shared-route-item-list
                            *ngFor="let item of routesExactKey | filterActiveRoutes:searchText2:filterMetadataExactKey | orderbyroutecarddto:true; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId, 'divided-childs': item.isDivided && item.childsCount > 0}"
                            [route]="item" [isActiveRoutes]="'true'" [selectedRoute]="selectedRoute"
                            (click)="onClickItem(item)">
                        </shared-route-item-list>

                        <shared-route-item-list
                            *ngFor="let item of getRoutes() | filterActiveRoutes:searchText2:filterMetadata | filterRemoveRepeat:routesExactKey:'routeId' | orderbyroutecarddto:true | slice:0:sliceCount; let i=index"
                            class="routeItem"
                            [ngClass]="{'selected':selectedRoute!=null && item.routeId==selectedRoute.routeId, 'divided-childs': item.isDivided && item.childsCount > 0}"
                            [route]="item" [isActiveRoutes]="'true'" (click)="onClickItem(item)">
                        </shared-route-item-list>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <shared-documentaryreview-route-item class="cards one activeroutes" [routeId]="routeId" [showbuttonback]="true" (clickBack)="onClickBack($event)"
        [fromActiveRoutes]="true">
    </shared-documentaryreview-route-item>
</div>