<shared-route-item class="routeItem"
        [ngClass]="{'selected':selectedRoute!=null && route.routeId==selectedRoute.routeId, 'divided-childs': route.isDivided && route.childs != null}"
        [route]="route" [ultim]="ultim" [isActiveRoutes]="isActiveRoutes" (click)="onClickItem(route)"
        (clickShowChilds)="onClickShowChilds($event)">
</shared-route-item>
<div *ngIf="route && route.showChilds">
        <shared-route-item *ngFor="let itemChild of pendingChilds" class="routeItem child"
                [ngClass]="{'selected':selectedRoute!=null && itemChild.routeId==selectedRoute.routeId}"
                [route]="itemChild" [isActiveRoutes]="isActiveRoutes" (click)="onClickItem(itemChild)">
        </shared-route-item>
</div>