<div class="container" *ngIf="route">
        <div *ngIf="viewmode != 'info'">
                <span class="help" [innerHTML]="help | translate"></span>

                <span class="stitle"> {{'ROUTEPAGE.ITEM.DIVIDED.CHILDOFDIVISIONEXPLANATION' | translate: {value:
                        activeChildsCount } }} </span>
                <div class="childsStatus">
                        <span class="stitle"> {{'ROUTEPAGE.ITEM.DIVIDED.CHILD.DONE' | translate: {value: childsDone} }}
                        </span>
                        <span class="stitle"> {{'ROUTEPAGE.ITEM.DIVIDED.CHILD.INPROGRESS' | translate: {value:
                                childsInprogress} }} </span>
                        <span class="stitle"> {{'ROUTEPAGE.ITEM.DIVIDED.CHILD.PROMISED' | translate: {value:
                                childsPromised} }} </span>
                </div>

                <button class="action" [ngClass]="{'show': viewmode != 'delete'}" [disabled]="readonly"
                        (click)="onClickButton()">
                        {{ buttontext | translate}}
                </button>

                <shared-route-division-info *ngFor="let itemChild of activeChilds" [route]=itemChild
                        [deletemode]="viewmode=='delete'" (onDelete)="onDeleteChildOfDivision(itemChild)">
                </shared-route-division-info>
        </div>
        <div *ngIf="viewmode == 'info'">
                <shared-route-item *ngFor="let itemChild of route.childs" class="routeItem child" [route]="itemChild"
                        [isActiveRoutes]="isActiveRoutes">
                </shared-route-item>
        </div>
</div>