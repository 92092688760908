<!-- time-picker.component.html!-->
<div [formGroup]="rFormTime" style="width:100%">
    <div *ngIf="!useIonic">
        <mat-form-field [ngClass]="getClass()">
            <input matInput [id]="identifier" formControlName="timevalidator" [(ngModel)]="value"
                [placeholder]="placeholder" type="text" [readonly]="readonly" (change)="onChange($event)"
                (keydown.enter)="keyEnter($event)">
            <button mat-button *ngIf="showClearButton()" matSuffix mat-icon-button aria-label="Clear"
                (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div *ngIf="useIonic" class="time-ionic">
        <ion-input ionInput [ngClass]="{'error': !rFormTime.valid && !rFormTime.pristine}" [id]="identifier"
            formControlName="timevalidator" ngDefaultControl [(ngModel)]="value" [placeholder]="placeholder" type="text"
            [readonly]="readonly" (change)="onChange($event)">
        </ion-input>
        <i *ngIf="showClearButton()" aria-label="Clear" (click)="value=''" class="ionicicona fa fa-remove"></i>
    </div>
</div>