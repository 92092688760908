import { Component, Inject, Input, OnInit } from "@angular/core";
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HColor } from "@shared/src/datatypes/HColor";
import { HString } from "@shared/src/datatypes/HString";
import { HTag } from "@shared/src/datatypes/HTag";
import { HTags } from "@shared/src/datatypes/HTags";
import { AlertService } from "@shared/src/public-api";

@Component({
  selector: 'shared-tags-strip-component',
  templateUrl: "./shared.tags.strip.component.html",
  styleUrls: ["./shared.tags.strip.component.scss"],
})
/***
 * Component de tags. Bàsicament és una llista un strip de buttons per a poder escollir els tags que vols. Només funciona amb tags amb univers
 */
export class SharedTagsStripComponent implements OnInit {

  constructor(@Inject("HomeController") private homeController: HomeController,
    @Inject('AlertService') public alertService: AlertService
  ) {

  }

  ngOnInit(): void {

  }

  hiHaTags: boolean = false;
  calculateHiHaRutes() {
    this.hiHaTags = false;
    if (this.tagsString != null && this.tagsString.length > 0)
      this.hiHaTags = true;
    if (this.__uniqueString != null && this.__uniqueString != "")
      this.hiHaTags = true;
    if (this.__tagsArray != null && this.__tagsArray.length > 0)
      this.hiHaTags = true;
    if (this.__tags != null && this.__tags.isEmpty != null && !this.__tags.isEmpty())
      this.hiHaTags = true;


  }

  @Input() public maxTags: number;

  @Input()
  public tagsString: Array<string> = new Array<string>();

  @Input()
  public applyColor: boolean = true;

  private __uniqueString = "";
  @Input()
  public set uniqueString(value: string) {
    if (this.__uniqueString === value)
      return;
    this.__uniqueString = value;
    this.tagsString = new Array<string>();
    if (this.__uniqueString != null)
      this.tagsString.push(this.__uniqueString);
    this.calculateHiHaRutes();
  }
  public get uniqueString(): string {
    return this.__uniqueString;
  }

  private __tagsArray = new Array<HTag>();
  @Input()
  public set tagsArray(value: Array<HTag>) {
    if (this.__tagsArray === value)
      return;
    this.__tagsArray = value;
    this.calculateHiHaRutes();
  }
  public get tagsArray(): Array<HTag> {
    return this.__tagsArray;
  }

  private __tags = new HTags();
  @Input()
  public set tags(value: HTags) {
    if (this.__tags === value)
      return;
    this.__tags = value;
    this.convertTagsToTagsArray();
    this.calculateHiHaRutes();
  }
  public get tags(): HTags {
    return this.__tags;
  }

  public getStyle(tag: string) {
    if (!this.applyColor)
      return { 'color': '#ffffff', 'background-color': '#666666' };

    if (HString.isNullOrNullString(tag))
      return { 'color': '#ffffff', 'background-color': '#666666' };

    return { 'color': this.getTagColor(tag), 'background-color': this.getTagBackColor(tag) };
  }

  convertTagsToTagsArray() {
    if (this.__tags == null) {
      this.tagsString = new Array<string>();
      return;
    }
    this.tagsString = HTags.getTags(this.__tags);
  }

  getTagColor(tag: string): string {
    return HColor.getWhiteBlackFromColor(this.getTagBackColor(tag));
  }
  getTagBackColor(tag: string): string {
    return HColor.getColorFromStringTable(tag, this.homeController);
  }

  copy(value: string) {
    if (value != null && value != "")
      this.alertService.copyToClipboard(value).subscribe(e => { });
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
