import { Component, Inject, Input, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { QRCodeController } from '@shared/src/controllers/qrcode/qrcode.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { HDateHour, HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-route-part-cmr-card',
    templateUrl: './shared.route.part.cmr.card.component.html',
    styleUrls: ['./shared.route.part.cmr.card.component.scss']
})
export class SharedRoutePartCMRCardComponent implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController, protected qrCodeController: QRCodeController) {

    }

    private _routePartCMR: RoutePartCMRDto;
    @Input()
    public set routePartCMR(value: RoutePartCMRDto) {
        if (this._routePartCMR === value)
            return;
        this._routePartCMR = value;
        if (this._routePartCMR && this._routePartCMR.routePartCMRId)
            this.loadQR();
        else
            this.formatDocumentQRCode = null;
    }
    public get routePartCMR(): RoutePartCMRDto {
        return this._routePartCMR
    }


    ngOnInit(): void { }

    dateIsNull(d: HDateHour): Boolean {
        return HDateHour.isNullOrNullValue(d);
    }

    datesAreEqual(a: HDateHour, b: HDateHour): boolean {
        return HDateHour.equals(a, b);
    }


    pretty(val: any): string {
        return JSON.stringify(val);
    }

    onClickQR() {
        if (this.routePartCMR != null && !HString.isNullOrNullString(this.routePartCMR.uuidQR))
            this.homeController.routeToExworkCMRUUIDQR(this.routePartCMR.uuidQR);
    }

    public formatDocumentQRCode: string;
    loadQR() {
        if (this.routePartCMR != null) {
            this.qrCodeController.getQRCodeCMRByRoutePartCMRId(this.routePartCMR.routePartCMRId).subscribe(data => {
                if (data != null && data.fileBytes != null)
                    this.formatDocumentQRCode = 'data:image/' + data.fileType + ';base64,' + data.fileBytes
            });
        }
    }
}
