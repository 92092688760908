
<div *ngIf="value" class="requirement-item" [ngClass]="{'ionic' : useIonic}">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="item-title">
                    <div *ngIf="showorder" class="requirement-order" [ngClass]="{'ok': value.requirementResponseStatus == 'DocumentOk', 'ko': value.requirementResponseStatus == 'DocumentKo'}">{{ order }}</div>
                    <div class="top-left">
                        {{ value.managementModelRequirementKey }}  {{ value.managementModelRequirementName }} <span class="numdocuments">{{ 'DOCUMENTREVIEW.NUMDOCUMENTS' | translate:{value: value.numDocuments} }}</span>
                    </div>  
                    <div class="top-center">
                        <button class="link send-claim" [ngClass]="{'hide': !value.canSendDocumentationClaimMessages}" (click)="onClickActionStringEvent('SendDocumentationClaimMessages', value.canSendDocumentationClaimMessages, $event)">{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTCLAIM.CAPTION' | translate }} </button>
                    </div>
                    <div class="top-right">
                        <button class="link chat" (click)="openChat($event)">{{ 'MANAGEMENTMODELREQUIREMENT.CHAT.CAPTION' | translate }} </button>
                        <actions *ngIf="!readonly" [actionstrip]="actions" (click)="onClickEvent($event)" (clickAction)="onClickAction($event)"></actions>
                    </div>
                    <div class="required" [ngClass]="{ 'pending' : (value.requirementResponseStatus == 'PendingUpload' || value.requirementResponseStatus == 'DocumentKo') && value.isRequiredDocument}"><i class="fa fa-exclamation-triangle"  matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.DOCUMENTREQUIREDPENDING' | translate }}"></i></div>         
                        
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <div class="edit-requirement">
                <div class="center-line">
                    <div class="text-documentko" [ngClass]="{ 'show' : value.requirementResponseStatus == 'DocumentKo'}">{{ 'MANAGEMENTMODELREQUIREMENT.DOCUMENTKO.LABEL' | translate }}</div>         
                    <div class="responsible-person" [ngClass]="{'hide': !value.canSendDocumentationClaimMessages}">{{ 'MANAGEMENTMODELREQUIREMENT.RESPONSIBLEPERSON.LABEL' | translate }} {{ value.responsiblePersonKey }} </div>
                    <div class="document-list">
                        <div *ngIf="!existsDocument()" class="nodocuments" for="input-file">
                            
                            <div *ngIf="value.canDocumentAutoCreate" class="icon-action-document big" (click)="onClickActionString('DocumentAutoCreate', value.canDocumentAutoCreate)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTAUTOCREATE.CAPTION' | translate }}"><i class="fa fa-file-text"></i></div>
                            <div *ngIf="value.canDocumentUpLoad" class="icon-action-document big" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTUPLOAD.CAPTION' | translate }}">
                                <div class="input-visual">
                                    <i class="fa fa-cloud-upload"></i>
                                </div>
                                <input *ngIf="value.canDocumentUpLoad" multiple type="file" id="input-file"
                                (change)="handleDocumentUpload($event.target.files)">
                            </div>
                            <div class="nodocuments-text">{{ 'MANAGEMENTMODELREQUIREMENT.DOCUMENTS.HISTORIC.NODOCUMENTS' | translate }} </div>
                        </div>
                        <shared-documentaryreview-documents-component [doLoadDocuments]="false" [parentObject]="value.parentObject" [documents]="value.documents" [readonly]="true">
                        </shared-documentaryreview-documents-component>
                    </div>
                </div>
                <div class="bottom-line">
                    <div class="icon-action-document" [ngClass]="{ 'enabled': value.canDocumentView }" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTVIEW.CAPTION' | translate }}">
                        <shared-opendocument-component  #openlastdocument class="open-document" [document]="value.lastManagementModelRequirementDocument" [readonly]="!value.canDocumentView"></shared-opendocument-component>
                    </div>
                    
                    <div class="icon-action-document"  [ngClass]="{ 'enabled': value.canDocumentUpLoad }" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTUPLOAD.CAPTION' | translate }}" for="inputlastfile">
                        <div class="input-visual" [ngClass]="{ 'not-upload': !value.canDocumentUpLoad }">
                            <i class="fa fa-cloud-upload"></i>
                        </div>
                        <input *ngIf="value.canDocumentUpLoad" multiple type="file" id="inputlastfile"
                        (change)="handleDocumentUpload($event.target.files)">
                    </div>

                    <div class="icon-action-document enabled" *ngIf="value.canDocumentDownLoad" (click)="onClickActionString('DocumentDownLoad', value.canDocumentDownLoad)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTDOWNLOAD.CAPTION' | translate }}"><i class="fa fa-cloud-download"></i></div>
                    <div class="icon-action-document checkko enabled" *ngIf="value.canDocumentKO" (click)="onShowCommentsClicked('DocumentKO', value.canDocumentKO)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTKO.CAPTION' | translate }}"><i class="fa fa-thumbs-down"></i></div>
                    <div class="icon-action-document checkok enabled" *ngIf="value.canDocumentOK" (click)="onClickActionString('DocumentOK', value.canDocumentOK)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTOK.CAPTION' | translate }}"><i class="fa fa-thumbs-up"></i></div>
                    <div class="icon-action-document enabled enabled" *ngIf="value.canDocumentAutoCreate" (click)="onClickActionString('DocumentAutoCreate', value.canDocumentAutoCreate)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTAUTOCREATE.CAPTION' | translate }}"><i class="fa fa-file-text"></i></div>
                    <div class="icon-action-document enabled" *ngIf="value.canDocumentActualize" (click)="onClickActionString('DocumentActualize', value.canDocumentActualize)" matTooltip="{{ 'MANAGEMENTMODELREQUIREMENT.OPTION.DOCUMENTACTUALIZE.CAPTION' | translate }}"><i class="fa fa-refresh"></i></div>
                
                    <div class="info-right">
                        <div class="icon-show-log" *ngIf="value.documents && value.documents.length > 0" (click)="onShowLogsClicked()" matTooltip="{{ 'DOCUMENTARYREVIEW.LOG.ITEM.HELP' | translate }}"><i class="fa fa-history"></i></div>
                        <div class="info-last-changed" [ngClass]="{'hide' : hideLastChanged()}">{{ value.lastMoment | datehournotz }} {{ value.lastUserName }}</div>
                    </div>
                    <div class="backgroundmask" *ngIf="showOpenComments" (click)="showOpenComments=false" [ngClass]="{'hidden' : !showOpenComments}"></div>
                    <div class="input-comments" *ngIf="showOpenComments">
                        <span class="input-comments-title ellipsis">{{ commentsTitle | translate }}</span>
                        <div class="input-comments-zone">

                            <textarea-component class="textarea" [(ngModel)]="comments"
                                placeholder="{{'MANAGEMENTMODELREQUIREMENT.COMMENTS.CAPTION' | translate}}" name="comments" autocomplete="off">
                            </textarea-component>
                        </div>
                        <div class="button-comments-zone">
                            <button class="action" (click)="onCommentExecuteActionClicked()">{{ 'MANAGEMENTMODELREQUIREMENT.BUTTONOK.LABEL' | translate }}</button>
                        </div>
                        <i class="tancar-comments fa fa-times-circle" aria-hidden="true" (click)="showOpenComments=false"></i>
                    </div>

                    <div class="backgroundmask" *ngIf="showOpenLogs" (click)="showOpenLogs=false" [ngClass]="{'hidden' : !showOpenLogs}"></div>
                    <div class="input-logs" *ngIf="showOpenLogs">
                        <span class="input-logs-title ellipsis">{{ 'DOCUMENTARYREVIEW.LOG.ITEM.CAPTION' | translate }}</span>
                        <div class="input-logs-zone">

                            <shared-managementmodel-requirements-log-component [logs]="logs"></shared-managementmodel-requirements-log-component>

                        </div>
                        <i class="tancar-logs fa fa-times-circle" aria-hidden="true" (click)="showOpenLogs=false"></i>
                    </div>
                </div>

            </div>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</div>