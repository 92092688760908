<div *ngIf="shipmentId">
    <div class="bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    </div>
    <shared-shipment-cmr-edit-additionaldata-component *ngIf="selectedOptionId == 'CMRADDITIONALDATA'" [shipmentId]="shipmentId">
    </shared-shipment-cmr-edit-additionaldata-component>
    <shared-shipment-cmr-edit-events-component *ngIf="selectedOptionId == 'CMREVENTS'" [shipmentId]="shipmentId">
    </shared-shipment-cmr-edit-events-component>
    
</div>