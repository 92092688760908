import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HTags } from '@shared/src/datatypes/HTags';


/**
 * Validador de temps, permet valors amb format ####:##
 */

@Directive({
  selector: '[validateTags][ngModel],[validateTags][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TagsValidator), multi: true }
  ]
})
export class TagsValidator implements Validator {

  public constructor() { }

  public static validate(control: AbstractControl): ValidationErrors | null {
    return TagsValidator.validateControl(control);
  }

  public static validateControl(control: AbstractControl): ValidationErrors | null {
    if (!control)
      return TagsValidator.getInvalidResult();
    let value = control.value;
    if (!TagsValidator.isValid(value))
      return TagsValidator.getInvalidResult();
    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    if (value instanceof HTags)
      return true;

    return true;
  }


  public validate(c: AbstractControl): ValidationErrors | null {
    return TagsValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateTags: false
    };
  }
}