import { Injectable } from '@angular/core';
import { Camera, CameraOptions, EncodingType } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(public camera: Camera, public file: File) {

  }

  public takeAPhoto(photoType: EncodingType): Promise<any[]>{

    const res = new Promise<any[]>(resolve => {

        const options: CameraOptions = {
          quality: 50,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: photoType,
          mediaType: this.camera.MediaType.PICTURE,
          correctOrientation: true,
          sourceType: this.camera.PictureSourceType.CAMERA
        };

        this.camera.getPicture(options).then((imageDataURI) => {
          resolve(imageDataURI);
          
        }, (err) => {
          // Handle error
          console.log('function.takeAPhoto.copyFile.err: ' + err);
        });

    });
    return res;
  }

}