import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'helppoint-dialog',
  templateUrl: './helppointdialog.component.html'
})
export class HelpPointDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<HelpPointDialogComponent>,
    private mdDialog: MatDialog) { }


  onClose() {
    this.mdDialog.closeAll();
  }
}