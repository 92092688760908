import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';
import { RouteCardDto } from "@shared/src/public-api";

@Component({
    selector: 'shared-route-item-withchilds',
    templateUrl: './shared.route.item.withchilds.component.html',
    styleUrls: ['./shared.route.item.withchilds.component.scss']
})
export class SharedRouteItemWithChildsComponent implements OnInit {

    public readonly: boolean = false;

    constructor(public routeController: RouteController, @Inject('HomeController') public homeController: HomeController, @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;
            
        this._route = value;
        this.setPendingChilds();
    }
    public get route(): RouteCardDto {
        return this._route;
    }
    
    @Input()
    public selectedRoute: RouteCardDto;

    @Input()
    public isActiveRoutes: boolean = false;
    
    @Input()
    public ultim: boolean = false;
    
    @Output() public clickShowChilds: EventEmitter<RouteCardDto> = new EventEmitter<RouteCardDto>();
    onClickShowChilds(showchilds: boolean){
        if(this.route){
            this.route.showChilds = showchilds;
            this.clickShowChilds.next(this.route);
        }
    }

    @Output() public clickItem: EventEmitter<any> = new EventEmitter<any>();
    onClickItem(item: RouteCardDto){
        this.clickItem.next(item);
    }

    ngOnInit(): void {
    }

    public pendingChilds: Array<RouteCardDto>;
    setPendingChilds() {
        let _pendingChilds = new Array<RouteCardDto>();
        if(this.route != null && this.route.childs != null){
            this.route.childs.forEach(element => {
                if(element.generalStatus == GeneralStatus.promised || element.generalStatus == GeneralStatus.inprogress)
                    _pendingChilds.push(element);
            });
        }
        this.pendingChilds = _pendingChilds;
    }

}
