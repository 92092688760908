import { Injectable } from '@angular/core';
import { Incoterms } from '@shared/src/public-api';

@Injectable()
export class Route_eCMRIncotermDto {
	public incoterm: Incoterms;
	public reset: boolean;

	public static build(incotermParam: Incoterms): Route_eCMRIncotermDto {
		let result: Route_eCMRIncotermDto = new Route_eCMRIncotermDto();
		result.incoterm = incotermParam;
		return result;
	}

	public static buildReset(): Route_eCMRIncotermDto {
		let result: Route_eCMRIncotermDto = new Route_eCMRIncotermDto();
		result.reset = true;
		return result;
	}
}