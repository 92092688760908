<ng-container *ngIf="value">
    <div class="container-table grup" [ngClass]="{'ionic' : useIonic}">
        <div class="contingut">
            <table class="hardman">
                <tr class="dark">
                    <th class="header left">{{'GENERAL.MOMENT' | translate}}</th>
                    <th class="header left">{{'GENERAL.USER' | translate}}</th>
                    <th class="header left">{{'GENERAL.ISVALID' | translate}}</th>
                </tr>
                <tr *ngFor="let item of value | sortBy:'-signatureMoment'" [ngClass]="item.action">
                    <td class="signatureMoment">
                        <datehour-viewer [value]="item.signatureMoment" [showOriginal]="false" [showTimeZone]="false">
                        </datehour-viewer>
                    </td>
                    <td class="message" matTooltip="{{item.userIdentification}}">{{ item.userContact }}</td>
                    <td class="message"><span
                            *ngIf="!item.isValidSign">{{'SHARED.SIGNATURE.COMPONENT.INVALID.TITLE'|translate}}</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</ng-container>