import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LoadConsoleListMaintenanceDto } from './LoadConsoleListMaintenanceDto';
import { LoadConsoleItemMaintenanceDto } from './LoadConsoleItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { Observable } from 'rxjs';


@Injectable()
export class LoadConsoleMaintenanceController extends
    BaseMaintenanceController<LoadConsoleListMaintenanceDto, LoadConsoleItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "loadconsole");
    }


    public getResourceRoot(): string {
        return "LOADCONSOLE";
    }

    public buildUrl(uri: string): string {
        return "/system/screensandforms/loadconsolesetting" + uri;
    }

    public getLoadConsolesUser(): Observable<Array<LoadConsoleListMaintenanceDto>> {
        return this.get(this.getBaseUrl() + "/loadconsolesuser");
    }

}
