import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRAdditionalDataDto } from '@shared/src/dtos/route/cmr/RoutePartCMRAdditionalDataDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-part-cmr-edit-additionaldata-component',
  templateUrl: './shared.route.part.cmr.edit.additionaldata.component.html',
  styleUrls: ['./shared.route.part.cmr.edit.additionaldata.component.scss'],
})
export class SharedRoutePartCMREditAdditionalDataComponent {

  constructor(private fb: FormBuilder,private routeCMRController: RouteCMRController,
    @Inject('HomeController') protected homeController: HomeController) {
    
      if (homeController.useIonic) 
        this.useIonic = homeController.useIonic();

      this.formGroup = fb.group({
        'handlingAndTransportation': ['', Validators.maxLength(512)],
        'handlingAndTransportationSubstituteShipments': ['', Validators.nullValidator],
        'applicant': ['', Validators.maxLength(512)],
        'payment': ['', Validators.maxLength(512)],
        'payer': ['', Validators.maxLength(512)],
        'amount': ['', Validators.min(0)],
        'conditions': ['', Validators.maxLength(512)],
        'conditionsSubstituteShipments': ['', Validators.nullValidator],
      });
  };

  public formGroup: FormGroup;

  public useIonic: boolean = false;

  @Input() public readonly: boolean;

  private _routePartCMRId: number;
  @Input()
  public set routePartCMRId(value: number) {
      if (this._routePartCMRId === value)
          return;
      this._routePartCMRId = value;
      this.loadAdditionalData();    
  }
  public get routePartCMRId(): number {
      return this._routePartCMRId;
  }

  public loadAdditionalData(){

    if(HLong.isNullOrNullLong(this.routePartCMRId)){
      this.value = null;
      return;
    }

    this.routeCMRController.getRoutePartCMRAdditionalData(this.routePartCMRId).subscribe(data => {
		  if (data) {
			  this.value = data;
		  }else{
        this.value = null;
      }
		});

  }
  
  private _value: RoutePartCMRAdditionalDataDto;
  set value(value: RoutePartCMRAdditionalDataDto) {
    if (this._value === value)
      return;
    this._value = value;
  }

  get value(): RoutePartCMRAdditionalDataDto {
    return this._value;
  }

  ngOnInit() {

  }

  public isValid: boolean = false;
  ngAfterViewInit() {
    this.formGroup.valueChanges.subscribe((change) => {
      this.isValid = this.formGroup.valid;
    });
    this.formGroup.controls['handlingAndTransportation'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.handlingAndTransportation, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['handlingAndTransportationSubstituteShipments'].valueChanges.subscribe((change) => {
      if(this.value.handlingAndTransportationSubstituteShipments != change)
        this.pendingSave = true;
    });
    this.formGroup.controls['applicant'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.applicant, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['payment'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.payment, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['payer'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.payer, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['amount'].valueChanges.subscribe((change) => {
      if(this.value.amount != change)
        this.pendingSave = true;
    });
    this.formGroup.controls['conditions'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.conditions, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['conditionsSubstituteShipments'].valueChanges.subscribe((change) => {
      if(this.value.conditionsSubstituteShipments != change)
        this.pendingSave = true;
    });
  
  }

  public pendingSave: boolean;
  
  onSaveChanges() {
    if (this.value != null) {
      this.routeCMRController.saveRoutePartCMRAdditionalData(this.value).subscribe(data => {
        if(data){
          this.value = data;
          this.pendingSave = false;
        }
      });
    }
    
  }
}
