import {Injectable} from '@angular/core';
import { ManagementModelObjectParentTypes } from '@shared/src/enums/ManagementModelObjectParentTypes';


@Injectable()
export class OpenChangeManagementModelDto {

    public objectParentType: ManagementModelObjectParentTypes;
    
	public objectParentId: number;

    public managementModelId: number;

    public static build(objectParentType: ManagementModelObjectParentTypes, objectParentId: number, managementModelId: number) {
        const dto = new OpenChangeManagementModelDto();
        dto.objectParentType = objectParentType;
        dto.objectParentId = objectParentId;
        dto.managementModelId = managementModelId;
        return dto;
    }
}
