import {Injectable} from '@angular/core';


@Injectable()
export class OpenRouteChangeAdditionalActorDto {

    public partnerActorId: number;

    public static build(partnerActorId: number) {
        const dto = new OpenRouteChangeAdditionalActorDto();
        dto.partnerActorId = partnerActorId;
        return dto;
    }
}
