import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { PlateAnnotationItemMaintenanceDto } from './PlateAnnotationItemMaintenanceDto';
import { PlateAnnotationListMaintenanceDto } from './PlateAnnotationListMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';

@Injectable()
export class PlateAnnotationMaintenanceController extends BaseMaintenanceController<PlateAnnotationListMaintenanceDto, PlateAnnotationItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "plateannotation");
    }

    public getResourceRoot(): string {
        return "PLATEANNOTATION";
    }

    public buildUrl(uri: string): string {
        return "system/securityandnotifications/plateannotation" + uri;
    }
}
