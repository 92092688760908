/*
A BACK HI HA LA MATEIXA ENUM AMB EQUIVALENCIA DE fitlerKey
*/
export enum RouteFilterTypes {

    NoFilters = 'ROUTE.FILTER.NOFILTERS',

	WithOutSlot = 'ROUTE.FILTER.WITHOUTSLOT', 
    WithSlot = 'ROUTE.FILTER.WITHSLOT',

	WithOutDock = 'ROUTE.FILTER.WITHOUTDOCK', 
    WithDock = 'ROUTE.FILTER.WITHDOCK',

	IsNotExwork = 'ROUTE.FILTER.ISNOTEXWORK', 
    IsExwork = 'ROUTE.FILTER.ISEXWORK',

	WithOutBooking = 'ROUTE.FILTER.WITHOUTBOOKING', 
    WithBooking = 'ROUTE.FILTER.WITHBOOKING',

	WithOutInspector = 'ROUTE.FILTER.WITHOUTINSPECTOR', 
    WithInspector = 'ROUTE.FILTER.WITHINSPECTOR',

	WithOutCertificate = 'ROUTE.FILTER.WITHOUTCERTIFICATE', 
    WithCertificate = 'ROUTE.FILTER.WITHCERTIFICATE',

	WithOutSlotReserveMarginTime = 'ROUTE.FILTER.WITHOUTSLOTRESERVEMARGINTIME',
	WithSlotReserveMarginTime = 'ROUTE.FILTER.WITHSLOTRESERVEMARGINTIME',

    IsNotDivided = 'ROUTE.FILTER.ISNOTDIVIDED', 
    IsDivided = 'ROUTE.FILTER.ISDIVIDED',

	IsNotGrouped = 'ROUTE.FILTER.ISNOTGROUPED', 
    IsGrouped = 'ROUTE.FILTER.ISGROUPED',

    NoDate = 'ROUTE.FILTER.NODATE', 
    IsToday = 'ROUTE.FILTER.ISTODAY', 
	IsTomorrow = 'ROUTE.FILTER.ISTOMORROW',
	IsNextMonday = 'ROUTE.FILTER.ISNEXTMONDAY',
	IsNextTuesday = 'ROUTE.FILTER.ISNEXTTUESDAY',
	IsNextWednesday = 'ROUTE.FILTER.ISNEXTWEDNESDAY',
	IsNextThursday = 'ROUTE.FILTER.ISNEXTTHURSDAY',
	IsNextFriday = 'ROUTE.FILTER.ISNEXTFRIDAY',
	IsNextSaturday = 'ROUTE.FILTER.ISNEXTSATURDAY',
	IsNextSunday = 'ROUTE.FILTER.ISNEXTSUNDAY',

	NormalType = 'ROUTE.FILTER.NORMAL', 
    NonacceptancesType = 'ROUTE.FILTER.NONACCEPTANCES',
	ReturnsType = 'ROUTE.FILTER.RETURNS', 
	ReverseLogisticsType = 'ROUTE.FILTER.REVERSELOGISTICS',
    InboundsType = 'ROUTE.FILTER.INBOUNDS', 
    OutboundsType = 'ROUTE.FILTER.OUTBOUNDS',
	DelegatesType = 'ROUTE.FILTER.DELEGATES', 
    OldRoutesType = 'ROUTE.FILTER.OLDROUTES',

	None = 'NONE'
    
}

export class RouteFilterTypesUtil {
    public static getRouteFilterTypeFromString(value: string): RouteFilterTypes {
        var routeFilterTypes;
        for (var enumRFT in RouteFilterTypes) {
            if (RouteFilterTypes[enumRFT] == value) {
                routeFilterTypes = enumRFT;
            }
        }
        return routeFilterTypes;
    }
}