import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccessControlAddressClassificationAccessControlDTO, AccessControlAddressClassificationAddressDTO, AccessControlAddressClassificationDTO, AccessControlController } from '@shared/src/controllers/accesscontrol/accesscontrol.controller';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { UserSettingController } from '@shared/src/controllers/user/usersetting.controller';
import { HColor } from '@shared/src/datatypes/HColor';
import { HString } from '@shared/src/datatypes/HString';
import { AddressSelectorDto } from '../../../components/address/shared.address.ambit.selector.component';

@Component({
  selector: 'shared-address-accesscontrol-selector-component',
  templateUrl: './shared.address.accesscontrol.selector.component.html',
  styleUrls: ['./shared.address.accesscontrol.selector.component.scss']
})

/***
 * Es el componentº que encapsula la visualització d'una adreça
 * origen i destí son dos objectes respectivament del tipus {longitude:2.23432, latitude:2.34323}
 */
export class SharedAddressAccessControlSelectorComponent implements OnInit {

  private PARAMETERACCESSCONTROLIDNAME = "accessControlId";
  private PARAMETERADDRESSIDNAME = "addressId";

  constructor(private activatedRoute: ActivatedRoute, private userSettingController: UserSettingController, private accessControlController: AccessControlController, private addressController: AddressController, private homeController: HomeController) {
  }

  ngOnInit(): void {

    const routeParams = this.activatedRoute.snapshot.paramMap;
    const queryParams = this.activatedRoute.snapshot.queryParamMap;


    let accesscontrol = routeParams.has(this.PARAMETERACCESSCONTROLIDNAME) ? routeParams.get(this.PARAMETERACCESSCONTROLIDNAME) : queryParams.get(this.PARAMETERACCESSCONTROLIDNAME);
    if (accesscontrol) {
      this.accessControlId = +accesscontrol;
    } else {
    }
    let address = routeParams.has(this.PARAMETERADDRESSIDNAME) ? routeParams.get(this.PARAMETERADDRESSIDNAME) : queryParams.get(this.PARAMETERADDRESSIDNAME);
    if (address) {
      this.addressId = +address;
    } else {
    }

  }

  public searchTextAddress: string = "";
  public searchTextAccessControl: string = "";
  public sliceCount: number = 5;

  @Input()
  public hideAddress: boolean;

  @Output() changeValues = new EventEmitter<AddressAccessControlSelectorDto>();

  private __value: AddressAccessControlSelectorDto;
  @Input()
  public set value(value: AddressAccessControlSelectorDto) {
    if (this.__value === value)
      return;
    this.__value = value;

    if (this.__value) {
      if (!HString.isNullOrNullString(this.value.classification)) {
        this.accessControlController.getAccessControlAddressClassification(this.value.classification).subscribe(data => {
          this.data = data;
          this.loadAccessControls(data, false, false);
        });
      } else
        this.loadAddressAccessControlSelector();
    }
  }
  public get value(): AddressAccessControlSelectorDto {
    return this.__value;
  }

  public loadAddressAccessControlSelector() {

    if (this.accessControlsTots)
      this.selectAccessControl(this.accessControlsTots.find(x => { return x.accessControlId == this.selectedAccessControlId }), false);
    else {

      if (this.value && this.value.forcedAddresses && this.value.forcedAddresses.length > 0) {
        const data: AccessControlAddressClassificationAccessControlDTO = new AccessControlAddressClassificationAccessControlDTO();
        data.addressList = this.value.forcedAddresses;
        this.loadAddress(data, false, false);
      } else if (this.addressTots)
        this.selectAddress(this.addressTots.find(x => { return x.addressId == this.selectedAddressId }), false, false, false);
      else
        this.changeValues.next(this.value);
    }

  }

  public get addressId() {
    if (this.value)
      return this.value.addressId;
    return 0;
  }
  public set addressId(value: number) {
    if (this.value)
      this.value.addressId = value;
  }

  public get accessControlId() {
    if (this.value)
      return this.value.accessControlId;
    return 0;
  }
  public set accessControlId(value: number) {
    if (this.value)
      this.value.accessControlId = value;
  }

  public get showAccessControls() {
    if (this.value)
      return this.value.showAccessControls;
    return false;
  }

  public get showAddresses() {
    if (this.value && !this.hideAddress)
      return this.value.showAddresses;
    return false;
  }

  public get maxAccessControls() {
    if (this.value)
      return this.value.maxAccessControls;
    return 0;
  }

  public get maxAddress() {
    if (this.value)
      return this.value.maxAddress;
    return 0;
  }

  public get canunsetaddress() {
    if (this.value)
      return this.value.canunsetaddress;
    return false;
  }



  public data: AccessControlAddressClassificationDTO;
  public accessControlsBarra: Array<AccessControlSelectorDto>;
  public accessControlsMenu: Array<AccessControlSelectorDto>;
  public accessControlsTots: Array<AccessControlSelectorDto>;
  public addressBarra: Array<AddressSelectorDto>;
  public addressMenu: Array<AddressSelectorDto>;
  public addressTots: Array<AddressSelectorDto>;
  public selectedAddressId: number;
  public selectedAccessControlId: number;
  public menuAccessControlsObert: boolean = false;
  public menuAddressObert: boolean = false;
  public accessControlsAddicionals: number;
  public addressAddicionals: number;
  public seleccionatMenuMesAddress: boolean = false;
  public seleccionatMenuMesAccessControl: boolean = false;

  /***
   * Es prepara les dades per tal de mostrar la pantalla. FA la part d0'accessControls
   */
  loadAccessControls(data: AccessControlAddressClassificationDTO, perPantalla: boolean, setLastAddress: boolean) {
    this.accessControlsBarra = new Array<AccessControlSelectorDto>();
    this.accessControlsMenu = new Array<AccessControlSelectorDto>();
    this.accessControlsTots = new Array<AccessControlSelectorDto>();
    if (data == null || data.accessControlList == null) {
      this.changeValues.next(this.value);
      return;
    }

    //No cal ordenar-los però si que calculo forecolor i backcolor per ser més ràpid després i sobretot si es mostra a barra o a llista
    let i = 0;
    data.accessControlList.sort(this.accessControlController.compareAccessControl).reverse().forEach(a => {
      let dto = AccessControlSelectorDto.build(a, i < this.maxAccessControls, this.homeController);
      if (i++ < this.maxAccessControls)
        this.accessControlsBarra.push(dto);
      else
        this.accessControlsMenu.push(dto);
      this.accessControlsTots.push(dto);
    });

    if (this.accessControlsMenu.length > 0)
      this.accessControlsMenu.unshift(this.accessControlsBarra.pop());
    this.accessControlsAddicionals = this.accessControlsMenu.length;

    let propostaAccessControl = data.defaultAccessControlId;
    if (this.accessControlId && this.accessControlId != 0)
      propostaAccessControl = this.accessControlId;
    this.selectAccessControl(this.accessControlsTots.find(x => { return x.accessControlId == propostaAccessControl }), perPantalla);

  }

  loadAddress(data: AccessControlAddressClassificationAccessControlDTO, perPantalla: boolean, changedAccessControl: boolean) {
    this.addressBarra = new Array<AddressSelectorDto>();
    this.addressMenu = new Array<AddressSelectorDto>();
    this.addressTots = new Array<AddressSelectorDto>();

    if (data == null || data.addressList == null) {
      this.actualitzarClassification(perPantalla, this.selectedAccessControlId, this.selectedAddressId);
      return;
    }

    let propostaAddress = this.data ? this.data.defaultAddressId : 0;
    if (this.addressId && this.addressId != 0)
      propostaAddress = this.addressId;

    data.addressList.forEach(element => {
      element.selected = element.addressId == propostaAddress;
    });

    //No cal ordenar-los però si que calculo forecolor i backcolor per ser més ràpid després i sobretot si es mostra a barra o a llista
    let i = 0;
    data.addressList.sort(this.accessControlController.compareAddress).reverse().forEach(a => {
      let dto = AddressSelectorDto.build(a, i < this.maxAddress, this.homeController)
      if (i++ < this.maxAddress)
        this.addressBarra.push(dto);
      else
        this.addressMenu.push(dto);
      this.addressTots.push(dto);
    });

    if (this.addressMenu.length > 0)
      this.addressMenu.unshift(this.addressBarra.pop());
    this.addressAddicionals = this.addressMenu.length;

    if (this.selectedAddressId && this.selectedAddressId != 0)
      this.selectAddress(this.addressTots.find(x => { return x.addressId == this.selectedAddressId }), perPantalla, changedAccessControl, true);
    else {

      if (propostaAddress && propostaAddress != 0)
        this.selectAddress(this.addressTots.find(y => { return y.addressId == propostaAddress }), perPantalla, changedAccessControl, false);
      else if (this.accessControlId && this.accessControlId != 0) {
        if (this.value && this.value.classification) {
          this.selectAddress(null, perPantalla, changedAccessControl, true);
        } else {
          this.userSettingController.getSetting("LastAddressIdOnAccessControlId." + this.accessControlId).subscribe(x => {
            this.selectAddress(this.addressTots.find(y => { return y.addressId == x }), perPantalla, changedAccessControl, false);
          });
        }
      } else {
        this.selectAddress(null, perPantalla, changedAccessControl, true);
      }
    }
  }

  obrirMenuAccessControls() {
    this.menuAccessControlsObert = !this.menuAccessControlsObert;
  }
  obrirMenuAddress() {
    this.menuAddressObert = !this.menuAddressObert;
  }
  selectScreenAccessControl(item: AccessControlSelectorDto) {
    this.accessControlId = item != null ? item.accessControlId : 0;
    if (this.value && this.value.classification) {
      this.selectAccessControl(item, true);
    } else {
      this.userSettingController.setDefaultAccessControlId(this.accessControlId).subscribe(data => {
        this.selectAccessControl(item, true);
      });
    }
  }
  selectAccessControl(item: AccessControlSelectorDto, perPantalla: boolean) {
    let proposta = item != null && item != undefined ? item.accessControlId : 0;
    if (this.accessControlId && this.accessControlId != 0)
      proposta = this.accessControlId;
    if (proposta === this.selectedAccessControlId) {
      if (perPantalla)
        this.changeValues.next(this.value);
      return;
    }
    this.selectedAccessControlId = proposta;
    this.menuAccessControlsObert = false;
    this.seleccionatMenuMesAccessControl = false;

    this.accessControlsMenu.forEach(i => { this.seleccionatMenuMesAccessControl = this.seleccionatMenuMesAccessControl || i.accessControlId == this.selectedAccessControlId });

    this.__value.accessControl = item;
    this.__value.accessControlId = item != null ? item.accessControlId : 0;


    //Poso les adreces al selector d'adreces
    if (this.data && this.data.accessControlList) {
      this.loadAddress(this.data.accessControlList.find(i => { return i.accessControlId == this.selectedAccessControlId }), perPantalla, true);
    } else
      this.changeValues.next(this.value);
  }

  public actualitzarClassification(perPantalla: boolean, accessControlId: number, addressId: number) {
    if (!perPantalla) {
      this.changeValues.next(this.value);
      return;
    }
    if (this.value && this.value.classification) {
      this.data.defaultAccessControlId = accessControlId;
      this.data.defaultAddressId = addressId;
      this.accessControlController.setAccessControlAddressClassification(this.value.classification, this.data.defaultAccessControlId, this.data.defaultAddressId).subscribe(data => {
        this.changeValues.next(this.value);
      });
    }
  }


  selectScreenAddress(item: AddressSelectorDto) {

    if (this.canunsetaddress && item != null && item.addressId === this.addressId) {
      this.addressId = 0;

      if (this.value && this.value.classification) {
        this.selectAddress(null, true, false, true);
      } else {
        this.userSettingController.setDefaultAddressId(this.addressId).subscribe(data => {
          this.selectAddress(null, true, false, true);
        });
      }
      return;
    }

    this.addressId = item != null ? item.addressId : 0;

    if (this.value && this.value.classification) {
      this.selectAddress(item, true, false, true);
    } else {
      this.userSettingController.setDefaultAddressId(this.addressId).subscribe(data => {
        this.selectAddress(item, true, false, true);
      });
    }
  }
  selectAddress(item: AddressSelectorDto, perPantalla: boolean, changedAmbit: boolean, setLastAddress: boolean) {
    let proposta = item != null && item != undefined ? item.addressId : 0;
    if (!perPantalla && this.addressId && this.addressId != 0)
      proposta = this.addressId;
    if (proposta === this.selectedAddressId) {
      if (changedAmbit) {
        this.actualitzarClassification(perPantalla, this.selectedAccessControlId, this.selectedAddressId);
      }
      return;
    }

    this.selectedAddressId = proposta;
    this.menuAddressObert = false;
    this.seleccionatMenuMesAddress = false;

    if (this.addressMenu != null)
      this.addressMenu.forEach(i => { this.seleccionatMenuMesAddress = this.seleccionatMenuMesAddress || i.addressId == this.selectedAddressId });

    this.__value.address = item;
    this.__value.addressId = item != null ? item.addressId : 0;

    if (this.accessControlId && this.accessControlId != 0 && setLastAddress) {
      if (this.value && this.value.classification) {
        this.actualitzarClassification(perPantalla, this.selectedAccessControlId, this.selectedAddressId);
      } else {
        this.userSettingController.setSetting("LastAddressIdOnAccessControlId." + this.accessControlId, this.selectedAddressId + "").subscribe(x => {
          this.changeValues.next(this.value);
        });
      }
    } else {
      this.actualitzarClassification(perPantalla, this.selectedAccessControlId, this.selectedAddressId);
    }
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}



export class AddressAccessControlSelectorDto {

  public classification: string;

  public addressId: number;
  public address: AddressSelectorDto;
  public accessControlId: number;
  public accessControl: AccessControlSelectorDto;
  public showAddresses: boolean = true;
  public showAccessControls: boolean = true;
  public canunsetaddress: boolean;
  public maxAccessControls: number = 6;
  public maxAddress: number = 6;

  public forcedAddresses: Array<AccessControlAddressClassificationAddressDTO>;

  public static buildStandard(classification: string): AddressAccessControlSelectorDto {
    let dto: AddressAccessControlSelectorDto = new AddressAccessControlSelectorDto();
    dto.classification = classification;
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildAccessControls(accessControlId: number, addressId: number): AddressAccessControlSelectorDto {
    let dto: AddressAccessControlSelectorDto = new AddressAccessControlSelectorDto();
    dto.classification = "accessControls";
    dto.accessControlId = accessControlId;
    dto.addressId = addressId;
    dto.canunsetaddress = true;
    return dto;
  }

  public static buildStatisticsAccessControls(): AddressAccessControlSelectorDto {
    let dto: AddressAccessControlSelectorDto = new AddressAccessControlSelectorDto();
    dto.classification = "statisticsaccesscontrols";
    dto.canunsetaddress = true;
    return dto;
  }

  public setClassification(classification: string): AddressAccessControlSelectorDto {
    this.classification = classification;
    return this;
  }
  public setCanUnsetAddress(value: boolean): AddressAccessControlSelectorDto {
    this.canunsetaddress = value;
    return this;
  }

}
export class AccessControlSelectorDto {
  public accessControlId: number;
  public accessControlKey: string;
  public description: string;
  public color: string;
  public backgroundColor: string;
  public score: number;

  public barra: boolean;

  public style: any;

  public static build(accessControl: AccessControlAddressClassificationAccessControlDTO, barra: boolean, homeController: HomeController): AccessControlSelectorDto {
    let result: AccessControlSelectorDto = new AccessControlSelectorDto();
    result.accessControlId = accessControl.accessControlId;
    result.accessControlKey = accessControl.accessControlKey;
    result.description = accessControl.description;
    result.score = accessControl.score;

    result.backgroundColor = HColor.getColorFromStringTable(accessControl.accessControlKey, homeController);
    result.color = HColor.getWhiteBlackFromColor(result.backgroundColor);

    result.style = { 'color': result.color, 'backgroundColor': result.backgroundColor };

    result.barra = barra;
    return result;
  }
}
