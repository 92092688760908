import { Injectable } from '@angular/core';
import { PalletTypes } from '@shared/src/enums/PalletTypes';
import { RoutePartCMRShipmentDeliveryLineRestDto } from './RoutePartCMRShipmentDeliveryLineRestDto';

@Injectable()
export class RoutePartCMRShipmentDeliveryLineDto {

	public order: number;

    public routePartCMRShipmentCMRDeliveryLineId: number;
    public routePartCMRId: number;
    public routeId: number;
    public shipmentCMRDeliveryLineId: number;
    public shipmentCMRDeliveryLineRestId: number;
    public restLine: RoutePartCMRShipmentDeliveryLineRestDto;
    public shipmentCMRId: number;
    public shipmentDeliveryLineId: number;
    public shipmentId: number;

    public line: number;
    public reference: string;
    public description: string;
    public palletType: PalletTypes;
    public wDimension: number;
    public yDimension: number;
    public hDimension: number;
    public m3: number;
    public quantity: number;
    public kg: number;
    public palletCount: number;

    public quantityBD: number;
    public kgBD: number;
    public palletCountBD: number;

	public canPutAll: boolean;
	public canRemoveAll: boolean;

    public static hasValues(value: RoutePartCMRShipmentDeliveryLineDto): boolean {
        if(value == null)
            return false;
        if(value.quantity > 0 || value.kg > 0 || value.wDimension > 0 || value.yDimension > 0 || value.hDimension > 0 || value.m3 > 0 || value.palletCount > 0)
            return true;
        return false;
    }

    public static putAll(lineObject: RoutePartCMRShipmentDeliveryLineDto) {
        if (lineObject == null || lineObject.restLine == null)
            return;
    
            lineObject.quantity = lineObject.restLine.quantityRestBD + lineObject.quantityBD;
            lineObject.palletCount = lineObject.restLine.palletCountRestBD + lineObject.palletCountBD;
            lineObject.kg = lineObject.restLine.kgRestBD + lineObject.kgBD;
    
            RoutePartCMRShipmentDeliveryLineDto.actualizedRestWith(lineObject);
    }

    public static removeAll(lineObject: RoutePartCMRShipmentDeliveryLineDto) {
        if (lineObject == null || lineObject.restLine == null)
            return;

        lineObject.quantity = 0;
        lineObject.palletCount = 0;
        lineObject.kg = 0;

        RoutePartCMRShipmentDeliveryLineDto.actualizedRestWith(lineObject);
    }

    /**
     * Ens han canviat els valors de quantity, kg o palletCount i s'han d'actualitzar els valors de Rest
     */
    public static actualizedRestWith(lineObject: RoutePartCMRShipmentDeliveryLineDto) {
        if (lineObject == null || lineObject.restLine == null)
            return;

        lineObject.restLine.quantityRest = (lineObject.restLine.quantityRestBD + lineObject.quantityBD) - lineObject.quantity;
        lineObject.restLine.palletCountRest = (lineObject.restLine.palletCountRestBD + lineObject.palletCountBD) - lineObject.palletCount;
        lineObject.restLine.kgRest = (lineObject.restLine.kgRestBD + lineObject.kgBD) - lineObject.kg;

        lineObject.canRemoveAll = lineObject.quantity > 0 || lineObject.kg > 0 || lineObject.palletCount > 0;

        lineObject.canPutAll = lineObject.restLine.quantityRest > 0 || lineObject.restLine.kgRest > 0
					|| lineObject.restLine.palletCountRest > 0;
		
    }
}
