import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { ShipmentCMRAdditionalDataDto } from '@shared/src/dtos/route/cmr/shipment/ShipmentCMRAdditionalDataDto';
import { ShipmentCMREventDto } from '@shared/src/dtos/route/cmr/shipment/ShipmentCMREventDto';


@Injectable({
    providedIn: 'root',
})
export class ShipmentCMRController extends BaseController {
    private base = '/shipmentcmr'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getShipmentCMRAdditionalData(shipmentId: number): Observable<ShipmentCMRAdditionalDataDto> {
        return this.get(this.base + "/" + shipmentId + "/additionaldata");
    }
    public saveShipmentCMRAdditionalData(value: ShipmentCMRAdditionalDataDto): Observable<ShipmentCMRAdditionalDataDto> {
        return this.post(this.base + "/additionaldata/save", value, "saveShipmentCMRAdditionalData");
    }

    public getShipmentCMREvents(shipmentId: number): Observable<ShipmentCMREventDto[]> {
        return this.get(this.base  + "/" + shipmentId + "/events");
    }
}
