<div class="container" *ngIf="isImageType && formatImages">
    <img *ngFor="let formatImage of formatImages; let i=index" class="imatge" [src]="formatImage" />
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail" class="opendocument">
    </shared-opendocument-component>
</div>
<div class="container" *ngIf="isAndroid && isPdfType && urlDatas" [ngClass]="documentDetail.fileType">
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail"
        class="opendocument"></shared-opendocument-component>
    
   <iframe *ngFor="let urlData of urlDatas; let i=index"
        [src]="'https://docs.google.com/gview?embedded=true&url=' + urlData | safe:'resourceUrl'"
        allowfullscreen class="frame"></iframe>

</div>
<div class="container" *ngIf="!isImageType && (!isPdfType || !isAndroid) && urlDatas" [ngClass]="documentDetail.fileType">
    <shared-opendocument-component *ngIf="showOpen && document" [document]="document" class="opendocument">
    </shared-opendocument-component>
    <shared-opendocument-component *ngIf="showOpen && documentDetail" [document]="documentDetail"
        class="opendocument"></shared-opendocument-component>
    <iframe *ngFor="let urlData of urlDatas; let i=index" #iframe class="frame" [src]="urlData | safe:'resourceUrl'"
        allowfullscreen></iframe>
</div>
<shared-opendocument-component *ngIf="useIonic && !isImageType && !isPdfType && document && !urlDatas"
    [document]="document" [iconclass]="'fa fa-file-text-o'"
    class="action button icon-ionic-file-text-o"></shared-opendocument-component>
<shared-opendocument-component *ngIf="useIonic && !isImageType && !isPdfType && documentDetail && !urlDatas"
    [documentDetail]="documentDetail" [iconclass]="'fa fa-file-text-o'"
    class="action button icon-ionic-file-text-o"></shared-opendocument-component>