import { Injectable } from "@angular/core";
import { FileTypes } from "@shared/src/enums/FileTypes";

@Injectable()
export class ImageDocument {
   public filename: string;
   public filebytes: any[];
   public filetypes: FileTypes;

   public static build(filename: string, filebytes: any[], filetypes: FileTypes): ImageDocument{
        let result: ImageDocument = new ImageDocument();
        result.filename = filename;
        result.filebytes = filebytes;
        result.filetypes = filetypes;

        return result;
   }
}