import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { BaseController } from '@shared/src/controllers/base.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { BookingSimpleDto } from '@shared/src/dtos/booking/BookingSimpleDto';
import { OpenChangeStatusDto } from '@shared/src/dtos/open/OpenChangeStatusDto';
import { OpenDocument } from '@shared/src/dtos/open/OpenDocument';
import { OpenResource } from '@shared/src/dtos/open/OpenResource';
import { OpenRouteChangeAdditionalActorDto } from '@shared/src/dtos/open/OpenRouteChangeAdditionalActorDto';
import { OpenRouteChangeDriverInfoAndPlatesDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoAndPlatesDto';
import { OpenRouteChangeDriverInfoDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoDto';
import { OpenChangeManagementModelDto } from '@shared/src/dtos/open/OpenChangeManagementModelDto';
import { OpenRouteChangePlatesDto } from '@shared/src/dtos/open/OpenRouteChangePlatesDto';
import { OpenRouteExworkInfoDto } from '@shared/src/dtos/open/OpenRouteExworkInfoDto';
import { OpenShipmentDocuments } from '@shared/src/dtos/open/OpenShipmentDocuments';
import { OpenTenantResource } from '@shared/src/dtos/open/OpenTenantResource';
import { TrackingShipmentDto } from '@shared/src/dtos/tracking/TrackingShipmentDto';
import { AddressTimetableIntervalDto, GeneralStatus, HDate, HDateHour, HHourInterval, HHourIntervals, HTriState } from '@shared/src/public-api';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { HomeController } from '../home/home.controller';
import { OpenUuidExwork } from './OpenUuidExwork';
import { OpenUuidRouteGroupExwork } from './OpenUuidRouteGroupExwork';
import { Incoterms } from '@shared/src/enums/Incoterms';
import { ReserveOutOfHoursDto } from '@shared/src/dtos/address/slots/actions/ReserveOutOfHoursDto';
import { SlotsController } from '../address/slots/slots.controller';
import { RouteGroupChildListDto } from '@shared/src/dtos/route/RouteGroupChildListDto';
import { OpenChangeConditionDto } from '@shared/src/dtos/open/OpenChangeConditionDto';
import { RouteCMRDto } from '@shared/src/dtos/route/cmr/RouteCMRDto';
import { PhaseQrDto } from '@shared/src/dtos/phase/PhaseQrDto';


@Injectable({
    providedIn: 'root',
})
export class OpenRouteController extends BaseController {
    private base = '/public/route'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        @Inject('HomeController') protected homeController: HomeController,
        protected slotsController: SlotsController
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public setPlates(routeid: number, body: OpenRouteChangePlatesDto): Observable<boolean> {
        return this.postBusy(this.base + "/set/plates/routeid/" + routeid, body, "changePlates");
    }
    public setExworkInfo(routeid: number, body: OpenRouteExworkInfoDto): Observable<boolean> {
        return this.postBusy(this.base + "/set/exworkinfo/routeid/" + routeid, body, "setExworkInfo");
    }


    public setDriverInfo(routeid: number, body: OpenRouteChangeDriverInfoDto): Observable<boolean> {
        return this.postBusy(this.base + "/set/driverinfo/routeid/" + routeid, body, "changeDriverInfo");
    }

    public setSecondDriverInfo(routeid: number, body: OpenRouteChangeDriverInfoDto): Observable<boolean> {
        return this.postBusy(this.base + "/set/seconddriverinfo/routeid/" + routeid, body, "changeSecondDriverInfo");
    }

    public setDriverInfoAndPlates(routeid: number, body: OpenRouteChangeDriverInfoAndPlatesDto): Observable<boolean> {
        return this.postBusy(this.base + "/set/driverinfoandplates/routeid/" + routeid, body, "changeDriverInfoAndPlates")
    }

    public setActiveNotificationDriver(routeid: number, notificationDriver: boolean): Observable<boolean> {
        return this.postBusy(this.base + "/set/notificationdriver/routeid/" + routeid, notificationDriver, "changeNotificationDriver")
    }

    public setActiveIsDivisible(routeid: number, isDivisible: boolean): Observable<boolean> {
        return this.postBusy(this.base + "/set/isdivisible/routeid/" + routeid, isDivisible, "changeIsDivisible")
    }

    public setRouteSuperCondition(routeid: number, changeCondition: OpenChangeConditionDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/supercondition/routeid/" + routeid, changeCondition, "setRouteSuperCondition");
    }

    public routeSetStatus(routeid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/state/routeid/" + routeid, changeStatus, "routeSetStatus");
    }

    public setRouteSuperStatus(routeid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/superstate/routeid/" + routeid, changeStatus, "setRouteSuperStatus");
    }

    public routeSetStatusAsDriver(routeid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/state/asdriver/routeid/" + routeid, changeStatus, "routeSetStatusAsDriver");
    }

    public phaseSetStatus(phaseid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/phase/state/phaseid/" + phaseid, changeStatus, "phaseSetStatus");
    }

    public phaseSetStatusAsDriver(phaseid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/phase/state/asdriver/phaseid/" + phaseid, changeStatus, "phaseSetStatusAsDriver");
    }

    public shipmentSetStatus(shipmentid: number, changeStatus: OpenChangeStatusDto): Observable<Boolean> {
        this.homeController.geolocationRequest$.next();
        return this.postBusy(this.base + "/set/shipment/state/shipmentid/" + shipmentid, changeStatus, "shipmentSetStatus");
    }

    public shipmentSetLoadForm(shipmentid: number, formid: number): Observable<Boolean> {
        return this.postBusy(this.base + "/set/shipment/loadform/shipmentid/" + shipmentid, formid, 'shipmentSetLoadForm')
    }

    public shipmentSetUnLoadForm(shipmentid: number, formid: number): Observable<Boolean> {
        return this.postBusy(this.base + "/set/shipment/unloadform/shipmentid/" + shipmentid, formid, 'shipmentSetUnLoadForm')
    }

    public shipmentSetPartnerClientId(shipmentId: number, partnerClientId: number): Observable<Boolean> {
        if (partnerClientId == null || partnerClientId == 0)
            return this.postBusy(this.base + "/clear/shipment/client/shipmentid/" + shipmentId + "/", "", 'shipmentClearPartnerClientId')
        return this.postBusy(this.base + "/set/shipment/client/partnerid/" + partnerClientId + "/shipmentid/" + shipmentId + "/", "", 'shipmentSetPartnerClientId')
    }

    public shipmentSetPartnerProviderId(shipmentId: number, partnerProviderId: number): Observable<Boolean> {
        if (partnerProviderId == null || partnerProviderId == 0)
            return this.postBusy(this.base + "/clear/shipment/provider/shipmentid/" + shipmentId + "/", "", 'shipmentClearPartnerProviderId')
        return this.postBusy(this.base + "/set/shipment/provider/partnerid/" + partnerProviderId + "/shipmentid/" + shipmentId + "/", "", 'shipmentSetPartnerProviderId')

    }

    public shipmentSetEmailsByGeneralStatus(shipmentId: number, generalstatus: string, emails: string): Observable<Boolean> {
       return this.post(this.base + "/set/shipment/emails/generalstatus/" + generalstatus + "/shipmentid/" + shipmentId + "/", emails != null ? emails : "", 'shipmentSetEmailsByGeneralStatus')
    }
    public shipmentSetEmailsProviderByGeneralStatus(shipmentId: number, generalstatus: string, emails: string): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/emailsprovider/generalstatus/" + generalstatus + "/shipmentid/" + shipmentId + "/", emails != null ? emails : "", 'shipmentSetEmailsByGeneralStatus')
    }
    public shipmentSetIsActiveConditionTemp(shipmentId: number, value: boolean): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/isactiveconditiontemp/shipmentid/" + shipmentId + "/", value, 'shipmentSetIsActiveConditionTemp')
    }
    public shipmentSetIsActiveConditionHR(shipmentId: number, value: boolean): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/isactiveconditionhr/shipmentid/" + shipmentId + "/", value, 'shipmentSetIsActiveConditionHR')
    }
    public shipmentSetConditionDouble(shipmentId: number, condition: string, value: number): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/condition/" + condition + "/valuedouble/shipmentid/" + shipmentId + "/", value != null ? value : 0, 'shipmentSetConditionDouble')
    }
    public shipmentSetConditionDateHour(shipmentId: number, condition: string, value: HDateHour): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/condition/" + condition + "/valuedatehour/shipmentid/" + shipmentId + "/", value != null ? value : HDateHour.nullDateHour(), 'shipmentSetConditionDateHour')
    }
    public shipmentSetConditionHourIntervals(shipmentId: number, condition: string, value: HHourIntervals): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/condition/" + condition + "/valuehourintervals/shipmentid/" + shipmentId + "/", value != null ? value : HHourIntervals.empty(), 'shipmentSetConditionHourIntervals')
    }
    public shipmentSetConditionTriState(shipmentId: number, condition: string, value: HTriState): Observable<Boolean> {
        return this.post(this.base + "/set/shipment/condition/" + condition + "/valuetristate/shipmentid/" + shipmentId + "/", value != null ? value : HTriState.Indeterminate, 'shipmentSetConditionTriState')
    }     

    public phaseAllocateSlot(phaseId: number, intervalId: number): Observable<Boolean> {
        return new Observable<any>((observer) => {
            this.postBusy(this.base + "/set/intervalslot/intervalid/" + intervalId + "/phase/phaseid/" + phaseId, {}, 'phaseAllocateSlot').subscribe(result => {
                this.slotsController.checkOverbookingByPhaseId(phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }

    public phaseAllocateSlotByDay(phaseId: number, day: HDate): Observable<Boolean> {
        day = HDate.buildFromHDate(day);
        return new Observable<any>((observer) => {
            this.postBusy(this.base + "/set/intervalslot/day/" + day.toStringRequest() + "/phase/phaseid/" + phaseId, {}, 'phaseAllocateSlotByDay').subscribe(result => {
                this.slotsController.checkOverbookingByPhaseId(phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }

    public phaseAllocateSlotByResource(phaseId: number, resourceId: number, data: OpenResource): Observable<Boolean> {
        return new Observable<any>((observer) => {
            this.postBusy(this.base + "/set/intervalslot/resourceid/" + resourceId + "/phase/phaseid/" + phaseId, data, 'phaseAllocateSlotByResource').subscribe(result => {
                this.slotsController.checkOverbookingByPhaseId(phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }

    public phaseReserveOutOfHours(phaseId: number, data: ReserveOutOfHoursDto): Observable<Boolean> {
        return new Observable<any>((observer) => {
            return this.postBusy(this.base + "/set/intervalslot/reserveoutofhours/phase/phaseid/" + phaseId, data, 'phaseReserveOutOfHours').subscribe(result => {
                this.slotsController.checkOverbookingByPhaseId(phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }

    public phaseReleaseSlot(phaseId: number, fromActiveRoutes: boolean): Observable<Boolean> {
        return this.postBusy(this.base + "/set/intervalslot/releaseslot/phase/phaseid/" + phaseId, fromActiveRoutes, 'phaseReleaseSlot')
    }

    public getAllShipmentDocuments(shipmentId: number): Observable<Array<OpenDocument>> {
        return this.get(this.base + "/get/documents/" + shipmentId)
    }

    public getAllPhaseDocuments(phaseId: number): Observable<Array<OpenShipmentDocuments>> {
        return this.get(this.base + "/get/phasedocuments/" + phaseId)
    }

    public getDeliveryNoteHistory(shipmentId: number): Observable<Array<OpenDocument>> {
        return this.get(this.base + "/get/deliverynote/" + shipmentId)
    }

    public getCMRHistory(shipmentId: number): Observable<Array<OpenDocument>> {
        return this.get(this.base + "/get/cmr/" + shipmentId)
    }

    public downloadShipmentDocument(shipmentId: number, documentId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/shipment/" + shipmentId + "/document/" + documentId)
    }

    public downloadShipmentDocumentDetail(shipmentId: number, documentDetailId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/shipment/" + shipmentId + "/documentdetail/" + documentDetailId)
    }

    public setCarrier(routeId: number, carrier: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/carrier/routeid/" + routeId, carrier, "setCarrier")
    }

    public setShipper(routeId: number, shipper: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/shipper/routeid/" + routeId, shipper, "setShipper")
    }

    public setLogisticOperator(routeId: number, logisticOperator: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/logisticoperator/routeid/" + routeId, logisticOperator, "setLogisticOperator")
    }

    public setInspector(routeId: number, inspector: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/inspector/routeid/" + routeId, inspector, "setInspector")
    }

    public setHealthCertifier(routeId: number, inspector: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/healthcertifier/routeid/" + routeId, inspector, "setHealthCertifier")
    }

    public setDriver(routeId: number, driver: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/driver/routeid/" + routeId, driver, "setDriver")
    }

    public setClientPreferent(routeId: number, clientPreferent: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/clientpreferent/routeid/" + routeId, clientPreferent, "setClientPreferent")
    }

    public setSecondDriver(routeId: number, secondDriver: OpenRouteChangeAdditionalActorDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/seconddriver/routeid/" + routeId, secondDriver, "setSecondDriver")
    }

    public setManagementModel(managementModel: OpenChangeManagementModelDto): Observable<Boolean> {
        return this.postBusy(this.base + "/set/managementmodel", managementModel, "setManagementModel")
    }

    public setBannerHtml(routeId: number, bannerHtml: string): Observable<Boolean> {
        return this.post(this.base + "/set/htmlbanner/routeid/" + routeId + "/", bannerHtml, "setBannerHtml");
    }

    public setPrivateBannerHtml(routeId: number, bannerHtml: string): Observable<Boolean> {
        return this.post(this.base + "/set/privatehtmlbanner/routeid/" + routeId + "/", bannerHtml, "setPrivateBannerHtml");
    }

    public setTypology(routeId: number, typologyId: number): Observable<Boolean> {
        if (!typologyId)
            typologyId = 0;
        return this.post(this.base + "/set/typology/" + typologyId + "/routeid/" + routeId, null, "setTypology")
    }

    public setManualTags(routeId: number, tags: HTags): Observable<Boolean> {
        return this.postBusy(this.base + "/set/manualtags/routeid/" + routeId, tags, "setManualTags")
    }
    public setPrivateTags(routeId: number, tags: HTags): Observable<Boolean> {
        return this.postBusy(this.base + "/set/privatetags/routeid/" + routeId, tags, "setPrivateTags")
    }

    public getTrackShipment(readuuid: string): Observable<TrackingShipmentDto> {
        return this.get(this.base + "/shipment/" + readuuid);
    }

    public setDriverActiveRouteId(routeId: number, status: GeneralStatus): Observable<Boolean> {
        return this.post(this.base + "/set/driveractiverouteid/" + routeId + "/" + status, {}, "setDriverActiveRouteId")
    }
    public renewUuidExworkRouteId(routeId: number): Observable<Boolean> {
        return this.post(this.base + "/exwork/refresh/uuid/routeid/" + routeId, {}, "renewUuidExworkRouteId")
    }
    public resendAllExworkByRouteId(routeId: number): Observable<Boolean> {
        return this.post(this.base + "/exwork/resend/routeid/" + routeId, {}, "resendAllExworkByRouteId")
    }
    public resendExworkEmails(routeId: number, data: OpenRouteExworkInfoDto): Observable<Boolean> {
        return this.post(this.base + "/exwork/resend/emails/routeid/" + routeId, data, "resendExworkEmails")
    }
    public getExwork(uuidExwork: string): Observable<OpenUuidExwork> {
        return this.get(this.base + "/exwork/open/" + uuidExwork);
    }
    public getExworkSlotsByPhaseId(uuid: string, phaseId: number): Observable<AddressTimetableIntervalDto[]> {
        return this.get(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId);
    }
    public getExworkCanSetSlotsByPhaseId(uuid: string, phaseId: number): Observable<boolean> {
        return this.get(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId + "/canset");
    }
    public getExworkCanReleaseSlotsByPhaseId(uuid: string, phaseId: number): Observable<boolean> {
        return this.get(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId + "/canrelease");
    }
    public setExworkDriverInfoAndPlates(uuid: string, data: OpenRouteChangeDriverInfoAndPlatesDto): Observable<Boolean> {
        return this.post(this.base + "/exwork/open/" + uuid + "/setdriverinfoandplates",
            data, "setExworkDriverInfoAndPlates");
    }
    public setExworkPhaseSlot(uuid: string, phaseId: number, addressTimetableIntervalId: number) {
        return new Observable<any>((observer) => {
            this.post(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId + "/set/" + addressTimetableIntervalId, {}, "setExworkPhaseSlot").subscribe(result => {
                this.checkExworkOverbookingByPhaseId(uuid, phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }
    public setExworkPhaseSlotByResource(uuid: string, phaseId: number, resourceId: number, data: OpenResource) {
        return new Observable<any>((observer) => {
            return this.post(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId + "/set/resource/" + resourceId, data, "setExworkPhaseSlot").subscribe(result => {
                this.checkExworkOverbookingByPhaseId(uuid, phaseId).subscribe(overbooking => {
                    observer.next(result && !overbooking);
                    observer.complete();
                })
            })
        });
    }
    public checkExworkOverbookingByPhaseId(uuid: string, phaseId: number): Observable<boolean> {
        return this.getBusy(this.base + "/exwork/open/" + uuid + "/overbooking/" + phaseId);
    }
    public clearExworkPhaseSlot(uuid: string, phaseId: number) {
        return this.post(this.base + "/exwork/open/" + uuid + "/slots/" + phaseId + "/clear", {}, "clearExworkPhaseSlot");
    }
    public addExworkEmail(uuid: string, email: string) {
        return this.post(this.base + "/exwork/open/" + uuid + "/email", email, "addExworkEmail");
    }
    public getExworkRouteGroup(uuid: string): Observable<OpenUuidRouteGroupExwork> {
        return this.get(this.base + "/exwork/open/group/" + uuid);
    }
    public doExworkRouteGroup(data: OpenUuidRouteGroupExwork): Observable<string> {
        return this.postBusy(this.base + "/exwork/open/group/do", data, "doExworkRouteGroup");
    }
    public actualizedExworkRouteGroup(data: OpenUuidRouteGroupExwork): Observable<boolean> {
        return this.postBusy(this.base + "/exwork/open/group/actualized", data, "actualizedExworkRouteGroup");
    }
    public getUuidExworkParentGroupByUuidChild(uuid: string): Observable<string> {
        return this.get(this.base + "/exwork/open/group/parent/uuidchild/" + uuid);
    }
    public getRouteGroupChildListByUuidExwork(uuid: string): Observable<RouteGroupChildListDto> {
        return this.get(this.base + "/exwork/open/group/childlist/" + uuid);
    }

    public addAdditionalDriverPhone(uuid: string, phone: string): Observable<boolean> {
        return this.post(this.base + "/open/" + uuid + "/phone", phone, "addAdditionalDriverPhone");
    }
    public setAdditionalDriverPhone(uuid: string, phones: string): Observable<boolean> {
        return this.post(this.base + "/open/" + uuid + "/phones", phones, "setAdditionalDriverPhone");
    }
    public renewWriteUuid(routeId: number): Observable<string> {
        return this.put(this.base + "/open/refresh/writeuuid/routeid/" + routeId, {}, "renewWriteUuid")
    }
    public renewReadUuid(routeId: number): Observable<string> {
        return this.put(this.base + "/open/refresh/readuuid/routeid/" + routeId, {}, "renewReadUuid")
    }
    public resendAllAdditionalDriversByRouteId(routeId: number): Observable<Boolean> {
        return this.post(this.base + "/open/resend/additionaldrivers/routeid/" + routeId, {}, "resendAllAdditionalDriversByRouteId")
    }

    public setContainerNumberByRouteId(routeId: number, containerNumber: string): Observable<boolean> {
        return this.post(this.base + "/open/set/containernumber/routeid/" + routeId, containerNumber, "setContainerNumberByRouteId");
    }

    public setARCByRouteId(routeId: number, arc: string): Observable<boolean> {
        return this.post(this.base + "/open/set/arc/routeid/" + routeId, arc, "setARCByRouteId");
    }
    
    public setDREByRouteId(routeId: number, dre: string): Observable<boolean> {
        return this.post(this.base + "/open/set/dre/routeid/" + routeId, dre, "setDREByRouteId");
    }

    public setIncoterm(routeId: number, incoterm: Incoterms) {
        return this.putBusy(this.base + "/open/set/incoterm/routeid/" + routeId, "\"" + incoterm + "\"", "setIncoterm")
    }


    public notifyDriver(routeId: number): Observable<Boolean> {
        return this.put(this.base + "/set/notifydriver/routeid/" + routeId, "", "notifyDriver")
    }

    public getExworkBooking(uuid: string): Observable<BookingSimpleDto> {
        return this.get(this.base + "/exwork/open/booking/" + uuid);
    }
    public putExworkBooking(uuid: string, booking: BookingSimpleDto): Observable<BookingSimpleDto> {
        return this.putBusy(this.base + "/exwork/open/booking/" + uuid, booking, "putExworkBooking");
    }

    public _ROUTE_UUID: string = null;
    public getAllResourcesByExwork(uuid: string): Observable<Array<OpenTenantResource>> {
        return this.get("/exwork/open/resources/" + uuid);
    }

    
    public getPhaseByUUID(uuid: string): Observable<PhaseQrDto> {
        return this.get(this.base + "/exwork/open/phase/" + uuid);
    }
}
