import { Component, forwardRef, Input, Inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HelpPointDialogComponent } from './dialog/helppointdialog.component';
import { AlertController } from '@ionic/angular';
import { HomeController } from '@shared/src/controllers/home/home.controller';



export const NOSEPERQUESERVEIXB: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => HelpPointDialogComponent),
  multi: true
};

@Component({
  selector: 'helppoint',
  templateUrl: './shared.helppoint.component.html',
  styleUrls: ['./shared.helppoint.component.scss'],
  providers: [NOSEPERQUESERVEIXB]
})
export class SharedHelpPointComponent {

  @Input() icon: string = "fa fa-question-circle";
  @Input() helptitle: string = "";
  @Input() helptitleresource: string = "HELP.FIELD";
  @Input() paramstitle: any;
  @Input() helpresource: string = "";
  @Input() helptext: string = "";
  @Input() paramstext: any;
  @Input() expanded: boolean = false;


  public useIonic: boolean = false;

  public position = "below";

  constructor(private translate: TranslateService, private mdDialog: MatDialog, private alertController: AlertController, @Inject('HomeController') protected homecontroller: HomeController) {

    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  ngOnInit() {
  }

  clickHelpNoIonic() {
    this.mdDialog.open(HelpPointDialogComponent, {
      disableClose: false,
      width: '600px',
      //height: '300px',
      data: { title: this.gettitle(), text: this.gettext() }
    });

    this.mdDialog.afterAllClosed.subscribe(() => {

    });
  }

  async clickHelpIonic() {

    const alert = await this.alertController.create({
      //cssClass: 'my-custom-class',
      header: this.gettitle(),
      //subHeader: 'Subtitle',
      message: this.gettext(),
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


  gettext() {
    if (this.helptext != null && this.helptext != "")
      return this.helptext;

    if (this.helpresource != null && this.helpresource != "")
      return this.translate.instant(this.helpresource, this.paramstext);

    return "";
  }

  gettitle() {
    if (this.helptitle != null && this.helptitle != "")
      return this.helptitle;

    if (this.helptitleresource != null && this.helptitleresource != "")
      return this.translate.instant(this.helptitleresource, this.paramstitle);

    return "";
  }
}