import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';
import { PermissionGroupUserItemMaintenanceDto } from './PermissionGroupUserItemMaintenanceDto';
import { PermissionGroupUserListMaintenanceDto } from './PermissionGroupUserListMaintenanceDto';

@Injectable()
export class PermissionGroupUserSubMaintenanceController extends BaseSubMaintenanceController<PermissionGroupUserListMaintenanceDto, PermissionGroupUserItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "system/securityandnotifications/permissiongroup/user");
    }

    public getResourceRoot(): string {
        return "PERMISSION.GROUP.USER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/securityandnotifications/permissiongroup/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}