import { Injectable } from '@angular/core';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { encode } from 'base64-arraybuffer';

@Injectable()
export class FileUpDownLoadDto {

    public static readonly MAXSIZEFILE: number = 0;//5000000;
    /**
    * Nom fitxer
    */
    public fileName: string;

    /**
     * Tipus fitxer
     */
    public fileType: FileTypes;

    /**
     * Fitxer en format String
     */
    public fileString: string;

    /**
     * Fitxer en format byte
     */
    public fileBytes: any[];

    /**
    * Fitxer carregat correctament
    */
    public upLoaded: boolean;

    public loadType: string;
    public parameters: string[][];

    public static buildEmpty(): FileUpDownLoadDto {
        let result = new FileUpDownLoadDto();
        result.fileName = "";
        result.fileType = FileTypes.None;
        result.upLoaded = false;
        result.fileString = "";
        result.fileBytes = [];
        return result;
    }

    public static buildByFile(file: File, fileType: FileTypes): Promise<FileUpDownLoadDto> {
        const res = new Promise<FileUpDownLoadDto>(resolve => {
            const result = new FileUpDownLoadDto();
            result.fileName = file.name;
            result.fileType = fileType;
            result.upLoaded = false;

            this.getBytesArrayFromFile(file).then((byteArray) => {
                result.fileBytes = byteArray;
                this.getBase64EncodedFileData(file).then((fileString) => {
                    result.fileString = fileString;
                    resolve(result);
                });
            });
        });
        return res;
    }

    public static getBytesArrayFromFile(file: File): Promise<any[]> {
        const res = new Promise<any[]>(resolve => {
            const reader = new FileReader();
            reader.onload = (event: any) => {

                let bytes = 0;
                const byteArray = [];
                const t: any = reader.result;
                const array = new Uint8Array(t);
                for (let i = 0; i < array.length; ++i) {
                    byteArray.push(array[i]);
                    bytes = i;
                }
                resolve(byteArray);
            };
            reader.readAsArrayBuffer(file);
        });
        return res;
    }

    public static getBase64EncodedFileData(file: File): Promise<string> {
        const res = new Promise<string>(resolve => {
            const reader = new FileReader();

            reader.onload = () => {
                const { result } = reader;
                const data = result as ArrayBuffer; // <--- FileReader gives us the ArrayBuffer
                const base64Encoded = encode(data); // <--- convert ArrayBuffer to base64 string

                resolve(base64Encoded);
            };

            reader.readAsArrayBuffer(file);
        });
        return res;
    }

    public static getBytesArrayFromDataURI(dataURI: any[], fileType: FileTypes): Promise<any[]> {
        const res = new Promise<any[]>(resolve => {

            const byteArray = [];
            for (let i = 0; i < dataURI.length; ++i) {
                byteArray.push(dataURI[i]);
            }
            resolve(byteArray);
        });
        return res;
    }


    public static getByteArrayFromString(fileString: string): any[] {
        let bytes = 0;
        const byteArray = [];
        const array = FileUpDownLoadDto.getUint8ArrayFromString(fileString);
        for (let i = 0; i < array.length; ++i) {
            byteArray.push(array[i]);
            bytes = i;
        }
        return byteArray;
    }


    public static getUint8ArrayFromString(fileString: string): Uint8Array {

        if (fileString == null || fileString == "")
            return new Uint8Array(0);

        var byteCharacters = atob(fileString);

        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        return byteArray;
    }

    public static validateFileTransfer(item: DataTransferItem, fileTypes: FileTypes[]): boolean {
        if (item == null)
            return false;

        var filetype = this.getFileType(item.type);
        if (item.kind == "file" && filetype != FileTypes.None && (fileTypes == null || fileTypes.length == 0 || this.inFileTypes(filetype, fileTypes)))
            return true;

        return false;
    }

    public static validateFile(item: File, fileTypes: FileTypes[]): boolean {
        if (item == null)
            return false;

        var filetype = this.getFileType(item.type);
        if (filetype != FileTypes.None && (fileTypes == null || fileTypes.length == 0 || this.inFileTypes(filetype, fileTypes)))
            return true;

        var filetype = this.getFileTypeByName(item, fileTypes);
        if (filetype != FileTypes.None && (fileTypes == null || fileTypes.length == 0 || this.inFileTypes(filetype, fileTypes)))
            return true;

        return false;
    }

    public static getFileTypeByName(item: File, fileTypes: FileTypes[]): FileTypes {
        var filetype: FileTypes = FileTypes.None;
        if (item == null)
            return filetype;

        var name: string = item.name;
        fileTypes.forEach(element => {
            var stringtofind = "." + element.toString();
            if (name.indexOf(stringtofind) > 0) {
                filetype = element;
            }
        });

        return filetype;
    }


    public static validateSizeFile(f: FileUpDownLoadDto): boolean {
        if (f == null || f.fileBytes == null)
            return false;

        if (FileUpDownLoadDto.MAXSIZEFILE > 0 && f.fileBytes.length > FileUpDownLoadDto.MAXSIZEFILE) {
            return false;
        }
        return true;
    }

    public static isValidFileType(contentType: string): boolean {
        let fileType: FileTypes = this.getFileType(contentType);
        if (fileType == null || fileType == FileTypes.None)
            return false;
        return true;
    }

    public static inFileTypes(filetype: FileTypes, fileTypes: FileTypes[]) {

        for (let ft of fileTypes) {
            if (ft == filetype)
                return true;
        }
        return false;
    }

    public static getContentType(filetype: FileTypes): string {
        let contentType: string;
        if (filetype == FileTypes.xls) {
            contentType = 'application/vnd.ms-excel';
        } else if (filetype == FileTypes.csv) {
            contentType = 'text/csv';
        } else if (filetype == FileTypes.pdf) {
            contentType = 'application/pdf';
        } else if (filetype == FileTypes.doc) {
            contentType = 'application/msword';
        } else if (filetype == FileTypes.docx) {
            contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else if (filetype == FileTypes.xlsx || filetype == FileTypes.ods) {
            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (filetype == FileTypes.pptx) {
            contentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        } else if (filetype == FileTypes.ppt) {
            contentType = 'application/vnd.ms-powerpoint';
        } else if (filetype == FileTypes.txt) {
            contentType = 'text/plain';
        } else if (filetype == FileTypes.html) {
            contentType = 'text/html';
        } else if (filetype == FileTypes.zip) {
            contentType = 'application/zip';
        } else if (filetype == FileTypes.jpeg) {
            contentType = 'image/jpeg';
        } else if (filetype == FileTypes.jpg) {
            contentType = 'image/jpg';
        } else if (filetype == FileTypes.png) {
            contentType = 'image/png';
        } else if (filetype == FileTypes.gif) {
            contentType = 'image/gif';
        } else if (filetype == FileTypes.bmp) {
            contentType = 'image/bmp';
        } else if (filetype == FileTypes.json) {
            contentType = 'application/json';
        }

        return contentType;
    }

    public static getFileType(contentType: string): FileTypes {
        let filetype: FileTypes = FileTypes.None;
        switch (contentType) {
            case 'application/vnd.ms-excel':
                return FileTypes.xls;
            case "application/vnd.oasis.opendocument.spreadsheet":
                return FileTypes.ods;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return FileTypes.xlsx;
        }

        //TODO: Canviar els else-if al switch de dalt
        if (contentType == 'text/csv') {
            filetype = FileTypes.csv;
        } else if (contentType == 'application/pdf') {
            filetype = FileTypes.pdf;
        } else if (contentType == 'application/msword') {
            filetype = FileTypes.doc;
        } else if (contentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            filetype = FileTypes.docx;
        } else if (contentType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            filetype = FileTypes.pptx;
        } else if (contentType == 'application/vnd.ms-powerpoint') {
            filetype = FileTypes.ppt;
        } else if (contentType == 'text/plain') {
            filetype = FileTypes.txt;
        } else if (contentType == 'text/html') {
            filetype = FileTypes.html;
        } else if (contentType == 'application/zip') {
            filetype = FileTypes.zip;
        } else if (contentType == 'image/jpeg') {
            filetype = FileTypes.jpeg;
        } else if (contentType == 'image/jpg') {
            filetype = FileTypes.jpg;
        } else if (contentType == 'image/png') {
            filetype = FileTypes.png;
        } else if (contentType == 'image/gif') {
            filetype = FileTypes.gif;
        } else if (contentType == 'image/bmp') {
            filetype = FileTypes.bmp;
        } else if (contentType == 'application/json') {
            filetype = FileTypes.json;
        }

        return filetype;
    }

    public static isImageType(filetype: FileTypes): boolean {
        if (!filetype)
            return false;

        if (filetype == FileTypes.jpeg)
            return true;
        if (filetype == FileTypes.jpg)
            return true;
        if (filetype == FileTypes.png)
            return true;
        if (filetype == FileTypes.gif)
            return true;
        if (filetype == FileTypes.bmp)
            return true;

        return false;
    }

    public static isPdfType(filetype: FileTypes): boolean {
        if (!filetype)
            return false;

        if (filetype == FileTypes.pdf)
            return true;

        return false;
    }
}
