import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { TenantResourceItemMaintenanceDto } from './TenantResourceItemMaintenanceDto';
import { TenantResourceListMaintenanceDto } from './TenantResourceListMaintenanceDto';

@Injectable()
export class TenantResourceMaintenanceController extends BaseMaintenanceController<TenantResourceListMaintenanceDto, TenantResourceItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "tenantresource");
    }


    public getResourceRoot(): string {
        return "TENANTRESOURCE";
    }

    public buildUrl(uri: string): string {
        return "system/dataandparameters/tenantresource" + uri;
    }

    public getAll(): Observable<Array<TenantResourceListMaintenanceDto>> {
        return this.get(this.getBaseUrl() + "/all");
    }
}
