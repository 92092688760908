<div class="container component">

    <div class="header">
        <div class="title">{{'SHARED.ROUTEGROUP.PANEL.COMPONENT.CAPTION'| translate}}</div>
        <div class="buttonBack" (click)="onClickBack($event)">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </div>

    <div class="block" *ngIf="!doActionNewRouteGroup">

        <div class="links">
            <button-link variant="forward" class="line left" (linkClick)="onClickNewRouteGroup()">{{
                'SHARED.ROUTEGROUP.PANEL.COMPONENT.NEW' | translate}}</button-link>
        </div>

        <div class="line">
            <div>{{'SHARED.ROUTEGROUP.PANEL.COMPONENT.SEARCH'| translate}}</div>
            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchTextGroup">
        </div>
        <div class="line llista">
            <div class="overflow">
                <shared-route-item-list
                    *ngFor="let item of getSearchRoutesGroup() | filterActiveRoutes:searchTextGroup:filterMetadata | orderbyroutecarddto:true; let i=index"
                    class="routeItem"
                    [ngClass]="{'selected':selectedRouteGroup!=null && item.routeId==selectedRouteGroup.routeId }"
                    [route]="item" [isActiveRoutes]="'true'" (click)="selectedRouteGroup=item">
                </shared-route-item-list>
            </div>
        </div>

    </div>
    <div class="block" *ngIf="doActionNewRouteGroup">
        <button-link variant="back" class="line left back" (linkClick)="onClickBackNewRouteGroup($event)">{{
            'SHARED.ROUTEGROUP.PANEL.COMPONENT.BACK' | translate}}</button-link>
        <shared-route-group-panel-maintenance *ngIf="valueSelected != null" [value]="valueSelected"
            (finish)="onFinishRouteGroup($event)">
        </shared-route-group-panel-maintenance>
        <shared-route-group-importfile *ngIf="valueSelected == null"></shared-route-group-importfile>
    </div>

</div>