import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PanelColumnEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PanelColumnEntitySelectorDto{
        let result: PanelColumnEntitySelectorDto = new PanelColumnEntitySelectorDto();
		result.loadData(EntitysSelectors.PanelColumn, "", activeFilterKeys, 0, false);
        return result;
    }

	public getUrlGoToMaintenance(): string {
		return "system/screensandforms/panelsetting";
	}

	public getUrlBack(): string {
		return "/maintenance/panel/panelcolumn";
	}
  
}