import { ActionsStripDto } from "@shared/src/dtos/maintenance/action/ActionsStripDto";
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { PlateJourneyDetailDto } from "./PlateJourneyDetailDto";
import { PlateJourneyListItemDto } from "./PlateJourneyListItemDto";

export class PlateJourneyDto {

    public plateId: number;
    public momentStart: HDateHour;
    public momentEnd: HDateHour;

    public actual: PlateJourneyDetailDto;
    public list: Array<PlateJourneyListItemDto>;
    public actions: ActionsStripDto;


    public static build(plateId: number, momentStart: HDateHour, momentEnd: HDateHour): PlateJourneyDto {

        let result = new PlateJourneyDto();
        result.plateId = plateId;
        result.momentStart = momentStart;
        if (HDateHour.isNullOrNullValue(result.momentStart))
            result.momentStart = HDateHour.nullDateHour();
        result.momentEnd = momentEnd;
        if (HDateHour.isNullOrNullValue(result.momentEnd))
            result.momentEnd = HDateHour.nullDateHour();
        return result;
    }
}