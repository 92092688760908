import { Injectable } from '@angular/core';
import { RouteCardItemDto } from './RouteCardItemDto';

@Injectable()
export class RouteGroupDto {

    public routeGroup: RouteCardItemDto;

    public routesGroup: RouteCardItemDto[];

}
