import { Component, Input, OnInit } from '@angular/core';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';

@Component({
  selector: 'shared-generalstatus-container',
  templateUrl: './shared.generalstatus.container.component.html',
  styleUrls: ['./shared.generalstatus.container.component.scss']
})
export class SharedGeneralStatusContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }

  private _generalStatus: GeneralStatus;
  @Input() public set generalStatus(value: GeneralStatus) {
    if (this._generalStatus === value)
      return;
    this._generalStatus = value;
    this.setGeneralClass();
  }
  public get generalStatus(): GeneralStatus {
    return this._generalStatus;
  }

  public generalClass: String = "";
  private setGeneralClass() {
    this.generalClass = "";
    if (this._generalStatus != null) {
      this.generalClass = this._generalStatus;
    }
  }

}
