<div class="component component-fullpage" [ngClass]="{'useIonic': useIonic}">
    <div class="filter" [ngClass]="{'shipment-selected': selectedShipment}">
        <div class="search">
            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText"
                (ngModelChange)="this.searchTextChanged.next($event)">
        </div>
        <div class="inOutBounds">
            <div [ngClass]="{'active': filter.inBounds}" (click)="filter.inBounds = !filter.inBounds; getShipments()"
                class="chip">
                <div class="caption" matTooltip="{{ 'CLIETVIEWCOMPONENT.FILTER.INBOUNDS.HELP' | translate }}">
                    {{ 'CLIETVIEWCOMPONENT.FILTER.INBOUNDS.CAPTION' | translate }}
                </div>
                <div class="cercle-exterior"></div>
            </div>
            <div [ngClass]="{'active': filter.outBounds}" (click)="filter.outBounds = !filter.outBounds; getShipments()"
                class="chip">
                <div class="caption" matTooltip="{{ 'CLIETVIEWCOMPONENT.FILTER.OUTBOUNDS.HELP' | translate }}">
                    {{ 'CLIETVIEWCOMPONENT.FILTER.OUTBOUNDS.CAPTION' | translate }}
                </div>
                <div class="cercle-exterior"></div>
            </div>
        </div>
        <div class="lastMonth">
            <div [ngClass]="{'active': filter.lastMonth}" (click)="filter.lastMonth = !filter.lastMonth; getShipments()"
                class="chip">
                <div class="caption" matTooltip="{{ 'CLIETVIEWCOMPONENT.FILTER.LASTMONTH.HELP' | translate }}">
                    {{ 'CLIETVIEWCOMPONENT.FILTER.LASTMONTH.CAPTION' | translate }}
                </div>
                <div class="cercle-exterior"></div>
            </div>
        </div>
    </div>

    <div class="shipments-list" [ngClass]="{'shipment-selected': selectedShipment}"
        [ngClass]="{'shipments-list-ionic': useIonic}">
        <shared-clientprovider-view-shipment-card-component
            *ngFor="let item of shipments | orderclientproviderviewshipmentdto:true | filter:searchText:filterMetadata | slice:0:sliceCount"
            [shipment]="item" (click)="selectedShipment = item"
            [ngClass]="{'selected': selectedShipment && selectedShipment.shipmentId == item.shipmentId}">
        </shared-clientprovider-view-shipment-card-component>
        <button class="slice-button" (click)="onSliceMore()"
            *ngIf="sliceCount!=itemsCount && sliceCount<filterMetadata.count">
            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
        </button>
    </div>

    <div *ngIf="shipmentCard" class="shipment-info" [ngClass]="platform">
        <div class="back-button">
            <i (click)="selectedShipment = null" class="fa fa-times"></i>
        </div>
        <!-- CAPÇALERA GENERAL SHIPMENT SELECCIONAT -->
        <div class="header">
            <shared-clientprovider-view-shipment-card-component [shipment]="selectedShipment" [detailed]="true">
            </shared-clientprovider-view-shipment-card-component>
        </div>
        <div class="bar">
            <div class="baroptions">
                <options [optionstrip]="shipmentOptions" (clickOption)="onClickOption($event)"></options>
                <span *ngIf="selectedOption && !useIonic" class="selected-option">
                    {{ selectedOption.optionCaption | translate }}
                </span>
            </div>
        </div>
        <div class="content" *ngIf="selectedOption">
            <div *ngIf="selectedOption.optionId == 'INFO'" class="info">
                <div class="notes" [innerHTML]="shipmentCard.notesHtml" matTooltip="{{shipmentCard.notesText}}">
                </div>
                <div class="grup address origin">
                    <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.ORIGIN.CAPTION'| translate}}</div>
                    <div class="contingut">
                        <span class="pre-tags-strip">@</span>
                        <shared-tags-strip-component class="line tags" [uniqueString]="shipmentCard.originSkinAddress">
                        </shared-tags-strip-component>
                        <div class="line useraddress originUserAddress">{{shipmentCard.originAddress}}</div>
                        <div class="line realaddress originRealAddress">{{shipmentCard.originFormatedAddress}}</div>
                        <div *ngIf="originPhase && originPhase.phaseId" class="block distanceduration">
                            <table class="hardman" style="width:100%">
                                <tr>
                                    <th class="header blank"></th>
                                    <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.BEFORE' | translate}}
                                    </th>
                                    <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.ORIGIN' | translate}}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="header"></th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                                </tr>
                                <tr>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DISTANCE' | translate}}</th>
                                    <td class="right">{{originPhase.theoreticalDistance}}</td>
                                    <td class="right">{{originPhase.realDistance}}</td>
                                    <td class="right"></td>
                                    <td class="right">{{originPhase.theoreticalDistanceOrigin}}</td>
                                    <td class="right">{{originPhase.realDistanceOrigin}}</td>
                                    <td class="right"></td>
                                </tr>
                                <tr>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DURATION' | translate}}</th>
                                    <td class="right">{{originPhase.theoreticalDuration | time}}</td>
                                    <td class="right">{{originPhase.realDuration | time}}</td>
                                    <td class="right"></td>
                                    <td class="right">{{originPhase.theoreticalDurationOrigin | time}}</td>
                                    <td class="right">{{originPhase.realDurationOrigin | time}}</td>
                                    <td class="right"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="grup address destination">
                    <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.DESTINATION.CAPTION'| translate}}</div>
                    <div class="contingut">
                        <span class="pre-tags-strip">@</span>
                        <shared-tags-strip-component class="line tags"
                            [uniqueString]="shipmentCard.destinationSkinAddress">
                        </shared-tags-strip-component>
                        <div class="line useraddress destinationUserAddress">{{shipmentCard.destinationAddress}}</div>
                        <div class="line realaddress destinationRealAddress">{{shipmentCard.destinationFormatedAddress}}
                        </div>
                        <div *ngIf="destinationPhase && destinationPhase.phaseId" class="block distanceduration">
                            <table class="hardman" style="width:100%">
                                <tr>
                                    <th class="header blank"></th>
                                    <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.BEFORE' | translate}}
                                    </th>
                                    <th class="header" colspan="3">{{'SHARED.SLOT.PICKER.COMPONENT.ORIGIN' | translate}}
                                    </th>
                                </tr>
                                <tr>
                                    <th class="header"></th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.THEORETICAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.REAL' | translate}}</th>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DEVIATION' | translate}}</th>
                                </tr>
                                <tr>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DISTANCE' | translate}}</th>
                                    <td class="right">{{destinationPhase.theoreticalDistance| price}}</td>
                                    <td class="right">{{destinationPhase.realDistance| price}}</td>
                                    <td class="right"></td>
                                    <td class="right">{{destinationPhase.theoreticalDistanceOrigin| price}}</td>
                                    <td class="right">{{destinationPhase.realDistanceOrigin| price}}</td>
                                    <td class="right"></td>
                                </tr>
                                <tr>
                                    <th class="header">{{'SHARED.SLOT.PICKER.COMPONENT.DURATION' | translate}}</th>
                                    <td class="right">{{destinationPhase.theoreticalDuration | time}}</td>
                                    <td class="right">{{destinationPhase.realDuration | time}}</td>
                                    <td class="right"></td>
                                    <td class="right">{{destinationPhase.theoreticalDurationOrigin | time}}</td>
                                    <td class="right">{{destinationPhase.realDurationOrigin | time}}</td>
                                    <td class="right"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="grup goods">
                    <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.GOODS.CAPTION'| translate}}</div>
                    <div class="contingut">{{shipmentCard.goods}}</div>
                </div>
                <div class="grup comments">
                    <div class="titol">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.COMMENTS.CAPTION'| translate}}</div>
                    <div class="contingut">{{shipmentCard.comments}}</div>
                </div>
                <div class="grup conditions">
                    <div class="titol">
                        {{'SHARED.SHIPMENT.ITEM.MAINTENANCE.CONDITIONS.CAPTION'| translate}}
                    </div>
                    <shared-route-shipment-condition class="contingut" [value]="elem"
                        *ngFor="let elem of shipmentCard.conditions" [showRecipients]="false">
                    </shared-route-shipment-condition>
                </div>
            </div>
            <shared-shipment-item-form-maintenance *ngIf="selectedOption.optionId == 'LOADFORM'"
                [shipment]="shipmentCard" [formShipmentType]="'LoadForm'">
            </shared-shipment-item-form-maintenance>
            <shared-shipment-item-form-maintenance *ngIf="selectedOption.optionId == 'UNLOADFORM'"
                [shipment]="shipmentCard" [formShipmentType]="'UnLoadForm'">
            </shared-shipment-item-form-maintenance>
            <shared-route-item-log-maintenance *ngIf="selectedOption.optionId == 'LOG'" [shipment]="shipmentCard">
            </shared-route-item-log-maintenance>
            <shared-documentaryreview-documents-component class="block" *ngIf="'DOCUMENTS'==selectedOption.optionId" [parentObject]="parentObject" [readonly]="true">
            </shared-documentaryreview-documents-component>
            <shared-chat class="chats-body-zonachat chat-without-header" *ngIf="selectedOption.optionId == 'CHAT'"
                [forcedOwnerParticipant]="forcedOwnerParticipant">
            </shared-chat>
            <shared-track-page *ngIf="selectedOption.optionId == 'TRACKING'" class="tracking"
                [ngClass]="{'body-ionic': useIonic}" [shipmentId]="shipmentCard.shipmentId"></shared-track-page>
        </div>
    </div>
</div>