import { TableDto, TableSerieDTO } from "./TableDto";
import { SimpleTableDto, SimpleTableRowDto, SimpleTableColumnDto, SimpleTableValueDto, SimpleTableFrontDto, SimpleTableRowFrontDto } from "./SimpleTableDto";
import { TranslateService } from "@ngx-translate/core";
import { HTime } from "@shared/src/datatypes/HTime";
import { ChartOptions } from "chart.js";
import * as Chart from "chart.js";
import { HColor, HTranslateService } from "@shared/src/public-api";

/**
 * Funciona una mica raro, Aquesta clase s'utilitza dins de table-component i NO es un trasllat de la TableDTO del backend. Això carrega la TableDTO en una TableDto que si que es representable i ho fa partint d'un any que és el TableDTO que rebem del back
 */
export class ChartDto {


    public static PIE: string = "pie";
    public static LINE: string = "line";
    public static BAR: string = "bar";

    public legend: boolean = true;
    public options: ChartOptions = {
        responsive: true,
        spanGaps: true,
        showLines: false,
        maintainAspectRatio: true,
        legend: ChartDto.FULLLEGEND,
        plugins: {}
    };

    constructor() {
    }

    public static FULLLEGEND: Chart.ChartLegendOptions = {
        display: true,
    }
    public static NOLEGEND: Chart.ChartLegendOptions = {
        display: false
    }


    public noLegend(): ChartDto {
        this.options.legend = ChartDto.NOLEGEND;
        return this;
    }

    public noRatio(): ChartDto {
        this.options.maintainAspectRatio = false;
        return this;
    }

    public noAxisLabel(): ChartDto {
        this.options.scales = {
            yAxes: [{ ticks: { display: false } }],
            xAxes: [{ ticks: { display: false } }]
        };
        return this;
    }

    public setTitle(title: string): ChartDto {
        this.title = title;
        return this;
    }


    public static COLORSPIE: any = [
        { // grey
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // red
            backgroundColor: 'rgba(204, 0, 0,0.2)',
            borderColor: 'rgba(204, 0, 0,1)',
            pointBackgroundColor: 'rgba(204, 0, 0,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(204, 0, 0,0.8)'
        },
        { // blue
            backgroundColor: 'rgba(0, 51, 204,0.2)',
            borderColor: 'rgba(0, 51, 204,1)',
            pointBackgroundColor: 'rgba(0, 51, 204,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0, 51, 204,0.8)'
        }
    ];
    public static COLORS: Array<any> = [
        { // grey
            backgroundColor: 'rgba(148,159,177,0.2)',
            borderColor: 'rgba(148,159,177,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // red
            backgroundColor: 'rgba(204, 0, 0,0.2)',
            borderColor: 'rgba(204, 0, 0,1)',
            pointBackgroundColor: 'rgba(204, 0, 0,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(204, 0, 0,0.8)'
        },
        { // blue
            backgroundColor: 'rgba(0, 51, 204,0.2)',
            borderColor: 'rgba(0, 51, 204,1)',
            pointBackgroundColor: 'rgba(0, 51, 204,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(0, 51, 204,0.8)'
        }
    ];
    public title: String = "";
    public data: Array<number> = new Array();
    public datasets: Array<ChartSerieDto> = new Array();
    public chartType: string = "";;
    public labels: Array<string> = new Array();
    public backgroundColor: Array<string> = new Array();

    public static barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true
    };
    public static lineChartOptions: any = {
        responsive: true,
        spangaps: true,
        showlines: false,
        maintainaspectratio: true
    };

    public static build(chartType: string, table: TableDto): ChartDto {
        let result: ChartDto = new ChartDto();

        result.chartType = chartType;

        switch (chartType) {
            case 'pie':
                this.loadDataChart(result, table);
                break;
            case 'line':
            case 'bar':
                this.loadDataSetChart(result, table);
                break;
            default:
        }
        return result;
    }

    public static buildAndFilter(chartType: string, table: TableDto, filterId: string): ChartDto {
        let tableFiltered: TableDto = TableDto.buildAndFilter(table, filterId);
        return ChartDto.build(chartType, tableFiltered);
    }


    public static buildFromSimpleTable(chartType: string, table: SimpleTableDto, graphGroupId: string, translateService: HTranslateService): ChartDto {
        let result: ChartDto = new ChartDto();

        result.chartType = chartType;

        switch (chartType) {
            case 'pie':
                break;
            case 'line':
            case 'bar':
                this.loadDataSetChartFromSimpleTable(result, table, graphGroupId, translateService);
                break;
            default:
        }
        return result;
    }

    public static buildFromSimpleFrontTable(chartType: string, table: SimpleTableFrontDto, graphGroupId: string, translateService: HTranslateService): ChartDto {
        let result: ChartDto = new ChartDto();

        result.chartType = chartType;

        switch (chartType) {
            case 'pie':
                this.loadDataChartFromSimpleTableFront(result, table, graphGroupId, translateService);
                break;
            case 'line':
            case 'bar':
                this.loadDataSetChartFromSimpleTableFront(result, table, graphGroupId, translateService);
                break;
            default:
        }
        return result;
    }


    //  { data: [18, 48, 77, 9, 100, 27, 40], label: 'Mes' }
    private static loadDataChart(result: ChartDto, table: TableDto): ChartDto {
        let numSeries = 0;
        let serieId = "";

        if (!table || !table.series) {
            console.log("Error, CharDto.loadDataChart from null");
            return;
        }

        for (let key in table.series) {
            serieId = key;
            numSeries++;
        }
        if (numSeries != 1)
            console.log("Error, la taula ha de contenir una i només una sèrie");

        //Poso les labels a les labels
        result.labels = new Array<string>();
        for (let key in table.labels)
            result.labels.push(table.labels[key]);

        //poso la taula de valors coma vector
        result.data = new Array<number>();
        let serie: TableSerieDTO = table.series[serieId];
        for (let key in serie.values) {
            result.data.push(ChartDto.getValue(table.typeName, serie.values[key]));
        }
        result.backgroundColor = ChartDto.COLORSPIE;

        return result;
    }

    //  { data: [18, 48, 77, 9, 100, 27, 40], label: 'Mes' }
    private static loadDataSetChart(result: ChartDto, table: TableDto): ChartDto {

        if (!table || !table.series) {
            console.log("Error, CharDto.loadDataChart from null");
            return;
        }
        //Poso les labels a les labels
        //No podem assegurar l'ordre les les coses al accedir per javascript.
        //Per tant el que fem és: Si sabem que labels venen ordenats per la primera columna, i que és numéric, així que obtenim els valors i els ordenem numéricament.
        let unsortedArray: Array<number> = new Array();
        for (let key in table.labels)
            unsortedArray.push(+key);
        var sortedArray: Array<number> = unsortedArray.sort((n1, n2) => n1 - n2);

        result.labels = new Array<string>();
        for (let key in sortedArray) {
            result.labels.push(table.labels[sortedArray[key]]);
        }

        result.datasets = new Array<ChartSerieDto>();
        for (let serieId in table.series) {
            let serie: TableSerieDTO = table.series[serieId];
            let resultserie = new ChartSerieDto();
            resultserie.label = serie.serieName;
            result.datasets.push(resultserie);
            //poso la taula de valors coma vector
            for (let key in sortedArray) {
                resultserie.data.push(ChartDto.getValue(table.typeName, serie.values[sortedArray[key]]));
                //Genero array de BackgroundColor per poser posteriorment assignar color o no en funció de si es clica o no
                resultserie.pointBackgroundColor.push(undefined);
            }
        }

        this.setOptions(result);
        return result;
    }

    //  { data: [18, 48, 77, 9, 100, 27, 40], label: 'Mes' }
    private static loadDataSetChartFromSimpleTable(result: ChartDto, table: SimpleTableDto, graphGroupId: string, translateService: HTranslateService): ChartDto {

        if (!table || !table.rows) {
            console.log("Error, CharDto.loadDataChart from null");
            return;
        }
        //Poso les labels a les labels
        result.labels = new Array<string>();
        let columns: SimpleTableColumnDto[] = new Array();
        for (let columnId in table.columns) {
            let column: SimpleTableColumnDto = table.columns[columnId];
            columns.push(column);
            if (!column.noGraph && (column.graphGroupId == graphGroupId || graphGroupId === ""))
                result.labels.push(translateService.instant(column.caption));
        }

        result.datasets = new Array<ChartSerieDto>();
        for (let rowId in table.rows) {
            let row: SimpleTableRowDto = table.rows[rowId];
            let resultserie = new ChartSerieDto();
            resultserie.label = translateService.instant(row.caption);
            result.datasets.push(resultserie);
            //poso la taula de valors coma vector
            let i = 0;
            for (let valueId in row.values) {
                let column: SimpleTableColumnDto = columns[i];
                if (!column.noGraph && (column.graphGroupId == graphGroupId || graphGroupId === "")) {
                    if (row.values[valueId].isExplicitType)
                        resultserie.data.push(ChartDto.getSimpleValue(row.values[valueId].explicitTypeName, row.values[valueId]));
                    else
                        resultserie.data.push(ChartDto.getSimpleValue(column.typeName, row.values[valueId]));

                    //Genero array de BackgroundColor per poser posteriorment assignar color o no en funció de si es clica o no
                    resultserie.pointBackgroundColor.push(undefined);
                }
                i++;
            }
        }
        this.setOptions(result);
        return result;
    }

    //  { data: [18, 48, 77, 9, 100, 27, 40], label: 'Mes' }
    private static loadDataChartFromSimpleTableFront(result: ChartDto, table: SimpleTableFrontDto, graphGroupId: string, translateService: HTranslateService): ChartDto {
        let numSeries = 0;
        let serieId = "";

        if (!table || !table.rows) {
            console.log("Error, CharDto.loadDataChart from null");
            return;
        }

        for (let key in table.rows) {
            serieId = key;
            numSeries++;
        }
        if (numSeries != 1)
            console.log("Error, la taula ha de contenir una i només una sèrie");

        //Poso les labels a les labels
        result.labels = new Array<string>();

        for (let i = 0; i < table.columns.length; i++)
            if (!table.columns[i].noGraph && (table.columns[i].graphGroupId == graphGroupId || graphGroupId === ""))
                result.labels.push(translateService.instant(table.columns[i].caption));

        //poso la taula de valors coma vector
        result.data = new Array<number>();
        let serie: TableSerieDTO = table.rows[serieId];
        for (let i = 0; i < table.columns.length; i++) {
            if (!table.columns[i].noGraph && (table.columns[i].graphGroupId == graphGroupId || graphGroupId === ""))
                result.data.push(ChartDto.getSimpleValue(table.columns[i].typeName, table.rows[0].values[i]));
        }
        //result.backgroundColor = ChartDto.COLORSPIE;

        return result;
    }


    private static loadDataSetChartFromSimpleTableFront(result: ChartDto, table: SimpleTableFrontDto, graphGroupId: string, translateService: HTranslateService): ChartDto {

        if (!table || !table.rows) {
            console.log("Error, CharDto.loadDataChart from null");
            return;
        }
        //Poso les labels a les labels
        result.labels = new Array<string>();
        let columns: SimpleTableColumnDto[] = new Array();
        for (let columnId in table.columns) {
            let column: SimpleTableColumnDto = table.columns[columnId];
            columns.push(column);
            if (!column.noGraph && (column.graphGroupId == graphGroupId || graphGroupId === ""))
                result.labels.push(translateService.instant(column.caption));
        }

        result.datasets = new Array<ChartSerieDto>();
        for (let rowId in table.rows) {
            let row: SimpleTableRowFrontDto = table.rows[rowId];
            let resultserie = new ChartSerieDto();
            resultserie.label = translateService.instant(row.caption);
            result.datasets.push(resultserie);
            //poso la taula de valors coma vector
            let i = 0;
            for (let valueId in row.values) {
                let column: SimpleTableColumnDto = columns[i];
                if (!column.noGraph && (column.graphGroupId == graphGroupId || graphGroupId === "")) {
                    if (row.values[valueId].isExplicitType)
                        resultserie.data.push(ChartDto.getSimpleValue(row.values[valueId].explicitTypeName, row.values[valueId]));
                    else
                        resultserie.data.push(ChartDto.getSimpleValue(column.typeName, row.values[valueId]));

                    //Genero array de BackgroundColor per poser posteriorment assignar color o no en funció de si es clica o no
                    resultserie.pointBackgroundColor.push(undefined);
                }
                i++;
            }
        }
        this.setOptions(result);
        return result;
    }

    public static setOptions(result: ChartDto) {
        switch (result.chartType) {
            case 'line':
                result.options = ChartDto.lineChartOptions;
            case 'bar':
                result.options = ChartDto.barChartOptions;
                break;
            default:
        }
    }
    private static getSimpleValue(typeName: string, value: SimpleTableValueDto): number {
        if (typeName == 'HTime')
            return Math.round(HTime.toNumber(value.valueTime) * 100) / 100;
        if (typeName == 'Double' || typeName == "double")
            return value.valueDouble;
        else
            return value.valueInt;
    }


    private static getValue(typeName: string, value: any): number {
        if (typeName == 'HTime')
            return Math.round(HTime.toNumber(value) * 100) / 100;
        else
            return value;
    }
}

export class ChartSerieDto {
    data: Array<number> = new Array<number>();
    label: string = "";
    pointBackgroundColor: Array<any> = new Array<any>();
}