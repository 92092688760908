import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';
import { Observable } from 'rxjs';

import { PanelColumnListMaintenanceDto } from './PanelColumnListMaintenanceDto';
import { PanelColumnItemMaintenanceDto } from './PanelColumnItemMaintenanceDto';
import { PanelColumnActionDto } from '@shared/src/dtos/panel/column/PanelColumnActionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { PanelColumnConditionDto } from '@shared/src/dtos/panel/column/PanelColumnConditionDto';


@Injectable()
export class PanelColumnSubMaintenanceController extends BaseSubMaintenanceController<PanelColumnListMaintenanceDto, PanelColumnItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "panel/panelcolumn");
    }

    public getResourceRoot(): string {
        return "PANEL.PANELCOLUMN";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/screensandforms/panelsetting/" + parentId + "/" + optionParentid + "/submain" + uri;
    }

    //////////////////////////////////////////////////////
    //////////////////// ACCIONS //////////////////////
    //////////////////////////////////////////////////////	

    public getPanelColumnActionTypes(panelColumnId: number): Observable<ActionsStripDto> {
        return this.get(this.getBaseUrl(panelColumnId) + "/actionstypes");
    }

    public getPanelColumnActions(panelColumnId: number): Observable<Array<PanelColumnActionDto>> {
        return this.get(this.getBaseUrl(panelColumnId) + "/actions");
    }

    public createPanelColumnAction(panelColumnId: number, data: PanelColumnActionDto): Observable<PanelColumnActionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/actions/add", data, "createPanelColumnAction");
    }

    public clonePanelColumnAction(panelColumnId: number, data: PanelColumnActionDto): Observable<PanelColumnActionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/actions/clone", data, "clonePanelColumnAction");
    }

    public updatePanelColumnAction(panelColumnId: number, data: PanelColumnActionDto): Observable<PanelColumnActionDto> {
        return this.put(this.getBaseUrl(panelColumnId) + "/actions/" + data.panelColumnActionId, data, "updatePanelColumnAction");
    }

    public deletePanelColumnAction(panelColumnId: number, panelColumnActionId: number): Observable<boolean> {
        return this.delete(this.getBaseUrl(panelColumnId) + "/actions/" + panelColumnActionId);
    }

    public compileCodeAction(panelColumnId: number, data: PanelColumnActionDto): Observable<PanelColumnActionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/actions/" + data.panelColumnActionId + "/compilecode", data, "compileCodeAction");
    }


    //////////////////////////////////////////////////////
    //////////////////// CONDICIONS //////////////////////
    //////////////////////////////////////////////////////	

    public getPanelColumnConditionTypes(panelColumnId: number): Observable<ActionsStripDto> {
        return this.get(this.getBaseUrl(panelColumnId) + "/conditionstypes");
    }

    public getPanelColumnConditions(panelColumnId: number): Observable<Array<PanelColumnConditionDto>> {
        return this.get(this.getBaseUrl(panelColumnId) + "/conditions");
    }

    public createPanelColumnCondition(panelColumnId: number, data: PanelColumnConditionDto): Observable<PanelColumnConditionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/conditions/add", data, "createPanelColumnCondition");
    }

    public clonePanelColumnCondition(panelColumnId: number, data: PanelColumnConditionDto): Observable<PanelColumnConditionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/conditions/clone", data, "clonePanelColumnCondition");
    }

    public updatePanelColumnCondition(panelColumnId: number, data: PanelColumnConditionDto): Observable<PanelColumnConditionDto> {
        return this.put(this.getBaseUrl(panelColumnId) + "/conditions/" + data.panelColumnConditionId, data, "updatePanelColumnCondition");
    }

    public deletePanelColumnCondition(panelColumnId: number, panelColumnConditionId: number): Observable<boolean> {
        return this.delete(this.getBaseUrl(panelColumnId) + "/conditions/" + panelColumnConditionId);
    }

    public compileCodeCondition(panelColumnId: number, data: PanelColumnConditionDto): Observable<PanelColumnConditionDto> {
        return this.post(this.getBaseUrl(panelColumnId) + "/conditions/" + data.panelColumnConditionId + "/compilecode", data, "compileCodeCondition");
    }

}
