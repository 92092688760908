<div *ngIf="shipment">

    <div class="container-group">
        <div *ngIf="formShipmentType == 'LoadForm'" class="title-information">{{ 'SHIPMENTPAGE.ITEM.FORMS.LOADFORM' | translate }}</div>
        <div *ngIf="formShipmentType == 'UnLoadForm'" class="title-information">{{ 'SHIPMENTPAGE.ITEM.FORMS.UNLOADFORM' | translate }}</div>

        <div *ngFor="let addressQA of addressQuestionsAnswers; let index=index" >
            <div class="title-address">{{ addressQA.addressTitle }}</div>
            <shared-form-questions-answers-shipment *ngIf="addressQA.answers" [shipment]="shipment" [(questionsAnswers)]="addressQA.answers" [iReadonly]="true"> </shared-form-questions-answers-shipment>
        </div>
     </div>
   
</div>