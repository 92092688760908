import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { ManagementModelObjectParentDto } from '@shared/src/dtos/managementmodel/ManagementModelObjectParentDto';
import { OpenChangeManagementModelDto } from '@shared/src/dtos/open/OpenChangeManagementModelDto';

@Component({
  selector: 'shared-managementmodel-info',
  templateUrl: './shared.managementmodel.info.component.html',
  styleUrls: ['./shared.managementmodel.info.component.scss']
})
export class SharedManagementModelInfoComponent implements OnInit {

  constructor(private openRouteController: OpenRouteController) { }

  ngOnInit(): void { }

  @Input() public readonly: boolean;
  
  private _value: ManagementModelObjectParentDto;
  @Input() public set value(v: ManagementModelObjectParentDto) {
    if (v && this._value && JSON.stringify(v) == JSON.stringify(this._value))
      return;

    if (v) {
      this._managementModelId = v.managementModelId;
    }

    this._value = v;

  }

  public get value(): ManagementModelObjectParentDto {
    return this._value;
  }

  @Output() managementModelChanged: EventEmitter<Boolean> = new EventEmitter();
  public changeManagementModel: boolean = false;
  private _managementModelId: number;
  public set managementModelId(managementModelId: number) {
    if (managementModelId == this._managementModelId)
        return;

    this._managementModelId = managementModelId;

    if(this.value){
      this.openRouteController.setManagementModel(OpenChangeManagementModelDto.build(this.value.objectParentType, this.value.objectParentId, managementModelId)).subscribe(data => {
          if (data){
              this.managementModelChanged.next(data);
          }
      });
    }
  }

  public get managementModelId(): number {
      return this._managementModelId;
  }

  
  public haveTags(tags: HTags): boolean {
    if (HTags.isEmpty(tags))
        return false;
    return true;
  }
}
