import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';
import { Observable } from 'rxjs';

import { ManagementModelRequirementListMaintenanceDto } from './ManagementModelRequirementListMaintenanceDto';
import { ManagementModelRequirementItemMaintenanceDto } from './ManagementModelRequirementItemMaintenanceDto';


@Injectable()
export class ManagementModelRequirementSubMaintenanceController extends BaseSubMaintenanceController<ManagementModelRequirementListMaintenanceDto, ManagementModelRequirementItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "managementmodel/requirement");
    }

    public getResourceRoot(): string {
        return "MANAGEMENTMODEL.REQUIREMENT";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/screensandforms/managementmodel/" + parentId + "/" + optionParentid + "/submain" + uri;
    }

    public compileCode(data: ManagementModelRequirementItemMaintenanceDto): Observable<ManagementModelRequirementItemMaintenanceDto> {
        return this.post(this.getBaseUrl(data.managementModelId) + "/compilecode", data, "compileCode");
    }

}
