import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { HHourIntervals } from '../datatypes/HHourIntervals';

@Pipe({
	name: 'hourintervals'
})
@Injectable()
export class HourIntervalsFilter implements PipeTransform {
	transform(value: HHourIntervals): string {
		return HHourIntervals.toScreenString(value);
	}
}

