export enum FileTypes {
	xlsx = "xlsx",
	xls = "xls",
	csv = "csv",
	json = "json",
	pdf = "pdf",
	doc = "doc",
	docx = "docx",
	pptx = "pptx",
	ppt = "ppt",
	txt = "txt",
	zip = "zip",
	jpeg = "jpeg",
	jpg = "jpg",
	png = "png",
	gif = "gif",
	bmp = "bmp",
	ods = "ods",
	html = "html",
	xml = "xml",
	js = "js",
	None = "None"
}