<ng-container *ngIf="!phaseUUID">
    <div class="block-esquerra">
        <div class="header"></div>
        <div class="nouuid">
            No valid uuid
        </div>
    </div>
</ng-container>
<div class="container">
    <div class="block-esquerra">
        <div class="list childs" *ngIf="routePartCMRs != null">
            <div class="title">
                {{'SHARED.EXWORK.PHASE.CMRS.TITLE' | translate:{ value: getCount() } }}
            </div>
            <div *ngFor="let routePartCMR of routePartCMRs; let i = index;"> 
                <shared-route-part-cmr-qr-component [routePartCMR]="routePartCMR" [showcount]="true" [count]="i+1"></shared-route-part-cmr-qr-component>
            </div>
            <div class="dummy"></div>
        </div>
    </div>
</div>
<div class="signaturahardman">
    <img class="logoimg" src="/assets/images/HardmanLogoBlanc.png" alt="Hardman logo">
    <a href="https:hardman.app" target="_blank" class="powered">{{'GLOBAL.DESENVOLUPATPER' | translate}}</a>
</div>