<div *ngIf="useIonic && enable && parentObject != null" class="imatges" [ngClass]="{'hidden' : showingDocumentList}">
   <div class="body-images">
      <shared-images [prefixFileNameImage]="getPrefixFileNameImage()" (selectedImage)="onSelectedImage($event)"
         (initTakeImage)="onInitTakeImage($event)" [hideImage]="false" [miniImages]="'small'">
      </shared-images>

      <div *ngIf="imageList && imageList.length > 0">
         <button class="action upload-button" (click)="uploadPhotos()">{{
            'MOBILE.ROUTE.ACTIVE.SHIPMENT.UPLOADPHOTOS' | translate}}</button>

         <button class="action cancel-button" (click)="cancelPhotos()">{{
            'MOBILE.ROUTE.ACTIVE.SHIPMENT.CANCELPHOTOS' | translate}}</button>
      </div>
   </div>
</div>
<div *ngIf="parentObject != null" class="container" [ngClass]="{'ionic' : useIonic}">
   <div class="upload-zone" *ngIf="!readonly">
      <div *ngIf="parentObject.parentType == 'RoutePartCMR'" class="document-type" for="input-file-cmr">
         <div class="input-visual">{{ 'ROUTEDOCUMENTTYPES.CMR' | translate }}</div>
         <input multiple type="file" id="input-file-cmr" (change)="handleDocumentUpload($event.target.files, enumDocumentTypes.CMR)">
         <button class="boto" (click)="onDocumentUploadClicked(enumDocumentTypes.CMR)"></button>
      </div>
      <div *ngIf="parentObject.parentType != 'RoutePartCMR'" class="document-type" for="input-file-deliverynote">
         <div class="input-visual">{{ 'ROUTEDOCUMENTTYPES.DELIVERYNOTE' | translate }}</div>
         <input multiple type="file" id="input-file-deliverynote" (change)="handleDocumentUpload($event.target.files, enumDocumentTypes.DeliveryNote)">
         <button class="boto" (click)="onDocumentUploadClicked(enumDocumentTypes.DeliveryNote)"></button>
      </div>
      <div *ngIf="parentObject.parentType != 'RoutePartCMR'" class="document-type" for="input-file-image">
         <div class="input-visual">{{ 'ROUTEDOCUMENTTYPES.LOADTEST' | translate }}</div>
         <input multiple type="file" id="input-file-image" accept="image/*"
            (change)="handleDocumentUpload($event.target.files, enumDocumentTypes.LoadTest)">
         <button class="boto" (click)="onDocumentUploadClicked(enumDocumentTypes.LoadTest)"></button>
      </div>
      <div *ngIf="parentObject.parentType != 'RoutePartCMR'" class="document-type" for="input-file-image">
         <div class="input-visual">{{ 'ROUTEDOCUMENTTYPES.TRIPINCIDENT' | translate }}</div>
         <input multiple type="file" id="input-file-image" accept="image/*"
            (change)="handleDocumentUpload($event.target.files, enumDocumentTypes.TripIncident)">
         <button class="boto" (click)="onDocumentUploadClicked(enumDocumentTypes.TripIncident)"></button>
      </div>
      <div *ngIf="parentObject.parentType != 'RoutePartCMR'" class="document-type" for="input-file-image">
         <div class="input-visual">{{ 'ROUTEDOCUMENTTYPES.UNLOADTEST' | translate }}</div>
         <input multiple type="file" id="input-file-image" accept="image/*"
            (change)="handleDocumentUpload($event.target.files, enumDocumentTypes.UnloadTest)">
         <button class="boto" (click)="onDocumentUploadClicked(enumDocumentTypes.UnloadTest)"></button>
      </div>
   </div>
   <div class="documenttype-list">
      <span *ngIf="!existsDocument()" class="titlenodocuments">{{ 'SHIPMENTCONTROLLER.MAINTENANCE.DOCUMENTS.HISTORIC.NODOCUMENTS' | translate }}</span>
      <span *ngIf="existsDocument()" class="title">{{ 'SHIPMENTCONTROLLER.MAINTENANCE.DOCUMENTS.HISTORIC.CAPTION' | translate }}</span>
      <!--Llista de tipus de documents-->
      <div class="documentType hashistory" *ngFor="let documenttype of documents | sortDocumentType; let i=index">
         <div class="documentTypeItem">
            <span class="toggle-document" *ngIf="!documenttype.opened"
               title="{{ 'SHOWDOCUMENTHISTORY.OPEN' | translate }}" (click)="documenttype.opened=true"><i
                  class="fa fa-caret-right"></i></span>
            <span class="toggle-document" *ngIf="documenttype.opened" (click)="documenttype.opened=false"><i
                  class="fa fa-caret-down"></i></span>

            <span class="documenttype">{{ 'ROUTEDOCUMENTTYPES.' +
               documenttype.documentType.toUpperCase() | translate }}</span>

            <div class="document-list" *ngIf="documenttype.opened">
               <!--Llista de documents d'aquest tipus-->
               <div class="document"
                  [ngClass]="{'hashistory': documentHasHistory(document), 'hasdetail' : documentHasDetail(document)}"
                  *ngFor="let document of documenttype.documents | sortBy:'-createdAt'">
                  <div class="documentItem" [ngClass]="document.classDocument">

                     <span class="toggle-document" *ngIf="!document.opened"
                        title="{{ 'SHOWDOCUMENTHISTORY.OPEN' | translate }}" (click)="document.opened=true"><i
                           class="fa fa-caret-right"></i></span>
                     <span class="toggle-document" *ngIf="document.opened" (click)="document.opened=false"><i
                           class="fa fa-caret-down"></i></span>

                     <span class="filename">{{ document.fileName }}</span>
                     <span class="document-createdat">{{ document.createdAt | datehournotz
                        }}</span>
                     <span class="document-user">{{ document.userDescription }}</span>
                     <span (click)="downloadDocumentHist(document)" class="download"
                        title="{{ 'DOWNLOADDOCUMENTBUTTON.HELP' | translate }}"><i class="fa fa-cloud-download"></i></span>
                  
                     <shared-opendocument-component class="open-document" [parentObject]="parentObject" [document]="document"></shared-opendocument-component>
            
                  </div>
                  <div class="history" *ngIf="document.opened">
                     <!--Llista de versions d'aquest document-->
                     <div class="documentItem" [ngClass]="{'hasdetail': documentHistHasDetail(histDocument)}"
                        *ngFor="let histDocument of document.historic | sortBy:'-createdAt'">

                        <span class="toggle-history" *ngIf="!histDocument.opened"
                           title="{{ 'SHOWDOCUMENTHISTORY.OPEN' | translate }}" (click)="histDocument.opened=true"><i
                              class="fa fa-caret-right"></i></span>
                        <span class="toggle-history" *ngIf="histDocument.opened" (click)="histDocument.opened=false"><i
                              class="fa fa-caret-down"></i></span>

                        <span class="datetime">{{ histDocument.createdAt | datehournotz }}
                        </span>
                        <span class="user">{{ histDocument.userDescription }}</span>
                        <span (click)="downloadDocument(histDocument)" class="download"
                           title="{{ 'DOWNLOADDOCUMENTBUTTON.HELP' | translate }}"><i class="fa fa-cloud-download"></i></span>

                        <shared-opendocument-component class="open-document" [parentObject]="parentObject" [document]="histDocument"></shared-opendocument-component>

                        <div class="detail" *ngIf="histDocument.opened">
                           <!--Llista de detalls d'aquesta versio-->
                           <div class="documentItemDetail" [ngClass]="document.classDocument" *ngFor="let detail of histDocument.documentDetailList | sortBy:'-createdAt'">
                              <span class="datetime">{{ detail.createdAt | datehournotz }}
                              </span>
                              <span class="user">{{ detail.userDescription }}</span>
                              <span (click)="downloadDocumentDetail(detail)" class="download"
                                 title="{{ 'DOWNLOADDOCUMENTBUTTON.HELP' | translate }}"><i
                                    class="fa fa-cloud-download"></i></span>
                           
                              <shared-opendocument-component class="open-document" [parentObject]="parentObject" [documentDetail]="detail"></shared-opendocument-component>

                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="detail" *ngIf="document.opened">
                     <div class="documentItemDetail" [ngClass]="document.classDocument" *ngFor="let detail of document.documentDetailList | sortBy:'documentDetailId'">
                        <span class="datetime">{{ detail.createdAt | datehournotz }}
                        </span>
                        <span class="user">{{ detail.userDescription }}</span>
                        <span (click)="downloadDocumentDetail(detail)" class="download"
                           title="{{ 'DOWNLOADDOCUMENTBUTTON.HELP' | translate }}"><i class="fa fa-cloud-download"></i></span>

                        <shared-opendocument-component class="open-document" [parentObject]="parentObject" [documentDetail]="detail"></shared-opendocument-component>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>