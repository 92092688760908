import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { UserController } from '@shared/src/controllers/user/user.controller';
import { MobileAlertService } from '../../../services/mobile.alert.service';
import { MobileLoadingService } from '../../../services/mobile.loading.service';
import { LogController } from '@shared/src/controllers/log/LogController';

@Injectable({
  providedIn: 'root',
})
export class MobileSecurityController extends SecurityController {

  public locationError$: EventEmitter<boolean> = new EventEmitter();
  public MINUTES_BETWEEN_RELOADHOMES: number = 0;

  constructor(protected http: HttpClient,
    @Inject('AlertService') protected alertService: MobileAlertService,
    protected translateService: TranslateService,
    @Inject('LoadingService') protected loadingService: MobileLoadingService,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected dateAdapter: DateAdapter<MomentDateAdapter>,
    protected userController: UserController,
    public logController: LogController,
    public storage: Storage
  ) {
    super(http, alertService, translateService, loadingService, router, activeRoute, dateAdapter, userController, logController);
    this.storage.ready().then(() => {
      this.initializeSecurity();
    });
  }

  public getStorageValue(namevalue: string): Promise<any> {
    if (!this.storage) {
      return new Promise(resolve => {
        resolve(null);
      });
    }
    return this.storage.get(namevalue);
  }

  public setStorageValue(namevalue: string, value: any) {
    if (this.storage) {
      this.storage.set(namevalue, value);
    }
  }

  public removeStorageValue(namevalue: string) {
    if (this.storage) {
      this.storage.remove(namevalue);
    }
  }

  public stopGeolocation() {
    // this.backgroundGeolocation.removeAllListeners();
  }


}
