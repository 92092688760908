import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccessControlController } from '@shared/src/controllers/accesscontrol/accesscontrol.controller';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { LoadConsoleController } from '@shared/src/controllers/loadconsole/loadconsole.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { UserSettingController, UserSettingsEnum } from '@shared/src/controllers/user/usersetting.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { LoadConsolePhaseFilterDto } from '@shared/src/dtos/loadconsole/LoadConsolePhaseFilterDto';
import { LoadConsoleSlotPhaseInfoDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseInfoDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RouteLogDto } from '@shared/src/dtos/route/RouteLogDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { PhaseCardDto, RouteCardDto, RouteController, ShipmentController } from '@shared/src/public-api';
import { Subscription, timer as observableTimer } from 'rxjs';

@Component({
  selector: 'shared-loadconsole-phase-info-component',
  templateUrl: './shared.loadconsole.phase.info.component.html',
  styleUrls: ['./shared.loadconsole.phase.info.component.scss'],
})
export class SharedLoadConsolePhaseInfoComponent implements OnInit, OnDestroy {

  public useIonic: boolean = false;
  constructor(public router: Router, public phaseController: PhaseController, public shipmentController: ShipmentController,
    public openRouteController: OpenRouteController,
    protected slotsController: SlotsController, protected userSettingController: UserSettingController,
    protected routeController: RouteController, private loadConsoleController: LoadConsoleController, private accessControlController: AccessControlController, protected translateService: TranslateService,
    @Inject('SecurityController') public securityController: SecurityController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }


  @Input() public readonly: boolean = false;

  public __phaseInfo: LoadConsolePhaseFilterDto;
  @Input()
  set phaseInfo(value: LoadConsolePhaseFilterDto) {
    if (this.__phaseInfo === value)
      return;
    this.__phaseInfo = value;
    this.loadPhaseData();
  }
  get phaseInfo(): LoadConsolePhaseFilterDto {
    return this.__phaseInfo;
  }

  public __data: LoadConsoleSlotPhaseInfoDto;
  set data(value: LoadConsoleSlotPhaseInfoDto) {
    if (this.__data === value)
      return;
    this.__data = value;
  }
  get data(): LoadConsoleSlotPhaseInfoDto {
    return this.__data;
  }

  public states: StatusTransitionsDto;
  public actions: ActionsStripDto;
  public actionsGates: ActionsStripDto;
  public options: OptionsStripDto;
  public phase: PhaseCardDto;
  public phaseId: number;
  public slotInfo: SlotInfoDto;

  public forcedOwnerParticipant: ParticipantDto;
  public route: RouteCardDto;
  public routeId: number;
  public actualOptionId: string = "GENERAL";
  public routeLog: RouteLogDto[];
  public slotLog: SlotLogDto[];

  public getoutofall: boolean;
  public loadPhaseData() {
    this.phaseId = this.phaseInfo.phaseId;
    this.phaseController.getPhase(this.phaseId).subscribe(phase => {
      this.phase = phase;

      if (this.phase) {
        this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.phaseId, ChatParticipantTypes.Phase);
        this.routeId = this.phase.routeId;
        this.routeController.getRoute(this.routeId).subscribe(route => {
          this.route = route;
        });
        this.phaseController.getSlotInfo(this.phaseId).subscribe(data => {
          this.slotInfo = data;
        });
        this.phaseController.getPhaseItemMaintenanceCtrl(this.phaseId).subscribe(data => {
          if (data != null) {
            this.states = data.states;
            this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);
            if (this.options == null)
              this.options = this.buildOptions();
          }
        });
      }
    });
    if(!HLong.isNullOrNullLong(this.phaseInfo.loadConsoleId)){
      this.loadConsoleController.getPhaseInfo(this.phaseInfo).subscribe(data => {
        this.data = data;
        if (data != null) {
          this.actionsGates = ActionsStripDto.buildFromActionsStripDto(data.actionsGates);
          this.activaTimer();
        }
      });
    }
  }

  public opcio: string = "";
  public setOpcio(value: string) {
    if (this.opcio !== value) {
      this.opcio = value;
      this.userSettingController.setSetting(UserSettingsEnum.DefaultLoadConsolePhaseOption, this.opcio).subscribe(data => {
        if (this.opcio === "activeroutes")
          this.loadPhaseData();
      });
    }
  }

  public buildOptions(): OptionsStripDto {
    let result = new OptionsStripDto();
    result.add(OptionDto.build("GENERAL", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GENERAL.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GENERAL.HELP", "", 1, -1, 1, true, false));
    result.add(OptionDto.build("SLOTSHISTORY", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOTSHISTORY.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOTSHISTORY.HELP", "", 10, -1, 1, false, false));
    result.add(OptionDto.build("ROUTEHISTORY", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.HELP", "", 15, -1, 1, false, false));
    result.add(OptionDto.build("CHAT", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.HELP", "", 20, -1, 1, false, false));
    if (this.phase != null && this.phase.isGrouped)
      result.add(OptionDto.build("GROUPED", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.HELP", "", 20, -1, 1, false, false));
    result.add(OptionDto.build("MODIFYBANNER", "SHARED.ROUTE.CHANGEBANNERS.COMPONENT.TITLE", "SHARED.ROUTE.CHANGEBANNERS.COMPONENT.HELPSHORT", "", 30, -1, 1, false, false));

    this.actualOptionId = result.getActualSelected().optionId;
    return result;
  }

  ngOnInit(): void {
    this.userSettingController.getSetting(UserSettingsEnum.DefaultLoadConsolePhaseOption).subscribe(data => {
      if (data && data != "")
        this.opcio = data;
      else
        this.setOpcio("activeroutes");
    });
  }

  ngOnDestroy() {
    this.desactivaTimer();
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 60000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(SharedLoadConsolePhaseInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS, SharedLoadConsolePhaseInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.timerSubscription = timer.subscribe(t => {
      this.desactivaTimer();
      this.loadPhaseData();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
