<div class="container" *ngIf="value">
    <div class="line titol" *ngIf="showtitle">
        {{ 'SHARED.ROUTE.GROUP.TITLE' | translate }}
    </div>
    <div class="linia  baroptions">
        <options class="slim" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>
    <ng-container *ngIf="actualOptionId=='SHIPMENTS' && value.shipments && value.shipments.length > 0">
        <div class="shipment-item"
            *ngFor="let shipment of value.shipments | sortBy:'originAddressSkin,partnerClientTradeMark,shipmentKey'; let i=index">
            <div class="address-header"
                *ngIf="(i == 0 || shipment.originAddressSkin != value.shipments[i-1].originAddressSkin)">
                {{ shipment.originAddressSkin }}
            </div>
            <div class="client-header"
                *ngIf="(i == 0 || shipment.partnerClientId != value.shipments[i-1].partnerClientId)"
                (dblclick)="copy(shipment.partnerClientTradeMark)">
                <span *ngIf="shipment.partnerClientId">{{ shipment.partnerOrganizationName }} ({{
                    shipment.partnerClientKey }})</span>
            </div>
            <div class="line prompt">{{'SHARED.ROUTEGROUP.SHIPMENT.LIST.COMPONENT.SHIPMENTKEY' | translate}}</div>
            <div class="line"><span class="value" (dblclick)="copy(shipment.shipmentKey)">{{ shipment.shipmentKey
                    }}</span> <span class="description">{{ shipment.shipmentDescription }}</span></div>
            <div class="line description goods">{{ shipment.goods }}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="actualOptionId=='ROUTES' && value.routes && value.routes.length > 0">
        <div class="route-item"
        *ngFor="let route of value.routes | sortBy:'originAddressSkins,partnerClientsTradeMark,routeKey'; let i=index">
        <div class="address-header"
            *ngIf="(i == 0 || route.originAddressSkins != value.routes[i-1].originAddressSkins)">
            {{ route.originAddressSkins }}
        </div>
        <div class="client-header"
            *ngIf="(i == 0 || route.partnerClientsTradeMark != value.routes[i-1].partnerClientsTradeMark)">
            <span *ngIf="route.partnerClientsTradeMark">{{ route.partnerClientsTradeMark }}</span>
        </div>
        <div class="line prompt">{{'SHARED.ROUTEGROUP.SHIPMENT.LIST.COMPONENT.ROUTEKEY' | translate}}</div>
        <div class="line"><span class="value" (dblclick)="copy(route.routeKey)">{{ route.routeKey
                }}</span></div>
        <div class="line description goods">{{ route.goods }}</div>
    </div>
    </ng-container>
</div>