import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRShipmentDeliveryLineRestDto } from '@shared/src/dtos/route/cmr/RoutePartCMRShipmentDeliveryLineRestDto';
import { PalletTypes } from '@shared/src/enums/PalletTypes';

@Component({
    selector: 'shared-route-cmr-delivery-origin-line',
    templateUrl: './shared.route.cmr.delivery.origin.line.component.html',
    styleUrls: ['./shared.route.cmr.delivery.origin.line.component.scss']
})
export class SharedRouteCMRDeliveryOriginLineComponent implements OnInit {

    public useIonic: boolean = false;

    constructor(private fb: FormBuilder, private routeCMRController: RouteCMRController,
        @Inject("HomeController") protected homeController: HomeController,
        protected translateService: TranslateService) {
       

        if (homeController.useIonic) this.useIonic = homeController.useIonic();
    }

    private _lineObject: RoutePartCMRShipmentDeliveryLineRestDto;

    public get lineObject(): RoutePartCMRShipmentDeliveryLineRestDto {
        return this._lineObject;
    }

    @Input()
    public set lineObject(value: RoutePartCMRShipmentDeliveryLineRestDto) {
        if (this._lineObject === value)
            return;
        this._lineObject = value;
    }

    getResourcePalletType(type: PalletTypes): string{
        if(type == null || type == PalletTypes.None)
            return "";
        return this.translateService.instant("PALLETTYPES." + type.toUpperCase());
    }

    ngOnInit(): void {

    }

    pretty(data) {
        return JSON.stringify(data);
    }
}
