<div [formGroup]="rForm" class="general">
  <div *ngIf="!useIonic" class="noUseIonic">
    <div class="container">
      <mat-form-field class="selectbody" [ngClass]="{'readonly':readonly}" [floatLabel]="floatLabel">
        <input #input matInput [id]="identifier" [(ngModel)]="searchTextString"
          formControlName="entityselectorvalidator" [formControl]="selectorCtrl" (click)="onContainerClick($event)"
          [placeholder]="placeholder" style="width:100%" [readonly]="readonly" autocomplete="off"
          (blur)="onLostFocus()">
        <button mat-button *ngIf="value && !readonly" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="showgotomaintenance && showGoToMaintenanceInternal" class=" gomaintenance"
        (click)="goToMaintenance()">
        <i class="icon-awesome fa fa-arrow-right" aria-hidden="true"></i>
      </div>
    </div>
    <div class="list-container" *ngIf="showOpen">
      <div class="list-zone" *ngIf="showList" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()"
        [scrollWindow]="false">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="onLostFocus()"></i>
        <div *ngFor="let item of listitems | sortBy:'-__order'" (click)="itemSelected(item)"
          class="entity-selector-item">
          <div class="entity-selector-item-container">
            <div class="entity-selector-description"
              [ngClass]="{'delete':showdeleteitemback && showDeleteIemBackInternal && item.canDeleteItem}">
              <div class="key">{{item.key}}</div>
              <div class="firstColumn" *ngIf="item.firstColumn">{{item.firstColumn}}</div>
              <div class="description" *ngIf="item.secondColumn">{{item.secondColumn}}</div>
            </div>
            <div *ngIf="showdeleteitemback && showDeleteIemBackInternal && item.canDeleteItem"
              class="button entity-selector-delete" (click)="deleteIemBack(item)"><i class="icon-awesome fa fa-trash"
                aria-hidden="true"></i></div>
          </div>
        </div>

      </div>
      <div class="list-zone nollista" *ngIf="listitems!=null && listitems.length==0 && !loading">
        <i class="fa fa-times-circle" aria-hidden="true" (click)="onClose()"></i>
        <div *ngIf="searchTextString && searchTextString.length>=3"
          class="nollista-entity-selector-item nothingcompatible">
          {{ 'ENTITYSELECTOR.COMPONENT.NOTHINGCOMPATIBLE.LABEL' | translate: {value: !searchTextString ? "" :
          searchTextString } }}
        </div>
        <div *ngIf="!searchTextString || searchTextString.length<3"
          class="nollista-entity-selector-item nothingcompatible">
          {{ 'ENTITYSELECTOR.COMPONENT.WRITEMORECHARACTERSTOSEARCH.LABEL' | translate: {value: !searchTextString ? "" :
          searchTextString } }}
        </div>
        <div class="nollista-entity-selector-item additem" (click)="goToMaintenanceAdd()"
          *ngIf="showGoToMaintenanceInternal">
          {{ 'ENTITYSELECTOR.COMPONENT.ADD.LABEL' | translate: {value: !searchTextString ? "" : searchTextString } }}
          <span class="valuetoadd">{{ !searchTextString ? "" : searchTextString}}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="useIonic" class="useIonic">
    <div class="container">
      <!--A la app de moment no fem que es pugui filtrar-->
      <ion-input #input id="input" class="selectbody" [id]="identifier" [(ngModel)]="searchTextString"
        (click)="onContainerClick($event)" (ionBlur)="onLostFocus()" [readonly]="readonly"
        formControlName="entityselectorvalidator" [formControl]="selectorCtrl" autocomplete="off" ngDefaultControl>
      </ion-input>
      <i (click)="onClear()" aria-label="Clear" class="clear ionicicona fa fa-remove"></i>
      <!--A la app no hi han l'acció de keydown.enter, pel que necessitem posar una lupa per fer l'accio de busqueda-->
      <div id="button" class="search" (click)="searchText(true, false)">
        <i class="icon-awesome fa fa-search" aria-hidden="true"></i>
      </div>
      <!--A la app no hi han manteniments-->
      <!--<div *ngIf="showgotomaintenance && showGoToMaintenanceInternal" class=" gomaintenance" (click)="goToMaintenance()">
        <i class="icon-awesome fa fa-arrow-right" aria-hidden="true"></i>
      </div>-->
    </div>
    <div class="list-container">
      <div class="list-zone" *ngIf="showList" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
        <div *ngFor="let item of listitems | sortBy:'__order'" (click)="itemSelected(item)"
          class="entity-selector-item">
          <div class="entity-selector-item-container">
            <div class="entity-selector-description" [ngClass]="{'delete':false}">{{ getDisplayFromObject(item) }}</div>
            <!--A la app no deixem eliminar elements-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>