export enum HardmanRegisterSteps {
    RegisterUserInfo = "RegisterUserInfo",
    RegisterUserInfoInternal = "RegisterUserInfoInternal",
    RegisterAskUserType = "RegisterAskUserType",
    RegisterTenantInfo = "RegisterTenantInfo",
    RegisterWantToWorkWithYou = "RegisterWantToWorkWithYou",
    RegisterMakeYourselfVisible = "RegisterMakeYourselfVisible",
    RegisterAskUserDriver = "RegisterAskUserDriver",
    RegisterDrivers = "RegisterDrivers",
    RegisterProducts = "RegisterProducts",
    RegisterFinal = "RegisterFinal"
}