<div class="container">
    <div class="bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    </div>
    <div class="list childs" *ngIf="'CMR'==selectedOptionId && routeCMR != null">
        <shared-route-part-cmr-card *ngFor="let item of getRoutePartCMRs()" class="routePartCMRItem" [routePartCMR]="item"
            (click)="selectRoutePartCMR(item)"
            [ngClass]="{'selected': item.routePartCMRId==selectedRoutePartCMRId}">
        </shared-route-part-cmr-card>
    </div>
    <div class="list childs" *ngIf="'QR'==selectedOptionId && qrDto != null">
        <div *ngIf="qrDto.formatDocumentQRCode" class="codeqr" (click)="onClickQR()">
            <div class="img"><img [src]="qrDto.formatDocumentQRCode" /></div>
            <div class="title">{{'ROUTECMR.LIST.QR.GENERAL.TITLE' | translate }}</div>
        </div>

        <div class="qr-item" *ngFor="let item of qrDto.qrs">
            <div *ngIf="item.formatDocumentQRCode" class="codeqr" (click)="onClickPartQR(item)">
                <div class="img"><img [src]="item.formatDocumentQRCode" /></div>
                <div class="title">{{item.routePartCMRKey}}</div>
            </div>
        </div>
    </div>
</div>