import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoLoggedGuard, HardmanUserGuard, OwnerGuard } from '@shared/src/public-api';
import { OwnerOrHardmanUserGuard } from '../../../shared/src/controllers/security/OwnerOrHardmanUserGuard';
import { LogoutPage } from './logout/logout.page';
import { MobileSignPage } from './sign/mobile.sign.page';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'externallink', loadChildren: () => import('./external/mobile.external.module').then(m => m.MobileExternalPageModule) },
  { path: 'login', loadChildren: () => import('./login/mobile.login.module').then(m => m.MobileLoginPageModule), canActivate: [NoLoggedGuard] },
  { path: 'logout', component: LogoutPage },
  { path: 'notfound', loadChildren: () => import('./notfound/notfound.module').then(m => m.NotfoundPageModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'register/:join/:linkid', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'sign/:agent/uuid/:uuid', loadChildren: () => import('./sign/sign.module').then(m => m.SignModule) },
  { path: '**', redirectTo: 'notfound', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    NoLoggedGuard,
    HardmanUserGuard,
    OwnerGuard,
    OwnerOrHardmanUserGuard
  ]
})
export class AppRoutingModule { }
