import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ActivatedRoute } from '@angular/router';
import { MaintenanceSearchByTextDto } from '@shared/src/controllers/maintenance/_base/MaintenanceSearchByTextDtos';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'shared-chats',
  templateUrl: './shared.chats.html',
  styleUrls: ['./shared.chats.scss']
})
export class SharedChats implements OnInit, OnDestroy {

  public chats: ChatsDto;
  public contacts: ParticipantDto[];
  public selectedchat: ChatDto;
  public showdetail: boolean;

  public searchtext: string = "";

  public useIonic: boolean = false;

  public allChatsLoaded: boolean = false;
  public chatsList: ChatDto[] = [];

  searchTextChanged = new Subject<string>();

  constructor(protected activeroute: ActivatedRoute, private chatController: ChatController, @Inject('HomeController') public homeController: HomeController,
    @Inject('SecurityController') public securityController: SecurityController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();

    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.onSearchTextChange()
      });
  }

  ngOnInit() {

    if (this.homeController.CHATS != null && this.homeController.CHATS.chats != null)
      this.refreshChats(this.homeController.CHATS, true);

    this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
      if (data && data.chats)
        data.chats = data.chats.filter(item => ((item.ownerParticipantType.valueOf() == "Route" ||
          item.ownerParticipantType.valueOf() == "Phase") && item.tenantId == this.homeController.TENANDID) ||
          item.ownerParticipantType.valueOf() != "Route" && item.ownerParticipantType.valueOf() != "Phase");

      this.refreshChats(data, false);
      // this.onSelectedChat(this.selectedchat);
    });

    this.activeroute.paramMap.subscribe(params => {

      let participantType = ChatParticipantTypes[params.get("participanttype")];
      let participantId = parseInt(params.get("participantid"));
      if (!HLong.isNullOrNullLong(participantId) && participantType != null && participantType != ChatParticipantTypes.None) {
        this.clickContact(ParticipantDto.buildByParams(participantId, participantType));
      }

    });

  }

  ngOnDestroy() {
    this.onSelectedChat(null);
  }

  refreshChats(chats: ChatsDto, init: boolean) {
    // if (chats && this.chats && (chats.chats.length == 0 || this.chatsList.length == chats.chats.length))
    //   this.allChatsLoaded = true;

    this.chats = chats;
    if (chats && chats.chats)
      this.chatsList = [...chats.chats];
    else
      this.chatsList = [];
    this.showselectcontact = false;
    if (init && (this.chats == null || this.chatsList == null)) {
      this.showselectcontact = true;
    } else {
      if (this.selectedchat == null) {
        if (this.chatsList != null) {
          this.chatsList.forEach(chat => {
            if (this.chats.selectedChatId == chat.chatId)
              this.selectedchat = { ...chat };
          });
        }
      }
    }

  }


  private _showselectcontact: boolean = false;
  public set showselectcontact(showselectcontact: boolean) {
    if (this._showselectcontact === showselectcontact)
      return;
    this._showselectcontact = showselectcontact;
    this.onSearchTextChange();
  }
  public get showselectcontact(): boolean {
    return this._showselectcontact;
  }

  public primeraLlistaCarregadaContacts: boolean = false;
  public searchByTextContacts: MaintenanceSearchByTextDto;
  loadContacts() {
    this.chatController.getContacts(this.searchByTextContacts).subscribe(data => {
      this.contacts = data;
      //TODO: De moment ho descarraguem tot
      //this.primeraLlistaCarregadaContacts = true;
    });
  }

  clickContact(itemcontact: ParticipantDto) {
    let chat = ChatDto.build(this.chats, itemcontact);
    this.chatController.getOrCreateChat(chat).subscribe(data => {
      this.onSelectedChat(data);
    });
  }

  public isSelected(item: ChatDto): boolean {
    if (this.selectedchat != null && item != null)
      if (item.chatId == this.selectedchat.chatId)
        return true;
    return false;
  }

  public onSelectedChat(item: ChatDto) {
    if (this.chats != null) {
      if (item != null && !HLong.isNullOrNullLong(item.chatId)) {
        this.showselectcontact = false;
        this.chats.selectedChatId = item.chatId;
        this.chats.searchtext = this.searchtext;
        this.selectedchat = item;
        this.homeController.CHATS.selectedChatId = item.chatId;
        this.homeController.getChat(item.chatId);
        this.showdetail = true;
      }
      else {
        this.chats.selectedChatId = 0;
        this.homeController.CHATS = this.chats;
        this.selectedchat = null;
        this.showdetail = false;
        // this.refreshChats(this.chats, false);
      }
    }
  }

  // public searchtextkeypress(e) {
  //   var code = (e.keyCode ? e.keyCode : e.which);
  //   if (!e.shiftKey && code == 13) { //Enter keycode
  //     this.onSearchTextChange();
  //   }
  // }

  public onSearchTextChange() {
    if (this.showselectcontact) {
      this.searchByTextContacts = MaintenanceSearchByTextDto.buildPage(this.searchtext, [], 0, 20);
      this.contacts = new Array<ParticipantDto>();
      this.loadContacts();
    } else {
      if (this.chats != null) {
        this.chats.searchtext = this.searchtext;
        this.chats.nextPage = 0;
        this.allChatsLoaded = false;
        this.chats.primeraLlistaCarregada = false;
        this.homeController.refreshChatsByChats(this.chats);
      }
    }
  }

  /***
   * Busquem la següent pàgina
   */
  public onScrollContacts() {
    /*
        if (!this.primeraLlistaCarregadaContacts) //Només fem scroll després de la primera llista Carregada
          return;
  
        this.searchByTextContacts.page += 1;
        this.chatController.getContacts(this.searchByTextContacts).subscribe(data => {
          //A diferencia del loadContacts aquí tinc que fusionar les dues llistes
          if (this.contacts == null || this.contacts.length == 0) {
              this.contacts = data;
              return;
          }
  
          //Afegeixo a la meva llista aquells que m'han vingut del back i que no tinc a la meva llista
          data.forEach(v => {
              if (this.contacts.filter(f => { v.participantId == f.participantId && v.participantType == f.participantType }).length == 0) {
                  this.contacts.push(v);
              }
          });
        });
      */
  }

  /***
   * Busquem la següent pàgina
   */
  public onScrollChats() {
    if (this.chats == null || !this.chats.primeraLlistaCarregada || this.allChatsLoaded) //Només fem scroll després de la primera llista Carregada
      return;

    this.chats.searchtext = this.searchtext;
    this.chats.loadMoreChats = true;
    this.chats.nChatsLoaded = this.chatsList.length;
    this.homeController.refreshChatsByChats(this.chats);

  }

  public chatBack($event: ChatDto) {
    this.onSelectedChat(null);
  }

  public getResourceTitle() {
    if (this.showselectcontact)
      return 'CHATS.CONTACTS.CAPTION';
    return 'CHATS.CHATS.CAPTION';
  }
}


