import { Output, EventEmitter, HostBinding } from '@angular/core';
import { SlotDto } from '../../../dtos/address/slots/SlotDto';
import { Component, Input, OnInit, NgZone, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DragDropService } from '../../../services/dragdrop/DragDropService';
import { fromEvent as observableFromEvent } from 'rxjs';
import { SlotsController } from '../../../controllers/address/slots/slots.controller';
import { SlotActionDto } from '@shared/src/dtos/address/slots/actions/SlotActionDto';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { AddressTimetableIntervalPlanningSlotsDto } from '@shared/src/dtos/address/planning/AddressTimetableIntervalPlanningSlotsDto';
import { PhaseSlotDto } from '@shared/src/dtos/slot/PhaseSlotDto';

@Component({
  selector: 'shared-slot-planning',
  templateUrl: './shared.slot.planning.component.html',
  styleUrls: ['./shared.slot.planning.component.scss'],
})
export class SharedSlotPlanningComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('className') componentClass: string;

  constructor(private ele: ElementRef, public dragDropService: DragDropService, private ngZone: NgZone, public slotsController: SlotsController) {
    //https://stackoverflow.com/questions/43108155/angular-2-how-to-keep-event-from-triggering-digest-loop-detection-cycle
    this.ngZone.runOutsideAngular(() => {
      observableFromEvent(ele.nativeElement, "dragover")
        .subscribe((event: DragEvent) => {
          this.dragDropService.onDragOver(null, null, event);
        }
        );
    });
  }

  ngOnInit() { }

  getHostClass(): string {
    let result = '';
    if (!this.isAssigned)
      result += 'empty ';
    if (this.absoluteMode)
      result += 'absolute-mode ';
    return result;
  }

  onDragEnter(event: DragEvent, item: SlotDto) {
    if (!item)
      return;

  }
  onDragLeave(event: DragEvent, item: SlotDto) {
    if (!item)
      return;

  }

  public onDragStart(event: DragEvent, item: SlotDto) {

    DragDropService.moverOrCopy(event);
    event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_SLOTDTO);
    event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(item));
  }

  drop(event: DragEvent, item: SlotDto) {
    if (!item)
      return;

    if (this.isSlotDroppable)
      this.dragDropService.onDrop(DragDropService.extractDraggedObject(event), DragDropService.createDropObject(item, DragDropService.HARDMAN_SLOTDTO), event, this.onDropCallBack, this);
  }

  public onDropCallBack(draggedobject: any, ondropobject: any, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackparam?: any, result?: any) {
    if (callbackparam && callbackparam.onRefreshData) {
      if (result && callbackparam.setSlotPlanning != null) {
        callbackparam.setSlotPlanning(draggedobject);
      }
      callbackparam.onRefreshData.next();
    }
  }

  private _absoluteMode: boolean = false;
  @Input() public set absoluteMode(val: boolean) {
    this._absoluteMode = val;
    this.componentClass = this.getHostClass();
  }
  public get absoluteMode(): boolean {
    return this._absoluteMode;
  }

  @Input() addressTimetableInterval: AddressTimetableIntervalPlanningSlotsDto;

  private _slot: SlotDto;
  @Input() public set slot(value: SlotDto) {
    if (this._slot === value)
      return;
    this._slot = value;
    this.refreshProperties();
  }
  public get slot(): SlotDto {
    return this._slot;
  }

  private _action: string;
  @Input() public set action(value: string) {
    if (this._action === value)
      return;
    this._action = value;
    this.refreshProperties();
  }
  public get action(): string {
    return this._action;
  }

  private _dockToAssigned: DockDto;
  @Input() public set dockToAssigned(value: DockDto) {
    if (this._dockToAssigned === value)
      return;
    this._dockToAssigned = value;
  }
  public get dockToAssigned(): DockDto {
    return this._dockToAssigned;
  }

  setSlotPlanning(value: PhaseSlotDto) {
    if (this.slot == null)
      return;
    if (this.slot.slotPlanning === value)
      return;
    this.slot.slotPlanning = value;
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  @Output() onSlotSelected: EventEmitter<SlotDto> = new EventEmitter<SlotDto>();
  slotSelected(slotId: number) {
    this.onSlotSelected.next(this.slot);
  }

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();
  refreshData() {
    this.onRefreshData.next();
  }

  @Output() onEnlargeSlots: EventEmitter<any> = new EventEmitter<any>();
  enlargeSlots() {
    this.onEnlargeSlots.next();
  }

  @Output() onReduceSlots: EventEmitter<any> = new EventEmitter<any>();
  reduceSlot() {
    this.onReduceSlots.next();
  }
  removePhase() {
    if (this.action == PhaseSlotDto.ACTION_REMOVE && this.canAlterElement && this.isAssigned) {
      if(this.slot.phaseId > 0)
        this.slotsController.releaseSlot(SlotActionDto.build(this.slot.slotId, this.slot.phaseId, 0, 0, false), false).subscribe((data) => {
          this.refreshData();
        });
      else
        this.slotsController.releaseInvalidReservation(SlotActionDto.build(this.slot.slotId, 0, 0, 0, false), false).subscribe((data) => {
          this.refreshData();
        });
    }
    return false;
  }

  getPhaseSlot() {
    if (this.slot != null)
      return this.slot.slotPlanning;
    return null;
  }


  public isAssigned: boolean;
  setIsAssigned() {
    this.isAssigned = this.slot != null && this.slot.isAvailable === false;
  }

  public inAccessible: boolean;
  setInAccessible() {
    this.inAccessible = this.slot != null && this.slot.inAccessible === true;
  }

  public inActive: boolean;
  setInActive() {
    this.inActive = this.slot != null && this.slot.inActive === true;
  }

  public notRecommendable: boolean;
  setNotRecommendable() {
    this.notRecommendable = this.slot != null && this.slot.notRecommendable === true;
  }

  public isRemove: boolean;
  setIsRemove() {
    this.isRemove = this.action == PhaseSlotDto.ACTION_REMOVE && this.canAlterElement && this.isAssigned;
  }

  public isReduce: boolean;
  setIsReduce() {
    if (this.isAssigned)
      this.isReduce = false;
    else
      this.isReduce = this.action == PhaseSlotDto.ACTION_REDUCE && this.canAlterElement;
  }

  public isEnlarge: boolean;
  setIsEnlarge() {
    if (this.isAssigned)
      this.isEnlarge = false;
    else
      this.isEnlarge = this.action == PhaseSlotDto.ACTION_ENLARGE && this.canAlterElement;
  }

  public isLast: boolean;
  setIsLast() {
    this.isLast = this.slot != null && this.slot.islast;
  }

  public canAlterElement: boolean;
  setCanAlterElement() {
    this.canAlterElement = this.slot != null && this.slot.canalterelement;
  }

  public isSlotDraggable: boolean;
  setIsSlotDraggable() {
    this.isSlotDraggable = (this.action == PhaseSlotDto.ACTION_MOVE || this.action == PhaseSlotDto.ACTION_SWAP) && this.canAlterElement && this.isAssigned;
  }

  public isSlotDroppable: boolean;
  setIsSlotDroppable() {
    this.isSlotDroppable = (this.action == PhaseSlotDto.ACTION_MOVE || this.action == PhaseSlotDto.ACTION_ASSIGN || this.action == PhaseSlotDto.ACTION_NONACCEPTANCE || this.action == PhaseSlotDto.ACTION_RETURNS || this.action == PhaseSlotDto.ACTION_INBOUNDS) && this.canAlterElement;
  }

  refreshProperties() {
    this.setIsAssigned();
    this.setInAccessible();
    this.setInActive();
    this.setNotRecommendable();
    this.setCanAlterElement();
    this.setIsSlotDraggable();
    this.setIsSlotDroppable();
    this.setIsRemove();
    this.setIsReduce();
    this.setIsLast();
    this.setIsEnlarge();
  }
}
