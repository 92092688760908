export enum TenantSettings {
    FixImpossiblePhaseDates = "FixImpossiblePhaseDates",
    CarrierLastLoggingID = "CarrierLastLoggingID",
    UseDividedRouteLoad = "UseDividedRouteLoad",
    BlockPhaseNMinutesBefore = "BlockPhaseNMinutesBefore",
    BlockSlotChangesBetweenUsers = "BlockSlotChangesBetweenUsers",
    ShowSlotsNotAvailableWithCapacity = "ShowSlotsNotAvailableWithCapacity",
    ShowSlotsAlways = "ShowSlotsAlways",
    ShowSlotsSwaps = "ShowSlotsSwaps",
    ShowSlotsObtains = "ShowSlotsObtains",
    OrderPlatesByInternalId = "OrderPlatesByInternalId",
    ShowSlotsSwapsDifferentUsers = "ShowSlotsSwapsDifferentUsers",
    ShowSlotsObtainsDifferentUsers = "ShowSlotsObtainsDifferentUsers",
    ShowSlotNumberImpossibleRoutes = "ShowSlotNumberImpossibleRoutes",
    ShowSlotsNumberMinimum = "ShowSlotsNumberMinimum",
    ShowDoneRoutesBeforeHours = "ShowDoneRoutesBeforeHours",
    LoadUnloadDuration = "LoadUnloadDuration",
    CanSetDocksCarrier = "CanSetDocksCarrier",
    AutoSetArrived = "AutoSetArrived",
    MetersFromDestinationToAutoSetArrived = "MetersFromDestinationToAutoSetArrived",
    CancelRouteOnRouteLoadOnFutureSlot = "CancelRouteOnRouteLoadOnFutureSlot",
    CancelRouteOnRouteLoadOnPastSlot = "CancelRouteOnRouteLoadOnPastSlot",
    BackDaysOnRouteLoad = "BackDaysOnRouteLoad",
    DefaultLoadFormKeyOnShipments = "DefaultLoadFormKeyOnShipments",
    DefaultUnLoadFormKeyOnShipments = "DefaultUnLoadFormKeyOnShipments",
    DefaultMinTemperatureCentConditionOnShipments = "DefaultMinTemperatureCentConditionOnShipments",
    DefaultMaxTemperatureCentConditionOnShipments = "DefaultMaxTemperatureCentConditionOnShipments",
    DefaultMinRelativeHumidityPercConditionOnShipments = "DefaultMinRelativeHumidityPercConditionOnShipments",
    DefaultMaxRelativeHumidityPercConditionOnShipments = "DefaultMaxRelativeHumidityPercConditionOnShipments",
    DefaultFridgeConditionOnShipments = "DefaultFridgeConditionOnShipments",
    UseDefaultLoadFormKeyOnShipments = "UseDefaultLoadFormKeyOnShipments",
    UseDefaultUnLoadFormKeyOnShipments = "UseDefaultUnLoadFormKeyOnShipments",
    UseDefaultMinMaxTemperatureCentConditionOnShipments = "UseDefaultMinMaxTemperatureCentConditionOnShipments",
    UseDefaultMinMaxRelativeHumidityPercConditionOnShipments = "UseDefaultMinMaxRelativeHumidityPercConditionOnShipments",
    UseDefaultFridgeConditionOnShipments = "UseDefaultFridgeConditionOnShipments",
    DefaultOriginAddresKey = "DefaultOriginAddresKey",
    DefaultDestinationAddresKey = "DefaultDestinationAddresKey",
    PrivateIntervalsTag = "PrivateIntervalsTag",
    OutsidersCanSetRoutePromised = "OutsidersCanSetRoutePromised",
    OutsidersCanSetRouteCanceled = "OutsidersCanSetRouteCanceled",
    OutsidersCanSetRouteInprogress = "OutsidersCanSetRouteInprogress",
    OutsidersCanSetRouteDone = "OutsidersCanSetRouteDone",
    OutsidersCanSetRouteIncomplete = "OutsidersCanSetRouteIncomplete",
    TelemetryPriorityCargobull = "TelemetryPriorityCargobull",
    TelemetryPriorityCarrier = "TelemetryPriorityCarrier",
    TelemetryPrioritySensitech = "TelemetryPrioritySensitech",
    TelemetryPriorityHardmanT = "TelemetryPriorityHardmanT",
    TelemetryPriorityHardmanTZ = "TelemetryPriorityHardmanTZ",
    TelemetryPriorityManual = "TelemetryPriorityManual",
    TelemetryPriorityUser = "TelemetryPriorityUser",
    TelemetryPriorityCheckThreshold = "TelemetryPriorityCheckThreshold",
    ShipmentTemperatureTracking = "ShipmentTemperatureTracking",
    ShipmentSpeedTracking = "ShipmentSpeedTracking",
    ShipmentAltitudeTracking = "ShipmentAltitudeTracking",
    JourneyStopTime = "JourneyStopTime",
    JourneyStopDistanceMeters = "JourneyStopDistanceMeters",
    JourneyRestTime = "JourneyRestTime",
    JourneyRestDistanceMeters = "JourneyRestDistanceMeters",
    RouteProposeKey = "RouteProposeKey",
    RouteConsecutiveKey = "RouteConsecutiveKey",
    RouteKeyMask = "RouteKeyMask",
    ShipmentProposeKey = "ShipmentProposeKey",
    ShipmentConsecutiveKey = "ShipmentConsecutiveKey",
    ShipmentKeyMask = "ShipmentKeyMask",
    AnticipationTimeForRouteChanges = "AnticipationTimeForRouteChanges",
    TimetableForRouteChanges = "TimetableForRouteChanges",
    PhaseCommitmentDependingOnArrival = "PhaseCommitmentDependingOnArrival",
    PhaseCommitmentDependingOnCompromiseEnd = "PhaseCommitmentDependingOnCompromiseEnd",
    PhaseCommitmentDependingOnCompromiseStart = "PhaseCommitmentDependingOnCompromiseStart",
    TimeBetweenArrivalAndPhaseStart = "TimeBetweenArrivalAndPhaseStart",
    RouteLoadActivateObservable = "RouteLoadActivateObservable",
    RouteLoadSendMessageErrors = "RouteLoadSendMessageErrors",
    RouteLoadAsureRoutePhasesByBatch = "RouteLoadAsureRoutePhasesByBatch",
    DefaultHourSendMailDeliveryDelayedDaily = "DefaultHourSendMailDeliveryDelayedDaily",
    SendMailDeliveryDelayed = "SendMailDeliveryDelayed",
    StatisticsDeliveryFilterPositiveTags = "StatisticsDeliveryFilterPositiveTags",
    StatisticsDeliveryFilterNegativeTags = "StatisticsDeliveryFilterNegativeTags",
    StatisticsSlotsByTagsFilterPhaseTags = "StatisticsSlotsByTagsFilterPhaseTags",
    StatisticsSlotsByTagsFilterRouteTags = "StatisticsSlotsByTagsFilterRouteTags",
    StatisticsSlotsMovementsFilterPhaseTags = "StatisticsSlotsMovementsFilterPhaseTags",
    StatisticsSlotsMovementsFilterRouteTags = "StatisticsSlotsMovementsFilterRouteTags",
    StatisticsSlotsKPIFilterPositiveTags = "StatisticsSlotsKPIFilterPositiveTags",
    StatisticsSlotsKPIFilterNegativeTags = "StatisticsSlotsKPIFilterNegativeTags",
    PromisedStatusColor = "PromisedStatusColor",
    CanceledStatusColor = "CanceledStatusColor",
    ObsoleteStatusColor = "ObsoleteStatusColor",
    InprogressStatusColor = "InprogressStatusColor",
    DoneStatusColor = "DoneStatusColor",
    IncompleteStatusColor = "IncompleteStatusColor",
    ArrivedStatusColor = "ArrivedStatusColor",
    LoadedunloadedStatusColor = "LoadedunloadedStatusColor",
    LoadedStatusColor = "LoadedStatusColor",
    UnloadedStatusColor = "UnloadedStatusColor",
    LogInterestedFields = "LogInterestedFields",
    LogBlameInterestedFields = "LogBlameInterestedFields",
    None = "None",
}
