import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class ShipmentDeliveryLineEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): ShipmentDeliveryLineEntitySelectorDto {
		let result: ShipmentDeliveryLineEntitySelectorDto = new ShipmentDeliveryLineEntitySelectorDto();
		result.loadData(EntitysSelectors.Package, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "";
	}

	public getUrlBack(): string {
		return "/shipmentdeliveryline";
	}

}