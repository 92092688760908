import { Injectable, EventEmitter } from '@angular/core';
import { ResponseMessage } from '../../controllers/ResponseMessage';
import { MessageTypes } from '../../enums/MessageTypes';
import { ResponseData } from '../../controllers/ResponseData';
import { Observable, Subject } from 'rxjs';
import { AlertDto } from '../../dtos/alert/AlertDto';
import { AlertTypes, AlertTypesClass } from '../../enums/AlertTypes';
import { ResponseMessages } from '@shared/src/controllers/ResponseMessages';
import { MapMarcadorDto } from '../map/map.service';
/***
 * Definex el comportament abstracte de l'Alert Service.
 * Serveix per a que el BaseController pugui fer peticions a front d'alerta quan sigui necessari i que cada front esculli com fer-ho
 */
@Injectable({
    providedIn: 'root',
})
export abstract class AlertService {
    constructor() {

    }

    private subject = new Subject<AlertDto>();
    private keepAfterRouteChange = false;
    public exceptionMessage: String;

    public push(alert: AlertDto) {
        if (alert == null)
            return;
        if (this.alerts.length == 0) {
            this.alerts.push(alert);
            return;
        }
        //Mirem si l'últim és el mateix, alehosres no fem res;
        let ultim = this.alerts[this.alerts.length - 1]
        if (ultim.message !== alert.message || ultim.type !== alert.type)
            this.alerts.push(alert);
    }

    public alerts: AlertDto[] = [];
    public havePending(): boolean {
        if (this.alerts != null && this.alerts.length > 0)
            return true;
        return false;
    }

    public showAlert$: EventEmitter<any> = new EventEmitter();
    public showAlerts() {
        this.showAlert$.next();
    }

    /***
     * Es molt important entendre que això és una promesa i t'has de vincular.
     */
    copyToClipboard(text: string): Observable<boolean> {
        return new Observable(observer => {
            navigator.clipboard.writeText(text).then(x => {
                this.alert(AlertTypes.Info, "copied!", false);
                observer.next(true);
                observer.complete();
            }).catch(x => {
                observer.next(false);
                observer.complete();
            });

        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, keepAfterRouteChange = false) {
        this.alert(AlertTypes.Success, message, keepAfterRouteChange);
    }

    error(message: string, keepAfterRouteChange = false) {
        this.alert(AlertTypes.Error, message, keepAfterRouteChange);
    }

    info(message: string, keepAfterRouteChange = false) {
        this.alert(AlertTypes.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
        this.alert(AlertTypes.Warning, message, keepAfterRouteChange);
    }

    alert(type: AlertTypes, message: string, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        let alertdto = <AlertDto>{ type: type, message: message, id: Date.now().toString() };
        this.subject.next(alertdto);

        this.showPopUpAlert(alertdto);
    }
    alertByAlertDto(alertDto: AlertDto, keepAfterRouteChange = false) {
        if (alertDto == null || alertDto.type == null)
            return;
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next(alertDto);
        this.showPopUpAlert(alertDto);
    }

    clear() {
        // clear alerts
        this.subject.next();
    }

    clearAlerts() {
        if (this.alerts == null)
            return;
        this.alerts = [];
        this.clear();
    }

    showServerMessage<T>(response: ResponseData<T>, keepAfterRouteChange = false) {
        if (!response)
            return;

        let responsemessages: ResponseMessages = response.responseMessages;
        if (!responsemessages)
            return;

        let messages: ResponseMessage[] = responsemessages.messages;
        if (!messages || messages.length <= 0)
            return;

        for (let message of messages) {
            this.alertByAlertDto(ResponseMessage.getAlertDTO(message), keepAfterRouteChange);
        }
    }

    showError(error: any, keepAfterRouteChange = false) {

    }
    showPopUpAlert(alertdto: AlertDto) {

    }

    showPopupMapMarcadorDto(value: MapMarcadorDto) {

    }
}