import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { RoutePartnerExworkDto } from "@shared/src/dtos/route/partner/RoutePartnerExworkDto";
import { AlertService, HomeController, RouteController } from "@shared/src/public-api";
import { Subject } from "rxjs-compat";

@Component({
  selector: 'shared-route-exworks',
  templateUrl: './shared.route.exworks.component.html',
  styleUrls: ['./shared.route.exworks.component.scss']
})
export class SharedRouteExworksComponent implements OnInit {

  public exworkToggleChangeSubject: Subject<boolean> = new Subject<boolean>();
  public exworkChangeSubject: Subject<string> = new Subject<string>();


  constructor(@Inject("HomeController") protected homeController: HomeController,
    @Inject("AlertService") protected alertService: AlertService,
    protected openRouteController: OpenRouteController,
    protected routeController: RouteController) { }

  ngOnInit(): void {
  }

  public panelOpenState: boolean;

  @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public readonly: boolean;
  @Input() public value: RoutePartnerExworkDto;
  
  onRefresh(event){
    this.valueChange.next(true);
  }

  pretty(val: any): string {
    return JSON.stringify(val);
  }
}