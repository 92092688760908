
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class AmbitEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): AmbitEntitySelectorDto {
		let result: AmbitEntitySelectorDto = new AmbitEntitySelectorDto();
		result.loadData(EntitysSelectors.Ambit, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/securityandnotifications/ambit";
	}

	public getUrlBack(): string {
		return "/maintenance/system/securityandnotifications/ambit";
	}

}