import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityController } from './security.controller';

/***
 * Es el filtre de la seguretat. Assegura al cridar-se cada opció, si està protegida per aquest que es pot accedir-hi
 */
@Injectable()
export class NoLoggedGuard implements CanActivate {

  constructor(@Inject('SecurityController') protected securityController: SecurityController) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable(observer => {
      try {
        if(this.securityController.isLogged){
          this.securityController.isLogged().subscribe(value => {
            if (value) {
              observer.next(false);
              observer.complete();
              return;
            } else {
              observer.next(true);
              observer.complete();
              return;
            }
          });
        }
      }
      catch {
        observer.next(false);
        observer.complete();
      }
    });
  }
} 
