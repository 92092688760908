import { Injectable } from '@angular/core';

@Injectable()
export class Route_eCMRPartnerDTO {
	public tenantId: number;
	public name: string;
	public nif: string;
	public formattedAddress: string;
	public contactName: string;
	public contactPhone: string;
	public reset: boolean;

	public static build(tenantId: number, name: string, nif: string, formattedAddress: string, contactName: string, contactPhone: string): Route_eCMRPartnerDTO {
		let result: Route_eCMRPartnerDTO = new Route_eCMRPartnerDTO();
		result.tenantId = tenantId;
		result.name = name;
		result.nif = nif;
		result.formattedAddress = formattedAddress;
		result.contactName = contactName;
		result.contactPhone = contactPhone;
		return result;
	}

	public static buildReset(): Route_eCMRPartnerDTO {
		let result: Route_eCMRPartnerDTO = new Route_eCMRPartnerDTO();
		result.reset = true;
		return result;
	}
}