import { Output, EventEmitter } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { AddressController } from "../../../controllers/address/address.controller";
import { AddressTimetableIntervalDockDto } from "@shared/src/dtos/address/dock/AddressTimetableIntervalDockDto";
import { DockDto } from "@shared/src/dtos/address/dock/DockDto";
import { AddressDaySettingPlanningDockDto } from "@shared/src/dtos/address/dock/AddressDaySettingPlanningDockDto";
import { HColor } from "@shared/src/datatypes/HColor";

@Component({
  selector: "shared-docks-planning",
  templateUrl: "./shared.docks.planning.component.html",
  styleUrls: ["./shared.docks.planning.component.scss"],
})
export class SharedDocksPlanningComponent implements OnInit {
  constructor(private addressController: AddressController) { }

  ngOnInit() {

  }

  @Output() onRefreshInterval: EventEmitter<AddressTimetableIntervalDockDto> = new EventEmitter<AddressTimetableIntervalDockDto>();
  refreshInterval(value: AddressTimetableIntervalDockDto) {
    this.onRefreshInterval.next(value);
  }

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();
  refreshData() {
    this.onRefreshData.next();
  }

  @Output() onDockSelect: EventEmitter<boolean> = new EventEmitter<boolean>();
  dockSelect(value: boolean) {
    this.onDockSelect.next(value);
  }

  private _addressDaySetting: AddressDaySettingPlanningDockDto;
  @Input() public set addressDaySetting(value: AddressDaySettingPlanningDockDto) {
    if (this._addressDaySetting === value)
      return;
    this._addressDaySetting = value;
  }
  public get addressDaySetting(): AddressDaySettingPlanningDockDto {
    return this._addressDaySetting;
  }

  private _action: string;
  @Input() public set action(value: string) {
    if (this._action === value)
      return;
    this._action = value;
  }
  public get action(): string {
    return this._action;
  }

  getGeneralStyleInterval(value: AddressTimetableIntervalDockDto) {
    if (value != null && value.backColor != null && value.backColor != "")
      return { "backgroundColor": value.backColor };
    else
      return { "backgroundColor": "#ffffff" };
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  private _dockToAssigned: DockDto;
  @Input() public set dockToAssigned(value: DockDto) {
    if (this._dockToAssigned === value)
      return;
    this._dockToAssigned = value;
  }
  public get dockToAssigned(): DockDto {
    return this._dockToAssigned;
  }

  getTextColor(data: HColor) {
    if (data != null)
        return HColor.getWhiteBlackFromColor(data.web);

    return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
  }

  getBackColor(data: HColor) {
      return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
  }
}
