import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerClientPreferentMaintenanceController extends
    PartnerMaintenanceController {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router);
    }

    public getResourceRoot(): string {
        return "PARTNER.CLIENTPREFERENT";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/clientpreferent" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.CLIENTPREFERENT.LINKPERMISSION.FILTER"];
    }
}
