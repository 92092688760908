import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HLong } from '@shared/src/datatypes/HLong';
import { ShipmentCMRAdditionalDataDto } from '@shared/src/dtos/route/cmr/shipment/ShipmentCMRAdditionalDataDto';
import { ShipmentCMRController } from '@shared/src/controllers/route/cmr/shipmentcmr.controller';
import { HString } from '@shared/src/public-api';

@Component({
  selector: 'shared-shipment-cmr-edit-additionaldata-component',
  templateUrl: './shared.shipment.cmr.edit.additionaldata.component.html',
  styleUrls: ['./shared.shipment.cmr.edit.additionaldata.component.scss'],
})
export class SharedShipmentCMREditAdditionalDataComponent {

  constructor(private fb: FormBuilder,private shipmentCMRController: ShipmentCMRController,
    @Inject('HomeController') protected homeController: HomeController) {
    
      if (homeController.useIonic) 
        this.useIonic = homeController.useIonic();

      this.formGroup = fb.group({
        'handlingAndTransportation': ['', Validators.maxLength(512)],
        'applicant': ['', Validators.maxLength(512)],
        'conditions': ['', Validators.maxLength(512)],
      });
  };

  public formGroup: FormGroup;

  public useIonic: boolean = false;

  @Input() public readonly: boolean;

  private _shipmentId: number;
  @Input()
  public set shipmentId(value: number) {
      if (this._shipmentId === value)
          return;
      this._shipmentId = value;
      this.loadAdditionalData();    
  }
  public get shipmentId(): number {
      return this._shipmentId
  }

  public loadAdditionalData(){

    if(HLong.isNullOrNullLong(this.shipmentId)){
      this.value = null;
      return;
    }

    this.shipmentCMRController.getShipmentCMRAdditionalData(this.shipmentId).subscribe(data => {
		  if (data) {
			  this.value = data;
		  }else{
        this.value = null;
      }
		});

  }
  
  private _value: ShipmentCMRAdditionalDataDto;
  set value(value: ShipmentCMRAdditionalDataDto) {
    if (this._value === value)
      return;
    this._value = value;
  }

  get value(): ShipmentCMRAdditionalDataDto {
    return this._value;
  }

  ngOnInit() {

  }

  public isValid: boolean = false;
  ngAfterViewInit() {
    this.formGroup.valueChanges.subscribe((change) => {
      this.isValid = this.formGroup.valid;
    });
    this.formGroup.controls['handlingAndTransportation'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.handlingAndTransportation, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['applicant'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.applicant, change))
      this.pendingSave = true;
    });
    this.formGroup.controls['conditions'].valueChanges.subscribe((change) => {
      if(!HString.equalsNull(this.value.conditions, change))
      this.pendingSave = true;
    });  
  }

  public pendingSave: boolean;
  
  onSaveChanges() {
    if (this.value != null) {
      this.shipmentCMRController.saveShipmentCMRAdditionalData(this.value).subscribe(data => {
        if(data){
          this.value = data;
          this.pendingSave = false;
        }
      });
    }
    
  }
}
