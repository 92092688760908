<!-- date-picker.component.html!-->
<div [formGroup]="rFormDatePicker">
    <div *ngIf="!useIonic">
        <mat-form-field *ngIf="!readonly">
            <input matInput [id]="identifier" formControlName="dtpvalidator" [matDatepicker]="picker" autocomplete="off"
                [(ngModel)]="value" [placeholder]="placeholder" [max]="_max" [min]="_min" (dateChange)="onChange($event)" (keydown.enter)="keyEnter($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field *ngIf="readonly" class="readonly">
            <input matInput [id]="identifier" formControlName="dtpvalidator" [readonly]=true [matDatepicker]="picker"
                [(ngModel)]="value" [placeholder]="placeholder" [max]="_max" [min]="_min">
        </mat-form-field>
    </div>
    <div *ngIf="useIonic">
        <ion-item>
            <ion-datetime [id]="identifier" formControlName="dtpvalidator" ngDefaultControl [(ngModel)]="value"  [placeholder]="placeholder" 
            displayFormat="YYYY-MM-DD" [min]="_min" [max]="_max" (ionChange)="onChange($event)"></ion-datetime>
        </ion-item>
    </div>
</div>