
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class MaritimeAgencyEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): MaritimeAgencyEntitySelectorDto {
		let result: MaritimeAgencyEntitySelectorDto = new MaritimeAgencyEntitySelectorDto();
		result.loadData(EntitysSelectors.MaritimeAgency, "", activeFilterKeys, 0, true);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "";
	}

	public getUrlBack(): string {
		return "/maintenance/maritimeagency";
	}

}