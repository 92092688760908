import { TenantResourceListMaintenanceDto } from "./TenantResourceListMaintenanceDto";

export class TenantResourceItemMaintenanceDto extends TenantResourceListMaintenanceDto {

   public resourceCA: string;
   public resourceEN: string;
   public resourceES: string;
   public resourcePT: string;
   public resourceSV: string;

   public static getTranslation(item: TenantResourceItemMaintenanceDto, langCode: string): string {
      switch (langCode) {
         case "ca":
            return item.resourceCA;
         case "en":
            return item.resourceEN;
         case "es":
            return item.resourceES;
         case "pt":
            return item.resourcePT;
         case "sv":
         default:
            return item.resourceEN;
      }
   }
}
