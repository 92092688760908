<div *ngIf="routeDReviewObject">
    <div *ngIf="showbuttonback" class="buttonBack" (click)="onClickBack($event)">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>

    <div class="data-left">


    </div>

    <div class="data-right">
        <div class="data-tabs">
            <div class="tab-item route"
                [ngClass]="{'selected':selectedShipmentId==null || selectedShipmentId==0}" 
                (click)="selectRoute()">
                <span class="title">{{ 'DOCUMENTARYREVIEW.ROUTE.TAB' | translate}}</span>
            </div>

            <div class="tab-item shipment" *ngFor="let shipmentId of routeDReviewObject.shipmentIds; let i = index" 
                [ngClass]="{'selected': selectedShipmentId>0 && shipmentId==selectedShipmentId}"
                (click)="selectShipment(shipmentId)">
                <span class="title">{{ 'DOCUMENTARYREVIEW.SHIPMENT.TAB' | translate: {value: i+1 } }}</span>
            </div>
        
        </div>
        <div class="data-documentaryreview" *ngIf="dReviewObject != null">
            
            <shared-documentaryreview-list-ecmr-component class="block-data" [value]="dReviewObject" [readonly]="readonly"></shared-documentaryreview-list-ecmr-component >

            <shared-managementmodel-requirements-component class="block-data" *ngIf="dReviewObject.managementModel != null" [value]="dReviewObject.managementModel.requirementsStruct" [showorder]="false" [readonly]="readonly" (dataRefresh)="onDataRefresh($event)"></shared-managementmodel-requirements-component>
      
            <shared-documentaryreview-item-documents-component class="block-data" [doLoadDocuments]="false" [parentObject]="dReviewObject.parentObject" [documents]="dReviewObject.documents" (dataRefresh)="onDataRefresh($event)"></shared-documentaryreview-item-documents-component>

        </div>
    </div>
</div>