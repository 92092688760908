import { Component, Input, OnInit } from "@angular/core";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";

@Component({
    selector: 'shared-phase-timeline',
    templateUrl: './shared.phase.timeline.component.html',
    styleUrls: ['./shared.phase.timeline.component.scss']
})
export class SharedPhaseTimeLineComponent implements OnInit {

    @Input()
    phases: Array<PhaseCardDto>;

    @Input()
    actualphase: PhaseCardDto;

    public isPhaseActiva(p: PhaseCardDto): boolean {
        if (this.actualphase != null && p != null)
            return this.actualphase.phaseId === p.phaseId;
        return false;
    }
    ngOnInit(): void {
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }
}
