export enum SocketNotificationAction {
    reloadHome = "reloadHome",
    reloadMenu = "reloadMenu",
    reloadLite = "reloadLite",
    chatReceived = "chatReceived",
    interested = "interested",
    sendingCredentials = "sendingCredentials",
    datasetTimeline = "datasetTimeline",
    none = "none",
    message = "message"
}