import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirmationdialog-dialog',
  templateUrl: './confirmationdialog.dialog.component.html'
})
export class ConfirmationDialogDialogComponent {

  @Output() public onResolve: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<ConfirmationDialogDialogComponent>,
    private mdDialog: MatDialog) { }


  onClose() {
    this.onResolve.next(false);
    this.mdDialog.closeAll();
  }

  onConfirm() {
    this.onResolve.next(true);
    this.mdDialog.closeAll();
  }
}