import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PackageEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PackageEntitySelectorDto {
		let result: PackageEntitySelectorDto = new PackageEntitySelectorDto();
		result.loadData(EntitysSelectors.Package, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "";
	}

	public getUrlBack(): string {
		return "/package";
	}

}