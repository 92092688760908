import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { HDate } from '@shared/src/datatypes/HDate';
import { HHour } from '@shared/src/datatypes/HHour';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { HString } from '@shared/src/datatypes/HString';

@Component({
  selector: 'shared-chatitem',
  templateUrl: './shared.chat.item.html',
  styleUrls: ['../shared.chats.scss']
})
export class SharedChatItem implements OnInit {

  constructor(private translate: TranslateService) {

  }

  @Input() public showdetail: boolean;

  public isGroup: boolean;
  public participantavatar: ParticipantDto;
  public pendingMessagesRead: number;
  public participantkey: String = "";
  public participantAnnotation: String = "";
  public lastdatehourmessage: String = "";
  public lastmessage: String = "";

  private _value: ChatDto;
  @Input() set value(param: ChatDto) {
    if (param === this._value)
      return;
    this._value = param;
    this.loadDataFromValue();
  }
  get value() {
    return this._value;
  }

  private _ownerUserId: number;
  @Input() set ownerUserId(param: number) {
    if (param === this._ownerUserId)
      return;
    this._ownerUserId = param;
    this.loadDataFromValue();
  }
  get ownerUserId() {
    return this._ownerUserId;
  }


  loadDataFromValue() {
    this.participantkey = "";
    this.participantAnnotation = "";
    this.participantAnnotation = "";
    this.lastdatehourmessage = "";
    this.lastmessage = "";
    if (this.value != null && this.ownerUserId != null) {
      if (this.value.ownerParticipantId != this.ownerUserId) {
        if (this.value.ownerParticipant != null) {
          this.participantkey = this.value.ownerParticipant.skin;
          this.participantAnnotation = this.value.ownerParticipant.participantAnnotation;
        } else {
          this.participantAnnotation = this.getStringAllParticipants();
        }
        this.participantavatar = this.value.ownerParticipant;
      }
      else if (this.value.targetParticipantId != this.ownerUserId) {
        if (this.value.targetParticipant != null) {
          this.participantkey = this.value.targetParticipant.skin;
          this.participantAnnotation = this.value.targetParticipant.participantAnnotation;
          this.participantavatar = this.value.targetParticipant;
        } else {
          this.participantAnnotation = this.getStringAllParticipants();
        }
      }

      this.isGroup = this.isGroup = this.value != null && this.value.ownerParticipantType == ChatParticipantTypes.Group;

      this.pendingMessagesRead = this.value.pendingMessagesRead;

      this.lastmessage = this.value.lastMessage;
      let lastHour: HHour = null;
      let lastDate: HDate = null;
      this.lastdatehourmessage = "";

      if (this.value.lastDate != null)
        lastHour = HHour.build(this.value.lastHour);

      if (this.value.lastDate != null)
        lastDate = HDate.buildFromHDate(this.value.lastDate);

      if (lastHour != null || lastDate != null) {
        if (HDate.isNullOrNullValue(lastDate) || HDate.isGreaterEqualsThan(lastDate, HDate.today())) {
          this.lastdatehourmessage = HHour.getHourString(lastHour);
        } else {
          let yestarday = HDate.addDaysToHDate(lastDate, -1);
          if (HDate.equals(yestarday, lastDate)) {
            this.lastdatehourmessage = this.translate.instant("CHATS.YESTARDAY");
          }
          else {
            this.lastdatehourmessage = HDate.getDateString(lastDate);
          }
        }
      }
    }
  }

  getStringAllParticipants(): string {
    let all: string = "";

    if (this.value != null && this.value.participants != null && this.value.participants.length > 0) {
      this.value.participants.forEach(element => {

        if (element.participantType == ChatParticipantTypes.User || element.participantType == ChatParticipantTypes.Support) {
          if (!HString.isNullOrNullString(all)) {
            all += ", ";
          }
          all += element.participantAnnotation;
        }
      });
    }

    return all;
  }

  ngOnInit() {

  }

}


