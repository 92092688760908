
export class SetPlateGateDto {

	public accessControlId: number;
	public plate: string;
    public routeId: number;
    public phaseId: number;
    public entryGateId: number;
    public exitGateId: number;

	public static build(accessControlId: number, plate: string, routeId: number, phaseId: number, entryGateId: number, exitGateId: number): SetPlateGateDto{
		let result: SetPlateGateDto = new SetPlateGateDto();
		result.accessControlId = accessControlId;
		result.plate = plate;
		result.routeId = routeId;
		result.phaseId = phaseId;
		result.entryGateId = entryGateId;
		result.exitGateId = exitGateId;
		return result;
	}
}
