
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class GateEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): GateEntitySelectorDto {
		let result: GateEntitySelectorDto = new GateEntitySelectorDto();
		result.loadData(EntitysSelectors.Gate, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/gate";
	}

	public getUrlBack(): string {
		return "/maintenance/gate";
	}

}