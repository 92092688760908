
import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenChangeStatusDto } from '@shared/src/dtos/open/OpenChangeStatusDto';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { HttpClient } from '@angular/common/http';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { Router } from '@angular/router';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { HString } from '@shared/src/datatypes/HString';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { environment } from '@shared/src/environments/environment';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { RouteShipmentTypes } from '@shared/src/enums/RouteShipmentTypes';
import { HLong } from '@shared/src/datatypes/HLong';
import { ManualAddressDto } from '@shared/src/controllers/maps/ManualAddressDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { DocumentParentTypes } from '@shared/src/enums/DocumentParentTypes';

@Component({
    selector: 'shared-shipment-item-maintenance',
    templateUrl: './shared.shipment.item.maintenance.component.html',
    styleUrls: ['./shared.shipment.item.maintenance.component.scss']
})
export class SharedShipmentItemMaintenanceComponent implements OnInit {

    @Input() public readonly: boolean = false;
    public BASE_URI = "";

    public useIonic: boolean = false;
    constructor(public router: Router, public shipmentController: ShipmentController,
        public openRouteController: OpenRouteController,
        public routeController: RouteController,
        @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory,
        public httpClient: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homecontroller: HomeController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        })
        this.BASE_URI = environment.BASE_URI;
        if (homecontroller.useIonic)
            this.useIonic = homecontroller.useIonic();
    }

    @Input()
    public routeId: number;

    private _shipmentId: number;
    @Input()
    set shipmentId(value: number) {
        if (this._shipmentId === value)
            return;

        this._shipmentId = value;
        this.loadData();
    }
    get shipmentId(): number {
        return this._shipmentId;
    }

    public _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment === value)
            return;
        this._shipment = value;
        if (this._shipment != null)
            this._shipmentId = this._shipment.shipmentId;
        else
            this._shipmentId = null;

        this.loadData();
    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }
  
    @Input()
    public _phase: PhaseCardDto;
    @Input()
    set phase(value: PhaseCardDto) {
        if (this._phase == undefined) {
            this._phase = value;
            this.loadData();
        }
        if (this._phase.phaseId === value.phaseId)
            return;
        this._phase = value;
        this.loadData();
    }
    get phase(): PhaseCardDto {
        return this._phase;
    }

    public states: StatusTransitionsDto;
    public actions: ActionsStripDto;
    public options: OptionsStripDto;
    public link: string;
    public linkQR: string;

    public forcedOwnerParticipant: ParticipantDto;

    @Output() refreshShipment: EventEmitter<boolean> = new EventEmitter();
    public loadData() {
        if (HLong.isNullOrNullLong(this.shipmentId))
            return;

        this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.shipmentId, ChatParticipantTypes.Shipment);

        this.shipmentController.getShipmentItemMaintenanceCtrl(this.shipmentId, this.phase != null ? this.phase.phaseId : 0).subscribe(data => {
            if (data != null) {
                if (data.options != null)
                    this.options = OptionsStripDto.buildFromOptionsStripDto(data.options);
                if (data.actions != null)
                    this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);

                this.states = data.states;
                if (this.selectedOptionId === "")
                    this.selectedOptionId = data.optionId;

                if (this.shipment != null) {
                    this.link = this.BASE_URI + "/track/shipment/" + this.shipment.readUuid;
                    if (this.phase == null)
                        this.linkQR = this.BASE_URI + "/publicfile/cmr/shipment/" + this.shipment.readUuid;
                } else {
                    this.link = "";
                    if (this.phase == null)
                        this.linkQR = "";
                }
                
                this.loadParentObject();

                if (this.homecontroller.onConditionsUpdated$)
                    this.homecontroller.onConditionsUpdated$.next(this.routeId);
            }
        });

    }

    public parentObject: DocumentParentObjectDto;
    loadParentObject(){
        if(this.shipment == null || HLong.isNullOrNullLong(this.shipment.shipmentId))
            this.parentObject = null;
        else if(this.phase != null && !HLong.isNullOrNullLong(this.phase.routeId))
            this.parentObject = DocumentParentObjectDto.build(DocumentParentTypes.RouteShipment, this.phase.routeId, this.shipment.shipmentId, 0, 0);
        else if(!HLong.isNullOrNullLong(this.routeId))
            this.parentObject = DocumentParentObjectDto.build(DocumentParentTypes.RouteShipment, this.routeId, this.shipment.shipmentId, 0, 0);
        else
            this.parentObject = DocumentParentObjectDto.build(DocumentParentTypes.Shipment, 0, this.shipment.shipmentId, 0, 0);
    }

    clickParticipant(participant: ParticipantDto) {
        if (participant == null)
            return;
        this.router.navigateByUrl("/chat/" + participant.participantType + "/" + participant.participantId);
    }

    onMarkerDoubleClick(type: string) {
        if (this.shipment == null)
            return;
        if ("origin" == type)
            this.sharedAsideFactory.invokeAddress(this.shipment.originAddressId, this.shipment.originAddress);
        else if ("destination" == type)
            this.sharedAsideFactory.invokeAddress(this.shipment.destinationAddressId, this.shipment.destinationAddress);
    }

    onManualAddressMarkerDoubleClick(address: ManualAddressDto) {
        if (this.shipment == null)
            return;
        this.sharedAsideFactory.invokeAddress(address.id, address.formattedAddress);
    }

    onClickAction(action: ActionDto) {
        if (this.shipment != null) {
            if (action != null && action.actionId === "SHIPMENT.CREATE_AS_OBSERVER")
                this.shipmentController.setasobserver(this.shipment.shipmentId).subscribe(data => {
                    this.refreshShipment.next(true);
                });
            else if (action != null && action.actionId === "SHIPMENT.DELETE_AS_OBSERVER")
                this.shipmentController.unsetasobserver(this.shipment.shipmentId).subscribe(data => {
                    this.refreshShipment.next(true);
                });
            else if (action != null && action.actionId === "SHIPMENT.PDF.REGULAR")
                this.shipmentController.printpdf(this.shipment.shipmentId, false).subscribe(data => {
                    FileUpDownloadController.resultDownLoad(data);
                });
            else if (action != null && action.actionId === "SHIPMENT.PDF.EXTENDED")
                this.shipmentController.printpdf(this.shipment.shipmentId, true).subscribe(data => {
                    FileUpDownloadController.resultDownLoad(data);
                });
            else if (action != null && action.actionId.startsWith("SHIPMENT.PDF.PROVIDER"))
                this.shipmentController.printpdfByProviderAndDevice(this.shipment.shipmentId, action.actionCaption).subscribe(data => {
                    FileUpDownloadController.resultDownLoad(data);
                });
            else if (action != null && action.actionId == "SHIPMENT.SENDTRACK")
                this.shipmentController.sendTracking(this.shipment.shipmentId).subscribe(e => {
                });
            else if (action != null && action.actionId === "SHIPMENT.TRACK")
                window.open(this.link, "_blank");
            else if (action != null && action.actionId === "SHIPMENT.COPYTRACK")
                HString.copyMessage(this.link, this.alertService);
            else if (action != null && action.actionId === "SHIPMENT.QR")
                window.open(this.linkQR, "_blank");
            else if (action != null && action.actionId === "ROUTE.CREATENONACCEPTANCE")
                this.sharedAsideFactory.invokeCreateNonacceptance(RouteReturnInBoundOutBoundDelegatedDto.buildFromPhase(RouteShipmentTypes.Nonacceptance, this.phase)).then(value => { });
            else if (action != null && action.actionId === "ROUTE.CREATERETURN")
                this.sharedAsideFactory.invokeCreateReturn(RouteReturnInBoundOutBoundDelegatedDto.buildFromPhase(RouteShipmentTypes.Return, this.phase)).then(value => { });
        }
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option)
            this.selectedOptionId = option.optionId;
        else
            this.selectedOptionId = "";
    }

    onSelectorChangedState(state: GeneralStatus) {
        if (this.shipment != null) {
            this.shipment.generalStatus = state;
            this.openRouteController.shipmentSetStatus(this.shipment.shipmentId, OpenChangeStatusDto.build(state, this.phase != null ? this.phase.phaseId : 0)).subscribe(data => {
                this.refreshShipment.next(true);
            });
        }
    }

    onEditChanged() {
        this.refreshShipment.next(true);
    }

    ngOnInit(): void {

    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
