import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HHour } from '@shared/src/datatypes/HHour';
import { HTime } from '@shared/src/datatypes/HTime';


/**
 * Validador de temps, permet valors amb format ####:##
 */

@Directive({
  selector: '[validateTime][ngModel],[validateTime][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimeValidator), multi: true }
  ]
})
export class TimeValidator implements Validator {

  public constructor() { }


  public static validate(control: AbstractControl): ValidationErrors | null {
    if (!control)
      return TimeValidator.getInvalidResult();
    let value = control.value;
    if (!TimeValidator.isValid(value))
      return TimeValidator.getInvalidResult();
    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    if (value instanceof HTime)
      return true;

    if (value instanceof HHour)
      value = value.toString();

    if (typeof value === 'string' || value instanceof String)
      if (HTime.isValidFormat(value))
        return true;

    if (typeof value === 'object' || value instanceof Date) {
      var sign: string = "";
      var hour: number = null;
      var minute: number = null;
      if (value.s != undefined)
        sign = value.s;
      if (value.h != undefined)
        hour = value.h;
      if (value.m != undefined)
        minute = value.m;
      if (value.getSign != undefined)
        sign = value.getSign();
      if (value.getHours != undefined)
        hour = value.getHours();
      if (value.getMinutes != undefined)
        minute = value.getMinutes();

      if (hour != null || minute != null) {
        let htime = new HTime(sign, hour, minute);
        if (HTime.isValidFormat(htime.toString()))
          return true;
      }
    }


    return false;
  }


  public validate(c: AbstractControl): ValidationErrors | null {
    return TimeValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateTime: false
    };
  }
}