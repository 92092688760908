import { Component, Input, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ManagementModelController } from '@shared/src/controllers/managementmodel/managementmodel.controller';
import { ManagementModelRequirementObjectParentActionDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementObjectParentActionDto';
import { HDateHour, HEnum, HString, SharedAsideFactory, SharedOpenDocumentComponent } from '@shared/src/public-api';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { ManagementModelRequirementDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementDto';
import { ManagementModelRequirementActionTypes } from '@shared/src/enums/ManagementModelRequirementActionTypes';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentDto } from "@shared/src/dtos/document/DocumentDto";
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { ManagementModelChatInfoDto } from '@shared/src/dtos/managementmodel/ManagementModelChatInfoDto';
import { ManagementModelRequirementResponseLogDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementResponseLogDto';

@Component({
  selector: 'shared-managementmodel-requirement-item-component',
  templateUrl: './shared.managementmodel.requirement.item.component.html',
  styleUrls: ['./shared.managementmodel.requirement.item.component.scss'],
})
export class SharedManagementModelRequirementItemComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
    private managementModelController: ManagementModelController, private documentController: DocumentController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }


  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  
  public actions: ActionsStripDto;

  @Input() public readonly: boolean = false;
  @Input() public showorder: boolean = true;
  @Input() public order: number;

  public __value: ManagementModelRequirementDto;
  @Input()
  set value(value: ManagementModelRequirementDto) {
    if (this.__value === value)
      return;
    this.__value = value;
    this.loadValue();
  }
  get value(): ManagementModelRequirementDto {
    return this.__value;
  }

  ngOnInit() {

  }

  public openChat(event: any){
    if(event && event.stopPropagation)
      event.stopPropagation();
    this.sharedAsideFactory.invokeManagementModelChat(ManagementModelChatInfoDto.build(this.value)).then(value => {

    });
  }

  public loadValue(){

    if(this.value == null){
      this.actions = null;
      return;
    }

    this.actions = ActionsStripDto.buildFromActionsStripDto(this.value.actions);

  }

  public comments: string;

  public onClickEvent(event: any){
    if(event && event.stopPropagation)
      event.stopPropagation();
  }
  public onClickAction(action: ActionDto){
    if(action == null)
      return;
    
    this.onClickActionString(action.actionId, true);
    
  }

  public onClickActionStringEvent(saction: string, canaction: boolean, event: any){
    if(event && event.stopPropagation)
      event.stopPropagation();
    this.onClickActionString(saction, canaction);
  }
  public onClickActionString(saction: string, canaction: boolean){
    
    if(saction == null || !canaction)
      return;

    let actiontype: ManagementModelRequirementActionTypes = HEnum.getEnumFromString(saction, ManagementModelRequirementActionTypes);

    if(actiontype == ManagementModelRequirementActionTypes.DocumentKO){
        this.onShowCommentsClicked(saction, canaction);
    }else if(actiontype == ManagementModelRequirementActionTypes.DocumentUpLoad){
        this.onUploadClick();
    }else if(actiontype == ManagementModelRequirementActionTypes.DocumentView){
        this.onDocumentViewClick();
    }else
        this.onClickActionWithDocument(actiontype , null);
  }
  
 
  public onClickActionWithDocument(actiontype: ManagementModelRequirementActionTypes, document: DocumentDto){
    if(this.value == null)
      return;

    this.managementModelController.doActionRequirement(ManagementModelRequirementObjectParentActionDto.build(actiontype, this.comments, this.value.objectParentType, this.value.objectParentId, this.value.managementModelRequirementId, this.value.managementModelRequirementResponseId, document)).subscribe(data => {
      
      if(data){
        if(data.documentDownload){
          if(actiontype == ManagementModelRequirementActionTypes.DocumentDownLoad){
              data.documentDownload.documentDetailList.forEach(element => {
                FileUpDownloadController.resultDownLoadByParameters(element.fileName, element.fileString, element.fileType);
            });
          }
        }else
          this.onDataRefresh(true);
      }
    });
  }


  onUploadClick(){
    let inputfile = document.getElementById("inputlastfile");
    if (inputfile)
      inputfile.click();
  }
  @ViewChild('openlastdocument') private openlastdocument: SharedOpenDocumentComponent;
  onDocumentViewClick(){
    if (this.openlastdocument && this.openlastdocument.openDialog)
      this.openlastdocument.openDialog();
  }

  public commentsActionType: ManagementModelRequirementActionTypes
  public commentsTitle: string;
  public showOpenComments: boolean = false;
  onCommentExecuteActionClicked() {
    this.onHideComments();
    if(this.commentsActionType != ManagementModelRequirementActionTypes.None)
      this.onClickActionWithDocument(this.commentsActionType, null);
    
  }
  onHideComments(){
    let tancar = document.getElementById("tancar-comments");
    if (tancar){
        tancar.click();
        this.commentsActionType = ManagementModelRequirementActionTypes.None;
        this.commentsTitle = "";
    }
  }
 
  onShowCommentsClicked(saction: string, canaction: boolean) {
    if(canaction){
      this.commentsActionType = HEnum.getEnumFromString(saction, ManagementModelRequirementActionTypes);
      this.commentsTitle = 'MANAGEMENTMODELREQUIREMENT.OPTION.' +  saction.toLocaleUpperCase() + '.CAPTION';
      this.showOpenComments = !this.showOpenComments;
    }
  }

  public __showOpenLogs: boolean;
  set showOpenLogs(value: boolean) {
    if (this.__showOpenLogs === value)
      return;
    this.__showOpenLogs = value;
    this.logs = null;
    if(this.__showOpenLogs)
      this.loadLogs();
  }
  get showOpenLogs(): boolean {
    return this.__showOpenLogs;
  }
  public logs: ManagementModelRequirementResponseLogDto[];
  public loadLogs(){
    if(this.value == null || this.value.managementModelRequirementResponseId == null)
      return;
      if(this.value.managementModelRequirementResponseId > 0){
        this.managementModelController.getLogsByRequirementResponseId(this.value.managementModelRequirementResponseId).subscribe(data => {
          this.logs = data;
        });
      }else
        this.logs = new Array();
  }
  onHideLogs(){
    let tancar = document.getElementById("tancar-logs");
    if (tancar){
        tancar.click();
    }
  }
 
  onShowLogsClicked() {
    this.showOpenLogs = !this.showOpenLogs;
  }
    
  public onDataRefresh(refreshData: boolean) {
    if (refreshData)
      this.dataRefresh.next(refreshData);
  }

  public handleDocumentUpload(files: FileList) {

      if (files.length > 0) {

          DocumentDto.buildByFilesName(files, FileUpDownLoadDto.getFileType(files[0].type)).then(value => {

            this.onClickActionWithDocument(ManagementModelRequirementActionTypes.DocumentUpLoad, value);

          });

      }
  }

  existsDocument() {
    if (this.value == null || this.value.documents == null || this.value.documents.length === 0)
        return false;

    return true;
  }

  hideLastChanged():boolean{
    if(this.value == null)
      return true;

    if(HDateHour.isNullOrNullValue(this.value.lastMoment) && HString.isNullOrNullString(this.value.lastUserName))
      return true;

    return false;
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
