import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HardmanActivityTypes } from '@shared/src/enums/HardmanActivityTypes';
import { TenantInfoController } from '@shared/src/controllers/tenant/tenantinfo.controller';
import { PaymentMethodDto } from '@shared/src/dtos/billing/PaymentMethodDto';
import { AlertService } from '@shared/src/services/alert/alert.service';

@Component({
  selector: 'shared-paymentmethod-component',
  templateUrl: './shared.paymentmethod.component.html',
  styleUrls: ['./shared.paymentmethod.component.scss']
})
export class SharedPaymentMethodComponent implements OnInit {

  constructor(@Inject('HomeController') protected homecontroller: HomeController, public tenantInfoController: TenantInfoController, private fb: FormBuilder, @Inject("AlertService") private alertService: AlertService) {

    this.paymentmethodFormGroup = fb.group({
      'cardNumber': ['', [Validators.required, Validators.maxLength(16)]],
      'expMonth': ['', [Validators.required, Validators.maxLength(2)]],
      'expYear': ['', [Validators.required, Validators.maxLength(4)]],
      'cvc': ['', [Validators.required, Validators.maxLength(3)]]
    });
  }

  public paymentmethodFormGroup: FormGroup;
  public enumCompanyActivityTypes = HardmanActivityTypes;

  public numero_tarjeta: string;
  public exp_month: string;
  public exp_year: string;
  public cvc: string;

  @Input()
  public readonly: boolean = false;

  ngOnInit() { }

  private _paymentMethod: PaymentMethodDto;
  @Input() set paymentMethod(value: PaymentMethodDto) {
    if (this._paymentMethod === value)
      return;
    this._paymentMethod = value;
  }

  get paymentMethod(): PaymentMethodDto {
    return this._paymentMethod;
  }

  @Output()
  public onValidationChange: EventEmitter<boolean> = new EventEmitter();


  public isValid: boolean = false;
  ngAfterViewInit() {
    this.paymentmethodFormGroup.valueChanges.subscribe((change) => {
      this.isValid = this.paymentmethodFormGroup.valid;
    })
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }

  newPaymentMethod() {
    if (this.paymentMethod != null)
      this.paymentMethod.isValid = false;
  }

  savePaymentMethod() {
    (<any>window).Stripe.card.createToken({
      number: this.numero_tarjeta,
      exp_month: this.exp_month,
      exp_year: this.exp_year,
      cvc: this.cvc
    }, (status: number, response: any) => {
      if (status === 200) {
        let token = response.id;
        this.paymentMethod.token = token;
        this.paymentMethod.cardId = response.card.id;
        this.paymentMethod.cardLast4 = response.card.last4;
        this.paymentMethod.cardExpMonth = response.card.exp_month;
        this.paymentMethod.cardExpYear = response.card.exp_year;
        this.tenantInfoController.modifyPaymentMethod(this.paymentMethod).subscribe((data: PaymentMethodDto) => {
          this.paymentMethod = data;
        });
      } else {
        this.alertService.error(response.error.message);
        console.log(response.error.message);
      }
    });
  }
}
