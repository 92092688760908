<div class="encolumnat">
    <h1 class="titol">{{ 'REGISTERCOMPONENT.USER_INFO.TITLE' | translate }}</h1>
    <h2 class="texte">{{ 'REGISTERCOMPONENT.USER_INFO.SUBTITLE' | translate }}</h2>

    <form class="form" [formGroup]="registerFormGroup">
        <input-component class="col-x" [(ngModel)]="registerData.userregister.eMail"
            (ngModelChange)="emailChangedEvent()" placeholder="{{'REGISTERCOMPONENT.EMAIL.LABEL' | translate}}"
            name="email" formControlName="eMail" autocomplete="on"></input-component>
        <div class="col-x">
            <enum-selector class="col col-prefix-phone" [(ngModel)]="prefixModel" [readonly]="false"
                [enumvalues]="enumPrefixes" placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}"
                name="prefix" [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
                formControlName="prefix">
            </enum-selector>
            <!-- <input-component class="col col-prefix-phone" [(ngModel)]="registerData.userregister.prefix"
                placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}" formControlName="prefix"
                autocomplete="on"></input-component> -->
            <input-component class="col col-xprefix" [(ngModel)]="registerData.userregister.phone"
                (ngModelChange)="phoneChangedEvent()" placeholder="{{'REGISTERCOMPONENT.USER_PHONE.LABEL' | translate}}"
                name="phone" formControlName="phone" autocomplete="on"></input-component>
        </div>
        <div class="col-x">
            <input-component [(ngModel)]="registerData.userregister.fullName"
                placeholder="{{'REGISTERCOMPONENT.USER_FULL_NAME.LABEL' | translate}}" name="given-name"
                formControlName="fullName" autocomplete="on"></input-component>
        </div>
        <div class="terms">
            <div class="col-sm-12 checkbox">
                <checkbox-component [(ngModel)]="registerData.userregister.acceptProductConditions"
                    label="{{'REGISTERCOMPONENT.ACCEPT_TERMS.LABEL' | translate}}"
                    formControlName="acceptProductConditions" class="required"></checkbox-component>
            </div>
            <div class="col-sm-12 link">
                <a href="https://hardman.app/politica-de-privacidad-de-hardman-app/"
                    target="_blank">{{'REGISTERCOMPONENT.ACCEPT_TERMS.LINK' | translate }}</a>
            </div>
            <div class="col-sm-12 link">
                <button class="link" (click)="onLoginButtonClick()">{{'REGISTERCOMPONENT.LOGIN.LINK' |
                    translate}}</button>
            </div>
        </div>

        <div class="button-zone">
            <button mat-flat-button color="primary" class="action seguent sol"
                [disabled]="!canRegister() || pendigResponse" (click)="next()">{{'REGISTERCOMPONENT.NEXTBUTTON.LABEL' |
                translate}}</button>
        </div>
    </form>
</div>