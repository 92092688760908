import { Pipe } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { ClientProviderViewShipmentDto } from '../dtos/shipment/ClientProviderViewShipmentDto';

@Pipe({
	name: "orderclientproviderviewshipmentdto"
})
export class OrderClientProviderViewShipmentDto {
	transform(array: Array<ClientProviderViewShipmentDto>, asc: boolean): Array<ClientProviderViewShipmentDto> {
		if (array == null)
			return null;

		array.sort((a: ClientProviderViewShipmentDto, b: ClientProviderViewShipmentDto) => {
			if (asc)
				return OrderClientProviderViewShipmentDto.compareAny(a, b);

			return OrderClientProviderViewShipmentDto.compareAny(b, a);
		});
		return array;
	}

	public static compareAny(a: ClientProviderViewShipmentDto, b: ClientProviderViewShipmentDto): number {

		//Primer han d'anar els que tenen la marca de callInToDock o callOutToDock
		if (a.delayed && !b.delayed)
			return -1;
		else if (!a.delayed && b.delayed)
			return 1;
		else
			return this.validateDateHour(a, b);
	}

	public static validateDateHour(a: ClientProviderViewShipmentDto, b: ClientProviderViewShipmentDto): number {
		let av = a.eta;
		if (HDateHour.isNullOrNullValue(av))
			av = a.lastMoment;

		let bv = b.eta;
		if (HDateHour.isNullOrNullValue(bv))
			bv = b.lastMoment;

		if (HDateHour.isNullOrNullValue(av) && !HDateHour.isNullOrNullValue(bv))
			return 1;
		else if (!HDateHour.isNullOrNullValue(av) && HDateHour.isNullOrNullValue(bv))
			return -1;
		else if (HDateHour.isGreaterThan(av, bv))
			return 1;
		else if (HDateHour.isMinorThan(av, bv))
			return -1;
		else
			return (a.customerReference + a.shipmentKey) > (b.customerReference + b.shipmentKey) ? 1 : -1;
	}
}
