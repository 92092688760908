import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'shared-imageslidermobile-dialog',
  templateUrl: 'shared.imageslidermobile.dialog.html',
  styleUrls: ['./shared.imageslidermobile.dialog.scss']
})
export class SharedImageSliderMobileDialog {

  constructor(private mdDialogRef: MatDialogRef<SharedImageSliderMobileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {

  }
  
  onClose() {
    this.mdDialogRef.close();
  }

}
