import { Component, Inject, Input } from '@angular/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';
import { PhaseSlotDto } from '@shared/src/dtos/slot/PhaseSlotDto';
import { PhaseCardDto, SecurityController } from '@shared/src/public-api';

@Component({
  selector: 'shared-slot-viewer-component',
  templateUrl: './shared.slot.viewer.component.html',
  styleUrls: ['./shared.slot.viewer.component.scss'],
})
export class SharedSlotViewerComponent {

  public useIonic: boolean = false;

  constructor(@Inject('SecurityController') protected securityController: SecurityController,
    protected slotsController: SlotsController, protected phaseController: PhaseController) {

  }

  @Input()
  public needSlot: boolean;
  @Input()
  public requiresSlot: boolean;

  private _slotId: number;
  @Input()
  public set slotId(value: number) {
    this._slotId = value;
  }

  public get slotId(): number {
    return this._slotId;
  }

  public __lostSlotInfo: SlotInfoDto;
  private _lostSlotId: number;
  @Input()
  public set lostSlotId(v: number) {
    if (v === this._lostSlotId)
      return;

    this._lostSlotId = v;

    if (this._lostSlotId) {
      this.phaseController.getLostSlotInfo(this.phaseId).subscribe(e => {
        if (e && e.slotId) {
          this.__lostSlotInfo = e;
          this.wellcome();
        }
      });
    }
  }

  public get lostSlotId(): number {
    return this._lostSlotId;
  }

  public __slotInfo: SlotInfoDto;
  @Input()
  set slotInfo(value: SlotInfoDto) {
    if (this.__slotInfo == value)
      return;
    this.__slotInfo = value;
    this.wellcome();
  }
  get slotInfo(): SlotInfoDto {
    return this.__slotInfo;
  }

  public view: number = 0;
  public nextView() {
    this.view++;
    this.wellcome();
  }

  public tooltip = "";
  public generalClass = "";
  public wellcome() {
    this.generalClass = "";
    if (this.slotInfo == null) {
    }
    else {
      if (this.slotInfo.noShow)
        this.generalClass = " noShow";
      if (this.slotInfo.isBlockedBookSlotSystem)
        this.generalClass += " isBlockedBookSlotSystem";
      else
        this.generalClass += " noIsBlockedBookSlotSystem";
      if (this.slotInfo.isBlockedEnterSystem)
        this.generalClass += " isBlockedEnterSystem";
      else
        this.generalClass += " noisBlockedEnterSystem";
      if (this.slotInfo.isBlockedExitSystem)
        this.generalClass += " isBlockedExitSystem";
      else
        this.generalClass += " noisBlockedExitSystem";
      if (this.slotInfo.isSlotBlocked)
        this.generalClass += " isSlotBlocked";
      else
        this.generalClass += " noIsSlotBlocked";
      if (this.slotInfo.isSlotBlockedExternal)
        this.generalClass += " isSlotBlockedExternal";
      else
        this.generalClass += " noIsSlotBlockedExternal";

      if (this.slotInfo.slotId && this.slotInfo.slotId > 0)
        this.generalClass += " ambslot";
      else if (!this.slotInfo.slotId || this.slotInfo.slotId == 0)
        this.generalClass += " senseslot";

      if (this.slotInfo.incompatibleSlot)
        this.generalClass += " incompatibleSlot";

      if (this.slotInfo.isLateAtSlot)
        this.generalClass += " isLateAtSlot";

      if (this.explicat)
        this.generalClass += " explicat";
      else
        this.generalClass += " noExplicat";

      if (!(!this.__lostSlotInfo || !this.__lostSlotInfo.slotId || this.__lostSlotInfo.slotId == 0))
        this.generalClass += " esSlotLost"

      if (this.slotInfo.pendingSlotQty && this.slotInfo.pendingSlotQty > 0)
        this.generalClass += " ambPendingSlotQty";

      if (this.slotInfo.slotStatus)
        this.generalClass += " " + this.slotInfo.slotStatus;

      if (this.view % 3 === 0)
        this.generalClass += " documentation";

      if (this.view % 3 === 1)
        this.generalClass += " dock";

      if (this.view % 3 === 2)
        this.generalClass += " lift";

    }
  }

  public __explicat: boolean = false;
  @Input()
  set explicat(value: boolean) {
    if (this.__explicat == value)
      return;
    this.__explicat = value;
    this.getLastLog();
    this.wellcome();
  }
  get explicat(): boolean {
    return this.__explicat;
  }

  public __phase: PhaseCardDto;
  @Input()
  set phase(value: PhaseCardDto) {
    if (this.__phase === value)
      return;

    this.__phase = value;
    this.phaseId = value.phaseId;

    if (!this.__phaseId)
      this.slotInfo = null;

    this.getLastLog();
  }
  get phase(): PhaseCardDto {
    return this.__phase;
  }

  public __phaseId: number = 0;
  @Input()
  set phaseId(value: number) {
    if (this.__phaseId === value)
      return;

    this.__phaseId = value;

    if (!this.__phaseId)
      this.slotInfo = null;

    this.getLastLog();

    if ((!this.__slotInfo || (this.__phase && this.__phase.slotId != this.__slotInfo.slotId)) && this.__phaseId) {
      this.phaseController.getSlotInfo(this.__phaseId).subscribe(data => {
        this.slotInfo = data;
      });
    }
  }
  get phaseId(): number {
    return this.__phaseId;
  }

  public __routePhaseSlot: PhaseSlotDto;
  @Input()
  set routePhaseSlot(value: PhaseSlotDto) {
    if (this.__routePhaseSlot == value)
      return;
    this.__routePhaseSlot = value;
    this.getRoutePhsaeSlot();
  }
  get routePhaseSlot(): PhaseSlotDto {
    return this.__routePhaseSlot;
  }

  public lastLog: SlotLogDto;
  public getLastLog() {
    if (!this.__explicat || !this.__phaseId || this.__phaseId == 0) {
      this.lastLog = null;
      return;
    }
    else
      this.slotsController.getLastSlotLogsByPhaseId(this.phaseId).subscribe(data => {
        this.lastLog = data;
        this.wellcome();
      });
  }

  getRoutePhsaeSlot() {
    if (this.routePhaseSlot == null) {
      this.slotInfo = null;
    }
    else {
      this.slotInfo = SlotInfoDto.buildFromRoutePhaseSlot(this.routePhaseSlot);
      this.phaseId = this.routePhaseSlot.phaseId;
    }
  }


  OnInit() {
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
