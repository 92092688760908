import { Component, Inject, Input } from "@angular/core";
import {
    LocalizableObjectDto,
    LocalizableObjectTypes,
} from "@shared/src/controllers/maps/LocalizableObjectDto";
import { Output, EventEmitter } from "@angular/core";
import "rxjs/add/operator/debounceTime";
import { ClusteredLocatorDto } from "@shared/src/controllers/maps/ClusteredLocatorDto";
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory'

@Component({
    selector: "shared-lite-item-component",
    templateUrl: "./shared.lite.item.component.html",
    styleUrls: ["./shared.lite.component.scss"],
})
export class SharedLiteItemComponent {
    constructor(
        @Inject("SharedAsideFactory")
        private sharedAsideFactory: SharedAsideFactory
    ) { }

    private __value: ClusteredLocatorDto;
    @Input()
    set value(loc: ClusteredLocatorDto) {
        if (this.__value === loc)
            return;
        this.__value = loc;
        this.objectHierarchy = this.__value.objectHierarchy.reverse();
        this.calculateHasMultipleChilds();
        this.calculateIcons();
    }
    get value(): ClusteredLocatorDto {
        return this.__value;
    }

    private __selectedItem: boolean;
    @Input()
    set selectedItem(sel: boolean) {
        if (this.__selectedItem === sel) return;
        this.__selectedItem = sel;
        this._selectedItemChild = null;
    }
    get selectedItem(): boolean {
        return this.__selectedItem;
    }

    public getClass(object: ClusteredLocatorDto) {
        if (object == null) return "";
        let result = object.sTags;
        if (this.selectedItem) result += " selected";

        return result;
    }
    private _selectedItemChild: ClusteredLocatorDto = null;
    public getClassChild(object: ClusteredLocatorDto) {
        if (object == null) return "";
        let result = object.sTags;
        if (this._selectedItemChild === object) result += " selected-child";

        return result;
    }

    @Output() clickItemChild = new EventEmitter<ClusteredLocatorDto>();
    public onClickItemChild(object: ClusteredLocatorDto) {
        this._selectedItemChild = object;
        this.clickItemChild.emit(object);
    }


    public hasMultipleChilds: boolean;
    public calculateHasMultipleChilds() {
        let count = 0;
        this.objectHierarchy.forEach((type) => {
            count += this.__value[type].length;
        });
        this.hasMultipleChilds = count > 1;
    }

    public showAside(item: LocalizableObjectDto) {
        switch (item.objectType) {
            case LocalizableObjectTypes.route:
                this.sharedAsideFactory.invokeRouteMapFromRoute(item.id);
                break;
            case LocalizableObjectTypes.shipment:
                this.sharedAsideFactory.invokeRouteMapFromShipment(item.id);
                break;
            default:
                break;
        }
    }

    public objectHierarchy: Array<LocalizableObjectTypes> = [];
    public hasRoute: boolean;
    public hasShipment: boolean;
    public hasUser: boolean;
    public hasDevice: boolean;
    public hasPlate: boolean;
    public hasAddress: boolean;
    public calculateIcons() {
        this.hasRoute = false;
        this.hasShipment = false;
        this.hasPlate = false;
        this.hasDevice = false;
        this.hasUser = false;
        this.objectHierarchy.forEach((item: LocalizableObjectTypes) => {
            if (item == LocalizableObjectTypes.route)
                this.hasRoute = true;
            if (item == LocalizableObjectTypes.shipment)
                this.hasShipment = true;
            if (item == LocalizableObjectTypes.plate)
                this.hasPlate = true;
            if (item == LocalizableObjectTypes.device)
                this.hasDevice = true;
            if (item == LocalizableObjectTypes.user)
                this.hasUser = true;
            if (item == LocalizableObjectTypes.address)
                this.hasAddress = true;
        })
    }

    pretty(value: any) {
        return JSON.stringify(value);
    }
}
