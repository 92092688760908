
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class LoadConsoleEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): LoadConsoleEntitySelectorDto{
        let result: LoadConsoleEntitySelectorDto = new LoadConsoleEntitySelectorDto();
		result.loadData(EntitysSelectors.LoadConsole, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "system/loadconsole";
	}

	public getUrlBack(): string {
		return "/maintenance/loadconsole";
	}
	
}