import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { ManagementModelRequirementObjectParentActionDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementObjectParentActionDto';
import { ManagementModelRequirementObjectParentResultActionDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementObjectParentResultActionDto';
import { ManagementModelRequirementResponseLogDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementResponseLogDto';
import { ManagementModelChatInfoDto } from '@shared/src/dtos/managementmodel/ManagementModelChatInfoDto';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';


@Injectable({
    providedIn: 'root',
})
export class ManagementModelController extends BaseController {

    private base = '/managementmodel'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getLogsByResponseId(responseId: number): Observable<ManagementModelRequirementResponseLogDto[]> {
        return this.get(this.base + "/logs/" + responseId);
    }
    public getLogsByRequirementResponseId(requirementResponseId: number): Observable<ManagementModelRequirementResponseLogDto[]> {
        return this.get(this.base + "/logs/requirement/" + requirementResponseId);
    }

    public doActionRequirement(data: ManagementModelRequirementObjectParentActionDto): Observable<ManagementModelRequirementObjectParentResultActionDto> {
        return this.post(this.base + "/doactionrequirement" , data, "actionRequirement");
    }

    public doActionRecalculateStatusRoute(routeId: number): Observable<boolean> {
        return this.post(this.base + "/doactionrecalculatestatus/route" , routeId, "doActionRecalculateStatusRoute");
    }

    public doActionRecalculateStatusShipment(shipmentId: number): Observable<boolean> {
        return this.post(this.base + "/doactionrecalculatestatus/shipment" , shipmentId, "doActionRecalculateStatusShipment");
    }

    public chat(data: ManagementModelChatInfoDto): Observable<ChatDto> {
        return this.post(this.base + "/chat" , data, "chat");
    }
}
