import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../../../_base/BaseMaintenanceController';
import { CustomSettingItemMaintenanceDto } from './CustomSettingItemMaintenanceDto';
import { CustomSettingListMaintenanceDto } from './CustomSettingListMaintenanceDto';

@Injectable()
export class CustomSettingMaintenanceController extends BaseMaintenanceController<CustomSettingListMaintenanceDto, CustomSettingItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "customsetting");
    }

    public getResourceRoot(): string {
        return "CUSTOMSETTING";
    }

    public buildUrl(uri: string): string {
        return "/system/dataandparameters/customsetting" + uri;
    }
} 