import { HomeController } from '../controllers/home/home.controller';
import { HString } from './HString';

export class HColor {
    web: string;

    public static WHITE = HColor.build("#FFFFFF");
    public static BLACK = HColor.build("#000000");
    public static RED = HColor.build("#FF0000");
    public static GREEN = HColor.build("#00FF00");
    public static YELLOW = HColor.build("#FFFF00");
    public static BLUE = HColor.build("#0000FF");


    public static buildArrayOf(length: number): string[] {
        let result = new Array<string>();

        for (let i = 0; i <= length; i++)
            result.push(HColor.getRandom().web);

        return result;
    }


    /***
     * Indica si l'objecte és null o conté un valor null
     */
    public static isNullOrEmpty(value: HColor): boolean {
        if (value == null || value.web == null || value.web == undefined)
            return true;
        return false;
    }

    public static build(webvalue: string): HColor {
        if (webvalue == undefined)
            return HColor.defaultColor();

        let hcolor = new HColor();
        hcolor.web = webvalue;
        return hcolor;
    }

    public static buildFromColor(color: HColor): HColor {
        if (color == undefined)
            return HColor.defaultColor();

        let hcolor = new HColor();
        hcolor.web = color.web;
        return hcolor;
    }

    public static buildFromRGB(r: number, g: number, b: number): HColor {
        let result = new HColor();
        result.web = HColor.rgbToHex(r, g, b);
        return result;
    }


    public static defaultColor(): HColor {
        let hcolor = new HColor();
        hcolor.web = "#000000";
        return hcolor;
    }

    public static defaultColorW(): HColor {
        let hcolor = new HColor();
        hcolor.web = "#FFFFFF";
        return hcolor;
    }


    public static validColor(value: string) {
        if (value == null || value == "")
            return false;
        if (value.length < 6)
            return false;
        return true;
    }

    /**
     * Retonra el color de l'objecte però si aquest és null aleshores retorna el colorifnull
     * @param value 
     * @param colorifnull 
     */
    public static getColor(value: HColor, colorifnull: string): string {
        if (value != null && value.web != undefined && value.web != null)
            return value.web;
        return colorifnull;
    }

    /**
    * Retonra el color de l'objecte però si aquest és null aleshores retorna el colorifnull
    * @param value 
    * @param colorifnull 
    */
    public static getColorRGBA(value: HColor): string {
        if (value != null && value.web != undefined && value.web != null)
            return value.web;
        return this.defaultColor().web;
    }

    /**
     * Retonra el color de l'objecte però si aquest és null aleshores retorna el colorifnull
     * @param value 
     * @param colorifnull 
     */
    public static getColorOrDefaultColor(value: HColor, colorifnull: string): string {
        if (value != null && value.web != undefined && value.web != null)
            return value.web;
        return colorifnull;
    }

    /**
 * Retonra el color de l'objecte però si aquest és null aleshores retorna el colorifnull AMB OPACITAT. Sigui guin sigui el color el retorna amb l'opacitat establerta
 * @param value 
 * @param colorifnull 
 */
    public static getColorOpacity(value: HColor, colorifnull: string, opacity: number): string {
        let v = HColor.getColorOrDefaultColor(value, colorifnull);
        let c = HColor.hexToRgb(v);
        return "rgba(" + c.r + "," + c.g + "," + c.b + "," + opacity + ")";
    }


    public static componentToHex(c): string {
        var hex: string = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    /**
     * Donat un RGB et dona la cadena en hexa
     * @param r 
     * @param g 
     * @param b 
     */
    public static rgbToHex(r, g, b): string {
        return "#" + HColor.componentToHex(r) + HColor.componentToHex(g) + HColor.componentToHex(b);
    }

    /**
     * Donat un hex et retorna el color en RGB
     * @param hex 
     */
    public static hexToRgb(hex: string): any {
        //cas, hi ha una caden que diu rgba(r,g,b,a)
        //cas, hi ha una cadena que diu rgb(r,g,b)
        if (hex == null || hex == "")
            return { r: 0, g: 0, b: 0 };

        if (hex.startsWith("rgb")) {
            let shex = hex.substring(hex.indexOf("(") + 1, hex.indexOf(")"));
            let dshex = shex.split(",");
            return {
                r: dshex[0],
                g: dshex[1],
                b: dshex[2]
            }
        }
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result)
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                h: 255
            };
        var result = /^#?([a-f\d]{1,2})([a-f\d]{1,2})([a-f\d]{1,2})$/i.exec(hex);
        if (result)
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                h: 255
            };
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})(([a-f\d]{2})?)$/i.exec(hex);
        if (result)
            return {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16),
                h: parseInt(result[4], 16)
            };

    }

    public static hexToRGB(hex, transparency): string {

        var t = HColor.hexToRgb(hex);
        let result: string = "rgba(" + t.r + "," + t.g + "," + t.b + "," + transparency + ")";
        return result;
    }


    /***
 * Retorna un color random tirant a fosc
 * 
 * @return
 */
    public static getRandomDark(): HColor {
        /** El 128 fa que el color sigui fosc per força, ja que tindrà poca luminiositat al no passar de la franja dels 128 */
        let r = (Math.floor(Math.random() * 128) + 0);
        let g = (Math.floor(Math.random() * 128) + 0);
        let b = (Math.floor(Math.random() * 128) + 0);
        return HColor.buildFromRGB(r, g, b);
    }

    /***
* Retorna un color random
* 
* @return
*/
    public static getRandom(): HColor {
        /** El 128 fa que el color sigui fosc per força, ja que tindrà poca luminiositat al no passar de la franja dels 128 */
        let r = (Math.floor(Math.random() * 255) + 0);
        let g = (Math.floor(Math.random() * 255) + 0);
        let b = (Math.floor(Math.random() * 255) + 0);
        return HColor.buildFromRGB(r, g, b);
    }


    /***
     * Donat un color i un factor retorna la cadena que el converteix en un color amb transparència
     */
    public static toRGBA(color: HColor, factor: number): string {
        return HColor.hexToRGB(color.web, factor);
    }


    /***
     * Retorna el color invertit a partir d'un color
     */
    public static invertColor(hex: string): string {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (!this.validColor(hex)) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + HColor.padZero(r) + HColor.padZero(g) + HColor.padZero(b);
    }

    public static padZero(str: string, len?: number) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    /***
     * Donat un color calcula la luminància i regtorna el color blanc o negre per aconseguir el màxim contrast entre els dos valors.
     * Si L<=0.1833 clarament és el blanc, si és L>=0.175 clarament és el negre. Hi ha una zona intermitja però en general el negre és 
     * millor així que deixo el negre també en la zona intermitja
     */
    public static getWhiteBlackFromColor(hex: string): string {
        let rgbColor = HColor.hexToRgb(hex);
        if (rgbColor == null) {
            return "#222222";
        }
        let L = 0.2126 * (rgbColor.r / 255) + 0.7152 * (rgbColor.g / 255) + 0.0722 * (rgbColor.b / 255);
        if (rgbColor.h != null && rgbColor.h != undefined && rgbColor.h != 255) {
            let fLum = 255 / rgbColor.h;
            L = 0.2126 * (fLum * rgbColor.r / 255) + 0.7152 * (fLum * rgbColor.g / 255) + 0.0722 * (fLum * rgbColor.b / 255);
        }
        if (L <= 0.500)
            return "#ffffff";
        return "#000000";
    }

    public static getColorFromStringTable(value: string, homeController: HomeController): string {
        if (homeController.tags && value.toUpperCase() in homeController.tags) {
            return homeController.tags[value.toUpperCase()].web;
        } else {
            switch (value.toUpperCase()) {
                case "PERFECTE":
                case "MOVE":
                    return "#089008";
                case "STOP":
                    return "#7e922f";
                case "NOTELEMETRY":
                case "ERROR":
                    return "#AA0000";
                case "LOST":
                    return "#ec8b11"
                case "DAMM":
                    return "#C7303D"
                default:
                    return this.getColorFromString(value);
            }
        }
    }
    public static getColorFromString(value: string): string {
        if (value == null || value === "" || value.trim() === "")
            return "#555555";

        let sCrc = HString.crc(value).toString(16);
        sCrc = sCrc.substr(0, 6);
        return "#" + sCrc;
    }

    public static getColorFromStringOpacity(value: string, opacity: string, homeController: HomeController): string {
        return HColor.getColorFromStringTable(value, homeController) + opacity;
    }

}