<div [id]="identifier" *ngIf="addressManualAddress" class="container" [ngClass]="generalClass">
    <div class="block viewtype">
        <div class="titol-seccio">
            {{title | translate}}
        </div>
        <div *ngIf="!readonly" class="selectorViewType">
            <div *ngIf="!disableRegistered" class="link registeredAddress" (click)="setViewType('registered')">
                {{'SHIPMENT.SELECTADDRESS.CAPTION' | translate}}
            </div>
            <div *ngIf="!disableAssisted" class="link assistedAddress" (click)="setViewType('assisted')">
                {{'SHIPMENT.ASSISTEDADDRESS.CAPTION' | translate}}
            </div>
            <div *ngIf="!disableManual" class="link manualAddress" (click)="setViewType('manual')">
                {{'SHIPMENT.MANUALADDRESS.CAPTION' | translate}}
            </div>
        </div>
    </div>
    <div *ngIf="readonly" class="block readonly">
        <div class="line"><span class="addressLine1">{{addressManualAddress.manualAddress.addressLine1}}</span>
            <shared-tags-strip-component [uniqueString]="addressManualAddress.manualAddress.skin">
            </shared-tags-strip-component>
        </div>
        <div *ngIf="addressManualAddress.manualAddress && addressManualAddress.manualAddress.formattedAddress"
            class="line formatedAddress">{{addressManualAddress.manualAddress.formattedAddress}}</div>
    </div>
    <div *ngIf="!readonly" class="block">
        <!-- DEFINED ADDRESS -->
        <div [formGroup]="rFormDefined" *ngIf="actualViewType=='registered' && addressManualAddress">
            <entity-selector class="col-x slim" id="addressId" [entitySelector]="'Address'"
                [fixedFilterKeys]="filterKeys" placeholder="{{selectorPlaceholder | translate}}" name="addressId"
                [readonly]="readonly" [(ngModel)]="addressManualAddress.addressId"
                (valuechangefunction)="registeredAddressChanged()" [showgotomaintenance]="false"
                formControlName="addressId" [autoselectwhenone]="autoselectwhenone">
            </entity-selector>
        </div>
        <!-- ASISTIDA-->
        <shared-assistedaddress-component *ngIf="actualViewType=='assisted' && addressManualAddress.manualAddress"
            class="col-x slim" [id]="'asistida'"
            [formattedAddress]="addressManualAddress.manualAddress.formattedAddress"
            (onCreate)="onAssistedAddressCreate($event)" placeholder="{{selectorPlaceholder | translate}}">
        </shared-assistedaddress-component>

        <!-- MANUAL-->
        <div *ngIf="actualViewType=='manual' && addressManualAddress.manualAddress" [formGroup]="rFormManual">
            <input-component class="col-x" placeholder="{{'SHIPMENT.ADDRESSLINE1.CAPTION' | translate}}"
                name="addressLine1" formControlName="addressLine1" autocomplete="off" [readonly]="readonly"
                [(ngModel)]="addressManualAddress.manualAddress.addressLine1" (valueChanged)="manualAddressChanged()">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.ADDRESSLINE2.CAPTION' | translate}}"
                name="addressLine2" formControlName="addressLine2" autocomplete="off" [readonly]="readonly"
                [(ngModel)]="addressManualAddress.manualAddress.addressLine2" (valueChanged)="manualAddressChanged()">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.CITY.CAPTION' | translate}}" name="addressCity"
                formControlName="addressCity" autocomplete="off" [readonly]="readonly"
                [(ngModel)]="addressManualAddress.manualAddress.city" (valueChanged)="manualAddressChanged()">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.PROVINCE.CAPTION' | translate}}"
                name="addressProvince" formControlName="addressProvince" autocomplete="off" [readonly]="readonly"
                (valueChanged)="manualAddressChanged()" [(ngModel)]="addressManualAddress.manualAddress.province">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.POSTALCODE.CAPTION' | translate}}"
                name="addressPostalCode" formControlName="addressPostalCode" (valueChanged)="manualAddressChanged()"
                autocomplete="off" [readonly]="readonly" [(ngModel)]="addressManualAddress.manualAddress.postalCode">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.STATE.CAPTION' | translate}}" name="addressState"
                formControlName="addressState" autocomplete="off" (valueChanged)="manualAddressChanged()"
                [readonly]="readonly" [(ngModel)]="addressManualAddress.manualAddress.state">
            </input-component>

            <input-component class="col-x" placeholder="{{'SHIPMENT.COUNTRY.CAPTION' | translate}}"
                (valueChanged)="manualAddressChanged()" name="addressCountry" formControlName="addressCountry"
                autocomplete="off" [readonly]="readonly" [(ngModel)]="addressManualAddress.manualAddress.country">
            </input-component>
        </div>
    </div>
</div>
<div *ngIf="!addressManualAddress">
    no addressManualAddress
</div>