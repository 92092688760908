
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { BookingStatus, HomeController, PhaseController } from '@shared/src/public-api';
import { SharedStateComponent } from '../shared.state.component';

@Component({
    selector: 'shared-booking-state',
    templateUrl: './shared.booking.state.component.html',
    styleUrls: ['./shared.booking.state.component.scss']
})
export class SharedBookingStateComponent extends SharedStateComponent {

    constructor(@Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homecontroller: HomeController, protected phaseController: PhaseController, protected bookingController: BookingController) {
        super(securityController, homecontroller, phaseController);
    }

    public availableStatuses: Array<BookingStatus> = [];

    @Output() public onBookingStateChange: EventEmitter<BookingStatus> = new EventEmitter<BookingStatus>();

    private _booking: BookingDto;
    @Input() public set booking(v: BookingDto) {
        if (this.booking && v && this.booking.bookingId == v.bookingId && this.booking.status == v.status)
            return;

        this._booking = v;

        this.setResourceStateCurrentStatus();

        this.bookingController.getBookingAvailableStatuses(this.booking.bookingId).subscribe(e => {
            if (e)
                this.availableStatuses = e;

            this.setResourceStateNextStatus();
        })
    }

    public get booking(): BookingDto {
        return this._booking;
    }

    getResourceItemToChange(item: BookingStatus) {
        return 'BOOKINGSTATUS.' + (this.booking.status ? this.booking.status.toUpperCase() + "." : "") + item.toUpperCase() + ".CAPTION";
    }

    loadInternalProperties() {

    }

    setResourceStateCurrentStatus() {
        if (!this.booking || !this.booking.status)
            return;
        this.resourceStateCurrentStatus = 'BOOKINGSTATUS.' + this.booking.status.toUpperCase() + ".CAPTION";
    }

    setResourceStateNextStatus() {
        if (!this.availableStatuses || this.availableStatuses.length == 0)
            return;
        this.resourceStateNextStatus = 'BOOKINGSTATUS.' + this.availableStatuses[0].toUpperCase() + ".CAPTION";
    }

    onSelectorChangedState(status: BookingStatus) {
        this.bookingController.setStatus(this.booking.bookingId, status).subscribe(e => {
            if (e && e.bookingId) {
                this.onBookingStateChange.next(e.status);
                this.booking = e;
            }

            this.showList = false;
        })
    }
}
