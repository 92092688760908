import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { PartnerCarrierMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerCarrierMaintenanceController';
import { PartnerDriverMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerDriverMaintenanceController';
import { PartnerFinalCustomerMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerFinalCustomerMaintenanceController';
import { PartnerLogisticOperatorMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerLogisticOperatorMaintenanceController';
import { PermissionGroupMaintenanceController } from '@shared/src/controllers/maintenance/permissiongroup/PermissionGroupMaintenanceController';
import { SharedAsideFactory } from './components/components.api';
import { AddressController } from './controllers/address/address.controller';
import { SlotsController } from './controllers/address/slots/slots.controller';
import { FileUpDownloadController } from './controllers/fileupdownload/fileupdownload.controller';
import { HomeController } from './controllers/home/home.controller';
import { InternalMailController } from './controllers/internal/mail/InternalMailController';
import { LogController } from './controllers/log/LogController';
import { AddressMaintenanceController } from './controllers/maintenance/address/AddressMaintenanceController';
import { AddressAmbitSubmaintenanceController } from './controllers/maintenance/address/ambit/AddressAmbitSubmaintenanceController';
import { AmbitAddressSubmaintenanceController } from './controllers/maintenance/ambit/AmbitAddressSubmaintenanceController';
import { AmbitMaintenanceController } from './controllers/maintenance/ambit/AmbitMaintenanceController';
import { AmbitPermissionGroupSubMaintenanceController } from './controllers/maintenance/ambit/group/AmbitPermissionGroupSubMaintenanceController';
import { DeviceMaintenanceController } from './controllers/maintenance/device/DeviceMaintenanceController';
import { TelemetryMaintenanceController } from './controllers/maintenance/device/telemetry/TelemetryMaintenanceController';
import { FormMaintenanceController } from './controllers/maintenance/form/FormMaintenanceController';
import { FormQuestionSubmaintenanceController } from './controllers/maintenance/form/formquestion/FormQuestionSubmaintenanceController';
import { QuestionMaintenanceController } from './controllers/maintenance/form/question/QuestionMaintenanceController';
import { GateMaintenanceController } from './controllers/maintenance/gate/GateMaintenanceController';
import { NotificationTopicSchemaSystemMaintenanceController } from './controllers/maintenance/notification/schema/NotificationTopicSchemaSystemMaintenanceController';
import { NotificationTopicSchemaPartnerCarrierSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerCarrierSubMaintenanceController';
import { NotificationTopicSchemaPartnerDriverSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerDriverSubMaintenanceController';
import { NotificationTopicSchemaPartnerFinalCustomerSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerFinalCustomerSubMaintenanceController';
import { NotificationTopicSchemaPartnerLogisticOperatorSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerLogisticOperatorSubMaintenanceController';
import { PermissionGroupAmbitSubMaintenanceController } from './controllers/maintenance/permissiongroup/ambit/PermissionGroupAmbitSubMaintenanceController';
import { PermissionGroupUserSubMaintenanceController } from './controllers/maintenance/permissiongroup/user/PermissionGroupUserSubMaintenanceController';
import { PhaseMaintenanceController } from './controllers/maintenance/phase/PhaseMaintenanceController';
import { PlateMaintenanceController } from './controllers/maintenance/plate/PlateMaintenanceController';
import { PlateDeviceSubmaintenanceController } from './controllers/maintenance/plate/device/PlateDeviceSubmaintenanceController';
import { RouteMaintenanceController } from './controllers/maintenance/route/RouteMaintenanceController';
import { ScopeMaintenanceController } from './controllers/maintenance/scope/ScopeMaintenanceController';
import { ShipmentMaintenanceController } from './controllers/maintenance/shipment/ShipmentMaintenanceController';
import { TenantSettingMaintenanceController } from './controllers/maintenance/tenant/TenantSettingMaintenanceController';
import { TenantCredentialMaintenanceController } from './controllers/maintenance/tenantcredential/TenantCredentialMaintenanceController';
import { UserMaintenanceController } from './controllers/maintenance/user/UserMaintenanceController';
import { UserPermissionGroupSubMaintenanceController } from './controllers/maintenance/user/group/UserPermissionGroupSubMaintenanceController';
import { OpenRouteController } from './controllers/open/open.route.controller';
import { PublicFileController } from './controllers/publicfile/publicfile.controller';
import { QRCodeController } from './controllers/qrcode/qrcode.controller';
import { IncidentController } from './controllers/route/incident/incident.controller';
import { PhaseController } from './controllers/route/phase/phase.controller';
import { RouteController } from './controllers/route/route.controller';
import { ShipmentController } from './controllers/route/shipment/shipment.controller';
import { SecurityController } from './controllers/security/security.controller';
import { SettingsController } from './controllers/settings/settings.controller';
import { StatisticsController } from './controllers/statistics/statistics.controller';
import { SystemController } from './controllers/system/system.controller';
import { testController } from './controllers/test/testController';
import { UserRegisterController } from './controllers/user/register/user.register.controller';
import { UserController } from './controllers/user/user.controller';
import { UserSettingController } from './controllers/user/usersetting.controller';
import { DragDropService } from './services/dragdrop/DragDropService';
import { MapService } from './services/map/map.service';
import { VersionService } from './services/version/version.service';

import { AccessControlController } from './controllers/accesscontrol/accesscontrol.controller';
import { ChatController } from './controllers/chat/chat.controller';
import { DocumentController } from './controllers/document/document.controller';
import { InternalSupervisionController } from './controllers/internal/supervision/internal.supervision.controller';
import { InternalVersionController } from './controllers/internal/version/internal.version.controller';
import { LoadConsoleController } from './controllers/loadconsole/loadconsole.controller';
import { AccessControlMaintenanceController } from './controllers/maintenance/accesscontrol/AccessControlMaintenanceController';
import { AccessControlGateSubMaintenanceController } from './controllers/maintenance/accesscontrol/gate/AccessControlGateSubMaintenanceController';
import { AccessControlPermissionGroupSubMaintenanceController } from './controllers/maintenance/accesscontrol/group/AccessControlPermissionGroupSubMaintenanceController';
import { AddressCustomTableSubmaintenanceController } from './controllers/maintenance/address/cutomtable/AddressCustomTableSubmaintenanceController';
import { AddressGateSubMaintenanceController } from './controllers/maintenance/address/gate/AddressGateSubMaintenanceController';
import { AddressPanelSubmaintenanceController } from './controllers/maintenance/address/panel/AddressPanelSubmaintenanceController';
import { AddressPlateAnnotationSubMaintenanceController } from './controllers/maintenance/address/plateannotation/AddressPlateAnnotationSubMaintenanceController';
import { AlarmDefinitionMaintenanceController } from './controllers/maintenance/alarmdefinition/AlarmDefinitionMaintenanceController';
import { BannedPhoneMaintenanceController } from './controllers/maintenance/banned/bannedphone/BannedPhoneMaintenanceController';
import { BillTypeMaintenanceController } from './controllers/maintenance/billtype/BillTypeMaintenanceController';
import { DelegateMaintenanceController } from './controllers/maintenance/delegate/DelegateMaintenanceController';
import { DevicePlateSubmaintenanceController } from './controllers/maintenance/device/plate/DevicePlateSubmaintenanceController';
import { FestivesMaintenanceController } from './controllers/maintenance/festives/FestivesMaintenanceController';
import { FestiveDaySubMaintenanceController } from './controllers/maintenance/festives/day/FestiveDaySubMaintenanceController';
import { FestivesFestivesSubMaintenanceController } from './controllers/maintenance/festives/parent/FestivesFestivesSubMaintenanceController';
import { GateAccessControlSubMaintenanceController } from './controllers/maintenance/gate/accesscontrol/GateAccessControlSubMaintenanceController';
import { GateAddressSubMaintenanceController } from './controllers/maintenance/gate/address/GateAddressSubMaintenanceController';
import { GateExcludeGateSubMaintenanceController } from './controllers/maintenance/gate/exclude/GateExcludeGateSubMaintenanceController';
import { InboundMaintenanceController } from './controllers/maintenance/inbound/InboundMaintenanceController';
import { LoadConsoleMaintenanceController } from './controllers/maintenance/loadconsole/LoadConsoleMaintenanceController';
import { LoadConsolePermissionGroupSubMaintenanceController } from './controllers/maintenance/loadconsole/group/LoadConsolePermissionGroupSubMaintenanceController';
import { ManagementModelMaintenanceController } from './controllers/maintenance/managementmodel/ManagementModelMaintenanceController';
import { ManagementModelRequirementSubMaintenanceController } from './controllers/maintenance/managementmodel/requirement/ManagementModelRequirementSubMaintenanceController';
import { MaritimeAgencyMaintenanceController } from './controllers/maintenance/maritimeagency/MaritimeAgencyMaintenanceController';
import { NonacceptanceMaintenanceController } from './controllers/maintenance/nonacceptance/NonacceptanceMaintenanceController';
import { NotificationTopicSchemaUserMaintenanceController } from './controllers/maintenance/notification/schema/NotificationTopicSchemaUserMaintenanceController';
import { OutboundMaintenanceController } from './controllers/maintenance/outbound/OutboundMaintenanceController';
import { PanelMaintenanceController } from './controllers/maintenance/panel/PanelMaintenanceController';
import { PanelAddressSubMaintenanceController } from './controllers/maintenance/panel/address/PanelAddressSubMaintenanceController';
import { PanelColumnSubMaintenanceController } from './controllers/maintenance/panel/column/PanelColumnSubMaintenanceController';
import { PanelPermissionGroupSubMaintenanceController } from './controllers/maintenance/panel/group/PanelPermissionGroupSubMaintenanceController';
import { PartnerClientPreferentMaintenanceController } from './controllers/maintenance/partner/PartnerClientPreferentMaintenanceController';
import { PartnerFreightForwarderMaintenanceController } from './controllers/maintenance/partner/PartnerFreightForwarderMaintenanceController';
import { PartnerHealthCertifierMaintenanceController } from './controllers/maintenance/partner/PartnerHealthCertifierMaintenanceController';
import { PartnerInspectorMaintenanceController } from './controllers/maintenance/partner/PartnerInspectorMaintenanceController';
import { PartnerMaintenanceController } from './controllers/maintenance/partner/PartnerMaintenanceController';
import { PartnerAssignmentByAddressMaintenanceController } from './controllers/maintenance/partner/partnerassignmentbyaddress/PartnerAssignmentByAddressMaintenanceController';
import { PartnerProviderMaintenanceController } from './controllers/maintenance/partner/PartnerProviderMaintenanceController';
import { PartnerShipperMaintenanceController } from './controllers/maintenance/partner/PartnerShipperMaintenanceController';
import { NotificationTopicSchemaPartnerCarrierUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerCarrierUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerClientPreferentSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerClientPreferentSubMaintenanceController';
import { NotificationTopicSchemaPartnerClientPreferentUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerClientPreferentUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerDriverUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerDriverUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerFinalCustomerUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerFinalCustomerUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerFreightForwarderSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerFreightForwarderSubMaintenanceController';
import { NotificationTopicSchemaPartnerFreightForwarderUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerFreightForwarderUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerHealthCertifierSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerHealthCertifierSubMaintenanceController';
import { NotificationTopicSchemaPartnerHealthCertifierUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerHealthCertifierUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerInspectorSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerInspectorSubMaintenanceController';
import { NotificationTopicSchemaPartnerInspectorUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerInspectorUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerLogisticOperatorUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerLogisticOperatorUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerProviderSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerProviderSubMaintenanceController';
import { NotificationTopicSchemaPartnerProviderUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerProviderUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerShipperSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerShipperSubMaintenanceController';
import { NotificationTopicSchemaPartnerShipperUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerShipperUserSubMaintenanceController';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerSubMaintenanceController';
import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './controllers/maintenance/partner/notification/NotificationTopicSchemaPartnerUserSubMaintenanceController';
import { PartnerSettingSubMaintenanceController } from './controllers/maintenance/partner/partnersetting/PartnerSettingSubMaintenanceController';
import { PermissionGroupAccessControlSubMaintenanceController } from './controllers/maintenance/permissiongroup/accesscontrol/PermissionGroupAccessControlSubMaintenanceController';
import { PermissionGroupLoadConsoleSubMaintenanceController } from './controllers/maintenance/permissiongroup/loadconsole/PermissionGroupLoadConsoleSubMaintenanceController';
import { PermissionGroupOpcioMenuSubMaintenanceController } from './controllers/maintenance/permissiongroup/menuoption/PermissionGroupOpcioMenuSubMaintenanceController';
import { PermissionGroupPanelSubMaintenanceController } from './controllers/maintenance/permissiongroup/panel/PermissionGroupPanelSubMaintenanceController';
import { PermissionGroupRoleSubMaintenanceController } from './controllers/maintenance/permissiongroup/role/PermissionGroupRoleSubMaintenanceController';
import { PlateAnnotationMaintenanceController } from './controllers/maintenance/plateannotation/PlateAnnotationMaintenanceController';
import { PortAddressMaintenanceController } from './controllers/maintenance/portaddress/PortAddressMaintenanceController';
import { ReturnMaintenanceController } from './controllers/maintenance/return/ReturnMaintenanceController';
import { AddressStrongAddressMaintenanceController } from './controllers/maintenance/strongaddress/AddressStrongAddressMaintenanceController';
import { TagMaintenanceController } from './controllers/maintenance/tag/TagMaintenanceController';
import { TenantResourceMaintenanceController } from './controllers/maintenance/tenant/TenantResourceMaintenanceController';
import { CustomSettingMaintenanceController } from './controllers/maintenance/tenant/custom/setting/CustomSettingMaintenanceController';
import { CustomTableMaintenanceController } from './controllers/maintenance/tenant/custom/table/CustomTableMaintenanceController';
import { TenantTaskMaintenanceController } from './controllers/maintenance/tenant/task/TenantTaskMaintenanceController';
import { TypologyMaintenanceController } from './controllers/maintenance/typology/TypologyMaintenanceController';
import { DocksPerTypologySubMaintenanceController } from './controllers/maintenance/typology/time/DocksPerTypologySubMaintenanceController';
import { ProductTimeSubMaintenanceController } from './controllers/maintenance/typology/time/ProductTimeSubMaintenanceController';
import { TypologyTimeSubMaintenanceController } from './controllers/maintenance/typology/time/TypologyTimeSubMaintenanceController';
import { UserRoleSubMaintenanceController } from './controllers/maintenance/user/role/UserRoleSubMaintenanceController';
import { ManagementModelController } from './controllers/managementmodel/managementmodel.controller';
import { OpenPanelController } from './controllers/open/open.panel.controller';
import { PackageController } from './controllers/route/delivery/package.controller';
import { ShipmentDeliveryController } from './controllers/route/delivery/shipmentdelivery.controller';
import { SocketController } from './controllers/socket/socket.controller';
import { TenantLoadLogController } from './controllers/tenant/loadlog/tenantloadlog.controller';
import { DesktopTrackController } from './controllers/track/desktop-track.controller';
import { FileService } from './services/file/file.service';
import { PlateTypologyMaintenanceController } from './controllers/maintenance/platetypology/PlateTypologyMaintenanceController';
import { UserMenuOptionSubMaintenanceController } from './controllers/maintenance/user/option/UserMenuOptionSubMaintenanceController';
import { RouteCMRController } from './controllers/route/cmr/routecmr.controller';
import { ShipmentCMRController } from './controllers/route/cmr/shipmentcmr.controller';
import { ReverseLogisticsMaintenanceController } from './controllers/maintenance/reverselogistics/ReverseLogisticsMaintenanceController';
import { GateStateGateSubMaintenanceController } from './controllers/maintenance/gate/state/GateStateGateSubMaintenanceController';
import { AddressHasAddressSubMaintenanceController } from './controllers/maintenance/address/address/AddressHasAddressSubMaintenanceController';
import { TotemSettingMaintenanceController } from './controllers/maintenance/totem/TotemSettingMaintenanceController';
import { SharedInactivityService } from './services/inactivity/SharedInactivityService';
import { DeliveryCommitmentMaintenanceController } from './controllers/maintenance/shipment/deliverycommitment/DeliveryCommitmentMaintenanceController';
import { TruckingController } from './controllers/trucking/trucking.controller';
import { DocumentaryReviewController } from './controllers/documentaryreview/documentaryreview.controller';
import { OpenRouteCMRController } from './controllers/open/openroutecmr.controller';
import { OpenDocumentController } from './controllers/open/open.document.controller';



@NgModule({
    declarations: [
    ],
    imports: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AccessControlController,
        AccessControlGateSubMaintenanceController,
        AccessControlPermissionGroupSubMaintenanceController,
        AccessControlMaintenanceController,
        TotemSettingMaintenanceController,
        AddressController,
        AddressStrongAddressMaintenanceController,
        AddressMaintenanceController,
        AddressAmbitSubmaintenanceController,
        AddressCustomTableSubmaintenanceController,
        AddressPanelSubmaintenanceController,
        AddressGateSubMaintenanceController,
        AddressHasAddressSubMaintenanceController,
        AmbitMaintenanceController,
        AmbitAddressSubmaintenanceController,
        AmbitPermissionGroupSubMaintenanceController,
        BillTypeMaintenanceController,
        ChatController,
        CustomSettingMaintenanceController,
        CustomTableMaintenanceController,
        TenantSettingMaintenanceController,
        TenantTaskMaintenanceController,
        TagMaintenanceController,
        DeviceMaintenanceController,
        DevicePlateSubmaintenanceController,
        DocumentController,
        DocumentaryReviewController,
        FormMaintenanceController,
        QuestionMaintenanceController,
        FormQuestionSubmaintenanceController,
        FestiveDaySubMaintenanceController,
        FestivesFestivesSubMaintenanceController,
        FestivesMaintenanceController,
        TelemetryMaintenanceController,
        DragDropService,
        LoadConsoleController,
        LoadConsolePermissionGroupSubMaintenanceController,
        LoadConsoleMaintenanceController,
        LogController,
        HomeController,
        HttpClient,
        IncidentController,
        InternalMailController,
        InternalVersionController,
        MapService,
        FileService,
        ManagementModelController,
        ManagementModelRequirementSubMaintenanceController,
        ManagementModelMaintenanceController,
        MaritimeAgencyMaintenanceController,
        PartnerSettingSubMaintenanceController,
        PortAddressMaintenanceController,
        NotificationTopicSchemaSystemMaintenanceController,
        NotificationTopicSchemaUserMaintenanceController,
        NotificationTopicSchemaPartnerCarrierSubMaintenanceController,
        NotificationTopicSchemaPartnerCarrierUserSubMaintenanceController,
        NotificationTopicSchemaPartnerClientPreferentSubMaintenanceController,
        NotificationTopicSchemaPartnerClientPreferentUserSubMaintenanceController,
        NotificationTopicSchemaPartnerFreightForwarderSubMaintenanceController,
        NotificationTopicSchemaPartnerFreightForwarderUserSubMaintenanceController,
        NotificationTopicSchemaPartnerShipperSubMaintenanceController,
        NotificationTopicSchemaPartnerShipperUserSubMaintenanceController,
        NotificationTopicSchemaPartnerFinalCustomerSubMaintenanceController,
        NotificationTopicSchemaPartnerFinalCustomerUserSubMaintenanceController,
        NotificationTopicSchemaPartnerDriverSubMaintenanceController,
        NotificationTopicSchemaPartnerDriverUserSubMaintenanceController,
        NotificationTopicSchemaPartnerLogisticOperatorSubMaintenanceController,
        NotificationTopicSchemaPartnerLogisticOperatorUserSubMaintenanceController,
        NotificationTopicSchemaPartnerProviderSubMaintenanceController,
        NotificationTopicSchemaPartnerProviderUserSubMaintenanceController,
        NotificationTopicSchemaPartnerInspectorSubMaintenanceController,
        NotificationTopicSchemaPartnerInspectorUserSubMaintenanceController,
        NotificationTopicSchemaPartnerHealthCertifierSubMaintenanceController,
        NotificationTopicSchemaPartnerHealthCertifierUserSubMaintenanceController,
        NotificationTopicSchemaPartnerSubMaintenanceController,
        NotificationTopicSchemaPartnerUserSubMaintenanceController,
        OpenPanelController,
        OpenRouteController,
        OpenRouteCMRController,
        OpenDocumentController,
        PanelColumnSubMaintenanceController,
        PanelAddressSubMaintenanceController,
        PanelPermissionGroupSubMaintenanceController,
        PanelMaintenanceController,
        PartnerMaintenanceController,
        PartnerAssignmentByAddressMaintenanceController,
        PartnerFinalCustomerMaintenanceController,
        PartnerProviderMaintenanceController,
        PartnerCarrierMaintenanceController,
        PartnerClientPreferentMaintenanceController,
        PartnerFreightForwarderMaintenanceController,
        PartnerShipperMaintenanceController,
        PartnerDriverMaintenanceController,
        PartnerLogisticOperatorMaintenanceController,
        PartnerInspectorMaintenanceController,
        PartnerHealthCertifierMaintenanceController,
        PermissionGroupMaintenanceController,
        PermissionGroupAccessControlSubMaintenanceController,
        PermissionGroupAmbitSubMaintenanceController,
        PermissionGroupLoadConsoleSubMaintenanceController,
        PermissionGroupPanelSubMaintenanceController,
        PermissionGroupUserSubMaintenanceController,
        PermissionGroupRoleSubMaintenanceController,
        PermissionGroupOpcioMenuSubMaintenanceController,
        PhaseController,
        PhaseMaintenanceController,
        PublicFileController,
        QRCodeController,
        FileUpDownloadController,
        RouteController,
        RouteMaintenanceController,
        ReturnMaintenanceController,
        ReverseLogisticsMaintenanceController,
        NonacceptanceMaintenanceController,
        InboundMaintenanceController,
        OutboundMaintenanceController,
        DelegateMaintenanceController,
        ScopeMaintenanceController,
        SecurityController,
        SettingsController,
        SharedAsideFactory,
        ShipmentController,
        ShipmentMaintenanceController,
        ShipmentDeliveryController,
        PackageController,
        RouteCMRController,
        SharedInactivityService,
        ShipmentCMRController,
        SlotsController,
        SocketController,
        StatisticsController,
        SystemController,
        testController,
        UserMaintenanceController,
        UserPermissionGroupSubMaintenanceController,
        UserRoleSubMaintenanceController,
        UserMenuOptionSubMaintenanceController,
        UserRegisterController,
        UserController,
        UserSettingController,
        PlateMaintenanceController,
        AddressPlateAnnotationSubMaintenanceController,
        PlateAnnotationMaintenanceController,
        PlateTypologyMaintenanceController,
        PlateDeviceSubmaintenanceController,
        InternalSupervisionController,
        VersionService,
        GateMaintenanceController,
        AlarmDefinitionMaintenanceController,
        GateAccessControlSubMaintenanceController,
        GateAddressSubMaintenanceController,
        GateStateGateSubMaintenanceController,
        GateExcludeGateSubMaintenanceController,
        TenantCredentialMaintenanceController,
        TenantLoadLogController,
        TenantResourceMaintenanceController,
        DesktopTrackController,
        TypologyMaintenanceController,
        TypologyTimeSubMaintenanceController,
        ProductTimeSubMaintenanceController,
        DocksPerTypologySubMaintenanceController,
        BannedPhoneMaintenanceController,
        DeliveryCommitmentMaintenanceController,
        TruckingController
    ],
    exports: [
    ]
})
export class BasicModule {
    public static forRoot(hTranslateService, alertService, loadingService, homecontroller, securityController, asideFactory, geolocationController): ModuleWithProviders<BasicModule> {
        return {
            ngModule: BasicModule,
            providers: [
                { provide: 'HTranslateService', useClass: hTranslateService },
                { provide: 'AlertService', useClass: alertService },
                { provide: 'LoadingService', useClass: loadingService },
                { provide: 'HomeController', useClass: homecontroller },
                { provide: 'SecurityController', useClass: securityController },
                { provide: 'SharedAsideFactory', useClass: asideFactory },
                { provide: 'GeolocationController', useClass: geolocationController }
            ]
        };
    }
}
