
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ListMaintenanceDto } from './ListMaintenanceDto';
import { ItemMaintenanceDto } from './ItemMaintenanceDto';
import { MaintenanceSearchByTextDto } from './MaintenanceSearchByTextDtos';
import { ListOfMaintenanceDto } from '../ListOfMaintenanceDto';
import { MaintenanceSettingsNoneDTO } from './MaintenanceSettingsNoneDTO';
import { MaintenanceSettingsItemDTO } from './MaintenanceSettingsItemDTO';
import { ObjectSecurityInfoDto } from './ObjectSecurityInfoDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { BaseController } from '../../base.controller';
import { LoadingService } from '@shared/src/services/loading/loading.service';

export class BaseSubMaintenanceController<listDTO extends ListMaintenanceDto, itemDTO extends ItemMaintenanceDto> extends BaseController {

    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        protected baseUrl: string) {
        super(http, alertService, translateService, loadingService, router);
    }

    /***
     * Això serveix per als recursos, defineix l'arrel dels recursos. Es la baseUrl no ens compliquem a majúscules amb un trim
     */
    public getResourceRoot(): string {
        return this.baseUrl.toUpperCase().trim();
    }

    executeExtendedAction(parentId: number, modelId: number, actionId: string): Observable<itemDTO> {
        return this.post(this.getBaseUrl(parentId) + "/executeaction", { "modelId": modelId, "actionId": actionId }, "BaseSubMaintenanceController.ExecuteExtendedAction." + this.baseUrl);
    }

    public search(parentId: number, maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<ListOfMaintenanceDto<listDTO>> {
        return this.post(this.getBaseUrl(parentId) + "/search", maintenanceSearchByText, "BaseSubMaintenanceController.Search." + this.baseUrl);
    }

    public maintenanceSettingsForNone(parentId: number): Observable<MaintenanceSettingsNoneDTO> {
        return this.get(this.getBaseUrl(parentId) + "/maintenancesettingsfornone");
    }

    public newModel(parentId: number): Observable<itemDTO> {
        return this.get(this.getBaseUrl(parentId) + "/new");
    }
    public postNewItem(parentId: number, newItem: itemDTO): Observable<listDTO> {
        return this.post(this.getBaseUrl(parentId) + "/", newItem, "BaseSubMaintenanceController.postNewItem." + this.baseUrl);
    }
    public putItem(parentId: number, maintenanceItem: itemDTO): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.put(this.getBaseUrl(parentId) + "/", maintenanceItem, "BaseSubMaintenanceController.putItem." + this.baseUrl);
    }
    public getById(parentId: number, elemId: number): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.get(this.getBaseUrl(parentId) + "/id/" + elemId);
    }
    public getByKey(parentId: number, key: string): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.get(this.getBaseUrl(parentId) + "/key/" + key);
    }
    public deleteItem(parentId: number, elemId: string): Observable<boolean> {
        return this.deleteBusy(this.getBaseUrl(parentId) + "/" + elemId);
    }
    public cloneItem(parentId: number, maintenanceItem: itemDTO): Observable<itemDTO> {
        return this.postBusy(this.getBaseUrl(parentId) + "/clone", maintenanceItem, "BaseSubMaintenanceController.cloneItem." + this.baseUrl);
    }
    public securityItem(parentId: number, elemId: string): Observable<ObjectSecurityInfoDto> {
        return this.get(this.getBaseUrl(parentId) + "/security/" + elemId);
    }

    public getBaseUrl(parentId: number): string {
        return "/maintenance/" + this.baseUrl + "/" + parentId;
    }
    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/" + this.baseUrl + "/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}