import { Injectable } from '@angular/core';
import { Languages } from '@shared/src/enums/Languages';
import { SkinDto } from '../core/avatar/SkinDto';
import { TimeZones } from '@shared/src/enums/TimeZones';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HDateHour, InitialMenuOptions } from '@shared/src/public-api';


@Injectable()
export class userDto extends SkinDto {

    public userId: number;
    public userKey: string;
    public fullName: string;
    public tenantId: number;
    public eMail: string;
    public prefix: string;
    public phone: string;
    public language: Languages;
    public timeZone: TimeZones;
    public isOwner: boolean;
    public isReadOnly: boolean;
    public alias: string;
    public useAliasAtAvatar: boolean;
    public initialMenuOption: InitialMenuOptions;
    public createdAt: HDateHour;

    public isTotem: boolean;
    public totemSettingId: number;

    public static buildByDto(userdto: userDto): userDto {
        let result = new userDto();
        result.copyFrom(userdto);
        return result;
    }

    public empty(): userDto {
        this.tenantId = 0;
        this.userId = 0;
        this.userKey = "";
        this.fullName = "";
        this.eMail = "";
        this.prefix = "";
        this.phone = "";
        this.alias = "";
        this.useAliasAtAvatar = false;
        this.language = Languages.es;
        this.timeZone = SecurityController.TIMEZONE;
        this.isOwner = false;
        this.initialMenuOption = InitialMenuOptions.none;
        this.createdAt = HDateHour.now();
        this.isTotem = false;
        this.totemSettingId = 0;
        super.empty();
        return this;
    }

    public copyFrom(userdto: userDto) {

        this.tenantId = userdto.tenantId;
        this.userId = userdto.userId;
        this.userKey = userdto.userKey;
        this.fullName = userdto.fullName;
        this.eMail = userdto.eMail;
        this.prefix = userdto.prefix;
        this.phone = userdto.phone;
        this.language = userdto.language;
        this.timeZone = userdto.timeZone;
        this.alias = userdto.alias;
        this.useAliasAtAvatar = userdto.useAliasAtAvatar;
        this.isOwner = userdto.isOwner;
        this.initialMenuOption = userdto.initialMenuOption;
        this.createdAt = userdto.createdAt;
        this.isTotem = userdto.isTotem;
        this.totemSettingId = userdto.totemSettingId;
        super.copyFrom(userdto);
    }

}