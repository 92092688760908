<div class="data" *ngIf="value">
    <div class="info-managementmodel" [ngClass]="{'changed': changeManagementModel }">
        <div class="caixa managementmodel" [ngClass]="{ 'hide': changeManagementModel }">
            <div class="key">{{ value.managementModelKey }}</div>
            <div class="name">{{ value.managementModelName }}</div>
        </div>
        <entity-selector *ngIf="value.canChangedManagementModel" class="selector"
            [ngClass]="{ 'hide': !changeManagementModel }" [(ngModel)]="managementModelId" [readonly]="readonly"
            [entitySelector]="'ManagementModel'" placeholder="{{'ROUTE.MANAGEMENTMODEL.CAPTION' | translate}}"
            name="managementModelId">
        </entity-selector>
        <div *ngIf="value.canChangedManagementModel" class="data-link-changed"
            [ngClass]="{ 'hide': readonly || !value.canChangedManagementModel }"
            (click)="changeManagementModel=!changeManagementModel">
            <button *ngIf="!(managementModelId > 0)" class="link change-text"
                [ngClass]="{ 'hide': changeManagementModel }">{{'ROUTE.MANAGEMENTMODEL.ASSIGN.CAPTION' | translate}}</button>
            <button *ngIf="(managementModelId > 0)" class="link change-text"
                [ngClass]="{ 'hide': changeManagementModel }">{{'ROUTE.MANAGEMENTMODEL.CHANGE.CAPTION' | translate}}</button>
            <button class="link show-text"
                [ngClass]="{ 'hide': !changeManagementModel }">{{'ROUTE.MANAGEMENTMODEL.INFO.CAPTION' | translate}}</button>
        </div>
    </div>
</div>