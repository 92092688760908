
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class AccessControlEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): AccessControlEntitySelectorDto{
        let result: AccessControlEntitySelectorDto = new AccessControlEntitySelectorDto();
		result.loadData(EntitysSelectors.AccessControl, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "system/accesscontrol";
	}

	public getUrlBack(): string {
		return "/maintenance/accesscontrol";
	}
	
}