import { Component, Inject, Input, OnInit } from '@angular/core';
import { HColor } from '@shared/src/datatypes/HColor';
import { HTags } from '@shared/src/datatypes/HTags';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { HHourIntervals } from '@shared/src/datatypes/HHourIntervals';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { HDateHour } from '@shared/src/datatypes/HDateHour';

@Component({
    selector: 'shared-shipment-item',
    templateUrl: './shared.shipment.item.component.html',
    styleUrls: ['./shared.shipment.item.component.scss']
})
export class SharedShipmentItemComponent implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController) {
        this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
            if (data && data.chats)
                data.chats.forEach(e => {
                    if (e && this.shipment)
                        if (e.ownerParticipantType == ChatParticipantTypes.Shipment && e.ownerParticipantId == this.shipment.shipmentId) {
                            this.shipment.pendingMessagesRead = e.pendingMessagesRead;
                        }
                });
        });
    }

    private _shipment: ShipmentCardDto;
    @Input()
    public set shipment(value: ShipmentCardDto) {
        if (this._shipment === value)
            return;
        this._shipment = value;
        this.userTags = HTags.getTags(value.userTags);
        this.internalTags = HTags.getTags(value.internalTags);

        this.homeController.chatsChanged$.next(this.homeController.CHATS);
    }
    public get shipment(): ShipmentCardDto {
        return this._shipment
    }

    @Input()
    public isActiveRoutes: boolean = false;

    userTags: Array<string>;
    internalTags: Array<string>;

    ngOnInit(): void { }

    getTagColor(tag: string): string {
        return HColor.getColorFromStringTable(tag, this.homeController);
    }

    dateIsNull(d: HDateHour): Boolean {
        return HDateHour.isNullOrNullValue(d);
    }

    datesAreEqual(a: HDateHour, b: HDateHour): boolean {
        return HDateHour.equals(a, b);
    }

    intervalIsNull(i: HHourIntervals): boolean {
        return HHourIntervals.isNullOrNullValue(i);
    }
}
