<div class="container" [ngClass]="{'ionic': useIonic }">
    
    <div class="page">
        <div class="column" [ngClass]="{'display-flex' : displayFlex}">
            <div class="title">
                {{'SHARED.TRUCKING.PAGE.TITLE' | translate}}
            </div>
            <div class="lists">
                <div class="trucking-shipments">
                    <shared-trucking-shipments (clickItem)="onSelectedShipment($event)" [selectedRouteExpand]="selectedRouteExpand"></shared-trucking-shipments>
                </div>
                <div class="trucking-routes">
                    <shared-trucking-routes (clickItem)="onSelectedRoute($event)" (refreshRoute)="loadRouteExpand($event)" [selectedRoute]="selectedRouteExpand"></shared-trucking-routes>
                </div>
            </div>
        </div>
        <shared-trucking-route-panel class="container-panel"
            [ngClass]="{'display-flex' : displayFlex, 'display-none' : !displayFlex}" [openPanel]="displayFlex"
            [selectedRouteExpand]="selectedRouteExpand" (clickBack)="onClickBack($event)" (updateRoute)="onSelectedRoute($event)">
        </shared-trucking-route-panel>
    </div>

</div>