
/***
 * Enumeració només de front.
 * Plataformes, no tinc clar que ho haguem fet bé. Platform hauria de ser desktop, mobile i correspondre al projecte.
 * Aquí estem barrejant so i plataformes
 */
export enum Platforms {
    desktop = "desktop",
    ios = "ios",
    android = "android"
}