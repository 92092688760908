
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { GeneralStatus, HomeController, HString, PhaseCardDto, PhaseController, PhaseDto, ShipmentCardDto } from '@shared/src/public-api';

@Component({
    selector: 'shared-state',
    templateUrl: './shared.state.component.html',
    styleUrls: ['./shared.state.component.scss']
})
export class SharedStateComponent implements OnInit {

    public useIonic: boolean = false;
    constructor(@Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homecontroller: HomeController, protected phaseController: PhaseController) {

        if (homecontroller.useIonic)
            this.useIonic = homecontroller.useIonic();
    }

    @ViewChild('listZone') element: ElementRef;
    public listPosition: string = "bottom";
    public innerHeight: number = window.innerHeight;
    public innerWidth: number = window.innerWidth;
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerHeight = window.innerHeight;
    }

    private _showList: boolean = false;
    public set showList(value: boolean) {
        if (value == this._showList)
            return;

        this._showList = value;
        var { x, y } = this.element.nativeElement.getBoundingClientRect();

        if ((y + 150) >= this.innerHeight) {
            this.listPosition = "top";
        }

        if ((x + 310) >= this.innerWidth) {
            this.listPosition = this.listPosition + " right ";
        }
    }
    public get showList(): boolean {
        return this._showList;
    }



    private _value: StatusTransitionsDto;


    @Input() entity: string = "generic";

    private _phase: PhaseCardDto;
    @Input() public set phase(v: PhaseCardDto) {
        if (v && this._phase && this._phase.phaseId == v.phaseId && this._phase.generalStatus == v.generalStatus)
            return;

        this._phase = v;
        if (v) {
            if (v.addressKey)
                this.addressKey = v.addressSkin;
            if (!this.addressKey)
                this.addressKey = v.addressCity;


            if (v.generalStatus == GeneralStatus.loadedunloaded) {
                this.getNextAddressKey();
            }
        }
        this.loadInternalProperties();
        this.setResourceStateCurrentStatus();
        this.setResourceStateNextStatus();
    }
    public get phase(): PhaseCardDto {
        return this._phase;
    }

    private _shipment: ShipmentCardDto;
    @Input() public set shipment(v: ShipmentCardDto) {
        this._shipment = v;
        if (v) {
            if ([GeneralStatus.inprogress, GeneralStatus.promised, GeneralStatus.canceled, GeneralStatus.loaded].includes(v.originStatus)) {
                this.addressKey = v.originSkinAddress;
                if (!this.addressKey)
                    this.addressKey = v.originAddressCity;
            } else {
                this.addressKey = v.destinationSkinAddress;
                if (!this.addressKey)
                    this.addressKey = v.destinationAddressCity;
            }
            this.loadInternalProperties();
            this.setResourceStateCurrentStatus();
            this.setResourceStateNextStatus();
        }
    }

    public get shipment(): ShipmentCardDto {
        return this._shipment;
    }


    @Input() readonly: boolean = false;
    @Input() shownextstate: boolean = false;

    @Input()
    set value(value: StatusTransitionsDto) {
        if (this._value === value)
            return;
        this._value = value;
        this.loadInternalProperties();
        this.setResourceStateCurrentStatus();
        this.setResourceStateNextStatus();
    }
    get value(): StatusTransitionsDto {
        return this._value;
    }

    public helpResource = "";
    loadInternalProperties() {
        if (!this.value || !this.value.currentStatus)
            return;
        this.helpResource = 'GENERALSTATUS.' + this.value.currentStatus.toUpperCase() + '.HELP';
    }
    ngOnInit(): void {
    }

    onSelectLabel() {
        if (this.readonly)
            return;
        this.showList = !this.showList;
    }

    public resourceStateCurrentStatus: string;
    setResourceStateCurrentStatus() {
        if (!this.value || !this.value.currentStatus)
            return;
        this.resourceStateCurrentStatus = 'GENERALSTATUS.' + this.value.currentStatus.toUpperCase() + ".CAPTION";
    }

    public addressKey: string = "";
    public resourceStateNextStatus: string;
    setResourceStateNextStatus() {
        let mobilePrefix = "";
        if (this.useIonic)
            mobilePrefix = "MOBILE.";
        if (this.entity != "generic")
            mobilePrefix += this.entity.toUpperCase() + ".";
        if (this.useIonic && this.value && this.value.currentStatus == GeneralStatus.arrived && this.value.nextStatus == GeneralStatus.inprogress) {
            if (this.phase && this.phase.isLoad) {
                mobilePrefix += "LOAD."
            } else if (this.phase && this.phase.isUnload) {
                mobilePrefix += "UNLOAD."
            }
        }

        if (!this.value || !this.value.currentStatus || !this.value.nextStatus)
            return;
        if (this.value.currentStatus == this.value.nextStatus) {
            this.resourceStateNextStatus = '';
        } else if (!HString.isNullOrNullString(this.value.forcedResourceNextStatus)) {
            this.resourceStateNextStatus = this.value.forcedResourceNextStatus;
        } else {
            this.resourceStateNextStatus = 'GENERALSTATUS.' + mobilePrefix + this.value.currentStatus.toUpperCase() + '.' + this.value.nextStatus.toUpperCase();
        }
    }

    getResourceItemToChange(value: any) {
        if (!value)
            return;
        return 'GENERALSTATUS.' + this.value.currentStatus.toUpperCase() + '.' + value.toUpperCase();
    }

    @Output() stateChange: EventEmitter<any> = new EventEmitter();
    onSelectorChangedState(state) {
        console.log("changedState")
        if (this.readonly)
            return;
        this.showList = false;
        this.value.currentStatus = state;
        this.stateChange.next(this._value.currentStatus);
    }
    @Output() additionalActionClick: EventEmitter<any> = new EventEmitter();
    onSelectorChangedAdditionalAction(action) {
        if (this.readonly)
            return;
        this.showList = false;
        this.additionalActionClick.next(action);
    }

    stateClick(state: any) {
        if (this.readonly)
            return;
        this.value.currentStatus = state;
        this.stateChange.next(state);
    }

    public getNextAddressKey() {
        this.phaseController.getAvailablePhasesToGo(this.phase.phaseId).subscribe((e: Array<PhaseDto>) => {
            if (e != null && e.length > 0) {
                let phase = e[0];
                this.addressKey = phase.addressSkin;
                if (!this.addressKey)
                    this.addressKey = phase.addressCity;
            }
        });
    }

}
