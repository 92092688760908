import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PermissionGroupEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PermissionGroupEntitySelectorDto {
		let result: PermissionGroupEntitySelectorDto = new PermissionGroupEntitySelectorDto();
		result.loadData(EntitysSelectors.PermissionGroup, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/securityandnotifications/permissiongroup";
	}

	public getUrlBack(): string {
		return "/maintenance/system/securityandnotifications/permissiongroup";
	}

}