import { Injectable } from '@angular/core';
import { AlertDto } from '../dtos/alert/AlertDto';
import { AlertTypesClass } from '../enums/AlertTypes';
import { MessageCodes } from '../enums/MessageCodes';
import { MessageTypes } from '../enums/MessageTypes';

@Injectable()
export class ResponseMessage {
    public message: string;
    public idMessage: string;
    public messageType: MessageTypes;
    public messageCode: MessageCodes;
    public styleClass: string;


    static getAlertDTO(message: ResponseMessage): AlertDto {
        let r = new AlertDto();
        r.id = message.idMessage;
        r.message = message.message;
        r.styleClass = message.styleClass;
        r.type = AlertTypesClass.getAlertType(message.messageType);
        return r;
    }

}