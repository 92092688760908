import { Injectable } from '@angular/core';

/***
 * Clase abstracta que defineix les crides de loading. Serveix per a que el BaseController pugui fer crides d'ocupació o de sistema ocupat i que el front decideixi com ha d'interactuar amb l'usuari.
 */
@Injectable()
export abstract class LoadingService {

    public counter: number = 0;
    public counterBusy: number = 0;
    show() {
        this.counter++;
    }
    hide() {
        if (this.counter > 0)
            this.counter--;
    }
    clean() {
        this.counter = 0;
    }

    showBusy() {
        this.counterBusy++;
        this.show();
    }

    hideBusy() {
        if (this.counterBusy > 0)
            this.counterBusy--;
        this.hide();
    }

    clearBusy() {
        this.counterBusy = 0;
        this.clean();
    }

}