import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { FormGroup } from '@angular/forms';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { RouteShipmentConditionTypes } from '@shared/src/enums/RouteShipmentConditionTypes';
import { RouteShipmentConditionDto } from '@shared/src/dtos/route/RouteShipmentConditionDto';


@Component({
    selector: 'shared-shipment-item-conditions-maintenance',
    templateUrl: './shared.shipment.item.conditions.maintenance.component.html',
    styleUrls: ['./shared.shipment.item.conditions.maintenance.component.scss']
})
export class SharedShipmentItemConditionsMaintenanceComponent implements OnInit {

    public readonly: boolean = false;
    public rFormShipment: FormGroup;

    constructor(public openRouteController: OpenRouteController, @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController, private shipmentController: ShipmentController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

        this.subscribeValuesChanged();
        this.securityController.isOwner()
    }

    public mode: string = "readonly";

    @Input()
    public routeId: number;

    @Input()
    public phase: PhaseCardDto;

    private _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment == value)
            return;

        this._shipment = value;

    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }

    ngOnInit(): void {

    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    public shipmentConditionChanged = new Subject<RouteShipmentConditionDto>();
    public subscribeValuesChanged() {
        this.shipmentConditionChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(data => {
            switch (data.type) {
                case RouteShipmentConditionTypes.MaxTemperature:
                case RouteShipmentConditionTypes.MinTemperature:
                case RouteShipmentConditionTypes.MaxHR:
                case RouteShipmentConditionTypes.MinHR:
                    this.openRouteController.shipmentSetConditionDouble(this._shipment.shipmentId, data.type.toString(), data.decimalValue).subscribe(result => {
                        this.valueChange.next(result);
                    });
                    break;
                case RouteShipmentConditionTypes.DueDatetime:
                    //Es de ruta
                    break;
                case RouteShipmentConditionTypes.OriginArrivalTimesIntervals:
                case RouteShipmentConditionTypes.DestinationArrivalTimesIntervals:
                    this.openRouteController.shipmentSetConditionHourIntervals(this._shipment.shipmentId, data.type.toString(), data.intervalValue).subscribe(result => {
                        this.valueChange.next(result);
                    });
                    break;
                case RouteShipmentConditionTypes.ThermostatRunMode:
                    this.openRouteController.shipmentSetConditionTriState(this._shipment.shipmentId, data.type.toString(), data.triStateValue).subscribe(result => {
                        this.valueChange.next(result);
                    });
                    break;
                case RouteShipmentConditionTypes.MinDateTime:
                case RouteShipmentConditionTypes.OriginMinDateTime:
                case RouteShipmentConditionTypes.OriginMaxDateTime:
                case RouteShipmentConditionTypes.OriginLastMomentDateTime:
                case RouteShipmentConditionTypes.OriginFirstMomentDateTime:
                case RouteShipmentConditionTypes.DestinationMinDateTime:
                case RouteShipmentConditionTypes.DestinationMaxDateTime:
                default:
                    this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, data.type.toString(), data.datetimeValue).subscribe(result => {
                        this.valueChange.next(result);
                    });
                    break;
            }
        });
    }

    public onItemConditionChanged(condition: RouteShipmentConditionDto) {
        if (condition == null || condition.type == null)
            return;

        this.shipmentConditionChanged.next(condition);
    }
}
