<div class="container" [ngClass]="generalClass">
        <div class="elem type" matTooltip="{{value.type}}">
                {{resourceName | translate}} <span class="code only-hardman-user">({{value.type}})</span></div>
        <div *ngIf="value.complexScheduler != null" class="elem value date">{{value.dateComplexScheduler | date}}</div>
        <div class="elem value datetimeValue">{{value.datetimeValue | datehour}}</div>
        <div class="elem value intervalValue">{{value.intervalValue | hourintervals}}</div>
        <div class="elem value decimalValue">{{value.value}}</div>
        <div class="elem value triStateValue">{{value.triStateValue | tristate}}</div>
        <shared-tags-strip-component *ngIf="showRecipients" class="tags-conditions"
                [tagsArray]="tags"></shared-tags-strip-component>
</div>