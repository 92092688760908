import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ManagementModelChatInfoDto } from '@shared/src/dtos/managementmodel/ManagementModelChatInfoDto';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ManagementModelController } from '@shared/src/controllers/managementmodel/managementmodel.controller';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';

@Component({
  selector: 'shared-managementmodel-chat-component',
  templateUrl: './shared.managementmodel.chat.component.html',
  styleUrls: ['./shared.managementmodel.chat.component.scss'],
})
export class SharedManagementModelChatComponent implements OnInit, OnDestroy {

  public useIonic: boolean = false;
  constructor( private managementModelController: ManagementModelController , protected translateService: TranslateService,
    @Inject('SecurityController') public securityController: SecurityController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }


  @Input() public readonly: boolean = false;

  public __chatInfo: ManagementModelChatInfoDto;
  @Input()
  set chatInfo(value: ManagementModelChatInfoDto) {
    if (this.__chatInfo === value)
      return;
    this.__chatInfo = value;
    this.loadChat();
  }
  get chatInfo(): ManagementModelChatInfoDto {
    return this.__chatInfo;
  }

  public chat: ChatDto;
  public ownerUserId: number;
  public ownerUserType: ChatParticipantTypes;

  ngOnInit(): void {

  }

  ngOnDestroy() {
  
  }

  loadChat(){
    if(this.chatInfo == null)
      return;
  
    this.managementModelController.chat(this.chatInfo).subscribe(data => {
      this.chat = data;
      if(this.chat && this.chat.user){
        this.ownerUserId = this.chat.userId;
        this.ownerUserType = ChatParticipantTypes.User;
      }else{
        this.ownerUserId = 0;
        this.ownerUserType = ChatParticipantTypes.None;
      }
    });
  }

}
