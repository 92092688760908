<shared-generalstatus-container class="card" *ngIf="routePartCMR" [generalStatus]="routePartCMR.generalStatus">
    <div class="internalcard">
        <div class="routePartCMRId">{{routePartCMR.routePartCMRId}}</div>


        <div class="row main">
            <div class="col left">
                <img *ngIf="formatDocumentQRCode" [src]="formatDocumentQRCode" />
            </div>
            <div class="col center">
                <div class="row">
                    <div class="col">
                        <div class="row routePartCMRKey">
                            <shared-copyclipboard
                                [value]="routePartCMR.routePartCMRKey">{{routePartCMR.routePartCMRKey}}
                            </shared-copyclipboard>
                        </div>
                        <div class="origin row">
                            <div class="row originTitle"
                                *ngIf="!routePartCMR.originTenantName && routePartCMR.originFormattedAddress">
                                Origen
                            </div>
                            <div class="row oiginTenantName" *ngIf="routePartCMR.originTenantName">
                                <shared-tags-strip-component class="tags square small"
                                    [uniqueString]="routePartCMR.originTenantName">
                                </shared-tags-strip-component>
                            </div>
                            <div class="row origen" *ngIf="routePartCMR.originFormattedAddress">
                                {{routePartCMR.originFormattedAddress}}
                            </div>
                        </div>
                        <div class="row destination">
                            <div class="row destinationTitle"
                                *ngIf="!routePartCMR.destinationTenantName && routePartCMR.destinationFormattedAddress">
                                Destino
                            </div>
                            <div class="row destinationTenantName" *ngIf="routePartCMR.destinationTenantName">
                                <shared-tags-strip-component class="tags square small"
                                    [uniqueString]="routePartCMR.destinationTenantName">
                                </shared-tags-strip-component>
                            </div>
                            <div class="row desti" *ngIf="routePartCMR.destinationFormattedAddress">
                                {{routePartCMR.destinationFormattedAddress}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row goods">
                    <div class="col goods ellipsis">{{routePartCMR.goods}}</div>
                </div>
            </div>
        </div>
    </div>
</shared-generalstatus-container>