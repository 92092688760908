import { Component, Input, OnInit, Inject } from '@angular/core';
import { UserInfoCardDto } from '@shared/src/dtos/user/UserInfoCardDto';
import { UserController } from "@shared/src/controllers/user/user.controller";
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { ChatController } from '@shared/src/public-api';
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { userDto } from '@shared/src/dtos/user/userDto';

@Component({
  selector: 'shared-user-log-info',
  templateUrl: './shared.user.log.info.component.html',
  styleUrls: ['./shared.user.log.info.component.scss']
})


export class SharedUserLogInfoComponent implements OnInit {

  constructor(@Inject('SecurityController') protected securityController: SecurityController, public userController: UserController, private chatController: ChatController) {
  }

  ngOnInit(): void {
    this.options = this.buildOptions();
  }

  public value: UserInfoCardDto;
  
  private _userId: number;
  @Input() set userId(value: number) {
    if (this._userId === value)
      return;
    this._userId = value;
    this.loadUser();
  }
  get userId(): number {
    return this._userId;
  }
  
  public options: OptionsStripDto;
  public actualOptionId: string = "CHAT";

  public chat: ChatDto;
  public ownerUserId: number;
  public ownerUserType: ChatParticipantTypes;
  
  loadUser(){
    if(this.userId != null){
      this.userController.getUserById(this.userId).subscribe(data => {
        this.value = data;
        if(this.actualOptionId == "CHAT")
          this.loadChat();
      });
    }
  }

  public buildOptions(): OptionsStripDto {
    let result = new OptionsStripDto();
    result.add(OptionDto.build("CHAT", "SHARED.USERLOGINFO.OPTIONS.CHAT.CAPTION", "SHARED.USERLOGINFO.OPTIONS.CHAT.HELP", "", 20, -1, 1, true, false));
          
    this.actualOptionId = result.getActualSelected().optionId;
    return result;
  }

  public selectedOptionId = "";
  onClickOption(option: OptionDto) {
    this.actualOptionId = option.optionId;
    if (option && option.optionId === "CHAT") {
      this.loadChat();
    } 
  }

  public userLogged: userDto;
  loadChat(){
    if(this.userId != null){
      if(this.userLogged){
        this.getChat();
      }
      this.securityController.getActualUserDto().subscribe(data => {
        this.userLogged = data;
        this.getChat();
      });
    }
  }
  getChat(){
    if(this.userLogged == null)
      return;

    //Si soc jo mateix, no te sentit recuperar chat
    if(this.userId == this.userLogged.userId)
      return;

    let userType = this.userId == -1 ?  ChatParticipantTypes.System : ChatParticipantTypes.User;
    let tchat = null;
    if(userType == ChatParticipantTypes.System)
       tchat = ChatDto.buildByIds(this.userLogged.userId, this.userId, ChatParticipantTypes.System, this.userLogged.userId,  ChatParticipantTypes.User);
    else
       tchat = ChatDto.buildByIds(this.userLogged.userId, this.userLogged.userId, ChatParticipantTypes.User, this.userId,  ChatParticipantTypes.User);
    
    this.chatController.getOrCreateChat(tchat).subscribe(data => {
      this.chat = data;
      if(this.chat){
        this.ownerUserId = this.chat.userId;
        this.ownerUserType = ChatParticipantTypes.User;
      }
    });
  }
}
