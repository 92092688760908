import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Attribute, Component, ContentChild, EventEmitter, HostBinding, Input, Output } from "@angular/core";

@Component({
  selector: "button-link",
  templateUrl: './shared.button.link.component.html',
  styleUrls: ["./shared.button.link.component.scss"],
  animations: [
    trigger('openClose', [
      state('open', style({
        transform: 'translateX(0px)',
        opacity: 1,
      })),
      state('closed', style({
        opacity: 0,
      })),
      transition('* => open', [
        animate('0.5s')
      ]),
      transition('* => close', [
        animate('0.5s')
      ]),
    ]),
    trigger('action', [
      state('action', style({
        transform: 'translateX(0px)',
        color: 'red'
      })),
      state('no-action', style({
        transform: 'translateX(0px)',
        color: 'unset'
      })),
      transition('* => action', [
        animate('0.5s')
      ]),
      transition('* => no-action', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class SharedButtonLinkComponent implements AfterViewInit {
  @HostBinding("class.loading")
  @HostBinding("attr.aria-disabled")

  @HostBinding("class")
  get classes(): string {
    return this.variant || "none";
  }

  constructor(
    @Attribute("variant")
    private variant: "back" | "forward" | "action"
  ) {

    this.variantOpen = "open";
  }


  @Output() linkClick: EventEmitter<any> = new EventEmitter();
  @Output() linkDblclick: EventEmitter<any> = new EventEmitter();

  public variantAction = "";
  public variantOpen = "";
  public onClick($event) {
    if (this.variant == "back" || this.variant == "forward")
      this.variantOpen = "close";
    else
      this.doVariantAction();
    this.linkClick.next($event);
  }
  public onDblClick($event) {
    if (this.variant == "back" || this.variant == "forward")
      this.variantOpen = "close";
    else
      this.doVariantAction();
    this.linkDblclick.next($event);
  }

  public doVariantAction() {
    this.variantAction = "action";
    setTimeout(() => {
      this.variantAction = "no-action";
    }, 500);
  }


  ngAfterViewInit(): void {
  }
}
