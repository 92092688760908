import { Pipe, PipeTransform } from '@angular/core';
import { HString } from '../public-api';
import { HInteger } from '../datatypes/HInteger';
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, filterMetadata?: any): any[] {
        if (!items) {
            if (items != null)
                filterMetadata.count = 0;
            return [];
        }

        if (!searchText) {
            if (filterMetadata != null && (typeof filterMetadata) != 'string')
                if (items != null)
                    filterMetadata.count = items.length;
                else
                    filterMetadata.count = 0;
            return items;
        }
        searchText = searchText.toLowerCase();
        let result = items.filter(it => {
            if ((typeof it) == 'object')
                return this.objectConte(it, searchText);
            else if ((typeof it) == 'string')
                return it.toLowerCase().includes(searchText);
            else if ((typeof it) == 'number')
                return it.toString().includes(searchText);
        });

        result.sort((a, b) => {
            let puntsA = this.getPuntuacio(a, searchText, filterMetadata);
            let puntsB = this.getPuntuacio(b, searchText, filterMetadata);

            if (puntsA == puntsB)
                return 0;
            else if (puntsA > puntsB)
                return -1;
            return 1;
        });

        if (filterMetadata != null && (typeof filterMetadata) != 'string')
            filterMetadata.count = result.length;
        return result;
    }




    private objectConte(value: any, searchText: string): boolean {
        let result = false;
        Object.entries(value).forEach(([key, value]) => {
            if (!result && value != null) {
                if ((typeof value) == 'object') {
                    result = result || this.objectConte(value, searchText);
                }
                else if ((typeof value) == 'string')
                    result = result || String(value).toLowerCase().includes(searchText);
                else if ((typeof value) == 'number')
                    result = result || String(value).includes(searchText);
            }
        });
        return result;
    }


    getPuntuacio(it: any, searchText: string, filterMetadata: any): number {
        if (it == null)
            return 0;
        if ((typeof it) == 'object')
            return this.objectPuntuacio(it, searchText, filterMetadata);
        else if ((typeof it) == 'string') {
            if (it.toLowerCase() === searchText)
                return 100;
            return 0;
        }
        else if ((typeof it) == 'number')
            if (it.toString() === searchText)
                return 100;

    }
    private objectPuntuacio(value: any, searchText: string, filterMetadata: any): number {
        let result = 0;
        Object.entries(value).sort((a: [string, unknown], b: [string, unknown]) => {
            if (!filterMetadata)
                return 0;

            if ((a[0] == filterMetadata && b[0] == filterMetadata) || (a[0] != filterMetadata && b[0] != filterMetadata)) {
                return 0
            } else if ((a[0] == filterMetadata && b[0] != filterMetadata)) {
                return -1;
            } else {
                return 1;
            }
        }).forEach(([key, value]) => {
            if (!result && value != null) {
                if ((typeof value) == 'object') {
                    result = result + this.objectPuntuacio(value, searchText, filterMetadata);
                }
                else if ((typeof value) == 'string') {
                    if (value.toString().toLowerCase() === searchText.toLowerCase()) {
                        if (key === filterMetadata)
                            result = result + 10000;
                        result = result + 1000;
                    }
                    result = result + this.contarPartCoincident(value.toString().toLowerCase(), searchText);
                }
                else if ((typeof value) == 'number')
                    if (value.toString() === searchText) {
                        if (key === filterMetadata)
                            result = result + 1000;
                        result = result + 1000;
                    }
            }
        });
        return result;
    }
    contarPartCoincident(value: string, searchText: string): number {
        if (value == null || searchText == null)
            return 0;

        if (HString.includes(value, searchText))
            return HInteger.min(value.length, searchText.length);
        return 0;
    }
}
