<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="routeId > 0">
    <div class="title">
        {{'SHARED.ROUTE.CHANGECONDITIONS.COMPONENT.TITLE' | translate}}
    </div>
    <div class="block-help">
        {{'SHARED.ROUTE.CHANGECONDITIONS.COMPONENT.HELP' | translate}}
    </div>
    
    <div class="condition-container" [ngClass]="condition.type">
        <enum-selector class="elem type" [(ngModel)]="condition.type" [enumvalues]="enumTypes"
            placeholder="{{'SHARED.ROUTE.CHANGECONDITIONS.ROUTESHIPMENTCONDITIONTYPE.CAPTION' | translate}}" name="type"
            [translategroup]="'ROUTESHIPMENTCONDITIONTYPES'" [showclosebutton]="false">
        </enum-selector>
  
        <div class="elem value valueDateHour">
            <datehour-picker class="col-20" name="valueDateHour" [(ngModel)]="condition.valueDateHour" [readonly]="readonly">
            </datehour-picker>
        </div>
        <div class="elem value valueHourIntervals">
            <hourintervals-picker class="col-20" name="valueHourIntervals" [(ngModel)]="condition.valueHourIntervals" [readonly]="readonly">
            </hourintervals-picker>
        </div>
        <div class="elem value valueDouble">
            <input-component class="col-5" name="valueDouble" type="number" [min]="0" [max]="100" [step]="0.1" autocomplete="off" 
                [(ngModel)]="condition.valueDouble" [readonly]="readonly">
            </input-component>
        </div>
        <div class="elem value valueTriState">
            <tristate name="valueTriState" [(ngModel)]="condition.valueTriState" [readonly]="readonly">
            </tristate>
        </div>
    </div>
    <button *ngIf="condition.type != null && condition.type != 'None'" class="confirmButton" (click)="onSetSuperCondition()">{{'SHARED.ROUTE.CHANGECONDITIONS.SETCONDITION.BUTTON' | translate }}</button>
        
</div>