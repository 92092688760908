<!--docks-planning.component.html-->
<div *ngIf="addressDaySetting != null" class="container-body"
  [ngClass]="{'nocolumns' : !addressDaySetting.columnDocks, 'sicolumns' : addressDaySetting.columnDocks }">

  <div class="container-data-scroll">

    <div class="container-data">


      <div class="general-header">

        <!-- CAPÇALERA ESQUERRA -->
        <div class="block-left">
          <span class="title"></span>
        </div>

        <!-- CAPÇALERA CENTRAL -->
        <div class="block-center">
          <div class="unique-columns">
            <div class="columnwidth">

            </div>
          </div>
          <div class="multiple-columns">
            <div class="columnwidth"
              *ngFor="let item of addressDaySetting.docksDefinedAndNotDefined | sortBy:'skin'; let index=index">
              <div class="dockskin-item">
                <div class="dockskin dockInfoWithOut" [style.color]="getTextColor(item.backgroundColor)"
                  [style.background-color]="getBackColor(item.backgroundColor)">{{item.skin}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- CAPÇALERA DRETA -->
        <div class="block-right seleccionar-dock">
          <div *ngIf="dockToAssigned != null && dockToAssigned.backgroundColor != null && action != 'REMOVE_DOCK'"
            class="dock-seleccionat" [style.background-color]="getBackColor(dockToAssigned.backgroundColor)"
            [style.color]="getTextColor(dockToAssigned.backgroundColor)">
            {{ dockToAssigned.skin }}
          </div>
          <button class="link link-canviDock"
            [ngClass]="{'show': dockToAssigned != null && dockToAssigned.backgroundColor != null && action != 'REMOVE_DOCK'}"
            (click)="dockSelect(true)">{{'DAILYDOCKS.CHANGEDOCK.CAPTION' | translate}}</button>
          <button class="link link-canviDock" [ngClass]="{'show': dockToAssigned == null && action != 'REMOVE_DOCK'}"
            (click)="dockSelect(true)">{{'DAILYDOCKS.SELECTDOCK.CAPTION' | translate}}</button>
        </div>

      </div>

      <div class="container-intervals">

        <div class="line-container item-container" [ngStyle]="getGeneralStyleInterval(intervaldockslots)"
          *ngFor="let intervaldockslots of addressDaySetting.intervals | sortBy:'order'; let index=index">

          <!-- INFO ESQUERRA -->
          <div class="block-left info" [ngStyle]="getGeneralStyleInterval(intervaldockslots)">
            <div class="hora">{{ intervaldockslots.interval | hourinterval }}</div>
            <div class="disponibles">{{ intervaldockslots.dockSlotOccupied }}/{{ intervaldockslots.dockSlotCount }}
            </div>
          </div>

          <!-- DADES CENTRALS -->
          <div class="block-center">
            <div class="unique-columns">
              <div class="columnwidth">
                <shared-dock-planning
                  *ngFor="let dockSlot of intervaldockslots.dockSlots | sortBy:'order'; let index=index"
                  class="dock-planning-item" [ngClass]="{ 'not-alter' : !intervaldockslots.canalterelement }"
                  [action]="action" [dockSlot]="dockSlot" (onRefreshData)="refreshInterval(intervaldockslots)"
                  [dockToAssigned]="dockToAssigned" [columnDocks]="addressDaySetting.columnDocks">
                </shared-dock-planning>
              </div>
            </div>
            <div class="multiple-columns">
              <div class="columnwidth"
                *ngFor="let dock of intervaldockslots.dockSlotsColumn | sortBy:'skin'; let index=index">
                <shared-dock-planning *ngFor="let dockSlot of dock.dockSlots | sortBy:'order'"
                  class="dock-planning-item" [ngClass]="{ 'not-alter' : !intervaldockslots.canalterelement }"
                  [action]="action" [dockSlot]="dockSlot" (onRefreshData)="refreshInterval(intervaldockslots)"
                  [dockToAssigned]="dock.dock" [columnDocks]="addressDaySetting.columnDocks">
                </shared-dock-planning>
              </div>
            </div>
          </div>
          <!-- PENDENTS -->
          <div class="block-right" [ngStyle]="getGeneralStyleInterval(intervaldockslots)">
            <div class="columnwidth">
              <shared-dock-planning
                *ngFor="let dockSlot of intervaldockslots.dockSlotsPending | sortBy:'order'; let index=index"
                class="dock-planning-item" [ngClass]="{ 'not-alter' : !intervaldockslots.canalterelement }"
                [action]="action" [dockSlot]="dockSlot" (onRefreshData)="refreshInterval(intervaldockslots)"
                [dockToAssigned]="dockToAssigned">
              </shared-dock-planning>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vl"></div>
  </div>
</div>