<div *ngIf="value" class="requirements-zone">
    <div class="requirements-list" *ngIf="value.requirements != null && value.requirements.length > 0">

        <div *ngIf="!useIonic">
            <shared-managementmodel-requirement-item-component *ngFor="let item of value.requirements | sortBy:'requirementOrder'; let i = index" [value]="item" (dataRefresh)="onDataRefresh(i, $event)" [order]="i+1"
            [showorder]="showorder" [readonly]="readonly">
            </shared-managementmodel-requirement-item-component>
        </div>
        <div *ngIf="useIonic">
            <shared-ionic-managementmodel-requirement-item-component *ngFor="let item of value.requirements | sortBy:'requirementOrder'; let i = index" [value]="item" (dataRefresh)="onDataRefresh(i, $event)" [order]="i+1"
            [showorder]="showorder" [readonly]="readonly">
            </shared-ionic-managementmodel-requirement-item-component>
        </div>
    </div>
</div>