<p class="helppoint-title">{{data.title}}</p>

<entity-selector [searchBeforeFocus]="true" [(ngModel)]="partnerId" (valuechangefunction)="propagatePartner()" placeholder="{{ 'UPDATEPARTNERDIALOG.PARTNERASSISTANT.TITLE' | translate}}"
    [entitySelector]="'Partner'" [fixedFilterKeys]="filter"></entity-selector>

<input-component class="col-x" [(ngModel)]="data.name"
    placeholder="{{'PARTNER.NAME.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.nif"
    placeholder="{{'PARTNER.NIF.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.contactName"
    placeholder="{{'PARTNER.REFERENCENAME.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.contactPhone"
    placeholder="{{'PARTNER.PHONE.CAPTION' | translate}}"></input-component>

<shared-selector-addressmanualaddress-component class="col-x" selectorPlaceholder="UPDATEPARTNERDIALOG.ADDRESSASSISTANT.TITLE"
    (onChanged)="onChangedAddress($event)" [disableManual]="true" [addressManualAddress]="address">
</shared-selector-addressmanualaddress-component>

<input-component class="col-x" [(ngModel)]="data.formattedAddress"
    placeholder="{{'REGISTERCOMPONENT.COMPANY_ADDRESS.LABEL' | translate}}"></input-component>

<div align="end">
    <button mat-raised-button class="helppoint-button confirm" (click)="onConfirm()"
        color="primary">{{'MAINTENANCE.ACCEPT' | translate}}</button>
    <button mat-raised-button class="helppoint-button close" (click)="onClose()"
        color="link">{{'CANCEL.BUTTON' | translate}}</button>
</div>