<div class="maintenance" class="generalClass">
    <div class="maintenance-internal internal-mapa"
        [ngClass]="{'internal-mapa-activeroutes' : isRutesActives, 'internal-mapa-ionic' : useIonic}">
        <div class="maintenance-zone zone-mapa"
            [ngClass]="{'zone-mapa-activeroutes' : isRutesActives, 'zone-mapa-ionic' : useIonic}">
            <shared-map class="map" [ngClass]="{'ionic' : useIonic}" [zoom]="zoom" [latitude]="latitude"
                [longitude]="longitude" [marcadors]="marcadors" [rutes]="rutes">
            </shared-map>
        </div>
    </div>
    <div class="dummy-maintenance-internal"></div>
</div>