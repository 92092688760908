import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { ShipmentCardDto } from '../../../dtos/shipment/ShipmentCardDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { ShipmentItemMaintenanceCtrlDto } from '@shared/src/dtos/route/ShipmentItemMaintenanceCtrlDto';
import { ShipmentDto } from '@shared/src/dtos/shipment/ShipmentDto';
import { ShipmentLogDto } from '@shared/src/dtos/shipment/ShipmentLogDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto'
import { DocumentDto } from '@shared/src/dtos/document/DocumentDto';
import { ShipmentDeliveryStateDto } from '@shared/src/dtos/shipment/ShipmentDeliveryStateDto';
import { FormQuestionAnswerShipmentFilterDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentFilterDto';
import { RouteMapInfoDto } from '@shared/src/dtos/route/RouteMapInfoDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ShipmentWizardDto } from '@shared/src/dtos/route/wizard/ShipmentWizardDto';
import { OpenDocument } from '@shared/src/public-api';
import { MaintenanceSearchByTextDto } from '../../maintenance/_base/MaintenanceSearchByTextDtos';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { ClientProviderViewShipmentDto } from '@shared/src/dtos/shipment/ClientProviderViewShipmentDto';
import { FilterClientProviderViewShipmentDto } from '@shared/src/dtos/shipment/FilterClientProviderViewShipmentDto';
import { TelemetryDto } from '../../maintenance/device/telemetry/TelemetryDto';
import { ShipmentAddressDto } from '@shared/src/dtos/shipment/ShipmentAddressDto';
import { FormQuestionAnswerShipmentListDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentListDto';
import { FilterLogDto } from '@shared/src/dtos/route/FilterLogDto';
import { RouteGroupShipmentDto } from '@shared/src/dtos/route/RouteGroupShipmentDto';
import { ShipmentStatusTimeDto } from '../dto/ShipmentStatusTimeDto';

@Injectable({
    providedIn: 'root',
})
export class ShipmentController extends BaseController {

    private base = '/shipment'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getShipmentsByPhaseId(phaseId: number): Observable<Array<ShipmentCardDto>> {
        return this.get(this.base + "/byphase/" + phaseId);
    }

    public getRouteGroupShipmentsByRouteId(routeId: number): Observable<Array<RouteGroupShipmentDto>> {
        return this.get(this.base + "/byroute/routegroup/shipments/" + routeId);
    }
    getShipment(phaseId: number, shipmentId: number): Observable<ShipmentCardDto> {
        return this.get(this.base + "/card/" + phaseId + "/" + shipmentId);
    }
    getCardByShipment(shipmentId: number): Observable<ShipmentCardDto> {
        return this.get(this.base + "/card/" + shipmentId);
    }
    getShipmentItemMaintenanceCtrl(shipmentId: number, phaseId: number): Observable<ShipmentItemMaintenanceCtrlDto> {
        return this.get(this.base + "/maintenance/" + shipmentId + "/" + phaseId);
    }

    public getByShipmentId(shipmentId: number): Observable<ShipmentDto> {
        return this.get(this.base + "/" + shipmentId);
    }
    public printpdf(shipmentId: number, extended: boolean): Observable<FileUpDownLoadDto> {
        return this.getBusy(this.base + "/pdf/" + shipmentId + "/" + extended);
    }

    public printpdfByProviderAndDevice(shipmentId: number, providerDevice: string): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/pdf/" + shipmentId + "/providerdevice/" + providerDevice);
    }

    public getCMRDocumentByShipment(shipmentid: number): Observable<DocumentDto> {
        return this.get(this.base + "/cmr/shipment/" + shipmentid);
    }

    public getAlbaraDocumentByShipment(shipmentid: number): Observable<DocumentDto> {
        return this.get(this.base + "/albara/shipment/" + shipmentid);
    }

    public getOtherDocumentByShipment(shipmentid: number): Observable<DocumentDto> {
        return this.get(this.base + "/other/shipment/" + shipmentid);
    }

    public getDeliveryDocumentByShipment(shipmentid: number): Observable<DocumentDto> {
        return this.get(this.base + "/delivery/shipment/" + shipmentid);
    }

    public getShipmentLegalDocuments(shipmentId: number): Observable<Array<OpenDocument>> {
        return this.get(this.base + "/legaldocuments/shipment/" + shipmentId)
    }

    public getShipmentDeliveryStates(shipmentid: number): Observable<ShipmentDeliveryStateDto> {
        return this.get(this.base + "/deliverystate/" + shipmentid);
    }

    public getFormQuestionsByShipmentId(shipmentid: number, filter: FormQuestionAnswerShipmentFilterDto): Observable<FormQuestionAnswerShipmentListDto[]> {
        return this.post(this.base + "/questionsanswer/get/" + shipmentid, filter, "getFormQuestionsByShipmentId");
    }


    public sendQuestionsAnswers(shipmentid: number, answers: FormQuestionAnswerShipmentListDto): Observable<Boolean> {
        return this.post(this.base + "/questionsanswer/set/" + shipmentid, answers, "sendQuestionsAnswers");
    }

    public getMapInfoByShipmentId(shipmentId: number): Observable<RouteMapInfoDto> {
        return this.get(this.base + "/map-info/" + shipmentId);
    }

    public getShipmentsByRoute(search: MaintenanceSearchByTextDto): Observable<Array<ShipmentCardDto>> {
        return this.post(this.base + "/getshipmentsbyroute", search, "getShipmentsByRoute");
    }

    public getRouteShipmentsActions(shipmentId: number): Observable<ActionsStripDto> {
        return this.get(this.base + "/routeshipmentsactions/" + shipmentId);
    }

    public getRouteShipmentsOptions(shipmentId: number, phaseId: number): Observable<OptionsStripDto> {
        return this.get(this.base + "/routeshipmentsoptions/" + shipmentId + "/" + phaseId);
    }

    public cloneShipmentById(shipmentId: number): Observable<boolean> {
        return this.getBusy(this.base + "/clone/" + shipmentId);
    }

    public deleteShipmentById(shipmentId: number): Observable<boolean> {
        return this.delete(this.base + "/delete/" + shipmentId);
    }

    public getShipmentWizardEmptyByRoute(routeId: number): Observable<ShipmentWizardDto> {
        return this.get(this.base + "/getshipmentwizard/" + routeId + "/empty");
    }

    public getShipmentWizardByRouteIdByShipmentId(routeId: number, shipmentId: number): Observable<ShipmentWizardDto> {
        return this.get(this.base + "/getshipmentwizard/" + routeId + "/" + shipmentId);
    }

    public setShipmentWizardTenantSettings(shipmentWizard: ShipmentWizardDto): Observable<ShipmentWizardDto> {
        return this.post(this.base + "/setshipmentwizardtenantsettings", shipmentWizard, "setshipmentwizardtenantsettings");
    }

    public addShipment(shipment: ShipmentWizardDto): Observable<Boolean> {
        return this.postBusy(this.base + "/addshipment", shipment, "addShipment");
    }

    public editShipment(shipment: ShipmentWizardDto): Observable<Boolean> {
        return this.post(this.base + "/editshipment", shipment, "editShipment");
    }

    public getProposeShipmentKey(): Observable<String> {
        return this.get(this.base + "/getproposekey");
    }

    public forceDefaultValues(shipment: ShipmentWizardDto, mode: string, filechanged: string): Observable<ShipmentWizardDto> {
        return this.post(this.base + "/forcedefaultvalues/" + mode + "/" + filechanged, shipment, "forceDefaultValues");
    }

    public requiredLoadForm(shipmentId: number): Observable<ShipmentWizardDto> {
        return this.post(this.base + "/requiredloadform", shipmentId, "requiredLoadForm");
    }

    public getAddressOrigin(shipmentId: number): Observable<ShipmentAddressDto[]> {
        return this.get(this.base + "/" + shipmentId + "/get/address/origin");
    }

    public getAddressDestination(shipmentId: number): Observable<ShipmentAddressDto[]> {
        return this.get(this.base + "/" + shipmentId + "/get/address/destination");
    }

    public emptyAddressOrigin(shipmentId: number): Observable<ShipmentAddressDto> {
        return this.get(this.base + "/" + shipmentId + "/empty/address/origin");
    }

    public emptyAddressDestination(shipmentId: number): Observable<ShipmentAddressDto> {
        return this.get(this.base + "/" + shipmentId + "/empty/address/destination");
    }

    public addAddress(shipmentId: number, shipmentAddress: ShipmentAddressDto): Observable<Boolean> {
        return this.post(this.base + "/" + shipmentId + "/add/address", shipmentAddress, "addAddress");
    }

    public deleteAddress(shipmentId: number, shipmentAddress: ShipmentAddressDto): Observable<Boolean> {
        return this.post(this.base + "/" + shipmentId + "/delete/address", shipmentAddress, "deleteAddress");
    }

    public requiredUnLoadForm(shipmentId: number): Observable<ShipmentWizardDto> {
        return this.post(this.base + "/requiredunloadform", shipmentId, "requiredUnLoadForm");
    }

    public getShipmentsByClientProvider(filter: FilterClientProviderViewShipmentDto): Observable<Array<ClientProviderViewShipmentDto>> {
        return this.post(this.base + "/byclientprovider", filter, "getShipmentsByClient");
    }

    public getClientProviderViewShipmentOptions(shipmentId: number): Observable<OptionsStripDto> {
        return this.get(this.base + "/clientproviderviewoptions/" + shipmentId);
    }
    
    public sendTracking(shipmentId: number): Observable<Boolean> {
        return this.get(this.base + "/sendtracking/" + shipmentId);
    }

    ///Endpoints Mobile
    public setasobserver(shipmentId: number): Observable<ShipmentCardDto> {
        return this.post(this.base + "/setasobserver/" + shipmentId + "/true", null, "setasobserver");
    }
    public unsetasobserver(shipmentId: number): Observable<ShipmentCardDto> {
        return this.post(this.base + "/setasobserver/" + shipmentId + "/false", null, "setasobserver");
    }

    public uploadDocument(shipmentId: number, file: DocumentDto): Observable<Boolean> {
        return this.postBusy(this.base + "/upload/document/" + shipmentId, file, 'uploadOther')
    }

    ////////////////////////////// Endpoints Mobile //////////////////////////////
    public getShipmentsMobileByRouteId(routeId: number): Observable<Array<ShipmentCardDto>> {
        return this.get(this.base + "/mobile/card/byroute/" + routeId);
    }
    public getShipmentCardMobile(shipmentId: number): Observable<ShipmentCardDto> {
        return this.get(this.base + "/mobile/card/" + shipmentId);
    }
    public getShipmentLogs(shipmentid: number, filter: FilterLogDto): Observable<Array<ShipmentLogDto>> {
        return this.post(this.base + "/mobile/logs/" + shipmentid, filter, "getShipmentLogs");
    }

    public getShipmentStatesMobile(phaseid: number, shipmentId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/mobile/states/" + phaseid + "/" + shipmentId);
    }

    public executeAdditionalActionMobile(routeid: number, phaseid: number, shipmentid: number, action: string): Observable<Boolean> {
        return this.post(this.base + "/mobile/additionalaction/execute/" + routeid + "/" + phaseid + "/" + shipmentid, action, "executeAdditionalActionMobile");
    }

    public getStatusTimeById(id: any): Observable<ShipmentStatusTimeDto> {
        return this.get(this.base + "/statustime/shipmentid/" + id);
    }

    ////////////////////////////////////////////////////////////
}
