import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs";

import { BaseController } from "../base.controller";
import { AlertService } from "../../services/alert/alert.service";
import { LoadingService } from "../../services/loading/loading.service";
import { UserSettingListMaintenanceDto } from "../maintenance/user/setting/UserSettingListMaintenanceDto";
import { HTags } from "@shared/src/datatypes/HTags";
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";


@Injectable({
    providedIn: 'root',
})
export class UserSettingController extends BaseController {
    private base = '/usersetting';

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    /**
     * Estableix un setting al back per al current User. Atenció no retorna promesa per que si no t'hi vincules no desa, així que ho fa ella mateixa
     */
    public setSetting(setting: string, value: String): Observable<any> {
        return super.post(this.base + "/setting/" + setting, value, "setSetting");
    }
    public getSetting(setting: string): Observable<any> {
        return super.get(this.base + "/setting/" + setting);
    }
    public setDefaultAmbitId(value: number): Observable<any> {
        return super.post(this.base + "/setting/DefaultAmbitId/" + value, "", "setDefaultAmbitId");
    }
    public setDefaultAddressId(value: number): Observable<any> {
        return super.post(this.base + "/setting/DefaultAddressId/" + value, "", "setDefaultAddressId");
    }
    public setDefaultAccessControlId(value: number): Observable<any> {
        return super.post(this.base + "/setting/DefaultAccessControlId/" + value, "", "setDefaultAccessControlId");
    }
    public updateEntitySelectorCounters(entityselector: string, id: number, filterKeys: Array<String>): Observable<any> {
        return super.post(this.base + "/setting/entityselector/" + entityselector + "/" + id, filterKeys, "updateEntitySelectorCounters");
    }
    public getUserSettings(): Observable<Array<UserSettingListMaintenanceDto>> {
        return this.get(this.base + "/setting/getall");
    }
    public getHTags(settingId: string): Observable<HTags> {
        return this.get(this.base + "/setting/htags/" + settingId);
    }
    public setHTags(settingId: string, value: HTags): Observable<HTags> {
        return this.put(this.base + "/setting/htags/" + settingId, value, "settingHTags." + settingId);
    }
    public updateMenuCounters(key: string): Observable<boolean> {
        return this.put(this.base + "/setting/menu/" + key, {}, "updateMenuCounters");
    }

}

export enum UserSettingsEnum {
    DefaultAddressId = "DefaultAddressId",
    DefaultAmbitId = "DefaultAmbitId",
    DefaultActiveRoutePhaseOption = "DefaultActiveRoutePhaseOption",
    DefaultLoadConsolePhaseOption = "DefaultLoadConsolePhaseOption",
    DefaultPanelElementOption = "DefaultPanelElementOption",
    DefaultLoadConsoleLoadConsoleId = "DefaultLoadConsoleLoadConsoleId",
    StatisticsDeliveryFilterPositiveTags = "StatisticsDeliveryFilterPositiveTags",
    StatisticsDeliveryFilterNegativeTags = "StatisticsDeliveryFilterNegativeTags",
}
