<form class="form" [formGroup]="billingInfoFormGroup" *ngIf="billingInfo">
    <input-component class="col-sm-12" [(ngModel)]="billingInfo.organizationName"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_DESCRIPTION.LABEL' | translate}}" formControlName="organizationName"
        autocomplete="on" [readonly]="readonly"></input-component>
    <input-component class="col-sm-12" [(ngModel)]="billingInfo.addressLine1"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_ADDRESS_1.LABEL' | translate}}" formControlName="addressLine1"
        autocomplete="on" [readonly]="readonly"></input-component>
    <input-component class="col-sm-12" [(ngModel)]="billingInfo.addressLine2"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_ADDRESS_2.LABEL' | translate}}" formControlName="addressLine2"
        autocomplete="on" [readonly]="readonly"></input-component>

    <div class="col-x">
        <input-component class="col-sm-2" [(ngModel)]="billingInfo.postalCode"
            placeholder="{{'REGISTERCOMPONENT.POSTALCODE.LABEL' | translate}}" formControlName="postalCode"
            autocomplete="on" [readonly]="readonly"></input-component>
        <input-component class="col-sm-4 ml-10" [(ngModel)]="billingInfo.vatNumber"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_VAT_NUMBER.LABEL' | translate}}" formControlName="vatNumber"
            autocomplete="on" [readonly]="readonly"></input-component>
    </div>

    <div class="col-x">
        <input-component class="col-sm-6" [(ngModel)]="billingInfo.city"
            placeholder="{{'REGISTERCOMPONENT.CITY.LABEL' | translate}}" formControlName="city" autocomplete="on"
            [readonly]="readonly"></input-component>
        <input-component class="col-sm-6 ml-10" [(ngModel)]="billingInfo.province"
            placeholder="{{'REGISTERCOMPONENT.PROVINCE.LABEL' | translate}}" formControlName="province"
            autocomplete="on" [readonly]="readonly"></input-component>
    </div>

    <div class="col-x">
        <input-component class="col-sm-6" [(ngModel)]="billingInfo.state"
            placeholder="{{'REGISTERCOMPONENT.STATE.LABEL' | translate}}" formControlName="state" autocomplete="on"
            [readonly]="readonly"></input-component>
        <input-component class="col-sm-6 ml-10" [(ngModel)]="billingInfo.country"
            placeholder="{{'REGISTERCOMPONENT.COUNTRY.LABEL' | translate}}" formControlName="country" autocomplete="on"
            [readonly]="readonly"></input-component>
    </div>

    <input-component class="col-sm-12" [(ngModel)]="billingInfo.contactName"
        placeholder="{{'REGISTERCOMPONENT.CONTACTNAME.LABEL' | translate}}" formControlName="contactName"
        autocomplete="on" [readonly]="readonly"></input-component>

    <div class="col-x">
        <enum-selector *ngIf="!useIonic" class="col-prefix-phone" [(ngModel)]="billingInfo.contactPhonePrefix"
            [readonly]="readonly" [enumvalues]="enumPrefixes"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE_PREFIX.LABEL' | translate}}"
            [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="contactPhonePrefix">
        </enum-selector>

        <enum-selector *ngIf="useIonic" [ngClass]="{'phone-prefix-ios' : isIos}"
            [(ngModel)]="billingInfo.contactPhonePrefix" [readonly]="readonly" [enumvalues]="enumPrefixes"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE_PREFIX.LABEL' | translate}}"
            [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="contactPhonePrefix">
        </enum-selector>
        <input-component class="col-sm-4 ml-10" [(ngModel)]="billingInfo.contactPhone"
            placeholder="{{'REGISTERCOMPONENT.CONTACTPHONE.LABEL' | translate}}" formControlName="contactPhone"
            autocomplete="on" [readonly]="readonly"></input-component>
        <input-component class="col-sm-4 ml-10" [(ngModel)]="billingInfo.contactEmail"
            placeholder="{{'REGISTERCOMPONENT.CONTACTEMAIL.LABEL' | translate}}" formControlName="contactEmail"
            autocomplete="on" [readonly]="readonly"></input-component>
    </div>
</form>