<!--directiva: actions.component.html!-->
<div class="actions" [ngClass]="getGeneralClass()" *ngIf="actionstrip!=null">
    <div class="actions-bar" *ngIf="actionstrip.listAsBar!=null && !hideactionsbar">
        <div #chips1 class="action-chip-list">
            <div class="action-chip" [ngClass]="getItemClass(item)"
                *ngFor="let item of actionstrip.listAsBar | orderactiondto | avoidActions:'only-toolbar'"
                matTooltip="{{item.actionHelp | translate}}" selected="true" (click)="chipClick(item, $event)">
                <div class="actionoption-container">
                    <mat-icon class="icon-no-awesome">{{item.actionIcon}}</mat-icon>
                    <i class="icon-awesome fa actions-fa-icon" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                    <i class="icon-hardman" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                    <div class="action-caption span-icon-text">{{getCaption(item) | translate}}</div>
                    <div class="action-caption span-only-text">{{item.actionCaption | translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="actionoptions-toolbar" *ngIf="actionstrip.listAsBar!=null && !hideactionstoolbar">
        <button mat-stroked-button class="actionoption-toolbar-button"
            *ngFor="let item of actionstrip.listAsBar  | orderactiondto | avoidActions:'no-toolbar'"
            [ngClass]="getItemClass(item)" matTooltip="{{item.actionHelp | translate}}"
            (click)="chipClick(item, $event)">
            <div class="actionoption-container">
                <mat-icon class="icon-no-awesome">{{item.actionIcon}}</mat-icon>
                <i class="icon-awesome fa options-fa-icon" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                <i class="icon-hardman" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                <div class="actionoption-caption">{{item.actionCaption | translate}}</div>
            </div>
        </button>
    </div>
    <div class="actions-menu" *ngIf="actionstrip.listAsMenu!=null && !hideactionsmenu">
        <button class="boto-more-vert" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon *ngIf="!useIonic">more_vert</mat-icon>
            <i *ngIf="useIonic" class="fa fa-ellipsis-v"></i>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-stroked-button
                *ngFor="let item of actionstrip.listAsMenu  | orderactiondto | avoidActions:'only-toolbar'"
                class="action-menu-button" (click)="menuClick(item, $event)" [ngClass]="getItemClass(item)"
                matTooltip="{{item.actionHelp | translate}}">
                <div class="action-menu-button-container">
                    <mat-icon class="icon-no-awesome">{{item.actionIcon}}</mat-icon>
                    <i class="icon-awesome fa actions-fa-icon" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                    <i class="icon-hardman" [ngClass]="item.actionIcon" aria-hidden="true"></i>
                    <div class="action-caption">{{item.actionCaption | translate}}</div>
                </div>
            </button>
        </mat-menu>
    </div>

</div>