import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { Route_eCMRIncotermDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRIncotermDto';
import { Incoterms } from '@shared/src/public-api';

@Component({
  selector: 'updateincotermdialog-dialog',
  templateUrl: './updateincotermdialog.dialog.component.html'
})
export class UpdateIncotermDialogDialogComponent {

  @Output() public onResolve: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<UpdateIncotermDialogDialogComponent>,
    private mdDialog: MatDialog,
    private ecmrController: RouteCMRController) { }

  public incoterms = Incoterms;

  onClose() {
    this.onResolve.next(false);
    this.mdDialog.closeAll();
  }

  onConfirm() {
    this.ecmrController.updateIncoterm(this.data.routePartCMRId, Route_eCMRIncotermDto.build(this.data.incoterm)).subscribe(e => {
      if (e) {
        this.onResolve.next(true);
        this.mdDialog.closeAll();
      }
    });
  }
}