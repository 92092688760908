<ng-container *ngIf="route && phase">
    <div class="linia primera">
        <div class="routeminicard esquerra"
            [ngClass]="{'isSlotBlocked':phase.isSlotBlocked, 'incompatibleSlot':phase.incompatibleSlot}">
            <div class="icona esquerra">
                <img *ngIf="phase.isUnload" src="/assets/images/unloadCargo.png" alt="unload Cargo">
                <img *ngIf="phase.isLoad" src="/assets/images/loadCargo.png" alt="unload Cargo">
                <img *ngIf="phase.isUnloadLoad" src="/assets/images/loadUnloadCargo.png" alt="unload Cargo">
                <i *ngIf="phase.isInitial || phase.isFinal" class="fa fa-circle-thin"></i>
            </div>
            <div class="esquerra">
                <div class="title">{{route.title}}</div>
                <div class="routekey">{{route.routeKey}}</div>
            </div>
            <shared-tags-strip-component class="tagsbottom" [tags]="route.manualTags"></shared-tags-strip-component>
            <shared-tags-strip-component class="tagsbottom" [tags]="route.tagsBottom"></shared-tags-strip-component>
            <shared-tags-strip-component class="tagsbottom" [tags]="phase.manualTags"></shared-tags-strip-component>
            <shared-tags-strip-component class="tagsbottom" [tags]="phase.tags"></shared-tags-strip-component>
        </div>
        <shared-slot-viewer-component *ngIf="phase" class="esquerra" [slotInfo]="slotInfo" [explicat]="'true'"
            [phaseId]="phase.phaseId">
        </shared-slot-viewer-component>
    </div>
    <div *ngIf="route && route.styledBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledBannerHtml | safe: 'html'">
    </div>
    <div *ngIf="route && route.styledPrivateBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledPrivateBannerHtml | safe: 'html'">
    </div>
    <div class="linia  baractions">
        <actions class="slim" *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)">
        </actions>
        <shared-state [(value)]="states" (stateChange)="onSelectorChangedState($event)" [readonly]="readonly">
        </shared-state>
    </div>
    <div class="linia  baroptions">
        <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>

    <div class="ultima-linia">
        <ng-container *ngIf="actualOptionId==='GENERAL'">
            <shared-driverplate class="block-option" *ngIf="route" [showCarrierCard]="false" [onlydriver]="true" [route]="route"></shared-driverplate>
            <div *ngIf="data" class="block actions-calldock">
                <button *ngIf="data.callInToDock" class="button-calldock" (click)="onCallInToDockClick()"><i
                        class="fa fa-bell" aria-hidden="true"></i>
                    <div class="text-action">
                        {{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.CALLINTODOCK.PROMPT' | translate}}
                    </div>
                </button>
                <button *ngIf="data.cancelCallInToDock" class="button-calldock cancel"
                    (click)="onCancelCallInToDockClick()"><i class="fa fa-bell-slash" aria-hidden="true"></i>
                    <div class="text-action">
                        {{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.CANCELCALLINTODOCK.PROMPT' | translate}}
                    </div>
                </button>

                <button *ngIf="data.callOutToDock" class="button-calldock exit" (click)="onCallOutToDockClick()"><i
                        class="fa fa-bell" aria-hidden="true"></i>
                    <div class="text-action">
                        {{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.CALLOUTODOCK.PROMPT' | translate}}
                    </div>
                </button>
                <button *ngIf="data.cancelCallOutToDock" class="button-calldock exit cancel"
                    (click)="onCancelCallOutToDockClick()"><i class="fa fa-bell-slash" aria-hidden="true"></i>
                    <div class="text-action">
                        {{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.CANCELCALLOUTODOCK.PROMPT' | translate}}
                    </div>
                </button>
            </div>

            <div *ngIf="data" class="block actions-gate">
                <div class="header">
                    <div class="title-log title-gate">{{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.ACTIONS-GATE.TITLE'
                        | translate}}</div>
                    <actions class="gate" [actionstrip]="actionsGates" (clickAction)="onClickActionGates($event)">
                    </actions>

                </div>

                <button *ngFor="let gate of data.allGates | sortBy:'gateOrder'; let i=index" class="button-gate"
                    [ngClass]="{ 'exit' : gate.isInsideGate }" [ngStyle]="getStyleGateTag(gate)"
                    (click)="onGateClick(gate)">

                    <i class="fa " [ngClass]="{ 'fa-sign-out' : gate.isInsideGate, 'fa-sign-in' : !gate.isInsideGate }"
                        aria-hidden="true"></i>
                    <div class="heading">{{getGateHeadingResource(gate) | translate}}</div>
                    <div class="text-action">
                        {{ getGateResource(gate) }}
                    </div>
                </button>

                <button *ngIf="data.showButtonGetOutOfAllGates"
                    matTooltip="{{ 'ACTIONS.GETOUTOFALLGATES.HELP' | translate }}" class="button-gate exit"
                    (click)="onGetOutOfAllGatesClick()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    <div class="heading">{{ 'GATE.OUT.HEADING' | translate}}</div>
                    <div class="text-action"> {{ 'ACTIONS.GETOUTOFALLGATES.CAPTION' | translate }} </div>
                </button>

            </div>

        </ng-container>

        <ng-container *ngIf="actualOptionId==='DRIVER'">
            <shared-driverplate class="block-option" *ngIf="route" [showCarrierCard]="false" [onlydriver]="true"
                [route]="route"></shared-driverplate>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='SLOT'">
            <div class="block-option" *ngIf="phase">
                <shared-slot-picker class="block hide-bloqueig hide-lastmoments hide-titol"
                    (valueChange)="loadPhaseData()" [phase]="phase">
                </shared-slot-picker>
            </div>
        </ng-container>
        <ng-container *ngIf="actualOptionId==='DOCKSSLOTS'">
            <div class="block-option" *ngIf="phase">
                <shared-dock-picker class="hide-bloqueig hide-lastmoments hide-titol" (valueChange)="loadPhaseData()"
                    [phase]="phase" [modeByPhase]="true">
                </shared-dock-picker>
                <shared-slot-picker class="block hide-bloqueig hide-lastmoments hide-titol"
                    (valueChange)="loadPhaseData()" [phase]="phase">
                </shared-slot-picker>
            </div>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='INTERNATIONAL'">
            <shared-route-booking class="block-option" *ngIf="route" [routeId]="route.routeId" [readonly]="readonly">
            </shared-route-booking>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='ROUTEHISTORY'">
            <shared-route-item-log-maintenance class="block-option" *ngIf="route" [routeId]="route.routeId">
            </shared-route-item-log-maintenance>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='CHAT'">
            <shared-chat class="block-option" [forcedOwnerParticipant]="forcedOwnerParticipant" [notes]="true"
                (clickParticipant)="clickParticipant($event)">
            </shared-chat>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='OBSERVATIONS'">
            <div class="block-option" *ngIf="route">
                <shared-banners [routeId]="route.routeId" class="aside"></shared-banners>
                <shared-route-changetags [routeId]="route.routeId" class="aside"
                    [showalltags]="true"></shared-route-changetags>
            </div>
        </ng-container>

        <ng-container *ngIf="actualOptionId==='GROUPED'">
            <shared-route-group-child-list-component class="block-option"
                [phaseId]="data.phaseId"></shared-route-group-child-list-component>
        </ng-container>

    </div>
</ng-container>