<form class="form" [formGroup]="companyFormGroup" *ngIf="linkType == 'company'">
    <input-component class="col-x" [(ngModel)]="link.partnerKey" name="name" formControlName="partnerKey"
        placeholder="{{'REGISTERCOMPONENT.TENANT_LINK_KEY.LABEL' | translate}}" autocomplete="on" [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.tradeMarkTarget" name="name" formControlName="tradeMarkTarget"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_TRADEMARK.LABEL' | translate}}" autocomplete="on"
        [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.organitzationNameTarget" name="name"
        formControlName="organitzationNameTarget" placeholder="{{'REGISTERCOMPONENT.COMPANY_NAME.LABEL' | translate}}"
        autocomplete="off" [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.nifTarget" name="name" formControlName="nifTarget"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_NIF.LABEL' | translate}}" autocomplete="off" [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.referenceNameTarget" name="name"
        formControlName="referenceNameTarget"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_REFERENCE_NAME.LABEL' | translate}}" autocomplete="off"
        [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.emailTarget" formControlName="emailTarget"
        placeholder="{{'REGISTERCOMPONENT.EMAIL.LABEL' | translate}}" name="email" autocomplete="on"
        [readonly]="readonly">
    </input-component>
</form>

<form class="form" [formGroup]="driverFormGroup" *ngIf="linkType == 'driver'">
    <!-- <input-component class="col-x" [(ngModel)]="link.partnerKey" name="name" formControlName="partnerKey"
        placeholder="{{'REGISTERCOMPONENT.TENANT_LINK_KEY.LABEL' | translate}}" autocomplete="on" [readonly]="readonly">
    </input-component>
    <input-component class="col-x" [(ngModel)]="link.tradeMarkTarget" name="name" formControlName="tradeMarkTarget"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_TRADEMARK.LABEL' | translate}}" autocomplete="on" [readonly]="readonly">
    </input-component> -->
    <input-component class="col-x" [(ngModel)]="link.referenceNameTarget" name="name"
        formControlName="referenceNameTarget"
        placeholder="{{'REGISTERCOMPONENT.DRIVER_REFERENCE_NAME.LABEL' | translate}}" autocomplete="on"
        [readonly]="readonly">
    </input-component>
    <!-- <input-component class="col-x" [(ngModel)]="link.emailTarget" formControlName="emailTarget"
        placeholder="{{'REGISTERCOMPONENT.EMAIL.LABEL' | translate}}" name="email" autocomplete="on" [readonly]="readonly">
    </input-component> -->
    <div *ngIf="!useIonic" class="col-x">
        <enum-selector class="col-prefix-phone" [(ngModel)]="prefixModel" [readonly]="readonly"
            [enumvalues]="enumPrefixes" placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}"
            name="phonePrefix" [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="prefixTarget">
        </enum-selector>
        <input-component class="phone" [(ngModel)]="link.phoneTarget" name="phone" formControlName="phoneTarget"
            placeholder="{{'REGISTERCOMPONENT.USER_PHONE.LABEL' | translate}}" autocomplete="on" [readonly]="readonly">
        </input-component>
    </div>
    <div *ngIf="useIonic" class="col-x">
        <enum-selector [ngClass]="{'phone-prefix-ios' : isIos}" [(ngModel)]="prefixModel" [readonly]="readonly"
            [enumvalues]="enumPrefixes" placeholder="{{'REGISTERCOMPONENT.USER_PHONE_PREFIX.LABEL' | translate}}"
            name="phonePrefix" [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="prefixTarget">
        </enum-selector>
        <input-component class="phone" [(ngModel)]="link.phoneTarget" name="phone" formControlName="phoneTarget"
            placeholder="{{'REGISTERCOMPONENT.USER_PHONE.LABEL' | translate}}" autocomplete="on" [readonly]="readonly">
        </input-component>
    </div>
</form>