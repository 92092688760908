import { CustomTableColumnData } from "./CustomTableColumnData";
import { CustomTableFieldData } from "./CustomTableFieldData";

export class CustomTableRowData {
    public row: number;
    public fields: CustomTableFieldData[];

    static fix(r: CustomTableRowData, header: CustomTableColumnData[]): CustomTableRowData {
        if (r != null && header != null)
            r.fields.forEach(x => {
                header.forEach(h => {
                    if (h.column == x.column)
                        x.order = h.order;
                })
            });

        return r;
    }
}
