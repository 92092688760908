<ng-container *ngIf="route && route.phases">
    <div class="title">
        {{'SHARED.PHASE.CHAGEDATES.COMPONENT.TITLE' | translate}}
    </div>
    <div class="block-help">
        {{'SHARED.PHASE.CHAGEDATES.COMPONENT.HELP' | translate}}
    </div>
    <div class="item" *ngFor="let phase of route.phases | sortBy:'executionOrder'">
        <shared-phase-changedates-item [phase]="phase" (refreshRoute)="loadRoute()" class="showMarker">
        </shared-phase-changedates-item>
    </div>
</ng-container>