import { Pipe } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { TruckingRouteDto } from '../dtos/trucking/TruckingRouteDto';

@Pipe({
	name: "orderbytruckingroutecarddto"
})
export class OrderByTruckingRouteDto {
	transform(array: Array<TruckingRouteDto>, asc: boolean): Array<TruckingRouteDto> {
		if (array == null)
			return null;

		array.sort((a: TruckingRouteDto, b: TruckingRouteDto) => {
			if (asc)
				return OrderByTruckingRouteDto.compareAny(a, b);

			return OrderByTruckingRouteDto.compareAny(b, a);
		});
		return array;
	}

	public static compareAny(a: TruckingRouteDto, b: TruckingRouteDto): number {

		//Primer han d'anar els que tenen la marca de callInToDock o callOutToDock
		if (a.incompatibleSlot && !b.incompatibleSlot)
			return OrderByTruckingRouteDto.xivato(-1, a, b);
		else if (!a.incompatibleSlot && b.incompatibleSlot)
			return OrderByTruckingRouteDto.xivato(1, a, b);
		else
			return this.validateDateHour(a, b);
	}

	public static validateDateHour(a: TruckingRouteDto, b: TruckingRouteDto): number {

		if (a.pendingMessagesRead != null && a.pendingMessagesRead > 0 && (b.pendingMessagesRead == null || b.pendingMessagesRead == 0))
			return OrderByTruckingRouteDto.xivato(-1, a, b);
		if (b.pendingMessagesRead != null && b.pendingMessagesRead > 0 && (a.pendingMessagesRead == null || a.pendingMessagesRead == 0))
			return OrderByTruckingRouteDto.xivato(1, a, b);

		let av = a.slotHDateHour;
		if (HDateHour.isNullOrNullValue(av))
			av = a.maxDateHourMinToLoadPackage;
		if (HDateHour.isNullOrNullValue(av))
			av = a.dateHourFrom;

		let bv = b.slotHDateHour;
		if (HDateHour.isNullOrNullValue(bv))
			bv = b.maxDateHourMinToLoadPackage;
		if (HDateHour.isNullOrNullValue(bv))
			bv = b.dateHourFrom;
		
		if (HDateHour.isNullOrNullValue(av) && !HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingRouteDto.xivato(1, a, b);
		else if (!HDateHour.isNullOrNullValue(av) && HDateHour.isNullOrNullValue(bv))
			return OrderByTruckingRouteDto.xivato(-1, a, b);
		else if (HDateHour.isGreaterThan(av, bv))
			return OrderByTruckingRouteDto.xivato(1, a, b);
		else if (HDateHour.isMinorThan(av, bv))
			return OrderByTruckingRouteDto.xivato(-1, a, b);
		else
			return a.routeKey > b.routeKey ? 1 : -1;
	}

	public static xivato(valor, a: TruckingRouteDto, b: TruckingRouteDto) {
		return valor;
	}
}
