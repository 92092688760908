<div class="container">
    <div *ngFor="let item of getProducts() | sortBy:'showInOrder'; let index=index" class="item producte"
        [ngClass]="{'modeDesactivar': itemModeDesactivar==item, 'active':item.isActive,'noactive':!item.isActive, 'available':item.isAvailable, 'essential':item.isEssential, 'readonly': item.isEssential || !item.isAvailable}">
        <div class="primera-linia">
            <div class="titol">{{ 'PRODUCTS.' + item.hardmanProduct.toUpperCase() + '.TITLE' | translate }}</div>

            <boolean-component [(ngModel)]="item.isActive"
                [readonly]="(item.isEssential || !item.isAvailable) && !readonly" [name]="item.hardmanProduct"
                (valueChanged)="productChange(item)">
            </boolean-component>
        </div>
        <div class="explicacio">{{ 'PRODUCTS.' + item.hardmanProduct.toUpperCase() + '.DESCRIPTION' | translate }}</div>
    </div>
</div>