import { EventEmitter, Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InternalVersionController } from '@shared/src/controllers/internal/version/internal.version.controller';
import { VersionService } from '@shared/src/services/version/version.service';
import { HLong } from '@shared/src/datatypes/HLong';
import { Deploy } from 'cordova-plugin-ionic';
import { Market } from '@ionic-native/market/ngx';
import { environment } from '@shared/src/public-api';
import { SplashScreen } from '@capacitor/splash-screen';
import { SplashPage } from 'src/app/splash/splash.page';

interface AppUpdate {
  current: string;
  maintenance: boolean;
  iosControl: boolean;
  androidControl: boolean;
  msg?: {
    title: string;
    msg: string;
    btn: {
      action: string;
      text: string;
    }
  };
  majorMsg?: {
    title: string;
    msg: string;
    btn: {
      action: string;
      text: string;
    }
  };
  minorMsg?: {
    title: string;
    msg: string;
    btn: {
      action: string;
      text: string;
    }
  };
}

@Injectable({
  providedIn: 'root'
})
export class UpdatesService {

  updateExample = "./update.currently.json";
  maintenanceExample = "./inappbrowser.json";

  constructor(private internalVersionController: InternalVersionController, private versionService: VersionService, private alertCtrl: AlertController, private iab: InAppBrowser, private market: Market, protected modalController: ModalController) { }

  public onUpdateProgressChanged: EventEmitter<number> = new EventEmitter<number>();
  public alert;
  async checkForUpdate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      console.log("[update] updateChannel: " + environment.APP_LIVE_UPDATE_CHANNEL);
      if (!environment.APP_LIVE_UPDATE_CHANNEL)
        resolve(true);

      //this.cancelTimerCheckForUpdate();
      this.internalVersionController.getAppUpdate().subscribe((info: AppUpdate) => {

        console.log("checkForUpdate:" + Capacitor.getPlatform());

        if (info.maintenance) {
          console.log("In Maintenance");
          //Si l'app no ha d'estar operativa per algun motiu de servidor
          //this.startTimerCheckForUpdate();
          this.presetAlert(info.msg.title, info.msg.msg, info.msg.btn).then(e => {
            resolve(true);
          })
        } else {
          //this.cancelTimerCheckForUpdate();
          if (Capacitor.getPlatform() !== 'web' && Capacitor.getPlatform() !== 'ios' && Capacitor.getPlatform() !== 'android') {
            console.log("Not ios and android");
            resolve(true);
            return;
          }
          if (!info.androidControl && Capacitor.getPlatform() === 'android') {
            console.log("androidControl is false");
            resolve(true);
            return;
          }
          if (!info.iosControl && Capacitor.getPlatform() === 'ios') {
            console.log("iosControl is false");
            resolve(true);
            return;
          }
          const versionNumber = this.versionService.getVersion();
          const splittedVersion = versionNumber.split('.');
          const serverVersion = info.current.split('.');

          if (HLong.isNullOrNullLong(parseInt(splittedVersion[0])) || this.getVersion(serverVersion[0]) > this.getVersion(splittedVersion[0])) {
            //Hi han nous plugins i s'ha de forçar l'actualització desde store/ios
            this.presetAlert(info.majorMsg.title, info.majorMsg.msg, info.majorMsg.btn).then(result => {
              resolve(true);
            });
          } else if (this.getVersionComplete(serverVersion[0], serverVersion[1]) > this.getVersionComplete(splittedVersion[0], splittedVersion[1])) {
            //Hi han canvis d'endpoints/opcions que afecten a mobile i s'està actualitzant
            this.presetAlert(info.minorMsg.title, info.minorMsg.msg, info.minorMsg.btn).then(result => {
              resolve(true);
            });
          } else {
            this.performManualUpdate(true).then(result => {
              resolve(true);
            })
          }
        }
      }, error => {
        console.log("[AppComponent] getUpdateError", JSON.stringify(error));
        resolve(true);
      });
    })
  }

  private getVersion(a: any): number {
    return parseInt(a);
  }
  private getVersionComplete(major: any, minor: any): number {
    return parseInt(major) * 1000 + parseInt(minor);
  }

  async openAppstoreEntry() {
    console.log("OPEN ME");
    if (Capacitor.getPlatform() === 'android') {
      this.market.open('app.hardman.cockpit');
    } else {
      this.iab.create('itms-apps://itunes.apple.com/app/id1549664799', '_blank');
    }
  }

  presetAlert(header, message, button, allowClose = false): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const buttons: any = [];

      if (button) {
        buttons.push({
          text: button.text,
          handler: () => {
            if (button.action == "store")
              this.openAppstoreEntry();
            else if (button.action == "liveupdate") {
              this.performManualUpdate(false).then(e => {
                resolve(e);
              })
            } else {
              this.checkForUpdate().then(e => {
                resolve(e);
              })
            }
          }
        });
      }
      if (allowClose) {
        buttons.push({
          text: 'Close',
          role: 'cancel',
          handler: () => {
            this.checkForUpdate().then(e => {
              resolve(e);
            });
          }
        });
      }
      this.alertCtrl.create({
        header,
        message,
        buttons,
        backdropDismiss: allowClose
      }).then(e => {
        this.alert = e;
        this.alert.present();
        resolve(true);
      })
    })
  }

  async performManualUpdate(alreadyCheckForUpdate: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      Deploy.configure({ channel: environment.APP_LIVE_UPDATE_CHANNEL, appId: environment.APP_ID }).then(config => {
        console.log("[AppComponent][performManualUpdate] starting");
        Deploy.checkForUpdate().then(update => {
          if (update.available) {
            console.log("[AppComponent][performManualUpdate] update available");
            var modal: HTMLIonModalElement = null;
            this.modalController.create({ component: SplashPage }).then(m => {
              m.present();
              modal = m;
              console.log("[AppComponent][performManualUpdate] fake modal");
              SplashScreen.hide();
            });

            Deploy.downloadUpdate((progress) => {
              this.onUpdateProgressChanged.next(progress);
            }).then(download => {
              if (download) {
                console.log("[AppComponent][performManualUpdate] downloaded", JSON.stringify(download));
                Deploy.extractUpdate().then(extract => {
                  if (extract) {

                    modal.dismiss();

                    console.log("[AppComponent][performManualUpdate] extracted", JSON.stringify(download));
                    Deploy.reloadApp().then(reload => {
                      if (reload) {
                        console.log("[AppComponent][performManualUpdate] reloaded", JSON.stringify(download));
                        resolve(true);
                        if (!alreadyCheckForUpdate)
                          this.checkForUpdate();
                      }
                    })
                  }
                })
              }
            })
          } else {
            SplashScreen.hide();
            console.log("[AppComponent][performManualUpdate] already up to date", JSON.stringify(update));
            resolve(true);
            if (!alreadyCheckForUpdate)
              this.checkForUpdate();
          }
        })
      })
    });
  }

  /*
    private timerCheckForUpdateSubscription: any;
    startTimerCheckForUpdate() {
      this.timerCheckForUpdateSubscription = timer(120000).subscribe(val => {
        this.checkForUpdate();
      });
    }
    cancelTimerCheckForUpdate() {
      if(this.alert != null){
        this.alert.cancel();
      }
      if (this.timerCheckForUpdateSubscription != null) {
        this.timerCheckForUpdateSubscription.unsubscribe();
        this.timerCheckForUpdateSubscription = null;
      }
    }*/
}
