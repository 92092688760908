import { Injectable } from '@angular/core';

@Injectable()
export class MultiSelectDto {
  public idList: Array<number>;

  public static build(idList: Array<number>): MultiSelectDto {
    let result = new MultiSelectDto();
    result.idList = idList;
    return result;
  }
}