<div class="container component">

    <div class="linia bar baroptions">
        <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>

    <ng-container *ngIf="actualOptionId==='GENERAL'">
        GENERAL
    </ng-container>
    <ng-container *ngIf="actualOptionId==='CHAT'">
        GENERAL
        <shared-chat class="block-option" [forcedOwnerParticipant]="forcedOwnerParticipant" [notes]="true"
            (clickParticipant)="clickParticipant($event)">
        </shared-chat>
    </ng-container>
    <ng-container *ngIf="actualOptionId==='PACKAGE'">
        PACKAGE
    </ng-container>
    <ng-container *ngIf="actualOptionId==='DELIVERY'">
        DELIVERY
    </ng-container>
    <ng-container *ngIf="actualOptionId==='DOCUMENT'">
        DOCUMENT
    </ng-container>

</div>