import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AddressCalendarDayDto } from '@shared/src/dtos/address/calendar/AddressCalendarDayDto';
import { AddressCalendarDto } from '@shared/src/dtos/address/calendar/AddressCalendarDto';
import { CalendarMonths } from '../calendar.months';

@Component({
  selector: 'address-calendarmonths',
  templateUrl: './../calendar.months.html',
  styleUrls: ["./../calendar.scss"]
})
export class AddressCalendarMonths extends CalendarMonths<AddressCalendarDayDto, AddressCalendarDto> implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

}


