import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HHourIntervals } from '@shared/src/datatypes/HHourIntervals';


/**
 * Validador de temps, permet valors amb format ##:##;##:##,##:##;##:##....
 */

@Directive({
  selector: '[validateHourIntervals][ngModel],[validateHourIntervals][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => HourIntervalsValidator), multi: true }
  ]
})
export class HourIntervalsValidator implements Validator {

  public constructor() { }

  public static validate(control: AbstractControl): ValidationErrors | null {
    return HourIntervalsValidator.validateControl(control, false);
  }
  public static validateWithNullValue(control: AbstractControl): ValidationErrors | null {
    return HourIntervalsValidator.validateControl(control, true);
  }

  public static validateControl(control: AbstractControl, withnullinterval: boolean): ValidationErrors | null {
    if (!control)
      return HourIntervalsValidator.getInvalidResult();
    let value = control.value;
    if (!HourIntervalsValidator.isValid(value))
      return HourIntervalsValidator.getInvalidResult();

    if (withnullinterval && HHourIntervals.isNullOrNullValue(value))
      return HourIntervalsValidator.getInvalidResult();
    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    if (value instanceof HHourIntervals)
      return true;

    if (value._Intervals != undefined)
      return true;

    if (typeof value === 'string' || value instanceof String)
      if (HHourIntervals.isValidFormat(value))
        return true;
    return false;
  }


  public validate(c: AbstractControl): ValidationErrors | null {
    return HourIntervalsValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateHourIntervals: false
    };
  }
}