
export class HTriState {

    state: number;

    public static True: number = 1;
    public static False: number = 0;
    public static Indeterminate: number = -1;

    /***
     * Indica si l'objecte és null o conté un valor null
     */
    public static isNullOrEmpty(value: HTriState): boolean {
        if (value == null || value.state == null || value.state == undefined)
            return true;
        return false;
    }

    public static build(statevalue: number): HTriState {
        if (statevalue != HTriState.True && statevalue != HTriState.False && statevalue != HTriState.Indeterminate)
            statevalue = HTriState.Indeterminate;

        let hstate = new HTriState();
        hstate.state = statevalue;
        return hstate;
    }
    public static fromString(statevalue: string): HTriState {
        return HTriState.build(parseInt(statevalue));
    }

    public static clone(value: HTriState): HTriState {
        if (value == null)
            return null;
        let result = new HTriState();
        result.state = value.state;
        return result;
    }

    public isTrue(): boolean {
        return this.state == HTriState.True;
    }

    public isFalse(): boolean {
        return this.state == HTriState.False;
    }

    public isIndeterminate(): boolean {
        return this.state == HTriState.Indeterminate;
    }

    public static validState(value: number) {
        if (value == HTriState.True || value == HTriState.False || value == HTriState.Indeterminate)
            return true;
        return false;
    }

    public static toString(value: HTriState): string {
        if (!value)
            return null;

        return String(value.state);
    }
}