import { Component, Input, Output, EventEmitter } from '@angular/core';
import { QuestionItemMaintenanceDto } from '@shared/src/controllers/maintenance/form/question/QuestionItemMaintenanceDto';
import { FormQuestionAnswerShipmentItemDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentItemDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';


@Component({
  selector: 'shared-form-questions-answers-shipment',
  templateUrl: './shared.form.questions.answers.shipment.html',
  styleUrls: ['./shared.form.questions.answers.shipment.scss']
})
export class SharedFormQuestionsAnswersShipment {

  constructor() {

  }

  @Input()
  public iReadonly: boolean;

  @Input() 
  public showDefaultCamera: boolean = false;

  private _shipment: ShipmentCardDto;
  @Input() public set shipment(value: ShipmentCardDto) {
    if (this._shipment === value)
      return;

    this._shipment = value;
  }
  public get shipment(): ShipmentCardDto {
    return this._shipment;
  }

  private _questionsAnswers: FormQuestionAnswerShipmentItemDto[];
  @Input() public set questionsAnswers(value: FormQuestionAnswerShipmentItemDto[]) {
    if (this._questionsAnswers === value)
      return;

    this._questionsAnswers = value;

  }
  public get questionsAnswers(): FormQuestionAnswerShipmentItemDto[] {
    return this._questionsAnswers;
  }

  @Output() valueChanged: EventEmitter<FormQuestionAnswerShipmentItemDto[]> = new EventEmitter();
  onValueChanged(questionAnswer: FormQuestionAnswerShipmentItemDto, index: number){

    if(this.questionsAnswers != null){
      this.questionsAnswers[index] = questionAnswer;
      this.valueChanged.next(this.questionsAnswers);
    }
      
  }

  getClassListItem(item: FormQuestionAnswerShipmentItemDto){
    if(item == null || item.formQuestion == null || item.formQuestion.question == null)
      return "";

    if(QuestionItemMaintenanceDto.isIntegrated(item.formQuestion.question.questionType)){
      return "integrated";
    }
    return "";
  }
}
