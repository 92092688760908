import { PanelTypes } from "@shared/src/enums/PanelTypes";

export class SetElementColumnDto {

    public elementId: number;
	public panelColumnlId: number;

	public static build(elementId: number, panelColumnlId: number): SetElementColumnDto{
		let result: SetElementColumnDto = new SetElementColumnDto();
		result.elementId = elementId;
		result.panelColumnlId = panelColumnlId;
		return result;
	}
}
