import { HHour } from './HHour';
import { HTime } from './HTime';

export class HHourInterval {
    private starts: HHour;
    private ends: HHour;

    constructor() {
    }

    /***
     * Et diu si una instància és un HHourIntervals
     */
    public static isHHourInterval(value: HHourInterval): boolean {
        if (value == null)
            return false;
        if (value.starts != undefined && value.ends != undefined)
            return true;
        return false;
    }
    public static build(starts: HHour, ends: HHour): HHourInterval {
        let result = new HHourInterval();
        result.setStart(starts);
        result.setEnd(ends);
        return result;
    }

    public static buildByDto(dto: HHourInterval): HHourInterval {
        let result = new HHourInterval();
        if (!dto || !dto.starts || !dto.ends)
            return result;
        let starts = new HHour(dto.starts.h, dto.starts.m);
        result.setStart(starts);
        let ends = new HHour(dto.ends.h, dto.ends.m);
        result.setEnd(ends);
        return result;
    }

    public static buildstr(value: string): HHourInterval {
        let result = new HHourInterval();

        if (!this.isValidFormat(value))
            return result;

        let interval: string[] = value.split("-");
        let start: HHour = HHour.empty();
        if (HHour.isValidFormat(interval[0]))
            start = HHour.build(interval[0]);

        let end: HHour = HHour.empty();
        if (HHour.isValidFormat(interval[0]))
            end = HHour.build(interval[1]);

        if (HHour.isMinorThan(end, start))
            return result;

        result.setStart(start);
        result.setEnd(end);
        return result;
    }

    public static clone(value: HHourInterval): HHourInterval {
        if (value == null)
            return null;
        let result = new HHourInterval();
        result.starts = HHour.clone(value.starts);
        result.ends = HHour.clone(value.ends);
        return result;
    }
    public static getStarts(value: HHourInterval): HHour {
        return value.starts;
    }
    public static getEnds(value: HHourInterval): HHour {
        return value.ends;
    }
    public getStart(): HHour {
        return this.starts;
    }
    public setStart(value: HHour) {
        this.starts = value;
    }
    public static setStart(interval: HHourInterval, starts: HHour) {
        interval.starts = starts;
    }
    public getEnd(): HHour {
        return this.ends;
    }
    public setEnd(value: HHour) {
        this.ends = value;
    }
    public static setEnd(interval: HHourInterval, ends: HHour) {
        interval.ends = ends;
    }

    public static compare(a: HHourInterval, b: HHourInterval) {
        if (HHour.isGreaterThan(a.starts, b.starts))
            return 1;
        if (HHour.isMinorThan(a.starts, b.starts))
            return -1;
        return 0;
    }

    /**
    * Retorna true qiuan el timeinterval representa un espai de temps 0
    */
    public static isNull(value: HHourInterval): boolean {
        if (!value)
            return true;
        if (!value.starts || HHour.isNull(value.starts))
            if (!value.ends || HHour.isNull(value.ends))
                return true;
        return false;
    }
    static nullInterval(): HHourInterval {
        return HHourInterval.build(HHour.NULLVALUE(), HHour.NULLVALUE());
    }

    /**
    * Retorna true si el timeinterval es null o representa un intervals null
    * @param value 
    */
    public static isNullOrNullInterval(value: HHourInterval): boolean {
        return HHourInterval.isNull(value);
    }

    /**
 * Reprsentació a pantalla d'un time interval com a texte
 * @param value 
 */
    public static toScreenString(value: HHourInterval): string {
        if (HHourInterval.isNullOrNullInterval(value))
            return "--:-- ??:??";
        let result: string = "";

        result += HHour.toScreenString(value.starts);
        if (!HHour.isEqualsThan(value.starts, value.ends)) {
            result += "-";
            result += HHour.toScreenString(value.ends);
        }
        return result;
    }

    /**
 * Retorna true si el timeintervals es null o representa un intervals null
 * @param value 
 */
    public static isNullOrNullValue(value: HHourInterval): boolean {

        return HHourInterval.isNull(value);
    }

    /**
 * Reprsentació a directiva d'un time intervals a texte
 * @param value 
 */
    public static toHourIntervalsString(value: HHourInterval): string {
        if (this.isNullOrNullValue(value))
            return "00:00-00:00";

        if (!HHourInterval.isNull(value)) { }
        return HHourInterval.toHourIntervalString(value);
    }

    public static empty(): HHourInterval {
        let hintervals = HHourInterval.build(new HHour(0, 0), new HHour(0, 0));
        return hintervals;
    }
    /**
 * Retorna true quan els dos timeinterval coincideixen en el temps. de 9:00-10:00 i de 10:00 a 11:00 tot i que coincideixen a les 10, entenem que no ho fan ja que un surt a les 10 i l'altre entra a les 10 que només és un punt i per tant no hi ha coincidència.
 * @param valuea 
 * @param valueb 
 */
    public static intersectsWith(valuea: HHourInterval, valueb: HHourInterval): boolean {
        if (HHourInterval.isNullOrNullInterval(valuea) && HHourInterval.isNullOrNullInterval(valuea))
            return false;

        if (HHour.isEqualsThan(valuea.starts, valueb.starts))
            if (HHour.isEqualsThan(valuea.ends, valueb.ends))
                return true;
        if (HHour.isMinorThan(valueb.starts, valuea.ends) && HHour.isGreaterThan(valueb.ends, valuea.starts))
            return true;
        return false;
    }

    /**
     * Retorna true si els intervals son iguals (hora inici i hora final i no son nuls)
     * @param valuea 
     * @param valueb 
     */
    public static isEqualsThan(valuea: HHourInterval, valueb: HHourInterval): boolean {
        valuea = HHourInterval.buildByDto(valuea);
        valueb = HHourInterval.buildByDto(valueb);

        if (HHourInterval.isNullOrNullInterval(valuea) && HHourInterval.isNullOrNullInterval(valueb))
            return true;

        if (HHourInterval.isNullOrNullInterval(valuea) || HHourInterval.isNullOrNullInterval(valueb))
            return false;

        if (HHour.isEqualsThan(valuea.starts, valueb.starts))
            if (HHour.isEqualsThan(valuea.ends, valueb.ends))
                return true;

        return false;
    }

    public static isValidFormat(value: String): boolean {
        if (!value)
            return false;

        let interval: string[] = value.split("-");
        if (!interval || interval.length != 2)
            return false;

        if (!HHour.isValidFormat(interval[0]))
            return false;

        if (!HHour.isValidFormat(interval[1]))
            return false;

        let start = HHour.build(interval[0]);
        let end = HHour.build(interval[1]);

        if (HHour.isMinorThan(end, start))
            return false;

        return true;
    }

    /**
     * Reprsentació a directiva d'un time interval a texte
     * @param value 
     */
    public static toHourIntervalString(value: HHourInterval): string {
        if (this.isNullOrNullInterval(value))
            return "00:00-00:00";

        let result: string = "";

        let sstart = HHour.getHourString(value.starts);
        let send = HHour.getHourString(value.ends);

        result = sstart + "-" + send;

        return result;
    }

    public toString(): string {
        return HHourInterval.toHourIntervalString(this);
    }

    public static totalOf(value: HHourInterval): HTime {

        let h = value.ends.h - value.starts.h;
        let m = value.ends.m - value.starts.m;

        //Ara tinc la distància en hores i minuts. La distància en hores * 60 ja tinc els minuts entenem que ends > starts
        //Aleshores si els minuts de final > que els minuts ends sumem però si aquests son negatius aleshores resto una hora i els minuts en realitat s'han de descontar de 60
        if (m < 0) {
            h = h - 1;
            m = 60 + m;
        }
        let result = new HTime("", h, m);

        return result;
    }

    public static totalOfInDecimal(value: HHourInterval): number {

        let h = value.ends.h - value.starts.h;
        let m = value.ends.m - value.starts.m;

        //Ara tinc la distància en hores i minuts. La distància en hores * 60 ja tinc els minuts entenem que ends > starts
        //Aleshores si els minuts de final > que els minuts ends sumem però si aquests son negatius aleshores resto una hora i els minuts en realitat s'han de descontar de 60
        if (m < 0) {
            h = h - 1;
            m = 60 + m;
        }
        let result = m + (h * 60);

        return result;
    }
}