<shared-generalstatus-container class="card" [ngClass]="getGeneralClass()" *ngIf="phaseslot"
  [generalStatus]="phaseslot.generalStatus">
  <div (drop)="drop($event, phaseslot)" (dragstart)="onDragStart($event, phaseslot)"
    (dragenter)="onDragEnter($event, phaseslot)" (dragleave)="onDragLeave($event, phaseslot)" class="body"
    [ngClass]="{ 'empty' : phaseslot == null, 'draggable' : isPhaseSlotDraggable, 'isSlotBlocked':phaseslot.isSlotBlocked, 'incompatibleSlot':phaseslot.incompatibleSlot}"
    [draggable]="true" (click)="slotSelected()" (dblclick)="onDoubleClick()">
    <div class="detail" [ngClass]="internalTags">
      <ng-container *ngIf="!loadConsoleSlotPhase">
        <div class="title ellipsis" [title]="phaseslot.title" (click)="copy(phaseslot.title)">
          {{phaseslot.title}}<shared-copyclipboard [value]="phaseslot.title"></shared-copyclipboard></div>
        <div class="subtitle" (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</div>
        <shared-tags-strip-component class="tags" [tags]="phaseslot.stops"></shared-tags-strip-component>
        <div class="description line1">{{phaseslot.line1}}</div>
        <div class="description disponibility line2">{{phaseslot.line2}}</div>
        <div class="description line3" (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</div>
        <shared-tags-strip-component *ngIf="haveTags(phaseslot.routeManualTags)" class="tags nomargin"
          [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
        <shared-tags-strip-component class="tags nomargin" [tags]="phaseslot.tags"
          [applyColor]="false"></shared-tags-strip-component>
        <shared-tags-strip-component *ngIf="haveTags(phaseslot.phaseManualTags)" class="tags nomargin"
          [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
        <shared-tags-strip-component class="tags nomargin" [tags]="phaseslot.phaseUserTags"
          [applyColor]="false"></shared-tags-strip-component>
      </ng-container>
      <ng-container *ngIf="loadConsoleSlotPhase">
        <div class="line title ellipsis">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='title'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='title'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='title'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <span *ngIf="loadConsoleSlotPhase.routeDatesPosition=='title'">{{phaseslot.line1}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='title'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='title'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='title' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='title'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='title' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='title'" class="tags nomargin"
            [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line subtitle">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='subtitle'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='subtitle'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='subtitle'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <span *ngIf="loadConsoleSlotPhase.routeDatesPosition=='subtitle'">{{phaseslot.line1}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='subtitle'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='subtitle'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='subtitle' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='subtitle'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='subtitle' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='subtitle'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line first-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='firstline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='firstline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='firstline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <span *ngIf="loadConsoleSlotPhase.routeDatesPosition=='firstline'" class="description line1">
            {{phaseslot.line1}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='firstline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='firstline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='firstline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='firstline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='firstline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='firstline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line second-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='secondline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='secondline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='secondline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <span *ngIf="loadConsoleSlotPhase.routeDatesPosition=='secondline'">
            {{phaseslot.line1}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='secondline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='secondline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='secondline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='secondline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='secondline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='secondline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line third-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='thirdline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='thirdline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='thirdline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <div *ngIf="loadConsoleSlotPhase.routeDatesPosition=='thirdline'" class="description line1">
            {{phaseslot.line1}}
          </div>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='thirdline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='thirdline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='thirdline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='thirdline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='thirdline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='thirdline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line fourth-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='fourthline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='fourthline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='fourthline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <div *ngIf="loadConsoleSlotPhase.routeDatesPosition=='fourthline'">
            {{phaseslot.line1}}
          </div>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='fourthline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='fourthline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='fourthline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='fourthline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='fourthline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='fourthline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line fifth-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='fifthline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='fifthline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='fifthline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <div *ngIf="loadConsoleSlotPhase.routeDatesPosition=='fifthline'">
            {{phaseslot.line1}}
          </div>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='fifthline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='fifthline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='fifthline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='fifthline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='fifthline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='fifthline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line sixth-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='sixthline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='sixthline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='sixthline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <div *ngIf="loadConsoleSlotPhase.routeDatesPosition=='sixthline'">
            {{phaseslot.line1}}
          </div>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='sixthline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='sixthline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='sixthline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='sixthline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='sixthline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='sixthline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
        <div class="line seventh-line">
          <span *ngIf="loadConsoleSlotPhase.routeTitlePosition=='seventhline'"
            (click)="copy(phaseslot.title)">{{phaseslot.title}}</span>
          <span *ngIf="loadConsoleSlotPhase.routeSubTitlePosition=='seventhline'"
            (click)="copy(phaseslot.subtitle)">{{phaseslot.subtitle}}</span>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.routeStopsPosition=='seventhline'" class="tags"
            [tags]="phaseslot.stops"></shared-tags-strip-component>
          <div *ngIf="loadConsoleSlotPhase.routeDatesPosition=='seventhline'">
            {{phaseslot.line1}}
          </div>
          <span *ngIf="loadConsoleSlotPhase.routeDisponibilityPosition=='seventhline'" class="disponibility">
            {{phaseslot.line2}}
          </span>
          <span *ngIf="loadConsoleSlotPhase.routePlatesPosition=='seventhline'"
            (click)="copy(phaseslot.line3)">{{phaseslot.line3}}</span>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.routeManualTagPosition=='seventhline' && haveTags(phaseslot.routeManualTags)"
            class="tags nomargin" [tags]="phaseslot.routeManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='seventhline'"
            class="tags nomargin" [tags]="phaseslot.tags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component
            *ngIf="loadConsoleSlotPhase.phaseManualTagPosition=='seventhline' && haveTags(phaseslot.phaseManualTags)"
            class="tags nomargin" [tags]="phaseslot.phaseManualTags" [applyColor]="false"></shared-tags-strip-component>
          <shared-tags-strip-component *ngIf="loadConsoleSlotPhase.phaseUserTagPosition=='seventhline'"
            class="tags nomargin" [tags]="phaseslot.phaseUserTags" [applyColor]="false"></shared-tags-strip-component>
        </div>
      </ng-container>
      <div class="fila-icones" [ngClass]="{'espai-baix': phaseslot.slotId > 0}">
        <shared-route-strip-component class="bed-bigger" [phaseslot]="phaseslot"
          [loadConsoleSlotPhase]="loadConsoleSlotPhase"></shared-route-strip-component>
      </div>
    </div>
    <div class="peu only-showBlockedBookSlotSystem" *ngIf="phaseslot.isBlockedBookSlotSystem">
      {{'ACCESSCONTROLPLATEITEM.SHOWBLOCKEDBOOKSLOTSYSTEM.CAPTION' |
      translate}}</div>
    <div class="peu only-showBlockedEnterSystem" *ngIf="phaseslot.isBlockedEnterSystem">
      {{'ACCESSCONTROLPLATEITEM.SHOWBLOCKEDENTERSYSTEM.CAPTION' |
      translate}}</div>
    <div class="peu only-showBlockedExitSystem" *ngIf="phaseslot.isBlockedExitSystem">
      {{'ACCESSCONTROLPLATEITEM.SHOWBLOCKEDEXITSYSTEM.CAPTION' | translate}}
    </div>
  </div>

  <div class="reserve-text-band" *ngIf="phaseslot.slotId > 0">
    {{ "PHASESLOT.RESERVEFOR.CAPTION" | translate:{ date: phaseslot.onDateSlot | date} }}</div>

  <button class="remove" (click)="removePhaseDock()">
    <span class="text-actions">{{ "ADDRESSSLOTS.ACTION.REMOVE" | translate }}</span>
  </button>

</shared-generalstatus-container>