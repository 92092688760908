<div *ngIf="routeCMR" class="container component">
    <div class="list">
        <shared-route-part-cmr-list [routeId]="routeId" [routeCMR]="routeCMR" [selectedRoutePartCMR]="selectedRoutePartCMR" class="component" (selectItem)="selectRoutePartCMR($event)">
        </shared-route-part-cmr-list>
    </div>

    <div class="wide-column" *ngIf="selectedRoutePartCMR">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.CMR.TITLE' | translate}}
        </div>
        <shared-route-part-cmr-maintenance [routePartCMR]="selectedRoutePartCMR" [readonly]="readonly || iReadonly"
            (routePartCMRUpdated)="onRoutePartCMRUpdated($event)"
            (refreshRoutePartCMR)="onRefreshRoutePartCMR($event)">
        </shared-route-part-cmr-maintenance>
    </div>

</div>