import { EntitySelectorItemDto } from "./EntitySelectorItemDto";
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";

export class EntitySelectorDto {

    public entitySelector: EntitysSelectors;
    public items: EntitySelectorItemDto[];
    public page: number = 0;
    public itemsPerSelector: number = 25;
    public filterKeys: Array<String>;
    public searchText: string;
    public canDeleteItem: boolean;
    public urlGoToMaintenance: string;
    public urlBack: string;
    public haDeSumarAContador: boolean;

    loadData(entityselector: EntitysSelectors, searchtext: string, activeFilterKeys: Array<String>, page: number, haDeSumarAContador: boolean) {
        this.entitySelector = entityselector;
        this.searchText = searchtext;
        this.filterKeys = activeFilterKeys;
        this.page = page;
        this.urlGoToMaintenance = this.getUrlGoToMaintenance();
        this.urlBack = this.getUrlBack();
        this.haDeSumarAContador = haDeSumarAContador;
    }
    
    loadDataByDto(data: EntitySelectorDto) {
        this.entitySelector = data.entitySelector;
        this.items = data.items;
        this.page = data.page;
        this.itemsPerSelector = data.itemsPerSelector;
        this.filterKeys = data.filterKeys;
        this.searchText = data.searchText;
        this.canDeleteItem = data.canDeleteItem;
        this.urlGoToMaintenance = data.urlGoToMaintenance;
        this.urlBack = data.urlBack;
        this.haDeSumarAContador = data.haDeSumarAContador;
    }
    public getUrlGoToMaintenance(): string {
        return "";
    }

    public getUrlBack(): string {
        return "";
    }
}