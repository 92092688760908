import { Component, Input, EventEmitter, Output, OnInit, Inject } from '@angular/core';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { ChatMessageDto } from '@shared/src/dtos/chat/message/ChatMessageDto';
import { HDate } from '@shared/src/datatypes/HDate';
import { HHour } from '@shared/src/datatypes/HHour';
import { HColor } from '@shared/src/datatypes/HColor';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HString } from '@shared/src/datatypes/HString';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';

@Component({
  selector: 'shared-chatmessage',
  templateUrl: './shared.chat.message.html',
  styleUrls: ['../shared.chats.scss']
})
export class SharedChatMessage implements OnInit {

  public useIonic: boolean = false;

  constructor(private chatController: ChatController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Output() onActionDelete: EventEmitter<ChatMessageDto> = new EventEmitter();

  private _value: ChatMessageDto;
  @Input() set value(param: ChatMessageDto) {
    this._value = param;
    this.loadData();
    this.getIsLost();
  }
  get value() {
    return this._value;
  }

  private _showAnnotation: boolean;
  @Input() set showAnnotation(value: boolean) {
    if (this._showAnnotation === value)
      return;
    this._showAnnotation = value;
  }

  get showAnnotation() {
    return this._showAnnotation;
  }



  private _ownerUserId: number;
  @Input() set ownerUserId(param: number) {
    this._ownerUserId = param;
    this.loadData();
  }
  get ownerUserId() {
    return this._ownerUserId;
  }

  private _index: number;
  @Input() set index(value: number) {
    this._index = value;
  }

  @Output() clickParticipant: EventEmitter<ParticipantDto> = new EventEmitter();
  onClickParticipant(participant: ParticipantDto) {
    this.clickParticipant.next(participant);
  }

  public msgowneryou: boolean;
  public hourmsg: HHour;

  loadData() {
    if (this.value != null && this.ownerUserId != null) {
      if (this.ownerUserId == this.value.fromUserId)
        this.msgowneryou = true;
    }
    this.deleted = false;
  }

  isToday(pdate: HDate) {
    var date: HDate = HDate.buildbydate(pdate);
    if (HDate.equals(date, HDate.today()))
      return true;
    return false;
  }

  @Input() disableSwipe: boolean = false;

  public onSwipeRight($event: any) {
    if (!this.disableSwipe)
      this.calcelar();
  }
  public onSwipeLeft($event: any) {
    if (!this.disableSwipe)
      this.eliminar();
  }

  public deleted: boolean = false;
  public eliminar() {
    if (!this.homeController.useIonic()) {
      //Si el missatge no ha sigut llegit, permetem eliminarlo
      if (!this.value.read)
        this.deleted = true;
    }
  }
  public calcelar() {
    if (!this.homeController.useIonic()) {
      this.deleted = false;
    }
  }

  doDelete() {
    this.onActionDelete.next();
  }

  ngOnInit() {
    this.deleted = false;
  }

  getTextColor(valueString: string) {
    if (HString.isNullOrNullString(valueString))
      return HColor.BLACK;
    return HColor.getColorFromString(valueString);
  }

  public user = ChatParticipantTypes.User;
  public support = ChatParticipantTypes.Support;

  mostrarLinksUsuaris() {
    if (this._value.participants != null) {
      if (this._value.participants.length > 2)
        return true;
      return false;
    }
  }

  getIsLost() {
    if (!this.value.waiting)
      return;

    setTimeout(() => {
      if (this.value.waiting) {
        this.value.lost = true;
      }
    }, 20 * 1000);
  }
}


