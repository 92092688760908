export class MaintenanceSearchByTextDto {

	public idReserca: string;

	public searchtext: string;
	public activeFilterKeys: Array<string>;
	public parentid: number;
	public ambitId: number;
	public addressId: number;
	public page: number = 0;
	public itemsPerPage: number = 50;
	public sliceCount = 100;

	public static buildPage(searchtext: string, activeFilterKeys: Array<string>, page: number, itemsPerPage: number): MaintenanceSearchByTextDto {
		let result = new MaintenanceSearchByTextDto();
		result.searchtext = searchtext;
		result.activeFilterKeys = activeFilterKeys;
		result.page = page;
		result.itemsPerPage = itemsPerPage;
		return result;
	}
}