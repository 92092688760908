import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { HTime } from '../datatypes/HTime';

@Pipe({
	name: 'time'
})
@Injectable()
export class TimeFilter implements PipeTransform {
	constructor() {

	}
	transform(value: HTime): string {
		if (value == null)
			return "-:--";
		let result: string;

		result = this.pad(value.h, 2).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ":" + this.pad(value.m, 2);
		if (value.s != null && value.s != "")
			result = value.s + result;

		return result;
	}

	pad(num, size): string {

		let snum = "" + num + "";

		if (snum != null && snum.length >= size)
			return snum;
		let s = "000000000" + snum;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'timeNotNull'
})
@Injectable()
export class TimeFilterNotNull implements PipeTransform {
	constructor() {

	}
	transform(value: HTime): string {
		if (HTime.isNullOrNullValue(value))
			return "";

		return new TimeFilter().transform(value);
	}
}

@Pipe({
	name: 'millisecondsToMinutesSecondsMillis'
})
@Injectable()
export class MillisecondsToMinutesSecondsMillis implements PipeTransform {
	transform(value: number): string {
		return HTime.millisecondsToMinutesSecondsMillis(value);
	}
}

