import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { HTranslateService } from "../services/translate/htranslate.service";

@Pipe({
    name: 'htranslate'
})
@Injectable()
export class HTranslateFilter implements PipeTransform {

    constructor(private translate: HTranslateService) {
    }

    public transform(key: string, variables?: any): string {
        return this.translate.instant(key, variables);
    }
}