import { Component, Input, EventEmitter, Output, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { ChatDto } from '@shared/src/dtos/chat/ChatDto';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { ChatMessageDto } from '@shared/src/dtos/chat/message/ChatMessageDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HString } from '@shared/src/datatypes/HString';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { Platforms } from '@shared/src/public-api';
import { userDto } from '@shared/src/dtos/user/userDto';

@Component({
  selector: 'shared-chat',
  templateUrl: './shared.chat.html',
  styleUrls: ['./shared.chats.scss']
})
export class SharedChat implements OnInit {


  public useIonic: boolean = false;
  public useIos: boolean = false;
  constructor(private chatController: ChatController, @Inject('HomeController') public homeController: HomeController,
    @Inject('SecurityController') public securityController: SecurityController) {
    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
    if (homeController.getPlatform())
      this.useIos = homeController.getPlatform() == Platforms.ios;

    this.securityController.getActualUserDto().subscribe(u => {
      this.currentUser = u;
    })
  }

  private currentUser: userDto;

  @Input() public notes: boolean = false;
  public getInputPlaceholder(): string {
    if (!this.notes)
      return 'CHATS.MESSAGE.WRITESOMETHING';
    else
      return 'CHATS.NOTES.WRITESOMETHING';
  }

  public isGroup: boolean;

  @ViewChild('scrollMessages', { static: true }) private scrollMessagesContainer: ElementRef;
  private scrollContainer: any;

  @Input() public ownerUserId: number;
  @Input() public ownerUserType: ChatParticipantTypes;

  private _value: ChatDto;
  @Input() set value(param: ChatDto) {
    if ((this._value == null && param == null) || (this._value != null && param != null && this._value.chatId == param.chatId))
      return;
    this._value = param;
    this.isPlegat = false;
    this.showChevron = false;
    this.localChatMessagesKeys = null;

    this.refreshChat(this._value);
    this.scrollToBottom();

    if (this.value && this._value.chatmessages) {
      this.localChatMessages = this._value.chatmessages;
      this.localChatMessagesKeys = this.localChatMessages.map(m => m.chatMessageKey);
    }

    console.log('this.localchatmessages', this.localChatMessages);
  }
  get value() {
    return this._value;
  }

  private _forcedOwnerParticipant: ParticipantDto;
  @Input() set forcedOwnerParticipant(param: ParticipantDto) {
    if (this._forcedOwnerParticipant == param)
      return;
    this._forcedOwnerParticipant = param;
    this.ownerUserId = this.securityController.getCurrentUserId();
    this.ownerUserType = this.securityController.isHardManUser() ? ChatParticipantTypes.Support : ChatParticipantTypes.User;
    let chat = ChatDto.buildByOwnerAndTargetParticipant(this._forcedOwnerParticipant, ParticipantDto.buildByParams(this.ownerUserId, this.ownerUserType));
    this.chatController.getOrCreateChat(chat).subscribe(data => {
      this.value = data;
      this.homeController.CHATS.selectedChatId = data.chatId;
    });
  }
  get forcedOwnertParticipant() {
    return this._forcedOwnerParticipant;
  }

  getMessages() {
    if (this.localChatMessages)
      return this.localChatMessages;
    return new Array();
  }

  public localChatMessages: Array<ChatMessageDto> = new Array<ChatMessageDto>();
  public localChatMessagesKeys: Array<string> = null;
  public height: number;
  public showChevron: boolean = false;
  public isPlegat: boolean = false;

  public participantavatar: ParticipantDto;
  public participantkey: String = "";
  public participantAnnotation: String = "";
  public participants = new Array();
  public allParticipants = 0;
  public showAnnotation = false;
  refreshChat(data: ChatDto) {
    this.scrollToBottom();
    this.participantkey = "";
    this.participantAnnotation = "";

    ChatDto.actualizedChatByFrom(this.value, data);
    if (this.value != null && this.ownerUserId != null) {
      if (this.value.ownerParticipantId != this.ownerUserId) {
        if (this.value.ownerParticipant != null) {
          this.participantkey = this.value.ownerParticipant.skin;
          this.participantAnnotation = this.value.ownerParticipant.participantAnnotation;
        }
        this.participantavatar = this.value.ownerParticipant;
      }
      else if (this.value.targetParticipantId != this.ownerUserId) {
        if (this.value.targetParticipant != null) {
          this.participantkey = this.value.targetParticipant.skin;
          this.participantAnnotation = this.value.targetParticipant.participantAnnotation;
          this.participantavatar = this.value.targetParticipant;
        }
      }
      if (!this.participantavatar)
        this.participantavatar = this.value.ownerParticipant;

      // Participants
      if (this.value.chatmessages && this.value.participants) {
        let orderedParticipantsMap: Map<string, ParticipantDto> = new Map<string, ParticipantDto>();
        this.value.chatmessages.slice().reverse().forEach(m => {
          if (!orderedParticipantsMap.get(m.fromUser.participantAnnotation)) {
            orderedParticipantsMap.set(m.fromUser.participantAnnotation, m.fromUser);
          }
        });
        this.value.participants.forEach(p => {
          if (!orderedParticipantsMap.get(p.participantAnnotation)) {
            orderedParticipantsMap.set(p.participantAnnotation, p);
          }
        });
        this.value.participants = Array.from(orderedParticipantsMap.values());
      }

      this.participants = new Array();
      this.participants = this.getDtoAllParticipants();
      this.showAnnotation = this.getShowAnnotation(data);
      this.isGroup = this.value != null && this.value.ownerParticipantType == ChatParticipantTypes.Group;

      (async () => {
        await this.delay(100);

        let divParticipants = document.getElementById("div-participants");
        if (divParticipants) {
          this.height = divParticipants.offsetHeight;
          let maxHeight = this.useIos ? 51 : 43;
          if (this.height > maxHeight) {
            this.showChevron = true;
            this.isPlegat = true;
          }
        }
      })();

      // Chats messages
      if (this.value.chatmessages)
        this.value.chatmessages.forEach(e => {
          if (e && e.chatMessageKey) {
            if (this.localChatMessagesKeys && !this.localChatMessagesKeys.includes(e.chatMessageKey)) {
              console.log('pushing', e);
              this.localChatMessages.push(e);
              this.localChatMessagesKeys.push(e.chatMessageKey);
            }
          }
        });
    }
  }

  onChevronClick() {
    this.isPlegat = !this.isPlegat;
  }

  getStringAllParticipants(): string {
    let all: string = "";

    if (this.value != null && this.value.participants != null && this.value.participants.length > 2) {
      this.value.participants.forEach(element => {
        if (element.participantType == ChatParticipantTypes.User || element.participantType == ChatParticipantTypes.Support) {
          all = HString.concat(all, ', ', element.participantAnnotation);
        }
      });
    }

    return all;
  }

  getShowAnnotation(data: ChatDto) {
    if (data && (this.allParticipants > 2 || (data.ownerParticipantType != ChatParticipantTypes.User && data.ownerParticipantType != ChatParticipantTypes.Support))) {
      return true;
    }
    return false;
  }

  getDtoAllParticipants(): Array<ParticipantDto> {
    this.allParticipants = 0;
    let all = new Array<ParticipantDto>();

    if (this.value != null && this.value.participants != null && this.value.participants.length > 2) {
      this.value.participants.forEach(element => {
        this.allParticipants++;
        if (element.participantType == ChatParticipantTypes.User || element.participantType == ChatParticipantTypes.Support) {
          all.push(element);
        }
      });
    }

    return all;
  }

  public textareakeypress(e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (!e.shiftKey && code == 13) { //Enter keycode
      e.preventDefault();
      this.send();
    }
  }

  public messagetosend: string = "";
  send() {
    if (this.value != null && !HString.isNullOrNullString(this.messagetosend)) {
      // Construim el missatge i li posem una key única per poder comparar els missatges locals amb els de la BD.
      let chatmessage = ChatMessageDto.build(this.ownerUserId, this.ownerUserType, this.value, this.messagetosend);
      this.messagetosend = "";
      if (!navigator.onLine) {
        chatmessage.lost = true;
      }

      console.log('currentUser', this.currentUser);
      if (this.currentUser) {
        chatmessage.fromUser = ParticipantDto.buildByParams(this.currentUser.userId, ChatParticipantTypes.User);
        chatmessage.fromUser.description = this.currentUser.fullName;
        chatmessage.fromUser.skin = this.currentUser.skin;
      }
      console.log('chatmessage', chatmessage);

      // Afegim el missatge escrit a la llista local i a la llista de keys local, que s'utilitza per a comparar-la amb la llista de keys dels missatges de la BD.
      this.localChatMessages.push(chatmessage);
      this.localChatMessagesKeys.push(chatmessage.chatMessageKey);
      if (this.localChatMessages.length > 50)
        this.localChatMessages.shift();

      this.scrollToBottom();
      this.chatController.addChatMessage(chatmessage).subscribe(data => {
        this.homeController.geolocationRequest$.next();
        this.refreshChat(data);
        this.compareLists();
        this.scrollToBottom();
      });
    }
  }

  /**
   * Compara la llista de missatges local amb la llista de missatges que arriba de la BD, si la llista local conté algun missatge que la llista de la BD no, 
   * el marca com a "waiting", més tard es marca com a "lost". 
   * No es marca directament com a "lost" perque per exemlpe al enviar molts missatges seguits ràpidament hi ha diferènicies temporals entre les dos llistes.
   */
  compareLists() {
    if (!this.value || !this.value.chatmessages)
      return;
    let valueMessageKeys: Array<string> = this.value.chatmessages.map(cm => cm.chatMessageKey);
    let lostKeys: Array<string> = new Array<string>();

    if (this.localChatMessagesKeys)
      this.localChatMessagesKeys.forEach(key => {
        if (!valueMessageKeys.includes(key)) {
          lostKeys.push(key);
        }
      });

    if (this.localChatMessages)
      this.localChatMessages.forEach(e => {
        if (e.chatMessageKey) {
          if (lostKeys.includes(e.chatMessageKey)) {
            this.localChatMessages[this.localChatMessages.indexOf(e)].waiting = true;
          }
          else
            this.localChatMessages[this.localChatMessages.indexOf(e)].waiting = false;
        }
      });
  }

  @Output() clickParticipant: EventEmitter<ParticipantDto> = new EventEmitter();
  onClickParticipant(participant: ParticipantDto) {
    this.clickParticipant.next(participant);
  }

  doActionDeleteChatMessage(chatmessage: ChatMessageDto) {
    if (chatmessage != null) {
      this.chatController.deleteChatMessage(chatmessage.chatMessageId).subscribe(data => {
        this.refreshChat(data);
      });
    }
  }

  @Output() onChatBack: EventEmitter<ChatDto> = new EventEmitter();

  back() {
    this.onChatBack.next(this.value);
  }

  ngOnInit() {
    this.scrollToBottom();
    this.homeController.chatsChanged$.subscribe(data => {
      if (this.value != null && data != null) {
        var vdata: ChatsDto = data;
        if (vdata.chats != null) {
          vdata.chats.forEach(chat => {
            if (this.value.chatId == chat.chatId) {
              this.refreshChat(chat);
            }
          });
        }
      }
    })
  }

  scrollToBottom(): void {
    try {
      if (this.scrollMessagesContainer != null && this.scrollContainer == null)
        if (this.scrollMessagesContainer.nativeElement != null)
          this.scrollContainer = this.scrollMessagesContainer.nativeElement;

      (async () => {
        await this.delay(100);

        this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
      })();

    } catch (err) { }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  doSomethingOnScroll($event: any) {
    let scrollOffset = $event.srcElement.scrollTop;
    //Si estem al inici recarreguem mes missatges
    if (scrollOffset == 0) {
      this.chatController.loadMoreMessagesChat(this.value).subscribe(data => {
        this.homeController.refreshChatByChat(data);
        if (this.value && this._value.chatmessages) {
          this.localChatMessages = this._value.chatmessages;
          this.localChatMessagesKeys = this.localChatMessages.map(m => m.chatMessageKey);
        }
      });
    }
  }
}