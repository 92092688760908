import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';

@Component({
  selector: 'shared-route-changetags',
  templateUrl: './shared.route.changetags.component.html',
  styleUrls: ['./shared.route.changetags.component.scss'],
})
export class SharedRouteChangeTagsComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
 
      this.hascarrier = this.homeController.hasProduct(HardmanProducts.carrier);
      this.hasshipper = this.homeController.hasProduct(HardmanProducts.shipper);
  }

  public hascarrier: boolean = false;
  public hasshipper: boolean = false;

  @Input() public readonly: boolean = false;
  
  @Input() public showtitle: boolean = false;
  @Input() public showalltags: boolean = false;

  public __route: RouteCardDto;
  @Input()
  public set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null){
      this.__routeId = 0;
      this._manualTags = null;
      this._privateTags = null;
    } else{
      this.__routeId = value.routeId;
      this._manualTags = this.__route.manualTags;
      this._privateTags = this.__route.privateTags;
    }
  }
  public get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public loadRouteData() {
    if (!this.routeId)
      return;

    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.routeUpdated.next(this.route);
    });

  }

  @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();
  
  public _manualTags: HTags = null;
  public set manualTags(manualTags: HTags) {
      if (manualTags == this._manualTags)
          return;

      this._manualTags = manualTags;
      this.openRouteController.setManualTags(this.__route.routeId, this._manualTags).subscribe(data => {
          if (data)
              this.valueChange.next(this.__route);
      })
  }

  public get manualTags(): HTags {
      return this._manualTags;
  }

  public _privateTags: HTags = null;
  public set privateTags(privateTags: HTags) {
      if (privateTags == this._privateTags)
          return;

      this._privateTags = privateTags;
      this.openRouteController.setPrivateTags(this.__route.routeId, this._privateTags).subscribe(data => {
          if (data)
              this.valueChange.next(this.__route);
      })
  }

  public get privateTags(): HTags {
      return this._privateTags;
  }

    
  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;

    this.routeUpdated.next(value);
  }

  save() {

  }
  public pretty(value) {
    return JSON.stringify(value);
  }
}
