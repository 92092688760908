import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerLogisticOperatorMaintenanceController extends
    PartnerMaintenanceController {

    public getResourceRoot(): string {
        return "PARTNER.LOGISTICOPERATOR";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/logisticoperator" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.LOGISTICOPERATOR.LINKPERMISSION.FILTER"];
    }

}
