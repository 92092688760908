import { Component, Input, OnInit, Inject } from "@angular/core";
import { ChangeBlameDto } from "@shared/src/dtos/phase/ChangeBlameDto";
import { PhaseController } from "@shared/src/controllers/route/phase/phase.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";
import { Blames } from "@shared/src/enums/Blames";
import { RouteCardDto } from "@shared/src/dtos/route/RouteCardDto";
import { RouteController } from "@shared/src/controllers/route/route.controller";



@Component({
    selector: "shared-blame-selector-component",
    templateUrl: "./shared.blame.selector.component.html",
    styleUrls: ["./shared.blame.selector.component.scss"],
})
export class SharedBlameSelectorComponent implements OnInit {
    @Input() public readonly: boolean = false;

    constructor(
        public phaseController: PhaseController,
        public routeController: RouteController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) {
        this.securityController.getStoredUserDto().subscribe((data) => {
            this.readonly = data.isReadOnly;
        });
    }

    ngOnInit(): void {

    }

    private _phase: PhaseCardDto;
    @Input() public set phase(value: PhaseCardDto) {
        if (this._phase && value.phaseId == this._phase.phaseId)
            return;

        this._phase = value;

        if (this.phase) {
            this.changeBlameData.blame = this.phase.blame;

            // Calcularem els actors què els hi podem assignar la culpa
            this.routeController.getRoute(this.phase.routeId).subscribe(route => {
                if (route) {
                    this.blamesList = {};

                    this.blamesList[Blames.Me as Blames] = Blames.Me;

                    if (route.partnerCarrierId)
                        this.blamesList[Blames.Carrier as Blames] = Blames.Carrier;

                    if (route.partnerDriverId)
                        this.blamesList[Blames.Driver as Blames] = Blames.Driver;

                    if (route.partnerSecondDriverId)
                        this.blamesList[Blames.Driver as Blames] = Blames.Driver;

                    if (route.partnerLogisticOperatorId)
                        this.blamesList[Blames.LogisticOperator as Blames] = Blames.LogisticOperator;

                    this.showBlamesList = true;
                }

            })
        }
    }

    public get phase(): PhaseCardDto {
        return this._phase;
    }

    public changeBlameData: ChangeBlameDto = new ChangeBlameDto();

    // Llista dels possibles actors a qui els hi podem assignar la culpa 
    public blamesList = null;

    // Necessari ja que l'enum-selector sols ensenya la llista que rep al principi, 
    // no mostra modificacions dinàmiques, per tant primer calcularem i després mostrarem la llista
    public showBlamesList: boolean = false;

    public save() {
        console.log(this.changeBlameData);

        this.phaseController.setPhaseBlame(this.phase.phaseId, this.changeBlameData).subscribe(e => {
            if (e) {
                this.changeBlameData = new ChangeBlameDto();
                this.phaseController.getPhase(this.phase.phaseId).subscribe(p => {
                    this.phase = p;
                    this.changeBlameData.blame = this.phase.blame;
                })
            }
        })
    }
}
