import { Component, Inject, NgZone, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { AlertService, HomeController, HTranslateService, SecurityController, SocketController } from '@shared/src/public-api';
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { TotemRouteDto } from '@shared/src/controllers/totem/TotemRouteDto';

@Component({
    selector: 'shared-totem-idverification',
    templateUrl: './shared.totem.idverification.component.html',
    styleUrls: ['./shared.totem.idverification.component.scss']
})
export class SharedTotemIdVerificationComponent implements OnInit, OnDestroy {

    constructor(protected inactivityService: SharedInactivityService,
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public secretTextSubject = new Subject<string>();
    public secretText: string = "";

    public totemSteps = TotemSteps;

    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;
    scrollObservable$: Observable<Event>;
    scrollSubscription$: Subscription;

    ngOnInit(): void {

    }
    ngOnDestroy() {
    }
    ngAfterViewInit() {
    }

    @Input()
    public totem: TotemDto;
    @Output() totemChange = new EventEmitter<TotemDto>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();

    confirmacioRutaAcceptar() {
        this.totemService.idverifyRoute(this.totem, this.secretText).subscribe(d => {
            this.totem = d;
            this.totemChange.next(this.totem);
        });
    }


    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


