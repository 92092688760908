<div class="all-container" [ngClass]="{'absolute' : showWebCam }">
  <div *ngIf="!showWebCam" class="body-container">
    <div *ngIf="!hideImage && formatImage" class="body-data-img" [ngClass]="miniImages">
      <img class="imatge" *ngIf="showImage" [src]="formatImage" />
    </div>
    <div class="footer-img" [ngClass]="miniImages">
      <div class="footer-img-mini-img">
        <div class="item-miniimg" [ngClass]="getClassItemMiniImg(miniimg)" *ngFor="let miniimg of minsImgs">
          <div class="icona icon-trash"><i class="fa fa-trash" (click)="removeMiniImg(miniimg)"></i></div>
          <img [src]="getDataImageUri(miniimg)" (click)="onSelectedImage(miniimg)" />
        </div>
      </div>
      <div *ngIf="!readonly" class="footer-img-icon-camera">
        <div class="body-btn-img-camera">
          <div id="take-a-photo" class="action button" (click)="takeAPhoto()">
            <i class="fa fa-camera icon-ionic-camera"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <shared-webcam class="webcam" *ngIf="showWebCam" (pictureTaken)="takeAPhotoWebCamResolver($event)"
    (close)="onCloseWebCamera($event)" [incident]="incident"></shared-webcam>
</div>