import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { PartnerDriverMaintenanceController } from '@shared/src/controllers/maintenance/partner/PartnerDriverMaintenanceController';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { ShipmentReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/ShipmentReturnInBoundOutBoundDelegatedDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { PartnerCategories } from '@shared/src/enums/PartnerCategories';
import { RouteShipmentTypes } from '@shared/src/enums/RouteShipmentTypes';
import { AlertService, DatePickerComponent, DatePickerValueChangedEvent, DateValidator, HDate, HHourIntervals, PartnerCarrierMaintenanceController, SharedSelectorAddressManualAddressEvent } from '@shared/src/public-api';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { RouteTypesModelsFuncionament } from './RouteTypesModelsFuncionament';

/***
 * Això encapsula el funcionament de otes les pantalles de retorns, devolucions, etc que apareixen als aside.
 */
@Component({
	template: ''
})
export class SharedRouteReturnBaseCreatePage implements OnInit, OnDestroy, AfterViewInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;
	public rFormShipment: FormGroup;
	resizeObservable$: Observable<Event>;
	resizeSubscription$: Subscription;
	scrollObservable$: Observable<Event>;
	scrollSubscription$: Subscription;
	public CREATE_ROUTE_BUTTON_CAPTION = "";
	public CREATE_ROUTE_CLIENTSUPPLIER_LABEL = "";
	public CREATE_ROUTE_PROVIDER_LABEL = "";
	public SELECT_ROUTE_TO_RETURN_TITLE = "";

	public potSelectCarrier: boolean = false;
	public potSelectDriver: boolean = false;

	@Output()
	public onCreate: EventEmitter<any> = new EventEmitter<any>();

	public ROUTE_FILTERKEYS: Array<string> = ["STARTED"];
	public CUSTOMER_FILTERKEY: Array<string> = ['PARTNER.FINALCUSTOMER.LINKPERMISSION.FILTER'];
	public PROVIDER_FILTERKEY: Array<string> = ['PARTNER.PROVIDER.LINKPERMISSION.FILTER'];
	public DRIVER_FILTERKEY: Array<string> = ['PARTNER.DRIVER.LINKPERMISSION.FILTER'];
	public CARRIER_FILTERKEY: Array<string> = ['PARTNER.CARRIER.LINKPERMISSION.FILTER'];
	public REVERSELOGISTICSPARTNER_FILTERKEY: Array<string> = ['PARTNER.REVERSELOGISTICS.LINKPERMISSION.FILTER'];

	constructor(
		public modelFuncionament: RouteTypesModelsFuncionament,

		public routeController: RouteController,
		public shipmentController: ShipmentController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService,
		protected addressController: AddressController,
		protected partnersController: PartnersController,
		protected partnerDriverController: PartnerDriverMaintenanceController,
		protected partnerCarrierController: PartnerCarrierMaintenanceController,
		protected translateService: TranslateService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		this.rFormShipment = this.buildFormValidators();
		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

		this.potSelectCarrier = this.homeController.potSelectCarrier();
		this.potSelectDriver = this.homeController.potSelectDriver();

		//Coses per iniciar la pantalla
		this.CREATE_ROUTE_BUTTON_CAPTION = 'SHARED.ROUTE.RETURN.BASE.CREATE.' + this.modelFuncionament.routeShipmentType.toUpperCase() + '.BUTTON.CAPTION';
		this.SELECT_ROUTE_TO_RETURN_TITLE = 'SHARED.ROUTE.RETURN.BASE.CREATE.' + this.modelFuncionament.routeShipmentType.toUpperCase() + '.ROUTE_TO_RETURN.TITLE';

		this.setAddressFilterKeys();
	}

	ngAfterViewInit() {
		this.onScroll();

		if (this.container != null && this.container.nativeElement != null && this.container.nativeElement.parentElement != null) {
			const scrollerHandle = this.container.nativeElement.parentElement;
			if (scrollerHandle.addEventListener) {
				scrollerHandle.addEventListener("scroll", () => {
					this.onScroll();
				});
			}
		}

		let dto = this.initialdto;
		if (dto == null || dto.type == null || dto.type == undefined || dto.type == RouteShipmentTypes.None)
			dto = RouteReturnInBoundOutBoundDelegatedDto.buildEmpty(this.modelFuncionament.routeShipmentType);
		this.routeController.wizardStart(dto).subscribe(e => {
			this.wellcome(e, false);
		});
	}

	/***
	 * Volem fer una devolució lliure
	 */
	public freeReturn() {
		if (!this.route)
			return;
		if (!this.modelFuncionament.shipmentsOrigenOpcional)
			return;

		this.modelFuncionament = RouteTypesModelsFuncionament.build().setTypeAsFreeReturn();

		this.route.basedOnRouteId = null;
		this.route.originalShipments = [];
		this.route.freeReturn = true;
		this.routeController.wizardStart(this.route).subscribe(e => {
			this.wellcome(e, false);
		});
	}

	/***
	 * Ens venen a buscar algo (Origen hi haurà les meves adreces i a destí les seves)
	 */
	public reverseLogisticsOrigin() {
		if (!this.route)
			return;

		this.modelFuncionament = RouteTypesModelsFuncionament.build().setTypeAsReverseLogisticsOriginOwn();

		this.route.basedOnRouteId = null;
		this.route.originalShipments = [];
		this.route.reverseLogisticsOriginOwn = true;

		this.next(true);
	}

	/***
	 * Anem a buscar algo (Destí hi haurà les meves adreces i a origen les seves)
	 */
	public reverseLogisticsDestination() {
		if (!this.route)
			return;

		this.modelFuncionament = RouteTypesModelsFuncionament.build().setTypeAsReverseLogisticsDestinationOwn();

		this.route.basedOnRouteId = null;
		this.route.originalShipments = [];
		this.route.reverseLogisticsOriginOwn = false;

		this.next(true);
	}

	public refreshDefaultDataByPartnerLogistics() {

		this.routeController.wizardRefreshDefaultDataByPartnerReverseLogistics(this.route).subscribe(e => {
			this.wellcome(e, true);
		});
	}

	/***
	 * Han canviat la ruta d'inici per tant tinc que seguir el wizard
	 */
	public next(refreshAddressKeys: boolean) {
		this.routeController.wizardNext(this.route).subscribe(e => {
			this.wellcome(e, refreshAddressKeys);
		});
	}
	/***
 * Han canviat la ruta d'inici per tant tinc que seguir el wizard
 */
	public previous() {
		this.routeController.wizardPrevious(this.route).subscribe(e => {
			this.wellcome(e, false);
		});
	}
	@Input()
	public initialdto: RouteReturnInBoundOutBoundDelegatedDto = null;
	public route: RouteReturnInBoundOutBoundDelegatedDto;
	public actualShipment: ShipmentReturnInBoundOutBoundDelegatedDto;
	/**
	 * Dona la benvinguda a la ruta i estableix les variables que fan funcionar el tema. Sobretot la part de les dates i actualShipment
	 */
	public wellcome(value: RouteReturnInBoundOutBoundDelegatedDto, refreshAddressKeys: boolean) {
		this.route = value;
		if (this.route && this.route.shipments && this.route.shipmentNumber >= 0 && this.route.shipmentNumber < this.route.shipments.length) {
			this.actualShipment = this.route.shipments[this.route.shipmentNumber];
			if (this.actualShipment != null) {
				this.actualShipment.originDateStart = HDate.buildFromHDate(this.actualShipment.originDateStart);
				this.actualShipment.originDateEnds = HDate.buildFromHDate(this.actualShipment.originDateEnds);
				if (this.actualShipment.originAddressManualAddress != null)
					this.actualShipment.originAddressManualAddress.loadIntervals = HHourIntervals.buildByDto(this.actualShipment.originAddressManualAddress.loadIntervals);
				this.actualShipment.destinationDateStart = HDate.buildFromHDate(this.actualShipment.destinationDateStart);
				this.actualShipment.destinationDateEnds = HDate.buildFromHDate(this.actualShipment.destinationDateEnds);
				if (this.actualShipment.destinationAddressManualAddress != null)
					this.actualShipment.destinationAddressManualAddress.unloadIntervals = HHourIntervals.buildByDto(this.actualShipment.destinationAddressManualAddress.unloadIntervals);
			}
		} if (this.route && this.route.stepNumber == -2)
			this.onCreate.next(this.route.routeId);

		if (refreshAddressKeys)
			this.setAddressFilterKeys();
	}

	public _forcedViewTypeAddressOrigin: string;
	public ORIGIN_ADDRESS_FILTERKEYS: Array<string> = ['ADDRESS.NOTHING.FILTER'];
	public DESTINATION_ADDRESS_FILTERKEYS: Array<string> = ['ADDRESS.NOTHING.FILTER'];
	setAddressFilterKeys() {

		let routeTypeOrigin: string = "";
		let routeTypeDestination: string = "";
		//Només poso el prefix amb ReverseLogistics ja que és necessàri aplicar filtres a les adreces
		if (this.modelFuncionament.routeShipmentType == RouteShipmentTypes.ReverseLogistics) {
			routeTypeOrigin = this.modelFuncionament.routeShipmentType.toUpperCase() + ".";
			routeTypeDestination = routeTypeOrigin;
			//A l'adreça d'origen sempre s'ha d'aplicar el filtre d'adreces d'slot
			routeTypeOrigin += "ENABLEDSLOT.";
		}

		if (this.modelFuncionament.adrecaOrigenPropia) {
			this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.FILTER.OWN'];
			if (this.modelFuncionament.routeShipmentType == RouteShipmentTypes.ReverseLogistics && this.route && this.route.partnerReverseLogisticsId > 0) {
				this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.FILTER.OWN.BYPARNTERID:' + this.route.partnerReverseLogisticsId];
			}
		} else if (this.modelFuncionament.adrecaOrigenPartner && this.route) {

			if (this.route.partnerReverseLogisticsId > 0) {
				this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.BYPARNTERID.FILTER:' + this.route.partnerReverseLogisticsId];
			} else if (this.actualShipment) {
				if (this.modelFuncionament.proveidorOrigenVisible)
					this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.BYPARNTERID.FILTER:' + this.actualShipment.partnerProviderId];
				else if (this.modelFuncionament.clientOrigenVisible || this.modelFuncionament.routeShipmentType == RouteShipmentTypes.Delegate)
					this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.BYPARNTERID.FILTER:' + this.actualShipment.partnerClientId];
				else
					this.ORIGIN_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];
			} else
				this.ORIGIN_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		} else if (this.modelFuncionament.adrecaOrigenTenantIdPartner && this.route) {

			if (this.route.partnerReverseLogisticsId > 0) {
				this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.route.partnerReverseLogisticsId];
			} else if (this.actualShipment) {
				if (this.modelFuncionament.proveidorOrigenVisible)
					this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.actualShipment.partnerProviderId];
				else if (this.modelFuncionament.clientOrigenVisible || this.modelFuncionament.routeShipmentType == RouteShipmentTypes.Delegate)
					this.ORIGIN_ADDRESS_FILTERKEYS = [routeTypeOrigin + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.actualShipment.partnerClientId];
				else
					this.ORIGIN_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];
			} else
				this.ORIGIN_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		} else
			this.ORIGIN_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		if (this.modelFuncionament.adrecaDestiPropia) {
			this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.FILTER.OWN'];
		} else if (this.modelFuncionament.adrecaDestiPartner && this.route) {

			if (this.route.partnerReverseLogisticsId > 0) {
				this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.BYPARNTERID.FILTER:' + this.route.partnerReverseLogisticsId];
			} else if (this.actualShipment) {
				if (this.modelFuncionament.proveidorDestiVisible)
					this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.BYPARNTERID.FILTER:' + this.actualShipment.partnerProviderId];
				else if (this.modelFuncionament.clientDestiVisible || this.modelFuncionament.routeShipmentType == RouteShipmentTypes.Delegate)
					this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.BYPARNTERID.FILTER:' + this.actualShipment.partnerClientId];
				else
					this.DESTINATION_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];
			} else
				this.DESTINATION_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		} else if (this.modelFuncionament.adrecaDestiTenantIdPartner && this.route) {

			if (this.route.partnerReverseLogisticsId > 0) {
				this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.route.partnerReverseLogisticsId];
			} else if (this.actualShipment) {
				if (this.modelFuncionament.proveidorDestiVisible)
					this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.actualShipment.partnerProviderId];
				else if (this.modelFuncionament.clientDestiVisible || this.modelFuncionament.routeShipmentType == RouteShipmentTypes.Delegate)
					this.DESTINATION_ADDRESS_FILTERKEYS = [routeTypeDestination + 'ADDRESS.OWNPARTNERBYPARNTERID.FILTER:' + this.actualShipment.partnerClientId];
				else
					this.DESTINATION_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];
			} else
				this.DESTINATION_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		} else
			this.DESTINATION_ADDRESS_FILTERKEYS = ['ADDRESS.NOTHING.FILTER'];

		if (this.modelFuncionament.routeShipmentType == RouteShipmentTypes.ReverseLogistics) {
			if (this.modelFuncionament.adrecaOrigenPropia || (this.route && this.route.partnerReverseLogisticsId > 0))
				this._forcedViewTypeAddressOrigin = "registered";
		}
	}

	ngOnInit(): void {
		this.resizeObservable$ = fromEvent(window, 'resize');
		this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
			this.onScroll();
		});
		this.scrollObservable$ = fromEvent(window, 'scroll');
		this.scrollSubscription$ = this.scrollObservable$.subscribe(evt => {
			this.onScroll();
		});
	}

	ngOnDestroy() {
		if (this.resizeSubscription$)
			this.resizeSubscription$.unsubscribe();
		if (this.scrollSubscription$)
			this.scrollSubscription$.unsubscribe();
	}


	canGoFreeReturn(): boolean {

		if (this.modelFuncionament.shipmentsOrigenOpcional && this.route.stepNumber > 0 && this.route.stepNumber < 3)
			return true;

		return false;
	}

	canGoPrevious(): boolean {
		if (this.route)
			if (this.route.stepNumber > 2 && (this.modelFuncionament.seleccioShipmentsOrigen || this.modelFuncionament.preguntaInicialRuta))
				return true;
		if (this.route.stepNumber < 0 || this.route.stepNumber > 3)
			return true;
		return false;
	}
	canGoNext(): boolean {
		if (this.rFormShipment.invalid)
			return false;

		if (this.route.stepNumber < 3 && this.route.shipments.length < 1)
			return false;

		//Totes les adreces de destí han d'estar informades
		//Totes les dates de destí han d'estar informades
		//Depenent de si l'origen és opcional
		//Totes les adreces de d'origen han d'estar informades
		//Totes les dates de d'origen han d'estar informades
		this.route.shipments.forEach(x => {
			if (HDate.isNullOrNullValue(x.destinationDateStart) || HDate.isNullOrNullValue(x.destinationDateEnds))
				return false;
			if (!AddressController.estaInformada(x.destinationAddressManualAddress))
				return false;
			if (!this.modelFuncionament.adrecaOrigenOpcional) {
				if (HDate.isNullOrNullValue(x.originDateStart) || HDate.isNullOrNullValue(x.originDateEnds))
					return false;
				if (!AddressController.estaInformada(x.originAddressManualAddress))
					return false;
			}
		});
		return true;
	}
	canFinish(): boolean {
		if (!this.canGoNext())
			return false;
		if (this.route.stepNumber >= 0 && this.route.stepNumber < 3)
			return false;
		return true;
	}

	finishRoute() {
		this.routeController.wizardFinish(this.route).subscribe(e => {
			if (e)
				this.onCreate.next(e);
		});
	}
	canDeleteShipment(): boolean {
		if (this.route && this.route.shipments && this.route.shipments.length > 1)
			return true;
		return false;
	}
	public deleteShipment() {
		this.routeController.wizardRemoveShipment(this.route).subscribe(e => {
			this.wellcome(e, false);
		});
	}
	public addShipment() {
		this.routeController.wizardAddShipment(this.route).subscribe(e => {
			this.wellcome(e, false);
		});
	}

	driverSet() {
		if (this.route.partnerDriverId)
			this.partnersController.getPartnerAs(this.route.partnerDriverId, PartnerCategories.Driver).subscribe(e => {
				if (e) {
					this.route.driverName = e.organitzationNameTarget;
					this.route.driverLegalIdentification = e.nifTarget;
					this.route.driverPhone = e.phoneTarget;
					this.route.plateA = e.plateA;
					this.route.plateB = e.plateB;
				}
				else {
					this.route.driverName = "";
					this.route.driverLegalIdentification = "";
					this.route.driverPhone = "";
					this.route.plateA = "";
					this.route.plateB = "";
				}
			});
		else {
			this.route.driverName = "";
			this.route.driverLegalIdentification = "";
			this.route.driverPhone = "";
			this.route.plateA = "";
			this.route.plateB = "";
		}
	}

	pretty(value) {
		return JSON.stringify(value);
	}

	public selectUnselectShipment(shipment: ShipmentCardDto) {
		let i = 0;
		let trobat = false;
		while (i < this.route.shipments.length) {
			if (shipment.shipmentId === this.route.shipments[i].returnedShipmentId) {
				trobat = true;
				this.route.shipments.splice(i, 1);
				break;
			}
			i++;
		}
		if (!trobat)
			this.route.shipments.push(ShipmentReturnInBoundOutBoundDelegatedDto.buildFromShipmentCardDto(shipment));
	}
	public isShipmentSelected(shipment: ShipmentCardDto): boolean {
		let i = 0;
		while (i < this.route.shipments.length) {
			if (shipment.shipmentId === this.route.shipments[i].returnedShipmentId)
				return true;
			i++;
		}
		return false;
	}


	//Hem de moure la data de de fi en funció de la data d'origen
	public setOriginDateStart($event: DatePickerValueChangedEvent) {
		if (this.route && this.actualShipment) {
			this.actualShipment.originDateEnds = DatePickerComponent.translate(this.actualShipment.originDateEnds, $event);
			this.actualShipment.destinationDateStart = DatePickerComponent.translate(this.actualShipment.destinationDateStart, $event);
			this.actualShipment.destinationDateEnds = DatePickerComponent.translate(this.actualShipment.destinationDateEnds, $event);
		}
	}
	public setDestinationDateStart($event: DatePickerValueChangedEvent) {
		if (this.route && this.actualShipment) {
			this.actualShipment.destinationDateEnds = DatePickerComponent.translate(this.actualShipment.destinationDateStart, $event);
		}
	}
	public setOriginDateStartChanged($event: HDate) {
		if (this.route.type == RouteShipmentTypes.ReverseLogistics) {
			this.refreshDefaultDataByPartnerLogistics();
		}
	}

	/***
	 * Ha canviat l'adreça d'origen, estableixo els valors predeterminats d'horari
	 */
	public onChangedOriginAddress($event: SharedSelectorAddressManualAddressEvent) {
		if (this.route.type == RouteShipmentTypes.ReverseLogistics) {
			this.refreshDefaultDataByPartnerLogistics();
			return;
		}
		if ($event.address) {
			if (this.actualShipment && this.actualShipment.originAddressManualAddress.loadIntervals)
				this.actualShipment.originAddressManualAddress.loadIntervals = $event.address.loadTimetableIntervals;
		}
	}
	/***
	 * Ha canviat l'adreça d'origen, estableixo els valors predeterminats d'horari
	 */
	public onChangedDestinationAddress($event: SharedSelectorAddressManualAddressEvent) {
		if (this.route.type == RouteShipmentTypes.ReverseLogistics)
			return;
		if ($event.address) {
			if (this.actualShipment && this.actualShipment.destinationAddressManualAddress)
				this.actualShipment.destinationAddressManualAddress.unloadIntervals = $event.address.unloadTimetableIntervals;
		}
	}



	//A partir d'aquí la part d'scroll. Mare de deu! això segur que es pot encapsular
	getShowScroll() {
		this.onScroll();
		return (this.scrollHeight > this.parentHeight && this.parentScrollTop == 0);
	}
	@ViewChild("container", { read: ElementRef, static: false }) container: ElementRef;
	public scrollHeight = 0;
	public parentHeight = 0;
	public parentScrollTop = 0;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.onScroll();
	}
	@HostListener("window:scroll", ['$event'])
	doSomethingOnInternalScroll($event: Event) {
		this.onScroll();
	}
	public onScroll() {
		if (this.container != null && this.container.nativeElement != null) {
			this.scrollHeight = this.container.nativeElement.scrollHeight;
			if (this.container.nativeElement.parentElement != null) {
				this.parentHeight = this.container.nativeElement.parentElement.clientHeight;
				this.parentScrollTop = this.container.nativeElement.parentElement.scrollTop;
			}
		}
	}
	public goScrollBottom() {
		if (this.container != null && this.container.nativeElement != null) {
			if (this.container.nativeElement.parentElement != null) {
				this.container.nativeElement.parentElement.scroll({
					top: this.container.nativeElement.scrollHeight,
					behavior: 'smooth'
				});
			}
		}
	}


	private buildFormValidators() {

		let fcDSOrigenData = new FormControl(['', [Validators.nullValidator]]);
		let fcDEOrigenData = new FormControl(['', [Validators.nullValidator]]);
		let fcDSDestiData = new FormControl(['', [Validators.nullValidator]]);
		let fcDEDestiData = new FormControl(['', [Validators.nullValidator]]);

		if (this.modelFuncionament != null) {
			if (this.modelFuncionament.dataOrigenRequerida) {
				fcDSOrigenData = new FormControl('', [
					DateValidator.validate]);
				fcDEOrigenData = new FormControl('', [
					DateValidator.validate]);
			}
			if (this.modelFuncionament.dataDestiRequerida) {
				fcDSDestiData = new FormControl('', [
					DateValidator.validate]);
				fcDEDestiData = new FormControl('', [
					DateValidator.validate]);
			}
		}

		return this.fb.group({
			'partnerClientId': [null, [Validators.nullValidator]],
			'partnerProviderId': [null, [Validators.nullValidator]],
			'clientName': ['', [Validators.maxLength(255)]],
			'clientReference': ['', [Validators.maxLength(255)]],
			'originDateStart': fcDSOrigenData,
			'originDateEnds': fcDEOrigenData,
			'originTimetable': [null, [Validators.nullValidator]],
			'destinationDateStart': fcDSDestiData,
			'destinationDateEnds': fcDEDestiData,
			'destinationTimetable': [null, [Validators.nullValidator]],
			'goods': ['', [Validators.maxLength(255)]],
			'userTags': ['', [Validators.nullValidator]],
			'routeKey': ['', [Validators.nullValidator]],
			'routeTitle': ['', [Validators.nullValidator]],
			'routePlateA': ['', [Validators.nullValidator]],
			'routePlateB': ['', [Validators.nullValidator]],
			'routePlateC': ['', [Validators.nullValidator]],
			'routeReverseLogisticsPartnerId': ['', [Validators.nullValidator]],
			'routeDriverId': ['', [Validators.nullValidator]],
			'routeDriverName': ['', [Validators.nullValidator]],
			'routeDriverLegalIdentification': ['', [Validators.nullValidator]],
			'routeDriverPhone': ['', [Validators.nullValidator]],
			'routeCarrierId': ['', [Validators.nullValidator]],
			'shipmentPartnerClientId': [0, [Validators.nullValidator]],
			'shipmentPartnerProviderId': [0, [Validators.nullValidator]],
		});

	}
}
