import { Injectable } from '@angular/core';
import { AddressManualAddressDto } from '@shared/src/controllers/maps/AddressManualAddressDto';
import { ManualAddressDto } from '@shared/src/controllers/maps/ManualAddressDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { RouteShipmentTypes } from '@shared/src/enums/RouteShipmentTypes';
import { HDateHour } from '../../../datatypes/HDateHour';
import { HTags } from '../../../datatypes/HTags';
import { TimeZones } from '../../../enums/TimeZones';

@Injectable()
export class ShipmentCreateBaseDto {

	public type: RouteShipmentTypes;
	public freeReturn: boolean;
	public reverseLogisticsOriginOwn: boolean;

	public partnerClientId: number;
	public clientName: string;
	public clientReference: string;
	public partnerProviderId: number;
	
	public originAddressManualAddress: AddressManualAddressDto;
	public originDateTimeStart: HDateHour;
	public originDateTimeEnds: HDateHour;
	public originStartTimezone: TimeZones;
	public originEndTimezone: TimeZones;

	public destinationAddressManualAddress: AddressManualAddressDto;
	public destinationDateTimeStart: HDateHour;
	public destinationDateTimeEnds: HDateHour;
	public destinationStartTimezone: TimeZones;
	public destinationEndTimezone: TimeZones;

	public goods: string;
	public userTags: HTags;

	public isOwnerShipment: boolean;

	public static loadFromCardAndRouteId(wizard: ShipmentCreateBaseDto, card: ShipmentCardDto) {

		wizard.partnerClientId = card.partnerClientId;
		wizard.partnerProviderId = card.partnerProviderId;

		wizard.originAddressManualAddress = new AddressManualAddressDto();
		wizard.originAddressManualAddress.addressId = card.originAddressId;
		wizard.originAddressManualAddress.manualAddress = new ManualAddressDto();
		wizard.originAddressManualAddress.loadIntervals = card.originTimetable;
		wizard.originDateTimeStart = card.originDateTimeStart;
		wizard.originDateTimeEnds = card.originDateTimeEnds;

		wizard.destinationAddressManualAddress = new AddressManualAddressDto();
		wizard.destinationAddressManualAddress.addressId = card.destinationAddressId;
		wizard.destinationAddressManualAddress.manualAddress = new ManualAddressDto();
		wizard.destinationAddressManualAddress.unloadIntervals = card.destinationTimetable;
		wizard.destinationDateTimeStart = card.destinationDateTimeStart;
		wizard.destinationDateTimeEnds = card.destinationDateTimeEnds;

		wizard.goods = card.goods;
		wizard.userTags = card.userTags;

		wizard.isOwnerShipment = card.isOwnerShipment;
	}
}
