import { Pipe } from '@angular/core';
import { FilterDto } from './FilterDtos';

@Pipe({
	name: "orderfilterdto"
})
export class OrderFilterDtoFilter {
	transform(array: Array<FilterDto>): Array<FilterDto> {
		array.sort((a: any, b: any) => {
			return OrderFilterDtoFilter.compareAny(a, b);
		});
		return array;
	}
	public static compareAny(a: FilterDto, b: FilterDto): number {
		if (a.filterOrder < b.filterOrder)
			return -1;
		else if (a.filterOrder > b.filterOrder)
			return +1;
		else
			return 0;
	}
}