export enum RouteShipmentConditionTypes {
    MaxTemperature = "MaxTemperature",
    MinTemperature = "MinTemperature",
    MaxHR = "MaxHR",
    MinHR = "MinHR",
    DueDatetime = "DueDatetime",
    MinDateTime = "MinDateTime",
    OriginArrivalTimesIntervals = "OriginArrivalTimesIntervals",
    DestinationArrivalTimesIntervals = "DestinationArrivalTimesIntervals",
    OriginMinDateTime = "OriginMinDateTime",
    OriginMaxDateTime = "OriginMaxDateTime",
    DestinationMinDateTime = "DestinationMinDateTime",
    DestinationMaxDateTime = "DestinationMaxDateTime",
    ThermostatRunMode = "ThermostatRunMode",
    OriginLastMomentDateTime = "OriginLastMomentDateTime",
    OriginFirstMomentDateTime = "OriginFirstMomentDateTime",
    None = "None"
}