<div class="container" *ngIf="shipmentDelivery">
    <div class="baractions">
        <div class="title">{{'SHIPMENTDELIVERY.TITLE'| translate}}</div>
        <actions *ngIf="!readonly && !hideactions" [actionstrip]="actions" (clickAction)="onClickAction($event)">
        </actions>

        <div class="grup header headers">
            <div class="value lineNumber"></div>
            <div class="value quantity" [ngClass]="{'m-quantity' : useIonic}">{{'SHIPMENTDELIVERY.QUANTITY.CAPTION' |
                translate}}</div>
            <div class="value reference" [ngClass]="{'m-reference' : useIonic}">{{'SHIPMENTDELIVERY.REFERENCE.CAPTION' |
                translate}}</div>
            <div class="value description" [ngClass]="{'m-description' : useIonic}">
                {{'SHIPMENTDELIVERY.DESCRIPTION.CAPTION' | translate}}</div>
            <div class="value unitPrice" [ngClass]="{'m-unit-price' : useIonic}">{{'SHIPMENTDELIVERY.UNITPRICE.CAPTION'
                | translate}}</div>
            <div class="value totalPrice">{{'SHIPMENTDELIVERY.TOTALPRICE.CAPTION' | translate}}</div>
            <div class="value button-sub-line"></div>
        </div>
    </div>

    <div class="line" *ngIf="shipmentDelivery.lines">
        <shared-shipment-delivery-line *ngFor="let line of getLines() | sortBy:'line'; let i=index"
            [readonly]="readonly || !shipmentDelivery.canModify" [lineObject]="line" [expandedLine]="expandedLineId == line.shipmentDeliveryLineId"
            (expandedLineIdChanged)="onExpandedLine($event)"
            (refreshDeliveryLine)="onRefreshDelivery($event, line.shipmentDeliveryLineId)"
            (refreshDelivery)="onRefreshDelivery($event, null)" (onDeleteSelected)="deleteSelectedLine = $event"
            [deleteSelected]="deleteSelectedLine">
        </shared-shipment-delivery-line>
    </div>
    <div class="grup debaix">
        <div class="element">
            <div class="prompt">
                {{'SHIPMENTDELIVERY.TOTALQUANTITY.CAPTION' | translate}}:
            </div>
            <div class="total">{{shipmentDelivery.totalQuantity | number}}</div>
        </div>
        <div class="element">
            <div class="prompt">{{'SHIPMENTDELIVERY.TOTALKG.CAPTION' | translate}}:</div>
            <div class="total">{{shipmentDelivery.totalKg | number}}</div>
        </div>
        <div class="element">
            <div class="prompt">{{'SHIPMENTDELIVERY.TOTALPRICE.CAPTION' | translate}}:</div>
            <div class="total">{{shipmentDelivery.totalPrice | number}}</div>
        </div>
        <div class="element">
            <div class="prompt">{{'SHIPMENTDELIVERY.TOTALM3.CAPTION' | translate}}:</div>
            <div class="total">{{shipmentDelivery.totalM3 | number}}</div>
        </div>
        <div class="element">
            <div class="prompt">{{'SHIPMENTDELIVERY.TOTALPALLET.CAPTION' | translate}}:</div>
            <div class="total">{{shipmentDelivery.totalPallet | number}}</div>
        </div>
        <div class="element">
            <div class="prompt">{{'SHIPMENTDELIVERY.TOTALLINES.CAPTION' | translate}}:</div>
            <div class="total">{{shipmentDelivery.totalLine | number}}</div>
        </div>
    </div>

</div>
