<div class="container">
    <textarea-component class="col-x" [(ngModel)]="value.additionalDriversPhones"
        placeholder="{{'ROUTE.ADDITIONALDRIVERPHONES.CAPTION' | translate }}" name="additoinalDriverPhones"
        autocomplete="off" [readonly]="readonly" (valueChanged)="additoinalDriverPhonesChange.next($event)">
    </textarea-component>

    <div class="uuid">
      <div class="title-uuid">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.LINK.TITLE' | translate}}</div>
      <button class="copy icon" (click)="copy()"
          matTooltip="{{ 'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.TOOLTIP.COPY' | translate}}">
          <a>{{value.writeUuid}}</a>
          <i class="fa fa-clone" aria-hidden="true"></i>
      </button>
      <button class="link icon" (click)="resendAll()"
          matTooltip="{{ 'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.TOOLTIP.RESEND' | translate}}">
          <i class="fa fa-share" aria-hidden="true"></i>
          <span class="link-caption">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.LINK.RESEND.CAPTION' | translate}}</span>
      </button>
      <button class="link icon" (click)="go()"
          matTooltip="{{ 'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.TOOLTIP.GO' | translate}}">
          <i class="fa fa-share-alt" aria-hidden="true"></i>
          <span class="link-caption">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.LINK.NEWSCREEN.CAPTION' | translate}}</span>
      </button>
      <button class="link icon" (click)="renewUuid()"
          matTooltip="{{ 'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.TOOLTIP.RENEW' | translate}}">
          <i class="fa fa-refresh" aria-hidden="true"></i>
          <span class="link-caption">{{'SHARED.ROUTE.ITEM.EDIT.MAINTENANCE.ADDITIONALDRIVERPHONES.LINK.REFRESH.CAPTION' | translate}}</span>
      </button>
  </div>

</div>