import {Injectable} from '@angular/core';

@Injectable()
export class RouteContainerDataDto {
    public routeId: number;
    public containerNumber: string;
    public ARC: string;
    public DRE: string;
    public tare: string;
    public seal: string;

    public static build(routeId: number, containerNumber: string, ARC: string, DRE: string, tare: string, seal: string):RouteContainerDataDto{
        let data = new RouteContainerDataDto();
        data.routeId = routeId;
        data.containerNumber = containerNumber;
        data.ARC = ARC;
        data.DRE = DRE;
        data.tare = tare;
        data.seal = seal;
        return data;
    }
}