import { TenantProductDto } from '../tenants/TenantProductDto';
import { MenuOptionsDto } from './MenuOptionsDto';

export class HomeDto {
    public menu: MenuOptionsDto;
    public upToDatePayment: boolean;
    public phoneVerificationRequired: boolean;
    public emailVerificationRequired: boolean;
    public registerPending: boolean;//Dto userregister --> si ve a nul es que no esta pendent
    public products: TenantProductDto[];
    public paymentWarning: boolean = false;
    public paymentBlocking: boolean = false;
    public sendPosition: boolean = false;

    public requestMoreDrivers: boolean;
    public requestMoreCompanies: boolean;

    public hasCarrier: boolean = false;
    public hasShipper: boolean = false;
    public hasLogisticOperator: boolean = false;
    public hasDriver: boolean = false;
    public hasSecondDriver: boolean = false;
    public hasInspector: boolean = false;
    public hasHealthCertifier: boolean = false;
    public hasClientPreferent = false;
    public hasClient = false;
    public hasProvider: boolean = false;
    public partnerStatus: string;
}
