
export enum MessageCodes {
    //Msn Error
    HTTP401 = 'HTTP401',
    HTTP500 = 'HTTP500',
    HTTP404 = 'HTTP404',
    HTTP422 = 'HTTP422',

    //Msn Success
    HTTP200 = 'HTTP200'
}