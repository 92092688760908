import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HourIntervalValidator } from '../../form/validations/HourIntervalValidator';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { HomeController } from '@shared/src/controllers/home/home.controller';


@Component({
  selector: 'hourinterval-picker',
  templateUrl: './hourinterval-picker.component.html',
  styleUrls: ['./hourinterval-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: HourIntervalPickerComponent, multi: true }]
})

export class HourIntervalPickerComponent extends ValueAccessorValidatedBase<string, HHourInterval> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormHourInterval = this.fb.group({
      hourintervalvalidator: ['', validators, asyncvalidators]
    });
  }

  @Output() valueScreenChange: EventEmitter<string> = new EventEmitter();
  isValid(value: string): boolean {
    this.valueScreenChange.next(value);
    return HourIntervalValidator.isValid(value);
  }
  showClearButton(): boolean {
    if (!this.value)
      return false;

    if (this.value == "00:00-00:00")
      return false;

    if (this.readonly)
      return false;

    return true;
  }


  public rFormHourInterval: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }


  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HHourInterval> = new EventEmitter();

  public identifier = 'hourinterval-picker-' + identifier++;

  externalValue(value: string): HHourInterval {
    if (!value)
      return HHourInterval.empty();
    let hintervals: HHourInterval = HHourInterval.buildstr(value);
    return hintervals;
  }
  internalValue(value: HHourInterval): string {

    let t: HHourInterval = HHourInterval.buildByDto(value);
    return HHourInterval.toHourIntervalsString(t);
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;