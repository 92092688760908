export class Route_eCMRAddressDTO {
	public id: number;
	public key: string;
	public name: string;
	public formattedAddress: string;
	public contactPhone: string;
	public reset: boolean;

	public static buildReset(): Route_eCMRAddressDTO {
		let result: Route_eCMRAddressDTO = new Route_eCMRAddressDTO();
		result.reset = true;
		return result;
	}

	public static build(addressId: number, key: string, name: string, formattedAddress: string, contactPhone: string): Route_eCMRAddressDTO {
		let result: Route_eCMRAddressDTO = new Route_eCMRAddressDTO();
		result.id = addressId;
		result.key = key;
		result.name = name;
		result.formattedAddress = formattedAddress;
		result.contactPhone = contactPhone;
		return result;
	}
}
