import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class RoleTypesEntitySelectorDto extends EntitySelectorDto {

    public static new(activeFilterKeys: Array<String>): RoleTypesEntitySelectorDto {
        let result: RoleTypesEntitySelectorDto = new RoleTypesEntitySelectorDto();
		result.loadData(EntitysSelectors.RoleTypes, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "system/role";
	}

	public getUrlBack(): string {
		return "/maintenance/system/role";
	}

}