import { HDate } from "../../datatypes/HDate";
import { BaseController } from "../base.controller";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AlertService } from "../../services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { SocketController } from "@shared/src/controllers/socket/socket.controller";
import { DockDto } from "@shared/src/dtos/address/dock/DockDto";
import { AddressDaySettingPlanningDockDto } from "@shared/src/dtos/address/dock/AddressDaySettingPlanningDockDto";
import { AddressTimetableIntervalDockDto } from "@shared/src/dtos/address/dock/AddressTimetableIntervalDockDto";

@Injectable({
    providedIn: "root",
})
export class DocksController extends BaseController {

    constructor(
        protected http: HttpClient,
        protected cookieService: CookieService,
        @Inject("AlertService") protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject("LoadingService") protected loadingService: LoadingService,
        protected router: Router,
        private socketController: SocketController
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    private base = "/dock";

    public getDocksByAddress(addressId: number): Observable<Array<DockDto>> {
        return this.get(this.base + "/" + addressId);
    }

    public getDocksByAddressActives(addressId: number): Observable<Array<DockDto>> {
        return this.get(this.base + "/actives/" + addressId);
    }

    public deleteDock(addressId: number, dockId: number): Observable<Boolean> {
        return this.delete(this.base + "/" + addressId + "/" + dockId);
    }

    public modifyDock(addressId: number, setting: DockDto): Observable<DockDto> {
        return this.post(this.base + "/" + addressId + "/" + setting.dockId, setting, "modifyDock");
    }

    public createDock(addressId: number, setting: DockDto): Observable<DockDto> {
        return this.post(this.base + "/" + addressId, setting, "createDock");
    }

    public openDock(addressId: number, dock: DockDto): Observable<boolean> {
        return this.post(this.base + "/opendock/" + addressId, dock, "openDock");
    }

    public closeDock(addressId: number, dock: DockDto): Observable<boolean> {
        return this.post(this.base + "/closedock/" + addressId, dock, "closeDock");
    }

    public getDocksToPhase(phaseId: number): Observable<Array<DockDto>> {
        return this.get(this.base + "/phase/" + phaseId);
    }

    public setDockIdToPhaseId(phaseId: number, dockId: number): Observable<Boolean> {
        return this.post(this.base + "/set/dockId/" + dockId + "/phaseId/" + phaseId, {}, "setDockIdToPhaseId");
    }

    public setDockToPhaseId(phaseId: number, dockSkin: string): Observable<Boolean> {
        return this.post(this.base + "/set/dock/phaseId/" + phaseId, dockSkin, "setDockToPhaseId");
    }

    public removeDockToPhaseId(phaseId: number): Observable<Boolean> {
        return this.post(this.base + "/removedock/phaseId/" + phaseId, {}, "removeDockToPhaseId");
    }

    public getAddressDayPlanningDocks(addressId: number, day: HDate, ambitId: number, busy: boolean): Observable<AddressDaySettingPlanningDockDto> {
        if (busy)
            return this.getBusy(this.base + "/" + addressId + "/planningdocks/" + HDate.getDateStringRequest(day) + "/" + ambitId);
        return this.get(this.base + "/" + addressId + "/planningdocks/" + HDate.getDateStringRequest(day) + "/" + ambitId);
    }

    public getAddressDayPlanningDocksInterval(addressId: number, day: HDate, ambitId: number, intervalId: number, busy: boolean): Observable<AddressTimetableIntervalDockDto> {
        if (busy)
            return this.getBusy(this.base + "/" + addressId + "/planningdocks/" + HDate.getDateStringRequest(day) + "/" + ambitId + "/intervalid/" + intervalId);
        return this.get(this.base + "/" + addressId + "/planningdocks/" + HDate.getDateStringRequest(day) + "/" + ambitId + "/intervalid/" + intervalId);
    }
}
