import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';


/**
 * Validador de temps, permet valors amb format ##:##
 */

@Directive({
  selector: '[validateHourInterval][ngModel],[validateHourInterval][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => HourIntervalValidator), multi: true }
  ]
})
export class HourIntervalValidator implements Validator {

  public constructor() { }

  public static validate(control: AbstractControl): ValidationErrors | null {
    return HourIntervalValidator.validateControl(control, false);
  }
  public static validateWithNullValue(control: AbstractControl): ValidationErrors | null {
    return HourIntervalValidator.validateControl(control, true);
  }

  public static validateControl(control: AbstractControl, withnullinterval: boolean): ValidationErrors | null {
    if (!control)
      return HourIntervalValidator.getInvalidResult();

    let value = control.value;
    if (!withnullinterval && (!value || value == '00:00-00:00'))
      return null;

    if (!HourIntervalValidator.isValid(value))
      return HourIntervalValidator.getInvalidResult();

    if (withnullinterval && HHourInterval.isNullOrNullValue(value))
      return HourIntervalValidator.getInvalidResult();
    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    if (value instanceof HHourInterval)
      return true;

    if (value._Intervals != undefined)
      return true;

    if (typeof value === 'string' || value instanceof String)
      if (HHourInterval.isValidFormat(value))
        return true;
    return false;
  }


  public validate(c: AbstractControl): ValidationErrors | null {
    return HourIntervalValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateHourInterval: false
    };
  }
}