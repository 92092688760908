import { MessageTypes } from "./MessageTypes";

/*
Enumeració només de front
*/
export enum AlertTypes {
    Success = "Success",
    SucessNotify = "SucessNotify",
    Error = "Error",
    Info = "Info",
    Warning = "Warning",
    PopUp = "PopUp"
}

export abstract class AlertTypesClass {
    public static getAlertType(messagetype: MessageTypes): AlertTypes {
        if (!messagetype)
            return undefined;
        switch (messagetype) {
            case MessageTypes.Error:
                return AlertTypes.Error;
            case MessageTypes.Info:
                return AlertTypes.Info;
            case MessageTypes.Success:
                return AlertTypes.Success;
            case MessageTypes.Warning:
                return AlertTypes.Warning;
            case MessageTypes.SuccessNotify:
                return AlertTypes.SucessNotify;
            case MessageTypes.PopUp:
                return AlertTypes.PopUp;
        }
        return undefined;
    }
}