export class HImage {

    static parseByteArrayToPng(byteArray: any[]): string {
        let binary = '';
        const len = byteArray.length;
        for (let i = 0; i < len; i++)
            binary += String.fromCharCode(byteArray[i]);
        return 'data:image/png;base64,' + btoa(binary);
    }
    static toPng(byteArray: any[]): string {
        return 'data:image/png;base64,' + byteArray;
    }
    static toBase64ToArrayBuffer(base64: any): any {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
}