import { Component, EventEmitter, Inject, Input, Output, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { HLong } from '@shared/src/datatypes/HLong';
import { RouteCMRDto } from '@shared/src/dtos/route/cmr/RouteCMRDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RouteCMRQrDto } from '@shared/src/dtos/route/cmr/qr/RouteCMRQrDto';
import { QRCodeController } from '@shared/src/controllers/qrcode/qrcode.controller';
import { RoutePartCMRQrDto } from '@shared/src/dtos/route/cmr/qr/RoutePartCMRQrDto';
import { HString, ReturnMaintenanceController } from '@shared/src/public-api';

@Component({
    selector: 'shared-route-part-cmr-list',
    templateUrl: './shared.route.part.cmr.list.component.html',
    styleUrls: ['./shared.route.part.cmr.list.component.scss']
})
export class SharedRoutePartCMRListComponent implements OnInit {

    public useIonic: boolean = false;
	public iReadonly: boolean = false;

	constructor(
		public qRCodeController: QRCodeController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

    @Output() selectItem: EventEmitter<RoutePartCMRDto> = new EventEmitter();

	@Input() public readonly: boolean;

	@Input() public routeId: number;

	public _routeCMR: RouteCMRDto;
    @Input()
    set routeCMR(value: RouteCMRDto) {
        if (this._routeCMR === value)
            return;

        this._routeCMR = value;
        this.buildOptions();
		this.validateSelectedRoutePartCMR();
    }
    get routeCMR(): RouteCMRDto {
        return this._routeCMR;
    }
     
	ngOnInit(): void {
	}

	public getRoutePartCMRs(){
		if (this.routeCMR != null && this.routeCMR.routePartCMRs != null)
			return this.routeCMR.routePartCMRs;
		return new Array();
	}

	public selectedRoutePartCMRId: number;
	public _selectedRoutePartCMR: RoutePartCMRDto;
    @Input()
    public set selectedRoutePartCMR(value: RoutePartCMRDto) {
        if (this._selectedRoutePartCMR === value)
            return;

        this._selectedRoutePartCMR = value;
        if (this.routeCMR != null && this.routeCMR.routePartCMRs != null && value != null) {
            this.routeCMR.routePartCMRs.forEach((p, i) => {
                if (p.routePartCMRId === value.routePartCMRId)
                    this.routeCMR.routePartCMRs[i] = value;
            });
        }
    }

    selectRoutePartCMR(value: RoutePartCMRDto) {
        this._selectedRoutePartCMR = value;
        this.selectedRoutePartCMRId = value != null ? value.routePartCMRId : null;
        this.selectItem.next(value);
    }

    validateSelectedRoutePartCMR() {
        if (this.routeCMR == null || HLong.isNullOrNullLong(this.routeId) || this.routeCMR.routeId != this.routeId){
            this.selectedRoutePartCMR = null;
            return;
        }

        if(this.selectedRoutePartCMR == null)
            return;
        
        if (this.routeId != this.selectedRoutePartCMR.routeId){
			this.selectedRoutePartCMR = null;
            return;
        }

        if (this.routeCMR != null && this.routeCMR.routePartCMRs != null) {
            let trobat = false;
            this.routeCMR.routePartCMRs.forEach(p => (trobat = (trobat || (this.selectedRoutePartCMR != null && p.routePartCMRId === this.selectedRoutePartCMR.routePartCMRId))));
            if (!trobat) {
                this.selectedRoutePartCMR = null;
            }
        }
    }

    public options: OptionsStripDto;
    public selectedOption: OptionDto = null;
    public buildOptions() {
        if (this.options)
            return;

        if(this._routeCMR == null)
            return;
        
        this.options = new OptionsStripDto();
        this.options.add(OptionDto.build('CMR', 'ROUTECMR.LIST.CMR.TITLE', null, null, 1, -1, 1, true, false));
        this.options.add(OptionDto.build('QR', 'ROUTECMR.LIST.QR.TITLE', null, null, 1, -1, 1, false, false));
        this.onClickOption(this.options.getActualSelected());
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option != null)
            this.selectedOptionId = option.optionId;
        else
            this.selectedOptionId = "";

        if(this.selectedOptionId == "CMR"){
            
        } else if(this.selectedOptionId == "QR"){
            this.loadQRCodes();
        }
    }

    public showDocumentQRCode: boolean;
 
  public qrDto: RouteCMRQrDto = new RouteCMRQrDto();
  loadQRCodes() {
    if (HLong.isNullOrNullLong(this.routeId))
      return;

    this.qRCodeController.getQRCodeCMRByRouteId(this.routeId).subscribe(data => {
      if (data) {
        this.qrDto = data;
        if(this.qrDto != null){
            if (this.qrDto.qrs != null && this.qrDto.qrs.length > 1 && this.qrDto.qr && this.qrDto.qr.fileBytes != null)
                this.qrDto.formatDocumentQRCode = 'data:image/' + this.qrDto.qr.fileType + ';base64,' + this.qrDto.qr.fileBytes;
            if (this.qrDto.qrs != null){
                this.qrDto.qrs.forEach(itemQR => {
                    if (itemQR.qr && itemQR.qr.fileBytes != null)
                        itemQR.formatDocumentQRCode = 'data:image/' + itemQR.qr.fileType + ';base64,' + itemQR.qr.fileBytes;
                });
            }
        }
      }
    });
  }

  onClickQR(){
    if(this.qrDto == null)
        return;

    if (this.routeId > 0 && this.qrDto != null && !HString.isNullOrNullString(this.qrDto.uuidQR))
        this.homeController.routeToExworkCMRUUIDQR(this.qrDto.uuidQR);   
  }

  onClickPartQR(item: RoutePartCMRQrDto){
    if(item == null)
        return;

    if (this.routeId > 0 && item != null && !HString.isNullOrNullString(item.uuidQR))
        this.homeController.routeToExworkCMRUUIDQR(item.uuidQR);   
  }
}
