import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeController } from '../../../controllers/home/home.controller';
import { PartnerTypes } from '@shared/src/enums/PartnerTypes';
import { PartnerDetailComponent } from '../../link/detail/partner.detail.component';
import { PartnerDetailDto } from '@shared/src/dtos/link/detail/PartnerDetailDto';

@Component({
  selector: 'register-make-yourself-visible',
  templateUrl: './register-make-yourself-visible.component.html',
  styleUrls: ['./register-make-yourself-visible.component.scss']
})
export class RegisterMakeYourselfVisibleComponent implements OnInit {

  public generalClass: string = "desktop";

  public linkType: PartnerTypes = PartnerTypes.company;
  public registerFormGroup: FormGroup;

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();

      this.registerFormGroup = PartnerDetailComponent.buildFromCompany(this.fb);
  }

  ngOnInit() { }

  @Input()
  public newLink: PartnerDetailDto = new PartnerDetailDto();

  @Output()
  public onClickNext: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onClickAdd: EventEmitter<PartnerDetailDto> = new EventEmitter();

  @Input()
  public countlinks: number;

  @Input() 
  public pendigResponse: boolean;
  
  next() {
    if(this.pendigResponse)
      return;
    this.onClickNext.next(true);
  }

  add() {
    if(this.pendigResponse)
      return;
    this.onClickAdd.next(this.newLink);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
