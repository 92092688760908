export enum HardmanProducts {
    towercontrol = 'towercontrol',
    slots = 'slots',
    docks = "docks",
    gates = 'gates',
    security = 'security',
    international = "international",
    lite = 'lite',
    driver = 'driver',
    shipper = 'shipper',
    carrier = 'carrier',
    chat = "chat",
    none = 'none'
}