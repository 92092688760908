import { HDate, HDateHour, HTime } from "@shared/src/public-api";

export class AccessControlStatusDto {
    public accessControlId: number;
    public accessControlKey: string;
    public description: string;

    public addressId: number;
    public surname: string;
    public marginParkingEnter: HDateHour;
    public marginParkingExit: HDateHour;
    public marginLoadingEnter: HDateHour;
    public marginLoadingExit: HDateHour;

    public marginParkingEnterDate: HDate;
    public marginParkingExitDate: HDate;
    public marginLoadingEnterDate: HDate;
    public marginLoadingExitDate: HDate;

    public marginParkingEnterShowDate: boolean;
    public marginParkingExitShowDate: boolean;
    public marginLoadingEnterShowDate: boolean;
    public marginLoadingExitShowDate: boolean;

    public isDelayed: boolean;

    public delay: HTime;
    public delayHDateHour: HDateHour;
    public delayMoll: HTime;
    public delayParking: HTime;

    public gates: Array<AccessControlStatusGateDTO>;

    public order: string;

    static wellcome(d: AccessControlStatusDto[], now: HDateHour): AccessControlStatusDto[] {

        let nowDate: HDate = HDateHour.getDate(now);
        d.forEach(e => {
            e.marginParkingEnterShowDate = !HDate.equals(HDateHour.getDate(e.marginParkingEnter), nowDate);
            e.marginParkingExitShowDate = !HDate.equals(HDateHour.getDate(e.marginParkingExit), nowDate);
            e.marginLoadingEnterShowDate = !HDate.equals(HDateHour.getDate(e.marginLoadingEnter), nowDate);
            e.marginLoadingExitShowDate = !HDate.equals(HDateHour.getDate(e.marginLoadingExit), nowDate);

            e.marginParkingEnterDate = HDateHour.getDate(e.marginParkingEnter);
            e.marginParkingExitDate = HDateHour.getDate(e.marginParkingExit);
            e.marginLoadingEnterDate = HDateHour.getDate(e.marginLoadingEnter);
            e.marginLoadingExitDate = HDateHour.getDate(e.marginLoadingExit);

        });


        return d;
    }
}

export class AccessControlStatusGateDTO {
    public gateKey: string;
    public gateName: string;
    public order: number;
    public count: number;
}