<div class="taula">
  <table id="taula" class="hardman" #container *ngIf="showTelemetry">
    <tr class="header">
      <th *ngIf="showTelemetry" class="maxwidth">{{ "TELEMETRYTABLE.MOMENT" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.LATITUDE" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.LONGITUDE" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.ALTITUDE" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.SPEED" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.TEMPERATURE" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.ODOMETER" | translate }}</th>
      <th *ngIf="showTelemetry">{{ "TELEMETRYTABLE.TOTALODOMETER" | translate }}</th>
      <th *ngFor="let element of dimensionNames">
        {{ element }}
      </th>
    </tr>

    <tr *ngFor="let element of listTelemetry | sortBy:'-moment' ; let i=index"
      (click)="onMarkerClick(element.telemetryId)"
      [ngClass]="{'selectedMarker': selectedTelemetry!= null && element.moment == selectedTelemetry.moment}">
      <td *ngIf="showTelemetry" class="blank string maxwidth">{{element.moment | datehourwithsecondsnotz}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.latitude}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.longitude}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.altitude}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.speed}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.temperature}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.odometer}} </td>
      <td *ngIf="showTelemetry" class="blank number">{{element.totalOdometer}} </td>
      <td *ngFor="let dimension of dimensionNames" class="blank"
        [ngClass]="getValueType(element.telemetryId ,dimension)">{{ getDimensionValue(element.telemetryId, dimension) }}
      </td>
    </tr>
  </table>

  <table class="hardman" *ngIf="showDimensions && !showTelemetry">
    <tr>
      <th>{{ "TELEMETRYTABLE.MOMENT" | translate }}</th>
      <th *ngFor="let element of dimensionNames">
        {{ element }}
      </th>
    </tr>
    <tr *ngFor="let element of listDimensionProcessat | keyvalue" (click)="onMarkerClick(element.key)">
      <td class="blank string">{{ getMoment(element.key) | datehourwithsecondsnotz }}</td>
      <td *ngFor="let dimension of dimensionNames" class="blank" [ngClass]="getValueType(element.key ,dimension)">
        {{ getDimensionValue(element.key, dimension) }}</td>
    </tr>
  </table>
</div>

<div class="botoScroll scrollTop" (click)="goScrollTop()" [ngClass]="{'scrollVisible': getShowScrollTop()}">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</div>
<div class="botoScroll" (click)="goScrollBottom()" [ngClass]="{'scrollVisible': getShowScrollBottom()}">
  <i class="fa fa-arrow-down" aria-hidden="true"></i>
</div>