import { Injectable, Pipe, PipeTransform, Inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment-timezone';
import { SecurityController } from '../controllers/security/security.controller';
import { HHourInterval } from "../datatypes/HHourInterval";
const moment = moment_;

@Pipe({
    name: 'interval'
})
@Injectable()
export class IntervalFilter implements PipeTransform {

    constructor(private translate: TranslateService, @Inject('SecurityController') protected securityController: SecurityController) {
    }

    public transform(value: HHourInterval): string {
        if (value == null)
            return "---";

        value = HHourInterval.buildByDto(value);
        if (HHourInterval.isNullOrNullInterval(value))
            return "---";

        return HHourInterval.toScreenString(value);
    }
}