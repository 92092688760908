export enum GeneralStatus {
    promised = "promised",
    canceled = "canceled",
    obsolete = "obsolete",
    inprogress = "inprogress",
    done = "done",
    incomplete = "incomplete",
	arrived = "arrived",
    loaded = "loaded",
    loadedunloaded = "loadedunloaded",
    unloaded = "unloaded",
    none = "none"
}
