import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { OpenDocument } from '@shared/src/public-api';

@Component({
  selector: 'shared-documentaryreview-item-documents-component',
  templateUrl: './shared.documentaryreview.item.documents.component.html',
  styleUrls: ['./shared.documentaryreview.item.documents.component.scss'],
})
export class SharedDocumentaryReviewItemDocumentsComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }
  
  @Input() public readonly: boolean = false;

  @Input() public doLoadDocuments: boolean = true;
  public _parentObject: DocumentParentObjectDto;
    @Input()
    set parentObject(value: DocumentParentObjectDto) {
        if (this._parentObject === value)
            return;
        this._parentObject = value;
    }
    get parentObject(): DocumentParentObjectDto {
        return this._parentObject;
  }

  public numDocuments: number = 0;
  public __documents: DocumentViewerTypeDto[];
  @Input()
  set documents(value: DocumentViewerTypeDto[]) {
    if (this.__documents === value)
      return;
    this.__documents = value;

    this.numDocuments = 0;
    if(this.__documents != null) {
      this.__documents.forEach(element => {
          this.numDocuments += element.documents.length;
      });
    }
  }
  get documents(): DocumentViewerTypeDto[] {
    return this.__documents;
  }

  ngOnInit() {

  }
  
  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  public onDataRefresh(value: boolean) {
    this.dataRefresh.next(value);
  }

}
