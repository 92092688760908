import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { enumValidator } from '@shared/src/components/core/form/validations/AutoCompleteValidator';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ShipmentDeliveryController } from '@shared/src/controllers/route/delivery/shipmentdelivery.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { HTags } from '@shared/src/datatypes/HTags';
import { ShipmentDeliveryDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryDto';
import { ShipmentDeliveryLineDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryLineDto';
import { PalletTypes } from '@shared/src/enums/PalletTypes';
import { TagsValidator } from '@shared/src/public-api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'shared-shipment-delivery-line',
    templateUrl: './shared.shipment.delivery.line.component.html',
    styleUrls: ['./shared.shipment.delivery.line.component.scss']
})
export class SharedShipmentDeliveryLineComponent implements OnInit {

    @Output() refreshDelivery: EventEmitter<ShipmentDeliveryDto> = new EventEmitter<ShipmentDeliveryDto>();
    @Output() refreshDeliveryLine: EventEmitter<ShipmentDeliveryDto> = new EventEmitter<ShipmentDeliveryDto>();
    @Output() expandedLineIdChanged: EventEmitter<number> = new EventEmitter<number>();
    public lineChanged = new Subject<number>();
    public rFormDeliveryLine: FormGroup;

    @Input() public readonly: boolean;

    enumPalletTypes = PalletTypes;

    public useIonic: boolean = false;

    constructor(private fb: FormBuilder, private shipmentDeliveryController: ShipmentDeliveryController,
        @Inject("HomeController") protected homeController: HomeController) {
        this.rFormDeliveryLine = fb.group({
            'quantity': ['', [Validators.nullValidator]],
            'reference': ['', [Validators.maxLength(15)]],
            'description': ['', Validators.maxLength(255)],
            'unitPrice': ['', Validators.nullValidator],
            'totalPrice': ['', Validators.nullValidator],
            'kg': ['', Validators.min(0)],
            'wDimension': ['', Validators.min(0)],
            'yDimension': ['', Validators.min(0)],
            'hDimension': ['', Validators.min(0)],
            'm3': ['', Validators.min(0)],
            'palletCount': ['', [Validators.nullValidator]],
            'palletType': [PalletTypes.None, [Validators.required, enumValidator(this.enumPalletTypes, false)]],
            'packageId': [null, [Validators.nullValidator]],
            'userTags': ['', TagsValidator.validate],
        });

        if (homeController.useIonic) this.useIonic = homeController.useIonic();
    }

    private _lineObject: ShipmentDeliveryLineDto;

    public get lineObject(): ShipmentDeliveryLineDto {
        return this._lineObject;
    }

    @Input()
    public set lineObject(value: ShipmentDeliveryLineDto) {
        if (this._lineObject === value)
            return;

        if (value)
            this.packageFixedFilterKeys = ["S:" + value.shipmentId];
        else
            this.packageFixedFilterKeys = [];

        this._lineObject = value;
        this.userTags = this._lineObject.userTags;
    }

    ngOnInit(): void {

        this.lineChanged
            .pipe(
                // Time in milliseconds between key events
                debounceTime(1000))
            // subscription for response
            .subscribe(() => {
                if (this._lineObject) {

                    if (this.readonly)
                        return;

                    if (HLong.isNullOrNullLong(this.lineObject.shipmentDeliveryId) || HLong.isNullOrNullLong(this.lineObject.shipmentDeliveryLineId)) {
                        this.shipmentDeliveryController.addShipmentDeliveryLine(this.lineObject)
                            .subscribe((data: ShipmentDeliveryDto) => {
                                if (data)
                                    this.refreshDeliveryLine.next(data);
                            });
                    } else {
                        console.log(this.lineObject);
                        this.shipmentDeliveryController.modifyShipmentDeliveryLine(this.lineObject)
                            .subscribe((data: ShipmentDeliveryDto) => {
                                if (data)
                                    this.refreshDeliveryLine.next(data);
                            });
                    }
                }
            });
    }

    public valueChangedEvent() {
        this.lineChanged.next();
    }

    public userTags: HTags;
    public userTagsChangedEvent(value: HTags) {
        this.userTags = value;
        if (this.lineObject != null && !HTags.equals(this.lineObject.userTags, this.userTags)) {
            this.lineObject.userTags = this.userTags;
            this.valueChangedEvent();
        }
    }

    private _deleteSelected: ShipmentDeliveryLineDto;
    @Input() public set deleteSelected(value: ShipmentDeliveryLineDto) {
        if (this._deleteSelected === value)
            return;
        this._deleteSelected = value;
    }
    public get deleteSelected(): ShipmentDeliveryLineDto {
        return this._deleteSelected;
    }

    private _expandedLine: boolean;
    @Input() public set expandedLine(value: boolean) {
        if (this._expandedLine === value)
            return;
        this._expandedLine = value;
    }
    public get expandedLine(): boolean {
        return this._expandedLine;
    }

    onExpandedLine() {
        this.expandedLine = !this.expandedLine;
        if (this.lineObject) {
            if (this.expandedLine)
                this.expandedLineIdChanged.next(this.lineObject.shipmentDeliveryLineId);
            else
                this.expandedLineIdChanged.next(0);
        }
    }


    @Output() onDeleteSelected: EventEmitter<ShipmentDeliveryLineDto> = new EventEmitter<ShipmentDeliveryLineDto>();
    public onDeleteSelectedEvent(value: ShipmentDeliveryLineDto) {
        this.onDeleteSelected.next(value);
    }

    showDeleteButton(): boolean {
        return !this.deleteSelected || (this.lineObject != null && this.deleteSelected.shipmentDeliveryLineId != this.lineObject.shipmentDeliveryLineId);
    }

    onDeleteLine() {

        if (this.readonly)
            return;
        this.shipmentDeliveryController.deleteShipmentDeliveryLine(
            this.lineObject).subscribe((data: ShipmentDeliveryDto) => {
                if (data) {
                    this._lineObject = null;
                    this.refreshDelivery.next(data);
                }
            });
    }

    public packageFixedFilterKeys: Array<string> = [];

    pretty(data) {
        return JSON.stringify(data);
    }
}
