<!-- color-picker.component.html!-->
<div class="container" [formGroup]="rFormColor">
    <div class="line">
        {{ placeholder }}
    </div>
    <div class="line">
        <input class="colorpicker" [id]="identifier" [(ngModel)]="value" [(colorPicker)]="value"
            (colorPickerChange)="onChange($event)" formControlName="dtpvalidator" ngDefaultControl [readonly]="readonly"
            [style.color]="value" [style.background]="value" [style.width.px]=35 [style.height.px]=35
            [style.color]="getColor()" [cpPosition]="position" (cpToggleChange)="colorPickerOpen()" (keydown.enter)="keyEnter($event)">
        <div class="avatars" *ngIf="showavatars">
            <avatar [avatar]="avatarvalue" [width]=75 [height]=75></avatar>
            <avatar [avatar]="avatarvalue" [width]=50 [height]=50></avatar>
            <avatar [avatar]="avatarvalue" [width]=25 [height]=25></avatar>
        </div>
    </div>
</div>