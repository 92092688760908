import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterRemoveRepeat'
})
export class FilterRemoveRepeat implements PipeTransform {
    transform(items: any[], itemsAlternatius: any[], propertyName: string): any[] {
        if (!items || !itemsAlternatius || !propertyName || propertyName == null || propertyName == "") {
            return items;
        }
        return items.filter((x) =>
            !itemsAlternatius.some((y) => x[propertyName] === y[propertyName]));
    }


}