import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { TelemetryDto } from "./TelemetryDto";

export class TelemetryDetail {

    public altitude: number;
    public latitude: number;
    public longitude: number;
    public moment: HDateHour;
    public startMoment: HDateHour;
    public lastMoment: HDateHour;
    public temperature: number;
    public speed: number;
    public count: number;
    public index: number;
    public maxAltitude: number;
    public minAltitude: number;
    public maxLatitude: number;
    public minLatitude: number;
    public maxLongitude: number;
    public minLongitude: number;
    public maxTemperature: number;
    public minTemperature: number;
    public maxSpeed: number;
    public minSpeed: number;

    public acumSpeed: number = 0;
    public acumTemperature: number = 0;
    public acumAltitude: number = 0;
    public acumLatitude: number = 0;
    public acumLongitude: number = 0;

    public telemetry: TelemetryDto;


    public static buildWithHours(index: number, start: HDateHour, end: HDateHour, t: TelemetryDto) {
        let result: TelemetryDetail = new TelemetryDetail();
        result.index = index;
        result.moment = start;
        result.startMoment = start;
        result.lastMoment = end;
        result.telemetry = t;
    }

    public static build(t: TelemetryDto, index: number) {
        let result: TelemetryDetail = new TelemetryDetail();
        result.moment = t.moment;
        result.latitude = t.latitude;
        result.longitude = t.longitude;
        result.altitude = t.altitude;
        result.temperature = t.temperature;
        result.speed = t.speed;
        result.count = 1;
        result.startMoment = result.moment;
        result.lastMoment = result.moment;
        result.index = result.index;
        result.telemetry = t;
        return result;
    }

    public static buildByTime(index: number, start: HDateHour, end: HDateHour) {        
        let result: TelemetryDetail = new TelemetryDetail();
        result.index = index;
        result.moment = start;
        result.startMoment = start;
        result.lastMoment = end;
        return result;
    }
    public static buildByDistance(startLat: number, endLat: number, startLong: number, endLong: number) {
        let result: TelemetryDetail = new TelemetryDetail();

        result.minLatitude = startLat;
        result.minLongitude = startLong;
        result.maxLatitude = endLat;
        result.maxLongitude = endLong;

        return result;
    }

    public addWithMoment(detail: TelemetryDetail, t: TelemetryDto) {
        TelemetryDetail.add(detail, t);

        detail.startMoment = HDateHour.min(detail.startMoment, t.moment);
        detail.lastMoment = HDateHour.max(detail.lastMoment, t.moment);
        detail.moment = HDateHour.addMilliseconds(detail.startMoment, (HDateHour.getTimeDiff(detail.startMoment, detail.lastMoment) / 2));
    }
    public static add(detail: TelemetryDetail, t: TelemetryDto): TelemetryDetail {        
        detail.acumSpeed = detail.acumSpeed + t.speed;
        detail.speed = detail.acumSpeed / (detail.count + 1);

        detail.acumLatitude = detail.acumLatitude + t.latitude;
        detail.latitude = t.latitude;

        detail.acumLongitude = detail.acumLongitude + t.longitude;
        detail.longitude = t.longitude;

        if ((t.altitude != null))
            detail.acumAltitude = detail.acumAltitude + t.altitude;
            detail.altitude = detail.acumAltitude / (detail.count + 1);

        detail.count++;        
        detail.minSpeed = TelemetryDetail.min(detail.minSpeed, t.speed);
        detail.maxSpeed = TelemetryDetail.max(detail.maxSpeed, t.speed);
        detail.minAltitude = TelemetryDetail.min(detail.minAltitude, t.altitude);
        detail.maxAltitude = TelemetryDetail.max(detail.maxAltitude, t.altitude);

        detail.telemetry = t;

        return detail;
    }

    public static min(a: number, b: number): number {
        if (a == null || b == null)
            return null;
        if (a < b)
            return a;
        else
            return b;
    }

    public static max(a: number, b: number): number {
        if (a == null || b == null)
            return null;
        if (a > b)
            return a;
        else
            return b;
    }
}