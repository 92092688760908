/*
 *ngFor="let c of oneDimArray | sortBy:'property'" ASC
 *ngFor="let c of oneDimArray | sortBy:'-property'" DESC
 *ngFor="let c of arrayOfObjects | sortBy:'property | -property'" ASC i DESC respectivament
*/
import { Pipe, PipeTransform } from '@angular/core';
import { HDate } from '../datatypes/HDate';
import { HDateHour } from '../datatypes/HDateHour';
import { HHourInterval } from '../datatypes/HHourInterval';
import { HTime } from '../datatypes/HTime';
import { AddressTimetableIntervalDto } from '../dtos/address/AddressTimetableIntervalDto';
import { DockDto } from '../dtos/address/dock/DockDto';
import { AddressDaySettingResourceDto, HHour } from '../public-api';
import { HInteger } from '../datatypes/HInteger';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(array: Array<any>, args: string | Array<string>): any[] {
        if (!array)
            return array;
        if (array.length <= 1)
            return array;  // array with only one item
        if (!args || args === '' || args == null || args.length == 0) {
            array.sort((a: any, b: any) => {
                //TODO: Si necessites fer comprovacions de tipus "raros" van aquí en la forma
                //if (a instanceof Tipus && b instanceof Tipus) {
                //return OrderByPipe.compareTipus(a, b);
                //else

                if (SortByPipe.isAddressTimetableIntervalDto(a) && SortByPipe.isAddressTimetableIntervalDto(b))
                    return SortByPipe.compareAddressTimetableIntervalDto(a, b);
                if (SortByPipe.isAddressDaySettingResourceDto(a) && SortByPipe.isAddressDaySettingResourceDto(b))
                    return SortByPipe.compareAddressDaySettingResourceDto(a, b);
                if (SortByPipe.isDockDto(a) && SortByPipe.isDockDto(b))
                    return SortByPipe.compareDockDto(a, b);
                if (SortByPipe.isHHourInterval(a) && SortByPipe.isHHourInterval(b))
                    return SortByPipe.compareHHourInterval(a, b);

                return SortByPipe.compareAny(a, b);

            });
            return array;
        }
        if ((typeof args) == 'string') {
            let reverse = false;
            let propertytoorder = <string>args;
            if (propertytoorder.startsWith("-")) {
                reverse = true;
                propertytoorder = propertytoorder.substring(1);
            }

            let propertiestoorder = []
            if (propertytoorder.includes(',')) {
                propertiestoorder = propertytoorder.split(',');
            } else {
                propertiestoorder.push(propertytoorder);
            }

            array.sort((a: any, b: any) => {

                let order = 0;
                let pa = a;
                let pb = b;
                propertiestoorder.forEach(item => {

                    //Ordenem per propietats mentres l'ordre sigui 0
                    if (order == 0) {
                        if (item.includes(".")) {
                            item.split('.').forEach(property => {
                                pa = pa[property];
                                pb = pb[property];
                            });
                        }
                        else {
                            pa = a[item];
                            pb = b[item];
                        }
                        if ((pa instanceof HHourInterval || HHourInterval.isHHourInterval(pa)) && (pb instanceof HHourInterval || HHourInterval.isHHourInterval(pb)))
                            order = HHourInterval.compare(pa, pb);
                        else if ((pa instanceof HDateHour || pa == null || HDateHour.isHDateHour(pa)) && (pb instanceof HDateHour || pb == null || HDateHour.isHDateHour(pb)))
                            order = HDateHour.compare(pa, pb);
                        else if ((pa instanceof HDate || HDate.isHDate(pa)) && (pb instanceof HDate || HDate.isHDate(pb)))
                            order = HDate.compare(pa, pb);
                        else if ((pa instanceof HTime || HTime.isHTime(pa)) && (pb instanceof HTime || HTime.isHTime(pb)))
                            order = HTime.compare(pa, pb);
                        else
                            order = SortByPipe.compareAny(pa, pb);

                    }

                });

                return order;
            });
            if (reverse)
                return array.reverse();
            return array;
        }
    }

    static isAddressTimetableIntervalDto(a: AddressTimetableIntervalDto): boolean {
        if (a == null)
            return false;
        if (a.onDate == undefined || a.onDate == null)
            return false;
        if (a.addressDaySettingId == undefined || a.addressDaySettingId == null)
            return false;
        if (a.addressTimetableIntervalId == undefined || a.addressTimetableIntervalId == null)
            return false;
        if (a.interval == undefined || a.interval == null)
            return false;
        return true;
    }
    static compareAddressTimetableIntervalDto(a: AddressTimetableIntervalDto, b: AddressTimetableIntervalDto): number {
        if (a == b == null)
            return 0;
        if (a == null)
            return -1;
        if (b == null)
            return 1;

        if (HDate.equals(a.onDate, b.onDate))
            return HHourInterval.compare(a.interval, b.interval);
        return HDate.compare(a.onDate, b.onDate)
    }

    static isHHourInterval(a: HHourInterval): boolean {
        if (a == null)
            return false;
        if (HHourInterval.getStarts(a) == undefined || HHourInterval.getStarts(a) == null)
            return false;
        if (HHourInterval.getEnds(a) == undefined || HHourInterval.getEnds(a) == null)
            return false;
        return true;
    }

    static isAddressDaySettingResourceDto(a: AddressDaySettingResourceDto): boolean {
        if (a == null)
            return false;
        if (a.onDate == undefined || a.onDate == null)
            return false;
        if (a.addressDaySettingId == undefined || a.addressDaySettingId == null)
            return false;
        if (a.addressDaySettingResourceId == undefined || a.addressDaySettingResourceId == null)
            return false;
        if (a.interval == undefined || a.interval == null)
            return false;
        return true;
    }
    static compareAddressDaySettingResourceDto(a: AddressDaySettingResourceDto, b: AddressDaySettingResourceDto): number {
        if (a == b == null)
            return 0;
        if (a == null)
            return -1;
        if (b == null)
            return 1;

        if (HDate.equals(a.onDate, b.onDate))
            return HHourInterval.compare(a.interval, b.interval);
        return HDate.compare(a.onDate, b.onDate)
    }

    static isDockDto(a: DockDto): boolean {
        if (a == null)
            return false;
        if (a.dockId == undefined || a.dockId == null)
            return false;
        if (a.dockScore == undefined || a.dockScore == null)
            return false;
        if (a.assignable == undefined || a.assignable == null)
            return false;
        if (a.occupied == undefined || a.occupied == null)
            return false;

        return true;
    }
    static compareDockDto(a: DockDto, b: DockDto): number {
        if (a == b == null)
            return 0;
        if (a == null)
            return -1;
        if (b == null)
            return 1;

        if (a.occupied && !b.occupied)
            return 1;
        if (!a.occupied && b.occupied)
            return -11;
        if (!a.assignable && b.assignable)
            return 1;
        if (a.assignable && !b.assignable)
            return -1;
        if (a.dockScore < b.dockScore)
            return -1;
        if (a.dockScore > b.dockScore)
            return 1;
        return 0;
    }
    static compareHHourInterval(a: HHourInterval, b: HHourInterval): number {
        if (a == b == null)
            return 0;

        let ha: HHour = HHourInterval.getStarts(a);
        let hb: HHour = HHourInterval.getStarts(b);

        if (ha == null && hb != null)
            return 1;
        if (ha != null && hb == null)
            return -1;

        return HInteger.comparesTo(HHour.getDecimal(ha), HHour.getDecimal(hb));

    }
    /***
     * Fa una comparació per strings però en el cas dels numèrics la fa per números. Per exemple
     * Oscar, 6b3, 12AF, 20, 1345, Pepet -> Oscar, Pepet, 12AF, 6b3, 20, 1345. Cadenes amb cadenes i valors numèrics amb numèrics
     */
    public static compareAny(a: any, b: any): number {
        if (!isNaN(a * 1) && !isNaN(b * 1)) {
            a = a * 1;
            b = b * 1;
        }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    }
}
