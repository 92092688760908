import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';


@Component({
  selector: 'shared-savebutton',
  templateUrl: './shared.savebutton.html',
  styleUrls: ['./shared.savebutton.scss'],
})
export class SharedSaveButton {


  constructor() {

  }

  public saving: boolean = false;
  @Input() public readonly: boolean = false;
  @Output() save: EventEmitter<SharedSaveButtonEvent> = new EventEmitter();

  public onSave() {
    this.saving = true;
    this.save.next(SharedSaveButtonEvent.build(this));
  }

  public callback(tis: any) {
    tis.saving = false;
  }

  OnInit() {
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}

export class SharedSaveButtonEvent {
  public tis: any;
  public callback() {
    this.tis.saving = false;
  }

  public static build(tis: SharedSaveButton): SharedSaveButtonEvent {
    let result = new SharedSaveButtonEvent();
    result.tis = tis;
    return result;
  }
}