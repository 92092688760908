<form class="form" [formGroup]="formGroup" *ngIf="value">
    <div class="title">{{'SHIPMENTCMR.ADDITIONALDATA.TITLE'| translate}}</div>
    <div class="block">
        <input-component class="col-x" [(ngModel)]="value.handlingAndTransportation"
            placeholder="{{'SHIPMENTCMR.MAINTENANCE.ADDITIONALDATA.HANDLINGANDTRANSPORTATION.CAPTION' | translate}}" name="handlingAndTransportation" formControlName="handlingAndTransportation"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <input-component class="col-x" [(ngModel)]="value.applicant"
            placeholder="{{'SHIPMENTCMR.MAINTENANCE.ADDITIONALDATA.APPLICANT.CAPTION' | translate}}" name="applicant" formControlName="applicant"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <input-component class="col-x" [(ngModel)]="value.conditions"
            placeholder="{{'SHIPMENTCMR.MAINTENANCE.ADDITIONALDATA.CONDITIONS.CAPTION' | translate}}" name="conditions" formControlName="conditions"
            autocomplete="off" [readonly]="readonly">
        </input-component>
    </div>

    <button *ngIf="!readonly && pendingSave" class="confirmButton" (click)="onSaveChanges()" [disabled]="!isValid">{{'SHIPMENTCMR.SAVE.BUTTON' | translate }}</button>

</form>
