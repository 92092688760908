import { Component, Inject, OnInit } from '@angular/core';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { MaintenanceSearchByTextDto } from '@shared/src/controllers/maintenance/_base/MaintenanceSearchByTextDtos';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { MultiSelectDto } from '@shared/src/dtos/booking/MultiSelectDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { AddressAmbitSelectorDto, HomeController, RouteCardDto, SecurityController, SharedActiveRoutes, SharedAsideFactory } from '@shared/src/public-api';
import { Subject } from 'rxjs-compat';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FilterStripDto } from '../core/maintenance/filter/FilterStripDtos';

@Component({
  selector: 'shared-booking-page',
  templateUrl: './shared.booking.page.component.html',
  styleUrls: ['./shared.booking.page.component.scss']
})
export class SharedBookingPageComponent implements OnInit {

  constructor(@Inject('SecurityController') protected securityController: SecurityController,
    @Inject('SharedAsideFactory') protected sharedAsideFactory: SharedAsideFactory,
    protected bookingController: BookingController) {
    this.calculateReadOnly();

    // Debounce search.
    this.searchTextUpdate.pipe(
      debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchTextUpdateEvent();
      });
  }

  ngOnInit(): void {
    this.addressAmbit = AddressAmbitSelectorDto.buildActiveRoutes();
    this.getFilters();
    this.loadActions();
  }

  public isListCollapsed: boolean = false;

  public searchTextUpdate = new Subject<string>();
  public searchText: string;

  public filterMetadata: any = {};
  public filterBookings: FilterStripDto = new FilterStripDto();
  public filter: MaintenanceSearchByTextDto = new MaintenanceSearchByTextDto();
  public filterPendingRoutes: MaintenanceSearchByTextDto = new MaintenanceSearchByTextDto();

  public selectedOptionId: string;
  public options: OptionsStripDto;

  public actions: ActionsStripDto;

  public readonly: boolean = false;

  public exactMatchBookings: Array<BookingDto> = [];
  public bookings: Array<BookingDto> = [];

  public bookingRoutes: Array<RouteCardDto> = [];
  public bookingRoutesPendingManage: Array<RouteCardDto> = [];
  public bookingRoutesManaged: Array<RouteCardDto> = [];
  public bookingRoutesDone: Array<RouteCardDto> = [];
  public selectedRoutes: Array<number> = [];
  public showingSelectedRoutes = false;
  public searchTextBookingRoutes: string;
  public filterMetadataBookingRoutes: any = {};
  public filterMetadataBookingRoutesPendingManage: any = {};
  public filterMetadataBookingRoutesManaged: any = {};
  public filterMetadataBookingRoutesDone: any = {};
  public bookingRoutesActions: ActionsStripDto;

  public selectedBookingRoute: RouteCardDto;

  public showingSelectedPendingRoutes = false;
  public pendingRoutesCount: number = 0;
  public pendingRoutes: Array<RouteCardDto> = [];
  public selectedPendingRoutes: Array<number> = [];
  public filterMetadataPendingRoutes: any = {};
  public searchTextPendingRoutes: string;
  public sliceCount: number = SharedActiveRoutes.SLICECOUNTINITIAL;
  public confirmPendingRoutesActions: ActionsStripDto;

  public conflictiveRoutes: Array<RouteCardDto> = [];
  public selectedConflictiveRoutes: Array<number> = [];
  public filterMetadataConflictiveRoutes: any = {};
  public searchTextConflictiveRoutes: string;
  public showingSelectedConflictiveRoutes = false;

  private _selectedBooking: BookingDto = null;

  public set selectedBooking(v: BookingDto) {
    const firstSelect = this._selectedBooking == null || v == null || v.bookingId == null;

    this._selectedBooking = v;
    this.loadActions();
    if (this._selectedBooking && this._selectedBooking.bookingId) {
      this.getBookingRoutes();
      this.getPendingRoutes(false);
      this.getConflictiveRoutes();
    }

    this.getOptions(firstSelect);
  }

  public get selectedBooking(): BookingDto {
    return this._selectedBooking;
  }

  public searchTextUpdateEvent() {
    this.loadExactBookings();
  }

  public calculateReadOnly() {
    this.securityController.getStoredUserDto().subscribe(data => {
      this.readonly = data.isReadOnly || (this.selectedBooking && this.selectedBooking.bookingId && !this.selectedBooking.isModifiable);
    })
  }

  public getFilters() {
    this.bookingController.getFilters().subscribe(e => {
      this.filterBookings = FilterStripDto.buildFromFilterStripDto(e);
      this.filter.activeFilterKeys = FilterStripDto.getActiveKeys(this.filterBookings);
      this.loadBookings();
    })
  }

  public getOptions(goToActiveTab: boolean) {
    let actualOptionId: OptionDto = null;
    if (this.options)
      actualOptionId = this.options.getActualSelected();

    this.bookingController.getOptions(this._selectedBooking && this._selectedBooking.bookingId ? this._selectedBooking.bookingId : null).subscribe(e => {
      this.options = OptionsStripDto.buildFromOptionsStripDto(e);
      if (goToActiveTab && this.options.getActualSelected()) {
        this.selectedOptionId = this.options.getActualSelected().optionId;
      } else if (actualOptionId) {
        OptionsStripDto.select(this.options, actualOptionId);
      }
    })
  }

  public loadActions() {
    this.loadPendingActions();
    this.bookingController.getActions(this.selectedBooking && this.selectedBooking.bookingId ? this.selectedBooking.bookingId : null).subscribe(e => {
      this.actions = ActionsStripDto.buildFromActionsStripDto(e);
    })
  }

  public loadPendingActions() {
    this.bookingRoutesActions = new ActionsStripDto();
    this.bookingRoutesActions.add(ActionDto.buildAdd("SELECTALL", "BOOKINGPAGE.SELECTALL.CAPTION", null, null, 0, -1, 1, null));
    this.bookingRoutesActions.add(ActionDto.build("DESELECTALL", "BOOKINGPAGE.DESELECTALL.CAPTION", null, null, 1, -1, 1, null));

    let showNext: boolean = false;
    switch (this.selectedOptionId) {
      case 'ROUTES':
        showNext = this.selectedRoutes && this.selectedRoutes.length > 0;
        break;
      case 'ADDROUTES':
        showNext = this.selectedPendingRoutes && this.selectedPendingRoutes.length > 0;
        break;
      case 'CONFLICTS':
        showNext = this.selectedConflictiveRoutes && this.selectedConflictiveRoutes.length > 0;
        break;
      default:
        break;
    }

    if (showNext)
      this.bookingRoutesActions.add(ActionDto.buildAdd("NEXT", "MAINTENANCE.NEXT", null, "fa-arrow-right", 2, 0, 1, null));

    this.confirmPendingRoutesActions = new ActionsStripDto();
    this.confirmPendingRoutesActions.add(ActionDto.build("CANCEL", "BOOKINGPAGE.CANCEL.CAPTION", null, null, 0, -1, 1, null));
    this.confirmPendingRoutesActions.add(ActionDto.buildAdd("CONFIRM", "BOOKINGPAGE.CONFIRM.CAPTION", null, null, 1, -1, 1, null));
  }

  public filterUpdate() {
    this.filter.activeFilterKeys = FilterStripDto.getActiveKeys(this.filterBookings);
    this.loadBookings();
  }

  public _addressAmbit: AddressAmbitSelectorDto;
  public get addressAmbit(): AddressAmbitSelectorDto {
    return this._addressAmbit;
  }
  public set addressAmbit(value: AddressAmbitSelectorDto) {
    if (this._addressAmbit === value)
      return;
    this._addressAmbit = value;
  }

  public onChangeAddressAmbitSelector($event: AddressAmbitSelectorDto) {
    this._addressAmbit = $event;
    if ($event != null) {
      this.filterPendingRoutes.ambitId = $event.ambitId;
      this.filterPendingRoutes.addressId = $event.addressId;
    } else {
      this.filterPendingRoutes.ambitId = 0;
      this.filterPendingRoutes.addressId = 0;
    }
    this.getPendingRoutes(true);
  }

  public onClickOption(option: OptionDto) {
    if (option)
      this.selectedOptionId = option.optionId;

    if (this.selectedOptionId == 'MANAGE') {
      this.getBookingRoutes();
      this.loadActions();
    }

    if (this.selectedOptionId == 'ROUTES') {
      this.getBookingRoutes();
      this.loadActions();
    }

    if (this.selectedOptionId == 'ADDROUTES') {
      this.getPendingRoutes(false);
      this.loadActions();
    }

    if (this.selectedOptionId == 'CONFLICTS') {
      this.getConflictiveRoutes();
      this.loadActions();
    }
  }

  getBookingRoutes() {
    this.bookingRoutesPendingManage = [];
    this.bookingRoutesManaged = [];
    this.bookingRoutesDone = [];
    this.bookingRoutes = [];
    this.selectedRoutes = [];
    this.selectedBookingRoute = null;
    this.showingSelectedRoutes = false;

    if (!this.selectedBooking || !this.selectedBooking.bookingId)
      return;

    this.bookingController.getRoutes(this.selectedBooking.bookingId).subscribe(e => {
      if (e) {
        this.bookingRoutes = e;
        e.forEach((item: RouteCardDto) => {
          if (item.pendingSlotQty > 0) {
            this.bookingRoutesPendingManage.push(item);
          } else if (item.assignedSlots && item.assignedSlots.length > 0 && item.assignedSlots[0].isFinished) {
            this.bookingRoutesDone.push(item);
          } else if (item.pendingSlotQty == 0) {
            this.bookingRoutesManaged.push(item);
          }
        })
      }
    });
  }

  getPendingRoutes(busy: boolean) {
    if (!this.selectedBooking || !this.selectedBooking.bookingId || !this.filterPendingRoutes.ambitId)
      return;
    this.pendingRoutes = [];
    this.pendingRoutesCount = 0;
    this.selectedPendingRoutes = [];
    this.showingSelectedPendingRoutes = false;

    this.bookingController.getPendingRoutes(this.selectedBooking.bookingId, this.filterPendingRoutes, busy).subscribe(e => {
      this.pendingRoutes = e;
      this.pendingRoutesCount = e.length;
      this.showingSelectedPendingRoutes = false;
    });
  }


  getConflictiveRoutes() {
    this.selectedConflictiveRoutes = [];
    this.showingSelectedConflictiveRoutes = false;

    if (!this.selectedBooking || !this.selectedBooking.bookingId)
      return;

    this.bookingController.getConflictiveRoutes(this._selectedBooking.bookingId).subscribe(e => {
      this.conflictiveRoutes = e;
      // this.reloadBooking();
    });
  }

  public onClickAction(action: ActionDto) {
    switch (action.actionId) {
      case "ADD":
        this.createMode();
        break;
      case "COPY":
        if (this.selectedBooking && this.selectedBooking.bookingId)
          this.bookingController.cloneBooking(this.selectedBooking.bookingId).subscribe(e => {
            if (e && e.bookingId) {
              this.bookings.push(e);
              this.selectedBooking = e;
              this.loadActions();
            }
          });
        break;
      case "DELETE":
        if (this.selectedBooking && this.selectedBooking.bookingId)
          this.bookingController.deleteBooking(this.selectedBooking.bookingId).subscribe(e => {
            if (e) {
              this.loadBookings();
              this.selectedBooking = undefined;
              this.loadActions();
            }
          });
        break;
      default:
        this.bookingController.executeAction(this.selectedBooking.bookingId, action).subscribe(e => {
          if (e) {
            this.obtenirDades(this.selectedBooking);
          }
        });
        break;
    }
  }

  public onClickBookingRouteAction(item: ActionDto) {
    if (!item)
      return;

    switch (item.actionId) {
      case "SELECTALL":
        if (this.options.getActualSelected())
          switch (this.options.getActualSelected().optionId) {
            case 'ROUTES':
              console.log(this.filterMetadataBookingRoutesManaged);
              this.selectedRoutes = [];
              if (this.filterMetadataBookingRoutesPendingManage.items)
                this.selectedRoutes = this.selectedRoutes.concat(this.filterMetadataBookingRoutesPendingManage.items.map((r: RouteCardDto) => r.routeId));
              if (this.filterMetadataBookingRoutesManaged.items)
                this.selectedRoutes = this.selectedRoutes.concat(this.filterMetadataBookingRoutesManaged.items.map((r: RouteCardDto) => r.routeId));
              if (this.filterMetadataBookingRoutesDone.items)
                this.selectedRoutes = this.selectedRoutes.concat(this.filterMetadataBookingRoutesDone.items.map((r: RouteCardDto) => r.routeId));
              break;
            case 'ADDROUTES':
              console.log(this.filterMetadataPendingRoutes);
              this.selectedPendingRoutes = [];
              if (this.filterMetadataPendingRoutes.items)
                this.selectedPendingRoutes = this.filterMetadataPendingRoutes.items.map((r: RouteCardDto) => r.routeId);
              break;
            case 'CONFLICTS':
              this.selectedConflictiveRoutes = [];
              if (this.filterMetadataConflictiveRoutes.items)
                this.selectedConflictiveRoutes = this.filterMetadataConflictiveRoutes.items.map((r: RouteCardDto) => r.routeId);
              break;
          }
        this.loadPendingActions();
        break;
      case "DESELECTALL":
        if (this.options.getActualSelected())
          switch (this.options.getActualSelected().optionId) {
            case 'ROUTES':
              this.selectedRoutes = [];
              break;
            case 'ADDROUTES':
              this.selectedPendingRoutes = [];
              break;
            case 'CONFLICTS':
              this.selectedConflictiveRoutes = [];
              break;
          }
        this.loadPendingActions();
        break;
      case "NEXT":
        if (this.options.getActualSelected())
          switch (this.options.getActualSelected().optionId) {
            case 'ROUTES':
              this.showingSelectedRoutes = true;
              break;
            case 'ADDROUTES':
              this.showingSelectedPendingRoutes = true;
              break;
            case 'CONFLICTS':
              this.showingSelectedConflictiveRoutes = true;
              break;
          }
        break;
      case "CONFIRM":
        if (this.options.getActualSelected())
          switch (this.options.getActualSelected().optionId) {
            case 'ROUTES':
              let msReleaseDto: MultiSelectDto = MultiSelectDto.build(this.selectedRoutes);
              this.bookingController.multiReleaseBooking(this.selectedBooking.bookingId, msReleaseDto).subscribe(e => {
                if (e) {
                  // Refresquem el Booking
                  this.reloadBooking();

                  // Obtenim les opcions 
                  this.getOptions(true);

                  // Recarreguem la llista de bookings per veure el nou estat de l'actual
                  this.loadBookings();

                  // Refresquem la llista de rutes del booking
                  this.getBookingRoutes();

                  // Buidem les dades del procés
                  this.selectedRoutes = [];
                  this.showingSelectedRoutes = false;

                  //Obtenim les accions
                  this.loadActions();
                }
              })
              break;
            case 'ADDROUTES':
              let msReserveDto: MultiSelectDto = MultiSelectDto.build(this.selectedPendingRoutes);
              this.bookingController.multiReserveBooking(this.selectedBooking.bookingId, msReserveDto).subscribe(e => {
                if (e) {
                  // Ens en anem a l'opció de rutes reservades
                  this.onClickOption(OptionDto.build("MANAGE", null, null, null,
                    null, null, null, null, null));
                  OptionsStripDto.selectById(this.options, "MANAGE");

                  // Refresquem el Booking
                  this.reloadBooking();

                  // Obtenim les opcions 
                  this.getOptions(true);

                  // Recarreguem la llista de bookings per veure el nou estat de l'actual
                  this.loadBookings();

                  // Buidem les dades del procés
                  this.selectedPendingRoutes = [];
                  this.showingSelectedPendingRoutes = false;

                  // Obtenim les accions
                  this.loadActions();
                }
              })
              break;
            case 'CONFLICTS':
              let msReleaseConflictDto: MultiSelectDto = MultiSelectDto.build(this.selectedConflictiveRoutes);
              this.bookingController.multiReleaseBooking(this.selectedBooking.bookingId, msReleaseConflictDto).subscribe(e => {
                if (e) {
                  // Refresquem el Booking
                  this.reloadBooking();

                  // Obtenim les opcions 
                  this.getOptions(true);

                  // Recarreguem la llista de bookings per veure el nou estat de l'actual
                  this.loadBookings();

                  // Refresquem la llista de rutes del booking
                  this.getConflictiveRoutes();

                  // Buidem les dades del procés
                  this.selectedConflictiveRoutes = [];
                  this.showingSelectedConflictiveRoutes = false;

                  // Obtenim les accions
                  this.loadActions();
                }
              })
              break;
          }
        break;
      case "CANCEL":
        this.showingSelectedPendingRoutes = false;
        this.showingSelectedRoutes = false;
        this.showingSelectedConflictiveRoutes = false;
        break;
      default:
        break;
    }
  }

  onSelectPendingRoute(item: RouteCardDto) {
    if (this.selectedPendingRoutes.includes(item.routeId))
      this.selectedPendingRoutes.splice(this.selectedPendingRoutes.indexOf(item.routeId), 1);
    else
      this.selectedPendingRoutes.push(item.routeId);

    this.loadPendingActions();
  }

  onSelectBookingRoute(item: RouteCardDto) {
    if (this.selectedRoutes.includes(item.routeId)) {
      this.selectedRoutes.splice(this.selectedRoutes.indexOf(item.routeId), 1);
    } else {
      this.selectedRoutes.push(item.routeId);
    }

    this.loadPendingActions();
  }

  onSelectConflictiveRoute(item: RouteCardDto) {
    if (this.selectedConflictiveRoutes.includes(item.routeId)) {
      this.selectedConflictiveRoutes.splice(this.selectedConflictiveRoutes.indexOf(item.routeId), 1);
    } else {
      this.selectedConflictiveRoutes.push(item.routeId);
    }

    this.loadPendingActions();
  }

  public loadExactBookings() {
    this.bookingController.getBookingsByKey(this.filter, this.searchText).subscribe(b => {
      if (b && b.length > 0)
        this.exactMatchBookings = b;
      else
        this.exactMatchBookings = [];
    })
  }
  public loadBookings() {
    this.bookingController.getAllBookings(this.filter).subscribe(b => {
      if (b && b.length > 0)
        this.bookings = b;
      else
        this.bookings = [];
    })
  }
  public reloadBooking() {
    this.bookingController.getBooking(this._selectedBooking.bookingId).subscribe(e => {
      this._selectedBooking = e;

      this.getOptions(false);

      let index = null;
      this.bookings.forEach((item, i) => {
        if (item.bookingId == this._selectedBooking.bookingId)
          index = i;
      });

      if (index)
        this.bookings[index] = e;
    })
  }

  public createMode() {
    this.selectedBooking = new BookingDto();
    this.loadActions();
  }

  public editMode(item: BookingDto) {
    if (item) {
      this.obtenirDades(item);
    } else {
      this.selectedBooking = null;
      this.calculateReadOnly();
    }
  }
  obtenirDades(item: BookingDto) {
    this.bookingController.getBooking(item.bookingId).subscribe(e => {
      this.selectedBooking = e;
      this.calculateReadOnly();
    });
  }

  public onSave(e: BookingDto) {
    if (!e)
      return;

    this.selectedBooking = e;
    this.loadBookings();

    if (e.hasConflicts) {
      // Ens en anem a l'opció de rutes reservades
      this.onClickOption(OptionDto.build("CONFLICTS", null, null, null,
        null, null, null, null, null));
      OptionsStripDto.selectById(this.options, "CONFLICTS");
    }
  }

  onSliceMorePendingRoutes() {
    this.sliceCount = Math.min(this.sliceCount + SharedActiveRoutes.SLICECOUNTMORE, this.pendingRoutesCount);
  }

  getSelectedPendingRoutes() {
    return this.pendingRoutes.filter(r => this.selectedPendingRoutes.includes(r.routeId));
  }

  getSelectedBookingRoutes() {
    return this.bookingRoutes.filter(r => this.selectedRoutes.includes(r.routeId));
  }

  getSelectedConflictiveRoutes() {
    return this.conflictiveRoutes.filter(r => this.selectedConflictiveRoutes.includes(r.routeId));
  }

  onDoubleClickBookingRoute(route: RouteCardDto) {
    if (route == null)
      return;

    this.sharedAsideFactory.invokeBookingRoute(route).then(value => {
      this.getBookingRoutes();
      this.getConflictiveRoutes();
      this.getPendingRoutes(false);
      this.reloadBooking();
    });
  }

  onBookingRouteChanged(item: RouteCardDto, reloadbooking: boolean) {
    if (!item)
      return;

    let index = null;
    this.bookingRoutes.forEach((route, i) => {
      if (route.routeId == item.routeId)
        index = i;
    });

    this.bookingRoutes[index] = item;
    this.selectedBookingRoute = item;

    if (reloadbooking)
      this.reloadBooking();
  }

  pretty(v) {
    return JSON.stringify(v);
  }
}
