<div class="grup">
    <div class="titol">
        {{'SHAREDSTATUSTIME.COMPONENT.TITLE'|translate}}
    </div>
    <div class="contingut">
        <table class="hardman" [ngClass]="data.generalStatus" *ngIf="data">
            <tr class="">
                <th colspan="2">{{'GENERALSTATUS.CAPTION'|translate}}</th>
                <th>{{'SHAREDSTATUSTIME.COMPONENT.FIRSTMOMENT.CAPTION'|translate}}</th>
                <th>{{'SHAREDSTATUSTIME.COMPONENT.LASTMOMENT.CAPTION'|translate}}</th>
                <th></th>
                <th>{{'SHAREDSTATUSTIME.COMPONENT.UNTILMOMENT.CAPTION'|translate}}</th>
                <th></th>
            </tr>
            <tr>
                <th><span class="only-promised"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.PROMISED" | translate }}</th>
                <th>{{ data.statusPromisedFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusPromisedLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusPromisedTime | time }}</th>
                <th>{{ data.statusPromisedUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusPromisedTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-inprogress"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                </th>
                <th>{{ "GENERALSTATUS.ENUM.INPROGRESS" | translate }}</th>
                <th>{{ data.statusInprogressFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusInprogressLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusInprogressTime | time }}</th>
                <th>{{ data.statusInprogressUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusInprogressTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-done"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.DONE" | translate }}</th>
                <th>{{ data.statusDoneFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusDoneLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusDoneTime | time }}</th>
                <th>{{ data.statusDoneUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusDoneTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-arrived"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.ARRIVED" | translate }}</th>
                <th>{{ data.statusArrivedFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusArrivedLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusArrivedTime | time }}</th>
                <th>{{ data.statusArrivedUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusArrivedTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-loaded"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.LOADED" | translate }}</th>
                <th>{{ data.statusLoadedFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusLoadedLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusLoadedTime | time }}</th>
                <th>{{ data.statusLoadedUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusLoadedTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-unloaded"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.UNLOADED" | translate }}</th>
                <th>{{ data.statusUnloadedFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusUnloadedLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusUnloadedTime | time }}</th>
                <th>{{ data.statusUnloadedUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusUnloadedTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-loadedunloaded"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                </th>
                <th>{{ "GENERALSTATUS.ENUM.LOADEDUNLOADED" | translate }}</th>
                <th>{{ data.statusLoadedUnloadedFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusLoadedUnloadedLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusLoadedUnloadedTime | time }}</th>
                <th>{{ data.statusLoadedUnloadedUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusLoadedUnloadedTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-canceled"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span></th>
                <th>{{ "GENERALSTATUS.ENUM.CANCELED" | translate }}</th>
                <th>{{ data.statusCanceledFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusCanceledLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusCanceledTime | time }}</th>
                <th>{{ data.statusCanceledUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusCanceledTime | time }}</th>
            </tr>
            <tr>
                <th><span class="only-incomplete"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                </th>
                <th>{{ "GENERALSTATUS.ENUM.INCOMPLETE" | translate }}</th>
                <th>{{ data.statusIncompleteFirstMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusIncompleteLastMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.statusIncompleteTime | time }}</th>
                <th>{{ data.statusIncompleteUntilMomentUTC | datehournosecondsnotzobserver }}</th>
                <th>{{ data.absolutStatusIncompleteTime | time }}</th>
            </tr>

        </table>
    </div>
</div>