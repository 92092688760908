
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { map, startWith } from 'rxjs/operators';

import { Host, Inject, Optional, SkipSelf } from '@angular/core';
import { AsyncValidatorFn, ControlContainer, FormBuilder, FormControl, FormGroup, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';


import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HEnum } from '@shared/src/datatypes/HEnum';
import { HString } from '@shared/src/datatypes/HString';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';
import { TimeZones } from '@shared/src/enums/TimeZones';
import { KeysPipe } from '@shared/src/filters/keysPipe';
import { enumValidatorNoTranslate, enumValidatorTranslate } from '../../form/validations/AutoCompleteValidator';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';

@Component({
  selector: 'enum-selector',
  templateUrl: './enum-selector.component.html',
  styleUrls: ['./enum-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: EnumSelectorComponent, multi: true }]
})

export class EnumSelectorComponent extends ValueAccessorValidatedBase<any, any> {


  rForm: FormGroup;
  enumCtrl: FormControl;
  reactiveItems: any;
  showAll: boolean = false;

  _asyncvalidators: AsyncValidatorFn[];
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this._asyncvalidators = asyncvalidators;
    this.setValidator();
  }

  private setValidator() {
    if (this._translateselected)
      this.enumCtrl = new FormControl('', enumValidatorTranslate(this._enumvalues, this.translategroup, this.translatesufix, this.translate, this.required), this._asyncvalidators);
    else
      this.enumCtrl = new FormControl('', enumValidatorNoTranslate(this._enumvalues, this.translategroup, this.translatesufix, this.translate, this.required), this._asyncvalidators);
    this.rForm = this.fb.group({
      'eselvalidator': this.enumCtrl
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.refreshList();
  }

  enumv: any;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    private translate: TranslateService,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }


  refreshList() {
    this.reactiveItems = this.enumCtrl.valueChanges.pipe(
      startWith(this.enumCtrl.value),
      map(val => this.displayFn(val)),
      map(name => this.filterItems(name)));
  }

  onInputChange($event: any) {
    if (!this.translateselected) {
      for (var enumMember in this.enumvalues)
        if (this.enumvalues[enumMember] == this.displayvalue) {
          this.value = enumMember;
          return;
        }
    }
    else {
      let sufix: string = "";
      if (!HString.isNullOrNullString(this.translatesufix))
        sufix += "." + this.translatesufix;
      for (var enumMember in this.enumvalues) {
        let trans = this.translate.instant(this.translategroup.toUpperCase() + "." + enumMember.toString().toUpperCase() + sufix);
        if (trans == this.displayvalue) {
          this.value = enumMember;
          return;
        }
      }
      for (var enumMember in this.enumvalues)
        if (this.enumvalues[enumMember] == this.displayvalue) {
          this.value = enumMember;
          return;
        }
    }
    for (var enumMember in this.enumvalues) {
      if (enumMember == this.displayvalue) {
        this.value = enumMember;
        return;
      }
    }
  }

  onContainerClick(event: MouseEvent) {
    if (this.readonly)
      return;

    this.showAll = true;
    this.refreshList();
  }

  onClear() {
    this.value = "";
    this.displayvalue = "";
  }

  displayFn(value: any): string {
    return value && typeof value === 'object' ? value.name : value;
  }

  filterItems(val: string) {
    if (this.useIonic) {
      val = "";
    }
    let items = val && !this.showAll ? this._filter(this._listitems, val) : this._listitems;
    this.showAll = false;
    return items;
  }

  private _filter(values: any[], val: string) {
    const filterValue = val.toLowerCase();
    return values.filter(enumval => {
      if (this.getValueTranslation(enumval.value).toLowerCase().startsWith(filterValue))
        return true;
      if (this.getValueTranslation(enumval.key).toLowerCase().startsWith(filterValue))
        return true;
      if (HString.includes(this.getValueTranslation(enumval.value).toLowerCase(), filterValue))
        return true;
      if (HString.includes(enumval.value, filterValue) || HString.includes(enumval.key, filterValue))
        return true;

      return false;
    }
    );
  }



  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Input() public translategroup: string = "";
  @Input() public showclosebutton: boolean = true;
  @Input() public required: boolean = false;

  @Output() valuechangefunction = new EventEmitter<any>();

  private _enumvalues: any;
  @Input() set enumvalues(values: any) {
    if (!this._externalitems)
      this._listitems = KeysPipe.enumToKeyValuePair(values);
    this._enumvalues = values;
  }
  get enumvalues(): any {
    return this._enumvalues;
  }

  private _externalitems: boolean;
  private _listitems: any;
  @Input() set externallistitems(values: any) {
    this._externalitems = true;
    this._listitems = values;
  }
  get externallistitems(): any {
    return this._listitems;
  }

  @Input()
  public _translateselected: boolean = true;
  @Input() set translateselected(value: boolean) {
    if (this._translateselected === value)
      return;
    this._translateselected = value;
    this.setValidator();
  }
  get translateselected(): boolean {
    return this._translateselected;
  }

  @Input()
  public _translatesufix: string;
  @Input() set translatesufix(value: string) {
    if (this._translatesufix === value)
      return;
    this._translatesufix = value;
    this.setValidator();
  }
  get translatesufix(): string {
    return this._translatesufix;
  }

  public getValueTranslation(value: any): string {

    if (value == null || value == undefined)
      return value;

    value = this.getEnumFromString(value);

    if (HString.isNullOrNullString(this.translategroup))
      return value;

    let sufix: string = "";
    if (!HString.isNullOrNullString(this.translatesufix))
      sufix += "." + this.translatesufix;

    if (this.translategroup.includes("{value}") || this.translategroup.includes("{VALUE}"))
      return this.translate.instant(this.translategroup.toUpperCase().replace("{VALUE}", value.toString().toUpperCase()) + sufix);
    return this.translate.instant(this.translategroup.toUpperCase() + "." + value.toString().toUpperCase() + sufix);
  }
  public getValueNoTranslation(value: any): string {
    if (HString.isNullOrNullString(this.translategroup) || value == null || value == undefined)
      return "";
    let result = HEnum.getStringFromEnum(value, this.enumvalues);
    return result;
  }

  public getEnumFromString(value: any): any {
    switch (this._enumvalues) {
      case TimeZones:
        value = HEnum.getEnumFromString(value, TimeZones);
        break;
      case PhonePrefix:
        value = HEnum.getEnumFromString(value, PhonePrefix);
        break;
      default:
        break;
    }
    return value;
  }

  dvalue: string;
  get displayvalue(): string {

    let sufix: string = "";
    if (!HString.isNullOrNullString(this.translatesufix))
      sufix += "." + this.translatesufix;

    // Valor per defecte
    if (!this.dvalue) {
      if (this.value) {
        if (this.translateselected)
          this.dvalue = this.translate.instant(this.translategroup + "." + HEnum.getEnumFromString(this.value.toString(), this._enumvalues).toUpperCase() + sufix);
        else
          this.dvalue = this.enumvalues[this.getEnumFromString(this.value.toString())];
      } else {
        switch (this._enumvalues) {
          case PhonePrefix:
            if (this.translateselected)
              this.dvalue = this.translate.instant(this.translategroup + "." + HEnum.getEnumFromString(PhonePrefix.Spain_34, this._enumvalues).toUpperCase() + sufix);
            break;
        }
      }
    }

    return this.dvalue;
  }

  set displayvalue(componentvalue: string) {
    this.dvalue = componentvalue;
    if (componentvalue == "")
      this.value = null;
  }

  onLostFocus() {
    setTimeout(() => {
      if (this.translateselected)
        this.displayvalue = this.getValueTranslation(this.value);
      else
        this.displayvalue = this.getValueNoTranslation(this.value);
    }, 400);
  }

  selectOption(event) {
    if (event) {
      if (event.option != null && event.option.value != this.value)
        this.valuechangefunction.next(event);

      if (event.detail)
        this.value = event.detail.value;
      else if (event.option != null)
        this.value = event.option.value;

      if (this.translateselected)
        this.displayvalue = this.getValueTranslation(this.value);
      else
        this.displayvalue = this.getValueNoTranslation(this.value);
    }
  }


  public identifier = 'enum-selector-' + identifier++;

  //Any = Enum --> externament treballem amb enum
  externalValue(value: any): any {
    if (value === "" || value === undefined)
      return null;

    return value;
  }
  //Any = Enum --> internament treballem amb enum
  internalValue(value: any): any {
    if (value === "" || value === undefined) {
      this.displayvalue = "";
      return null;
    }

    // Son iguals
    if (value === this.value) {
      return value;
    }

    if (this.translateselected)
      this.displayvalue = this.getValueTranslation(value);
    else
      this.displayvalue = this.getValueNoTranslation(value);


    return value;
  }


  public pretty(value: any): string {
    return JSON.stringify(value);
  }

}

let identifier = 0;
