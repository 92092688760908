import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoadingService } from '@shared/src/public-api';
import { BaseController } from '../../base.controller';

@Injectable({
    providedIn: 'root',
})
export class InternalSupervisionController extends BaseController {

    private base = '/supervision'
    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);

    }
    public getSupervision(): Observable<any> {
        return this.getBusy(this.base + "/");
    }
    public sendNotification(to: string, subject: string, body: string, sendToTenantId: boolean, tenantId: string, sendToEveryBody: boolean): Observable<any> {
        return this.putBusy(this.base + "/sendnotification", { to: to, subject: subject, body: body, sendToTenantId: sendToTenantId, tenantId: tenantId, sendToEveryBody: sendToEveryBody }, "sendNotification");
    }


}

