import { Component, OnInit, Inject, Input } from '@angular/core';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
  selector: 'shared-chatsicon',
  templateUrl: './shared.chats.icon.html',
  styleUrls: ['../shared.chats.scss']
})
export class SharedChatsIcon implements OnInit {

  public chats: ChatsDto;

  @Input() public ngclass: string;

  constructor(private chatController: ChatController, @Inject('HomeController') public homeController: HomeController) {

  }

  ngOnInit() {
    this.homeController.refreshChats();

    this.homeController.chatsChanged$.subscribe(data => {
      this.refreshChats(data);
    })

  }

  public pendingMessagesRead: number;
  refreshChats(chats: ChatsDto) {
    this.chats = chats;
    if (this.chats != null)
      this.pendingMessagesRead = this.chats.countPendingMessagesRead;
  }


}


