import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';

@Component({
  selector: 'register-ask-user-driver',
  templateUrl: './register-ask-user-driver.component.html',
  styleUrls: ['./register-ask-user-driver.component.scss']
})
export class RegisterAskUserDriverComponent implements OnInit {

  public generalClass: string = "desktop";

  constructor(@Inject('HomeController') protected homecontroller: HomeController) {
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();
  }

  ngOnInit() { }

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  @Input()
  public registerData: UserRegisterWizardDto = new UserRegisterWizardDto;

  @Input() 
  public pendigResponse: boolean;

  next() {
    if(this.pendigResponse)
      return;
    this.onClickNext.next(this.registerData);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
