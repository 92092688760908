import { Component, Input } from "@angular/core";
import { PhaseController } from "@shared/src/controllers/route/phase/phase.controller";
import { RouteController } from "@shared/src/controllers/route/route.controller";
import { ShipmentController } from "@shared/src/controllers/route/shipment/shipment.controller";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";
import { RouteMapInfoDto } from "@shared/src/dtos/route/RouteMapInfoDto";
import { GeneralStatus } from "@shared/src/enums/GeneralStatus";
import { MapPuntDto, MapRutaDto } from "@shared/src/services/map/map.service";

@Component({
  selector: "shared-route-map-info-component",
  templateUrl: "./shared.route.map-info.component.html",
  styleUrls: ["./shared.route.map-info.component.scss"],
})
export class SharedRouteMapInfoComponent {
  constructor(
    private phaseController: PhaseController,
    private routeController: RouteController,
    private shipmentController: ShipmentController
  ) { }

  public punts: Array<MapPuntDto> = [];
  public ruta: Array<MapRutaDto> = [];
  public centerLatitude = 0;
  public centerLongitude = 0;
  private _data: RouteMapInfoDto;
  public get data(): RouteMapInfoDto {
    return this._data;
  }

  public set data(value: RouteMapInfoDto) {
    if (value === this._data) return;

    this._data = value;

    if (!this._data)
      return;

    this.punts = [];

    if (value.phases)
      value.phases
        .sort((p) => p.executionOrder)
        .filter((p) => p.isActivePhase && p.latitude !== null && p.longitude !== null)
        .forEach((item) => {
          console.log(this.punts.push(
            MapPuntDto.builder(item.longitude, item.latitude)
          ));
        });

    if (value.lastTelemetry && this.punts.length > 0) {
      console.log(this.punts.unshift(
        MapPuntDto.builder(
          value.lastTelemetry.longitude,
          value.lastTelemetry.latitude
        )
      ));
    }

    if (this.punts.length > 0)
      this.ruta = [MapRutaDto.builderWaypoints(this.punts)];

    if (value.lastTelemetry) {
      this.centerLatitude = value.lastTelemetry.latitude;
      this.centerLongitude = value.lastTelemetry.longitude;
    }
    console.log(this.centerLongitude)
    console.log(this.centerLatitude)
  }

  private _shipmentId: number;
  @Input() public set shipmentId(value: number) {
    if (this._shipmentId == value) return;
    this._shipmentId = value;
    this.loadRouteByShipmentId();
  }
  public get shipmentId(): number {
    return this._shipmentId;
  }

  public loadRouteByShipmentId() {
    if (!this._shipmentId) return;

    this.shipmentController
      .getMapInfoByShipmentId(this._shipmentId)
      .subscribe((data) => {
        this.data = data;
      });
  }

  private _routeId: number;
  @Input() public set routeId(value: number) {
    if (this._routeId == value) return;
    this._routeId = value;
    this.loadRouteByRouteId();
  }
  public get routeId(): number {
    return this._routeId;
  }

  public loadRouteByRouteId() {
    if (!this._routeId) return;

    this.routeController
      .getMapInfoByRouteId(this._routeId)
      .subscribe((data) => {
        this.data = data;
      });
  }

  private _pendingStates: Array<GeneralStatus> = [GeneralStatus.promised];

  public getPendingPhases(): Array<PhaseCardDto> {
    if (!this._data)
      return [];
    return this._data.phases.filter(p => this._pendingStates.indexOf(p.generalStatus) !== -1)
  }

  public getCompletedPhases(): Array<PhaseCardDto> {
    if (!this._data)
      return [];
    return this._data.phases.filter(p => this._pendingStates.indexOf(p.generalStatus) === -1)
  }

  ngOnInit() { }

  pretty(value) {
    return JSON.stringify(value);
  }
}
