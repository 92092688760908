import { Component, Input, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ManagementModelRequirementResponseLogDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementResponseLogDto';

@Component({
  selector: 'shared-managementmodel-requirements-log-component',
  templateUrl: './shared.managementmodel.requirements.log.component.html',
  styleUrls: ['./shared.managementmodel.requirements.log.component.scss'],
})
export class SharedManagementModelRequirementsLogComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  public _logs: ManagementModelRequirementResponseLogDto[];
  @Input()
  set logs(value: ManagementModelRequirementResponseLogDto[]) {
    if (this._logs === value)
      return;
    this._logs = value;
  }
  get logs(): ManagementModelRequirementResponseLogDto[] {
    return this._logs;
  }

  ngOnInit() {
    
  }

}
