import { Component, Input, OnInit } from '@angular/core';
import { InternalSupervisionController } from '@shared/src/controllers/internal/supervision/internal.supervision.controller';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';


@Component({
    selector: 'shared-supervision-component',
    templateUrl: './shared.supervision.component.html',
    styleUrls: ['./shared.supervision.component.scss'],
})
export class SharedSupervisionComponent implements OnInit {
    public data: any;
    public actions: ActionsStripDto;

    constructor(protected internalSupervisionController: InternalSupervisionController) {

    }

    onClickAction($event: ActionDto) {
        if ($event.actionId === "GETDATA") {
            this.reload();
        }
    }

    ngOnInit(): void {
        this.actions = ActionsStripDto.buildDefaultActionsForGetData();
        this.reload();
    }
    public reload() {
        this.internalSupervisionController.getSupervision().subscribe(x => {
            this.data = x;
        })
    }
    public pretty(value: any): string {
        return JSON.stringify(value);
    }


    public mailTo: string;
    public mailSubject: string;
    public mailBody: string;
    public mailRevisat: boolean = false;
    public mailFaltes: boolean = false;
    public mailProva: boolean = false;

    public mailSendToTenantId: boolean = false;
    public mailTenantId: string;
    public mailSendToEveryBody: boolean = false;


    public sendMail() {
        this.internalSupervisionController.sendNotification(this.mailTo, this.mailSubject, this.mailBody, this.mailSendToTenantId, this.mailTenantId, this.mailSendToEveryBody).subscribe(x => {
        })

    }
}
