import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { Router } from '@angular/router';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';

@Component({
  selector: 'shared-route-info-component',
  templateUrl: './shared.route.info.component.html',
  styleUrls: ['./shared.route.info.component.scss'],
})
export class SharedRouteInfoComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private phaseController: PhaseController,
    private routeController: RouteController, private shipmentController: ShipmentController,
    private routeCMRController: RouteCMRController,
    protected router: Router) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public fromActiveRoutes: boolean = false;
  @Input() public fromMaintenance: boolean = false;

  @Input() public readonly: boolean = false;

  public __route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null)
      this.__routeId = 0;
    else
      this.__routeId = value.routeId;
    this.validateSelectedItems();
  }
  get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }


  public __phaseId: number;
  @Input()
  set phaseId(value: number) {
    if (this.__phaseId === value)
      return;
    this.__phaseId = value;
    this.loadPhaseData();
  }
  get phaseId(): number {
    return this.__phaseId;
  }

  public __routePartCMRId: number;
  @Input()
  set routePartCMRId(value: number) {
    if (this.__routePartCMRId === value)
      return;
    this.__routePartCMRId = value;
    this.loadRoutePartCMRData();
  }
  get routePartCMRId(): number {
    return this.__routePartCMRId;
  }

  public validateSelectedItems() {
    if (this.route == null || this.route.phases == null || this.route.phases.length == 0)
      this.selectedPhase = null;
    if (this.route == null || this.route.shipments == null || this.route.shipments.length == 0)
      this.selectedShipment = null;

    if (this.selectedPhase) {
      let trobat = false;
      this.route.phases.forEach(s => { trobat = trobat || s.phaseId == this.selectedPhase.phaseId });
      if (!trobat)
        this.selectedPhase = null;
    }
    if (this.selectedShipment) {
      let trobat = false;
      this.route.shipments.forEach(s => { trobat = trobat || s.shipmentId == this.selectedShipment.shipmentId });
      if (!trobat)
        this.selectedShipment = null;
    }
  }

  public loadRouteData() {
    if(HLong.isNullOrNullLong(this.routeId)){
      return;
    }
    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
    });
  }
  public loadPhaseData() {
    var value;
    if (this.selectedPhase) {
      value = this.selectedPhase.phaseId;
      this.__routeId = this.selectedPhase.routeId;
    } else {
      value = this.phaseId;
    }
    if (value == null)
      return;
    this.phaseController.getPhase(value).subscribe(phase => {
      if (phase) {
        this.__routeId = phase.routeId;
        this.onPhaseUpdated(phase);
      }
    });
  }
  public loadShipmentData() {

    if (this.selectedShipment == null)
      return;

    if (this.selectedPhase == null) {
      this.shipmentController.getCardByShipment(this.selectedShipment.shipmentId).subscribe(data => {
        this.onShipmentUpdated(data);
      });
    } else {
      this.shipmentController.getShipment(this.selectedPhase.phaseId, this.selectedShipment.shipmentId).subscribe(data => {
        this.onShipmentUpdated(data);
      });
    }

  }
  public loadRoutePartCMRData() {
    var value: number;
    if (this.selectedRoutePartCMR) {
      value = this.selectedRoutePartCMR.routePartCMRId;
      this.__routeId = this.selectedRoutePartCMR.routeId;
    } else {
      value = this.routePartCMRId;
    }

    if(HLong.isNullOrNullLong(value)){
        return;
    }
    this.routeCMRController.getRoutePartCMR(value).subscribe(routePartCMR => {
      if (routePartCMR) {
        this.__routeId = routePartCMR.routeId;
        this.onRoutePartCMRUpdated(routePartCMR);
      }
    });
  }
  public onRefreshRoute(refreshRoute: boolean) {
    if (refreshRoute)
      this.loadRouteData();
  }

  onClickBack(event) {
    this.clickBack.next(true);
  }


  @Output() clickBack: EventEmitter<boolean> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;
  }
  @Output() phaseUpdated: EventEmitter<PhaseCardDto> = new EventEmitter();
  public onPhaseUpdated(value: PhaseCardDto) {
    if (this.selectedPhase === value)
      return;

    this.selectedPhase = value;

    if (this.route != null && this.route.phases != null && value != null) {
      this.route.phases.forEach((p, i) => {
        if (p.phaseId === value.phaseId)
          this.route.phases[i] = value;
      });
    }

    this.phaseUpdated.next(value);
  }
  @Output() shipmentUpdated: EventEmitter<ShipmentCardDto> = new EventEmitter();
  public onShipmentUpdated(value: ShipmentCardDto) {

    if (this.selectedShipment === value)
      return;

    this.selectedShipment = value;

    if (this.selectedPhase != null && this.selectedPhase.shipments != null && value != null) {
      this.selectedPhase.shipments.forEach((s, i) => {
        if (s.shipmentId === value.shipmentId)
          this.selectedPhase.shipments[i] = value;
      });
    }
  }
  @Output() routePartCMRUpdated: EventEmitter<RoutePartCMRDto> = new EventEmitter();
  public onRoutePartCMRUpdated(value: RoutePartCMRDto) {
    if (this.selectedRoutePartCMR === value)
      return;

    this.selectedRoutePartCMR = value;

    this.routePartCMRUpdated.next(value);
  }

  public selectedPhase: PhaseCardDto;
  public onSelectedPhase(value: PhaseCardDto) {

    if (value == null) {
      this.onPhaseUpdated(null);
      return;
    }

    this.phaseController.getPhase(value.phaseId).subscribe(phase => {
      if (phase) {
        this.routeId = phase.routeId;
        this.onPhaseUpdated(phase);
      }
    });
  }

  public selectedShipment: ShipmentCardDto;
  public onSelectedShipment(shipment: ShipmentCardDto) {

    if (shipment == null) {
      this.onShipmentUpdated(null);
      return;
    }

    var value;
    if (this.selectedPhase) {
      value = this.selectedPhase.phaseId;
    } else {
      value = this.phaseId;
    }

    if (value == null) {
      value = shipment.phaseId;
    }

    if (HLong.isNullOrNullLong(value)) {
      this.shipmentController.getCardByShipment(shipment.shipmentId).subscribe(data => {
        this.onShipmentUpdated(data);
      });
    } else {
      this.shipmentController.getShipment(value, shipment.shipmentId).subscribe(data => {
        this.onShipmentUpdated(data);
      });
    }

  }

  public selectedRoutePartCMR: RoutePartCMRDto;
  public onSelectedRoutePartCMR(value: RoutePartCMRDto) {

    if (value == null || HLong.isNullOrNullLong(value.routePartCMRId))  {
      this.onRoutePartCMRUpdated(null);
      return;
    }

    this.routeCMRController.getRoutePartCMR(value.routePartCMRId).subscribe(routepartcmr => {
      if (routepartcmr) {
        this.routeId = routepartcmr.routeId;
        this.onRoutePartCMRUpdated(routepartcmr);
      }
    });
  }

  public showDocumentaryRevision: boolean;
  public onShowDocumentaryRevisionChange(value: boolean) {
    this.showDocumentaryRevision = value;
  }

  

  public onRefreshPhase(value: boolean) {

    if (!value)
      return;

    this.loadPhaseData();
  }

  public onRefreshShipment(value: boolean) {
    if (!value)
      return;

    this.loadShipmentData();
  }

  public onRefreshRoutePartCMR(value: boolean) {

    if (!value)
      return;

    this.loadRoutePartCMRData();
  }

  public _selectedOption: string = "";
  @Output() selectedOptionChange: EventEmitter<String> = new EventEmitter();
  @Input()
  public set selectedOption(value: string) {
    if (this._selectedOption === value)
      return;
    this._selectedOption = value;

    switch (this._selectedOption) {
      case 'SHIPMENTS':
        this.selectedShipment = null;
        this.selectedPhase = null;
        this.selectedRoutePartCMR = null;
        break;
      case 'PHASES':
        this.selectedShipment = null;
        this.selectedRoutePartCMR = null;
        break;
      case 'CMR':
        this.selectedShipment = null;
        this.selectedPhase = null;
        break;
      default:
        break;
    }
  }
  public get selectedOption(): string {
    return this._selectedOption;
  }

  public clearSelectedRoute() {
    this.route = null;
    this.clearSelectedPhase();
    this.clearSelectedRoutePartCMR();
  }
  public clearSelectedPhase() {
    this.selectedPhase = null;
    this.clearSelectedShipment();
  }
  public clearSelectedShipment() {
    this.selectedShipment = null;
  }
  public clearSelectedRoutePartCMR() {
    this.selectedRoutePartCMR = null;
  }


  public goToActiveSlots() {

      if(!this.useIonic)
        window.open(this.homeController.createRouteIdLinkToActiveSlots(this.routeId), '_blank');
      else
        this.router.navigateByUrl(this.homeController.createRouteIdLinkToActiveSlotsMobile(this.routeId));    
  }


  ngOnInit() {

  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
