export enum PhonePrefix {
    Afghanistan_93 = '+93',
    Albania_355 = '+355',
    Algeria_213 = '+213',
    AmericanSamoa_1_684 = '+1-684',
    Andorra_376 = '+376',
    Angola_244 = '+244',
    Anguilla_1_264 = '+1_264',
    Antarctica_672 = '+672',
    AntiguaBarbuda_1_268 = '+1-268',
    Argentina_54 = '+54',
    Armenia_374 = '+374',
    Aruba_297 = '+297',
    Australia_61 = '+61',
    Austria_43 = '+43',
    Azerbaijan_994 = '+994',
    Bahamas_1_242 = '+1-242',
    Bahrain_973 = '+973',
    Bangladesh_880 = '+880',
    Barbados_1_246 = '+1-246',
    Belarus_375 = '+375',
    Belgium_32 = '+32',
    Belize_501 = '+501',
    Benin_229 = '+229',
    Bermuda_1_441 = '+1-441',
    Bhutan_975 = '+975',
    Bolivia_591 = '+591',
    Bosnia_387 = '+387',
    Botswana_267 = '+267',
    BouvetIsland_47 = '+47',
    Brazil_55 = '+55',
    BritishIndianOceanTerritory_246 = '+246',
    BritishVirginIslands_1_284 = '+1-284',
    Brunei_673 = '+673',
    Bulgaria_359 = '+359',
    BurkinaFaso_226 = '+226',
    Burundi_257 = '+257',
    Cambodia_855 = '+855',
    Cameroon_237 = '+237',
    Canada_1 = '+1',
    CapeVerde_238 = '+238',
    CaribbeanNetherlands_599 = '+599',
    CaymanIslands_1_345 = '+1-345',
    CentralAfricanRepublic_236 = '+236',
    Chad_235 = '+235',
    Chile_56 = '+56',
    China_86 = '+86',
    ChristmasIsland_61 = '+61',
    Colombia_57 = '+57',
    Comoros_269 = '+269',
    Congo_Brazzaville_242 = '+242',
    Congo_Kinshasa_243 = '+243',
    CookIslands_682 = '+682',
    CostaRica_506 = '+506',
    Croatia_385 = '+385',
    Cuba_53 = '+53',
    Cyprus_357 = '+357',
    CzechRepublic_420 = '+420',
    Denmark_45 = '+45',
    Djibouti_253 = '+253',
    Dominica_1_767 = '+1-767',
    Ecuador_593 = '+593',
    Egypt_20 = '+20',
    ElSalvador_503 = '+503',
    EquatorialGuinea_240 = '+240',
    Eritrea_291 = '+291',
    Estonia_372 = '+372',
    Ethiopia_251 = '+251',
    FalklandIslands_500 = '+500',
    FaroeIslands_298 = '+298',
    Fiji_679 = '+679',
    Finland_358 = '+358',
    France_33 = '+33',
    FrenchGuiana_594 = '+594',
    FrenchPolynesia_689 = '+689',
    FrenchSouthernTerritories_262 = '+262',
    Gabon_241 = '+241',
    Gambia_220 = '+220',
    Georgia_995 = '+995',
    Germany_49 = '+49',
    Ghana_233 = '+233',
    Gibraltar_350 = '+350',
    Greece_30 = '+30',
    Greenland_299 = '+299',
    Grenada_1_473 = '+1-473',
    Guadeloupe_590 = '+590',
    Guam_1_671 = '+1-671',
    Guatemala_502 = '+502',
    Guernsey_44 = '+44',
    Guinea_224 = '+224',
    Guinea_Bissau_245 = '+245',
    Guyana_592 = '+592',
    Haiti_509 = '+509',
    HeardMcDonaldIslands_672 = '+672',
    Honduras_504 = '+504',
    HongKong_852 = '+852',
    Hungary_36 = '+36',
    Iceland_354 = '+354',
    India_91 = '+91',
    Indonesia_62 = '+62',
    Iran_98 = '+98',
    Iraq_964 = '+964',
    Ireland_353 = '+353',
    IsleofMan_44 = '+44',
    Israel_972 = '+972',
    Italy_39 = '+39',
    Jamaica_1_876 = '+1-876',
    Japan_81 = '+81',
    Jersey_44 = '+44',
    Jordan_962 = '+962',
    Kazakhstan_7 = '+7',
    Kenya_254 = '+254',
    Kiribati_686 = '+686',
    Kuwait_965 = '+965',
    Kyrgyzstan_996 = '+996',
    Laos_856 = '+856',
    Latvia_371 = '+371',
    Lebanon_961 = '+961',
    Lesotho_266 = '+266',
    Liberia_231 = '+231',
    Libya_218 = '+218',
    Liechtenstein_423 = '+423',
    Lithuania_370 = '+370',
    Luxembourg_352 = '+352',
    Macau_853 = '+853',
    Macedonia_389 = '+389',
    Madagascar_261 = '+261',
    Malawi_265 = '+265',
    Malaysia_60 = '+60',
    Maldives_960 = '+960',
    Mali_223 = '+223',
    Malta_356 = '+356',
    MarshallIslands_692 = '+692',
    Martinique_596 = '+596',
    Mauritania_222 = '+222',
    Mauritius_230 = '+230',
    Mayotte_262 = '+262',
    Mexico_52 = '+52',
    Micronesia_691 = '+691',
    Moldova_373 = '+373',
    Monaco_377 = '+377',
    Mongolia_976 = '+976',
    Montenegro_382 = '+382',
    Montserrat_1_664 = '+1-664',
    Morocco_212 = '+212',
    Mozambique_258 = '+258',
    Myanmar_95 = '+95',
    Namibia_264 = '+264',
    Nauru_674 = '+674',
    Nepal_977 = '+977',
    Netherlands_31 = '+31',
    NewCaledonia_687 = '+687',
    NewZealand_64 = '+64',
    Nicaragua_505 = '+505',
    Niger_227 = '+227',
    Nigeria_234 = '+234',
    Niue_683 = '+683',
    NorfolkIsland_672 = '+672',
    NorthKorea_850 = '+850',
    NorthernMarianaIslands_1_670 = '+1-670',
    Norway_47 = '+47',
    Oman_968 = '+968',
    Pakistan_92 = '+92',
    Palau_680 = '+680',
    Palestine_970 = '+970',
    Panama_507 = '+507',
    PapuaNewGuinea_675 = '+675',
    Paraguay_595 = '+595',
    Peru_51 = '+51',
    Philippines_63 = '+63',
    PitcairnIslands_870 = '+870',
    Poland_48 = '+48',
    Portugal_351 = '+351',
    PuertoRico_1 = '+1',
    Qatar_974 = '+974',
    Romania_40 = '+40',
    Russia_7 = '+7',
    Rwanda_250 = '+250',
    Samoa_685 = '+685',
    SanMarino_378 = '+378',
    SaudiArabia_966 = '+966',
    Senegal_221 = '+221',
    Serbia_381 = '+381',
    Seychelles_248 = '+248',
    SierraLeone_232 = '+232',
    Singapore_65 = '+65',
    SintMaarten_1_721 = '+1-721',
    Slovakia_421 = '+421',
    Slovenia_386 = '+386',
    SolomonIslands_677 = '+677',
    Somalia_252 = '+252',
    SouthAfrica_27 = '+27',
    SouthGeorgiaSouthSandwichIslands_500 = '+500',
    SouthKorea_82 = '+82',
    SouthSudan_211 = '+211',
    Spain_34 = '+34',
    SriLanka_94 = '+94',
    StHelena_290 = '+290',
    StKittsNevis_1_869 = '+1_869',
    StLucia_1_758 = '+1_758',
    StMartin_590 = '+590',
    StPierreMiquelon_508 = '+508',
    StVincentGrenadines_1_784 = '+1-784',
    Sudan_249 = '+249',
    Suriname_597 = '+597',
    SvalbardJanMayen_47 = '+47',
    Swaziland_268 = '+268',
    Sweden_46 = '+46',
    Switzerland_41 = '+41',
    Syria_963 = '+963',
    SaoTomeandPrincipe_239 = '+239',
    Taiwan_886 = '+886',
    Tajikistan_992 = '+992',
    Tanzania_255 = '+255',
    Thailand_66 = '+66',
    Timor_Leste_670 = '+670',
    Togo_228 = '+228',
    Tokelau_690 = '+690',
    Tonga_676 = '+676',
    TrinidadTobago_1_868 = '+1-868',
    Tunisia_216 = '+216',
    Turkey_90 = '+90',
    Turkmenistan_993 = '+993',
    TurksCaicosIslands_1_649 = '+1-649',
    Tuvalu_688 = '+688',
    USOutlyingIslands_ = '+',
    USVirginIslands_1_340 = '+1-340',
    UK_44 = '+44',
    US_1 = '+1',
    Uganda_256 = '+256',
    Ukraine_380 = '+380',
    UnitedArabEmirates_971 = '+971',
    Uruguay_598 = '+598',
    Uzbekistan_998 = '+998',
    Vanuatu_678 = '+678',
    VaticanCity_39_06_698 = '+39-06-698',
    Venezuela_58 = '+58',
    Vietnam_84 = '+84',
    WallisFutuna_681 = '+681',
    WesternSahara_212 = '+212',
    Yemen_967 = '+967',
    Zambia_260 = '+260',
    Zimbabwe_263 = '+263',
}
