import { Component, ElementRef, ViewChild, Inject } from '@angular/core';
import { LocatorDto } from '@shared/src/controllers/maps/LocatorDto';
import { MapsController } from '@shared/src/controllers/maps/maps.controller';
import { ButtonToggleDto } from '../../dtos/button-toggle/ButtonToggleDto';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { MapMarcadorDto } from '@shared/src/services/map/map.service';
import { HTags } from '@shared/src/datatypes/HTags';
import { LocalizableObjectDto, LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HomeDto } from '@shared/src/dtos/home/HomeDto';
import { Router } from '@angular/router';
import { ClusteredLocatorDto } from '@shared/src/controllers/maps/ClusteredLocatorDto';
import { HString } from '@shared/src/datatypes/HString';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { RouteLocalizableObjectDto } from '@shared/src/controllers/maps/RouteLocalizableObjectDto';
import { PlateLocalizableObjectDto } from '@shared/src/controllers/maps/PlateLocalizableObjectDto';
import { SharedAsideFactory } from '../components.api';

@Component({
  selector: 'shared-lite-component',
  templateUrl: './shared.lite.component.html',
  styleUrls: ['./shared.lite.component.scss'],
})
export class SharedLiteComponent {

  private subject = new Subject();
  public extended = false;
  public extendedChils = false;
  public locator: LocatorDto;
  public locatorChilds: LocatorDto;
  public buttonToggle: ButtonToggleDto;
  public marcadors: Array<MapMarcadorDto>;

  public useIonic: boolean = false;
  // Llistat de clusters de telemetria
  public clusteredLocators: Array<ClusteredLocatorDto> = [];
  // Map amb id telemetria - posició de l'array de clusters que fa referència a aquella telemetria
  // NOTA: Ho he separat en dos variables perquè al haver LocalizableObjectDto que poden tenir la telemetryId a null, faria que
  // un suposat map telemetryId - LocalizableObjectDto sobreescrigués constantment la key null
  public mapClusteredLocatorsByTelemetryId: Map<number, number> = new Map<number, number>();

  constructor(private mapsController: MapsController,
    @Inject('SecurityController') protected securityController: SecurityController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
    protected socketController: SocketController,
    protected router: Router) {
    if (this.homeController.useIonic)
      this.useIonic = this.homeController.useIonic();
  }

  ngOnInit() {

    this.getLocator();

    this.socketController.notificationReloadLite$.subscribe(notification => {
      this.getLocator();
    })

    this.subject.debounceTime(1000).subscribe(value => {
      this.actualiceLocator();
    });

    this.homeController.homeChanged$.subscribe(value => {
      this.wellComeHome(value);
    })
    this.wellComeHome(this.homeController.HOME);
  }

  public requestMoreDrivers: boolean = false;
  public wellComeHome(home: HomeDto) {
    if (home == null) {
      this.requestMoreDrivers = false;
      return;
    }
    this.requestMoreDrivers = home.requestMoreDrivers;
  }

  public searchStringChanged($event) {
    this.subject.next(this.locator.searchString);
  }

  public onTagsToggleChagend() {
    this.actualiceLocator();
  }

  public nextExecution: HDateHour = HDateHour.now();
  public nextExecutionMinutes: number = 1;
  public getLocator() {
    if (HDateHour.isGreaterEqualsThan(HDateHour.now(), this.nextExecution)) {
      this.nextExecution = HDateHour.now().addMinutes(this.nextExecutionMinutes);
      this.mapsController.initiateLocator().subscribe(loc => {
        this.setLocator(loc);
      });
    }
  }

  actualiceLocator() {
    this.nextExecution = HDateHour.now().addMinutes(this.nextExecutionMinutes);
    this.mapsController.locator(this.locator).subscribe(loc => {
      this.setLocator(loc);
    });
  }

  public buildClusteredLocator(type: LocalizableObjectTypes, marcadors: Array<MapMarcadorDto>, clusteredLocators: Array<ClusteredLocatorDto>) {
    if (!this.locator.hasOwnProperty(type) || !this.locator[type])
      return;

    if (type == "route") {
      this.locator[type].forEach((obj: RouteLocalizableObjectDto) => {
        let clusteredByTelemetryId: ClusteredLocatorDto = null;

        // Si tenim telemetria buscarem si podem anidar-lo a algun cluster
        if (obj.globalTelemetry.telemetryId) {
          let position = this.mapClusteredLocatorsByTelemetryId.get(obj.globalTelemetry.telemetryId);
          if (position != undefined) {
            clusteredByTelemetryId = clusteredLocators[position];
          }
        }

        // Si podem anidar-lo i no estem buscant res l'anidem
        if (clusteredByTelemetryId != null && HString.isNullOrNullString(this.locator.searchString)) {
          clusteredByTelemetryId.sTags += " " + HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId[type].push(obj);
          // Anem omplint el hierarchy amb només els tipus que conté
          if (clusteredByTelemetryId.objectHierarchy.indexOf(type) === -1)
            clusteredByTelemetryId.objectHierarchy.push(type);
        } else {
          clusteredByTelemetryId = new ClusteredLocatorDto();
          clusteredByTelemetryId[type].push(obj);
          clusteredByTelemetryId.title = obj.key;
          clusteredByTelemetryId.description = obj.description;
          clusteredByTelemetryId.globalTelemetry = obj.globalTelemetry;
          clusteredByTelemetryId.sTags = HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId.objectHierarchy = [type];
          clusteredLocators.push(clusteredByTelemetryId);
          if (obj.globalTelemetry.telemetryId)
            this.mapClusteredLocatorsByTelemetryId.set(obj.globalTelemetry.telemetryId, clusteredLocators.length - 1);

          let marcador = MapMarcadorDto.builderByRouteLocalizableObject(obj);

          if (obj.globalTelemetry.isLost)
            marcador.lost();
          else if (obj.globalTelemetry.speed == 0)
            marcador.stop();
          else
            marcador.move();
          marcadors.push(marcador);
        }
      });
    } else if (type == "plate") {
      this.locator[type].forEach((obj: PlateLocalizableObjectDto) => {
        let clusteredByTelemetryId: ClusteredLocatorDto = null;

        // Si tenim telemetria buscarem si podem anidar-lo a algun cluster
        if (obj.globalTelemetry.telemetryId) {
          let position = this.mapClusteredLocatorsByTelemetryId.get(obj.globalTelemetry.telemetryId);
          if (position != undefined) {
            clusteredByTelemetryId = clusteredLocators[position];
          }
        }

        // Si podem anidar-lo i no estem buscant res l'anidem
        if (clusteredByTelemetryId != null && HString.isNullOrNullString(this.locator.searchString)) {
          clusteredByTelemetryId.sTags += " " + HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId[type].push(obj);
          // Anem omplint el hierarchy amb només els tipus que conté
          if (clusteredByTelemetryId.objectHierarchy.indexOf(type) === -1)
            clusteredByTelemetryId.objectHierarchy.push(type);
        } else {
          clusteredByTelemetryId = new ClusteredLocatorDto();
          clusteredByTelemetryId[type].push(obj);
          clusteredByTelemetryId.title = obj.key;
          clusteredByTelemetryId.description = obj.description;
          clusteredByTelemetryId.globalTelemetry = obj.globalTelemetry;
          clusteredByTelemetryId.sTags = HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId.objectHierarchy = [type];
          clusteredLocators.push(clusteredByTelemetryId);
          if (obj.globalTelemetry.telemetryId)
            this.mapClusteredLocatorsByTelemetryId.set(obj.globalTelemetry.telemetryId, clusteredLocators.length - 1);

          let marcador = MapMarcadorDto.builderByPlateLocalizableObject(obj);

          if (obj.globalTelemetry.isLost)
            marcador.lost();
          else if (obj.globalTelemetry.speed == 0)
            marcador.stop();
          else
            marcador.move();
          marcadors.push(marcador);
        }
      });
    } else {
      this.locator[type].forEach((obj: LocalizableObjectDto) => {
        let clusteredByTelemetryId: ClusteredLocatorDto = null;

        // Si tenim telemetria buscarem si podem anidar-lo a algun cluster
        if (obj.globalTelemetry.telemetryId) {
          let position = this.mapClusteredLocatorsByTelemetryId.get(obj.globalTelemetry.telemetryId);
          if (position != undefined) {
            clusteredByTelemetryId = clusteredLocators[position];
          }
        }

        // Si podem anidar-lo i no estem buscant res l'anidem
        if (clusteredByTelemetryId != null && HString.isNullOrNullString(this.locator.searchString)) {
          clusteredByTelemetryId.sTags += " " + HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId[type].push(obj);
          // Anem omplint el hierarchy amb només els tipus que conté
          if (clusteredByTelemetryId.objectHierarchy.indexOf(type) === -1)
            clusteredByTelemetryId.objectHierarchy.push(type);
        } else {
          clusteredByTelemetryId = new ClusteredLocatorDto();
          clusteredByTelemetryId[type].push(obj);
          clusteredByTelemetryId.title = obj.key;
          clusteredByTelemetryId.description = obj.description;
          clusteredByTelemetryId.globalTelemetry = obj.globalTelemetry;
          clusteredByTelemetryId.sTags = HTags.convertToStringWithDelimiter(obj.tags, " ");
          clusteredByTelemetryId.objectHierarchy = [type];
          clusteredLocators.push(clusteredByTelemetryId);
          if (obj.globalTelemetry.telemetryId)
            this.mapClusteredLocatorsByTelemetryId.set(obj.globalTelemetry.telemetryId, clusteredLocators.length - 1);

          let marcador = MapMarcadorDto.builderByLocalizableObject(obj);

          if (type == "address")
            marcador.adreça();
          else if (obj.globalTelemetry.isLost)
            marcador.lost();
          else if (obj.globalTelemetry.speed == 0)
            marcador.stop();
          else
            marcador.move();
          marcadors.push(marcador);
        }
      });
    }
  }

  public setLocator(locator: LocatorDto) {
    this.locator = locator;
    if (this.locator != null) {
      if (this.locator.objectHierarchy != null) {
        let marcadors: Array<MapMarcadorDto> = []
        let clusteredLocators = []
        this.mapClusteredLocatorsByTelemetryId = new Map<number, number>();
        this.minZoom = null;
        this.locator.objectHierarchy.forEach(type => {
          this.buildClusteredLocator(type, marcadors, clusteredLocators);
        });
        this.marcadors = marcadors;
        this.clusteredLocators = clusteredLocators;
      }
    }
  }

  private _selectedItem: ClusteredLocatorDto = null;
  public isSelected(object: ClusteredLocatorDto) {

    if (this._selectedItem === object)
      return true;

    return false;
  }

  public longitude: number;
  public latitude: number;
  public minZoom: number;
  public clickItem(object: ClusteredLocatorDto) {
    if (this._selectedItem != object) {
      this._selectedItem = object;
      this.longitude = object.globalTelemetry.longitude;
      this.latitude = object.globalTelemetry.latitude;
      this.minZoom = 15;
    }
  }

  private _selectedItemChild: LocalizableObjectDto = null;
  public clickItemChild(object: LocalizableObjectDto) {
    this._selectedItemChild = object;
    this.longitude = object.globalTelemetry.longitude;
    this.latitude = object.globalTelemetry.latitude;
  }

  public onMarkerClick(m: MapMarcadorDto) {
    this.clusteredLocators.forEach(obj => {
      if (obj.globalTelemetry.longitude == m.longitude && obj.globalTelemetry.latitude == m.latitude) {
        this.clickItem(obj);
        return;
      }
    });

  }
  public onMarkerDblClick(m: MapMarcadorDto) {
    this.sharedAsideFactory.invokeMapMarcadorDto(m);
  }

  public moreDriversClick() {
    if (this.useIonic)
      this.router.navigateByUrl('/system/link/driver/list');
    else
      this.router.navigateByUrl('/partner/driver');
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
