<form class="form" [formGroup]="formGroup" *ngIf="value">
    <div class="title">{{'ROUTEPARTCMR.ADDITIONALDATA.TITLE'| translate}}</div>
    <div class="block">
        <input-component class="col-x" [(ngModel)]="value.handlingAndTransportation"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.HANDLINGANDTRANSPORTATION.CAPTION' | translate}}" name="handlingAndTransportation" formControlName="handlingAndTransportation"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <div class="maintenance-input-x checkbox">
            <boolean-component class="col-x" [(ngModel)]="value.handlingAndTransportationSubstituteShipments"
                placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.HANDLINGANDTRANSPORTATIONSUBSTITUTESHIPMENTS.CAPTION' | translate}}" name="handlingAndTransportationSubstituteShipments" formControlName="handlingAndTransportationSubstituteShipments"
                [showHelpPoint]=false [readonly]="readonly">
            </boolean-component>
        </div>
        <input-component class="col-x" [(ngModel)]="value.applicant"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.APPLICANT.CAPTION' | translate}}" name="applicant" formControlName="applicant"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <input-component class="col-x" [(ngModel)]="value.payment"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.PAYMENT.CAPTION' | translate}}" name="payment" formControlName="payment"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <input-component class="col-x" [(ngModel)]="value.payer"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.PAYER.CAPTION' | translate}}" name="payer" formControlName="payer"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <input-component name="amount" formControlName="amount" class="col-20"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.AMOUNT.CAPTION' | translate}}" [(ngModel)]="value.amount" type="number" [readonly]="readonly">
        </input-component>
        <input-component class="col-x" [(ngModel)]="value.conditions"
            placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.CONDITIONS.CAPTION' | translate}}" name="conditions" formControlName="conditions"
            autocomplete="off" [readonly]="readonly">
        </input-component>
        <div class="maintenance-input-x checkbox">
            <boolean-component class="col-x" [(ngModel)]="value.conditionsSubstituteShipments"
                placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.CONDITIONSSUBSTITUTESHIPMENTS.CAPTION' | translate}}" name="conditionsSubstituteShipments" formControlName="conditionsSubstituteShipments"
                [showHelpPoint]=false [readonly]="readonly">
            </boolean-component>
        </div>
    </div>

    <button *ngIf="!readonly && pendingSave" class="confirmButton" (click)="onSaveChanges()" [disabled]="!isValid">{{'ROUTEPARTCMR.SAVE.BUTTON' | translate }}</button>

</form>
