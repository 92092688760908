<!--<p>dock-picker works!</p>-->
<div class="block selector-docks-container"
    [ngClass]="{'loadconsole': isloadconsole, 'canNotSetDock': modeByPhase && !phase.canSetDock,'canviDock':canviDock,'oneSelected': dockSelected != null && dockSelected.skin != null && dockSelected.skin.length > 0}">
    <div *ngIf="modeByPhase && hideTitle == false" class="titol">
        {{'SHARED.DOCK.PICKER.DOCKS.CAPTION'| translate}}
    </div>
    <div class="dock-seleccionat" *ngIf="dockSelected != null && dockSelected.dockId!=null  && dockSelected.dockId!=0">
        <div class="buttonInterval selected-item" [style.background-color]="getBackColor(dockSelected.backgroundColor)"
            [style.color]="getTextColor(dockSelected.backgroundColor)">
            {{ dockSelected.skin }}
        </div>
    </div>

    <div *ngIf="modeByPhase && !phase.canSetDock && dockSelected == null" class="block bloqueig"
        [innerHTML]="'SHARED.DOCK.PICKER.DOCKS.ISCANNOTSETDOCK' | translate"></div>
    <div class="slot-actiu"
        *ngIf="docks && docks.length > 0 && dockSelected && phase && phase.canSetDock && !sempreCanviDock">
        <button class="link right" (click)="toggleCanviDock()">{{'DESKTOP.DOCK.PICKER.COMPONENT.BUTTON.CANVIMOLL'|
            translate}}</button>
    </div>
    <div class="botonera-buttonInterval"
        *ngIf="(docks != null && docks.length > 0 && (dockSelected == null || canviDock || sempreCanviDock) && (!modeByPhase || (modeByPhase && phase!=null && phase.canSetDock)))">

        <div class="help">
            {{'DESKTOP.DOCK.PICKER.COMPONENT.HELP'| translate}}
        </div>

        <ng-container *ngFor="let item of docks | sortBy:'-dockScore'; let i=index">
            <div class="saltlinia" [ngClass]="getDockClass(item)"></div>
            <div class="buttonInterval" [ngClass]="getDockClass(item)"
                [style.background-color]="getBackColor(item.backgroundColor)"
                [style.color]="getTextColor(item.backgroundColor)" (click)="onDockSelected(item)">
                <div class="skin">{{ item.skin }}</div>
                <div class="score" *ngIf="item.dockScore!=0">{{item.dockScore}}</div>
                <i class="fa fa-exclamation-triangle only-occupied" aria-hidden="true"></i>
                <i class="fa fa-question-circle only-no-assignable" aria-hidden="true"></i>
                <i class="fa fa-ban only-disabled" aria-hidden="true"></i>
            </div>
        </ng-container>
        <div class="notDefinedDock" *ngIf="allowNotDefinedDocks">
            <input-component class="skin" [(ngModel)]="dockSkin" name="skin"
                placeholder="{{'ADDRESSDOCKOMPONENT.SKIN.LABEL' | translate}}">
            </input-component>
            <button class="add confirmButton" [disabled]="dockSkin == null || dockSkin.length == 0"
                (click)="setDockToPhaseId()">{{ "DESKTOP.DOCK.PICKER.COMPONENT.SAVENOTDEFINEDDOCK.BUTTON" |
                translate }}</button>
        </div>
    </div>
    <div *ngIf="docks == null || docks.length == 0" class="block selector-slots-container">
        <div class="loading-label">
            {{ 'SHARED.NOOPTIONS.CAPTION' | translate}}
        </div>
    </div>
</div>