<div class="column">
    <input-component *ngIf="!readonly" name="columnName" class="value nolabel" [ngClass]="{ 'ultraslim': !inline }"
        [(ngModel)]="columnData.columnName" [readonly]="readonly" (ngModelChange)="valueChangedEvent()">
    </input-component>

    <span *ngIf="readonly" name="columnName" class="ultraslim value nolabel">{{ columnData.columnName }}</span>
</div>
<div class="datatype">
    <enum-selector class="value dataType" [(ngModel)]="dataType" [readonly]="readonly" [enumvalues]="enumDataTypes"
        name="dataType" (valuechangefunction)="valueChangedDataType($event)" [translategroup]="'DATATYPES'"
        [showclosebutton]="false">
    </enum-selector>

    <div class="accions">
        <i aria-hidden="true" class="cercle fa fa-times-circle todeleteselect" (click)="onDeleteColumnSelected()"></i>
        <i aria-hidden="true" class="cercle fa fa-times-circle tocanceledevent mr3"
            (click)="onDeleteColumnCanceled()"></i>
        <i aria-hidden="true" class="cercle fa fa-trash todeleteevent" (click)="onDeleteColumn()"></i>
    </div>
</div>

<input-component name="order" class="column order integer" [(ngModel)]="columnData.order" [min]="0" type="number"
    [readonly]="readonly" (ngModelChange)="valueChangedEvent()"></input-component>