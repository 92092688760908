import { Validators, PatternValidator } from '@angular/forms';
import { Pattern } from 'estree';
import { AlertTypes } from '../enums/AlertTypes';
import { AlertService } from '../services/alert/alert.service';
import { Languages } from '../public-api';

export class HString {

    /***
     * Concatena 2 strings utilitzant el separdor només quan cal
     */
    static concat(a: string, sep: string, b: string): string {
        if (HString.isNullOrNullString(a))
            return b;
        if (HString.isNullOrNullString(b))
            return a;
        return a + sep + b;
    }

    static startsWith(a: string, b: string): boolean {
        if (a == b)
            return true;
        if (a != null)
            return a.startsWith(b);
        return false;
    }


    private static crcTable = HString.makeCRCTable();
    private static makeCRCTable() {
        var c;
        var crcTable = [];
        for (var n = 0; n < 256; n++) {
            c = n;
            for (var k = 0; k < 8; k++) {
                c = ((c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
            }
            crcTable[n] = c;
        }
        return crcTable;
    }

    public static crc(str) {
        var crcTable = HString.crcTable;
        var crc = 0 ^ (-1);

        for (var i = 0; i < str.length; i++) {
            crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
        }

        return (crc ^ (-1)) >>> 0;
    };

    public static isNullOrNullString(value: string) {
        if (value == null || value == undefined || value == "")
            return true;
        return false;
    }

    public static toUpperCase(value: string): string {
        if (value == null)
            return "";
        return value.toUpperCase();
    }
    public static toLowerCase(value: string): string {
        if (value == null)
            return "";
        return value.toLowerCase();
    }

    public static cleanSpaces(value: String): string {

        let result: string = "";
        if (!value || value.length == 0)
            return result;

        for (let i = 0; i < value.length; i++) {
            let t = value.substr(i, 1);
            result = result.trim() + t.trim();
        }
        return result;
    }

    public static pad(num, size): string {
        let snum = num + "";
        let s = "000000000" + num;
        return s.substr(s.length - Math.max(snum.length, size));
    }

    public static readonly EMAIL_REGEX_EXPRESSION = '';
    /***
     * Valida si la cadena donada és un email
     */
    public static isEmail(value: string): boolean {
        if (HString.isNullOrNullString(value))
            return false;

        value = value.trim();

        let result = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);

        return result;
    }


    /***
     * Converteix l'string donat en un string segur per utiltzar com a paràmetre get. Treu els caràcters raros i converteix els espais en +
     */
    public static toUrlParam(value: string): string {
        if (value == null)
            return "";
        return encodeURIComponent(value.trim().replace(/\//g, '').replace(' ', '+').replace('/', '')).replace(/[!'()]/g, escape).replace(/\*/g, "%2A");
    }


    public static copyMessage(val: string, alertService: AlertService = null) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (alertService != null)
            alertService.alert(AlertTypes.Info, "Copy done!");
    }

    /***
     * Fa el trim de forma segura
     */
    public static trim(arg0: string): string {
        if (arg0 == null)
            return "";
        return arg0.trim();
    }

    public static equals(a: string, b: string) {
        if (HString.isNullOrNullString(a) && !HString.isNullOrNullString(b))
            return false;
        if (!HString.isNullOrNullString(a) && HString.isNullOrNullString(b))
            return false;
        if (a != b)
            return false;

        return true;
    }

    public static equalsNull(a: string, b: string) {
        if (HString.isNullOrNullString(a) && HString.isNullOrNullString(b))
            return true;
        return this.equals(a, b);
    }

    static replaceAll(original: string, toBeReplaced: string, replace: string): string {
        return original.split(toBeReplaced).join(replace);
    }

    static includes(value: string, searchText: string): boolean {
        if (HString.isNullOrNullString(value))
            return false;
        if (HString.isNullOrNullString(searchText))
            return false;
        return value.includes(searchText);
    }

    public static compare(a: string, b: string): number {
        a = a.toLocaleLowerCase();
        b = b.toLocaleLowerCase();
        return a.localeCompare(b);
    }

    static toBase64ToArrayBuffer(base64: any): any {
        if (base64 == null)
            return base64;
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
}