import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { RoutePartnerExworkDto } from "@shared/src/dtos/route/partner/RoutePartnerExworkDto";
import { AlertService, HomeController, RouteController } from "@shared/src/public-api";
import { Subject } from "rxjs-compat";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'shared-route-externaldrivers-edit',
  templateUrl: './shared.route.externaldrivers.edit.component.html',
  styleUrls: ['./shared.route.externaldrivers.edit.component.scss']
})
export class SharedRouteExternalDriversEditComponent implements OnInit {

  public additoinalDriverPhonesChange: Subject<string> = new Subject<string>();

  constructor(@Inject("HomeController") protected homeController: HomeController,
    @Inject("AlertService") protected alertService: AlertService,
    protected openRouteController: OpenRouteController,
    protected routeController: RouteController) { }

  ngOnInit(): void {

    this.additoinalDriverPhonesChange.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.openRouteController.setAdditionalDriverPhone(this.value.writeUuid, this.value.additionalDriversPhones).subscribe(data => {
          if (data)
            this.refresh.next(true);
        });
      });

  }

  @Input() public readonly: boolean;

  @Input() public value: RoutePartnerExworkDto;

  @Output() public refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  
  go() {
    if (this.value)
      this.homeController.routeByUuid(this.value.writeUuid);
  }
  copy() {
    this.alertService.copyToClipboard(this.homeController.getRouteByUuid(this.value.writeUuid)).subscribe();
  }
  renewUuid() {
    this.openRouteController.renewWriteUuid(this.value.routeId).subscribe(data => {
      if (data) {
        this.value.writeUuid = data;
        this.refresh.next(true);
      }
    })
  }
  resendAll() {
    this.openRouteController.resendAllAdditionalDriversByRouteId(this.value.routeId).subscribe(data => {
      if (data)
        this.refresh.next(true);
    })
  }
}