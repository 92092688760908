import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { ShipmentItemMaintenanceCtrlDto } from '@shared/src/dtos/route/ShipmentItemMaintenanceCtrlDto';
import { ShipmentDto } from '@shared/src/dtos/shipment/ShipmentDto';
import { ShipmentLogDto } from '@shared/src/dtos/shipment/ShipmentLogDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentsDto } from '@shared/src/dtos/document/DocumentsDto';
import { SecurityController } from '../security/security.controller';
import { BaseController } from '../base.controller';

@Injectable({
    providedIn: 'root',
})
export class PublicFileController extends BaseController {

    private base = '/publicfile'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getCMRDocumentsByShipmentReadUUID(readuuid: string, documentid: number): Observable<DocumentsDto> {
        return this.get(this.base + "/cmr/shipment/" + readuuid + "/" + documentid);
    }
    
    public downloadShipmentDocumentDetail(readuuid: string, documentDetailId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/shipment/" + readuuid + "/documentdetail/" + documentDetailId)
    }
}
