import { TimeZones } from "../../../enums/TimeZones";
import { ChatParticipantTypes } from "../../../enums/ChatParticipantTypes";
import {Injectable} from '@angular/core';

@Injectable()
export class ParticipantDto {
    
	public participantId: number;
	public participantType: ChatParticipantTypes;
	public skin: string;
	public participantAnnotation: string;
	public description: string;
	public timeZone: TimeZones;

	public __order: string;

	public static buildByParams(participantId: number, participantType: ChatParticipantTypes): ParticipantDto{

		let result = new ParticipantDto();
		result.participantId = participantId;
		result.participantType = participantType;
		return result;
	}
}