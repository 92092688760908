
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class AddressEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): AddressEntitySelectorDto{
        let result: AddressEntitySelectorDto = new AddressEntitySelectorDto();
		result.loadData(EntitysSelectors.Address, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "activity/address";
	}

	public getUrlBack(): string {
		return "/maintenance/address";
	}
	
}