<div [formGroup]="rFormDeliveryLine" class="line"
    [ngClass]="{'showDeleteButton': showDeleteButton(), 'readonly': readonly, 'expandedLine': expandedLine}"
    *ngIf="lineObject">
    <div class="first-line">
        <div class="value lineNumber" [ngClass]="{'m-line-number' : useIonic}">{{ lineObject.line }}</div>
        <input-component name="quantity" formControlName="quantity" class="ultraslim value quantity nolabel"
            placeholder="{{'SHIPMENTDELIVERY.QUANTITY.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
            [(ngModel)]="lineObject.quantity" [min]="0" type="number" [readonly]="readonly"></input-component>
        <input-component name="reference" formControlName="reference" class="ultraslim value reference nolabel"
            [ngClass]="{'m-reference' : useIonic}" placeholder="{{'SHIPMENTDELIVERY.REFERENCE.CAPTION' | translate}}"
            (ngModelChange)="valueChangedEvent()" [(ngModel)]="lineObject.reference" [readonly]="readonly">
        </input-component>
        <input-component name="description" formControlName="description" class="ultraslim value description nolabel"
            [ngClass]="{'m-description' : useIonic}"
            placeholder="{{'SHIPMENTDELIVERY.DESCRIPTION.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
            [(ngModel)]="lineObject.description" [readonly]="readonly">
        </input-component>
        <input-component name="unitPrice" formControlName="unitPrice" class="ultraslim value unitPrice nolabel"
            placeholder="{{'SHIPMENTDELIVERY.UNITPRICE.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
            [(ngModel)]="lineObject.unitPrice" type="number" [readonly]="readonly">
        </input-component>
        <input-component name="unitPrice" formControlName="totalPrice" class="ultraslim value totalPrice nolabel"
            placeholder="{{'SHIPMENTDELIVERY.TOTALPRICE.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
            [(ngModel)]="lineObject.totalPrice" type="number" [readonly]="readonly"></input-component>

        <div class="value button-sub-line" (click)="onExpandedLine()">
            <i aria-hidden="true" class="cercle fa fa-chevron-down nomesexpanded"></i>
            <i aria-hidden="true" class="cercle fa fa-chevron-right noexpanded"></i>
        </div>
    </div>

    <div class="second-line nomesexpanded">
        <div class="line">
            <input-component class="value kg" name="kg" formControlName="kg"
                placeholder="{{'SHIPMENTDELIVERY.KG.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
                [(ngModel)]="lineObject.kg" [min]="0" type="number" [readonly]="readonly"></input-component>
            <input-component class="value wDimension" name="wDimension" formControlName="wDimension"
                placeholder="{{'SHIPMENTDELIVERY.WDIMENSION.CAPTION' | translate}}"
                (ngModelChange)="valueChangedEvent()" [(ngModel)]="lineObject.wDimension" [min]="0" type="number"
                [readonly]="readonly"></input-component>
            <input-component class="value yDimension" name="yDimension" formControlName="yDimension"
                placeholder="{{'SHIPMENTDELIVERY.YDIMENSION.CAPTION' | translate}}"
                (ngModelChange)="valueChangedEvent()" [(ngModel)]="lineObject.yDimension" [min]="0" type="number"
                [readonly]="readonly"></input-component>
            <input-component class="value hDimension" name="hDimension" formControlName="hDimension"
                placeholder="{{'SHIPMENTDELIVERY.HDIMENSION.CAPTION' | translate}}"
                (ngModelChange)="valueChangedEvent()" [(ngModel)]="lineObject.hDimension" [min]="0" type="number"
                [readonly]="readonly"></input-component>
            <input-component class="value m3" name="m3" formControlName="m3"
                placeholder="{{'SHIPMENTDELIVERY.M3.CAPTION' | translate}}" (ngModelChange)="valueChangedEvent()"
                [(ngModel)]="lineObject.m3" [min]="0" type="number" [readonly]="readonly"></input-component>
            <input-component class="value palletCount" name="palletCount" formControlName="palletCount"
                placeholder="{{'SHIPMENTDELIVERY.PALLETCOUNT.CAPTION' | translate}}"
                (ngModelChange)="valueChangedEvent()" [(ngModel)]="lineObject.palletCount" [min]="0" type="number"
                [readonly]="readonly"></input-component>
            <enum-selector class="value palletType" [(ngModel)]="lineObject.palletType" [readonly]="readonly"
                [enumvalues]="enumPalletTypes" placeholder="{{'SHIPMENTDELIVERY.PALLETTYPE.CAPTION' | translate}}"
                name="palletType" (ngModelChange)="valueChangedEvent()" formControlName="palletType"
                [translategroup]="'PALLETTYPES'" [showclosebutton]="false">
            </enum-selector>
            <entity-selector class="col-20" placeholder="{{'SHIPMENTDELIVERY.PACKAGE.CAPTION' | translate}}"
                [(ngModel)]="lineObject.packageId" (ngModelChange)="valueChangedEvent()" [entitySelector]="'Package'" [readonly]="readonly" name="packageId"
                formControlName="packageId" [fixedFilterKeys]="packageFixedFilterKeys" [showgotomaintenance]="false">
            </entity-selector>
            <tags-chips placeholder="{{ 'SHIPMENTDELIVERY.USERTAGS.CAPTION' | translate }}"
                [(ngModel)]="userTags" (ngModelChange)="userTagsChangedEvent($event)" name="userTags"
                formControlName="userTags" [readonly]="readonly">
            </tags-chips>
        </div>
        <div class="deleteZone" *ngIf="!readonly">
            <button *ngIf="showDeleteButton()" class="deleteButton" (click)="onDeleteSelectedEvent(lineObject)"
                [disabled]="readonly">{{ 'DELETE.BUTTON' | translate }}</button>

            <div *ngIf="!showDeleteButton()" class="deleteForm">
                <div class="buttonZone">
                    <button class="cancelButton" (click)="onDeleteSelectedEvent(null)">{{'CANCEL.BUTTON' | translate
                        }}</button>
                    <button class="confirmButton" (click)="onDeleteLine()">{{'CONFIRM.BUTTON' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>