import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '../../../controllers/home/home.controller';
import { HEnum } from '@shared/src/datatypes/HEnum';
import { SituacionsEnLesQueEncaixes } from '@shared/src/enums/SituacionsEnLesQueEncaixes';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';

/***
 * Aquesta directiva no te sentit. No puc jugar a pingpong. A on haig d'anar, en quin estat estic?
 */
@Component({
  selector: 'shared-register-final',
  templateUrl: './shared.register.final.component.html',
  styleUrls: ['./shared.register.final.component.scss']
})
export class SharedRegisterFinalComponent implements OnInit {

  public generalClass: string = "desktop";

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();

  }

  ngOnInit() { }

  public canRegister(): boolean {
    return true;
  }

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  @Input()
  public registerData: UserRegisterWizardDto = new UserRegisterWizardDto;

  @Input() 
  public pendigResponse: boolean;
  
  next() {
    if(this.pendigResponse)
      return;
    this.onClickNext.next(this.registerData);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
