import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { QRCodeController } from '@shared/src/controllers/qrcode/qrcode.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { HString } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-cmr-qr-component',
  templateUrl: './shared.phase.cmr.qr.component.html',
  styleUrls: ['./shared.phase.cmr.qr.component.scss'],
})
export class SharedPhaseCMRQRComponent {

  constructor(private qRCodeController: QRCodeController,
    @Inject('HomeController') protected homeController: HomeController) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };

  public useIonic: boolean = false;

  private _phaseUUID: string;
  @Input()
  public set phaseUUID(value: string) {
      if (this._phaseUUID === value)
          return;
      this._phaseUUID = value;
      this.loadQR();
  }
  public get phaseUUID(): string {
      return this._phaseUUID;
  }

  ngOnInit() {

  }

  onClickQR(){
    if (!HString.isNullOrNullString(this.phaseUUID))
        this.homeController.phaseToExworkCMRsUUIDQR(this.phaseUUID);   
  }

  public filedto: FileUpDownLoadDto;
  public formatDocumentQRCode: string;
  loadQR() {
    if (!HString.isNullOrNullString(this.phaseUUID)) {
        this.qRCodeController.getQRCodePhaseCMRByPhaseUUID(this.phaseUUID).subscribe(data => { 
        if(data == null){
            this.filedto = null;
        }else
            this.filedto = data.qr;
        if(this.filedto != null && this.filedto.fileBytes != null)
            this.formatDocumentQRCode = 'data:image/' + this.filedto.fileType + ';base64,' + this.filedto.fileBytes
        });
    }
  }
}
