import { HDate } from '@shared/src/public-api';
import { ShipmentCardDto } from '../../shipment/ShipmentCardDto';
import { ShipmentCreateBaseDto } from './ShipmentCreateBaseDto';

export class ShipmentReturnInBoundOutBoundDelegatedDto extends ShipmentCreateBaseDto {
	public returnedShipmentId: number;

	public originDateStart: HDate;
	public originDateEnds: HDate;
	public destinationDateStart: HDate;
	public destinationDateEnds: HDate;
	public shipmentKey: string;

	public oldGoods: string;

	static buildFromShipmentCardDto(shipment: ShipmentCardDto): ShipmentReturnInBoundOutBoundDelegatedDto {
		let result = new ShipmentReturnInBoundOutBoundDelegatedDto();
		result.returnedShipmentId = shipment.shipmentId;
		result.goods = shipment.goods;
		result.oldGoods = shipment.goods;
		return result;
	}
}
