<!--directiva: shared.chart.component.html!-->
<ng-container *ngIf="_value!=null">
    <div class="canvas title">{{_value.title}}</div>
    <canvas #chart baseChart [data]="_value.data" [labels]="_value.labels" [options]="_value.options"
        [legend]="_value.legend" [chartType]="_value.chartType" *ngIf="isData()" (chartClick)="onChartClick($event)"
        [colors]="_value.backgroundColor"></canvas>

    <canvas #chart baseChart [datasets]="_value.datasets" [labels]="_value.labels" [options]="_value.options"
        [legend]="_value.legend" [chartType]="_value.chartType" *ngIf="isDataSets()"
        (chartClick)="onChartClick($event)"></canvas>

    <div class="selected-label" [ngClass]="{'show': showSelectedLabel}">{{selectedlabel}}</div>
</ng-container>