import { Component, EventEmitter, Output, Input, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder, FormControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { EntitySelectorController } from '@shared/src/controllers/base/entity-selector/entity.selector.controller';
import { EntitySelectorItemDto } from '@shared/src/dtos/base/entity-selector/EntitySelectorItemDto';
import { EntitysSelectors } from '@shared/src/enums/EntitysSelectors';
import { EntitySelectorDto } from '@shared/src/dtos/base/entity-selector/EntitySelectorDto';
import { Router } from "@angular/router"
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HString } from '@shared/src/datatypes/HString';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UserSettingController } from '@shared/src/controllers/user/usersetting.controller';

@Component({
  selector: 'entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: EntitySelectorComponent, multi: true }]
})

export class EntitySelectorComponent extends ValueAccessorValidatedBase<any, any> {

  rForm: FormGroup;
  selectorCtrl: FormControl;
  public useIonic: boolean = false;

  private SHOWLISTFASE: boolean = false;

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {

    this.selectorCtrl = new FormControl('', validators, asyncvalidators);
    this.rForm = this.fb.group({
      'entityselectorvalidator': this.selectorCtrl
    });
  }

  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    protected entitySelectorController: EntitySelectorController, protected userSettingController: UserSettingController,
    protected router: Router,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();

    this.clickedOutside$.subscribe(data => {
      this.onLostFocus();
    });
  }

  public itemselected: boolean;
  public showList: boolean = false;
  public showOpen: boolean = false;
  public listitems: EntitySelectorItemDto[];
  private selectorloaded: boolean;
  public primeraLlistaCarregada: boolean = false;
  public showGoToMaintenanceInternal: boolean = false;
  public showDeleteIemBackInternal: boolean = false;
  public _entitySelectorCtrl: EntitySelectorDto;

  public searchButtonClicked: boolean = false;
  public containerClicked: boolean = false;

  @Input() public floatLabel: string;
  @Input() public placeholder: string;
  @Input() public showgotomaintenance: boolean;
  @Input() public showdeleteitemback: boolean;
  @Input() public readonly: boolean;
  @Input() public autoselectwhenone: boolean = false;

  @Input() public searchBeforeFocus: boolean = false;
  @Output() public hasResults: EventEmitter<boolean> = new EventEmitter<boolean>();
  private hasResultsEmmited: boolean = false;

  @Input() public preventGoToMaintenanceInternal: boolean = false;
  @Output() public onGoToMaintenanceInternal: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    super.ngOnInit();
    this.listitems = undefined;
    if (this.selectedObject != null)
      this.listitems = [this.selectedObject];

    this.selectorloaded = false;
    this.primeraLlistaCarregada = false;

    document.addEventListener('click', function (e) {
      //if (!this.searchButtonClicked && !this.containerClicked) {
      //this.clickedOutside$.next(true);
      //}
    }, false);

    if (this.searchBeforeFocus) {
      this.loadSelectorValueList(false);
    }
  }
  @ViewChild('input') input: ElementRef;
  ngAfterViewInit() {
    // server-side search
    if (this.input && this.input.nativeElement) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap((text) => {
            this.searchText(false, false);
          })
        )
        .subscribe();
    }
  }


  public clickedOutside$: EventEmitter<boolean> = new EventEmitter();

  private _entitySelector: EntitysSelectors;
  @Input() set entitySelector(value: EntitysSelectors) {
    if (this._entitySelector === value)
      return;
    this._entitySelector = value;
    this.loadEntitySelector(EntitySelectorController.buildCtrl(this._entitySelector, this.fixedFilterKeys));
  }
  get entitySelector(): EntitysSelectors {
    return this._entitySelector;
  }

  private _fixedFilterKeys: Array<String>;
  @Input() set fixedFilterKeys(value: Array<String>) {
    if (this._fixedFilterKeys === value)
      return;
    this._fixedFilterKeys = value;
    let refresh = false;
    if (this._entitySelector != null) {
      refresh = true;
      this.loadEntitySelector(EntitySelectorController.buildCtrl(this._entitySelector, this.fixedFilterKeys));
    }
    if (this.autoselectwhenone || refresh)
      this.refreshSelector();
  }
  get fixedFilterKeys(): Array<String> {
    return this._fixedFilterKeys;
  }

  public refreshSelector() {
    this.selectorloaded = false;
    this.primeraLlistaCarregada = false;
    if (this._entitySelectorCtrl != null)
      this._entitySelectorCtrl.page = 0;
    if (this.autoselectwhenone)
      this.runAutoSelectWhenOne();
    if (this.showList) {
      if (this._entitySelectorCtrl != null)
        this.searchText(false, false);
    }
  }

  private runAutoSelectWhenOne() {
    if (this._entitySelectorCtrl != null) {
      this.searchText(false, true);
    }
  }

  private _selectedObject: EntitySelectorItemDto;
  @Input() set selectedObject(selectedvalue: EntitySelectorItemDto) {

    if (this._selectedObject === selectedvalue)
      return;

    let valuechange = this.getvalue(this._selectedObject) != this.getvalue(selectedvalue);

    this._selectedObject = selectedvalue;
    if (!selectedvalue || EntitySelectorItemDto.isNullOrNullId(selectedvalue.id))
      this.itemselected = false;

    if (valuechange) {
      if (this._selectedObject != null && this._entitySelectorCtrl != null && this._entitySelectorCtrl.haDeSumarAContador) {
        let entitySelector: string = this._entitySelector.toString();
        let id: number = this._selectedObject.id;
        if (this.fixedFilterKeys == undefined || this.fixedFilterKeys == null)
          this.fixedFilterKeys = [];
        this.userSettingController.updateEntitySelectorCounters(entitySelector, id, this.fixedFilterKeys).subscribe(data => { });
      }

      this.selectedobjectchangefunction.next(this._selectedObject);
    }
  }
  get selectedObject(): EntitySelectorItemDto {
    return this._selectedObject;
  }

  dvalue: string;
  get displayvalue(): string {
    return this.dvalue;
  }

  set displayvalue(componentvalue: string) {
    this.dvalue = componentvalue;
    if (componentvalue == "") {
      this.value = null;
      this.itemselected = false;
    }
  }

  public isFocused: boolean = false;
  private _searchTextString: string;
  get searchTextString(): string {
    if (!this.isFocused || this.readonly)
      return this.dvalue;
    return this._searchTextString;
  }

  set searchTextString(componentvalue: string) {
    if (this.readonly)
      return;
    if (this.isFocused && componentvalue != this.dvalue) {
      this._searchTextString = componentvalue;
    }
  }

  externalValue(value: any): any {
    let liste: EntitySelectorItemDto = this.getFromList(value);
    if (liste)
      return value;
    return null;
  }
  internalValue(value: any): any {
    // Son iguals
    if (value === this.value)
      return value;

    this.itemselected = false;

    if (EntitySelectorItemDto.isNullOrNullId(value) && EntitySelectorItemDto.isNullOrNullId(this.value))
      return value;

    this.loadDisplayFromId(value);

    this.itemselected = true;

    return value;
  }

  itemSelected(item: EntitySelectorItemDto) {

    this.displayvalue = this.getDisplayFromKey(item.id);

    if (this.value == item.id)
      return;
    this.value = item.id;
    if (EntitySelectorItemDto.isNullOrNullId(this.value))
      this.itemselected = false;

    this.onClose();
    this.isFocused = false;
    this._searchTextString = "";
    this.selectedObject = this.getFromList(this.value);
    this.valuechangefunction.next(this.value);
  }

  getFromList(keyvalue: any): EntitySelectorItemDto {
    if (this.listitems == null || this.listitems.length <= 0)
      return null;
    for (let entry of this.listitems) {
      if (this.getvalue(entry) == keyvalue)
        return entry;
    }
    return null;

  }

  getvalue(object: EntitySelectorItemDto): any {
    if (object == null)
      return null;

    return object.id;
  }

  /**
   * Retorna el texte a mostrar a partir del identificador d'un element de la llista
   * @param keyvalue index de l'element
   */
  getDisplayFromKey(keyvalue: any) {
    if (!keyvalue && keyvalue != 0)
      return keyvalue;

    let liste: EntitySelectorItemDto = this.getFromList(keyvalue);
    return this.getDisplayFromObject(liste);
  }

  /**
   * Retorna el texte a mostrar a partir d'un objecte de la lista
   * @param object Objecte element
   */
  getDisplayFromObject(object: EntitySelectorItemDto) {
    if (!object) {
      return "";
    }

    let sdisplay: string = "";

    if (object.firstColumn != null && object.firstColumn != "")
      sdisplay = object.firstColumn;
    if (sdisplay != null && sdisplay != "" && object.secondColumn != null && object.secondColumn)
      sdisplay += " ";
    if (object.secondColumn != null && object.secondColumn)
      sdisplay += object.secondColumn;

    return sdisplay;
  }



  //
  /** Carrega el literal a mostrar (displayvalue) i carrega nomes l'item seleccionat a la llista de items */
  loadDisplayFromId(value: any) {

    // Si el selector ja està carregat no cal fer res
    // No cal carregar l'objecte perquè hauria d'estar al selector o Si no tenim informat el tipus de selector no podem fer res
    if (this.selectorloaded || !this._entitySelectorCtrl) {
      this.displayvalue = this.getDisplayFromKey(value);
      this._entitySelectorCtrl.searchText = this._searchTextString;
      this.value = value;
      if (EntitySelectorItemDto.isNullOrNullId(this.value))
        this.itemselected = false;
      return;
    }

    // En funció del valor actuem
    if (!value) {
      this.selectedObject = null;
      this.displayvalue = this.getDisplayFromKey(value);
      this._entitySelectorCtrl.searchText = this._searchTextString;
      this.value = value;
      if (EntitySelectorItemDto.isNullOrNullId(this.value))
        this.itemselected = false;
    } else {

      //Com no tenim carregada la llista, fiquem l'objecte i una llista d'un element
      this.entitySelectorController.getEntitySelectorItem(this._entitySelectorCtrl, value).subscribe(data => {
        if (data != null)
          this.listitems = [data];
        this.selectedObject = data;
        this.value = this.getvalue(data);
        this.displayvalue = this.getDisplayFromObject(data);
        this._entitySelectorCtrl.searchText = this._searchTextString;
      });
    }
  }

  focusFunction() {
    if (this.readonly)
      return;
    this.showOpen = true;
    this.isFocused = true;
  }
  focusOutFunction() {
    this.onLostFocus();
  }

  public loading: boolean = false;
  /**
   * Carrega la llista de valors del selector
   */
  loadSelectorValueList(forcedshowlist: boolean) {

    //Si el selector ja està carregat no cal fer res
    if (this.selectorloaded) {
      if (forcedshowlist && this.listitems != null && this.listitems.length > 0)
        this.showList = true;
      return;
    }

    // Tenim informat el tipus de selector no podem fer res
    if (!this._entitySelectorCtrl) {
      if (forcedshowlist && this.listitems != null && this.listitems.length > 0)
        this.showList = true;
      return;
    }
    this.loading = true;
    this._entitySelectorCtrl.searchText = this._searchTextString;
    this.entitySelectorController.getEntitySelectorItems(this._entitySelectorCtrl).subscribe(data => {
      if (data)
        if (!this.hasResultsEmmited) {
          if (data.items.length == 0)
            this.hasResults.emit(false);
          else if (data.items.length > 0)
            this.hasResults.emit(true);
          this.hasResultsEmmited = true;
        }

      this.loadEntitySelector(EntitySelectorController.buildCtrlByCtrl(data));
      this.loading = false;
    });

  }

  loadEntitySelector(entityselectorctrl: EntitySelectorDto) {
    this._entitySelectorCtrl = entityselectorctrl;
    this.showGoToMaintenanceInternal = this._entitySelectorCtrl != null && this._entitySelectorCtrl.urlGoToMaintenance != null && this._entitySelectorCtrl.urlGoToMaintenance != "";
    this.showDeleteIemBackInternal = this._entitySelectorCtrl != null && this._entitySelectorCtrl.canDeleteItem;
    this.listitems = this._entitySelectorCtrl.items;
    if (this.listitems != null)
      this.showList = true;

    this.selectorloaded = true;
    this.primeraLlistaCarregada = true;

    if (this.autoselectwhenone) {
      if (this.listitems && this.listitems.length == 1) {
        this.itemSelected(this.listitems[0]);
      }
    }
  }




  onContainerClick(event: MouseEvent) {
    this.containerClicked = true;
    setTimeout(() => {
      this.containerClicked = false;
    }, 200);

    if (this.readonly)
      return;

    this.isFocused = true;
    this.searchText(false, false);
  }


  onLostFocus() {
    setTimeout(() => {
      if (!this.searchButtonClicked && this.showOpen) {
        let object: EntitySelectorItemDto = this.getFromList(this.value);
        if (object != null)
          this.displayvalue = this.getDisplayFromObject(object);
        this.onClose();
        this.isFocused = false;
      }
    }, 400);
  }

  onClose() {
    this.showOpen = false;
    this.showList = this.SHOWLISTFASE;
  }

  onClear() {
    this.value = "";
    this.itemselected = false;
    this.displayvalue = "";
    this.valuechangefunction.next(this.value);
  }

  searchText(clicked: boolean, avoidOpen: boolean) {
    if (this.readonly)
      return;
    let element: Element = document.getElementById("searchButton");
    this.searchButtonClicked = clicked;
    setTimeout(() => {
      this.searchButtonClicked = false;
    }, 200);
    //Si es el mateix text que s'ha buscat a últim cop, no tornem a fer cerca
    if (!avoidOpen) {
      this.showOpen = true;
      this.showList = true;
    }
    if (this.listitems && this.listitems.length > 0 && this.primeraLlistaCarregada &&
      HString.equals(this._entitySelectorCtrl.searchText, this._searchTextString)) {
      // Això estava abans però m'adono que quan no has seleccionat res, no et permet buscar res la segona vegada. S'ha de buscar si o si quan el texte és diferent
      //      (this._entitySelectorCtrl.searchText == this._searchTextString
      // || this._entitySelectorCtrl.searchText == null && this.displayvalue == ""
      // || this._entitySelectorCtrl.searchText == "" && this.displayvalue == null)) 
      return;
    }


    if (this.itemselected == true)
      this._entitySelectorCtrl.searchText = "";
    else
      this._entitySelectorCtrl.searchText = this._searchTextString;

    this.selectorloaded = false;
    this.primeraLlistaCarregada = false;
    if (this._entitySelectorCtrl != null)
      this._entitySelectorCtrl.page = 0;
    this.loadSelectorValueList(true);
  }

  /***
     * Busquem la següent pàgina
     */
  public onScroll() {
    if (!this.primeraLlistaCarregada) //Només fem scroll després de la primera llista Carregada
      return;
    this._entitySelectorCtrl.page += 1;
    this.entitySelectorController.getEntitySelectorItems(this._entitySelectorCtrl).subscribe(data => {
      this.unionListOfEntitySelectorItemDto(data.items);
    });
  }

  public unionListOfEntitySelectorItemDto(items: EntitySelectorItemDto[]) {
    //A diferencia del wellcome aquí tinc que fusionar les dues llistes
    if (this.listitems == null || this.listitems.length == 0) {
      this.listitems = items;
      return;
    }

    //Afegeixo a la meva llista aquells que m'han vingut del back i que no tinc a la meva llista
    items.forEach(v => {
      if (this.listitems.filter(f => { v.id == f.id }).length == 0) {
        this.listitems.push(v);
      }
    });
  }

  deleteIemBack(item: EntitySelectorItemDto,) {
    if (item != null) {
      this.entitySelectorController.deleteEntitySelectorItem(this._entitySelectorCtrl, item.id).subscribe(data => {
        if (data) {
          this.selectorloaded = false;
          this.primeraLlistaCarregada = false;
          this.loadSelectorValueList(false);
        }
      });
    }
  }

  goToMaintenanceAdd() {
    this.onGoToMaintenanceInternal.emit(true);
    if (this.preventGoToMaintenanceInternal)
      return;
    this.router.navigate([this._entitySelectorCtrl.urlGoToMaintenance + "/new"]);
  }

  goToMaintenance() {
    if (this._entitySelectorCtrl != null) {
      if (this.value != null)
        this.router.navigate([this._entitySelectorCtrl.urlGoToMaintenance + "/" + this.value]);
      else
        this.router.navigate([this._entitySelectorCtrl.urlGoToMaintenance]);
    }
  }

  @Output() valuechangefunction = new EventEmitter<any>();
  @Output() selectedobjectchangefunction = new EventEmitter<any>();

  public identifier = 'object-selector-' + identifier++;
}

let identifier = 0;