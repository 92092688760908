<div class="line">
    <div class="routeKey">
        {{value.routeKey}}
    </div>
    <div class="customerReference">
        {{value.customerReference}}
    </div>
    <div class="shipmentReference">
        {{value.shipmentReferences}}
    </div>
</div>
<div class="plate-box">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translateIn:totem.language | async}}
    </div>
    {{value.plateA}}
</div>
<div class="plate-box">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translateIn:totem.language | async}}
    </div>
    {{value.plateB}}
</div>
<div class="plate-box" *ngIf="value.plateC">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.PLATEC' | translateIn:totem.language | async}}
    </div>
    {{value.plateC}}
</div>
<div class="plate-box large">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translateIn:totem.language | async}}
    </div>
    {{value.driverName}}
</div>
<div class="plate-box large">
    <div class="toplabel">
        {{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translateIn:totem.language | async}}
    </div>
    {{value.driverPhone}}
</div>