import { Injectable } from '@angular/core';
import { FileUpDownLoadDto } from '../base/FileUpDownLoadDto';


@Injectable()
export class ImageSliderObject {
    public image: string; //'.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    public thumbImage: string; //'.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    public title: string; //'Image title', //Optional: You can use this key if want to show image with title
    public alt: string; //'Image alt', //Optional: You can use this key if want to show image with alt
    public order: number; //1 //Optional: if you pass this key then slider images will be arrange according @input: slideOrderType

    public static buildByFileUpDownLoad(detail: FileUpDownLoadDto, order: number): ImageSliderObject{
        if(detail == null)
            return null;
        let imageobject = new ImageSliderObject();
        let imageB64 = 'data:image/' + detail.fileType + ';base64,' + detail.fileBytes;
        imageobject.image = imageB64;
        imageobject.thumbImage = imageB64;
        imageobject.title = detail.fileName;
        imageobject.order = order;
        return imageobject;
    }
}