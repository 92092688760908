<!-- hour-picker.component.html!-->
<div [formGroup]="rFormHour">
    <div *ngIf="!useIonic">
        <mat-form-field>
            <input matInput [id]="identifier" formControlName="hourvalidator" [(ngModel)]="value" [placeholder]="placeholder" type="text"
                [readonly]="readonly" (change)="onChange($event)" (keydown.enter)="keyEnter($event)">
            <button mat-button *ngIf="showClearButton()" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>    
    <div *ngIf="useIonic" class="hour-ionic">
        <ion-input ionInput [ngClass]="{'error': !rFormHour.valid && !rFormHour.pristine}" [id]="identifier" formControlName="hourvalidator" ngDefaultControl
            [(ngModel)]="value" [placeholder]="placeholder" type="text"
                [readonly]="readonly" (change)="onChange($event)">
        </ion-input>
        <i *ngIf="showClearButton()" aria-label="Clear" (click)="value=''" class="ionicicona fa fa-remove"></i>
    </div>
</div>