import { Component, Inject, OnInit } from '@angular/core';
import { userDto } from '@shared/src/dtos/user/userDto';
import { AlertService } from '../../../services/alert/alert.service';
import { HColor } from '@shared/src/datatypes/HColor';
import { TranslateService } from '@ngx-translate/core';
import { AvatarDto } from '@shared/src/dtos/core/avatar/AvatarDto';

@Component({
  selector: 'test-avatar-page',
  templateUrl: './test-avatar-page.component.html',
  styleUrls: ['./test-avatar-page.component.scss']
})
export class TestAvatarPageComponent implements OnInit {

  constructor(private translate: TranslateService, @Inject('AlertService') protected alertService: AlertService) { }

  public user: userDto;
  public usernull: userDto;
  ngOnInit(): void {

    this.user = this.buildUser("Test", HColor.getRandom().web);;
  }

  public buildUser(skin: string, hcolor: string): userDto {
    let result = new userDto();
    result.skin = skin;
    return result;
  }

  public backgroundColor: HColor = HColor.build("83d34d");
  public backgroundColorChanged($event: any) {

  }
  public buildUserC(skin: string): AvatarDto {
    let result = new AvatarDto();
    result.skin = skin;
    result.backgroundColor = this.backgroundColor;
    return result;
  }
}
