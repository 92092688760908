import {Injectable} from '@angular/core';
import { FormShipmentTypes } from '@shared/src/enums/FormShipmentTypes';

@Injectable()
export class FormQuestionAnswerShipmentFilterDto {
	public formShipmentType: FormShipmentTypes;
	public phaseId: number;
	public pendings: boolean;
	public questionsByBlog: boolean;

	public static build(formShipmentType: FormShipmentTypes, phaseId: number, pendings: boolean, questionsByBlog: boolean): FormQuestionAnswerShipmentFilterDto{

		let filter: FormQuestionAnswerShipmentFilterDto = new FormQuestionAnswerShipmentFilterDto();
		filter.formShipmentType = formShipmentType;
		filter.phaseId = phaseId;
		filter.pendings = pendings;
		filter.questionsByBlog = questionsByBlog;
		return filter;

	}
}
