import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { RoutePartCMRBaseDto } from '@shared/src/dtos/route/cmr/RoutePartCMRBaseDto';
import { DocumentParentTypes } from '@shared/src/enums/DocumentParentTypes';
import { RoutePartCMRTypes } from '@shared/src/enums/RoutePartCMRTypes';

@Component({
  selector: 'shared-documentaryreview-item-ecmr-component',
  templateUrl: './shared.documentaryreview.item.ecmr.component.html',
  styleUrls: ['./shared.documentaryreview.item.ecmr.component.scss'],
})
export class SharedDocumentaryReviewItemECMRComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
  public documentController: DocumentController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }
  
  @Input() public readonly: boolean = false;

  public parentObject: DocumentParentObjectDto;
  public __value: RoutePartCMRBaseDto;
  @Input()
  set value(value: RoutePartCMRBaseDto) {
    if (this.__value === value)
      return;
    this.__value = value;
    this.loadParentObject();
    this.setResourceTitle();
  }
  get value(): RoutePartCMRBaseDto {
    return this.__value;
  }

  public documents: Array<DocumentViewerTypeDto>;

  loadParentObject(){
    this.parentObject = this.value != null ? DocumentParentObjectDto.build(DocumentParentTypes.RoutePartCMR, 0, 0, this.value.routePartCMRId, 0) : null;
    this.loadDocuments();
  }

  loadDocuments(){
    if(this.parentObject == null)
      this.documents = null;
    else{
        this.documentController.getAllDocumentsByParentObject(this.parentObject).subscribe(data => {
          this.documents = data;
      });
    }
  }

  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  public onDataRefresh(value: boolean) {
    this.loadDocuments();
    this.dataRefresh.next(value);
  }

  ngOnInit() {

  }

  public resourceTitle = 'DOCUMENTARYREVIEW.ECMR.CMR';
  setResourceTitle(){
    if(this.value == null)
      return;
    if(this.value.routePartCMRType == RoutePartCMRTypes.CMR)
      this.resourceTitle = 'DOCUMENTARYREVIEW.ECMR.CMR';
    else if(this.value.routePartCMRType == RoutePartCMRTypes.PortChart)
      this.resourceTitle = 'DOCUMENTARYREVIEW.ECMR.PORTCHART';
  }
}
