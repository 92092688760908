import { EventEmitter, Input, Output, Component, OnInit, Inject } from "@angular/core";
import { HTag } from "@shared/src/datatypes/HTag";
import { HTags } from "@shared/src/datatypes/HTags";
import { HomeController } from "@shared/src/controllers/home/home.controller";

@Component({
  selector: "shared-tags-toggle",
  templateUrl: "./shared.tags.toggle.component.html",
  styleUrls: ["./shared.tags.toggle.component.scss"],
})
/***
 * Component de tags. Bàsicament és una llista un strip de buttons per a poder escollir els tags que vols. Només funciona amb tags amb univers
 */
export class SharedTagsToggleComponent implements OnInit {

  @Output() changed: EventEmitter<void> = new EventEmitter<void>();
  @Output() tagsChange: EventEmitter<HTags> = new EventEmitter<HTags>();

  constructor(@Inject('HomeController') public homeController: HomeController) {

  }

  ngOnInit(): void {

  }

  private _tags: HTags;
  @Input()
  set tags(value: HTags) {
    if (this._tags === value)
      return;
    this._tags = value;

    this.loadComponent();
  }
  get tags(): HTags {
    return this._tags;
  }

  public processedTags: Array<HTag>;
  public loadComponent() {
    this.processedTags = HTags.getHTagArray(this._tags, this.homeController);
  }

  public onTagClicked(value: HTag) {
    value.active = !value.active;
    this._tags = HTags.fromHTagArray(this.processedTags);
    this.tagsChange.next(this._tags);
    this.changed.next();
  }



  pretty(value) {
    return JSON.stringify(value);
  }
}
