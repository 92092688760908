import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { ResultStatisticsDeliveryBoxDTO } from "@shared/src/controllers/statistics/ResultStatisticsDeliveryDto";

@Component({
    selector: "shared-boxes",
    templateUrl: "./shared.boxes.component.html",
    styleUrls: ["./shared.boxes.component.scss"],
})
export class SharedBoxesComponent {
    public useIonic: boolean = false;

    constructor(
        private translate: TranslateService,
        @Inject("HomeController") protected homecontroller: HomeController
    ) {
        if (homecontroller.useIonic) this.useIonic = homecontroller.useIonic();
    }

    ngOnInit() {

    }

    private _boxes: ResultStatisticsDeliveryBoxDTO[];;
    @Input() set boxes(value: ResultStatisticsDeliveryBoxDTO[]) {
        if (this._boxes === value)
            return;
        this._boxes = value;
    }
    get boxes(): ResultStatisticsDeliveryBoxDTO[] {
        return this._boxes;
    }
    pretty(value) {
        return JSON.stringify(value);
    }

}
