<div *ngIf="shipment" class="list-conditions" [ngClass]="mode">
    <button-link *ngIf="shipment.isOwnerShipment && mode=='readonly'" variant="forward" class="right"
        (linkClick)="mode='edit'">{{
        'SHARED.SHIPMENT.ITEM.MAINTENANCE.CONDITIONS.EDIT.FORWARD.CAPTION' | translate}}</button-link>
    <button-link *ngIf="shipment.isOwnerShipment && mode=='edit'" variant="forward" class="right"
        (linkClick)="mode='readonly'">{{
        'SHARED.SHIPMENT.ITEM.MAINTENANCE.CONDITIONS.READONLY.FORWARD.CAPTION' | translate}}</button-link>
    <shared-route-shipment-condition class="no-actors" [value]="elem"
        *ngFor="let elem of shipment.conditions | sortBy:'datetimeValue'">
    </shared-route-shipment-condition>
    <shared-shipment-item-condition-edititem [value]="elem"
        *ngFor="let elem of shipment.conditions | sortBy:'datetimeValue'"
        (valueChange)="onItemConditionChanged($event)">
    </shared-shipment-item-condition-edititem>
</div>