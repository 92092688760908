<div class="container" *ngIf="routeId > 0">
    <div *ngIf="options" class="options">
        <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        <span class="selected-option" *ngIf="selectedOption">{{ selectedOption.optionCaption | translate
            }}</span>
    </div>
    <div class="cos" *ngIf="routeExworkInfo && selectedOption && selectedOption.optionId == 'GENERAL'">
        <boolean-component *ngIf="routeExworkInfo.hasProducteBo" class="col-x isExwork-toggle"
            [(ngModel)]="routeExworkInfo.isExwork" [readonly]="false"
            placeholder="{{'ROUTE.ISEXWORK.CAPTION' | translate}}" name="isExwork" [showHelpPoint]=true
            (valueChanged)="exworkToggleChangeSubject.next($event)"
            helpresource="{{'ROUTE.ISEXWORK.HELP' | translate}}">
        </boolean-component>

        <div class="exwork-item" *ngFor="let email of routeExworkInfo.emails">
            <div class="header">
                <div class="chevron">
                    <i class="fa fa-chevron-circle-right" [ngClass]="{'fa-chevron-circle-down': isOpenedLog(email)}"
                        (click)="openCloseLog(email)"></i>
                </div>
                <span class="email ellipsis" matTooltip="{{ email }}" (click)="openCloseLog(email)">{{ email
                    }}</span>
                <div class="delete" (click)="deleteEmail(email)">
                    <i class="fa fa-times"></i>
                </div>
                <div class="delete" (click)="resendEmail(email)">
                    <i class="fa fa-refresh"></i>
                </div>
            </div>
            <div class="exwork-log" [ngClass]="{'show': isOpenedLog(email)}">
                <table class="hardman dark" [ngClass]="{'show': isOpenedLog(email)}">
                    <tr class="dark">
                        <th class="header text-left">{{ 'ROUTE.EXWORKS.LOG.TOPIC.CAPTION' | translate }}</th>
                        <th class="header text-left">{{ 'ROUTE.EXWORKS.LOG.MOMENT.CAPTION' | translate }}</th>
                    </tr>
                    <tr *ngFor="let log of getLogByEmail(email)">
                        <td class="text-left">{{ 'ROUTE.EXWORKS.LOG.TOPICS.' + log.topic.toUpperCase() |
                            translate }}</td>
                        <td class="text-left">{{ log.moment | datehournosecondsnotzobserver }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <input-component class="col-x" [(ngModel)]="email" placeholder="{{'ROUTE.EMAILSEXWORK.CAPTION' | translate }}"
            name="email" autocomplete="off" type="email" [readonly]="readonly"
            (valueChanged)="exworkChangeSubject.next(email)" (onKeyEnter)="exworkChangeSubject.next(email)">
        </input-component>

        <div class="uuid">
            <button-link *ngIf="routeExworkInfo.uuidExwork" variant="action" class="line left"
                (linkClick)="resendAllExwork()"><i class="link-icon fa fa-share" aria-hidden="true"></i>
                <span class="link-caption">{{'SHARED.ROUTE.EXWORK.LINK.RESEND.CAPTION' |
                    translate}}</span></button-link>

            <button-link *ngIf="routeExworkInfo.uuidExwork" variant="action" class="line left"
                (linkClick)="goExwork()"><i class="link-icon fa fa-share-alt" aria-hidden="true"></i>
                <span class="link-caption">{{'SHARED.ROUTE.EXWORK.LINK.NEWSCREEN.CAPTION' |
                    translate}}</span></button-link>

            <button-link variant="action" class="line left" (linkClick)="renewUuidExwork()"><i
                    class="link-icon fa fa-refresh" aria-hidden="true"></i>
                <span class="link-caption">{{'SHARED.ROUTE.EXWORK.LINK.REFRESH.CAPTION' | translate}}</span>
            </button-link>


            <div class="title-uuid">{{'SHARED.ROUTE.EXWORK.LINK.TITLE' | translate}} <span class="uuidExwork"
                    (click)="copyExwork()">{{routeExworkInfo.uuidExwork}} <i class="fa fa-clone"
                        aria-hidden="true"></i></span></div>
        </div>
    </div>
    <div class="cos logs" *ngIf="routeExworkLog && selectedOption && selectedOption.optionId == 'LOGS'">
        <table class="hardman show dark">
            <colgroup>
                <col class="email" />
                <col class="topic" />
                <col class="moment" />
            </colgroup>
            <tr class="dark">
                <th class="header text-left email">{{ 'ROUTE.EXWORKS.LOG.EMAIL.TOPIC.CAPTION' | translate }}
                </th>
                <th class="header text-left">{{ 'ROUTE.EXWORKS.LOG.TOPIC.CAPTION' | translate }}</th>
                <th class="header text-left">{{ 'ROUTE.EXWORKS.LOG.MOMENT.CAPTION' | translate }}</th>
            </tr>
            <tr *ngFor="let log of routeExworkLog.logs">
                <td class="text-left email" matTooltip="{{ log.email }}"><span class="ellipsis">{{ log.email
                        }}</span></td>
                <td class="text-left">{{ 'ROUTE.EXWORKS.LOG.TOPICS.' + log.topic.toUpperCase() |
                    translate }}</td>
                <td class="text-left">{{ log.moment | datehournosecondsnotzobserver }}</td>
            </tr>
        </table>
    </div>
</div>