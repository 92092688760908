import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PhaseDto } from '@shared/src/dtos/phase/PhaseDto';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';

@Component({
  selector: 'shared-route-progress-component',
  templateUrl: './shared.route.progress.component.html',
  styleUrls: ['./shared.route.progress.component.scss']
})
export class SharedRouteProgressComponent {

  constructor(protected router: Router,
    protected route: ActivatedRoute) {
  }

  @Input()
  public phases: Array<PhaseDto> = new Array<PhaseDto>();

  public getGeneralStatusClass(status: GeneralStatus, position: number) {
    // promised, arrived, inprogress, loadedunloaded, done, canceled
    let classe: string = '';
    switch (status) {
      case GeneralStatus.promised:
        classe += 'promised';
        break;
      case GeneralStatus.arrived:
        classe += 'arrived';
        break;
      case GeneralStatus.inprogress:
        classe += 'inprogress';
        break;
      case GeneralStatus.loadedunloaded:
        classe += 'loadedunloaded';
        break;
      case GeneralStatus.done:
        classe += 'done';
        break;
      case GeneralStatus.canceled:
        classe += 'canceled';
        break;
      default:
        classe += 'promised';
        break;
    }

    if (position > 0) {
      classe += ' margin-left';
    }

    if (position == 0) {
      classe += ' radius-left';
    } else if (position == this.phases.length - 1) {
      classe += ' radius-right';
    }

    return classe;
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}