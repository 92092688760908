import { Component, Input, EventEmitter, OnInit, Output, ViewChild, AfterViewInit, ViewChildren, QueryList, HostListener } from '@angular/core';
import { HDate } from '@shared/src/datatypes/HDate';
import { CalendarDayDto } from '@shared/src/dtos/base/calendar/CalendarDayDto';
import { CalendarDto } from '@shared/src/dtos/base/calendar/CalendarDto';

@Component({
  selector: 'calendarmonths',
  templateUrl: './calendar.months.html',
  styleUrls: ["./calendar.scss"]
})
export class CalendarMonths<DAY extends CalendarDayDto, CAL extends CalendarDto<DAY>> implements OnInit, AfterViewInit {

  constructor() {
  }

  ngOnInit() {

  }
  @ViewChild('calendaritem') private calendaritem: any;
  @ViewChild('calendarmonthitem') private calendarmonthitem: any;
  ngAfterViewInit() {
    this.clientWidth = this.calendaritem.nativeElement.clientWidth;
    this.clientHeight = this.calendaritem.nativeElement.clientHeight;
    this.monthWidth = this.calendarmonthitem.nativeElement.clientWidth;
    this.monthHeight = this.monthWidth + 25;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.clientWidth = this.calendaritem.nativeElement.clientWidth;
    this.clientHeight = this.calendaritem.nativeElement.clientHeight;
    this.monthWidth = this.calendarmonthitem.nativeElement.clientWidth;
    this.monthHeight = this.monthWidth + 25;
  }

  getStyleMonthItem() {
    if (this.monthCount == 1)
      return { height: "100%" };
    return { height: this.monthHeight + "px" };
  }

  @Input() clientWidth: number = 0;
  @Input() clientHeight: number = 0;
  @Input() monthWidth: number = 0;
  @Input() monthHeight: number = 0;

  @Input() readonly: boolean = false;

  @Output() onDateClick: EventEmitter<DAY> = new EventEmitter();
  @Output() onWeekDayClick: EventEmitter<any> = new EventEmitter();
  @Output() onWeekClick: EventEmitter<any> = new EventEmitter();
  @Output() onMonthClick: EventEmitter<any> = new EventEmitter();

  private _ondate: HDate;
  @Input() set ondate(value: HDate) {
    if (this._ondate === value)
      return;
    if (HDate.equals(this._ondate, value))
      return;
    this._ondate = value;
    this.loadStructure();
  }
  get ondate(): HDate {
    return this._ondate;
  }

  private _monthCount: number = 1;
  @Input() set monthCount(value: number) {
    if (this._monthCount === value)
      return;
    this._monthCount = value;
    this.loadStructure();
  }
  get monthCount(): number {
    return this._monthCount;
  }
  @Output() onmonthCountChanged: EventEmitter<number> = new EventEmitter();

  private _calendar: CAL;
  @Input() set calendar(value: CAL) {
    if (this._calendar === value)
      return;
    this._calendar = value;
  }
  get calendar(): CAL {
    return this._calendar;
  }

  setView(mounth: number) {
    this.monthCount = mounth;
    this.onmonthCountChanged.next(mounth);
  }

  public months: HDate[] = new Array();
  public loadStructure() {
    if (this.ondate == null)
      return;

    let initialdate = HDate.getFirstDayOfMonth(this.ondate);
    if (this.monthCount == 12)
      initialdate = HDate.getFirstDayOfYear(this.ondate);

    let j = 0;
    while (j < this.monthCount) {
      if (this.months.length = j)
        this.months.push(HDate.getLastDayOfMonth(initialdate.addMonths(j)));
      else
        this.months[j] = HDate.getLastDayOfMonth(initialdate.addMonths(j));
      j++;
    }
    if (this.months.length != this.monthCount)
      this.months = this.months.slice(0, this.monthCount - 1);

  }

  public getnumsetmanes(value: HDate): number {
    let initialdate = HDate.getFirstDayOfMonth(value);
    let finaldate = HDate.getLastDayOfMonth(value);
    let hprimerdia = HDate.addDaysToHDate(initialdate, (-1) * HDate.getDayOfWeekNumber(initialdate));
    let primerdia = HDate.getDate(hprimerdia).getTime() / 86400000;
    let hultimdia = HDate.addDaysToHDate(finaldate, 6 - HDate.getDayOfWeekNumber(finaldate));
    let ultimdia = HDate.getDate(hultimdia).getTime() / 86400000;

    let distanciaentredies = (ultimdia - primerdia) + 1;
    let setmanes = Math.ceil(distanciaentredies / 7);
    return setmanes
  }
  public getinitialdate(value: HDate) {
    return HDate.getFirstDayOfMonth(value);
  }
  public getfinaldate(value: HDate) {
    return HDate.getLastDayOfMonth(value);
  }
  public getinnerstyle(value: HDate) {
    let height = 100;
    if (this.monthHeight)
      height = this.monthHeight - 25;
    let numset = this.getnumsetmanes(value);
    if (numset == 0)
      return { height: height + "px" };
    return { height: (height / 6) + 'px' }; //Sempre contem 6 setmanes
  }

  public getOndate(value: HDate) {
    return HDate.getLastDayOfMonth(value);
  }

  public dayclick(e: DAY) {
    this.onDateClick.next(e);
  }
  public weekdayclick(e: any) {
    this.onWeekDayClick.next(e);
  }
  public weekclick(e: any) {
    this.onWeekClick.next(e);
  }
  public monthclick(e: any) {
    this.onMonthClick.next(e);
  }

}


