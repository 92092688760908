import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';


@Component({
  selector: 'shared-image',
  templateUrl: './shared.image.component.html',
  styleUrls: ['./shared.image.component.scss']
})
export class SharedImageComponent implements OnInit {

  constructor(public systemController: SystemController, @Inject('SecurityController') protected securityController: SecurityController) {
    this.securityController.getStoredUserDto().subscribe(data => {
      if (data)
        this.readonly = data.isReadOnly;
      else
        this.readonly = false;
    })
  }

  ngOnInit(): void {
  }

  public readonly: boolean = true;
  public showsave: boolean = false;
  public messages: Array<String> = [];
  public imageUri: string;

  public acceptedTypes: FileTypes[] = [FileTypes.jpeg, FileTypes.jpg, FileTypes.png];

  private _width: number = null;
  @Input()
  public set width(value: number) {
    if (this._width === value || value === undefined)
      return;

    this._width = value;
    this.setStyle();
  }

  public get width(): number {
    return this._width;
  }

  private _height: number = null;
  @Input()
  public set height(value: number) {
    if (this._height === value || value === undefined)
      return;

    this._height = value;
    this.setStyle();
  }

  public get height(): number {
    return this._height;
  }

  private _image: FileUpDownLoadDto = null;
  @Input()
  public set image(value: FileUpDownLoadDto) {
    if (this._image === value || value === undefined)
      return;

    this._image = value;
    this.setImage(value, false);
  }

  public get image(): FileUpDownLoadDto {
    return this._image;
  }

  @Output() imageChanged: EventEmitter<FileUpDownLoadDto> = new EventEmitter();
  uploadFile() {
    if (this.readonly)
      return;

    this.imageChanged.emit(this.image);
    this.showsave = false;
  }

  handleFileInput(file: FileUpDownLoadDto) {
    this.showsave = true;
    this.setImage(file, true);
  }

  setImage(file: FileUpDownLoadDto, handleFile: boolean) {
    if (file == null && this.image == null)
      return;
    if (file == null) {
      this.image.fileBytes = null;
      this.image.fileType = FileTypes.None;
      this.imageUri = "";
      return;
    }
    this.image = file;
    this.imageUri = 'data:image/' + file.fileType + ';base64,' + (handleFile ? file.fileString : file.fileBytes);

  }

  public style: {};
  setStyle() {
    let s = {
      'width': '100%',
      'height': '100%'
    };
    if (this.width != null)
      s.width = this.width + "px";
    if (this.height != null)
      s.height = this.height + "px";

    this.style = s;
  }

}