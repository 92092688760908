import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HString } from '@shared/src/public-api';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { RoutePartCMREventDto } from '@shared/src/dtos/route/cmr/RoutePartCMREventDto';
import { CMREventTypes } from '@shared/src/enums/CMREventTypes';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';

@Component({
  selector: 'shared-route-part-cmr-edit-events-component',
  templateUrl: './shared.route.part.cmr.edit.events.component.html',
  styleUrls: ['./shared.route.part.cmr.edit.events.component.scss'],
})
export class SharedRoutePartCMREditEventsComponent {

  constructor(private fb: FormBuilder,private routeCMRController: RouteCMRController,
    @Inject('HomeController') protected homeController: HomeController) {
    
      if (homeController.useIonic) 
        this.useIonic = homeController.useIonic();

      this.formGroup = fb.group({
        'event': ['', Validators.maxLength(255)]
      });
  };

  public formGroup: FormGroup;

  public useIonic: boolean = false;

  @Input() public readonly: boolean;

  private _routePartCMR: RoutePartCMRDto;
  @Input()
  public set routePartCMR(value: RoutePartCMRDto) {
      if (this._routePartCMR === value)
          return;
      this._routePartCMR = value; 
      this.buildOptions();
  }
  public get routePartCMR(): RoutePartCMRDto {
      return this._routePartCMR;
  }

  public options: OptionsStripDto;
  public selectedOption: OptionDto = null;
  public buildOptions() {
    if (this.options)
      return;

    this.options = new OptionsStripDto();
    this.options.add(OptionDto.build('LISTEVENTS', 'ROUTEPARTCMR.EVENT.LIST.TITLE', null, null, 1, -1, 1, true, false));
    this.options.add(OptionDto.build('ADDEVENT', 'ROUTEPARTCMR.EVENT.NEW.TITLE', null, null, 1, -1, 1, false, false));
    this.onClickOption(this.options.getActualSelected());
  }

  public selectedOptionId = "";
  onClickOption(option: OptionDto) {
      if (option != null)
          this.selectedOptionId = option.optionId;
      else
          this.selectedOptionId = "";

      if(this.selectedOptionId == "LISTEVENTS"){
        this.loadListEvents();
      } else if(this.selectedOptionId == "ADDEVENT"){
        this.event = "";
      }
  }

  public listEvents: RoutePartCMREventDto[];
  public getListEvents(){
    if(this.listEvents == null)
      return new Array();
    return this.listEvents;
  }
  public loadListEvents(){

    if(this.routePartCMR == null){
      this.listEvents = new Array();
      return;
    }

    this.routeCMRController.getRoutePartCMREvents(this.routePartCMR.routePartCMRId).subscribe(data => {
		  if (data) {
			  this.listEvents = data;
		  }else{
        this.listEvents = new Array();
      }
		});

  }
  
  private _event: string;
  set event(value: string) {
    if (this._event === value)
      return;
    this._event = value;
  }

  get event(): string {
    return this._event;
  }


  ngOnInit() {

  }

  public isValid: boolean = false;
  ngAfterViewInit() {
    this.formGroup.valueChanges.subscribe((change) => {
      this.isValid = this.formGroup.valid;
      if(!HString.isNullOrNullString(change))
        this.pendingSave = true;
    });
      
  }

  public pendingSave: boolean;
  
  onAddEvent() {
    if (this.routePartCMR != null && !HString.isNullOrNullString(this.event)) {
      this.routeCMRController.saveRoutePartCMREvent(RoutePartCMREventDto.build(this.routePartCMR, this.event, CMREventTypes.Generic)).subscribe(data => {
        if(data){
          this._event = "";
          this.pendingSave = false;
        }
      });
    }
    
  }
}
