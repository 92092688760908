import { HString } from '@shared/src/public-api';
import { FilterDto } from './FilterDtos';


export class FilterStripDto {

	public list: Array<FilterDto>; // la llista de opcions
	constructor() {
		this.list = new Array<FilterDto>();
	}


	public selectUnique(filterId: string) {
		if (this.list != null)
			for (let i = 0; i < this.list.length; i++) {
				this.list[i].filterActive = this.list[i].filterId == filterId;
			}
	}

	/***
	 * Selecciona les mateixesx opcions (si existeixen que al cpyfreom)
	 */
	public selectSameAs(copyFrom: FilterStripDto) {
		this.list.forEach(x => {
			let trobat = false;
			this.list.forEach(y => {
				if (x.filterId === y.filterId && y.filterActive) {
					trobat = true;
				}
			})
			x.filterActive = trobat;
		})
	}
	static buildFromFilterStripDto(value: FilterStripDto): FilterStripDto {
		let result = new FilterStripDto();
		if (value != null && value.list != null)
			Object.keys(value.list).forEach(k => {
				result.add(FilterDto.buildFromFilterDto(value.list[k]));
			});
		return result;
	}

	static getActiveKeys(filter: FilterStripDto): string[] {
		let result = new Array<string>();
		if (filter != null && filter.list != null)
			filter.list.forEach(v => {
				if (v.filterActive)
					result.push(v.filterId);
			});
		return result;
	}

	static getFirstActive(filter: FilterStripDto): FilterDto {
		return filter.list.find(v => v.filterActive === true);
	}

	static isActiveKeys(filter: FilterStripDto, filterName: string): boolean {
		let filterActive: boolean = false;
		if (filter != null && filter.list != null)
			filter.list.forEach(v => {
				if (v.filterActive && HString.equals(v.filterId, filterName)){
					filterActive = true;
					return true;
				}
			});
		return filterActive;
	}

	/**
	 * Afegeix la opció a l'strip. Si duplicada no fa res, retorna false.
	 * @param optionDto 
	 */
	public add(value: FilterDto): boolean {
		if (this.list.find(v => { v.filterId == value.filterId }))
			return false;
		this.list.push(value);
		return true;
	}

	/**
	 * Elimina la opció de l'strip. Si no hi ha no fa res, retorna false;
	 * @param optionDto 
	 */
	public remove(value: FilterDto): boolean {
		return this.removeByKey(value.filterId);
	}
	public removeByKey(key: string): boolean {
		if (this.list.find(v => { v.filterId == value.filterId }))
			return false;
		let value = this.list.find(v => { v.filterId == value.filterId });
		this.list.splice(this.list.indexOf(value), 1);
		return true;
	}


	public toggle(value: FilterDto) {
		value.filterActive = !value.filterActive;
		this.toggled(value);
	}
	public toggled(value: FilterDto) {
		if (value != null && value.filterActive && value.incompatibilities) {
			value.incompatibilities.forEach((incompatibility: string) => {
				this.list.forEach(other => {
					if (incompatibility == other.filterId) {
						other.filterActive = false;
					}
				});
			})
		};
	}

	/***
	 * Converteix un array de normalment objectes a un FilterStripDto
	 */
	static buildFromArray(array: Array<any>, properties: Array<string>, selectedId: string): FilterStripDto {
		const result: FilterStripDto = new FilterStripDto();
		if (array != null) {
			const incompatibilities = new Array<string>();
			array.forEach(elem => {
				const filter = new FilterDto();
				filter.filterId = elem[properties[0]];
				if (properties.length > 1)
					filter.filterCaption = elem[properties[1]];
				if (properties.length > 2)
					filter.filterHelp = elem[properties[2]];
				filter.filterActive = filter.filterId == selectedId;

				result.add(filter)

				incompatibilities.push(filter.filterId);
			});

			let index = 0;
			result.list.forEach(elem => {
				elem.incompatibilities = incompatibilities.filter((v, i) => {
					return i != index;
				});
				index++;
			})
		}
		return result;
	}
}