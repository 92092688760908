import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HHourIntervals } from "@shared/src/datatypes/HHourIntervals";
import { HTime } from "@shared/src/datatypes/HTime";
import { Languages } from "@shared/src/enums/Languages";
import { TimeZones } from "@shared/src/enums/TimeZones";

export class AddressDto {
    public addressId: number;

    public partnerCode: string;
    public addressKey: string;
    public skin: string;
    public billingCode: string;

    public descriptionShort: string;
    public descriptionLong: string;

    public addressLine1: string;
    public addressLine2: string;
    public address: string;
    public city: string;
    public province: string;
    public state: string;
    public postalCode: string;
    public country: string;
    public latitude: number;
    public longitude: number;
    public formattedAddress: string;

    public language: Languages;
    public contactName: string;
    public contactEmail: string;
    public contactPhone: string;

    public isActive: boolean
    public isOwn: boolean;

    public enabledSlots: boolean;
    public enabledCapacitySlots: boolean;
    public maximizeGranularityCapacitySlots: boolean;
    public granularityInterval: HTime;
    public activateGranularitiesIntervalsAtDisponibility: boolean;
    public granularitiesIntervalsAtDisponibility: string;
    public activateGranularitiesIntervalsAtNow: boolean;
    public granularitiesIntervalsAtNow: string;
    public activeListOfGranularitiesIntervals: boolean;
    public listOfGranularitiesIntervals: string;

    public timetableStartTime: HDateHour;
    public scheduledTime: HDateHour;
    public unloadTimetableIntervals: HHourIntervals;
    public loadTimetableIntervals: HHourIntervals;

    public useComplexSchedule: boolean;
    public complexSchedule: string;

    public slotDefaultDuration: HTime;

    public timeZone: TimeZones;
}