import { ActionsMaintenance } from '@shared/src/enums/ActionsMaintenance';

export class ActionDto {

    public actionId: string; //Ha de ser string ja que ens arriben més accions de back i poden ser de qualsevol entitat //Suposadament per diferenciarlo dels altres. És Unic
    public actionCaption: string; //el recurs que utilitzarem com anom
    public actionHelp: string; //explicació de la opció, es mostrar com a tooltip molt segurament
    public actionIcon: string; //nom de la icona. Els noms els trobaràs a https://material.io/icons/
    public actionOrder: number; //Ordre de la opció0. A més petit es mostra més a l'esquerra a l'estrip o a la part superior
    public actionShowAs: number; //+1 es mostra com a iciona, -1, com a text, 0 com les dues.
    public actionShowIn: number; //+1 es mostra a la barra, -1 al menu, 0 a les dues
    public addbutton: boolean = false; //Serveix per indicar que és d'afegir, és un cas especial que canvia el backcolor
    public additionalcaption: string;
    public classString: string; //Permet indicar quines classes addicionals s'han d'aplicar al option en concret. Permet canviar background-color, etc.
    public classification: string;
    public additionalData: string;
    public styleClass: string;

    public static build(actionid: string, actioncaption: string, actionhelp: string, actionicon: string, actionorder: number, actionshowas: number, actionshowin: number, additionalcaption: string): ActionDto {
        let result = new ActionDto();
        result.actionId = actionid;
        result.actionCaption = actioncaption;
        result.actionHelp = actionhelp;
        result.actionIcon = actionicon;
        result.actionOrder = actionorder;
        result.actionShowAs = actionshowas;
        result.actionShowIn = actionshowin;
        result.additionalcaption = additionalcaption;
        result.classString = "action-" + actionid;
        return result;
    }

    public static buildById(actionid: string): ActionDto {
        let result = new ActionDto();
        result.actionId = actionid;
        return result;
    }

    public static buildAdd(actionid: string, actioncaption: string, actionhelp: string, actionicon: string, actionorder: number, actionshowas: number, actionshowin: number, additionalcaption: string): ActionDto {
        let result = ActionDto.build(actionid, actioncaption, actionhelp, actionicon, actionorder, actionshowas, actionshowin, additionalcaption);
        result.addbutton = true;
        return result;
    }

    static buildFromActionDto(origin: ActionDto): ActionDto {
        let result = new ActionDto();
        result.actionCaption = origin.actionCaption;
        result.actionHelp = origin.actionHelp;
        result.actionIcon = origin.actionIcon;
        result.actionId = origin.actionId;
        result.actionOrder = origin.actionOrder;
        result.actionShowAs = origin.actionShowAs;
        result.actionShowIn = origin.actionShowIn;
        result.addbutton = origin.addbutton;
        result.additionalcaption = origin.additionalcaption;
        result.classString = origin.classString;
        result.classification = origin.classification;
        result.additionalData = origin.additionalData;
        result.styleClass = origin.styleClass;
        return result;
    }


    static CANCELADD(): ActionDto {
        return ActionDto.build(ActionsMaintenance.CANCELALL, "CANCELADD.CAPTION", "CANCELADD.HELP", "awesome:fa-ban", 1, 0, 0, "");
    }
    static DOWNLOAD(): ActionDto {
        return ActionDto.build("DOWNLOAD", "GENERAL.ACTIONS.DOWNLOAD.CAPTION", "GENERAL.ACTIONS.DOWNLOAD.HELP", "fa-cloud-download", 1, 0, 0, "");
    }

    public static getActionShowAsIcon(value: ActionDto): boolean {
        return value.actionShowAs <= 0;
    }
    public static getActionShowAsText(value: ActionDto): boolean {
        return value.actionShowAs >= 0;
    }
    public static getActionShowInBar(value: ActionDto): boolean {
        return value.actionShowIn >= 0;
    }
    public static getActionShowInMenu(value: ActionDto): boolean {
        return value.actionShowIn <= 0;
    }
    public setAdd(): ActionDto {
        this.addbutton = true;
        return this;
    }

    public addClass(styleClass: string): ActionDto {
        this.styleClass = styleClass;
        return this;
    }

}
