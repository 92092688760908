<input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">

<div class="container-table grup" [ngClass]="{'ionic' : useIonic}">
    <div class="contingut">
        <table class="hardman" style="width:100%">
            <tr class="dark">
                <th class="header id">ID</th>
                <th class="header datehour">{{'CMREVENTSTABLE.EVENT_AT' | translate}}</th>
                <th class="header message">{{'CMREVENTSTABLE.EVENT' | translate}}</th>
                <th class="header alias">{{'CMREVENTSTABLE.USER' | translate}}</th>
            </tr>
            <tr *ngFor="let item of getEvents() | filter:searchText | sortBy:'-eventAt'" [ngClass]="item.cmrEventType">
                <td class="id">{{item.id}}</td>
                <td class="datehour">
                    <datehour-viewer [value]="item.eventAt" [showOriginal]="false" [showTimeZone]="false">
                    </datehour-viewer>
                </td>
                <td class="message">{{ item.event }}</td>
                <td class="alias">
                    <span class="only-alias">{{item.alias}}</span>
                    <button-link class="link-alias" variant="action" (linkClick)="onClickUser(item)">
                        {{item.alias}}</button-link>
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="minimal-table">
    <table class="hardman" style="width:100%">
        <tr class="dark">
            <th class="header id">ID</th>
            <th class="header datehour">{{'CMREVENTSTABLE.EVENT_AT' | translate}}</th>
            <th class="header message">{{'CMREVENTSTABLE.EVENT' | translate}}</th>
            <th class="header alias">{{'CMREVENTSTABLE.USER' | translate}}</th>
        </tr>
        <tr *ngFor="let item of getEvents() | filter:searchText | sortBy:'-eventAt'" [ngClass]="item.cmrEventType">
            <td class="id">{{item.id}}</td>
            <td class="datehour">
                <datehour-viewer [value]="item.eventAt" [showOriginal]="false" [showTimeZone]="false">
                </datehour-viewer>
            </td>
            <td class="message">{{ item.event }}</td>
            <td class="alias">
                <span class="only-alias">{{item.alias}}</span>
                <button-link class="link-alias" variant="action" (linkClick)="onClickUser(item)">
                    {{item.alias}}</button-link>
            </td>
        </tr>
    </table>
</div>