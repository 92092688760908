import { Injectable, Inject } from "@angular/core";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../alert/alert.service";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(protected file: File, protected opener: FileOpener, protected platform: Platform,
        @Inject('AlertService') private alertService: AlertService,
        private translate: TranslateService) {

    }

    convertBase64ToBlob(b64Data, contentType): Blob {
        contentType = contentType || '';
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    saveAndOpenPdf(pdf: string, filename: string) {
        const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.platform.is('android') ? this.file.externalDataDirectory : null;
        if (writeDirectory)
            this.file.writeFile(writeDirectory, filename, this.convertBase64ToBlob(pdf, 'data:application/pdf;base64'), { replace: true })
                .then(() => {
                    this.opener.open(writeDirectory + filename, 'application/pdf')
                        .catch(() => {
                            let msg = this.translate.instant('OPENDOCUMENT.OPENINGPDF.CAPTION');
                            this.alertService.error(msg, false);
                            console.log(msg);
                        });
                })
                .catch(() => {
                    let msg = this.translate.instant('OPENDOCUMENT.WRITINGPDF.CAPTION');
                    this.alertService.error(msg, false);
                });
        else{
            let msg = this.translate.instant('OPENDOCUMENT.NOTIONICNATIVE.CAPTION');
            this.alertService.info(msg, false);
            console.log(msg);
        }
         
    }
}