import {
    Component,
    Input,
    OnInit,
    Inject,
} from "@angular/core";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";
import { PhaseController } from "@shared/src/controllers/route/phase/phase.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { Router } from "@angular/router";
import { Blames } from "@shared/src/enums/Blames";
import { HTime } from "@shared/src/datatypes/HTime";
import { PhaseTimesExplainedDto } from "@shared/src/dtos/phase/PhaseTimesExplainedDto";
import { PhaseTimesChunkDto } from "@shared/src/dtos/phase/PhaseTimesChunkDto";

@Component({
    selector: "shared-phase-times-component",
    templateUrl: "./shared.phase.times.component.html",
    styleUrls: ["./shared.phase.times.component.scss"],
})
export class SharedPhaseTimesComponent implements OnInit {
    @Input() public readonly: boolean = false;

    public timeNull: HTime = HTime.NULLVALUE();

    constructor(
        public router: Router,
        public phaseController: PhaseController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) {
        this.securityController.getStoredUserDto().subscribe((data) => {
            this.readonly = data.isReadOnly;
        });
    }

    ngOnInit(): void {}

    public enumBlames = Blames;

    private _selectedBlame: Blames = Blames.Me;
    public get selectedBlame(): Blames {
        return this._selectedBlame;
    }
    @Input() public set selectedBlame(value: Blames) {
        if (value && value != this._selectedBlame) {
            this._selectedBlame = value;
            this.loadData();
        }
    }

    private _phase: PhaseCardDto;
    public get phase(): PhaseCardDto {
        return this._phase;
    }
    @Input() public set phase(value: PhaseCardDto) {
        this._phase = value;
        this.loadData();
    }

    public data: PhaseTimesExplainedDto;
    public loadData() {
        if (this._phase && this._phase.phaseId && this._selectedBlame) {
            this.phaseController.getPhaseTimes(
                this._phase.phaseId,
                this._selectedBlame
            ).subscribe((data: PhaseTimesExplainedDto) => {
                this.data = data;
                if (this.data) {
                    this.data.total.myDelay = HTime.buildbytime(this.data.total.myDelay);
                    this.data.total.ownerDelay = HTime.buildbytime(this.data.total.ownerDelay);
                    this.data.total.carrierDelay = HTime.buildbytime(this.data.total.carrierDelay);
                    this.data.total.driverDelay = HTime.buildbytime(this.data.total.driverDelay);
                    this.data.total.logisticOperatorDelay = HTime.buildbytime(this.data.total.logisticOperatorDelay);
                    this.data.total.myAnticipation = HTime.buildbytime(this.data.total.myAnticipation);
                    this.data.total.driverAnticipation = HTime.buildbytime(this.data.total.driverAnticipation);
                    this.data.total.carrierAnticipation = HTime.buildbytime(this.data.total.carrierAnticipation);
                    this.data.total.logisticOperatorAnticipation = HTime.buildbytime(this.data.total.logisticOperatorAnticipation);

                    this.data.chunks.forEach((item: PhaseTimesChunkDto) => {
                       item.myDelay = HTime.buildbytime(item.myDelay);
                       item.ownerDelay = HTime.buildbytime(item.ownerDelay);
                       item.carrierDelay = HTime.buildbytime(item.carrierDelay);
                       item.driverDelay = HTime.buildbytime(item.driverDelay);
                       item.logisticOperatorDelay = HTime.buildbytime(item.logisticOperatorDelay);
                       item.myAnticipation = HTime.buildbytime(item.myAnticipation);
                       item.driverAnticipation = HTime.buildbytime(item.driverAnticipation);
                       item.carrierAnticipation = HTime.buildbytime(item.carrierAnticipation);
                       item.logisticOperatorAnticipation = HTime.buildbytime(item.logisticOperatorAnticipation);
                    });
                }
            });
        } else {
            this.data = null;
        }
    }

    pretty(item: any): string {
        return JSON.stringify(item);
    }
}
