import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { RouteShipmentConditionDto } from "@shared/src/dtos/route/RouteShipmentConditionDto";
import { ConditionCommunications } from "@shared/src/enums/ConditionCommunications";
import { HDateHour, HHourIntervals, HTriState } from "@shared/src/public-api";
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'shared-shipment-item-condition-edititem',
    templateUrl: './shared.shipment.item.condition.edititem.component.html',
    styleUrls: ['./shared.shipment.item.condition.edititem.component.scss']
})
export class SharedShipmentItemConditionEditItemComponent implements OnInit {

    public conditionValueChanged = new Subject<any>();

    constructor() {
        this.conditionValueChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(data => {
            this.valueChange.next(this.value);
        });
    }

    
    @Input() 
    public readonly: boolean = false;

    private _value: RouteShipmentConditionDto;
    @Input()
    public set value(val: RouteShipmentConditionDto) {
        if (this._value === val)
            return;

        this._value = val;
        this.loadInternalProperties();
    }
    public get value(): RouteShipmentConditionDto {
        return this._value;
    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    public resourceName = "";
    public generalClass = "";

    public loadInternalProperties() {
        if (this._value == null)
            return;

        this._datetimeValue = this.value.datetimeValue;
        this._intervalValue = this.value.intervalValue;
        this._decimalValue = this.value.decimalValue;
        this._triStateValue = this.value.triStateValue;

        this.resourceName = ("RouteShipmentConditionTypes." + this.value.type.toString()).toUpperCase();
        this.generalClass = this.value.type.toString();
        if (this.value.communicationVia != ConditionCommunications.None)
            this.generalClass += " " + this.value.communicationVia.toString();

    }

    private _datetimeValue: HDateHour;
    public set datetimeValue(value: HDateHour) {
        if (value == this._datetimeValue)
            return;

        this._datetimeValue = value;
        this._value.datetimeValue = value;
        this.conditionValueChanged.next(value); 
    }

    public get datetimeValue(): HDateHour {
        return this._datetimeValue;
    }
    
    private _intervalValue: HHourIntervals;
    public set intervalValue(value: HHourIntervals) {
        if (value == this._intervalValue)
            return;

        this._intervalValue = value;
        this._value.intervalValue = value;
        this.conditionValueChanged.next(value); 
    }

    public get intervalValue(): HHourIntervals {
        return this._intervalValue;
    }

    private _decimalValue: number;
    public set decimalValue(value: number) {
        if (value == this._decimalValue)
            return;

        this._decimalValue = value;
        this._value.decimalValue = value;
        this.conditionValueChanged.next(value); 
    }

    public get decimalValue(): number {
        return this._decimalValue;
    }

    private _triStateValue: HTriState;
    public set triStateValue(value: HTriState) {
        if (value == this._triStateValue)
            return;

        this._triStateValue = value;
        this._value.triStateValue = value;
        this.conditionValueChanged.next(value); 
    }

    public get triStateValue(): HTriState {
        return this._triStateValue;
    }
    ngOnInit(): void {
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }
}
