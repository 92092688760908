
<div class="container" *ngIf="reserveObject">
    <div class="sub-titol">{{'SHARED.SLOT.PICKER.RESERVEOUTOFHOURS.TITLE'| translate}}</div>
    <div class="data-info" [formGroup]="rForm">

        <date-picker class="col-date" [(ngModel)]="reserveObject.date"
            placeholder="{{'DESKTOP.SLOT.PICKER.COMPONENT.REESERVARFORAHORES.DATE' | translate}}"
            name="date" formControlName="date"></date-picker>

        <hour-picker class="col-hour" [(ngModel)]="startHour" (valueChanged)="valueChange()" 
            name="startHour" formControlName="startHour"
            placeholder="{{'DESKTOP.SLOT.PICKER.COMPONENT.REESERVARFORAHORES.STARTHOUR' | translate}}"></hour-picker>

        <div class="box-times">

            <div class="box box-documentation">
                <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCUMENTATION' | translate}}</div>
                <div class="box-time doc">
                    <time-picker class="input-time" [(ngModel)]="previousTimeDocumentation" (valueChanged)="valueChange()" 
                        name="previousTimeDocumentation" formControlName="previousTimeDocumentation"></time-picker>
                </div>

                <div class="box box-dock">
                    <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCK' | translate}}</div>
                    <div class="box-time">
                        <time-picker class="input-time" [(ngModel)]="previousTimeDock" (valueChanged)="valueChange()" 
                            name="previousTimeDock" formControlName="previousTimeDock"></time-picker>
                    </div>

                    <div class="box box-forklift">
                        <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.FORKLIFT' | translate}}</div>
                        <div class="box-time">
                            <time-picker class="input-time" [(ngModel)]="timeWheelbarrowDriver" (valueChanged)="valueChange()" 
                                name="timeWheelbarrowDriver" formControlName="timeWheelbarrowDriver"></time-picker>
                        </div>
                    </div>

                    <div class="box-time">
                        <time-picker class="input-time" [(ngModel)]="postTimeDock" (valueChanged)="valueChange()" 
                            name="postTimeDock" formControlName="postTimeDock"></time-picker>
                    </div>

                </div>

                <div class="box-time doc">
                    <time-picker class="input-time" [(ngModel)]="postTimeDocumentation" (valueChanged)="valueChange()" 
                        name="postTimeDocumentation" formControlName="postTimeDocumentation"></time-picker>
                </div>
            </div>
        </div>
        <div class="box-times">
            <div class="box box-documentation">
                <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCUMENTATION' | translate}}</div>
                <div class="box-time doc star">
                    <div class="value-time">{{ reserveObject.interval.getStart() | hour }}</div>
                </div>
        
                <div class="box box-dock">
                    <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCK' | translate}}</div>
                    <div class="box-time star">
                        <div class="value-time">{{ reserveObject.intervalDock.getStart() | hour }}</div>
                    </div>
        
                    <div class="box box-forklift">
                        <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.FORKLIFT' | translate}}</div>
                        <div class="box-time star forklift">
                            <div class="value-time">{{ reserveObject.intervalWheelbarrowDriver | hourinterval }}</div>
                        </div>
                    </div>
        
                    <div class="box-time end">
                        <div class="value-time">{{ reserveObject.intervalDock.getEnd() | hour }}</div>
                    </div>
        
                </div>
        
                <div class="box-time doc end">
                    <div class="value-time">{{ reserveObject.interval.getEnd() | hour }}</div>
                </div>
            </div>

        </div>

        <div class="zona-boto-add">
            <button class="add confirmButton" [disabled]="!isReserveOutOfHoursValid()"
                (click)="onReservarForaHoresClick()">{{
                "DESKTOP.SLOT.PICKER.COMPONENT.REESERVARFORAHORES.BUTTON" | translate }}</button>
        </div>
    </div>
</div>