import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { TenantSettingItemMaintenanceDto } from './TenantSettingItemMaintenanceDto';
import { TenantSettingListMaintenanceDto } from './TenantSettingListMaintenanceDto';
import { TenantSettings } from '@shared/src/enums/TenantSettings';
import { HTags } from '@shared/src/datatypes/HTags';

@Injectable()
export class TenantSettingMaintenanceController extends BaseMaintenanceController<TenantSettingListMaintenanceDto, TenantSettingItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "tenantsetting");
    }


    public buildUrl(uri: string): string {
        return "system/dataandparameters/tenantsetting" + uri;
    }

    public getStatusColors(): Observable<Array<TenantSettingItemMaintenanceDto>> {
        return this.get(this.getBaseUrl() + "/group/status-colors");
    }

    public getTenantSetting(tenantSetting: TenantSettings): Observable<TenantSettingItemMaintenanceDto> {
        return this.get(this.getBaseUrl() + "/get/" + tenantSetting);
    }
    public getHTags(tenantSetting: TenantSettings): Observable<HTags> {
        return this.get(this.getBaseUrl() + "/htags/" + tenantSetting);
    }
    public putHTags(tenantSetting: TenantSettings, value: HTags): Observable<HTags> {
        return this.put(this.getBaseUrl() + "/htags/" + tenantSetting, value, "HTAGS." + tenantSetting);
    }
}