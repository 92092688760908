<!--slots-planning.component.html-->
<div class="body" [ngStyle]="generalStyle" [ngClass]="{'absolute-mode': absoluteMode}">
    <div class="info">
        <p class="hora">{{ interval | hourinterval }}</p>
        <p class="disponibles" *ngIf="!absoluteMode">{{ getSlotsReserved() }}/{{ getSlotsMax() }}</p>
        <ng-content></ng-content>
    </div>
    <div *ngIf="intervalslots != null" class="item-container noslotdetails">
        <shared-slot-planning *ngFor="let slot of intervalslots.slots | sortBy:'order'; let index=index"
            class="slot-planning-item noslotdetails" [ngClass]="{ 'not-alter' : !canAlterElement }" [action]="action"
            [slot]="slot" [dockToAssigned]="dockToAssigned" (onRefreshData)="refreshData()"
            (onReduceSlots)="reduceSlotFromintervalslots()" (onEnlargeSlots)="enlargeSlotTointervalslots()"
            (onSlotSelected)="slotSelected($event)" [absoluteMode]="absoluteMode"
            [addressTimetableInterval]="intervalslots"></shared-slot-planning>
    </div>
    <div *ngIf="intervalslots != null && canEnlarge" class="buttons">
        <button class="slot-button enlarge desperate" [ngClass]="{ 'show' : canEnlarge }"
            (click)="enlargeSlotTointervalslots()">
            <i class="fa fa-plus icon" aria-hidden="true"></i>
        </button>
    </div>

</div>