import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HString } from '@shared/src/datatypes/HString';
import { PartnerDetailDto } from '@shared/src/dtos/link/detail/PartnerDetailDto';
import { PartnerTypes } from '@shared/src/enums/PartnerTypes';
import { HomeController } from '../../../controllers/home/home.controller';
import { PartnerDetailComponent } from '../../link/detail/partner.detail.component';

@Component({
  selector: 'register-drivers',
  templateUrl: './register-drivers.component.html',
  styleUrls: ['./register-drivers.component.scss']
})
export class RegisterDriversComponent implements OnInit {

  public generalClass: string = "desktop";

  public linkType: PartnerTypes = PartnerTypes.driver;
  public registerFormGroup: FormGroup;

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();

    this.registerFormGroup = PartnerDetailComponent.buildFromDriver(this.fb);

  }

  ngOnInit() { }

  @Input()
  public newLink: PartnerDetailDto = new PartnerDetailDto();

  @Output()
  public onClickNext: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public onClickAdd: EventEmitter<PartnerDetailDto> = new EventEmitter();

  @Input()
  public countlinks: number;

  @Input() 
  public pendigResponse: boolean;
  
  next() {
    if(this.pendigResponse)
      return;
    this.onClickNext.next(true);
  }

  add() {
    if(this.pendigResponse)
      return;
    this.onClickAdd.next(this.newLink);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
