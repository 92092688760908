import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerInspectorUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.INSPECTOR";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/inspector/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}