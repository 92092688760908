import { Component, EventEmitter, Inject, OnInit, Output, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';


@Component({
	selector: 'shared-route-group-panel',
	templateUrl: './shared.route.group.panel.component.html',
	styleUrls: ['./shared.route.group.panel.component.scss']
})
export class SharedRouteGroupPanelComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	public searchTextGroup: string = "";
	public filterMetadata: any = {};


	@Output() clickBack = new EventEmitter<boolean>();
	@Output() finish = new EventEmitter<RouteGroupDto>();

	constructor(
		public routeController: RouteController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

	public valueSelected: RouteGroupDto;
	public _selectedRouteGroup: RouteCardItemDto;
	public get selectedRouteGroup(): RouteCardItemDto {
		return this._selectedRouteGroup;
	}
	public set selectedRouteGroup(value: RouteCardItemDto) {
		if (this._selectedRouteGroup === value)
			return;
		this._selectedRouteGroup = value;
		
		if(this.selectedRouteGroup != null){
			this.routeController.getRouteGroup(this._selectedRouteGroup.routeId).subscribe(data => {
				this.valueSelected = data;
				this.doActionNewRouteGroup = true;
			});
		}
	}

	ngOnInit(): void {

	}
	clearData(){
		this.doActionNewRouteGroup = false;
		this.selectedRouteGroup = null;
		this.valueSelected = null;
	}

	public _openPanel: boolean = false;
	@Input()
	public get openPanel(): boolean {
		return this._openPanel;
	}
	public set openPanel(value: boolean) {
		if (this._openPanel === value)
			return;
		this._openPanel = value;
		if(this.openPanel){
			this.clearData();
			this.loadSerachData();
		}
	}

	onClickNewRouteGroup(){
		this.valueSelected = new RouteGroupDto();
		this.doActionNewRouteGroup = true;
	}
	onClickImportFileRouteGroup(){
		this.doActionNewRouteGroup = true;
	}

	public doActionNewRouteGroup: boolean = false;
	onClickBackNewRouteGroup(event){
		this.clearData();
	}

	public _searchRoutesGroup: Array<RouteCardItemDto> = [];
	getSearchRoutesGroup() {
		if (this._searchRoutesGroup == null)
			return new Array();
		return this._searchRoutesGroup;
	}

	loadSerachData(){
		this.routeController.getActiveRoutesGroup().subscribe(data => {
			if (data == null) {
				this._searchRoutesGroup = new Array();
				return;
			}
			this._searchRoutesGroup = data;
		});
	}

	onFinishRouteGroup(value: RouteGroupDto) {
		if (value == null || value.routeGroup == null) {
		  return;
		}
		this.clearData();
		this.finish.next(value);
	}

	onClickBack(event) {
		this.clickBack.next(true);
	}

	
}
