<div class="container component">
    
        <div class="linia bar baroptions">
            <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    
        <ng-container *ngIf="actualOptionId==='PACKAGE'">
            PACKAGE
            <shared-trucking-package-item class="package-item" 
                *ngFor="let package of selectedRouteExpand.packages" [selectedRouteExpand]="selectedRouteExpand" [package]="package" (click)="onClickPackage($event, package)"
                [ngClass]="{'selected':selectedPackage!=null && package.packageId==selectedPackage.packageId}"
                [isDraggable]="false" (refresh)="onRefreshShipmentList($event)">
            </shared-trucking-package-item>
        </ng-container>
        <ng-container *ngIf="actualOptionId==='DELIVERY'">
            DELIVERY
        </ng-container>
        <ng-container *ngIf="actualOptionId==='SHIPMENT'">
            SHIPMENT
        </ng-container>

</div>