import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { ShipmentDeliveryDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryDto';
import { ShipmentDeliveryLineDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryLineDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';


@Injectable({
    providedIn: 'root',
})
export class ShipmentDeliveryController extends BaseController {
    private base = '/shipmentdelivery'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getShipmentDelivery(shipmentId: number, forced: boolean): Observable<ShipmentDeliveryDto> {
        return this.get(this.base + "/" + shipmentId + "/" + forced);
    }

    public recalculate(deliveryId: number): Observable<ShipmentDeliveryDto> {
        return this.get(this.base + "/recalculate/" + deliveryId);
    }
    
    public printpdf(deliveryId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/pdf/" + deliveryId);
    }

    public downLoadPdf(delivery: ShipmentDeliveryDto): Observable<ShipmentDeliveryDto> {
        return this.postBusy(this.base + "/recalculate", delivery, "recalculate");
    }

    public emptyShipmentDeliveryLine(delivery: ShipmentDeliveryDto): Observable<ShipmentDeliveryDto> {
        return this.post(this.base + "/line/empty", delivery, "emptyShipmentDeliveryLine");
    }

    public addShipmentDeliveryLine(line: ShipmentDeliveryLineDto): Observable<ShipmentDeliveryDto> {
        return this.post(this.base + "/line/add", line, "addShipmentDeliveryLine");
    }
     
    public modifyShipmentDeliveryLine(line: ShipmentDeliveryLineDto): Observable<ShipmentDeliveryDto> {
        return this.post(this.base + "/line/modify", line, "modifyShipmentDeliveryLine");
    }
    
    public deleteShipmentDeliveryLine(line: ShipmentDeliveryLineDto): Observable<ShipmentDeliveryDto> {
        return this.post(this.base + "/line/delete", line, "deleteShipmentDeliveryLine");
    }
}
