import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomTableData } from '@shared/src/controllers/maintenance/tenant/custom/table/data/CustomTableData';
import { CustomTableRowData } from '@shared/src/controllers/maintenance/tenant/custom/table/data/CustomTableRowData';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';

@Component({
  selector: 'shared-customtable',
  templateUrl: './shared.customtable.component.html',
  styleUrls: ['./shared.customtable.component.scss'],
})
export class SharedCustomTableComponent {

  constructor() { }

  @Output() changedTableData: EventEmitter<void> = new EventEmitter<void>();

  public actions: ActionsStripDto = new ActionsStripDto();
  public actionsAdd: ActionsStripDto = new ActionsStripDto();

  public searchText: string = "";

  @Input() public readonly: boolean;

  @Input() public tableName: string;

  public __customTableData: CustomTableData;
  @Input()
  set customTableData(value: CustomTableData) {
    if (this.__customTableData === value)
      return;
    this.__customTableData = value;
    this.loadCustomTableData();
  }
  get customTableData(): CustomTableData {
    return this.__customTableData;
  }

  public loadCustomTableData() {
    if (this.__customTableData == null) {
      this.actions = new ActionsStripDto();
      return;
    }

    this.__customTableData = CustomTableData.fix(this.__customTableData);

    if (!this.readonly) {
      // this.actions.add(ActionDto.build("NEWCOLUMN", "CUSTOMTABLE.NEWCOLUMN.CAPTION", "CUSTOMTABLE.NEWCOLUMN.HELP", "awesome:fa-trash", 1, -1, -1, ""));
      this.actions.add(ActionDto.build("NEWROW", "CUSTOMTABLE.NEWROW.CAPTION", "CUSTOMTABLE.NEWROW.HELP", "awesome:fa-save", 1, 1, -1, ""));
      // this.actions.add(ActionDto.build("TRUNCATE", "CUSTOMTABLE.TRUNCATE.CAPTION", "CUSTOMTABLE.TRUNCATE.HELP", "awesome:fa-trash", 1, -1, -1, ""));

      this.actionsAdd.add(ActionDto.build("NEWROW", "CUSTOMTABLE.NEWROW.CAPTION", "CUSTOMTABLE.NEWROW.HELP", "awesome:fa-save", 1, -1, 1, ""));
    } else
      this.actions = this.actionsAdd = new ActionsStripDto();
  }


  getHeader() {
    if (this.customTableData != null && this.customTableData.header) {
      this.setColumnMaxWidthCss(this.customTableData.header.length);
      return this.customTableData.header;
    }
    return new Array();
  }

  public setColumnMaxWidthCss(columns: number) {
    let r = document.documentElement;

    let columnMaxWidth = 100 / columns - 1.2;

    r.style.setProperty('--columnMaxWidth', columnMaxWidth.toString() + "vw");
  };


  onClickAction(action: ActionDto) {
    if (this.customTableData != null)
      if (action != null) {
        if (action.actionId == "NEWCOLUMN") {
          CustomTableData.newColumn(this.__customTableData);
          this.changedTableData.next();
        } else if (action.actionId == "NEWROW") {
          CustomTableData.newRow(this.__customTableData);
          this.__customTableData.rows = [...this.__customTableData.rows]
          this.changedTableData.next();
        } else if (action.actionId == "TRUNCATE") {
          CustomTableData.truncate(this.__customTableData);
          this.changedTableData.next();
        }
        this.__customTableData.rows = [...this.__customTableData.rows]
      }
  }

  onChangedTableData() {
    CustomTableData.refreshDataTypes(this.__customTableData);
    this.changedTableData.next();
  }

  ngOnInit() {

  }

  public expandedLine: boolean;

  public expandedLineId: number;
  isExpandedLine(rowData: CustomTableRowData) {
    if (rowData == null)
      return false;

    if (rowData.row == this.expandedLineId)
      return true;

    return false;
  }

  public onExpandedLine(rowData: CustomTableRowData) {

    this.expandedLine = !this.expandedLine;

    if (rowData == null || !this.expandedLine) {
      this.expandedLineId = null;
      return;
    }

    this.expandedLineId = rowData.row;

  }

  public expandedHeader: boolean;
  public onExpandedHeader() {
    this.expandedHeader = !this.expandedHeader;
  }


  ////////////actions/////////////////

  public isDeleteColumn(column: number) {
    if (this.deleteColumnSelectedId > 0)
      return this.deleteColumnSelectedId == column;
    return false;
  }

  public deleteColumnSelectedId: number;
  public onDeleteColumnSelected(value: number) {
    this.deleteColumnSelectedId = value;
  }

  public deleteRowSelectedId: number;
  public onDeleteRowSelected(value: number) {
    this.deleteRowSelectedId = value;
  }

  showDeleteRowConfirmButton(row: number): boolean {
    return this.deleteRowSelectedId > 0 && this.deleteRowSelectedId == row;
  }
  showDeleteColumnButton(column: number): boolean {
    return this.deleteColumnSelectedId > 0 && this.deleteColumnSelectedId == column;
  }

  onDeleteLine(row: number) {
    CustomTableData.removeRow(this.__customTableData, row);
    this.deleteRowSelectedId = null;
    this.changedTableData.next();
  }
  onDeleteColumn(column: number) {
    CustomTableData.removeColumn(this.__customTableData, column);
    this.deleteColumnSelectedId = null;
    this.changedTableData.next();
  }
}