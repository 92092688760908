import { ManagementModelRequirementActionTypes } from "@shared/src/enums/ManagementModelRequirementActionTypes";
import { DocumentDto } from "../document/DocumentDto";
import { ManagementModelObjectParentTypes } from "@shared/src/enums/ManagementModelObjectParentTypes";


export class ManagementModelRequirementObjectParentActionDto {

    public action: ManagementModelRequirementActionTypes;

    public objectParentType: ManagementModelObjectParentTypes;
    
	public objectParentId: number;
    
    public managementModelRequirementId: number;
    
    public managementModelRequirementResponseId: number;

    public comments: string;

    public documentUpload: DocumentDto;
    
   public static build(action: ManagementModelRequirementActionTypes, comments: string, objectParentType: ManagementModelObjectParentTypes, objectParentId: number, managementModelRequirementId: number, managementModelRequirementResponseId: number, documentUpload: DocumentDto): ManagementModelRequirementObjectParentActionDto{
        let dto = new ManagementModelRequirementObjectParentActionDto();
        dto.action = action;
        dto.objectParentType = objectParentType;
        dto.objectParentId = objectParentId;
        dto.managementModelRequirementId = managementModelRequirementId;
        dto.managementModelRequirementResponseId = managementModelRequirementResponseId;
        dto.comments = comments;
        dto.documentUpload = documentUpload;
        return dto;
   }
}
