import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerClientPreferentSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.CLIENTPREFERENT";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/clientpreferent/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}