
import { Component, Input, Inject, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { RoutePanelLogDto } from '@shared/src/dtos/panel/log/RoutePanelLogDto';

@Component({
    selector: 'shared-panel-element-log',
    templateUrl: './shared.panel.element.log.component.html',
    styleUrls: ['./shared.panel.element.log.component.scss']
})
export class SharedPanelElementLogComponent implements OnInit {

    public useIonic: boolean = false;

    constructor(@Inject('HomeController') protected homeController: HomeController) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    private _logs: Array<RoutePanelLogDto>;
    @Input() public set logs(value: Array<RoutePanelLogDto>) {
        if (this._logs === value)
            return;

        this._logs = value;
    }
    public get logs(): Array<RoutePanelLogDto> {
        return this._logs;
    }

    ngOnInit(): void {
        
    }

   
}
