<div *ngIf="shipment">

    <div class="container-group" *ngIf="canAssignForms">
        <div class="title-information">{{'SHIPMENT.PROVIDER.CAPTION' | translate}}</div>
        <entity-selector class="col-40 client" [(ngModel)]="partnerProviderId" [entitySelector]="'Partner'"
            [fixedFilterKeys]="filterkeysProvider" name="partnerProviderId" name="partnerProviderId">
        </entity-selector>

        <div class="title-information">{{'SHIPMENT.CLIENT.CAPTION' | translate}}</div>
        <entity-selector class="col-40 client" [(ngModel)]="partnerClientId" [entitySelector]="'Partner'"
            [fixedFilterKeys]="filterkeysClient" name="partnerClientId" name="partnerClientId">
        </entity-selector>

        <div class="title-information">{{ 'SHIPMENTPAGE.ITEM.EDIT.FORMS' | translate }}</div>
        <entity-selector class="col-x" [(ngModel)]="loadFormId" [readonly]="readonly"
            [fixedFilterKeys]="filterkeysLoadForm" [entitySelector]="'Form'"
            placeholder="{{'SHIPMENT.LOADFORMID.CAPTION' | translate}}" name="loadFormId">
        </entity-selector>
        <entity-selector class="col-x" [(ngModel)]="unLoadFormId" [readonly]="readonly"
            [fixedFilterKeys]="filterkeysUnLoadForm" [entitySelector]="'Form'"
            placeholder="{{'SHIPMENT.UNLOADFORMID.CAPTION' | translate}}" name="unLoadFormId">
        </entity-selector>
        <div class="container-group">
            <!-- Condicions basiques -->
            <div class="title-information">{{'SHARED.SHIPMENT.ITEM.MAINTENANCE.CONDITIONS.CAPTION'| translate}}</div>
            <div class="col-x">
                <datehour-picker class="col-20" name="minDateTime"
                    placeholder="{{'ROUTESHIPMENTCONDITIONTYPES.MINDATETIME' | translate}}" [readonly]="readonly"
                    [(ngModel)]="minDateTime">
                </datehour-picker>
            </div>
            <div class="col-x">
                <datehour-picker class="col-20" name="originDateTimeStart"
                    placeholder="{{'SHIPMENT.WIZARD.ORIGINSTART.CAPTION' | translate}}" [readonly]="readonly"
                    [(ngModel)]="originDateTimeStart" [max]="destinationDateTimeStart">
                </datehour-picker>
    
                <datehour-picker class="col-20" name="originDateTimeEnds"
                    placeholder="{{'SHIPMENT.WIZARD.ORIGINEND.CAPTION' | translate}}" [readonly]="readonly"
                    [(ngModel)]="originDateTimeEnds" [min]="getMin(originDateTimeStart)" [max]="destinationDateTimeStart">
                </datehour-picker>
            </div>
            <div class="col-x">
                <hourintervals-picker class="col-20" placeholder="{{'SHIPMENT.TIMETABLE.CAPTION' | translate}}"
                    name="originTimetable" [readonly]="readonly" [(ngModel)]="originTimetable">
                </hourintervals-picker>
            </div>
    
            <div class="col-x">
                <datehour-picker class="col-20" name="destinationDateTimeStart"
                    placeholder="{{'SHIPMENT.WIZARD.DESTINATIONSTART.CAPTION' | translate}}" [readonly]="readonly"
                    [(ngModel)]="destinationDateTimeStart" [min]="getMin(originDateTimeEnds)"
                    [max]="destinationDateTimeEnds">
                </datehour-picker>
                <datehour-picker class="col-20" name="destinationDateTimeEnds"
                    placeholder="{{'SHIPMENT.WIZARD.DESTINATIONEND.CAPTION' | translate}}" [readonly]="readonly"
                    [(ngModel)]="destinationDateTimeEnds" [min]="getMin(destinationDateTimeStart)">
                </datehour-picker>
            </div>
            <div class="col-x">
                <hourintervals-picker class="col-20" placeholder="{{'SHIPMENT.TIMETABLE.CAPTION' | translate}}"
                    name="destinationTimetable" [readonly]="readonly" [(ngModel)]="destinationTimetable">
                </hourintervals-picker>
            </div>
    
            <!-- Condicions de modeTermostat/temperatura/humitat -->
            <div class="col-x">
                <tristate class="mode" [(ngModel)]="thermostatRunMode"
                    placeholder="{{'SHIPMENT.THERMOSTATRUNMODE.CAPTION' | translate}}"
                    preplaceholder="{{'SHIPMENT.PRETHERMOSTATRUNMODE.CAPTION' | translate}}" name="thermostatRunMode"
                    [readonly]="readonly">
                </tristate>
            </div>
    
            <div class="col-x">
                <input-component class="col-5 temp-input" placeholder="{{'SHIPMENT.TEMPERATURE.MIN.CAPTION' | translate}}"
                    name="minTemperature" type="number" [min]="-100" [max]="100" [step]="0.1" autocomplete="off"
                    [(ngModel)]="minTemperature" [readonly]="readonly">
                </input-component>
                <input-component class="col-5 temp-input" placeholder="{{'SHIPMENT.TEMPERATURE.MAX.CAPTION' | translate}}"
                    name="maxTemperature" type="number" [min]="-100" [max]="100" [step]="0.1" autocomplete="off"
                    [(ngModel)]="maxTemperature" [readonly]="readonly">
                </input-component>
                <boolean-component class="temp-boolean" [(ngModel)]="isActiveConditionTemp" [readonly]="readonly"
                    placeholder="{{'SHIPMENT.ISACTIVECONDITIONTEMP.CAPTION' | translate}}" name="isActiveConditionTemp"
                    [showHelpPoint]=false>
                </boolean-component>
            </div>
    
            <div class="col-x">
                <input-component class="col-5 temp-input" placeholder="{{'SHIPMENT.HR.MIN.CAPTION' | translate}}"
                    name="minHR" type="number" [min]="0" [max]="100" [step]="0.1" autocomplete="off" [(ngModel)]="minHR"
                    [readonly]="readonly">
                </input-component>
                <input-component class="col-5 temp-input" placeholder="{{'SHIPMENT.HR.MAX.CAPTION' | translate}}"
                    name="maxHR" type="number" [min]="0" [max]="100" [step]="0.1" autocomplete="off" [(ngModel)]="maxHR"
                    [readonly]="readonly">
                </input-component>
                <boolean-component class="temp-boolean" [(ngModel)]="isActiveConditionHR" [readonly]="readonly"
                    placeholder="{{'SHIPMENT.ISACTIVECONDITIONHR.CAPTION' | translate}}" name="isActiveConditionHR"
                    [showHelpPoint]=false>
                </boolean-component>
            </div>
        </div>
        <!-- EMAILS -->
        <div class="col-x emails">
            <mat-accordion class="expansionConditionsForm">
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="ellipsis">
                                <span class="title-expansion">
                                    {{'SHIPMENT.EMAILSPROVIDER.TITLE.CAPTION' | translate }}</span>
                                <span *ngIf="shipment.emailsProviderResumed">: </span>
                                <span matTooltip="{{ shipment.emailsProviderResumed }}" class="title-expansion-resumed">
                                    {{ shipment.emailsProviderResumed }}
                                </span>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel-body>
                        <div class="cos">
                            <div class="col-x help">
                                {{'SHIPMENT.EMAILSPROVIDER.HELP.CAPTION' | translate }}
                            </div>

                            <textarea-component class="col-x" [(ngModel)]="emailsProviderPromised"
                                placeholder="{{'SHIPMENT.EMAILS.PROMISED.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsProviderPromised" autocomplete="off" [readonly]="readonly">
                            </textarea-component>

                            <textarea-component class="col-x" [(ngModel)]="emailsProviderInProgress"
                                placeholder="{{'SHIPMENT.EMAILS.LOADED.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsProviderInProgress" autocomplete="off" [readonly]="readonly">
                            </textarea-component>

                            <textarea-component class="col-x" [(ngModel)]="emailsProviderDone"
                                placeholder="{{'SHIPMENT.EMAILS.DONE.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsProviderDone" autocomplete="off" [readonly]="readonly">
                            </textarea-component>
                        </div>
                    </mat-expansion-panel-body>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion class="expansionConditionsForm">
                <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="ellipsis">
                                <span class="title-expansion">
                                    {{'SHIPMENT.EMAILSCLIENT.TITLE.CAPTION' | translate }}</span>
                                <span *ngIf="shipment.emailsResumed">: </span>
                                <span matTooltip="{{ shipment.emailsResumed }}" class="title-expansion-resumed">
                                    {{ shipment.emailsResumed }}
                                </span>
                            </div>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel-body>
                        <div class="cos">
                            <div class="col-x help">
                                {{'SHIPMENT.EMAILSCLIENT.HELP.CAPTION' | translate }}
                            </div>

                            <textarea-component class="col-x" [(ngModel)]="emailsPromised"
                                placeholder="{{'SHIPMENT.EMAILS.PROMISED.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsPromised" autocomplete="off" [readonly]="readonly">
                            </textarea-component>

                            <textarea-component class="col-x" [(ngModel)]="emailsInProgress"
                                placeholder="{{'SHIPMENT.EMAILS.LOADED.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsInProgress" autocomplete="off" [readonly]="readonly">
                            </textarea-component>

                            <textarea-component class="col-x" [(ngModel)]="emailsDone"
                                placeholder="{{'SHIPMENT.EMAILS.DONE.DESCRIPTION.CAPTION' | translate }}"
                                name="emailsDone" autocomplete="off" [readonly]="readonly">
                            </textarea-component>
                        </div>
                    </mat-expansion-panel-body>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

</div>
