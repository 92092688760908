import { Component, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PhaseSecretDto } from '@shared/src/controllers/route/phase/PhaseSecretDto';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { iPhaseSlot } from '@shared/src/dtos/phase/iPhaseSlot';
import { PhaseController } from '@shared/src/public-api';


@Component({
  selector: 'shared-secret',
  templateUrl: './shared.secret.component.html',
  styleUrls: ['./shared.secret.component.scss'],
})
export class SharedSecretComponent {

  constructor(public phaseController: PhaseController) {

  }

  public _iphaseSlot: iPhaseSlot = null;
  @Input() public set iPhaseSlot(value: iPhaseSlot) {
    if (this._iphaseSlot == value)
      return;
    this._iphaseSlot = value;
    this.wellComeIPhaseSlot();
  }
  public get iPhaseSlot(): iPhaseSlot {
    return this._iphaseSlot;
  }

  public value: PhaseSecretDto = null;
  public valueIsNull: boolean = true;
  public generalClass = "";
  public wellComeIPhaseSlot() {

    if (this._iphaseSlot == null) {
      console.log("iphseSlot es null");
      return;
    }
    console.log("iphseSlot no es null " + this.iPhaseSlot.phaseId);
    this.phaseController.getPhaseSecretInfo(this._iphaseSlot.phaseId).subscribe(e => {
      this.generalClass = "";
      this.value = e;

      if (this.value == null) {
        this.valueIsNull = true;
        this.generalClass = "noinfo";
      }
      else {
        this.valueIsNull = false;
        if (this.value.slotInfo != null) {
          if (!this.value.slotInfo.requiresSlot)
            this.generalClass = "noshow";
        }
      }

    });
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
