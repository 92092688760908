import { Input, OnInit, Directive } from '@angular/core';
import { ControlContainer, FormControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ValueAccessorBase } from './value-accessor';

@Directive()
export abstract class ValueAccessorValidatedBase<T, S> extends ValueAccessorBase<T, S> implements OnInit {

  @Input() public formControlName: string;
  @Input() public formControl: FormControl;

  private parentvalidators: ValidatorFn[] = [];
  private parentasyncvalidators: AsyncValidatorFn[] = [];

  constructor(
    private controlContainer: ControlContainer,
    private validators: ValidatorFn[],
    private asyncValidators: AsyncValidatorFn[]
  ) {
    super();
  }

  ngOnInit() {
    if (this.validators) {
      this.validators.forEach(element => {
        this.parentvalidators.push(element);
      });
    }

    if (this.asyncValidators) {
      this.asyncValidators.forEach(element => {
        this.parentasyncvalidators.push(element);
      });
    }

    if (this.controlContainer) {
      if (this.formControlName) {
        let control = this.controlContainer.control.get(this.formControlName);
        if (control) {
          if (control.validator)
            this.parentvalidators.push(control.validator);
          if (control.asyncValidator)
            this.parentasyncvalidators.push(control.asyncValidator);
        }
      }
    }

    if (this.formControl) {
      if (this.formControl.validator)
        this.parentvalidators.push(this.formControl.validator);
      if (this.formControl.asyncValidator)
        this.parentasyncvalidators.push(this.formControl.asyncValidator);
    }

    this.createForm(this.parentvalidators, this.parentasyncvalidators);
  }
  abstract createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]);
}