<div *ngIf="phase">
    <enum-selector *ngIf="showBlamesList" class="col-x" [enumvalues]="blamesList" translategroup="PHASETIMES.BLAMES"
        [(ngModel)]="changeBlameData.blame" [readonly]="readonly" placeholder="{{ 'PHASETIMES.BLAME' | translate }}"></enum-selector>

    <textarea-component class="col-x" [(ngModel)]="changeBlameData.message"
        placeholder="{{'PHASETIMES.CHANGEBLAMEMESSAGE' | translate}}" name="message" autocomplete="off"
        [readonly]="readonly">
    </textarea-component>

    <button class="action" [disabled]="!changeBlameData.blame || phase.blame == changeBlameData.blame" (click)="save()">{{ 'MAINTENANCE.ACCEPT' | translate }}</button>
</div>
