import { Inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { MobileSecurityController } from '../app/controllers/security/mobile-security.controller';
import { MobileAlertService } from './mobile.alert.service';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private router: Router, @Inject('SecurityController') private securityController: MobileSecurityController, @Inject('AlertService') private alertService: MobileAlertService) {

  }

  initPush() {
    console.log("initPush");
    if (Capacitor.getPlatform() !== 'web') {
      console.log('pushService.registerPush');
      this.registerPush();
    }
  }

  registerPush() {
    console.log("registerPush");
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive == 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        console.log("registerPush permission.granted");
        PushNotifications.register();
      } else {
        // No permission for push granted
        console.log("registerPush !permission.granted");
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: Token) => {
        console.log("token FMC Service: " + token.value);
        this.securityController.setDeviceToken(token.value);
      }
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        if (notification != null && notification.data != null)
          this.alertService.info(notification.data.body, false);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (notification.notification != null && notification.notification.data != null)
          this.alertService.info(notification.notification.data.body, false);
      }
    );
  }
}