import { Injectable } from '@angular/core';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { DocumentListDto } from './DocumentListDto';
import { iDocumentDetail } from './iDocumentDetail';

@Injectable()
export class DocumentDetailDto extends DocumentListDto implements iDocumentDetail {
    public documentDetailId: number;
    public fileBytes: any[];
    public fileString: string;
    public fileType: FileTypes;
    public fileName: string;

}
