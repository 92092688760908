import { Component, Inject, Input } from '@angular/core';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { ShipmentDeliveryController } from '@shared/src/controllers/route/delivery/shipmentdelivery.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ShipmentDeliveryDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryDto';
import { ShipmentDeliveryLineDto } from '@shared/src/dtos/shipment/delivery/ShipmentDeliveryLineDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
  selector: 'shared-shipment-delivery-component',
  templateUrl: './shared.shipment.delivery.component.html',
  styleUrls: ['./shared.shipment.delivery.component.scss'],
})
export class SharedShipmentDeliveryComponent {

  constructor(private shipmentDeliveryController: ShipmentDeliveryController,
    @Inject('HomeController') protected homeController: HomeController) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };


  public actions: ActionsStripDto;
  public useIonic: boolean = false;

  @Input() public readonly: boolean;
  @Input() public hideactions: boolean;

  public __shipmentId: number;
  @Input()
  set shipmentId(value: number) {
    if (this.__shipmentId === value)
      return;
    this.__shipmentId = value;
    this.loadShipmentDeliveryData();
  }
  get shipmentId(): number {
    return this.__shipmentId;
  }

  public shipmentDelivery: ShipmentDeliveryDto;
  public loadShipmentDeliveryData() {
    if (this.__shipmentId == null || this.__shipmentId == 0) {
      this.loadData(null);
      return;
    }
    this.shipmentDeliveryController.getShipmentDelivery(this.__shipmentId, true).subscribe(data => {
      this.loadData(data);
    });

  }


  public loadData(data: ShipmentDeliveryDto) {
    this.shipmentDelivery = data;
    if (this.shipmentDelivery != null)
      this.lines = this.shipmentDelivery.lines;
    else
      this.lines = null;

    if (data != null && data.actions != null)
      this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);
    else
      this.actions = new ActionsStripDto();
  }

  public deleteSelectedLine: ShipmentDeliveryLineDto;

  public expandedLineId: number;

  public onExpandedLine(expandedlineid: number) {
    this.expandedLineId = expandedlineid;
  }

  public lines: ShipmentDeliveryLineDto[];
  getLines() {
    if (this.lines != null) {
      return this.lines;
    }
    return new Array();
  }

  public onRefreshDelivery(value: ShipmentDeliveryDto, shipmentDeliveryLineIdModify: number) {
    this.shipmentDelivery = value;
    if (value == null) {
      this.lines = null;
      return;
    }

    if (HLong.isNullOrNullLong(shipmentDeliveryLineIdModify)) {
      this.lines = value.lines;
      return;
    }
    if (this.lines == null)
      return;

    let i = 0;
    while (i < this.lines.length) {
      //Només actualitzem la línea que s'ha modificat
      if (this.lines[i].shipmentDeliveryLineId === shipmentDeliveryLineIdModify) {
        let j = 0;
        //Busquem la línea per actualitzar
        while (j < value.lines.length) {
          if (this.lines[i].shipmentDeliveryLineId === value.lines[j].shipmentDeliveryLineId) {
            ShipmentDeliveryLineDto.actualizedData(value.lines[j], this.lines[i]);
          }
          j++;
        }
      }
      i++;
    }
  }

  public line: ShipmentDeliveryLineDto


  onClickAction(action: ActionDto) {
    if (this.shipmentDelivery != null)
      if (action != null) {
        if (action.actionId == "SHIPMENTDELIVERY.RECALCULATE")
          this.shipmentDeliveryController.recalculate(this.shipmentDelivery.shipmentDeliveryId).subscribe(data => {
            this.loadData(data);
          });
        if (action.actionId == "SHIPMENTDELIVERY.PDF")
          this.shipmentDeliveryController.printpdf(this.shipmentDelivery.shipmentDeliveryId).subscribe(data => {
            FileUpDownloadController.resultDownLoad(data);
          });
        if (action.actionId == "SHIPMENTDELIVERY.NEWLINE")
          this.shipmentDeliveryController.emptyShipmentDeliveryLine(this.shipmentDelivery).subscribe(data => {
            this.loadData(data);
          });
      }
  }

  ngOnInit() {

  }

}
