<div *ngIf="locator" class="input-zone" [ngClass]="{'extended': extended}">
    <i class="barres fa fa-bars" aria-hidden="true" (click)="extended=!extended"></i>

    <div class="extendedzone">
        <div class="block search">
            <input class="inputsearch" [(ngModel)]="locator.searchString" autocomplete="on"
                placeholder="{{'SHARED.LITE.COMPONENT.SEARCHSTRING.PLACEHOLDER' | translate}}"
                (keyup)="searchStringChanged($event)">
        </div>

        <div class="block status">
            <div class="title">
                {{'SHARED.LITE.COMPONENT.STATUS.CAPTION' | translate}}
            </div>
            <shared-tags-toggle [(tags)]="locator.status" (changed)="onTagsToggleChagend()"></shared-tags-toggle>
        </div>
        <div class="block objects">
            <shared-lite-item-component [value]="item" [selectedItem]="isSelected(item)"
                *ngFor="let item of clusteredLocators | sortBy: '-globalTelemetry.moment' | slice:0:100;"
                (click)="clickItem(item)" (clickItemChild)="clickItemChild($event)"></shared-lite-item-component>
        </div>
    </div>
</div>

<!-- <shared-sublite-component *ngIf="extendedChils && extended" [locatorextern]="locatorChilds"></shared-sublite-component> -->
<button *ngIf="requestMoreDrivers" class="more-drivers action action-required" (click)="moreDriversClick()"><i
        class="fa fa-truck" aria-hidden="true"></i>
    {{'SHARED.LITE.COMPONENT.MOREDRIVERS.BUTTON' | translate}}</button>

<shared-map [marcadors]="marcadors" [(latitude)]="latitude" [(longitude)]="longitude"
    (marcadorClick)="onMarkerClick($event)" (marcadorDblClick)="onMarkerDblClick($event)"
    [minZoom]="minZoom"></shared-map>