
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTranslateService, BasicModule, ComponentsModule, AuthInterceptor, HttpLoaderFactory } from '@shared/src/public-api';
import { MobileAlertService } from '../services/mobile.alert.service';
import { MobileLoadingService } from '../services/mobile.loading.service';
import { CommonAppModule } from './common.app.module';
import { MobileGlobalErrorHandler } from '../services/mobile.global.error.handler';
import { MobileHomeController } from './controllers/home/mobile-home.controller';
import { MobileAlertComponent } from './components/alert/mobile.alert.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MobileSecurityController } from './controllers/security/mobile-security.controller';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { MobileComponentsAppModule } from './mobile.components.app.module';
import { LogoutPage } from './logout/logout.page';
import { MobileGeolocationController } from './controllers/geolocalization/mobile.geolocation.controller';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { MobileAsideFactory } from './aside/mobile.aside.factory';
import { CameraService } from '@shared/src/services/camera/camera.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileService } from '@shared/src/services/file/file.service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Market } from '@ionic-native/market/ngx';

@NgModule({
  declarations: [
    AppComponent,
    MobileAlertComponent,
    LogoutPage
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    CommonAppModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    GoogleMapsModule,
    BasicModule.forRoot(HTranslateService, MobileAlertService, MobileLoadingService, MobileHomeController, MobileSecurityController, MobileAsideFactory, MobileGeolocationController),
    ComponentsModule,
    MobileComponentsAppModule,
    AppRoutingModule
  ],
  providers: [
    InAppBrowser,
    CommonAppModule,
    TranslateService,
    AndroidPermissions,
    BackgroundMode,
    Geolocation,
    HTranslateService,
    MobileGeolocationController,
    MobileAlertService,
    MobileLoadingService,
    MobileHomeController,
    MobileSecurityController,
    MobileAsideFactory,
    StatusBar,
    SplashScreen,
    CameraService,
    FileService,
    Deeplinks,
    Market,
    { provide: ErrorHandler, useClass: MobileGlobalErrorHandler }, // override default error handler
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  exports: [
  ],
})
export class AppModule { }
