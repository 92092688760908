<div class="container" *ngIf="route">
        <div class="primeraFila">
                <div class="titles">
                        <span class="title key">{{route.routeKey}}</span>
                        <span class="stitle">{{route.title}}</span>
                </div>
                <div class="right">
                        <button class="general-link-boto jump light" (click)="onARClick()"><i aria-hidden="true"
                                        class="fa fa-share"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AR.PROMPT'
                                |
                                translate}}</button>
                        <button class="general-link-boto jump light" (click)="onASClick()"><i aria-hidden="true"
                                        class="fa fa-share"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AS.PROMPT'
                                |
                                translate}}</button>
                        <button *ngIf="deletemode" class="deleteButton mini" (click)="delete()"><i class="fa fa-trash"
                                        aria-hidden="true"></i></button>
                </div>
        </div>
        <div class="tags">
                <shared-tags-strip-component [tags]="route.tagsBottom"></shared-tags-strip-component>
        </div>
        <div class="bottom-zone">
                <shared-route-strip-component [route]="route" [isActiveRoutes]="false"></shared-route-strip-component>
        </div>
</div>