<ng-container *ngIf="!data">
    <div class="block-esquerra">
        <div class="header"></div>
        <div class="nouuid">
            No valid uuid
        </div>
    </div>
</ng-container>
<div *ngIf="data" class="container" [ngClass]="{'obert':paneDretaObert, 'tancat':!paneDretaObert, 'una':data.uniquePhase, 'multiple':!data.uniquePhase,
'plateAIncorrect':data.plateAIncorrect,'plateBIncorrect':data.plateBIncorrect,'plateCIncorrect':data.plateCIncorrect,'driverNameIncorrect':data.driverNameIncorrect,'driverPhoneIncorrect':data.driverPhoneIncorrect,'driverLegalIdentificationIncorrect':data.driverLegalIdentificationIncorrect,
'carrierNameIncorrect':data.carrierNameIncorrect,'carrierLegalIdentificationIncorrect':data.carrierLegalIdentificationIncorrect,'carrierAddressIncorrect':data.carrierAddressIncorrect,
'bannerHtml':data.bannerHtml, 'bannerText':data.bannerText}">
    <div class="block-esquerra">
        <div class="header">
            <img *ngIf="!logoPropi" _ngcontent-uom-c345="" src="/assets/images/HardmanLogoBlauNom.png"
                alt="Hardman logo" class="logo logoambnom">
            <shared-image class="logo" *ngIf="logoPropi" [image]="logoImage">
            </shared-image>
        </div>
        <div class="titol paddingtop">
            <div class="routeKey">{{data.routeKey}}</div>
            <div class="routeTitle">{{data.title}}</div>
            <div class="clientReferences">{{data.customerReferences}}</div>
            <div class="banner only-bannerHtml" [innerHtml]="data.bannerHtml"></div>
            <div class="banner only-bannerText">{{data.bannerText}}</div>
            <div *ngIf="data.isGrouped" class="agrupada"></div>
        </div>
        <div *ngIf="data.isChildOfGrouped" class="block-dades">
            <div class="cannot-managment">
                <div class="titol">{{'SHARED.EXWORK.CANNOTMANAGEMENT.CAPTION' | translate}}</div>
                <div class="text">{{'SHARED.EXWORK.CANNOTMANAGEMENT.GROUPING.CAPTION' | translate}}</div>
            </div>
            <button-link variant="forward" class="line left" (linkClick)="onLoadNewUUID()">{{
                'SHARED.EXWORK.CANNOTMANAGEMENT.LINK.GONEWUUID' | translate}}</button-link>

        </div>
        <div *ngIf="data.isDivided" class="block-dades">
            <div class="cannot-managment">
                <div class="titol">{{'SHARED.EXWORK.CANNOTMANAGEMENT.CAPTION' | translate}}</div>
                <div class="text">{{'SHARED.EXWORK.CANNOTMANAGEMENT.DIVIDED.CAPTION' | translate}}</div>
            </div>
        </div>

        <div *ngIf="!data.isChildOfGrouped && !data.isDivided && viewToShow=='general'" class="block-dades">
            <div *ngIf="!requereixBooking()" class="titol">
                <div class="inline" *ngIf="!editMode">
                    <div *ngIf="data.showAskCarrierName" class="box carrierName">
                        <div class="toplabel">
                            {{'ROUTEPAGE.ITEM.EDIT.CARRIERNAME' | translate}}
                        </div><span class="invisible" *ngIf="!data.carrierName">{{'SHARED.EXWORK.NOCARRIERNAME' |
                            translate}}</span>
                        {{data.carrierName}}
                    </div>
                    <div *ngIf="data.showAskCarrierLegalIdentification" class="box carrierLegalIdentification">
                        <div class="toplabel">
                            {{'ROUTEPAGE.ITEM.EDIT.CARRIERLEGALIDENTIFICATION' | translate}}
                        </div><span class="invisible"
                            *ngIf="!data.carrierLegalIdentification">{{'SHARED.EXWORK.NOCARRIERLEGALIDENTIFICATION' |
                            translate}}</span>
                        {{data.carrierLegalIdentification}}
                    </div>
                    <div *ngIf="data.showAskCarrierAddress" class="box carrierAddress">
                        <div class="toplabel">
                            {{'ROUTEPAGE.ITEM.EDIT.CARRIERADDRESS' | translate}}
                        </div><span class="invisible" *ngIf="!data.carrierAddress">{{'SHARED.EXWORK.NOCARRIERADDRESS' |
                            translate}}</span>
                        {{data.carrierAddress}}
                    </div>

                    <div class="box plateA">
                        <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translate}}</div><span class="invisible"
                            *ngIf="!data.plateA">{{'SHARED.EXWORK.NOPLATEA' | translate}}</span> {{data.plateA}}
                    </div>
                    <div class="box plateB">
                        <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translate}}</div><span class="invisible"
                            *ngIf="!data.plateB">{{'SHARED.EXWORK.NOPLATEB' | translate}}</span> {{data.plateB}}
                    </div>
                    <div class="box plateC" *ngIf="!data.hidePlateC">
                        <div class="toplabel">{{ data.resourcePlateC | translate}}</div><span class="invisible"
                            *ngIf="!data.plateC">{{'SHARED.EXWORK.NOPLATEC' | translate}}</span> {{data.plateC}}
                    </div>
                    <div class="box driverName">
                        <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translate}}</div><span
                            class="invisible" *ngIf="!data.driverName">{{'SHARED.EXWORK.NODRIVERNAME' |
                            translate}}</span>
                        {{data.driverName}}
                    </div>
                    <div class="box driverLegalIdentification">
                        <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERLEGALIDENTIFICATION' | translate}}</div><span
                            class="invisible"
                            *ngIf="!data.driverLegalIdentification">{{'SHARED.EXWORK.NODRIVERLEGALIDENTIFICATION' |
                            translate}}</span>
                        {{data.driverLegalIdentification}}
                    </div>
                    <div class="box driverPhone">
                        <div class="toplabel">{{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translate}}</div><span
                            class="invisible" *ngIf="!data.driverPhone">{{'SHARED.EXWORK.NODRIVERPHONE' |
                            translate}}</span>
                        <a *ngIf="data.driverPhone" href="tel:{{data.driverPhone}}"><i class="fa fa-phone-square"
                                aria-hidden="true"></i>{{data.driverPhone}}</a>
                    </div>

                    <button *ngIf="!requereixBooking() && !data.readonlyDataInfo" class="link right positionrara"
                        (click)="startEditmode()">{{'SHARED.EXWORK.EDIT.DRIVERPLATE.CAPTION' | translate}}</button>
                </div>
            </div>
            <div class="canvi-dades" *ngIf="algoAFer() && !data.readonly && !data.readonlyDataInfo && !requereixBooking()">
                <div class="zona-edit" *ngIf="editMode">
                    <div class="title d-block">{{ 'SHARED.DRIVEREPLATE.COMPONENT.TITLE' | translateH }}</div>
                    <div class="help">
                        {{'SHARED.EXWORK.EDIT.HELP' | translate}}
                    </div>

                    <input-component *ngIf="data.showAskCarrierName" class="col-x" [(ngModel)]="carrierName"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERNAME' | translate}}"></input-component>
                    <input-component *ngIf="data.showAskCarrierLegalIdentification" class="col-x"
                        [(ngModel)]="carrierLegalIdentification"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERLEGALIDENTIFICATION' | translate}}"></input-component>
                    <input-component *ngIf="data.showAskCarrierAddress" class="col-x" [(ngModel)]="carrierAddress"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.CARRIERADDRESS' | translate}}"></input-component>

                    <input-component class="col-x" [(ngModel)]="driverName"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERNAME' | translate}}"></input-component>
                    <input-component class="col-x" [(ngModel)]="driverLegalIdentification"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERLEGALIDENTIFICATION' | translate}}"></input-component>

                    <input-component class="col-x" [(ngModel)]="driverPhone"
                        placeholder="{{'ROUTEPAGE.ITEM.EDIT.DRIVERPHONE' | translate}}"></input-component>

                    <div class="col-x">
                        <input-component class="col-plate" [(ngModel)]="plateA"
                            placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEA' | translate}}"></input-component>
                        <input-component class="col-plate" [(ngModel)]="plateB"
                            placeholder="{{'ROUTEPAGE.ITEM.EDIT.PLATEB' | translate}}"></input-component>
                        <input-component *ngIf="!data.hidePlateC" class="col-plate" [(ngModel)]="plateC"
                            placeholder="{{ data.resourcePlateC | translate}}"></input-component>
                    </div>

                    <shared-savebutton class="botodesar" (save)="save($event)">
                        {{'SHARED.EXWORK.DRIVER.BUTTON.SAVE' | translate}}
                    </shared-savebutton>
                </div>

            </div>
            <div class="fases" *ngIf="algoAFer() && data">
                <div class="item only-multiple" [ngClass]="{'showNoRequiresSlot':showNoRequiresSlot}">
                    <div class="item" *ngFor="let phase of data.phaseList | sortBy:'phaseCard.executionOrder'">
                        <shared-exwork-phasecard-component [phase]="phase.phaseCard"
                            [ngClass]="{'requiresSlot':phase.requiresSlot,'noRequiresSlot':!phase.requiresSlot}">
                        </shared-exwork-phasecard-component>
                        <shared-exwork-slotpicker-component class="align-right"
                            *ngIf="phase.requiresSlot && !requereixBooking()" [uuid]="uuid" [phase]="phase.phaseCard"
                            (slotChanged)="getDades()" [slotInfo]="phase.slotInfo" [readonly]="data.readonly">
                        </shared-exwork-slotpicker-component>
                    </div>
                    <button class="link right ng-star-inserted" (click)="showNoRequiresSlot=!showNoRequiresSlot">
                        <span *ngIf="showNoRequiresSlot">{{'SHARED.EXWORK.SHOWNOREQUIRESSLOT.ACTIVE' |
                            translate}}</span>
                        <span *ngIf="!showNoRequiresSlot">{{'SHARED.EXWORK.SHOWNOREQUIRESSLOT.NOACTIVE' |
                            translate}}</span></button>
                </div>
            </div>
            <div *ngIf="esBooking() && !data.readonly && !data.readonlyDataInfo" class="block-dades">
                <shared-exwork-routebooking-component [uuid]="uuid"
                    (onRefreshData)="onRouteBookingRefreshData($event)"></shared-exwork-routebooking-component>
            </div>

            <div class="resafer" *ngIf="!algoAFer()">
                <div class="content" [innerHTML]="'EXWORK.NOTHINGTODO' | translate"></div>
            </div>
            <div class="item emails" *nfIf="!data.readonly && !data.readonlyDataInfo">
                <div class="help" [innerHTML]="'SHARED.EXWORK.COMPONENT.EMAIL.HELP' | translate"></div>
                <input-component class="col-x" [(ngModel)]="email"
                    placeholder="{{'SHARED.EXWORK.COMPONENT.EMAIL.PLACEHOLDER' | translate}}">
                </input-component>
                <button class="link right" (click)="addEmail()">{{'SHARED.EXWORK.COMPONENT.EMAIL.BUTTONADD' |
                    translate}}</button>
            </div>
            <div *ngIf="(data.canGrouping || data.isGrouped) && !data.readonly && !data.readonlyDataInfo" class="block-links-bottom">

                <button-link *ngIf="data.isGrouped" variant="forward" class="line left"
                    (linkClick)="onClickOption('routeGroupShipment')">{{
                    'SHARED.EXWORK.OPTION.ROUTEGROUPSHIPMENT.LINK.GOLIST' | translate}}</button-link>
                <button-link *ngIf="data.canGrouping && !data.isGrouped" variant="forward" class="line left"
                    (linkClick)="onClickOption('routeGroup')">{{ 'SHARED.EXWORK.OPTION.ROUTEGROUP.LINK.GOGROUP' |
                    translate}}</button-link>
                <button-link *ngIf="data.canGrouping && data.isGrouped" variant="forward" class="line left"
                    (linkClick)="onClickOption('routeGroupAdd')">{{ 'SHARED.EXWORK.OPTION.ROUTEGROUP.LINK.GOADD' |
                    translate}}</button-link>
                <button-link *ngIf="data.canGrouping && data.isGrouped" variant="forward" class="line left"
                    (linkClick)="onClickOption('routeGroupRemove')">{{ 'SHARED.EXWORK.OPTION.ROUTEGROUP.LINK.GOREMOVE' |
                    translate}}</button-link>


                <div *ngIf="data.canGrouping && data.isGrouped && listRouteGroupKeys != null && listRouteGroupKeys.length > 0"
                    class="info-list-routekeys">
                    <div class="info-list-title">
                        {{ 'SHARED.EXWORK.ROUTEGROUP.LISTKEYS.TITLE' | translate }}
                    </div>
                    <div class="item-key" *ngFor="let routekey of listRouteGroupKeys">
                        <span class="text">{{ routekey }}</span>
                    </div>

                </div>

            </div>
            <div class="block-line">
                <div class="cosespendents"
                    *ngIf="data.driverNameIncorrect || data.driverLegalIdentificationIncorrect || data.driverPhoneIncorrect || data.plateAIncorrect || data.plateBIncorrect ||data.plateCIncorrect || data.carrierNameIncorrect || data.carrierLegalIdentificationIncorrect || data.carrierAddressIncorrect">
                    <div class="titolpendent">{{'SHARED.EXWORK.ERROR.TITLE'| translate}}</div>
                    <div class="cosapendent" *ngIf="data.carrierNameIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.CARRIERNAMEINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.carrierLegalIdentificationIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.CARRIERLEGALIDENTIFICATIONINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.carrierAddressIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.CARRIERADDRESSINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.driverNameIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.DRIVERNAMEINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.driverLegalIdentificationIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.DRIVERLEGALIDENTIFICATIONINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.driverPhoneIncorrect">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.DRIVERPHONEINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.plateAIncorrect"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.PLATEAINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="data.plateBIncorrect"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i>
                        {{'SHARED.EXWORK.ERROR.PLATEBINCORRECT' | translate}}
                    </div>
                    <div class="cosapendent" *ngIf="!data.hidePlateC && data.plateCIncorrect"><i class="fa fa-exclamation-triangle"
                            aria-hidden="true"></i>
                        {{ data.resourcePlateCIncorrect | translate}}
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="data.isGrouped && viewToShow=='routeGroupShipment' && !data.readonly && !data.readonlyDataInfo" class="block-dades">
            <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
                'SHARED.EXWORK.OPTION.ROUTEGROUPSHIPMENT.LINK.BACK' | translate}}</button-link>
            <shared-route-group-child-list-component [uuidexwork]="uuid"
                [showtitle]="true"></shared-route-group-child-list-component>

        </div>
        <div *ngIf="!data.isChildOfGrouped && (viewToShow=='routeGroup' || viewToShow=='routeGroupAdd' || viewToShow=='routeGroupRemove') && !data.readonly && !data.readonlyDataInfo"
            class="block-dades">
            <button-link variant="back" class="line left" (linkClick)="goToNormal()">{{
                'SHARED.EXWORK.OPTION.ROUTEGROUP.LINK.BACK' | translate}}</button-link>
            <shared-exwork-routegroup [uuid]="uuid" [mode]="viewToShow" [route]="data" (goNewUUID)="onGoNewUUID($event)"
                (refreshExwork)="goToNormal()"></shared-exwork-routegroup>
        </div>
        <div class="peu">.</div>
    </div>
    <div class="pane-dreta" [ngClass]="this.data.classes">
        <div class="line"></div>
        <div class="button-cycle" (click)="paneDretaObert=!paneDretaObert">
            <i class="only-obert fa fa-chevron-right baixa-data-icon" aria-hidden="true"></i>
            <i class="only-tancat fa fa-chevron-left baixa-data-icon" aria-hidden="true"></i>
        </div>
        <div class="content" [innerHTML]="data.helpHtml"></div>
    </div>
</div>
<div class="signaturahardman">
    <img class="logoimg" src="/assets/images/HardmanLogoBlanc.png" alt="Hardman logo">
    <a href="https:hardman.app" target="_blank" class="powered">{{'GLOBAL.DESENVOLUPATPER' | translate}}</a>
</div>