<div class="container" *ngIf="route">
        <div class="divSeccio booking-info" *ngIf="showBookingInfo">
                <shared-booking-card [booking]="booking" class="big no-clickable no-card"></shared-booking-card>
                <button-link class="line right" (linkClick)="showBookingInfo = false">{{
                        'BOOKINGPAGE.CHANGEBOOKING.CAPTION'
                        |translate}}</button-link>
        </div>

        <div class="divSeccio booking-selector" *ngIf="!showBookingForm && !showBookingInfo">
                <entity-selector class="col-x" [(ngModel)]="bookingId" [entitySelector]="'Booking'"
                        [preventGoToMaintenanceInternal]="true" [showGoToMaintenanceInternal]="true"
                        (onGoToMaintenanceInternal)="setShowBookingForm(true)" (valuechangefunction)="bookingChanged()"
                        placeholder="{{'BOOKINGPAGE.BOOKINGNUMBER.CAPTION' | translate}}">
                </entity-selector>

                <button-link class="line right" (linkClick)="setShowBookingForm(true)">{{
                        'BOOKINGPAGE.ACTIONS.ADDSMALL.CAPTION' |
                        translate }}</button-link>
                <button-link variant="back" class="line right" (linkClick)="showBookingInfo = true">{{
                        'GENERAL.BACK.CAPTION' | translate}}</button-link>
        </div>
        <shared-booking-form [selectedBooking]="booking" class="relative-position-save-button narrow"
                (onSave)="reserveBooking($event)" *ngIf="showBookingForm && !showBookingInfo"></shared-booking-form>
        <button class="d-block cancel-create-booking-button" *ngIf="showBookingForm"
                (click)="setShowBookingForm(false)">{{ 'CANCEL.BUTTON' | translate }}</button>


        <shared-containernumber class="container-number-form" [routeId]="route.routeId"
                class="big"></shared-containernumber>
</div>