import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { HomeDto } from '@shared/src/dtos/home/HomeDto';
import { OpenRouteChangeAdditionalActorDto } from '@shared/src/dtos/open/OpenRouteChangeAdditionalActorDto';
import { RoutePartnerExworkDto } from '@shared/src/dtos/route/partner/RoutePartnerExworkDto';
import { RoutePartnerTypesForcedBlockedDto } from '@shared/src/dtos/route/partner/RoutePartnerTypesForcedBlockedDto';
import { SetRoutePartnerTypeForcedDto } from '@shared/src/dtos/route/partner/SetRoutePartnerTypeForcedDto';
import { RoutePermissionTypes } from '@shared/src/enums/RoutePermissionTypes';
import { HomeController, RouteController } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-partner-selector',
  templateUrl: './shared.route.partnerselector.component.html',
  styleUrls: ['./shared.route.partnerselector.component.scss']
})
export class SharedRoutePartnerSelectorComponent implements OnInit {

  /***
   * Poso una mica d'ordre. Aquesta directiva permet que de forma externa s'eviti que es mostri un camp o card
   * Això es fa amb les publiques showCarrier, showLogisticOperator, etc.
   * En principi estan totes a true pel que no caldria tocar-ho desde fora
   * 
   * Internament utilitza les variables internalShowCarrier, internalShowShipper, etc
   */
  constructor(protected fb: FormBuilder,
    @Inject('HomeController') public homeController: HomeController,
    protected openRouteController: OpenRouteController, protected routeController: RouteController) {

    this.formGroup = fb.group({
      'partnerShipperId': ['', [Validators.nullValidator]],
      'partnerCarrierId': ['', [Validators.nullValidator]],
      'partnerLogisticOperatorId': ['', [Validators.nullValidator]],
      'partnerInspectorId': ['', [Validators.nullValidator]],
      'partnerHealthCertifierId': ['', [Validators.nullValidator]],
      'partnerClientPreferentId': ['', [Validators.nullValidator]],
      'partnerDriverId': ['', [Validators.nullValidator]],
      'partnerSecondDriverId': ['', [Validators.nullValidator]],
    })

  }

  public mostrarCards: boolean = true;


  public internalShowParent: boolean = false;
  public internalShowCarrier: boolean = false;
  public internalShowShipper: boolean = false;
  public internalShowLogisticOperator: boolean = false;
  public internalShowDriver: boolean = false;
  public internalShowSecondDriver: boolean = false;
  public internalShowInspector: boolean = false;
  public internalShowHealthCertifier: boolean = false;
  public internalShowClientPreferent: boolean = false;

  public internalShowParentInfoCard: boolean = false;
  public internalShowCarrierInfoCard: boolean = false;
  public internalShowShipperInfoCard: boolean = false;
  public internalShowLogisticOperatorInfoCard: boolean = false;
  public internalShowDriverInfoCard: boolean = false;
  public internalShowSecondDriverInfoCard: boolean = false;
  public internalShowInspectorInfoCard: boolean = false;
  public internalShowHealthCertifierInfoCard: boolean = false;
  public internalShowClientPreferentInfoCard: boolean = false;

  public hasResultsCarrier: boolean = false;
  public hasResultsShipper: boolean = false;
  public hasResultsLogisticOperator: boolean = false;
  public hasResultsDriver: boolean = false;
  public hasResultsSecondDriver: boolean = false;
  public hasResultsInspector: boolean = false;
  public hasResultsHealthCertifier: boolean = false;
  public hasResultsClientPreferent: boolean = false;

  ngOnInit(): void { }

  @Input() readonly: boolean = false;

  private _mode: "read" | "write" = "read";
  @Input() public set mode(v: "read" | "write") {
    if (v == this.mode)
      return;

    this._mode = v;
  }
  public get mode(): "read" | "write" {
    return this._mode;
  }

  public formGroup: FormGroup;

  public filterkeysCarrier: Array<String> = PartnersController.FILTERKEYSCARRIER;
  public filterkeysShipper: Array<String> = PartnersController.FILTERKEYSSHIPPER;
  public filterkeysLogisticOperator: Array<String> = PartnersController.FILTERKEYSLOGISTICOPERATOR;
  public filterkeysDriver: Array<String> = PartnersController.FILTERKEYSDRIVER;
  public filterkeysSecondDriver: Array<String> = PartnersController.FILTERKEYSSECONDDRIVER;
  public filterkeysInspector: Array<String> = PartnersController.FILTERKEYSINSPECTOR;
  public filterkeysHealthCertifier: Array<String> = PartnersController.FILTERKEYSHEALTHCERTIFIER;
  public filterkeysClientPreferent: Array<String> = PartnersController.FILTERKEYSCLIENTPREFERENT;

  @Input() public showParent: boolean = true;
  @Input() public showCarrier: boolean = true;
  @Input() public showShipper: boolean = true;
  @Input() public showLogisticOperator: boolean = true;
  @Input() public showDriver: boolean = true;
  @Input() public showSecondDriver: boolean = true;
  @Input() public showInspector: boolean = true;
  @Input() public showHealthCertifier: boolean = true;
  @Input() public showClientPreferent: boolean = true;

  @Output() public valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public partnerCarrierId: number;
  public partnerShipperId: number;
  public partnerLogisticOperatorId: number;
  public partnerDriverId: number;
  public partnerSecondDriverId: number;
  public partnerInspectorId: number;
  public partnerHealthCertifierId: number;
  public partnerClientPreferentId: number;

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public loadRouteData() {
    if (!this.routeId)
      return;

    this.routeController.getRoutePartnerExwork(this.routeId).subscribe(data => {
      this.value = data;
    });
  }

  private _value: RoutePartnerExworkDto;
  public set value(v: RoutePartnerExworkDto) {
    if (v && this._value && JSON.stringify(this._value) == JSON.stringify(v))
      return;

    this._value = v;
    this.wellCome();
    this.loadPartnerTypeForced();
  }
  public get value(): RoutePartnerExworkDto {
    return this._value;
  }

  public styleClass: any;

  setMode(mode: "read" | "write") {
    this.mode = mode;
    this.wellCome();
  }

  public home: HomeDto;
  private wellCome() {

    if (this._value == null) {
      this.__routeId = 0;
      this.internalShowParent = false;
      this.internalShowCarrier = false;
      this.internalShowShipper = false;
      this.internalShowLogisticOperator = false;
      this.internalShowDriver = false;
      this.internalShowSecondDriver = false;
      this.internalShowInspector = false;
      this.internalShowHealthCertifier = false;
      this.internalShowClientPreferent = false;
      return;
    }

    this.__routeId = this._value.routeId;
    this.partnerCarrierId = this._value.partnerCarrierId;
    this.partnerShipperId = this._value.partnerShipperId;
    this.partnerLogisticOperatorId = this._value.partnerLogisticOperatorId;
    this.partnerDriverId = this._value.partnerDriverId;
    this.partnerSecondDriverId = this._value.partnerSecondDriverId;
    this.partnerInspectorId = this._value.partnerInspectorId;
    this.partnerHealthCertifierId = this._value.partnerHealthCertifierId;
    this.partnerClientPreferentId = this._value.partnerClientPreferentId;

    this.internalShowParent = this.showParent && this._value.routePermission != 'Owner';
    this.internalShowCarrier = this.showCarrier && (this.hasResultsCarrier || this.value.partnerCarrierId > 0);
    this.internalShowShipper = this.showShipper && (this.hasResultsShipper || this.value.partnerShipperId > 0);
    this.internalShowLogisticOperator = this.showLogisticOperator && (this.hasResultsLogisticOperator || this.value.partnerLogisticOperatorId > 0);
    this.internalShowDriver = this.showDriver && (this.hasResultsDriver || this.value.partnerDriverId > 0);
    this.internalShowSecondDriver = this.showSecondDriver && (this.hasResultsSecondDriver || this.value.partnerSecondDriverId > 0);
    this.internalShowInspector = this.showInspector && (this.hasResultsInspector || this.value.partnerInspectorId > 0);
    this.internalShowHealthCertifier = this.showHealthCertifier && (this.hasResultsHealthCertifier || this.value.partnerHealthCertifierId > 0);
    this.internalShowClientPreferent = this.showClientPreferent && (this.hasResultsClientPreferent || this.value.partnerClientPreferentId > 0);

    this.home = this.homeController.HOME;

    this.internalShowParentInfoCard = this.showParent && this._value.routePermission != 'Owner';
    this.internalShowCarrierInfoCard = this.showCarrier && (this.value.partnerCarrierId > 0 || this.home.hasCarrier);
    this.internalShowShipperInfoCard = this.showShipper && (this.value.partnerShipperId > 0 || this.home.hasShipper);
    this.internalShowLogisticOperatorInfoCard = this.showLogisticOperator && (this.value.partnerLogisticOperatorId > 0 || this.home.hasLogisticOperator);
    this.internalShowDriverInfoCard = this.showDriver && (this.value.partnerDriverId > 0 || this.home.hasDriver);
    this.internalShowSecondDriverInfoCard = this.showSecondDriver && (this.value.partnerSecondDriverId > 0 || this.home.hasSecondDriver);
    this.internalShowInspectorInfoCard = this.showInspector && (this.value.partnerInspectorId > 0 || this.home.hasInspector);
    this.internalShowHealthCertifierInfoCard = this.showHealthCertifier && (this.value.partnerHealthCertifierId > 0 || this.home.hasHealthCertifier);
    this.internalShowClientPreferentInfoCard = this.showClientPreferent && (this.value.partnerClientPreferentId > 0 || this.home.hasClientPreferent);



    this.styleClass = this.mode;
    if (this.internalShowParent)
      this.styleClass += " internalShowParent";
    if (this.internalShowCarrier)
      this.styleClass += " internalShowCarrier";
    if (this.internalShowShipper)
      this.styleClass += " internalShowShipper";
    if (this.internalShowLogisticOperator)
      this.styleClass += " internalShowLogisticOperator";
    if (this.internalShowDriver)
      this.styleClass += " internalShowDriver";
    if (this.internalShowSecondDriver)
      this.styleClass += " internalShowSecondDriver";
    if (this.internalShowInspector)
      this.styleClass += " internalShowInspector";
    if (this.internalShowHealthCertifier)
      this.styleClass += " internalShowHealthCertifier";
    if (this.internalShowClientPreferent)
      this.styleClass += " internalShowClientPreferent";
  }
  setHasResults(qui: string, $event: boolean) {

    switch (qui) {
      case 'Carrier':
        this.hasResultsCarrier = $event;
        break;
      case 'Shipper':
        this.hasResultsShipper = $event;
        break;
      case 'LogisticOperator':
        this.hasResultsLogisticOperator = $event;
        break;
      case 'Driver':
        this.hasResultsDriver = $event;
        break;
      case 'SecondDriver':
        this.hasResultsSecondDriver = $event;
        break;
      case 'Inspector':
        this.hasResultsInspector = $event;
        break;
      case 'HealthCertifier':
        this.hasResultsHealthCertifier = $event;
        break;
      case 'ClientPreferent':
        this.hasResultsClientPreferent = $event;
        break;
        break;
    }
    this.wellCome();
  }
  public setShipper() {
    this.openRouteController.setShipper(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerShipperId)).subscribe(e => {
      if (e) {
        this._value.partnerShipperId = this.partnerShipperId;
        this.formGroup.get("partnerShipperId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setCarrier() {
    this.openRouteController.setCarrier(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerCarrierId)).subscribe(e => {
      if (e) {
        this._value.partnerCarrierId = this.partnerCarrierId;
        this.formGroup.get("partnerCarrierId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setLogisticOperator() {
    this.openRouteController.setLogisticOperator(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerLogisticOperatorId)).subscribe(e => {
      if (e) {
        this._value.partnerLogisticOperatorId = this.partnerLogisticOperatorId;
        this.formGroup.get("partnerLogisticOperatorId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setInspector() {
    this.openRouteController.setInspector(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerInspectorId)).subscribe(e => {
      if (e) {
        this._value.partnerInspectorId = this.partnerInspectorId;
        this.formGroup.get("partnerInspectorId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setHealthCertifier() {
    this.openRouteController.setHealthCertifier(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerHealthCertifierId)).subscribe(e => {
      if (e) {
        this._value.partnerHealthCertifierId = this.partnerHealthCertifierId;
        this.formGroup.get("partnerHealthCertifierId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setClientPreferent() {
    this.openRouteController.setClientPreferent(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerClientPreferentId)).subscribe(e => {
      if (e) {
        this._value.partnerClientPreferentId = this.partnerClientPreferentId;
        this.formGroup.get("partnerClientPreferentId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setDriver() {
    this.openRouteController.setDriver(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerDriverId)).subscribe(e => {
      if (e) {
        this._value.partnerDriverId = this.partnerDriverId;
        this.formGroup.get("partnerDriverId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public setSecondDriver() {
    this.openRouteController.setSecondDriver(this._value.routeId, OpenRouteChangeAdditionalActorDto.build(this.partnerSecondDriverId)).subscribe(e => {
      if (e) {
        this._value.partnerSecondDriverId = this.partnerSecondDriverId;
        this.formGroup.get("partnerSecondDriverId").markAsPristine();
        this.valueChanged.emit(true);
      }
    })
  }

  public dataForcedBlocked: RoutePartnerTypesForcedBlockedDto;
  public loadPartnerTypeForced() {
    if (this._value == null || HLong.isNullOrNullLong(this._value.routeId)) {
      this.dataForcedBlocked = null;
      return;
    }
    this.routeController.getPartnerTypeForced(this._value.routeId).subscribe(data => {
      if (data) {
        this.dataForcedBlocked = data;
      }
    })
  }
  public setPartnerTypeForced(sPermissionType: string, value: boolean) {
    if (this._value == null || HLong.isNullOrNullLong(this._value.routeId)) {
      return;
    }
    let permissionType: RoutePermissionTypes = RoutePermissionTypes[sPermissionType];
    if (permissionType == null || permissionType == RoutePermissionTypes.None)
      return;
    this.routeController.setPartnerTypeForced(this._value.routeId, SetRoutePartnerTypeForcedDto.build(this._value.routeId, permissionType, value)).subscribe(data => {
      if (data) {
        this.dataForcedBlocked = data;
      }
    })
  }

  pretty(val: any) {
    return JSON.stringify(val);
  }

}
