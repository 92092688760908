<div class="form" *ngIf="formGroup != null" [formGroup]="formGroup">
    <!-- Número de booking -->
    <!-- Data hora sortida -->
    <!-- Data hora arribada -->
    <div class="col-x">
        <input-component class="booking-number" placeholder="{{ 'BOOKINGPAGE.BOOKINGNUMBER.CAPTION' | translate }}"
            [(ngModel)]="selectedBooking.bookingNumber" formControlName="bookingNumber"
            [readonly]="readonly"></input-component>
        <datehour-picker [(ngModel)]="selectedBooking.datetimeStart"
            placeholder="{{ 'BOOKINGPAGE.DATETIMESTART.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="datetimeStart" [readonly]="readonly" [max]="selectedBooking.datetimeEnd"></datehour-picker>
        <datehour-picker [(ngModel)]="selectedBooking.datetimeEnd"
            placeholder="{{ 'BOOKINGPAGE.DATETIMEEND.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="datetimeEnd" [readonly]="readonly" [min]="selectedBooking.datetimeStart"></datehour-picker>
    </div>
    <!-- Data hora inici depòsit -->
    <!-- Data hora final depòsit -->
    <div class="col-x">
        <div class="booking-number fake"></div>
        <datehour-picker class="bold" [(ngModel)]="selectedBooking.depositStart"
            placeholder="{{ 'BOOKINGPAGE.DEPOSITSTART.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="depositStart" [readonly]="readonly" [max]="selectedBooking.depositEnd"></datehour-picker>
        <datehour-picker class="bold" [(ngModel)]="selectedBooking.depositEnd"
            placeholder="{{ 'BOOKINGPAGE.DEPOSITEND.CAPTION' | translate }}" [showLocaleObserver]="false"
            formControlName="depositEnd" [readonly]="readonly" [min]="selectedBooking.depositStart"
            [max]="selectedBooking.datetimeStart"></datehour-picker>
    </div>
    <input-component class="col-x" placeholder="{{ 'BOOKINGPAGE.SHIPNAME.CAPTION' | translate }}"
        [(ngModel)]="selectedBooking.shipName" formControlName="shipName" [readonly]="readonly"></input-component>
    <input-component class="col-x" placeholder="{{ 'BOOKINGPAGE.PLACES.CAPTION' | translate }}" type="number"
        [(ngModel)]="selectedBooking.places" formControlName="places" [readonly]="readonly"></input-component>
    <entity-selector class="col-x" [(ngModel)]="selectedBooking.maritimeAgencyId" [entitySelector]="'MaritimeAgency'"
        placeholder="{{'BOOKING.MARITIMEAGENCY.CAPTION' | translate}}" name="maritimeAgencyId"
        formControlName="maritimeAgencyId" [readonly]="readonly">
    </entity-selector>
    <input-component class="col-x" placeholder="{{ 'BOOKINGPAGE.CUSTOMSWAREHOUSE.CAPTION' | translate }}"
        [(ngModel)]="selectedBooking.customsWarehouse" formControlName="customsWarehouse"
        [readonly]="readonly"></input-component>

    <!-- Adreça origen-->
    <entity-selector class="col-x" [(ngModel)]="selectedBooking.departureAddressId" [entitySelector]="'PortAddress'"
        [fixedFilterKeys]="filterKeysPortAddressDeparture"
        placeholder="{{'BOOKING.DEPARTUREADDRESS.CAPTION' | translate}}" name="departureAddressId"
        formControlName="departureAddressId" [readonly]="readonly">
    </entity-selector>
    <div class="col-x"
        *ngIf="selectedBooking.departureFormattedAddress && isNullOrNullLong(selectedBooking.departureAddressId)">
        <span>{{'BOOKING.DEPARTUREADDRESS.ACTUAL.CAPTION' | translate}} {{ selectedBooking.departureFormattedAddress
            }}</span>
    </div>
    <!-- Adreça destí-->
    <entity-selector class="col-x" [(ngModel)]="selectedBooking.arrivalAddressId" [entitySelector]="'PortAddress'"
        [fixedFilterKeys]="filterKeysPortAddressArrival" placeholder="{{'BOOKING.ARRIVALADDRESS.CAPTION' | translate}}"
        name="arrivalAddressId" formControlName="arrivalAddressId" [readonly]="readonly">
    </entity-selector>
    <div class="col-x"
        *ngIf="selectedBooking.arrivalFormattedAddress && isNullOrNullLong(selectedBooking.arrivalAddressId)">
        <span>{{'BOOKING.ARRIVALADDRESS.ACTUAL.CAPTION' | translate}} {{ selectedBooking.arrivalFormattedAddress
            }}</span>
    </div>

    <tags-chips class="col-x" [readonly]="readonly" [(ngModel)]="selectedBooking.userTags" name="userTags"
        formControlName="userTags" placeholder="{{'BOOKINGPAGE.USERTAGS.CAPTION' | translate}}">
    </tags-chips>

    <div class="block">
        <shared-booking-partner-selector [readonly]="readonly" [formGroup]="formGroup"
            [booking]="selectedBooking"></shared-booking-partner-selector>
    </div>

    <button class="add savebutton save" (click)="save()" *ngIf="formGroup.dirty && !formGroup.invalid && !readonly"
        [disabled]="formGroup.invalid">
        <i class="fa fa-lg fa-floppy-o" aria-hidden="true"></i>
        {{ "MAINTENANCE.ACCEPT" | translate }}
    </button>
</div>