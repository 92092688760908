import { Component, Inject, Input } from '@angular/core';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { RoutePartCMRShipmentDeliveryLineDto } from '@shared/src/dtos/route/cmr/RoutePartCMRShipmentDeliveryLineDto';

@Component({
  selector: 'shared-route-part-cmr-edit-component',
  templateUrl: './shared.route.part.cmr.edit.component.html',
  styleUrls: ['./shared.route.part.cmr.edit.component.scss'],
})
export class SharedRoutePartCMREditComponent {

  constructor(private routeCMRController: RouteCMRController,
    @Inject('HomeController') protected homeController: HomeController) {
    if (homeController.useIonic) this.useIonic = homeController.useIonic();
  };


  public options: OptionsStripDto;
  public actions: ActionsStripDto;
  public useIonic: boolean = false;

  @Input() public readonly: boolean;
  @Input() public hideactions: boolean;

  private _routePartCMR: RoutePartCMRDto;
  @Input()
  public set routePartCMR(value: RoutePartCMRDto) {
      if (this._routePartCMR === value)
          return;
      this._routePartCMR = value;

      if (value != null && value.actionsEdit != null)
        this.actions = ActionsStripDto.buildFromActionsStripDto(value.actionsEdit);
      else
        this.actions = new ActionsStripDto();

      if (value != null && value.optionsEdit != null)
        this.options = OptionsStripDto.buildFromOptionsStripDto(this.routePartCMR.optionsEdit);
      else
        this.options = new OptionsStripDto();

      this.selectedOptionId = OptionsStripDto.arrange(this.options, this.selectedOptionId, this.routePartCMR.optionEditId);
    
  }
  public get routePartCMR(): RoutePartCMRDto {
      return this._routePartCMR;
  }

  getLines() {
    if (this.routePartCMR != null && this.routePartCMR.lines != null) {
      return this.routePartCMR.lines;
    }
    return new Array();
  }
  
  getLinesOrigin() {
    if (this.routePartCMR != null && this.routePartCMR.lines != null) {
      let lines = new Array();
      this.routePartCMR.lines.forEach(element => {
          if(element.restLine != null)
            lines.push(element.restLine);
      });
      return lines;
    }
    return new Array();
  }
  
  onClickAction(action: ActionDto) {
    if (this.routePartCMR != null)
      if (action != null) {
        
      }
  }
  public selectedOptionId = "";
  onClickOption(option: OptionDto) {
      if (option != null)
          this.selectedOptionId = option.optionId;
      else
          this.selectedOptionId = "";
  }
  ngOnInit() {

  }

  public pendingSave: boolean;
  onValueLineChange(line: RoutePartCMRShipmentDeliveryLineDto){
    if (this.routePartCMR != null && this.routePartCMR.lines != null && line != null) {
      this.routePartCMR.lines.forEach((l, i) => {
        if (l.shipmentCMRDeliveryLineId === line.shipmentCMRDeliveryLineId)
        this.routePartCMR.lines[i] = line;
      });
    }
    this.pendingSave = true;
  }

  onSaveChanges() {
    if (this.routePartCMR != null && this.routePartCMR.lines != null) {
      this.routeCMRController.saveRoutePartCMR(this.routePartCMR).subscribe(data => {
        if(data){
          this.routePartCMR = data;
          this.pendingSave = false;
        }
      });
    }
    
  }
}
