import { HTime } from "@shared/src/datatypes/HTime";
import { IncidentGroupTypes } from "@shared/src/enums/IncidentGroupTypes";
import { IncidentTypes } from "@shared/src/enums/IncidentTypes";
import { DocumentDto } from "../document/DocumentDto";

export class IncidentDto {
    public routeId: number;
    public phaseId: number;
    public shipmentId: number;
    public incidentGroup: IncidentGroupTypes;
    public incident: IncidentTypes;
    public comment: string;
    public iStandStill: boolean;
    public iHavePricked: boolean;
    public timeStandStill: HTime;

    public documents: DocumentDto[];
    
    public static build(routeId: number, phaseId: number, shipmentId: number, incidentgrouptype: IncidentGroupTypes, incidenttype: IncidentTypes, comment: string, standStill: boolean, iHavePricked: boolean, time: HTime): IncidentDto{
        let incident = new IncidentDto();
        incident.routeId = routeId;
        incident.phaseId = phaseId;
        incident.shipmentId = shipmentId;
        incident.incidentGroup = incidentgrouptype;
        incident.incident = incidenttype;
        incident.comment = comment;
        incident.iStandStill = standStill;
        incident.iHavePricked = iHavePricked;
        incident.timeStandStill = time;
        return incident;
    }

    public isValid(){
        if(this.routeId == null && this.phaseId == null && this.shipmentId == null)
            return false;
        if(this.incidentGroup == null || this.incidentGroup == IncidentGroupTypes.None)
            return false;
        if(this.incidentGroup != IncidentGroupTypes.Otro && (this.incident == null || this.incident == IncidentTypes.None))
            return false;
        return true;
    }
}
