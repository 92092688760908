import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { PartnerInfoDto } from '@shared/src/dtos/link/PartnerInfoDto';
import { OpenRouteChangeDriverInfoAndPlatesDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoAndPlatesDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { PartnerCategories } from '@shared/src/enums/PartnerCategories';
import { HTranslateService, SharedSaveButtonEvent } from '@shared/src/public-api';

@Component({
  selector: 'shared-driverplate',
  templateUrl: './shared.driverplate.component.html',
  styleUrls: ['./shared.driverplate.component.scss'],
})
export class SharedDriverPlateComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private phaseController: PhaseController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController, private chatController: ChatController,
    private partnersController: PartnersController,
    private slotsController: SlotsController,
    public hTranslateService: HTranslateService) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public readonly: boolean = false;

  @Input() public showCarrierCard: boolean = true;
  @Input() public onlydriver: boolean = false;


  public __route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null)
      this.__routeId = 0;
    else
      this.__routeId = value.routeId;

    this.loadRouteDetailData();
  }
  get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public editMode: boolean = false;

  public loadRouteData() {
    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.routeUpdated.next(this.route);
    });
  }

  public showAskCarrierName: boolean;
  public showAskCarrierLegalIdentification: boolean;
  public showAskCarrierAddress: boolean;
  public hidePlateC: boolean;
  public carrier: PartnerInfoDto;
  public driver: PartnerInfoDto;
  public loadRouteDetailData() {
    if (this.route && this.route.routeId > 0) {

      this.routeController.getActiveAskCarrierNameByRouteId(this.route.routeId).subscribe(data => { this.showAskCarrierName = data; });
      this.routeController.getActiveAskCarrierLegalIdentificationByRouteId(this.route.routeId).subscribe(data => { this.showAskCarrierLegalIdentification = data; });
      this.routeController.getActiveAskCarrierAddressByRouteId(this.route.routeId).subscribe(data => { this.showAskCarrierAddress = data; });
      this.routeController.getHidePlateCToOutsidersByRouteId(this.route.routeId).subscribe(data => { this.hidePlateC = data; });

      this.partnersController.getPartnerInfoRoute(PartnerCategories.Carrier, !HLong.isNullOrNullLong(this.route.partnerCarrierId) ? this.route.partnerCarrierId : 0, this.route.routeId).subscribe(data => { this.carrier = data; });

      if (this.route.partnerDriverId && this.route.partnerDriverId > 0)
        this.partnersController.getPartnerInfoRoute(PartnerCategories.Driver, this.route.partnerDriverId, this.route.routeId).subscribe(data => { this.driver = data; });
      else if (this.route.partnerSecondDriverId && this.route.partnerSecondDriverId > 0)
        this.partnersController.getPartnerInfoRoute(PartnerCategories.SecondDriver, this.route.partnerSecondDriverId, this.route.routeId).subscribe(data => { this.driver = data; });
      else if (this.route.driverName || this.route.driverPhone)
        this.driver = PartnerInfoDto.build(this.route.driverName, this.route.driverLegalIdentification, "", this.route.driverPhone, "");
      else
        this.driver = null;
    } else {
      this.driver = null;
      this.carrier = null;
    }

  }

  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;

    this.routeUpdated.next(value);

  }

  public plateA: string;
  public plateB: string;
  public plateC: string;
  public driverName: string;
  public driverLegalIdentification: string;
  public driverPhone: string;
  public carrierName: string;
  public carrierLegalIdentification: string;
  public carrierAddress: string;

  startEditmode() {
    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.plateA = this.route.plateA;
      this.plateB = this.route.plateB;
      this.plateC = this.route.plateC;
      this.driverName = this.route.driverName;
      this.driverLegalIdentification = this.route.driverLegalIdentification;
      this.driverPhone = this.route.driverPhone;
      this.carrierName = this.route.carrierName;
      this.carrierLegalIdentification = this.route.carrierLegalIdentification;
      this.carrierAddress = this.route.carrierAddress;

      this.editMode = true;
      this.routeUpdated.next(this.route);
    });
  }

  save($event: SharedSaveButtonEvent) {
    this.openRouteController.setDriverInfoAndPlates(this.route.routeId, OpenRouteChangeDriverInfoAndPlatesDto.build(this.driverName != null ? this.driverName : "",
      this.driverLegalIdentification != null ? this.driverLegalIdentification : "",
      this.driverPhone != null ? this.driverPhone : "", this.plateA != null ? this.plateA : "",
      this.plateB != null ? this.plateB : "", this.plateC != null ? this.plateC : "", this.carrierName != null ? this.carrierName : "", this.carrierLegalIdentification != null ? this.carrierLegalIdentification : "", this.carrierAddress != null ? this.carrierAddress : "")).subscribe(data => {
        if (data) {
          this.loadRouteData();
          this.routeUpdated.next(this.route);
        }
        if ($event)
          $event.callback();
        this.editMode = false;
      });
  }


  cancel() {
    this.editMode = false;
  }

  changeActiveNotificationDriver() {
    this.openRouteController.setActiveNotificationDriver(this.route.routeId, this.route.notificationDriver).subscribe(data => { });
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
