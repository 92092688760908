import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HColor } from '@shared/src/datatypes/HColor'
import { PhaseCardDto } from '../../../dtos/phase/PhaseCardDto';
import { DocksController } from '@shared/src/controllers/address/docks.controller';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-dock-picker',
    templateUrl: './shared.dock.picker.component.html',
    styleUrls: ['./shared.dock.picker.component.scss']
})
export class SharedDockPickerComponent implements OnInit {

    constructor(public dockController: DocksController) {
    }


    @Input() public hideTitle: boolean = false;
    @Input() public modeByPhase: boolean = false;
    @Input() public isloadconsole: boolean = false;

    private _phase: PhaseCardDto;
    @Input()
    public set phase(value: PhaseCardDto) {
        if (this._phase === value)
            return;
        this._phase = value;
        this.loadDockSelectedByPhase();
        this.loadDocksByPhase();
    }
    public get phase(): PhaseCardDto {
        return this._phase;
    }

    loadDocksByPhase() {
        if (this.phase == null || this.phase.phaseId == null)
            return;
        this.dockController.getDocksToPhase(this.phase.phaseId).subscribe((data: Array<DockDto>) => {
            this.docks = data;
        });
    }

    loadDockSelectedByPhase() {
        if (this.phase == null) {
            this.dockSelected = null;
            return;
        }
        this.allowNotDefinedDocks = this.phase.allowNotDefinedDocks && this.phase.canSetDock;
        this.dockSelected = DockDto.buildByPhase(this.phase);
    }


    private _allowNotDefinedDocks: boolean;
    @Input()
    public set allowNotDefinedDocks(value: boolean) {
        if (this._allowNotDefinedDocks === value)
            return;
        this._allowNotDefinedDocks = value;
    }
    public get allowNotDefinedDocks(): boolean {
        return this._allowNotDefinedDocks;
    }


    private _docks: Array<DockDto>;
    @Input()
    public set docks(value: Array<DockDto>) {
        if (this._docks === value)
            return;
        this._docks = value;
        this.loadDocks();
    }
    public get docks(): Array<DockDto> {
        return this._docks;
    }

    private _dockSelected: DockDto;
    @Input()
    public set dockSelected(value: DockDto) {
        if (this._dockSelected === value)
            return;
        this._dockSelected = value;
    }
    public get dockSelected(): DockDto {
        return this._dockSelected;
    }

    @Input()
    public sempreCanviDock: boolean = false;

    @Output() public valueChange: EventEmitter<DockDto> = new EventEmitter<DockDto>();

    public canviDock: boolean = false;
    public dockSkin: string;

    ngOnInit(): void {
    }

    public toggleCanviDock() {
        this.canviDock = !this.canviDock;
        if (this.canviDock) {
            this.loadDocksByPhase();
        }
    }

    public docksAssignables: DockDto[];
    public docksOccupied: DockDto[];
    loadDocks() {
        this.docksAssignables = new Array();
        this.docksOccupied = new Array();

        if (!this.docks)
            return;

        if (this.docks != null && this.docks.length > 1) {
            this.docks.forEach(element => {
                if (element.assignable && !element.occupied)
                    this.docksAssignables.push(element);
                else
                    this.docksOccupied.push(element);

            });
        }
    }

    getDockClass(dock: DockDto): string {
        let dockclass = "";
        if (this.isSelected(dock))
            dockclass += 'selected-item';

        dockclass += (dock.assignable ? ' assignable' : ' no-assignable');
        dockclass += (dock.occupied ? ' occupied' : ' no-occupied');
        dockclass += (dock.isDisabled ? ' disabled' : ' no-disabled');

        if (!HString.isNullOrNullString(dock.dockClass))
            dockclass += ' ' + dock.dockClass;

        return dockclass;
    }
    isSelected(dock: DockDto) {
        return dock != null
            && this.dockSelected != null
            && this.dockSelected.dockId != null
            && this.dockSelected.dockId == dock.dockId;
    }

    onDockSelected(dock: DockDto) {

        if (dock == null)
            return;

        if (this.isSelected(dock))
            return;

        if (this.modeByPhase) {
            if (this.phase == null || this.phase.phaseId == null)
                return;

            this.dockController.setDockIdToPhaseId(this.phase.phaseId, dock.dockId).subscribe((data: boolean) => {
                if (data) {
                    this.dockSelected = dock;
                    this.canviDock = false;
                    this.valueChange.next(dock);
                }
            });
        } else {
            this.dockSelected = dock;
            this.valueChange.next(dock);
        }
    }

    setDockToPhaseId() {

        this.dockSelected = DockDto.build(0, 0, this.dockSkin, this.dockSkin, HColor.BLACK);

        if (this.modeByPhase) {
            if (this.phase == null || this.phase.phaseId == null)
                return;

            this.dockController.setDockToPhaseId(this.phase.phaseId, this.dockSkin).subscribe((data: boolean) => {
                if (data) {
                    this.canviDock = false;
                    this.dockSkin = null;
                    this.valueChange.next(this.dockSelected);
                }
            });
        } else {
            this.valueChange.next(this.dockSelected);
        }
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    pretty(value) {
        return JSON.stringify(value);
    }
}

