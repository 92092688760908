import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HomeController } from '../../../../../shared/src/controllers/home/home.controller';
import { SocketController } from '../../../../../shared/src/controllers/socket/socket.controller';
import { Platform } from "@angular/cdk/platform";
import { Solutions, Platforms, SharedAsideFactory } from '@shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MobileAlertService } from '../../../services/mobile.alert.service';
import { MobileLoadingService } from '../../../services/mobile.loading.service';
import { OperatingSystems } from '@shared/src/services/platform/OperatingSystems';
import { MobileSecurityController } from '../security/mobile-security.controller';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { TenantSettingMaintenanceController } from '@shared/src/controllers/maintenance/tenant/TenantSettingMaintenanceController';
import { TagMaintenanceController } from '@shared/src/controllers/maintenance/tag/TagMaintenanceController';


@Injectable({
  providedIn: 'root',
})
export class MobileHomeController extends HomeController {

  constructor(
    protected http: HttpClient,
    @Inject('AlertService') public alertService: MobileAlertService,
    protected translateService: TranslateService,
    protected router: Router,
    @Inject('LoadingService') protected loadingService: MobileLoadingService,
    protected platform: Platform,
    @Inject('SecurityController') protected securityController: MobileSecurityController,
    protected chatController: ChatController,
    protected socketController: SocketController,
    protected tenantSettingController: TenantSettingMaintenanceController,
    protected tagMaintenanceController: TagMaintenanceController,
    @Inject('SharedAsideFactory')
    public sharedAsideFactory: SharedAsideFactory) {

    super(http, alertService, translateService, router, loadingService, securityController, chatController, socketController, tenantSettingController, tagMaintenanceController, sharedAsideFactory);

  }

  public getOS(): OperatingSystems {

    if (this.platform.IOS)
      return OperatingSystems.ios;
    if (this.platform.ANDROID)
      return OperatingSystems.android;

    if (this.platform.isBrowser)
      return OperatingSystems.browser;

    return null;

  }

  public getSolution(): Solutions {
    return Solutions.mobile;
  }
  public getPlatform(): Platforms {

    if (this.platform.IOS)
      return Platforms.ios;
    if (this.platform.ANDROID)
      return Platforms.android;

    return Platforms.desktop;
  }

}