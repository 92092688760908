<!-- tags.chips.component.html!-->
<div class="placeholder "
    *ngIf="(tagsString && tagsString.length>0) || (readonly && (!tagsString || tagsString.length == 0))">
    {{placeholder}}
</div>
<div class="chips" [ngClass]="{'readonly': readonly, 'ionic': useIonic}">
    <div class="tag" *ngFor="let tag of tagsString" [style.background-color]="getTagBackColor(tag)"
        [style.color]="getTagColor(tag)">
        {{tag}}
        <i *ngIf="!readonly" class="fa fa-close" aria-hidden="true" (click)="remove(tag)"></i>
    </div>
</div>
<div class="input" [ngClass]="{'readonly': readonly}">
    <div [formGroup]="rFormTags" *ngIf="!useIonic" [ngClass]="{'un' : tagsString && tagsString.length>0}">
        <mat-form-field class="lineheight">
            <!-- <input [id]="identifier" [(ngModel)]="text" [placeholder]="placeholder" 
        (keydown.enter)="add($event)" [readonly]="readonly" ngDefaultControl>!-->
            <input matInput [id]="identifier" [(ngModel)]="text" formControlName="tagsvalidator"
                [placeholder]="(tagsString && tagsString.length>0) ? placeholderNext : placeholder"
                (change)="onChange($event)" (keydown.enter)="add($event)" [readonly]="readonly">
        </mat-form-field>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div [formGroup]="rFormTags" *ngIf="useIonic">
        <ion-input ionInput [ngClass]="{'error': !rFormTags.valid && !rFormTags.pristine}" [id]="identifier"
            [(ngModel)]="text" formControlName="tagsvalidator" ngDefaultControl [readonly]="readonly"
            [placeholder]="(tagsString && tagsString.length>0) ? placeholderNext : placeholder"
            autocomplete="autocomplete" (keydown.enter)="add($event)">
        </ion-input>

    </div>
</div>