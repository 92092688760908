<div class="item" [ngClass]="getClass(value)">
    <div class="key">{{value.title}}</div>
    <div class="description">{{value.description}}</div>
    <div class="icones">
        <div class="iconlabel isStop cercle"></div>
        <div class="iconlabel isMove cercle"></div>
        <div class="iconlabel isLost cercle"></div>
        <div class="iconlabel isNoTelemetry cercle"></div>
        <span *ngFor="let type of objectHierarchy">
            <div *ngIf="type == 'shipment'" [title]="'SHIPMENT.TITLE' | translate" class="iconlabel isShipment"><i
                    class="fa fa-paper-plane" aria-hidden="true"></i></div>
            <div *ngIf="type == 'route'" [title]="'ROUTE.TITLE' | translate" class="iconlabel isRoute"><i
                    class="fa fa-road" aria-hidden="true"></i></div>
            <div *ngIf="type == 'plate'" [title]="'PLATE.TITLE' | translate" class="iconlabel isPlate"><i
                    class="fa fa-truck" aria-hidden="true"></i></div>
            <div *ngIf="type == 'device'" [title]="'DEVICE.TITLE' | translate" class="iconlabel isDevice"><i
                    class="fa fa-mobile" aria-hidden="true"></i></div>
            <div *ngIf="type == 'user'" [title]="'USER.TITLE' | translate" class="iconlabel isUser"><i
                    class="fa fa-user-circle-o" aria-hidden="true"></i></div>
            <div class="iconlabel info"
                *ngIf="(type == 'route' || type == 'shipment') && !hasMultipleChilds && value[type].length > 0"
                (click)="showAside(value[type][0])"><i class="fa fa-info-circle"></i></div>
        </span>
    </div>
    <div class="zoneselected posicioActual">
        <div class="pretable">
            {{value.globalTelemetry.moment | datehour }}
        </div>
        <div class="table">
            <div class="row">
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.LATITUDE.CAPTION' | translate}}
                </div>
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.SPEED.CAPTION' | translate}}
                </div>
            </div>
            <div class="row">
                <div class="col valor">
                    {{value.globalTelemetry.latitude}}
                </div>
                <div class="col valor">
                    {{value.globalTelemetry.speed}}
                </div>
            </div>
            <div class="row">
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.LONGITUDE.CAPTION' | translate}}
                </div>
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.ACCURACY.CAPTION' | translate}}
                </div>
            </div>
            <div class="row">
                <div class="col valor">
                    {{value.globalTelemetry.longitude}}
                </div>
                <div class="col valor">
                    {{value.globalTelemetry.accuracy}}
                </div>
            </div>
            <div class="row">
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.ALTITUDE.CAPTION' | translate}}
                </div>
                <div class="col prompt">
                    {{'MOBILE.GEONOTCH.COMPONENT.ALTITUDEACCURACY.CAPTION' | translate}}
                </div>
            </div>
            <div class="row">
                <div class="col valor">
                    {{value.globalTelemetry.altitude}}
                </div>
                <div class="col valor">
                    {{value.globalTelemetry.altitudeAccuracy}}
                </div>
            </div>
        </div>
    </div>

    <span *ngIf="hasMultipleChilds">
        <span *ngFor="let type of objectHierarchy">
            <div [title]=" type.toUpperCase() + '.TITLE' | translate" class="item-child" [ngClass]="type"
                *ngFor="let item of value[type]">
                <div class="text">
                    <div class="key">{{item.key}}</div>
                    <div class="description">{{item.description}}</div>
                </div>
                <div *ngIf="type == 'shipment'" class="iconlabel isShipment"><i class="fa fa-paper-plane"
                        aria-hidden="true"></i></div>
                <div *ngIf="type == 'route'" class="iconlabel isRoute"><i class="fa fa-road" aria-hidden="true"></i>
                </div>
                <div *ngIf="type == 'plate'" class="iconlabel isPlate"><i class="fa fa-truck" aria-hidden="true"></i>
                </div>
                <div *ngIf="type == 'device'" class="iconlabel isDevice"><i class="fa fa-mobile" aria-hidden="true"></i>
                </div>
                <div *ngIf="type == 'address'" class="iconlabel isAddress"><i class="fa fa-user-circle-o"
                        aria-hidden="true"></i>
                </div>
                <div *ngIf="type == 'user'" class="iconlabel isUser"><i class="fa fa-user-circle-o"
                        aria-hidden="true"></i></div>
                <div class="iconlabel" *ngIf="type == 'route' || type == 'shipment'" (click)="showAside(item)"><i
                        class="fa fa-info-circle"></i></div>
            </div>
        </span>
    </span>
</div>