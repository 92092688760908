import { Component, Input, Output, EventEmitter, ViewChild, QueryList, ElementRef } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MapsController } from '../../../../controllers/maps/maps.controller';
import { PlaceSearchItemDto } from '../../../../dtos/maps/PlaceSearchItemDto';

@Component({
  selector: 'maps-search-component',
  templateUrl: './maps-search.component.html',
  styleUrls: ['./maps-search.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MapsSearchComponent, multi: true }
  ]
})

export class MapsSearchComponent extends ValueAccessorValidatedBase<string, string> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormInput = this.fb.group({
      'dtpvalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return true;
  }

  rFormInput: FormGroup;

  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    private mapsController: MapsController
  ) {
    super(controlContainer, validators, asyncValidators);
  }

  @Input() public placeholder: string;
  @Input() public helpresource: string;
  @Input() public color: string = 'primary';
  @Input() public readonly: boolean;
  @Input() public showHelpPoint: boolean;
  @Input() public autocomplete: string = "off";
  @Input() public type: string = "text";
  @Input() public name: string = null;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  public identifier = 'input-' + identifier++;

  externalValue(value: string): string {
    if (!value)
      return "";

    return value;
  }
  internalValue(value: string): string {
    if (!value)
      return "";
    return value;
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  ngOnInit(): void {
    super.ngOnInit()
    fromEvent(this.search.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // if character length greater then 2
      , filter(res => res.length > 2)
      // Time in milliseconds between key events
      , debounceTime(500)
      // If previous query is diffent from current   
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((query: string) => {
      if (query && query.trim().length > 0) {
        this.mapsController.search(query).subscribe((data: Array<PlaceSearchItemDto>) => {
          this.filteredOptions = data
        })
      }
    });
  }

  @ViewChild('search', { static: true }) search: ElementRef;

  public resultLabel: string;
  triggerData(value) {
    if (typeof value == 'object' && value.description) {
      this.resultLabel = value.description
      this.mapsController.getPlaceInfo(value.description).subscribe((data:any) => {
        this.value = data
      })
    }
  }

  private _filteredOptions: PlaceSearchItemDto[]
  public set filteredOptions(data: PlaceSearchItemDto[]) {
    this._filteredOptions = data
  }
  public get filteredOptions(): PlaceSearchItemDto[] {
    return this._filteredOptions
  }

}

let identifier = 0;