<!-- select.component.html!-->
<div [formGroup]="rFormInput">
    <div *ngIf="!useIonic">
        <mat-form-field>
            <mat-label>{{ label }}</mat-label>
            <mat-select [id]="identifier" [(ngModel)]="value" [readonly]="readonly" [name]="name"
                formControlName="dtpvalidator" (selectionChange)="onChange($event)">
                <mat-option *ngFor="let option of options" [value]="option">
                    {{ optionLabel ? option[optionLabel] : option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div *ngIf="useIonic" class="app-select">
        <ion-select [placeholder]="label" [id]="identifier" [(ngModel)]="value" formControlName="dtpvalidator"
            ngDefaultControl [disabled]="readonly" [color]="color" (ionChange)="onChange($event)">
            <ion-select-option *ngFor="let option of options" [value]="option">
                {{ optionLabel ? option[optionLabel] : option }}
            </ion-select-option>
        </ion-select>
    </div>
</div>