<ng-container *ngIf="totem">
    <div class="page-title">
        {{'TOTEMPAGE.IDVERIFICATION.TITLE' | translateIn:totem.language | async}}
    </div>
    <div class="column-container" *ngIf="totem.arriveMustConfirmWithSecret">
        <div class="column-central">
            <input class="searchbox"
                placeholder="{{'TOTEMPAGE.IDVERIFICATION.SECRET.PROMPT'| translateIn:totem.language | async}}" type=text
                [(ngModel)]="secretText" (ngModelChange)="this.secretTextSubject.next($event)">

            <span [innerHtml]="totem.secretHelp"></span>
        </div>
        <div class="column-dreta">
            <div class="confirmacio-ruta">
                <button class="button-action" (click)="confirmacioRutaAcceptar()" [disabled]="secretText==''">
                    <i aria-hidden="true" class="fa fa-check-circle"></i>
                    <div class="code">{{'TOTEMPAGE.IDVERIFICATION.CONFIRMATION.BUTTON.ADD.CODE'|
                        translateIn:totem.language | async}}</div>
                </button>
            </div>
        </div>
    </div>
</ng-container>