import { Pipe, PipeTransform } from '@angular/core';
import { ActionDto } from './actionDto';

@Pipe({
    name: "orderactiondto"
})
export class OrderActionDtoFilter {
    transform(array: Array<ActionDto>): Array<ActionDto> {
        if (array == null)
            return array;
        array.sort((ao: any, bo: any) => {
            var a = 0;
            var b = 0;
            if (ao != null)
                a = (<ActionDto>ao).actionOrder;
            if (bo != null)
                b = (<ActionDto>bo).actionOrder;
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}
