import { Component, Input } from '@angular/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { LoadConsoleSlotPhaseDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseDto';
import { RouteGroupShipmentDto } from '@shared/src/dtos/route/RouteGroupShipmentDto';
import { PhaseSlotDto } from '@shared/src/dtos/slot/PhaseSlotDto';
import { TruckingShipmentDto } from '@shared/src/dtos/trucking/TruckingShipmentDto';
import { HColor, HomeController, RouteCardDto, ShipmentController } from '@shared/src/public-api';

@Component({
  selector: 'shared-trucking-shipment-strip-card-component',
  templateUrl: './shared.trucking.shipment.strip.component.html',
  styleUrls: ['./shared.trucking.shipment.strip.component.scss'],
})
export class SharedTruckingShipmentStripComponent {

  public useIonic: boolean = false;

  constructor(protected homeController: HomeController, protected slotsController: SlotsController, protected phaseController: PhaseController, protected shipmentController: ShipmentController) {

  }

  private _shipment: TruckingShipmentDto;
  @Input()
  public set shipment(value: TruckingShipmentDto) {
      if (this._shipment === value)
          return;
      this._shipment = value;

  }
  public get shipment(): TruckingShipmentDto {
      return this._shipment;
  }
  public showAssignedSlots: boolean = false;

  @Input()
  public isActiveRoutes: boolean = false;

  private _route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this._route === value)
      return;
    this._route = value;
    this.obtainValues();
  }
  get route(): RouteCardDto {
    return this._route;
  }

  private _phaseslot: PhaseSlotDto;
  @Input() public set phaseslot(value: PhaseSlotDto) {
    if (this._phaseslot === value)
      return;
    this._phaseslot = value;
    this.obtainValues();
  }
  public get phaseslot(): PhaseSlotDto {
    return this._phaseslot;
  }
  private _loadConsoleSlotPhase: LoadConsoleSlotPhaseDto;
  @Input()
  set loadConsoleSlotPhase(value: LoadConsoleSlotPhaseDto) {
    if (this._loadConsoleSlotPhase === value)
      return;
    this._loadConsoleSlotPhase = value;
  }
  get loadConsoleSlotPhase(): LoadConsoleSlotPhaseDto {
    return this._loadConsoleSlotPhase;
  }


  public routeShipmentDto: RouteGroupShipmentDto[];
  public slotInfo: SlotInfoDto;
  private obtainValues() {
    if (this._route != null) {
      this.slotInfo = SlotInfoDto.buildFromRouteCardDto(this._route);
      if (this._route.isGrouped)
        this.shipmentController.getRouteGroupShipmentsByRouteId(this._route.routeId).subscribe(d => {
          this.routeShipmentDto = d;
        });
    }
    else if (this._phaseslot != null) {
      this.slotInfo = SlotInfoDto.buildFromPhaseSlot(this._phaseslot);
      if (this._phaseslot.isGrouped)
        this.shipmentController.getRouteGroupShipmentsByRouteId(this._phaseslot.routeId).subscribe(d => {
          this.routeShipmentDto = d;
        });
    }
    else {
      this.slotInfo = null;
    }
  }

  getTextColor(data: HColor) {
    if (data != null)
      return HColor.getWhiteBlackFromColor(data.web);

    return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
  }
  getBackColor(data: HColor) {
    return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
  }
  getColor(text: string) {
    return HColor.getWhiteBlackFromColor(HColor.getColorFromStringTable(text, this.homeController));
  }
  getBackgroundColor(text: string) {
    return HColor.getColorFromStringTable(text, this.homeController);
  }

  getChildsNumber(route: RouteCardDto) {
    if (route != null)
      if (route.childs != null)
        return route.childs.length;
    return "";
  }
  OnInit() {
  }

  public goToExwork(route: RouteCardDto) {
    this.homeController.routeToExWork(route.uuidExwork);
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
