import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { BaseController } from '@shared/src/controllers/base.controller';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable, Subject } from 'rxjs';
import { TotemDto } from './TotemDto';

@Injectable({
    providedIn: 'root',
})
export class TotemController extends BaseController {


    private base = '/totem'

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router);
    }

    public getSetting(id: number): Observable<TotemDto> {
        return this.get(this.base + "/setting/" + id);
    }
    public next(totemSetting: TotemDto): Observable<TotemDto> {
        return this.post(this.base + "/next", totemSetting, "next");
    }

    public back(totemSetting: TotemDto): Observable<TotemDto> {
        return this.post(this.base + "/back", totemSetting, "back");
    }

    public identifyRoute(totemSetting: TotemDto, searchText: string): Observable<TotemDto> {
        return this.post(this.base + "/identify/" + searchText, totemSetting, "identify");
    }
    idverifyRoute(totem: TotemDto, secretText: string): Observable<TotemDto> {
        return this.post(this.base + "/idverify/" + secretText, totem, "idverify");
    }
}
