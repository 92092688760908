import { Component, Input, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { HomeController } from '../../../../controllers/home/home.controller';

@Component({
  selector: 'textarea-component',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TextAreaComponent, multi: true }
  ]
})

export class TextAreaComponent extends ValueAccessorValidatedBase<string, string> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormText = this.fb.group({
      dtpvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return true;
  }

  public rFormText: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder, private _ngZone: NgZone,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => {
        if (this.rows > 0) {
          this.autosize.enabled = false;
          this.autosize.maxRows = this.rows;
        } else {
          this.autosize.enabled = true;
          this.autosize.resizeToFitContent(true);
        }
      });
  }

  @Input() public placeholder: string;
  @Input() public helpresource: string;
  @Input() public color: string = 'primary';
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  _rows: number = 0;
  @Input() set rows(bvalue: number) {
    if (this._rows === bvalue)
      return;
    this._rows = bvalue;
    this.triggerResize();
  }
  get rows(): number {
    return this._rows;
  }

  public identifier = 'textarea-' + identifier++;

  _showHelpPoint: boolean;
  @Input() set showHelpPoint(bvalue: boolean) {
    if (this._showHelpPoint === bvalue)
      return;
    this._showHelpPoint = bvalue;
  }
  get showHelpPoint(): boolean {
    return this._showHelpPoint;
  }

  externalValue(value: string): string {
    if (value === null || value === undefined)
      return "";

    return value;
  }
  internalValue(value: string): string {
    if (value === null || value === undefined)
      return "";
    return value;
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;