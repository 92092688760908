import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class TotemSettingEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): TotemSettingEntitySelectorDto {
		let result: TotemSettingEntitySelectorDto = new TotemSettingEntitySelectorDto();
		result.loadData(EntitysSelectors.TotemSetting, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/screensandforms";
	}

	public getUrlBack(): string {
		return "/maintenance/totemsetting";
	}
}