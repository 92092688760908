import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { HHourInterval } from '../datatypes/HHourInterval';
import { HTime } from '../datatypes/HTime';

@Pipe({
	name: 'hourinterval'
})
@Injectable()
export class HourIntervalFilter implements PipeTransform {
	transform(value: HHourInterval): string {
		return HHourInterval.toScreenString(value);
	}
}

@Pipe({
	name: 'hourintervalTotal'
})
@Injectable()
export class HourIntervalTotalFilter implements PipeTransform {
	transform(value: HHourInterval): string {
		return HTime.getTimeString(HHourInterval.totalOf(value));
	}
}
