<!-- shared.chat.item.html !-->
<shared-chat-avatar class="shared-chatitem chat-header-avatar" [participant]="participantavatar" [isGroup]="isGroup">
</shared-chat-avatar>
<div class="shared-chatitem resta">
    <div class="shared-chatitem chat-header-key" [ngClass]="{'detail' : showdetail}">
        <span class="participant-key">{{participantkey}}</span>
        <span class="participant-annotation">{{participantAnnotation}}</span>
    </div>
    <div class="shared-chatitem chat-header-lastdathourmessage">{{lastdatehourmessage}}</div>
    <div class="shared-chatitem chat-header-lastmessage" [ngClass]="{'detail' : showdetail}">{{lastmessage}}</div>
    <div *ngIf="pendingMessagesRead > 0" class="shared-chatitem chatsicon-circle-item">
        <i class="fa fa-commenting" aria-hidden="true"></i>
        <div class="count">{{pendingMessagesRead}}</div>
    </div>
</div>
<div class="chatId" *ngIf="value">{{value.lastDateHour | datehour}} - {{value.chatId}}</div>