import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { HString } from '@shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'shared-exwork-phase-cmrs-qr-component',
    templateUrl: './shared.exwork.phase.cmrs.qr.component.html',
    styleUrls: ['./shared.exwork.phase.cmrs.qr.component.scss']
})
export class SharedExworkPhaseCMRsQRComponent implements OnInit {

    public useIonic: boolean = false;
    constructor(public router: Router, protected activatedRoute: ActivatedRoute,
        @Inject('HomeController') protected homeController: HomeController, protected translateService: TranslateService,
        public openRouteCMRController: OpenRouteCMRController) {


        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    ngOnInit(): void {
        this.getDades();
    }

    public phaseUUID: string = "";
    public getDades() {
        this.activatedRoute.params.subscribe(a => {
            this.phaseUUID = a.phaseuuid;
            this.loadRoutePartCMRs();
        });
    }

    public getCount():number{
        if(this.routePartCMRs == null)
            return 0;
        return this.routePartCMRs.length;
    }

    public routePartCMRs: RoutePartCMRDto[] = new Array();
    public formatDocumentQRCode: string;
    loadRoutePartCMRs() {
        if (!HString.isNullOrNullString(this.phaseUUID)) {
            this.openRouteCMRController.getRoutePartCMRsByPhaseUUID(this.phaseUUID).subscribe(data => { 
                if(data)
                    this.routePartCMRs = data;
                else
                    this.routePartCMRs = new Array();
            });
        }
    }
  
    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
