export class HInteger {
    public static comparesTo(a: number, b: number) {
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    }
    public static random(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    static min(a: number, b: number): number {
        if (a <= b)
            return a;
        return b;
    }
}