import { Injectable } from '@angular/core';

@Injectable()
export class SlotActionDto {

    public slotOrigenId: number;
    public phaseOrigenId: number;
    public slotDestiId: number;
    public phaseDestiId: number;
    public fromActiveRoutes: boolean;

    public static build(slotOrigenId: number, phaseOrigenId: number, slotDestiId: number, phaseDestiId: number, fromActiveRoutes: boolean): SlotActionDto{
        let dto: SlotActionDto = new SlotActionDto();
        dto.slotOrigenId = slotOrigenId;
        dto.phaseOrigenId = phaseOrigenId;
        dto.slotDestiId = slotDestiId;
        dto.phaseDestiId = phaseDestiId;
        dto.fromActiveRoutes = fromActiveRoutes;
        return dto
    }
}

