import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenUuidExwork, OpenUuidExworkPhase } from '@shared/src/controllers/open/OpenUuidExwork';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { HTags } from '@shared/src/datatypes/HTags';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { BookingSimpleDto } from '@shared/src/dtos/booking/BookingSimpleDto';
import { OpenRouteChangeDriverInfoAndPlatesDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoAndPlatesDto';
import { HTagsDefined } from '@shared/src/enums/HTagsDefined';
import { AlertService, HString, SharedSaveButtonEvent } from '@shared/src/public-api';
;

@Component({
    selector: 'shared-exwork-component',
    templateUrl: './shared.exwork.component.html',
    styleUrls: ['./shared.exwork.component.scss']
})
export class SharedExworkComponent implements OnInit {


    public useIonic: boolean = false;
    constructor(protected socketController: SocketController, public router: Router, protected activatedRoute: ActivatedRoute,
        @Inject('HomeController') protected homeController: HomeController,
        public openRouteController: OpenRouteController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {


        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    ngOnInit(): void {
        this.getDades();
    }

    public viewToShow: string = "general";

    public showNoRequiresSlot: boolean = false;
    public uuid: string = "";
    public data: OpenUuidExwork;
    public paneDretaObert: boolean = true;
    public logoPropi: boolean = false;
    public logoImage: FileUpDownLoadDto;
    public uniquePhase: OpenUuidExworkPhase;
    public getDades() {
        this.activatedRoute.params.subscribe(a => {
            this.uuid = a.uuidExwork;
            this.getExwork();
        });
    }

    getExwork() {

        if (HString.isNullOrNullString(this.uuid)) {
            this.data = null;
        } else {
            this.openRouteController.getExwork(this.uuid).subscribe(d => {
                this.data = d;
                if (d.editMode) {
                    this.startEditmode();
                } else {
                    this.editMode = false;
                }

                this.uniquePhase = d.uniquePhase;
                if (this.data != null && this.data.isGrouped)
                    this.loadInfoRouteGroup();

                if (this.logoImage == null) {
                    if (this.data != null && !HLong.isNullOrNullLong(this.data.tenantId))
                        this.systemController.getLogo(this.data.tenantId).subscribe((data: FileUpDownLoadDto) => {
                            this.logoImage = data;
                            if (this.logoImage != null && this.logoImage.fileBytes != null && this.logoImage.fileBytes.length > 0)
                                this.logoPropi = true;
                            else
                                this.logoPropi = false;
                        });
                }
            });
        }
    }
    public onRouteBookingRefreshData($event: BookingSimpleDto) {
        this.getExwork();
    }

    public algoAFer(): boolean {
        if (this.data && this.data.phaseList && this.data.phaseList.length > 0)
            return true;
        return false;
    }

    public esBooking(): boolean {
        if (this.data != null)
            if (HTags.contains(this.data.internalTags, HTagsDefined.HTAG_requiresBooking))
                return true;
        return false;
    }

    public requereixBooking(): boolean {
        if (this.esBooking())
            if (this.data != null && (this.data.bookingId == null || this.data.bookingId == 0))
                return true;

        return false;
    }
    public editMode: boolean = false;
    public plateA: string;
    public plateB: string;
    public plateC: string;
    public driverName: string;
    public driverLegalIdentification: string;
    public driverPhone: string;
    public carrierName: string;
    public carrierLegalIdentification: string;
    public carrierAddress: string;
    public startEditmode() {
        this.editMode = true;

        this.plateA = this.data.plateA;
        this.plateB = this.data.plateB;
        this.plateC = this.data.plateC;
        this.driverName = this.data.driverName;
        this.driverLegalIdentification = this.data.driverLegalIdentification;
        this.driverPhone = this.data.driverPhone;
        this.carrierName = this.data.carrierName;
        this.carrierLegalIdentification = this.data.carrierLegalIdentification;
        this.carrierAddress = this.data.carrierAddress;
    }

    save($event: SharedSaveButtonEvent) {
        this.openRouteController.setExworkDriverInfoAndPlates(this.uuid,
            OpenRouteChangeDriverInfoAndPlatesDto.build(this.driverName, this.driverLegalIdentification, this.driverPhone, this.plateA, this.plateB, this.plateC, this.carrierName, this.carrierLegalIdentification, this.carrierAddress)).subscribe(data => {
                this.editMode = false;
                this.getDades();
                if ($event)
                    $event.callback();
            });
    }
    public email: string = "";
    addEmail() {
        if (HString.isNullOrNullString(this.email))
            return;
        this.openRouteController.addExworkEmail(this.uuid,
            this.email).subscribe(data => {
                this.getDades();
            });
    }

    call(phone: string) {
        /* window.open(phone); */
        window.location.href = 'tel:' + phone;
    }
    copy(text: string) {
        this.alertService.copyToClipboard(text).subscribe();
    }

    onClickOption(option: string) {
        if (option == null || option == "") {
            this.viewToShow = "general";
        }
        this.viewToShow = option;
    }

    goToNormal() {
        this.viewToShow = 'general';
        this.getExwork();
    }

    onGoNewUUID(newUUID: string) {
        this.uuid = newUUID;
        this.goToNormal();
        this.router.navigateByUrl("/exwork/" + this.uuid);
    }

    onLoadNewUUID() {
        this.openRouteController.getUuidExworkParentGroupByUuidChild(this.uuid).subscribe(data => {
            this.onGoNewUUID(data);
        });
    }

    public listRouteGroupKeys: string[];
    loadInfoRouteGroup() {

        this.listRouteGroupKeys = new Array();
        if (HString.isNullOrNullString(this.uuid) || this.data == null || !this.data.isGrouped)
            return;

        this.openRouteController.getExworkRouteGroup(this.uuid).subscribe(data => {

            if (data != null && data.routesGroup != null) {
                data.routesGroup.forEach(element => {
                    this.listRouteGroupKeys.push(element);
                });
            }
        });

    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
