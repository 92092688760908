import { Component, Input, OnInit, Inject } from "@angular/core";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { RouteController } from "@shared/src/controllers/route/route.controller";
import { RouteCardItemExpandDto } from "@shared/src/dtos/route/RouteCardItemExpandDto";
import { HLong } from "@shared/src/datatypes/HLong";

@Component({
    selector: "shared-phase-changedates-component",
    templateUrl: "./shared.phase.changedates.component.html",
    styleUrls: ["./shared.phase.changedates.component.scss"],
})
export class SharedPhaseChangeDatesComponent implements OnInit {
    @Input() public readonly: boolean = false;

    constructor(
        public routeController: RouteController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) {
        this.securityController.getStoredUserDto().subscribe((data) => {
            this.readonly = data.isReadOnly;
        });
    }

    ngOnInit(): void {

    }

    private _routeId: number;
    @Input() public set routeId(value: number) {
        if(this._routeId === value)
            return;

        this._routeId = value;
        this.loadRoute();
    }

    public route: RouteCardItemExpandDto;

    loadRoute(){
        if(HLong.isNullOrNullLong(this._routeId))
            return;

        this.routeController.getRouteCardItemExpand(this._routeId).subscribe(data => {
            if(data)
                this.route = data;
            else
                this.route = null;
        });
    }

}
