<!-- datehour-picker.component.html!-->
<div class="container" [formGroup]="rFormDateHourPicker">
    <div *ngIf="!useIonic" class="datehour">
        <div *ngIf="!showTimeZoneObserver || !canShowTimeZoneObserver" class="datehour-zone"
            [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
            <mat-form-field *ngIf="!readonly">
                <input matInput [ngxMatDatetimePicker]="timepicker" [id]="identifier" formControlName="dtpvalidator"
                    [(ngModel)]="value" [placeholder]="placeholder" [max]="_max" [min]="_min"
                    (dateChange)="onChange($event)" (keydown.enter)="keyEnter($event)">
                <mat-datepicker-toggle [for]="timepicker" matSuffix></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #timepicker [showSpinners]="configuration.showSpinners"
                    [showSeconds]="configuration.showSeconds" [stepHour]="configuration.stepHour"
                    [stepMinute]="configuration.stepMinute" [stepSecond]="configuration.stepSecond"
                    [touchUi]="configuration.touchUi" [color]="configuration.color"
                    [enableMeridian]="configuration.enableMeridian" [disableMinute]="configuration.disableMinute"
                    [hideTime]="configuration.hideTime">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field *ngIf="readonly" class="readonly">
                <input matInput [id]="identifier" formControlName="dtpvalidator" [readonly]=true [(ngModel)]="value"
                    [placeholder]="placeholder" [max]="_max" [min]="_min">
            </mat-form-field>
            <span class="dateHourTimezone" *ngIf="_hdatehour && _hdatehour.Timezone && showDateHourTimezone">{{ 'TIMEZONES.' + _hdatehour.Timezone.toUpperCase() | translate }}</span>
        </div>
        <div *ngIf="showTimeZoneObserver && canShowTimeZoneObserver" class="datehour-zone"
            [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
            <div class="timezone-observer-zone">
                <mat-label class="title-timezone-observer">{{ placeholder }}</mat-label>
                <mat-label class="timezone-observer">{{ getDateHourObserver() | datehour }}</mat-label>
            </div>
        </div>
        <div *ngIf="showLocaleObserver && canShowTimeZoneObserver" class="showtimezoneobserver-icon">
            <i *ngIf="showTimeZoneObserver" (click)="onShowTimeZoneObserver()" class="fa fa-minus-square"
                style="font-size: 18px" aria-hidden="true"></i>
            <i *ngIf="!showTimeZoneObserver" (click)="onShowTimeZoneObserver()" class="fa fa-plus-square"
                style="font-size: 18px" aria-hidden="true"></i>
        </div>
    </div>
    <div *ngIf="useIonic" class="datehour-ionic">
        {{'No es pot format data hora, actualment només data'}}
        <ion-item *ngIf="!showTimeZoneObserver || !canShowTimeZoneObserver" class="datehour-zone"
            [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
            <ion-datetime [id]="identifier" formControlName="dtpvalidator" ngDefaultControl [(ngModel)]="value"
                [placeholder]="placeholder" displayFormat="YYYY-MM-DD HH:mm" [min]="_min" [max]="_max"
                (ionChange)="onChange($event)"></ion-datetime>
        </ion-item>
        <span class="dateHourTimezone mobile" *ngIf="_hdatehour && _hdatehour.Timezone && showDateHourTimezone">{{ 'TIMEZONES.' + _hdatehour.Timezone.toUpperCase() | translate }}</span>
        <div *ngIf="showTimeZoneObserver && canShowTimeZoneObserver" class="datehour-zone"
            [ngClass]="{ 'observer' : canShowTimeZoneObserver }">
            <ion-label class="timezone-observer-zone">
                <ion-label class="title-timezone-observer">{{ placeholder }}</ion-label>
                <ion-label class="timezone-observer">{{ getDateHourObserver() | datehour }}</ion-label>
            </ion-label>
        </div>
        <div *ngIf="showLocaleObserver && canShowTimeZoneObserver" class="showtimezoneobserver-icon"
            (click)="onShowTimeZoneObserver()">
            <i *ngIf="showTimeZoneObserver" class="fa fa-minus-square" style="font-size: 18px" aria-hidden="true"></i>
            <i *ngIf="!showTimeZoneObserver" class="fa fa-plus-square" style="font-size: 18px" aria-hidden="true"></i>
        </div>
    </div>

</div>
