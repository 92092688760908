import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';

@Injectable()
export class PartnerDriverMaintenanceController extends
    PartnerMaintenanceController {

        public getResourceRoot(): string {
        return "PARTNER.DRIVER";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/driver" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.DRIVER.LINKPERMISSION.FILTER"];
    }

}
