export enum ManagementModelRequirementActionTypes  {

	DocumentView = "DocumentView",
	DocumentUpLoad = "DocumentUpLoad",
	DocumentDownLoad = "DocumentDownLoad",
	DocumentKO = "DocumentKO",
	DocumentOK = "DocumentOK",
	DocumentCreateByTemplate = "DocumentCreateByTemplate",
	DocumentAutoCreate = "DocumentAutoCreate",
	DocumentActualize = "DocumentActualize",
	SendDocumentationClaimMessages = "SendDocumentationClaimMessages",
	None = "None"
}
