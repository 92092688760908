import { Component, Input, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { DocumentaryReviewObjectBaseDto } from '@shared/src/dtos/documentaryreview/DocumentaryReviewObjectBaseDto';

@Component({
  selector: 'shared-documentaryreview-list-ecmr-component',
  templateUrl: './shared.documentaryreview.list.ecmr.component.html',
  styleUrls: ['./shared.documentaryreview.list.ecmr.component.scss'],
})
export class SharedDocumentaryReviewListECMRComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }
  
  @Input() public readonly: boolean = false;

  public numDocuments: number = 0;
  public __value: DocumentaryReviewObjectBaseDto;
  @Input()
  set value(value: DocumentaryReviewObjectBaseDto) {
    if (this.__value === value)
      return;
    this.__value = value;
    if(this.__value == null || this.__value.cmrs == null)
      this.numDocuments = 0;
    else
      this.numDocuments = this.__value.cmrs.length;
  }
  get value(): DocumentaryReviewObjectBaseDto {
    return this.__value;
  }

  ngOnInit() {

  }

}
