import { Component, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
  selector: 'options',
  templateUrl: './shared.options.component.html',
  styleUrls: ['./shared.options.component.scss'],
})
export class SharedOptionsComponent {

  public useIonic: boolean = false;
  constructor(@Inject('HomeController') protected homecontroller: HomeController) {
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() disabled: boolean = false;

  private _optionStrip: OptionsStripDto;
  @Input() set optionstrip(value: OptionsStripDto) {
    // console.log("optionsstrip", value)
    if (this._optionStrip === value)
      return;
    this._optionStrip = value;
    if (this._optionStrip != null)
      if (this.optionstrip.getActualSelected() != null)
        this.value = this.optionstrip.getActualSelected().optionId;
      else
        this.value = "";
    this.getGeneralClass();
  }
  get optionstrip(): OptionsStripDto {
    return this._optionStrip;
  }

  @Output() click: EventEmitter<OptionDto> = new EventEmitter();
  @Output() clickOption: EventEmitter<OptionDto> = new EventEmitter();
  @Input() value: string;

  ngOnInit() {
  }

  getItemClass(value: OptionDto) {
    let result = "";
    if (value.optionSelected)
      result = "option-selected";
    else
      result = "option-no-selected";

    if (value != null && OptionDto.getOptionShowAsIcon(value))
      result = result + " icon-visible";
    else
      result = result + " icon-no-visible";

    if (value.optionShowAs >= 0 && this.isAwesome(value))
      result += " is-icon-awesome";
    else if (value.optionShowAs >= 0 && this.isHardman(value))
      result += " is-icon-hardman";
    else if (value.optionShowAs >= 0)
      result += " is-icon-no-awesome";

    if (value.optionShowAs == 1)
      result += " is-only-icon";
    if (value.optionShowAs == 0)
      result += " is-icon-text";
    if (value.optionShowAs < 0)
      result += " is-only-text";

    result += " " + value.classString;

    return result;
  }

  public generalClass = "";
  public getGeneralClass() {
    this.generalClass = "";
    if (this.haveBar())
      this.generalClass += " haveBar";
    if (this.haveMenu())
      this.generalClass += " haveMenu";


    let asIcon = false;
    let asText = false;
    if (this.optionstrip != null)
      if (this.optionstrip.listAsBar != null)
        this.optionstrip.listAsBar.forEach((value) => {
          if (value.optionShowAs == 1)
            asIcon = true;
          if (value.optionShowAs == 0) {
            asIcon = true; asText = true;
          }
          if (value.optionShowAs < 0)
            asText = true;
        });
    if (asIcon && asText)
      this.generalClass += " mixed-text-icon";
    else if (asIcon)
      this.generalClass += " only-icon";
    if (asText)
      this.generalClass += " only-text";

    return this.generalClass;
  }

  haveMenu() {
    if (this.optionstrip != null && this.optionstrip.listAsMenu != null && this.optionstrip.listAsMenu.length > 0)
      return true;
    return false;
  }
  haveBar() {
    if (this.optionstrip != null && this.optionstrip.listAsBar != null && this.optionstrip.listAsBar.length > 0)
      return true;
    return false;
  }

  set selectedOption(value: OptionDto) {
    this.chipClick(value, null);
  }
  get selectedOption(): OptionDto {
    return OptionsStripDto.find(this._optionStrip, this.value);
  }

  chipClickIon($event: any) {
    if ($event) {
      if ($event.detail)
        this.chipClick($event.detail.value, null);
      else if ($event.option != null)
        this.chipClick($event.option.value, null);
    }
  }
  chipClick(value: OptionDto, $event: any) {
    if (this.disabled)
      return;

    if (value != null) {
      this.value = value.optionId;
    }
    else
      this.value = "";
    OptionsStripDto.selectById(this._optionStrip, this.value);
    if (this.click != null)
      this.click.next(value);
    if (this.clickOption != null)
      this.clickOption.next(value);

    if ($event != null)
      $event.stopPropagation();
  }

  @ViewChild(MatMenuTrigger, { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild('menu', { static: true }) menu: MatMenu;
  menuClick(value: OptionDto, $event: any) {
    if (this.disabled)
      return;

    if (value != null) {
      this.value = value.optionId;
    }
    else
      this.value = "";
    OptionsStripDto.selectById(this._optionStrip, this.value);
    if (this.click != null)
      this.click.next(value);
    if (this.clickOption != null)
      this.clickOption.next(value);

    if (this.menuTrigger != null && this.menuTrigger.closeMenu != undefined)
      this.menuTrigger.closeMenu();
  }

  menuToggle($event: any) {
    if ($event != null)
      $event.stopPropagation();
  }

  isAwesome(value: OptionDto): boolean {
    if (value == null) {
      return false;
    }
    return value.optionIcon && (value.optionIcon.startsWith("awesome:") || value.optionIcon.startsWith("fa"));
  }

  isHardman(value: OptionDto): boolean {
    if (value == null) {
      return false;
    }
    return value.optionIcon && value.optionIcon.startsWith("hardman-");
  }

  getIcon(value: OptionDto): string {
    if (this.isAwesome(value) || this.isHardman(value)) {
      return value.optionIcon.substring(8, value.optionIcon.length);
    }
    return value.optionIcon;
  }

  compareWith(o1: OptionDto, o2: OptionDto) {
    if (o1 == null || o2 == null || o1 == undefined || o2 == undefined)
      return false;
    return o1.optionId === o2.optionId;
  }

  public pretty(value: any): String {
    return JSON.stringify(value);
  }
}

