import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomTableFieldData } from '@shared/src/controllers/maintenance/tenant/custom/table/data/CustomTableFieldData';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTypes } from '@shared/src/enums/DataTypes';
import { HDate } from '@shared/src/datatypes/HDate';
import { HTime } from '@shared/src/datatypes/HTime';
import { HHour } from '@shared/src/datatypes/HHour';
import { HTriState } from '@shared/src/datatypes/HTriState';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { HColor } from '@shared/src/datatypes/HColor';
import { HTags } from '@shared/src/datatypes/HTags';

@Component({
    selector: 'shared-customtable-field',
    templateUrl: './shared.customtable.field.component.html',
    styleUrls: ['./shared.customtable.field.component.scss']
})
export class SharedCustomTableFieldComponent implements OnInit {

    @Input() public readonly: boolean;

    @Output() changedTableData: EventEmitter<void> = new EventEmitter<void>();

    public fieldChanged = new Subject<number>();

    constructor() {
        this.fieldChanged
            .pipe(
                // Time in milliseconds between key events
                debounceTime(1000))
            // subscription for response
            .subscribe(() => {
                if (this._fieldData) {
                    this.changedTableData.next();
                }
            });
    }

    public dtString: DataTypes = DataTypes.String;
    public dtInteger: DataTypes = DataTypes.Integer;
    public dtDouble: DataTypes = DataTypes.Double;
    public dtLong: DataTypes = DataTypes.Long;
    public dtBoolean: DataTypes = DataTypes.Boolean;
    public dtHTriState: DataTypes = DataTypes.HTriState;
    public dtHDate: DataTypes = DataTypes.HDate;
    public dtHTime: DataTypes = DataTypes.HTime;
    public dtHHour: DataTypes = DataTypes.HHour;
    public dtHHourInterval: DataTypes = DataTypes.HHourInterval;
    public dtColor: DataTypes = DataTypes.Color;
    public dtHTags: DataTypes = DataTypes.HTags;
    public dtStringList: DataTypes = DataTypes.StringList;

    public valueString: string;
    public valueNumber: number;
    public valueBoolean: boolean;
    public valueHDate: HDate;
    public valueHTime: HTime;
    public valueHHour: HHour;
    public valueHHourInterval: HHourInterval;
    public valueColor: HColor;
    public valueHTags: HTags;
    public valueTriState: HTriState;

    private _fieldData: CustomTableFieldData;
    public get fieldData(): CustomTableFieldData {
        return this._fieldData;
    }

    @Input()
    public set fieldData(value: CustomTableFieldData) {
        if (this._fieldData === value)
            return;

        this._fieldData = value;
    }

    ngOnInit() {
        if (this.fieldData.dataType == DataTypes.Boolean)
            this.valueBoolean = this.fieldData.value == "true" ? true : false;
        else if (this.fieldData.dataType == DataTypes.HTriState)
            this.valueTriState = HTriState.fromString(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.HDate)
            this.valueHDate = HDate.fromStringDate(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.HTime)
            this.valueHTime = HTime.build(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.HHour)
            this.valueHHour = HHour.build(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.Long || this.fieldData.dataType == DataTypes.Integer || this.fieldData.dataType == DataTypes.Double)
            this.valueNumber = +this.fieldData.value;
        else if (this.fieldData.dataType == DataTypes.String || this.fieldData.dataType == DataTypes.StringList)
            this.valueString = this.fieldData.value;
        else if (this.fieldData.dataType == DataTypes.HHourInterval)
            this.valueHHourInterval = HHourInterval.buildstr(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.Color)
            this.valueColor = HColor.build(this.fieldData.value);
        else if (this.fieldData.dataType == DataTypes.HTags)
            this.valueHTags = HTags.fromString(this.fieldData.value);
    }

    public onValueChanged($event: any) {
        if (this.fieldData.dataType == DataTypes.Boolean)
            this.fieldData.value = $event == true ? "true" : "false";
        else if (this.fieldData.dataType == DataTypes.HTags)
            this.fieldData.value = HTags.convertToString($event);
        else if (this.fieldData.dataType == DataTypes.HTriState)
            this.fieldData.value = $event.state == 1 ? "1" : $event.state == 0 ? "0" : "-1";
        else
            this.fieldData.value = $event.toString();

        this.fieldChanged.next();
    }

    public onColorChanged($event: HColor) {
        this.fieldData.value = $event.web;
        this.fieldChanged.next();
    }

    // public valueChangedEvent() {
    //     this.fieldChanged.next();
    // }
}
