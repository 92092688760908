import { ActiveRouteDto } from "./ActiveRouteDto";
import { MenuOptionDto } from "./MenuOptionDto";

export class MenuOptionsDto {

    public aside: MenuOptionDto;

    public principal: MenuOptionDto;
    public options: MenuOptionDto[];
    public routeHomeButton: ActiveRouteDto;
    public defaultHome: string;
    public listAllPaths: string[];

    public static empty(): MenuOptionsDto {
        let result = new MenuOptionsDto();
        result.principal = null;
        result.options = [MenuOptionDto.settingsOption()];
        return result;
    }

}
