import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerProviderMaintenanceController extends
    PartnerMaintenanceController {

    public getResourceRoot(): string {
        return "PARTNER.PROVIDER";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/provider" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.PROVIDER.LINKPERMISSION.FILTER"];
    }

}
