import { Component, Input, OnInit, NgZone, ElementRef, Output, Inject, EventEmitter } from '@angular/core';
import { fromEvent as observableFromEvent } from 'rxjs';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';
import { PanelColumnElementDto } from '@shared/src/dtos/panel/column/element/PanelColumnElementDto';
import { PanelMaintenanceController } from '@shared/src/controllers/maintenance/panel/PanelMaintenanceController';
import { HColor } from '@shared/src/public-api';


@Component({
    selector: 'shared-panel-column-item',
    templateUrl: './shared.panel.column.item.component.html',
    styleUrls: ['./shared.panel.column.item.component.scss']
})
export class SharedPanelColumnItemComponent implements OnInit {

    constructor(private ele: ElementRef,
        public dragDropService: DragDropService,
        private ngZone: NgZone,
        public panelMaintenanceController: PanelMaintenanceController) {
        //https://stackoverflow.com/questions/43108155/angular-2-how-to-keep-event-from-triggering-digest-loop-detection-cycle
        this.ngZone.runOutsideAngular(() => {
            observableFromEvent(ele.nativeElement, "dragover")
                .subscribe((event: DragEvent) => {
                    this.dragDropService.onDragOver(null, null, event);
                }
                );
        });
    }

    ngOnInit(): void {
    }

    @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();

    private _value: PanelColumnElementDto;
    @Input()
    set value(value: PanelColumnElementDto) {
        if (this._value === value)
            return;
        this._value = value;
        this.setValueDraggable();
    }
    get value(): PanelColumnElementDto {
        return this._value;
    }

    private _canalterelement: boolean = true;
    @Input() public set canalterelement(value: boolean) {
        if (this._canalterelement === value)
            return;
        this._canalterelement = value;
        this.setValueDraggable();
    }
    public get canalterelement(): boolean {
        return this._canalterelement;
    }

    @Input() showPhaseCardAtAccessControl: boolean = false;

    onDragEnter(event: DragEvent, item: PanelColumnElementDto) {
        if (!item)
            return;

    }
    onDragLeave(event: DragEvent, item: PanelColumnElementDto) {
        if (!item)
            return;

    }

    public onDragStart(event: DragEvent, item: PanelColumnElementDto) {

        if (!this.isValueDraggable)
            item = null;

        DragDropService.moverOrCopy(event);
        event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_PANELCOLUMNELEMENTDTO);
        event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(item));
    }


    public isValueDraggable: boolean
    setValueDraggable() {
        if (!this.canalterelement)
            this.isValueDraggable = false;
        else if (this.value != null)
            this.isValueDraggable = true;
        else
            this.isValueDraggable = false;
    }

    
    public getColumnStyle() {
        if (this.value != null && this.value.columnBackColor != null) {
            let bcolor: string = this.value.columnBackColor.web;

            return {
                "color": HColor.getWhiteBlackFromColor(bcolor),
                "background-color": bcolor
            };
        }

        return {};
    }

}
