import { Injectable } from "@angular/core";
import { GlobalTelemetry } from "@shared/src/controllers/maps/GlobalTelemetry";
import { LocalizableObjectDto, LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';
import { PlateLocalizableObjectDto } from "@shared/src/controllers/maps/PlateLocalizableObjectDto";
import { RouteLocalizableObjectDto } from "@shared/src/controllers/maps/RouteLocalizableObjectDto";
import { HString } from "@shared/src/datatypes/HString";
import { HTags } from "@shared/src/datatypes/HTags";
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';

@Injectable({
    providedIn: 'root',
})
export class MapService {

    public static readonly DEFAULT_LATITUDE: number = 41.384567;
    public static readonly DEFAULT_LONGITUDE: number = 2.139055;
    public static readonly DEFAULT_GOOGLEMAPS_ZOOM: number = 5.5;

    public static readonly DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM = 16;

    public static defaultMapMarcador(): MapMarcadorDto {
        return MapMarcadorDto.builder(MapService.DEFAULT_LONGITUDE, MapService.DEFAULT_LATITUDE);
    }
}


/***
 * Defineix un punt en el mapa
 */
export class MapMarcadorDto {

    public position: google.maps.LatLng = null;
    public type: LocalizableObjectTypes;
    public longitude: number;
    public latitude: number;
    public iconUrl: string = "";
    public label: string = "";
    public sublabel: string = "";
    public sTags: string = "";
    public markerLabel: string = "";

    public isLost: boolean = false;
    public lostSince: number;

    public draggable: boolean;
    public localizableObject: LocalizableObjectDto;
    public routeLocalizableObject: RouteLocalizableObjectDto;
    public plateLocalizableObject: PlateLocalizableObjectDto;

    public phase: PhaseCardDto;

    //public animation: any;

    public static builder(long: number, lat: number): MapMarcadorDto {
        let result = new MapMarcadorDto();
        result.longitude = long;
        result.latitude = lat;
        result.position = new google.maps.LatLng(lat ? lat : 0, long ? long : 0);
        return result;
    }

    public static builderWaypointsPhase(waypoints: Array<MapPuntDto>): Array<MapMarcadorDto> {
        let result = new Array<MapMarcadorDto>();
        waypoints.forEach((w, index) => {
            if (MapRutaDto.acceptable(w)) {
                let marker = MapMarcadorDto.builder(w.longitude, w.latitude);
                marker.markerLabel = (index + 1).toString();
                marker.type = LocalizableObjectTypes.phase;
                marker.adreça();
                marker.phase = w.phase;
                result.push(marker);
            }
        });
        return result;
    }

    public static builderByLocalizableObject(obj: LocalizableObjectDto): MapMarcadorDto {
        let result = new MapMarcadorDto();

        if (obj != null) {
            result.type = obj.objectType;
            result.label = obj.key;
            result.sublabel = obj.description;
            if (obj.globalTelemetry != null) {
                result.longitude = obj.globalTelemetry.longitude;
                result.latitude = obj.globalTelemetry.latitude;
                result.isLost = obj.globalTelemetry.isLost;
                result.lostSince = obj.globalTelemetry.lostSince;
                result.position = new google.maps.LatLng(result.latitude ? result.latitude : 0, result.longitude ? result.longitude : 0);
            }
            result.localizableObject = obj;
            result.sTags = HTags.convertToString(obj.tags);
        }

        return result;
    }

    public static builderByPlateLocalizableObject(obj: PlateLocalizableObjectDto): MapMarcadorDto {
        let result = new MapMarcadorDto();

        if (obj != null) {
            result.type = obj.objectType;
            result.label = obj.key;
            result.sublabel = obj.description;
            if (obj.globalTelemetry != null) {
                result.longitude = obj.globalTelemetry.longitude;
                result.latitude = obj.globalTelemetry.latitude;
                result.isLost = obj.globalTelemetry.isLost;
                result.lostSince = obj.globalTelemetry.lostSince;
                result.position = new google.maps.LatLng(result.latitude ? result.latitude : 0, result.longitude ? result.longitude : 0);
            }
            result.plateLocalizableObject = obj;
            result.localizableObject = obj;
            result.sTags = HTags.convertToString(obj.tags);
        }

        return result;
    }
    public static builderByRouteLocalizableObject(obj: RouteLocalizableObjectDto): MapMarcadorDto {
        let result = new MapMarcadorDto();

        if (obj != null) {
            result.type = obj.objectType;
            result.label = obj.key;
            result.sublabel = obj.description;
            if (obj.globalTelemetry != null) {
                result.longitude = obj.globalTelemetry.longitude;
                result.latitude = obj.globalTelemetry.latitude;
                result.isLost = obj.globalTelemetry.isLost;
                result.lostSince = obj.globalTelemetry.lostSince;
                result.position = new google.maps.LatLng(result.latitude ? result.latitude : 0, result.longitude ? result.longitude : 0);
            }
            result.routeLocalizableObject = obj;
            result.localizableObject = obj;
            result.sTags = HTags.convertToString(obj.tags);
        }

        return result;
    }
    public static builderByGlobalTelemetry(obj: GlobalTelemetry): MapMarcadorDto {
        let result = new MapMarcadorDto();

        if (obj != null) {
            if (!obj.noTelemetry) {
                result.longitude = obj.longitude;
                result.latitude = obj.latitude;
                result.isLost = obj.isLost
                result.lostSince = obj.lostSince;
                result.position = new google.maps.LatLng(result.latitude ? result.latitude : 0, result.longitude ? result.longitude : 0);
            }
            result.sTags = "";
            if (obj.isLost)
                result.sTags = HString.concat(result.sTags, ", ", "lost");
            if (obj.noTelemetry)
                result.sTags = HString.concat(result.sTags, ", ", "noTelemetry");
        }

        return result;
    }

    public jo(): MapMarcadorDto {
        this.iconUrl = "/assets/images/markergolden.svg";
        return this;
    }
    public vehicle(): MapMarcadorDto {
        this.iconUrl = "/assets/images/markerblue.svg";
        return this;
    }
    public adreça(): MapMarcadorDto {
        this.iconUrl = MapMarcadorDto.DEFAULTMARKER;
        return this;
    }
    public static readonly DEFAULTMARKER: string = "/assets/images/markerblack.svg";

    public lost(): MapMarcadorDto {
        this.iconUrl = "/assets/images/markerec8b11.svg";;
        return this;
    }
    public stop(): MapMarcadorDto {
        this.iconUrl = "/assets/images/marker7e922f.svg";
        return this;
    }
    public move(): MapMarcadorDto {
        this.iconUrl = "/assets/images/marker089008.svg";
        return this;
    }
    public isDraggable() {
        this.draggable = true;
        return this;
    }

}

/***
 * Defineix un punt en un mapa.
 */
export class MapPuntDto {
    public position: {} = null;
    public longitude: number;
    public latitude: number;
    public phase: PhaseCardDto;

    public static builder(long: number, lat: number, phase?: PhaseCardDto): MapPuntDto {
        let result = new MapPuntDto();
        result.longitude = long;
        result.latitude = lat;
        result.position = new google.maps.LatLng(result.latitude ? result.latitude : 0, result.longitude ? result.longitude : 0);

        if (phase)
            result.phase = phase;
        return result;
    }
}

/***
 * Definex una ruta a fer, punt d'origen i punt de desti
 */
export class MapRutaDto {
    public origen: MapPuntDto;
    public desti: MapPuntDto;
    public waypoints: Array<MapPuntDto> = [];
    public isPhaseList: boolean = false;

    public static builder(origen: MapPuntDto, desti: MapPuntDto): MapRutaDto {
        let result = new MapRutaDto();
        result.origen = origen;
        result.desti = desti;
        return result;
    }

    public static builderWaypoints(ruta: Array<MapPuntDto>, isPhaseList?: boolean): MapRutaDto {
        let result = new MapRutaDto();
        ruta.forEach((r, index) => {
            if (MapRutaDto.acceptable(r)) {
                if (result.origen == null)
                    result.origen = r;

                result.desti = r;
                result.waypoints.push(r);
            }
        });
        if (isPhaseList)
            result.isPhaseList = isPhaseList;
        return result;
    }

    /***
     * Indica si un wayPoint és acceptable pel mapa. Els waypoints amb coordenades 0 no son acceptats.
     */
    public static acceptable(wp: MapPuntDto): boolean {
        return !(wp == null || ((wp.latitude == null || wp.latitude == 0) && (wp.longitude == null || wp.longitude == 0)));
    }

}

