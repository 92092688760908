
import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RouteDocumentTypes } from '@shared/src/enums/RouteDocumentTypes';
import { OpenDocument } from '@shared/src/dtos/open/OpenDocument';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DocumentDto } from '@shared/src/dtos/document/DocumentDto';
import { OpenDocumentDetail } from '@shared/src/dtos/open/OpenDocumentDetail';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ImageDocument } from '@shared/src/dtos/document/ImageDocument';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { DocumentViewerTypeDto } from '@shared/src/dtos/document/struct/DocumentViewerTypeDto';
import { DocumentViewerHistoricDto } from '@shared/src/dtos/document/struct/DocumentViewerHistoricDto';
import { HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-documentaryreview-documents-component',
    templateUrl: './shared.documentaryreview.documents.component.html',
    styleUrls: ['./shared.documentaryreview.documents.component.scss']
})


export class SharedDocumentaryReviewDocumentsComponent implements OnInit {

    @Input() public readonly: boolean = false;
    public useIonic: boolean = false;

    constructor(public documentController: DocumentController, 
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') protected homecontroller: HomeController,
        private sanitizer: DomSanitizer, public dialog: MatDialog) {
        if (!this.readonly) {
            this.securityController.getStoredUserDto().subscribe(data => {
                this.readonly = data.isReadOnly;
            })
        }

        if (homecontroller.useIonic)
            this.useIonic = homecontroller.useIonic();
    }

    @Input() public doLoadDocuments: boolean = true;
    public _parentObject: DocumentParentObjectDto;
    @Input()
    set parentObject(value: DocumentParentObjectDto) {
        if (this._parentObject === value)
            return;
        this._parentObject = value;
        this.loadDocuments();
    }
    get parentObject(): DocumentParentObjectDto {
        return this._parentObject;
    }

    public _documents: Array<DocumentViewerTypeDto>;
    @Input()
    set documents(value: Array<DocumentViewerTypeDto>) {
        if (this._documents === value)
            return;
        this._documents = value;
    }
    get documents(): Array<DocumentViewerTypeDto> {
        return this._documents;
    }

    ngOnInit(): void {

    }
    
    @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();

    public enumDocumentTypes = RouteDocumentTypes;

    public documentHasHistory(document: DocumentViewerHistoricDto): boolean {
        if(document == null)
            return false;

        if(document.historic != null && document.historic.length > 0)
            return true;

        return false;
    }

    public documentHasDetail(document: DocumentViewerHistoricDto): boolean {
        if(this.documentHasHistory(document))
            return false;
        
        if (document == null || document.documentDetailList == null)
            return false;
        return document.documentDetailList.length > 0;
    }

    public documentHistHasDetail(thisdocument: OpenDocument): boolean {
        
        if (thisdocument == null || thisdocument.documentDetailList == null)
            return false;
        return thisdocument.documentDetailList.length > 0;
    }

    public loadDocuments() {
        if (this.parentObject == null || !this.doLoadDocuments)
            return;
        this.documentController.getAllDocumentsByParentObject(this.parentObject).subscribe(data => {
            this.documents = data;
        });
    }

    public downloadDocumentHist(doc: DocumentViewerHistoricDto) {
        if (doc == null || (doc.historic == null && doc.documentDetailList == null))
            return;

        if(doc.historic != null && doc.historic.length > 0){
            doc.historic.forEach(element => {
                element.documentDetailList.forEach(detail => {
                    this.downloadDocumentDetail(detail);
                });
            });
        } else{
            doc.documentDetailList.forEach(detail => {
                this.downloadDocumentDetail(detail);
            });
        }
    }

    public downloadDocument(doc: OpenDocument) {
        if (this.parentObject == null)
            return;

        doc.documentDetailList.forEach(element => {
            this.downloadDocumentDetail(element);
        });
    }

    public downloadDocumentDetail(doc: OpenDocumentDetail) {
        if (this.parentObject == null)
            return;

        this.documentController.downloadParentObjectDocumentDetail(this.parentObject, doc.documentDetailId).subscribe(data => {
            FileUpDownloadController.resultDownLoad(data);
        });
    }

    isImageDocument(doc: OpenDocument): boolean {
        if (doc == null)
            return;
        return FileUpDownLoadDto.isImageType(doc.fileType);
    }
    isImageDocumentDetail(doc: OpenDocumentDetail): boolean {
        if (doc == null)
            return;
        return FileUpDownLoadDto.isImageType(doc.fileType);
    }

    public handleDocumentUpload(files: FileList, documentType: RouteDocumentTypes) {
        if (this.parentObject == null)
            return;
        if (files.length > 0) {

            let file: File = files[0];
            let fileName = file.name;
            
            DocumentDto.buildByFiles(files, fileName, FileUpDownLoadDto.getFileType(file.type), documentType).then(value => {
                this.documentController.uploadParentObjectDocument(this.parentObject, value).subscribe(data => {
                    if (data) {
                        this.loadDocuments();
                        this.dataRefresh.next(true);
                    }
                });
            });

        }
    }

    existsDocument() {
        if (this.documents == null || this.documents.length === 0)
            return false;

        return true;
    }

    /** ESPECÍFIC MOBILE */
    getPrefixFileNameImage() {
        let filename: string = "";
        if (this.parentObject != null && !HString.isNullOrNullString(this.parentObject.parentKey))
            filename = this.parentObject.parentKey + ".";
        return filename;
    }
    
    public documentType: RouteDocumentTypes = RouteDocumentTypes.None;
    onButtonClicked() {
        //this.showingDocumentList = false;
        this.homecontroller.onMakingPhoto$.next(true);

        let element = document.getElementById("take-a-photo");
        if (element)
            element.click();

        let tancar = document.getElementById("tancar");
        if (tancar)
            tancar.click();
    }

    onDocumentUploadClicked(documenttype: RouteDocumentTypes) {
        this.documentType = documenttype;
        this.onButtonClicked();
    }

    public imageList: Array<ImageDocument> = new Array<ImageDocument>();
    onSelectedImage(value: ImageDocument) {
        if (value && !this.imageList.includes(value))
            this.imageList.push(value);
    }

    uploadPhotos() {
        if (this.imageList && this.imageList.length > 0) {
            let details: Array<DocumentDetailDto> = new Array<DocumentDetailDto>();
            this.imageList.forEach(i => {
                let detail: DocumentDetailDto = new DocumentDetailDto();
                detail.fileName = i.filename ? i.filename : "";
                detail.fileType = i.filetypes ? i.filetypes : FileTypes.jpg;
                detail.fileBytes = i.filebytes;
                details.push(detail);
            });

            let document: DocumentDto = new DocumentDto();
            document.documentDetailList = new Array<DocumentDetailDto>();
            document.documentDetailList = details;
            document.fileName = this.imageList[0].filename ? this.imageList[0].filename : "";
            document.fileType = this.imageList[0].filetypes ? this.imageList[0].filetypes : FileTypes.jpg;
            document.documentType = this.documentType;

            this.documentController.uploadParentObjectDocument(this.parentObject, document).subscribe(data => {
                if (data){
                    this.loadDocuments();   
                    this.dataRefresh.next(true);
                }
            });
            
            this.cancelPhotos();
        }
    }

    public enable: boolean = true;
    public showingDocumentList = true;
    cancelPhotos() {
        this.imageList = new Array<ImageDocument>();
        this.showingDocumentList = true;
        this.homecontroller.onMakingPhoto$.next(false);
        this.enable = false;
        setTimeout(() => {
            this.enable = true;
        }, 100);
    }

    onInitTakeImage($event) {
        if (!$event) {
            this.homecontroller.onMakingPhoto$.next(false);
            this.showingDocumentList = true;
        } else
            this.showingDocumentList = false;
    }
}
