<mat-accordion class="exworks accordion" *ngIf="value.canExwork">
    <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="ellipsis">
                    <span class="title-expansion">
                        {{'ROUTE.EXWORKS.CAPTION' | translate }}</span>
                </div>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <shared-route-exworks-edit [routeId]="panelOpenState ? value.routeId : 0" [readonly]="readonly" (refresh)="onRefresh($event)"></shared-route-exworks-edit>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</mat-accordion>
<div class="exworks no-accordion">
    <shared-route-exworks-edit [routeId]="value.routeId" [readonly]="readonly" (refresh)="onRefresh($event)"></shared-route-exworks-edit>
</div>