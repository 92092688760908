import { TelemetryDto } from "./TelemetryDto";
import { TelemetryDetail } from "./TelemetryDetail";

export class TelemetryD {

    public numMax: number;
    public numElements: number;
    public items: Array<TelemetryDetail>;

    public static build(list: Array<TelemetryDto>, numMax: number) {
        let result: TelemetryD = new TelemetryD();
        result.numMax = numMax;
        result.items = new Array<TelemetryDetail>();
        list = list.reverse();
        result.loadList(list);
        return result;
    }

    public loadList(list: Array<TelemetryDto>) {
        if (((list == null) || list.length == 0)) {
            return;
        }
        if (list.length < this.numMax) {
            var i = 0;
            list.forEach(t => {
                this.items.push(TelemetryDetail.build(t, i++));
            });
        } else {
            //Calculo la distància total entre dos punts
            let anterior: TelemetryDto = null;
            let distancia = 0;
            let distanciaAcumulada = 0;
            list.forEach(t => {
                if (anterior != null)
                    distancia += TelemetryD.getDistanceFromLatLonInKm(anterior.latitude, anterior.longitude, t.latitude, t.longitude);

                anterior = t;
            });

            let tram = distancia / this.numMax;
            //Cas raro, si la distància és 0 hem de generar numMax values sense moviment
            if (distancia == 0) {
                let i = 0;
                while (i < this.numMax)
                    this.items.push(TelemetryDetail.build(list[i], i++));
            } else {
                let i = 0;
                anterior = null;
                list.forEach(t => {
                    if (anterior == null) {
                        anterior = t;
                        this.items.push(TelemetryDetail.build(t, i++));
                    } else {
                        distanciaAcumulada += TelemetryD.getDistanceFromLatLonInKm(anterior.latitude, anterior.longitude, t.latitude, t.longitude);
                        if (distanciaAcumulada < (i * tram))
                            TelemetryDetail.add(this.items[this.items.length - 1], t);
                        else {
                            while (distanciaAcumulada > (i * tram))
                                this.items.push(TelemetryDetail.build(t, i++));
                        }
                    }
                    anterior = t;
                });
            }
            this.numElements = this.items.length;
        }
    }


    public static getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = TelemetryD.deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = TelemetryD.deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(TelemetryD.deg2rad(lat1)) * Math.cos(TelemetryD.deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return Math.abs(d);
    }

    public static deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }
}