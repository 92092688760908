import { Component, Input, OnInit } from '@angular/core';
import { HColor } from '@shared/src/datatypes/HColor';
import { AvatarDto } from '../../../dtos/core/avatar/AvatarDto';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  public padding: number = 0;
  public fontsize: number = 10;

  constructor() {

  }

  ngOnInit() {
    this.calculate();
  }


  @Input() width;
  @Input() height;
  @Input() monochrome: boolean = false;


  public _avatar: AvatarDto;
  @Input()
  public set avatar(value: AvatarDto) {
    if (this._avatar === value)
      return;
    this._avatar = value;
    this.calculate();
  }
  public get avatar(): AvatarDto {
    return this._avatar;
  }

  public backgroundColor: string;
  public foreColor: string;
  public skin: string;

  private calculate() {
    if (this.avatar == null) {
      this.backgroundColor = "#000000";
      this.foreColor = "#ffffff";
      this.skin = "-";
      return;
    }

    this.skin = this.avatar.skin;
    this.backgroundColor = HColor.getColor(this.avatar.backgroundColor, HColor.getColorFromString(this.skin));
    this.foreColor = HColor.getWhiteBlackFromColor(this.backgroundColor);

    if (this.width == null)
      this.width = 50;
    if (this.height == null)
      this.height = 50;

    this.fontsize = Math.max(5, Math.min(this.width, this.height) / 3);

    if (this.height > 25)
      this.padding = this.height / 4.5;
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
