import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { HDate } from '../datatypes/HDate';

@Pipe({
	name: 'date'
})
@Injectable()
export class DateFilter implements PipeTransform {
	
	public transform(value: HDate): string {
		return DateFilter.getTransform(value);
	}

	public static getTransform(value: HDate): string {
		if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
			return "---";
		let result: string;

		result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year;
		return result;

	}

	public static pad(num, size): string {
		let s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'datenoyear'
})
export class DateNoYearFilter implements PipeTransform {
	public transform(value: HDate): string {
		return DateNoYearFilter.getTransform(value);
	}

	public static getTransform(value: HDate): string {
		if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
			return "---";
		let result: string;

		result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2);
		return result;

	}

	public static pad(num, size): string {
		let s = "000000000" + num;
		return s.substr(s.length - size);
	}
}