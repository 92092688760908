import { ManagementModelObjectParentTypes } from "@shared/src/enums/ManagementModelObjectParentTypes";
import { ParticipantDto } from "../chat/participant/ParticipantDto";
import { ManagementModelRequirementDto } from "./ManagementModelRequirementDto";

export class ManagementModelChatInfoDto {

     public objectParentType: ManagementModelObjectParentTypes;

	public objectParentId: number;
    
    public managementModelRequirementId: number;
    
    public managementModelRequirementResponseId: number;

   public static build(value: ManagementModelRequirementDto): ManagementModelChatInfoDto{
          if(value == null)
               return null;
        let dto = new ManagementModelChatInfoDto();
        dto.objectParentType = value.objectParentType;
        dto.objectParentId = value.objectParentId;
        dto.managementModelRequirementId = value.managementModelRequirementId;
        dto.managementModelRequirementResponseId = value.managementModelRequirementResponseId;
        return dto;
   }
}
