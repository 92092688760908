import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseController } from "../base.controller";
import { UserSettingsDto } from "./../../dtos/settings/UserSettingsDto";
import { SecurityController } from "../security/security.controller";
import { Languages } from '@shared/src/enums/Languages';
import { TimeZones } from "@shared/src/enums/TimeZones";
import { InitialMenuOptions } from "@shared/src/enums/InitialMenuOptions";

@Injectable({
    providedIn: 'root',
})
export class SettingsController extends BaseController {
    private base = '/settings';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }


    public getUserSettings(): Observable<UserSettingsDto> {
        return super.get(this.base + "/");
    }

    public changeUserName(fullname: string): Observable<UserSettingsDto> {

        return new Observable(observer => {
            try {
                this.post(this.base + "/fullname", fullname, "changeFullName")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.fullname == null) return;
                            observer.next(data);
                            observer.complete();
                            return;

                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }


    public changeUserEmail(email: string): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/email", email, "changeEmail")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null) return;
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });

    }

    public changeUserPhone(prefix: string, phone: string): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/phone", { 'prefix': prefix, 'phone': phone }, "changePhone")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null) return;
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeLanguage(language: Languages): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/language", language, "changeLanguage")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.language == null) return;
                            this.securityController.setLanguage(data.language);
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeTimeZone(timezone: TimeZones): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/timezone", timezone, "changeTimeZone")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.timeZone == null) return;
                            this.securityController.setTimeZone(data.timeZone);
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeInitialMenuOption(initialmenuoption: InitialMenuOptions): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/initialmenuoption", initialmenuoption, "changeInitialMenuOptions")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null) return;
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeUserPass(old_pass: string, new_pass: string): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/password", { 'old_pass': old_pass, 'new_pass': new_pass }, "changePass")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.email == null && data.phone == null) return;
                            observer.next(data);
                            observer.complete();
                            return;
                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeAlias(alias: string): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/alias", alias, "changeAlias")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.alias == null) return;
                            observer.next(data);
                            observer.complete();
                            return;

                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeUseAliasAtAvatar(useAliasAtAvatar: boolean): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/usealiasatavatar", useAliasAtAvatar, "changeUseAliasAtAvatar")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.alias == null) return;
                            observer.next(data);
                            observer.complete();
                            return;

                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }

    public changeSkin(skin: string): Observable<UserSettingsDto> {
        return new Observable(observer => {
            try {
                this.post(this.base + "/skin", skin, "changeSkin")
                    .subscribe(
                        (data: UserSettingsDto) => {
                            if (data == null || data.alias == null) return;
                            observer.next(data);
                            observer.complete();
                            return;

                        },
                        (error: any) => {
                            observer.next();
                            observer.complete();
                            return;
                        }
                    );
            } catch {
                observer.next()
                observer.complete();
                return;
            }
        });
    }
}