<div class="line" *ngIf="lineObject">
    <div class="data-left">
        <div class="reference">{{ lineObject.reference }}</div>
        <div class="description">{{ lineObject.description }}</div>
    </div>
    <div class="data-right">  
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.WDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.wDimensionOrigin | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.YDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.yDimensionOrigin | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.HDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.hDimensionOrigin | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.M3.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.m3Origin | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.QUANTITY.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.quantityOrigin | number}}</div>
        </div>
        <div class="element-value expand">
            <div class="subtitle">{{'ROUTEPARTCMR.PALLETCOUNT.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.palletCountOrigin | number}} {{ getResourcePalletType(lineObject.palletType)}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.KG.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.kgOrigin | number}}</div>
        </div>
    </div>
</div>