<div class="container-group">
  <div *ngIf="data && data.bookingId">
    <span class="titol">{{ 'ESCHENKERCOMPONENT.BOOKING_ID.CAPTION' | translate }}</span>
    <span class="valor">{{data.bookingId}}</span>
    <span class="titol">{{ 'ESCHENKERCOMPONENT.LABEL_CREATION_DATE_TIME.CAPTION' | translate }}</span>
    <span class="valor">
      <datehour-viewer [value]="data.labelCreationDateTime" [showOriginal]="false" [showTimeZone]="false">
      </datehour-viewer>
    </span>
    <button class="action slim" *ngIf="data.hasLabel"
      (click)="getLabel()">{{ 'ESCHENKERCOMPONENT.GETLABEL.CAPTION' | translate}}</button>
  </div>
  <div *ngIf="data && !data.bookingId">
    <span class="valor">{{ 'ESCHENKERCOMPONENT.NOLABEL.CAPTION' | translate }}</span>
    <button class="action slim" *ngIf="data && !data.hasLabel"
      (click)="askingCreateLabel = true">{{ 'ESCHENKERCOMPONENT.CREATELABEL.CAPTION' | translate}}</button>
    <confirmation-dialog [expanded]="askingCreateLabel" helptitleresource="ESCHENKERCOMPONENT.CREATELABEL.CAPTION"
      helpresource="ESCHENKERCOMPONENT.CREATELABEL.CONFIRM" (onConfirm)="createLabel()"
      (onCancel)="askingCreateLabel = false"></confirmation-dialog>
  </div>
</div>