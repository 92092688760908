import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { TruckingRouteExpandDto } from '@shared/src/dtos/trucking/TruckingRouteExpandDto';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';

@Component({
    selector: 'shared-trucking-package-item',
    templateUrl: './shared.trucking.package.item.html',
    styleUrls: ['./shared.trucking.package.item.scss']
})
export class SharedTruckingPackageItem implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController,
        public truckingController: TruckingController) {
        this.truckingController.shipmentPackageChanged$.subscribe((data: TruckingShipmentPackageDto) => {
            if(data && this.package && data.packageId == this.package.packageId)
			    this.refresh.next(true);
		});
    }

	@Output() refresh = new EventEmitter<boolean>();

    ngOnInit(): void { }

    @Input()
    public selectedRouteExpand: TruckingRouteExpandDto;

    @Input()
    public isDraggable: boolean = false;
    
    @Input() selected: boolean;
    
    public actions: ActionsStripDto;
    private _package: TruckingShipmentPackageDto;
    @Input()
    public set package(value: TruckingShipmentPackageDto) {
        if (this._package === value)
            return;
        this._package = value;
        this.actions = new ActionsStripDto();
        if(this._package){
            this.actions.add(SharedTruckingPackageItem.SHOWDETAIL);
            if(this.selectedRouteExpand){
                if(this.package.isTruckedPackage)
                    this.actions.add(SharedTruckingPackageItem.REMOVEPACKAGE);
                else
                    this.actions.add(SharedTruckingPackageItem.PUTPACKAGE);
            }
        }
    }
    public get package(): TruckingShipmentPackageDto {
        return this._package;
    }


    
    onDragEnter(event: DragEvent) {
        return;
    }
    onDragLeave(event: DragEvent) {
        return;
    }

    public getGeneralClass(): string {
        let result = "";
        if (this.selected)
            result += "selected ";
        if (this.isDraggable)
            result += "dragable ";
       
        return result;
    }

    public onDragStart(event: DragEvent) {

        if (this.package == null)
            return;
        DragDropService.moverOrCopy(event);
        event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_TRUCKINGPACKAGEITEMDTO);
        event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(this.package));
    }

    onClickAction($event: ActionDto) {
        if ($event == null)
          return;
        switch ($event.actionId) {
            case 'SHOWDETAIL':
                this.onShowDetailClick();
                break;
            case 'PUTPACKAGE':
                this.onPutPackageClick();
                break;
            case 'REMOVEPACKAGE':
                this.onRemovePackageClick();
                break;
        }
    }

    onShowDetailClick(){

    }

    onPutPackageClick(){
        if (this.selectedRouteExpand == null || this.package == null)
			return;

        this.truckingController.addPackageInRoute(this.selectedRouteExpand.routeId, this.package.packageId).subscribe(data => {
			if(data){
                this.selectedRouteExpand.totalPackages++;
                this.refresh.next(true);
                this.truckingController.routeChanged$.next(this.selectedRouteExpand.routeId);
            }
		});
    }
    onRemovePackageClick(){
        if (this.selectedRouteExpand == null || this.package == null)
			return;

        this.truckingController.removePackageInRoute(this.selectedRouteExpand.routeId, this.package.packageId).subscribe(data => {
			if(data){
                this.selectedRouteExpand.totalPackages--;
                this.refresh.next(true);
                this.truckingController.routeChanged$.next(this.selectedRouteExpand.routeId);
            }
		});
    }

    public static SHOWDETAIL: ActionDto = ActionDto.build("SHOWDETAIL", "TRUCKING.SHIPMENT.ACTIONS.SHOWDETAIL.CAPTION", "TRUCKING.SHIPMENT.ACTIONS.SHOWDETAIL.HELP", "", 1, 0, 0, "");
    public static PUTPACKAGE: ActionDto = ActionDto.build("PUTPACKAGE", "TRUCKING.SHIPMENT.ACTIONS.PUTPACKAGE.CAPTION", "TRUCKING.SHIPMENT.ACTIONS.PUTPACKAGE.HELP", "", 1, 0, 0, "");
    public static REMOVEPACKAGE: ActionDto = ActionDto.build("REMOVEPACKAGE", "TRUCKING.SHIPMENT.ACTIONS.REMOVEPACKAGE.CAPTION", "TRUCKING.SHIPMENT.ACTIONS.REMOVEPACKAGE.HELP", "", 1, 0, 0, "");
 
}
