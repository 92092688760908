import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { AlarmDefinitionListMaintenanceDto } from './AlarmDefinitionListMaintenanceDto';
import { AlarmDefinitionItemMaintenanceDto } from './AlarmDefinitionItemMaintenanceDto';
import { AlarmDefinitionLogFilterDto } from '@shared/src/dtos/alarmdefinition/AlarmDefinitionLogFilterDto';
import { Observable } from 'rxjs';
import { BaseAlarmTargetDto } from '@shared/src/dtos/alarmdefinition/BaseAlarmTargetDto';

@Injectable()
export class AlarmDefinitionMaintenanceController extends BaseMaintenanceController<AlarmDefinitionListMaintenanceDto, AlarmDefinitionItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "alarmdefinition");
    }

    public getResourceRoot(): string {
        return "ALARMDEFINITION";
    }

    public buildUrl(uri: string): string {
        return "/system/securityandnotifications/alarmdefinition" + uri;
    }


    private baseUrlPrefix = "maintenance"
    public getLogs(alarmDefinitionId: number, filter: AlarmDefinitionLogFilterDto): Observable<Array<BaseAlarmTargetDto>> {
        return this.post(this.baseUrlPrefix + "/" + this.baseUrl + "/" + alarmDefinitionId + "/log", filter, "getLogs");
    }

    public getRouteLogs(routeId: number, filter: AlarmDefinitionLogFilterDto): Observable<Array<BaseAlarmTargetDto>> {
        return this.post(this.baseUrlPrefix + "/" + this.baseUrl + "/log/route/" + routeId, filter, "getRouteLogs");
    }

    public getShipmentLogs(shipmentId: number, filter: AlarmDefinitionLogFilterDto): Observable<Array<BaseAlarmTargetDto>> {
        return this.post(this.baseUrlPrefix + "/" + this.baseUrl + "/log/shipment/" + shipmentId, filter, "getShipmentLogs");
    }

    public getPlateLogs(plateId: number, filter: AlarmDefinitionLogFilterDto): Observable<Array<BaseAlarmTargetDto>> {
        return this.post(this.baseUrlPrefix + "/" + this.baseUrl + "/log/plate/" + plateId, filter, "getPlateLogs");
    }
}