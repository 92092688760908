
export class FilterDto {
	public filterId: string; //Suposadament per diferenciarlo dels altres. És Unic
	public filterOrder: number; //Ordre de la opció0. A més petit es mostra més a l'esquerra a l'estrip o a la part superior
	public filterActive: boolean;
	public incompatibilities: string[];

	//Aquestes no estan al back, no els hi fa falta
	public filterCaption: string; //el recurs que utilitzarem com anom
	public filterHelp: string; //explicació de la opció, es mostrar com a tooltip molt segurament
	public filterClass: string;
    public originalResource: boolean; //especifiquem si volem fer servir el mateix nom obtes del back

	public urlParam: string; // Indica el nom del parametre que es vol mostrar a la url si es el cas

	public static build(id: string, caption: string, help: string, order: number, active: boolean, incomptabibilities: string[]): FilterDto {
		let result = new FilterDto();
		result.filterId = id;
		result.filterCaption = caption;
		result.filterHelp = help;
		result.filterOrder = order;
		result.filterActive = active;
		result.incompatibilities = incomptabibilities;
        result.originalResource = false;
		return result;
	}
	static buildFromFilterDto(origin: FilterDto): FilterDto {
		let result = new FilterDto();
		result.filterActive = origin.filterActive;
		result.filterId = origin.filterId;
		result.filterOrder = origin.filterOrder
		result.incompatibilities = origin.incompatibilities;
        result.originalResource = origin.originalResource;
		result.filterCaption = origin.filterCaption;
		result.filterHelp = origin.filterHelp;
		result.urlParam = origin.urlParam;
		result.filterClass = "filterId-" + origin.filterId.toLowerCase().replace(".", "-") + " " + origin.filterClass;

		return result;
	}

	public toggle() {
		this.filterActive = !this.filterActive;
	}
	public setActive() {
		this.filterActive = true;
	}

	public setInactive() {
		this.filterActive = false;
	}

}
