import { Component, Inject, Input, OnInit } from '@angular/core';
import { MapService, MapPuntDto, MapMarcadorDto, MapRutaDto } from '../../services/map/map.service';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';

@Component({
  selector: 'shared-map-route',
  templateUrl: 'shared.map.route.component.html',
  styleUrls: ['shared.map.route.component.scss'],
})

/***
 * Es el componentº que encapsula la visualització d'una adreça
 * origen i destí son dos objectes respectivament del tipus {longitude:2.23432, latitude:2.34323}
 */
export class SharedMapRouteComponent implements OnInit {

  public useIonic: boolean = false;
  constructor(@Inject('HomeController') protected homeController: HomeController,
    protected routeController: RouteController) {
    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  ngOnInit(): void {
  }

  private _origen: MapPuntDto;
  @Input()
  public set origen(value: MapPuntDto) {
    if (this._origen === value)
      return;
    this._origen = value;
    this.calculate();
  }
  public get origen() {
    return this._origen;
  }

  private _desti: MapPuntDto;
  @Input()
  public set desti(value: MapPuntDto) {
    if (this._desti === value)
      return;
    this._desti = value;
    this.calculate();
  }
  public get desti() {
    return this._desti;
  }

  private _waypoints: Array<MapPuntDto>;
  @Input()
  public set waypoints(value: Array<MapPuntDto>) {
    if (this._waypoints === value)
      return;
    this._waypoints = value;
    this.calculate();
  }
  public get waypoints() {
    return this._waypoints;
  }

  private _routeId: number;
  @Input()
  public set routeId(value: number) {
    if (this._routeId === value)
      return;
    this._routeId = value;

    this.getWaypoints();
  }
  public get routeId() {
    return this._routeId;
  }

  @Input()
  public isRutesActives: boolean = false;
  
  private _marcadorsExtres: Array<MapMarcadorDto>;
  @Input()
  public set marcadorsExtres(value: Array<MapMarcadorDto>) {
    if (this._marcadorsExtres === value)
      return;
    this._marcadorsExtres = value;
    this.calculate();
  }
  public get marcadorsExtres() {
    return this._marcadorsExtres;
  }

  public longitude: number = MapService.DEFAULT_LONGITUDE;
  public latitude: number = MapService.DEFAULT_LATITUDE;
  public rutes: Array<MapRutaDto> = new Array<MapRutaDto>();
  public marcadors: Array<MapMarcadorDto> = new Array<MapMarcadorDto>();
  public zoom: number = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;

  getWaypoints() {
    if (this.routeId) {
      this.routeController.getRoute(this.routeId).subscribe(data => {
        if (data) {
          this.waypoints = new Array<MapPuntDto>();
          data.phases.forEach(p => {
            if (p.longitude && p.latitude)
              this.waypoints.push(MapPuntDto.builder(p.longitude, p.latitude, p));
          });
          if (this.waypoints && this.waypoints.length > 0)
            this.calculate();
        }
      });
    }
  }

  private calculate() {
    if (this.waypoints && this.waypoints.length > 0) {
      let originLon: number = this.origen ? this.origen.longitude : this.waypoints[0] ? this.waypoints[0].longitude : MapService.DEFAULT_LONGITUDE;
      let originLat: number = this.origen ? this.origen.longitude : this.waypoints[0] ? this.waypoints[0].latitude : MapService.DEFAULT_LONGITUDE;
      let destinationLon: number = this.desti ? this.desti.longitude : this.waypoints[this.waypoints.length - 1] ? this.waypoints[this.waypoints.length - 1].longitude : MapService.DEFAULT_LATITUDE;
      let destinationLat: number = this.desti ? this.desti.longitude : this.waypoints[this.waypoints.length - 1] ? this.waypoints[this.waypoints.length - 1].latitude : MapService.DEFAULT_LATITUDE;

      this.longitude = (originLon + destinationLon) / 2;
      this.latitude = (originLat + destinationLat) / 2;
      let tmarcadors = MapMarcadorDto.builderWaypointsPhase(this.waypoints);
      this.loadMarcadorsExtres(tmarcadors);
      this.marcadors = tmarcadors;
      this.rutes = new Array<MapRutaDto>();
      this.rutes.push(MapRutaDto.builderWaypoints(this.waypoints, true));
      this.zoom = 6;
    }
    else if (this._origen == null && this._desti == null) {
      this.zoom = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;
      this.longitude = MapService.DEFAULT_LONGITUDE;
      this.latitude = MapService.DEFAULT_LATITUDE;
      this.marcadors = new Array<MapMarcadorDto>();
      this.loadMarcadorsExtres(this.marcadors);
      this.rutes = new Array<MapRutaDto>();
      return;
    }
    else if (this.origen == null) {
      this.zoom = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;
      this.longitude = this.desti.longitude;
      this.latitude = this.desti.latitude;
      this.marcadors = new Array<MapMarcadorDto>();
      this.marcadors.push(MapMarcadorDto.builder(this.desti.longitude, this.desti.latitude).adreça());
      this.loadMarcadorsExtres(this.marcadors);
      this.rutes = new Array<MapRutaDto>();
    }
    else if (this.desti == null) {
      this.zoom = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;
      this.longitude = this.origen.longitude;
      this.latitude = this.origen.latitude;
      this.marcadors = new Array<MapMarcadorDto>();
      this.marcadors.push(MapMarcadorDto.builder(this.origen.longitude, this.origen.latitude).adreça());
      this.loadMarcadorsExtres(this.marcadors);
      this.rutes = new Array<MapRutaDto>();
    }
    else {
      this.longitude = (this.origen.longitude + this.desti.longitude) / 2;
      this.latitude = (this.origen.latitude + this.desti.latitude) / 2;
      this.rutes = new Array<MapRutaDto>();
      this.rutes.push(MapRutaDto.builder(this.origen, this.desti));
      this.marcadors = new Array<MapMarcadorDto>();
      this.loadMarcadorsExtres(this.marcadors);
      this.zoom = 6;
    }
    
  }

  public loadMarcadorsExtres(tmarcadors: Array<MapMarcadorDto>) {
    if(this.marcadorsExtres != null && this.marcadorsExtres.length > 0){
      this.marcadorsExtres.forEach(element => {
        tmarcadors.push(element);
      });
    }
  }
}
