<form class="form" [formGroup]="paymentmethodFormGroup" *ngIf="paymentMethod">
    <div *ngIf="paymentMethod.hasToken && paymentMethod.isValid">
        <div class="card">
            <div class="numero-tarja">XXXX XXXX XXXX {{ paymentMethod.cardLast4 }}</div>
            <div class="caducitat">{{ paymentMethod.cardExpMonth }}/{{ paymentMethod.cardExpYear }}</div>
        </div>
        <button *ngIf="!readonly" class="warning save" (click)="newPaymentMethod()">
            {{'TENANTINFO.NEWPAYMENTMETHOD.BUTTON' | translate}}
        </button>
    </div>

    <div *ngIf="!paymentMethod.hasToken || !paymentMethod.isValid">
        <div class="card">
            <input-component class="numero-tarja-edit" type="number" [(ngModel)]="numero_tarjeta"
                placeholder="{{'REGISTERCOMPONENT.CARD_NUMBER.LABEL' | translate}}" formControlName="cardNumber"
                autocomplete="on" [readonly]="readonly"></input-component>

            <input-component class="caducitat-month" type="number" [(ngModel)]="exp_month"
                placeholder="{{'REGISTERCOMPONENT.EXPIRATION_MONTH.LABEL' | translate}}" formControlName="expMonth"
                autocomplete="on" [readonly]="readonly"></input-component>
            <input-component class="caducitat-year" type="number" [(ngModel)]="exp_year"
                placeholder="{{'REGISTERCOMPONENT.EXPIRATION_YEAR.LABEL' | translate}}" formControlName="expYear"
                autocomplete="on" [readonly]="readonly"></input-component>
            <input-component class="cvc" type="number" [(ngModel)]="cvc"
                placeholder="{{'REGISTERCOMPONENT.CVC.LABEL' | translate}}" formControlName="cvc" autocomplete="on"
                [readonly]="readonly"></input-component>
        </div>

        <button *ngIf="!readonly" class="warning save" (click)="savePaymentMethod()" [disabled]="!isValid">
            {{'TENANTINFO.SAVEBUTTON' | translate}}
        </button>
    </div>

</form>