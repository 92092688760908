
/*
Tipus de pregunta que es pot fer en un formulari
*/
export enum QuestionTypes {
    CMR="CMR",
    Delivery="Delivery",
    SignatureForm="SignatureForm",
    SignatureCMR="SignatureCMR",
    SignatureDelivery="SignatureDelivery",
    Boolean="Boolean",
    Integer="Integer",
    Double="Double",
    TriState="TriState",
    Image="Image",
    String="String"
}