<form class="form" [formGroup]="tenantInfoFormGroup" *ngIf="tenantInfo">
    <input-component class="col-sm-12" [(ngModel)]="tenantInfo.organizationTrademark"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_TRADEMARK.LABEL' | translate}}" formControlName="company_trademark"
        autocomplete="on" [readonly]="readonly"></input-component>
    <input-component class="col-sm-12" [(ngModel)]="tenantInfo.organizationName"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_DESCRIPTION.LABEL' | translate}}"
        formControlName="company_description" autocomplete="on" [readonly]="readonly"></input-component>
    <input-component class="col-sm-12" [(ngModel)]="tenantInfo.nif"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_VAT_NUMBER.LABEL' | translate}}" formControlName="company_vat_number"
        autocomplete="on" [readonly]="readonly"></input-component>
    <input-component class="col-sm-12" [(ngModel)]="tenantInfo.address" autocomplete="on"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_ADDRESS.LABEL' | translate}}" name="address"
        formControlName="company_address" [readonly]="readonly">
    </input-component>
    <div *ngIf="!useIonic" class="col-x">
        <enum-selector class="col-prefix-phone" [(ngModel)]="prefixModel" [readonly]="readonly"
            [enumvalues]="enumPrefixes" placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE_PREFIX.LABEL' | translate}}"
            [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="company_phone_prefix">
        </enum-selector>
        <input-component class="col col-xprefix" [(ngModel)]="tenantInfo.phone"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE.LABEL' | translate}}" formControlName="company_phone"
            autocomplete="on" [readonly]="readonly"></input-component>
    </div>
    <div class="col-x">
        <input-component class="col-sm-12" [(ngModel)]="tenantInfo.contactName"
            placeholder="{{'REGISTERCOMPONENT.CONTACTNAME.LABEL' | translate}}" formControlName="company_contact_name" [readonly]="readonly">
        </input-component>
    </div>
    <div *ngIf="useIonic" class="col-x">
        <enum-selector [ngClass]="{'phone-prefix-ios' : isIos}" [(ngModel)]="prefixModel" [readonly]="readonly"
            [enumvalues]="enumPrefixes" placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE_PREFIX.LABEL' | translate}}"
            [translategroup]="translategroup" [translateselected]="false" [showclosebutton]="false"
            formControlName="company_phone_prefix">
        </enum-selector>
        <input-component class="col col-xprefix" [(ngModel)]="tenantInfo.phone"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_PHONE.LABEL' | translate}}" formControlName="company_phone"
            autocomplete="on" [readonly]="readonly"></input-component>
    </div>
    <enum-selector class="col-x enumcompanytypes" [(ngModel)]="tenantInfo.hardmanActivityType" [readonly]="readonly"
        [enumvalues]="enumCompanyActivityTypes"
        placeholder="{{'REGISTERCOMPONENT.COMPANY_ACTIVITY_TYPE.LABEL' | translate}}"
        [translategroup]="translategroupType" [translateselected]="false" [showclosebutton]="false"
        formControlName="company_activity_type">
    </enum-selector>
    <div class="part-interna">
        <input-component class="col-sm-12" [(ngModel)]="tenantInfo.htmlMailSignature" autocomplete="on"
            placeholder="{{'REGISTERCOMPONENT.COMPANY_HTMLMAILSIGNATURE.LABEL' | translate}}" name="html_mail_signature"
            formControlName="company_html_mail_signature" [readonly]="readonly">
        </input-component>
    </div>
</form>