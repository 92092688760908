import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { AddressCalendarDayDto } from '@shared/src/dtos/address/calendar/AddressCalendarDayDto';
import { HDate } from '@shared/src/datatypes/HDate';
import { HString } from '@shared/src/datatypes/HString';
import { Subscription } from 'rxjs';

@Component({
  selector: 'addresscalendarday',
  templateUrl: "./address.calendar.day.html",
  styleUrls: ["./address.calendar.day.scss"]
})
export class AddressCalendarDay implements OnInit, OnDestroy {

  private subscription: Subscription;

  @Output() onDateClick: EventEmitter<AddressCalendarDayDto> = new EventEmitter();

  constructor(private addressController: AddressController) {

    if (this.addressController && this.addressController.dayClickFinish$) {
      this.subscription = this.addressController.dayClickFinish$.subscribe(sdateA => {
        if (this.value != null) {
          let sdateB = HDate.getDateString(this.value.onDate);
          if (HString.equals(sdateA, sdateB)) {
            this.actualitzant = false;
          }
        }
      });
    }

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  private _value: AddressCalendarDayDto;
  @Input() set value(value: AddressCalendarDayDto) {
    if (this._value === value)
      return;
    this._value = value;
  }
  get value(): AddressCalendarDayDto {
    return this._value;
  }

  public actualitzant: boolean = false;
  public dayclick() {
    if (this.actualitzant)
      return;
    this.actualitzant = true;
    this.onDateClick.next(this.value);
  }

  /** 
   * No es pot assignar en una variable fixa ja que en el refresc del dia 
   * al realitar una acció no s'actualitza
   */
  public getShwoStart() {
    if (this.value.setting != null)
      return this.value.setting.modifiedQuickAddress;
    return false;
  }

  /** 
  * No es pot assignar en una variable fixa ja que en el refresc del dia 
  * al realitar una acció no s'actualitza
  */
  public getDayStyle() {

    if (this.value == null)
      return {};

    if (this.value.backgroundColor != null)
      return { background: this.value.backgroundColor.web };

    if (this.value.setting != null && this.value.setting.intervals != null && this.value.setting.intervals.length > 0)
      return { background: "#f0ecec" };

    return {};
  }
}


