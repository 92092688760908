import { Component, Inject, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { HColor } from '@shared/src/datatypes/HColor';
import { HHourIntervals } from '@shared/src/datatypes/HHourIntervals';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { TruckingRouteDto } from '@shared/src/dtos/trucking/TruckingRouteDto';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';
import { TruckingShipmentPackageDto } from '@shared/src/dtos/trucking/TruckingShipmentPackageDto';
import { TruckingController } from '@shared/src/controllers/trucking/trucking.controller';

@Component({
    selector: 'shared-trucking-route-card',
    templateUrl: './shared.trucking.route.card.html',
    styleUrls: ['./shared.trucking.route.card.scss']
})
export class SharedTruckingRouteCard implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController,
    public truckingController: TruckingController) {
    
    }

    private _route: TruckingRouteDto;
    @Input()
    public set route(value: TruckingRouteDto) {
        if (this._route === value)
            return;
        this._route = value;

    }
    public get route(): TruckingRouteDto {
        return this._route
    }

    ngOnInit(): void { }

    getTagColor(tag: string): string {
        return HColor.getColorFromStringTable(tag, this.homeController);
    }

    dateIsNull(d: HDateHour): Boolean {
        return HDateHour.isNullOrNullValue(d);
    }

    datesAreEqual(a: HDateHour, b: HDateHour): boolean {
        return HDateHour.equals(a, b);
    }

    intervalIsNull(i: HHourIntervals): boolean {
        return HHourIntervals.isNullOrNullValue(i);
    }


    //////////////////////////////// DRAG & DROP ///////////////////////////////////////////
	
	dragover(event: DragEvent) {
		event.preventDefault();
		if (DragDropService.isDraggedObjectHardmanType(event)) {
			event.dataTransfer.dropEffect = 'copy';
		}else{
            event.dataTransfer.dropEffect = 'none';
		}
	}

	dropAtZone(event: DragEvent) {
		if (DragDropService.isDraggedObjectHardmanType(event)) {
			let draggedobject: any = DragDropService.extractDraggedObjectHardmanType(event);
			if (draggedobject != null) {
				this.onPackageAdd(draggedobject);
				return;
			}
		}
	
	}

    
	@Output() refresh = new EventEmitter<TruckingRouteDto>();
    public onPackageAdd(spackage: TruckingShipmentPackageDto) {
		if (this.route == null || spackage == null)
			return;

        this.truckingController.addPackageInRoute(this.route.routeId, spackage.packageId).subscribe(data => {
			if(data){
                this.route.totalPackages++;
                this.refresh.next(this.route);
                this.truckingController.shipmentPackageChanged$.next(spackage);
            }
		});
        
	}
}
