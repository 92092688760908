import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PackageDto } from '@shared/src/dtos/shipment/delivery/PackageDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs-compat';
import { BaseController } from '../../base.controller';
import { SecurityController } from '../../security/security.controller';


@Injectable({
    providedIn: 'root',
})
export class PackageController extends BaseController {
    private base = '/package'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getAllByRoute(routeId: number): Observable<Array<PackageDto>> {
        return this.get(this.base + "/byrouteid/" + routeId);
    }    
    
    public getAllByShipment(shipmentId: number): Observable<Array<PackageDto>> {
        return this.get(this.base + "/byshipmentid/" + shipmentId);
    }    

    public modifyPackage(pack: PackageDto): Observable<PackageDto> {
        return this.putBusy(this.base + "/" + pack.packageId, pack, "modifyPackage");
    }

    public createPackage(pack: PackageDto): Observable<PackageDto> {
        return this.postBusy(this.base, pack, "createPackage");
    }
}
