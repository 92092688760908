import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, Output, Input, AfterViewInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HImage } from '@shared/src/datatypes/HImage';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { FileTypes } from '@shared/src/enums/FileTypes';
import SignaturePad from 'signature_pad';



@Component({
  selector: 'shared-signature-component',
  templateUrl: './shared.signature.component.html',
  styleUrls: ['./shared.signature.component.scss'],
})
export class SharedSignatureComponent implements OnInit, OnDestroy, AfterViewInit {


  constructor(protected activeroute: ActivatedRoute,
    @Inject('SecurityController') public securityController: SecurityController) {

    this.securityController.getStoredUserDto().subscribe(data => {
      if (data != null)
        this.readonly = data.isReadOnly;
    })
  }

  private _readonly: boolean = false;
  @Input() public set readonly(value: boolean) {
    if (this._readonly === value)
      return;

    this._readonly = value;
    if (this._readonly && this.signaturePad != null)
      this.signaturePad.off();

  }
  public get readonly(): boolean {
    return this._readonly;
  }

  @ViewChild('canvas') private signaturePadElement: ElementRef;

  private _fileSignatureBytes: any[];
  @Input() public set fileSignatureBytes(value: any[]) {
    if (this._fileSignatureBytes === value)
      return;

    this._fileSignatureBytes = value;
    if (value == null)
      this.onClearSignature();
    else
      this.prepararImatge();

  }
  public get fileSignatureBytes(): any[] {
    return this._fileSignatureBytes;
  }

  private _fileSignatureType: FileTypes = FileTypes.png;
  @Input() public set fileSignatureType(value: FileTypes) {
    if (this._fileSignatureType === value)
      return;

    this._fileSignatureType = value;

  }
  public get fileSignatureType(): FileTypes {
    return this._fileSignatureType;
  }

  public drawSignature() {

    if (this.signaturePadElement == null || this.fileSignatureBytes == null || this.fileSignatureBytes.length == 0 || this.fileSignatureType == null || this.fileSignatureType == FileTypes.None) {
      this.onClearSignature();
      return;
    }

    if (this.signaturePad != null)
      this.signaturePad.on();

    let base64 = "";

    let binary = '';
    let bytes = new Uint8Array(this.fileSignatureBytes);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    base64 = window.btoa(binary);

    let valueBase64: string = "";

    if (base64 != "") {
      valueBase64 = 'data:image/' + this.fileSignatureType + ';base64,' + base64;
    } else {
      valueBase64 = 'data:image/' + this.fileSignatureType + ';base64,' + this.fileSignatureBytes;
    }

    var ctx = this.signaturePadElement.nativeElement.getContext("2d");

    var image = new Image();
    image.onload = function () {
      ctx.drawImage(image, 0, 0);
    };
    image.src = valueBase64;
  }

  @Output() clearSignature: EventEmitter<boolean> = new EventEmitter();
  public signaturePad: SignaturePad;
  onClearSignature() {
    if (this.signaturePad == null) {
      this.initSignature();
    } else
      this.signaturePad.clear();

    if (this.signaturePad != null) {
      this.signaturePad.on();
    }
    this.clearSignature.next(true);
  }

  endDrawing(event: Event) {

    if (this.signaturePad == null) {
      return;
    }

    let signature = this.signaturePad.toDataURL("image/png");
    if (signature == null) {
      this.fileSignatureBytes = null;
      this.fileSignatureType = null;
    } else {

      let base64DataSignature = signature.split(',')[1];
      this.fileSignatureBytes = FileUpDownLoadDto.getByteArrayFromString(base64DataSignature);
      this.fileSignatureType = FileTypes.png;
    }

    this.endDrawSignature.next({ signbytes: this.fileSignatureBytes, bytestype: this.fileSignatureType });

  }

  @Output() endDrawSignature: EventEmitter<any> = new EventEmitter();

  initSignature() {
    if (this.signaturePadElement != null && this.signaturePadElement.nativeElement != null) {
      this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);
      this.drawSignature();
      if (this.signaturePad != null && this._readonly)
        this.signaturePad.off();
    }
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.initSignature();
  }


  ngOnDestroy() {
  }

  public imageSrc: string;
  prepararImatge() {
    this.imageSrc = HImage.toPng(this.fileSignatureBytes)
  }
}
