import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerHealthCertifierSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.HEALTHCERTIFIER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/healthcertifier/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}