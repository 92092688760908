import { Component, OnInit, Inject, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HomeController } from '../../../controllers/home/home.controller';
import { enumValidator } from '../../core/form/validations/AutoCompleteValidator';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { HardmanActivityTypes } from '@shared/src/enums/HardmanActivityTypes';

/***
 * Aquesta directiva no te sentit. No puc jugar a pingpong. A on haig d'anar, en quin estat estic?
 */

@Component({
  selector: 'register-tenant-info',
  templateUrl: './register-tenant-info.component.html',
  styleUrls: ['./register-tenant-info.component.scss']
})
export class RegisterCompanyInfoPageComponent implements OnInit {

  constructor(@Inject('HomeController') protected homecontroller: HomeController) {}

  ngOnInit() { }

  enumCompanyActivityTypes = HardmanActivityTypes;

  public registerFormGroup: FormGroup;
  
  public canRegister: boolean = false;

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  @Input()
  public registerData: UserRegisterWizardDto = new UserRegisterWizardDto;

  @Input() 
  public pendigResponse: boolean;

  next() {
    if(this.pendigResponse)
      return;
    if (this.registerData.organization.hardmanActivityType && typeof this.registerData.organization.hardmanActivityType != 'string') {
      this.registerData.organization.hardmanActivityType = this.registerData.organization.hardmanActivityType['value'];
    }
    this.onClickNext.next(this.registerData);
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }
}
