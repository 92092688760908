import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HColor } from '@shared/src/datatypes/HColor';
import { HTags } from '@shared/src/datatypes/HTags';
import { AssignedSlotDto } from '@shared/src/dtos/address/slots/AssignedSlotDto';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { AlertService } from '@shared/src/public-api';
import { Router } from '@angular/router';

@Component({
    selector: 'shared-active-slots-item-header',
    templateUrl: './shared.active.slots.item.header.html',
    styleUrls: ['./shared.active.slots.item.header.scss']
})
export class SharedActiveSlotsItemHeader implements OnInit {

	public useIonic: boolean = false;
    constructor(@Inject('HomeController') protected homeController: HomeController,
        @Inject('AlertService') public alertService: AlertService,
        protected router: Router) {

        if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

    }

    private _route: RouteCardItemDto;
    @Input()
    set route(value: RouteCardItemDto) {
        if (this._route === value)
            return;
        this._route = value;
        this.obtainValues();
    }
    get route(): RouteCardItemDto {
        return this._route;
    }

    public manualTags: HTags;
    public userTags: HTags;
    public internalTags: HTags;
    public privateTags: HTags;
    public stops: HTags;

    showAssignedSlots: boolean;
    firstAssignedSlotDate: String;
    firstAssignedSlotInterval: string;
    tooltipAssignedSlots: string;
    private obtainValues() {
        if (this._route != null) {
            if (this._route.assignedSlots != null && this._route.assignedSlots.length > 0) {
                this.showAssignedSlots = true;
                this.firstAssignedSlotDate = AssignedSlotDto.toDateString(this._route.assignedSlots[0]);
                this.firstAssignedSlotInterval = AssignedSlotDto.toHourIntervalsString(this._route.assignedSlots[0]);
                this.tooltipAssignedSlots = AssignedSlotDto.toToolTipTextString(this._route.assignedSlots);
            } else {
                this.showAssignedSlots = false;
                this.firstAssignedSlotDate = null;
                this.firstAssignedSlotInterval = "";
                this.tooltipAssignedSlots = "";
            }

            this.manualTags = !HTags.isEmpty(this._route.manualTags) ? this._route.manualTags : null;
            this.userTags = !HTags.isEmpty(this._route.userTags) ? this._route.userTags : null;
            this.internalTags = !HTags.isEmpty(this._route.internalTags) ? this._route.internalTags : null;
            this.privateTags = !HTags.isEmpty(this._route.privateTags) ? this._route.privateTags : null;
            this.manualTags = !HTags.isEmpty(this._route.stops) ? this._route.stops : null;
        }
        else {
            this.showAssignedSlots = false;
            this.firstAssignedSlotDate = null;
            this.firstAssignedSlotInterval = "";
            this.tooltipAssignedSlots = "";
        }
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    public haveTags(tags: HTags): boolean {
        if (HTags.isEmpty(tags))
            return false;
        return true;
    }

    public goToActiveRoute() {
        if (this.route ){
            if(!this.useIonic)
                window.open(this.homeController.createRouteIdLinkToActiveRoutes(this.route.routeId), '_blank');
            else
                this.router.navigateByUrl(this.homeController.createRouteIdLinkToActiveRoutesMobile(this.route.routeId));    
        }
    }
    ngOnInit(): void {
    }

    copy(value: string) {
        if (value != null && value != "")
            this.alertService.copyToClipboard(value).subscribe(e => { });
    }
    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
