import { HHour } from './HHour';
import { HHourInterval } from './HHourInterval';
import { HTime } from './HTime';

export class HHourIntervals {

    private _Intervals: HHourInterval[] = new Array();

    constructor() {

    }

    public static getIntervals(value: HHourIntervals): Array<HHourInterval> {
        return value._Intervals;
    }

    public static totalOf(value: HHourIntervals): HTime {
        if (!value)
            return HTime.NULLVALUE();

        let result: HTime = HTime.NULLVALUE();

        for (let interval of value._Intervals) {
            result = result.addTime(HHourInterval.totalOf(interval));
        }

        return result;
    }

    public static empty(): HHourIntervals {
        let hintervals = HHourIntervals.build(new HHour(0, 0), new HHour(0, 0));
        return hintervals;
    }

    public static build(start: HHour, end: HHour): HHourIntervals {
        let result = new HHourIntervals();
        result.addHours(start, end);
        return result;
    }

    public static buildByDto(dto: HHourIntervals): HHourIntervals {
        let result = new HHourIntervals();
        if (!dto)
            return result;
        for (let i of dto._Intervals) {
            result.addInterval(HHourInterval.buildByDto(i));
        }

        return result;
    }

    public static buildstr(value: string): HHourIntervals {
        let result = new HHourIntervals();

        if (!this.isValidFormat(value))
            return result;

        let intervals: string[] = value.split(",");
        intervals.forEach(element => {
            let interval = HHourInterval.buildstr(element);
            if (!HHourInterval.isNullOrNullInterval(interval))
                result.addInterval(interval);
        });

        return result;
    }


    public static compact(value: HHourIntervals): HHourIntervals {
        let result = new HHourIntervals();
        for (let i of value._Intervals.sort(HHourInterval.compare))
            result.addInterval(i);

        return result;
    }

    public static clone(value: HHourIntervals): HHourIntervals {
        if (HHourIntervals.isNullOrNullValue(value))
            return new HHourIntervals();
        let result = new HHourIntervals();
        for (let i of value._Intervals.sort(HHourInterval.compare)) {
            result._Intervals.push(HHourInterval.clone(i));
        }
        return result;
    }

    addIntervals(value: HHourIntervals): HHourIntervals {
        for (let i of value._Intervals.sort(HHourInterval.compare)) {
            let trobat = false;
            for (let j of this._Intervals) {
                if (HHour.isEqualsThan(HHourInterval.getEnds(j), HHourInterval.getStarts(i))) {
                    HHourInterval.setEnd(j, HHourInterval.getEnds(i));
                    trobat = true;
                    break;
                }
            }
            if (!trobat)
                this.addInterval(i);
        }
        return this;
    }
    addInterval(value: HHourInterval): HHourIntervals {
        let trobat = false;
        for (let j of this._Intervals) {
            if (HHour.isEqualsThan(HHourInterval.getEnds(j), HHourInterval.getStarts(value))) {
                HHourInterval.setEnd(j, HHourInterval.getEnds(value));
                trobat = true;
                break;
            }
        }
        if (!trobat)
            this._Intervals.push(value);
        return this;
    }
    addHours(start: HHour, ends: HHour): HHourIntervals {
        let temp: HHourInterval = HHourInterval.build(start, ends);
        this.addInterval(temp);
        return this;
    }

    starts(): HHour {
        return HHourIntervals.getStarts(this);
    }

    public static getStarts(value: HHourIntervals): HHour {
        let result: HHour = null;
        for (let i of value._Intervals) {
            if (!result || HHour.isMinorThan(HHourInterval.getStarts(i), result))
                result = HHourInterval.getStarts(i);
        }
        if (!result)
            result = new HHour(0, 0);
        return result;
    }

    ends() {
        return HHourIntervals.getEnds(this);

    }
    public static getEnds(value: HHourIntervals): HHour {
        let result: HHour = null;
        for (let i of value._Intervals) {
            if (!result || HHour.isGreaterThan(HHourInterval.getEnds(i), result))
                result = HHourInterval.getEnds(i);
        }
        if (!result)
            result = new HHour(0, 0);
        return result;
    }
    /**
     * Retorna true qiuan el timeintervals representa un espai de temps 0
     */
    public static isNull(value: HHourIntervals): boolean {
        if (!value)
            return true;

        if (!value._Intervals || value._Intervals.length == 0)
            return true;

        for (let i of value._Intervals)
            if (!HHourInterval.isNull(i))
                return false;


        return true;
    }

    /**
     * Retorna true si el timeintervals es null o representa un intervals null
     * @param value 
     */
    public static isNullOrNullValue(value: HHourIntervals): boolean {

        return HHourIntervals.isNull(value);
    }

    /**
     * Reprsentació a pantalla d'un time intervals com a texte
     * @param value 
     */
    public static toScreenString(value: HHourIntervals): string {
        if (this.isNullOrNullValue(value))
            return "---";
        let result: string = "";

        for (let i of value._Intervals) {
            if (!HHourInterval.isNull(i)) { }
            if (result != "")
                result += ", ";
            result += HHourInterval.toScreenString(i);
        }
        return result;
    }

    /**
 * Reprsentació a pantalla d'un time intervals com a texte
 * @param value 
 */
    public static toHTMLScreenString(value: HHourIntervals): string {
        if (this.isNullOrNullValue(value))
            return "---";
        let result: string = "";

        for (let i of value._Intervals) {
            if (!HHourInterval.isNull(i)) { }
            if (result != "")
                result += "<br>";

            result += HHourInterval.toScreenString(i);
        }
        return result;
    }

    /**
     * Retorna true quan els dos timeintervals coincideixen en el temps. de 9:00-10:00 i de 10:00 a 11:00 tot i que coincideixen a les 10, entenem que no ho fan ja que un surt a les 10 i l'altre entra a les 10 que només és un punt i per tant no hi ha coincidència.
     * @param valuea 
     * @param valueb 
     */
    public static intersectsWith(valuea: HHourIntervals, valueb: HHourIntervals): boolean {
        if (HHourIntervals.isNullOrNullValue(valuea) && HHourIntervals.isNullOrNullValue(valuea))
            return false;

        for (let i of valuea._Intervals)
            if (HHourIntervals.intersectsWithInterval(valueb, i))
                return true;

        return false;
    }


    /**
 * Retorna true quan els dos timeintervals coincideixen en el temps. de 9:00-10:00 i de 10:00 a 11:00 tot i que coincideixen a les 10, entenem que no ho fan ja que un surt a les 10 i l'altre entra a les 10 que només és un punt i per tant no hi ha coincidència.
 * @param valuea 
 * @param valueb 
 */
    public static intersectsWithInterval(valuea: HHourIntervals, valueb: HHourInterval): boolean {
        if (HHourIntervals.isNullOrNullValue(valuea) && HHourIntervals.isNullOrNullValue(valuea))
            return false;

        for (let i of valuea._Intervals)
            if (HHourInterval.intersectsWith(i, valueb))
                return true;

        return false;
    }


    /**
     * Retorna un Timeintervals mogut, amb les hores segons l0inici original vs tostarthour proposat
     * @param value 
     * @param tostarthour 
     */
    public static move(value: HHourIntervals, tostarthour: HHour): HHourIntervals {
        let temp = HHourIntervals.buildByDto(value);
        let diffhours = tostarthour.h - temp.starts().h;
        let diffminutes = tostarthour.m - temp.starts().m;

        let result = HHourIntervals.build(temp.starts().addHours(diffhours).addMinutes(diffminutes), temp.ends().addHours(diffhours).addMinutes(diffminutes));
        return result;
    }

    public static toSyntaxString(value: HHourIntervals): string {
        if (HHourIntervals.isNullOrNullValue(value))
            return "0000@0000";
        let result = "";
        value._Intervals.forEach(interval => {
            result += HHour.toSyntaxString(interval.getStart()) + "@" + HHour.toSyntaxString(interval.getEnd());
        })
        return result;
    }

    public static SET24H(): HHourIntervals {
        return HHourIntervals.build(new HHour(0, 0), new HHour(24, 0));
    }

    public static isValidFormat(value: String): boolean {
        if (!value)
            return false;

        let intervals: string[] = value.split(",");
        if (!intervals || intervals.length < 1)
            return false;

        let tempintervals = new HHourIntervals();

        let okintervals: boolean = true;
        intervals.forEach(element => {
            if (okintervals) {
                if (!HHourInterval.isValidFormat(element))
                    okintervals = false;
                else {
                    //Comprovem si s'interseccionen entre ells
                    let tempinterval = HHourInterval.buildstr(element);
                    if (!HHourInterval.isNullOrNullInterval(tempinterval)) {
                        if (this.intersectsWithInterval(tempintervals, tempinterval))
                            okintervals = false;
                        else
                            tempintervals.addInterval(tempinterval);
                    }
                }
            }
        });

        return okintervals;
    }

    public static isEqualsThan(valuea: HHourIntervals, valueb: HHourIntervals): boolean {
        if (HHourIntervals.isNullOrNullValue(valuea) || HHourIntervals.isNullOrNullValue(valuea))
            return false;

        if (valuea._Intervals.length != valueb._Intervals.length)
            return false;

        for (let i = 0; i < valuea._Intervals.length; i++)
            if (!HHourInterval.isEqualsThan(valuea._Intervals[i], valueb._Intervals[i]))
                return false;

        return false;
    }

    /**
     * Reprsentació a directiva d'un time intervals a texte
     * @param value 
     */
    public static toHourIntervalsString(value: HHourIntervals): string {

        if (this.isNullOrNullValue(value))
            return "00:00-00:00";

        return this.toHourIntervalArrayString(value._Intervals);
    }
    /**
     * Reprsentació a directiva d'un time intervals a texte
     * @param value 
     */
    public static toHourIntervalArrayString(intervals: HHourInterval[]): string {
        if (intervals == null || intervals.length == 0)
            return "00:00-00:00";

        let result: string = "";

        for (let i of intervals) {
            if (!HHourInterval.isNull(i)) { }
            if (result != "")
                result += ",";
            result += HHourInterval.toHourIntervalString(i);
        }
        return result;
    }


    /***
     * Indica si l'interval a comença abans o comença igual i acaba abans
     */
    public static isMinor(a: HHourIntervals, b: HHourIntervals): boolean {
        if (a == null || b == null)
            return false;

        if (HHour.isMinorThan(HHourIntervals.getStarts(a), HHourIntervals.getStarts(b)))
            return true;
        if (HHour.isGreaterThan(HHourIntervals.getStarts(a), HHourIntervals.getStarts(b)))
            return false;

        if (HHour.isMinorThan(HHourIntervals.getEnds(a), HHourIntervals.getEnds(b)))
            return true;
        if (HHour.isGreaterThan(HHourIntervals.getEnds(a), HHourIntervals.getEnds(b)))
            return false;

        return true;
    }

    /***
 * Indica si l'interval a comença abans o comença igual i acaba abans
 */
    public static isGreater(a: HHourIntervals, b: HHourIntervals): boolean {
        if (a == null || b == null)
            return false;

        if (HHour.isGreaterThan(HHourIntervals.getStarts(a), HHourIntervals.getStarts(b)))
            return true;
        if (HHour.isMinorThan(HHourIntervals.getStarts(a), HHourIntervals.getStarts(b)))
            return false;

        if (HHour.isGreaterThan(HHourIntervals.getEnds(a), HHourIntervals.getEnds(b)))
            return true;
        if (HHour.isMinorThan(HHourIntervals.getEnds(a), HHourIntervals.getEnds(b)))
            return false;

        return true;
    }
}