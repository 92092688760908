import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { TenantResourceMaintenanceController } from '@shared/src/controllers/maintenance/tenant/TenantResourceMaintenanceController';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/public-api';

/**
 * Aquest servei s'encarrega de traduir els recursos utilitzant els dels fitxers json i els del TenantResource.
 * Aquests últims són els prioritaris en cas d'haver-hi una definició a ambdós llistes.
 */
@Injectable
    ({
        providedIn: 'root',
    })
export class HTranslateService {
    constructor(private translateService: TranslateService,
        private tenantResourceController: TenantResourceMaintenanceController,
        private openRouteController: OpenRouteController,
        @Inject("SecurityController") private securityController: SecurityController) {
    }

    public onTenantResourcesLoaded$: EventEmitter<boolean> = new EventEmitter();

    public instant(key: string, variables?: any): string {
        if (key === null || key == "")
            return "";

        // if (HomeController.LOADING_TENANT_RESOURCES && !HomeController.LOADED_TENANT_RESOURCES)
        //     this.onTenantResourcesLoaded$.subscribe(() => {
        //         return this.translateService.instant(key);
        //     })

        // // this.translateService.onTranslationChange.subscribe((e: TranslationChangeEvent) => {
        // //     console.log("onTranslationChange ens avisa per traduir " + key);
        // //     return this.translateService.instant(key);
        // // });

        // // Si encara no hem carregat els recursos
        // if (!HomeController.LOADING_TENANT_RESOURCES && this.securityController.getCurrentUserId()) {
        //     // Marquem com a carregats els recursos des d'un bon inici per evitar que tots demanin a l'hora els recursos
        //     HomeController.LOADING_TENANT_RESOURCES = true;

        //     // Anem a buscar els recursos custom
        //     this.tenantResourceController.getAll().subscribe(data => {
        //         // Els afegim d'un en un perquè el merge massiu no funciona com esperava
        //         HomeController.LOADED_TENANT_RESOURCES = true;

        //         var loop = new Promise(resolve => {
        //             data.forEach((item, index) => {
        //                 if (this.translateService.instant(item.tenantResourceKey) != item.tenantResourceKey)
        //                     this.translateService.set(item.tenantResourceKey, item.resource, this.translateService.currentLang);

        //                 if (index == (data.length - 1)) {
        //                     resolve(true);
        //                 }
        //             })
        //         });

        //         // Retornem el valor de la llista
        //         loop.then(() => {
        //             this.onTenantResourcesLoaded$.emit(true);
        //             return this.translateService.instant(key);
        //         });
        //     });
        // } else if (HomeController.LOADED_TENANT_RESOURCES || !(!HomeController.LOADED_TENANT_RESOURCES && !this.securityController.getCurrentUserId())) {
        //     // Si ja els hem carregat, mostrem els que tenim guardats
        //     return this.translateService.instant(key, variables);
        // }

        return this.translateService.instant(key, variables);
    }

}