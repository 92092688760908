import { Component, Inject, NgZone, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { AlertService, HomeController, HString, HTranslateService, SecurityController, SocketController } from '@shared/src/public-api';
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { TotemController } from '@shared/src/controllers/totem/totem.controller';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TotemSteps } from '@shared/src/controllers/totem/TotemSteps';
import { TotemRouteDto } from '@shared/src/controllers/totem/TotemRouteDto';

@Component({
    selector: 'shared-totem-enter-dataconfirmation',
    templateUrl: './shared.totem.enter.dataconfirmation.component.html',
    styleUrls: ['./shared.totem.enter.dataconfirmation.component.scss']
})
export class SharedTotemEnterDataConfirmationComponent implements OnInit, OnDestroy {

    constructor(protected inactivityService: SharedInactivityService,
        protected totemService: TotemController,
        protected translateService: HTranslateService) {

    }

    public totemSteps = TotemSteps;

    ngOnInit(): void {
    }
    ngOnDestroy() {
    }


    @Input()
    public totem: TotemDto;
    @Output() totemChange = new EventEmitter<TotemDto>();

    @Output() onFinish: EventEmitter<any> = new EventEmitter<any>();


    public getTitle(): string {
        return "TOTEMPAGE.DATACONFIRMATION." + HString.toUpperCase(this.totem.step) + ".TITLE";
    }
    public getHelp(): string {
        return "TOTEMPAGE.DATACONFIRMATION." + HString.toUpperCase(this.totem.step) + ".HELP";
    }
    public getPrompt(): string {
        return "TOTEMPAGE.DATACONFIRMATION." + HString.toUpperCase(this.totem.step) + ".PROMPT";
    }
    public buttonOk() {
        this.onFinish.next();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


