<div class="card" [ngClass]="generalClass">
    <ng-container *ngIf="booking && booking.bookingId">
        <div class="conflictive">
            <i class="fa fa-exclamation-triangle"></i>
        </div>
        <span class="title">{{ booking.shipName }}</span>
        <span class="subtitle">{{ booking.bookingNumber }}</span>
        <shared-tags-strip-component *ngIf="booking.userTags && !booking.userTags.isEmpty()"
            class="tagsbottom tags no-margin-between" [tags]="booking.userTags">
        </shared-tags-strip-component>
        <div class="dates">
            <span>{{ 'BOOKINGPAGE.DEPOSIT.LABEL' | translate }}: {{ booking.depositStart | datahoradetalldiasetmana }} -
                {{ booking.depositEnd | datahoradetalldiasetmana }}</span>
            <span>{{ 'BOOKINGPAGE.TRIP.LABEL' | translate }}: {{ booking.datetimeStart | datahoradetalldiasetmana }} -
                {{ booking.datetimeEnd | datahoradetalldiasetmana }}</span>
        </div>
        <span class="places" matTooltip="{{ 'BOOKINGPAGE.BOOKINGSLOTAVAILABLE.CAPTION' | translate }}"
            [ngClass]="slotsStatus">{{ booking.routesLoadedUnloaded }} / {{ booking.routesManaged }} /
            {{ booking.slotsOccupied }} / {{ booking.places }}</span>
        <div class="bookingId">{{booking.bookingId}}</div>
    </ng-container>
    <ng-container *ngIf="!booking || !booking.bookingId">
        <span class="no-data">{{'BOOKINGPAGE.NOBOOKING' | translate}}</span>
    </ng-container>
</div>