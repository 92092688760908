import { FileTypes } from "@shared/src/enums/FileTypes";

export class RoutePartCMRSignatureDto {

    public fileSignatureBytes: any;
    public fileSignatureType: FileTypes;

    public printDocumentation: boolean;

    constructor(fileSignatureBytes: any, fileSignatureType: FileTypes, printDocumentation: boolean) {
        this.fileSignatureBytes = fileSignatureBytes;
        this.fileSignatureType = fileSignatureType;
        this.printDocumentation = printDocumentation;
    }

}