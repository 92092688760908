import { HColor } from "@shared/src/datatypes/HColor";
import { HDate } from "@shared/src/datatypes/HDate";

export class CalendarDayDto {

    public onDate: HDate;
    public skin: string;
    public backgroundColor: HColor;
    public type: string;

    public static NULLDAY(ondate: HDate){
        let day = new CalendarDayDto();
        day.onDate = ondate;
        return day;
    }

    public setClassWidth(){
        
    }

    public static copyFrom(target: CalendarDayDto, copyfrom: CalendarDayDto){

        if(target == null)
            target = new CalendarDayDto();

        if(copyfrom != null){
            target.onDate = copyfrom.onDate;
            target.skin = copyfrom.skin;
            target.backgroundColor = copyfrom.backgroundColor;
            target.type = copyfrom.type;            
        }else{
            target.onDate = HDate.NULLDATE;
            target.skin = null;
            target.backgroundColor = null;
            target.type = null;         
        }
    }
}