<div class="address-section" *ngIf="showAddresses">
    <ng-container *ngIf="addressBarra!=null">
        <div class="itemBarra" *ngFor="let item of addressBarra | sortBy:'-score'"
            [ngClass]="{'selected':item.addressId==selectedAddressId}" (click)="selectScreenAddress(item)">
            <div class="address boleta" [ngStyle]="item.style" matTooltip="{{getAddressToolTip(item)}}">
                <div class="inner-boleta">
                    {{item.skin}}
                    <div class="score">{{item.score}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="addressAddicionals>0" class="address boleta menuMes"
            [ngClass]="{'selected':seleccionatMenuMesAddress}" (click)="obrirMenuAddress()">
            <div class="inner-boleta">
                +{{addressAddicionals}}
            </div>
        </div>
        <div *ngIf="menuAddressObert" class="menuAddicional">
            <div class="itemMenu"
                *ngFor="let item of addressMenu | filter:searchTextAddress:'skin' | slice:0:sliceCount"
                [ngClass]="{'selected':item.addressId==selectedAddressId}" (click)="selectScreenAddress(item)">
                <div class="address boleta" [ngStyle]="item.style" matTooltip="{{getAddressToolTip(item)}}">
                    <div class="inner-boleta">
                        {{item.skin}}
                        <div class="score">{{item.score}}</div>
                    </div>
                </div>
            </div>
            <input class="searchbox" *ngIf="addressAddicionals>sliceCount" placeholder="{{'SEARCH'| translate}}"
                type=text [(ngModel)]="searchTextAddress">
        </div>
    </ng-container>
</div>
<div class="ambit-section" *ngIf="ambitsBarra!=null && showAmbits">
    <div class="itemBarra" *ngFor="let item of ambitsBarra | sortBy:'-score'"
        [ngClass]="{'selected':item.ambitId==selectedAmbitId}" (click)="selectScreenAmbit(item)">
        <div class="ambit boleta" [ngStyle]="item.style" matTooltip="{{getAmbitToolTip(item)}}">
            <div class="inner-boleta">
                {{item.skin}}
                <div class="score">{{item.score}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="ambitsAddicionals>0" class="ambit boleta menuMes" [ngClass]="{'selected':seleccionatMenuMesAmbit}"
        (click)="obrirMenuAmbits()">
        <div class="inner-boleta">
            +{{ambitsAddicionals}}
        </div>
    </div>
    <div *ngIf="menuAmbitsObert" class="menuAddicional">
        <div class="itemMenu" *ngFor="let item of ambitsMenu | filter:searchTextAmbit:'skin' | slice:0:sliceCount"
            [ngClass]="{'selected':item.ambitId==selectedAmbitId}" (click)="selectScreenAmbit(item)">
            <div class="ambit boleta" [ngStyle]="item.style" matTooltip="{{getAmbitToolTip(item)}}">
                <div class="inner-boleta">
                    {{item.skin}}
                    <div class="score">{{item.score}}</div>
                </div>
            </div>
        </div>
        <input class="searchbox" *ngIf="ambitsAddicionals>sliceCount" placeholder="{{'SEARCH'| translate}}" type=text
            [(ngModel)]="searchTextAmbit">

    </div>
</div>