<ng-container class="container" *ngIf="isCameraExist">
    <webcam class="webcam" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
        (initError)="handleInitError($event)"></webcam>
    <div class="buttons-zone" [ngClass]="{ 'padding-bottom' : incident }">
        <button class="boleta cancel" (click)="onClose()">
            <i class="fa fa-close"></i>
        </button>
        <button class="boleta add" (click)="triggerSnapshot()">
            <i class="fa fa-camera"></i>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="!isCameraExist">
    No camera
</ng-container>