<div *ngIf="route && route.routeId" class="general-container">
    <!-- Header -->
    <shared-route-item [route]="route"></shared-route-item>

    <!-- Tabs -->
    <div class="tabs">
        <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        <!-- <span class="title"
            *ngIf="selectedOptionId">{{ 'BOOKINGROUTE.TABS.' + selectedOptionId.toUpperCase() + ".CAPTION" | translate }}</span> -->
    </div>

    <!-- Content -->
    <div class="content">
        <!-- SLOTS -->
        <div class="slots" *ngIf="selectedOptionId == 'SLOTS'">
            <div class="phase-block" *ngFor="let phase of slotPhases; let i = index">
                <div class="block info">
                    <div class="icon">
                        <span class="text">
                            {{ i+1 }}
                        </span>
                    </div>
                    <div class="address">
                        <div class="addressKey" *ngIf="phase.addressSkin && phase.addressSkin.length > 0">
                            <div class="map-icon petit" (click)="onMarkerClick(phase)"><i class="fa fa-map-marker"></i>
                            </div>
                            <shared-tags-strip-component *ngIf="phase.addressSkin"
                                class="tagsbottom tags no-margin-between"
                                [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
                        </div>
                        <div class="dateSlot">
                            <div class="dockInfo" *ngIf="phase.dockSkin != null && phase.dockSkin.length > 0">
                                {{ phase.dockSkin }}
                            </div>
                        </div>
                        <div class="raddress">{{phase.addressDescriptionShort}}
                        </div>
                        <div class="addressLine1">{{phase.address}}</div>
                        <div class="formattedAddress">{{phase.formattedAddress}}</div>
                    </div>
                </div>
                <shared-slot-picker [phase]="phase" [isActiveRoutes]="'true'" [booking]="booking"
                    (valueChange)="onSlotsChanged(phase, i)">
                </shared-slot-picker>

            </div>
        </div>

        <!-- CHAT -->
        <shared-chat class="chats-body-zonachat chat-without-header" *ngIf="selectedOptionId == 'CHAT'"
            [forcedOwnerParticipant]="chatOwner" [notes]="true">
        </shared-chat>

        <!-- LOG -->
        <shared-route-item-log-maintenance *ngIf="selectedOptionId == 'LOG'" [route]="route">
        </shared-route-item-log-maintenance>

        <!-- CONDUCTOR -->
        <div class="driver" *ngIf="selectedOptionId == 'DRIVER'">

            <shared-driverplate *ngIf="showAssignDriverAndPlates" [route]="route" [showCarrierCard]="false"
                [readonly]="!(canAssignDriverAndPlates || hascarrier)">
            </shared-driverplate>

            <shared-route-partner-selector [routeId]="route.routeId" [readonly]="readonly"
                (valueChanged)="reloadRoute()"></shared-route-partner-selector>
        </div>

        <div class="extra-info" *ngIf="selectedOptionId == 'CONTAINER'">
            <shared-containernumber [routeId]="route.routeId"></shared-containernumber>
        </div>

        <!-- DOCUMENTACIÓ -->
        <div class="managementmodel-booking" *ngIf="selectedOptionId == 'MANAGEMENTMODEL'">
            <shared-documentaryreview-route-item class="resta" [routeId]="route.routeId"
                (dataUpdated)="onRouteDocumentaryReviewUpdated($event)">
            </shared-documentaryreview-route-item>
        </div>
    </div>
</div>