<p class="helppoint-title">{{data.title}}</p>

<shared-selector-addressmanualaddress-component class="col-x"
    selectorPlaceholder="UPDATEPARTNERDIALOG.ADDRESSASSISTANT.TITLE" (onChanged)="onChangedAddress($event)"
    [disableManual]="true" [addressManualAddress]="address">
</shared-selector-addressmanualaddress-component>

<input-component class="col-x" [(ngModel)]="data.key"
    placeholder="{{'ADDRESS.ADDRESSKEY.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.name"
    placeholder="{{'ADDRESS.DESCRIPTIONSHORT.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.contactPhone"
    placeholder="{{'ADDRESS.CONTACTPHONE.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.formattedAddress"
    placeholder="{{'ADDRESS.FORMATTEDADDRESS.CAPTION' | translate}}"></input-component>

<div align="end">
    <button mat-raised-button class="helppoint-button confirm" (click)="onConfirm()"
        color="primary">{{'MAINTENANCE.ACCEPT' | translate}}</button>
    <button mat-raised-button class="helppoint-button close" (click)="onClose()"
        color="link">{{'CANCEL.BUTTON' | translate}}</button>
</div>