import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { LoadConsoleCtrlDto } from '@shared/src/dtos/loadconsole/LoadConsoleCtrlDto';
import { LoadConsoleListMaintenanceDto } from '../maintenance/loadconsole/LoadConsoleListMaintenanceDto';
import { FilterLoadConsoleDto } from '@shared/src/dtos/loadconsole/FilterLoadConsoleDto';
import { LoadConsoleSlotPhaseDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseDto';
import { LoadConsolePhaseFilterDto } from '@shared/src/dtos/loadconsole/LoadConsolePhaseFilterDto';
import { LoadConsoleSlotPhaseInfoDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseInfoDto';
import { LoadConsoleSearchRightCtrlDto } from '@shared/src/dtos/loadconsole/LoadConsoleSearchRightCtrlDto';
import { FilterLoadConsoleSearchRightDto } from '@shared/src/dtos/loadconsole/FilterLoadConsoleSearchRightDto';

@Injectable({
    providedIn: 'root',
})
export class LoadConsoleController extends BaseController {
    private base = '/loadconsole'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getLoadConsolesUser(): Observable<Array<LoadConsoleListMaintenanceDto>> {
        return this.get(this.base + "/loadconsolesuser");
    }

    public getLoadConsole(filter: FilterLoadConsoleDto, busy: boolean): Observable<LoadConsoleCtrlDto> {
        if (busy)
            return this.postBusy(this.base + "/", filter, "getLoadConsole");
        return this.post(this.base + "/", filter, "getLoadConsole");
    }

    public getLoadConsoleSearchRight(filter: FilterLoadConsoleSearchRightDto, busy: boolean): Observable<LoadConsoleSearchRightCtrlDto> {
        if (busy)
            return this.postBusy(this.base + "/searchright", filter, "getLoadConsoleSearchRight");
        return this.post(this.base + "/searchright", filter, "getLoadConsoleSearchRight");
    }

    public getLoadConsoleSearchRightExactKey(filter: FilterLoadConsoleSearchRightDto, busy: boolean): Observable<LoadConsoleSearchRightCtrlDto> {
        return this.post(this.base + "/searchright/exactkey", filter, "getLoadConsoleSearchRightExactKey");
    }

    public getPhaseInfo(dto: LoadConsolePhaseFilterDto): Observable<LoadConsoleSlotPhaseInfoDto> {
        return this.post(this.base + "/getphaseinfo", dto, "getPhaseInfo");
    }

    public setCallInToDock(item: LoadConsoleSlotPhaseDto): Observable<boolean> {
        return this.post(this.base + "/setcallintodock", item, "setCallInToDock");
    }

    public setAtToDock(item: LoadConsoleSlotPhaseDto): Observable<boolean> {
        return this.post(this.base + "/setattodock", item, "setAtToDock");
    }

    public setCallOutToDock(item: LoadConsoleSlotPhaseDto): Observable<boolean> {
        return this.post(this.base + "/setcallouttodock", item, "setCallOutToDock");
    }

    public exitCallToDock(item: LoadConsoleSlotPhaseDto): Observable<boolean> {
        return this.post(this.base + "/exitcalltodock", item, "exitCallToDock");
    }
}
