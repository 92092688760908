import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';


@Injectable()
export class NotificationTopicSchemaPartnerDriverUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.DRIVER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/driver/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}