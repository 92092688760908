<div class="container" *ngIf="value">
    <div class="states-selector">
        <div *ngIf="!shownextstate || value.nextStatus == null || value.nextStatus == 'none'"
            class="select-label-current" [ngClass]="value.currentStatus" (click)="onSelectLabel()">
            <div class="state-label">{{ resourceStateCurrentStatus | translate }} <i
                    class="fa fa-caret-down separat"></i></div>
        </div>
        <div *ngIf="shownextstate && value.nextStatus != null && value.nextStatus != 'none'" class="select-label-next"
            [ngClass]="value.nextStatus">
            <div class="state-label" (click)="onSelectorChangedState(value.nextStatus)">
                {{ resourceStateNextStatus | translate: {'key': addressKey} }}
            </div>
            <div class="select-dropdown" [ngClass]="value.nextStatus" (click)="onSelectLabel()">
                <i class="fa fa-caret-down"></i>
            </div>
        </div>
        <div class="list-container" #listZone>
            <div class="list-zone" [ngClass]="listPosition" *ngIf="showList">
                <div *ngFor="let item of value.availableStatuses" (click)="onSelectorChangedState(item)"
                    class="selector-state-item">
                    <div class="selector-state-item-container">
                        <div class="selector-state-description">{{ getResourceItemToChange(item) | translate }}</div>
                    </div>
                </div>
                <div *ngFor="let item of value.additionalActions" (click)="onSelectorChangedAdditionalAction(item)"
                    class="selector-state-item">
                    <div class="selector-state-item-container">
                        <div class="selector-state-description">{{ item | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="states-pane" [ngClass]="value.currentStatus">
        <div class="line titol">
            <span class="prompt">{{'SHARED.STATE.COMPONENT.ACTUALSTATE.PROMPT' | translate}}</span><span
                class="value">{{ resourceStateCurrentStatus | translate }}</span>
        </div>
        <div class="line help">
            {{helpResource | translate}}
        </div>
        <div class="botonera">
            <button class="boto" *ngFor="let item of value.availableStatuses" (click)="stateClick(item)"
                [ngClass]="item" [disabled]="readonly">{{ getResourceItemToChange(item) | translate }}</button>
            <button class="boto" *ngFor="let item of value.additionalActions" (click)="stateClick(item)"
                [ngClass]="item" [disabled]="readonly">{{ item | translate }}</button>
        </div>
    </div>
</div>