<div class="encolumnat">
    <h1 class="titol">{{ 'REGISTERCOMPONENT.WANT_TO_WORK_WITH_YOU.TITLE' | translate }}</h1>
    <h2 class="texte">{{ 'REGISTERCOMPONENT.WANT_TO_WORK_WITH_YOU.SUBTITLE' | translate }}</h2>

        <div *ngFor="let item of registerData.partnerPendings; let index=index" class="item organitzation">
            <boolean-component [(ngModel)]="item.active"></boolean-component>
            <div class="titol">{{ item.organizationTrademarkSource }}</div>
            <div class="explicacio">{{ item.organizationNameSource }}</div>
        </div>

        <div class="button-zone">
            <button mat-flat-button color="primary" class="action seguent sol"
                (click)="next()" [disabled]="pendigResponse">{{'REGISTERCOMPONENT.NEXTBUTTON.LABEL' | translate}}</button>
        </div>

</div>
