import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { AlertService, GlobalErrorHandlerUtils } from '@shared/src/public-api';
import { ResponseMessage } from '@shared/src/controllers/ResponseMessage';
import { ResponseData } from '@shared/src/controllers/ResponseData';
import { AlertDto } from '@shared/src/dtos/alert/AlertDto';


@Injectable({
    providedIn: 'root'
  })
export class MobileAlertService extends AlertService {

    constructor() {
        super();
    }

    /***
     * Funció que es crida al obtenir una resposta del server.
     * res és la resposta complerta
     */
    showServerMessage<T>(response: ResponseData<T>, keepAfterRouteChange = false) {
        super.showServerMessage(response, keepAfterRouteChange);
    }
    setExceptionMessage(messages: ResponseMessage[]) {
       //super.setExceptionMessage(messages);
    }
    showErrorFromException(error: string, arg1: boolean) {
        //super.showErrorFromException(error, arg1);
    }

    showError(error: HttpErrorResponse, keepAfterRouteChange: boolean) {
        let message: string = GlobalErrorHandlerUtils.getErrorMessage(error);
        if (!message)
            return;
        this.error(message, keepAfterRouteChange);
    }

    showPopUpAlert(alertdto: AlertDto){

    }
}