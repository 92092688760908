import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteDocumentaryReviewObjectDto } from '@shared/src/dtos/documentaryreview/RouteDocumentaryReviewObjectDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { DocumentaryReviewController } from '@shared/src/controllers/documentaryreview/documentaryreview.controller';
import { ManagementModelController } from '@shared/src/controllers/managementmodel/managementmodel.controller';
import { ShipmentCardDto } from '@shared/src/public-api';
import { DocumentaryReviewObjectBaseDto } from '@shared/src/dtos/documentaryreview/DocumentaryReviewObjectBaseDto';
import { RouteShipmentDocumentaryReviewObjectDto } from '@shared/src/dtos/documentaryreview/RouteShipmentDocumentaryReviewObjectDto';
import { HLong } from '@shared/src/datatypes/HLong';

@Component({
  selector: 'shared-documentaryreview-route-item',
  templateUrl: './shared.documentaryreview.route.item.component.html',
  styleUrls: ['./shared.documentaryreview.route.item.component.scss'],
})
export class SharedDocumentaryReviewRouteItemComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
  private documentaryReviewController: DocumentaryReviewController,
    private managementModelController: ManagementModelController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public readonly: boolean = false;

  @Input() public showbuttonback: boolean = false;
  
  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.__routeDReviewObject = null;
    this.loadData(false);
  }
  get routeId(): number {
    return this.__routeId;
  }

  public __routeDReviewObject: RouteDocumentaryReviewObjectDto;
  set routeDReviewObject(value: RouteDocumentaryReviewObjectDto) {
    if (this.__routeDReviewObject === value)
      return;
    this.__routeDReviewObject = value;
  }
  get routeDReviewObject(): RouteDocumentaryReviewObjectDto {
    return this.__routeDReviewObject;
  }

  public __shipmentDReviewObject: RouteShipmentDocumentaryReviewObjectDto;
  set shipmentDReviewObject(value: RouteShipmentDocumentaryReviewObjectDto) {
    if (this.__shipmentDReviewObject === value)
      return;
    this.__shipmentDReviewObject = value;
  }
  get shipmentDReviewObject(): RouteShipmentDocumentaryReviewObjectDto {
    return this.__shipmentDReviewObject;
  }

  @Output() dataUpdated: EventEmitter<RouteDocumentaryReviewObjectDto> = new EventEmitter();
  public loadData(dataupdate: boolean) {
    if(this.routeId > 0){
      if(HLong.isNullOrNullLong(this.selectedShipmentId)){
        this.documentaryReviewController.getRouteDocumentaryReview(this.routeId).subscribe(data => {
          this.routeDReviewObject = data;
          this.dReviewObject = data;
          if(this.routeDReviewObject && dataupdate)
            this.dataUpdated.next(this.routeDReviewObject);
          
        });
      }else{
        this.documentaryReviewController.getRouteShipmentDocumentaryReview(this.routeId, this.selectedShipmentId).subscribe(data => {
          this.shipmentDReviewObject = data;
          this.dReviewObject = data;
        });
      }
    }
  }

  public onDataRefresh(value: boolean) {    
    if(value)
      this.loadData(true);
  }

  public onActionExecute(item: ActionDto) {    

    if (!item)
      return;

      switch (item.actionId) {
        case "RECALCULATESTATUS":
          this.managementModelController.doActionRecalculateStatusRoute(this.routeId).subscribe(data => {
            if(data)
              this.loadData(true);
          });
      }

  }
  
  @Output() clickBack: EventEmitter<boolean> = new EventEmitter();
  onClickBack(event) {
    this.clickBack.next(true);
  }

  public dReviewObject : DocumentaryReviewObjectBaseDto;

  public __selectedShipmentId: number = 0;
  set selectedShipmentId(value: number) {
    if (this.__selectedShipmentId === value)
      return;
    this.__selectedShipmentId = value;
    this.loadData(true);
  }
  get selectedShipmentId(): number {
    return this.__selectedShipmentId;
  }
  selectShipment(shipmentId: number) {
      this.selectedShipmentId = shipmentId;
  }

  selectRoute() {
      this.dReviewObject = this.routeDReviewObject;
      this.selectedShipmentId = 0;
  }

}
