<div *ngIf="value">
    <shared-managementmodel-info [value]="value" (managementModelChanged)="onDataRefresh($event)" [readonly]="readonly"></shared-managementmodel-info>
    
    <div *ngIf="value.requirementsStruct && value.requirementsStruct.managementModelId > 0">
        <div class="col-x">
            <div class="header">
                <div class="tabs">
                    <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
                </div>
                <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
            </div>
        </div>
        <div class="col-x">
            <shared-managementmodel-requirements-component *ngIf="selectedOptionId == 'REQUIREMENTS'" [value]="value.requirementsStruct" [readonly]="readonly" (dataRefresh)="onDataRefresh($event)"></shared-managementmodel-requirements-component>
            <shared-managementmodel-requirements-log-component *ngIf="selectedOptionId == 'LOGS'" [logs]="logs" [readonly]="readonly"></shared-managementmodel-requirements-log-component>
        </div>
    </div>
</div>