<div class="address-section" *ngIf="showAddresses">
    <ng-container *ngIf="addressBarra!=null">
        <div class="itemBarra" *ngFor="let item of addressBarra | sortBy:'-score'"
            [ngClass]="{'selected':item.addressId==selectedAddressId}" (click)="selectScreenAddress(item)">
            <div class="address boleta" [ngStyle]="item.style">
                <div class="inner-boleta">
                    {{item.addressKey}}
                    <div class="score">{{item.score}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="addressAddicionals>0" class="address boleta menuMes"
            [ngClass]="{'selected':seleccionatMenuMesAddress}" (click)="obrirMenuAddress()">
            <div class="inner-boleta">
                +{{addressAddicionals}}
            </div>
        </div>
        <div *ngIf="menuAddressObert" class="menuAddicional">
            <div class="itemMenu"
                *ngFor="let item of addressMenu |filter:searchTextAddress| sortBy:'-score' | slice:0:sliceCount"
                [ngClass]="{'selected':item.addressId==selectedAddressId}" (click)="selectScreenAddress(item)">
                <div class="address boleta" [ngStyle]="item.style">
                    <div class="inner-boleta">
                        {{item.addressKey}}
                        <div class="score">{{item.score}}</div>
                    </div>
                </div>
            </div>
            <input class="searchbox" *ngIf="addressAddicionals>sliceCount" placeholder="{{'SEARCH'| translate}}"
                type=text [(ngModel)]="searchTextAddress">
        </div>
    </ng-container>
</div>
<div class="accessControl-section" *ngIf="accessControlsBarra!=null && showAccessControls">
    <div class="itemBarra" *ngFor="let item of accessControlsBarra | sortBy:'-score'"
        [ngClass]="{'selected':item.accessControlId==selectedAccessControlId}"
        (click)="selectScreenAccessControl(item)">
        <div class="accessControl boleta" [ngStyle]="item.style">
            <div class="inner-boleta">
                {{item.accessControlKey}}
                <div class="score">{{item.score}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="accessControlsAddicionals>0" class="accessControl boleta menuMes" [ngClass]="{'selected':seleccionatMenuMesAccessControl}"
        (click)="obrirMenuAccessControls()">
        <div class="inner-boleta">
            +{{accessControlsAddicionals}}
        </div>
    </div>
    <div *ngIf="menuAccessControlsObert" class="menuAddicional">
        <div class="itemMenu"
            *ngFor="let item of accessControlsMenu |filter:searchTextAccessControl| sortBy:'-score' | slice:0:sliceCount"
            [ngClass]="{'selected':item.accessControlId==selectedAccessControlId}"
            (click)="selectScreenAccessControl(item)">
            <div class="accessControl boleta" [ngStyle]="item.style">
                <div class="inner-boleta">
                    {{item.accessControlKey}}
                    <div class="score">{{item.score}}</div>
                </div>
            </div>
        </div>
        <input class="searchbox" *ngIf="accessControlsAddicionals>sliceCount" placeholder="{{'SEARCH'| translate}}" type=text
            [(ngModel)]="searchTextAccessControl">

    </div>
</div>