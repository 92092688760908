import { Component, Input } from '@angular/core';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';
import { TotemRouteDto } from '@shared/src/controllers/totem/TotemRouteDto';

@Component({
    selector: 'shared-totem-route',
    templateUrl: './shared.totem.route.component.html',
    styleUrls: ['./shared.totem.route.component.scss']
})
export class SharedTotemRouteComponent {

    constructor() {
    }

    @Input()
    value: TotemRouteDto;

    @Input()
    public totem: TotemDto;

    ngAfterViewInit() {
    }


    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


