import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { AlertService, PhaseCardDto, PhaseController } from '@shared/src/public-api';

@Component({
  selector: 'shared-phase-change-strongaddress',
  templateUrl: './shared.phase.change.strongaddress.component.html',
  styleUrls: ['./shared.phase.change.strongaddress.component.scss'],
})
export class SharedPhaseChangeStrongAddressComponent {

  constructor(private phaseController: PhaseController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('AlertService') protected alertService: AlertService) {

  };

  public ADDRESS_FILTERKEYS: Array<string> = ['STRONGADDRESS.ADDRESS.FILTER.OWN.BYPHASEID:'];

  public __phase: PhaseCardDto;
  @Input()
  set phase(value: PhaseCardDto) {
    if (this.__phase === value)
      return;
    this.__phase = value;
    let phaseId = value != null ? value.phaseId : 0;
    this.ADDRESS_FILTERKEYS = ['STRONGADDRESS.ADDRESS.FILTER.OWN.BYPHASEID:' + phaseId];
  }
  get phase(): PhaseCardDto {
    return this.__phase;
  }

  public _addressId: number;
  set addressId(value: number) {
    if (this._addressId === value)
      return;
    this._addressId = value;
  }
  get addressId(): number {
    return this._addressId;
  }


  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter();

  public setOriginStrongAddress(){
    if (this.__phase == null || this.__phase.phaseId == 0) {
      return;
    }
    this.phaseController.setForcedStrongAddress(this.phase.phaseId, 0).subscribe(data => {
      if(data)
        this.valueChanged.next(true);
    });
  }

  public setForcedStrongAddress() {
    if (this.__phase == null || this.__phase.phaseId == 0 || HLong.isNullOrNullLong(this.addressId)) {
      return;
    }

    this.phaseController.setForcedStrongAddress(this.phase.phaseId, this.addressId).subscribe(data => {
      if(data)
        this.valueChanged.next(true);
    });

  }

  ngOnInit() {
   
  }
 
}
