import { Component, Input, OnInit, Output, EventEmitter, ViewChild, Inject, ComponentFactoryResolver, Injector, ComponentRef, ApplicationRef } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';
import { GeofenceDto } from '@shared/src/dtos/gate/GeofenceDto';
import { GeofencePointDto } from '@shared/src/dtos/gate/GeofencePointDto';
import { GeofenceShapes } from '@shared/src/enums/GeofenceShapes';
import { MapMarcadorDto, MapPuntDto, MapRutaDto, MapService } from '../../services/map/map.service';
import { SharedAsideFactory } from '../aside/shared.aside.factory';
import { SharedMapInfoWindowComponent } from './info/shared.map.info.window.component';
import { HString } from '@shared/src/datatypes/HString';
import { HLong } from '@shared/src/datatypes/HLong';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HColor } from '@shared/src/public-api';

@Component({
  selector: 'shared-map',
  templateUrl: './shared.map.component.html',
  styleUrls: ['./shared.map.component.scss'],
})

/***
 * Es el componentº que encapsula els mapes.
 * Mira el MapService de shared per obtenir les estructures de dades i/o transormacions que necesitis
 */
export class SharedMapComponent implements OnInit {

  public static LONGITUDESCALE: number[][] = [
    [1, 20], [4, 8], [10, 7], [12.5, 6.4], [19, 6.4], [43, 5], [40, 4.4], [85, 4], [360, 1.5]
  ];
  public static LATITUDESCALE: number[][] = [
    [1, 20], [1.75, 8], [2.8, 8], [4.7, 7], [6, 7], [10, 6], [20, 5], [23, 4.4], [57, 4], [75, 4], [90, 3.5], [180, 1.5]
  ];

  public useIonic: boolean = false;
  public compMapInfoWindow: ComponentRef<SharedMapInfoWindowComponent>;
  constructor(
    @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory, private injector: Injector,
    private resolver: ComponentFactoryResolver, private appRef: ApplicationRef,
    @Inject('HomeController') protected homeController: HomeController) {

    const compFactory = this.resolver.resolveComponentFactory(SharedMapInfoWindowComponent);
    this.compMapInfoWindow = compFactory.create(this.injector);

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.map = null;
    this._rutesTransformades = [];
    if (this.compMapInfoWindow)
      this.compMapInfoWindow.destroy();
  }

  @ViewChild(GoogleMap, { static: false }) set mapGoogle(m: GoogleMap) {
    if (m) {
      this.onMapReady(m);
    }
  }

  //Web de la API que s'utilitza https://developers.google.com/maps/documentation/javascript/reference
  public map: google.maps.Map;

  onMapReady(mapGoogle?: GoogleMap) {
    if (mapGoogle && mapGoogle.googleMap) {
      mapGoogle.googleMap.setOptions({
        streetViewControl: false,
        fullscreenControl: true,
        disableDefaultUI: true
      });

      this.map = mapGoogle.googleMap;

      if (this.__geofence) {
        this.drawShape([this.__geofence]);
      } else if (this.__geofences) {
        this.drawShape(this.__geofences);
      }

      if (this.drawing) {
        this.loadDrawingManager();
      }

      this.calculateRutesTransformades();

      this.loadMarkers();
    }
  }

  @Input() public containerClass: string;

  //les rutes son parells ordenats de origen i desti
  private _rutes: Array<MapRutaDto>;
  public _rutesTransformades: Array<RutaDto>;
  @Input()
  set rutes(value: Array<MapRutaDto>) {
    if (this._rutes === value)
      return;
    this._rutes = value;
    this.calculateRutesTransformades();
  }
  private calculateRutesTransformades() {
    if (!this.map)
      return;
    if (this._rutes == null)
      this._rutesTransformades = new Array();
    else
      this._rutesTransformades = this.transformarRutes(this._rutes);

    this.loadListDirections();
  }
  get rutes(): Array<MapRutaDto> {
    return this._rutes;
  }

  public listDirectionsRenderers: Array<google.maps.DirectionsRenderer>;
  loadListDirections() {

    //Primer fem neteja de les actuals
    if (this.listDirectionsRenderers && this.listDirectionsRenderers.length > 0) {
      this.listDirectionsRenderers.forEach(element => {
        element.setMap(null);
      });
    }

    this.listDirectionsRenderers = new Array();
    if (this.map && this._rutesTransformades && this._rutesTransformades.length > 0) {

      var map = this.map;
      const directionsService = new google.maps.DirectionsService();

      this._rutesTransformades.forEach(element => {
        const request: google.maps.DirectionsRequest = {
          destination: element.desti,
          origin: element.origen,
          waypoints: element.waypoints,
          travelMode: google.maps.TravelMode.DRIVING
        };

        if (this.fixedCenter)
          this.map.panTo(new google.maps.LatLng(this._latitude, this._longitude));

        directionsService.route(request, (result, status) => {
          if (status == 'OK') {
            const directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(result);
            if (element.isPhaseList)
              directionsRenderer.setOptions({ suppressMarkers: true });
            this.listDirectionsRenderers.push(directionsRenderer);

            setTimeout(() => {
              this.centerChanged();
              this.zoomDirectionsChanged(this.map.getZoom());
            }, 100);
          }
        });

      });

    }
  }

  //Els marcadors "punts" que ha de mostrar el mapa
  private _marcadors: Array<MapMarcadorDto>;
  //Variables càlcul zoom
  public windowHeight: any;
  public windowWidth: any;
  public zoomRelacio: any;

  @Input()
  set marcadors(marcadors: Array<MapMarcadorDto>) {
    if (this._marcadors === marcadors)
      return;

    if (!this._forcedZoom)
      this.forcedZoom = null;

    this._marcadors = marcadors;

    this.calculatePosAndZoom();
    this.loadMarkers();
  }
  get marcadors(): Array<MapMarcadorDto> {
    return this._marcadors;
  }

  private calculatePosAndZoom() {
    if ((this._marcadors == null || this._marcadors.length == 0)
      || (this._marcadors != null && this._marcadors.length == 1 && (this._marcadors[0].isLost || this._marcadors[0].isLost == null) && (this._marcadors[0].latitude == 0 || this._marcadors[0].latitude == null) && (this._marcadors[0].longitude == 0 || this._marcadors[0].longitude == null))) {
      if (!this.fixedCenter) {
        this._latitude = MapService.DEFAULT_LATITUDE;
        this._longitude = MapService.DEFAULT_LONGITUDE;
      }

      if (!this.forcedZoom && !this.minZoom) {
        this.zoom = MapService.DEFAULT_GOOGLEMAPS_ZOOM;
      }

    }
    else if (this._marcadors != null && this._marcadors.length == 1) {
      if (!this.fixedCenter) {
        this.latitude = this._marcadors[0].latitude;
        this.longitude = this._marcadors[0].longitude;
      }

      if (!this.forcedZoom && !this.minZoom) {
        this.zoom = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;
      }

    } else if (!this.fixedCenter) {
      let minlatitude = this._marcadors[0].latitude;
      let maxlatitude = this._marcadors[0].latitude;
      let minlongitude = this._marcadors[0].longitude;
      let maxlongitude = this._marcadors[0].longitude;
      this._marcadors.forEach(marca => {
        if (marca.latitude != null && marca.longitude != null) {
          minlatitude = minlatitude == null || marca.latitude < minlatitude ? marca.latitude : minlatitude;
          maxlatitude = maxlatitude == null || marca.latitude > maxlatitude ? marca.latitude : maxlatitude;
          minlongitude = minlongitude == null || marca.longitude < minlongitude ? marca.longitude : minlongitude;
          maxlongitude = maxlongitude == null || marca.longitude > maxlongitude ? marca.longitude : maxlongitude;
        }
      });

      this.longitude = minlongitude + (maxlongitude - minlongitude) / 2;
      this.latitude = minlatitude + (maxlatitude - minlatitude) / 2;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      // let dLong = maxlongitude - minlongitude;
      // let dLat = maxlatitude - minlatitude;

      // let zLong = this.zoomForLongitude(dLong);
      // let zLat = this.zoomForLatitude(dLat);
      // this.zoomRelacio = zLong < zLat ? zLong : zLat;

      if (!this.forcedZoom) {
        // this.zoom = this.zoomRelacio;
        // Aquest bonds representa el rectangle que volem enquadrar en pantalla
        let bonds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        // Li fixem un extrem als valors mmínims
        bonds.extend(new google.maps.LatLng(minlatitude, minlongitude));
        // Li fixem un extrem als valors màxims
        bonds.extend(new google.maps.LatLng(maxlatitude, maxlongitude));
        // Li diem al mapa que volem que es situe en aquest rectangle
        if (this.map)
          this.map.fitBounds(bonds);
      }

    }

  }

  //Han fet un click a un marcador
  public onMarkerClick(m: MapMarcadorDto) {
    this.marcadorClick.next(m);

    if (m != null)
      if (m.localizableObject != null || m.routeLocalizableObject != null)
        if (m.localizableObject.objectType == LocalizableObjectTypes.route || m.routeLocalizableObject != null && m.routeLocalizableObject != undefined)
          this.sharedAsideFactory.invokeRouteMapFromRoute(m.localizableObject.id);
        else if (m.localizableObject.objectType == LocalizableObjectTypes.shipment)
          this.sharedAsideFactory.invokeRouteMapFromShipment(m.localizableObject.id);
  }

  //Han fet un dobleclick a un marcador
  public onMarkerDblClick(m: MapMarcadorDto) {
    this.marcadorDblClick.next(m);
  }

  markerDragEnd(lat, lng, $event: any) {
    if ($event != null && $event.latLng != null) {
      this.latitude = $event.latLng.lat();
      this.longitude = $event.latLng.lng();
    }
  }

  public transformarRutes(rutes: Array<MapRutaDto>): any {
    if (rutes == null)
      return new Array();

    let result = new Array();
    rutes.forEach(r => {
      result.push(this.transformarRutesWaypoints(r));
    });
    return result;
  }

  public transformarRutesWaypoints(rutes: MapRutaDto): RutaDto {
    var result: RutaDto = new RutaDto();
    if (!rutes)
      return result;

    result.origen = this.transformDirection(rutes.origen);
    result.desti = this.transformDirection(rutes.desti);
    result.isPhaseList = rutes.isPhaseList;

    rutes.waypoints.forEach(r => {
      result.waypoints.push({ location: this.transformDirection(r), stopover: false })
    });
    return result;
  }


  public transformDirection(r: MapPuntDto): any {
    if (r == null || google == null || google.maps == null)
      return {};
    let result = new google.maps.LatLng(r.latitude ? r.latitude : 0, r.longitude ? r.longitude : 0);
    return result;
  }


  public listMarkers: Array<google.maps.Marker>;
  loadMarkers() {

    //Generem els markers sense les directives <map-marker> ja que el rendiment millora molt

    //Primer fem neteja de les actuals
    if (this.listMarkers && this.listMarkers.length > 0) {
      this.listMarkers.forEach(element => {
        element.setMap(null);
      });
    }

    this.listMarkers = new Array();
    if (this.map && this.marcadors && this.marcadors.length > 0) {

      var map = this.map;
      this.marcadors.forEach(marcador => {



        //Si no tenim latitud ni longitud no l'afegim
        if (!HLong.isNullOrNullLong(marcador.longitude) && !HLong.isNullOrNullLong(marcador.latitude)) {

          //NOTA: De moment no posem el label, ja que si ni han moltes queda tot negre, s'ha d'implementar el cluster que no està disponible fins
          //que no pugem de versió
          const marker = new google.maps.Marker({
            position: marcador.position,
            icon: marcador.iconUrl,
            // label: "",
            draggable: marcador.draggable,
            map: map,
          });

          if (marcador.markerLabel) {
            marker.setLabel({
              text: marcador.markerLabel,
              color: 'white',
            });
          } else {
            if (marcador != null && marcador.localizableObject != null)
              marker.setIcon(
                {
                  url: this.createSVG(marcador.localizableObject.key, HColor.getWhiteBlackFromColor(HColor.getColor(marcador.localizableObject.color, "#000")), HColor.getColor(marcador.localizableObject.color, "#000000")),
                  scaledSize: new google.maps.Size(50, 70),
                }
              );
          }

          //Si no hi ha icona definida, la API posa la de per defecte
          //if (HString.isNullOrNullString(marcador.iconUrl))
          //marker.setIcon(MapMarcadorDto.DEFAULTMARKER);

          var _this = this;

          var useIonicAndIsPhase = this.useIonic && marcador.type == LocalizableObjectTypes.phase && marcador.phase;
          var noUseIonicAndIsPhase = !this.useIonic && marcador.type == LocalizableObjectTypes.phase && marcador.phase;

          const infoWindow = new google.maps.InfoWindow();
          marker.addListener("click", (event) => {
            if (useIonicAndIsPhase && _this.compMapInfoWindow && marcador != null) {
              _this.compMapInfoWindow.instance.marcador = marcador;
              _this.appRef.attachView(_this.compMapInfoWindow.hostView);
              let div = document.createElement('div');
              div.appendChild(_this.compMapInfoWindow.location.nativeElement);
              infoWindow.setContent(div);

              infoWindow.open(map, marker);
            } else {
              _this.onMarkerClick(marcador);
              /*if(marker.getLabel() != null)
                marker.setLabel(null);
              else
                marker.setLabel(marcador.label);*/
              event.stop();
            }
          });

          marker.addListener("dblclick", (event) => {
            _this.onMarkerDblClick(marcador);
            event.stop();
          });

          marker.addListener('dragend', function (evt) {
            _this.markerDragEnd(marker.getPosition().lat, marker.getPosition().lng, evt);
          });

          marker.addListener('mouseover', function () {
            if (noUseIonicAndIsPhase || (_this.compMapInfoWindow && marcador != null && !HString.isNullOrNullString(marcador.label))) {
              _this.compMapInfoWindow.instance.marcador = marcador;
              _this.appRef.attachView(_this.compMapInfoWindow.hostView);
              let div = document.createElement('div');
              div.appendChild(_this.compMapInfoWindow.location.nativeElement);
              infoWindow.setContent(div);

              infoWindow.open(map, marker);
            }
          });

          marker.addListener('mouseout', function () {
            if (!useIonicAndIsPhase)
              infoWindow.close();
          });

          this.listMarkers.push(marker);
        }
      });

    }
  }

  createSVG(label: string, foreColor: string, backgroundColor: string): string {
    const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;
    let rawSvgString = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"71\" height=\"45\" viewBox=\"0 0 71 45\" fill=\"none\"><rect width=\"71\" height=\"37\" rx=\"10\" fill=\"${backgroundColor}\"/> <path d=\"M35 45L27 37H43L35 45Z\" fill=\"${backgroundColor}\"/><text x=\"50%\" y=\"24\" text-anchor=\"middle\" fill=\"${foreColor}\" font-size=\"14px\" font-family=\"sans-serif\" font-weight=\"bold\">${label}</text></svg>";
    //rawSvgString = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"71\" height=\"45\" viewBox=\"0 0 71 45\" fill=\"none\"><rect width=\"71\" height=\"37\" rx=\"10\" fill=\"#cb372b\"/> <path d=\"M35 45L27 37H43L35 45Z\" fill=\"#cb372b\"/><text x=\"50%\" y=\"24\" text-anchor=\"middle\" fill=\"#FFF\" font-size=\"14px\" font-family=\"sans-serif\" font-weight=\"bold\">${label}</text></svg>";
    rawSvgString = HString.replaceAll(rawSvgString, "${label}", label);
    rawSvgString = HString.replaceAll(rawSvgString, "${foreColor}", foreColor);
    rawSvgString = HString.replaceAll(rawSvgString, "${backgroundColor}", backgroundColor);
    rawSvgString = rawSvgString
      .replace(/'/g, '"')
      .replace(/>\s+</g, '><')
      .replace(/\s{2,}/g, ' ');
    rawSvgString = 'data:image/svg+xml;utf-8,' + rawSvgString.replace(symbols, encodeURIComponent);
    return rawSvgString;
  }

  public openInfoWindow() {

  }

  public zoomcalculat: any;

  public zoomForLatitude(latitude) {
    let t = SharedMapComponent.regression(SharedMapComponent.LATITUDESCALE, latitude);
    return t;
  }

  public zoomForLongitude(longitude) {
    let t = SharedMapComponent.regression(SharedMapComponent.LONGITUDESCALE, longitude);
    return t;
  }

  public static regression(matrixValues: number[][], value: number): number {
    //Busquem a la matrix un valor similar a value;
    //Si el trobem retornem el valor, si no retornem la proporció entre els dos més propers

    let coincidenciaExacta: number[] = null;
    let inferior: number[] = null;
    let superior: number[] = null;

    matrixValues.forEach(v => {
      if (v[0] === value)
        coincidenciaExacta = v;

      if (v[0] < value && ((inferior == null) || (inferior != null && v[0] > inferior[0])))
        inferior = v;
      if (v[0] > value && ((superior == null) || (superior != null && v[0] < superior[0])))
        superior = v;
    });

    if (coincidenciaExacta != null)
      return coincidenciaExacta[1];

    if (inferior == null)
      return superior[1];
    if (superior == null)
      return inferior[1];

    //Interpolar
    // y = y1 + ((y2-y1)*(x-x1)/(x2-x1))

    let y1 = inferior[1];
    let x1 = inferior[0];
    let y2 = superior[1];
    let x2 = superior[0];
    return y1 + ((y2 - y1) * (value - x1) / (x2 - x1));
  }

  public static linearRegression(y, x) {
    var lr = { slope: 0, intercept: 0, r2: 0 };
    var n = y.length;
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var sum_yy = 0;

    for (var i = 0; i < y.length; i++) {

      sum_x += x[i];
      sum_y += y[i];
      sum_xy += (x[i] * y[i]);
      sum_xx += (x[i] * x[i]);
      sum_yy += (y[i] * y[i]);
    }

    lr.slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
    lr.intercept = (sum_y - lr.slope * sum_x) / n;
    lr.r2 = Math.pow((n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)), 2);

    return lr;
  }

  public centerChanged() {
    if ((this.map.getCenter().lat() != this._latitude || this.map.getCenter().lng() != this._longitude)) {
      if (this.fixedCenter && this._latitude && this._latitude != MapService.DEFAULT_LATITUDE && this._longitude && this._longitude != MapService.DEFAULT_LONGITUDE) {
        this.map.setCenter(new google.maps.LatLng(this._latitude, this._longitude));
      }
      this.externalCenterChanged(this.map.getCenter());
    }
  }

  @Input()
  public fixedCenter: boolean = false;

  public center: {} = null;

  //La latitut a on es situa el mapa
  private _latitude: number = MapService.DEFAULT_LATITUDE;
  @Output() latitudeChange = new EventEmitter<number>();
  @Input()
  public set latitude(lat: number) {
    if (this._latitude === lat)
      return;
    this._latitude = lat;
    this.center = new google.maps.LatLng(this._latitude ? this._latitude : 0, this._longitude ? this._longitude : 0);
    this.latitudeChange.next(this._latitude);
  }
  public get latitude(): number {
    return this._latitude;
  }

  //La longitud a on es situa el mapa
  public _longitude: number = MapService.DEFAULT_LONGITUDE;
  @Output() longitudeChange = new EventEmitter<number>();
  @Input()
  public set longitude(long: number) {
    if (this._longitude === long)
      return;
    this._longitude = long;
    this.center = new google.maps.LatLng(this._latitude ? this._latitude : 0, this._longitude ? this._longitude : 0);
    this.longitudeChange.next(this._longitude);
  }
  public get longitude(): number {
    return this._longitude;
  }

  @Input()
  public autozoom: boolean = true;

  //Per ara ho deixo aquí te pinta de que està vinculat a la plataforma a més gran ...
  private _zoom = MapService.DEFAULT_GOOGLEMAPS_ZOOM;

  @Input()
  public set zoom(value: number) {
    this._zoom = value;
    if (this.map)
      this.map.setZoom(this.zoom);
  }

  public get zoom(): number {
    return this._zoom;
  }

  private _forcedZoom = null;
  @Input()
  public set forcedZoom(value: number) {
    if (this._forcedZoom === value)
      return;
    this._forcedZoom = value;
    this._minZoom = value;
    this.zoom = value;
  }
  public get forcedZoom(): number {
    return this._forcedZoom;
  }

  private _minZoom = null;
  @Input()
  public set minZoom(value: number) {
    if (!value)
      return;
    if (this._minZoom === value)
      return;
    this._minZoom = value;
    if (!this._forcedZoom || (this.zoom && this.zoom < value))
      this.forcedZoom = value;
  }
  public get minZoom(): number {
    return this._minZoom;
  }

  //Event que indica que un marcador s'ha clickat
  @Output() marcadorClick: EventEmitter<MapMarcadorDto> = new EventEmitter<MapMarcadorDto>();
  @Output() marcadorDblClick: EventEmitter<MapMarcadorDto> = new EventEmitter<MapMarcadorDto>();

  @Output() mapClick = new EventEmitter<any>();
  public clickOnMap($event) {
    this.mapClick.next($event);
  }
  public _height: number;
  @Input()
  set height(value: number) {
    if (this._height === value)
      return;
    this._height = value;
    this.calculateStyle();
  }
  get height(): number {
    return this._height;
  }

  public sheight: string = "0px";
  public calculateStyle() {
    if (this.height == null)
      this.sheight = "0px";
    else
      this.sheight = this._height + "px";
  }

  public origin = { lat: MapService.DEFAULT_LATITUDE, lng: MapService.DEFAULT_LONGITUDE };
  public destination = { lat: MapService.DEFAULT_LATITUDE + 5, lng: MapService.DEFAULT_LONGITUDE + 5 };

  public shape = [];
  @Output() newDrawing = new EventEmitter<GeofenceDto>();
  @Input() public drawing: boolean = false;
  public drawingManager: google.maps.drawing.DrawingManager;

  private __geofence: GeofenceDto;
  @Input() public set geofence(value: GeofenceDto) {
    if (!value && this.shape) {
      this.shape.forEach(item => {
        item.setMap(null);
      });
      this.shape = [];
      return;
    }

    this.__geofence = value;
    this.drawShape([this.__geofence]);
  }

  private __geofences: Array<GeofenceDto>;
  @Input() public set geofences(value: Array<GeofenceDto>) {
    if (!value && this.shape) {
      this.shape.forEach(item => {
        item.setMap(null);
      });
      this.shape = [];
      return;
    }

    this.__geofences = value;
    this.drawShape(this.__geofences);
  }

  public drawShape(geofences: Array<GeofenceDto>) {
    if (!this.map)
      return;

    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
    };

    geofences.forEach((value, index) => {
      var points = [];
      let bonds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      value.points.forEach(item => {
        points.push(new google.maps.LatLng(item.latitude, item.longitude));
        if (value.shape != GeofenceShapes.Circle)
          bonds.extend(new google.maps.LatLng(item.latitude, item.longitude));
        else {
          let latRadius = value.radius / (111.32 * 1000 * Math.cos(item.latitude * (Math.PI / 180))) / 2;
          let lonRadius = value.radius / (111.32 * 1000 * Math.cos(item.longitude * (Math.PI / 180))) / 2;
          bonds.extend(new google.maps.LatLng(item.latitude + latRadius, item.longitude + lonRadius));
          bonds.extend(new google.maps.LatLng(item.latitude - latRadius, item.longitude - lonRadius));
        }
      });

      if (this.map && !this.fixedCenter) {
        this.map.fitBounds(bonds);
        this.centerChanged();
        this.zoomDirectionsChanged(this.map.getZoom());
      }

      if (points.length > 0) {
        if (this.shape && index == 0) {
          this.shape.forEach(item => {
            item.setMap(null);
          });
          this.shape = [];
        }

        switch (value.shape) {
          case GeofenceShapes.Rectangle:
            this.shape.push(new google.maps.Rectangle({
              bounds: {
                north: points[0].lat(),
                south: points[1].lat(),
                east: points[0].lng(),
                west: points[1].lng(),
              }
            }));
            break;
          case GeofenceShapes.Circle:
            this.shape.push(new google.maps.Circle({
              center: points[0],
              radius: value.radius
            }));
            break;
          case GeofenceShapes.Polygon:
            this.shape.push(new google.maps.Polygon({
              paths: points
            }));
          case GeofenceShapes.Polyline:
            const line = new google.maps.Polyline({
              path: points,
              strokeColor: value.color,
              icons: [{
                icon: lineSymbol,
                offset: "100%",
              }]
            });
            this.shape.push(line);
          default:
            break;
        }
      }

    });

    this.shape.forEach(item => {
      item.setMap(this.map);
    });
  }


  public loadDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.CIRCLE],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      }
    });

    this.drawingManager.setMap(this.map);
    this.drawingManager.addListener('overlaycomplete', event => {
      this.__geofence = new GeofenceDto();
      switch (event.type) {
        case "circle":
          this.__geofence.shape = GeofenceShapes.Circle;
          let center: GeofencePointDto = new GeofencePointDto();
          center.latitude = event.overlay.center.lat();
          center.longitude = event.overlay.center.lng();
          this.__geofence.points.push(center);
          this.__geofence.radius = event.overlay.radius;
          break;
        case "rectangle":
          this.__geofence.shape = GeofenceShapes.Rectangle;
          let northEast: GeofencePointDto = new GeofencePointDto();
          northEast.latitude = event.overlay.getBounds().getNorthEast().lat();
          northEast.longitude = event.overlay.getBounds().getNorthEast().lng();
          this.__geofence.points.push(northEast);

          let southWest: GeofencePointDto = new GeofencePointDto();
          southWest.latitude = event.overlay.getBounds().getSouthWest().lat();
          southWest.longitude = event.overlay.getBounds().getSouthWest().lng();
          this.__geofence.points.push(southWest);

          break;
        case "polygon":
          this.__geofence.shape = GeofenceShapes.Polygon;
          event.overlay.getPaths().getAt(0).getArray().forEach(item => {
            var point: GeofencePointDto = new GeofencePointDto();
            point.latitude = item.lat();
            point.longitude = item.lng();
            this.__geofence.points.push(point);
          })
          break;
      }
      this.newDrawing.emit(this.__geofence);
      if (this.shape) {
        this.shape.forEach(item => {
          item.setMap(null);
        });
        this.shape = [];
      }
      this.shape.push(event.overlay);
    })
  }

  externalCenterChanged(value: google.maps.LatLng) {
    this.latitudeChange.emit(value.lat());
    this.longitudeChange.emit(value.lng());
  }

  @Output() public zoomDirectionsValueChanged: EventEmitter<number> = new EventEmitter<number>();
  zoomDirectionsChanged(zoom: number) {
    this.zoomDirectionsValueChanged.emit(zoom)
  }

  @Output() public zoomValueChanged: EventEmitter<number> = new EventEmitter<number>();
  zoomChanged(event: any) {
    if (this.zoom != this.map.getZoom())
      this.zoom = this.map.getZoom();
    if (!this._forcedZoom) {
      this._forcedZoom = null;
      // this._minZoom = null;
      // this.zoom = this.map.getZoom();
      this.zoomValueChanged.emit(this.map.getZoom())
    }
  }
  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  public c(value: any) {
    //console.log(this.pretty(value));
  }
}

/***
 * Definex una ruta a fer, punt d'origen i punt de desti
 */
export class RutaDto {
  public origen: any;
  public desti: any;
  public waypoints: Array<google.maps.DirectionsWaypoint> = [];
  public isPhaseList: boolean;
}


