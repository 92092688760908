import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { Observable } from 'rxjs';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { MaintenanceSearchByTextDto } from '../_base/MaintenanceSearchByTextDtos';
import { PanelElementInfoDto } from '@shared/src/dtos/panel/column/element/PanelElementInfoDto';
import { PanelElementFilterDto } from '@shared/src/dtos/panel/column/element/PanelElementFilterDto';
import { PanelListMaintenanceDto } from './PanelListMaintenanceDto';
import { PanelItemMaintenanceDto } from './PanelItemMaintenanceDto';
import { PanelStructCtrlDto } from '@shared/src/dtos/panel/PanelStructCtrlDto';
import { PanelConditionDto } from '@shared/src/dtos/panel/condition/PanelConditionDto';
import { SetElementColumnDto } from '@shared/src/dtos/panel/column/element/SetElementColumnDto';


@Injectable()
export class PanelMaintenanceController extends
    BaseMaintenanceController<PanelListMaintenanceDto, PanelItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "panel");
    }


    public getResourceRoot(): string {
        return "PANEL";
    }

    public buildUrl(uri: string): string {
        return "/system/screensandforms/panelsetting" + uri;
    }

    //////////////////////////////////////////////////////
    //////////////////// CONDICIONS //////////////////////
    //////////////////////////////////////////////////////	

    public getPanelConditionTypes(panelId: number): Observable<ActionsStripDto> {
        return this.get(this.getBaseUrl() + "/" + panelId + "/conditionstypes");
    }

    public getPanelConditions(panelId: number): Observable<Array<PanelConditionDto>> {
        return this.get(this.getBaseUrl() + "/" + panelId + "/conditions");
    }

    public createPanelCondition(panelId: number, data: PanelConditionDto): Observable<PanelConditionDto> {
        return this.post(this.getBaseUrl() + "/" + panelId + "/conditions/add", data, "createPanelCondition");
    }

    public clonePanelCondition(panelId: number, data: PanelConditionDto): Observable<PanelConditionDto> {
        return this.post(this.getBaseUrl() + "/" + panelId + "/conditions/clone", data, "clonePanelCondition");
    }

    public updatePanelCondition(panelId: number, data: PanelConditionDto): Observable<PanelConditionDto> {
        return this.put(this.getBaseUrl() + "/" + panelId + "/conditions/" + data.panelConditionId, data, "updatePanelCondition");
    }

    public deletePanelCondition(panelId: number, panelConditionId: number): Observable<boolean> {
        return this.delete(this.getBaseUrl() + "/" + panelId + "/conditions/" + panelConditionId);
    }

    public compileCode(panelId: number, data: PanelConditionDto): Observable<PanelConditionDto> {
        return this.post(this.getBaseUrl() + "/" + panelId + "/conditions/" + data.panelConditionId + "/compilecode", data, "compileCode");
    }

}
