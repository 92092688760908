import { Injectable } from '@angular/core';
import { HDate } from '@shared/src/datatypes/HDate';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { AddressTimetableIntervalDto } from '../address/AddressTimetableIntervalDto';

@Injectable()
export class OpenResource {
    public resourceId: number;
    public day: HDate;
    public interval: HHourInterval;

    public static build(item: AddressTimetableIntervalDto): OpenResource {
        const result = new OpenResource();
        result.resourceId = item.addressDaySettingResourceId;
        result.day = item.onDate;
        result.interval = item.interval;
        return result;
    }
}
