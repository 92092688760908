import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from "rxjs";

import { BaseController } from "../base.controller";
import { AlertService } from "../../services/alert/alert.service";
import { LoadingService } from "../../services/loading/loading.service";
import { userDto } from '@shared/src/dtos/user/userDto';
import { UserInfoCardDto } from "@shared/src/dtos/user/UserInfoCardDto";


@Injectable({
    providedIn: 'root',
})
export class UserController extends BaseController {
    private base = '/security';

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    /**
     * A back recopera l'usuari a partir de l'apy_key dels headers
     */
    public getUserData(): Observable<userDto>{
        return super.internal_get(this.base + "/user");
    }

    public getUserById(userId: number): Observable<UserInfoCardDto> {
        return this.get(this.base + "/user/" + userId)
    }
}