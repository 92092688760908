import { Pipe, PipeTransform } from '@angular/core';
import { OptionDto } from './OptionDto';

@Pipe({
    name: "orderoptiondto"
})
export class OrderOptionDtoFilter {
    transform(array: Array<OptionDto>): Array<OptionDto> {
        if (array == null)
            return array;
        array.sort((ao: any, bo: any) => {
            var a = 0;
            var b = 0;
            if (ao != null)
                a = (<OptionDto>ao).optionOrder;
            if (bo != null)
                b = (<OptionDto>bo).optionOrder;
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}