import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GeolocationController } from '@shared/src/controllers/geolocalization/geolocation.controller';
import { OpcionsMenu } from '@shared/src/enums/OpcionsMenu';
import { MobileAlertService } from '../../../services/mobile.alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MobileLoadingService } from '../../../services/mobile.loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LogController, LogLevels } from '@shared/src/controllers/log/LogController';
// Import the SDK in addition to any desired interfaces:
import BackgroundGeolocation, { Config, Coords, Location } from "cordova-background-geolocation";
import BackgroundFetch from "cordova-plugin-background-fetch";
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { ResponseData } from '@shared/src/controllers/ResponseData';
import { api } from '@shared/src/environments/environment';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AlertController } from '@ionic/angular';
import { MobileSecurityController } from '../security/mobile-security.controller';
import { MobileHomeController } from '../home/mobile-home.controller';

/****
 * Servei que gestiona la geolocalització.
 * Genera un event quan la nostra posició canvia
 * Escriu al back indicant la seva posició, això no deixa de ser un dispositiu
 */
@Injectable({
  providedIn: 'root'
})
export class MobileGeolocationController extends GeolocationController {

  private static readonly TEMPS_PRIMERA_LECTURA: number = 10 * 1000; // 10 SEGONS
  // private static readonly TEMPS_LECTURES_RECURRENTS: number = 1 * 60 * 1000; // 1 MINUTS
  private static readonly TEMPS_LECTURES_RECURRENTS: number = 5 * 60 * 1000; // 5 MINUTS relacionat amb TEMPS_LECTURES_RECURRENTS_BACKGROUND
  private static readonly TEMPS_ESTEM_SENSE_GPS: number = 4 * 60 * 1000; //  4 MINUTS
  private static readonly TEMPS_MAXIMUMAGE: number = 30 * 1000; // 30 segons

  private static readonly MAX_GEOLOCATION_INFO_ELEMENTS = 1000;

  public static ULTIMESCOORDENADES: GeoLocationDto = null;
  public positionChanged$: EventEmitter<GeoLocationDto> = new EventEmitter();
  public estemPerduts$: EventEmitter<boolean> = new EventEmitter();
  private readonly isIOS = Capacitor.getPlatform() === 'ios';
  private _estemPerduts = false;

  private baseurl = 'mobilegeolocation';

  private options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: MobileGeolocationController.TEMPS_MAXIMUMAGE,
  };
  private geoTimer: Subscription;
  private geoWatch: Subscription;


  private static geolocState = "stoped";

  constructor(
    private platform: Platform,
    private geolocation: Geolocation,
    private logController: LogController,
    @Inject('HomeController') private homeService: MobileHomeController,
    @Inject('SecurityController') protected securityController: MobileSecurityController,
    protected http: HttpClient,
    @Inject('AlertService') protected alertService: MobileAlertService,
    protected translateService: TranslateService,
    @Inject('LoadingService') protected loadingService: MobileLoadingService,
    protected router: Router,
    protected alertController: AlertController,
    @Optional()
    private androidPermissions?: AndroidPermissions
  ) {
    super(http, alertService, translateService, loadingService, router);

    this.securityController.loginStatusChanged$.subscribe(logged => {
      console.log('[geoLocService] loginStatusChanged');
      this.collectCurrentPositionEvent().then(p => { });
      this.geoLocServicesSwitch(logged);
    });

    this.homeService.homeChanged$.subscribe(home => {
      console.log('[geoLocService] homeChanged');
      this.securityController.isLogged().subscribe(logged => {
        this.geoLocServicesSwitch(logged);
      });
    });

    this.homeService.geolocationRequest$.subscribe((data: void) => {
      this.collectCurrentPositionEvent().then(p => { });
    })

    // this.securityController.isLogged().subscribe(x => { this.geoLocServicesSwitch(x); });
  }
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  private geoLocServicesSwitch(isLogged: boolean) {
    if (isLogged) {
      console.log("[geoLocService] geLocServiceSwitch isLogged");
      if (this.isEmpty(this.homeService.HOME))
        return;

      console.log("[geoLocService] geLocServiceSwitch geolocState:" + MobileGeolocationController.geolocState);
      if (MobileGeolocationController.geolocState != "started") {
        if (LogController.GEOLOCATIONMODE === 'none') {
          console.log("[geoLocService] GEOLOCATION MODE " + LogController.GEOLOCATIONMODE);
        } else if (LogController.GEOLOCATIONMODE === 'web') {
          this.startGelocation();
        } else {
          this.permissionIsNeeded().then(async permissionNeeded => {
            console.log("[geoLocService] permissionNeeded: " + permissionNeeded);
            if (permissionNeeded) {
              await this.askForPermission();
            }
            else {
              console.log("[geoLocService] call startGelocation");
              this.startGelocation();
            }
          });
        }
        console.log("[geoLocService] set geolocState started");
        MobileGeolocationController.geolocState = "started";
      }
    } else {
      if (MobileGeolocationController.geolocState != "stoped") {
        if (LogController.GEOLOCATIONMODE === 'none') {
        } else {
          this.stopTimerForeRun(); // Aturem Fore
          this.stopIOSAndroidBackGround(); // Aturem Bck
          console.log("[geoLocService] set geolocState stoped");
          MobileGeolocationController.geolocState = "stoped";
        }
      }
    }
  }

  private startGelocation() {
    console.log("[geoLocService] startGelocation");
    this.startTimerForeRun(); // Quan estem en Fore
    this.configBackgroundGeolocation();
    if (LogController.GEOLOCATIONMODE === 'backgroundFetch') {
      this.startIOSAndroidBackGroundFetch();
    }
  }

  private permissionIsNeeded(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      console.log("[geoLocService] tenim producte drver: " + this.homeService.hasProduct(HardmanProducts.driver));
      if (this.homeService.hasProduct(HardmanProducts.driver)) {
        if ('android' === Capacitor.getPlatform()) {
          console.log("[geoLocService] comprovem si es necessita permís Android");
          if (this.androidPermissions != null) {
            await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(permission => {
              console.log("[geoLocService] comprovem si tenim el permís " + this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION + ": " + JSON.stringify(permission));
              resolve(!permission.hasPermission);
            }, error => {
              console.log('[geoLocService] PermERR: ', error);
              //TODO: alert
            });
          }
        }
      }
      resolve(false);
    });
  }

  async askForPermission() {
    console.log('[geoLocService] askForPermission: ', this.alertController);
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translateService.instant('MOBILEGEOLOCATIONCONTROLLER.ASFORPERMISSION.HEADER'),
      subHeader: this.translateService.instant('MOBILEGEOLOCATIONCONTROLLER.ASFORPERMISSION.SUBHEADER'),
      message: this.translateService.instant('MOBILEGEOLOCATIONCONTROLLER.ASFORPERMISSION.MESSAGE'),
      buttons: [
        {
          text: this.translateService.instant('MOBILEGEOLOCATIONCONTROLLER.ASFORPERMISSION.BUTTON'),
          handler: () => {
            console.log('[geoLocService] Button Handler: going to startGeolocation...');
            this.startGelocation();
            return true;
          }
        }
      ]
    });

    await alert.present();
  }

  configBackgroundGeolocation() {
    console.log("[geoLocService] platform: " + Capacitor.getPlatform());
    console.log("[geoLocService] mode: " + LogController.GEOLOCATIONMODE);

    const iOSConfig = {
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 100,
      stationaryRadius: 100,
      stopOnTerminate: false,
      startOnBoot: true,
      // HTTP & Persistence Options
      // https://transistorsoft.github.io/cordova-background-geolocation/interfaces/_cordova_background_geolocation_.config.html
      // #http-amp-persistence-options
      headers: { Authorization: this.securityController.getAuthorizationHeaderValue() },
      url: api(this.baseurl + '/bg'),
      locationTemplate: '{"moment":"<%= timestamp %>","latitude":<%= latitude %>,"longitude":<%= longitude %>,"speed":<%= speed %>,"accuracy":<%= accuracy %>,"heading":<%= heading %>,"altitude":<%= altitude %>,"altitudeAccuracy":<%= altitude_accuracy %>}',
      method: 'PUT',
      httpRootProperty: '.',
    };
    const androidConfig: Config = {
      // debug: true,
      // preventSuspend: true, // ios
      foregroundService: true, // android
      // logLevel: BackgroundGeolocation.LOG_LEVEL_DEBUG,
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: this.isIOS ? 10 : 0,  // ios = 10, android = 0
      locationUpdateInterval: 300000,  // 5 minuts
      // stationaryRadius: 10,  // meters
      allowIdenticalLocations: true,
      stopOnTerminate: false,
      startOnBoot: true,
      backgroundPermissionRationale: {
        title: this.translateService.instant("MOBILEGEOLOCATIONCONTROLLER.BACKGROUNDPERMISSIONRATIONALE.TITLE"),
        message: this.translateService.instant("MOBILEGEOLOCATIONCONTROLLER.BACKGROUNDPERMISSIONRATIONALE.MESSAGE"),
        positiveAction: this.translateService.instant("MOBILEGEOLOCATIONCONTROLLER.BACKGROUNDPERMISSIONRATIONALE.POSITIVEACTION"),
        negativeAction: this.translateService.instant("CANCEL.BUTTON")
      },
      // HTTP & Persistence Options
      // https://transistorsoft.github.io/cordova-background-geolocation/interfaces/_cordova_background_geolocation_.config.html
      // #http-amp-persistence-options
      headers: { Authorization: this.securityController.getAuthorizationHeaderValue() },
      url: api(this.baseurl + '/bg'),
      locationTemplate: '{"moment":"<%= timestamp %>","latitude":<%= latitude %>,"longitude":<%= longitude %>,"speed":<%= speed %>,"accuracy":<%= accuracy %>,"heading":<%= heading %>,"altitude":<%= altitude %>,"altitudeAccuracy":<%= altitude_accuracy %>}',
      method: 'PUT',
      httpRootProperty: '.',
    };

    let config = {};
    if (LogController.GEOLOCATIONMODE === 'backgroundFetch') {
      config = androidConfig;
    } else if (LogController.GEOLOCATIONMODE === 'backgroundGeolocation') {
      config = iOSConfig;
    } else {
      throw new Error(
        `GEOLOCATIONMODE must be (backgroundFetch | backgroundGeolocation). (${LogController.GEOLOCATIONMODE}) was given`
      );
    }
    console.log(`[geoLocService] API Key: isNullOrNone(${(!this.securityController.getAuthorizationHeaderValue())})`);
    console.log("[geoLocService] config: " + JSON.stringify(config));

    // if (Capacitor.getPlatform() === 'ios') {
    BackgroundGeolocation.onLocation(location => {
      console.log('[geoLocService] - ', JSON.stringify(location));
      const result = GeoLocationDto.build(Number(location.timestamp), location.coords);
      this.saveCoordenades(result);
    });
    // }
    BackgroundGeolocation.ready(config, state => {
      console.log('[geoLocService] BackgroundGeolocation.ready success: ', state);
      // if (Capacitor.getPlatform() === 'ios') {
      BackgroundGeolocation.start().then(e => {
        console.log("[geoLocService] BackgroundGeolocation.start then: " + e);
      }).catch(e => {
        console.log("[geoLocService] BackgroundGeolocation.start catch: " + e);
      });
      // }
    }, error => {
      console.error('[geoLocService] BackgroundGeolocation.ready error: ', error);
    });
  }

  /***
   * Inicia el sistema de BackGround en IOS
   * Al Info.plst s'ha d'habilitar aquesta crida com.transistorsoft.fetch sota BGTaskSchedulerPermittedIdentifiers al config.xml
   */
  startIOSAndroidBackGroundFetch() {
    if (
      (Capacitor.getPlatform() !== 'ios' && Capacitor.getPlatform() !== 'android') || LogController.GEOLOCATIONMODE === 'none'
    ) {
      return;
    }
    console.log(`[geoLocService] startIOSAndroidBackGroundFetch`);
    const fetchCallback = async (taskId) => {
      console.log(`[geoLocService] BackgroundFetch task(${taskId})`);
      try {
        const location = await BackgroundGeolocation.getCurrentPosition({
          maximumAge: 0,
          samples: 1,
          persist: true
        });
        /*
        * No cal usar una cua de mètriques no enviades. El plugin ho gestiona automàticament.
        */
        console.log(
          `[geoLocService] [BackgroundFetch][getCurrentPosition] `,
          `ts(${location.timestamp}), coords(${location.coords.latitude},${location.coords.longitude})`
        );
        const GeoLocDto = GeoLocationDto.build(Number(location.timestamp), location.coords);
        this.saveCoordenades(GeoLocDto);
        // let result = await this.directPut(this.baseurl + '', GeoLocDto);  // <-- make return a Promise.
        // console.log('[directPut] - result: ', result);
      } catch (error) {
        console.warn('ERROR: ', error);
      } finally {
        BackgroundFetch.finish(taskId);
      }
    };

    const failureCallback = (error) => {
      console.error(`[BackgroundFetch] failed: ${error}`);
      this.logController.logMessage(LogLevels.Error, 'MobileGeolocationController', 'failureCallback: ' + error);
    };

    var status = BackgroundFetch.configure({ minimumFetchInterval: 15 }, fetchCallback, failureCallback);
    console.log('[geoLocService] [BackgroundFetch] configure status: ', status);
  }
  /***
   * Para el sistma de background per IOS
   */
  stopIOSAndroidBackGround() {
    //Casos simples
    if (Capacitor.getPlatform() !== 'ios' && Capacitor.getPlatform() !== 'android')
      return;
    if (LogController.GEOLOCATIONMODE === 'none')
      return;

    if (LogController.GEOLOCATIONMODE === 'backgroundFetch')
      this.platform.ready().then(s => { BackgroundFetch.stop(); });
    this.platform.ready().then(s => { BackgroundGeolocation.stop(); });
  }


  public collectCurrentPositionEvent(): Promise<void> {
    return new Promise<void>(resolve => {
      //Mirar si tinc ruta activa
      if (this.homeService.HOME && this.homeService.HOME.sendPosition) {
        this.collectCurrentPosition(false).then(p => {
          resolve();
        });
      }
      else
        resolve();

    });
  }

  public collectCurrentPosition(automatic: boolean): Promise<GeoLocationDto> {
    return new Promise<GeoLocationDto>(resolve => {
      this.geolocation.getCurrentPosition().then(location => {
        const result = GeoLocationDto.build(Number(location.timestamp), location.coords);
        result.automatic = automatic;
        this.saveCoordenades(result);
        resolve(result);
      }).catch(error => {
        throw (error);
      });
    });
  }

  public collectCurrentPositionBackground(): Promise<GeoLocationDto> {
    return new Promise<GeoLocationDto>(resolve => {
      this.getCurrentPositionBackground().then(location => {
        const result = GeoLocationDto.build(Number(location.timestamp), location.coords);
        result.automatic = true;
        this.saveCoordenades(result);
        resolve(result);
      });
    });
  }

  getCurrentPositionBackground(): Promise<Location> {
    return new Promise<Location>(resolve => {
      BackgroundGeolocation.getCurrentPosition({
        // timeout: 5,
        maximumAge: 0,
        samples: 1,
        persist: true
      }).then((location) => {
        resolve(location);
      }).catch(err => {
        console.error(err);
        return err;
      });
    });
  }

  // startAplicacioActiva() {
  //   this.geolocation.getCurrentPosition(this.options).then((position) => {
  //     this.saveCoordenades(GeoLocationDto.build(position.timestamp, position.coords));
  //   }).catch((error) => {
  //     this.saveCoordenades(GeoLocationDto.buildLost());
  //   });
  //
  //   this.securityController.isLogged().subscribe(logged => {
  //     if (logged) {
  //
  //     }
  //   });
  // }

  ngOnDestroy() {
    if (this.geoTimer != null) {
      this.geoTimer.unsubscribe();
    }
    this.platform.ready().then(() => {
      // BackgroundFetch.stop();
    });
  }

  public startTimerForeRun() {
    console.log("[geoLocService] inicio geoTimer " + MobileGeolocationController.TEMPS_PRIMERA_LECTURA + " " + MobileGeolocationController.TEMPS_LECTURES_RECURRENTS);

    this.geoTimer = timer(
      MobileGeolocationController.TEMPS_PRIMERA_LECTURA, MobileGeolocationController.TEMPS_LECTURES_RECURRENTS
    ).subscribe(time => {
      console.log("[geoLocService] geoTimer collectCurrentPosition ");
      this.collectCurrentPosition(true).catch((error) => {
        console.log("[geoLocService] geoTimer collectCurrentPosition 2");
        this.saveCoordenades(GeoLocationDto.buildLost());
      });
    });
  }
  public stopTimerForeRun() {
    if (this.geoTimer != null) {
      this.geoTimer.unsubscribe();
    }
  }

  public araEstemPerduts(value: boolean): void {
    if (this._estemPerduts === value) {
      return;
    }
    this.logController.logMessage(LogLevels.Warning, 'MobileGeolocationController', 'Estem perduts ' + value);
    this._estemPerduts = value;
    this.estemPerduts$.next(value);
  }

  public caducades(coordenades: GeoLocationDto): boolean {
    if (coordenades == null) {
      return true;
    } else {
      const time = new Date().getTime() - coordenades.moment;
      if (time > MobileGeolocationController.TEMPS_ESTEM_SENSE_GPS) {
        return true;
      }
    }
    return false;
  }

  /***
   * Ens indica si estem perduts, vol dir que no tenim senyal de GPS
   */
  public estemPerduts(): boolean {
    return this._estemPerduts;
  }

  /***
   * Desa les coordenades al back o bé en una estructura propia interna
   * De tal manera que al recueprar la conexió les desa totes.
   * Desar-les totes ho farem quan torni a "saveCoordenades".Mirem tenim coses pendents al pap? Si és que si, les enviem totes LIFO
   * Les coordenades les desarem a nom de l'usuari i del dispositiu, ententen el dispositiu com un DEVICETOKEN
   */
  public saveCoordenades(coordenades: GeoLocationDto): GeoLocationDto {
    MobileGeolocationController.ULTIMESCOORDENADES = coordenades;
    this.securityController.getStorageValue(LogController.GEOLOCATION_QUEUE_NAME).then((value) => {
      let array = new Array<GeoLocationDto>();
      if (value == null || value.trim() === '') {
        //TODO: Alert?
      } else {
        array = JSON.parse(value);
        if (!Array.isArray(array)) {
          array = new Array<GeoLocationDto>();
        }
      }
      array.push(coordenades);
      this.securityController.setStorageValue(LogController.GEOLOCATION_QUEUE_NAME, JSON.stringify(array));
      // Ara ens cal intentem desar la geolocation al back i si no podem l'encuem.
      // Es una lifo, això potser hauríem de crear un mecanisme de cues genèric que ens permeti quan ens trobem
      // en aquesta situació posar totes les crides a fer.
      this.sendNextPending(array);
    });
    this.securityController.getStorageValue(LogController.GEOLOCATION_INFO_NAME).then((value) => {
      let array = new Array<GeoLocationDto>();
      if (value == null || value.trim() === '') {
      } else {
        array = JSON.parse(value);
        if (!Array.isArray(array)) {
          array = new Array<GeoLocationDto>();
        }
        if (array.length > MobileGeolocationController.MAX_GEOLOCATION_INFO_ELEMENTS) {
          array.pop();
        }
      }
      array.push(coordenades);
      this.securityController.setStorageValue(LogController.GEOLOCATION_INFO_NAME, JSON.stringify(array));
      this.araEstemPerduts(coordenades.isLost);
      this.positionChanged$.next(coordenades);

    });

    return coordenades;
  }

  /***
   * Una recursiva. Enviem el següent de la llista si hem pogut enviar l'anterior.
   * L'únic problma es que n'hi haguessin tant que petes la pila però per ara descarto aquest problema
   */
  public sendNextPending(array: Array<GeoLocationDto>) {
    if (array == null || array.length === 0) {
    } else {
      this.securityController.isLogged().subscribe(islogged => {
        if (islogged) {
          const nextInLine = array[0]; // Agafo el primer de l'array

          this.put(this.baseurl + '', nextInLine, 'geolocation').subscribe(data => {
            if (data) {
              // shift -> FIFO, pop()-> LIFO. Això està relacioant amb el this_queue[0]. El treiem de la llista quan l'hem enviat.
              array.shift();
              this.securityController.setStorageValue(LogController.GEOLOCATION_QUEUE_NAME, JSON.stringify(array));
              this.sendNextPending(array);
            }
          });
        } else {
        }
      });
    }
  }


  public getCoordenadesDesades(): Promise<Array<GeoLocationDto>> {
    return new Promise<Array<GeoLocationDto>>(resolve => {
      this.securityController.getStorageValue(LogController.GEOLOCATION_INFO_NAME).then((value) => {
        if (value == null || value.trim() === '') {
          resolve(null);
        } else {
          const array = JSON.parse(value);
          if (Array.isArray(array)) {
            resolve(array);
          } else {
            resolve(null);
          }
        }
      }).catch();
    });
  }

  public isLocation(arg: any): arg is Location {
    return arg && arg.timestamp && typeof (arg.timestamp) === 'string' && arg.coords;
  }

  // public isGeoPosition(arg: any): arg is Geoposition {
  //   return arg && arg.timestamp && typeof (arg.timestamp) === 'number' && arg.coords;
  // }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  /***
 * Base put
 */
  private async directPut<T>(url: string, body: any): Promise<ResponseData<boolean>> {
    return this.http.put<ResponseData<boolean>>(api(url), body, { headers: this.getHeader() }).toPromise();
  }
  protected getHeader(): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }
}


/***
 * Pues eso, son les coordenades
 * https://transistorsoft.github.io/cordova-background-geolocation/interfaces/_cordova_background_geolocation_.config.html#locationtemplate
 */
export class GeoLocationDto {
  public moment: number;
  public latitude: number;
  public longitude: number;
  public speed: number;
  public accuracy: number;
  public heading: number;
  public altitude: number;
  public altitudeAccuracy: number;
  public isLost = false;
  public automatic: boolean;

  public static build(timestamp: number, coordinates: Coords): GeoLocationDto {
    const result = new GeoLocationDto();
    result.moment = timestamp;
    result.latitude = coordinates.latitude;
    result.longitude = coordinates.longitude;
    result.accuracy = coordinates.accuracy;
    result.speed = coordinates.speed;
    result.altitude = coordinates.altitude;
    result.altitudeAccuracy = coordinates.altitude_accuracy;
    result.heading = coordinates.heading;
    result.isLost = false;
    return result;
  }

  // public static buildBackground(coordinates: any): GeoLocationDto {
  //   const result = new GeoLocationDto();
  //   result.moment = coordinates.time;
  //   result.latitude = coordinates.latitude;
  //   result.longitude = coordinates.longitude;
  //   result.accuracy = coordinates.accuracy;
  //   result.speed = coordinates.speed;
  //   result.altitude = coordinates.altitude;
  //   // result.altitudeAccuracy = coordinates.altitudeAccuracy;
  //   // result.heading = coordinates.heading;
  //   result.isLost = false;
  //   return result;
  // }

  public static buildLost(): GeoLocationDto {
    const result = new GeoLocationDto();
    result.moment = Date.now();
    result.isLost = true;
    return result;
  }
}
