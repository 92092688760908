import { Injectable } from '@angular/core';
import { HTags } from '@shared/src/datatypes/HTags';
import { PalletTypes } from '@shared/src/enums/PalletTypes';

@Injectable()
export class ShipmentDeliveryLineDto {

    public shipmentDeliveryLineId: number;
    public shipmentDeliveryId: number;
    public shipmentId: number;
    public line: number;
    public quantity: number;
    public reference: string;
    public description: string;
    public unitPrice: number;
    public totalPrice: number;
    public kg: number;
    public wDimension: number;
    public yDimension: number;
    public hDimension: number;
    public m3: number;
    public palletCount: number;
    public palletType: PalletTypes;
    public packageId: number;
    public userTags: HTags;

    public static actualizedData(from: ShipmentDeliveryLineDto, to: ShipmentDeliveryLineDto) {
        if (to == null || from == null)
            return;

        to.line = from.line;
        to.quantity = from.quantity;
        to.reference = from.reference;
        to.description = from.description;
        to.unitPrice = from.unitPrice;
        to.totalPrice = from.totalPrice;
        to.kg = from.kg;
        to.wDimension = from.wDimension;
        to.yDimension = from.yDimension;
        to.hDimension = from.hDimension;
        to.m3 = from.m3;
        to.palletCount = from.palletCount;
        to.palletType = from.palletType;
        to.packageId = from.packageId;
        to.userTags = from.userTags;
    }
}
