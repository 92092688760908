import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomTableColumnData } from '@shared/src/controllers/maintenance/tenant/custom/table/data/CustomTableColumnData';

import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DataTypes } from '@shared/src/public-api';

@Component({
    selector: 'shared-customtable-column',
    templateUrl: './shared.customtable.column.component.html',
    styleUrls: ['./shared.customtable.column.component.scss']
})
export class SharedCustomTableColumnComponent implements OnInit {

    @Input() public readonly: boolean;
    @Input() public inline: boolean = false;

    enumDataTypes = DataTypes;

    @Output() changedTableData: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteColumnCanceled: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteColumnSelected: EventEmitter<void> = new EventEmitter<void>();
    @Output() deleteColumn: EventEmitter<void> = new EventEmitter<void>();

    public columnChanged = new Subject<number>();

    constructor() {

        this.columnChanged
            .pipe(
                // Time in milliseconds between key events
                debounceTime(1000))
            // subscription for response
            .subscribe(() => {
                if (this._columnData) {
                    this.changedTableData.next();
                }
            });

    }

    private _columnData: CustomTableColumnData;

    public get columnData(): CustomTableColumnData {
        return this._columnData;
    }

    @Input()
    public set columnData(value: CustomTableColumnData) {
        if (this._columnData === value)
            return;

        this._columnData = value;
        if (this._columnData != null)
            this.dataType = this._columnData.dataType;

    }

    public dataType: DataTypes = DataTypes.String;

    public valueChangedEvent() {
        console.log("valueChanged");
        this.columnChanged.next();
    }

    public valueChangedDataType(value: any) {
        if (this._columnData != null && value.option) {
            this._columnData.dataType = value.option.value;
            this.dataType = value;
            this.columnChanged.next();
        }
    }

    public onDeleteColumnCanceled() {
        this.deleteColumnCanceled.next();
    }
    public onDeleteColumnSelected() {
        this.deleteColumnSelected.next();
    }
    public onDeleteColumn() {
        this.deleteColumn.next();
    }

    ngOnInit(): void {

    }

}
