<div [formGroup]="rForm">
  <div *ngIf="!useIonic">
    <mat-form-field [ngClass]="{'readonly':readonly}">
      <input matInput [id]="identifier" [(ngModel)]="displayvalue" formControlName="eselvalidator"
        [formControl]="enumCtrl" [placeholder]="placeholder" [matAutocomplete]="reactiveAuto" autocomplete="off"
        style="width: 200px; width:100%" [readonly]="readonly" (click)=onContainerClick($event) (blur)="onLostFocus()"
        (change)="onInputChange($event)">
      <mat-autocomplete #reactiveAuto="matAutocomplete" mat-select-on-match=true [displayWith]="displayFn"
        (optionSelected)="selectOption($event);">

        <mat-option *ngFor="let item of reactiveItems | async" [value]="item.value">
          {{getValueTranslation(item.key)}} -[{{item.key}}]
        </mat-option>
      </mat-autocomplete>
      <button matButton *ngIf="value && !readonly && showclosebutton" matSuffix mat-icon-button aria-label="Clear"
        (click)="onClear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div *ngIf="useIonic">
    <ion-input hidden [id]="identifier" [(ngModel)]="displayvalue" formControlName="eselvalidator"
      [formControl]="enumCtrl" ngDefaultControl></ion-input>
    <ion-item [ngClass]="{'readonly':readonly}">
      <ion-select *ngIf="displayvalue" [selectedText]="displayvalue" [placeholder]="placeholder" [disabled]="readonly"
        (ionChange)="selectOption($event)">
        <ion-select-option ion-select-option *ngFor="let item of reactiveItems | async"
          [value]="getEnumFromString(item.value)">
          {{getValueTranslation(item.key)}}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </div>
</div>