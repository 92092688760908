import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { ClientProviderViewShipmentDto } from "@shared/src/dtos/shipment/ClientProviderViewShipmentDto";
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
    selector: "shared-clientprovider-view-shipment-card-component",
    templateUrl: "./shared.clientprovider.view.shipment.card.component.html",
    styleUrls: ["./shared.clientprovider.view.shipment.card.component.scss"],
})
export class SharedClientProviderViewShipmentCardComponent implements OnInit, OnDestroy {

    public useIonic: boolean = false;
    constructor(@Inject('HomeController') public homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    ngOnDestroy(): void { }

    ngOnInit(): void { }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

    @Input() public detailed: boolean = false;

    private _shipment: ClientProviderViewShipmentDto;
    @Input() public set shipment(value: ClientProviderViewShipmentDto) {
        this._shipment = value;
        this._shipment.lastMoment = HDateHour.cloneByHDateHour(this._shipment.lastMoment);
        this._shipment.firstMoment = HDateHour.cloneByHDateHour(this._shipment.firstMoment);
    }

    public get shipment(): ClientProviderViewShipmentDto {
        return this._shipment;
    }

}
