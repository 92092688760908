import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { ESchenkerDetailsDto } from '@shared/src/dtos/shipment/dbschenker/ESchenkerDetailsDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs-compat';
import { BaseController } from '../../../base.controller';
import { SecurityController } from '../../../security/security.controller';
import { HDate } from '@shared/src/public-api';

@Injectable({
    providedIn: 'root',
})
export class ESchenkerController extends BaseController {

    private base = '/eschenker'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getESchenkerDetails(packageId: number): Observable<ESchenkerDetailsDto> {
        return this.get(this.base + "/details/" + packageId);
    }

    public getESchenkerDetailsByShipment(shipmentId: number): Observable<ESchenkerDetailsDto> {
        return this.get(this.base + "/detailsbyshipment/" + shipmentId);
    }

    public downloadLabel(eschenkerPackageDataId: number): Observable<FileUpDownLoadDto> {
        return this.getBusy(this.base + "/downloadlabel/" + eschenkerPackageDataId);
    }

    public downloadLabelByPackage(packageDataId: number): Observable<FileUpDownLoadDto> {
        return this.getBusy(this.base + "/downloadlabelbypackageid/" + packageDataId);
    }
    
    public downloadLabelByDate(date: HDate): Observable<FileUpDownLoadDto> {
        return this.getBusy(this.base + "/downloadlabelbydate/" + date.toStringRequest());
    }

    public getDocumentLabel(eschenkerPackageDataId: number): Observable<DocumentDetailDto> {
        return this.getBusy(this.base + "/documentlabel/" + eschenkerPackageDataId);
    }

    public getDocumentLabelByPackage(packageDataId: number): Observable<DocumentDetailDto> {
        return this.getBusy(this.base + "/documentlabelbypackageid/" + packageDataId);
    }

    public createLabel(packageId: number): Observable<ESchenkerDetailsDto> {
        return this.getBusy(this.base + "/createlabel/" + packageId);
    }

    public getLabel(packageId: number): Observable<ESchenkerDetailsDto> {
        return this.getBusy(this.base + "/getlabel/" + packageId);
    }

    public createLabelByPackages(packageIds: Array<number>): Observable<Boolean> {
        return this.postBusy(this.base + "/createlabel", packageIds, "createLabelByPackages");
    }

    public createLabelByShipment(shipmentId: number): Observable<ESchenkerDetailsDto> {
        return this.getBusy(this.base + "/createlabelbyshipmentid/" + shipmentId);
    }
}
