import { Pipe, PipeTransform } from '@angular/core';
import { BookingDto } from '../dtos/booking/BookingDto';

@Pipe({
    name: 'excludeBookings'
})
export class ExcludeBookingsPipe implements PipeTransform {

    transform(items: BookingDto[], exactMatchBookings: BookingDto[]): any[] {
        if (!items) {
            return [];
        }
        if (!exactMatchBookings) {
            return items;
        }
        let result = items.filter(it => {
            let trobat = false;
            for (let i = 0; i < exactMatchBookings.length; i++) {
                if (it.bookingNumber === exactMatchBookings[i].bookingNumber)
                    return false;
            }
            return true;
        });

        return result;
    }
}