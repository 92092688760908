import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HHour } from '@shared/src/datatypes/HHour';
import { HTime } from '@shared/src/datatypes/HTime';

/**
 * Validador de temps, permet valors amb format ####:##
 */

@Directive({
  selector: '[validateHour][ngModel],[validateHour][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => HourValidator), multi: true }
  ]
})
export class HourValidator implements Validator {

  public constructor() { }


  public static validate(control: AbstractControl): ValidationErrors | null {
    if (!control)
      return HourValidator.getInvalidResult();
    let value = control.value;
    if (!HourValidator.isValid(value))
      return HourValidator.getInvalidResult();
    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    if (value instanceof HHour)
      return true;

    if (value instanceof HTime)
      value = value.toString();

    if (typeof value === 'string' || value instanceof String)
      if (HHour.isValidFormat(value))
        return true;

      if (typeof value === 'object' || value instanceof Date) {
          var hour: number = null;
          var minute: number = null;
          if(value.h != undefined)
              hour = value.h;
          if(value.m != undefined)
              minute = value.m;
          if(value.getHours != undefined)
              hour = value.getHours();
          if(value.getMinutes != undefined)
              minute = value.getMinutes();

          if(hour != null || minute != null){
            let hhour = new HHour(hour, minute);
            if (HHour.isValidFormat(hhour.toString()))
              return true;
          }
      }
        
    return false;
  }


  public validate(c: AbstractControl): ValidationErrors | null {
    return HourValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateHour: false
    };
  }
}