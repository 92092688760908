import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { QuestionListMaintenanceDto } from './QuestionListMaintenanceDto';
import { QuestionItemMaintenanceDto } from './QuestionItemMaintenanceDto';
import { BaseMaintenanceController } from '../../_base/BaseMaintenanceController';
import {AlertService} from '@shared/src/services/alert/alert.service';
import {LoadingService} from '@shared/src/services/loading/loading.service';

@Injectable()
export class QuestionMaintenanceController extends BaseMaintenanceController<QuestionListMaintenanceDto, QuestionItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "question");
    }


    public getResourceRoot(): string {
        return "QUESTION";
    }

    public buildUrl(uri: string): string {
        return "/system/screensandforms/formquestion" + uri;
    }
    
    public postNewItemQuestion(newItem: QuestionItemMaintenanceDto): Observable<QuestionItemMaintenanceDto> {
        return this.post(this.getBaseUrl() + "/newquestion", newItem, "BaseMaintenanceController.postNewItemQuestion." + this.baseUrl);
    }
 
}
