import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartnerTypes } from '@shared/src/enums/PartnerTypes';
import { PartnerDetailDto } from '@shared/src/dtos/link/detail/PartnerDetailDto';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';

@Component({
  selector: 'shared-partner-detail',
  templateUrl: './partner.detail.component.html',
  styleUrls: ['./partner.detail.component.scss']
})
export class PartnerDetailComponent implements OnInit {

  public readonly: boolean = false;

  constructor(
    protected partnersController: PartnersController,
    @Inject('SecurityController') protected securityController: SecurityController,
    @Inject('HomeController') protected homecontroller: HomeController,
    private fb: FormBuilder) {
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();

    this.companyFormGroup = PartnerDetailComponent.buildFromCompany(fb);
    this.driverFormGroup = PartnerDetailComponent.buildFromDriver(fb);

    this.securityController.getStoredUserDto().subscribe(data => {
      this.readonly = data.isReadOnly;
    })
    this.useIonic = homecontroller.useIonic();
    this.isIos = this.homecontroller.getOS() == 'ios';
  }

  public useIonic: boolean = false;
  public isIos: boolean = false;

  @Input() public linkType: PartnerTypes;

  public companyFormGroup: FormGroup;
  public driverFormGroup: FormGroup;
  @Input() public set formGroup(value: FormGroup) {
    if (this.companyFormGroup === value)
      return;
    if (this.linkType == PartnerTypes.company) {
      this.companyFormGroup = value;
    } else if (this.linkType == PartnerTypes.driver) {
      this.driverFormGroup = value;
    }
  }
  public get formGroup(): FormGroup {
    if (this.linkType == PartnerTypes.company)
      return this.companyFormGroup;
    else if (this.linkType == PartnerTypes.driver)
      return this.driverFormGroup;
    return null;
  }

  private _link: PartnerDetailDto;
  @Input() public set link(value: PartnerDetailDto) {
    if (this._link === value)
      return;
    this._link = value;
  }
  public get link(): PartnerDetailDto {
    return this._link;
  }

  public static buildFromCompany(fb: FormBuilder) {
    return fb.group({
      'partnerKey': [null, Validators.required],
      'tradeMarkTarget': [null, Validators.required],
      'organitzationNameTarget': [null, Validators.required],
      'nifTarget': [null, Validators.required],
      'referenceNameTarget': [null, Validators.required],
      'emailTarget': [null, [Validators.required, Validators.email]]
    });


  }

  public static buildFromDriver(fb: FormBuilder) {
    return fb.group({
      // 'partnerKey': [null, Validators.required],
      // 'tradeMarkTarget': [null, Validators.required],
      'referenceNameTarget': [null, Validators.required],
      // 'emailTarget': [null, [Validators.email]],
      'prefixTarget': [null, Validators.nullValidator],
      'phoneTarget': [null, Validators.required]
    });
  }

  public generalClass: string = "desktop";
  getGeneralClass() {
    return this.generalClass;
  }

  ngOnInit(): void {

  }

  public enumPrefixes = PhonePrefix;
  public translategroup: string = 'PHONEPREFIX.ENUM';

  public _prefixModel: any;
  set prefixModel(value: string) {
    if (PhonePrefix[value])
      this.link.prefixTarget = PhonePrefix[value];
    console.log("this.prefixModel", this.prefixModel)
    console.log("prefixTarget", this.link.prefixTarget)
  }
  get prefixModel(): any {
    return this.link.prefixTarget;
  }
}
