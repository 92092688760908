
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class FestivesEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): FestivesEntitySelectorDto{
        let result: FestivesEntitySelectorDto = new FestivesEntitySelectorDto();
		result.loadData(EntitysSelectors.Festives, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/festives";
	}

	public getUrlBack(): string {
		return "/maintenance/festives";
	}
	
}