<div class="container" [ngClass]="generalClass" matTooltip="{{ tooltip }}" (click)="nextView()">
    <div class="xslotInfo real">
        <div class="primeralinia">
            <span class="date  texteaugmentar" *ngIf="slotInfo">{{slotInfo.onDate | date }}</span>
            <span class="only-dock"><i class="fa fa-building" aria-hidden="true"></i></span>
            <span class="only-lift"><i class="fa fa-th" aria-hidden="true"></i></span>
        </div>
        <div class="segonalinia">
            <div class="icones">
                <i class="fa fa-bed" aria-hidden="true"></i>
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <i class="fa fa-lock internal" aria-hidden="true"></i>
                <i class="fa fa-unlock internal" aria-hidden="true"></i>
                <i class="fa fa-lock external" aria-hidden="true"></i>
                <i class="fa fa-unlock external" aria-hidden="true"></i>
            </div>
            <span class="interval texteaugmentar" *ngIf="slotInfo">
                <span class="only-documentation">{{ slotInfo.interval | interval }}</span>
                <span class="only-dock">{{ slotInfo.intervalDock | interval }}</span>
                <span class="only-lift">{{ slotInfo.intervalLift | interval }}</span>
            </span>
            <span class="noslot pendingSlotQty"
                *ngIf="slotInfo && slotInfo.pendingSlotQty>0 && !slotInfo.isBlockedBookSlotSystem">
                {{ 'ROUTEITEMCOMPONENT.NOSLOTCOUNT.LABEL' |
                translate:{pendingSlotQty:slotInfo.pendingSlotQty } }}
            </span>
            <span class="noslot"
                *ngIf="!slotInfo || (slotInfo && !slotInfo.isBlockedBookSlotSystem && (slotInfo.pendingSlotQty==0 || slotInfo.pendingSlotQty==undefined) )">
                {{ 'ROUTEITEMCOMPONENT.NOSLOT.LABEL' | translate }}
            </span>
            <span class="noslot" *ngIf="(slotInfo && slotInfo.isBlockedBookSlotSystem)">
                {{ 'SLOTVIEWERCOMPONENT.NODISPONIBLE.LABEL' | translate }}
            </span>
        </div>
        <div class="terceralinia">
            <div class="only-ambslot slotInfo-log" *ngIf="lastLog">
                <span>{{lastLog.modifiedAt | datehournosecondsnotzobserver}} @{{lastLog.userName}}</span>
                <br>
                <span class="message">
                    {{lastLog.message}}
                </span>
            </div>
        </div>
    </div>

    <div class="xslotInfo lost" *ngIf="!(!__lostSlotInfo || !__lostSlotInfo.slotId || __lostSlotInfo.slotId==0)"
        matTooltip="{{ 'SHARED.PHASE.LOSTSLOT.TOOLTIP' | translate }}">
        <div class="primeralinia">
            <span class="lost-date">{{__lostSlotInfo.onDate | date }}</span>
        </div>
        <div class="segonalinia">
            <i class="fa fa-lock internal" aria-hidden="true"></i>
            <i class="fa fa-unlock internal" aria-hidden="true"></i>
            <i class="fa fa-lock external" aria-hidden="true"></i>
            <i class="fa fa-unlock external" aria-hidden="true"></i>
            <span class="lost-interval">{{ __lostSlotInfo.interval | interval }}</span>
        </div>
    </div>
</div>