//Enum per definir què ensenyem a la pàgina de vincles
export enum PartnerTypes {
    company = "company",
    driver = "driver",
    carrier = "carrier",
    finalcustomer = "finalcustomer",
    provider = "provider",
    logisticoperator = "logisticoperator",
    partner = "partner",
    none = "none"
}
