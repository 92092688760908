import { Component, Input } from '@angular/core';
import { TotemDto } from '@shared/src/controllers/totem/TotemDto';


@Component({
    selector: 'shared-totem-loading',
    templateUrl: './shared.totem.loading.component.html',
    styleUrls: ['./shared.totem.loading.component.scss']
})
export class SharedTotemLoadingComponent {

    constructor() {

    }
    @Input()
    public totem: TotemDto;

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


