export enum HTagsDefined {
    HTAG_move = "move",
    HTAG_stop = "stop",
    HTAG_lost = "lost",
    HTAG_noTelemetry = "noTelemetry",
    HTAG_user = "user",
    HTAG_plate = "plate",
    HTAG_device = "device",
    HTAG_route = "route",
    HTAG_shipment = "shipment",

    HTAG_slotPending = "slotPending",
    HTAG_slotNotPending = "slotNotPending",
    HTAG_delayed = "delayed",

    HTAG_testObject = "testObject",
    HTAG_requiresBooking = "requiresBooking"
}
