
import { QuestionListMaintenanceDto } from "./QuestionListMaintenanceDto";

export class QuestionItemMaintenanceDto extends QuestionListMaintenanceDto {

   public help: string;
   public minimumLength: number;
   public withImage: boolean;
   public isActive: boolean;
  
}
