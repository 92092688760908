import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeController } from '../../../controllers/home/home.controller';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { UserRegisterController } from '@shared/src/controllers/user/register/user.register.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from "rxjs";
import { HardmanRegisterSteps } from '@shared/src/enums/HardmanRegisterSteps';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';


/***
 * Aquesta directiva no te sentit. No puc jugar a pingpong. A on haig d'anar, en quin estat estic?
 */
@Component({
  selector: 'shared-register-user-info',
  templateUrl: './shared.register.user.info.component.html',
  styleUrls: ['./shared.register.user.info.component.scss']
})
export class SharedRegisterUserInfoComponent implements OnInit {

  public generalClass: string = "desktop";
  public registerFormGroup: FormGroup;

  constructor(
    private userRegisterController: UserRegisterController,
    @Inject('SecurityController') protected securityController: SecurityController,
    private fb: FormBuilder,
    protected router: Router,
    @Inject('AlertService') private alertService: AlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    @Inject('HomeController') protected homecontroller: HomeController) {

    this.registerFormGroup = fb.group({
      'eMail': [null, [Validators.email]],
      'phone': [null, [Validators.nullValidator]],
      'prefix': [null, [Validators.nullValidator]],
      'fullName': [null, [Validators.required]],
      'acceptProductConditions': [null, [Validators.required, Validators.requiredTrue]],
    })

    if (this.registerData != null)
      this.registerData.language = SecurityController.LANGUAGE;
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();
  }

  ngOnInit(): void {

    this.securityController.logoutNONAVIGATE(true);

    this.route.paramMap.subscribe(params => {
      this.userRegisterController.startPage(this.registerData, params).subscribe((data: UserRegisterWizardDto) => {
        this.registerData = data;
      })
    })

    this.emailChanged
      .pipe(
        // Time in milliseconds between key events
        debounceTime(500))
      // subscription for response
      .subscribe(() => {
        if (this.registerData.userregister.eMail != null && this.registerData.userregister.eMail.length > 0) {
          this.userRegisterController.checkEmail(this.registerData.userregister.eMail).subscribe((data: boolean) => {
            if (!data) {
              this.validEmail = true;
            } else {
              this.validEmail = false;
              this.translateService.get("REGISTERCOMPONENT.OWNER_EMAIL_EXISTS.LABEL").subscribe((text: string) => {
                this.alertService.error(text, false);
              })
            }
          })
        }
      })

    this.phoneChanged
      .pipe(
        // Time in milliseconds between key events
        debounceTime(500))
      // subscription for response
      .subscribe(() => {
        if (this.registerData.userregister.phone != null && this.registerData.userregister.phone.length > 0) {
          this.userRegisterController.checkPhone(this.registerData.userregister.prefix, this.registerData.userregister.phone).subscribe((data: boolean) => {
            if (!data) {
              this.validPhone = true;
            } else {
              this.validPhone = false;
              this.translateService.get("REGISTERCOMPONENT.OWNER_PHONE_EXISTS.LABEL").subscribe((text: string) => {
                this.alertService.error(text, false);
              })
            }
          })
        }
      })
  }

  private emailChanged = new Subject<string>();
  public emailChangedEvent() {
    this.emailChanged.next();
  }

  // El correu no existeix o és el d'un non-owner
  public validEmail: boolean = true;

  private phoneChanged = new Subject<string>();
  public phoneChangedEvent() {
    this.phoneChanged.next();
  }

  // El telefon no existeix o és el d'un non-owner
  public validPhone: boolean = true;

  public canRegister(): boolean {
    if (this.registerData.userregister.phone == "" || this.registerData.userregister.eMail != "")
      return this.validEmail && !this.registerFormGroup.invalid;

    return this.validPhone && !this.registerFormGroup.invalid;

  }

  private _registerData: UserRegisterWizardDto = new UserRegisterWizardDto;
  @Input()
  public set registerData(value: UserRegisterWizardDto) {
    this._registerData = value;
  }
  public get registerData(): UserRegisterWizardDto {
    return this._registerData;
  }

  @Input()
  public pendigResponse: boolean;

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  next() {
    if (this.pendigResponse)
      return;
    this.registerData.pas = HardmanRegisterSteps.RegisterUserInfoInternal;
    this.onClickNext.next(this.registerData);
  }

  public onLoginButtonClick() {
    this.router.navigateByUrl("/logout");
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  public enumPrefixes = PhonePrefix;
  public translategroup: string = 'PHONEPREFIX.ENUM';

  public _prefixModel: any;
  set prefixModel(value: string) {
    if (PhonePrefix[value])
      this.registerData.userregister.prefix = PhonePrefix[value];
  }
  get prefixModel(): any {
    return this.registerData.userregister.prefix;
  }
}
