import { Output, EventEmitter } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { SlotDto } from "../../../dtos/address/slots/SlotDto";
import { AddressController } from "../../../controllers/address/address.controller";
import { AddressTimetableIntervalPlanningSlotsDto } from "@shared/src/dtos/address/planning/AddressTimetableIntervalPlanningSlotsDto";
import { HHourInterval } from "@shared/src/datatypes/HHourInterval";
import { DockDto } from "@shared/src/dtos/address/dock/DockDto";
import { AddressTimetableIntervalDto, HTime } from "@shared/src/public-api";
import { PhaseSlotDto } from "@shared/src/dtos/slot/PhaseSlotDto";

@Component({
  selector: 'shared-slots-planning',
  templateUrl: "./shared.slots.planning.component.html",
  styleUrls: ["./shared.slots.planning.component.scss"],
})
export class SharedSlotsPlanningComponent implements OnInit {
  constructor(private addressController: AddressController) { }

  ngOnInit() {

  }

  public isShortInterval: boolean = false;
  @Input() absoluteMode: boolean = false;

  @Output() onSlotSelected: EventEmitter<SlotDto> = new EventEmitter<SlotDto>();
  slotSelected(value: SlotDto) {
    this.onSlotSelected.next(value);
  }

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();

  refreshData() {
    this.onRefreshData.next();
  }

  @Input()
  public addressId: number;

  public slots: Array<SlotDto>;

  private _intervalslots: AddressTimetableIntervalPlanningSlotsDto;
  @Input() public set intervalslots(value: AddressTimetableIntervalPlanningSlotsDto) {
    if (this._intervalslots === value)
      return;
    this._intervalslots = AddressTimetableIntervalPlanningSlotsDto.build(value);
    this.refreshProperties();
  }
  public get intervalslots(): AddressTimetableIntervalPlanningSlotsDto {
    return this._intervalslots;
  }

  private _action: string;
  @Input() public set action(value: string) {
    if (this._action === value)
      return;
    this._action = value;
    this.refreshProperties();
  }
  public get action(): string {
    return this._action;
  }

  private _dockToAssigned: DockDto;
  @Input() public set dockToAssigned(value: DockDto) {
    if (this._dockToAssigned === value)
      return;
    this._dockToAssigned = value;
  }
  public get dockToAssigned(): DockDto {
    return this._dockToAssigned;
  }

  public _selectedIntervalFilter: string;
  @Input()
  public set selectedIntervalFilter(value: string) {
    if (this._selectedIntervalFilter === value)
      return;

    this._selectedIntervalFilter = value;
    this.refreshProperties();
  }
  public get selectedIntervalFilter() {
    return this._selectedIntervalFilter;
  }

  getSlotsReserved(): number {
    if (this.intervalslots != null) return this.intervalslots.slotOccupied;
    return 0;
  }

  getSlotsMax(): number {
    if (this.intervalslots != null) return this.intervalslots.slotCount;
    return 0;
  }

  public canAlterElement: boolean;
  setCanAlterElement() {
    this.canAlterElement = this.intervalslots != null && this.intervalslots.canalterelement;
  }


  public incrementant: boolean = false;
  enlargeSlotTointervalslots() {
    if (this.incrementant)
      return;
    if (this.canEnlarge) {
      this.incrementant = true;
      this.addressController.incrementIntervalSlots(this.addressId, this.intervalslots).subscribe((data: AddressTimetableIntervalPlanningSlotsDto) => {
        this.incrementant = false;
        this.refreshData();
      });
    }
  }

  public canEnlarge: boolean;
  setCanEnlarge() {
    this.canEnlarge = this.action == PhaseSlotDto.ACTION_ENLARGE && this.canAlterElement;
  }

  canReduce() {
    return this.action == PhaseSlotDto.ACTION_REDUCE && this.canAlterElement && this.intervalslots != null && this.intervalslots.slotCount > this.intervalslots.slotOccupied;
  }

  public reduint: boolean = false;
  reduceSlotFromintervalslots() {
    if (this.reduint)
      return;

    if (this.canReduce()) {
      this.reduint = true;
      this.addressController.decrementIntervalSlots(this.addressId, this.intervalslots).subscribe((data: AddressTimetableIntervalPlanningSlotsDto) => {
        this.reduint = false;
        this.refreshData();
      });
    }
  }

  public generalStyle: any;
  refreshProperties() {
    this.setCanAlterElement();
    this.setCanEnlarge();
    this.setInterval();

    //
    if (this.intervalslots != null && this.intervalslots.backColor != null && this.intervalslots.backColor != "" && this.intervalslots.backColor != "#fff" && this.intervalslots.backColor != "#ffffff")
      this.generalStyle = {
        "backgroundColor": this.intervalslots.backColor + "80",
        "border-left": this.absoluteMode ? "3px solid" + this.intervalslots.backColor : "unset"
      }
    else
      this.generalStyle = {
        "backgroundColor": "#ffffff",
        "border-left": this.absoluteMode ? "3px solid #ccc" : "unset"
      }

    this.isShortInterval = HTime.isMinorEqualsThan(HHourInterval.totalOf(this.interval), HTime.buildByHoursAndMinutes(1,0));
  }

  public interval: HHourInterval;
  setInterval() {
    if (this.intervalslots != null && this.getInterval(this.intervalslots) != null)
      this.interval = HHourInterval.buildByDto(this.getInterval(this.intervalslots));
    else
      this.interval = HHourInterval.buildstr("00:00-00:00");
  }

  
  getInterval(aInterval: AddressTimetableIntervalDto): HHourInterval{
    if(aInterval == null)
      return null;

    if(this.selectedIntervalFilter == "DOCUMENTATION")
      return aInterval.interval;
    if(this.selectedIntervalFilter == "DOCK")
      return aInterval.intervalDock;
    if(this.selectedIntervalFilter == "FORKLIFT")
      return aInterval.intervalWheelbarrowDriver;

    return aInterval.interval;
  }


  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
