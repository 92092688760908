import { Injectable } from '@angular/core';


@Injectable()
export class RouteDividedInfoDto {
    public childNumber: number;
    public maxDivision: number;

    static empty(): RouteDividedInfoDto {
        let result = new RouteDividedInfoDto();
        result.childNumber = 0;
        result.maxDivision = 0;
        return result;
    }
}
