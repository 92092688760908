import { Output, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, NgZone, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DragDropService } from '../../../services/dragdrop/DragDropService';
import { fromEvent as observableFromEvent } from 'rxjs';
import { DocksController } from '@shared/src/controllers/address/docks.controller';
import { DockSlotPhaseDto } from '@shared/src/dtos/address/dock/DockSlotPhaseDto';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { HColor } from '@shared/src/datatypes/HColor';
import { PhaseSlotDto } from '@shared/src/dtos/slot/PhaseSlotDto';

@Component({
  selector: 'shared-dock-planning',
  templateUrl: './shared.dock.planning.component.html',
  styleUrls: ['./shared.dock.planning.component.scss'],
})
export class SharedDockPlanningComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private ele: ElementRef, public dragDropService: DragDropService, private ngZone: NgZone, public docksController: DocksController) {
    //https://stackoverflow.com/questions/43108155/angular-2-how-to-keep-event-from-triggering-digest-loop-detection-cycle
    this.ngZone.runOutsideAngular(() => {
      observableFromEvent(ele.nativeElement, "dragover")
        .subscribe((event: DragEvent) => {
          this.dragDropService.onDragOver(null, null, event);
        }
        );
    });
  }

  ngOnInit() { }

  onDragEnter(event: DragEvent, item: DockSlotPhaseDto) {
    if (!item)
      return;

  }
  onDragLeave(event: DragEvent, item: DockSlotPhaseDto) {
    if (!item)
      return;

  }

  public onDragStart(event: DragEvent, item: DockSlotPhaseDto) {

    DragDropService.moverOrCopy(event);
    event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_DOCKSLOTPHASEDTO);
    event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(item));
  }

  drop(event: DragEvent, item: DockSlotPhaseDto) {
    if (!item)
      return;

    if (this.dockToAssigned != null) {
      item.dockId = this.dockToAssigned.dockId;
      item.dockSkin = this.dockToAssigned.skin;
    }

    if (this.isDockSlotDroppable)
      this.dragDropService.onDrop(DragDropService.extractDraggedObject(event), DragDropService.createDropObject(item, DragDropService.HARDMAN_DOCKSLOTPHASEDTO), event, this.onDropCallBack, this);
  }

  public onDropCallBack(draggedobject: any, ondropobject: any, event: DragEvent, hardmanobjectdragged: any, hardmanobjectdropped: any, callbackparam?: any, result?: any) {
    if (callbackparam && callbackparam.onRefreshData) {
      if (result && callbackparam.setSlotPlanning != null) {
        callbackparam.setSlotPlanning(draggedobject);
      }
      callbackparam.onRefreshData.next();
    }
  }

  setSlotPlanning(value: PhaseSlotDto) {
    if (this.dockSlot == null)
      return;
    if (this.dockSlot.slotPlanning === value)
      return;
    this.dockSlot.slotPlanning = value;
  }

  private _columnDocks: boolean;
  @Input() public set columnDocks(value: boolean) {
    if (this._columnDocks === value)
      return;
    this._columnDocks = value;
    this.refreshProperties();
  }
  public get columnDocks(): boolean {
    return this._columnDocks;
  }

  private _dockSlot: DockSlotPhaseDto;
  @Input() public set dockSlot(value: DockSlotPhaseDto) {
    if (this._dockSlot === value)
      return;
    this._dockSlot = value;
    this.refreshProperties();
  }
  public get dockSlot(): DockSlotPhaseDto {
    return this._dockSlot;
  }

  private _action: string;
  @Input() public set action(value: string) {
    if (this._action === value)
      return;
    this._action = value;
    this.refreshProperties();
  }
  public get action(): string {
    return this._action;
  }

  private _dockToAssigned: DockDto;
  @Input() public set dockToAssigned(value: DockDto) {
    if (this._dockToAssigned === value)
      return;
    this._dockToAssigned = value;
  }
  public get dockToAssigned(): DockDto {
    return this._dockToAssigned;
  }

  getTextColor(data: HColor) {
    if (data != null)
        return HColor.getWhiteBlackFromColor(data.web);

    return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
  }

  getBackColor(data: HColor) {
      return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
  }


  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter<any>();
  refreshData() {
    this.onRefreshData.next();
  }


  public isDockSlotPending: boolean;
  setIsDockSlotPending() {
    this.isDockSlotPending = this.dockSlot == null || this.dockSlot.isDockSlotPending;
  }

  public isAssignedDock: boolean;
  setIsAssignedDock() {
    this.isAssignedDock = this.dockSlot != null && !this.dockSlot.isEmptyDock;
  }

  public isAssignedPhase: boolean;
  setIsAssignedPhase() {
    this.isAssignedPhase = this.dockSlot != null && this.dockSlot.slotPlanning != null && this.dockSlot.slotPlanning.phaseId > 0;
  }

  public canAlterElement: boolean;
  setCanAlterElement() {
    this.canAlterElement = this.dockSlot != null;
  }

  public isDockSlotDraggable: boolean;
  setIsDockSlotDraggable() {
    this.isDockSlotDraggable = this.isAssignedPhase && this.action == PhaseSlotDto.ACTION_ASSIGN_DOCK && this.canAlterElement;
  }

  public isDockSlotDroppable: boolean;
  setIsDockSlotDroppable() {
    this.isDockSlotDroppable = ((!this.columnDocks && !this.isDockSlotPending && !this.isAssignedDock) || (this.columnDocks && !this.isAssignedPhase && this.isAssignedDock)) && this.action == PhaseSlotDto.ACTION_ASSIGN_DOCK && this.canAlterElement;
  }

  refreshProperties() {
    this.setIsDockSlotPending();
    this.setIsAssignedDock();
    this.setCanAlterElement();
    this.setIsAssignedPhase();
    this.setIsDockSlotDraggable();
    this.setIsDockSlotDroppable();
  }
}
