import { Component, Inject, OnInit } from '@angular/core';
import { SecurityController } from '../../controllers/security/security.controller';
import { HomeController } from '../../controllers/home/home.controller';
import { UserRegisterController } from '@shared/src/controllers/user/register/user.register.controller';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { HardmanRegisterSteps } from '@shared/src/enums/HardmanRegisterSteps';
import { PartnerDetailDto } from '@shared/src/dtos/link/detail/PartnerDetailDto';

@Component({
  selector: 'shared-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {

  constructor(
    private userRegisterController: UserRegisterController,
    @Inject('SecurityController') protected securityController: SecurityController,
    @Inject('HomeController') protected homecontroller: HomeController) {

  }

  public generalClass: string = "desktop";
  getGeneralClass() {
    return this.generalClass;
  }

  ngOnInit(): void {
    this.userRegisterController.refresh(this.registerData).subscribe((data: UserRegisterWizardDto) => {
      this.registerData = data;
    })

    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();
  }


  private _registerData: UserRegisterWizardDto = new UserRegisterWizardDto();
  set registerData(value: UserRegisterWizardDto) {
    if (this._registerData === value)
      return;
    this._registerData = value;
    if (this._registerData != null) {
      this.registerStep = this._registerData.pas;
    }
  }
  get registerData(): UserRegisterWizardDto {
    return this._registerData;
  }

  private _registerStep: HardmanRegisterSteps;
  set registerStep(value: HardmanRegisterSteps) {
    if (this._registerStep === value)
      return;
    this._registerStep = value;
    this.setActionOnRegisterStep();
  }
  get registerStep(): HardmanRegisterSteps {
    return this._registerStep;
  }

  setActionOnRegisterStep() {
    switch (this.registerStep) {
      case HardmanRegisterSteps.RegisterUserInfo:
        if (this.registerData.pas == HardmanRegisterSteps.RegisterUserInfo) {
          this.securityController.logoutNONAVIGATE(true);
        }
        break;
      case HardmanRegisterSteps.RegisterAskUserType:
        break;
      case HardmanRegisterSteps.RegisterTenantInfo:
        break;
      case HardmanRegisterSteps.RegisterWantToWorkWithYou:
        break;
      case HardmanRegisterSteps.RegisterMakeYourselfVisible:
        break;
      case HardmanRegisterSteps.RegisterAskUserDriver:
        break;
      case HardmanRegisterSteps.RegisterDrivers:
        break;
      case HardmanRegisterSteps.RegisterProducts:
        break;
      case HardmanRegisterSteps.RegisterFinal:
        if (this.registerData.pas == HardmanRegisterSteps.RegisterFinal) {
          this.homecontroller.home();
        }
        break;
    }
  }

  public pendigResponse: boolean = false;
  next(data: UserRegisterWizardDto) {
    if(this.pendigResponse)
      return;
      
    this.pendigResponse = true;
    this.userRegisterController.next(this.registerData).subscribe((data: UserRegisterWizardDto) => {
      this.pendigResponse = false;
      this.registerData = data;
      this.setActionOnRegisterStep();
    }, error => {
      this.pendigResponse = false;
    });
  }

  addLinkCompany(link: PartnerDetailDto) {
    this.pendigResponse = true;
    this.registerData.newPartnerCompany = link;
    this.userRegisterController.addLinkCompany(this.registerData).subscribe((data: UserRegisterWizardDto) => {
      this.pendigResponse = false;
      this.registerData = data;
    }, error => {
      this.pendigResponse = false;
    });
  }
  addLinkDriver(link: PartnerDetailDto) {
    this.pendigResponse = true;
    this.registerData.newPartnerDriver = link;
    this.userRegisterController.addlinkdriver(this.registerData).subscribe((data: UserRegisterWizardDto) => {
      this.pendigResponse = false;
      this.registerData = data;
    }, error => {
      this.pendigResponse = false;
    });
  }

  pretty(params: any): string {
    return JSON.stringify(params);
  }
}
