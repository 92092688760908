<ng-container *ngIf="value">
    <div class="block grup taula">
        <table class="hardman">
            <tr>
                <th class="header nodiv" rowspan="2">
                    {{'SHARED.JOURNEY.DETAIL.COMPONENT.ORIGINTITLE' | translate}}
                </th>
                <td>{{value.originMoment | datehournotz}}</td>
            </tr>
            <tr>
                <td rowspan="2">{{value.originFormatedAddress}}</td>
            </tr>
            <tr>
                <th class="header nodiv" rowspan="2">{{'SHARED.JOURNEY.DETAIL.COMPONENT.DESTINATIONTITLE' | translate}}
                </th>
                <td>{{value.destinationMoment | datehournotz}}</td>
            </tr>
            <tr>
                <td rowspan="2">{{value.destinationFormatedAddress}}</td>
            </tr>
            <tr>
                <th class="header nodiv">{{'SHARED.JOURNEY.DETAIL.COMPONENT.TOTALTIME.PROMPT' | translate}}</th>
                <td>{{value.totalTime | time}}</td>
            </tr>
            <tr>
                <th class="header nodiv">{{'SHARED.JOURNEY.DETAIL.COMPONENT.MOVEMENTTIME.PROMPT' | translate}}</th>
                <td>{{value.movementTime | time}}</td>
            </tr>
            <tr>
                <th class="header nodiv">{{'SHARED.JOURNEY.DETAIL.COMPONENT.RESTTIME.PROMPT' | translate}}</th>
                <td>{{value.restTime | time}}</td>
            </tr>
            <tr>
                <th class="header nodiv">{{'SHARED.JOURNEY.DETAIL.COMPONENT.STOP.PROMPT' | translate}}</th>
                <td>{{value.stopNumber}}</td>
            </tr>
        </table>
    </div>
    <!--
    <shared-telemetry-item class="block" [ngClass]="getTelemetryClass(value)" [telemetry]="value.telemetry"
        [destinationAddress]="value.destinationAddress" [originAddress]="value.originAddress"></shared-telemetry-item>
-->
    <div class="block resta">
        <div class="segments">
            <div class="segment origen inicifinal">
                <div class="line title"> {{'SHARED.JOURNEY.DETAIL.COMPONENT.ORIGINTITLE' | translate}} <a
                        class="button-icon-marker marker" (click)="onIniciMarkerClick()"><i
                            class="fa fa-map-marker"></i></a>
                </div>
                <div class="line moment">{{value.originMoment | datehournotz}}</div>
                <div class="line address">{{value.originFormatedAddress}}</div>
            </div>
            <div class="segment mig" *ngFor="let item of value.segmentList | sortBy:'moment'"
                (click)="canviarMarcadorsA(item)"
                [ngClass]="{'Stop':item.journeySegmentType=='Stop','StopPhase':item.journeySegmentType=='StopPhase','Movement':item.journeySegmentType=='Movement' ,'selected':selectedSegment && selectedSegment.plateJourneySegmentId==item.plateJourneySegmentId}">

                <div class="line moment">
                    <span>{{item.moment | datehourinterval:item.endMoment}}</span>
                </div>
                <i class="fa fa-play-circle play" aria-hidden="true"></i>
                <i class="fa fa-pause-circle pause" aria-hidden="true"></i>
                <div class="time">{{item.segmentTime | time}}</div>
            </div>
            <div class="segment desti inicifinal">
                <div class="line title"> {{'SHARED.JOURNEY.DETAIL.COMPONENT.DESTINATIONTITLE' | translate}}<a
                        class="button-icon-marker marker" (click)="onFinalMarkerClick()"><i
                            class="fa fa-map-marker"></i></a></div>
                <div class="line moment">{{value.destinationMoment | datehournotz}}</div>
                <div class="line address">{{value.destinationFormatedAddress}}</div>
            </div>
        </div>
        <shared-map [rutes]="rutes" [marcadors]="marcadors" (zoomDirectionsValueChanged)="generalZoom = $event"
            (latitudeChange)="setLatitude($event)" (longitudeChange)="setLongitude($event)" [minZoom]="generalZoom"
            [autozoom]="generalZoom == null || generalZoom == undefined" [latitude]="latitude != null ? latitude : null"
            [longitude]="longitude != null ? longitude : null" [fixedCenter]="latitude != null && longitude != null"
            [geofences]="geofences" *ngIf="value">
        </shared-map>
    </div>
</ng-container>