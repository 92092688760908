<p class="helppoint-title">{{data.title}}</p>
<input-component class="col-x" [(ngModel)]="data.payment"
    placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.PAYMENT.CAPTION' | translate}}"></input-component>
<input-component class="col-x" [(ngModel)]="data.amount" type="number"
    placeholder="{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.AMOUNT.CAPTION' | translate}}"></input-component>

<div align="end">
    <button mat-raised-button class="helppoint-button confirm" (click)="onConfirm()"
        color="primary">{{'MAINTENANCE.ACCEPT' | translate}}</button>
    <button mat-raised-button class="helppoint-button close" (click)="onClose()"
        color="link">{{'CANCEL.BUTTON' | translate}}</button>
</div>