import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { TenantProductDto } from '@shared/src/dtos/tenants/TenantProductDto';

@Component({
  selector: 'register-products',
  templateUrl: './register-products.component.html',
  styleUrls: ['./register-products.component.scss']
})
export class RegisterProductsComponent implements OnInit {

  constructor(@Inject('HomeController') protected homecontroller: HomeController) { }

  ngOnInit() {  }

  @Input()
  public registerData: UserRegisterWizardDto;

  @Input() 
  public pendigResponse: boolean;

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  next() {
    if(this.pendigResponse)
      return;
    this.onClickNext.next(this.registerData);
  }

  productChange(product: TenantProductDto){
    
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }
}
