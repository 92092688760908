<div *ngIf="route" class="header"
    [ngClass]="{'isSlotBlocked':route.isSlotBlocked, 'incompatibleSlot':route.incompatibleSlot}">
    <div class="primeraLinea">
        <div class="leftData">
            <!-- TÍTOL -->
            <div class="line route-title ellipsis pointer">
                {{route.title}}<shared-copyclipboard [value]="route.title"></shared-copyclipboard>
                <span class="route-titlesubtitle pointer">{{route.titleSubtitle}}</span>
            </div>
            <!-- SUBTÍTOL -->
            <div class="line route-subtitle ellipsis">{{route.subtitle}}<shared-copyclipboard
                    [value]="route.subtitle"></shared-copyclipboard>
            </div>

            <!-- DATES -->
            <div class="line route-description ellipsis" *ngIf="route.line1">{{ route.line1 }}
            </div>
            <div class="line route-description ellipsis disp" *ngIf="route.line2">{{ route.line2 }}
            </div>
            <div class="line route-description ellipsis" *ngIf="route.line3">{{ route.line3 }}
            </div>
            <button *ngIf="!useIonic" class="general-link-boto jump" (click)="goToActiveRoute()"><i class="fa fa-share"
                    aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AR.PROMPT' | translate}}</button>
        </div>
        <div class="rightData">
            <div class="container-tags">
                <div class="container-tag"><span
                        class="mini-title-tags">{{'TAG.MANUAL.CAPTION' | translate }}:</span><shared-tags-strip-component
                        class="only-single" [tags]="manualTags"></shared-tags-strip-component></div>
                <div class="container-tag"><span class="mini-title-tags"
                        *ngIf="userTags">{{'TAG.USER.CAPTION' | translate }}:</span><shared-tags-strip-component
                        class="only-single" [tags]="userTags"></shared-tags-strip-component></div>
                <div class="container-tag"><span class="mini-title-tags"
                        *ngIf="privateTags">{{'TAG.PRIVATE.CAPTION' | translate }}:</span><shared-tags-strip-component
                        class="only-single" [tags]="privateTags"></shared-tags-strip-component></div>
                <div class="container-tag"><span class="mini-title-tags"
                        *ngIf="stops">{{'TAG.STOP.CAPTION' | translate }}:</span><shared-tags-strip-component
                        class="only-single" [tags]="stops"></shared-tags-strip-component></div>
                <div class="container-tag"><span class="mini-title-tags"
                        *ngIf="internalTags">{{'TAG.INTERNAL.CAPTION' | translate }}:</span><shared-tags-strip-component
                        class="only-single" [tags]="internalTags"></shared-tags-strip-component></div>
            </div>
        </div>
    </div>
    <div class="bottom-zone">
        <shared-general-status-viewer [generalStatus]="route.generalStatus"
            class="in-bottom-zone"></shared-general-status-viewer>

        <!-- SLOT -->
        <div *ngIf="showAssignedSlots" matTooltip="{{ tooltipAssignedSlots }}" class="requadre slotInfo"
            [ngClass]="{'pendingassigned': route.pendingSlotQty>0 }">
            <div class="slotInfo-date">{{firstAssignedSlotDate}}</div>
            <div class="slotInfo-interval">{{firstAssignedSlotInterval}}</div>
        </div>

        <!-- MOLL -->
        <span *ngIf="route.docks != null && route.docks.length > 0" class="requadre docksContainer">
            <div class="dockInfo" *ngFor="let item of route.docks" [style.color]="getTextColor(item.backgroundColor)"
                [style.background-color]="getBackColor(item.backgroundColor)">{{ item.skin }}</div>
        </span>
        <!-- REQUADRES -->
        <!-- PARTNERS -->
        <div *ngIf="route.partnerParentTrademark" class="requadre parent">
            <span class="trademark ellipsis">{{ route.partnerParentTrademark }}</span>
        </div>
        <div *ngIf="route.partnerShipperTrademark" class="requadre shipper">
            <span class="trademark ellipsis">{{ route.partnerShipperTrademark }}</span>
        </div>
        <div *ngIf="route.partnerCarrierTrademark" class="requadre carrier">
            <span class="trademark ellipsis">{{ route.partnerCarrierTrademark }}</span>
        </div>
        <div *ngIf="route.partnerLogisticOperatorTrademark" class="requadre logistic-operator"><span
                class="trademark ellipsis">{{ route.partnerLogisticOperatorTrademark }}</span></div>
        <div *ngIf="route.partnerDriverTrademark" class="requadre driver">
            <span class="trademark ellipsis">{{ route.partnerDriverTrademark }}</span>
        </div>
        <div *ngIf="route.partnerSecondDriverTrademark" class="requadre driver">
            <span class="trademark ellipsis">{{ route.partnerSecondDriverTrademark }}</span>
        </div>
        <div *ngIf="route.partnerInspectorTrademark" class="requadre inspector">
            <span class="trademark ellipsis">{{ route.partnerInspectorTrademark }}</span>
        </div>
        <div *ngIf="route.partnerClientPreferentTrademark" class="requadre client">
            <span class="trademark ellipsis">{{ route.partnerClientPreferentTrademark }}</span>
        </div>
        <!-- LABEL ENTREGUES -->
        <div class="requadre deliveries-label"
            [ngClass]="{'withslot':showAssignedSlots,'show': route.isDivided && route.childsCount > 0 }">
            <span class="deliveries-label-text"> {{ 'ROUTEPAGE.ITEM.DIVIDED.HASCHILDS' | translate }}</span>
        </div>
        <!-- EXWORK -->
        <div *ngIf="route.isExwork" class="requadre exwork">{{ 'ROUTE.EXWORK.CAPTION' | translate }}</div>
        <!-- MARGE DE TEMPS PER RESERVA DE SLOT -->
        <div *ngIf="route.hasSlotReserveMarginTime" class="requadre slot-reserve-margin-time">
            {{ route.slotReserveMarginTime | time }}
        </div>
        <!-- TIPOLOGIA -->
        <div *ngIf="route.typology" class="requadre typology">
            {{ route.typology }}
        </div>

        <!-- ICONES -->
        <!-- BOOKING -->
        <div *ngIf="route.bookingSlotId" class="icona booking"><i class="fa fa-ship"></i></div>
        <!-- CERTIFICAT -->
        <div *ngIf="route.requiredCertificate" class="icona required-certificate"><i class="hardman-certificate"></i>
        </div>
        <!-- INSPECTOR -->
        <div *ngIf="route.requiredInspector" class="icona required-inspector"><i class="hardman-inspector"></i></div>
        <!-- AGRUPADA -->
        <div *ngIf="route.isGrouped" class="icona grouped"><i class="fa fa-compress"></i></div>
        <!-- FILLA AGRUPADA -->
        <div *ngIf="route.isChildOfGrouped" class="icona grouped"><i class="fa fa-expand"></i></div>
        <!-- BANNER HTML -->
        <div *ngIf="route.hasBannerHtml" class="icona bell"><i class="fa fa-bell" aria-hidden="true"></i></div>

        <!-- Icones custom -->
        <div *ngIf="route.icons && route.icons.length > 0">
            <div *ngFor="let icona of route.icons" class="icona element">
                <i *ngIf="icona.family == 'fontawesome'" class="{{ icona.icon }}"></i>
                <mat-icon *ngIf="icona.family == 'material'">{{ icona.icon }}</mat-icon>
            </div>
        </div>

        <!-- TIPOLOGIES DE RUTA -->
        <img *ngIf="route.isInbound" class="icona" src="/assets/images/routetype-inbound.svg" alt="Inbound">
        <img *ngIf="route.isNonacceptance" class="icona" src="/assets/images/routetype-nonacceptance.svg"
            alt="Non acceptance">
        <img *ngIf="route.isReturn" class="icona" src="/assets/images/routetype-return.svg" alt="Return">
        <img *ngIf="route.isReverseLogistics" class="icona" src="/assets/images/logisticaInversa.svg"
            alt="Reverse logistics">
        <img *ngIf="route.isOutbound" class="icona" src="/assets/images/routetype-outbound.svg" alt="Outbound">
        <img *ngIf="route.isDelegate" class="icona" src="/assets/images/routetype-delegate.svg" alt="Delegate">


        <!-- EN RETRÀS -->
        <i class="isdelayed fa fa-clock-o" aria-hidden="true" *ngIf="route.isDelayed"></i>

        <!-- SÓC OBSERVADOR -->
        <i class="iamobserver fa fa-eye" aria-hidden="true" *ngIf="route.iAmObserver"></i>

        
        <!-- NÚMERO DE PALETS -->
        <div *ngIf="route.showPalletCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PALLETCOUNT.TOOLTIP' | translate: {value: route.palletCount} }}">
            <div class="route-card-info-count pallets">{{ route.palletCount }}</div>
        </div> 

        <!-- QUANTITAT DE PRODUCTE -->
        <div *ngIf="route.showQuantityProductOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.QUANTITYPRODUCT.TOOLTIP' | translate: {value: route.quantityProduct} }}">
            <div class="route-card-info-count quantity">{{ route.quantityProduct }}</div>
        </div> 

        <!-- NÚMERO DE PAQUETS -->
        <div *ngIf="route.showPacketCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PACKETCOUNT.TOOLTIP' | translate: {value: route.packetCount} }}">
            <div class="route-card-info-count packets">{{ route.packetCount }}</div>
        </div> 
        
        <!-- NÚMERO DE PRODUCTES -->
        <div *ngIf="route.showProductCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.PRODUCTCOUNT.TOOLTIP' | translate: {value: route.productCount} }}">
            <div class="route-card-info-count products">{{ route.productCount }}</div>
        </div> 

        <!-- NÚMERO DE SHIPMENTS -->
        <div *ngIf="route.showShipmentCountOnRouteCard" class="block-route-card-info-count" matTooltip="{{ 'ROUTECARD.SHIPMENTCOUNT.TOOLTIP' | translate: {value: route.shipmentCount} }}">
            <div class="route-card-info-count shipments">{{ route.shipmentCount }}</div>
        </div> 

    </div>

    <!-- SEMÀFOR -->
    <span *ngIf="route.showMMResponseStatus && route.responseStatus != null" class="responseStatusContainer"
        [ngClass]="{'withdock' : route.docks != null && route.docks.length > 0 }">
        <div class="responseStatus" [ngClass]="route.responseStatus"></div>
    </span>

    <!-- FILLES -->
    <div *ngIf="route.isDivided && route.pendingChildsCount > 0" class="showchilds ichilds">
        <div class="contador">{{route.pendingChildsCount}}</div>
    </div>

    <!-- BANNER HTML PRIVAT -->
    <div *ngIf="route && route.styledBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledBannerHtml | safe: 'html'">
    </div>
    <div *ngIf="route.styledPrivateBannerHtml" class="private-banner-html"
        [innerHTML]="route.styledPrivateBannerHtml | safe: 'html'">

        <!-- ID DE LA RUTA -->
        <div class="routeId">{{route.routeId}}</div>
    </div>