import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HLong } from '@shared/src/datatypes/HLong';

@Component({
  selector: 'shared-booking-form',
  templateUrl: './shared.booking.form.component.html',
  styleUrls: ['./shared.booking.form.component.scss']
})
export class SharedBookingFormComponent implements OnInit {

  constructor(public fb: FormBuilder,
    protected bookingController: BookingController,
    @Inject('SecurityController') protected securityController: SecurityController) {
    this.createForm();
  }

  ngOnInit(): void { }

  private _selectedBooking: BookingDto;
  @Input() public set selectedBooking(v: BookingDto) {
    if (JSON.stringify(v) == JSON.stringify(this._selectedBooking))
      return;

    this.formGroup = null;
    this._selectedBooking = v;
    this.calculateReadOnly();
    setTimeout(() => {
      this.createForm();
    }, 50);
  }

  public get selectedBooking(): BookingDto {
    return this._selectedBooking;
  }


  public readonly: boolean = false;

  public calculateReadOnly() {
    this.securityController.getStoredUserDto().subscribe(data => {
      this.readonly = data.isReadOnly || (this._selectedBooking.bookingId && !this._selectedBooking.isModifiable);
    });
  }

  public filterKeysPortAddressDeparture: Array<string> = ["PORT_ADDRESS_DEPARTURE"];
  public filterKeysPortAddressArrival: Array<string> = ["PORT_ADDRESS_ARRIVAL"];
 
  public formGroup: FormGroup;
  public createForm() {
    this.formGroup = this.fb.group({
      "bookingNumber": ['', Validators.nullValidator],
      "shipName": ['', Validators.nullValidator],
      "datetimeStart": ['', Validators.nullValidator],
      "datetimeEnd": ['', Validators.nullValidator],
      "maritimeAgencyId": ['', Validators.nullValidator],
      "customsWarehouse": ['', Validators.nullValidator],
      "places": ['', Validators.nullValidator],
      "depositStart": ['', Validators.nullValidator],
      "depositEnd": ['', Validators.nullValidator],
      "userTags": ['', Validators.nullValidator],
      "partnerFreightForwarderId": ['', Validators.nullValidator],
      "partnerShipperId": ['', Validators.nullValidator],
      "departureAddressId": ['', Validators.nullValidator],
      "arrivalAddressId": ['', Validators.nullValidator],
    });
  }

  isNullOrNullLong(value: number): boolean{
    return HLong.isNullOrNullLong(value);
  }

  @Output() public onSave: EventEmitter<BookingDto> = new EventEmitter<BookingDto>();

  public save() {
    if (!this.selectedBooking)
      return;
    if (!this.selectedBooking.bookingId)
      this.bookingController.createBooking(this.selectedBooking).subscribe(e => {
        if (e && e.bookingId) {
          this.formGroup.markAsPristine();
          this.selectedBooking = e;
          this.onSave.emit(e);
        }
      })
    else
      this.bookingController.updateBooking(this.selectedBooking.bookingId, this.selectedBooking).subscribe(e => {
        if (e) {
          this.formGroup.markAsPristine();
          this.selectedBooking = e;
          this.onSave.emit(e);
        }
      })
  }

  pretty(val: any): string {
    return JSON.stringify(val);
  }
}