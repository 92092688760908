import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '../../../controllers/home/home.controller';
import { HEnum } from '@shared/src/datatypes/HEnum';
import { SituacionsEnLesQueEncaixes } from '@shared/src/enums/SituacionsEnLesQueEncaixes';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';

/***
 * Aquesta directiva no te sentit. No puc jugar a pingpong. A on haig d'anar, en quin estat estic?
 */
@Component({
  selector: 'register-user-type',
  templateUrl: './register-user-type.component.html',
  styleUrls: ['./register-user-type.component.scss']
})
export class RegisterUserTypeComponent implements OnInit {

  public generalClass: string = "desktop";
  public situacions: Array<any> = new Array();

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {
    //Mai es pregunta per si és conductor, es pregunta per quin tipus de situació et sents més representat

    if(this.homecontroller.getPlatform)
        this.generalClass = this.homecontroller.getPlatform();

    let llista = HEnum.getAsArrayExcept(SituacionsEnLesQueEncaixes, SituacionsEnLesQueEncaixes.None);
    for (let i = 0; i < llista.length; i++)
      this.situacions.push(llista[i]);
  }

  ngOnInit() {}

  public getTitle(value: SituacionsEnLesQueEncaixes): string {
    return 'SITUACIONSENLESQUEENCAIXES.' + value.toUpperCase() + ".HTML.TITLE";
  }
  public getHelp(value: SituacionsEnLesQueEncaixes): string {
    return 'SITUACIONSENLESQUEENCAIXES.' + value.toUpperCase() + ".HTML.HELP";
  }

  public setSituacio(value: SituacionsEnLesQueEncaixes) {
    this.registerData.situacioEnLaQueEncaixes = SituacionsEnLesQueEncaixes[value];
  }

  public canRegister(): boolean {
    return this.registerData != null && (this.registerData.situacioEnLaQueEncaixes != null && this.registerData.situacioEnLaQueEncaixes != SituacionsEnLesQueEncaixes.None);
  }

  @Output()
  public onClickNext: EventEmitter<UserRegisterWizardDto> = new EventEmitter();

  @Input()
  public registerData: UserRegisterWizardDto = new UserRegisterWizardDto;

  @Input() 
  public pendigResponse: boolean;

  next() {    
    if(this.pendigResponse)
      return;
    this.onClickNext.next(this.registerData);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
