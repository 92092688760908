import { Component, Inject, Input, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { SecurityController } from '../../../controllers/security/security.controller';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from "rxjs";
import { AlertService } from '../../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { UserRegisterController } from '@shared/src/controllers/user/register/user.register.controller';
import { UserRegisterWizardDto } from '@shared/src/dtos/register/UserRegisterWizardDto';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeZoneUtil } from '@shared/src/enums/TimeZones';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';
import { HEnum } from '@shared/src/datatypes/HEnum';
import { HString } from '@shared/src/datatypes/HString';
import { Languages, SettingsController } from '@shared/src/public-api';
import { UserSettingsDto } from '@shared/src/dtos/settings/UserSettingsDto';

/***
 * Aquesta directiva no te sentit. No puc jugar a pingpong. A on haig d'anar, en quin estat estic?
 */

@Component({
  selector: 'register-user-info-page',
  templateUrl: './register-user-info-page.component.html',
  styleUrls: ['./register-user-info-page.component.scss']
})
export class RegisterUserInfoPageComponent implements OnInit {

  constructor(
    private userRegisterController: UserRegisterController,
    @Inject('SecurityController') protected securityController: SecurityController,
    private fb: FormBuilder,
    public settingsController: SettingsController,
    protected router: Router,
    @Inject('AlertService') private alertService: AlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    @Inject('HomeController') protected homecontroller: HomeController) {

    this.registerFormGroup = fb.group({
      'eMail': [null, [Validators.email]],
      'phone': [null, [Validators.nullValidator]],
      'language': [null, [Validators.nullValidator]],
      'prefix': [null, [Validators.nullValidator]],
      'password': [null, Validators.required],
      'fullName': [null, [Validators.required]],
      'acceptProductConditions': [null, [Validators.required, Validators.requiredTrue]],
    })

    if (this.registerData != null)
      this.registerData.language = SecurityController.LANGUAGE;
    if (this.homecontroller.getPlatform)
      this.generalClass = this.homecontroller.getPlatform();

    this.useIonic = homecontroller.useIonic();
    this.isIos = this.homecontroller.getOS() == 'ios';
  }


  public generalClass: string = "desktop";
  public showEmail: boolean = true;
  public useIonic: boolean = false;
  public isIos: boolean = false;

  ngAfterViewInit(): void {
    this.languagevalue = HEnum.getEnumFromStringWithDefaultValue(navigator.language, Languages, Languages.en);
  }
  ngOnInit(): void {

    this.securityController.logoutNONAVIGATE2(true);

    console.log("route", this.route)
    this.route.paramMap.subscribe(params => {
      if (params.get("join") == "driver")
        this.showEmail = false;
      this.userRegisterController.startPage(this.registerData, params).subscribe((data: UserRegisterWizardDto) => {
        this.registerData = data;
      })
    })

    this.emailChanged
      .pipe(
        // Time in milliseconds between key events
        debounceTime(500))
      // subscription for response
      .subscribe(() => {
        if (this.registerData.userregister.eMail != null && this.registerData.userregister.eMail.length > 0) {
          this.userRegisterController.checkEmail(this.registerData.userregister.eMail).subscribe((data: boolean) => {
            if (!data) {
              this.validEmail = true;
            } else {
              this.validEmail = false;
              this.translateService.get("REGISTERCOMPONENT.OWNER_EMAIL_EXISTS.LABEL").subscribe((text: string) => {
                this.alertService.error(text, false);
              })
            }
          })
        }
      })

    this.phoneChanged
      .pipe(
        // Time in milliseconds between key events
        debounceTime(500))
      // subscription for response
      .subscribe(() => {
        if (this.registerData.userregister.phone != null && this.registerData.userregister.phone.length > 0) {
          this.userRegisterController.checkPhone(this.registerData.userregister.prefix, this.registerData.userregister.phone).subscribe((data: boolean) => {
            if (!data) {
              this.validPhone = true;
            } else {
              this.validPhone = false;
              this.translateService.get("REGISTERCOMPONENT.OWNER_PHONE_EXISTS.LABEL").subscribe((text: string) => {
                this.alertService.error(text, false);
              })
            }
          })
        }
      })

    this.securityController.getCurrentLanguage().then(
      d => { this.languagevalue = d; }
    );
  }

  public registerFormGroup: FormGroup;

  private emailChanged = new Subject<string>();
  public emailChangedEvent() {
    this.emailChanged.next();
  }
  enumLanguages = Languages;
  public _languagevalue: Languages;
  set languagevalue(value: Languages) {
    if (this._languagevalue == value)
      return;

    this._languagevalue = value;
    this.registerData.language = value;
    this.securityController.setLanguage(value);
  }

  get languagevalue(): Languages {
    return this._languagevalue;
  }

  public languageChanged() {
    //this.emailChanged.next();
    this.securityController.setLanguage(this.languagevalue);

  }

  // El correu no existeix o és el d'un non-owner
  public validEmail: boolean = true;

  private phoneChanged = new Subject<string>();
  public phoneChangedEvent() {
    this.phoneChanged.next();
  }

  // El telefon no existeix o és el d'un non-owner
  public validPhone: boolean = true;

  public canRegister(): boolean {
    if (this.registerData.userregister.phone == "" || this.registerData.userregister.eMail != "")
      return this.validEmail && !this.registerFormGroup.invalid;

    return this.validPhone && !this.registerFormGroup.invalid;

  }

  private _registerData: UserRegisterWizardDto = new UserRegisterWizardDto;
  public set registerData(value: UserRegisterWizardDto) {
    this._registerData = value;
  }
  public get registerData(): UserRegisterWizardDto {
    return this._registerData;
  }

  public pendigResponse: boolean = false;
  onClickRegisterButton() {
    if (this.pendigResponse)
      return;
    this.pendigResponse = true;
    this.registerData.timeZone = TimeZoneUtil.getTimeZoneFromBrowser();
    console.log("regdata", this.registerData)
    this.userRegisterController.registerEMail(this.registerData).subscribe(data => {
      this.pendigResponse = false;
    }, error => {
      this.pendigResponse = false;
    });
  }

  public enumPrefixes = PhonePrefix;
  public translategroup: string = 'PHONEPREFIX.ENUM';

  public _prefixModel: PhonePrefix = PhonePrefix.Spain_34;
  set prefixModel(value: PhonePrefix) {
    if (this._prefixModel === value)
      return;

    this._prefixModel = value;
    this.registerData.userregister.prefix = PhonePrefix[this._prefixModel];
  }
  get prefixModel(): PhonePrefix {
    return this._prefixModel;
  }

  public goHome() {
    this.router.navigate(['/']);
  }
  public onLoginButtonClick() {
    this.router.navigateByUrl("/logout");
  }

  pretty(params: any): string {
    return JSON.stringify(params);
  }
}
