import { Component, EventEmitter, Output, Input, Inject } from '@angular/core';
import { QuestionItemMaintenanceDto } from '@shared/src/controllers/maintenance/form/question/QuestionItemMaintenanceDto';
import { FormQuestionAnswerShipmentItemDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentItemDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageDocument } from '@shared/src/dtos/document/ImageDocument';
import { DocumentDto } from '@shared/src/dtos/document/DocumentDto';
import { RouteDocumentTypes } from '@shared/src/enums/RouteDocumentTypes';
import { HomeController, } from '@shared/src/controllers/home/home.controller';
import { QuestionTypes } from '@shared/src/enums/QuestionTypes';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { MatDialog } from '@angular/material/dialog';
import { DocumentDetailDto } from '@shared/src/dtos/document/DocumentDetailDto';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';
import { DocumentParentTypes } from '@shared/src/enums/DocumentParentTypes';

@Component({
  selector: 'shared-form-question-answer-shipment-item',
  templateUrl: './shared.form.question.answer.shipment.item.html',
  styleUrls: ['./../shared.form.questions.answers.shipment.scss']
})
export class SharedFormQuestionAnswerShipmentItem {

  public useIonic: boolean = false;
  rForm: FormGroup;
  constructor(private fb: FormBuilder, protected shipmentController: ShipmentController, protected openRouteController: OpenRouteController, public dialog: MatDialog, @Inject('HomeController') protected homecontroller: HomeController) {

    this.rForm = this.fb.group({
      'valueBoolean': ['', Validators.nullValidator],
      'valueInteger': ['', Validators.nullValidator],
      'valueDouble': ['', Validators.nullValidator],
      'valueTriState': ['', Validators.nullValidator],
      'valueString': ['', Validators.nullValidator]
    });

    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input()
  public iReadonly: boolean;

  @Input()
  public showDefaultCamera: boolean = false;

  public parentObject: DocumentParentObjectDto;

  private _shipment: ShipmentCardDto;
  @Input() public set shipment(value: ShipmentCardDto) {
    if (this._shipment === value)
      return;

    this._shipment = value;
    this.parentObject = value != null ? DocumentParentObjectDto.build(DocumentParentTypes.Shipment, 0, value.shipmentId, 0, 0) : null;
  }
  public get shipment(): ShipmentCardDto {
    return this._shipment;
  }

  private _value: FormQuestionAnswerShipmentItemDto;
  @Input() public set value(value: FormQuestionAnswerShipmentItemDto) {
    if (this._value === value)
      return;

    this._value = value;
    this.loadData();
  }
  public get value(): FormQuestionAnswerShipmentItemDto {
    return this._value;
  }

  @Output() valueChanged: EventEmitter<FormQuestionAnswerShipmentItemDto> = new EventEmitter();
  onValueChanged(value: any) {
    this.valueChanged.next(this.value);
  }

  public question: QuestionItemMaintenanceDto;

  loadData() {
    if (this.value != null && this.value.formQuestion != null)
      this.question = this.value.formQuestion.question;

  }

  getPrefixFileNameImage() {
    let filename: string = "";
    if (this.question != null && this.question.questionType != null)
      filename = this.question.questionType + ".";
    return filename;
  }

  onSelectedImage(value: ImageDocument) {

  }

  public imageList: ImageDocument[];
  onTakeImage(images: ImageDocument[]) {
    this.imageList = images;

    let documentType = RouteDocumentTypes.Other;
    if (this.question != null) {
      documentType = this.question.questionType == QuestionTypes.CMR ? RouteDocumentTypes.CMR : (this.question.questionType == QuestionTypes.Delivery ? RouteDocumentTypes.DeliveryNote : RouteDocumentTypes.Other);
    }

    this.pushCMRAlbaraImage(documentType);
  }

  pushCMRAlbaraImage(documentType: RouteDocumentTypes) {
    if (this.value.document == null) {
      this.value.document = new DocumentDto();
    }

    this.value.document.documentDetailList = new Array();
    if (this.imageList != null && this.imageList.length > 0) {
      this.imageList.forEach(element => {

        let documentDetail: DocumentDetailDto = new DocumentDetailDto();
        documentDetail.documentType = documentType;
        documentDetail.fileName = element.filename;
        documentDetail.fileBytes = element.filebytes;
        documentDetail.fileType = element.filetypes;

        this.value.document.add(documentDetail);

      });
    }
    this.valueChanged.next(this.value);

  }


  onEndDrawSignature(signature) {
    if (this.value == null || signature == null || signature.signbytes == null || signature.bytestype == null) {
      this.onClearSignature(true);
      return;
    }

    this.value.fileSignatureBytes = signature.signbytes;
    this.value.fileSignatureType = signature.bytestype;
    this.valueChanged.next(this.value);
  }

  onClearSignature(value: boolean) {
    if (!value)
      return;

    this.value.fileSignatureBytes = null;
    this.value.fileSignatureType = null;
    this.valueChanged.next(this.value);
  }

  getFileName(document: DocumentDto): string {
    let fileName: string = "";
    if (document != null) {
      fileName = document.fileName + " (" + document.userDescription + ")";
    }
    return fileName;
  }

  public downloadDocumentDetail(doc: DocumentDetailDto) {
    if (this.shipment == null)
        return;

    this.openRouteController.downloadShipmentDocumentDetail(this.shipment.shipmentId, doc.documentDetailId).subscribe(data => {
        FileUpDownloadController.resultDownLoad(data);
    });
  }

  isImageDocument(doc: DocumentDto): boolean {
    if (doc == null)
        return;
    return FileUpDownLoadDto.isImageType(doc.fileType);
  }

  
}
