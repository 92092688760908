import { HomeController } from "../controllers/home/home.controller";
import { HColor } from "./HColor";

/***
 * Representa una llista d'etiquetes. Relacionat amb el HTags del back.
 * Poden estar obertes o tancades en un univers
 */
export class HTag {
    public readonly id: string;
    public label: string;
    public help: string;
    public tooltip: string;
    public active: boolean = false;
    public background_color: string = '#000000';
    public background_colorNA: string = '#00000055';
    public styleActive: any;
    public styleNoActive; any;

    constructor(id: string) {
        this.id = id;
        this.label = 'HTAG.' + id.toUpperCase() + ".CAPTION";
        this.help = 'HTAG.' + id.toUpperCase() + ".HELP";
        this.tooltip = 'HTAG.' + id.toUpperCase() + ".TOOLTIP";
    }

    public static buildWithResources(id: string, label: string, help: string, tooltip: string): HTag{
        let result = new HTag(id);
        result.label = label;
        result.help = help;
        result.tooltip = tooltip;
        return result;
    }

    public static build(id: string, active: boolean, homeController: HomeController): HTag {
        let result = new HTag(id);
        result.active = active;
        result.background_color = HColor.getColorFromStringTable(id, homeController);
        result.background_colorNA = result.background_color + "55";

        result.styleActive = {
            'background-color': result.background_color,
            'color': HColor.getWhiteBlackFromColor(result.background_color)
        };

        result.styleNoActive = {
            'background-color': result.background_colorNA,
            'color': HColor.getWhiteBlackFromColor(result.background_colorNA)
        };

        return result;
    }
}