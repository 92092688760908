import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { BaseController } from '@shared/src/controllers/base.controller';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs';
import { TrackingShipmentDto } from '@shared/src/dtos/tracking/TrackingShipmentDto';

@Injectable({
    providedIn: 'root',
})
export class DesktopTrackController extends BaseController {
    private base = '/track'

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,) {

        super(http, alertService, translateService, loadingService, router);

    }

    public getTrackShipment(readuuid: string): Observable<TrackingShipmentDto> {
        return this.get(this.base + "/shipment/" + readuuid);
    }

    public getTrackShipmentById(shipmentId: number): Observable<TrackingShipmentDto> {
        return this.get(this.base + "/shipment/byid/" + shipmentId);
    }

}
