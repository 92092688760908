<div *ngIf="routePartCMR">
    <div class="title">{{'ROUTEPARTCMR.EVENT.TITLE'| translate}}</div>
    <div class="bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    </div>
    <div class="info" *ngIf="'LISTEVENTS'==selectedOptionId">
        <div class="block info">
            <shared-cmr-events-list [events]="getListEvents()">
            </shared-cmr-events-list>
        </div>
    </div>
    <div class="info" *ngIf="'ADDEVENT'==selectedOptionId">
        <form class="form" [formGroup]="formGroup">
            <div class="block">
                <input-component class="col-x" [(ngModel)]="event"
                    placeholder="{{'ROUTEPARTCMR.MAINTENANCE.EVENT.CAPTION' | translate}}" name="event" formControlName="event"
                    autocomplete="off" [readonly]="readonly">
                </input-component>
            </div>

            <button *ngIf="!readonly && pendingSave" class="confirmButton" (click)="onAddEvent()" [disabled]="!isValid">{{'ROUTEPARTCMR.SAVEEVENT.BUTTON' | translate }}</button>

        </form>
    </div>
</div>
