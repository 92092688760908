<div class="container" *ngIf="booking">
    <div class="states-selector">
        <div *ngIf="!shownextstate || availableStatuses == null || availableStatuses.length == 0"
            class="select-label-current" [ngClass]="booking.status" (click)="onSelectLabel()">
            <div class="state-label">{{ resourceStateCurrentStatus | translate }} <i
                    *ngIf="!readonly" class="fa fa-caret-down separat"></i></div>
        </div>
        <div *ngIf="shownextstate && availableStatuses != null && availableStatuses.length > 0"
            class="select-label-next" [ngClass]="availableStatuses[0]">
            <div class="state-label" (click)="onSelectorChangedState(availableStatuses[0])">
                {{ resourceStateNextStatus | translate }}
            </div>
            <div class="select-dropdown" [ngClass]="availableStatuses[0]" (click)="onSelectLabel()">
                <i *ngIf="!readonly" class="fa fa-caret-down"></i>
            </div>
        </div>
        <div class="list-container" #listZone>
            <div class="list-zone" [ngClass]="listPosition" *ngIf="showList">
                <div *ngFor="let item of availableStatuses" (click)="onSelectorChangedState(item)"
                    class="selector-state-item">
                    <div class="selector-state-item-container">
                        <div class="selector-state-description">{{ getResourceItemToChange(item) | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="states-pane" [ngClass]="booking.status">
        <div class="line titol">
            <span class="prompt">{{'SHARED.STATE.COMPONENT.ACTUALSTATE.PROMPT' | translate}}</span><span
                class="value">{{ resourceStateCurrentStatus | translate }}</span>
        </div>
        <div class="line help">
            {{helpResource | translate}}
        </div>
        <div class="botonera">
            <button class="boto" *ngFor="let item of availableStatuses" (click)="stateClick(item)" [ngClass]="item"
                [disabled]="readonly">{{ getResourceItemToChange(item) | translate }}</button>
        </div>
    </div>
</div>