import { Component, Input, OnInit } from '@angular/core';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';

@Component({
  selector: 'shared-booking-card',
  templateUrl: './shared.booking.card.component.html',
  styleUrls: ['./shared.booking.card.component.scss']
})
export class SharedBookingCardComponent implements OnInit {

  constructor(protected bookingController: BookingController) { }

  ngOnInit(): void { }

  private _booking: BookingDto;
  @Input() public set booking(v: BookingDto) {
    if (v && this._booking && JSON.stringify(v) === JSON.stringify(this._booking))
      return;

    if (v) {
      v.userTags = HTags.buildFromDto(v.userTags);
      v.internalTags = HTags.buildFromDto(v.internalTags);
    }

    this._booking = v;

    this.calculateGeneralClass();
    this.calculateSlotStatus();
  }

  public get booking(): BookingDto {
    return this._booking;
  }

  public generalClass: string = "";
  public calculateGeneralClass() {
    if (!this.booking)
      return;
    this.generalClass = HTags.convertToStringWithDelimiter(this.booking.internalTags, " ");
    this.generalClass += " " + this.booking.status;
  }


  public slotsStatus: string;

  public calculateSlotStatus() {
    let result: string = undefined;
    if (!this._booking) {
      return;
    } else {
      let total = this._booking.places;
      let available = this._booking.slotsAvailable;
      let percentage = (available * 100 / total);

      if (percentage == 0) {
        result = "completed";
      } else if (percentage <= 50 && percentage > 0) {
        result = "partially-occupied";
      } else if (percentage > 50) {
        result = "incompleted"
      }
    }

    this.slotsStatus = result;
  }

}
