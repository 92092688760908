import { RouteShipmentTypes } from "@shared/src/enums/RouteShipmentTypes";

/**
 * Aquesta clase te un reflex a back!!!
 */
export class RouteTypesModelsFuncionament {

    public routeShipmentType: RouteShipmentTypes = RouteShipmentTypes.None;
    public adrecaOrigenVisible: boolean = true;
    public adrecaDestiVisible: boolean = true;
    public adrecaOrigenOpcional: boolean = false;
    public adrecaOrigenReadOnly: boolean = false;
    public adrecaDestiReadOnly: boolean = false;
    public adrecaOrigenPartner: boolean = false;
    public adrecaDestiPartner: boolean = false;
    public adrecaOrigenPropia: boolean = false;
    public adrecaDestiPropia: boolean = false;
    public adrecaOrigenTenantIdPartner: boolean = false;
    public adrecaDestiTenantIdPartner: boolean = false;

    public preguntaInicialRuta: boolean = false;

    public reverseLogisticsPartnersVisible: boolean = false;

    public proveidorOrigenVisible: boolean = false;
    public clientOrigenVisible: boolean = false;
    public proveidorDestiVisible: boolean = false;
    public clientDestiVisible: boolean = false;

    public dataOrigenReadOnly: boolean = false;
    public dataOrigenVisible: boolean = true;
    public dataOrigenRequerida: boolean = false;

    public dataDestiReadOnly: boolean = false;
    public dataDestiVisible: boolean = true;
    public dataDestiRequerida: boolean = false;

    public seleccioShipmentsOrigen: boolean = false;
    public shipmentsOrigenOpcional: boolean  = false;
    public canAddShipments: boolean = false;
    public canDeleteShipments: boolean = false;

    public static build(): RouteTypesModelsFuncionament {
        return new RouteTypesModelsFuncionament();
    }

    public static buildByType(type: RouteShipmentTypes, freeReturn: boolean, reverseLogisticsOriginOwn: boolean): RouteTypesModelsFuncionament {
        let modelFuncionament = new RouteTypesModelsFuncionament();
        switch(type){
            case RouteShipmentTypes.Return:
                if(freeReturn)
                    return modelFuncionament.setTypeAsFreeReturn();
                return modelFuncionament.setTypeAsReturn();
            case RouteShipmentTypes.Nonacceptance:
                return modelFuncionament.setTypeAsNonacceptance();
            case RouteShipmentTypes.Delegate:
                return modelFuncionament.setTypeAsDelegate();
            case RouteShipmentTypes.Inbound:
                return modelFuncionament.setTypeAsInBound();
            case RouteShipmentTypes.Outbound:
                return modelFuncionament.setTypeAsOutBound();
            case RouteShipmentTypes.ReverseLogistics:
                if(reverseLogisticsOriginOwn)
                    return modelFuncionament.setTypeAsReverseLogisticsOriginOwn();
                return modelFuncionament.setTypeAsReverseLogisticsDestinationOwn();
            default:
        }
        return modelFuncionament;
    }

    /***
     * LEs adreces no es poden tocar
     */
    public setTypeAsReturn(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Return;
        this.seleccioShipmentsOrigen = true;
        this.shipmentsOrigenOpcional = true;
        this.preguntaInicialRuta = true;
        this.adrecaOrigenOpcional = true;
        this.adrecaOrigenReadOnly = true;
        this.adrecaDestiPropia = true;
        this.dataDestiRequerida = true;
        
        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = true;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;
        return this;
    }

     /***
     * LEs adreces no es poden tocar
     */
      public setTypeAsFreeReturn(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Return;
        this.seleccioShipmentsOrigen = true;
        this.shipmentsOrigenOpcional = true;
        this.preguntaInicialRuta = true;
        this.adrecaOrigenOpcional = true;
       
        this.adrecaDestiPropia = true;
        this.adrecaOrigenPartner = true;
        this.dataDestiRequerida = true;
        this.canAddShipments = true;
        this.canDeleteShipments = true;

        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = true;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;
   
        return this;
    }

    public setTypeAsReverseLogistics(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.ReverseLogistics;
 
        this.preguntaInicialRuta = true;

        this.adrecaOrigenPropia = false;
        this.adrecaOrigenPartner = false;
        this.adrecaOrigenTenantIdPartner = false;
        this.adrecaOrigenOpcional = false;

        this.adrecaDestiPropia = false;
        this.adrecaDestiPartner = false;
        this.adrecaDestiTenantIdPartner = false;
    
        this.reverseLogisticsPartnersVisible = true;

        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = false;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;

        this.dataDestiRequerida = true;
        this.dataOrigenRequerida = true;

		//Només tendrem un shipment
		this.canAddShipments = false;
		this.canDeleteShipments = false;
   
        return this;
    }
    
    //'Em venen a buscar algo' --> les adreces d'origen han de ser meves i s'han d'aplicar filtre d'slots actius i les de destí han de ser del meu proveidor
	public setTypeAsReverseLogisticsOriginOwn(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.ReverseLogistics;
 
        this.preguntaInicialRuta = true;

        this.adrecaOrigenPropia = true;
        this.adrecaOrigenPartner = false;
        this.adrecaOrigenTenantIdPartner = false;
        this.adrecaOrigenOpcional = false;

        this.adrecaDestiPropia = false;
        this.adrecaDestiPartner = false;
        this.adrecaDestiTenantIdPartner = true;
    
        //Mostro tots els proveidors
        this.reverseLogisticsPartnersVisible = true;

        //El destí no és mai obligatori i l'origen sempre
        this.dataDestiRequerida = false;
        this.dataOrigenRequerida = true;

		//Només tendrem un shipment
		this.canAddShipments = false;
		this.canDeleteShipments = false;
   
        return this;
    }

    //'Vaig a buscar algo' --> les adreces d'origen han de ser les del meu client i s'han d'aplicar filtre d'slots actius i les de destí son les meves
    public setTypeAsReverseLogisticsDestinationOwn(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.ReverseLogistics;

        this.preguntaInicialRuta = true;

        this.adrecaOrigenPropia = false;
        this.adrecaOrigenPartner = false;
        this.adrecaOrigenTenantIdPartner = true;
        this.adrecaOrigenOpcional = true;

        this.adrecaDestiPropia = true;
        this.adrecaDestiPartner = false;
        this.adrecaDestiTenantIdPartner = false;
    
        //Mostro tots els proveidors
        this.reverseLogisticsPartnersVisible = true;

         //El destí no és mai obligatori i l'origen sempre
         this.dataDestiRequerida = false;
         this.dataOrigenRequerida = true;

		//Només tendrem un shipment
		this.canAddShipments = false;
		this.canDeleteShipments = false;
   
        return this;
    }

    /***
     * Les adreces no es poden tocar. No es pot canviar dataOrigen, no hem descarregat
     */
    public setTypeAsNonacceptance(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Nonacceptance;
        this.seleccioShipmentsOrigen = true;
        this.preguntaInicialRuta = true;
        this.shipmentsOrigenOpcional = false;
        this.adrecaOrigenReadOnly = true;
        this.dataOrigenVisible = false;
        this.adrecaDestiPropia = true;
        this.dataDestiRequerida = true;

        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = true;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;
        return this;
    }

    /***
     * Son coses d'altres, origen i destí son dels altres
     */
    public setTypeAsDelegate(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Delegate;
        this.adrecaOrigenPartner = true;
        this.adrecaDestiPartner = true;
        this.canAddShipments = true;
        this.canDeleteShipments = true;

		this.dataOrigenRequerida = true;
		this.dataDestiRequerida = true;

        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = true;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;
        return this;
    }

    /***
     * Coses que ens arriben, el destí és propi
     */
    public setTypeAsInBound(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Inbound;
        this.adrecaOrigenOpcional = true;
        this.adrecaOrigenPartner = true;
        this.adrecaDestiPropia = true;
        this.dataDestiRequerida = true;
        this.canAddShipments = true;
        this.canDeleteShipments = true;

        this.proveidorOrigenVisible = true;
		this.clientOrigenVisible = false;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = false;
        return this;
    }

    /***
     * Coses que marxen, l'origen és propi
     */
    public setTypeAsOutBound(): RouteTypesModelsFuncionament {
        this.routeShipmentType = RouteShipmentTypes.Outbound;
        this.dataOrigenRequerida = true;
        this.adrecaDestiPartner = true;
        this.adrecaOrigenPropia = true;
        this.canAddShipments = true;
        this.canDeleteShipments = true;

        this.proveidorOrigenVisible = false;
		this.clientOrigenVisible = false;
		this.proveidorDestiVisible = false;
		this.clientDestiVisible = true;
        return this;
    }

}