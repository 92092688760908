import { Injectable } from "@angular/core";
import { CMREventTypes } from "@shared/src/enums/CMREventTypes";
import { HDateHour } from "@shared/src/public-api";

@Injectable()
export class BaseCMREventDto {
    public id: string;
	public type: string;
	public userId: number;
	public alias: string;
	public userName: string;
	public event: string;
	public eventAt: HDateHour;
	public cmrEventType: CMREventTypes;

}