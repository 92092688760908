import { HString } from '@shared/src/datatypes/HString';

/***
 * Encapsula totes les utilitats referents a una enumeració
 */
export class HEnum {

    public static getEnumResource(enumtype: string, enumvalue: string): string {
        let str = (enumtype + "." + enumvalue).toUpperCase();
        return (str + ".CAPTION");
    }

    public static getEnumHelpResource(enumtype: string, enumvalue: string): string {
        let str = (enumtype + "." + enumvalue).toUpperCase();
        return (str + ".HELP");
    }

    /***
     * Retorna una enumeración com un array evitant els valors numèrics
     */
    public static getAsArray(value: any): any {
        let keys = new Array<string>();
        if (!value)
            return keys;
        for (var enumMember in value) {
            if (isNaN(parseInt(enumMember, 10)))
                keys.push(enumMember.toString());
        }
        return keys;
    }


    /***
     * Retorna l'enumeració sense un valor determinat. Normalment per a que posis none al valorQueNovols
     */
    public static getAsArrayExcept(enumeracio: any, valorQueNoVols: any): any {
        if (valorQueNoVols == null)
            return HEnum.getAsArray(enumeracio);

        let result = [];
        let preLlista = HEnum.getAsArray(enumeracio);
        for (var enumMember in preLlista) {
            if (preLlista[enumMember] != valorQueNoVols) {
                result.push(preLlista[enumMember]);
            }
        }
        return result;
    }

    public static getStringFromEnum(valor: any, enumeracio: any): string {
        if (enumeracio) {
            let senum = enumeracio[valor];
            if (!senum)
                return valor;
            return senum;
        }
        return valor;
    }
    public static getEnumFromString(valor: string, enumeracio: any): any {
        if (enumeracio) {
            let senum = valor;
            for (var enumMember in enumeracio) {
                let senummember = enumeracio[enumMember];
                if (senummember == valor) {
                    senum = enumMember;
                    break;
                }
            }

            return senum;
        }
        return valor;
    }
    public static getEnumFromStringWithDefaultValue(valor: string, enumeracio: any, defaultValue: any): any {
        if (enumeracio) {
            let senum = defaultValue;
            for (var enumMember in enumeracio) {
                let senummember = enumeracio[enumMember];
                if (senummember == valor) {
                    senum = enumMember;
                    break;
                }
            }
            return senum;
        }
        return defaultValue;
    }
}