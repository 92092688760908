import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LogController } from '../log/LogController';
import { SecurityController } from './security.controller';
import { AlertService } from '../../services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/public-api';

/***
 * Es el filtre de la seguretat. Assegura al cridar-se cada opció, si està protegida per aquest que es pot accedir-hi
 */
@Injectable()
export class AuthGuard implements CanActivate {


  constructor(@Inject('SecurityController') protected securityController: SecurityController, 
  private logController: LogController, 
  @Inject('AlertService') protected alertService: AlertService,
  protected translateService: TranslateService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(resolve => {
      this.securityController.securityLoaded$.subscribe(value => {
        this.calculateSecurity(route, state).then(value => {
          this.logController.navigate(route.routeConfig.path, value);
          resolve(value);
        });
      })
      if (this.securityController.isSecurityLoaded()) {
        this.calculateSecurity(route, state).then(value => {
          this.logController.navigate(route.routeConfig.path, value);
          resolve(value);
        });
      }
    });


  }

  private calculateSecurity(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(resolve => {
      this.securityController.isLogged().subscribe(value => {
        if (value) {
          let pathreal = state.url;
          let pathvirtual = route.routeConfig.path;
          try {
            return this.securityController.canAccess(pathreal, pathvirtual).subscribe(canaccess => {
              if(!canaccess){
                this.translateService.get("AUTHCUARD.CANNOTACCESSPATH", { value: pathreal }).subscribe((text: string) => {
                  console.log(text);
                  this.alertService.error(text, false);
                })
              }
              resolve(canaccess);
            });
          }
          catch {
            resolve(false);
          }
        } else {
          this.securityController.goToLogin();
          resolve(false);
        }
      });
    });
  }
} 
