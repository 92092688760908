export enum RoutePartCMRSignatureAgents {
    sender = "sender",
    carrier = "carrier",
    consignee = "consignee",
    driver = "driver",
    none = "none"

}
export class RoutePartCMRSignatureAgentsEnum {
    public static ID(agent: RoutePartCMRSignatureAgents) {
        return agent.toString().toUpperCase();
    }
    public static CAPTION(agent: RoutePartCMRSignatureAgents) {
        return "ROUTEPARTCMRSIGNATUREAGENTS.ENUM." + agent.toString().toUpperCase();
    }
    public static HELP(agent: RoutePartCMRSignatureAgents) {
        return "ROUTEPARTCMRSIGNATUREAGENTS.HELP." + agent.toString().toUpperCase();
    }
}