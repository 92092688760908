import {
    Component,
    forwardRef,
    Input,
    Inject,
    Output,
    EventEmitter,
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ConfirmationDialogDialogComponent } from "./dialog/confirmationdialog.dialog.component";
import { AlertController } from "@ionic/angular";
import { HomeController } from "@shared/src/controllers/home/home.controller";

export const NOSEPERQUESERVEIXA: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ConfirmationDialogDialogComponent),
    multi: true,
};

@Component({
    selector: "confirmation-dialog",
    templateUrl: "./shared.confirmationdialog.component.html",
    styleUrls: ["./shared.confirmationdialog.component.scss"],
    providers: [NOSEPERQUESERVEIXA],
})
export class SharedConfirmationDialogComponent {
    @Output() public onConfirm: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() public onCancel: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    @Input() helptitle: string = "";
    @Input() helptitleresource: string = "HELP.FIELD";
    @Input() paramstitle: any;
    @Input() helpresource: string = "";
    @Input() helptext: string = "";
    @Input() paramstext: any;

    private _expanded: boolean = false;
    @Input() public set expanded(value: boolean) {
        if (value)
            if (this.useIonic) this.clickHelpIonic();
            else this.clickHelpNoIonic();

        this._expanded = value;
    }

    public get expanded(): boolean {
        return this._expanded;
    }

    public useIonic: boolean = false;

    public position = "below";

    constructor(
        private translate: TranslateService,
        private mdDialog: MatDialog,
        private alertController: AlertController,
        @Inject("HomeController") protected homecontroller: HomeController
    ) {
        if (homecontroller.useIonic) this.useIonic = homecontroller.useIonic();
    }

    ngOnInit() { }

    clickHelpNoIonic() {
        let dialog = this.mdDialog.open(ConfirmationDialogDialogComponent, {
            disableClose: false,
            width: "600px",
            //height: '300px',
            data: { title: this.gettitle(), text: this.gettext() },
        });

        dialog.backdropClick().subscribe((data) => {
            this.onCancel.next(true);
        });

        dialog.componentInstance.onResolve.subscribe((data: boolean) => {
            if (data) this.onConfirm.next(true);
            else this.onCancel.next(true);
        });

        this.mdDialog.afterAllClosed.subscribe(() => { });
    }

    async clickHelpIonic() {
        const alert = await this.alertController.create({
            //cssClass: 'my-custom-class',
            header: this.gettitle(),
            //subHeader: 'Subtitle',
            message: this.gettext(),
            buttons: [
                {
                    text: this.translate.instant("CONFIRM.BUTTON"),
                    role: "confirm",
                    handler: () => {
                        this.onConfirm.next(true);
                        return false;
                    },
                },
                {
                    text: this.translate.instant("CANCEL.BUTTON"),
                    role: "cancel",
                    handler: () => {
                        this.onCancel.next(true);
                        return false;
                    },
                },
            ],
        });

        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log("onDidDismiss resolved with role", role);
    }

    gettext() {
        if (this.helptext != null && this.helptext != "") return this.helptext;

        if (this.helpresource != null && this.helpresource != "")
            return this.translate.instant(this.helpresource, this.paramstext);

        return "";
    }

    gettitle() {
        if (this.helptitle != null && this.helptitle != "")
            return this.helptitle;

        if (this.helptitleresource != null && this.helptitleresource != "")
            return this.translate.instant(
                this.helptitleresource,
                this.paramstitle
            );

        return "";
    }
}
