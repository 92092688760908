<div *ngIf="routePartCMR">
    <div class="bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <button *ngIf="'EDIT'==selectedOptionId && pendingSave" class="confirmButton" (click)="onSaveChanges()">{{'ROUTEPARTCMR.SAVE.BUTTON' | translate }}</button>
        <div class="baractions">
            <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>

    <div class="info" *ngIf="'ORIGIN'!=selectedOptionId">
        <shared-route-cmr-delivery-line class="line" *ngFor="let line of getLines() | sortBy:'order'; let i=index"
            [readonly]="'ACTUAL'==selectedOptionId || !routePartCMR.canModify" [lineObject]="line" (valueChange)="onValueLineChange($event)">
        </shared-route-cmr-delivery-line>
    </div>

    <div class="info" *ngIf="'ORIGIN'==selectedOptionId">
        <shared-route-cmr-delivery-origin-line class="line" *ngFor="let line of getLinesOrigin() | sortBy:'order'; let i=index"
        [readonly]="true" [lineObject]="line">
        </shared-route-cmr-delivery-origin-line>
    </div>
</div>
