import { Languages } from '../../enums/Languages';

export class LanguagesUtil {
    public static getLocaleString(language: Languages): string {
        switch (language) {
            case Languages.ca:
                return "ca-CA";
            case Languages.es:
                return "es-ES";
            case Languages.en:
                return "en-EN";
            default:
                return "es-ES";
        }
    }
    public static getByString(language: string): Languages {

        if (!language)
            return Languages.es;

        if (language.startsWith("en"))
            return Languages.en;
        if (language.startsWith("es"))
            return Languages.es;
        if (language.startsWith("ca"))
            return Languages.ca;
        if (language.startsWith("fr"))
            return Languages.fr;
        if (language.startsWith("pt"))
            return Languages.pt;
        if (language.startsWith("sv"))
            return Languages.sv;

        console.error("Language not defined: " + language);
        
        return Languages.es;
    }
}