import { Component, Input, OnInit } from '@angular/core';
import { LocalizableObjectTypes } from '@shared/src/controllers/maps/LocalizableObjectDto';
import { PhaseLocalizableObjectDto } from '@shared/src/controllers/maps/RouteLocalizableObjectDto';
import { HTags } from '@shared/src/datatypes/HTags';
import { DateHourFilter, HourFilter, HDate, HDateHour, HHour } from '@shared/src/public-api';
import { MapMarcadorDto } from '@shared/src/services/map/map.service';


@Component({
  selector: 'shared-map-info-window',
  templateUrl: './shared.map.info.window.component.html',
  styleUrls: ['./shared.map.info.window.component.scss']
})


export class SharedMapInfoWindowComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  private _marcador: MapMarcadorDto;
  @Input() set marcador(value: MapMarcadorDto) {
    if (this._marcador === value)
      return;

    this._marcador = value;
    this.calculateClass();
    this.getTitle();

    if (this._marcador.type == LocalizableObjectTypes.phase && this._marcador.phase)
      this.tags = HTags.getTags(this._marcador.phase.tags);
  }
  get marcador(): MapMarcadorDto {
    return this._marcador;
  }

  public tags: Array<string>;
  public generalClass: string = "";
  private calculateClass() {
    this.generalClass = "";
    if (this._marcador != null)
      this.generalClass += " " + this.marcador.sTags;
  }

  getTitle() {
    if (this.marcador.routeLocalizableObject != null && this.marcador.routeLocalizableObject.title != null)
      this.marcador.label = this.marcador.routeLocalizableObject.title;
  }


  isLoad(phase: PhaseLocalizableObjectDto) {
    if (phase != null)
      if (phase.isLoad)
        return true;
    return false;
  }

  isUnLoad(phase: PhaseLocalizableObjectDto) {
    if (phase != null)
      if (phase.isLoad)
        return false;
    return true;
  }

  colorEta(phase: PhaseLocalizableObjectDto) {
    if (HDateHour.isBefore(phase.commitentEnd, phase.ETA))
      return "green";

    return "red";
  }

  public hourTodayEta: HHour;
  isTodayEta(date: HDateHour) {
    if (HDate.equals(HDate.today(), HDateHour.getDate(date))) {
      this.hourTodayEta = HDateHour.getHour(date);
      return true;
    }

    return false;
  }

  public hourTodayCommitent: HHour;
  isTodayCommitent(date: HDateHour) {
    if (HDate.equals(HDate.today(), HDateHour.getDate(date))) {
      this.hourTodayCommitent = HDateHour.getHour(date);
      return true;
    }

    return false;
  }
}
