import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { HHour } from '../datatypes/HHour';
import { HString } from '../public-api';

@Pipe({
	name: 'hour'
})
@Injectable()
export class HourFilter implements PipeTransform {
	transform(value: HHour): string {
		if (value == null)
			return "--:--";
		let result: string;

		result = this.pad(value.h, 2) + ":" + this.pad(value.m, 2);
		return result;
	}

	pad(num, size): string {
		let s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'hourNotNull'
})
@Injectable()
export class HourFilterNotNull implements PipeTransform {
	transform(value: HHour): string {
		if (value == null || HHour.isNullOrNullValue(value))
			return "";

		return new HourFilter().transform(value);
	}
}