import { Pipe, PipeTransform } from '@angular/core';
import { OptionDto } from './OptionDto';

@Pipe({
    name: "onlyOptions"
})
export class onlyOptionsFilter implements PipeTransform {
    public transform(items: OptionDto[], term: string) {
        return onlyOptionsFilter.filter(items, term);
    }

    public static filter(items: OptionDto[], term: string): OptionDto[] {
        if (!items) return [];
        if (!term) return items;

        let lowercase = term.toLowerCase();
        let result = items.filter(value => {
            return onlyOptionsFilter.doTheFilter(lowercase, value)
        });
        return result;
    }

    public static doTheFilter(value: string, option: OptionDto): boolean {
        if (value != null && option != null && option.classification != null) {
            if (option.classification.toLowerCase() === value)
                return true;
            let classifications = option.classification.split(",");
            let i = 0;
            while (i < classifications.length) {
                let classification = classifications[i];
                if (classification != null && classification.trim() === value)
                    return true;
                i++;
            }
        }
        return false;

    }
}