import {
    Component,
    forwardRef,
    Input,
    Inject,
    Output,
    EventEmitter,
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { UpdateAddressDialogDialogComponent } from "./dialog/updateaddressdialog.dialog.component";

export const SharedUpdateAddressDialogComponent_NOSEPERQUESERVEIXA: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UpdateAddressDialogDialogComponent),
    multi: true,
};

@Component({
    selector: "updateaddress-dialog",
    templateUrl: "./shared.updateaddressdialog.component.html",
    styleUrls: ["./shared.updateaddressdialog.component.scss"],
    providers: [SharedUpdateAddressDialogComponent_NOSEPERQUESERVEIXA],
})
export class SharedUpdateAddressDialogComponent {
    @Output() public onConfirm: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() public onCancel: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    @Input() title: string = "";
    @Input() paramstitle: any;

    @Input() routePartCMRId: number;

    @Input() isOrigin: boolean = false;
    @Input() isDestination: boolean = false;
    @Input() key: string;
    @Input() name: string;
    @Input() formattedAddress: string;
    @Input() contactPhone: string;

    private _expanded: boolean = false;
    @Input() public set expanded(value: boolean) {
        if (value)
            this.clickHelpNoIonic();

        this._expanded = value;
    }

    public get expanded(): boolean {
        return this._expanded;
    }

    public useIonic: boolean = false;

    public position = "below";

    constructor(
        private translate: TranslateService,
        private mdDialog: MatDialog,
        @Inject("HomeController") protected homecontroller: HomeController
    ) {
        if (homecontroller.useIonic) this.useIonic = homecontroller.useIonic();
    }

    ngOnInit() { }

    clickHelpNoIonic() {
        let dialog = this.mdDialog.open(UpdateAddressDialogDialogComponent, {
            disableClose: true,
            width: "600px",
            //height: '300px',
            data: {
                title: this.gettitle(),
                routePartCMRId: this.routePartCMRId,
                key: this.key,
                name: this.name,
                formattedAddress: this.formattedAddress,
                contactPhone: this.contactPhone,
                isOrigin: this.isOrigin,
                isDestination: this.isDestination
            },
        });

        dialog.backdropClick().subscribe((data) => {
            this.onCancel.next(true);
        });

        dialog.componentInstance.onResolve.subscribe((data: boolean) => {
            if (data) this.onConfirm.next(true);
            else this.onCancel.next(true);
        });

        this.mdDialog.afterAllClosed.subscribe(() => { });
    }

    gettitle() {
        if (this.title != null && this.title != "")
            return this.translate.instant(
                this.title,
                this.paramstitle
            );

        return "";
    }
}
