import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { BaseSubMaintenanceController } from '../../_base/BaseSubMaintenanceController';
import { AddressCustomTableListMaintenanceDto } from './AddressCustomTableListMaintenanceDto';
import { AddressCustomTableItemMaintenanceDto } from './AddressCustomTableItemMaintenanceDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { Observable } from 'rxjs-compat';

@Injectable()
export class AddressCustomTableSubmaintenanceController extends BaseSubMaintenanceController<AddressCustomTableListMaintenanceDto, AddressCustomTableItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router, "address/customtable");
    }

    public getResourceRoot(): string {
        return "ADDRESS.CUSTOMTABLE";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/activity/address/" + parentId + "/" + optionParentid + "/submain" + uri;
    }

    
    public importCustomTable(parentId: number, id: number, val: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.getBaseUrl(parentId) + "/import/" + id, val, "importCustomTable");
    }
    
    public exportCustomTable(parentId: number, id: number) : Observable<FileUpDownLoadDto> {
        return this.getBusy(this.getBaseUrl(parentId) + "/export/" + id);
    }
}
