
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleTableDto, SimpleTableFrontDto, SimpleTableRowFrontDto, SimpleTableValueDto } from './SimpleTableDto';

@Component({
  selector: 'shared-simpletable-component',
  templateUrl: './shared.simpletable.component.html',
  styleUrls: ['./canvas.scss']
})
export class SharedSimpleTableComponent {

  public _value: SimpleTableDto;
  @Input() set value(value: SimpleTableDto) {
    this._value = value;
    this._internalValue = this.transposeIfNecessary(SimpleTableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get value(): SimpleTableDto {
    return this._value;
  }

  public _internalValue: SimpleTableFrontDto;
  @Input() set internalValue(value: SimpleTableFrontDto) {
    this._internalValue = value;
  }
  get internalValue(): SimpleTableFrontDto {
    return this._internalValue;
  }

  public _graphGroupId: string = "";
  @Input() set graphGroupId(value: string) {
    this._graphGroupId = value;
    this._internalValue = this.transposeIfNecessary(SimpleTableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get graphGroupId(): string {
    return this._graphGroupId;
  }

  public _transpose: boolean = false;
  @Input() set transpose(value: boolean) {
    if (value === this._transpose)
      return;
    this._transpose = value;
    this._internalValue = this.transposeIfNecessary(SimpleTableFrontDto.buildAndFilter(this._value, this._graphGroupId));
  }
  get transpose(): boolean {
    return this._transpose;
  }

  private transposeIfNecessary(value: SimpleTableFrontDto): SimpleTableFrontDto {
    if (this._transpose && value)
      return SimpleTableFrontDto.transpose(value);
    return value;
  }

  @Output() clickRow: EventEmitter<SimpleTableRowFrontDto> = new EventEmitter<any>();
  onClickRow(row: SimpleTableRowFrontDto) {
    if (row == null)
      return;
    this.clickRow.next(row);
  }


  @Input() showTitle: boolean = false;
  @Input() searchText: string = "";

  public titlecolspan: number = 1;

  public getClass(i, j) {
    if (i == 0 || j == 0)
      return "table-component-th";

    return "table-component-td";
  }

  public pretty(value: any) {
    return JSON.stringify(value);
  }

  public getClassColumn(column: number): string {
    let classs = "";
    if (this._internalValue == null || this._internalValue.columns == null || this._internalValue.columns[column] == null) {
    }
    else if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null) { }
    else {
      classs = this._internalValue.columns[column].classStyles;
      if (this._internalValue.columns[column].resalta)
        classs += " resalta";
      if (this._internalValue.columns[column].divideix)
        classs += " divideix";

      classs += " " + this._internalValue.columns[column].typeName;
    }
    return classs;
  }


  public esResalta(column: number) {
    if (this._internalValue == null || this._internalValue.columns == null || this._internalValue.columns[column] == null)
      return false;
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].resalta;
  }
  public esDivideix(column: number) {
    if (this._internalValue == null || this._internalValue.columns == null || this._internalValue.columns[column] == null)
      return false;
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].divideix;
  }

  public isTime(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HTime";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HTime";
  }
  public isDouble(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "double" || value.explicitTypeName == "Double";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "double" || this._internalValue.columns[column].typeName == "Double";
  }
  public isBoolean(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "boolean" || value.explicitTypeName == "Boolean";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "boolean" || this._internalValue.columns[column].typeName == "Boolean";
  }
  public isInteger(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "integer" || value.explicitTypeName == "Integer" || value.explicitTypeName == "int";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "integer" || this._internalValue.columns[column].typeName == "Integer" || this._internalValue.columns[column].typeName == "int";
  }

  public isString(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "String" || value.explicitTypeName == "string";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "String" || this._internalValue.columns[column].typeName == "string";
  }
  public isHDate(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HDate";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HDate";
  }
  public isHDateHour(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HDateHour";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HDateHour";
  }
  public isHHour(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HHour";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HHour";
  }
  public isHHourInterval(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HHourInterval";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HHourInterval";
  }
  public isHTags(column: number, value: SimpleTableValueDto) {
    if (value.isExplicitType)
      return value.explicitTypeName == "HTags";
    if (this._internalValue == null || this._internalValue.columns == null || column == null || this._internalValue.columns[column] == null)
      return false;
    return this._internalValue.columns[column].typeName == "HTags";
  }


}
