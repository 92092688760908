import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class TypologyEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): TypologyEntitySelectorDto {
		let result: TypologyEntitySelectorDto = new TypologyEntitySelectorDto();
		result.loadData(EntitysSelectors.Typology, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/dataandparameters/typology";
	}

	public getUrlBack(): string {
		return "/maintenance/typology";
	}

}