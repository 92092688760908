import { HString } from '@shared/src/datatypes/HString';
import { HomeController } from "../controllers/home/home.controller";
import { HTag } from "./HTag";
import { HTagsDefined } from '../enums/HTagsDefined';

/***
 * Representa una llista d'etiquetes. Relacionat amb el HTags del back.
 * Poden estar obertes o tancades en un univers
 */
export class HTags {
    private _Tags: Array<string>;
    private _Universe: Array<string>;

    public static Separator: string = ";";
    public static SeparatorOld: string = " ";

    /***
     * retorna la llista de tags d'un HTAGS
     */
    static getTags(value: HTags): Array<string> {
        if (value == null || value._Tags == null || !Array.isArray(value._Tags))
            return new Array<string>();

        return value._Tags;
    }
    /***
     * retorna la llista de tags d'un univers
     */
    static getUniverse(value: HTags): Array<string> {
        if (value == null || value._Universe == null || !Array.isArray(value._Universe))
            return new Array<string>();

        return value._Universe;
    }

    /***
     * Retorna una llista dels tags de forma combinada entre els Tags i el Universe.
     * Els tags s'injecten com a true i els Universe restants com a false
     */
    public static getHTagArray(value: HTags, homeController: HomeController): Array<HTag> {
        let result = new Array<HTag>();
        if (value._Universe != null)
            value._Universe.forEach(element => {
                result.push(HTag.build(element, value._Tags.includes(element), homeController));
            });
        return result;
    }

    /***
     * Contraposició de getHTagArray
     */
    static fromHTagArray(processedTags: HTag[]): HTags {
        let result = new HTags();
        result._Tags = new Array<string>();
        result._Universe = new Array<string>();

        processedTags.forEach(pt => {
            if (pt.active)
                result._Tags.push(pt.id);
            result._Universe.push(pt.id);
        });

        return result;
    }

    /***
     * Transforma a llist de _Tags en un string separat per espais.
     */
    static convertToString(tags: HTags): string {
        if (tags == null || tags._Tags == null)
            return "";

        let result = "";
        tags._Tags.forEach(s => {
            result += s + HTags.Separator;
        });
        return result;
    }

    static convertToStringWithDelimiter(tags: HTags, delimiter: string): string {
        if (tags == null || tags._Tags == null)
            return "";

        let result = "";
        tags._Tags.forEach(s => {
            result += s + delimiter;
        });
        return result;
    }

    /***
     * Transforma a l'string a HTags separant l'string per espais.
     */
    static convertToHTags(tags: string): HTags {
        if (tags == null || tags == "")
            return new HTags();

        let atags = tags.split(HTags.Separator);

        let result = new HTags();
        atags.forEach(s => {
            result.add(s);
        });
        return result;
    }

    public add(tag: string): boolean {
        if (HString.isNullOrNullString(tag))
            return false;
        if (this._Tags == null)
            this._Tags = new Array<string>();
        for (const ht of this._Tags) {
            if (ht === tag) return false;
        }
        this._Tags.push(tag);
        return true;
    }

    public addAll(tags: HTags): boolean {
        tags = HTags.buildFromDto(tags);
        if (HTags.isEmpty(tags))
            return false;
        if (this._Tags == null)
            this._Tags = new Array<string>();

        this._Tags = this._Tags.concat(tags._Tags)

        return true;
    }
    static getHTagsString(tags: HTags): string {
        return HTags.convertToStringWithDelimiter(tags, HTags.Separator);
    }

    public remove(tag: string): boolean {
        if (HString.isNullOrNullString(tag))
            return false;
        if (this._Tags == null)
            this._Tags = new Array<string>();

        const index = this._Tags.indexOf(tag);
        if (index >= 0) {
            this._Tags.splice(index, 1);
            return true;
        }
        return false;
    }

    static isEmpty(tags: HTags): boolean {
        if (tags == null || tags._Tags == null || tags._Tags.length == 0)
            return true;
        return false;
    }

    static fromString(val: string): HTags {
        let result: HTags = new HTags();

        if (typeof val === "object")
            val = "";

        if (!val)
            return result;

        val.split(HTags.Separator).forEach(item => {
            result.add(item);
        })

        return result;
    }

    public isEmpty(): boolean {
        return !this._Tags || this._Tags.length == 0;
    }

    public static buildFromDto(item: HTags): HTags {
        if (!item)
            return null;

        return HTags.fromString(HTags.convertToString(item));
    }

    public static equals(a: HTags, b: HTags): boolean {
        a = HTags.buildFromDto(a);
        b = HTags.buildFromDto(b);

        if ((!a || !a._Tags) && (!b || !b._Tags))
            return true;

        if ((!a || !a._Tags) && (b && b._Tags))
            return false;

        if ((a && a._Tags) && (!b || !b._Tags))
            return false;

        if (a._Tags.length != b._Tags.length)
            return false;

        a._Tags.forEach(item => {
            if (!b._Tags.includes(item))
                return false;
        });

        return true;
    }

    public static contains(tags: HTags, tag: string): boolean {
        if (tags == null || tags._Tags == null || tags._Tags.length == 0 || tag == null || tag === "")
            return false;

        for (let i = 0; i < tags._Tags.length; i++) {
            if (tags._Tags[i] === tag)
                return true;
        }

        return false;
    }



}
