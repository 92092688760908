import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn, FormControl } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HomeController } from '../../../../controllers/home/home.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { HColor } from '@shared/src/datatypes/HColor';
import { HString } from '@shared/src/datatypes/HString';
import { TagsValidator } from '../../form/validations/TagsValidator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tags-chips',
  templateUrl: './tags.chips.component.html',
  styleUrls: ['./tags.chips.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TagsChipsComponent, multi: true }]
})

/***
 * Encapsula una llista de tags editable.
 */
export class TagsChipsComponent extends ValueAccessorValidatedBase<string, HTags> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormTags = this.fb.group({
      tagsvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return TagsValidator.isValid(value);
  }

  rFormTags: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController,
    private translateService: TranslateService
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();

  }

  public placeholderNext: string;

  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Input() public helpresource: string;
  @Input() public showHelpPoint: boolean;
  @Output() valueChanged: EventEmitter<HTags> = new EventEmitter();

  public identifier = 'tags-chips-' + identifier++;

  public tagsString: Array<string> = new Array<string>();

  externalValue(value: string): HTags {
    if (value === null || value === undefined)
      return new HTags();

    let htags = HTags.convertToHTags(value);
    this.tagsString = HTags.getTags(htags);
    this.placeholderNext = this.translateService.instant("TAGSCHIPS.COMPONENT.NEWTAG");

    return htags;
  }
  internalValue(value: HTags): string {
    if (value === null || value === undefined)
      return "";

    this.tagsString = HTags.getTags(value);
    return HTags.convertToString(value);
  }

  public text: string;
  add(event) {

    if (!HString.isNullOrNullString(this.text)) {
      let allstring: string[] = new Array();
      //Separem pel separador dels HTags
      let tarray = this.text.split(HTags.Separator);
      tarray.forEach(element => {
        allstring.push(element);
      });

      let htags = this.externalValue(this.value);

      allstring.forEach(element => {
        htags.add(element);
      });

      this.value = this.internalValue(htags);
      this.text = "";
      this.valueChanged.next(htags);
    }
    event.preventDefault();
    event.stopPropagation();

    return false;
  }

  remove(tag: string) {
    if (!HString.isNullOrNullString(tag)) {
      let htags = this.externalValue(this.value);
      htags.remove(tag);
      this.value = this.internalValue(htags);
      this.valueChanged.next(htags);
    }
  }

  getTagColor(tag: string): string {
    return HColor.getWhiteBlackFromColor(this.getTagBackColor(tag));
  }
  getTagBackColor(tag: string): string {
    return HColor.getColorFromStringTable(tag, this.homecontroller);
  }

  onChange(e) {
    //this.rFormInput.markAsDirty();
    this.valueChanged.next(this.externalValue(this.value));
  }

}

let identifier = 0;

