import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PhaseEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PhaseEntitySelectorDto {
		let result: PhaseEntitySelectorDto = new PhaseEntitySelectorDto();
		result.loadData(EntitysSelectors.Phase, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		//No hi ha manteniment estricte, poden ser customer, driver i/o provider
		return "";
	}

	public getUrlBack(): string {
		return "/maintenance/phase";
	}

}