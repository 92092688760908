import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { HTime } from '../datatypes/HTime';

@Pipe({
	name: 'timedays'
})
@Injectable()
export class TimeDaysFilter implements PipeTransform {
	constructor() {

	}
	transform(value: HTime): string {
		if (value == null)
			return "-:--";
		let result: string;

		if(value.h > 23){
			let days = Math.trunc(value.h / 24);

			let hourstorest = days * 24;

			let resthours = value.h - hourstorest;

			result = days + "d " + this.pad(resthours, 2) + ":" + this.pad(value.m, 2);
		}else
			result = this.pad(value.h, 2) + ":" + this.pad(value.m, 2);
		return result;
	}

	pad(num, size): string {

		let snum = "" + num + "";

		if (snum != null && snum.length >= size)
			return snum;
		let s = "000000000" + snum;
		return s.substr(s.length - size);
	}
}