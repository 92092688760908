import { Component, Inject, Input, OnInit } from "@angular/core";
import { FileUpDownloadController } from "@shared/src/controllers/fileupdownload/fileupdownload.controller";
import { PackageController } from "@shared/src/controllers/route/delivery/package.controller";
import { ShipmentDeliveryController } from "@shared/src/controllers/route/delivery/shipmentdelivery.controller";
import { ESchenkerController } from "@shared/src/controllers/route/shipment/eschenker/eschenker.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { PackageDto } from "@shared/src/dtos/shipment/delivery/PackageDto";
import { HDate, RouteCardDto, ShipmentCardDto, ShipmentController } from "@shared/src/public-api";

@Component({
selector: 'shared-package-component',
    templateUrl: "./shared.package.component.html",
    styleUrls: ["./shared.package.component.scss"],
})
export class SharedPackageComponent implements OnInit {

    public readonly: boolean = false;

    constructor(
        public shipmentController: ShipmentController,
        public packageController: PackageController,
        public shipmentDeliveryController: ShipmentDeliveryController,
        public eschenkerController: ESchenkerController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) {
        this.securityController.getActualUserDto().subscribe(e => {
            this.readonly = e.isReadOnly;
        });
    }

    ngOnInit(): void { }

    private _date: HDate;

    private _route: RouteCardDto;
    @Input()
    public set route(v: RouteCardDto) {
        if (this._route == v)
            return;

        this._route = v;
        this.routeId = v.routeId;
        if (this._route.dateHourFrom)
            this._date = HDate.buildas(this._route.dateHourFrom.year, this._route.dateHourFrom.month, this._route.dateHourFrom.dayOfMonth)
        else
            this._date = HDate.today();
    }

    public get route(): RouteCardDto {
        return this._route;
    }

    private _routeId: number;

    @Input()
    public set routeId(v: number) {
        if (this._routeId === v)
            return;

        this._routeId = v;

        this.reloadData();
    }

    public get routeId(): number {
        return this._routeId;
    }


    private _shipment: ShipmentCardDto;
    @Input()
    public set shipment(v: ShipmentCardDto) {
        if (this._shipment == v)
            return;

        this._shipment = v;
        this.shipmentId = v.shipmentId;
        if (this._shipment.originExecutionDate)
            this._date = HDate.buildFromHDate(this._shipment.originExecutionDate);
        else if (this._shipment.originDateTimeStart)
            this._date = HDate.buildas(this._shipment.originDateTimeStart.year, this._shipment.originDateTimeStart.month, this._shipment.originDateTimeStart.dayOfMonth);
        else
            this._date = HDate.today();
    }

    public get shipment(): ShipmentCardDto {
        return this._shipment;
    }

    private _shipmentId: number;

    @Input()
    public set shipmentId(v: number) {
        if (this._shipmentId === v)
            return;

        this._shipmentId = v;

        this.reloadData();
    }

    public get shipmentId(): number {
        return this._shipmentId;
    }

    public get anyWithoutLabel(): boolean {
        if (!this.data || this.data.length == 0)
            return false;

        return this.data.filter(item => !item.hasLabel && item.labelCandidate).length > 0;
    }

    public askingCreateLabel: boolean = false;
    public createLabel() {
        if (!this.anyWithoutLabel)
            return;

        let ids: Array<number> = this.data.filter(item => !item.hasLabel && item.labelCandidate).map(item => item.packageId);
        this.eschenkerController.createLabelByPackages(ids).subscribe(e => {
            if (e) {
                this.reloadData();
            }
        }, error => {
            this.askingCreateLabel = false;
            this.reloadData();
        })
    }

    public reloadData() {
        this.data = [];

        if (this._routeId)
            this.packageController.getAllByRoute(this._routeId).subscribe(e => {
                this.data = e;
            })
        else if (this._shipmentId)
            this.packageController.getAllByShipment(this._shipmentId).subscribe(e => {
                this.data = e;
            })
    }

    public data: Array<PackageDto>;

    pretty(item: any): string {
        return JSON.stringify(item);
    }

    public downloadLabelsByDate() {
        this.eschenkerController.downloadLabelByDate(this._date).subscribe(e => {
            if (e && e.fileBytes)
                FileUpDownloadController.resultDownLoad(e);
        });
    }
}
