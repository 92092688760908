import { ResponseMessage } from './ResponseMessage';
import { MessageCodes } from '../enums/MessageCodes';
import { ResponseMessages } from './ResponseMessages';

export class ResponseData<T> {

    public transactionId: string;
    public transactionNum: string;

    public data: T;
    public responseMessages: ResponseMessages;

    public isException: boolean;
    public exceptionMessage: string;

    public static exist401(responseMessages: ResponseMessages): boolean {
        let exists = false;
        if(responseMessages != null && responseMessages.messages != null){
            responseMessages.messages.forEach(element => {
                if(element.messageCode == MessageCodes.HTTP401){
                    exists = true;
                }
            });
        }
        return exists;
    }

}