import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerCarrierMaintenanceController extends
    PartnerMaintenanceController {

    public getResourceRoot(): string {
        return "PARTNER.CARRIER";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/carrier" + uri;
    }

    public getPermission() {
        return ["PARTNER.CARRIER.LINKPERMISSION.FILTER"];
    }

}
