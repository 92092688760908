export class GlobalErrorHandlerUtils {
    public static getErrorMessage(error: any): string {
        if (!error || !error.message)
            return;

        let message: string = error.message;
        if (error.error && error.error.message)
            message = error.error.message;

        if (error.error) {
            let url: string = this.getURL(error.error.currentTarget);
            if (!url)
                url = this.getURL(error.error.srcElement);
            if (!url)
                url = this.getURL(error.error.target);
            if (url)
                message += "; URL: " + url;
        }


        return message;
    }

    private static getURL(xmlHttpRequest: any): string {
        if (!xmlHttpRequest || !xmlHttpRequest.__zone_symbol__xhrURL)
            return undefined;

        return xmlHttpRequest.__zone_symbol__xhrURL
    }
}