import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { MaintenanceSearchByTextDto } from '../maintenance/_base/MaintenanceSearchByTextDtos';
import { RouteCardItemListDto } from '@shared/src/dtos/route/RouteCardItemListDto';
import { RouteDocumentaryReviewObjectDto } from '@shared/src/dtos/documentaryreview/RouteDocumentaryReviewObjectDto';
import { RouteShipmentDocumentaryReviewObjectDto } from '@shared/src/dtos/documentaryreview/RouteShipmentDocumentaryReviewObjectDto';


@Injectable({
    providedIn: 'root',
})
export class DocumentaryReviewController extends BaseController {

    private base = '/documentaryreview'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public activeroutes(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeroutes", maintenanceSearchByText, "managementmodel.activeroutes");
        return this.post(this.base + "/activeroutes", maintenanceSearchByText, "managementmodel.activeroutes");
    }

    public activeroutesExactKey(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeroutes/exactkey", maintenanceSearchByText, "managementmodel.activeroutes.exactkey");
        return this.post(this.base + "/activeroutes/exactkey", maintenanceSearchByText, "managementmodel.activeroutes.exactkey");
    }

    public getRouteDocumentaryReview(routeId: number): Observable<RouteDocumentaryReviewObjectDto> {
        return this.get(this.base + "/documentaryreview/routeid/" + routeId);
    }

    public getRouteShipmentDocumentaryReview(routeId: number, shipmentId: number): Observable<RouteShipmentDocumentaryReviewObjectDto> {
        return this.get(this.base + "/documentaryreview/routeid/" + routeId + "/shipmentid/" + shipmentId);
    }
}
