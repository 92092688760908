import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormListMaintenanceDto } from './FormListMaintenanceDto';
import { FormItemMaintenanceDto } from './FormItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import {AlertService} from '@shared/src/services/alert/alert.service';
import {LoadingService} from '@shared/src/services/loading/loading.service';

@Injectable()
export class FormMaintenanceController extends BaseMaintenanceController<FormListMaintenanceDto, FormItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "form");
    }


    public getResourceRoot(): string {
        return "FORM";
    }

    public buildUrl(uri: string): string {
        return "/system/screensandforms/definitionform" + uri;
    }
}
