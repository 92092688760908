import { DataTypes } from "@shared/src/enums/DataTypes";

export class CustomTableFieldData {
    public order: number;
    public column: number;
    public row: number;
    public dataType: DataTypes;
    public value: string;

}
