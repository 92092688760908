import { ActionDto } from './actionDto';

/***
 * Representa un conjunt d'accions.
 */
export class ActionsStripDto {
    private map: Map<string, ActionDto>; // la llista de opcions
    private mapAsMenu: Map<string, ActionDto>; // la llista de opcions
    private mapAsBar: Map<string, ActionDto>; // la llista de opcions

    public listAsMenu: ActionDto[];
    public listAsBar: ActionDto[];

    constructor() {
        this.map = new Map<string, ActionDto>();
    }

    /***
     * Per regenerar l'objecte després de serializtzar
     */
    public static buildFromActionsStripDto(value: ActionsStripDto): ActionsStripDto {
        let result = new ActionsStripDto();
        if (value == null || value.map == null)
            return result;
        Object.keys(value.map).forEach(k => {
            result.add(ActionDto.buildFromActionDto(value.map[k]));
        });
        return result;
    }



    /***
     * Donaes unes noves accions afegeix o elimines les velles per a que siguin iguals.
     */
    public static sync(actualStripActions: ActionsStripDto, newStripActions: ActionsStripDto): ActionsStripDto {
        //Cas simple total
        if ((actualStripActions == null || actualStripActions.map == null) && (newStripActions == null || newStripActions.map == null))
            return new ActionsStripDto();

        if (actualStripActions != null && actualStripActions.map != null) {
            //Nosaltres estem plens pero el new està buit
            if (newStripActions == null || newStripActions.map == null)
                return new ActionsStripDto();

            //Eliminem les que ja no estan a la nova llista
            actualStripActions.map.forEach((v, k) => {
                if (!newStripActions.map.has(k))
                    actualStripActions.removeByKey(k);
            });
        }
        let result = actualStripActions;
        if (actualStripActions == null || actualStripActions.map == null)
            result = new ActionsStripDto;

        if (newStripActions != null && newStripActions.map != null) {
            //Afegim les noves
            Object.keys(newStripActions.map).forEach(k => {
                if (!result.map.has(k))
                    result.add(newStripActions.map[k]);
            });
        }
        return result;
    }

    public static contains(actions: ActionsStripDto, actionId: string): boolean {
        if (actions == null || actions.map == null || actions.map.size == 0)
            return false;
        return actions.map.has(actionId);
    }

    /**
     * Afegeix la opció a l'strip. Si duplicada no fa res, retorna false.
     * @param actionDto 
     */
    public add(value: ActionDto): boolean {
        if (this.map.has(value.actionId))
            return false;
        this.map.set(value.actionId, value);
        this.setLists();
        return true;
    }

    public containsKey(actionId: string): boolean {
        return this.map.has(actionId);
    }
    public get(actionId: string): ActionDto {
        return this.map.get(actionId);
    }
    /**
     * Elimina la opció de l'strip. Si no hi ha no fa res, retorna false;
     * @param actionDto 
     */
    public remove(value: ActionDto): boolean {
        return this.removeByKey(value.actionId);
    }
    /***
     * Elimina la opció per Key
     */
    public removeByKey(key: string): boolean {
        if (!this.map.has(key))
            return false;
        this.map.delete(key);
        this.setLists();
        return true;
    }

    private setLists() {
        this.mapAsBar = new Map<string, ActionDto>();
        this.mapAsMenu = new Map<string, ActionDto>();

        this.map.forEach((value: ActionDto, key: string) => {
            if (ActionDto.getActionShowInBar(value))
                this.mapAsBar.set(key, value);
            if (ActionDto.getActionShowInMenu(value))
                this.mapAsMenu.set(key, value);
        });


        this.listAsBar = new Array(this.mapAsBar.size);
        let i = 0;
        this.mapAsBar.forEach((value: ActionDto, key: string) => {
            this.listAsBar[i] = value;
            i++;
        });
        this.listAsMenu = new Array(this.mapAsMenu.size);
        i = 0;
        this.mapAsMenu.forEach((value: ActionDto, key: string) => {
            this.listAsMenu[i] = value;
            i++;
        });
    }

    public clear() {
        this.map.clear();
        this.setLists();
    }

    static buildDefaultActionsForReports(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 0, "").addClass("action"));
        result.add(ActionDto.build("DOWNLOAD", "GENERAL.ACTIONS.DOWNLOAD.CAPTION", "GENERAL.ACTIONS.DOWNLOAD.HELP", "fa-cloud-download", 20, 0, 0, ""));
        return result;
    }
    static buildDefaultActionsForReportsExcel(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 0, "").addClass("action"));
        result.add(ActionDto.build("GETEXCEL", "GATE.LOG.GETEXCEL", "", "fa-file-excel-o", 20, 0, -1, ""));
        return result;
    }
    static buildDefaultActionsForReportsPdfExcel(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 0, "").addClass("action"));
        result.add(ActionDto.build("GETEXCEL", "GATE.BOOK.GETEXCEL", "", "fa-file-excel-o", 20, 0, -1, ""));
        result.add(ActionDto.build("GETPDF", "GATE.BOOK.GETPDF", "", "fa-file-pdf-o", 30, 0, -1, ""));
        return result;
    }
    static buildOnlyGetDataActionsForReports(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 0, "").addClass("action"));
        return result;
    }
    static buildDefaultActionsForGetData(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 0, "").addClass("action"));
        return result;
    }

    static buildDefaultActionsForGetDataOnlyButton(): ActionsStripDto {
        let result = new ActionsStripDto();
        result.add(ActionDto.build("GETDATA", "GENERAL.ACTIONS.GETDATA.CAPTION", "GENERAL.ACTIONS.GETDATA.HELP", "fa-bolt", 10, 0, 1, "").addClass("action"));
        return result;
    }

}