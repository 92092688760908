<div *ngIf="value" class="container component" [ngClass]="{'edit' : selectedRoute != null && selectedRoute.isGrouped}">
    <div class="searchroutes" *ngIf="!preFinishActive">
        <div class="line">
            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText"
                (ngModelChange)="this.searchTextUpdate.next($event)">
            <div class="block-help">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                {{'SHARED.ROUTEGROUP.COMPONENT.HELP'| translate}}
            </div>
        </div>
        <div class="line llista">
            <shared-route-item-list
                *ngFor="let item of getSearchRoutes() | filterActiveRoutes:searchText:filterMetadata | orderbyroutecarddto:true; let i=index"
                class="routeItem"
                [ngClass]="{'selected':routeToAdd!=null && item.routeId==routeToAdd.routeId, 'divided-childs': item.isDivided && item.childsCount > 0}"
                [route]="item" [isActiveRoutes]="'true'" (click)="routeToAdd=item">
            </shared-route-item-list>
        </div>
        <div class="block-buttons">
            <button *ngIf="!(iReadonly || routeToAdd == null)" class="add"
                (click)="onRouteAdd()">{{'ROUTEGROUP.OPTION.ROUTEGROUP.ADD' | translate}}</button>
        </div>
    </div>

    <div class="actualroutes">
        <div class="subtitle">{{ getActualRoutesResource() | translate}}</div>
        <div class="line llista">
            <div class="overflow">
                <div class="grouped-route-item" *ngFor="let item of getActualRoutes(); let i=index"
                    [ngClass]="{'divided-childs': item.isDivided && item.childsCount > 0}">
                    <div class="info"><div class="key">{{item.routeKey}}</div><div class="description">{{item.line1}}</div></div>
                    <i *ngIf="!preFinishActive && getCountActualRoutes() > 0 && item.routeId != routeId"
                        class="fa fa-times-circle" aria-hidden="true" (click)="onRouteRemove(item)"></i>
                </div>
            </div>
        </div>
        <div *ngIf="preFinishActive && getCountActualRoutes() == 0 && value != null && value.routeGroup != null"
            class="warning">{{ 'ROUTEGROUP.WARNING.CANCELED' | translate }}</div>
        <div class="block-buttons">
            <button *ngIf="preFinishActive" class="cancel" [disabled]="iReadonly"
                (click)="onCancelRouteGrouped()">{{ 'ROUTEGROUP.OPTION.ROUTEGROUP.CANCEL' | translate}}</button>
            <button *ngIf="!(iReadonly || !canOnRouteGrouped())" class="add"
                (click)="onRouteGrouped()">{{ getActionButtonToGroupResource() | translate}}</button>
        </div>
    </div>
</div>