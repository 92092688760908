import { Component, OnInit, Input } from '@angular/core';
import { InvoiceDto } from '@shared/src/controllers/billing/InvoiceDto';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { DiscountTypes } from '@shared/src/enums/DiscountTypes';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';

@Component({
  selector: 'shared-invoice-component',
  templateUrl: './shared.invoice.component.html',
  styleUrls: ['./shared.invoice.component.scss']
})
export class SharedInvoiceComponent implements OnInit {

  constructor() {
  }

  ngOnInit() { }

  private _invoice: InvoiceDto;
  @Input() set invoice(value: InvoiceDto) {
    if (this._invoice === value)
      return;
    this._invoice = value;
  }
  get invoice(): InvoiceDto {
    return this._invoice;
  }

  buildProductCaption(product) {
    return 'PRODUCTS.' + product.toString().toUpperCase() + '.TITLE';
  }
  buildDiscountCaption(discount) {
    return 'DISCOUNTS.' + discount.toString().toUpperCase() + '.TITLE';
  }
  buildProductHelp(product) {
    return 'PRODUCTS.' + product.toString().toUpperCase() + '.INVOICEHELP';
  }
  buildDiscountHelp(discount) {
    return 'DISCOUNTS.' + discount.toString().toUpperCase() + '.INVOICEHELP';
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }
}
