<ng-container *ngIf="phase">
    <div class="line">
        <div class="requadre load" *ngIf="phase.isLoad">{{'PHASE.ISLOAD'| translate}}</div>
        <div class="requadre unload" *ngIf="phase.isUnload">{{'PHASE.ISUNLOAD'| translate}}</div>
        <div class="requadre unloadload" *ngIf="phase.isUnloadLoad">{{'PHASE.ISUNLOADLOAD'| translate}}</div>
    </div>
    <div class="line  sangrat">
        <shared-tags-strip-component class="tags" [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
        {{phase.formattedAddress}}
    </div>
    <shared-tags-strip-component class="line tags sangrat" [tags]="phase.userTags"
        [applyColor]="false"></shared-tags-strip-component>

    <div class="line goods sangrat">
        {{phase.goods}}
    </div>
</ng-container>