<div class="info-partner" [ngClass]="type">
    <div class="agent"><i class="fa fa-user-circle-o" aria-hidden="true"></i>
        <div class="nom-agent">{{ caption | translate}}</div>
    </div>
    <div class="partDreta">
        <div *ngIf="partner" class="info-data">
            <div class="title">{{ partner.title }}</div>
            <div class="subtitle">{{ partner.subTitle }}</div>
            <div class="email"><a href="mailto:{{partner.email}}">{{ partner.email }}</a></div>
        </div>
        <div *ngIf="partner && partner.phone" class="phone"><a href="tel:{{partner.phone}}"><i
                    class="fa fa-phone-square" aria-hidden="true"></i></a>
            <div class="phone-number"><span class="parterPhone">{{partner.phone}}</span>
                <shared-copyclipboard [value]="partner.phone"></shared-copyclipboard>
            </div>
        </div>
    </div>
</div>