import { Injectable } from '@angular/core';
import { BaseMaintenanceDto } from '@shared/src/controllers/maintenance/BaseMaintenanceDto';
import { HColor } from '../../../datatypes/HColor';
import { SkinDto } from './SkinDto';

@Injectable()
export class AvatarDto extends SkinDto {
    public backgroundColor: HColor;
    public foreColor: HColor;

    public static build(code: string, forecolor: HColor, backgroundcolor: HColor): AvatarDto {
        let avatar = new AvatarDto();
        avatar.skin = code;
        avatar.foreColor = forecolor;
        avatar.backgroundColor = backgroundcolor;
        return avatar;
    }

    public empty(): AvatarDto {
        this.backgroundColor = HColor.build("#f5f5f0");
        this.foreColor = HColor.build("#000000");
        super.empty();
        return this;
    }

    public copyFrom(value: AvatarDto) {
        this.backgroundColor = value.backgroundColor;
        this.foreColor = value.foreColor;
        super.copyFrom(value);
    }

    public randomBColor(): AvatarDto {
        this.backgroundColor = HColor.getRandomDark();
        this.foreColor = HColor.build(HColor.getWhiteBlackFromColor(this.backgroundColor.web));
        super.defaultSkin();
        return this;
    }

    public static fillDark(value: AvatarDto) {
        value.foreColor = HColor.WHITE;
        value.backgroundColor = HColor.getRandomDark();
        super.fillDark(value);
    }
}