
<div *ngIf="value" class="ecmr-list" [ngClass]="{'ionic' : useIonic}">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="item-title">
                    <div class="top-left">
                        {{ 'DOCUMENTARYREVIEW.ECMR.CMRS' | translate}} <span class="numdocuments">{{ 'DOCUMENTREVIEW.NUMDOCUMENTS' | translate:{value: numDocuments} }}</span>
                    </div>  
                    <div class="top-center">
                        
                    </div>
                    <div class="top-right">
                        
                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <div class="block-data" *ngIf="value != null && value.cmrs != null">
                <shared-documentaryreview-item-ecmr-component *ngFor="let item of value.cmrs | sortBy:'originFastConditionFirstMoment'; let i = index" class="ecmr-item" [value]="item" [readonly]="readonly">
                </shared-documentaryreview-item-ecmr-component >
            </div>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</div>