<div class="container" [ngClass]="generalClass" [ngClass]="marcador.type">
    <div class="key">{{marcador.label}}
        <div class="icones" *ngIf="marcador.type != 'phase'">
            <span *ngIf="marcador.isLost"> <i class="fa fa-eye-slash elem value" aria-hidden="true"></i></span>
            <span *ngIf="!marcador.isLost"> <i class="fa fa-eye elem value" aria-hidden="true"></i></span>

            <div *ngIf="marcador.type == 'plate'" [title]="'PLATE.TITLE' | translate" class="elem value"><i
                    class="elem value fa fa-truck" aria-hidden="true"></i></div>
            <div *ngIf="marcador.type == 'route'" [title]="'ROUTE.TITLE' | translate" class="elem value"><i
                    class="elem value fa fa-road" aria-hidden="true"></i></div>
            <div *ngIf="marcador.type == 'shipment'" [title]="'SHIPMENT.TITLE' | translate" class="iconlabel"><i
                    class="fa fa-paper-plane" aria-hidden="true"></i></div>
            <div *ngIf="marcador.type == 'device'" [title]="'DEVICE.TITLE' | translate" class="iconlabel"><i
                    class="fa fa-mobile" aria-hidden="true"></i></div>
            <div *ngIf="marcador.type == 'user'" [title]="'USER.TITLE' | translate" class="iconlabel"><i
                    class="fa fa-user-circle-o" aria-hidden="true"></i></div>
        </div>

    </div>
    <div *ngIf="marcador.type == 'route'" class="grup description">
        <table>
            <tr>
                <td class="title elem value">{{marcador.routeLocalizableObject.key}}</td>
                <td class="matricula elem value"> {{marcador.routeLocalizableObject.plateA}}</td>
            </tr>
            <tr>
                <td class="elem value"> {{marcador.routeLocalizableObject.dateString }}</td>
            </tr>
            <tr>
                <td class="elem value">
                    <shared-tags-strip-component [tags]="marcador.routeLocalizableObject.routeTags">
                    </shared-tags-strip-component>
                </td>
            </tr>
        </table>

        <div class="phases">
            <div class="phaseInfo" *ngFor="let phase of marcador.routeLocalizableObject.phaseList">
                <div class="detall item" *ngIf="isUnLoad(phase)">
                    <img class="icona" src="/assets/images/unloadCargo.png" alt="Unload cargo">

                </div>
                <div class="detall item" *ngIf="isLoad(phase)">
                    <img class="icona" src="/assets/images/loadCargo.png" alt="Load cargo">
                </div>

                <div class="info">
                    <div class="hores">
                        <div class="eta">
                            <span class="prompt">{{'MAP.MARKER.ETA' | translate}}</span>
                            <span *ngIf="isTodayEta(phase.ETA)" [ngClass]="colorEta(phase)" class="item">{{hourTodayEta
                                | hour
                                }}</span>
                            <span *ngIf="!isTodayEta(phase.ETA)" [ngClass]="colorEta(phase)" class="item">{{phase.ETA |
                                datehournotz}}</span>
                        </div>
                        <div>
                            <span *ngIf="isTodayCommitent(phase.commitentEnd)" class="item">{{hourTodayCommitent | hour
                                }}</span>
                            <span *ngIf="!isTodayCommitent(phase.commitentEnd)" class="item">{{phase.commitentEnd |
                                datehournotz }}</span>
                        </div>
                    </div>

                    <div class="mercaderia">
                        <span class="item">{{phase.goods}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="marcador.type == 'plate' && marcador.plateLocalizableObject" class="plate mincontent description">
        <shared-journey-detail-component class="briefly mincontent"
            [plateJourneyId]="marcador.plateLocalizableObject.plateJourneyId">
        </shared-journey-detail-component>
    </div>

    <div *ngIf="marcador.type != 'route' && marcador.type != 'plate' && marcador.localizableObject"
        class="grup description">
        <table>
            <tr>
                <td class="elem type"> {{'MAP.MARKER.DESCRIPTION' | translate}} </td>
                <td class="elem value"> {{marcador.localizableObject.description}} </td>
            </tr>
            <tr>
                <td class="elem type"> {{'MAP.MARKER.MOMENT' | translate}} </td>
                <td class="elem value"> {{ marcador.localizableObject.globalTelemetry.moment | datehour }} </td>
            </tr>
        </table>
    </div>
    <div *ngIf="marcador.type == 'phase' && marcador.phase" class="grup description">
        <table>
            <tr class="flex">
                <div class="detall item" *ngIf="marcador.phase.isUnload">
                    <img class="icona" src="/assets/images/unloadCargo.png" alt="Unload cargo">

                </div>
                <div class="detall item" *ngIf="marcador.phase.isLoad">
                    <img class="icona" src="/assets/images/loadCargo.png" alt="Load cargo">
                </div>
                <td class="elem value ml-5">
                    {{ marcador.phase.executionDate | datadetall }}, {{ marcador.phase.disponibilityMessage}}
                </td>
            </tr>
            <tr>
                <td class="elem value">
                    <span class="pre-tags-strip">@</span>
                    <shared-tags-strip-component class="tags marcador" [uniqueString]="marcador.phase.addressSkin">
                    </shared-tags-strip-component>
                    <span class="ml-5">
                        {{ marcador.phase.address }}
                    </span>
                </td>
            </tr>
            <tr>
                <td class="elem value">
                    <span class="pre-tags-strip">@</span>
                    <shared-tags-strip-component class="tags no-margin-between marcador" [tagsString]="tags">
                    </shared-tags-strip-component>
                </td>
            </tr>
            <tr>
                <td class="elem value">
                    <span>{{marcador.phase.goods}}</span>
                </td>
            </tr>
        </table>
    </div>
</div>