<!--directiva: desktop.alert.component.html!-->
<div class="alert-float" [ngClass]="{'visible': isVisible()}">
	<div class="alertzone">
		<div *ngFor="let alert of alertService.alerts" class="alertitem alert-dismissable fade show"
			[ngClass]="getClass(alert)">
			<span
				style="width:calc(100% - 50px); float: left;padding-left: 20px; padding-top: 7px; padding-bottom: 10px;" [innerHTML]="alert.message"></span>
				<i class="fa fa-times" aria-hidden="true" (click)="removeAlert(alert)" style="margin-right: 10px;margin-top: 11px;"></i>
		</div>
	</div>
</div>
<div class="alert-fixed" *ngIf="fullShow">
	<div class="header">
		<i class="tanca fa fa-times" aria-hidden="true" (click)="closeWindow()"></i>
		<div class="clear clickable" (click)="clearAlerts()">{{'ALERT.CLEAR' | translate}}</div>
	</div>
	<div class="alertzone">
		<div *ngFor="let alert of alertService.alerts" class="alertitem alert-dismissable fade show"
			[ngClass]="getClass(alert)">
			<span
				style="width:calc(100% - 50px); float: left;padding-left: 20px; padding-top: 2px; padding-bottom: 10px;" [innerHTML]="alert.message"></span>
				<i class="fa fa-times" aria-hidden="true" (click)="removeAlert(alert)" style="margin-right: 10px;margin-top: 11px;"></i>
		</div>
	</div>
</div>