import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaintenanceSettingsItemDTO } from '@shared/src/controllers/maintenance/_base/MaintenanceSettingsItemDTO';
import { PartnerItemMaintenanceDto } from '@shared/src/controllers/maintenance/partner/PartnerItemMaintenanceDto';
import { AddressManualAddressDto } from '@shared/src/controllers/maps/AddressManualAddressDto';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { TenantInfoController } from '@shared/src/controllers/tenant/tenantinfo.controller';
import { Route_eCMRAddressDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRAddressDto';
import { Route_eCMRPartnerDTO } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPartnerDto';
import { TenantInfoDto } from '@shared/src/dtos/tenants/TenantInfoDto';
import { PartnerMaintenanceController, SharedSelectorAddressManualAddressEvent } from '@shared/src/public-api';


@Component({
  selector: 'updateaddressdialog-dialog',
  templateUrl: './updateaddressdialog.dialog.component.html',
  styleUrls: ['../shared.updateaddressdialog.component.scss']
})
export class UpdateAddressDialogDialogComponent {

  @Output() public onResolve: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<UpdateAddressDialogDialogComponent>,
    private mdDialog: MatDialog,
    private ecmrController: RouteCMRController) {
  }


  onClose() {
    this.onResolve.next(false);
    this.mdDialog.closeAll();
  }

  public address: AddressManualAddressDto = new AddressManualAddressDto();
  onChangedAddress(event: SharedSelectorAddressManualAddressEvent) {
    console.log("event", event);
    if (event.address && event.address.formattedAddress) {
      this.data.addressId = event.address.addressId;
      this.data.formattedAddress = event.address.formattedAddress;
      this.data.name = event.address.descriptionShort;
      this.data.key = event.address.addressKey;
      this.data.formattedAddress = event.address.formattedAddress;
      this.data.contactPhone = event.address.contactPhone;
    } else if (event.addressManualAddress && event.addressManualAddress.manualAddress && event.addressManualAddress.manualAddress.formattedAddress) {
      this.data.formattedAddress = event.addressManualAddress.manualAddress.formattedAddress;
    } else {
      this.data.addressId = null;
      this.data.formattedAddress = null;
      this.data.name = null;
      this.data.key = null;
      this.data.formattedAddress = null;
    }
  }

  onConfirm() {
    let result: Route_eCMRAddressDTO = Route_eCMRAddressDTO.build(this.data.addressId, this.data.key, this.data.name, this.data.formattedAddress, this.data.contactPhone);

    if (this.data.isOrigin as boolean) {
      this.ecmrController.updateOriginAddress(this.data.routePartCMRId, result).subscribe(e => {
        if (e) {
          this.onResolve.next(true);
          this.mdDialog.closeAll();
        }
      });
    } else if (this.data.isDestination as boolean) {
      this.ecmrController.updateDestinationAddress(this.data.routePartCMRId, result).subscribe(e => {
        if (e) {
          this.onResolve.next(true);
          this.mdDialog.closeAll();
        }
      });
    }


  }
}