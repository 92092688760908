export enum PartnerCategories {
    Carrier = "Carrier",
    FreightForwarder = "FreightForwarder",
    Shipper = "Shipper",
    Driver = "Driver",
    SecondDriver = "SecondDriver",
    None = "None",
    LogisticOperator = "LogisticOperator",
    FinalCustomer="FinalCustomer",
    Provider="Provider",
    Inspector="Inspector",
    HealthCertifier="HealthCertifier",
    ClientPreferent="ClientPreferent"
}
