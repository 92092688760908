<div class="header-log">
    <div class="title">{{ 'PANEL.ELEMENT.LOG.TITLE' | translate }}</div>
</div>
<div *ngIf="logs" class="container-table grup" [ngClass]="{'ionic' : useIonic}">
    <div class="contingut">
        <table class="hardman" style="width:100%">
            <tr class="dark">
                <th class="header">{{'PANEL.ELEMENT.LOG.USER' | translate}}</th>
                <th class="header">{{'PANEL.ELEMENT.LOG.MOMENT' | translate}}</th>
                <th class="header">{{'PANEL.ELEMENT.LOG.COLUMN' | translate}}</th>
            </tr>
            <tr *ngFor="let item of logs | sortBy:'-moment'">
                <td class="">{{item.userName}}</td>
                <td class="">
                    <datehour-viewer [value]="item.moment" [showOriginal]="false" [showTimeZone]="false">
                    </datehour-viewer>
                </td>
                <td class="">{{item.panelColumnName}}</td>
            </tr>
        </table>
    </div>
</div>
