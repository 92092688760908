
export class HTime {
    s: string = "";
    h: number = 0;
    m: number = 0;

    /**
     * Indica una quantitat d'hores, poden ser negatives o positives
     */

    constructor(sign: string, hours: number, minutes: number) {
        this.s = sign;

        if (minutes >= 60) {
            hours = hours + Math.floor(minutes / 60);
            minutes = minutes % 60;
        } else if (minutes < 0) {
            minutes = minutes * -1;
            let r = Math.max(Math.floor(minutes / 60), 1);
            hours = hours - r;
            minutes = minutes % 60;
        }
        this.h = hours;
        this.m = minutes;
    }

    public static NULLVALUE() {
        return new HTime("", 0, 0);
    }

    public getSign(): string {
        return this.s;
    }

    public getHours(): number {
        return this.h;
    }
    public getMinutes(): number {
        return this.m;
    }

    public static empty(): HTime {
        let htime = new HTime("", 0, 0);
        return htime;
    }

    public static clone(value: HTime): HTime {
        if (HTime.isNullOrNullValue(value))
            return this.NULLVALUE();
        return new HTime(value.s, value.h, value.m);
    }

    public static buildByHoursAndMinutes(hours: number, minutes: number): HTime {
        return new HTime("", hours, minutes);
    }
    public static build(value: any): HTime {
        var sign: string = "";
        var hour: number = 0;
        var minute: number = 0;
        if (typeof value === 'string' || value instanceof String) {
            if (value == null || value == undefined || value == '')
                return HTime.empty();

            if (value.indexOf('-') > -1) {
                sign = "-";
                value = value.substring(1, value.length);
            }

            if (value.indexOf('+') > -1) {
                sign = "";
                value = value.substring(1, value.length);
            }

            if (value.indexOf(':')) {
                var time: string[] = value.split(':');
                hour = Number(time[0]);
                minute = Number(time[1]);
            } else {
                minute = Number(value.substring(value.length - 2, value.length));
                hour = Number(value.substring(0, value.length - 3));
            }

        } if (typeof value === 'number' || value instanceof Number) {
            if (value == null || value == undefined || value == 0)
                return HTime.empty();

            if ((value as number) < 0) {
                sign = "-";
                value = Math.abs((value as number));
            }

            hour = Math.floor(value as number); // Part sencera
            minute = Math.round(((value as number) - hour) * 60); // Convertim la part decimal a minuts

        } else if (typeof value === 'object' || value instanceof Date) {
            if (value != null) {
                if (value.s != undefined)
                    sign = value.s;
                if (value.h != undefined)
                    hour = value.h;
                if (value.m != undefined)
                    minute = value.m;
                if (value.getSign != undefined)
                    sign = value.getSign();
                if (value.getHours != undefined)
                    hour = value.getHours();
                if (value.getMinutes != undefined)
                    minute = value.getMinutes();
            }
        }
        let htime = new HTime(sign, hour, minute);
        return htime;
    }

    public static buildbytime(value: HTime): HTime {
        if (this.isNullOrNullValue(value))
            return this.empty();
        let result: HTime = this.empty();
        if (value.s != null)
            result.s = value.s;
        result.h = value.h;
        result.m = value.m;
        return result;
    }

    public static isValidFormat(value: String): boolean {
        if (!value)
            return false;

        let time: string[] = value.split(":");
        if (!time || time.length != 2)
            return false;

        let sign = "";
        let hours: string = time[0];
        let minutes: string = time[1];

        if (hours == '--' || hours == '---' || hours == '+--')
            hours = '00';
        if (minutes == '--')
            minutes = '00';

        if (hours.substring(0, 1) == '+' || hours.substring(0, 1) == '-') {
            sign = "-";
            hours = hours.substring(1, hours.length);
        }

        //els minuts només permetem 2 números
        if (!minutes || minutes.length != 2)
            return false;

        //les hores com a màxim son 2 números
        if (!hours || hours.length > 5 || hours.length <= 0)
            return false;

        let iminutes: number = Number(minutes);
        if (isNaN(iminutes) || iminutes > 59)
            return false;

        let ihours: number = Number(hours);
        if (isNaN(ihours))
            return false;

        return true;
    }

    public static getTimeString(value: HTime): string {
        if (value == null)
            value = HTime.empty();

        if (value.s == null)
            value.s = "";
        if (value.h == null)
            value.h = 0;
        if (value.m == null)
            value.m = 0;

        var shour: string = (value.h < 10 ? "0" + value.h : "" + value.h);
        var sminute: string = (value.m < 10 ? "0" + value.m : "" + value.m);
        var time: string = value.s + shour + ":" + sminute;
        return time;
    }


    /**
    * Retorna true qiuan el timeinterval representa un espai de temps 0
    */
    public static isNull(value: HTime): boolean {
        if (!value)
            return true;
        if (!value.h && !value.m)
            return true;
        return false;
    }

    /**
     * Indica si és null o reprewenta un valor null o neutre
     * @param value 
     */
    public static isNullOrNullValue(value: HTime): boolean {
        if (value == null)
            return true;
        return HTime.isNull(value);
    }

    public toString(): string {
        return HTime.getTimeString(this);
    }

    public addTime(time: HTime) {
        if (this.s == time.s || (this.s == "" || this.s == null || this.s == undefined) && (time.s == "" || time.s == null || time.s == undefined))
            return new HTime(this.s, this.h + time.h, this.m + time.m);
        return new HTime(this.s, this.h - time.h, this.m - time.m);
    }

    public addHours(hours: number): HTime {
        return new HTime(this.s, this.h + hours, this.m);
    }

    public addMinutes(minutes: number): HTime {
        return new HTime(this.s, this.h, this.m + minutes);
    }

    /**
     * Retorna true quan Les hores i els minuts han de ser els mateixos. Per tant representen la mateixa hora
     * @param other 
     */
    public equals(other: HTime): boolean {
        return HTime.isEqualsThan(this, other);
    }

    public static compare(a: HTime, b: HTime) {
        if (HTime.isGreaterThan(a, b))
            return 1;
        if (HTime.isMinorThan(a, b))
            return -1;
        return 0;
    }


    /**
     * Retorna true quan jo (this) sòc més petit que other (param)
     * @param other 
     */
    public minorThan(other: HTime): boolean {
        return HTime.isMinorThan(this, other);
    }

    static isHTime(value: any): boolean {
        if (value == null)
            return false;
        if (value.h != undefined && value.m != undefined && value.s != undefined)
            return true;
        return false;
    }

    /**
     * True si valuea es més petit que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isMinorThan(valuea: HTime, valueb: HTime): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.s == "-" && valueb.s == "")
            return true;
        if (valuea.s == "" && valueb.s == "-")
            return false;

        let natura = true;
        if (valuea.s == "-")
            natura = false;
        if (valuea.h < valueb.h)
            return natura;
        if (valuea.h > valueb.h)
            return !natura;
        if (valuea.m < valueb.m)
            return natura;
        return !natura;

    }

    /**
     * True si valuea es més petit o igual que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isMinorEqualsThan(valuea: HTime, valueb: HTime): boolean {
        if (!valuea || !valueb)
            return false;
        return valuea.equals(valueb) || valuea.minorThan(valueb);
    }


    /**
     * Retorna true quan jo (this) sòc més gran que other (param)
     * @param other 
     */
    public greaterThan(other: HTime): boolean {
        return HTime.isGreaterThan(this, other);
    }


    /**
     * True si valuea es més gran que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isGreaterThan(valuea: HTime, valueb: HTime): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.s == "" && valueb.s == "-")
            return true;
        if (valuea.s == "-" && valueb.s == "")
            return false;
        let natura = true;
        if (valuea.s == "-")
            natura = false;
        if (valuea.h > valueb.h)
            return natura;
        if (valuea.h < valueb.h)
            return !natura;
        if (valuea.m > valueb.m)
            return natura;
        return !natura;
    }

    /**
     * True si valuea es més gran que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isGreaterEqualsThan(valuea: HTime, valueb: HTime): boolean {
        if (!valuea || !valueb)
            return false;
        return HTime.isEqualsThan(valueb, valueb) || HTime.isGreaterThan(valuea, valueb);
    }

    /**
    * True si valuea és igual valueb. Cap dels dos pot ser null
    * @param valuea 
    * @param valueb 
    */
    public static isEqualsThan(valuea: HTime, valueb: HTime): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.h != valueb.h)
            return false;
        if (valuea.m != valueb.m)
            return false;
        if (valuea.s != valueb.s)
            return false;

        return true;

    }

    /**
    * Reprsentació a pantalla d'un time interval com a texte
    * @param value 
    */
    public static toScreenString(value: HTime): string {
        if (HTime.isNull(value))
            return "--:--";
        return HTime.getTimeString(value);
    }

    /** Retorna una cadena que és la hora totalment comprimida */
    public static toSyntaxString(value: HTime): string {
        if (HTime.isNullOrNullValue(value))
            return "0000";
        return HTime.pad((value.h * 100) + value.m, 7);
    }

    public static pad(num, size): string {
        var s = "000000000" + num;
        return s.substr(s.length - size);
    }

    public static toNumber(value: HTime): number {
        if (HTime.isNullOrNullValue(value))
            return 0;

        return value.h + (((100 * value.m) / 60) / 100);
    }

    /***
     * Retorna la suma dels dos valors de time. Entenem que cap dels dos pot ser null
     */
    public static add(a: HTime, b: HTime): HTime {

        let h = a.h + b.h;
        let m = a.m + b.m;
        h = h + Math.floor(m / 60);
        m = m % 60;

        let result = new HTime("", h, m);
        return result;

    }

    public static millisecondsToMinutesSecondsMillis(duration) {
        let milliseconds = Math.floor((duration % 1000) / 100);
        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        minutes = minutes + (hours * 60);
        let sminutes = (minutes < 10) ? "0" + minutes : minutes;
        let sseconds = (seconds < 10) ? "0" + seconds : seconds;

        if (milliseconds > 0)
            return sminutes + ":" + sseconds + "." + milliseconds;
        return sminutes + ":" + sseconds;
    }

    static distance(a: HTime, b: HTime): HTime {
        if (HTime.isEqualsThan(a, b)) {
            return HTime.NULLVALUE();
        } else if (HTime.isGreaterThan(a, b)) {
            return HTime.operatorMinus(a, b);
        } else {
            return HTime.operatorMinus(b, a);
        }
    }

    static operatorMinus(t1: HTime, t2: HTime): HTime {
        const t1null = HTime.isNullOrNullValue(t1);
        const t2null = HTime.isNullOrNullValue(t2);

        if (t1null && t2null) {
            return HTime.NULLVALUE();
        }

        if (t2null) {
            return t1null ? HTime.NULLVALUE() : t1;
        }
        if (t1null) {
            if (HTime.isNegative(t2)) {
                return HTime.build(HTime.getDecimalValue(Math.abs(t2.h), Math.abs(t2.m)));
            }
            return HTime.build("-" + HTime.getDecimalValueOfHTime(t2));
        }
        return HTime.build(HTime.getDecimalValueOfHTime(t1) - HTime.getDecimalValueOfHTime(t2));
    }
    static isNegative(value: HTime) {
        return "-" === value.getSign();
    }

    public static getDecimalValueOfHTime(value: HTime): number {
        if (value == null)
            return 0;
        return HTime.getDecimalValue(value.h, value.m);
    }
    public static getDecimalValue(hours: number, minutes: number): number {
        // Estàs d'acord que les hores són hores?

        // Els minuts poden ser positius o negatius
        // I els resultats superiors a 60. Aleshores canvi d'hora.
        const horesAdd: number = Math.floor(minutes / 60);
        hours = hours + horesAdd;
        minutes = minutes % 60;

        // Hores + i minuts + -> suma
        // Hores - i minuts - -> suma
        if (!((hours >= 0 && minutes >= 0) || (hours <= 0 && minutes <= 0))) {
            hours -= 1;
            if (minutes < 0) {
                minutes = 60 + minutes;
            } else {
                minutes = 60 - minutes;
            }
        }

        const dminutes: number = minutes / 60;

        const value: number = hours + dminutes;
        return value;
    }

    public static operatorSum(t1: HTime | null, t2: HTime | null): HTime {
        const t1null: boolean = HTime.isNullOrNullValue(t1);
        const t2null: boolean = HTime.isNullOrNullValue(t2);

        if (t1null && t2null) {
            return HTime.NULLVALUE();
        }

        if (t1null) {
            return t2 as HTime;
        }

        if (t2null) {
            return t1 as HTime;
        }

        return HTime.build(HTime.getDecimalValueOfHTime(t1 as HTime) + HTime.getDecimalValueOfHTime(t2 as HTime));
    }

    public static operatorMultiplication(t1: HTime, days: number): HTime {
        if (HTime.isNullOrNullValue(t1))
            return HTime.NULLVALUE();
        return HTime.build(HTime.getDecimalValueOfHTime(t1) * days);
    }

    static toNegative(value: HTime): HTime {
        return HTime.build(-1 * Math.abs(HTime.toNumber(value)));
    }



}