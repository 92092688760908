import { HHour } from "./HHour";
import { Moment } from 'moment';
import * as moment_ from 'moment-timezone';
import { HDate } from "./HDate";
import { SecurityController } from "../controllers/security/security.controller";
import { TimeZones, TimeZoneUtil } from "../enums/TimeZones";
import { HTime } from "./HTime";

const moment = moment_;


export class HDateHour {
    year: number = 0;
    month: number = 0;
    dayOfMonth: number = 0;
    hourOfDay: number = 0;
    minute: number = 0;
    second: number = 0;
    millisecond: number = 0;

    Timezone: string = SecurityController.TIMEZONE;
    TimezoneObserver: string = SecurityController.TIMEZONE;

    public static nullDateHour(): HDateHour {
        let hdatehour = new HDateHour();
        hdatehour.year = 4000;
        hdatehour.month = 12;
        hdatehour.dayOfMonth = 31;
        hdatehour.hourOfDay = 23;
        hdatehour.minute = 59;
        hdatehour.second = 0;
        hdatehour.millisecond = 0;
        /*
        hdatehour.year = 2038;
        hdatehour.month = 1;
        hdatehour.dayOfMonth = 19;
        hdatehour.hourOfDay = 3;
        hdatehour.minute = 14;
        hdatehour.second = 7;
        hdatehour.millisecond = 0;
        */
        return hdatehour;
    }

    setMinutes(value: number): HDateHour {
        let result = new HDateHour();
        result.year = this.year;
        result.month = this.month;
        result.dayOfMonth = this.dayOfMonth;
        result.hourOfDay = this.hourOfDay;
        result.minute = value;
        result.second = this.second;
        result.millisecond = this.millisecond;
        return result;
    }
    setSeconds(value: number): HDateHour {
        let result = new HDateHour();
        result.year = this.year;
        result.month = this.month;
        result.dayOfMonth = this.dayOfMonth;
        result.hourOfDay = this.hourOfDay;
        result.minute = this.minute;
        result.millisecond = this.millisecond;
        result.second = value;
        return result;
    }
    setMilliSeconds(value: number): HDateHour {
        let result = new HDateHour();
        result.year = this.year;
        result.month = this.month;
        result.dayOfMonth = this.dayOfMonth;
        result.hourOfDay = this.hourOfDay;
        result.minute = this.minute;
        result.second = this.second;
        result.millisecond = value;
        return result;
    }
    public static isNullOrNullValue(value: HDateHour): boolean {

        if (value == null)
            return true;
        if (value.year == this.nullDateHour().year)
            if (value.month == this.nullDateHour().month)
                if (value.dayOfMonth == this.nullDateHour().dayOfMonth)
                    if (value.hourOfDay == this.nullDateHour().hourOfDay)
                        if (value.minute == this.nullDateHour().minute)
                            if (value.millisecond == this.nullDateHour().millisecond)
                                return true;

        return false;
    }

    public static isMinor(a: HDateHour, b: HDateHour): boolean {
        let x_null_dt: boolean = this.isNullOrNullValue(a);
        let y_null_dt: boolean = this.isNullOrNullValue(b);
        if ((x_null_dt && y_null_dt)) {
            return false;
        }

        if (x_null_dt) {
            return false;
        }

        if (y_null_dt) {
            return true;
        }

        return HDateHour.isBefore(a, b);
    }

    public static isBefore(a: HDateHour, b: HDateHour): boolean {
        let aUTC: HDateHour = HDateHour.toUTC(a);
        let bUTC: HDateHour = HDateHour.toUTC(b);

        if (aUTC.year <= bUTC.year) {
            if (aUTC.month <= bUTC.month) {
                if (aUTC.dayOfMonth <= bUTC.dayOfMonth) {
                    if (aUTC.dayOfMonth < bUTC.dayOfMonth || aUTC.hourOfDay < bUTC.hourOfDay)
                        return true;
                    else if (aUTC.hourOfDay == bUTC.hourOfDay)
                        if (aUTC.minute < bUTC.minute)
                            return true;
                        else if (aUTC.minute == bUTC.minute)
                            if (aUTC.second < bUTC.second)
                                return true;
                            else if (aUTC.second == bUTC.second)
                                if (aUTC.millisecond < bUTC.millisecond)
                                    return true;
                }
            }
        }
        return false;
    }

    public static isGreater(a: HDateHour, b: HDateHour): boolean {
        let x_null_dt: boolean = this.isNullOrNullValue(a);
        let y_null_dt: boolean = this.isNullOrNullValue(b);
        if ((x_null_dt && y_null_dt)) {
            return false;
        }

        if (x_null_dt) {
            return false;
        }

        if (y_null_dt) {
            return true;
        }

        return HDateHour.isAfter(a, b);
    }

    public static isAfter(a: HDateHour, b: HDateHour): boolean {
        let aUTC: HDateHour = HDateHour.toUTC(a);
        let bUTC: HDateHour = HDateHour.toUTC(b);

        if (aUTC.year >= bUTC.year)
            if (aUTC.month >= bUTC.month)
                if (aUTC.dayOfMonth >= bUTC.dayOfMonth)
                    if (aUTC.dayOfMonth > bUTC.dayOfMonth || aUTC.hourOfDay > bUTC.hourOfDay)
                        return true;
                    else if (aUTC.hourOfDay == bUTC.hourOfDay)
                        if (aUTC.minute > bUTC.minute)
                            return true;
                        else if (aUTC.minute == bUTC.minute)
                            if (aUTC.second > bUTC.second)
                                return true;
                            else if (aUTC.second == bUTC.second)
                                if (aUTC.millisecond > bUTC.millisecond)
                                    return true;

        return false;
    }

    public static getTimeDiff(a: HDateHour, b: HDateHour): number {
        let x_null_dt: boolean = this.isNullOrNullValue(a);
        let y_null_dt: boolean = this.isNullOrNullValue(b);
        if ((x_null_dt && y_null_dt)) {
            return -1;
        }

        if (x_null_dt) {
            return -1;
        }

        if (y_null_dt) {
            return 1;
        }

        return HDateHour.getTimeDifference(a, b);
    }

    public static getTimeDifference(a: HDateHour, b: HDateHour): number {
        let aUTC: HDateHour = HDateHour.toUTC(a);
        let bUTC: HDateHour = HDateHour.toUTC(b);

        let aMillis = +Date.UTC(aUTC.year, aUTC.month, aUTC.dayOfMonth, aUTC.hourOfDay, aUTC.minute, aUTC.second, aUTC.millisecond).toPrecision();
        let bMillis = +Date.UTC(bUTC.year, bUTC.month, bUTC.dayOfMonth, bUTC.hourOfDay, bUTC.minute, bUTC.second, bUTC.millisecond).toPrecision();

        return Math.abs(aMillis - bMillis);
    }

    public static getNativeDate(date: HDateHour): Date {
        return new Date(date.year, date.month - 1, date.dayOfMonth, date.hourOfDay, date.minute, date.second, date.millisecond);
    }

    public static addMilliseconds(date: HDateHour, value: number): HDateHour {
        let thisMillis = + HDateHour.getNativeDate(date).getTime();
        thisMillis += value;

        let date2: HDateHour = HDateHour.build(new Date(thisMillis), date.Timezone, date.TimezoneObserver);

        return date2;
    }

    public static addSeconds(date: HDateHour, value: number): HDateHour {
        return HDateHour.addMilliseconds(date, value * 1000);
    }

    static addMinutes(value: HDateHour, minutes: number): HDateHour {
        return HDateHour.addMilliseconds(value, minutes * 60000);
    }

    public static toUTC(value: HDateHour): HDateHour {
        let date = HDateHour.getMomentAtTimeZone(value, 'UTC');

        let result = HDateHour.buildFromMoment(date);

        return result;
    }

    public static toUserLocal(value: HDateHour): HDateHour {

        let stimezone = SecurityController.TIMEZONE;

        if (stimezone == null || SecurityController == null) {
            stimezone = TimeZoneUtil.getTimeZoneFromBrowser();
        }

        let date = HDateHour.getMomentAtTimeZone(value, stimezone);

        let result = HDateHour.buildFromMoment(date);



        return result;
    }

    public static min(a: HDateHour, b: HDateHour): HDateHour {
        if (HDateHour.isMinorEqualsThan(a, b)) {
            return a;
        }

        return b;
    }

    public static max(a: HDateHour, b: HDateHour): HDateHour {
        if (HDateHour.isGreaterEqualsThan(a, b)) {
            return a;
        }

        return b;
    }

    public static todayFirstMoment(): HDateHour {
        let stimezone = SecurityController.TIMEZONE;
        return HDateHour.todayFirstMomentByTZ(stimezone, stimezone);
    }

    public static todayFirstMomentByTZ(timezone: string, timezoneobserver: string): HDateHour {
        let date = HDate.today();
        let hour = HHour.buildByHoursAndMinutes(0, 0);
        return this.buildByHDateByHHour(date, hour, timezone, timezoneobserver);
    }

    public static todayLastMoment(): HDateHour {
        let stimezone = SecurityController.TIMEZONE;
        return HDateHour.todayLastMomentByTZ(stimezone, stimezone);
    }

    public static todayLastMomentByTZ(timezone: string, timezoneobserver: string): HDateHour {
        let date = HDate.today();
        let hour = HHour.buildByHoursAndMinutes(23, 59);
        return this.buildByHDateByHHour(date, hour, timezone, timezoneobserver);
    }

    public static buildByHDateByHHour(date: HDate, hour: HHour, timezone: string, timezoneobserver: string): HDateHour {
        let result = new HDateHour();
        result.year = date.year;
        result.month = date.month;
        result.dayOfMonth = date.dayOfMonth;
        result.hourOfDay = hour.h;
        result.minute = hour.m;
        result.second = 0;
        result.Timezone = timezone;
        result.TimezoneObserver = timezoneobserver;
        return result;
    }

    public static cloneByHDateHour(value: HDateHour): HDateHour {
        if (value == null)
            return null;
        let result = new HDateHour();
        result.year = value.year;
        result.month = value.month;
        result.dayOfMonth = value.dayOfMonth;
        result.hourOfDay = value.hourOfDay;
        result.minute = value.minute;
        result.second = value.second;
        result.millisecond = value.millisecond;
        result.Timezone = value.Timezone;
        result.TimezoneObserver = value.TimezoneObserver;
        return result;
    }

    public static now(): HDateHour {
        let stimezone = SecurityController.TIMEZONE;
        return HDateHour.nowByTZ(stimezone, stimezone);
    }
    public static nowByTZ(timezone: string, timezoneobserver: string): HDateHour {
        let result = new HDateHour();
        var date = new Date();

        let datemoment: Moment = this.getMomentAtTimeZoneByDate(date, timezone);

        result = this.buildFromMoment(datemoment);
        result.TimezoneObserver = timezoneobserver;
        return result;
    }

    public static getMoment(value: HDateHour): Moment {
        if (value == null)
            value = HDateHour.nullDateHour();

        let date = new Date(value.year, value.month - 1, value.dayOfMonth, value.hourOfDay, value.minute, value.second);
        return moment(date).tz(value.Timezone, true);
    }
    public static getMomentAtTimeZone(value: HDateHour, timezone: string): Moment {
        if (value == null)
            value = HDateHour.nullDateHour();

        let date = this.getDateApplyTimeZone(value);

        let datemoment: Moment = this.getMomentAtTimeZoneByDate(date, timezone);

        return datemoment;
    }

    public static getDateApplyTimeZone(value: HDateHour): Date {

        let dateimezone = value.Timezone;
        if (dateimezone == null)
            dateimezone = 'UTC';
        let date = null;
        if (dateimezone == 'UTC') {
            date = new Date(Date.UTC(value.year, value.month - 1, value.dayOfMonth, value.hourOfDay, value.minute, value.second));
        } else {
            date = new Date(value.year, value.month - 1, value.dayOfMonth, value.hourOfDay, value.minute, value.second);
            let m = moment(date).tz(dateimezone, false);
            date = m.toDate();
        }

        return date;
    }

    public static getMomentAtTimeZoneByDate(date: Date, timezone: string): Moment {
        if (date == null)
            date = new Date();

        if (timezone == null)
            timezone = 'UTC';

        let datemoment: Moment = moment(date).tz(timezone, false);

        return datemoment;
    }

    public static build(value: Date, timezone: string, timezoneobserver: string): HDateHour {
        if (!value)
            return HDateHour.nullDateHour();

        let hdatehour = new HDateHour();
        hdatehour.year = value.getFullYear();
        hdatehour.month = value.getMonth() + 1;
        hdatehour.dayOfMonth = value.getDate();
        hdatehour.hourOfDay = value.getHours();
        hdatehour.minute = value.getMinutes();
        hdatehour.second = value.getSeconds();
        hdatehour.Timezone = timezone;
        hdatehour.TimezoneObserver = timezoneobserver;

        return hdatehour;
    }

    public static buildByHDateHour(value: HDateHour, timezone: string, timezoneobserver: string): HDateHour {
        if (!value)
            return HDateHour.nullDateHour();

        let hdatehour = new HDateHour();
        hdatehour.year = value.year;
        hdatehour.month = value.month;
        hdatehour.dayOfMonth = value.dayOfMonth;
        hdatehour.hourOfDay = value.hourOfDay;
        hdatehour.minute = value.minute;
        hdatehour.second = value.second;
        hdatehour.Timezone = value.Timezone;
        hdatehour.TimezoneObserver = value.TimezoneObserver;
        if (timezone)
            hdatehour.Timezone = timezone;
        if (timezoneobserver)
            hdatehour.TimezoneObserver = timezoneobserver;

        return hdatehour;
    }

    public static buildFromMoment(value: Moment): HDateHour {
        if (!value)
            return HDateHour.nullDateHour();
        if (value.tz)
            return this.buildFromMomentByTZ(value, value.tz(), value.tz());

        let stimezone = SecurityController.TIMEZONE;
        return this.buildFromMomentByTZ(value, stimezone, stimezone);
    }

    public static buildFromMomentByTZ(value: Moment, timezone: string, timezoneobserver: string): HDateHour {
        if (!value)
            return HDateHour.nullDateHour();

        let hdatehour = new HDateHour();
        if (value instanceof Date)
            hdatehour = HDateHour.build(value, timezone, timezoneobserver);
        else if (moment.isMoment(value)) {
            hdatehour.year = value.year();
            hdatehour.month = value.month() + 1;
            hdatehour.dayOfMonth = value.date();
            hdatehour.hourOfDay = value.hour();
            hdatehour.minute = value.minutes();
            hdatehour.second = value.seconds();
            hdatehour.Timezone = timezone;
            hdatehour.TimezoneObserver = timezoneobserver;
        }

        if (!this.isValidDateHour(hdatehour))
            return HDateHour.nullDateHour();

        return hdatehour;
    }


    public static isValidDateHour(value: HDateHour): boolean {
        if (!value)
            return false;

        let d: string = value.dayOfMonth.toString();
        let m: string = value.month.toString();
        let y: string = value.year.toString();

        if (d.length > 2 || value.dayOfMonth > 31 || value.dayOfMonth < 1)
            return false;
        if (m.length > 2 || value.month > 12 || value.month < 1)
            return false;
        if (y.length != 4)
            return false;

        let hour: string = value.hourOfDay.toString();
        let min: string = value.minute.toString();
        let sec: string = value.second.toString();

        if (hour.length > 2 || value.hourOfDay > 24 || value.hourOfDay < 0)
            return false;
        if (min.length > 2 || value.minute > 59 || value.minute < 0)
            return false;
        if (sec.length > 2 || value.second > 59 || value.second < 0)
            return false;

        return true;
    }

    /**
     * Valida que un periode és correcte interpretant que cap de les dues dates pot ser nula o sense periode. Per exemple per demanar un llistat, etc  (amb data de fi obligatoria)
     * @param dateHourFrom 
     * @param dateHourTo 
     */
    public static isValidPeriodNotNullAllowed(dateHourFrom: HDateHour, dateHourTo: HDateHour) {
        if (HDateHour.isNullOrNullValue(dateHourFrom))
            return false;

        if (HDateHour.isNullOrNullValue(dateHourTo))
            return false;

        if (!HDateHour.isGreaterEqualsThan(dateHourTo, dateHourFrom))
            return false;

        return true;
    }

    public static isHDateHour(value: HDateHour): boolean {
        if (value == null)
            return false;
        if (value.year != undefined && value.month != undefined && value.dayOfMonth != undefined
            && value.hourOfDay != undefined && value.minute != undefined && value.second != undefined)
            return true;
        return false;
    }

    public static compare(a: HDateHour, b: HDateHour) {
        if (this.isGreaterThan(a, b))
            return 1;
        if (this.isMinorThan(a, b))
            return -1;
        return 0;
    }

    public static getDate(value: HDateHour): HDate {
        if (value == null)
            return HDate.nullDate();
        return HDate.buildas(value.year, value.month, value.dayOfMonth);

    }

    public static getHour(value: HDateHour): HHour {
        if (value == null)
            return HHour.NULLVALUE();
        return HHour.buildas(value.hourOfDay, value.minute);

    }

    public getDateHourObserver(): HDateHour {
        return HDateHour.dateHourObserver(this);
    }

    /***
     * Jo tin cun datehour i en realitat només l'haig de canviar de zona horaria.
    */
    public static dateHourObserver(value: HDateHour): HDateHour {
        if (HDateHour.isNullOrNullValue(value))
            return HDateHour.nullDateHour();

        let m = HDateHour.getMoment(value);
        let n = m.tz(SecurityController.TIMEZONE);

        return HDateHour.buildFromMoment(n);
    }

    public addYears(years: number): HDateHour {
        if (years == 0)
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);
        resultdatehour.year(resultdatehour.year() + parseInt(years + ""));

        let result = HDateHour.buildFromMomentByTZ(resultdatehour, this.Timezone, this.TimezoneObserver);

        return result;
    }

    public addDays(days: number): HDateHour {
        if (days == 0)
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);
        resultdatehour.date(resultdatehour.date() + parseInt(days + ""));
        let result = HDateHour.buildFromMomentByTZ(resultdatehour, this.Timezone, this.TimezoneObserver);

        return result;
    }

    public addHours(hours: number): HDateHour {
        if (hours == 0)
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);
        resultdatehour = resultdatehour.add(hours, "hours");
        let result = HDateHour.buildFromMomentByTZ(resultdatehour, this.Timezone, this.TimezoneObserver);

        return result;
    }

    public addMinutes(minutes: number): HDateHour {
        if (minutes == 0)
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);
        resultdatehour = resultdatehour.add(minutes, "minutes");
        let result = HDateHour.buildFromMomentByTZ(resultdatehour, this.Timezone, this.TimezoneObserver);

        return result;
    }

    public addSeconds(seconds: number): HDateHour {
        if (seconds == 0)
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);
        resultdatehour = resultdatehour.add(seconds, "seconds");
        let result = HDateHour.buildFromMomentByTZ(resultdatehour, this.Timezone, this.TimezoneObserver);

        return result;
    }

    public addDateHour(datehour: HDateHour, resta: boolean): HDateHour {

        if (HDateHour.isNullOrNullValue(datehour))
            return this;

        let resultdatehour: Moment = HDateHour.getMoment(this);

        if (!resta) {
            resultdatehour.date(resultdatehour.date() + datehour.dayOfMonth);
            resultdatehour.month(resultdatehour.month() + datehour.month);
            resultdatehour.year(resultdatehour.year() + datehour.year);
            resultdatehour.hours(resultdatehour.hours() + datehour.hourOfDay);
            resultdatehour.minutes(resultdatehour.minutes() + datehour.minute);
            resultdatehour.seconds(resultdatehour.seconds() + datehour.second);
        } else {
            resultdatehour.date(resultdatehour.date() - datehour.dayOfMonth);
            resultdatehour.month(resultdatehour.month() - datehour.month);
            resultdatehour.year(resultdatehour.year() - datehour.year);
            resultdatehour.hours(resultdatehour.hours() - datehour.hourOfDay);
            resultdatehour.minutes(resultdatehour.minutes() - datehour.minute);
            resultdatehour.seconds(resultdatehour.seconds() - datehour.second);
        }
        let result = HDateHour.buildFromMoment(resultdatehour);
        return result;
    }

    public static distance(from: HDateHour, to: HDateHour): HDateHour {

        if (this.isNullOrNullValue(from) || this.isNullOrNullValue(to))
            return this.nullDateHour();

        //TODO: TENIR EN COMPTE ELS ZONE
        var t1 = new Date(from.year, from.month - 1, from.dayOfMonth, from.hourOfDay, from.minute, from.second, 0);
        var t2 = new Date(to.year, to.month - 1, to.dayOfMonth, to.hourOfDay, to.minute, to.second, 0);
        var dif = Math.abs(t1.getTime() - t2.getTime());

        var preresult = HDateHour.dhm(dif);

        var result = new HDateHour();
        result.dayOfMonth = preresult.d;
        result.hourOfDay = preresult.h;
        result.minute = preresult.m;
        result.second = preresult.s;

        return result;
    }

    public static distanceTime(from: HDateHour, to: HDateHour): HHour {
        if (HDateHour.isNullOrNullValue(from) || HDateHour.isNullOrNullValue(to))
            return HHour.NULLVALUE();

        let dateHour = HDateHour.distance(from, to);
        let result = HHour.buildByHoursAndMinutes(0, dateHour.minute + dateHour.hourOfDay * 60 + dateHour.dayOfMonth * 1440);
        return result;
    }

    public static buildYearMonthDay(year: number, month: number, day: number): HDate {
        let result = new HDate();
        result.year = year;
        result.month = month;
        result.dayOfMonth = day;
        return result;
    }
    public static getHDate(value: HDateHour): HDate {
        return HDate.buildFromParams(value.year, value.month, value.dayOfMonth);
    }

    public static distanceHTime(a: HDateHour, b: HDateHour): HTime {
        if (HDateHour.isNullOrNullValue(a) || HDateHour.isNullOrNullValue(b)) {
            throw new Error("a or b is null or null value");
        }

        let o1: HDateHour = HDateHour.toUTC(a);
        let o2: HDateHour = HDateHour.toUTC(b);

        if (HDateHour.equals(o1, o2)) {
            return HTime.NULLVALUE();
        }

        // Imaginem que a>b
        let invertit = false;
        if (HDateHour.isGreater(o1, o2)) {
            const t: HDateHour = o2;
            o2 = o1;
            o1 = t;
            invertit = true;
        }

        let result: HTime = HTime.NULLVALUE();

        if (HDate.equals(HDateHour.getHDate(o1), HDateHour.getHDate(o2))) {
            result = HTime.distance(HHour.toHTime(HDateHour.getHHour(o1)), HHour.toHTime(HDateHour.getHHour(o2)));
        } else {
            // Calculem o1 fins 24
            let primerDia = HTime.distance(HHour.toHTime(HDateHour.getHHour(o1)), HHour.toHTime(HHour.HOUR_24));
            // Calculem 0 fins o2
            let ultimDia = HTime.distance(HHour.toHTime(HHour.HOUR_0), HHour.toHTime(HDateHour.getHHour(o2)));
            // i els dies del mig *24
            const dies: number = HDate.daysInInterval(HDateHour.getHDate(o1), HDateHour.getHDate(o2));
            let diesMig = HTime.NULLVALUE();
            if (dies > 2) {
                diesMig = HTime.operatorMultiplication(HHour.toHTime(HHour.HOUR_24), dies - 2);
            }
            result = HTime.add(HTime.add(primerDia, ultimDia), diesMig);
        }

        if (invertit) {
            result = HTime.toNegative(result);
        }

        return result;
    }
    public static getHHour(value: HDateHour): HHour {
        if (value == null)
            return HHour.NULLVALUE();

        return new HHour(value.hourOfDay, value.minute);
    }



    protected static dhm(t) {
        var cd = 24 * 60 * 60 * 1000,
            ch = 60 * 60 * 1000,
            cm = 60 * 1000,
            d = Math.floor(t / cd),
            h = Math.floor((t - d * cd) / ch),
            m = Math.floor((t - d * cd - h * ch) / 60000),
            s = Math.round((t - d * cd - h * ch - m * cm) / 1000),

            pad = function (n) { return n < 10 ? '0' + n : n; };
        if (s === 60) {
            m++;
            s = 0;
        } if (m === 60) {
            h++;
            m = 0;
        }
        if (h === 24) {
            d++;
            h = 0;
        }
        return { d: d, h: h, m: m, s: s }
    }



    public static equals(a: HDateHour, b: HDateHour): boolean {
        if (a == b)
            return true;


        let aisnull: Boolean = this.isNullOrNullValue(a);
        let bisnull: Boolean = this.isNullOrNullValue(b);

        if (a == b)
            return true;
        if (aisnull || bisnull)
            return false;

        if (a.dayOfMonth == b.dayOfMonth && a.month == b.month && a.year == b.year && a.hourOfDay == b.hourOfDay && a.minute == b.minute && a.second == b.second && a.millisecond == b.millisecond)
            return true;

        return false;
    }

    /**
 * Retorna true quan a es una data válida i és més petita que b
 * @param a 
 * @param b 
 */
    public static isMinorThan(a: HDateHour, b: HDateHour): boolean {
        if (HDateHour.isNullOrNullValue(a) && HDateHour.isNullOrNullValue(b))
            return false;
        if (HDateHour.isNullOrNullValue(a))
            return true;
        if (HDateHour.isNullOrNullValue(b))
            return false;
        if (b.year > a.year)
            return true;
        if (b.year == a.year && b.month > a.month)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth > a.dayOfMonth)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay > a.hourOfDay)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute > a.minute)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute == a.minute && b.second > a.second)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute == a.minute && b.second == a.second && b.millisecond > a.millisecond)
            return true;

        return false;
    }
    /**
     * Retorna true quan a es una data válida i és més petita o igual que b
     * @param a 
     * @param b 
     */
    public static isMinorEqualsThan(a: HDateHour, b: HDateHour): boolean {
        if (HDateHour.isMinorThan(a, b) || HDateHour.equals(a, b))
            return true;
        return false;
    }

    /**
 * Retorna true quan a es una data válida i és més gran que b
 * @param a 
 * @param b 
 */
    public static isGreaterThan(a: HDateHour, b: HDateHour): boolean {
        if (HDateHour.isNullOrNullValue(a) && HDateHour.isNullOrNullValue(b))
            return false;
        if (HDateHour.isNullOrNullValue(a))
            return false;
        if (HDateHour.isNullOrNullValue(b))
            return true;
        if (b.year < a.year)
            return true;
        if (b.year == a.year && b.month < a.month)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth < a.dayOfMonth)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay < a.hourOfDay)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute < a.minute)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute == a.minute && b.second < a.second)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth == a.dayOfMonth && b.hourOfDay == a.hourOfDay && b.minute == a.minute && b.second == a.second && b.millisecond < a.millisecond)
            return true;
        return false;
    }

    /**
* Retorna true quan a es una data válida i és més gran que b o igual
* @param a 
* @param b 
*/
    public static isGreaterEqualsThan(a: HDateHour, b: HDateHour): boolean {
        if (HDateHour.isGreaterThan(a, b))
            return true;
        if (HDateHour.equals(a, b))
            return true;
        return false;
    }


    public static toStringSorteable(value: HDateHour) {
        if (HDateHour.isNullOrNullValue(value))
            return "00000000000000.000";

        let result = "";
        result += HHour.pad(value.year, 4);
        result += HHour.pad(value.month, 2);
        result += HHour.pad(value.dayOfMonth, 2);
        result += HHour.pad(value.hourOfDay, 2);
        result += HHour.pad(value.minute, 2);
        result += HHour.pad(value.second, 2);
        result += ".";
        result += HHour.pad(value.millisecond, 3);

        return result;
    }

    public static toStringSorteableShort(value: HDateHour) {
        if (HDateHour.isNullOrNullValue(value))
            return "000000000000";

        let result = "";
        result += HHour.pad(value.year, 4);
        result += HHour.pad(value.month, 2);
        result += HHour.pad(value.dayOfMonth, 2);
        result += HHour.pad(value.hourOfDay, 2);
        result += HHour.pad(value.minute, 2);
        result += HHour.pad(value.second, 2);

        return result;
    }

    public static getUtcOffset(value: HDateHour): HHour {
        let string = HDateHour.getMoment(value);
        let hours = string.utcOffset() / 60;
        let minutes = string.utcOffset() % 60;

        return HHour.buildByHoursAndMinutes(hours, minutes);
    }

    public firstMomentOfDay(): HDateHour {
        return HDateHour.buildByHDateByHHour(HDateHour.getDate(this), HHour.build(0), this.Timezone, this.TimezoneObserver);
    }

    lastDayOfMonth() {
        let date = HDateHour.getDate(this);
        date = HDate.getLastDayOfMonth(date);
        return HDateHour.buildByHDateByHHour(date, HDateHour.getHour(this), this.Timezone, this.TimezoneObserver);
    }
    firstDayOfMonth() {
        let date = HDateHour.getDate(this);
        date = HDate.getFirstDayOfMonth(date);
        return HDateHour.buildByHDateByHHour(date, HDateHour.getHour(this), this.Timezone, this.TimezoneObserver);
    }

    firstMoment(): HDateHour {
        return HDateHour.buildByHDateByHHour(HDateHour.getDate(this), HHour.build(0), this.Timezone, this.TimezoneObserver);
    }
    lastMoment(): HDateHour {
        return HDateHour.buildByHDateByHHour(HDateHour.getDate(this), HHour.buildByHoursAndMinutes(23, 59), this.Timezone, this.TimezoneObserver);
    }

    public static buildFromJson(value: string): HDateHour {
        if (value == null)
            return null;

        var reference: HDateHour = JSON.parse(value) as HDateHour;
        if (!reference)
            return null;

        const result: HDateHour = HDateHour.buildByHDateHour(reference, reference.Timezone, reference.TimezoneObserver);
        return result;
    }

}