<shared-generalstatus-container class="card" *ngIf="shipment" [ngClass]="internalTags"
    [generalStatus]="shipment.generalStatus">
    <div class="internalcard">
        <div class="shipmentId">{{shipment.shipmentId}}</div>
        <div class="line">
            <div class="shipmentKey">
                <shared-copyclipboard [value]="shipment.shipmentKey">{{shipment.shipmentKey}} </shared-copyclipboard>
                <span class="disponibilityMessage">{{shipment.disponibilityMessage}}</span>
            </div>
        </div>
        <div class="line line-problematica">
            <span class="pre-tags-strip">@</span>
            <shared-tags-strip-component class="tags" [tagsString]="userTags"></shared-tags-strip-component>
            <div class="text goods">{{shipment.goods}}</div>
        </div>
        <div class="line origen">
            <span class="pre-tags-strip">@</span>
            <shared-tags-strip-component class="tags" [uniqueString]="shipment.originSkinAddress">
            </shared-tags-strip-component>
            <div class="text address" matTooltip="{{shipment.originFormatedAddress}}">
                <div class="line">
                    {{shipment.originAddress}}
                </div>
                <div *ngIf="!dateIsNull(shipment.originDateTimeStart) || !dateIsNull(shipment.originDateTimeEnds) || !intervalIsNull(shipment.originTimetable)"
                    class="line data">
                    <span *ngIf="!dateIsNull(shipment.originDateTimeStart)">
                        {{shipment.originDateTimeStart | datehournotz}}<span
                            *ngIf="!dateIsNull(shipment.originDateTimeEnds) && !datesAreEqual(shipment.originDateTimeStart, shipment.originDateTimeEnds)">-</span>
                    </span>
                    <span
                        *ngIf="!dateIsNull(shipment.originDateTimeEnds) && !datesAreEqual(shipment.originDateTimeStart, shipment.originDateTimeEnds)">{{shipment.originDateTimeEnds
                        | datehournotz }}</span>
                    <span *ngIf="!intervalIsNull(shipment.originTimetable)">
                        {{shipment.originTimetable | hourintervals }}</span>
                </div>
            </div>
        </div>
        <div class="line desti">
            <span class="pre-tags-strip">@</span>
            <shared-tags-strip-component class="tags" [uniqueString]="shipment.destinationSkinAddress">
            </shared-tags-strip-component>
            <div class="text address" matTooltip="{{shipment.destinationFormatedAddress}}">
                <div class="line">
                    {{shipment.destinationAddress}}
                </div>
                <div *ngIf="!dateIsNull(shipment.destinationDateTimeStart) || !dateIsNull(shipment.destinationDateTimeEnds) || !intervalIsNull(shipment.destinationTimetable)"
                    class="line data">
                    <span *ngIf="!dateIsNull(shipment.destinationDateTimeStart)">
                        {{shipment.destinationDateTimeStart | datehournotz }}<span
                            *ngIf="!dateIsNull(shipment.destinationDateTimeEnds) && !datesAreEqual(shipment.destinationDateTimeStart, shipment.destinationDateTimeEnds)">-</span>
                    </span>
                    <span
                        *ngIf="!dateIsNull(shipment.destinationDateTimeEnds) && !datesAreEqual(shipment.destinationDateTimeStart, shipment.destinationDateTimeEnds)">{{shipment.destinationDateTimeEnds
                        | datehournotz }}</span>
                    <span *ngIf="!intervalIsNull(shipment.destinationTimetable)">
                        {{shipment.destinationTimetable | hourintervals }}</span>
                </div>
            </div>

            <div *ngIf="isActiveRoutes && shipment.pendingMessagesRead > 0" class="pending-messages">
                <i class="fa fa-commenting icon" aria-hidden="true"></i>
                <div class="number">{{ shipment.pendingMessagesRead }}</div>
            </div>
            <div *ngIf="isActiveRoutes" class="icona-inbound"
                [ngClass]="{'right37' : shipment.pendingMessagesRead > 0}">
                <img *ngIf="shipment.isInbound" src="/assets/images/routetype-inbound.svg" alt="Inbound">
                <img *ngIf="shipment.isReturn" src="/assets/images/routetype-return.svg" alt="Return">
                <img *ngIf="shipment.isReverseLogistics" src="/assets/images/logisticaInversa.svg" alt="Reverse logistics">
                <img *ngIf="shipment.isNonacceptance" src="/assets/images/routetype-nonacceptance.svg"
                    alt="Nonacceptance">
                <img *ngIf="shipment.isOutbound" src="/assets/images/routetype-outbound.svg" alt="Outbound">
                <img *ngIf="shipment.isDelegate" src="/assets/images/routetype-delegate.svg" alt="Delegate">
            </div>
        </div>
        <i class="iamobserver fa fa-eye" aria-hidden="true" *ngIf="shipment.iAmObserver"></i>
    </div>
</shared-generalstatus-container>