import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { OpenChangeConditionDto } from '@shared/src/dtos/open/OpenChangeConditionDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { RouteShipmentConditionTypes } from '@shared/src/enums/RouteShipmentConditionTypes';

@Component({
  selector: 'shared-route-changeconditions',
  templateUrl: './shared.route.changeconditions.component.html',
  styleUrls: ['./shared.route.changeconditions.component.scss'],
})
export class SharedRouteChangeConditionsComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  public enumTypes = RouteShipmentConditionTypes;

  @Input() public readonly: boolean = false;

  public condition: OpenChangeConditionDto = new OpenChangeConditionDto();

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
  }
  get routeId(): number {
    return this.__routeId;
  }
  
  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onSetSuperCondition() {
    if (this.routeId == null || this.condition == null)
      return;

    this.openRouteController.setRouteSuperCondition(this.routeId, this.condition).subscribe(data => {
      if (data) {
        this.condition = new OpenChangeConditionDto();
        this.routeController.getRoute(this.routeId).subscribe(route => {
          this.routeUpdated.next(route);
        });
      }
    });
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
