<!-- shared.chat.html !-->
<div class="shared-chat chat-body" [ngClass]="{'empty' : !value, 'ionic': useIonic, 'notes' : notes }">
    <div class="chat-header">
        <shared-chat-avatar *ngIf="participantavatar" class="chat-header-avatar" [participant]="participantavatar"
            [isGroup]="isGroup"></shared-chat-avatar>
        <div class="participantkey">
            <div class="participantKeyValue"> {{participantkey}} </div>
            <div class="participantAnnotation link white">{{participantAnnotation}}</div>
        </div>
        <div id="div-participants" class="participants"
            [ngClass]="{'plegat' : useIonic && !useIos && isPlegat, 'plegat-ios' : useIonic && useIos && isPlegat, 'desplegat' : useIonic && !isPlegat}">
            <div *ngFor="let p of participants" class="link white participantValue" (click)="onClickParticipant(p)">
                {{p.participantAnnotation}}
            </div>
            <span *ngIf="useIonic && showChevron">
                <i *ngIf="!useIos && isPlegat" (click)="onChevronClick()"
                    class="fa fa-chevron-right chevron chevron-plegat" aria-hidden="true"></i>
                <i *ngIf="useIos && isPlegat" (click)="onChevronClick()"
                    class="fa fa-chevron-right chevron-ios chevron-plegat" aria-hidden="true"></i>
                <i *ngIf="!useIos && !isPlegat" (click)="onChevronClick()"
                    class="fa fa-chevron-down chevron chevron-desplegat" aria-hidden="true"></i>
                <i *ngIf="useIos && !isPlegat" (click)="onChevronClick()"
                    class="fa fa-chevron-down chevron-ios chevron-desplegat" aria-hidden="true"></i>
            </span>
        </div>

        <div class="aaa">
            <div class="chat-header-back" [ngClass]="{'chat-header-back-ios' : useIos}">
                <i class="chat-avatar-img back fa fa-times" aria-hidden="true" (click)="back()"></i>
            </div>
        </div>
    </div>
    <div #scrollMessages class="chat-messages" (scroll)="doSomethingOnScroll($event)">
        <shared-chatmessage *ngFor="let item of getMessages(); let index=index" [ownerUserId]="ownerUserId"
            [value]="item" [showAnnotation]="showAnnotation" [index]=index
            (onActionDelete)="doActionDeleteChatMessage(item)" (clickParticipant)="onClickParticipant($event)">
        </shared-chatmessage>
    </div>
    <div class="chat-bottom" [ngClass]="{'readonly' : value != null && value.ownerParticipantType == 'System' }">
        <textarea class="send-text" placeholder="{{getInputPlaceholder() | translate}}" [(ngModel)]="messagetosend"
            (keypress)="textareakeypress($event)"></textarea>
        <i class="send-button fa fa-lg fa-paper-plane" (click)="send()"></i>
    </div>
</div>