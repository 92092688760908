<div class="line" *ngIf="lineObject && readonly">
    <div class="data-left">
        <div class="reference">{{ lineObject.reference }}</div>
        <div class="description">{{ lineObject.description }}</div>
    </div>
    <div class="data-right">
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.WDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.wDimension | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.YDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.yDimension | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.HDIMENSION.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.hDimension | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle" [innerHTML]="'ROUTEPARTCMR.M3.CAPTION' | translate | safe: 'html'"></div>
            <div class="total">{{lineObject.m3 | number}}</div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.QUANTITY.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.quantity | number}}</div>
        </div>
        <div class="element-value expand">
            <div class="subtitle">{{'ROUTEPARTCMR.PALLETCOUNT.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.palletCount | number}} {{ getResourcePalletType(lineObject.palletType)}}
            </div>
        </div>
        <div class="element-value">
            <div class="subtitle">{{'ROUTEPARTCMR.KG.CAPTION' | translate}}</div>
            <div class="total">{{lineObject.kg | number}}</div>
        </div>
    </div>

</div>

<div [formGroup]="rFormCMRDeliveryLine" class="line" *ngIf="lineObject && !readonly">
    <div class="first-line">
        <div class="data-left">
            <div class="reference">{{ lineObject.reference }}</div>
            <div class="description">{{ lineObject.description }}</div>
        </div>
        <div class="data-right">
            <div class="element-value">
                <input-component name="wDimension" formControlName="wDimension" class="value wDimension"
                    placeholder="{{'ROUTEPARTCMR.WDIMENSION.CAPTION' | translate}}" [(ngModel)]="_wDimension" [min]="0"
                    type="number"></input-component>
            </div>
            <div class="element-value">
                <input-component name="yDimension" formControlName="yDimension" class="value yDimension"
                    placeholder="{{'ROUTEPARTCMR.YDIMENSION.CAPTION' | translate}}" [(ngModel)]="_yDimension" [min]="0"
                    type="number"></input-component>
            </div>
            <div class="element-value">
                <input-component name="hDimension" formControlName="hDimension" class="value hDimension"
                    placeholder="{{'ROUTEPARTCMR.HDIMENSION.CAPTION' | translate}}" [(ngModel)]="_hDimension" [min]="0"
                    type="number"></input-component>
            </div>
            <div class="element-value">
                <input-component name="m3" formControlName="m3" class="value m3"
                    placeholder="{{'ROUTEPARTCMR.M3.CAPTION' | translate | safe: 'html'}}" [(ngModel)]="_m3" [min]="0"
                    type="number"></input-component>
            </div>

            <div class="element-value">
                <input-component name="quantity" formControlName="quantity" class="value quantity"
                    placeholder="{{'ROUTEPARTCMR.QUANTITY.CAPTION' | translate}}" [(ngModel)]="_quantity" [min]="0"
                    type="number"></input-component>
            </div>
            <div class="element-value expand">
                <div class="element-value inputPalletCount">
                    <input-component class="value palletCount" name="palletCount" formControlName="palletCount"
                        placeholder="{{'ROUTEPARTCMR.PALLETCOUNT.CAPTION' | translate}}" [(ngModel)]="_palletCount"
                        [min]="0" type="number"></input-component>
                </div>
                <div class="total resourcePalletType">{{ getResourcePalletType(lineObject.palletType)}}</div>
            </div>
            <div class="element-value">
                <input-component class="value kg" name="kg" formControlName="kg"
                    placeholder="{{'ROUTEPARTCMR.KG.CAPTION' | translate}}" [(ngModel)]="_kg" [min]="0"
                    type="number"></input-component>
            </div>
        </div>
        <div *ngIf="lineObject.restLine != null" class="second-line">
            <div class="data-left">

                <div class="buttonZone">
                    <button class="confirmButton" (click)="onPutAll()"
                        [disabled]="!lineObject.canPutAll">{{'ROUTEPARTCMR.PUTALL.BUTTON' | translate
                        }}</button>
                    <button class="cancelButton" (click)="onRemoveAll()"
                        [disabled]="!lineObject.canRemoveAll">{{'ROUTEPARTCMR.REMOVEALL.BUTTON' | translate }}</button>
                </div>

            </div>
            <div class="data-right">
                <div class="line-rest">
                    <div class="subtitle">{{'ROUTEPARTCMR.RESTASSIGN.CAPTION' | translate}}</div>
                    <div class="element-value">
                        <div class="total">{{lineObject.restLine.quantityRest | number}}</div>
                    </div>
                    <div class="element-value expand">
                        <div class="total">{{lineObject.restLine.palletCountRest | number}}
                            {{ getResourcePalletType(lineObject.palletType)}}</div>
                    </div>
                    <div class="element-value">
                        <div class="total">{{lineObject.restLine.kgRest | number}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>