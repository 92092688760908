export enum TelemetryProvider {
    Cargobull = "Cargobull",
    Carrier = "Carrier",
    HardmanT = "HardmanT",
    Sensitech = "Sensitech",
    HardmanTZ = "HardmanTZ",
    User = "User",
    Manual = "Manual",
    Queclink = "Queclink",
    None = "None"
}