import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router"
import { MatDialog } from '@angular/material/dialog';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { AlertService } from '@shared/src/public-api';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';

@Component({
    selector: 'shared-exwork-phasecard-component',
    templateUrl: './shared.exwork.phasecard.component.html',
    styleUrls: ['./shared.exwork.phasecard.component.scss']
})
export class SharedExworkPhaseCardComponent implements OnInit {


    constructor(protected socketController: SocketController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
    }

    public __phase: PhaseCardItemDto;
    @Input()
    public set phase(value: PhaseCardItemDto) {
        if (this.__phase == value)
            return;
        this.__phase = value;
    }
    public get phase(): PhaseCardItemDto {
        return this.__phase;
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
