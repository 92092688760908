<mat-accordion class="exworks accordion" *ngIf="value.canExwork">
  <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
          <mat-panel-title>
              <div class="ellipsis">
                  <span class="title-expansion">
                      {{'ROUTE.ADDITIONALDRIVERS.CAPTION' | translate }}</span>
              </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-expansion-panel-body>
        <shared-route-externaldrivers-edit [value]="value" [readonly]="readonly" (refresh)="onRefresh($event)"></shared-route-externaldrivers-edit> 
      </mat-expansion-panel-body>
  </mat-expansion-panel>
</mat-accordion>
<div class="exworks no-accordion">
    <shared-route-externaldrivers-edit [value]="value" [readonly]="readonly" (refresh)="onRefresh($event)"></shared-route-externaldrivers-edit>
</div>