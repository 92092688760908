import { Injectable, Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment-timezone';
import { SecurityController } from '../controllers/security/security.controller';
import { HDateHour } from "../datatypes/HDateHour";
const moment = moment_;

@Pipe({
	name: 'datehouronlyhour'
})
@Injectable()
export class DateHourOnlyHourFilter implements PipeTransform {
    transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "00:00";
		let result: string;

        result = this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2);
		return result;
	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}