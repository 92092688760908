import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { Route_eCMRPaymentDto } from '@shared/src/dtos/route/cmr/ecmrparts/Route_eCMRPaymentDto';

@Component({
  selector: 'updatepaymentdialog-dialog',
  templateUrl: './updatepaymentdialog.dialog.component.html'
})
export class UpdatePaymentDialogDialogComponent {

  @Output() public onResolve: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private mdDialogRef: MatDialogRef<UpdatePaymentDialogDialogComponent>,
    private mdDialog: MatDialog,
    private ecmrController: RouteCMRController) { }

  onClose() {
    this.onResolve.next(false);
    this.mdDialog.closeAll();
  }

  onConfirm() {
    this.ecmrController.updatePayment(this.data.routePartCMRId, Route_eCMRPaymentDto.build(this.data.payment, this.data.amount)).subscribe(e => {
      if (e) {
        this.onResolve.next(true);
        this.mdDialog.closeAll();
      }
    });
  }
}