<ng-container *ngIf="routePartCMRId == 0 || !routePartCMRId">
    <div class="block-esquerra">
        <div class="header"></div>
        <div class="nouuid">
            No valid uuid
        </div>
    </div>
</ng-container>
<div *ngIf="routePartCMRId > 0" class="container">
    <div class="block-esquerra">
        <shared-route-part-cmr-digitaldocument-component class="nosignatures" [routePartCMRId]="routePartCMRId" [readonly]="true">
        </shared-route-part-cmr-digitaldocument-component>
        <div class="dummy"></div>
    </div>
</div>
<div class="signaturahardman">
    <img class="logoimg" src="/assets/images/HardmanLogoBlanc.png" alt="Hardman logo">
    <a href="https:hardman.app" target="_blank" class="powered">{{'GLOBAL.DESENVOLUPATPER' | translate}}</a>
</div>