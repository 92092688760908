<div class="line" *ngIf="package && package.packageId">
  <div class="first-line">
    <div class="value key">
      <input-component class="ultraslim nolabel" [readonly]="readonly" [(ngModel)]="package.packageKey"
        (ngModelChange)="valueChangedEvent()"></input-component>
    </div>
    <div class="value xDimension">
      <input-component class="ultraslim nolabel" [readonly]="readonly" [(ngModel)]="package.xDimension"
        (ngModelChange)="valueChangedEvent()" textAlign="right"></input-component>
    </div>
    <div class="value yDimension">
      <input-component class="ultraslim nolabel" [readonly]="readonly" [(ngModel)]="package.yDimension"
        (ngModelChange)="valueChangedEvent()" textAlign="right"></input-component>
    </div>
    <div class="value zDimension">
      <input-component class="ultraslim nolabel" [readonly]="readonly" [(ngModel)]="package.zDimension"
        (ngModelChange)="valueChangedEvent()" textAlign="right"></input-component>
    </div>
    <div class="value kg">
      <input-component class="ultraslim nolabel" [readonly]="readonly" [(ngModel)]="package.kg"
        (ngModelChange)="valueChangedEvent()" textAlign="right"></input-component>
    </div>
    <div class="value button-sub-line-group">
      <div class="eschenker-information" *ngIf="package.inESchenker">
        <i aria-hidden="true" (click)="getLabel()" class="cercle fa fa-eye"></i>
      </div>
      <div *ngIf="package.labelCandidate">
        <confirmation-dialog [expanded]="askingCreateLabel" helptitleresource="ESCHENKERCOMPONENT.CREATELABEL.CAPTION"
          helpresource="ESCHENKERCOMPONENT.CREATELABEL.CONFIRM" (onConfirm)="createLabel()"
          (onCancel)="askingCreateLabel = false"></confirmation-dialog>

        <i aria-hidden="true" *ngIf="!package.inESchenker"
          matTooltip="{{ 'ESCHENKERCOMPONENT.NOLABEL.CAPTION' | translate }}" (click)="askingCreateLabel = true"
          class="cercle fa fa-file-text-o"></i>
        <i aria-hidden="true" *ngIf="package.inESchenker" (click)="downloadLabel()"
          class="cercle fa fa-cloud-download"></i>
        <!-- <shared-document-view-component *ngIf="documentLabel" (onClose)="documentLabel = null"
          class="document-item-iframe" [documentDetail]="documentLabel"></shared-document-view-component> -->
      </div>
      <div *ngIf="package.lines && package.lines.length > 0" (click)="isExpanded = !isExpanded">
        <i aria-hidden="true" *ngIf="isExpanded" class="cercle fa fa-chevron-down"></i>
        <i aria-hidden="true" *ngIf="!isExpanded" class="cercle fa fa-chevron-right"></i>
      </div>
    </div>
  </div>
  <div class="second-line" *ngIf="isExpanded">
    <span class="second-line-title">{{ 'PACKAGE.SHIPMENTDELIVERYLINES.CAPTION' | translate }}</span>



    <div class="shipment-delivery-line" *ngFor="let line of package.lines; let lineIndex=index">
      <div class="value reference">
        <input-component class="ultraslim" [(ngModel)]="line.reference" [readonly]="true"
          placeholder="{{ 'SHIPMENTDELIVERY.REFERENCE.CAPTION' | translate }}"></input-component>
      </div>
      <div class="value description">
        <input-component class="ultraslim" [(ngModel)]="line.description" [readonly]="true"
          placeholder="{{ 'SHIPMENTDELIVERY.DESCRIPTION.CAPTION' | translate }}"></input-component>
      </div>

      <div class="delete button-sub-line" (click)="removeShipmentDeliveryLineFromPackage(line, lineIndex)">
        <i aria-hidden="true" class="cercle fa fa-times"></i>
      </div>

      <div class="dimensions">
        <div class="value key">
          <input-component class="ultraslim" [(ngModel)]="line.wDimension != null ? line.quantity : emptyValue"
            [readonly]="true" placeholder="{{ 'SHIPMENTDELIVERY.QUANTITY.CAPTION' | translate }}"
            textAlign="right"></input-component>
        </div>
        <div class="value xDimension">
          <input-component class="ultraslim" [(ngModel)]="line.wDimension != null ? line.wDimension : emptyValue"
            [readonly]="true" placeholder="{{ 'PACKAGE.XDIMENSION.CAPTION' | translate }}"
            textAlign="right"></input-component>
        </div>
        <div class="value yDimension">
          <input-component class="ultraslim" [(ngModel)]="line.hDimension != null ? line.hDimension : emptyValue"
            [readonly]="true" placeholder="{{ 'PACKAGE.YDIMENSION.CAPTION' | translate }}"
            textAlign="right"></input-component>
        </div>
        <div class="value zDimension">
          <input-component class="ultraslim" [(ngModel)]="line.yDimension != null ? line.yDimension : emptyValue"
            [readonly]="true" placeholder="{{ 'PACKAGE.ZDIMENSION.CAPTION' | translate }}"
            textAlign="right"></input-component>
        </div>
        <div class="value kg">
          <input-component class="ultraslim" [(ngModel)]="line.kg != null ? line.kg : emptyValue" [readonly]="true"
            placeholder="{{ 'PACKAGE.KG.CAPTION' | translate }}" textAlign="right"></input-component>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="line" *ngIf="!internalDelete && (!package || !package.packageId)">
  <div *ngIf="!package" class="create-line">
    <div class="value button-sub-line" (click)="createPackageObject()">
      <i aria-hidden="true" class="cercle fa fa-plus"></i>
    </div>
  </div>
  <div *ngIf="package && !package.packageId" class="create-line" [formGroup]="pForm">
    <div class="value key">
      <input-component class="ultraslim" [readonly]="readonly" [(ngModel)]="package.packageKey"
        formControlName="packageKey" placeholder="{{ 'PACKAGE.KEY.CAPTION' | translate }}"></input-component>
    </div>
    <div class="value xDimension">
      <input-component class="ultraslim" [readonly]="readonly" [(ngModel)]="package.xDimension"
        formControlName="xDimension" placeholder="{{ 'PACKAGE.XDIMENSION.CAPTION' | translate }}"
        textAlign="right"></input-component>
    </div>
    <div class="value yDimension">
      <input-component class="ultraslim" [readonly]="readonly" [(ngModel)]="package.yDimension"
        formControlName="yDimension" placeholder="{{ 'PACKAGE.YDIMENSION.CAPTION' | translate }}"
        textAlign="right"></input-component>
    </div>
    <div class="value zDimension">
      <input-component class="ultraslim" [readonly]="readonly" [(ngModel)]="package.zDimension"
        formControlName="zDimension" placeholder="{{ 'PACKAGE.ZDIMENSION.CAPTION' | translate }}"
        textAlign="right"></input-component>
    </div>
    <div class="value kg">
      <input-component class="ultraslim" [readonly]="readonly" [(ngModel)]="package.kg" formControlName="kg"
        placeholder="{{ 'PACKAGE.KG.CAPTION' | translate }}" textAlign="right"></input-component>
    </div>
    <div class="value shipmentDeliveryLine">
      <entity-selector class="ultraslim noborder" placeholder="{{'PACKAGE.DELIVERYLINE.CAPTION' | translate}}"
        [(ngModel)]="package.shipmentDeliveryLineIdToAdd" [fixedFilterKeys]="shipmentDeliveryLineByRouteFilterKeys"
        [entitySelector]="'ShipmentDeliveryLine'" formControlName="shipmentDeliveryLine" [showgotomaintenance]="false">
      </entity-selector>
    </div>
    <div class="value button-sub-line" *ngIf="pForm.valid" (click)="createPackage()">
      <i aria-hidden="true" class="cercle fa fa-floppy-o"></i>
    </div>
  </div>
</div>