import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { PhaseListMaintenanceDto } from './PhaseListMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { PhaseItemMaintenanceDto } from './PhaseItemMaintenanceDto';
import { Router } from '@angular/router';
import { MaintenanceSearchByTextDto } from '../_base/MaintenanceSearchByTextDtos';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { PhaseItemMaintenanceCtrlDto } from '@shared/src/public-api';
import { HLong } from '@shared/src/datatypes/HLong';

@Injectable()
export class PhaseMaintenanceController extends BaseMaintenanceController<PhaseListMaintenanceDto, PhaseItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "activity/phase");
    }

    public getResourceRoot(): string {
        return "PHASE";
    }

    public getPhasesByRoute(search: MaintenanceSearchByTextDto): Observable<PhaseItemMaintenanceDto[]> {
        return this.postBusy(this.getBaseUrl() + "/getphasesbyroute", search, "getPhasesByRoute");
    }

    public getPhaseOptions(phaseId: number): Observable<PhaseItemMaintenanceCtrlDto> {
        if (HLong.isNullOrNullLong(phaseId))
            return this.get(this.getBaseUrl() + "/phaseoptions");
        return this.get(this.getBaseUrl() + "/phaseoptions/" + phaseId);
    }


    public getPhaseActions(phaseId: number): Observable<ActionsStripDto> {
        if (HLong.isNullOrNullLong(phaseId))
            return this.get(this.getBaseUrl() + "/actions");
        else
            return this.get(this.getBaseUrl() + "/actions/" + phaseId);
    }



}