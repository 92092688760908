import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoadingService } from '@shared/src/public-api';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { FestivesItemMaintenanceDto } from './FestivesItemMaintenanceDto';
import { FestivesListMaintenanceDto } from './FestivesListMaintenanceDto';

@Injectable()
export class FestivesMaintenanceController extends BaseMaintenanceController<FestivesListMaintenanceDto, FestivesItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "festives");
    }

    public getResourceRoot(): string {
        return "FESTIVES";
    }

    public buildUrl(uri: string): string {
        return "/system/dataandparameters/festives" + uri;
    }
}