import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory';
import { PlateRouteDto } from '@shared/src/dtos/accesscontrol/PlateRouteDto';
import { LoadConsolePhaseFilterDto } from '@shared/src/dtos/loadconsole/LoadConsolePhaseFilterDto';
import { ManagementModelChatInfoDto } from '@shared/src/dtos/managementmodel/ManagementModelChatInfoDto';
import { PanelElementFilterDto } from '@shared/src/dtos/panel/column/element/PanelElementFilterDto';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { first } from 'rxjs/operators'
import { RouteCardDto } from '@shared/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class MobileAsideFactory<T = undefined> extends SharedAsideFactory<T> {
    constructor() {
        super();
    }

    public invokeActiveRoutePhase(routeId: number, phaseId: number): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }
    public invokeRoute(routeId: number): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }
    public invokePhase(phaseId: number): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    public invokeAddressCoordinates(addressId: number, formattedAddress: string, longitude: number, latitude: number) {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    public invokePlateRoute(data: PlateRouteDto) {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    public invokeLoadConsolePhase(data: LoadConsolePhaseFilterDto) {
        return new Promise(resolve => {
            resolve(null);
        });
    }
    
    public invokePanelElement(data: PanelElementFilterDto) {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateNonacceptance(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateInbound(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateReturn(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateOutbound(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateDelegate(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeCreateReverseLogistics(data: RouteReturnInBoundOutBoundDelegatedDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    invokeBookingRoute(data: RouteCardDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }
    
    invokeManagementModelChat(data: ManagementModelChatInfoDto): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

    public invokeUserLogInfo(data: number): Promise<any> {
        return new Promise(resolve => {
            resolve(null);
        });
    }

}