import { Injectable } from '@angular/core';
import { BaseMaintenanceDto } from '@shared/src/controllers/maintenance/BaseMaintenanceDto';

@Injectable()
export class SkinDto extends BaseMaintenanceDto {
    public skin: string;

    public empty(): SkinDto {
        this.skin = "";
        return this;
    }

    public copyFrom(value: SkinDto) {
        this.skin = value.skin;
    }

    public defaultSkin(): SkinDto {
        if (this.skin == null || this.skin == "")
            this.skin = "HM";
        return this;
    }

    public static fillDark(value: SkinDto) {
        value.skin = "-";
    }
}