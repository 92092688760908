import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AmbitListMaintenanceDto } from './AmbitListMaintenanceDto';
import { AmbitItemMaintenanceDto } from './AmbitItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { AmbitCustomCodeDto } from '@shared/src/dtos/ambit/AmbitCustomCodeDto';
import { Observable } from 'rxjs';


@Injectable()
export class AmbitMaintenanceController extends
    BaseMaintenanceController<AmbitListMaintenanceDto, AmbitItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "system/securityandnotifications/ambit");
    }


    public getResourceRoot(): string {
        return "AMBIT";
    }

    public getAmbitsUser(): Observable<Array<AmbitListMaintenanceDto>> {
        return this.get(this.getBaseUrl() + "/ambitsuser");
    }

    public getAmbitCustomCode(ambitid: number): Observable<AmbitCustomCodeDto> {
        return this.get(this.getBaseUrl() + "/code/" + ambitid);
    }

    public compileCode(data: AmbitCustomCodeDto): Observable<AmbitCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/compilecode", data, "compileCode");
    }

    public saveCode(data: AmbitCustomCodeDto): Observable<AmbitCustomCodeDto> {
        return this.post(this.getBaseUrl() + "/savecode", data, "saveCode");
    }

    public buildUrl(uri: string): string {
        return "/system/securityandnotifications/ambit" + uri;
    }
}
