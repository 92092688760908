import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '../../../services/alert/alert.service';
import { LoadingService } from '../../../services/loading/loading.service';
import { ManagementModelListMaintenanceDto } from './ManagementModelListMaintenanceDto';
import { ManagementModelItemMaintenanceDto } from './ManagementModelItemMaintenanceDto';


@Injectable()
export class ManagementModelMaintenanceController extends
    BaseMaintenanceController<ManagementModelListMaintenanceDto, ManagementModelItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "managementmodel");
    }


    public getResourceRoot(): string {
        return "MANAGEMENTMODEL";
    }

    public buildUrl(uri: string): string {
        return "/system/screensandforms/managementmodel" + uri;
    }

}
