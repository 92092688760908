
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartDto } from './ChartDto';

@Component({
  selector: 'shared-chart-component',
  templateUrl: './shared.chart.component.html',
  styleUrls: ['./canvas.scss']
})
export class SharedChartComponent implements AfterViewInit {

  public _value: ChartDto;
  @Input() set value(value: ChartDto) {
    if (this._value === value)
      return;
    this._value = value;
    this.loadChart();
  }
  get value(): ChartDto {
    return this._value;
  }

  @Input() public showSelectedLabel: boolean;

  public isSomething(): boolean {
    if (!this._value || !this._value.chartType || this._value.chartType == "")
      return false;
    return true;
  }

  public isData(): boolean {
    if (!this.isSomething())
      return false;

    if (this._value.chartType == ChartDto.PIE && this._value.data != null && this.value.data.length != 0)
      return true;

    return false;

  }

  public isDataSets(): boolean {
    if (!this.isSomething())
      return false;

    if ((this._value.chartType == ChartDto.LINE || this._value.chartType == ChartDto.BAR) && this._value.datasets != null)
      return true;

    return false;

  }

  public selectedlabel: string;
  private _selectedIndex: number;
  @Input() public set selectedIndex(value: number) {
    if (this._selectedIndex === value)
      return;

    this._selectedIndex = value;
    this.setPointsColor(this._selectedIndex);
  }
  public get selectedIndex(): number {
    return this._selectedIndex;
  }

  private lastIndex: number = -1;
  @Output() ChartClick: EventEmitter<any> = new EventEmitter<any>();
  onChartClick($event) {
    if ($event != null) {
      if ($event.active != null) {
        if ($event.active[0] != null) {
          this.selectedIndex = $event.active[0]._index;
        }
      }
    }
    this.ChartClick.next($event);
  }

  setPointsColor(index: number) {

    if (this.value != null && this.value.labels != null) {
      if (index == null)
        this.selectedlabel = "";
      else
        this.selectedlabel = this.value.labels[index];

      if (this.myChart != null && this.myChart.data != null) {

        let dataset = this.myChart.data.datasets[0];
        if (dataset != null) {
          if (this.lastIndex >= 0) {
            dataset.pointBackgroundColor[this.lastIndex] = undefined;
          }

          if (index != null)
            dataset.pointBackgroundColor[index] = 'black';

          this.lastIndex = index;

          this.myChart.update();
        }
      }
    }
  }

  @ViewChild('chart') private chart: ElementRef<HTMLCanvasElement>;
  public myChart: Chart;

  ngAfterViewInit(): void {
    this.loadChart();
  }

  loadChart(): void {

    this.lastIndex = -1;
    if (this.chart == null || this.chart.nativeElement == null)
      return;

    var ctx = this.chart.nativeElement.getContext('2d');
    if (ctx == null)
      return;

    let chartdata: Chart.ChartData;
    if (this.isData()) {

      let datasets = new Array();
      let dataset = {
        data: this._value.data
      };
      datasets.push(dataset);

      chartdata = {
        labels: this._value.labels,
        datasets: datasets
      };

    } else if (this.isDataSets()) {
      this._value.data
      chartdata = {
        labels: this._value.labels,
        datasets: this._value.datasets,
      };
    }
    this.myChart = new Chart(ctx, {
      type: this._value.chartType,
      data: chartdata,
      options: this._value.options
    });
  }

  public pretty(value: any): string {
    return JSON.stringify(value);

  }

}
