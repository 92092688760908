import { ErrorHandler, Injectable, Inject } from '@angular/core';
import { MobileAlertService } from './mobile.alert.service';
import { GlobalErrorHandlerUtils } from '@shared/src/public-api';
import { MobileSecurityController } from 'src/app/controllers/security/mobile-security.controller';

@Injectable({
    providedIn: 'root'
  })
export class MobileGlobalErrorHandler implements ErrorHandler {

    constructor(@Inject('AlertService') public alertService: MobileAlertService, @Inject("SecurityController") public securityController: MobileSecurityController) {
    }

    handleError(error: any): void {
        if (!error)
            return;

        let message: string = GlobalErrorHandlerUtils.getErrorMessage(error);

        if (message && message.startsWith("Uncaught (in promise): GeolocationPositionError")) {
            console.log("Error startsWith " + error);
            if (this.securityController.locationError$) {
                this.securityController.locationError$.next(true);
            }
            return;
        }

        // Absorver el error que sale siempre y no sabemos que hace
        if (message && message.startsWith("ExpressionChangedAfterItHasBeenCheckedError"))
            return;

        this.alertService.showError(error, false);

        console.error(error);
    }

}
