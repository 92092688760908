<!-- calendar.month.html !-->
<div class="calendar-month-container">
	<div class="calendarmonth-month esunlink"><a (click)="monthclick()">{{ getMonthResource() | translate }}</a></div>
	<div class="calendarmonth-weekdays">
		<div class="calendar-week">
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(1)">
				<span class="long">{{ 'WEEKDAY.1' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.1' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(2)">
				<span class="long">{{ 'WEEKDAY.2' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.2' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(3)">
				<span class="long">{{ 'WEEKDAY.3' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.3' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(4)">
				<span class="long">{{ 'WEEKDAY.4' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.4' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(5)">
				<span class="long">{{ 'WEEKDAY.5' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.5' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(6)">
				<span class="long">{{ 'WEEKDAY.6' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.6' | translate }}</span>
			</a>
		</div>
		<div class="calendar-weekday esunlink">
			<a (click)="weekdayclick(7)">
				<span class="long">{{ 'WEEKDAY.7' | translate }}</span>
				<span class="small">{{ 'WEEKDAY.MINI.7' | translate }}</span>
			</a>
		</div>
	</div>
	<div class="calendarmonth-days">
		<table class="calendarmonth-table">
			<tr class="calendarmonth-tr" *ngFor="let week of getcalendarweeks()" [ngStyle]="getStyleTr()">
				<td class="calendarmonth-week  esunlink" (click)="weekclick(getWeekNumber(week))"><span
						class="absoluteWeek">{{getWeekNumber(week)}}</span></td>
				<td class="calendarmonth-td  esunlink" [ngClass]="getclass(day)"
					*ngFor="let day of getcalendardaysofweek(week); let index=index">
					<span class="dayofmonth" [ngClass]="getClassOfDayCaption(day)">{{getDayCaption(day)}}</span>
					<calendarday [monthWidth]="monthWidth/7" [readonly]="readonly" [value]="day"
						(onDateClick)="dayclick(day)" [draggable]=false [ngClass]="getCalendarDayClass(day)">
					</calendarday>
				</td>
			</tr>
		</table>
	</div>
</div>