import { Pipe, PipeTransform } from '@angular/core';
import { HDateHour } from '../datatypes/HDateHour';
import { HDate } from '../datatypes/HDate';
import { DateFilter } from './DateFilter';
import { SecurityController } from '../controllers/security/security.controller';

@Pipe({
	name: 'datehour'
})
export class DateHourFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		let stimezone = "";
		if (value.Timezone != null)
			stimezone = value.Timezone.replace('_', '/')

		result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + " " + stimezone;
		return result;
	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'datehourwithseconds'
})
export class DateHourWithSecondsFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		let stimezone = "";
		if (value.Timezone != null)
			stimezone = value.Timezone.replace('_', '/')

		result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + " " + stimezone;
		return result;
	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'datehourwithsecondsnotz'
})
export class DateHourWithSecondsFilterNoTz implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2);
		return result;
	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'datehournotz'
})
export class DateHourNoTzFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2) + ":" + DateHourNoTzFilter.pad(value.second, 2);
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}

@Pipe({
	name: 'datehournosecondsnotz'
})
export class DateHourNoSecondsNoTzFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2);
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}
@Pipe({
	name: 'datehournosecondsnotzobserver'
})
export class DateHourNoSecondsNoTzObserverFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		value = HDateHour.dateHourObserver(value);

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2);
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}
@Pipe({
	name: 'datehournosecondsobserver'
})
export class DateHourNoSecondsObserverFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		value = HDateHour.dateHourObserver(value);

		let stimezone = "";
		if (value.Timezone != null)
			stimezone = value.Timezone.replace('_', '/')

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2) + " " + stimezone;;
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}
@Pipe({
	name: 'datehournosecondsreactive'
})
export class DateHourNoSecondsReactiveFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		let stimezone = "";
		if (SecurityController.TIMEZONE != value.Timezone)
			stimezone = value.Timezone.replace('_', '/')

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2) + " " + stimezone;;
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}
@Pipe({
	name: 'datehournosecondsobserverreactive'
})
export class DateHourNoSecondsObserverReactiveFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		value = HDateHour.dateHourObserver(value);

		let stimezone = "";
		if (SecurityController.TIMEZONE != value.Timezone)
			stimezone = value.Timezone.replace('_', '/')

		//result = this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2) + ":" + this.pad(value.second, 2) + "." + this.pad(value.millisecond, 3);
		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year + " " + DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2) + " " + stimezone;;
		return result;
	}

	public static pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}
}
@Pipe({
	name: 'datehouronlydatenotz'
})
export class DateHourOnlyDateNoTzFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year;
		return result;
	}
}


@Pipe({
	name: 'datehouronlyhournotz'
})
export class DateHourOnlyHourNoTzFilter implements PipeTransform {

	public transform(value: HDateHour): string {
		return DateHourOnlyHourNoTzFilter.getTransform(value);
	}

	public static getTransform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		result = DateHourNoTzFilter.pad(value.hourOfDay, 2) + ":" + DateHourNoTzFilter.pad(value.minute, 2);
		return result;
	}
}

@Pipe({
	name: 'datehourinterval'
})
export class DateHourIntervalFilter implements PipeTransform {

	transform(from: HDateHour, to: HDateHour): string {
		if (HDateHour.isNullOrNullValue(from) || HDateHour.isNullOrNullValue(to))
			return "---";
		let result: string;

		let fromDate: string = DateFilter.getTransform(HDate.buildFromMoment(HDateHour.getMoment(from)));
		let toDate: string = DateFilter.getTransform(HDate.buildFromMoment(HDateHour.getMoment(to)));

		let fromHour: string = DateHourOnlyHourNoTzFilter.getTransform(from);
		let toHour: string = DateHourOnlyHourNoTzFilter.getTransform(to);

		if (fromDate == toDate) {
			result = fromDate + " " + fromHour + " - " + toHour;
		} else {
			result = fromDate + " " + fromHour + " - " + toDate + " " + toHour;
		}

		return result;
	}
}

@Pipe({
	name: 'datehourintervalobserver'
})
export class DateHourIntervalObserverFilter implements PipeTransform {

	transform(from: HDateHour, to: HDateHour): string {
		if (HDateHour.isNullOrNullValue(from) || HDateHour.isNullOrNullValue(to))
			return "---";
		let result: string;

		from = HDateHour.dateHourObserver(from);
		to = HDateHour.dateHourObserver(to);

		let fromDate: string = DateFilter.getTransform(HDate.buildFromMoment(HDateHour.getMoment(from)));
		let toDate: string = DateFilter.getTransform(HDate.buildFromMoment(HDateHour.getMoment(to)));

		let fromHour: string = DateHourOnlyHourNoTzFilter.getTransform(from);
		let toHour: string = DateHourOnlyHourNoTzFilter.getTransform(to);

		if (fromDate == toDate) {
			result = fromDate + " " + fromHour + " - " + toHour;
		} else {
			result = fromDate + " " + fromHour + " - " + toDate + " " + toHour;
		}

		return result;
	}
}

@Pipe({
	name: 'datehouronlytimezone'
})
export class DateHourOnlyTimeZoneFilter implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "";
		let result: string;

		let stimezone = "";
		if (value.Timezone != null)
			stimezone = value.Timezone.replace('_', '/')
		return stimezone;
	}

}

@Pipe({
	name: 'datehouronlydateobserver'
})
export class DateHouronlydateobserver implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		value = HDateHour.dateHourObserver(value);

		result = DateHourNoTzFilter.pad(value.dayOfMonth, 2) + "/" + DateHourNoTzFilter.pad(value.month, 2) + "/" + value.year;
		return result;
	}
}

@Pipe({
	name: 'datehouronlyhourobserver'
})
export class DateHouronlyhourobserver implements PipeTransform {

	transform(value: HDateHour): string {
		if (HDateHour.isNullOrNullValue(value))
			return "--:--";
		let result: string;

		value = HDateHour.dateHourObserver(value);

		result = DateHourOnlyHourNoTzFilter.getTransform(value);

		return result;
	}
}



