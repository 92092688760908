import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { HLong } from '@shared/src/datatypes/HLong';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { RouteCMRDto } from '@shared/src/dtos/route/cmr/RouteCMRDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';


@Component({
	selector: 'shared-active-slots-item-cmr',
	templateUrl: './shared.active.slots.item.cmr.html',
	styleUrls: ['./shared.active.slots.item.cmr.scss']
})
export class SharedActiveSlotsItemCMR implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	constructor(
		public routeCMRController: RouteCMRController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

	}

	@Input() public readonly: boolean;

	public _routeId: number;
	@Input()
	public get routeId(): number {
		return this._routeId;
	}
	public set routeId(value: number) {
		if (this._routeId === value)
			return;
		this._routeId = value;
		this.loadRouteCMR();
	}

	public _routeCMR: RouteCMRDto;
    set routeCMR(value: RouteCMRDto) {
        if (this._routeCMR === value)
            return;

        this._routeCMR = value;
    }
    get routeCMR(): RouteCMRDto {
        return this._routeCMR;
    }
     
    loadRouteCMR() {
        if(HLong.isNullOrNullLong(this._routeId)){
            this.routeCMR = null;
            return;
        }
        this.routeCMRController.getRouteCMR(this._routeId).subscribe(data => {
            this.routeCMR = data;
        });
    }

	ngOnInit(): void {
	}

	public getRoutePartCMRs(){
		if (this.routeCMR != null && this.routeCMR.routePartCMRs != null)
			return this.routeCMR.routePartCMRs;
		return new Array();
	}

	selectRoutePartCMR(value: RoutePartCMRDto) {
        this.selectedRoutePartCMRId = value != null ? value.routePartCMRId : null;
    }

	public selectedRoutePartCMR: RoutePartCMRDto;
	private _selectedRoutePartCMRId: number;
    public set selectedRoutePartCMRId(value: number) {
        if (this._selectedRoutePartCMRId === value)
            return;

        this._selectedRoutePartCMRId = value;
		this.loadRoutePartCMRData();
    }
    public get selectedRoutePartCMRId(): number {
        return this._selectedRoutePartCMRId;
    }

	public onRoutePartCMRUpdated(value: RoutePartCMRDto) {
		if (this.selectedRoutePartCMR === value)
		return;

		this.selectedRoutePartCMR = value;

		if (this.routeCMR != null && this.routeCMR.routePartCMRs != null && value != null) {
            this.routeCMR.routePartCMRs.forEach((p, i) => {
                if (p.routePartCMRId === value.routePartCMRId)
                    this.routeCMR.routePartCMRs[i] = value;
            });
        }

	}

	public onRefreshRoutePartCMR(value: boolean) {

		if (!value)
		  return;
	
		this.loadRoutePartCMRData();
	  }

	public loadRoutePartCMRData() {
		if (HLong.isNullOrNullLong(this.selectedRoutePartCMRId)) {
		  this.selectedRoutePartCMR = null;
		  return;
		} 
		this.routeCMRController.getRoutePartCMR(this.selectedRoutePartCMRId).subscribe(routePartCMR => {
		  if (routePartCMR) {
			this._routeId = routePartCMR.routeId;
			this.onRoutePartCMRUpdated(routePartCMR);
		  }
		});
		
	  }
}
