
<div class="container-group" *ngIf="shipment">
    <div class="multi-address">
    
        <div class="container-list">
            <div *ngFor="let address of listAddress | sortBy:'executionOrder'" class="address-item">
                <div class="sub-title-information">{{ getResourceType(address) | translate}}</div>
                <div class="text-item">{{address.addressManualAddress.manualAddress.formattedAddress}}</div>
                <div class="text-item">{{address.dateTimeStart | datehournotz }} - {{address.dateTimeEnd | datehournotz }}</div>
                <div class="text-item">{{address.goods}}</div>
                <div class="link" (click)="onDeleteAddress(address)">{{getResourceDeleteAddress(address)| translate }}</div>
            </div>

        </div>
        
          
        <div class="new-address" [ngClass]="{'show': showFormAddress}"  *ngIf="shipmentAddress && shipmentAddress.addressManualAddress">
            <form [formGroup]="rForm" class="form-address">
                <shared-selector-addressmanualaddress-component
                    class="col-x" [addressManualAddress]="shipmentAddress.addressManualAddress"
                    [filterKeys]="ADDRESS_FILTERKEYS"
                    [title]="getResourceConfirm()" (onChanged)="onChangedAddress($event)"
                    [autoselectwhenone]="false">
                </shared-selector-addressmanualaddress-component>
                <div class="inputGran">
                    <datehour-picker class="col-date" name="dateTimeStart" formControlName="dateTimeStart"
                        placeholder="{{'SHIPMENT.MULTI.ADDRESS.START.CAPTION' | translate}}" [readonly]="readonly"
                        [(ngModel)]="dateTimeStart">
                    </datehour-picker>
                    <datehour-picker class="col-date" name="dateTimeEnd" formControlName="dateTimeEnd"
                        placeholder="{{'SHIPMENT.MULTI.ADDRESS.END.CAPTION' | translate}}" [readonly]="readonly"
                        [(ngModel)]="shipmentAddress.dateTimeEnd" [min]="shipmentAddress.dateTimeStart">
                    </datehour-picker>
                    <hourintervals-picker class="col-2intervals" [readonly]="false"
                        placeholder="{{'SHIPMENT.MULTI.ADDRESS.TIMETABLE.CAPTION' | translate}}" name="timetable"
                        formControlName="timetable" [readonly]="readonly"
                        [(ngModel)]="shipmentAddress.isOrigin ? shipmentAddress.addressManualAddress.loadIntervals : shipmentAddress.addressManualAddress.unloadIntervals">
                    </hourintervals-picker>
                </div>
                <input-component class="slim inputGran" [ngClass]="{'disabled': readonly}"
                    placeholder="{{'SHIPMENT.GOODS.CAPTION' | translate}}" name="goods" formControlName="goods"
                    autocomplete="off" [readonly]="readonly" [(ngModel)]="shipmentAddress.goods">
                </input-component>

                <button class="savebutton" (click)="onSaveAddress()">{{'MAINTENANCE.ACCEPT'| translate }}</button>
            </form>
 
        </div>
        <div class="link" [ngClass]="{'left': !(shipmentAddress && shipmentAddress.addressManualAddress)}" (click)="onAddAddress()">{{getResourceAddAddress()| translate }}</div>
           
    </div>

</div>