import { OpcionsMenu } from "../../enums/OpcionsMenu";

export class MenuOptionDto {

    public name: OpcionsMenu;
    public icon: string;
    public showIconAsSkin: boolean;
    public top: boolean;
    public order: number;
    public path: string;
    public subOptions: MenuOptionDto[];
    public showCaption = true;
    public showIcon = true;
    public showLogo = false;
    public classes = "";
    public showPending: boolean = false;
    public pendingCount: number = 0;
    public captionForced: string;

    public userCount: number;
    public userCountKey: string;

    public visible: boolean;

    public static PATHSETTINGSOPTION = "/settings";
    public static settingsOption(): MenuOptionDto {
        let result = new MenuOptionDto();
        result.name = OpcionsMenu.userdata;
        result.order = 999999999;
        result.icon = "fa-cog";
        result.classes = "animated";
        result.path = MenuOptionDto.PATHSETTINGSOPTION;
        result.userCountKey = "userdata"
        return result;
    }

    public static burgerAsideOption(everything: MenuOptionDto[]): MenuOptionDto {
        let result = new MenuOptionDto();
        result.name = OpcionsMenu.burguer;
        result.path = "#";
        result.order = 1;
        result.icon = "fa-bars";
        result.classes = "burger-aside";
        result.subOptions = everything;
        result.top = true;
        result.showIcon = true;
        result.showCaption = false;
        result.userCountKey = "burger"
        return result;
    }
}

export class ProcessedMenuOptionDto {

    public name: OpcionsMenu;
    public icon: string;
    public showIconAsSkin: boolean;
    public top: boolean;
    public order: number;
    public path: string;
    public subOptions: ProcessedMenuOptionDto[];
    public showCaption = true;
    public showIcon = true;
    public showLogo = false;
    public classes = "";
    public showPending = false;
    public style: any = {};
    public internClasses = "";
    public pendingCount = 0;
    public captionForced: string;
    public static build(option: MenuOptionDto): ProcessedMenuOptionDto {
        if (option == null)
            return;

        let result = new ProcessedMenuOptionDto();
        result.name = option.name;
        result.icon = option.icon;
        result.showIconAsSkin = option.showIconAsSkin;
        result.top = option.top;
        result.order = option.order;
        result.path = option.path;
        result.classes = option.classes;
        result.showPending = option.showPending;
        result.pendingCount = option.pendingCount;
        result.captionForced = option.captionForced;
        result.internClasses = option.name;
        result.internClasses += option.showIcon ? " showIcon" : "";
        result.internClasses += option.showLogo ? " showLogo" : "";
        result.internClasses += option.showCaption ? " showCaption" : "";
        result.internClasses += option.showPending ? " showPending" : "";
        result.internClasses += option.pendingCount > 0 ? " showPendingCount" : "";
        result.path = option.path;

        result.subOptions = new Array<ProcessedMenuOptionDto>();
        if (option != null && option.subOptions != null) {
            option.subOptions.forEach(subopt => {
                result.subOptions.push(ProcessedMenuOptionDto.build(subopt));
            });
        }

        return result;
    }
}