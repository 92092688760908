import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { ChatController } from '@shared/src/controllers/chat/chat.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { UserSettingController, UserSettingsEnum } from '@shared/src/controllers/user/usersetting.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { RouteLogDto } from '@shared/src/dtos/route/RouteLogDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { GeneralStatus, OpenChangeStatusDto, PhaseItemMaintenanceCtrlDto } from '@shared/src/public-api';

@Component({
  selector: 'shared-active-route-phase-component',
  templateUrl: './shared.active.route.phase.component.html',
  styleUrls: ['./shared.active.route.phase.component.scss'],
})
export class SharedActiveRoutePhaseComponent implements OnInit {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private phaseController: PhaseController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController, private chatController: ChatController,
    private userSettingController: UserSettingController,
    private slotsController: SlotsController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public fromMaintenance: boolean = false;

  @Input() public readonly: boolean = false;

  public __route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null)
      this.__routeId = 0;
    else
      this.__routeId = value.routeId;
  }
  get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }


  public __phaseId: number;
  @Input()
  set phaseId(value: number) {
    if (this.__phaseId === value)
      return;
    this.__phaseId = value;
    this.loadPhaseData();
  }
  get phaseId(): number {
    return this.__phaseId;
  }

  public loadRouteData() {
    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.loadPhaseData();
      this.routeUpdated.next(this.route);
    });
  }

  public phase: PhaseCardDto;
  public states: StatusTransitionsDto;
  public slotInfo: SlotInfoDto;
  public loadPhaseData() {
    if (this.route == null || this.phaseId == null || this.phaseId == 0) {
      this.phase = null;
      this.states = null;
      this.slotInfo = null;
    }
    else {
      this.route.phases.forEach(p => {
        this.states = null;
        this.slotInfo = null;
        if (p.phaseId == this.__phaseId) {
          this.phase = p;
          this.phaseController.getSlotInfo(p.phaseId).subscribe(data => {
            this.slotInfo = data;
          });
          this.phaseController.getPhaseItemMaintenanceCtrl(this.phase.phaseId).subscribe(data => {
            if (data != null) {
              this.states = data.states;
              this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);
              if (this.options == null)
                this.options = this.buildOptions(data);
            }
          });
        }
      });
    }
  }
  public buildOptions(data: PhaseItemMaintenanceCtrlDto): OptionsStripDto {   
    let result = new OptionsStripDto();
    result.add(OptionDto.build("DRIVER", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DRIVER.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DRIVER.HELP", "fa-user-o", 1, 1, 1, true, false));
    if (this.homeController.hasProduct(HardmanProducts.docks))
      result.add(OptionDto.build("DOCKSSLOTS", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DOCKSSLOTS.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DOCKSSLOTS.HELP", "fa-calendar-check-o", 10, 1, 1, false, false));
    else
      result.add(OptionDto.build("SLOT", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOT.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOT.HELP", "fa-calendar-check-o", 10, 1, 1, false, false));
    result.add(OptionDto.build("INTERNATIONAL", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.INTERNATIONAL.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.INTERNATIONAL.HELP", "", 12, -1, 1, false, false));
    result.add(OptionDto.build("ROUTEHISTORY", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.HELP", "", 15, -1, 1, false, false));
    result.add(OptionDto.build("CHAT", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.HELP", "", 20, -1, 1, false, false));
    if (this.phase != null && this.phase.isGrouped)
      result.add(OptionDto.build("GROUPED", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.HELP", "", 21, -1, 1, false, false));
    result.add(OptionDto.build("OBSERVATIONS", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.TITLE", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.HELPSHORT", "", 30, -1, 1, false, false));

    this.actualOptionId = result.getActualSelected().optionId;
    return result;
  }

  public onRefreshRoute(refreshRoute: boolean) {
    if (refreshRoute)
      this.loadRouteData();
  }

  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;

    this.routeUpdated.next(value);

  }

  public onSelectorChangedState(state: GeneralStatus) {
    if (this.phase != null) {
      this.openRouteController.phaseSetStatus(this.phase.phaseId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
        this.loadPhaseData();
      });
    }
  }

  public opcio: string = "";
  public setOpcio(value: string) {
    let routeId = 0;
    if (this.route != null)
      routeId = this.route.routeId;
    else if (this.phase != null)
      routeId = this.phase.routeId;

    if (value === "AR")
      window.open(this.homeController.createRouteIdLinkToActiveRoutes(routeId), '_blank');
    else if (value === "AS")
      window.open(this.homeController.createRouteIdLinkToActiveSlots(routeId), '_blank');
    else if (this.opcio !== value) {
      this.opcio = value;
      this.userSettingController.setSetting(UserSettingsEnum.DefaultActiveRoutePhaseOption, this.opcio).subscribe(data => {
        if (this.opcio === "activeroutes")
          this.loadRouteData();
      });
    }
  }

  public actions: ActionsStripDto;
  public options: OptionsStripDto;
  public actualOptionId: string = "GENERAL";
  public routeLog: RouteLogDto[];
  public slotLog: SlotLogDto[];
  public forcedOwnerParticipant: ParticipantDto;

  onClickOption(option: OptionDto) {
    this.actualOptionId = option.optionId;
    if (option && option.optionId === "CHAT") {
      this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.routeId, ChatParticipantTypes.Route);
    }
  }
  onClickAction(action: ActionDto) {
    this.phaseController.executePhaseActions(action, this.phase).subscribe(
      d => {
        this.loadPhaseData();
      }
    )
  }
  clickParticipant(participant: ParticipantDto) {
    this.chatController.onClickParticipant(participant);
  }

  ngOnInit(): void {
    this.userSettingController.getSetting(UserSettingsEnum.DefaultActiveRoutePhaseOption).subscribe(data => {
      if (data && data != "")
        this.opcio = data;
      else
        this.setOpcio("activeroutes");
    });

  }
  public haveTags(tags: HTags): boolean {
    if (HTags.isEmpty(tags))
      return false;
    return true;
  }
  public pretty(value) {
    return JSON.stringify(value);
  }
}
