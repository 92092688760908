import { ChangeDetectorRef, Component, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AlertDto } from '@shared/src/dtos/alert/AlertDto';
import { AlertTypes } from '@shared/src/enums/AlertTypes';
import { Subscription, timer as observableTimer } from 'rxjs';
import { MobileAlertService } from '../../../services/mobile.alert.service';

@Component({
  selector: 'mobile-alert',
  templateUrl: './mobile.alert.component.html',
  styleUrls: ['./mobile.alert.component.scss']
})

export class MobileAlertComponent {
  public fullShow: boolean = false;
  constructor(@Inject('AlertService') public alertService: MobileAlertService, public router: Router,
    private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.alertService.getAlert().subscribe((alert: AlertDto) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alertService.alerts = [];
        return;
      }

      // add alert to array
      if (alert.message != null) {
        if (!alert.message.startsWith("NG0100")) {
          this.alertService.push(alert);
          this.activaTimer();
        }
      }
    });

    this.alertService.showAlert$.subscribe(data => {
      this.fullShow = true;
    });
  }

  removeAlert(alert: AlertDto) {
    this.alertService.alerts = this.alertService.alerts.filter(x => x !== alert);
    this.ref.detectChanges();
  }

  getClass(alert: AlertDto) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertTypes.Success:
        return 'alert-success';
      case AlertTypes.SucessNotify:
        return 'alert-success';
      case AlertTypes.Error:
        return 'alert-danger';
      case AlertTypes.Info:
        return 'alert-info';
      case AlertTypes.Warning:
        return 'alert-warning';
    }
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 10000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(MobileAlertComponent.AUTOREFRESHFREQUENCYMILLISECONDS, MobileAlertComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.visible = true;
    this.ref.detectChanges();
    this.timerSubscription = timer.subscribe(t => {
      this.visible = false;
      this.desactivaTimer();
      this.clearAlerts();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }



  getTranslateObject() {
    return { count: this.getCount() };
  }

  getCount() {
    if (this.alertService.alerts == null)
      return 0;
    return this.alertService.alerts.length;
  }

  clearAlerts() {
    this.fullShow = false;
    this.alertService.clear();
    if (this.alertService.alerts == null)
      return;
    this.alertService.alerts = [];
    this.ref.detectChanges();
  }
  closeWindow() {
    this.fullShow = false;
  }

  public visible: boolean = false;
  isVisible() {
    if (this.alertService.alerts == null)
      return;
    return this.alertService.alerts.length > 0 && this.visible;
  }

  // Watch for clicks in our component
  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    // If we don't have an anchor tag, we don't need to do anything.
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    // Prevent page from reloading
    event.preventDefault();
    let target = <HTMLAnchorElement>event.target;
    // Navigate to the path in the link
    this.router.navigate([target.pathname]);
  }
}