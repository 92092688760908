<div class="component component-fullpage scroll">
    <div class="capçalera">
        <shared-address-ambit-selector-component [(value)]="addressAmbit"
            (changeValues)="onChangeAddressAmbitSelector($event)">
        </shared-address-ambit-selector-component>
        <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>


        <div class="period">
            <datehour-picker [(ngModel)]="dateHourStart" placeholder="{{'GATE.LOG.DATEFROM' | translate}}"
                name="dateHourStart" [showLocaleObserver]=false>
            </datehour-picker>
            <datehour-picker [(ngModel)]="dateHourEnd" placeholder="{{'GATE.LOG.DATETO' | translate}}"
                name="dateHourStart" [showLocaleObserver]=false [min]="dateHourStart" [(from)]="dateHourStart">
            </datehour-picker>

            <button class="refresh" (click)="loadData()">{{'GENERAL.REFRESHDATA.CAPTION' | translate}}</button>
        </div>
    </div>


    <div *ngIf="data!=null && data!=undefined" class="content">
        <div class="slots-zone">
            <table *ngIf="haveSlotsProduct">
                <tr>
                    <td class="header">{{'STATUSTABLE.SLOTS.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.SLOTSALLOCATED.HEADING' | translate}}</td>
                </tr>
                <tr>
                    <td class="big value" rowspan="3">{{data.slotsNumber}}</td>
                    <td class="value">{{data.slotsAllocated}}</td>
                </tr>
                <tr>
                    <td class="header">{{'STATUSTABLE.SLOTSNOALLOCATED.HEADING' | translate}}</td>
                </tr>
                <tr>
                    <td class="value">{{data.slotsNotAllocated}}</td>
                </tr>
            </table>

            <table>
                <tr>
                    <td class="header">{{'STATUSTABLE.PHASES.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.PHASES.DONE.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.PHASES.ONTIME.HEADING' | translate}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td class="big value" rowspan="5">{{data.phasesNumber}}</td>
                    <td class="value">{{data.phasesNumberCompleted}}</td>
                    <td class="value">{{data.phasesOnTime}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td class="header">{{'STATUSTABLE.PHASES.ACTIVE.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.PHASES.DELAYED.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.PHASES.DELAYED.SLOTSTARTS.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.PHASES.DELAYED.SLOTENDS.HEADING' | translate}}</td>
                </tr>
                <tr>
                    <td class="value">{{data.phasesNumberActive}}</td>
                    <td class="value">{{data.phasesDelayed}}</td>
                    <td class="value">{{data.phasesDelayedAtSlotStarts}}</td>
                    <td class="value">{{data.phasesDelayedAtSlotEnds}}</td>
                </tr>
                <tr>
                    <td class="header">{{'STATUSTABLE.PHASES.PENDING.HEADING' | translate}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td class="value">{{data.phasesNumberPending}}</td>
                    <td></td>
                    <td></td>

                </tr>
            </table>
            <table>
                <tr>
                    <td></td>
                    <td class="header">{{'STATUSTABLE.DELAY.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.DELAY.SLOTSTARTS.HEADING' | translate}}</td>
                    <td class="header">{{'STATUSTABLE.DELAY.SLOTENDS.HEADING' | translate}}</td>
                </tr>
                <tr>
                    <td class="header">{{'STATUSTABLE.DELAY.TOTAL.PROMPT' | translate}}</td>
                    <td class="value">{{data.delayTime | time}}</td>
                    <td class="value">{{data.delayTimeSlotStarts | time}}</td>
                    <td class="value">{{data.delayTimeSlotEnds | time}}</td>
                </tr>
                <tr>
                    <td class="header">{{'STATUSTABLE.DELAY.AVERAGE.PROMPT' | translate}}</td>
                    <td class="value">{{data.averageDelayTime | time}}</td>
                    <td class="value">{{data.averageDelayTimeSlotStarts | time}}</td>
                    <td class="value">{{data.averageDelayTimeSlotEnds | time}}</td>
                </tr>
                <tr class="important">
                    <td class="header">{{'STATUSTABLE.DELAY.ACTUAL.PROMPT' | translate}}</td>
                    <td class="value">{{data.actualDelayTime | time}}</td>
                    <td class="value">{{data.actualDelayTimeSlotStarts | time}}</td>
                    <td class="value">{{data.actualDelayTimeSlotEnds | time}}</td>
                </tr>

            </table>
        </div>
        <div class="llistat grup">
            <div class="col-x">
                <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">
                <div class="filter-block ml-10">
                    <div class="placeholder"> {{'STATUSTABLE.ROUTESTATUS.HEADING' | translate}} </div>
                    <div class="col-x">
                        <shared-filter class="filter" [applyColor]="true" [filterstrip]="filterRouteStatus"
                            (change)="filterRouteStatusUpdate()">
                        </shared-filter>
                    </div>
                </div>
                <div class="filter-block">
                    <div class="placeholder"> {{'STATUSTABLE.PHASESTATUS.HEADING' | translate}} </div>
                    <div class="col-x">
                        <shared-filter class="filter" [applyColor]="true" [filterstrip]="filterPhaseStatus"
                            (change)="filterPhaseStatusUpdate()">
                        </shared-filter>
                    </div>
                </div>
            </div>
            <table class="llistat hardman">
                <tr>
                    <th class="key">{{'STATUSTABLE.ROUTEKEY.HEADING' | translate}}</th>
                    <th class="startdate">{{'STATUSTABLE.ROUTESTARTDATE.HEADING' | translate}}</th>
                    <th class="enddate">{{'STATUSTABLE.ROUTEENDDATE.HEADING' | translate}}</th>
                    <th class="destinations"></th>
                    <th class="usertags"></th>
                    <th class="status">{{'STATUSTABLE.ROUTESTATUS.HEADING' | translate}}</th>
                    <th *ngIf="data.showUserGeneralStatus" class="userstatus">
                        {{'STATUSTABLE.ROUTEUSERSTATUS.HEADING' | translate}}
                    </th>
                    <th class="phasestatus">{{'STATUSTABLE.PHASESTATUS.HEADING' | translate}}</th>
                    <th class="slotdate">{{'STATUSTABLE.SLOTDATE.HEADING' | translate}}</th>
                    <th class="slotinterval">{{'STATUSTABLE.SLOTINTERVAL.HEADING' | translate}}</th>
                    <th class="carrier"></th>
                    <th class="drivername">{{'STATUSTABLE.DRIVERNAME.HEADING' | translate}}</th>
                    <th class="driverphone">{{'STATUSTABLE.DRIVERPHONE.HEADING' | translate}}</th>
                    <th class="platea">{{'STATUSTABLE.PLATEA.HEADING' | translate}}</th>
                    <th class="plateb">{{'STATUSTABLE.PLATEB.HEADING' | translate}}</th>
                    <th class="platec">{{'STATUSTABLE.PLATEC.HEADING' | translate}}</th>
                    <th class="delayed">{{'STATUSTABLE.PHASES.DELAYED.HEADING' | translate}}</th>
                    <th class="slotstarts">{{'STATUSTABLE.PHASES.DELAYED.SLOTSTARTS.HEADING' | translate}}</th>
                    <th class="slotends">{{'STATUSTABLE.PHASES.DELAYED.SLOTENDS.HEADING' | translate}}</th>
                    <th class="loadunload">{{'STATUSTABLE.PHASES.LOADUNLOADDURATION.HEADING' | translate}}</th>
                    <th class="routeprogress">{{'STATUSTABLE.ROUTEPROGRESS.HEADING' | translate}}</th>
                </tr>
                <tr class="item" *ngFor="let item of data.routePhasesList | filter:searchText | sortBy:'orderBy'"
                    (dblclick)="onDblClick(item)">
                    <td class="key">{{item.routeKey}}</td>
                    <td class="startdate">{{item.routeStartDate | date }}</td>
                    <td class="blank string enddate">{{item.routeEndDate | datehournotz }}</td>
                    <td class="destinations">
                        <shared-tags-strip-component [tagsString]="item.destinations">
                        </shared-tags-strip-component>
                    </td>
                    <td class="usertags">
                        <shared-tags-strip-component [tags]="item.routeUserTags">
                        </shared-tags-strip-component>
                    </td>
                    <td class="status">{{ getResourceItem(item.routeStatus) | translate }}</td>
                    <td *ngIf="data.showUserGeneralStatus" class="userstatus">
                        {{ getResourceItem(item.routeUserStatus) | translate }}
                    </td>
                    <td class="phasestatus">{{ getResourceItem(item.phaseStatus) | translate }}</td>
                    <td class="slotdate">{{item.slotDate | date}} </td>
                    <td class="slotinterval">{{item.slotInterval | interval}} </td>
                    <td class="carrier">
                        <shared-tags-strip-component [uniqueString]="item.partnerCarrierTradeMark">
                        </shared-tags-strip-component>
                    </td>
                    <td class="drivername">{{item.driverName}}</td>
                    <td class="driverphone">{{item.driverPhone}}</td>
                    <td class="platea">{{item.plateA}}</td>
                    <td class="plateb">{{item.plateB}}</td>
                    <td class="platec">{{item.plateC}}</td>
                    <td class="delayed">{{item.delayTime | time}}</td>
                    <td class="slotstarts">{{item.delayTimeSlotStarts | time}}</td>
                    <td class="slotends">{{item.delayTimeSlotEnds | time}}</td>
                    <td class="loadunload">{{item.loadUnloadDuration | time}}</td>
                    <td class="routeprogress">
                        <shared-route-progress-component [phases]="item.phases">
                        </shared-route-progress-component>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>