import { Injectable } from '@angular/core';

import { NotificationTopicSchemaPartnerUserSubMaintenanceController } from './NotificationTopicSchemaPartnerUserSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerClientPreferentUserSubMaintenanceController extends NotificationTopicSchemaPartnerUserSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.USER.CLIENTPREFERENT";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/clientpreferent/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}