
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HDate } from '@shared/src/datatypes/HDate';
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '@shared/src/datatypes/HString';
import { AddressDaySettingResourceDto } from '@shared/src/dtos/address/AddressDaySettingResourceDto';
import { ActionOnAddressCalendarDayDto } from '@shared/src/dtos/address/calendar/ActionOnAddressCalendarDayDto';
import { AddressCalendarDayDto } from '@shared/src/dtos/address/calendar/AddressCalendarDayDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs';
import { AddressDaySettingDto } from '../../dtos/address/AddressDaySettingDto';
import { AddressTimetableIntervalDto } from '../../dtos/address/AddressTimetableIntervalDto';
import { QuickAddressSettingDto } from '../../dtos/address/QuickAddressSettingDto';
import { AddressCalendarDto } from '../../dtos/address/calendar/AddressCalendarDto';
import { BaseController } from '../base.controller';
import { AddressMaintenanceController } from '../maintenance/address/AddressMaintenanceController';
import { AddressDto } from '../maps/AddressDto';
import { AddressManualAddressDto } from '../maps/AddressManualAddressDto';

@Injectable({
    providedIn: 'root',
})
export class AddressController extends BaseController {

    public dayClickFinish$: EventEmitter<string> = new EventEmitter();

    private base = "/address"

    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected addressMaintenanceController: AddressMaintenanceController,
        protected router: Router) {
        super(http, alertService, translateService, loadingService, router);
    }

    createPrevInterval(addressTimetableIntervalId: number) {
        return this.postBusy(this.base + "/createprevinterval/" + addressTimetableIntervalId, "", "createPrevInterval");
    }
    createNextInterval(addressTimetableIntervalId: number) {
        return this.postBusy(this.base + "/createnextinterval/" + addressTimetableIntervalId, "", "createNextInterval");
    }

    public importAddress(body: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.base + "/import", body, "importAddress");
    }

    public getAddressDaySetting(addressId: number, day: HDate): Observable<AddressDaySettingDto> {
        return this.get(this.base + "/" + addressId + "/setting/" + HDate.getDateStringRequest(day));
    }

    public createAddressDaySetting(addressId: number, data: AddressDaySettingDto): Observable<AddressDaySettingDto> {
        return this.post(this.base + "/" + addressId + "/setting", data, "createAddressDaySetting");
    }

    public modifyAddressDaySetting(addressId: number, data: AddressDaySettingDto): Observable<AddressDaySettingDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId, data, "modifyAddressDaySetting");
    }

    public incrementIntervalSlots(addressId: number, data: AddressTimetableIntervalDto): Observable<AddressTimetableIntervalDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/interval/" + data.addressTimetableIntervalId + "/increment", data, "incrementIntervalSlots");
    }

    public modifyAddressTimetableInterval(addressId: number, data: AddressTimetableIntervalDto): Observable<AddressTimetableIntervalDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/interval/" + data.addressTimetableIntervalId, data, "modifyAddressTimetableInterval");
    }

    public deleteAddressTimetableInterval(addressId: number, addressDaySettingId: number, addressTimetableIntervalId: number): Observable<boolean> {
        return this.delete(this.base + "/" + addressId + "/setting/" + addressDaySettingId + "/interval/" + addressTimetableIntervalId)
    }

    public createAddressTimetableIntervalNext(addressId: number, data: AddressTimetableIntervalDto): Observable<AddressTimetableIntervalDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/interval/next", data, "createAddressTimetableIntervalNext");
    }

    public createAddressTimetableIntervalPrevious(addressId: number, data: AddressTimetableIntervalDto): Observable<AddressTimetableIntervalDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/interval/previous", data, "createAddressTimetableIntervalPrevious");
    }

    public decrementIntervalSlots(addressId: number, data: AddressTimetableIntervalDto): Observable<AddressTimetableIntervalDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/interval/" + data.addressTimetableIntervalId + "/decrement", data, "decrementIntervalSlots");
    }

    public cleanDaySetting(addressId: number, addressDaySettingId: number): Observable<AddressDaySettingDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + addressDaySettingId + "/clean", null, "cleanDaySetting");
    }

    public createQuickSetting(addressId: number, addressDaySettingId: number, data: QuickAddressSettingDto): Observable<QuickAddressSettingDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + addressDaySettingId + "/create-quick-setting", data, "createQuickSetting");
    }
    public executeAction(addressId: number, addressDaySettingId: number, actionId: String, additionalData: string): Observable<any> {
        return this.post(this.base + "/" + addressId + "/addressDaySettingId/" + addressDaySettingId + "/actionId/" + actionId + "/", additionalData, "executeAction");
    }
    public saveAdditionalTextInfo(addressId: number, addressDaySettingId: number, additionalTextInfo: string) {
        return this.post(this.base + "/" + addressId + "/addressDaySettingId/" + addressDaySettingId + "/additionaltextinfo", additionalTextInfo, "saveAdditionalTextInfo");
    }

    public getQuickSetting(addressId: number): Observable<Array<QuickAddressSettingDto>> {
        return this.get(this.base + "/" + addressId + "/quick-setting");
    }
    public getQuickSettingDetail(addressId: number, quickAddressSettingId: number): Observable<QuickAddressSettingDto> {
        return this.get(this.base + "/" + addressId + "/quick-setting/" + quickAddressSettingId);
    }


    public modifyQuickSetting(addressId: number, data: QuickAddressSettingDto): Observable<QuickAddressSettingDto> {
        return this.post(this.base + "/" + addressId + "/quick-setting/" + data.quickAddressSettingId, data, "modifyQuickSetting");
    }

    public deleteQuickSetting(addressId: number, quickAddressSettingId: number): Observable<boolean> {
        return this.delete(this.base + "/" + addressId + "/quick-setting/" + quickAddressSettingId);
    }
    public getJsonExpression(addressId: number, quickAddressSettingId: number): Observable<string> {
        return this.get(this.base + "/" + addressId + "/quick-setting/" + quickAddressSettingId + "/json");
    }
    public postJsonExpression(addressId: number, quickAddressSettingId: number, json: string): Observable<boolean> {
        return this.post(this.base + "/" + addressId + "/quick-setting/" + quickAddressSettingId + "/json", json, "postJsonExpression");
    }

    public getAddressCalendarByAddressInForce(addresscalendar: AddressCalendarDto): Observable<AddressCalendarDto> {
        return super.post(this.base + "/" + addresscalendar.addressId + "/calendar", addresscalendar, "getAddressCalendarByAddressInForce");
    }

    public actionOnAddressCalendarDay(addressId: number, data: ActionOnAddressCalendarDayDto): Observable<AddressCalendarDayDto> {
        return this.internal_post(this.base + "/" + addressId + "/calendar/action", data, "actionOnAddressCalendarDay");
    }

    public getOwn(): Observable<Array<AddressDto>> {
        return this.get(this.base + "/own");
    }

    public getByAddressId(addressId: number): Observable<AddressDto> {
        return this.get(this.base + "/" + addressId);
    }

    public getFormatAddress(address: AddressDto): Observable<AddressDto> {
        return this.post(this.base + "/formatAddress", address, "getFormatAddress");
    }

    public hasAddressDefined(value: string): Observable<boolean> {
        return this.get(this.base + "/hasaddressdefined/" + value);
    }

    public getAddressDaySettingActions(addressId: number): Observable<ActionsStripDto> {
        return this.get(this.base + "/addressdaysettingactions/" + addressId);
    }

    public getAddressDaySettingOptions(addressId: number): Observable<OptionsStripDto> {
        return this.get(this.base + "/addressdaysettingoptions/" + addressId);
    }

    /**
     * RECURSOS
     */
    public createPrevResource(addressDaySettingResourceId: number) {
        return this.postBusy(this.base + "/createprevresource/" + addressDaySettingResourceId, "", "createPrevResource");
    }

    public createNextResource(addressDaySettingResourceId: number) {
        return this.postBusy(this.base + "/createnextresource/" + addressDaySettingResourceId, "", "createNextResource");
    }

    public modifyAddressDaySettingResource(addressId: number, data: AddressDaySettingResourceDto): Observable<AddressDaySettingResourceDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/resource/" + data.addressDaySettingResourceId, data, "modifyAddressDaySettingResource");
    }

    public deleteAddressDaySettingResource(addressId: number, addressDaySettingId: number, addressDaySettingResourceId: number): Observable<boolean> {
        return this.delete(this.base + "/" + addressId + "/setting/" + addressDaySettingId + "/resource/" + addressDaySettingResourceId)
    }

    public createAddressDaySettingResourceNext(addressId: number, data: AddressDaySettingResourceDto): Observable<AddressDaySettingResourceDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/resource/next", data, "createAddressDaySettingResourceNext");
    }

    public createAddressDaySettingResourcePrevious(addressId: number, data: AddressDaySettingResourceDto): Observable<AddressDaySettingResourceDto> {
        return this.post(this.base + "/" + addressId + "/setting/" + data.addressDaySettingId + "/resource/previous", data, "createAddressDaySettingResourcePrevious");
    }



    /***
     * Valida si una addresds addressManual
     */
    public static estaInformada(value: AddressManualAddressDto): boolean {
        if (value != null) {
            if (!HLong.isNullOrNullLong(value.addressId))
                return true;
            if (value.manualAddress && !HString.isNullOrNullString(value.manualAddress.formattedAddress))
                return true;
            if (value.manualAddress && !HString.isNullOrNullString(value.manualAddress.addressLine1))
                return true;
        }
        return false;
    }
}