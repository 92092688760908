import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';


@Injectable()
export class NotificationTopicSchemaPartnerFreightForwarderSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.FREIGHTFORWARDER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/freight-forwarder/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}