<ng-container *ngIf="phase != null">
    <div class="title">
        {{'SHARED.PHASE.CHANGE.STRONGADDRESS.TITLE' | translate}}
    </div>
    <div class="block-help">
        {{'SHARED.PHASE.CHANGE.STRONGADDRESS.HELP' | translate}}
    </div>

    <div class="block info">
        <div class="address">
            <div class="addressKey" *ngIf="phase.addressSkin && phase.addressSkin.length > 0">
                {{phase.addressSkin}}<shared-copyclipboard [value]="phase.addressSkin"></shared-copyclipboard>
            </div>
            <div class="addressKey"
                *ngIf="phase.showStrongAddress && phase.strongAddressSkin && phase.strongAddressSkin.length > 0">
                {{phase.strongAddressSkin}}<shared-copyclipboard
                    [value]="phase.strongAddressSkin"></shared-copyclipboard>
            </div>
            <div class="raddress">{{phase.addressDescriptionShort}}<shared-copyclipboard
                    [value]="phase.addressDescriptionShort"></shared-copyclipboard>
            </div>
            <div class="formattedAddress">{{phase.formattedAddress}}<shared-copyclipboard
                    [value]="phase.formattedAddress"></shared-copyclipboard></div>
        </div>
    </div>

    <div class="block info detail">

        <entity-selector class="selector-address" [(ngModel)]="addressId" [entitySelector]="'Address'"
            placeholder="{{'SHARED.PHASE.CHANGE.STRONGADDRESS.ADDRESS.CAPTION' | translate}}"
            name="addressId" [fixedFilterKeys]="ADDRESS_FILTERKEYS">
        </entity-selector>

        
        <div class="buttons">
            <button class="action" (click)="setForcedStrongAddress()" [disabled]="addressId == null || addressId == 0">{{ 'SHARED.PHASE.CHANGE.STRONGADDRESS.BUTTON.FORCED' | translate }}</button>
            <button class="action right" (click)="setOriginStrongAddress()">{{ 'SHARED.PHASE.CHANGE.STRONGADDRESS.BUTTON.ORIGIN' | translate }}</button>
        </div>
    </div>
</ng-container>