<div class="container">
    <shared-user-info-card [value]="value"></shared-user-info-card>

    <div class="baroptions" *ngIf="options && chat">
        <options class="slim" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>
    <shared-chat *ngIf="actualOptionId==='CHAT' && chat" class="chats-body-zonachat chat-without-header"
        [value]="chat" [ownerUserId]="ownerUserId" [ownerUserType]="ownerUserType">
    </shared-chat>

</div>