import { HDate, HHourInterval, PhaseSlotLogTypes, RouteCardDto } from '@shared/src/public-api';
import { PhaseSlotDto } from '../../slot/PhaseSlotDto';
import { AssignedSlotDto } from './AssignedSlotDto';

export class SlotInfoDto {

    public slotId: number;
    public tenantId: number;
    public onDate: HDate;
    public interval: HHourInterval;
    public intervalDock: HHourInterval;
    public intervalLift: HHourInterval;
    public isBlockedBookSlotSystem: boolean;
    public isBlockedEnterSystem: boolean;
    public isBlockedExitSystem: boolean;
    public isSlotBlocked: boolean;
    public isSlotBlockedExternal: boolean;
    public incompatibleSlot: boolean;
    public addressTimetableIntervalId: number;

    public pendingSlotQty: number = 0;
    public assignedSlots: number = 0;

    public noShow: boolean = false;
    public isLateAtSlot: boolean = false;

    public slotStatus: PhaseSlotLogTypes;

    public requiresSlot: boolean = false;

    static buildFromRoutePhaseSlot(value: PhaseSlotDto): SlotInfoDto {
        let result = new SlotInfoDto();
        result.incompatibleSlot = value.incompatibleSlot;
        result.interval = value.intervalSlot;
        result.intervalDock = value.intervalSlotDock;
        result.intervalLift = value.intervalSlotLift;
        result.isBlockedBookSlotSystem = value.isBlockedBookSlotSystem;
        result.isBlockedEnterSystem = value.isBlockedEnterSystem;
        result.isBlockedExitSystem = value.isBlockedExitSystem;
        result.isSlotBlocked = value.isSlotBlocked;
        result.isSlotBlockedExternal = value.isSlotBlockedExternal;
        result.onDate = value.onDateSlot;
        result.slotId = value.slotId;
        result.isLateAtSlot = value.isLateAtSlot;
        return result;
    }

    static buildFromPhaseSlot(value: PhaseSlotDto): SlotInfoDto {
        let result = new SlotInfoDto();
        result.incompatibleSlot = value.incompatibleSlot;
        result.interval = value.intervalSlot;
        result.intervalDock = value.intervalSlotDock;
        result.intervalLift = value.intervalSlotLift;
        result.isBlockedBookSlotSystem = value.isBlockedBookSlotSystem;
        result.isBlockedEnterSystem = value.isBlockedEnterSystem;
        result.isBlockedExitSystem = value.isBlockedExitSystem;
        result.isSlotBlocked = value.isSlotBlocked;
        result.isSlotBlockedExternal = value.isSlotBlockedExternal;
        result.onDate = value.onDateSlot;
        result.slotId = value.slotId;
        result.slotStatus = value.slotStatus;
        result.isLateAtSlot = value.isLateAtSlot;
        return result;
    }

    static buildFromRouteCardDto(value: RouteCardDto): SlotInfoDto {
        let result = new SlotInfoDto();
        result.incompatibleSlot = value.incompatibleSlot;
        result.isBlockedBookSlotSystem = value.isBlockedBookSlotSystem;
        result.isBlockedEnterSystem = value.isBlockedEnterSystem;
        result.isBlockedExitSystem = value.isBlockedExitSystem;
        result.isSlotBlocked = value.isSlotBlocked;
        result.isSlotBlockedExternal = value.isSlotBlockedExternal;
        result.interval = AssignedSlotDto.getHHourInterval(value);
        result.intervalDock = AssignedSlotDto.getHHourIntervalDock(value);
        result.intervalLift = AssignedSlotDto.getHHourIntervalLift(value);
        result.onDate = AssignedSlotDto.getDate(value);
        result.slotId = AssignedSlotDto.getSlotId(value);
        result.slotStatus = AssignedSlotDto.getSlotStatus(value);

        result.pendingSlotQty = value.pendingSlotQty;
        result.assignedSlots = 0;
        if (value != null)
            if (value.assignedSlots != null && value.assignedSlots != undefined)
                result.assignedSlots = value.assignedSlots.length;

        if (result.pendingSlotQty == 0 && result.assignedSlots == 0)
            result.noShow = true;
        return result;
    }
}