import { Component, Input, OnInit, Inject } from "@angular/core";
import { PhaseController } from "@shared/src/controllers/route/phase/phase.controller";
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { Router } from "@angular/router";
import { PhaseCardDto, RouteCardDto, RouteController, ShipmentCardDto, ShipmentController } from "@shared/src/public-api";
import { StatusTimeDto } from "@shared/src/controllers/route/dto/StatusTimeDto";

@Component({
    selector: "shared-statustime-component",
    templateUrl: "./shared.statustime.component.html",
    styleUrls: ["./shared.statustime.component.scss"],
})
export class SharedStatusTimeComponent implements OnInit {
    constructor(
        public router: Router,
        public routeController: RouteController,
        public phaseController: PhaseController,
        public shipmentController: ShipmentController,
        @Inject("SecurityController")
        public securityController: SecurityController
    ) {
    }


    private _phase: PhaseCardDto;
    public get phase(): PhaseCardDto {
        return this._phase;
    }
    @Input() public set phase(value: PhaseCardDto) {
        this._phase = value;
        if (this._phase != null) {
            this.phaseController.getStatusTimeById(this._phase.phaseId).subscribe(x => {
                this.wellcome(x);
            });
        }
    }

    private _route: RouteCardDto;
    public get route(): RouteCardDto {
        return this._route;
    }
    @Input() public set route(value: RouteCardDto) {
        this._route = value;
        if (this._route != null) {
            this.routeController.getStatusTimeById(this._route.routeId).subscribe(x => {
                this.wellcome(x);
            });
        }
    }

    private _shipment: ShipmentCardDto;
    public get shipment(): ShipmentCardDto {
        return this._shipment;
    }
    @Input() public set shipment(value: ShipmentCardDto) {
        this._shipment = value;
        if (this._shipment != null) {
            this.shipmentController.getStatusTimeById(this._shipment.shipmentId).subscribe(x => {
                this.wellcome(x);
            });
        }
    }

    public data: StatusTimeDto;
    public wellcome(statustime: StatusTimeDto) {
        this.data = statustime;
    }

    ngOnInit(): void { }

    pretty(item: any): string {
        return JSON.stringify(item);
    }
}
