<!-- maps-search.component.html!-->
<div [formGroup]="rFormInput">
    <mat-form-field>
        <input matInput placeholder="State" [matAutocomplete]="auto" #search [id]="identifier" [(ngModel)]="resultLabel"
            [readonly]="readonly" [type]="type" [name]="name" formControlName="dtpvalidator" [placeholder]="placeholder"
            autocomplete="autocomplete">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='triggerData($event.option.value)'>
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                <span>{{option.description}}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
    </helppoint>
</div>