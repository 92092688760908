import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { MobileToolBarComponent } from './components/toolbar/mobile.toolbar.component';
import { TranslateService } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BackButtonComponent,
    MobileToolBarComponent
  ],
  imports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    TranslateService,
  ],
  exports: [
    BackButtonComponent,
    MobileToolBarComponent
  ]
})
export class MobileComponentsAppModule {

}
