import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { RegisterUserInfoPageComponent, SecurityController, SocketController } from '@shared/src/public-api';
import { PushService } from '../services/push.service';
import { MobileHomeController } from './controllers/home/mobile-home.controller';
import { NavigationEnd, Router } from '@angular/router';
import { MobileSecurityController } from './controllers/security/mobile-security.controller';
import { Capacitor } from '@capacitor/core';
import { UpdatesService } from 'src/services/updates.service';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public generalClass = '';
  public lang = 'en';

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    @Inject('SecurityController') private securityController: MobileSecurityController,
    @Inject('HomeController') public mobileHomeController: MobileHomeController,
    public router: Router,
    private pushService: PushService,
    private zone: NgZone,
    private socketController: SocketController,
    private updateService: UpdatesService,
    protected navController: NavController, protected deeplinks: Deeplinks
  ) {
    this.initializeApp();
    if (this.securityController.refreshLanguage) {
      this.securityController.refreshLanguage();
    }
    if (this.securityController.refreshTimeZone) {
      this.securityController.refreshTimeZone();
    }


    // So if you want track a click on your home component for example,
    // all you need to do is to inject the GoogleAnalyticsEventsService
    // and call the emitEvent() method.
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // (<any>window).ga('send', 'pageview');
      }
    });

    this.securityController.languageChanged$.subscribe(language => {
      this.calculateGeneralClass();
    });
    this.securityController.timezoneChanged$.subscribe(timezone => {
      this.calculateGeneralClass();
    });
    this.securityController.loginStatusChanged$.subscribe(logged => {
      this.calculateGeneralClass();
    });
    this.mobileHomeController.homeChanged$.subscribe(home => {
      this.calculateGeneralClass();
    });
    /* this.mobileSecurityController.locationError$.subscribe(location => {
      this.calculateGeneralClass();
    }) */

    if (Capacitor.getPlatform() !== 'web') {

      this.platform.ready().then(async () => {

        console.log('[AppComponent] this.platform.ready');

        this.updateService.checkForUpdate().then(e => {
          console.log('[AppComponent] updated', this.pretty(e));
          SplashScreen.hide();
          this.statusBar.styleDefault();

          this.deeplinks.routeWithNavController(this.navController,
            {
              '/register': RegisterUserInfoPageComponent,
              '/register/:join/:linkid': RegisterUserInfoPageComponent
            }).subscribe((match) => {
              // match.$route - the route we matched, which is the matched entry from the arguments to route()
              // match.$args - the args passed in the link
              // match.$link - the full link data
              console.log('Successfully matched route', match);
            },
              (nomatch) => {
                // nomatch.$link - the full link data
                console.error('Got a deeplink that didn\'t match', nomatch);
              });

        });

      });
    }

    this.socketController.notificationReloadHome$.subscribe(value => {
      this.mobileHomeController.home();
    });

    this.socketController.notificationReloadMenu$.subscribe(value => {
      this.mobileHomeController.menu();
    });
  }
  initializeApp() {
    App.addListener('appUrlOpen', (data: any) => {
      console.log('appUrlOpen');
      this.zone.run(() => {
        const slug = "/" + data.url.split("://").pop();
        console.log('url:' + data.url + "  slug:" + slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
  ngOnInit() {

    this.pushService.initPush();

    if (this.securityController && this.securityController.loginStatusChanged$) {
      this.securityController.loginStatusChanged$.subscribe(data => {
        this.calculateGeneralClass();
        this.home();
      });
    }
    if (this.mobileHomeController && this.mobileHomeController.homeChanged$) {
      this.mobileHomeController.homeChanged$.subscribe(data => {
        this.calculateGeneralClass();

        //Estem d'acord que estem a l'init i que tenim per primera vegada el home?
        if (this.mobileHomeController != null && this.mobileHomeController.HOME != null && this.mobileHomeController.HOME.menu != null &&
          this.mobileHomeController.HOME.menu.defaultHome != null && this.mobileHomeController.HOME.menu.defaultHome != "") {
          //hauria de fer un routing
          this.router.navigate(["." + this.mobileHomeController.HOME.menu.defaultHome], {});
        }
      });
    }

    if (this.securityController && this.securityController.userDataChanged$) {
      this.securityController.userDataChanged$.subscribe(data => {
        this.calculateGeneralClass();
      });
    }

    if (this.securityController && this.securityController.languageChanged$) {
      this.securityController.languageChanged$.subscribe(data => {
        this.calculateGeneralClass();
      });
    }

    if (this.securityController && this.securityController.timezoneChanged$) {
      this.securityController.timezoneChanged$.subscribe(data => {
        this.calculateGeneralClass();
      });
    }

    //TODO: event de la localització
    /* if (this.securityController && this.securityController.locationError$) {
      this.securityController.locationError$.subscribe(data => {
        this.calculateGeneralClass();
      })
    } */

    this.calculateGeneralClass();

    this.socketController.start();
  }

  public home() {
    if (this.mobileHomeController && this.mobileHomeController.home) {
      this.mobileHomeController.home();
    }
  }

  ngOnDestroy() {
    if (this.securityController && this.securityController.loginStatusChanged$) {
      this.securityController.loginStatusChanged$.unsubscribe();
    }
  }

  isUserLogged() {
    let logged = false;
    if (this.securityController.isLogged) {
      this.securityController.isLogged().subscribe(value => {
        logged = value;
      });
    }
    return logged;
  }

  isRegisterPending(): boolean {
    if (this.mobileHomeController.HOME != null) {
      return this.mobileHomeController.HOME.registerPending;
    }
    return true;
  }

  isNotSettingsPage(): boolean {
    return this.router.url !== '/settings';
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }

  protected calculateGeneralClass() {
    this.generalClass = 'mobile ';
    this.generalClass += this.mobileHomeController.getOS();
    this.generalClass += " " + this.mobileHomeController.getPlatform();
    SecurityController.GENERAL_CLASS = this.generalClass;
    this.securityController.isLogged().subscribe(value => {
      if (value) {
        this.generalClass += ' logged';
        this.generalClass += ' lang-' + SecurityController.LANGUAGE;
        this.generalClass += SecurityController.HARDMANENTORNGENERALCLASS;
        SecurityController.GENERAL_CLASS = this.generalClass;
      }
      this.securityController.isHardManUser().subscribe(value => {
        if (value)
          this.generalClass += ' hardman-user';
        SecurityController.GENERAL_CLASS = this.generalClass;
      });
      if (this.mobileHomeController.HOME != null && this.mobileHomeController.HOME.products != null) {
        this.mobileHomeController.HOME.products.forEach(product => {
          if (product.isActive) {
            this.generalClass += ' product-' + product.hardmanProduct;
            SecurityController.GENERAL_CLASS = this.generalClass;
          }
        });
      }
    });
  }

  // initBackgroundGeolocation() {
  //
  //       console.log('[LOCATION] initBackgroundGeolocation');
  //
  //       let config: BackgroundGeolocationConfig = {
  //         stationaryRadius: 50,
  //         distanceFilter: 50,
  //         notificationTitle: 'Background tracking',
  //         notificationText: 'enabled',
  //         debug: true,
  //         interval: 10000,
  //         fastestInterval: 5000,
  //         activitiesInterval: 10000,
  //         stopOnTerminate: false
  //       };
  //
  //       this.backgroundGeolocation.configure(config).then((location: BackgroundGeolocationResponse)=>{
  //
  //         this.sendLocation(location);
  //
  //       }, (error) => {
  //           alert(error);
  //       });
  //
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.location).subscribe((location: BackgroundGeolocationResponse) => {
  //
  //           this.sendLocation(location);
  //
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.stationary).subscribe(
  //       (stationaryLocation: BackgroundGeolocationResponse) => {
  //         console.log('[LOCATION]: [INFO] BackgroundGeolocation stationary:', this.pretty(stationaryLocation));
  //         this.sendLocation(stationaryLocation);
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.error).subscribe((error)=> {
  //         console.log('[LOCATION]: [ERROR] BackgroundGeolocation error:', this.pretty(error));
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.start).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] BackgroundGeolocation service has been started');
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.stop).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] BackgroundGeolocation service has been stopped');
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.authorization).subscribe((status)=> {
  //         console.log('[LOCATION]: [INFO] BackgroundGeolocation authorization status: ' + this.pretty(status));
  //
  //         this.backgroundGeolocation.checkStatus().then((checkstatus) => {
  //           if (!checkstatus.authorization) {
  //             // we need to set delay or otherwise alert may not be shown
  //             setTimeout(function() {
  //               var showSettings = confirm('App requires location tracking permission. Would you like to open app settings?');
  //               if (showSettings) {
  //                 return this.backgroundGeolocation.showAppSettings();
  //               }
  //             }, 1000);
  //           }
  //         });
  //
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.background).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] App is in background');
  //         // you can also reconfigure service (changes will be applied immediately)
  //         this.backgroundGeolocation.configure({ debug: true });
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.foreground).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] App is in foreground');
  //         this.backgroundGeolocation.configure({ debug: false });
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.abort_requested).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] Server responded with 285 Updates Not Required');
  //
  //         // Here we can decide whether we want stop the updates or not.
  //         // If you've configured the server to return 285, then it means the server does not require further update.
  //         // So the normal thing to do here would be to `BackgroundGeolocation.stop()`.
  //         // But you might be counting on it to receive location updates in the UI, so you could just reconfigure and set `url` to null.
  //       });
  //
  //       this.backgroundGeolocation.on(BackgroundGeolocationEvents.http_authorization).subscribe(()=> {
  //         console.log('[LOCATION]: [INFO] App needs to authorize the http requests');
  //       });
  //
  //       this.backgroundGeolocation.checkStatus().then((status) => {
  //
  //           console.log('[LOCATION]: [INFO] BackgroundGeolocation service is running', status.isRunning);
  //           console.log('[LOCATION]: [INFO] BackgroundGeolocation services enabled', status.locationServicesEnabled);
  //           console.log('[LOCATION]: [INFO] BackgroundGeolocation auth status: ' + status.authorization);
  //
  //         //Si no està en marxa, ho configurem tot i iniciem
  //         if (!status.isRunning) {
  //         // you don't need to check status before start (this is just the example)
  //           this.backgroundGeolocation.start(); //triggers start on start event
  //         }
  //       });
  //
  //       // you can also just start without checking for status
  //       // BackgroundGeolocation.start();
  //
  //       // Don't forget to remove listeners at some point!
  //       // BackgroundGeolocation.removeAllListeners();
  // }

  // sendLocation(location: BackgroundGeolocationResponse){
  //     console.log('[LOCATION] BackgroundGeolocationEvents location:', this.pretty(location));
  //
  //     // handle your locations here
  //     // to perform long running operation on iOS
  //     // you need to create background task
  //     if (Capacitor.platform !== 'ios'){
  //       if (location)
  //           this.mobileGeolocationController.saveCoordenades(GeoLocationDto.buildBackground(location));
  //     }else{
  //
  //       this.backgroundGeolocation.startTask().then((taskKey) => {
  //
  //       console.log('[LOCATION] [startTask]:', this.pretty(taskKey));
  //
  //         if (location)
  //             this.mobileGeolocationController.saveCoordenades(GeoLocationDto.buildBackground(location));
  //         // execute long running task
  //         // eg. ajax post location
  //         // IMPORTANT: task has to be ended by endTask
  //         this.backgroundGeolocation.endTask(taskKey);
  //       });
  //     }
  //  }

  protected calculateLang() {
    this.lang = SecurityController.LANGUAGE;
  }
}
