<div class="component component-fullpage">
    <div *ngIf="route" class="container" #container
        [ngClass]="{'NOdataOrigenVisible':!modelFuncionament.dataOrigenVisible}">
        <div *ngIf="route.stepNumber==1 || route.stepNumber==2" class="divSeccio">
            <div *ngIf="route.type != 'ReverseLogistics'">
                <!-- Això sembla que no tingui sentit però es pasa d'estat al seleccionar una ruta i no al picar sobre el botó NExt-->
                <div class="title">{{ SELECT_ROUTE_TO_RETURN_TITLE | translate }}</div>
                <entity-selector class="col-x" [(ngModel)]="route.basedOnRouteId" [entitySelector]="'Route'"
                    [fixedFilterKeys]="ROUTE_FILTERKEYS" [readonly]="iReadonly" name="routeId"
                    [showgotomaintenance]="false" (valuechangefunction)="next(false)">
                </entity-selector>

                <div class="help-box" *ngIf="route && route.originalShipments && route.originalShipments.length == 0">{{
                    'ROUTE.RETURN.NOAVAILABLESHIPMENTS.CAPTION' |
                    translate }}</div>
                <div class="help-box" *ngIf="route && route.originalShipments && route.originalShipments.length > 0">{{
                    'ROUTE.RETURN.SELECTSHIPMENTS.CAPTION' |
                    translate }}</div>

                <ng-container *ngIf="route.stepNumber==2">
                    <shared-shipment-item *ngFor="let shipment of route.originalShipments" [shipment]="shipment"
                        (click)="selectUnselectShipment(shipment)"
                        [ngClass]="{'selected': isShipmentSelected(shipment)}">
                    </shared-shipment-item>
                </ng-container>
                <div class="noshipments"
                    *ngIf="route.stepNumber==2 && route && route.originalShipments && route.originalShipments.length==0">
                    {{'SHARED.ROUTE.RETURN.BASE.CREATE.NOSHIPMENTS.CAPTION' | translate }}

                </div>
            </div>
            <div class="divSeccio reverselogistics" *ngIf="route.type == 'ReverseLogistics'">
                <!-- Escullim si ens han de venir a buscar algo o si anem a buscar algo-->

                <div class="questionreverselogistics">
                    <span class="question">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.REVERSELOGISTICS.CAPTION' | translate
                        }}</span>
                    <i class="fa fa-info-circle" aria-hidden="true"
                        matTooltip="{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.REVERSELOGISTICS.HELP' | translate }}"></i>
                </div>
                <div class="botons-reverselogistics">
                    <button class="slim displacement destination" (click)="reverseLogisticsDestination()"
                        [ngClass]="route.originDestinationClass">{{
                        'SHARED.ROUTE.RETURN.BASE.CREATE.REVERSELOGISTICSDESTINATION.CAPTION' | translate }}</button>

                    <button class="slim displacement origin" (click)="reverseLogisticsOrigin()"
                        [ngClass]="route.originDestinationClass">{{
                        'SHARED.ROUTE.RETURN.BASE.CREATE.REVERSELOGISTICSORIGIN.CAPTION' | translate }}</button>
                </div>
            </div>
        </div>
        <div *ngIf="route.stepNumber>=3" [formGroup]="rFormShipment">
            <!-- Segona pantalla del wizard-->
            <!-- Això està fet per només demanar les dades de la ruta la primera vegada, shipment 0-->
            <div class="divSeccio">
                <input-component class="slim col-x" [ngClass]="{'disabled': iReadonly}" name="routeKey"
                    autocomplete="off" [(ngModel)]="route.routeKey" formControlName="routeKey"
                    placeholder="{{ 'ROUTE.ROUTEKEY.CAPTION' | translate }}">
                </input-component>

                <input-component class="slim col-x" [ngClass]="{'disabled': iReadonly}" name="routeTitle"
                    autocomplete="off" [(ngModel)]="route.routeTitle" formControlName="routeTitle"
                    placeholder="{{ 'ROUTE.ROUTETITLE.CAPTION' | translate }}">
                </input-component>
            </div>

            <div class="divSeccio">
                <div *ngIf="modelFuncionament.reverseLogisticsPartnersVisible">
                    <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.REVERSELOGISTICSPARTNER.CAPTION' | translate
                        }}</div>
                    <entity-selector id="routeReverseLogisticsPartnerId" class="ultraslim col-x"
                        [entitySelector]="'Partner'" name="routeReverseLogisticsPartnerId"
                        formControlName="routeReverseLogisticsPartnerId" [readonly]="iReadonly"
                        [fixedFilterKeys]="REVERSELOGISTICSPARTNER_FILTERKEY"
                        [(ngModel)]="route.partnerReverseLogisticsId"
                        (valuechangefunction)="refreshDefaultDataByPartnerLogistics()" [showgotomaintenance]="false">
                    </entity-selector>
                </div>
            </div>

            <div class="divSeccio">
                <div *ngIf="modelFuncionament.proveidorOrigenVisible">
                    <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.PROVIDER.CAPTION' | translate }}</div>
                    <entity-selector class="ultraslim col-x" [(ngModel)]="actualShipment.partnerProviderId"
                        [entitySelector]="'Partner'" name="partnerProviderId"
                        (valuechangefunction)="setAddressFilterKeys()" formControlName="shipmentPartnerProviderId"
                        [showgotomaintenance]="false" [fixedFilterKeys]="PROVIDER_FILTERKEY"
                        [readonly]="modelFuncionament.adrecaOrigenReadOnly">
                    </entity-selector>
                </div>
                <div *ngIf="modelFuncionament.clientOrigenVisible">
                    <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.CLIENT.CAPTION' | translate }}</div>
                    <entity-selector class="ultraslim col-x" [(ngModel)]="actualShipment.partnerClientId"
                        [entitySelector]="'Partner'" name="partnerClientId"
                        (valuechangefunction)="setAddressFilterKeys()" formControlName="shipmentPartnerClientId"
                        [showgotomaintenance]="false" [readonly]="modelFuncionament.adrecaOrigenReadOnly"
                        [fixedFilterKeys]="CUSTOMER_FILTERKEY">
                    </entity-selector>
                </div>
            </div>

            <!-- Adreça Origen-->
            <div class="divSeccio">
                <shared-selector-addressmanualaddress-component *ngIf="modelFuncionament.adrecaOrigenVisible"
                    [required]="modelFuncionament.dataOrigenRequerida" class="col-x"
                    [addressManualAddress]="actualShipment.originAddressManualAddress"
                    [readonly]="modelFuncionament.adrecaOrigenReadOnly" [filterKeys]="ORIGIN_ADDRESS_FILTERKEYS"
                    [title]="'SHIPMENT.ORIGINADDRESS.CAPTION'" (onChanged)="onChangedOriginAddress($event)"
                    [autoselectwhenone]="true" [forcedViewType]="_forcedViewTypeAddressOrigin">
                </shared-selector-addressmanualaddress-component>
                <!-- Hora d'origen Origen-->
                <div class="col-x" *ngIf="modelFuncionament.dataOrigenVisible">
                    <date-picker class="col-date" name="originDateStart" formControlName="originDateStart"
                        placeholder="{{'SHIPMENT.WIZARD.ORIGINSTART.CAPTION' | translate}}" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.originDateStart" (beforeChange)="setOriginDateStart($event)"
                        (valueChanged)="setOriginDateStartChanged($event)">
                    </date-picker>
                    <date-picker class="col-date" name="originDateEnds" formControlName="originDateEnds"
                        placeholder="{{'SHIPMENT.WIZARD.ORIGINEND.CAPTION' | translate}}" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.originDateEnds" [min]="actualShipment.originDateStart">
                    </date-picker>
                    <hourintervals-picker class="col-2intervals" [readonly]="false"
                        placeholder="{{'SHIPMENT.TIMETABLE.CAPTION' | translate}}" name="originTimetable"
                        formControlName="originTimetable" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.originAddressManualAddress.loadIntervals">
                    </hourintervals-picker>
                </div>
            </div>

            <div class="divSeccio">
                <div *ngIf="modelFuncionament.proveidorDestiVisible">
                    <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.PROVIDER.CAPTION' | translate }}</div>
                    <entity-selector class="ultraslim col-x" [(ngModel)]="actualShipment.partnerProviderId"
                        [entitySelector]="'Partner'" name="partnerProviderId"
                        (valuechangefunction)="setAddressFilterKeys()" formControlName="shipmentPartnerProviderId"
                        [showgotomaintenance]="false" [fixedFilterKeys]="PROVIDER_FILTERKEY"
                        [readonly]="modelFuncionament.adrecaDestiReadOnly">
                    </entity-selector>
                </div>

                <div *ngIf="modelFuncionament.clientDestiVisible">
                    <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.CLIENT.CAPTION' | translate }}</div>
                    <entity-selector class="ultraslim col-x" [(ngModel)]="actualShipment.partnerClientId"
                        [entitySelector]="'Partner'" name="partnerClientId"
                        (valuechangefunction)="setAddressFilterKeys()" formControlName="shipmentPartnerClientId"
                        [showgotomaintenance]="false" [readonly]="modelFuncionament.adrecaDestiReadOnly"
                        [fixedFilterKeys]="CUSTOMER_FILTERKEY">
                    </entity-selector>
                </div>
            </div>

            <!-- Adreça destí-->
            <div class="divSeccio adrecaDesti">
                <shared-selector-addressmanualaddress-component *ngIf="modelFuncionament.adrecaDestiVisible"
                    [required]="modelFuncionament.dataDestiRequerida" class="col-x"
                    [(addressManualAddress)]="actualShipment.destinationAddressManualAddress"
                    [readonly]="modelFuncionament.adrecaDestiReadOnly" [filterKeys]="DESTINATION_ADDRESS_FILTERKEYS"
                    [title]="'SHIPMENT.DESTINATIONADDRESS.CAPTION'" (onChanged)="onChangedDestinationAddress($event)"
                    [autoselectwhenone]="true">
                </shared-selector-addressmanualaddress-component>
                <!-- Hora desti destí-->
                <div class="col-x" *ngIf="modelFuncionament.dataDestiVisible">
                    <date-picker class="col-date slim" name="destinationDateStart"
                        formControlName="destinationDateStart"
                        placeholder="{{'SHIPMENT.WIZARD.DESTINATIONSTART.CAPTION' | translate}}" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.destinationDateStart" [min]="actualShipment.originDateStart"
                        (beforeChange)="setDestinationDateStart($event)">
                    </date-picker>
                    <date-picker class="col-date slim" name="destinationDateEnds" formControlName="destinationDateEnds"
                        placeholder="{{'SHIPMENT.WIZARD.DESTINATIONEND.CAPTION' | translate}}" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.destinationDateEnds" [min]="actualShipment.destinationDateStart">
                    </date-picker>
                    <hourintervals-picker class="col-2intervals slim" [readonly]="iReadonly"
                        placeholder="{{'SHIPMENT.TIMETABLE.CAPTION' | translate}}" name="destinationTimetable"
                        formControlName="destinationTimetable" [readonly]="iReadonly"
                        [(ngModel)]="actualShipment.destinationAddressManualAddress.unloadIntervals">
                    </hourintervals-picker>
                </div>
            </div>

            <div class="divSeccio">
                <div class="title goods">{{ 'ROUTE.RETURN.GOODS.CAPTION' | translate }}</div>
                <textarea-component class="col-x slim" name="goods" formControlName="goods" autocomplete="off"
                    [readonly]="iReadonly" [(ngModel)]="actualShipment.goods">
                </textarea-component>
                <div *ngIf="modelFuncionament.seleccioShipmentsOrigen" class="oldGoods">
                    <span class="prompt">{{'SHARED.ROUTE.RETURN.BASE.CREATE.OLDGOODS.PROMPT' |
                        translate}}</span>{{actualShipment.oldGoods}}
                </div>

                <input-component class="col-x slim" placeholder="{{'SHIPMENT.CLIENTREFERENCE.CAPTION' | translate}}"
                    name="clientReference" formControlName="clientReference" autocomplete="off" [readonly]="iReadonly"
                    [(ngModel)]="actualShipment.clientReference">
                </input-component>
                <tags-chips class="col-x" [readonly]="iReadonly" [(ngModel)]="actualShipment.userTags" name="userTags"
                    formControlName="userTags" placeholder="{{'SHIPMENT.USERTAGS.CAPTION' | translate}}">
                </tags-chips>
            </div>

            <div *ngIf="potSelectCarrier" class="divSeccio">
                <!-- transportista -->
                <div class="title">{{ 'ROUTE.RETURN.PROVIDER.CAPTION' | translate }}</div>
                <entity-selector id="routeCarrierId" class="ultraslim" [entitySelector]="'Partner'"
                    name="routeCarrierId" formControlName="routeCarrierId" [readonly]="iReadonly"
                    [fixedFilterKeys]="CARRIER_FILTERKEY" [(ngModel)]="route.partnerCarrierId">
                </entity-selector>
            </div>

            <div class="divSeccio">
                <div class="title">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERINFO.CAPTION' | translate }}</div>
                <entity-selector *ngIf="potSelectDriver" id="routeDriverId" class="ultraslim"
                    [entitySelector]="'Partner'" name="routeDriverId" formControlName="routeDriverId"
                    [readonly]="iReadonly" [fixedFilterKeys]="DRIVER_FILTERKEY" [(ngModel)]="route.partnerDriverId"
                    [showgotomaintenance]="false" (valuechangefunction)="driverSet()">
                </entity-selector>
                <input-component class="slim col-x" name="routeDriverName" autocomplete="off"
                    [(ngModel)]="route.driverName" formControlName="routeDriverName"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERNAME.CAPTION' | translate}}">
                </input-component>
                <input-component class="slim col-x" name="routeDriverLegalIdentification" autocomplete="off"
                    [(ngModel)]="route.driverLegalIdentification" formControlName="routeDriverLegalIdentification"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.LEGALIDENTIFICATION.CAPTION' | translate}}">
                </input-component>
                <input-component class="slim col-phone" name="routeDriverPhone" autocomplete="off"
                    [(ngModel)]="route.driverPhone" formControlName="routeDriverPhone"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERPHONE.CAPTION' | translate}}">
                </input-component>
                <input-component class="slim col-plate" formControlName="routePlateA"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.PLATEA.CAPTION' | translate}}" name="plateA"
                    autocomplete="off" [readonly]="iReadonly" [(ngModel)]="route.plateA">
                </input-component>
                <input-component class="slim col-plate" formControlName="routePlateB"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.PLATEB.CAPTION' | translate}}" name="plateB"
                    autocomplete="off" [readonly]="iReadonly" [(ngModel)]="route.plateB">
                </input-component>
                <input-component class="slim col-plate" formControlName="routePlateC"
                    placeholder="{{'SHARED.ROUTE.RETURN.BASE.CREATE.PLATEC.CAPTION' | translate}}" name="plateC"
                    autocomplete="off" [readonly]="iReadonly" [(ngModel)]="route.plateC">
                </input-component>
            </div>
        </div>

        <div *ngIf="route.stepNumber==-1" class="divSeccio">
            <div class="grup">
                <div class="titol">
                    {{ "ROUTE.ABSTRACT.CAPTION" | translate }}
                </div>
                <div class="contingut">
                    <div class="line">
                        <span class="prompt">{{ 'ROUTE.ROUTEKEY.CAPTION' | translate }}</span><span
                            class="dada bold">{{route.routeKey}}</span>
                    </div>
                    <div class="line">
                        <span class="prompt">{{ 'ROUTE.ROUTETITLE.CAPTION' | translate }}</span><span
                            class="dada bold">{{route.routeTitle}}</span>
                    </div>
                </div>
            </div>
            <div class="grup" *ngFor="let shipment of route.shipments">
                <div class="titol">
                    {{shipment.clientName}}
                </div>
                <div class="contingut">
                    <div class="line"
                        *ngIf="shipment.originAddressManualAddress && shipment.originAddressManualAddress.manualAddress">
                        <div class="titol">
                            {{ 'SHIPMENT.ORIGINADDRESS.CAPTION' | translate }}
                        </div>
                        <div class="line dada">
                            <shared-tags-strip-component
                                [uniqueString]="shipment.originAddressManualAddress.manualAddress.skin">
                            </shared-tags-strip-component>
                            <div class="dada">{{shipment.originAddressManualAddress.manualAddress.addressLine1}}</div>
                        </div>
                        <div class="line dada">{{shipment.originAddressManualAddress.manualAddress.formattedAddress}}
                        </div>
                        <div class="line dates">{{shipment.originDateStart | date }} -
                            {{shipment.originDateEnds | date }} :
                            {{shipment.originAddressManualAddress.loadIntervals | hourinterval }}
                        </div>
                    </div>
                    <div class="line"
                        *ngIf="shipment.destinationAddressManualAddress && shipment.destinationAddressManualAddress.manualAddress">
                        <div class="titol">
                            {{ 'SHIPMENT.DESTINATIONADDRESS.CAPTION' | translate }}
                        </div>
                        <div class="line dada">
                            <shared-tags-strip-component
                                [uniqueString]="shipment.destinationAddressManualAddress.manualAddress.skin">
                            </shared-tags-strip-component>
                            <div class="dada">{{shipment.destinationAddressManualAddress.manualAddress.addressLine1}}
                            </div>
                        </div>
                        <div class="line dada">
                            {{shipment.destinationAddressManualAddress.manualAddress.formattedAddress}}
                        </div>
                        <div class="line dates">{{shipment.destinationDateStart | date }} -
                            {{shipment.destinationDateEnds | date }} :
                            {{shipment.destinationAddressManualAddress.unloadIntervals | hourinterval }}
                        </div>
                    </div>
                    <shared-tags-strip-component class="line" [tags]="shipment.userTags"></shared-tags-strip-component>
                    <div class="line">
                        <div class="titol">{{ 'SHARED.ROUTE.RETURN.BASE.CREATE.GOODS.CAPTION' | translate }}</div>
                        <span class="dada bold">{{shipment.goods}}</span>
                    </div>
                </div>
            </div>

            <div class="grup" *ngIf="route.partnerCarrierId">
                <div class="titol">
                    {{ 'ROUTE.RETURN.PROVIDER.CAPTION' | translate }}
                </div>
                <div class="contingut">
                    {{route.partnerCarrier}}
                </div>
            </div>

            <div class="grup"
                *ngIf="route.driverName || route.driverLegalIdentification || route.driverPhone || route.plateA || route.plateB || route.plateC">
                <div class="titol">
                    {{ 'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERINFO.CAPTION' | translate }}
                </div>
                <div class="contingut">
                    <div class="line">
                        <span class="prompt">{{'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERNAME.PROMPT' |
                            translate}}</span><span class="dada">{{route.driverName}}</span>
                    </div>
                    <div class="line">
                        <span class="prompt">{{'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERLEGALIDENTIFICATION.PROMPT' |
                            translate}}</span><span class="dada">{{route.driverLegalIdentification}}</span>
                    </div>
                    <div class="line">
                        <span class="grupet" *ngIf="route.driverPhone">
                            <span class="prompt">{{'SHARED.ROUTE.RETURN.BASE.CREATE.DRIVERPHONE.PROMPT' |
                                translate}}</span><span class="dada">{{route.driverPhone}}</span>
                        </span>
                        <span class="grupet" *ngIf="route.plateA">
                            <span class="prompt">{{'SHARED.ROUTE.RETURN.BASE.CREATE.PLATES.PROMPT' |
                                translate}}</span><span class="dada">{{route.plateA}} {{route.plateB}}
                                {{route.plateC}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!iReadonly && !(route.stepNumber==1 && route.type == 'ReverseLogistics')" class="botonera">
            <div class="line">
                <div class="contadors">
                    <div class="title">{{'SHARED.ROUTE.RETURN.BASE.CREATE.COUNTERS.TITLE' | translate }}</div>
                    <span *ngIf="route.shipmentsNumber>0">
                        {{ 'SHARED.ROUTE.RETURN.BASE.CREATE.COUNTERS.CAPTION' | translate:{
                        actual:route.shipmentNumber+1, total:route.shipmentsNumber } }}
                    </span>
                    <span *ngIf="route.shipmentsNumber==0">-</span>
                </div>
                <button class="slim displacement" [disabled]="!canGoPrevious()"
                    (click)="previous()">{{'SHARED.ROUTE.RETURN.BASE.CREATE.PREVIOUS.CAPTION' | translate }}</button>
                <button class="slim displacement" [disabled]="!canGoNext()" (click)="next(false)">{{
                    'SHARED.ROUTE.RETURN.BASE.CREATE.NEXT.CAPTION' | translate }}</button>
                <button class="slim displacement fort" *ngIf="canGoFreeReturn()" (click)="freeReturn()">{{
                    'SHARED.ROUTE.RETURN.BASE.CREATE.FREERETURN.CAPTION' | translate }}</button>

            </div>
            <div *ngIf="route.stepNumber>2" class="line">
                <button class="slim warning" *ngIf="modelFuncionament.canDeleteShipments && route.shipments.length>0"
                    (click)="deleteShipment()">{{'SHARED.ROUTE.RETURN.BASE.CREATE.DELETE.SHIPMENT.CAPTION' |
                    translate}}</button>
                <button class="slim add" *ngIf="modelFuncionament.canAddShipments" [disabled]="!canGoNext()"
                    (click)="addShipment()">{{'SHARED.ROUTE.RETURN.BASE.CREATE.ADD.SHIPMENT.CAPTION' | translate
                    }}</button>
            </div>
            <div class="line">
                <button class="add" [disabled]="!canFinish()"
                    (click)="finishRoute()">{{'SHARED.ROUTE.RETURN.BASE.CREATE.FINISH.CAPTION' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="botoScroll" (click)="goScrollBottom()" [ngClass]="{'scrollVisible': getShowScroll()}">
    <i class="fa fa-arrow-down" aria-hidden="true"></i>
</div>