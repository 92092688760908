
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { Incoterms } from '@shared/src/enums/Incoterms';
import { RoutePermissionTypesRules } from '@shared/src/enums/RoutePermissionTypes';
import { AlertService, RouteController } from '@shared/src/public-api';



@Component({
    selector: 'shared-route-item-edit-maintenance',
    templateUrl: './shared.route.item.edit.maintenance.component.html',
    styleUrls: ['./shared.route.item.edit.maintenance.component.scss']
})
export class SharedRouteItemEditMaintenanceComponent implements OnInit {

    public readonly: boolean = false;
    public enumIncoterms = Incoterms;

    constructor(public openRouteController: OpenRouteController,
        public routeController: RouteController,
        @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('AlertService') public alertService: AlertService) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

        this.hascarrier = this.homeController.hasProduct(HardmanProducts.carrier);
        this.hasshipper = this.homeController.hasProduct(HardmanProducts.shipper);
    }

    public hascarrier: boolean = false;
    public hasshipper: boolean = false;


    public changeIsDivisible = false;

    private _route: RouteCardDto;
    @Input()
    set route(value: RouteCardDto) {
        if (this._route === value)
            return;
        this._route = value;

        this.canAssignDriverAndPlates = RoutePermissionTypesRules.canAssignDriverAndPlatesOnRoute(value);
        this.showAssignDriverAndPlates = RoutePermissionTypesRules.showAssignDriverAndPlatesOnRoute(value);

    }
    get route(): RouteCardDto {
        return this._route;
    }

    ngOnInit(): void {

    }

    @Output() public goParentGrouped: EventEmitter<any> = new EventEmitter<any>();
    onGoParentGrouped() {
        this.goParentGrouped.next();
    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    public onValueChange(value: RouteCardDto) {
        if (this.route == null || value == null || this.route.routeId === value.routeId)
            this.route = value;

        this.valueChange.next(this.route);
    }


    changeActiveIsDivisible() {
        this.openRouteController.setActiveIsDivisible(this.route.routeId, this.route.isDivisible).subscribe(data => {
            if (data)
                this.valueChange.next(this.route);
        });
        this.changeIsDivisible = false;
    }

    public canAssignDriverAndPlates: boolean = true;
    public showAssignDriverAndPlates: boolean = true;

    reloadRoute() {
        this.routeController.getRoute(this._route.routeId).subscribe(e => {
            this.route = e;
            this.onValueChange(e);
        })
    }

    @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
    public onRouteUpdated(value: RouteCardDto) {
        if (this.route == null || value == null || this.route.routeId === value.routeId)
            this.route = value;

        this.routeUpdated.next(value);
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
