<div class="container-table grup">
    <div class="contingut" *ngIf="logs != null">
        <table class="hardman" style="width: 100%;">
          <tr class="dark">
            <th class="header">{{ "MANAGEMENTMODELREQUIREMENT.DESCRIPTION.LOG" | translate }}</th>
            <th class="header">{{ "MANAGEMENTMODELREQUIREMENT.ACTION.LOG" | translate }}</th>
            <th class="header">{{ "MANAGEMENTMODELREQUIREMENT.DOCUMENT.LOG" | translate }}</th>
            <th class="header">{{ "MANAGEMENTMODELREQUIREMENT.MOMENT.LOG" | translate }}</th>
            <th class="header">{{ "MANAGEMENTMODELREQUIREMENT.USERNAME.LOG" | translate }}</th>
          </tr>
          <tr *ngFor="let element of logs | sortBy:'-momentUTC' ; let i=index">
            <td class="small-value">{{ element.requirementDescription }} </td>
            <td class="small-value">{{ element.actionResource }} </td>
            <td class="small-value">{{ element.documentName }} </td>
            <td class="small-value">{{ element.moment | datehournotz }}</td>
            <td class="small-value">{{ element.userName }} </td>
          </tr>
        </table>
      </div>
</div>