import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { AddressController } from "@shared/src/controllers/address/address.controller";
import { AddressManualAddressDto } from "@shared/src/controllers/maps/AddressManualAddressDto";
import { ControlContainer, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ManualAddressDto } from "@shared/src/controllers/maps/ManualAddressDto";
import { AddressDto } from "@shared/src/controllers/maps/AddressDto";
import { TimeZones } from "@shared/src/enums/TimeZones";
import { ThemeService } from "ng2-charts";
import { HString } from "@shared/src/public-api";

@Component({
    selector: "shared-selector-addressmanualaddress-component",
    templateUrl: "./shared.selector.addressmanualaddress.component.html",
    styleUrls: ["./shared.selector.addressmanualaddress.component.scss"]
})
export class SharedSelectorAddressmanualaddressComponent implements OnInit, AfterViewInit {

    constructor(public fb: FormBuilder, protected addressController: AddressController) {
        this.buildRForm(this.fb);
    }

    public buildRForm(fb: FormBuilder): FormGroup {
        return fb.group({
            'addressId': [null, [Validators.nullValidator]],
            'addressLine1': ['', [Validators.maxLength(255)]],
            'addressLine2': ['', [Validators.maxLength(255)]],
            'addressCity': ['', [Validators.maxLength(255)]],
            'addressProvince': ['', [Validators.maxLength(255)]],
            'addressPostalCode': ['', [Validators.maxLength(255)]],
            'addressState': ['', [Validators.maxLength(255)]],
            'addressCountry': ['', [Validators.maxLength(255)]]
        });
    }

    public identifier = 'addressmanualaddress-' + identifier++;

    ngAfterViewInit(): void {
        this.calculateGeneralClass();
    }

    public hasAddressDefined: boolean;
    public rFormDefined: FormGroup;
    public rFormManual: FormGroup;
    ngOnInit(): void {
        this.loadForms();
    }

    private _required: boolean;
    @Input()
    set required(value: boolean) {
        if (this._required === value)
            return;

        this._required = value;
        this.loadForms();
    }
    get required(): boolean {
        return this._required;
    }
    loadForms() {
        if (this.required) {
            this.rFormManual = this.fb.group({
                'addressLine1': ['', [Validators.maxLength(255), Validators.required]],
                'addressLine2': ['', [Validators.maxLength(255)]],
                'addressCity': ['', [Validators.maxLength(255)]],
                'addressProvince': ['', [Validators.maxLength(255)]],
                'addressPostalCode': ['', [Validators.maxLength(255)]],
                'addressState': ['', [Validators.maxLength(255)]],
                'addressCountry': ['', [Validators.maxLength(255)]]
            });
            this.rFormDefined = this.fb.group({
                'addressId': ['', [Validators.required]]
            });
        } else {
            this.rFormManual = this.fb.group({
                'addressLine1': ['', [Validators.maxLength(255)]],
                'addressLine2': ['', [Validators.maxLength(255)]],
                'addressCity': ['', [Validators.maxLength(255)]],
                'addressProvince': ['', [Validators.maxLength(255)]],
                'addressPostalCode': ['', [Validators.maxLength(255)]],
                'addressState': ['', [Validators.maxLength(255)]],
                'addressCountry': ['', [Validators.maxLength(255)]]
            });
            this.rFormDefined = this.fb.group({
                'addressId': ['']
            });
        }
    }

    @Input()
    public readonly: boolean = false;

    @Input()
    public title: string = "";

    @Input()
    public filterKeys: Array<string> = [];

    @Input()
    public selectorPlaceholder: string = "";

    @Input() public autoselectwhenone: boolean = false;

    private _addressManualAddress: AddressManualAddressDto;
    @Input()
    set addressManualAddress(value: AddressManualAddressDto) {
        if (this._addressManualAddress === value)
            return;

        this._addressManualAddress = value;

        this.loadAddressDefined();

    }
    get addressManualAddress(): AddressManualAddressDto {
        return this._addressManualAddress;
    }


    private loadDataHasAddressDefined: boolean;
    loadAddressDefined() {
        if (!this.loadDataHasAddressDefined) {
            this.loadDataHasAddressDefined = true;
            this.addressController.hasAddressDefined(this.identifier).subscribe(a => {
                this.hasAddressDefined = a;
                if (!this.hasAddressDefined) {
                    if (!this._addressManualAddress) {
                        this._addressManualAddress = new AddressManualAddressDto();
                    }
                    if (!this._addressManualAddress.manualAddress) {
                        this._addressManualAddress.manualAddress = new ManualAddressDto();
                    }
                    this.setViewType("assisted");
                } else {
                    this.loadSetViewType();
                }
            });
        } else {
            this.loadSetViewType();
        }
    }

    loadSetViewType() {
        if (!this._addressManualAddress) {
            this.setViewType("registered");
            return;
        }

        if (!this._addressManualAddress.manualAddress) {
            this._addressManualAddress.manualAddress = new ManualAddressDto();
        }

        if (this._addressManualAddress.addressId)
            this.setViewType("registered");
        else if (this._addressManualAddress.manualAddress && this._addressManualAddress.manualAddress.formattedAddress)
            this.setViewType("assisted");
        else if (this._addressManualAddress.manualAddress && this._addressManualAddress.manualAddress.addressLine1)
            this.setViewType("manual");
        else
            this.setViewType("registered");
    }

    public addressManualAddressChange: EventEmitter<AddressManualAddressDto> = new EventEmitter<AddressManualAddressDto>();

    @Output()
    public onChanged: EventEmitter<SharedSelectorAddressManualAddressEvent> = new EventEmitter<SharedSelectorAddressManualAddressEvent>();

    public generalClass: string = "";
    calculateGeneralClass() {
        this.generalClass = this.actualViewType;
        if (this.readonly)
            this.generalClass += " readonly";
    }

    @Input() disableRegistered: boolean = false;
    @Input() disableAssisted: boolean = false;
    @Input() disableManual: boolean = false;

    public _actualViewType: string;
    @Input()
    public get actualViewType() {
        return this._actualViewType;
    }
    public set actualViewType(value: string) {
        if (this._actualViewType === value)
            return;
        this._actualViewType = value;
        this.calculateGeneralClass();
    }
    public setViewType(value: string) {

        if (!HString.isNullOrNullString(this.forcedViewType)) {
            this.actualViewType = this.forcedViewType;
            return;
        }

        if (value == 'registered')
            this.clearManualAddress();
        this.actualViewType = value;
    }

    public _forcedViewType: string;
    @Input()
    public get forcedViewType() {
        return this._forcedViewType;
    }
    public set forcedViewType(value: string) {
        if (this._forcedViewType === value)
            return;
        this._forcedViewType = value;
        this.actualViewType = this.forcedViewType;
    }

    public registeredAddressChanged() {
        if (this.addressManualAddress != null) {
            if (this.addressManualAddress.manualAddress == null)
                this.addressManualAddress.manualAddress = new ManualAddressDto();

            if (this.addressManualAddress.addressId)
                this.addressController.getByAddressId(this.addressManualAddress.addressId).subscribe(a => {
                    if (a) {
                        this.addressManualAddress.manualAddress.address = a.address;
                        this.addressManualAddress.manualAddress.addressLine1 = a.addressLine1;
                        this.addressManualAddress.manualAddress.addressLine2 = a.addressLine2;
                        this.addressManualAddress.manualAddress.city = a.city;
                        this.addressManualAddress.manualAddress.country = a.country;
                        this.addressManualAddress.manualAddress.formattedAddress = a.formattedAddress;
                        this.addressManualAddress.manualAddress.postalCode = a.postalCode;
                        this.addressManualAddress.manualAddress.province = a.province;
                        this.addressManualAddress.manualAddress.state = a.state;
                        this.addressManualAddress.manualAddress.timeZone = a.timeZone;
                        this.addressManualAddress.manualAddress.latitude = a.latitude;
                        this.addressManualAddress.manualAddress.longitude = a.longitude;
                    }
                    else
                        this.clearManualAddress();
                    this.addressManualAddressChange.next(this.addressManualAddress);
                    this.onChanged.next(SharedSelectorAddressManualAddressEvent.build(this.addressManualAddress, a));
                });
            else {
                this.clearManualAddress();
                this.addressManualAddressChange.next(this.addressManualAddress);
                this.onChanged.next(SharedSelectorAddressManualAddressEvent.build(this.addressManualAddress, null));
            }
        }
    }

    /***
     * Han executat la recerca d'assistida, la copio com a manual, resetejo addressId i event
     */
    public onAssistedAddressCreate(value: AddressDto) {
        if (value) {
            if (!this.addressManualAddress)
                this.addressManualAddress = new AddressManualAddressDto();
            if (!this.addressManualAddress.manualAddress)
                this.addressManualAddress.manualAddress = new ManualAddressDto();
            this.addressManualAddress.manualAddress.address = value.address;
            this.addressManualAddress.manualAddress.addressLine1 = value.addressLine1;
            this.addressManualAddress.manualAddress.addressLine2 = value.addressLine2;
            this.addressManualAddress.manualAddress.city = value.city;
            this.addressManualAddress.manualAddress.country = value.country;
            this.addressManualAddress.manualAddress.formattedAddress = value.formattedAddress;
            this.addressManualAddress.manualAddress.postalCode = value.postalCode;
            this.addressManualAddress.manualAddress.province = value.province;
            this.addressManualAddress.manualAddress.state = value.state;
            this.addressManualAddress.manualAddress.timeZone = value.timeZone;
            this.addressManualAddress.manualAddress.latitude = value.latitude;
            this.addressManualAddress.manualAddress.longitude = value.longitude;

            this.addressManualAddress.addressId = 0;
            this.addressManualAddressChange.next(this.addressManualAddress);
            this.onChanged.next(SharedSelectorAddressManualAddressEvent.build(this.addressManualAddress, null));
        }
    }

    public clearManualAddress() {
        this.addressManualAddress.manualAddress.address = "";
        this.addressManualAddress.manualAddress.addressLine1 = "";
        this.addressManualAddress.manualAddress.addressLine2 = "";
        this.addressManualAddress.manualAddress.city = "";
        this.addressManualAddress.manualAddress.country = "";
        this.addressManualAddress.manualAddress.formattedAddress = "";
        this.addressManualAddress.manualAddress.postalCode = "";
        this.addressManualAddress.manualAddress.province = "";
        this.addressManualAddress.manualAddress.state = "";
        this.addressManualAddress.manualAddress.timeZone = TimeZones.UTC;
        this.addressManualAddress.manualAddress.latitude = null;
        this.addressManualAddress.manualAddress.longitude = null;

    }

    /***
     * Estem canviant la manual
     */
    public manualAddressChanged() {
        this.addressManualAddress.addressId = 0;
        this.onChanged.next(SharedSelectorAddressManualAddressEvent.build(this.addressManualAddress, null));
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}

let identifier = 0;

export class SharedSelectorAddressManualAddressEvent {
    public addressManualAddress: AddressManualAddressDto;
    public address: AddressDto;

    public static build(addressManualAddress: AddressManualAddressDto, address: AddressDto): SharedSelectorAddressManualAddressEvent {
        let result = new SharedSelectorAddressManualAddressEvent();
        result.address = address;
        result.addressManualAddress = addressManualAddress;
        return result;
    }
}
