<ng-container *ngIf="phases!=null">
    <div class="container" [ngClass]="{'actualphase-notnull': actualphase!=null}">
        <div class="line"></div>
        <div class="phase" *ngFor="let p of phases"
            [ngClass]="{'initial': p.isInitial, 'needslot': p.needSlot, 'final': p.isFinal, 'phase-activa': isPhaseActiva(p)}">
        </div>
    </div>
    <div class="dates" *ngIf="actualphase">
        <datehour-viewer class="arrivedAt" [value]="actualphase.arrivedAt" [showOriginal]="false" [showTimeZone]="false"
            [showNulls]="false">
        </datehour-viewer>
        <datehour-viewer class="completedAt" [value]="actualphase.completedAt" [showOriginal]="false"
            [showTimeZone]="false" [showNulls]="false">
        </datehour-viewer>
    </div>
</ng-container>