<div (drop)="drop($event, slot)" class="body"
    [ngClass]="{ 'empty' : !isAssigned, 'inaccessible': inAccessible,'notrecommendable': notRecommendable, 'inactive': inActive,'draggable' : isSlotDraggable }"
    [draggable]="isSlotDraggable">
    <button class="remove" [ngClass]="{ 'active' : isRemove }" (click)="removePhase()"><span
            class="text-actions">{{ "ADDRESSSLOTS.ACTION.REMOVE" | translate }}</span></button>
    <button class="reduce" [ngClass]="{ 'active' : isReduce }" (click)="reduceSlot()"><span
            class="text-actions">{{ "ADDRESSSLOTS.ACTION.REDUCE" | translate }}</span></button>

    <shared-phase-slot *ngIf="slot!=null" class="phaseslot noborder slotOnlyLock"
        [ngClass]="{'absolute-mode': absoluteMode}" [canalterelement]="canAlterElement" [action]="action"
        [phaseslot]="slot.slotPlanning" [dockToAssigned]="dockToAssigned" (onRefreshData)="refreshData()"
        (onSlotSelected)="slotSelected($event)">
    </shared-phase-slot>

    <div *ngIf="!isAssigned && absoluteMode && addressTimetableInterval" class="slot-available absolute-mode">
        <span class="dins-text-slot-available">
            +{{ addressTimetableInterval.slotAvailable }}
        </span>
    </div>

    <div class="text-inaccessible">
        <div class="dins-text-inaccessible">
            {{ "ADDRESSSLOTS.INACCESSIBLE" | translate }}
        </div>
    </div>
    <div class="text-inactive">
        <div *ngIf="!isAssigned && absoluteMode && addressTimetableInterval" class="slot-available">
        </div>
    </div>
    <div class="text-notrecommendable">
        <div *ngIf="!isAssigned && absoluteMode && addressTimetableInterval" class="slot-available">
        </div>
    </div>