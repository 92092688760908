import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseController } from "../base.controller";
import { AlertService } from "../../services/alert/alert.service";
import { LoadingService } from "../../services/loading/loading.service";

@Injectable({
    providedIn: 'root'
  })
export class GeolocationController extends BaseController{
    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
      ) {
        super(http, alertService, translateService, loadingService, router);
      }

      public collectCurrentPositionEvent(): Promise<void> {
        return new Promise<void>(resolve => {
            resolve();
        });
      }

}