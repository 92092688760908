import { Component, EventEmitter, Input, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';

@Component({
  selector: 'shared-booking-partner-selector',
  templateUrl: './shared.booking.partnerselector.component.html',
  styleUrls: ['./shared.booking.partnerselector.component.scss']
})
export class SharedBookingPartnerSelectorComponent implements OnInit {

  constructor(protected fb: FormBuilder, protected bookingController: BookingController,
    @Inject('SecurityController') protected securityController: SecurityController) {
    this.formGroup = fb.group({
      'partnerShipperId': ['', [Validators.nullValidator]],
      'partnerFreightForwarderId': ['', [Validators.nullValidator]]
    })
  }

  ngOnInit(): void { }

  @Input() readonly: boolean = false;

  private _mode: "read" | "write" = "read";
  @Input() public set mode(v: "read" | "write") {
    if (v == this.mode)
      return;

    this._mode = v;
  }
  public get mode(): "read" | "write" {
    return this._mode;
  }

  @Input() formGroup: FormGroup;

  public filterkeysFreightForwarder: Array<String> = ['PARTNER.FREIGHTFORWARDER.LINKPERMISSION.FILTER'];
  public filterkeysShipper: Array<String> = ['PARTNER.SHIPPER.LINKPERMISSION.FILTER'];

  public showFreightForwarderSelector: boolean = true;
  public showShipperSelector: boolean = true;

  public partnerFreightForwarderId: number;
  public partnerShipperId: number;

  public forcedHidePartnerFreightForwarder: boolean;
  public forcedHidePartnerShipper: boolean;

  private _booking: BookingDto;
  @Input() public set booking(v: BookingDto) {
    if (v && this._booking && JSON.stringify(this._booking) == JSON.stringify(v))
      return;

    this._booking = v;

    if(this._booking == null)
      return;
    this.securityController.getStoredUserDto().subscribe(data => {

      this.forcedHidePartnerFreightForwarder = data.tenantId == this._booking.freightForwarderId;
      this.forcedHidePartnerShipper = data.tenantId == this._booking.shipperId;

      this.partnerFreightForwarderId = this._booking.partnerFreightForwarderId;
      this.partnerShipperId = this._booking.partnerShipperId;

    });

  }

  public get booking(): BookingDto {
    return this._booking;
  }

  public setFreightForwarder() {
    this.bookingController.setFreightForwarder(this._booking.bookingId, this.partnerFreightForwarderId).subscribe(e => {
      if (e && e.bookingId) {
        this.booking = e;
        this.formGroup.get("partnerFreightForwarderId").markAsPristine();
      }
    })
  }

  public setShipper() {
    this.bookingController.setShipper(this._booking.bookingId, this.partnerShipperId).subscribe(e => {
      if (e && e.bookingId) {
        this.booking = e;
        this.formGroup.get("partnerShipperId").markAsPristine();
      }
    })
  }

  pretty(val: any) {
    return JSON.stringify(val);
  }

}
