import { Injectable } from "@angular/core";
import { PhonePrefix } from "@shared/src/enums/PhonePrefix";
import { TimeZones } from "@shared/src/enums/TimeZones";

@Injectable()
export class UserRegisterDto {
    public eMail: string;
    public prefix: string = PhonePrefix.Spain_34;
    public phone: string;
    public fullName: string;
    public password: string;
    public acceptProductConditions: boolean;
}