import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HString } from '@shared/src/datatypes/HString';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { SharedAsideFactory } from '../aside/shared.aside.factory';
import { PanelStructCtrlDto } from '@shared/src/dtos/panel/PanelStructCtrlDto';
import { Subscription, Subject } from 'rxjs';
import { MaintenanceSearchByTextDto } from '@shared/src/controllers/maintenance/_base/MaintenanceSearchByTextDtos';
import { SocketController } from '@shared/src/public-api';
import { OpenPanelController } from '@shared/src/controllers/open/open.panel.controller';

@Component({
    template: ''
})
export class SharedPanelPageAbstract implements OnInit {

    constructor(public openPanelController: OpenPanelController,
        @Inject('HomeController') protected homeController: HomeController,
        @Inject("SecurityController") protected securityController: SecurityController,
        @Inject('SharedAsideFactory') public sharedAsideFactory: SharedAsideFactory,
        protected socketController: SocketController,
        protected router: Router,
        protected activatedRoute: ActivatedRoute) {

    }

    public panelStruct: PanelStructCtrlDto;
    public searchText: string = "";
    public filterMetadata: any = {};
    public maintenanceSearchByText: MaintenanceSearchByTextDto = MaintenanceSearchByTextDto.buildPage("", [], 0, 50);

    public timeoutStarted: boolean = false;
    public timeoutInterrupted: boolean = false;
    private pauseInteres = false;
    private actualInterest = "";
    private notificationSubscription: Subscription;

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.callResolveParams();
        });
        this.activatedRoute.params.subscribe(routeParams => {
            this.callResolveParams();
        });

        this.callResolveParams();

        this.notificationSubscription = this.socketController.notificationInterestedOn$.subscribe(value => {
            if (value === this.actualInterest && !this.pauseInteres && this.selfEventReceived)
                this.loadData(false);
            this.selfEventReceived = true;
        });
    }

    private _resolvingParams: boolean = false;
    private callResolveParams() {
        if (this._resolvingParams)
            return;
        this._resolvingParams = true;
        this.resolveParams();
        this._resolvingParams = false;
    }

    protected __panelId: number;
    private resolveParams(): boolean {
        let result: boolean = false;
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const routeUrl = this.activatedRoute.snapshot.url;

        let panelid = routeParams.get('panelid');
        if (!HString.isNullOrNullString(panelid)) {
            let pId: number = Number.parseInt(panelid);

            if (this.__panelId === pId)
                return;

            this.__panelId = pId;
            this.loadData(true);
            this.actualInterest = "PanelPage:" + this.__panelId;
            this.socketController.interested(this.actualInterest);
        }

        return result;
    }

    ngAfterViewChecked(): void {
    }

    ngOnDestroy(): void {

        if (this.notificationSubscription)
            this.notificationSubscription.unsubscribe();

        this.timeoutInterrupted = true;
    }

    private selfEventReceived: boolean = true;
    public getDataFromLocalChange(busy: boolean) {
        this.selfEventReceived = false;
        this.loadData(busy);
    }


    public updateData(busy: boolean) {
        if (!this.timeoutStarted) {
            this.timeoutStarted = true;
            setTimeout(() => {
                if (!this.timeoutInterrupted)
                    this.loadData(true);

                this.timeoutStarted = false;
            }, 60 * 1000);
        }
    }

    loadData(busy: boolean) {

        if (this.pauseInteres)
            return;

        this.pauseInteres = true;
        this.timeoutInterrupted = true;

        this.maintenanceSearchByText.parentid = this.__panelId;
        this.maintenanceSearchByText.searchtext = this.searchText;
        this.openPanelController.panelStruct(this.maintenanceSearchByText, busy).subscribe(data => {
            this.wellComeStruct(data);
            this.pauseInteres = false;
            this.timeoutInterrupted = false;
        });
    }

    wellComeStruct(data: PanelStructCtrlDto) {
        this.panelStruct = data;
    }

}
