import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';
import { HString } from '@shared/src/datatypes/HString';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HLong } from '@shared/src/datatypes/HLong';


@Component({
	selector: 'shared-route-group',
	templateUrl: './shared.route.group.component.html',
	styleUrls: ['./shared.route.group.component.scss']
})
export class SharedRouteGroupComponent implements OnInit {

	public useIonic: boolean = false;
	public iReadonly: boolean = false;

	searchTextUpdate = new Subject<string>();
	public searchText: string = "";
	public filterMetadata: any = {};

	@Output() finish = new EventEmitter<RouteGroupDto>();

	constructor(
		public routeController: RouteController,
		public fb: FormBuilder,
		@Inject('HomeController') protected homeController: HomeController,
		@Inject("SecurityController") protected securityController: SecurityController,
		@Inject("AlertService") protected alertService: AlertService) {
		this.securityController.getStoredUserDto().subscribe(data => {
			this.iReadonly = data.isReadOnly;
		});

		if (homeController.useIonic)
			this.useIonic = homeController.useIonic();

		// Debounce search.
		this.searchTextUpdate.pipe(
			debounceTime(HomeController.DEFAULT_BOUNCE_BUTTON_TIME),
			distinctUntilChanged())
			.subscribe(value => {
				this.loadSearchRoute();
			});

	}

	public _routeId: number;
	@Input()
	public get routeId(): number {
		return this._routeId;
	}
	public set routeId(value: number) {
		if (this._routeId === value)
			return;
		this._routeId = value;
		this.preFinishActive = false;
		this.loadData();
	}

	public _selectedRoute: RouteCardItemDto;
	public get selectedRoute(): RouteCardItemDto {
		return this._selectedRoute;
	}
	public set selectedRoute(value: RouteCardItemDto) {
		if (this._selectedRoute === value)
			return;
		this._selectedRoute = value;
		this.routeId = value != null ? value.routeId : 0;
	}

	public preFinishActive: boolean;
	public routeToAdd: RouteCardItemDto;

	public value: RouteGroupDto;

	ngOnInit(): void {
	}

	loadData() {
		if (HLong.isNullOrNullLong(this.routeId)) {
			this.selectedRoute = null;
			return;
		}

		this.routeController.getRouteGroup(this.routeId).subscribe(data => {
			this.value = data;
			this.selectedRoute = data != null ? data.routeGroup : null;
		});
	}

	getActualRoutes(): Array<RouteCardItemDto> {

		if (this.value == null || this.value.routesGroup == null)
			return new Array();

		return this.value.routesGroup;
	}

	getCountActualRoutes(): number {
		if (this.value == null || this.value.routesGroup == null)
			return 0;

		return this.value.routesGroup.length;
	}

	public _searchRoutes: Array<RouteCardItemDto> = [];
	getSearchRoutes() {
		if (this._searchRoutes == null)
			return new Array();
		return this._searchRoutes;
	}

	loadSearchRoute() {

		if (this.value == null) {
			this.searchText == "";
			this._searchRoutes = new Array();
		}

		//Només ferem cerca al back si el filtre de pantalla ens retorna 0 elements i si el searchText te més de 3 caràcters
		if (this.filterMetadata.count > 0 || this.searchText == "" || this.searchText.length < 4)
			return;

		this.routeToAdd = null;
		if (!HString.isNullOrNullString(this.searchText)) {

			this.routeController.searchActiveRouteCanChildsRouteGroupByText(this.searchText).subscribe(data => {
				if (data == null) {
					this._searchRoutes = new Array();
					return;
				}
				let newlist = new Array();

				if (this.value.routesGroup == null)
					this.value.routesGroup = new Array();

				//Només ens quedem amb les que no existeixen a la llista actual
				data.forEach(element => {
					let exists = false;
					this.value.routesGroup.forEach(actualelement => {
						if (element.routeId == actualelement.routeId)
							exists = true;
					});
					if (!exists)
						newlist.push(element);
				});
				this._searchRoutes = newlist;
			});
		}
		else {
			this._searchRoutes = new Array();
		}
	}

	onRouteAdd() {
		if (this.value == null || this.routeToAdd == null)
			return;

		if (this.value.routesGroup == null)
			this.value.routesGroup = new Array();

		let exists = false;
		this.value.routesGroup.forEach(element => {
			if (element.routeId == this.routeToAdd.routeId)
				exists = true;
		});

		if (exists)
			return;

		this.value.routesGroup.push(this.routeToAdd);
		this.routeToAdd = null;
		this.searchText = "";
		this._searchRoutes = new Array();
	}

	onRouteRemove(item: RouteCardItemDto) {
		if (item == null)
			return;
		let newlist: Array<RouteCardItemDto> = [];
		this.value.routesGroup.forEach(element => {
			if (element.routeId != item.routeId)
				newlist.push(element);
		});
		this.value.routesGroup = newlist;
	}

	onCancelRouteGrouped() {
		this.preFinishActive = false;
	}
	canOnRouteGrouped(): boolean {
		if (this.value == null || this.value.routesGroup == null)
			return false;

		if (this.value.routeGroup == null && this.value.routesGroup.length < 2)
			return false;

		return true;
	}
	onRouteGrouped() {
		if (!this.canOnRouteGrouped())
			return;

		if (!this.preFinishActive) {
			this.preFinishActive = true;
			return;
		}

		if (this.value.routeGroup != null && !HLong.isNullOrNullLong(this.value.routeGroup.routeId)) {
			this.routeController.routeGroupActualized(this.value).subscribe(data => {
				if (data != null) {
					this.finish.next(data);
					this.preFinishActive = false;
				}
			});
		} else {
			this.routeController.routeGroupCreate(this.value).subscribe(data => {
				if (data != null && data.routeGroup != null) {
					this.finish.next(data);
					this.preFinishActive = false;
				}
			});
		}

	}

	getActualRoutesResource(): string {
		if (this.selectedRoute != null && this.selectedRoute.isGrouped){
			if(this.preFinishActive)
				return 'ROUTEGROUP.ACTUALROUTEACTUALIZED.TITLE';
			return 'ROUTEGROUP.ACTUALROUTEDELETE.TITLE';
		}

		return 'ROUTEGROUP.ACTUALROUTECHILDS.TITLE';
	}

	getActionButtonToGroupResource(): string {
		if (this.selectedRoute != null && this.selectedRoute.isGrouped) {
			if (this.preFinishActive)
				return 'ROUTEGROUP.OPTION.ROUTEGROUP.SAVE';
			return 'ROUTEGROUP.OPTION.ROUTEGROUP.ACCEPT';
		}
		if (this.preFinishActive)
			return 'ROUTEGROUP.OPTION.ROUTEGROUP.CONFIRM';
		return 'ROUTEGROUP.OPTION.ROUTEGROUP.GROUPED';
	}
}
