import { Component, Input, Output, EventEmitter, Inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActionDto } from "@shared/src/dtos/maintenance/action/actionDto";
import { ActionsStripDto } from "@shared/src/dtos/maintenance/action/ActionsStripDto";
import { HomeController } from "@shared/src/controllers/home/home.controller";

@Component({
    selector: "actions",
    templateUrl: "./shared.actions.component.html",
    styleUrls: ["./shared.actions.component.scss"],
})
export class SharedActionsComponent {
    @Output() click: EventEmitter<ActionDto> = new EventEmitter();
    @Output() clickAction: EventEmitter<ActionDto> = new EventEmitter();

    @Input() hideactionsbar: boolean = false;
    @Input() hideactionstoolbar: boolean = false;
    @Input() hideactionsmenu: boolean = false;

    public useIonic: boolean = false;

    constructor(
        private translate: TranslateService,
        @Inject("HomeController") protected homecontroller: HomeController
    ) {
        if (homecontroller.useIonic) this.useIonic = homecontroller.useIonic();
    }

    ngOnInit() { }
    private _actionstrip: ActionsStripDto;
    @Input() set actionstrip(value: ActionsStripDto) {
        if (this._actionstrip === value) return;
        this._actionstrip = value;
        this.getGeneralClass();
    }
    get actionstrip(): ActionsStripDto {
        return this._actionstrip;
    }

    getItemClass(value: ActionDto) {
        let result = value.styleClass;

        if (value != null && ActionDto.getActionShowAsIcon(value))
            result = result + " icon-visible";
        else result = result + " icon-no-visible";

        if (value.actionShowAs >= 0 && this.isAwesome(value))
            result += " is-icon-awesome";
        else if (value.actionShowAs >= 0 && this.isHardman(value))
            result += " is-icon-hardman";
        else if (value.actionShowAs >= 0)
            result += " is-icon-no-awesome";

        if (value.actionShowAs == 0) result += " is-icon-text";
        if (value.actionShowAs < 0) result += " is-only-text";

        if (value.addbutton) {
            result += " is-add"
        }

        result += " " + value.classString;

        return result;
    }

    public generalClass = "";
    public getGeneralClass() {
        this.generalClass = "";
        if (this.haveBar()) this.generalClass += " haveBar";
        if (this.haveMenu()) this.generalClass += " haveMenu";
        return this.generalClass;
    }

    haveMenu() {
        if (
            this._actionstrip != null &&
            this._actionstrip.listAsMenu != null &&
            this.getLength(this._actionstrip.listAsMenu) > 0
        )
            return true;
        return false;
    }
    haveBar() {
        if (
            this._actionstrip != null &&
            this._actionstrip.listAsBar != null &&
            this.getLength(this._actionstrip.listAsBar) > 0
        )
            return true;
        return false;
    }

    getLength(value: any) {
        if (value == null || value.length == undefined || value.length == null)
            return 0;
        return value.length;
    }

    chipClick(value, $event) {
        if (this.click != null) this.click.next(value);
        if (value != null) this.clickAction.next(value);
    }
    menuClick(value, $event) {
        if (this.click != null) this.click.next(value);
        if (value != null) this.clickAction.next(value);
    }

    getCaption(item: ActionDto) {
        if (
            item != null &&
            item.additionalcaption != "" &&
            item.additionalcaption != null
        )
            return (
                " " +
                this.translate.instant(item.additionalcaption.toUpperCase())
            );
        else return this.translate.instant(item.actionCaption);
    }

    isAwesome(value: ActionDto): boolean {
        if (value == null) {
            return false;
        }
        return value.actionIcon.startsWith("awesome:") || value.actionIcon.startsWith("fa");
    }

    isHardman(value: ActionDto): boolean {
        if (value == null) {
            return false;
        }
        return value.actionIcon.startsWith("hardman-");
    }

    getIcon(value: ActionDto): string {
        if (this.isAwesome(value)) {
            return value.actionIcon.substring(8, value.actionIcon.length);
        }
        return value.actionIcon;
    }
}
