<div class="body" *ngIf="true && invoice!=null">
    <div class="general-title">
        Hardman Innovation SL
    </div>
    <div class="idcode">{{invoice.id}}</div>
    <img class="logo" src="/assets/images/HardmanLogoBlauNom.png" alt="Hardman logo">
    <table class="block">
        <tr>
            <td class="vatnumber">{{'GENERAL.HARDMAN.INVOICE.VATNUMBER' | translate}}</td>
        </tr>
        <tr>
            <td class="addressline">{{'GENERAL.HARDMAN.INVOICE.ADDRESSLINE1' | translate}}</td>
        </tr>
        <tr>
            <td class="addressline">{{'GENERAL.HARDMAN.INVOICE.ADDRESSLINE2' | translate}}</td>
        </tr>
        <tr>
            <td class="addressline">{{'GENERAL.HARDMAN.INVOICE.ADDRESSLINE3' | translate}}</td>
        </tr>
    </table>
    <table class="block">
        <tr>
            <td class="header">{{'GENERAL.HARDMAN.INVOICE.FACTURA' | translate}}</td>
            <td class="separador"></td>
            <td class="header">{{'GENERAL.HARDMAN.INVOICE.FACTURARA' | translate}}</td>
            <td class="separador"></td>
            <td class="header">{{'GENERAL.HARDMAN.INVOICE.CONTACTE' | translate}}</td>
        </tr>
        <tr>
            <td class="invoiceNumber"><span *ngIf="!invoice.future">{{invoice.invoiceId}}</span><span
                    *ngIf="invoice.future">{{'GENERAL.HARDMAN.INVOICE.PREVISIO' | translate}}</span></td>
            <td class="separador"></td>
            <td class="vatnumber">{{invoice.vatNumber}}</td>
            <td class="separador"></td>
            <td class=""></td>
        </tr>
        <tr>
            <td class="dates">{{invoice.dateFrom | date}} - {{invoice.dateTo | date}}
            </td>
            <td class="separador"></td>
            <td class="">{{invoice.billingName}}</td>
            <td class="separador"></td>
            <td class="">{{invoice.contactName}}</td>
        </tr>
        <tr>
            <td class=""></td>
            <td class="separador"></td>
            <td class="">{{invoice.billingAddress}}</td>
            <td class="separador"></td>
            <td class="">{{invoice.contactEmail}}</td>
        </tr>
        <tr>
            <td class=""></td>
            <td class="separador"></td>
            <td class="">{{invoice.billingCity}} {{invoice.billingProvince}} {{invoice.billingCountry}}</td>
            <td class="separador"></td>
            <td class="">{{invoice.contactPhonePrefix}} {{invoice.contactPhone}}</td>
        </tr>
    </table>

    <table class="block">
        <tr class="line total">
            <td class="prompt" colspan="3">{{'GENERAL.HARDMAN.INVOICE.TOTAL' | translate}}</td>
            <td class="amount">{{invoice.totalAmount | price }} {{invoice.currency}}</td>
        </tr>
        <tr class="line subtotal impostos">
            <td class="prompt" colspan="3">{{'GENERAL.HARDMAN.INVOICE.TAXES' | translate :{ vatType: invoice.taxName} }}
            </td>
            <td class="amount">{{invoice.taxes | price }} {{invoice.currency}}</td>
        </tr>
        <tr class="line subtotal import">
            <td class="prompt" colspan="3">{{'GENERAL.HARDMAN.INVOICE.BASE' | translate}}</td>
            <td class="amount">{{invoice.amount | price }} {{invoice.currency}}</td>
        </tr>
        <ng-template ngFor let-product [ngForOf]="invoice.products">
            <tr class="producte">
                <td class="titol">{{buildProductCaption(product.product) | translate}}</td>
                <td class="quantity">{{product.quantity | quantity}}</td>
                <td class="previsionQuantity">{{product.previsionQuantity | quantity}}</td>
                <td class="amount">{{product.amount | price}} {{invoice.currency}}</td>
            </tr>
            <tr class="help">
                <td class="help">{{buildProductHelp(product.product)| translate}}</td>
            </tr>
        </ng-template>
        <ng-template ngFor let-discount [ngForOf]="invoice.discounts">
            <tr class="descompte">
                <td class="titol" colspan="3">{{buildDiscountCaption(discount.discountTypeId) | translate}}
                </td>
                <td class="amount">{{discount.amount | price}} {{invoice.currency}}</td>
            </tr>
            <tr class="help descompte-help">
                <td class="help">{{buildDiscountHelp(discount.discountTypeId) | translate}}</td>
            </tr>
        </ng-template>
    </table>
</div>