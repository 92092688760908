import { Component, Input } from '@angular/core';
import { DateHourFilter } from '@shared/src/filters/DateHourFilter';
import { HDateHour } from '../../../../datatypes/HDateHour';


@Component({
  selector: 'datehour-viewer',
  templateUrl: './datehour.viewer.component.html'
})

export class DateHourViewerComponent {

  constructor(private dateHourFilter: DateHourFilter) {

  }

  public isNotNull: boolean = true;
  _value: HDateHour;
  valueOriginal: HDateHour;
  valueUser: HDateHour;
  @Input() set value(param: HDateHour) {
    this._value = param;
    this.valueOriginal = param;
    this.valueUser = HDateHour.toUserLocal(param);
    this.isNotNull = !HDateHour.isNullOrNullValue(param);
    this.calculateShowingReactive();
  }
  get value(): HDateHour {
    return this._value;
  }

  @Input()
  public showOriginal: boolean = true;

  @Input()
  public showNulls: boolean = true;

  public showingTimeZone: boolean = true;
  @Input()
  public _showTimeZone: boolean = true;
  @Input() set showTimeZone(param: boolean) {
    this._showTimeZone = param;
    this.showingTimeZone = param;
  }
  get showTimeZone(): boolean {
    return this._showTimeZone;
  }

  public showingReactive: boolean = false;
  @Input()
  public _reactive: boolean = false;
  @Input() set reactive(param: boolean) {
    this._reactive = param;
    this.calculateShowingReactive();
  }
  get reactive(): boolean {
    return this._reactive;
  }

  public calculateShowingReactive() {
    if (this.reactive && !HDateHour.isNullOrNullValue(this._value)) {
      this.showingReactive = true;
    }
  }

  public onClick() {
    if (this.showingTimeZone || this.showingReactive)
      this.showOriginal = !this.showOriginal;
    this.showingTimeZone = true;
    this.showingReactive = false;
  }
}