<!-- shared.chats.html !-->
<div class="chats" *ngIf="chats" [ngClass]="{'ionic': useIonic}">
    <div class="chats-body-zonachatsleft"
        [ngClass]="{'detail' : showdetail, 'chat' : !showselectcontact, 'contactes' : showselectcontact}">
        <div class="chats-header">
            <div class="title-search">
                <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchtext"
                    (ngModelChange)="this.searchTextChanged.next($event)">
                <i class="chats-search fa fa-search" aria-hidden="true" (click)="onSearchTextChange()"></i>
            </div>
            <i class="chats-back fa fa-times" aria-hidden="true" (click)="showselectcontact=false"></i>
        </div>

        <div class="chats-body">
            <div *ngIf="!showselectcontact" class="list-zone" infiniteScroll [infiniteScrollDistance]="2"
                (scrolled)="onScrollChats()" [scrollWindow]="false" [fromRoot]="true">
                <shared-chatitem *ngFor="let item of chatsList | sortBy:'-lastDateHour'" (click)="onSelectedChat(item)"
                    [ownerUserId]="chats.ownerUserId" [showdetail]="showdetail" [value]="item"
                    [ngClass]="{'selected':isSelected(item)}">
                </shared-chatitem>

            </div>
            <div *ngIf="showselectcontact" class="list-zone" infiniteScroll [infiniteScrollDistance]="2"
                (scrolled)="onScrollContacts()" [scrollWindow]="false" [fromRoot]="true">
                <div *ngFor="let itemcontact of contacts | sortBy:'__order'" class="shared-chatitem contact-item"
                    (click)="clickContact(itemcontact)">
                    <shared-chat-avatar class="shared-chatitem chat-header-avatar" [participant]="itemcontact"
                        [width]=50 [height]=50>
                    </shared-chat-avatar>
                    <div class="shared-chatitem resta">
                        <div class="shared-chatitem chat-header-key">{{itemcontact.skin}}</div>
                        <div class="shared-chatitem chat-header-lastmessage">{{itemcontact.participantAnnotation}}</div>
                    </div>
                </div>
            </div>
            <div class="boto-buscar-contacte button" [ngClass]="{'detail' : showdetail}"
                (click)="showselectcontact=!showselectcontact">
                <i class="boto-buscar-contacte-icon fa fa-commenting" [ngClass]="{'hidden': showselectcontact}"
                    aria-hidden="true"></i>
                <i class="boto-buscar-contacte-icon fa fa-comment"
                    [ngClass]="{'hidden': !showselectcontact, 'back': showselectcontact}" aria-hidden="true"></i>
                <i class="boto-buscar-contacte-icon fa fa-user petit"
                    [ngClass]="{'hidden': !showselectcontact, 'back': showselectcontact}" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <shared-chat class="chats-body-zonachatsright" [ngClass]="{'detail' : showdetail}" [value]="selectedchat"
        (onChatBack)="chatBack($event)" [ownerUserId]="chats.ownerUserId" [ownerUserType]="chats.ownerUserType"
        (clickParticipant)="clickContact($event)"> </shared-chat>
</div>