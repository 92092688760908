import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { HDate } from '../../../../datatypes/HDate';
import * as moment_ from 'moment-timezone';
const moment = moment_;

@Directive({
  selector: '[validateDate][ngModel],[validateDate][formControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateValidator), multi: true }
  ]
})
export class DateValidator implements Validator {

  public constructor() { }

  public static validate(control: AbstractControl): ValidationErrors | null {
    return DateValidator.validateControl(control, true);
  }

  public static validateWithOutNullDate(control: AbstractControl): ValidationErrors | null {
    return DateValidator.validateControl(control, false);
  }

  public static validateControl(control: AbstractControl, withnulldate: boolean): ValidationErrors | null {

    if (!control)
      return DateValidator.getInvalidResult();
    let value = control.value;

    if (!withnulldate && !value)
      return null;

    if (!value)
      return DateValidator.getInvalidResult();

    let hdate: HDate = value;

    if (value instanceof Date)
      hdate = HDate.build(value);
    else if (moment.isMoment(value))
      hdate = HDate.buildFromMoment(value);
    else if (value instanceof HDate)
      hdate = HDate.buildFromHDate(value);
    else {
      // let temp = moment(value.toString());
      const IsoDateTo = moment(value).format('YYYY-MM-DD');
      let temp = moment(IsoDateTo);
      if (moment.isMoment(temp))
        hdate = HDate.buildFromMoment(temp);
    }

    if (withnulldate && HDate.isNullOrNullValue(hdate))
      return DateValidator.getInvalidResult();

    if (!HDate.isValidDate(hdate))
      return DateValidator.getInvalidResult();

    return null;
  }

  public static isValid(value: any): boolean {
    if (!value)
      return true;

    let hdate: HDate = value;

    if (value instanceof Date)
      hdate = HDate.build(value);
    else if (moment.isMoment(value))
      hdate = HDate.buildFromMoment(value);
    else if (value instanceof HDate)
      hdate = HDate.buildFromHDate(value);
    else {
      let temp = moment(value.toString());
      if (moment.isMoment(temp))
        hdate = HDate.buildFromMoment(temp);
    }

    if (HDate.isValidDate(hdate))
      return true;

    return false;
  }

  public validate(c: AbstractControl): ValidationErrors | null {
    return DateValidator.validate(c);
  }


  private static getInvalidResult(): ValidationErrors {
    return {
      validateDate: false
    };
  }
}