import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { HomeController } from '@shared/src/controllers/home/home.controller';;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { AlertService, DateHourValidator } from '@shared/src/public-api';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { OpenUuidExwork } from '@shared/src/controllers/open/OpenUuidExwork';
import { BookingSimpleDto } from '@shared/src/dtos/booking/BookingSimpleDto';
import { HLong } from '@shared/src/datatypes/HLong';

@Component({
    selector: 'shared-exwork-routebooking-component',
    templateUrl: './shared.exwork.routebooking.component.html',
    styleUrls: ['./shared.exwork.routebooking.component.scss']
})
export class SharedExworkRouteBookingComponent implements OnInit {


    public useIonic: boolean = false;
    constructor(public fb: FormBuilder, protected socketController: SocketController, public router: Router, protected activatedRoute: ActivatedRoute,
        @Inject('HomeController') protected homeController: HomeController,
        public openRouteController: OpenRouteController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {


        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }
    public formGroup: FormGroup;
    public createForm() {
        this.formGroup = this.fb.group({
            "bookingNumber": ['', [Validators.required]],
            "shipName": ['', Validators.nullValidator],
            "datetimeStart": ['', Validators.nullValidator],
            "datetimeEnd": ['', Validators.nullValidator],
            "customsWarehouse": ['', Validators.nullValidator],
            "places": ['', Validators.nullValidator],
            "depositStart": ['', [DateHourValidator.validate, Validators.required]],
            "depositEnd": ['', [DateHourValidator.validate, Validators.required]],
            "userTags": ['', Validators.nullValidator],
            "partnerFreightForwarderId": ['', Validators.nullValidator],
            "partnerShipperId": ['', Validators.nullValidator],
            "departureAddressId": ['', Validators.nullValidator],
            "arrivalAddressId": ['', Validators.nullValidator],
        });
    }
    ngOnInit(): void {
        this.createForm();
    }

    isNullOrNullLong(value: number): boolean{
        return HLong.isNullOrNullLong(value);
    }
    
    public filterKeysPortAddressDeparture: Array<string> = ["PORT_ADDRESS_DEPARTURE"];
    public filterKeysPortAddressArrival: Array<string> = ["PORT_ADDRESS_ARRIVAL"];
   
    public readonly: boolean = false;

    public _value: OpenUuidExwork;
    @Input()
    public get value(): OpenUuidExwork {
        return this._value;
    }
    public set value(valor: OpenUuidExwork) {
        if (this._value === valor)
            return;
        this._value = valor;
        this.getData();
    }

    public _uuid: string = "";
    @Input()
    public get uuid(): string {
        return this._uuid;
    }
    public set uuid(valor: string) {
        if (this._uuid === valor)
            return;
        this._uuid = valor;
        this.getData();
    }

    public booking: BookingSimpleDto = null;
    public getData() {
        this.openRouteController.getExworkBooking(this.uuid).subscribe(data => {
            this.wellCome(data);
        });
    }
    public wellCome(booking: BookingSimpleDto) {
        this.booking = booking;
        if (this.booking)
            this.editMode = this.booking.empty;
    }
    @Output() onRefreshData: EventEmitter<BookingSimpleDto> = new EventEmitter<BookingSimpleDto>();
    public save() {
        this.openRouteController.putExworkBooking(this.uuid, this.booking).subscribe(data => {
            this.wellCome(data);
            this.onRefreshData.next(data);
        });
    }

    public editMode: boolean = false;
    public startEditmode() {
        this.editMode = true;
    }
    public endEditmode() {
        this.editMode = false;
        this.getData();
    }
    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}