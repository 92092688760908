import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { DateValidator } from '../../form/validations/DateValidator';
import { HDate } from '../../../../datatypes/HDate';
import { HomeController } from '../../../../controllers/home/home.controller';
import { Moment } from 'moment';
import * as moment_ from 'moment-timezone';
import { MAT_DATE_FORMATS } from '@angular/material/core';
const moment = moment_;

// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'D/M/YYYY',
  },
  display: {
    dateInput: 'D/M/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DatePickerComponent, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class DatePickerComponent extends ValueAccessorValidatedBase<any, HDate> {



  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormDatePicker = this.fb.group({
      'dtpvalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: any): boolean {
    if (!this.useIonic) {
      return DateValidator.isValid(value);
    } else {
      let temp: Moment = moment(value);
      return DateValidator.isValid(temp);
    }
  }

  public rFormDatePicker: FormGroup;

  @ViewChild('picker', { static: true }) public picker: any;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }

  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HDate> = new EventEmitter();
  @Output() beforeChange: EventEmitter<DatePickerValueChangedEvent> = new EventEmitter();

  _max: any;
  @Input() set max(selectedvalue: HDate) {
    if (!this.useIonic) {
      this._max = HDate.getMoment(selectedvalue);
    } else {
      this._max = HDate.getMoment(selectedvalue).toDate().toISOString();
    }
  }
  get max(): HDate {
    return HDate.buildFromMoment(this._max);
  }

  _min: any;
  @Input() set min(selectedvalue: HDate) {
    if (!this.useIonic) {
      this._min = HDate.getMoment(selectedvalue);
    } else {
      this._min = HDate.getMoment(selectedvalue).toDate().toISOString();
    }
  }
  get min(): HDate {
    return HDate.buildFromMoment(this._min);
  }

  /** si es modifica from, value es modifica, 
  * s'ha d'abançar el valor tants dies com diferencia hi hagi en l'actual */
  public _from: HDate;
  @Input() set from(selectedvalue: HDate) {

    // Actualitzem value tenint en compte l'actual from
    let actualvalue: HDate = HDate.buildFromMoment(this.value);
    if (!HDate.isNullOrNullValue(actualvalue) && !HDate.isNullOrNullValue(selectedvalue) && !HDate.isNullOrNullValue(this._from)) {
      let ndays = HDate.daysBetween(this._from, actualvalue);
      if (!this.useIonic) {
        this.value = HDate.getMoment(selectedvalue.addDays(ndays));
      } else {
        this.value = HDate.getMoment(selectedvalue.addDays(ndays)).toDate().toISOString();
      }
    }
    this._from = selectedvalue;
  }
  get from(): HDate {
    return this._from;
  }

  public identifier = 'date-picker-' + identifier++;

  externalValue(value: any): HDate {
    if (!value)
      return undefined;

    if (this.useIonic)
      value = moment(value.detail.value);

    let hdate: HDate = HDate.buildFromMoment(value);
    if (HDate.isNullOrNullValue(hdate))
      return null;
    return hdate;
  }
  internalValue(value: HDate): any {
    if (!value)
      return undefined;

    if (HDate.isNullOrNullValue(value))
      return null;
    if (!this.useIonic) {
      return HDate.getMoment(value);
    } else {
      return HDate.getMoment(value).toDate().toISOString();
    }
  }

  valorCanviat(antic: HDate, nou: HDate) {
    this.beforeChange.next(DatePickerValueChangedEvent.build(this.externalValue(antic), this.externalValue(nou)));
  }
  onChange(e) {
    if (this.useIonic) {
      this.valueChanged.next(this.externalValue(e));
    } else {
      this.valueChanged.next(this.externalValue(this.value));
    }
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  /***
   * Trasllada la date endavant o endarrera segons la distància entre dates de l'event.
   * Si canviem la data d'inici del 1/1 - 10/1 a 15/1 determina que hi han 14 dies de distància per tant la data fi ha de pasar a ser 24
   */
  public static translate(date: HDate, event: DatePickerValueChangedEvent): HDate {
    if (HDate.isNullOrNullValue(date) || event == null || HDate.isNullOrNullValue(event.beforeChange) || HDate.isNullOrNullValue(event.afterChange))
      return date;

    let distancia = HDate.distance(event.beforeChange, event.afterChange);
    return HDate.addDaysToHDate(date, distancia);
  }
}

let identifier = 0;

export class DatePickerValueChangedEvent {
  public beforeChange: HDate;
  public afterChange: HDate;

  public static build(before: HDate, after: HDate): DatePickerValueChangedEvent {
    let result = new DatePickerValueChangedEvent();
    result.beforeChange = before;
    result.afterChange = after;
    return result;
  }
}