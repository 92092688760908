import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TenantInfoDto } from '@shared/src/dtos/tenants/TenantInfoDto';
import { HardmanActivityTypes } from '@shared/src/enums/HardmanActivityTypes';
import { PhonePrefix } from '@shared/src/enums/PhonePrefix';
import { HomeController } from '../../../controllers/home/home.controller';

@Component({
  selector: 'shared-tenantinfo-component',
  templateUrl: './shared.tenantinfo.component.html',
  styleUrls: ['./shared.tenantinfo.component.scss']
})
export class SharedTenantInfoComponent implements OnInit {

  constructor(@Inject('HomeController') protected homecontroller: HomeController, private fb: FormBuilder) {

    this.tenantInfoFormGroup = fb.group({
      'company_trademark': ['', [Validators.required, Validators.maxLength(512)]],
      'company_description': ['', [Validators.required, Validators.maxLength(128)]],
      'company_vat_number': ['', [Validators.required, Validators.maxLength(15)]],
      'company_phone': ['', [Validators.required, Validators.maxLength(25)]],
      'company_contact_name': ['', [Validators.nullValidator, Validators.maxLength(25)]],
      'company_phone_prefix': [null, [Validators.nullValidator]],
      'company_activity_type': [null, [Validators.nullValidator]],
      'company_address': ['', [Validators.required, Validators.maxLength(512)]],
      'time_threshold_telemetry_report': ['', [Validators.nullValidator]],
      'distance_threshold_telemetry_report': ['', [Validators.nullValidator, Validators.min(0)]],
      'company_html_mail_signature': ['', [Validators.maxLength(64)]]
    });

    this.useIonic = homecontroller.useIonic();
    this.isIos = this.homecontroller.getOS() == 'ios';
  }

  public useIonic: boolean = false;
  public isIos: boolean = false;

  public tenantInfoFormGroup: FormGroup;
  public enumCompanyActivityTypes = HardmanActivityTypes;
  public translategroupType: string = 'COMPANY_ACTIVITY_TYPE';

  @Input()
  public readonly: boolean = false;

  ngOnInit() {
    if (this._tenantInfo != null && this._tenantInfo.hardmanActivityType == null)
      this._tenantInfo.hardmanActivityType = HardmanActivityTypes.Other;
  }

  private _tenantInfo: TenantInfoDto;
  @Input() set tenantInfo(value: TenantInfoDto) {
    if (this._tenantInfo === value)
      return;
    this._tenantInfo = value;
  }

  get tenantInfo(): TenantInfoDto {
    return this._tenantInfo;
  }

  @Output()
  public onValidationChange: EventEmitter<boolean> = new EventEmitter();


  ngAfterViewInit() {
    this.tenantInfoFormGroup.valueChanges.subscribe((change) => {
      this.onValidationChange.emit(this.tenantInfoFormGroup.valid);
    })
  }

  pretty(data: any): string {
    return JSON.stringify(data);
  }

  public enumPrefixes = PhonePrefix;
  public translategroup: string = 'PHONEPREFIX.ENUM';

  public _prefixModel: PhonePrefix = PhonePrefix.Spain_34;
  set prefixModel(value: PhonePrefix) {
    if (this._prefixModel === value)
      return;

    this._prefixModel = value;
    this.tenantInfo.prefix = PhonePrefix[this._prefixModel];
    console.log(this.tenantInfo.prefix);
  }
  get prefixModel(): PhonePrefix {
    return this._prefixModel;
  }
}
