

export class FilterTruckingShipmentDto {

	public idReserca: string;
	
    public filterKeys: String[];

	public searchText: string;
	
	public sliceCount: number = 100;

	public static buildPage(searchtext: string, activeFilterKeys: Array<string>): FilterTruckingShipmentDto {
		let result = new FilterTruckingShipmentDto();
		result.searchText = searchtext;
		result.filterKeys = activeFilterKeys;
		return result;
	}
}
