<!-- text.component.html!-->
<div [formGroup]="rFormText" class="textarea">
    <div *ngIf="!useIonic">
        <mat-form-field [ngClass]="{'textarea-with-helppoint': showHelpPoint}">
            <!-- <mat-label>{{ placeholder }}</mat-label>!-->
            <textarea matInput [id]="identifier" [placeholder]="placeholder" [(ngModel)]="value" [readonly]="readonly"
                formControlName="dtpvalidator" (change)="onChange($event)" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" (keydown.enter)="keyEnter($event)">
            </textarea>
        </mat-form-field>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div *ngIf="useIonic" class="ionic-textarea">
        <!-- <ion-label>{{ placeholder }}</ion-label> -->
        <ion-textarea [ngClass]="{'error': !rFormText.valid}" [id]="identifier" [placeholder]="placeholder"
            [(ngModel)]="value" [readonly]="readonly" formControlName="dtpvalidator" ngDefaultControl
            (ionChange)="onChange($event)">
        </ion-textarea>

    </div>
</div>