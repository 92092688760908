import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserListMaintenanceDto } from './UserListMaintenanceDto';
import { UserItemMaintenanceDto } from './UserItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { PermissionGroupListMaintenanceDto } from '../permissiongroup/PermissionGroupListMaintenanceDto';

@Injectable()
export class UserMaintenanceController extends BaseMaintenanceController<UserListMaintenanceDto, UserItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "system/securityandnotifications/user");
    }



    public getRoleTypesPerUser(userId: number): Observable<Array<String>> {
        return this.get(this.getBaseUrl() + "/roletypes/peruser/" + userId)
    }
    public getRoleTypesPerGroup(userId: number): Observable<Array<String>> {
        return this.get(this.getBaseUrl() + "/roletypes/pergroup/" + userId)
    }
    public getRoleTypesAll(userId: number): Observable<Array<String>> {
        return this.get(this.getBaseUrl() + "/roletypes/all/" + userId)
    }

    getInvitationLink(userId: number): Observable<string> {
        return this.get(this.getBaseUrl() + "/invitationlink/" + userId)
    }


    public getResourceRoot(): string {
        return "USER";
    }

    public buildUrl(uri: string): string {
        return "/system/securityandnotifications/user" + uri;
    }
}
