<div class="container-statistics">
    <div class="statistics-header">
        <!--
        <mat-expansion-panel [expanded]=false>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'FILTER.PROMPT' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'STATISTICS.FILTERRESUMEE' | translate:{ dateFrom: filter.dateHourStart | datehour, dateTo:
                    filter.dateHourEnd | datehour} }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <shared-address-ambit-selector-component class="selector-component" [(value)]="addressAmbit"
                [(addressId)]="filter.addressId" [(ambitId)]="filter.ambitId"
                (changeValues)="onChangeAddressAmbitSelector($event)">

            </shared-address-ambit-selector-component>
            <div class="dates">
                <datehour-picker class="date" [(ngModel)]="filter.dateHourStart"
                    placeholder="{{'GATE.LOG.DATEFROM' | translate}}" name="dateHourStart" [showLocaleObserver]=false
                    [(to)]="filter.dateHourEnd">
                </datehour-picker>
                <datehour-picker class="date" [(ngModel)]="filter.dateHourEnd"
                    placeholder="{{'GATE.LOG.DATETO' | translate}}" name="dateHourStart" [showLocaleObserver]=false>
                </datehour-picker>

            </div>
            <enum-selector class="enum-selector" [enumvalues]="enumBlames" translategroup="PHASETIMES.AGREEMENTS"
                [(ngModel)]="filter.blame"
                placeholder="{{'PHASETIMESTABLE.BLAMES.HEADING' | translate}}"></enum-selector>
        </mat-expansion-panel>
        -->
        <div class="statistics-title-zone">
            <div class="statistics-title">{{'SHARED.ACCESSCONTROL.STATUS.COMPONENT.TITLE'|translate}}</div>
            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">
            <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div *ngIf="data!=null && data!=undefined" class="content">
        <!-- taula llistat -->
        <div class="item" *ngFor="let item of data | sortBy:'order'" [ngClass]="computeGeneralClass(item)">
            <div class="line">
                <div class="seccio key">
                    {{item.accessControlKey}} - {{item.surname}}
                </div>
            </div>
            <div class="line help-text">
                {{item.description}}
            </div>
            <div class="line">
                <div class="seccio box conjunta marginGeneralExit">
                    <div class="firstcolumn">
                        <div class="title">
                            {{'ACCESSCONTROLSTATUS.GENERAL.PROMPT'|htranslate}}
                        </div>
                        <div class=" data ">
                            {{item.marginGeneralExit | date}} <span class="hora"> {{item.marginGeneralExit |
                                datehouronlyhournotz}}</span>
                        </div>
                    </div>
                    <div class="secondcolumn" [ngClass]="getClassEndarreriment(item.marginParkingExit)">
                        <div class="temps">
                            {{getEndarreriment(item.delayHDateHour)}}
                        </div>
                    </div>
                </div>
                <div class="seccio box conjunta marginLoadingExit">
                    <div class="firstcolumn">
                        <div class="title">
                            {{'ACCESSCONTROLSTATUS.LOADING.PROMPT'|htranslate}}
                        </div>
                        <div class=" data ">
                            {{item.marginLoadingExit | date}} <span class="hora"> {{item.marginLoadingExit |
                                datehouronlyhournotz}}</span>
                        </div>
                    </div>
                    <div class="secondcolumn" [ngClass]="getClassEndarreriment(item.marginLoadingExit)">
                        <div class="temps">
                            {{getEndarreriment(item.marginLoadingExit)}}
                        </div>
                    </div>
                </div>
                <div class="seccio box conjunta marginParkingExit">
                    <div class="firstcolumn">
                        <div class="title">
                            {{'ACCESSCONTROLSTATUS.PARKING.PROMPT'|htranslate}}
                        </div>
                        <div class=" data ">
                            {{item.marginParkingExit | date}} <span class="hora"> {{item.marginParkingExit |
                                datehouronlyhournotz}}</span>
                        </div>
                    </div>
                    <div class="secondcolumn" [ngClass]="getClassEndarreriment(item.marginParkingExit)">
                        <div class="temps">
                            {{getEndarreriment(item.marginParkingExit)}}
                        </div>
                    </div>
                </div>
                <div class="seccio gateboxes">
                    <div class="gateboxes-container">
                        <div class="gatebox" *ngFor="let gate of item.gates" [ngStyle]="getStyleGateTag(gate)">
                            <div class="gateName">{{gate.gateName}}</div>
                            <div class="count">{{gate.count}}</div>
                        </div>
                    </div>
                    <div class="box explicacio" [innerHTML]="item.explicacio | safe: 'html'"></div>
                </div>
            </div>
        </div>
    </div>
</div>