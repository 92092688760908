import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenPanelController } from '@shared/src/controllers/open/open.panel.controller';
import { SecurityController } from "@shared/src/controllers/security/security.controller";
import { UserSettingController, UserSettingsEnum } from '@shared/src/controllers/user/usersetting.controller';
import { PanelColumnDto } from '@shared/src/dtos/panel/column/PanelColumnDto';
import { PanelElementFilterDto } from '@shared/src/dtos/panel/column/element/PanelElementFilterDto';
import { PanelElementInfoDto } from '@shared/src/dtos/panel/column/element/PanelElementInfoDto';
import { SetElementColumnDto } from '@shared/src/dtos/panel/column/element/SetElementColumnDto';
import { PanelTypes } from '@shared/src/enums/PanelTypes';
import { HColor } from '@shared/src/public-api';
import { Subscription, timer as observableTimer } from 'rxjs';

@Component({
  selector: 'shared-panel-element-info-component',
  templateUrl: './shared.panel.element.info.component.html',
  styleUrls: ['./shared.panel.element.info.component.scss'],
})
export class SharedPanelElementInfoComponent implements OnInit, OnDestroy {

  public useIonic: boolean = false;
  constructor(public router: Router, 
    protected userSettingController: UserSettingController,
    private openPanelController: OpenPanelController, protected translateService: TranslateService,
    @Inject('SecurityController') public securityController: SecurityController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }


  @Input() public readonly: boolean = false;

  public __elementFilter: PanelElementFilterDto;
  @Input()
  set elementFilter(value: PanelElementFilterDto) {
    if (this.__elementFilter === value)
      return;
    this.__elementFilter = value;
    this.loadElementData();
  }
  get elementFilter(): PanelElementFilterDto {
    return this.__elementFilter;
  }

  public __value: PanelElementInfoDto;
  set value(value: PanelElementInfoDto) {
    if (this.__value === value)
      return;
    this.__value = value;
  }
  get value(): PanelElementInfoDto {
    return this.__value;
  }

  public loadElementData() {

    if(this.elementFilter == null)
      return;
    this.openPanelController.getPanelElementInfo(this.elementFilter).subscribe(data => {
      this.value = data;
      this.calculateResourceObject();
      if (data != null) {
        this.activaTimer();
      }
    });
  }

  calculateResourceObject(){
    let tresource = "";
    if(this.value != null){
      if(this.value.panelType == PanelTypes.Route)
        tresource = "SHARED.PANEL.ELEMENT.INFO.COMPONENT.OPTION.ROUTE.PROMPT";
      else if(this.value.panelType == PanelTypes.Phase)
        tresource = "SHARED.PANEL.ELEMENT.INFO.COMPONENT.OPTION.PHASE.PROMPT";
      else if(this.value.panelType == PanelTypes.Shipment)
        tresource = "SHARED.PANEL.ELEMENT.INFO.COMPONENT.OPTION.SHIPMENT.PROMPT";
    }
    this.resourceObject = tresource;
  }
  public resourceObject: string = "";
  getResourceObject(){
    return this.resourceObject;
  }

  getOpcioObject(): string {
    if(this.value != null){
      if(this.value.panelType == PanelTypes.Route)
        return "route";
      else if(this.value.panelType == PanelTypes.Phase)
        return "phase";
      else if(this.value.panelType == PanelTypes.Shipment)
        return "shipment";
    }
    return null;
  }

  public opcio: string = "";
  public setOpcio(value: string) {
    if (this.opcio !== value) {
      this.opcio = value;
      this.userSettingController.setSetting(UserSettingsEnum.DefaultPanelElementOption, this.opcio).subscribe(data => {
        if (this.opcio === "panel")
          this.loadElementData();
      });
    }
  }


  ngOnInit(): void {
    this.userSettingController.getSetting(UserSettingsEnum.DefaultPanelElementOption).subscribe(data => {
      if (data && data != "")
        this.opcio = data;
      else
        this.setOpcio("panel");
    });
  }

  onColumnClick(column: PanelColumnDto){
    if(this.value == null || column == null)
      return;
    this.openPanelController.changeElementColumn(SetElementColumnDto.build(this.value.elementId, column.panelColumnId)).subscribe(data => {
      if (data)
        this.loadElementData();
    });
  }

  public getColumnStyle(column: PanelColumnDto) {
    if (column != null && column.backColor != null) {
        let bcolor: string = column.backColor.web;

        return {
            "color": HColor.getWhiteBlackFromColor(bcolor),
            "background-color": bcolor
        };
    }

    return {};
}

  ngOnDestroy() {
    this.desactivaTimer();
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 60000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(SharedPanelElementInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS, SharedPanelElementInfoComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.timerSubscription = timer.subscribe(t => {
      this.desactivaTimer();
      this.loadElementData();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

}
