import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerProviderSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.PROVIDER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/provider/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}