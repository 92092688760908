import { Injectable, Pipe, PipeTransform } from "@angular/core";
import * as moment_ from 'moment-timezone';
const moment = moment_;

@Pipe({
	name: 'percentage'
})
@Injectable()
export class PercentageFilter implements PipeTransform {
    transform(value: number): string {

        if(value == null)
            return "0.00%";

        let result: string;
        let partsencera = Math.floor(value);
        let partdecimal = Math.floor(value - partsencera) * 100;

        result = this.pad(partsencera.toString(), 1) + "," + this.pad(partdecimal.toString(), 2) + "%";

        return result;
    }

    pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - Math.max(num.length, size));
    }
}