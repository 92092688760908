import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class ShipmentEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): ShipmentEntitySelectorDto{
        let result: ShipmentEntitySelectorDto = new ShipmentEntitySelectorDto();
		result.loadData(EntitysSelectors.Shipment, "", activeFilterKeys, 0, false);
        return result;
    }

    public getUrlGoToMaintenance(): string {
		return "activity/shipment";
	}

	public getUrlBack(): string {
		return "/maintenance/shipment";
	}
    
}