<ng-container *ngIf="phase">
    <a class="button-icon-marker marker" (click)="onMarkerClick()"><i class="fa fa-map-marker"></i></a>
    <div class="info-phase">
        <div class="line first-line">
            <div class="square-tag executionorder">
                {{phase.executionOrder+1}}
            </div>
            <div class="square-tag load" *ngIf="phase.isLoad">
                <i class="hardman-routetype-outbound iconaInboundReturnNonacceptance"></i>
                {{'PHASE.ISLOAD'| translate}}
            </div>
            <div class="square-tag unload" *ngIf="phase.isUnload">
                <i class="hardman-routetype-inbound iconaInboundReturnNonacceptance"></i>
                {{'PHASE.ISUNLOAD'| translate}}
            </div>
            <div class="square-tag unloadload" *ngIf="phase.isUnloadLoad">
                <i class="hardman-routetype-delegate iconaInboundReturnNonacceptance"></i>
                {{'PHASE.ISUNLOADLOAD'| translate}}
            </div>
            <shared-tags-strip-component class="square tags"
                [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
        </div>
        <div class="line">
            <shared-tags-strip-component class="tags" [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
            <shared-tags-strip-component *ngIf="phase.showStrongAddress" class="tags"
                [uniqueString]="phase.strongAddressSkin"></shared-tags-strip-component>
            <span class="formatted-address">{{phase.formattedAddress}}</span>
        </div>

        <div class="line">
            <shared-tags-strip-component class="tags" [tags]="phase.userTags"></shared-tags-strip-component>
            <div class="docks">
                <div *ngFor="let dock of (phase.docksEnabled ? phase.docksEnabled : [])" class="enabled-dock">{{ dock }}
                </div>
            </div>
        </div>

        <div class="line goods">
            {{phase.goods}}
        </div>
        <div class="line" *ngIf="phase.verificationCode">
            <shared-secret [iPhaseSlot]="phase"></shared-secret>
        </div>

        <div class="line slot">
            <div class="dockInfo" *ngIf="phase.dockSkin != null && phase.dockSkin.length > 0"
                [style.color]="getTextColor(phase.dockBackgroundColor)"
                [style.background-color]="getBackColor(phase.dockBackgroundColor)">{{ phase.dockSkin }}</div>
            <shared-slot-viewer-component *ngIf="phase.slotInfo" class="relative" [slotInfo]="phase.slotInfo">
            </shared-slot-viewer-component>
        </div>
        <button class="line link right" *ngIf="!phase.isSlotBlocked && phase.canSetSlot && !hideLink"
            (click)="getSlots()">{{ getSlotPickerResource() | translate}}</button>

    </div>
    <shared-slot-picker *ngIf="intervals && phase.canSetSlot" class="relative activeslots" [phase]="phase"
        [hideTitle]="true" [canviSlot]="true" (valueChange)="onRefreshRoute($event)"></shared-slot-picker>
</ng-container>