import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { CustomTableListMaintenanceDto } from './CustomTableListMaintenanceDto';
import { CustomTableItemMaintenanceDto } from './CustomTableItemMaintenanceDto';
import { BaseMaintenanceController } from '../../../_base/BaseMaintenanceController';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { Observable } from 'rxjs-compat';

@Injectable()
export class CustomTableMaintenanceController extends BaseMaintenanceController<CustomTableListMaintenanceDto, CustomTableItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "customtable");
    }

    public getResourceRoot(): string {
        return "CUSTOMTABLE";
    }

    public buildUrl(uri: string): string {
        return "/system/dataandparameters/customtable" + uri;
    }

    public importCustomTable(id: number, val: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.getBaseUrl() + "/import/" + id, val, "importCustomTable");
    }
    
    public exportCustomTable(id: number) : Observable<FileUpDownLoadDto> {
        return this.getBusy(this.getBaseUrl() + "/export/" + id);
    }
} 