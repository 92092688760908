import {Injectable} from '@angular/core';
import { RouteShipmentConditionTypes } from '@shared/src/enums/RouteShipmentConditionTypes';
import { HDateHour, HHourIntervals, HTriState } from '@shared/src/public-api';

@Injectable()
export class OpenChangeConditionDto {

    public shipmentId: number;
    public type: RouteShipmentConditionTypes;
    public valueDateHour: HDateHour;
	public valueHourIntervals: HHourIntervals;
	public valueDouble: number;
	public valueTriState: HTriState;

    public static build(shipmentId: number, type: RouteShipmentConditionTypes, valueDateHour: HDateHour, valueHourIntervals: HHourIntervals,
        valueDouble: number, valueTriState: HTriState) {
        const dto = new OpenChangeConditionDto();
        dto.shipmentId = shipmentId;
        dto.type = type;
        dto.valueDateHour = valueDateHour;
        dto.valueHourIntervals = valueHourIntervals;
        dto.valueDouble = valueDouble;
        dto.valueTriState = valueTriState;
        return dto;
    }
}
