import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedAsideFactory } from "@shared/src/components/aside/shared.aside.factory";
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { StatusTableAddressResultDto } from '@shared/src/controllers/statistics/StatusTableAddressResultDto';
import { StatisticsController } from "@shared/src/controllers/statistics/statistics.controller";
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { HLong } from '@shared/src/datatypes/HLong';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { AddressAmbitSelectorDto } from '../../../components/address/shared.address.ambit.selector.component';
import { FilterStripDto } from '../../core/maintenance/filter/FilterStripDtos';

@Component({
    selector: 'shared-statustable-address',
    templateUrl: './shared.statustable.address.component.html',
    styleUrls: ['./shared.statustable.address.component.scss']
})
export class SharedStatusTableAddressComponent implements OnInit {
    public addressId: number;
    public ambitId: number;
    public searchText: string = "";
    public actions: ActionsStripDto;

    private _dateHourStart: HDateHour = HDateHour.now().addHours(-2);
    private _dateHourEnd: HDateHour = this._dateHourStart.addDays(1);

    public filterRouteStatus: FilterStripDto = new FilterStripDto();
    public filterPhaseStatus: FilterStripDto = new FilterStripDto();
    private selectedRouteStatus: String[];
    private selectedPhaseStatus: String[];

    constructor(public activatedRoute: ActivatedRoute, public router: Router, public statisticsController: StatisticsController,
        public translateService: TranslateService,
        @Inject('HomeController')
        public homeContoller: HomeController,
        @Inject('SharedAsideFactory')
        public sharedAsideFactory: SharedAsideFactory) {
    }
    ngOnInit(): void {
        this.loadParameters();
        this.actions = new ActionsStripDto();
        this.actions.add(ActionDto.DOWNLOAD());
        this.addressAmbit = AddressAmbitSelectorDto.buildSharedStatusTableAddress();
    }

    public haveSlotsProduct = false;
    public data: StatusTableAddressResultDto = null;
    loadData() {
        if (this.addressId == undefined || this.ambitId == undefined)
            return;

        this.haveSlotsProduct = this.homeContoller.hasProduct(HardmanProducts.slots);
        this.statisticsController.getStatusTable(this.addressId, this.ambitId, this._dateHourStart, this._dateHourEnd, this.selectedRouteStatus, this.selectedPhaseStatus).subscribe(data => {
            this.data = data;
            if (this.data && this.data.filterRouteStatus)
                this.filterRouteStatus = FilterStripDto.buildFromFilterStripDto(this.data.filterRouteStatus);
            if (this.data && this.data.filterPhaseStatus)
                this.filterPhaseStatus = FilterStripDto.buildFromFilterStripDto(this.data.filterPhaseStatus);
        })
    }

    filterRouteStatusUpdate() {
        let array: String[] = FilterStripDto.getActiveKeys(this.filterRouteStatus);
        this.selectedRouteStatus = array;
        this.loadData();
    }

    filterPhaseStatusUpdate() {
        let array: String[] = FilterStripDto.getActiveKeys(this.filterPhaseStatus);
        this.selectedPhaseStatus = array;
        this.loadData();
    }


    public loadParameters() {
        const routeParams = this.activatedRoute.snapshot.paramMap;

        let taddressId = routeParams.get("addressId");
        let tambitId = routeParams.get("ambitId");

        if (taddressId) {
            this.addressId = +taddressId;
        }
        if (tambitId) {
            this.ambitId = +tambitId;
        }
    }

    public _addressAmbit: AddressAmbitSelectorDto;
    public get addressAmbit(): AddressAmbitSelectorDto {
        return this._addressAmbit;
    }
    public set addressAmbit(value: AddressAmbitSelectorDto) {
        if (this._addressAmbit === value)
            return;
        this._addressAmbit = value;
    }

    public onChangeAddressAmbitSelector($event: AddressAmbitSelectorDto) {
        this._addressAmbit = $event;
        if ($event != null) {
            this.addressId = $event.addressId;
            this.ambitId = $event.addressId;
        } else {
            this.addressId = 0;
            this.ambitId = 0;
        }

        this.renewUri();
    }


    public set dateHourStart(value: HDateHour) {
        if (this._dateHourStart === value)
            return;
        this._dateHourStart = value;
    }
    public get dateHourStart(): HDateHour {
        return this._dateHourStart;
    }

    public set dateHourEnd(value: HDateHour) {
        if (this._dateHourEnd === value)
            return;
        this._dateHourEnd = value;
        this.loadData();
    }
    public get dateHourEnd(): HDateHour {
        return this._dateHourEnd;
    }


    public renewUri() {
        let object = {};
        if (!HLong.isNullOrNullLong(this.addressId))
            object['addressId'] = this.addressId;
        if (!HLong.isNullOrNullLong(this.ambitId))
            object['ambitId'] = this.ambitId;

        //this.router.navigate([".", {}], { queryParams: object, queryParamsHandling: '' });

    }

    onDblClick(item: any) {
        if (item == null)
            return;
        this.sharedAsideFactory.invokePhase(item.phaseId).then(value => {
            this.loadData();
        });
    }
    getResourceItem(value: any) {
        if (value == null)
            return;
        return 'GENERALSTATUS.' + value.toString().toUpperCase() + '.CAPTION';
    }

    //            <button class="refresh"(click) = "download()" > {{ '' | translate }}</button>

    onClickAction($event) {
        this.statisticsController.downloadStatusTable(this.addressId, this.ambitId, this._dateHourStart, this._dateHourEnd).subscribe((data: FileUpDownLoadDto) => {
            console.log(data);
            FileUpDownloadController.resultDownLoad(data);
        });
    }

    pretty(value) {
        return JSON.stringify(value);
    }
}
