import { Injectable } from '@angular/core';
import { BookingStatus, HDateHour, TimeZones } from '@shared/src/public-api';
import { BookingSlotDto } from './BookingSlotDto';
import { HTags } from "@shared/src/datatypes/HTags";

@Injectable()
export class BookingDto {
  public bookingId: number;
  public bookingNumber: string;
  public shipName: string;

  public status: BookingStatus;
  
  public maritimeAgencyId: number;
  public maritimeAgency: string;
  public customsWarehouse: string;

  public datetimeStart: HDateHour;
  public datetimeEnd: HDateHour;

  public depositStart: HDateHour;
  public depositEnd: HDateHour;

  public departureAddressId: number;
  public departureAddressLine1: string;
  public departureAddressLine2: string;
  public departureCity: string;
  public departurePostalCode: string;
  public departureProvince: string;
  public departureState: string;
  public departureCountry: string;
  public departureFormattedAddress: string;
  public departureTimezone: TimeZones;
  public departureLatitude: number;
  public departureLongitude: number;

  public arrivalAddressId: number;
  public arrivalAddressLine1: string;
  public arrivalAddressLine2: string;
  public arrivalCity: string;
  public arrivalPostalCode: string;
  public arrivalProvince: string;
  public arrivalState: string;
  public arrivalCountry: string;
  public arrivalFormattedAddress: string;
  public arrivalTimezone: TimeZones;
  public arrivalLatitude: number;
  public arrivalLongitude: number;

  public shipperId: number;
  public partnerShipperId: number;

  public freightForwarderId: number;
  public partnerFreightForwarderId: number;

  public places: number;
  public slots: Array<BookingSlotDto>;
  public slotsAvailable: number;
  public slotsOccupied: number;
  public routesManaged: number;
  public routesLoadedUnloaded: number;

  public isModifiable: boolean;
  public isOwn: boolean

  public hasConflicts: boolean;

  public internalTags: HTags;
  public userTags: HTags;
}