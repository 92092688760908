import { Component, Inject, OnInit } from '@angular/core';
import { MobileSecurityController } from '../controllers/security/mobile-security.controller';

@Component({
  selector: 'mobile-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {

  constructor(@Inject('SecurityController') private securityController: MobileSecurityController) { }


  ngOnInit() {
    this.securityController.logout();
  }
  ngAfterViewInit() {
    this.securityController.logout();
  }
  anarLogin() {
    this.securityController.goToLogin();
    this.securityController.goToHome();
  }

}
