import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ManagementModelRequirementsStructDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementsStructDto';

@Component({
  selector: 'shared-managementmodel-requirements-component',
  templateUrl: './shared.managementmodel.requirements.component.html',
  styleUrls: ['./shared.managementmodel.requirements.component.scss'],
})
export class SharedManagementModelRequirementsComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private routeController: RouteController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }


  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  

  @Input() public readonly: boolean = false;
  @Input() public showorder: boolean = true;

  public __value: ManagementModelRequirementsStructDto;
  @Input()
  set value(value: ManagementModelRequirementsStructDto) {
    if (this.__value === value)
      return;
    this.__value = value;
  }
  get value(): ManagementModelRequirementsStructDto {
    return this.__value;
  }

  ngOnInit() {

  }

    
  public onDataRefresh(index: number, refreshData: boolean) {
    if (refreshData)
      this.dataRefresh.next(refreshData);
  }


}
