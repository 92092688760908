import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ManagementModelObjectParentDto } from '@shared/src/dtos/managementmodel/ManagementModelObjectParentDto';
import { ManagementModelController } from '@shared/src/controllers/managementmodel/managementmodel.controller';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { ManagementModelRequirementResponseLogDto } from '@shared/src/dtos/managementmodel/ManagementModelRequirementResponseLogDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';

@Component({
  selector: 'shared-managementmodel-item',
  templateUrl: './shared.managementmodel.item.component.html',
  styleUrls: ['./shared.managementmodel.item.component.scss'],
})
export class SharedManagementModelItemComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private managementModelController: ManagementModelController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  @Input() public readonly: boolean = false;

  public __value: ManagementModelObjectParentDto;
  @Input()
  set value(value: ManagementModelObjectParentDto) {
    if (this.__value === value)
      return;
    this.__value = value;

    this.loadActions();
  }
  get value(): ManagementModelObjectParentDto {
    return this.__value;
  }


  public selectedOptionId: string = "REQUIREMENTS";
  public options: OptionsStripDto;
  public actions: ActionsStripDto;
  ngOnInit() {
    this.options = new OptionsStripDto();
    this.options.add(OptionDto.build("REQUIREMENTS", "DOCUMENTARYREVIEW.REQUIREMENTS.CAPTION", "DOCUMENTARYREVIEW.REQUIREMENTS.HELP", "", 10, -1, 1, true, false));
    this.options.add(OptionDto.build("LOGS", "DOCUMENTARYREVIEW.LOG.CAPTION", "DOCUMENTARYREVIEW.LOG.HELP", "", 20, -1, 1, false, false));
  }

  loadActions(){
    if(this.value)
      this.actions = ActionsStripDto.buildFromActionsStripDto(this.value.actions);
    else       
      this.actions = new ActionsStripDto();
  }

  public onClickOption(option: OptionDto) {
    if (option)
      this.selectedOptionId = option.optionId;

    if (this.selectedOptionId == 'LOGS') {
      this.loadLogs();
    }
  }
  
  public logs: ManagementModelRequirementResponseLogDto[];
  public loadLogs(){
    if(this.value == null || this.value.requirementsStruct == null)
      return;
      if(this.value.requirementsStruct.managementModelResponseId > 0){
        this.managementModelController.getLogsByResponseId(this.value.requirementsStruct.managementModelResponseId).subscribe(data => {
          this.logs = data;
        });
      }else
        this.logs = new Array();
  }

  @Output() actionExecute: EventEmitter<ActionDto> = new EventEmitter();
  public onClickAction(item: ActionDto) {
      this.actionExecute.next(item);
  }
    
  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  public onDataRefresh(value: boolean) {    
    this.dataRefresh.next(value);
  }

}
