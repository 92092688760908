<!-- hourintervals-picker.component.html!-->
<div [formGroup]="rFormHourIntervals" style="width:100%">
    <div *ngIf="!useIonic">
        <mat-form-field [ngClass]="{'readonly': readonly}">
            <input matInput [id]="identifier" formControlName="hourintervalsvalidator" [(ngModel)]="value"
                [placeholder]="placeholder" type="text" [readonly]="readonly" (change)="onChange($event)" (keydown.enter)="keyEnter($event)">
            <button mat-button *ngIf="showClearButton()" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div *ngIf="useIonic" class="hourintervals-ionic">
        <ion-input ionInput [ngClass]="{'error': !rFormHourIntervals.valid && !rFormHourIntervals.pristine}" [id]="identifier" 
            formControlName="hourintervalsvalidator" ngDefaultControl [(ngModel)]="value"
                [placeholder]="placeholder" type="text" [readonly]="readonly" (change)="onChange($event)">
        </ion-input>
        <i *ngIf="showClearButton()" aria-label="Clear" (click)="value=''" class="ionicicona fa fa-remove"></i>
    </div>
</div>