import { Injectable } from '@angular/core';
import { RouteShipmentTypes } from '@shared/src/enums/RouteShipmentTypes';
import { PhaseCardDto } from '../../phase/PhaseCardDto';
import { RouteCardDto } from '../RouteCardDto';
import { ShipmentReturnInBoundOutBoundDelegatedDto } from './ShipmentReturnInBoundOutBoundDelegatedDto';

@Injectable()
export class RouteReturnInBoundOutBoundDelegatedDto {
	public type: RouteShipmentTypes;
	public stepNumber: number;
	public originalShipments: Array<ShipmentReturnInBoundOutBoundDelegatedDto>;

	public routeId: number;
	public routeTitle: string;
	public routeKey: string;
	public basedOnRouteId: number;
	public freeReturn: boolean;
	public partnerDriverId: number;
	public partnerCarrierId: number;
	public partnerCarrier: string;
	public driverName: string;
	public driverLegalIdentification: string;
	public driverPhone: string;
	public plateA: string;
	public plateB: string;
	public plateC: string;

	//Si type == 'ReverseLogistics' i reverseLogisticsOriginOwn es true, vol dir que 'em venen a buscar algo' i les adreces d'origen han de ser meves i s'han d'aplicar filtre d'slots actius i les de destí han de ser del meu proveidor
	//Si type == 'ReverseLogistics' i reverseLogisticsOriginOwn es false, vol dir que 'vaig a buscar algo' i les adreces d'origen han de ser les del meu client i s'han d'aplicar filtre d'slots actius i les de destí son les meves
	public reverseLogisticsOriginOwn: boolean;
	public partnerReverseLogisticsId: number;

	public shipmentNumber: number;
	public shipmentsNumber: number;
	public shipments: Array<ShipmentReturnInBoundOutBoundDelegatedDto>;

	public autoArrived: boolean;
	public screenAddressId: number;
	public originDestinationClass: string;

	static buildFromPhase(type: RouteShipmentTypes, phase: PhaseCardDto): RouteReturnInBoundOutBoundDelegatedDto {
		let result: RouteReturnInBoundOutBoundDelegatedDto = this.buildEmpty(type);
		if (phase != null)
			result.basedOnRouteId = phase.routeId;
		return result;
	}
	static buildFromRoute(type: RouteShipmentTypes, route: RouteCardDto): RouteReturnInBoundOutBoundDelegatedDto {
		let result: RouteReturnInBoundOutBoundDelegatedDto = this.buildEmpty(type);
		if (route != null) {
			result.basedOnRouteId = route.routeId;
			result.partnerDriverId = route.partnerDriverId;
			result.partnerCarrierId = route.partnerCarrierId;
			result.plateA = route.plateA;
			result.plateB = route.plateB;
		}
		return result;
	}
	static buildEmpty(type: RouteShipmentTypes): RouteReturnInBoundOutBoundDelegatedDto {
		let result: RouteReturnInBoundOutBoundDelegatedDto = new RouteReturnInBoundOutBoundDelegatedDto();

		result.type = type;
		result.stepNumber = 0;
		result.shipments = [];

		return result;
	}
}
