import { HColor } from "@shared/src/datatypes/HColor";
import { HString } from "@shared/src/datatypes/HString";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";

export class DockDto {
    public dockId: number;
    public addressId: number;
    public key: string;
    public skin: string;
    public backgroundColor: HColor;
    public isDisabled: boolean;
    public isUnlimited: boolean;

    public assignable: boolean; // --> Pots assignarlo
    public occupied: boolean; // --> Ocupat
    public dockScore: number = 0; // --> Puntuació sobre el nivell d'adequació (com més criteris compleix, més
    // puntuació té)
    public dockClass: string = "";

    static default(addressId: number) {
        let d: DockDto = new DockDto();
        d.backgroundColor = HColor.build("#000000");
        d.addressId = addressId;
        return d;
    }

    public static build(addressId: number, dockId: number, key: string, skin: string, backgroundColor: HColor): DockDto {
        let d = new DockDto();
        d.addressId = addressId;
        d.dockId = dockId;
        d.key = key;
        d.skin = skin;
        d.backgroundColor = backgroundColor;
        return d;
    }

    public static buildByPhase(phase: PhaseCardDto): DockDto {
        if (phase == null || HString.isNullOrNullString(phase.dockSkin))
            return null;
        let d = new DockDto();
        d.addressId = phase.addressId;
        d.dockId = phase.dockId;
        d.key = phase.dockSkin;
        d.skin = phase.dockSkin;
        d.backgroundColor = phase.dockBackgroundColor;
        return d;
    }
}