
import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { BaseController } from '../../base.controller';
import { ListOfMaintenanceDto } from '../ListOfMaintenanceDto';
import { ItemMaintenanceDto } from './ItemMaintenanceDto';
import { ListMaintenanceDto } from './ListMaintenanceDto';
import { MaintenanceSearchByTextDto } from './MaintenanceSearchByTextDtos';
import { MaintenanceSettingsItemDTO } from './MaintenanceSettingsItemDTO';
import { MaintenanceSettingsNoneDTO } from './MaintenanceSettingsNoneDTO';
import { ObjectSecurityInfoDto } from './ObjectSecurityInfoDto';

export class BaseMaintenanceController<listDTO extends ListMaintenanceDto, itemDTO extends ItemMaintenanceDto> extends BaseController {

    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        protected baseUrl: string) {
        super(http, alertService, translateService, loadingService, router);
    }

    /***
     * Això serveix per als recursos, defineix l'arrel dels recursos. Es la baseUrl no ens compliquem a majúscules amb un trim
     */
    public getResourceRoot(): string {
        return this.baseUrl.toUpperCase().trim();
    }

    executeExtendedAction(modelId: number, actionId: string): Observable<itemDTO> {
        return this.postBusy(this.getBaseUrl() + "/executeaction", { "modelId": modelId, "actionId": actionId }, "BaseMaintenanceController.ExecuteExtendedAction." + this.baseUrl);
    }

    public search(maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<ListOfMaintenanceDto<listDTO>> {
        return this.post(this.getBaseUrl() + "/search", maintenanceSearchByText, "BaseMaintenanceController.Search." + this.baseUrl);
    }

    public searchPaged(maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<ListOfMaintenanceDto<listDTO>> {
        return this.post(this.getBaseUrl() + "/search-paged", maintenanceSearchByText, "BaseMaintenanceController.SearchPaged." + this.baseUrl);
    }

    public maintenanceSettingsForNone(): Observable<MaintenanceSettingsNoneDTO> {
        return this.get(this.getBaseUrl() + "/maintenancesettingsfornone");
    }

    public newModel(): Observable<itemDTO> {
        return this.get(this.getBaseUrl() + "/new");
    }
    public postNewItem(newItem: itemDTO): Observable<listDTO> {
        return this.post(this.getBaseUrl() + "/", newItem, "BaseMaintenanceController.postNewItem." + this.baseUrl);
    }
    public putItem(maintenanceItem: itemDTO): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.put(this.getBaseUrl() + "/", maintenanceItem, "BaseMaintenanceController.putItem." + this.baseUrl);
    }
    public getById(elemId: number): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.get(this.getBaseUrl() + "/id/" + elemId);
    }
    public getByKey(key: string): Observable<MaintenanceSettingsItemDTO<itemDTO>> {
        return this.get(this.getBaseUrl() + "/key/" + key);
    }
    public deleteItem(elemId: string): Observable<boolean> {
        return this.deleteBusy(this.getBaseUrl() + "/" + elemId);
    }
    public cloneItem(maintenanceItem: itemDTO): Observable<itemDTO> {
        return this.postBusy(this.getBaseUrl() + "/clone", maintenanceItem, "BaseMaintenanceController.cloneItem." + this.baseUrl);
    }
    public securityItem(elemId: string): Observable<ObjectSecurityInfoDto> {
        return this.get(this.getBaseUrl() + "/security/" + elemId);
    }

    public getBaseUrl(): string {
        return "/maintenance/" + this.baseUrl;
    }
    public buildUrl(uri: string): string {
        return "/" + this.baseUrl + uri;
    }
}