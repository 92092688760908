import { Injectable } from '@angular/core';
import { HDate, HDateHour, HHour, HTime, HHourInterval } from '@shared/src/public-api';

@Injectable()
export class ReserveOutOfHoursDto {

    public date: HDate;
    public interval: HHourInterval = new HHourInterval();
    public intervalDock: HHourInterval = new HHourInterval();
    public intervalWheelbarrowDriver: HHourInterval = new HHourInterval();

    public previousTimeDock: HTime;
	public postTimeDock: HTime;
	public previousTimeDocumentation: HTime;
	public postTimeDocumentation: HTime;

    public isValid(): boolean{
        if(HDate.isNullOrNullValue(this.date))
            return false;

        if(HHourInterval.isNullOrNullValue(this.interval))
            return false;
        
        if(HHourInterval.isNullOrNullValue(this.intervalDock))
            return false;
        
        if(HHourInterval.isNullOrNullValue(this.intervalWheelbarrowDriver))
            return false;

        if(HHour.isGreaterThan(this.interval.getStart(), this.intervalDock.getStart()))
            return false;
        
        if(HHour.isGreaterThan(this.intervalDock.getStart(), this.intervalWheelbarrowDriver.getStart()))
            return false;

        if(HHour.isMinorThan(this.interval.getEnd(), this.intervalDock.getEnd()))
            return false;
        
        if(HHour.isMinorThan(this.intervalDock.getEnd(), this.intervalWheelbarrowDriver.getEnd()))
            return false;

        if(HHour.isMinorThan(this.intervalWheelbarrowDriver.getEnd(), this.intervalWheelbarrowDriver.getStart()))
            return false;

        return true;
    }
}

