<div class="container" [ngClass]="{'generalstatus-done':phase!=null && phase.generalStatus=='done'}">
    <div class="line only-generalstatus-done">
        {{'SHARED.EXWORK.SLOTPICKER.GENERALSTATUS.DONE.HELP' | translate:{ arrivedAt: phase.arrivedAt |
        datehournosecondsnotzobserver,
        completedAt: phase.completedAt | datehournosecondsnotzobserver} }}
        <shared-slot-viewer-component *ngIf="slotInfo" class="relative align-right" [slotInfo]="slotInfo">
        </shared-slot-viewer-component>
    </div>
    <div class="only-not-generalstatus-done line line-slot">
        <shared-slot-viewer-component *ngIf="slotInfo" class="relative  align-right" [slotInfo]="slotInfo">
        </shared-slot-viewer-component>
        <div class="block-explanation" *ngIf="phase!=null && phase.isSlotBlocked">
            {{'SHARED.EXWORK.SLOTPICKER.ISSLOTBLOCKEHELP' | translate}}
            <div class="block-explanation-reason" [innerHtml]="phase.isSlotBlockedExplanation"></div>
        </div>
        <button *ngIf="!intervals && !readonly && phase!=null && !phase.isSlotBlocked" class="line link right"
            (click)="getSlots()">{{'SHARED.EXPORT.SLOTPICKER.OPENSLOTSBUTTON.CAPTION'| translate}}</button>
    </div>
    <div *ngIf="phase != null && (intervals || loading || nooptions)" class="block selector-slots-container canviSlot" [ngClass]="{'isSlotBlocked':phase.isSlotBlocked,'incompatibleSlot':phase.incompatibleSlot,'oneSelected':(phase.intervalInfo!=null && phase.intervalInfo.addressTimetableIntervalId!=null && phase.intervalInfo.addressTimetableIntervalId!=0)}">
        <div class="block firstlastmoment">
            <table>
                <tr>
                    <td class="help" colspan="2">{{'SHARED.EXWORK.SLOTPICKER.HELP' | translate}}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <td class="prompt">{{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONFIRSTMOMENT.CAPTION' | translate}}
                    </td>
                    <td class="prompt">{{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONLASTMOMENT.CAPTION' | translate}}
                    </td>
                </tr>
                <tr>
                    <td class="value">{{phase.fastConditionFirstMoment | datehournotz}}</td>
                    <td class="value">{{phase.fastConditionLastMoment | datehournotz}}</td>
                </tr>
            </table>
        </div>

        <div class="line">
            <button class="link right" (click)="getSlots()">{{'SHARED.EXPORT.SLOTPICKER.REFRESHSLOTSBUTTON.CAPTION'|
                translate}}</button>
        </div>
        <div>
            <div class="botonera-buttonInterval">
                
                <button [ngClass]="{'hidden': !canSlotRelease}" class="buttonInterval item botoCancelar"
                    (click)="clickAlliberarSlot()">
                    <div class="hora">
                        {{'SHARED.SLOT.PICKER.COMPONENT.BUTTON.ALLIBERARSLOT.CAPTION'| translate}}
                    </div>
                    <div class="estat">
                        {{'SHARED.SLOT.PICKER.COMPONENT.BUTTON.ALLIBERARSLOT.HELP'| translate}}
                    </div>
                </button>
                <div class="listintervals">
                    <ng-container *ngFor="let item of addressintervals | sortBy:[]; let i = index;">

                        <div class="date-break" *ngIf="trencaData(i, item)">
                            <div class="line"></div>
                            <div class="date-container">
                                <div class="date">{{item.onDate | date}}</div>
                            </div>
                        </div>
                        <div class="buttonInterval item"
                            [ngClass]="{'swapVision': item.canSwap||item.canObtain, 'assignable':item.assignable,'no-assignable':!item.assignable,'notrecommendable':item.notRecommendable,'inactive':item.inActive, 'selected-item': isSelected(item), 'impossible-interval': item.impossibleInterval, 'swap-interval': item.canSwap, 'obtain-interval': item.canObtain }"
                            (click)="onIntervalSelected(item)"
                            matTooltip="{{ 'DESKTOP.SLOT.PICKER.COMPONENT.SLOT.BUTTON.TOOLTIP' | translate: { slotAvailableByMe:item.slotAvailableByMe, slotOccupiedByMe:item.slotOccupiedByMe, sum: item.slotAvailableByMe+item.slotOccupiedByMe,slotAvailable:item.slotAvailable,  slotCount:item.slotCount } }}">
                            <div class="routekey">
                                {{ item.routeKeySwap }}
                            </div>
                            <div class="dia">
                                {{item.onDate | date}}
                            </div>
                            <div class="hora">
                                {{item.interval | hourinterval}}
                            </div>
                            <div class="estat">
                                {{ 'DESKTOP.SLOT.PICKER.COMPONENT.SLOT.BUTTON.ESTAT' |
                                translate:{slotAvailableByMe:item.slotAvailableByMe, slotOccupiedByMe:item.slotOccupiedByMe,
                                sum:
                                item.slotAvailableByMe+item.slotOccupiedByMe,slotAvailable:item.slotAvailable,
                                slotCount:item.slotCount}
                                }}
                            </div>
                            <div class="observacions" *ngIf="getShowObservacionsFunc(item)">
                                {{ 'DESKTOP.SLOT.PICKER.COMPONENT.ITEMS.OBSERVACIONS' |
                                translate:{realDate:getRealDateFunc(item) |
                                date,
                                realInterval: getRealIntervalFunc(item) | hourinterval} }}
                            </div>
                            <div class="notRecommendableReason" *ngIf="item.notRecommendable"
                                [innerHTML]="item.notRecommendableReason">
                            </div>
                            <div class="noAvailableReason" *ngIf="item.slotAvailableByMe==0 || !item.assignable"
                                [innerHTML]="item.noAvailableReason">
                            </div>
                        </div>
                    </ng-container>
                    <div class="loading-label onlyloading" [ngClass]="{'loading': loading}">
                        {{ 'SHARED.LOADING.CAPTION' | translate}}
                    </div>
                    <div class="loading-label onlynooptions" [ngClass]="{'nooptions': nooptions}">
                        {{ 'SHARED.NOOPTIONS.CAPTION' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>