<div *ngIf="shipment" class="form-question-answer-item-container" [ngClass]="{ 'useIonic' : useIonic }">
    <div *ngIf="question" class="titlequestion">
        <span class="key">{{ question.title }}</span>
        <helppoint [icon]="'fa fa-info-circle'" [helptitle]="question.question" [helptext]="question.help"></helppoint>
    </div>
    <div *ngIf="question" class="question" [ngClass]="question.questionType">
        <div class="question-text">
            <span class="sub-title">{{question.question}}</span>
        </div>
    </div>
    <form *ngIf="question" class="answer" [formGroup]="rForm">
        <boolean-component class="Boolean" *ngIf="question.questionType == 'Boolean'" [(ngModel)]="value.valueBoolean"
            name="valueBoolean" formControlName="valueBoolean" [showHelpPoint]=false [readonly]="iReadonly"
            (valueChanged)="onValueChanged($event)">
        </boolean-component>

        <input-component class="Integer input" *ngIf="question.questionType == 'Integer' && !iReadonly"
            [(ngModel)]="value.valueInteger" name="valueInteger" formControlName="valueInteger" type="number"
            autocomplete="off" [readonly]="iReadonly" (valueChanged)="onValueChanged($event)">
        </input-component>
        <div class="Integer" *ngIf="question.questionType == 'Integer' && iReadonly">
            <span class="integer-readonly">{{value.valueInteger}}</span>
        </div>

        <input-component class="Double input" *ngIf="question.questionType == 'Double' && !iReadonly"
            [(ngModel)]="value.valueDouble" name="valueDouble" formControlName="valueDouble" type="number" [step]="0.1"
            autocomplete="off" [readonly]="iReadonly" (valueChanged)="onValueChanged($event)">
        </input-component>
        <div class="Double" *ngIf="question.questionType == 'Double' && iReadonly">
            <span class="double-readonly">{{value.valueDouble}}</span>
        </div>

        <tristate class="TriState" *ngIf="question.questionType == 'TriState'" [(ngModel)]="value.valueTriState"
            name="valueTriState" formControlName="valueTriState" [readonly]="iReadonly"
            (valueChanged)="onValueChanged($event)">
        </tristate>

        <textarea-component class="String textarea" *ngIf="question.questionType == 'String' && !iReadonly"
            [(ngModel)]="value.valueString" name="valueString" formControlName="valueString"
            (valueChanged)="onValueChanged($event)">
        </textarea-component>
        <div class="String" *ngIf="question.questionType == 'String' && iReadonly">
            <div class="textarea-readonly">{{value.valueString}}</div>
        </div>

        <div class="Image"
            *ngIf="question.questionType == 'Image' || question.questionType == 'CMR' || question.questionType == 'Delivery' || question.withImage">
            <shared-images *ngIf="!iReadonly" [prefixFileNameImage]="getPrefixFileNameImage()"
                (selectedImage)="onSelectedImage($event)" (takeImage)="onTakeImage($event)" [hideImage]="false"
                [miniImages]="'small'" [readonly]="iReadonly" [showDefaultCamera]="showDefaultCamera"></shared-images>

            <div *ngIf="iReadonly && value.document != null">

                 <shared-opendocument-component *ngIf="value.document" [parentObject]="parentObject" [document]="value.document" class="opendocument"></shared-opendocument-component>
                <div class="document-item" *ngFor="let item of value.document.documentDetailList | sortBy:'documentDetailId'">
                    <shared-document-view-component class="document-item-iframe" [documentDetail]="item"></shared-document-view-component>
                    <div class="actions-file-icons">
                        <span (click)="downloadDocumentDetail(item)" class="download"
                            title="{{ 'DOWNLOADDOCUMENTBUTTON.HELP' | translate }}"><i
                                class="fa fa-cloud-download"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Només el mostrem si estem a readonly -->
        <div class="Signature"
            *ngIf="iReadonly && (question.questionType == 'SignatureForm' || question.questionType == 'SignatureCMR' || question.questionType == 'SignatureDelivery')">

            <shared-signature-component [fileSignatureBytes]="value.fileSignatureBytes"
                [fileSignatureType]="value.fileSignatureType" (endDrawSignature)="onEndDrawSignature($event)"
                (clearSignature)="onClearSignature($event)" [readonly]="iReadonly"></shared-signature-component>

        </div>
    </form>
</div>