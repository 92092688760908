<div *ngIf="shipment" class="container" [ngClass]="{'ionic' : useIonic}">
    <div class="loadunload-icon">
        <img *ngIf="shipment.isUnload && !shipment.isLoad" class="imatge" src="/assets/images/unloadCargo.png"
            alt="Unload cargo">
        <img *ngIf="!shipment.isUnload && shipment.isLoad" class="imatge" src="/assets/images/loadCargo.png"
            alt="Load cargo">
        <img *ngIf="shipment.isUnload && shipment.isLoad" class="imatge" src="/assets/images/loadUnloadCargo.png"
            alt="LoadUnload cargo">
    </div>
    <div class="main-content">
        <div class="primera linia ellipsis">
            <span class="customerReference"> {{shipment.customerReference}}</span>
            <span class="shipmentKey">{{ shipment.shipmentKey }}</span>
        </div>
        <div class="linia">
            <shared-tags-strip-component class="origin" [uniqueString]="shipment.originAddressSkin">
            </shared-tags-strip-component>
            <shared-tags-strip-component class="destination" [uniqueString]="shipment.destinationAddressSkin">
            </shared-tags-strip-component>
        </div>
        <div class="linia goods ellipsis">{{ shipment.goods }}</div>
    </div>

    <div class="owner-eta">
        <shared-tags-strip-component class="owner" [uniqueString]="shipment.ownerKey"></shared-tags-strip-component>
        <div *ngIf="shipment.showEta" class="eta" [ngClass]="{'delayed': shipment.delayed}">
            <div *ngIf="detailed" class="eta-label">ETA</div>
            {{ shipment.eta | datehournosecondsnotz }}
        </div>
    </div>

    <div class="right-icons">
        <div class="icon inprogress" *ngIf="shipment.isUnload"><i class="fa fa-truck"></i></div>
        <div class="icon delayed" *ngIf="shipment.delayed"><i class="fa fa-exclamation"></i></div>
    </div>

    <div class="linia execution-moments" *ngIf="!shipment.impossible">
        <span *ngIf="detailed && !shipment.showingDeliveryCommitment" class="prompt">{{ 'TRACKING.DELIVERYFORECAST.CAPTION' | translate}}</span>
        <span *ngIf="detailed && shipment.showingDeliveryCommitment" class="prompt">{{ 'TRACKING.COMMITMENTDELIVERY.CAPTION' | translate}}</span>
        <span class="value">{{ shipment.firstMoment | datehourinterval:shipment.lastMoment }}</span>
    </div>
    <div class="linia execution-moments impossible" *ngIf="shipment.impossible">
        <span class="prompt">{{ shipment.commitmentDelivery | datehournosecondsnotz }}</span>
    </div>
</div>