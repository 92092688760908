import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ShipmentController } from "@shared/src/controllers/route/shipment/shipment.controller";
import { ParticipantDto } from "@shared/src/dtos/chat/participant/ParticipantDto";
import { OptionDto } from "@shared/src/dtos/maintenance/option/OptionDto";
import { OptionsStripDto } from "@shared/src/dtos/maintenance/option/OptionsStripDto";
import { ClientProviderViewShipmentDto } from "@shared/src/dtos/shipment/ClientProviderViewShipmentDto";
import { FilterClientProviderViewShipmentDto } from "@shared/src/dtos/shipment/FilterClientProviderViewShipmentDto";
import { ChatParticipantTypes } from "@shared/src/enums/ChatParticipantTypes";
import { PhaseController } from "@shared/src/controllers/route/phase/phase.controller";
import { Subject } from "rxjs-compat";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { ShipmentCardDto } from "@shared/src/dtos/shipment/ShipmentCardDto";
import { PhaseCardDto } from "@shared/src/dtos/phase/PhaseCardDto";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HString } from "@shared/src/datatypes/HString";
import { MatDialog } from "@angular/material/dialog";
import { SocketController } from "@shared/src/controllers/socket/socket.controller";
import { Platforms } from '../../services/platform/Platforms';
import { HLong } from "@shared/src/datatypes/HLong";
import { DocumentParentObjectDto } from "@shared/src/dtos/documentaryreview/DocumentParentObjectDto";
import { DocumentParentTypes } from "@shared/src/enums/DocumentParentTypes";

@Component({
    selector: "shared-clientprovider-view-component",
    templateUrl: "./shared.clientprovider.view.component.html",
    styleUrls: ["./shared.clientprovider.view.component.scss"],
})
export class SharedClientProviderViewComponent implements OnInit, OnDestroy {

    public static SLICECOUNTINITIAL: number = 100;
    public static SLICECOUNTMORE: number = 50;
    public useIonic: boolean = false;
    public platform: Platforms = Platforms.desktop;

    public itemsCount = 0;
    public sliceCount: number = SharedClientProviderViewComponent.SLICECOUNTINITIAL;
    public onSliceMore() {
        this.sliceCount = Math.min(this.sliceCount + SharedClientProviderViewComponent.SLICECOUNTMORE, this.itemsCount);
    }

    constructor(private socketController: SocketController, @Inject("HomeController") private homeController: HomeController, private shipmentController: ShipmentController, private phaseController: PhaseController,
        public dialog: MatDialog, public openRouteController: OpenRouteController) {
        this.getShipments();
        this.searchTextChanged
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((value) => {
                this._filter.searchText = value;
                this.getShipments();
            });

        this.useIonic = this.homeController.useIonic();
        this.platform = this.homeController.getPlatform();

        this.socketController.notificationInterestedOn$.subscribe((topic: string) => {
            if (this.actualInterest == topic && this._selectedShipment) {
                this.getShipment(false);
            }
        })
    }

    ngOnDestroy(): void { }

    ngOnInit(): void { }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

    public searchTextChanged = new Subject<string>();
    public searchText: string;
    public filterMetadata: any = {};
    private _filter: FilterClientProviderViewShipmentDto = new FilterClientProviderViewShipmentDto();
    public set filter(value: FilterClientProviderViewShipmentDto) {
        this._filter = value;
        this.getShipments();
    }

    public get filter(): FilterClientProviderViewShipmentDto {
        return this._filter;
    }

    public shipments: Array<ClientProviderViewShipmentDto> = [];
    public getShipments() {
        if (this.filterMetadata.count == 0 && !HString.isNullOrNullString(this.searchText)) {
            this.filter.searchText = this.searchText;
        } else {
            this.filter.searchText = null;
        }

        this.shipmentController.getShipmentsByClientProvider(this._filter).subscribe((data: Array<ClientProviderViewShipmentDto>) => {
            if (data)
                this.shipments = data;
            else
                this.shipments = [];

            this.itemsCount = this.shipments.length;
            this.sliceCount = SharedClientProviderViewComponent.SLICECOUNTINITIAL;
            this.sliceCount = Math.min(this.sliceCount, this.itemsCount);
        });
    }

    private _selectedShipment: ClientProviderViewShipmentDto = null;
    private actualInterest: string = null;
    public set selectedShipment(value: ClientProviderViewShipmentDto) {

        if(this._selectedShipment != null && value != null && this._selectedShipment.shipmentId == value.shipmentId)
            return;

        if (value) {
            this.actualInterest = "Shipment:" + value.shipmentId;
            this.socketController.interested(this.actualInterest);
        }

        this._selectedShipment = value;
        this.getShipment(true);
        
    }

    public get selectedShipment(): ClientProviderViewShipmentDto {
        return this._selectedShipment;
    }

    private _shipmentCard: ShipmentCardDto = null;
    public set shipmentCard(value: ShipmentCardDto){
      if(this._shipmentCard === value)
        return;
      
      this._shipmentCard = value;
      this.loadParentObject();
    }
    public get shipmentCard(): ShipmentCardDto{
      return this._shipmentCard;
    }
  
    public shipmentOptions: OptionsStripDto;
    public forcedOwnerParticipant: ParticipantDto;
    public originPhase: PhaseCardDto;
    public destinationPhase: PhaseCardDto;
    private getShipment(refreshOptions: boolean) {
        if (!this._selectedShipment)
            this.shipmentCard = null;
        else
            this.shipmentController.getCardByShipment(this._selectedShipment.shipmentId).subscribe((shipment: ShipmentCardDto) => {
                this.shipmentCard = shipment;
                this.forcedOwnerParticipant = ParticipantDto.buildByParams(this._selectedShipment.shipmentId, ChatParticipantTypes.Shipment);
                if (refreshOptions)
                    this.shipmentController.getClientProviderViewShipmentOptions(this._selectedShipment.shipmentId).subscribe((data: OptionsStripDto) => {
                        this.shipmentOptions = OptionsStripDto.buildFromOptionsStripDto(data);
                        this.selectedOption = this.shipmentOptions.getActualSelected();
                    });

                if (!HLong.isNullOrNullLong(this._selectedShipment.originPhaseId))
                    this.phaseController.getPhase(this._selectedShipment.originPhaseId).subscribe((origin: PhaseCardDto) => {
                        this.originPhase = origin;
                    });

                if (!HLong.isNullOrNullLong(this._selectedShipment.destinationPhaseId))
                    this.phaseController.getPhase(this._selectedShipment.destinationPhaseId).subscribe((destination: PhaseCardDto) => {
                        this.destinationPhase = destination;
                    });
            });
    }

    public parentObject: DocumentParentObjectDto;
    loadParentObject(){
      if(this.shipmentCard == null || HLong.isNullOrNullLong(this.shipmentCard.shipmentId))
        this.parentObject = null;
      else
        this.parentObject = DocumentParentObjectDto.build(DocumentParentTypes.Shipment, 0, this.shipmentCard.shipmentId, 0, 0);
    }

    public selectedOption: OptionDto = null;
    public onClickOption(value: OptionDto) {
        this.selectedOption = value;
    }
}
