import {Injectable} from '@angular/core';
import { RoutePartCMRQrDto } from './RoutePartCMRQrDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';

@Injectable()
export class RouteCMRQrDto {

    public qr: FileUpDownLoadDto;
	public routeId: number;
	public uuidQR: string;
	public formatDocumentQRCode: string;

    public qrs: Array<RoutePartCMRQrDto>;
}