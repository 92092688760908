import { Component, Input } from '@angular/core';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { HDate } from '@shared/src/datatypes/HDate';
import { HDateHour } from '@shared/src/datatypes/HDateHour';
import { HInteger } from '@shared/src/datatypes/HInteger';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { ShipmentDto } from '@shared/src/dtos/shipment/ShipmentDto';

@Component({
  selector: 'shared-shipment-info-component',
  templateUrl: './shared.shipment.info.component.html',
  styleUrls: ['./shared.shipment.info.component.scss'],
})
export class SharedShipmentInfoComponent {

  constructor(private shipmentController: ShipmentController) { }


  public __shipmentId: number;
  @Input()
  set shipmentId(value: number) {
    if (this.__shipmentId === value)
      return;
    this.__shipmentId = value;
    this.loadShipmentData();
  }
  get shipmentId(): number {
    return this.__shipmentId;
  }

  public selectedShipment: ShipmentCardDto;
  public loadShipmentData() {
    if (this.__shipmentId == null || this.__shipmentId == 0) {
      this.selectedShipment = null;
      return;
    }
    this.shipmentController.getCardByShipment(this.__shipmentId).subscribe(data => {
      this.selectedShipment = data;
    });
    this.distanceToDestination = HInteger.random(120, 650);
    this.speed = HInteger.random(60, 92);
    this.direction = HInteger.random(0, 360);
    this.temperature = HInteger.random(24, 32);
    this.eta = HDateHour.addMilliseconds(HDateHour.now(), (this.distanceToDestination / this.speed) * 60 * 60 * 1000);
    this.dueDate = HDateHour.addMilliseconds(this.eta, HInteger.random(90, 1440) * 60 * 1000).setMinutes(0).setSeconds(0).setMilliSeconds(0);
    this.status = "On time";
  }
  public distanceToDestination = 126;
  public speed = 80;
  public direction = 125;
  public temperature = 27.5;
  public dueDate = HDateHour.addMilliseconds(HDateHour.now(), 98 * 60 * 1000);
  public eta = HDateHour.addMilliseconds(this.dueDate, HInteger.random(90, 1440) * 60 * 1000);
  public status = "On time";

  ngOnInit() {

  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
