<div class="container" [ngClass]="{'ionic': useIonic,  'selectedshipment' : selectedShipment!=null && selectedShipment.shipmentId > 0 }">
    <div class="cards list">
        <div class="heard">
            <div class="title">
                {{'SHARED.TRUCKING.COLUMN.SHIPMENTS.TITLE' | translate}}
                <span class="count">{{filterMetadata.count}}/{{shipmentsCount}}</span>
            </div>
            <shared-filter class="extrapadding" [filterstrip]="filter" (change)="filterUpdate()"></shared-filter>

            <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text
                [(ngModel)]="searchText" (ngModelChange)="this.searchTextUpdate.next($event)"
                (focus)="onSearchFocus($event)" (click)="onSearchClick($event)">
        </div>

        <div class="page">
            <div class="column">
                <div class="llista">
                    <div class="overflow">

                        <shared-trucking-shipment-card
                            *ngFor="let item of shipmentsExactKey | filterActiveRoutes:searchText2:filterMetadataExactKey | orderbytruckingshipmentcarddto:true; let i=index"
                            class="shipmentItem"
                            [ngClass]="{'selected':selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}"
                            [shipment]="item" [showPackages]="selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickItem(item)" (clickBack)="onClickBack($event)">
                        </shared-trucking-shipment-card>

                        <shared-trucking-shipment-card
                            *ngFor="let item of getShipments() | filterActiveRoutes:searchText2:filterMetadata | filterRemoveRepeat:shipmentsExactKey:'shipmentId' | orderbytruckingshipmentcarddto:true | slice:0:sliceCount; let i=index"
                            class="shipmentItem"
                            [ngClass]="{'selected':selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId}"
                            [shipment]="item" [showPackages]="selectedShipment!=null && item.shipmentId==selectedShipment.shipmentId"
                            [selectedRouteExpand]="selectedRouteExpand"
                            (click)="onClickItem(item)" (clickBack)="onClickBack($event)">
                        </shared-trucking-shipment-card>

                        <button class="slice-button" (click)="onSliceMore()" *ngIf="sliceCount<itemsCount">
                            {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:itemsCount} }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>