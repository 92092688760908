import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'number'
})
export class NumberFilter implements PipeTransform {

	transform(value: number): string {
		if (!value)
			return "";

		let result = value.toString();
		result = result.replace(",", '#')
		result = result.replace(".", ',')
		result = result.replace('#', '.')

		return result;
	}
}
