import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { BaseController } from '../../controllers/base.controller';
import { SecurityController } from '../../controllers/security/security.controller';
import { ChatDto } from '../../dtos/chat/ChatDto';
import { ChatsDto } from '../../dtos/chat/ChatsDto';
import { ChatMessageDto } from '../../dtos/chat/message/ChatMessageDto';
import { ParticipantDto } from '../../dtos/chat/participant/ParticipantDto';
import { AlertService } from '../../services/alert/alert.service';
import { LoadingService } from '../../services/loading/loading.service';
import { MaintenanceSearchByTextDto } from '../maintenance/_base/MaintenanceSearchByTextDtos';


@Injectable({
    providedIn: 'root',
})
export class ChatController extends BaseController {
    private base = '/chat'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }
    public onClickParticipant(participant: ParticipantDto) {
        if (participant == null)
            return;
        this.router.navigateByUrl("/chat/" + participant.participantType + "/" + participant.participantId);
    }
    public refreshChats(chats: ChatsDto): Observable<ChatsDto> {
        return this.post(this.base + "/refreshchats", chats, "refreshChats");
    }

    public refreshChat(chatId: number, selectedChatId: number): Observable<ChatDto> {
        return this.post(this.base + "/refreshchats/" + chatId, selectedChatId, "refreshChat");
    }

    public getContacts(searchByText: MaintenanceSearchByTextDto): Observable<ParticipantDto[]> {
        return super.post(this.base + "/getcontacts", searchByText, "getContacts");
    }
    
    public addChatMessage(chatmessage: ChatMessageDto): Observable<ChatDto> {
        return this.post(this.base + "/addchatmessage", chatmessage, "addChatMessage");
    }

    public deleteChatMessage(chatmessageId: number): Observable<any> {
        return this.delete(this.base + "/deletechatmessage/" + chatmessageId)
    }

    public getOrCreateChat(chat: ChatDto): Observable<ChatDto> {
        return this.post(this.base + "/getorcreatechat", chat, "getOrCreateChat");
    }

    public loadMoreMessagesChat(chat: ChatDto): Observable<ChatDto> {
        return this.post(this.base + "/loadmoremessageschat", chat, "loadMoreMessagesChat");
    }

    public chatsCheckSum(): Observable<string> {
        return super.get(this.base + "/chatschecksum");
    }
}
