import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PhaseByAddressEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PhaseByAddressEntitySelectorDto {
		let result: PhaseByAddressEntitySelectorDto = new PhaseByAddressEntitySelectorDto();
		result.loadData(EntitysSelectors.PhaseByAddress, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		//No hi ha manteniment estricte, poden ser customer, driver i/o provider
		return "";
	}

	public getUrlBack(): string {
		return "/phase/search-by-address";
	}

}