import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { SharedSaveButtonEvent } from '@shared/src/public-api';
import { Observable } from 'rxjs-compat';

@Component({
  selector: 'shared-banners',
  templateUrl: './shared.banners.component.html',
  styleUrls: ['./shared.banners.component.scss'],
})
export class SharedBannersComponent {

  public useIonic: boolean = false;

  public rForm: FormGroup;

  constructor(@Inject('HomeController') public homeController: HomeController,
    private openRouteController: OpenRouteController,
    private routeController: RouteController,
    private fb: FormBuilder) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();

    this.rForm = this.fb.group({
      'bannerHtml': ['', Validators.nullValidator],
      'privateBannerHtml': ['', Validators.nullValidator],
    });
  }

  @Input() public readonly: boolean = false;

  public __route: RouteCardDto;
  @Input()
  set route(value: RouteCardDto) {
    if (this.__route === value)
      return;
    this.__route = value;
    if (this.__route == null)
      this.__routeId = 0;
    else
      this.__routeId = value.routeId;
  }
  get route(): RouteCardDto {
    return this.__route;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadRouteData();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public loadRouteData() {
    if (!this.routeId)
      return;

    this.routeController.getRoute(this.routeId).subscribe(route => {
      this.route = route;
      this.routeUpdated.next(this.route);
    });
  }

  @Output() routeUpdated: EventEmitter<RouteCardDto> = new EventEmitter();
  public onRouteUpdated(value: RouteCardDto) {
    if (this.route == null || value == null || this.route.routeId === value.routeId)
      this.route = value;

    this.routeUpdated.next(value);

  }

  save($event: SharedSaveButtonEvent) {
    if (!this.route)
      return;

    if (this.rForm.get('bannerHtml').dirty || this.rForm.get('privateBannerHtml').dirty) {
      this.internalSave(this.openRouteController.setBannerHtml(this.route.routeId, this.route.bannerHtml), this.openRouteController.setPrivateBannerHtml(this.route.routeId, this.route.privateBannerHtml));
    }
  }
  
  internalSave(first: Observable<Boolean>, second: Observable<Boolean>) {
    first.subscribe(firstData => {
      second.subscribe(secondData => {
        if (firstData || secondData) {
          this.routeUpdated.next(this.route);
          this.rForm.markAsPristine();
        }
      });
    });
  }

  public pretty(value) {
    return JSON.stringify(value);
  }
}
