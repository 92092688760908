<div class="container-statistics">
    <div class="statistics-header">
        <div class="statistics-title-zone">
            <div class="statistics-title">{{'SUPERVISION' | translate}}</div>
            <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div *ngIf="data!=null && data!=undefined" class="content">
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    SUPERVISION
                </ng-template>

                <shared-boxes [boxes]="data.boxes"></shared-boxes>
                <shared-simpletable-component *ngFor="let table of data.listTableCues | sortBy:'caption'"
                    [value]="table" [showTitle]="true"></shared-simpletable-component>
                <div class="container-table grup">
                    <table class="hardman">
                        <tr class="dark">
                            <td class="">{{data.batchHHourEndarreriment | datehour}} hora</td>
                            <td class="">{{data.batchSegonsEndarreriment}} segons</td>
                            <td class="">{{data.batchMinutsEndarreriment}} minuts</td>
                        </tr>
                    </table>
                </div>
                <div class="container-table grup">
                    <table class="hardman">
                        <tr class="dark">
                            <th class="header">Cues</th>
                            <th class="header">#</th>
                            <th class="header">desde</th>
                        </tr>
                        <tr *ngFor="let item of data.cues | sortBy:'value0'">
                            <td class="">{{item.value0}}</td>
                            <td class="">{{item.value1}}</td>
                            <td class="">{{item.value2 | datehour}}</td>
                        </tr>
                    </table>
                </div>
                <div class="container-table grup">
                    <table class="hardman">
                        <tr class="dark">
                            <th class="header">BatchAction</th>
                            <th class="header">#</th>
                        </tr>
                        <tr *ngFor="let item of data.batchos | sortBy:'-value1'">
                            <td class="">{{item.value0}}</td>
                            <td class="">{{item.value1}}</td>
                        </tr>
                    </table>
                </div>
                <div class="container-table grup">
                    <table class="hardman">
                        <tr class="dark">
                            <th class="header">Telemetry provider</th>
                            <th class="header">#</th>
                        </tr>
                        <tr *ngFor="let item of data.telemetries | sortBy:'-value1'">
                            <td class="">{{item.value0}}</td>
                            <td class="">{{item.value1}}</td>
                        </tr>
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    NOTIFICACIONS
                </ng-template>

                <div class="container-table grup">
                    <textarea-component class="col-x" [(ngModel)]="mailTo" placeholder="{{'TO' | translate}}" name="to"
                        autocomplete="off">
                    </textarea-component>
                    <boolean-component class="col-x" [(ngModel)]="mailSendToTenantId" placeholder="Enviar a un tenant"
                        name="sendToTenantId"></boolean-component>
                    <input-component class="col-x" [(ngModel)]="mailTenantId" placeholder="TenantId"
                        name="mailTenantId">
                    </input-component>
                    <boolean-component class="col-x" [(ngModel)]="mailSendToEveryBody" placeholder="Enviar a tothom"
                        name="sendToEveryBody"></boolean-component>


                    <div class="divider"></div>

                    <input-component class="col-x" [(ngModel)]="mailSubject" placeholder="Subject" name="subject">
                    </input-component>
                    <div class="divider"></div>
                    <textarea-component class="col-x" [(ngModel)]="mailBody" placeholder="{{'BODY' | translate}}"
                        name="body" autocomplete="off">
                    </textarea-component>
                    <div class="divider"></div>
                    <boolean-component class="col-x" [(ngModel)]="mailRevisat" placeholder="Ho has revisat"
                        name="revisat">
                    </boolean-component>
                    <boolean-component class="col-x" [(ngModel)]="mailFaltes" placeholder="No hi han faltes"
                        name="faltes">
                    </boolean-component>
                    <boolean-component class="col-x" [(ngModel)]="mailProva"
                        placeholder="T'has enviat una prova i tot queda be" name="prova">
                    </boolean-component>

                    <button class="action send" (click)="sendMail()"
                        [disabled]="!mailRevisat || !mailFaltes || !mailProva">Send</button>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>