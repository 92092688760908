<div class="encolumnat">
    <h1 class="titol">{{ 'REGISTERCOMPONENT.ASK_USER_DRIVER.TITLE' | translate }}</h1>
    <h2 class="texte" [innerHTML]="'REGISTERCOMPONENT.ASK_USER_DRIVER.SUBTITLE' | translate"></h2>

    <div class="form center separat">
        <boolean-component class="checkbox" [(ngModel)]="registerData.isDriver"
            [placeholder]="'REGISTERCOMPONENT.ASK_USER_DRIVER_TOGGLE.LABEL' | translate"></boolean-component>
    </div>
    <div *ngIf="registerData.isDriver" class="backgroundPoliciy">
        {{'REGISTERCOMPONENT.USER_TYPE.POLICY.CONDUCTOR' | translate}}
    </div>
    <div class="button-zone">
        <button mat-flat-button color="primary" class="action seguent sol"
            (click)="next()" [disabled]="pendigResponse">{{'REGISTERCOMPONENT.OTHERDRIVERSBUTTON.LABEL' |
            translate}}</button>
    </div>
</div>