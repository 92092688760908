import { Component, Inject, Input, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HColor } from '@shared/src/datatypes/HColor';
import { HTags } from '@shared/src/datatypes/HTags';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';

@Component({
    selector: 'shared-phase-item',
    templateUrl: './shared.phase.item.component.html',
    styleUrls: ['./shared.phase.item.component.scss']
})
export class SharedPhaseItemComponent implements OnInit {

    constructor(@Inject('HomeController') protected homeController: HomeController) {
        this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
            if (data && data.chats)
                data.chats.forEach(e => {
                    if (e && this.phase)
                        if (e.ownerParticipantType == ChatParticipantTypes.Phase && e.ownerParticipantId == this.phase.phaseId) {
                            this.phase.pendingMessagesRead = e.pendingMessagesRead;
                        }
                });
        });
    }

    private _phase: PhaseCardDto;
    @Input()
    public set phase(value: PhaseCardDto) {
        if (this._phase === value)
            return;
        this._phase = value;

        this.tags = HTags.getTags(value.tags);
        if (this.haveTags(value.manualTags) && this.tags && this.tags.length > 0)
            this.tags = HTags.getTags(value.manualTags).concat(this.tags);

        this.internalTags = HTags.convertToStringWithDelimiter(value.internalTags, ' ');
        if (this._phase.slotOverbooked)
            this.internalTags = this.internalTags + " slotOverbooked";

        this.homeController.chatsChanged$.next(this.homeController.CHATS);
    }
    public get phase(): PhaseCardDto {
        return this._phase
    }

    @Input()
    public phases: PhaseCardDto[];

    @Input()
    public isActiveRoutes: boolean = false;

    tags: Array<string>;
    internalTags: String = "";

    ngOnInit(): void { }

    getTagColor(tag: string): string {
        return HColor.getColorFromStringTable(tag, this.homeController);
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    public haveTags(tags: HTags): boolean {
        if (HTags.isEmpty(tags))
            return false;
        return true;
    }

    pretty(val: any): string {
        return JSON.stringify(val);
    }
}
