import { Injectable } from '@angular/core';
import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerDriverSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.DRIVER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/driver/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}