import { Injectable } from "@angular/core";
import { BaseCMREventDto } from "./BaseCMREventDto";
import { RoutePartCMRDto } from "./RoutePartCMRDto";
import { CMREventTypes } from "@shared/src/enums/CMREventTypes";

@Injectable()
export class RoutePartCMREventDto extends BaseCMREventDto {
    public routePartCMRId: number;
	public routeId: number;

	public static build(routePartCMR: RoutePartCMRDto, event: string, eventType: CMREventTypes): RoutePartCMREventDto{
	
		if(routePartCMR == null)
			return null;
		let data: RoutePartCMREventDto = new RoutePartCMREventDto();
		data.routePartCMRId = routePartCMR.routePartCMRId;
		data.routeId = routePartCMR.routeId;
		data.event = event;
		data.cmrEventType = eventType;
		return data;
	}
}