import { Injectable, Pipe, PipeTransform, Inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment-timezone';
import { SecurityController } from '../controllers/security/security.controller';
import { HDateHour } from "../datatypes/HDateHour";
import { HDate } from "../datatypes/HDate";
const moment = moment_;

@Pipe({
	name: 'datahoradetall'
})
@Injectable()
export class DataHoraDetall implements PipeTransform {
	private at: string;

	constructor(translate: TranslateService, @Inject('SecurityController') protected securityController: SecurityController) {
		translate.get('AT').subscribe((text: string) => { this.at = text });
	}
	transform(value: any): string {
		return moment(value).locale(SecurityController.LANGUAGE).format('dddd D [' + this.at + '] H:mm');
	}
}

@Pipe({
	name: 'datadetalldiasetmana'
})
@Injectable()
export class DataDetallDiaSetmana implements PipeTransform {

	constructor(private translate: TranslateService) {
	}

	transform(value: HDateHour): string {

		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		let valued = HDateHour.getDate(value);

		result = this.translate.instant(HDate.getDayOfWeek(valued)) + " " + this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year;
		return result;

	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}

}

@Pipe({
	name: 'datahoradetalldiasetmana'
})
@Injectable()
export class DataHoraDetallDiaSetmana implements PipeTransform {

	constructor(private translate: TranslateService) {
	}

	transform(value: HDateHour): string {

		if (HDateHour.isNullOrNullValue(value))
			return "---";
		let result: string;

		let valued = HDateHour.getDate(value);

		result = this.translate.instant(HDate.getDayOfWeek(valued)) + " " + this.pad(value.dayOfMonth, 2) + "/" + this.pad(value.month, 2) + "/" + value.year + " " + this.pad(value.hourOfDay, 2) + ":" + this.pad(value.minute, 2);
		return result;

	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}

}

@Pipe({
	name: 'datahoradetallonlydiasetmana'
})
@Injectable()
export class DataHoraDetallOnlyDiaSetmana implements PipeTransform {

	constructor(private translate: TranslateService) {
	}

	transform(value: HDateHour): string {

		if (HDateHour.isNullOrNullValue(value))
			return "-";
		let result: string;

		let valued = HDateHour.getDate(value);

		result = this.translate.instant(HDate.getDayOfWeek(valued));
		return result;

	}

	pad(num, size): string {
		var s = "000000000" + num;
		return s.substr(s.length - size);
	}

}