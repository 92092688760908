import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { RouteCardDto } from "@shared/src/public-api";
import { ActionDto } from "@shared/src/dtos/maintenance/action/actionDto";
import { OptionDto } from "@shared/src/dtos/maintenance/option/OptionDto";
import { ActionsStripDto } from "@shared/src/dtos/maintenance/action/ActionsStripDto";
import { OptionsStripDto } from "@shared/src/dtos/maintenance/option/OptionsStripDto";

@Component({
    selector: 'shared-route-divided',
    templateUrl: './shared.route.divided.component.html',
    styleUrls: ['./shared.route.divided.component.scss']
})
export class SharedRouteDividedComponent implements OnInit {

    public readonly: boolean = false;
    public actions: ActionsStripDto;
    public options: OptionsStripDto;
    public selectedOptionId: string = "";
    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();

    constructor(public routeController: RouteController,
        @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController) {

        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    private _routeId: number;
    @Input()
    public set routeId(value: number) {
        if (this._routeId === value)
            return;

        this._routeId = value;
        this.routeController.getRoute(this._routeId).subscribe(data => {
            this.route = data;
        });

    }
    public get routeId(): number {
        return this._routeId;
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;

        this._route = value;
        this.actualizeActionsAndOptions();
    }
    public get route(): RouteCardDto {
        return this._route;
    }

    public actualizeRoute() {
        if (this.route != null)
            this.routeController.getRoute(this.route.routeId).subscribe(data => {
                this.route = data;
                this.actualizeActionsAndOptions();
            });
    }
    public actualizeActionsAndOptions() {
        if (this.route)
            this.routeController.getDividedActionsAndOptions(this.route.routeId).subscribe(data => {
                this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);
                this.options = OptionsStripDto.sync(data.options, this.options);
            });
        else {
            this.actions = null;
            this.options = null;
        }


    }

    public onClickAction(action: ActionDto) {

    }
    public onClickOption(option: OptionDto) {
        if (option == null)
            this.selectedOptionId = "";
        else
            this.selectedOptionId = option.optionId;
    }

    public onRefreshRoute() {
        this.actualizeRoute();
        this.refreshRoute.next(this.route);
    }



    ngOnInit(): void {
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

}
