<!-- checkbox.component.html!-->
<div [formGroup]="rFormInput">
    <div *ngIf="!useIonic">
        <mat-checkbox [id]="identifier" [(ngModel)]="value" [readonly]="readonly" [type]="type" [name]="name"
            formControlName="dtpvalidator" autocomplete="autocomplete" (change)="onChange($event)" [color]="color">
            {{ label }}</mat-checkbox>
        <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
        </helppoint>
    </div>
    <div *ngIf="useIonic">
        <ion-checkbox [id]="identifier" [(ngModel)]="value" [checked]="value" formControlName="dtpvalidator"
            ngDefaultControl [disabled]="readonly" [color]="color" (ionChange)="onChange($event)" mode="md">
        </ion-checkbox>
        <ion-label class="mobile-checkbox-label">{{ label }}</ion-label>
    </div>
</div>