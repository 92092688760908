import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { HomeController } from '../../../controllers/home/home.controller';
import { TenantProductDto } from '@shared/src/dtos/tenants/TenantProductDto';
import { SecurityController } from '@shared/src/controllers/security/security.controller';

@Component({
  selector: 'shared-products-component',
  templateUrl: './shared.products.component.html',
  styleUrls: ['./shared.products.component.scss']
})
export class SharedProductsComponent implements OnInit {

  public itemModeDesactivar: TenantProductDto = null;

  public readonly: boolean = false;

  constructor(@Inject('HomeController') protected homecontroller: HomeController, @Inject('SecurityController') protected securityController: SecurityController) {
    this.securityController.getStoredUserDto().subscribe(data => {
      this.readonly = data.isReadOnly;
    });
  }

  ngOnInit() { }

  @Input()
  public products: Array<TenantProductDto>;

  @Output()
  public onProductChange: EventEmitter<TenantProductDto> = new EventEmitter();

  getProducts() {
    if (this.products != null)
      return this.products;
    return new Array();
  }

  productChange(product: TenantProductDto) {
    this.onProductChange.next(product);
  }

  clickActivar(product: TenantProductDto) {
    if (product == null || product.isActive)
      return;
    product.isActive = true;
    this.itemModeDesactivar = null;
    this.onProductChange.next(product);
  }
  clickActivaDesactivar(product: TenantProductDto) {
    this.itemModeDesactivar = product;
  }
  clickDesactivar(product: TenantProductDto) {
    if (product == null || !product.isActive)
      return;

    this.itemModeDesactivar = null;
    product.isActive = false;
    this.onProductChange.next(product);
  }
  pretty(data: any): string {
    return JSON.stringify(data);
  }
}
