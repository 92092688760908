import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseController } from "../../base.controller";
import { SecurityController } from "../../security/security.controller";
import { FileUpDownLoadDto } from "@shared/src/dtos/base/FileUpDownLoadDto";
import { FilterLoadLogDto } from "@shared/src/dtos/tenants/loadlog/FilterLoadLogDto";
import { TenantLoadLogDto } from "@shared/src/dtos/tenants/loadlog/TenantLoadLogDto";

@Injectable({
    providedIn: 'root',
})
export class TenantLoadLogController extends BaseController {
    private base = '/tenantloadlog';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getLoads(filter: FilterLoadLogDto): Observable<TenantLoadLogDto[]> {
        return this.post(this.base + "/", filter, "getLoads");
    }

    public downloadFileProcess(tenantLoadLogId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/fileprocess/" + tenantLoadLogId);
    }

    public downloadFileResult(tenantLoadLogId: number): Observable<FileUpDownLoadDto> {
        return this.get(this.base + "/download/fileresult/" + tenantLoadLogId);
    }
    public cancel(tenantLoadLogId: number): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/cancel/" + tenantLoadLogId, "", "TenantLoadLogControllerCancel");
    }
}