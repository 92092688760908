import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory';
import { ShipmentCMRController } from '@shared/src/controllers/route/cmr/shipmentcmr.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { AlertService } from '@shared/src/public-api';

@Component({
    selector: 'shared-shipment-cmr-maintenance',
    templateUrl: './shared.shipment.cmr.maintenance.component.html',
    styleUrls: ['./shared.shipment.cmr.maintenance.component.scss']
})
export class SharedShipmentCMRMaintenanceComponent implements OnInit {

    @Input() public readonly: boolean = false;

    constructor(public router: Router, public shipmentCMRController: ShipmentCMRController,
        @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory,
        @Inject('AlertService') private alertService: AlertService,
        @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        })
    }


    private _shipmentId: number;
    @Input()
    public set shipmentId(value: number) {
        if (this._shipmentId === value)
            return;
        this._shipmentId = value;
        this.loadOptions();    
    }
    public get shipmentId(): number {
        return this._shipmentId
    }

    public options: OptionsStripDto;

    public loadOptions() {

        this.options = new OptionsStripDto();
        this.options.add(OptionDto.build('CMRADDITIONALDATA', 'SHIPMENTCONTROLLER.MAINTENANCE.CMRADDITIONALDATA.CAPTION', null, null, 1, -1, 1, true, false));
        this.options.add(OptionDto.build('CMREVENTS', 'SHIPMENTCONTROLLER.MAINTENANCE.CMREVENTS.CAPTION', null, null, 2, -1, 1, false, false));
        this.onClickOption(this.options.getActualSelected());
            
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option != null)
            this.selectedOptionId = option.optionId;
        else
            this.selectedOptionId = "";
    }

    ngOnInit(): void {
    }

}
