import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/datatypes/HString';
import { MenuOptionDto } from '@shared/src/dtos/home/MenuOptionDto';
import { userDto } from '@shared/src/dtos/user/userDto';
import { TimeZones } from '@shared/src/enums/TimeZones';
import { OperatingSystems } from '@shared/src/services/platform/OperatingSystems';
import { Platforms } from '@shared/src/services/platform/Platforms';
import { Solutions } from '@shared/src/services/platform/Solutions';
import { Observable } from 'rxjs';
import { Languages } from '../../enums/Languages';
import { TimeZoneUtil } from '../../enums/TimeZones';
import { AlertService } from '../../services/alert/alert.service';
import { LanguagesUtil } from '../../services/lang/LanguagesUtil';
import { LoadingService } from '../../services/loading/loading.service';
import { BaseController } from '../base.controller';
import { HomeController } from '../home/home.controller';
import { LogController } from '../log/LogController';
import { UserController } from '../user/user.controller';
import { JwtUser } from './JwtUser';


@Injectable({
    providedIn: 'root',
})
export class SecurityController extends BaseController {
    private baseurl: string = "/security";

    //Aquí hi guardem l'apiKey per anar ràpids quan la necessitem (a cada request)
    //Aquest és el nom de la cookie a on guardarem l'apikey

    //Aquest hi guardem el language per anar ràpids quan el necessitem
    public static LANGUAGE: Languages = Languages.es;

    //Per defecte posem UTC que es el mateix valor que a back
    public static TIMEZONE: TimeZones = TimeZones.UTC;

    public static GENERAL_CLASS: string = "";

    public static HARDMANENTORNGENERALCLASS: string = "";

    //Aquest és el nom de la cookie a on guardarem el language (cal?)

    public static DEVICETOKEN: string = "";

    public MINUTES_BETWEEN_RELOADHOMES: number = 5;

    public PATHACCESSALWAYS: string[] = [MenuOptionDto.PATHSETTINGSOPTION];
    public ALLPATHACCESS: string[];

    public static readonly USERID_NAME = 'userId';
    public static readonly GENERALCLASS_NAME = 'generalClass';
    public static readonly GEOLOCATION_MODE_NAME = "geolocationMode";

    public loginStatusChanged$: EventEmitter<boolean> = new EventEmitter();
    public languageChanged$: EventEmitter<Languages> = new EventEmitter();
    public timezoneChanged$: EventEmitter<TimeZones> = new EventEmitter();
    public userDataChanged$: EventEmitter<JwtUser> = new EventEmitter();
    public securityLoaded$: EventEmitter<boolean> = new EventEmitter();
    public locationChanged$: EventEmitter<boolean> = new EventEmitter();
    public asideClosed$: EventEmitter<boolean> = new EventEmitter();
    public asideReloadData$: EventEmitter<boolean> = new EventEmitter();

    protected userData: JwtUser;
    protected securityLoaded = false;
    protected _localStorage;
    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        protected activeRoute: ActivatedRoute,
        protected dateAdapter: DateAdapter<MomentDateAdapter>,
        protected userController: UserController,
        public logController: LogController) {
        super(http, alertService, translateService, loadingService, router);

        this._localStorage = window.localStorage;

    }

    public isSecurityLoaded(): boolean {
        return this.securityLoaded;
    }
    initializeSecurity() {
        //Obtenim el llenguatge amb el que hem de treballar. Serà l'últim usat per l'usuari o el del navegador
        //this.setLanguage(null);
        //this.setTimeZone(null);
        //Recuperem l'apiKey de la cookie i la posem com a estàtica. Estem suposant que la apikey és encara vàlida
        this.actualizeStoredValues().subscribe(data => {
            this.securityLoaded = true;
            this.securityLoaded$.next(true);
            this.loginStatusChanged$.next(true);
            this.isLogged().subscribe(data => {
                if (data) {
                    this.waitUntilSecurityLoaded$.next(true);
                }
                else {
                    this.logoutNONAVIGATE(false);
                    this.waitUntilSecurityLoaded$.next(true);
                }
            });
        });
    }

    public waitUntilSecurityLoaded$: EventEmitter<boolean> = new EventEmitter();
    public waitUntilSecurityLoaded(): Observable<boolean> {
        return new Observable(observer => {
            if (this.securityLoaded) {
                observer.next(true);
                observer.complete();
            }
            else {
                this.securityLoaded$.subscribe(d => {
                    observer.next(true);
                    observer.complete();
                });
            }
        });
    }

    public actualizeStoredValues() {
        return new Observable(observer => {
            try {
                this.getStorageValue(LogController.APIKEY_NAME).then(apiKEY => {
                    LogController.APIKEY = apiKEY;

                    this.getStorageValue(LogController.LANGUAGE_NAME).then(language => {
                        this.setLanguage(language);

                        this.getStorageValue(LogController.TIMEZONE_NAME).then(timezone => {
                            this.setTimeZone(timezone);

                            this.getStorageValue(SecurityController.GENERALCLASS_NAME).then(value => {
                                SecurityController.HARDMANENTORNGENERALCLASS = value;

                                this.getStorageValue(SecurityController.USERID_NAME).then(userId => {
                                    LogController.USERID = userId;

                                    this.getStorageValue(SecurityController.GEOLOCATION_MODE_NAME).then(geolocationMode => {
                                        LogController.GEOLOCATIONMODE = geolocationMode;

                                        observer.next(true);
                                        observer.complete();
                                    });
                                });
                            });
                        });
                    });
                });
            }
            catch {
                observer.next(false);
                observer.complete();
                this.logout();
            }
        });
    }

    /**
     * Inicia la sessió amb l'usuari hello del tenantId indicat.
     * @param tenantId: number
     */
    loginByTenantId(tenantId: number, platform: Platforms, solution: Solutions, os: OperatingSystems): Observable<boolean> {
        return new Observable(observer => {
            this.post(this.baseurl + "/switchtenant", SwitchTenantLoginDto.build(tenantId, SecurityController.DEVICETOKEN, platform, solution, os), "switchtenant")
                .subscribe(
                    (data: any) => {
                        this.loggin = false;
                        if (data.token) {
                            this.doTheLogin(data.token, data.language, data.userId, data.geolocationMode, data.timezone, "", data.hardmanEntornGeneralClass);
                            observer.next(true);
                            observer.complete();
                            return;
                        } else {
                            observer.next(false);
                            observer.complete();
                            return;
                        }
                    },
                    error => { this.loggin = false; }
                );
        });
    }

    /**
     * Inicia la sessió de l'usuari amb les credencials indicades.
     * @param username: email
     * @param password: password
     */
    loginByEmail(email: string, password: string, platform: Platforms, solution: Solutions, os: OperatingSystems) {
        this.loginByEmailWithRedirect(email, password, platform, solution, os, "");
    }
    loginByEmailWithRedirect(email: string, password: string, platform: Platforms, solution: Solutions, os: OperatingSystems, redirect: string) {
        this.loginByPropertyName(LoginDto.buildEmail(email, password, SecurityController.DEVICETOKEN, platform, solution, os), redirect);
    }

    /* Inicia la sessió de l'usuari amb les credencials indicades
     * @param phone: international prefix and phone number.
     * @param password: password.
     */
    loginByPhone(phone: string, password: string, platform: Platforms, solution: Solutions, os: OperatingSystems) {
        this.loginByPhoneWithRedirect(phone, password, platform, solution, os, "");
    }
    loginByPhoneWithRedirect(phone: string, password: string, platform: Platforms, solution: Solutions, os: OperatingSystems, redirect: string) {
        this.loginByPropertyName(LoginDto.buildPhone(phone, password, SecurityController.DEVICETOKEN, platform, solution, os), redirect);
    }
    private loggin = false;
    loginByPropertyName(login: LoginDto, redirect: string) {
        if (this.loggin) {
            console.error("Te pillat!");
            return;
        }
        console.log("provant Login");

        if (this.loggin)
            return;
        this.loggin = true;
        this.post(this.baseurl + "/auth", login, "login")
            .subscribe(
                (data: any) => {
                    console.log("resposta Login");
                    this.loggin = false;
                    if (data.token) {
                        console.log("Hi ha data token");

                        this.doTheLogin(data.token, data.language, data.userId, data.geolocationMode, data.timezone, redirect, data.hardmanEntornGeneralClass);
                        return;
                    }
                },
                error => {
                    this.loggin = false;
                    console.log("error Login");

                }
            );
    }

    /**
    * Retorna true si l'usuari té a sessió iniciada.
    */
    public isLoggedInternal(): Observable<boolean> {
        return new Observable(observer => {
            try {
                let authHeader = this.getAuthorizationHeaderValue();
                if (authHeader == null || authHeader == undefined || HString.isNullOrNullString(authHeader)) {
                    observer.next(false);
                    observer.complete();
                    return;
                }
                observer.next(true);
                observer.complete();
                return;
            }
            catch {
                observer.next(false);
                observer.complete();
                this.logout();
            }
        });
    }
    public isLogged(): Observable<boolean> {
        return new Observable(observer => {
            try {
                this.waitUntilSecurityLoaded().subscribe(d => {
                    let authHeader = this.getAuthorizationHeaderValue();
                    if (authHeader == null || authHeader == undefined || HString.isNullOrNullString(authHeader)) {
                        observer.next(false);
                        observer.complete();
                        return;
                    }
                    observer.next(true);
                    observer.complete();
                    return;
                });
            }
            catch {
                observer.next(false);
                observer.complete();
                this.logout();
            }
        });
    }


    canAccess(pathreal: string, pathvirtual: string): Observable<boolean> {
        return new Observable(observer => {
            try {
                if (HString.isNullOrNullString(pathreal) || HString.equals(pathreal, "/") || this.canHasPathAccess(pathreal, pathvirtual)) {
                    observer.next(true);
                    observer.complete();
                    return;
                }
                //Si no tenim la opció al menú, recarraguem el menu i tornem a validar
                this.internalAllPathAccess().subscribe(allpathaccess => {
                    this.ALLPATHACCESS = allpathaccess;
                    //Si s'ha carregat el menu, tornem a validar
                    if (this.canHasPathAccess(pathreal, pathvirtual)) {
                        observer.next(true);
                        observer.complete();
                        return;
                    };
                    observer.next(false);
                    observer.complete();
                    return;
                });
            }
            catch {
                observer.next(false);
                observer.complete();
                return;
            }
        });
    }

    /**
     * Podem tenir paths que es construeixin dins de ruta activa o dels manteniments com per exemple afegir l'id de l'objecte i la opcio interna d'un manteniment
     * Per això, si no existeix dins de la llista de ALLPATHACCESS amb el valor exacte, comprovarem si pathvirtual conte ':' (senyal que inclou variables/subopcions) 
     * i si es així, comprovarem si a ALLPATHACCESS existeix alguna que coincideixi amb l'inici de pathreal 
     */
    public canHasPathAccess(pathreal: string, pathvirtual: string): boolean {

        if (!pathreal.startsWith("/"))
            pathreal = "/" + pathreal;
        if (!pathvirtual.startsWith("/"))
            pathvirtual = "/" + pathvirtual;

        let exists = false;
        if (this.PATHACCESSALWAYS) {
            this.PATHACCESSALWAYS.forEach(element => {
                if (HString.equals(pathreal, element) || HString.equals(pathvirtual, element)) {
                    exists = true;
                    return;
                }
            });
        }
        if (!exists && this.ALLPATHACCESS) {
            this.ALLPATHACCESS.forEach(element => {
                if (HString.equals(pathreal, element) || HString.equals(pathvirtual, element)) {
                    exists = true;
                    return;
                }
            });

            if (!exists && pathvirtual.includes(':')) {
                this.ALLPATHACCESS.forEach(element => {
                    if (pathreal.startsWith(element)) {
                        exists = true;
                        return;
                    }
                });
            }
        }
        return exists;

    }

    private internalAllPathAccess(): Observable<string[]> {
        return this.get(this.baseurl + '/allpathaccess');
    }

    /**
     * Tanca la sessió de l'usuari.
     */
    logout() {
        this.logoutNONAVIGATE(true);
        this.goToLogin();
    }

    private _storedUserDto: userDto = null;
    logoutNONAVIGATE(posttoback: boolean) {

        this.stopGeolocation();

        if (posttoback) {
            this.post(this.baseurl + '/logout', "", "logout").subscribe(data => { });
        }


        this._storedUserDto = null;
        LogController.APIKEY = '';
        LogController.USERID = 0;
        LogController.LOGGED = false;
        this.removeStorageValue(LogController.APIKEY_NAME);
        this.removeStorageValue(SecurityController.USERID_NAME);
        this.removeStorageValue(LogController.GEOLOCATION_QUEUE_NAME);
        this.refreshLanguage();
        this.refreshTimeZone();
        this.loginStatusChanged$.next(false);
        if (this.alertService.clearAlerts)
            this.alertService.clearAlerts();
    }

    logoutNONAVIGATE2(posttoback: boolean) {

        this.stopGeolocation();

        if (posttoback) {
            this.post(this.baseurl + '/logout', "", "logout").subscribe(data => { });
        }


        this._storedUserDto = null;
        LogController.APIKEY = '';
        LogController.USERID = 0;
        LogController.LOGGED = false;
        this.removeStorageValue(LogController.APIKEY_NAME);
        this.removeStorageValue(SecurityController.USERID_NAME);
        this.removeStorageValue(LogController.GEOLOCATION_QUEUE_NAME);
        this.refreshLanguage();
        this.refreshTimeZone();
        this.loginStatusChanged$.next(true);
        if (this.alertService.clearAlerts)
            this.alertService.clearAlerts();
    }

    public stopGeolocation() {
        //TODO: Override on mobile.security.controller
    }

    public getStoredUserDto(): Observable<userDto> {
        if (this._storedUserDto == null) {
            return this.getActualUserDto();
        } else {
            return new Observable(observer => {
                observer.next(this._storedUserDto);
                observer.complete();
            });
        }
    }
    public STOREDUSERDTO() {
        return this._storedUserDto;
    }

    public refreshStoredUserDto() {
        this._storedUserDto = null;
        this.getStoredUserDto();
    }

    public getTenant(): Observable<number> {
        return new Observable(observer => {
            return this.getStoredUserDto().subscribe(data => {
                if (data != null)
                    observer.next(data.tenantId);
                else
                    observer.next(-1);
                observer.complete();
            });
        });
    }

    goToLogin() {
        console.log('goToLogin');
        this.router.navigateByUrl('/login');
    }
    goToHome() {
        console.log('goToHome');
        this.router.navigateByUrl('/');
    }

    /**
     * Indicates if the user is HardManUser
     */
    public isHardManUser(): Observable<boolean> {
        return new Observable(observer => {
            if (this._storedUserDto == null) {
                this.getStoredUserDto().subscribe((data) => {
                    if (this._storedUserDto != null && this.isEmailHardManUser(this._storedUserDto.eMail)) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(false);
                        observer.complete();
                    }
                })
            } else if (this.isEmailHardManUser(this._storedUserDto.eMail)) {
                observer.next(true);
                observer.complete();
            } else {
                observer.next(false);
                observer.complete();
            }
        })
    }
    public isOwnerOrHardManUser(): Observable<boolean> {
        return new Observable(observer => {
            if (this._storedUserDto == null) {
                this.getStoredUserDto().subscribe((data) => {
                    if (this._storedUserDto != null && (this.isEmailHardManUser(this._storedUserDto.eMail) || this._storedUserDto.isOwner)) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(false);
                        observer.complete();
                    }
                })
            } else if (this.isEmailHardManUser(this._storedUserDto.eMail) || this._storedUserDto.isOwner) {
                observer.next(true);
                observer.complete();
            } else {
                observer.next(false);
                observer.complete();
            }
        })
    }

    public isEmailHardManUser(email: string) {
        if (email != null && email.toLocaleLowerCase().endsWith("@hardman.app"))
            return true;
        return false;
    }

    /**
    * Indicates if the user is owner
    */
    public isOwner(): Observable<boolean> {
        return new Observable(observer => {
            if (this._storedUserDto == null) {
                this.getStoredUserDto().subscribe((data) => {
                    observer.next(this._storedUserDto.isOwner);
                    observer.complete();
                })
            } else {
                observer.next(this._storedUserDto.isOwner);
                observer.complete();
            }
        })
    }

    public isTotem(): Observable<boolean> {
        return new Observable(observer => {
            if (this._storedUserDto == null) {
                this.getStoredUserDto().subscribe((data) => {
                    observer.next(this._storedUserDto.isTotem);
                    observer.complete();
                })
            } else {
                observer.next(this._storedUserDto.isTotem);
                observer.complete();
            }
        })
    }

    public doTheLogin(apiKey: any, language: Languages, userId: number, geolocationMode: string, timezone: TimeZones, redirect: string, generalClass: string) {
        console.log("doTheLogin", language);
        LogController.APIKEY = apiKey;
        this.setStorageValue(LogController.APIKEY_NAME, apiKey);
        LogController.USERID = userId;
        this.setStorageValue(SecurityController.USERID_NAME, userId);
        console.log("GEOLOCATIONMODE = " + geolocationMode)
        LogController.GEOLOCATIONMODE = geolocationMode;
        this.setStorageValue(SecurityController.GENERALCLASS_NAME, generalClass);
        SecurityController.HARDMANENTORNGENERALCLASS = generalClass;
        this.setStorageValue(SecurityController.GEOLOCATION_MODE_NAME, geolocationMode);
        this.setLanguage(language);
        this.setTimeZone(timezone);
        this.logController.cleanQueque();
        LogController.LOGGED = true;
        this.loginStatusChanged$.next(true);
        if (redirect == null || redirect == "") {
            HomeController.FIRSTMENU = true;
            this.goToHome();
        } else
            this.router.navigateByUrl(redirect);
    }


    public getAuthorizationHeaderValue(): string {
        return LogController.APIKEY;
    }

    public getCurrentUserId(): number {
        return LogController.USERID;
    }



    public getActualUserDto(): Observable<userDto> {
        return new Observable(observer => {
            this.isLogged().subscribe(data => {
                if (data) {
                    return this.userController.getUserData().subscribe(data => {
                        this._storedUserDto = data;
                        LogController.USERID = data.userId;
                        observer.next(data);
                        observer.complete();
                    })
                } else {
                    this._storedUserDto = null;
                    observer.next(null);
                    observer.complete();
                }
            });
        });
    }

    public getCurrentLanguage(): Promise<Languages> {
        return new Promise<Languages>(resolve => {
            this.getStorageValue(LogController.LANGUAGE_NAME).then(language => {
                if (!language) {
                    language = window.navigator.language;
                }
                return LanguagesUtil.getByString(language);
            });
        });
    }

    public setLanguage(language: Languages) {
        if (language == null) {
            var ln = window.navigator.language;
            language = LanguagesUtil.getByString(ln);
        }

        this.translateService.setDefaultLang(language.toString());
        this.translateService.use(language.toString());
        this.dateAdapter.setLocale(LanguagesUtil.getLocaleString(language));
        this.setStorageValue(LogController.LANGUAGE_NAME, language);
        SecurityController.LANGUAGE = language;

        this.languageChanged$.next(language);
    }

    public getCurrentTimeZone(): Promise<TimeZones> {
        return new Promise<TimeZones>(resolve => {
            this.getStorageValue(LogController.TIMEZONE_NAME).then(timezone => {
                if (timezone == null || timezone == undefined)
                    timezone = SecurityController.TIMEZONE.toString();
                resolve(timezone);
            });
        });
    }

    setTimeZone(timezone: TimeZones) {
        if (timezone == null) {
            timezone = TimeZoneUtil.getTimeZoneFromBrowser();
        }
        this.setStorageValue(LogController.TIMEZONE_NAME, timezone);
        SecurityController.TIMEZONE = timezone;
        this.timezoneChanged$.next(timezone);
    }

    getDeviceToken() {
        return SecurityController.DEVICETOKEN;
    }
    setDeviceToken(deviceToken: string) {
        //Poden passar 2 coses, ja estem loguejats o no ho estem. Si no ho estem
        this.isLogged().subscribe(logged => {
            if (logged) {
                //TODO: Hauriem d'avisar al bck de que ara tenim toekn
                console.log("avisem al back que ja tenim device token");
                this.post(this.baseurl + "/updatedevicetoken", deviceToken, "updatedevicetoken").subscribe(e => {
                    SecurityController.DEVICETOKEN = deviceToken;
                });
            }
            else
                SecurityController.DEVICETOKEN = deviceToken;
        });
    }

    refreshLanguage() {
        this.isLogged().subscribe(data => {
            if (data) {
                this.getStoredUserDto().subscribe(value => {
                    if (value != null)
                        this.setLanguage(LanguagesUtil.getByString(value.language));
                    else
                        this.setLanguage(null);
                });
            }
            else
                this.setLanguage(null);
        });
    }

    refreshTimeZone() {
        this.isLogged().subscribe(data => {
            if (data) {
                this.getStoredUserDto().subscribe(value => {
                    if (value != null)
                        this.setTimeZone(value.timeZone);
                    else
                        this.setTimeZone(null);
                });
            }
            else
                this.setTimeZone(null);
        });
    }

    /***
     * Retorna de forma encapsulada el valor enmagatzemat per la variable definida
     */
    public getStorageValue(namevalue: string): Promise<any> {
        return new Promise(resolve => {
            resolve(this._localStorage.getItem(namevalue));
        });
    }

    /***
     * Set de forma encapsulada per la variable definida
     */
    public setStorageValue(namevalue: string, value: any) {
        this._localStorage.setItem(namevalue, value);
    }

    /***
     * Delete de forma encapsulada per la variable definida
     */
    public removeStorageValue(namevalue: string) {
        this._localStorage.removeItem(namevalue);
    }

    terminate() {
        this.post(this.baseurl + "/terminate", "", "terminate").subscribe(data => {
            if (data)
                this.logout();
        });
    }

    public lostPassword(body: LostPasswordDTO): Observable<boolean> {
        return this.post(this.baseurl + "/lostpassword", body, "lostPassword");
    }

    public setNewPassword(body: SetNewPasswordDTO): Observable<boolean> {
        return this.post(this.baseurl + "/setnewpassword", body, "setNewPassword");
    }

    pretty(value: any) {
        return JSON.stringify(value);
    }

}

export class LoginDto {
    public phone: string;
    public email: string;
    public password: string;
    public device_token: string;
    public sms_code: string;
    public platform: Platforms;
    public solution: Solutions;
    public os: OperatingSystems;

    public static buildPhone(phone: string, password: string, deviceToken: string, platform: Platforms, solution: Solutions, os: OperatingSystems): LoginDto {
        let result = new LoginDto();
        result.phone = phone;
        result.password = password;
        result.device_token = deviceToken;
        result.platform = platform;
        result.solution = solution;
        result.os = os;
        return result;
    }
    public static buildEmail(email: string, password: string, deviceToken: string, platform: Platforms, solution: Solutions, os: OperatingSystems): LoginDto {
        let result = new LoginDto();
        result.email = email;
        result.password = password;
        result.device_token = deviceToken;
        result.platform = platform;
        result.solution = solution;
        result.os = os;
        return result;

    }

}

export class SwitchTenantLoginDto {
    public tenantId: number;
    public device_token: string;
    public platform: Platforms;
    public solution: Solutions;
    public os: OperatingSystems;

    public static build(tenantId: number, deviceToken: string, platform: Platforms, solution: Solutions, os: OperatingSystems): SwitchTenantLoginDto {
        let result = new SwitchTenantLoginDto();
        result.tenantId = tenantId;
        result.device_token = deviceToken;
        result.platform = platform;
        result.solution = solution;
        result.os = os;
        return result;

    }

}

export class SetNewPasswordDTO {
    public token: string;
    public password: string;
}

export class LostPasswordDTO {
    public email: string;
    public prefix: string;
    public phone: string;
    public platform: Platforms;
    public solution: Solutions;
    public os: OperatingSystems;

    public static buildPhone(
        prefix: string, phone: string, platform: Platforms, solution: Solutions, os: OperatingSystems
    ): LostPasswordDTO {
        const result = new LostPasswordDTO();
        result.prefix = prefix;
        result.phone = phone;
        result.platform = platform;
        result.solution = solution;
        result.os = os;
        return result;
    }

    public static buildEmail(
        email: string, platform: Platforms, solution: Solutions, os: OperatingSystems
    ): LostPasswordDTO {
        const result = new LostPasswordDTO();
        result.email = email;
        result.platform = platform;
        result.solution = solution;
        result.os = os;
        return result;
    }
}
