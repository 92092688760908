import { Pipe, PipeTransform } from '@angular/core';
import { ActionDto } from './actionDto';

@Pipe({
    name: "avoidActions"
})
export class avoidActionsFilter implements PipeTransform {
    public transform(items: ActionDto[], term: string) {
        if (!items) return [];
        if (!term) return items;

        let lowercase = term.toLowerCase();

        return items.filter(value => {
            if (value != null && value.classification != null && value.classification.toLowerCase() === lowercase)
                return false;
            return true;
        });
    }
}