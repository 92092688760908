<div *ngIf="route != null && routeGroup != null" class="block">
    <div class="titol">
        {{ title | translate }}
    </div>

    <div class="block" *ngIf="(mode=='routeGroup' || mode=='routeGroupAdd') && !showResultGrouped">

        <div class="input-data">
            <input-component class="input-routekey" [(ngModel)]="newRouteKey"
                placeholder="{{'SHARED.EXWORK.ROUTEGROUP.NEWROUTEKEY' | translate}}"></input-component>
            <div class="block-buttons">
                <button class="action afegir" [disabled]="readonly || newRouteKey == null || newRouteKey == ''"
                    (click)="onRouteKeyAdd()">{{ 'SHARED.EXWORK.ROUTEGROUP.ACTION.ADD' | translate}}</button>
                <button class="action acceptar"
                    [disabled]="readonly || listRouteItemKeys == null || listRouteItemKeys.length < 2"
                    (click)="onRouteGroupedActualized()">{{ 'SHARED.EXWORK.ROUTEGROUP.ACTION.GROUPED' |
                    translate}}</button>
            </div>

        </div>
        <div class="info-list-routekeys">
            <div class="info-list-title">
                {{ 'SHARED.EXWORK.ROUTEGROUP.LISTKEYS.TITLE' | translate }}
            </div>
            <div class="item-key" *ngFor="let routeitem of listRouteItemKeys">
                <span class="text">{{ routeitem.routekey }}</span><i
                    *ngIf="route.routeKey != routeitem.routekey && routeitem.pendingAdd" class="fa fa-times-circle"
                    aria-hidden="true" (click)="onRouteKeyRemove(routeitem)"></i>
            </div>

        </div>

        <div class="help">
            {{ 'SHARED.EXWORK.ROUTEGROUP.GROUP.HELP' | translate}}
        </div>
        <div class="help" [ngClass]="{'hide' : route.isGrouped }">
            {{ 'SHARED.EXWORK.ROUTEGROUP.NOTGROUPED.HELP' | translate: { referencia:route.routeKey } }}
        </div>
        <div class="help" [ngClass]="{'hide' : !route.isGrouped }">
            {{ 'SHARED.EXWORK.ROUTEGROUP.ISGROUPED.HELP' | translate: { referencia:route.routeKey } }}
        </div>

    </div>


    <div class="block" *ngIf="mode=='routeGroupRemove' && !showResultGrouped">

        <div class="info-list-routekeys">

            <div class="item-key" *ngFor="let routeitem of listRouteItemKeys">
                <span class="text">{{ routeitem.routekey }}</span> <checkbox-component
                    [(ngModel)]="routeitem.pendingRemove"></checkbox-component>
            </div>

        </div>
        <div class="block-buttons eliminar">
            <button class="action eliminar"
                [disabled]="readonly || listRouteItemKeys == null || listRouteItemKeys.length < 1"
                (click)="onRouteGroupedActualized()">{{ 'SHARED.EXWORK.ROUTEGROUP.ACTION.REMOVE' | translate}}</button>
        </div>
        <div class="help">
            {{ 'SHARED.EXWORK.ROUTEGROUP.REMOVE.HELP' | translate }}
        </div>
    </div>

    <div class="block result" *ngIf="showResultGrouped">

        <div class="infotextresult">
            {{ 'SHARED.EXWORK.ROUTEGROUP.NEWUUID.HELP' | translate }}
        </div>

        <div class="line referencia">
            {{'SHARED.EXWORK.ROUTEGROUP.UUID' | translate}} <span class="referenciaCode">{{ uuid }}</span>
        </div>
        <div class="info-list-phasecard">
            <div class="item-phase" *ngFor="let phase of _phasesSlots">
                <shared-exwork-phasecard-component [phase]="phase">
                </shared-exwork-phasecard-component>
                <shared-secret [value]="phase.verificationCode" [slotInfo]="phase.slotInfo"></shared-secret>
            </div>
        </div>

        <div class="block-send-newuiid">
            <div class="send-email-title">
                {{ 'SHARED.EXWORK.ROUTEGROUP.NEWUUID.SENDEMAILTITLE' | translate}}
            </div>
            <input-component class="input-routekey" [(ngModel)]="emalsSendNewUuid"></input-component>
            <div class="block-buttons">
                <button class="action send" [disabled]="readonly || emalsSendNewUuid == null || emalsSendNewUuid == ''"
                    (click)="onSendEmailNewUuid()">{{ 'SHARED.EXWORK.ROUTEGROUP.ACTION.SEND' | translate}}</button>
            </div>
        </div>

        <div class="info-list-routekeys">
            <div class="info-list-title">
                {{ 'SHARED.EXWORK.ROUTEGROUP.LISTKEYS.TITLE' | translate }}
            </div>
            <div class="item-key" *ngFor="let routeitem of listRouteItemKeys">
                <span class="text">{{ routeitem.routekey }}</span><i
                    *ngIf="route.routeKey != routeitem.routekey && routeitem.pendingAdd" class="fa fa-times-circle"
                    aria-hidden="true" (click)="onRouteKeyRemove(routeitem)"></i>
            </div>

        </div>

        <button-link variant="forward" class="line left" (linkClick)="onGoNewUUID()">{{
            'SHARED.EXWORK.ROUTEGROUP.LINK.GONEWUUID' | translate}}</button-link>

    </div>

    <div class="dummy">
        dummy
    </div>
</div>