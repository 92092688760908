<shared-generalstatus-container class="card" [ngClass]="{internalTags}" *ngIf="phase"
    [generalStatus]="phase.generalStatus">
    <div class="internalcard"
        [ngClass]="{'isSlotBlocked':phase.isSlotBlocked, 'incompatibleSlot':phase.incompatibleSlot}">
        <div [ngClass]="{'line': !phase.isIntermodal, 'bookingHeader': phase.isIntermodal}">
            <div class="icona">
                <i *ngIf="phase.isInitial || phase.isFinal" class="fa fa-circle-thin"></i>
                <img *ngIf="phase.isUnload" src="/assets/images/unloadCargo.png" alt="unload Cargo">
                <img *ngIf="phase.isLoad" src="/assets/images/loadCargo.png" alt="unload Cargo">
                <img *ngIf="phase.isUnloadLoad" src="/assets/images/loadUnloadCargo.png" alt="unload Cargo">

                <i *ngIf="phase.isIntermodal" class="fa fa-ship" alt="unload Cargo"></i>
                <div class="intermodalPhaseType" *ngIf="phase.isIntermodal">
                    {{ 'PHASE.TYPES.' + phase.type.toUpperCase() | translate }}
                </div>
            </div>
            <div class="shipNameBookingNumber">
                <div *ngIf="phase.isIntermodal" class="executionDate shipName">
                    {{phase.bookingShipName}} <shared-copyclipboard
                        [value]="phase.bookingShipName"></shared-copyclipboard>
                </div>
                <div *ngIf="phase.isIntermodal" class="bookingKey">
                    {{phase.bookingNumber}} <shared-copyclipboard [value]="phase.bookingNumber"></shared-copyclipboard>
                </div>
            </div>

            <div *ngIf="!phase.isIntermodal" class="executionDate">
                {{phase.executionDate | datadetall }}
                <span class="disponibilityMessage">{{phase.disponibilityMessage}}</span>
            </div>
            <div *ngIf="!phase.isIntermodal" class="phaseKey">
                {{phase.phaseKey}}-{{phase.phaseId}}
            </div>
        </div>
        <div class="line" *ngIf="!phase.isIntermodal">
            <span class="pre-tags-strip">@</span>
            <shared-tags-strip-component class="tags" [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
            <shared-tags-strip-component *ngIf="phase.showStrongAddress" class="tags"
                [uniqueString]="phase.strongAddressSkin"></shared-tags-strip-component>
            <div class="text address" matTooltip="{{phase.formattedAddress}}">{{phase.address}}</div>
        </div>
        <div class="line" *ngIf="!phase.isIntermodal">
            <span class="pre-tags-strip">@</span>
            <!-- <shared-tags-strip-component class="tags manualTags  no-margin-between" [tags]="phase.manualTags"
                *ngIf="haveTags(phase.manualTags)"></shared-tags-strip-component> -->
            <shared-tags-strip-component class="tags no-margin-between" [tagsString]="tags" [applyColor]="false">
            </shared-tags-strip-component>

            <div class="docks">
                <div *ngFor="let dock of (phase.docksEnabled ? phase.docksEnabled : [])" class="enabled-dock">{{ dock }}
                </div>
            </div>
        </div>
        <div *ngIf="isActiveRoutes && phase.pendingMessagesRead > 0" class="pending-messages">
            <i class="fa fa-commenting icon" aria-hidden="true"></i>
            <div class="number">{{ phase.pendingMessagesRead }}</div>
        </div>
        <div class="line" *ngIf="!phase.isIntermodal">
            <div class="text goods ellipsis">{{phase.goods}}</div>
        </div>

        <div class="bookingData" *ngIf="phase.isIntermodal">
            <div class="originPortSkin full">
                <shared-tags-strip-component class="tags square"
                    [uniqueString]="phase.bookingOriginPortSkin"></shared-tags-strip-component>
            </div>

            <div class="datetimesStart">
                <div class="depositStart bookingLabel">
                    <span><span class="prompt">{{ 'PHASE.BOOKINGDEPOSITSTART.CAPTION' |
                            translate }}: </span><span *ngIf="phase.bookingDepositStart" class="weekday"> {{
                            phase.bookingDepositStart |
                            datahoradetallonlydiasetmana }}</span></span><br>
                    <span class="value">{{ phase.bookingDepositStart | datehournosecondsnotz }}</span>
                </div>
                <div class="datetimeStart bookingLabel">
                    <span><span class="prompt">{{ 'PHASE.BOOKINGDATETIMESTART.CAPTION' |
                            translate }}: </span><span *ngIf="phase.bookingDatetimeStart" class="weekday"> {{
                            phase.bookingDatetimeStart |
                            datahoradetallonlydiasetmana }}</span></span><br>
                    <span class="value">{{ phase.bookingDatetimeStart | datehournosecondsnotz }}</span>
                </div>
            </div>


            <div class="datetimesEnd">
                <div class="depositEnd bookingLabel">
                    <span><span class="prompt">{{ 'PHASE.BOOKINGDEPOSITEND.CAPTION' | translate
                            }}: </span><span *ngIf="phase.bookingDepositEnd" class="weekday"> {{ phase.bookingDepositEnd
                            |
                            datahoradetallonlydiasetmana
                            }}</span></span><br>
                    <span class="value"> {{ phase.bookingDepositEnd | datehournosecondsnotz }}</span>
                </div>
            </div>

            <div class="destinationPortSkin">
                <shared-tags-strip-component class="tags  square"
                    [uniqueString]="phase.bookingDestinationPortSkin"></shared-tags-strip-component>
            </div>
        </div>

        <shared-slot-viewer-component class="esquerra"
            *ngIf="!phase.isChildOfGrouped && !phase.isDivided && phase.requiresSlot" [slotInfo]="phase.slotInfo">
        </shared-slot-viewer-component>

        <div class="dockInfo" *ngIf="phase.dockSkin != null && phase.dockSkin.length > 0"
            [style.color]="getTextColor(phase.dockBackgroundColor)"
            [style.background-color]="getBackColor(phase.dockBackgroundColor)">{{ phase.dockSkin }}</div>

        <div class="line separacio" *ngIf="phase.verificationCode">
            <shared-secret class="box" [iPhaseSlot]="phase"></shared-secret>
        </div>

        <div class="line">
            <ng-content></ng-content>
        </div>

        <shared-phase-timeline *ngIf="phases && phase" class="bottomData routeTimeline show-times" [phases]="phases"
            [actualphase]="phase">
        </shared-phase-timeline>
    </div>
</shared-generalstatus-container>