<ion-content [fullscreen]="true">
    <div class="component component-fullpage">
        <div class="group">
            <div class="subtitle">{{ 'LOGOUTPAGE.TITLE' | translate }}</div>
        </div>
        <div class="content">{{ 'LOGOUTPAGE.HELP' | translate }}</div>
        <div class="group boto">
            <button color="action" class="action seguent sol"
                (click)="anarLogin()">{{'LOGOUTPAGE.BUTTON.LABEL' | translate}}</button>
        </div>
        <div class="imgcontainer">
            <img class="logo" src="/assets/images/HardmanLogoBlauNom.png" alt="Hardman logo">
        </div>
    </div>
</ion-content>