import { Injectable } from "@angular/core";
import { RoutePermissionTypes } from "@shared/src/enums/RoutePermissionTypes";

@Injectable()
export class SetRoutePartnerTypeForcedDto {
    public routeId: number;
    public permissionType: RoutePermissionTypes;
    public value: boolean;

    public static build(routeId: number, permissionType: RoutePermissionTypes, value: boolean):SetRoutePartnerTypeForcedDto{
        let data: SetRoutePartnerTypeForcedDto = new SetRoutePartnerTypeForcedDto();
        data.routeId = routeId;
        data.permissionType = permissionType;
        data.value = value;
        return data;
    }
}