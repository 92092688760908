import { HttpClient } from "@angular/common/http";
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseController } from "../base.controller";
import { SecurityController } from "../security/security.controller";
import { TenantProductDto } from '@shared/src/dtos/tenants/TenantProductDto';
import { FileUpDownLoadDto } from "@shared/src/dtos/base/FileUpDownLoadDto";
import { ImageTypes } from "@shared/src/enums/ImageTypes";
import { TenantDiscountDto } from "@shared/src/dtos/tenants/TenantDiscountDto";
import { PartnerTypes } from "@shared/src/public-api";

@Injectable({
    providedIn: 'root',
})
export class SystemController extends BaseController {
    private base = '/system';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getProducts(): Observable<Array<TenantProductDto>> {
        return super.internal_get(this.base + "/products");
    }
    public getDiscounts(): Observable<Array<TenantDiscountDto>> {
        return super.internal_get(this.base + "/discounts");
    }

    public productchangestate(product: TenantProductDto): Observable<TenantProductDto> {
        return this.postBusy(this.base + "/productchangestate", product, "productchangestate");
    }
    public useDiscount(discountCode: string): Observable<boolean> {
        return this.postBusy(this.base + "/usediscount", discountCode, "addDiscount");
    }
    public saveImage(body: FileUpDownLoadDto, imageType: ImageTypes): Observable<boolean> {
        return this.postBusy(this.base + "/save/imagetype/" + imageType, body, "saveLogo");
    }

    public getImage(imageType: ImageTypes): Observable<FileUpDownLoadDto> {
        return new Observable((observer) => {
            this.securityController.isLogged().subscribe(b => {
                if (b)
                    this.internal_get<FileUpDownLoadDto>(this.base + "/get/imagetype/" + imageType).subscribe(t => {
                        observer.next(t);
                        observer.complete();
                        return;
                    });
                else {
                    observer.next(null);
                    observer.complete();
                }
            },
                (err) => {
                    observer.next(null);
                    observer.complete()
                },
                () => {
                }
            )
        }
        );
    }

    public getLogo(tenantId: number): Observable<FileUpDownLoadDto> {
        return this.internal_get(this.base + "/logo/" + tenantId);
    }


}