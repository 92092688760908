import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { VersionService } from '../../services/version/version.service';
import { LogController } from '../log/LogController';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { environment } from '@shared/src/public-api';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUser = LogController.APIKEY;
        if (currentUser) {
            if (!this.staticFile(request)) {
                request = request.clone({
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'Version': VersionService.version,
                        'Authorization': currentUser
                    }
                });
            } else {
                request = request.clone({
                    setHeaders: {
                        'Version': VersionService.version,
                    }
                });
            }
        }
        return next.handle(request).pipe(tap(() => {
        },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    // this.router.navigate(['login']);
                }
            }));
    }

    /**
     * Comprovem si estem accedint a un fitxer, en cas afirmatiu, no afegirem la capçalera 
     * d'Authorization ja que ens genera problemes amb Google Cloud
     * @param request
     * @returns 
     */
    staticFile(request: HttpRequest<any>): boolean {
        if (!request)
            return false;

        const url = request.url.toUpperCase();
        for (const element of Object.keys(FileTypes)) {
            if (url.endsWith("." + element.toUpperCase())) {
                return true
            }
        };
        return false;
    }

}