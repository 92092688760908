import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HColor } from '@shared/src/datatypes/HColor';
import { HTags } from '@shared/src/datatypes/HTags';
import { AssignedSlotDto } from '@shared/src/dtos/address/slots/AssignedSlotDto';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { DragDropService } from '@shared/src/services/dragdrop/DragDropService';


@Component({
    selector: 'shared-route-item-list',
    templateUrl: './shared.route.item.list.component.html',
    styleUrls: ['./shared.route.item.list.component.scss']
})
export class SharedRouteItemListComponent implements OnInit {

    public MAX_TAGS_IN_LIST_ITEM: number = 8;
    public useIonic: boolean = false;
    constructor(@Inject('HomeController') protected homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();

        this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
            if (data && data.chats)
                data.chats.forEach(e => {
                    if (e && this.route)
                        if (e.ownerParticipantType == ChatParticipantTypes.Route && e.ownerParticipantId == this.route.routeId) {
                            if (e.pendingMessagesRead != null)
                                this.route.pendingMessagesRead = e.pendingMessagesRead;
                        }
                });
        });
    }

    public refreshed: boolean = false;
    private _ultim: boolean;
    @Input()
    set ultim(value: boolean) {
        this._ultim = value;

        if (this._ultim && !this.refreshed) {
            this.homeController.chatsChanged$.next(this.homeController.CHATS);
            this.refreshed = true;
        }
    }
    get ultim(): boolean {
        return this._ultim;
    }

    @Input()
    public isActiveRoutes: boolean = false;

    @Input()
    public isDraggable: boolean = false;


    private _route: RouteCardItemDto;
    @Input()
    set route(value: RouteCardItemDto) {
        if (this._route === value)
            return;
        this._route = value;
        this.obtainValues();
    }
    get route(): RouteCardItemDto {
        return this._route;
    }

    showAssignedSlots: boolean;
    firstAssignedSlotDate: String;
    firstAssignedSlotInterval: string;
    tooltipAssignedSlots: string;
    private obtainValues() {
        if (this._route != null) {
            if (this._route.assignedSlots != null && this._route.assignedSlots.length > 0) {
                this.showAssignedSlots = true;
                this.firstAssignedSlotDate = AssignedSlotDto.toDateString(this._route.assignedSlots[0]);
                this.firstAssignedSlotInterval = AssignedSlotDto.toHourIntervalsString(this._route.assignedSlots[0]);
                this.tooltipAssignedSlots = AssignedSlotDto.toToolTipTextString(this._route.assignedSlots);
            } else {
                this.showAssignedSlots = false;
                this.firstAssignedSlotDate = null;
                this.firstAssignedSlotInterval = "";
                this.tooltipAssignedSlots = "";
            }

            this.allTags = this._route.allTags;
        }
        else {
            this.showAssignedSlots = false;
            this.firstAssignedSlotDate = null;
            this.firstAssignedSlotInterval = "";
            this.tooltipAssignedSlots = "";
        }
    }

    @Output() clickShowChilds: EventEmitter<boolean> = new EventEmitter();
    onClickShowChilds(e) {
        //this.route.showChilds = !this.route.showChilds;
        //this.clickShowChilds.next(this.route.showChilds);
        e.preventDefault();
        e.stopPropagation();
    }

    @Input() selected: boolean;

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    public haveTags(tags: HTags): boolean {
        if (HTags.isEmpty(tags))
            return false;
        return true;
    }

    public allTags: HTags;

    ngOnInit(): void {
    }

    onDragEnter(event: DragEvent) {
        return;
    }
    onDragLeave(event: DragEvent) {
        return;
    }

    public getGeneralClass(): string {
        let result = "";
        if (this.selected)
            result += "selected ";
        if (this.isDraggable)
            result += "dragable ";
        if (this.route != null) {
            if (this.route.isDivided)
                result += "divided divided-childs ";
        }
        result += HTags.convertToStringWithDelimiter(this.route.internalTags, " ");
        return result;
    }

    public onDragStart(event: DragEvent) {

        if (this.route == null)
            return;
        DragDropService.moverOrCopy(event);
        event.dataTransfer.setData(DragDropService.TEXTPLAIN, DragDropService.HARDMAN_ROUTECARDITEMDTO);
        event.dataTransfer.setData(DragDropService.HARDMANTYPE, JSON.stringify(this.route));
    }


    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
