import { TimeZones } from "../../enums/TimeZones";

export class ManualAddressDto {
    public id: number;
    public skin: string;
    public key: string;
    public addressLine1: string;
    public addressLine2: string;
    public address: string;
    public city: string;
    public province: string;
    public state: string;
    public postalCode: string;
    public country: string;
    public formattedAddress: string;
    public timeZone: TimeZones;
    public latitude: number;
    public longitude: number;
}