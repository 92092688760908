<table class="telemetry">
    <tr class="header">
        <th class="col valor">
            {{'SHARED.TELEMETRY.ITEM.TITLE' | translate}}
        </th>
        <th classs="col valor">
            {{telemetry.moment | datehour }}
        </th>
    </tr>
    <tr class="header">
        <th class="prompt">
            {{'MOBILE.GEONOTCH.COMPONENT.LATITUDE.CAPTION' | translate}}
        </th>
        <th class="prompt">
            {{'MOBILE.GEONOTCH.COMPONENT.LONGITUDE.CAPTION' | translate}}
        </th>
    </tr>
    <tr>
        <td class="col valor">
            {{telemetry.latitude}}
        </td>
        <td class="col valor">
            {{telemetry.longitude}}
        </td>
    </tr>
    <tr>
        <th class="col prompt" colspan="2">
            {{'MOBILE.GEONOTCH.COMPONENT.SPEED.CAPTION' | translate}}
        </th>
    </tr>
    <tr>
        <td class="col valor" colspan="2">
            {{telemetry.speed}}
        </td>
    </tr>
    <tr>
        <th class="col prompt" colspan="2">
            {{'MOBILE.GEONOTCH.COMPONENT.ALTITUDE.CAPTION' | translate}}
        </th>
    </tr>
    <tr>
        <td class="col valor" colspan="2">
            {{telemetry.altitude}}
        </td>
    </tr>
    <tr *ngIf="originAddress" class="manualAddress">
        <th class="col prompt" colspan="2">
            {{'SHARED.TELEMETRY.ITEM.INICIAA' | translate}}
        </th>
    </tr>
    <tr *ngIf="originAddress" class="manualAddress">
        <td class="col valor" colspan="2">
            {{originAddress.formattedAddress}}
        </td>
    </tr>
    <tr *ngIf="destinationAddress" class="manualAddress">
        <th class="col prompt" colspan="2">
            {{'SHARED.TELEMETRY.ITEM.ACABAA' | translate}}
        </th>
    </tr>
    <tr *ngIf="destinationAddress" class="manualAddress">
        <td class="col valor" colspan="2">
            {{destinationAddress.formattedAddress}}
        </td>
    </tr>
</table>