import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoadingService } from '@shared/src/public-api';
import { BaseMaintenanceController } from '../../_base/BaseMaintenanceController';
import { BannedPhoneItemMaintenanceDto } from './BannedPhoneItemMaintenanceDto';
import { BannedPhoneListMaintenanceDto } from './BannedPhoneListMaintenanceDto';

@Injectable()
export class BannedPhoneMaintenanceController extends BaseMaintenanceController<BannedPhoneListMaintenanceDto, BannedPhoneItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "bannedphone");
    }

    public getResourceRoot(): string {
        return "BANNEDPHONE";
    }

    public buildUrl(uri: string): string {
        return "system/securityandnotifications/bannedphone" + uri;
    }
}