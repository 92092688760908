<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="route" [formGroup]="rForm">
    <div class="title">
        {{'SHARED.ROUTE.CHANGEBANNERS.COMPONENT.TITLE' | translate}}
    </div>
    <div class="block-help">
        {{'SHARED.ROUTE.CHANGEBANNERS.COMPONENT.HELP' | translate}}
    </div>

    <div *ngIf="route.routePermission == 'Owner'">
        <input-component class="col-x inputGeneral grupGeneral htmlBanner" [(ngModel)]="route.bannerHtml"
            formControlName="bannerHtml" placeholder="{{'ROUTE.BANNERHTML.CAPTION' | translate}}" name="bannerHtml"
            autocomplete="off">
        </input-component>
    </div>

    <div>
        <input-component class="col-x inputGeneral grupGeneral htmlBanner" [(ngModel)]="route.privateBannerHtml"
            placeholder="{{'ROUTE.PRIVATEBANNERHTML.CAPTION' | translate}}" name="privateBannerHtml" autocomplete="off"
            formControlName="privateBannerHtml">
        </input-component>
    </div>
    <shared-savebutton *ngIf="rForm.dirty" (save)="save($event)" [readonly]="readonly">
        {{'ROUTEPAGE.ITEM.EDIT.BUTTON.SAVE' | translate}}
    </shared-savebutton>
</div>