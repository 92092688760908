
<div *ngIf="value" class="ecmr-item" [ngClass]="{'ionic' : useIonic}">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="item-title">
                    <div class="top-left">
                        {{ resourceTitle | translate}}  {{ value.originAddressKey }}  {{ value.destinationAddressKey }} 
                    </div>  
                    <div class="top-center">
                        
                    </div>
                    <div class="top-right">
                        
                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <shared-route-part-cmr-digitaldocument-component [routePartCMRId]="value.routePartCMRId">
            </shared-route-part-cmr-digitaldocument-component>
            <shared-documentaryreview-item-documents-component class="block-data" [doLoadDocuments]="false" [parentObject]="parentObject" [documents]="documents" (dataRefresh)="onDataRefresh($event)"></shared-documentaryreview-item-documents-component>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</div>