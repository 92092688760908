<div class="container" [ngClass]="{'ionic' : useIonic, 'activeroutes':opcio=='activeroutes'}">
    <div class="linia inicial" [ngClass]="opcio">
        <button class="general-link-boto activeroutes"
            (click)="setOpcio('activeroutes')">{{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.OPTION.CONSOLE.PROMPT' |
                            translate}}</button>
        <button class="general-link-boto route"
            (click)="setOpcio('route')">{{'SHARED.LOADCONSOLE.PHASE.INFO.COMPONENT.OPTION.ROUTE.PROMPT' | translate}}</button>
        <button class="general-link-boto jump" (click)="setOpcio('AR')"><i class="fa fa-share"
                aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AR.PROMPT' | translate}}</button>
        <button class="general-link-boto jump" (click)="setOpcio('AS')"><i class="fa fa-share"
                aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AS.PROMPT' | translate}}</button>
    </div>
    <ng-container *ngIf="opcio=='activeroutes' && route && phase">
        <shared-phase-loadconsole-management [phaseInfo]="phaseInfo"></shared-phase-loadconsole-management>
    </ng-container>
</div>
<shared-route-info-component class="aside nolinks" *ngIf="opcio=='route'" [routeId]="routeId">
</shared-route-info-component>