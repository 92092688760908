import { Injectable } from '@angular/core';
import { ShipmentDeliveryLineDto } from './ShipmentDeliveryLineDto';

@Injectable()
export class PackageDto {

	public packageId: number;
	public packageKey: string;
	public xDimension: number;
	public yDimension: number;
	public zDimension: number;
	public kg: number;

	public labelCandidate: boolean;
	public hasLabel: boolean;
	public inESchenker: boolean;

	public lines: Array<ShipmentDeliveryLineDto>;
	public shipmentDeliveryLineIdToAdd: number;
}
