import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedSelectorAddressManualAddressEvent } from '@shared/src/components/assistedaddress/shared.selector.addressmanualaddress.component';
import { DateHourValidator } from '@shared/src/components/core/form/validations/DateHourValidator';
import { HourIntervalsValidator } from '@shared/src/components/core/form/validations/HourIntervalsValidator';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { ShipmentAddressDto } from '@shared/src/dtos/shipment/ShipmentAddressDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { HDateHour } from '@shared/src/public-api';


@Component({
    selector: 'shared-shipment-multi-address-component',
    templateUrl: './shared.shipment.multi.address.component.html',
    styleUrls: ['./shared.shipment.multi.address.component.scss']
})
export class SharedShipmentMultiAddressComponent implements OnInit {

    public readonly: boolean = false;


    rForm: FormGroup;
    
    constructor(public openRouteController: OpenRouteController, @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController, private shipmentController: ShipmentController,
        private fb: FormBuilder) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

        this.rForm = this.fb.group({
            'dateTimeStart': ['', [
              DateHourValidator.validate,
              Validators.required]],
            'dateTimeEnd': ['', [
                DateHourValidator.validate,
              Validators.required]],
            'timetable': ['', [HourIntervalsValidator.validate]],
            'goods': ['', [Validators.maxLength(255)]]
          });

    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.loadFilerAddress();
        this.loadData();
    }

    private _isOrigin: boolean = true;
    @Input()
    set isOrigin(value: boolean) {
        if (this._isOrigin == value)
            return;

        this._isOrigin = value;

        this.loadFilerAddress();

    }
    get isOrigin(): boolean {
        return this._isOrigin;
    }


	public shipmentAddress: ShipmentAddressDto;

    public _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment === value)
            return;
        this._shipment = value;
        
        this.loadFilerAddress();
        this.loadData();
    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }

    public ADDRESS_FILTERKEYS: Array<string> = ['ADDRESS.NOTHING.FILTER'];
    public loadFilerAddress(){
        if(this.isOrigin){
            if(this.shipment != null && !HLong.isNullOrNullLong(this.shipment.partnerProviderId))
                this.ADDRESS_FILTERKEYS = ['ADDRESS.BYPARNTERID.FILTER:' + this.shipment.partnerProviderId];
            else
                this.ADDRESS_FILTERKEYS = ['ADDRESS.FILTER.OWN'];
        }else{
            if(this.shipment != null && !HLong.isNullOrNullLong(this.shipment.partnerClientId))
                this.ADDRESS_FILTERKEYS = ['ADDRESS.BYPARNTERID.FILTER:' + this.shipment.partnerClientId];
            else
                this.ADDRESS_FILTERKEYS = ['ADDRESS.FILTER.OWN'];
        }
    }

    public listAddress: ShipmentAddressDto[] = new Array();
    public loadData(){

        if(!this.shipment)
            return;

        if(this.isOrigin){
            this.shipmentController.getAddressOrigin(this.shipment.shipmentId).subscribe(data => {
                if(data)
                    this.listAddress = data;
                else
                    this.listAddress = new Array();       
            });

        }else{
            this.shipmentController.getAddressDestination(this.shipment.shipmentId).subscribe(data => {
                if(data)
                    this.listAddress = data;
                else
                    this.listAddress = new Array();  
            });
        }
    }

    public getResourceType(address: ShipmentAddressDto): string{
        if(address == null)
            return "";

        if(address.isOrigin)
            return 'SHIPMENT.MULTI.ADDRESS.ORIGIN.CAPTION';
        return 'SHIPMENT.MULTI.ADDRESS.DESTINATION.CAPTION';
    }

    public getResourceConfirm(): string{
        if(this.isOrigin)
            return 'SHIPMENT.MULTI.ADDRESS.ORIGIN.CONFIRM.CAPTION';
        return 'SHIPMENT.MULTI.ADDRESS.DESTINATION.CONFIRM.CAPTION';
    }

    public getResourceAddAddress(): string{

        if(this.showFormAddress){
            if(this.isOrigin)
                return 'SHIPMENT.MULTI.ADDRESS.ORIGIN.CANCEL.CAPTION';
            return 'SHIPMENT.MULTI.ADDRESS.DESTINATION.CANCEL.CAPTION';
        }
        if(this.isOrigin)
            return 'SHIPMENT.MULTI.ADDRESS.ORIGIN.ADD.CAPTION';
        return 'SHIPMENT.MULTI.ADDRESS.DESTINATION.ADD.CAPTION';
    }

    public getResourceDeleteAddress(address: ShipmentAddressDto): string{
        if(address == null)
            return "";

        if(address.isOrigin)
            return 'SHIPMENT.MULTI.ADDRESS.ORIGIN.CANCEL.CAPTION';
        return 'SHIPMENT.MULTI.ADDRESS.DESTINATION.CANCEL.CAPTION';
    }


    public showFormAddress: boolean = false;
    public onAddAddress(){
        this.showFormAddress = !this.showFormAddress;

        if(!this.shipment)
            return;

        if(this.showFormAddress){
            if(this.isOrigin){
                this.shipmentController.emptyAddressOrigin(this.shipment.shipmentId).subscribe(data => {
                    this.shipmentAddress = data;
                });
            }else{
                this.shipmentController.emptyAddressDestination(this.shipment.shipmentId).subscribe(data => {
                    this.shipmentAddress = data;
                });
            }
        }else{
            this.shipmentAddress = null;
        }
    }
    public onSaveAddress(){
        if(!this.shipment)
            return;

        if (this.shipmentAddress && this.shipmentAddress.addressManualAddress){
            this.shipmentController.addAddress(this.shipment.shipmentId, this.shipmentAddress).subscribe(data => {
                if(data){ 
                    this.showFormAddress = false;
                    this.shipmentAddress = null;
                    this.valueChange.next(data);
                    this.loadData();
                }
            });
        }
    }

    public onDeleteAddress(address: ShipmentAddressDto){
        if (address){
            this.shipmentController.deleteAddress(this.shipment.shipmentId, address).subscribe(data => {
                if(data){
                    this.showFormAddress = false;
                    this.shipmentAddress = null;
                    this.loadData();
                }
            });
        }
    }
    
      /***
	 * Ha canviat l'adreça d'origen, estableixo els valors predeterminats d'horari
	 */
	public onChangedAddress($event: SharedSelectorAddressManualAddressEvent) {
		if ($event.address) {
			if (this.shipmentAddress && this.shipmentAddress.addressManualAddress){
                this.shipmentAddress.addressManualAddress.loadIntervals = $event.address.loadTimetableIntervals;
                this.shipmentAddress.addressManualAddress.unloadIntervals = $event.address.unloadTimetableIntervals;
                this.shipmentAddress.dateTimeStart = HDateHour.buildByHDateHour( this.shipmentAddress.dateTimeStart, this.shipmentAddress.addressManualAddress.manualAddress.timeZone, this.shipmentAddress.addressManualAddress.manualAddress.timeZone);
                this.shipmentAddress.dateTimeEnd = HDateHour.buildByHDateHour( this.shipmentAddress.dateTimeEnd, this.shipmentAddress.addressManualAddress.manualAddress.timeZone, this.shipmentAddress.addressManualAddress.manualAddress.timeZone);
            }
		}
	}

    public set dateTimeStart(value: HDateHour) {
        if (value == this.dateTimeStart)
            return;

        const prevValue = this.dateTimeStart;

        this.shipmentAddress.dateTimeStart = value;
        if (prevValue && value) {
            if (HDateHour.isNullOrNullValue(this.shipmentAddress.dateTimeEnd)) {
                this.shipmentAddress.dateTimeEnd = value;
            } else {
                let distance = HDateHour.distance(prevValue, value);
                this.shipmentAddress.dateTimeEnd = HDateHour.buildByHDateHour(this.shipmentAddress.dateTimeEnd, this.shipmentAddress.dateTimeEnd.Timezone, this.shipmentAddress.dateTimeEnd.TimezoneObserver);
                this.shipmentAddress.dateTimeEnd = this.shipmentAddress.dateTimeEnd.addDateHour(distance, HDateHour.isBefore(value, prevValue));
            }
        }
    }

    public get dateTimeStart(): HDateHour {
        return this.shipmentAddress.dateTimeStart;
    }
   
}
