import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseController } from '../base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { OpenRouteController } from '../open/open.route.controller';
import { MatDialog } from '@angular/material/dialog';
import { FileTypes } from '@shared/src/enums/FileTypes';

@Injectable()
export class FileUpDownloadController extends BaseController {

  constructor(protected http: HttpClient,
    @Inject('AlertService') protected alertService: AlertService,
    protected translateService: TranslateService,
    @Inject('LoadingService') protected loadingService: LoadingService,
    protected router: Router,
    protected openRouteController: OpenRouteController,
    public dialog: MatDialog) {
    super(http, alertService, translateService, loadingService, router);
  }

  public uploadFile(url: string, body: any, transactionId: string): Observable<string> {
    return this.post(url, body, transactionId);
  }

  public static resultDownLoad(file: FileUpDownLoadDto) {

    this.resultDownLoadByParameters(file.fileName, file.fileString, file.fileType);
    
  }

  public static resultDownLoadByParameters(fileName: string, fileString: string, fileType: FileTypes) {

    var byteArray = FileUpDownLoadDto.getUint8ArrayFromString(fileString);
    var blob = new Blob([byteArray], { type: FileUpDownLoadDto.getContentType(fileType) });

    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  }


  public downloadShipmentDocument(shipmentId: number, documentId: number) {
    if (shipmentId == null || shipmentId == 0 || documentId == null || documentId == 0)
      return;
    this.openRouteController.downloadShipmentDocument(shipmentId, documentId).subscribe(data => {
      FileUpDownloadController.resultDownLoad(data);
    });
  }

  public showUploadDocumentType: boolean = false;
  onClickButtonSelectFile() {
    this.showUploadDocumentType = !this.showUploadDocumentType;
  }
}
