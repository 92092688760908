<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="value">
    <div class="title" *ngIf="!hideTitle">{{'BOOKINGPAGE.CONTAINERNUMBER.TITLE'|translate}}</div>
    <ng-container *ngIf="!editMode">
        <div class="block read" *ngIf="showInfoContainer">

            <div class="box container-number">
                <div class="toplabel">{{'BOOKINGPAGE.CONTAINERNUMBER.CAPTION' | translate}}</div><span class="no-data"
                    *ngIf="!value.containerNumber">{{'BOOKINGPAGE.NOCONTAINERNUMBER' | translate}}</span>
                {{value.containerNumber}}
            </div>
            <div class="box tare">
                <div class="toplabel">{{'BOOKINGPAGE.TARE.CAPTION' | translate}}</div><span class="no-data"
                    *ngIf="!value.tare">{{'BOOKINGPAGE.NOTARE' | translate}}</span>
                {{value.tare}}
            </div>
        </div>
        <div class="block read" *ngIf="showInfoContainer">
            <div class="box tare">
                <div class="toplabel">{{'BOOKINGPAGE.SEAL.CAPTION' | translate}}</div><span class="no-data"
                    *ngIf="!value.seal">{{'BOOKINGPAGE.NOSEAL' | translate}}</span>
                {{value.seal}}
            </div>
        </div>
        <div class="block read" *ngIf="showArcDre">
            <div class="box arc">
                <div class="toplabel">{{'BOOKINGPAGE.ARC.CAPTION' | translate}}</div><span class="no-data"
                    *ngIf="!value.ARC">{{'BOOKINGPAGE.NOARC' | translate}}</span>
                {{value.ARC}}
            </div>
        </div>
        <div class="block read" *ngIf="showArcDre">
            <div class="box dre">
                <div class="toplabel">{{'BOOKINGPAGE.DRE.CAPTION' | translate}}</div><span class="no-data"
                    *ngIf="!value.DRE">{{'BOOKINGPAGE.NODRE' | translate}}</span>
                {{value.DRE}}
            </div>
        </div>

        <button class="link" *ngIf="!readonly && (showInfoContainer || showArcDre)"
            (click)="startEditmode()">{{'BOOKINGPAGE.CHANGECONTAINERNUMBER.CAPTION' | translate}}</button>
    </ng-container>

    <div class="zona-edit" *ngIf="editMode">
        <div *ngIf="showInfoContainer">
            <input-component [(ngModel)]="data.containerNumber" class="col-x"
                placeholder="{{ 'BOOKINGPAGE.CONTAINERNUMBER.CAPTION' | translate }}"></input-component>

            <input-component [(ngModel)]="data.tare" class="col-x"
                placeholder="{{ 'BOOKINGPAGE.TARE.CAPTION' | translate }}"></input-component>

            <input-component [(ngModel)]="data.seal" class="col-x"
                placeholder="{{ 'BOOKINGPAGE.SEAL.CAPTION' | translate }}"></input-component>

        </div>

        <div *ngIf="showArcDre">
            <input-component [(ngModel)]="data.ARC" class="col-x"
                placeholder="{{ 'BOOKINGPAGE.ARC.CAPTION' | translate }}"></input-component>

            <input-component [(ngModel)]="data.DRE" class="col-x"
                placeholder="{{ 'BOOKINGPAGE.DRE.CAPTION' | translate }}"></input-component>

        </div>

        <button-link variant="back" class="" (linkClick)="cancel()">{{
            'GENERAL.BACK.CAPTION' | translate}}</button-link>

        <shared-savebutton (save)="save($event)" [readonly]="readonly">
            {{'ROUTEPAGE.ITEM.EDIT.BUTTON.SAVE' | translate}}
        </shared-savebutton>


    </div>

</div>