import { OptionDto } from './OptionDto';

export class OptionsStripDto {
    private map: Map<string, OptionDto>; // la llista de opcions
    private mapAsMenu: Map<string, OptionDto>; // la llista de opcions
    private mapAsBar: Map<string, OptionDto>; // la llista de opcions

    public listGeneral: OptionDto[];
    public listAsMenu: OptionDto[];
    public listAsBar: OptionDto[];

    constructor() {
        this.map = new Map<string, OptionDto>();
    }

    /**
     * Afegeix la opció a l'strip. Si duplicada no fa res, retorna false.
     * @param OptionDto 
     */
    public add(value: OptionDto): boolean {
        if (this.map.has(value.optionId))
            return false;
        this.map.set(value.optionId, value);
        this.setLists();
        value.optionStrip = this;
        if (value.optionSelected)
            OptionsStripDto.select(this, value);
        return true;
    }

    /**
     * Elimina la opció de l'strip. Si no hi ha no fa res, retorna false;
     * @param OptionDto 
     */
    public remove(value: OptionDto): boolean {
        if (!this.map.has(value.optionId))
            return false;
        this.map.delete(value.optionId);
        this.setLists();
        value.optionStrip = null;
        return true;
    }
    public static select(options: OptionsStripDto, optionDto: OptionDto): OptionDto {
        if (options == null || options.map == null || options.map.size == 0)
            return null;
        let result = null;
        options.map.forEach((v, k) => {
            v.optionSelected = optionDto != null && optionDto.optionId == v.optionId;
            result = v.optionSelected ? v : result;
            if (v.optionSelected)
                options.actualSelected = v;
        });
        return result;
    }
    public static selectById(options: OptionsStripDto, optionId: string): OptionDto {
        if (options == null || options.map == null || options.map.size == 0)
            return null;
        let result = null;
        options.map.forEach((v, k) => {
            v.optionSelected = optionId == v.optionId;
            result = v.optionSelected ? v : result;
            if (v.optionSelected)
                options.actualSelected = v;
        });
        return result;
    }

    public static unselect(options: OptionsStripDto) {
        if (options == null || options.map == null || options.map.size == 0)
            return;
        options.map.forEach((v, k) => {
            v.optionSelected = false;
        });
    }

    public static getSelect(options: OptionsStripDto): OptionDto {
        if (options == null || options.map == null || options.map.size == 0)
            return null;
        let result = null;
        options.map.forEach((v, k) => {
            result = v.optionSelected ? v : result;
        });
        return result;
    }
    public static contains(options: OptionsStripDto, optionId: string): boolean {
        if (options == null || options.map == null || options.map.size == 0)
            return false;
        return options.map.has(optionId);
    }
    public static selectFirst(options: OptionsStripDto): OptionDto {
        if (options == null || options.map == null || options.map.size == 0)
            return null;
        let menor: OptionDto = null;
        options.map.forEach((v, k) => {
            if (menor == null || v.optionOrder < menor.optionOrder)
                menor = v;
        });
        return menor;
    }

    static find(options: OptionsStripDto, optionId: string): OptionDto {
        if (options == null || options.map == null || options.map.size == 0 || optionId == null || optionId == undefined || optionId === "")
            return null;
        let result = null;
        options.map.forEach((v, k) => {
            if (optionId == v.optionId)
                result = v;
        });
        return result;
    }


    private setLists() {
        this.mapAsBar = new Map<string, OptionDto>();
        this.mapAsMenu = new Map<string, OptionDto>();

        let i = 0;
        this.listGeneral = new Array(this.map.size);
        this.map.forEach((value: OptionDto, key: string) => {
            if (OptionDto.getOptionShowInBar(value))
                this.mapAsBar.set(key, value);
            if (OptionDto.getOptionShowInMenu(value))
                this.mapAsMenu.set(key, value);

            this.listGeneral[i] = value;
            i++;
        });


        this.listAsBar = new Array(this.mapAsBar.size);
        i = 0;
        this.mapAsBar.forEach((value: OptionDto, key: string) => {
            this.listAsBar[i] = value;
            i++;
        });
        this.listAsMenu = new Array(this.mapAsMenu.size);
        i = 0;
        this.mapAsMenu.forEach((value: OptionDto, key: string) => {
            this.listAsMenu[i] = value;
            i++;
        });
    }

    private actualSelected: OptionDto;
    public getActualSelected() {
        return this.actualSelected;
    }
    /***
 * Per regenerar l'objecte després de serializtzar
 */
    public static buildFromOptionsStripDto(value: OptionsStripDto): OptionsStripDto {
        let result = new OptionsStripDto();
        if (value == null || value.map == null)
            return result;
        Object.keys(value.map).forEach(k => {
            result.add(OptionDto.buildFromOptionDto(value.map[k]));
        });
        return result;
    }

    /***
     * Arregla l'option que s'ha d'assignar.
     * Se suposa que estem a la selectedOptionId i rebem una nova llista d'options.
     * Si laopcio està dins de options es queda igual.
     * Si no està aleshores assigna defaultOptionId
     * Si aquesta està en blanc o fins i tot no està a options aleshores agafa la primera.
     */
    static arrange(options: OptionsStripDto, selectedOptionId: string, defaultOtionId: string): string {
        if (selectedOptionId === "" || selectedOptionId == null)
            selectedOptionId = defaultOtionId;

        let trobat = false;
        let index = 1;
        let opcio = "";
        if (options != null && options.listGeneral != null)
            options.listGeneral.forEach(o => {
                trobat = trobat || (o.optionId === selectedOptionId);
                if (opcio === "" || index > o.optionOrder) {
                    opcio = o.optionId;
                    index = o.optionOrder
                }
                o.optionSelected = o.optionId === selectedOptionId;
            });
        if (!trobat)
            selectedOptionId = opcio;
        return selectedOptionId;
    }

    static sync(options: OptionsStripDto, oldOptions: OptionsStripDto): OptionsStripDto {

        if (oldOptions != null && oldOptions.actualSelected != null) {
            if (options != null && options.listGeneral != null)
                options.listGeneral.forEach(o => {
                    if (o.optionId === oldOptions.actualSelected.optionId)
                        o.optionSelected = true;
                });
        }

        return this.buildFromOptionsStripDto(options);
    }
}