
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PortAddressEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PortAddressEntitySelectorDto {
		let result: PortAddressEntitySelectorDto = new PortAddressEntitySelectorDto();
		result.loadData(EntitysSelectors.PortAddress, "", activeFilterKeys, 0, true);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "";
	}

	public getUrlBack(): string {
		return "/maintenance/portaddress";
	}
}