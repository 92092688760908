
import { Component, Input, OnInit, Inject } from '@angular/core';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { MatDialog } from '@angular/material/dialog';
import { ImageSliderObject } from '@shared/src/dtos/document/ImageSliderObject';
import { SharedImageSliderMobileDialog } from '@shared/src/components/core/images/imageslidermobile/shared.imageslidermobile.dialog';
import { SharedIFrameItemDialog } from '@shared/src/components/core/iframe/shared.iframe.item.dialog';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { CameraService } from '@shared/src/services/camera/camera.service';
import { FileService } from '@shared/src/services/file/file.service';
import { iDocument } from '@shared/src/dtos/document/iDocument';
import { iDocumentDetail } from '@shared/src/dtos/document/iDocumentDetail';
import { AlertService, HomeController } from '@shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { DocumentParentObjectDto } from '@shared/src/dtos/documentaryreview/DocumentParentObjectDto';

@Component({
    selector: 'shared-opendocument-component',
    templateUrl: './shared.opendocument.component.html',
    styleUrls: ['./shared.opendocument.component.scss']
})

export class SharedOpenDocumentComponent implements OnInit {

    public useIonic: boolean = false;

    constructor(public documentController: DocumentController,
        public cameraService: CameraService,
        public dialog: MatDialog, public platform: Platform, protected router: Router, protected fileService: FileService,
        @Inject('HomeController') protected homecontroller: HomeController,
        @Inject('AlertService') private alertService: AlertService,
        private translate: TranslateService) {
        if (homecontroller.useIonic)
            this.useIonic = homecontroller.useIonic();
    }

    ngOnInit(): void {

    }

    /**
     * Si s'informa del parentObject es te en compte al obrir el document/documentdetail, si no, només te en compte el document/documentdetail
     */
    @Input() public parentObject: DocumentParentObjectDto;

    @Input() public document: iDocument;
    @Input() public documentDetail: iDocumentDetail;
    @Input() public withbutton: boolean;
    @Input() public resourcetitle: string;
    @Input() public iconclass: string = "fa fa-eye";
    @Input() public titleclass: string = "title-icon-eye";
    @Input() public buttonclass: string = "action";

    @Input() public file: FileUpDownLoadDto;

    @Input() public readonly: boolean;

    isImageDocument(): boolean {
        if (this.document)
            return FileUpDownLoadDto.isImageType(this.document.fileType);
        if (this.file)
            return FileUpDownLoadDto.isImageType(this.file.fileType);
        return false;
    }
    isImageDocumentDetail(): boolean {
        if (this.documentDetail)
            return FileUpDownLoadDto.isImageType(this.documentDetail.fileType);
        if (this.file)
            return FileUpDownLoadDto.isImageType(this.file.fileType);
        return false;
    }

    isPdfDocumentDetail(): boolean {
        if (this.documentDetail)
            return FileUpDownLoadDto.isPdfType(this.documentDetail.fileType);
        if (this.file)
            return FileUpDownLoadDto.isPdfType(this.file.fileType);
        return false;
    }

    isPdfDocument(): boolean {
        if (this.document)
            return FileUpDownLoadDto.isPdfType(this.document.fileType);
        if (this.file)
            return FileUpDownLoadDto.isPdfType(this.file.fileType);
        return false;
    }


    public openDialog() {

        if(this.readonly)
            return;

        if (this.document && (!this.useIonic || this.isImageDocument())) {
            this.openDialogImageIFrame(this.document);
        } else if (this.documentDetail && (!this.useIonic || this.isImageDocumentDetail())) {
            this.openDialogDetailImageIFrame(this.documentDetail);
        } else if (this.document && this.useIonic && this.isPdfDocument()) {
            this.openPdfDocument(this.document);
        } else if (this.documentDetail && this.useIonic && this.isPdfDocumentDetail()) {
            this.openPdfDocumentDetail(this.documentDetail);
        }

    }

    openDialogImageIFrame(doc: iDocument) {

        if(this.readonly)
            return;

        if (!doc)
            return;
        //Si el document es de tipus imatge ho mostrem amb un slider i si te més de un detall (imatge) es visualitzaran totes en el popup
        if (FileUpDownLoadDto.isImageType(doc.fileType)) {
            let imagesObject = new Array();

            if (this.parentObject == null || !DocumentParentObjectDto.isValid(this.parentObject)){
                doc.documentDetailList.forEach(element => {
                    this.documentController.downloadDocumentDetail(element.documentDetailId).subscribe(data => {
                        let imageobject = ImageSliderObject.buildByFileUpDownLoad(data, element.documentDetailId);
                        if (imageobject != null)
                            imagesObject.push(imageobject);
                    });
                });
            } else {
                doc.documentDetailList.forEach(element => {
                    this.documentController.downloadParentObjectDocumentDetail(this.parentObject, element.documentDetailId).subscribe(data => {
                        let imageobject = ImageSliderObject.buildByFileUpDownLoad(data, element.documentDetailId);
                        if (imageobject != null)
                            imagesObject.push(imageobject);
                    });
                });
            }
            this.getShowDialogSlider(imagesObject);
        } else {
            //Si no es de tipus imatge i hi ha més de un detall, descarraguem directament els detalls, sinó, ho mostrem amb el iframe
            if (doc.documentDetailList.length > 1) {
                doc.documentDetailList.forEach(element => {
                    this.downloadDocumentDetail(element);
                });
            } else {
                doc.documentDetailList.forEach(element => {
                    this.openDialogDetailImageIFrame(element);
                });
            }
        }
    }

    openDialogDetailImageIFrame(doc: iDocumentDetail) {
        if (!doc)
            return;

        if (FileUpDownLoadDto.isImageType(doc.fileType)) {
            if (this.parentObject == null || !DocumentParentObjectDto.isValid(this.parentObject)){
                this.documentController.downloadDocumentDetail(doc.documentDetailId).subscribe(data => {
                    this.showDocumentImage(data);
                });
            }else{
                this.documentController.downloadParentObjectDocumentDetail(this.parentObject, doc.documentDetailId).subscribe(data => {
                    this.showDocumentImage(data);
                });
            }
        } else {
            if (this.parentObject == null || !DocumentParentObjectDto.isValid(this.parentObject)){
                this.documentController.downloadDocumentDetail(doc.documentDetailId).subscribe(data => {
                    this.showDocumentNoImage(data);
                });
            }else{
                this.documentController.downloadParentObjectDocumentDetail(this.parentObject, doc.documentDetailId).subscribe(data => {
                    this.showDocumentNoImage(data);
                });
            }
        }
    }

    openPdfDocument(doc: iDocument) {
        if (!doc)
            return;

        doc.documentDetailList.forEach(element => {
            this.openPdfDocumentDetail(element);
        });
    }

    openPdfDocumentDetail(doc: iDocumentDetail) {
        if (!doc)
            return;

        if (this.parentObject == null || !DocumentParentObjectDto.isValid(this.parentObject)){
            if(doc.fileString){
                this.fileService.saveAndOpenPdf(doc.fileString, doc.fileName);
            } else {
                this.documentController.downloadDocumentDetail(doc.documentDetailId).subscribe(data => {
                    this.fileService.saveAndOpenPdf(data.fileString, data.fileName);
                });
            }
            return;
        }

        this.documentController.downloadParentObjectDocumentDetail(this.parentObject, doc.documentDetailId).subscribe((data: FileUpDownLoadDto) => {
            this.fileService.saveAndOpenPdf(data.fileString, data.fileName);
        });
    }

    showDocumentImage(data: FileUpDownLoadDto) {
        let imagesObject = new Array();
        let imageobject = ImageSliderObject.buildByFileUpDownLoad(data, 1);
        if (imageobject != null)
            imagesObject.push(imageobject);
        this.getShowDialogSlider(imagesObject);
    }
    showDocumentNoImage(data: FileUpDownLoadDto) {
        var byteArray = FileUpDownLoadDto.getUint8ArrayFromString(data.fileString);
        var blob = new Blob([byteArray], { type: FileUpDownLoadDto.getContentType(data.fileType) });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        fetch(link.href)
            .then(res => res.blob())
            .then(a => {
                let url = window.URL.createObjectURL(a);
                this.getShowDialog(url);
            });
    }

    public downloadDocumentDetail(doc: iDocumentDetail) {
        if (doc == null)
            return;

        if (this.parentObject == null || !DocumentParentObjectDto.isValid(this.parentObject)){
            if(doc.fileString){
                FileUpDownloadController.resultDownLoadByParameters(doc.fileName, doc.fileString, doc.fileType);
            } else {
                this.documentController.downloadDocumentDetail(doc.documentDetailId).subscribe(data => {
                    FileUpDownloadController.resultDownLoad(data);
                });
            }
            return;
        }

        this.documentController.downloadParentObjectDocumentDetail(this.parentObject, doc.documentDetailId).subscribe(data => {
            FileUpDownloadController.resultDownLoad(data);
        });
    }

    public getShowDialogSlider(imagesObject: ImageSliderObject[]) {
        let imagesize = { width: '305', height: '300', space: 3 };

        const dialogRef = this.dialog.open(SharedImageSliderMobileDialog, {
            disableClose: false,
            width: '100%',
            height: '100%',
            data: { images: imagesObject, imageSize: imagesize },
            backdropClass: 'backdropBackground',
            // panelClass: 'custom-modalbox'
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    public getShowDialog(url: String) {
        const dialogRef = this.dialog.open(SharedIFrameItemDialog, {
            disableClose: false,
            width: '100%',
            height: '100%',
            data: url,
            backdropClass: 'backdropBackground',
            // panelClass: 'custom-modalbox'
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

}
