import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from "@angular/router"
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { AlertService, HString } from '@shared/src/public-api';
import { OpenUuidExwork } from '@shared/src/controllers/open/OpenUuidExwork';
import { OpenUuidRouteGroupExwork } from '@shared/src/controllers/open/OpenUuidRouteGroupExwork';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';

@Component({
    selector: 'shared-exwork-routegroup',
    templateUrl: './shared.exwork.routegroup.html',
    styleUrls: ['./shared.exwork.routegroup.scss']
})
export class SharedExworkRouteGroup implements OnInit {

    constructor(protected socketController: SocketController, protected activatedRoute: ActivatedRoute, 
        public openRouteController: OpenRouteController, 
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
        this.showResultGrouped = false;
    }

    @Output() goNewUUID = new EventEmitter<string>();
    @Output() refreshExwork = new EventEmitter<boolean>();
    
    @Input() public readonly: boolean;

    public newRouteKey: string;
    
    private _uuid: string;
    @Input()
    set uuid(value: string) {
        if (this._uuid === value)
            return;
        this._uuid = value;
        this.loadData();
    }
    get uuid(): string {
        return this._uuid;
    }

    private _mode: string; //routeGroup,  routeGroupAdd, routeGroupRemove
    @Input()
    set mode(value: string) {
        if (this._mode === value)
            return;
        this._mode = value;
        this.loadData();
    }
    get mode(): string {
        return this._mode;
    }

    private _route: OpenUuidExwork;
    @Input()
    set route(value: OpenUuidExwork) {
        if (this._route === value)
            return;
        this._route = value;
        this.loadData();
    }
    get route(): OpenUuidExwork {
        return this._route;
    }

    public listRouteItemKeys: RouteItemKey[];
    public routeGroup: OpenUuidRouteGroupExwork;
    loadData(){
        if(HString.isNullOrNullString(this.mode) || HString.isNullOrNullString(this.uuid) || this.route == null)
            return;

        this.listRouteItemKeys = new Array();

        this.openRouteController.getExworkRouteGroup(this.uuid).subscribe(data => {
            this.routeGroup = data;
            if(this.routeGroup != null && this.routeGroup.routesGroup != null){
                this.routeGroup.routesGroup.forEach(element => {
                    this.listRouteItemKeys.push(RouteItemKey.build(element, true, false));
                });
            }
            this.setTitle();
        });
        
    }

    public emalsSendNewUuid: string;
    public showResultGrouped: boolean;
    onRouteGroupedActualized(){
        if(this.route == null || this.routeGroup == null || this.listRouteItemKeys == null)
            return;

        if(!this.route.isGrouped){

            let routekeys: string[] = new Array();
            this.listRouteItemKeys.forEach(element => {
                if(element.pendingAdd)
                    routekeys.push(element.routekey)
            });
    
            this.routeGroup.routesGroup = routekeys;


            this.openRouteController.doExworkRouteGroup(this.routeGroup).subscribe(data => {
                if(!HString.isNullOrNullString(data)){
                    
                    this.uuid = data;

                    this.openRouteController.getExwork(this.uuid).subscribe(d => {
                        this.showResultGrouped = true;
                        this.route = d;
                        this.loadPhasesSlots();
                    });
                }
            });
        }else{

            let routekeys: string[] = new Array();
            this.listRouteItemKeys.forEach(element => {
                if(!element.pendingRemove)
                    routekeys.push(element.routekey)
            });
    
            this.routeGroup.routesGroup = routekeys;

            this.openRouteController.actualizedExworkRouteGroup(this.routeGroup).subscribe(data => {
                if(data){
                    this.refreshExwork.next(true);
                }
            });
        }
        
    }

    public _phasesSlots: PhaseCardItemDto[] = new Array();
    public loadPhasesSlots() {
        let phases: PhaseCardItemDto[] = new Array();

        //Només em de mostrar les que requereixen slot
        if (this.route && this.route.phaseList && this.route.phaseList.length > 0) {
            this.route.phaseList.forEach(element => {
                if (element.phaseCard != null && element.phaseCard.requiresSlot) {
                    phases.push(element.phaseCard);
                }
            });
        }
        this._phasesSlots = phases;
    }

    onSendEmailNewUuid(){
        if(!HString.isNullOrNullString(this.emalsSendNewUuid)){
        this.openRouteController.addExworkEmail(this.uuid, this.emalsSendNewUuid).subscribe(data => {
            if (data) {
              this.emalsSendNewUuid = "";
            }
          });
        }
    }


    onGoNewUUID(){
        if(!HString.isNullOrNullString(this.uuid))
            this.goNewUUID.next(this.uuid);
    }

    onRouteKeyAdd(){
        if(HString.isNullOrNullString(this.newRouteKey))
            return;

        let exists: boolean = false;
        this.listRouteItemKeys.forEach(element => {
            if(element.routekey == this.newRouteKey)
                exists = true;
        });

        if(!exists){
            this.listRouteItemKeys.push(RouteItemKey.build(this.newRouteKey, true, false));
            this.newRouteKey = "";
        }
    }

    onRouteKeyRemove(routeitem: RouteItemKey){

        if(routeitem == null || HString.isNullOrNullString(routeitem.routekey))
            return;

        let newList: RouteItemKey[] = new Array();

        this.listRouteItemKeys.forEach(element => {
            if(element.routekey != routeitem.routekey)
                newList.push(element);
        });
        this.listRouteItemKeys = newList;
    }

    public title: string ="";
    setTitle(){

        if(this.routeGroup == null)
            return "";

        if(this.mode == "routeGroupAdd"){
            this.title = "SHARED.EXWORK.ROUTEGROUP.ADD.TITLE";
            return;
        }

        if(this.mode == "routeGroupRemove"){
            this.title = "SHARED.EXWORK.ROUTEGROUP.REMOVE.TITLE";
            return;
        }

        if(this.showResultGrouped){
            this.title ="SHARED.EXWORK.ROUTEGROUP.OK.TITLE";
            return;
        }

        this.title ="SHARED.EXWORK.ROUTEGROUP.TITLE";

    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }


}

export class RouteItemKey {

    public pendingAdd: boolean;
    public pendingRemove: boolean;
  
    public routekey: string;

    public static build(routekey: string, pendingAdd: boolean, pendingRemove: boolean): RouteItemKey{
        let result: RouteItemKey = new RouteItemKey();
        result.routekey = routekey;
        result.pendingAdd = pendingAdd;
        result.pendingRemove = pendingRemove;
        return result;
    }
}