import { Component, Inject, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RouteController } from '@shared/src/controllers/route/route.controller';
import { RouteCardDto } from "@shared/src/public-api";
import { OpenRouteController } from "@shared/src/controllers/open/open.route.controller";
import { BookingController } from "@shared/src/controllers/booking/booking.controller";
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { BookingSlotDto } from '@shared/src/dtos/booking/BookingSlotDto';
import { HLong } from "@shared/src/datatypes/HLong";

@Component({
    selector: 'shared-route-booking',
    templateUrl: './shared.route.booking.component.html',
    styleUrls: ['./shared.route.booking.component.scss']
})
export class SharedRouteBookingComponent implements OnInit {

    public readonly: boolean = false;

    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();

    constructor(public routeController: RouteController,
        public openRouteController: OpenRouteController,
        public bookingController: BookingController,
        @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController) {

        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });
    }

    private _routeId: number;
    @Input()
    public set routeId(value: number) {
        if (this._routeId === value)
            return;

        this._routeId = value;
        this.routeController.getRoute(this._routeId).subscribe(data => {
            this.route = data;
        });

    }
    public get routeId(): number {
        return this._routeId;
    }

    private _route: RouteCardDto;
    @Input()
    public set route(value: RouteCardDto) {
        if (this._route === value)
            return;

        this._route = value;

        this.showBookingForm = false;
        if (this._route != null && this._route.bookingId) {
            this.bookingId = this._route.bookingId;
            this.loadBooking();
        } else {
            this.booking = null;
            this.bookingId = null;
        }

    }
    public get route(): RouteCardDto {
        return this._route;
    }

    public actualizeRoute() {
        if (this.route != null)
            this.routeController.getRoute(this.route.routeId).subscribe(data => {
                this.route = data;
            });
    }

    public onRefreshRoute() {
        this.actualizeRoute();
        this.refreshRoute.next(this.route);
    }

    public booking: BookingDto = new BookingDto();
    public bookingId: number;
    public bookingSlot: BookingSlotDto;
    public bookingSlotId: number;
    public showBookingForm: boolean = false;
    public showBookingInfo: boolean = true;

    loadBooking() {
        if (!HLong.isNullOrNullLong(this.bookingId)) {
            this.bookingController.getBooking(this.bookingId).subscribe(booking => {
                if (booking && booking.bookingId) {
                    this.booking = booking;
                    this.showBookingInfo = true;
                }
            });
        }
    }

    setShowBookingInfo(val: boolean) {
        if (val) {
            this.showBookingForm = false;
            if (this.booking == null || this.booking.bookingId != this.bookingId) {
                this.loadBooking();
            } else {
                this.showBookingInfo = true;
            }
        } else {
            this.showBookingInfo = false;
        }
    }

    setShowBookingForm(val: boolean) {
        if (val) {
            this.booking = new BookingDto();
            this.showBookingForm = true;
        } else {
            this.showBookingForm = false;
            this.booking = null;
        }
    }

    bookingChanged() {
        if (!this.bookingId) {
            this.bookingController.releaseBooking(this._route.bookingId, this._route.routeId).subscribe(e => {
                if (e) {
                    this.booking = null;
                    this.bookingId = null;
                    this._route.bookingId = null;
                    this._route.bookingSlotId = null;
                    this.showBookingInfo = false;
                }
                this.onRefreshRoute();
            })
        } else {
            this.bookingController.reserveBooking(this.bookingId, this._route.routeId).subscribe(e => {
                if (e) {
                    this.bookingController.getBooking(this.bookingId).subscribe(booking => {
                        this.booking = booking;
                        this.showBookingForm = false;
                    });
                    this.onRefreshRoute();
                }
            })
        }
    }

    getBookingSlot() {
        if (this._route == null)
            return;
        this.bookingController.getBookingSlot(this._route.routeId).subscribe(bookingSlot => {
            if (bookingSlot && bookingSlot.bookingSlotId) {
                this.bookingSlot = bookingSlot;
                this.bookingSlotId = bookingSlot.bookingSlotId;
                this.showBookingInfo = true;
            } else {
                this.bookingSlot = null;
                this.bookingSlotId = null;
                this.showBookingInfo = false;
            }
        })
    }

    reserveBooking(item: BookingDto) {
        if (item)
            this.bookingController.reserveBooking(item.bookingId, this.route.routeId).subscribe(e => {
                if (e)
                    this.bookingController.getBooking(item.bookingId).subscribe(booking => {
                        this.booking = booking;
                        this.bookingId = booking.bookingId;
                        this.showBookingInfo = true;
                        this.showBookingForm = false;
                    })
            })
    }

    ngOnInit(): void {
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

}
