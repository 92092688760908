import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { SharedIFrameItemDialog } from '@shared/src/components/core/iframe/shared.iframe.item.dialog';
import { SharedImageSliderDialog } from '@shared/src/components/core/images/imageslider/shared.imageslider.dialog';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '@shared/src/datatypes/HString';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { ImageSliderObject } from '@shared/src/dtos/document/ImageSliderObject';
import { TrackingShipmentDto } from '@shared/src/dtos/tracking/TrackingShipmentDto';
import { TrackingStatus } from '@shared/src/enums/TrackingStatus';
import { AlertService, HDateHour } from '@shared/src/public-api';
import { MapMarcadorDto } from '@shared/src/services/map/map.service';
import { DesktopTrackController } from '../../../controllers/track/desktop-track.controller';

@Component({
    selector: 'shared-track-page',
    templateUrl: './track-page.component.html',
    styleUrls: ['./track-page.component.scss']
})
export class TrackPageComponent implements OnInit {

    public logoImage: FileUpDownLoadDto;

    constructor(protected socketController: SocketController, protected desktopTrackController: DesktopTrackController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
        this.getDadesShipment();
    }
    public state: string;
    public uuid: string;
    public data: TrackingShipmentDto;
    public loaded: boolean = false;
    public marcadors: Array<MapMarcadorDto>;

    private listening: boolean = false;
    private _shipmentId: number;
    @Input() public set shipmentId(value: number) {
        if (value != this._shipmentId) {
            this._shipmentId = value;
            this.getDadesShipment();
        }
    }
    public get shipmentId(): number {
        return this._shipmentId;
    }

    public getDadesShipment() {
        if (!HLong.isNullOrNullLong(this._shipmentId)) {
            this.desktopTrackController.getTrackShipmentById(this._shipmentId).subscribe(data => {
                this.buildForShipment(data)
            })
        } else {
            this.activatedRoute.params.subscribe(a => {
                this.uuid = a.trackId
                if (!HString.isNullOrNullString(this.uuid)) {
                    this.desktopTrackController.getTrackShipment(this.uuid).subscribe(data => {
                        if (data)
                            this._shipmentId = data.shipmentId;
                        this.buildForShipment(data)
                    })
                } else {
                    this.marcadors = [];
                    this.loaded = false;
                    this.data = null;
                }
            });
        }
    }

    public buildForShipment(data: TrackingShipmentDto) {
        this.data = data;
        this.loaded = true;
        this.marcadors = [];

        if (data == null)
            return;

        if (data.telemetry != null)
            this.marcadors = [MapMarcadorDto.builderByGlobalTelemetry(data.telemetry)];
        this.getcolorState();

        if (!this.listening || this._shipmentId != this.data.shipmentId) {
            this.listening = true;
            this.socketController.interested("Shipment:" + this.data.shipmentId);
            this.socketController.notificationInterestedOn$.subscribe((topic: string) => {
                if ("Shipment:" + this.data.shipmentId == topic) {
                    this.getDadesShipment();
                }
            })
        }

        this.systemController.getLogo(this.data.tenantId).subscribe((data: FileUpDownLoadDto) => {
            if (FileUpDownLoadDto.isImageType(data.fileType))
                this.logoImage = data;
        });

    }
    public openDialogContacte() {
        return;
    }
    public openDialogEntrega() {
        return;
    }

    public openDocument() {
        if (this.data == null || this.data.document == null)
            return;

        //Si el document es de tipus imatge ho mostrem amb un slider i si te més de un detall (imatge) es visualitzaran totes en el popup
        if (FileUpDownLoadDto.isImageType(this.data.document.fileType)) {
            let imagesObject = new Array();
            this.data.document.documentDetailList.forEach(element => {
                this.openRouteController.downloadShipmentDocumentDetail(this.data.shipmentId, element.documentDetailId).subscribe(data => {
                    let imageobject = ImageSliderObject.buildByFileUpDownLoad(data, element.documentDetailId);
                    if (imageobject != null)
                        imagesObject.push(imageobject);
                });
            });
            this.getShowDialogSlider(imagesObject);
        } else {
            //Si no es de tipus imatge i hi ha més de un detall, descarraguem directament els detalls, sinó, ho mostrem amb el iframe
            if (this.data.document.documentDetailList.length > 1) {
                this.data.document.documentDetailList.forEach(element => {
                    this.openRouteController.downloadShipmentDocumentDetail(this.data.shipmentId, element.documentDetailId).subscribe(data => {
                        FileUpDownloadController.resultDownLoad(data);
                    });
                });
            } else {
                this.data.document.documentDetailList.forEach(element => {
                    this.openRouteController.downloadShipmentDocumentDetail(this.data.shipmentId, element.documentDetailId).subscribe(data => {
                        var byteArray = FileUpDownLoadDto.getUint8ArrayFromString(data.fileString);
                        var blob = new Blob([byteArray], { type: FileUpDownLoadDto.getContentType(data.fileType) });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        fetch(link.href)
                            .then(res => res.blob())
                            .then(a => {
                                let url = window.URL.createObjectURL(a);
                                this.getShowDialog(url);
                            });
                    });
                });
            }
        }
    }

    public downloadShipmentReport() {
        if (this.shipmentId)
            this.shipmentController.printpdf(this.shipmentId, false).subscribe(data => {
                FileUpDownloadController.resultDownLoad(data);
            });
    }

    public getShowDialogSlider(imagesObject: ImageSliderObject[]) {
        let imagesize = { width: '305', height: '300', space: 3 };

        const dialogRef = this.dialog.open(SharedImageSliderDialog, {
            disableClose: false,
            width: '100%',
            height: '100%',
            data: { images: imagesObject, imageSize: imagesize },
            backdropClass: 'backdropBackground',
            panelClass: 'custom-modalbox'
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    public getShowDialog(url: String) {
        const dialogRef = this.dialog.open(SharedIFrameItemDialog, {
            disableClose: false,
            width: '100%',
            height: '100%',
            data: url,
            backdropClass: 'backdropBackground',
            panelClass: 'custom-modalbox'
        });
        dialogRef.afterClosed().subscribe(result => {

        });
    }

    public showActions(): boolean {
        let show: boolean = false;

        if (this.data.showContact)
            show = true;
        if (this.data.showChangeDelivery)
            show = true;
        if (this.data.showDocument)
            show = true;
        if (this.data.showShipmentReport)
            show = true;

        return show;
    }

    public isNullOrNulldate(date: HDateHour): boolean {
        if (HDateHour.isNullOrNullValue(date))
            return true;

        return false;
    }

    public percentatgeTransito: number = 0;
    public percentatgeTotal: number = 0;

    public getcolorState() {
        if (this.state == TrackingStatus.loaded) {
            this.percentatgeTotal = 20
        }
        if (this.state == TrackingStatus.cargado) {
            this.percentatgeTotal = 40
        }
        if (this.percentatgeTransito == 100) {
            this.percentatgeTotal = 80
        }
        if (this.state == TrackingStatus.entregado) {
            this.percentatgeTotal = 100
        }
    }

    public getTransitColor(): string {
        //this.percentatgeTransito = this.phasesBetween.filter(i => i.generalStatus == "done").length * 100 / this.phasesBetween.length;
        return `rgb(80 126 35 / ${this.percentatgeTransito}%)`;
    }

    call(phone: string) {
        /* window.open(phone); */
        window.location.href = 'tel:' + phone;
    }
    copy(text: string) {
        this.alertService.copyToClipboard(text).subscribe();
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
