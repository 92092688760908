import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HourIntervalsValidator } from '../../form/validations/HourIntervalsValidator';
import { HHourIntervals } from '@shared/src/datatypes/HHourIntervals';
import { HomeController } from '@shared/src/controllers/home/home.controller';


@Component({
  selector: 'hourintervals-picker',
  templateUrl: './hourintervals-picker.component.html',
  styleUrls: ['./hourintervals-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: HourIntervalsPickerComponent, multi: true }]
})

export class HourIntervalsPickerComponent extends ValueAccessorValidatedBase<string, HHourIntervals> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormHourIntervals = this.fb.group({
      hourintervalsvalidator: ['', validators, asyncvalidators]
    });
  }

  @Output() valueScreenChange: EventEmitter<string> = new EventEmitter();
  isValid(value: string): boolean {
    this.valueScreenChange.next(value);
    return HourIntervalsValidator.isValid(value);
  }
  showClearButton(): boolean {
    if (!this.value)
      return false;

    if (this.value == "00:00-00:00")
      return false;

    if(this.readonly)
      return false;

    return true;
  }


  public rFormHourIntervals: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if(this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }


  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HHourIntervals> = new EventEmitter();

  public identifier = 'hourintervals-picker-' + identifier++;

  externalValue(value: string): HHourIntervals {
    if (!value)
      return HHourIntervals.empty();
    let hintervals: HHourIntervals = HHourIntervals.buildstr(value);
    return hintervals;
  }
  internalValue(value: HHourIntervals): string {

    let t: HHourIntervals = HHourIntervals.buildByDto(value);
    return HHourIntervals.toHourIntervalsString(t);
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }
  
  keyEnter(event){
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;