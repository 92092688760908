import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { FormGroup } from '@angular/forms';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { GeneralStatus, HDate, HDateHour, HHourIntervals, HTriState } from '@shared/src/public-api';
import { RouteShipmentConditionTypes } from '@shared/src/enums/RouteShipmentConditionTypes';


@Component({
    selector: 'shared-shipment-item-edit-maintenance',
    templateUrl: './shared.shipment.item.edit.maintenance.component.html',
    styleUrls: ['./shared.shipment.item.edit.maintenance.component.scss']
})
export class SharedShipmentItemEditMaintenanceComponent implements OnInit {

    public readonly: boolean = false;
    public rFormShipment: FormGroup;

    constructor(public openRouteController: OpenRouteController, @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController, private shipmentController: ShipmentController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

        this.subscribeValuesChanged();
        this.subscribeEmailsChanged();
        this.securityController.isOwner()
    }

    public canAssignForms: boolean = false;
    public today: HDateHour = HDateHour.todayFirstMoment();

    @Input()
    public routeId: number;

    @Input()
    public phase: PhaseCardDto;

    private _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment == value)
            return;

        this._shipment = value;
        this.canAssignForms = value.isOwnerShipment;

        this.resetFileds();

    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }

    resetFileds() {

        if (this._shipment != null) {
            this._loadFormId = this._shipment.loadFormId;
            this._unLoadFormId = this._shipment.unLoadFormId;
            this._partnerClientId = this._shipment.partnerClientId;
            this._partnerProviderId = this._shipment.partnerProviderId;
            this._minDateTime = this.shipment.minDateTime;
            this._originDateTimeStart = this.shipment.originDateTimeStart;
            if (HDateHour.isNullOrNullValue(this.shipment.originDateTimeEnds) && !HDateHour.isNullOrNullValue(this.shipment.originDateTimeStart))
                this._originDateTimeEnds = this.shipment.originDateTimeStart;
            else
                this._originDateTimeEnds = this.shipment.originDateTimeEnds;
            this._originTimetable = HHourIntervals.buildByDto(this._shipment.originTimetable);

            if (HDateHour.isNullOrNullValue(this.shipment.destinationDateTimeStart) && !HDateHour.isNullOrNullValue(this.shipment.destinationDateTimeEnds))
                this._destinationDateTimeStart = this.shipment.destinationDateTimeEnds;
            else
                this._destinationDateTimeStart = this.shipment.destinationDateTimeStart;
            this._destinationDateTimeEnds = this.shipment.destinationDateTimeEnds;
            this._destinationTimetable = HHourIntervals.buildByDto(this._shipment.destinationTimetable);
            this._thermostatRunMode = this._shipment.thermostatRunMode;
            this._minTemperature = this._shipment.minTemperature;
            this._maxTemperature = this._shipment.maxTemperature;
            this._isActiveConditionTemp = this._shipment.isActiveConditionTemp;
            this._minHR = this._shipment.minHR;
            this._maxHR = this._shipment.maxHR;
            this._isActiveConditionHR = this._shipment.isActiveConditionHR;
            this._emailsPromised = this._shipment.emailsPromised;
            this._emailsInProgress = this._shipment.emailsInProgress;
            this._emailsDone = this._shipment.emailsDone;
            this._emailsProviderPromised = this._shipment.emailsProviderPromised;
            this._emailsProviderInProgress = this._shipment.emailsProviderInProgress;
            this._emailsProviderDone = this._shipment.emailsProviderDone;
        }
    }

    public filterkeysLoadForm = new Array();
    public filterkeysUnLoadForm = new Array();
    public filterkeysClient = new Array();
    public filterkeysProvider = new Array();
    ngOnInit(): void {

        this.filterkeysLoadForm.push('FORM.SHIPMENT.LOAD.FILTER');
        this.filterkeysUnLoadForm.push('FORM.SHIPMENT.UNLOAD.FILTER');

        this.filterkeysClient.push('PARTNER.FINALCUSTOMER.LINKPERMISSION.FILTER');
        this.filterkeysProvider.push('PARTNER.PROVIDER.LINKPERMISSION.FILTER');
    }

    @Output() public valueChange: EventEmitter<any> = new EventEmitter<any>();

    private _loadFormId = null;
    private _unLoadFormId = null;

    public set loadFormId(loadFormId: number) {
        if (loadFormId == this._loadFormId)
            return;

        this._loadFormId = loadFormId;
        if (this._loadFormId == null)
            this._loadFormId = 0;

        this.openRouteController.shipmentSetLoadForm(this._shipment.shipmentId, this._loadFormId).subscribe(data => {
            // this.valueChange.next(data);
        });
    }

    public get loadFormId(): number {
        return this._loadFormId;
    }

    public set unLoadFormId(unLoadFormId: number) {
        if (unLoadFormId == this._unLoadFormId)
            return;

        this._unLoadFormId = unLoadFormId;
        if (this._unLoadFormId == null)
            this._unLoadFormId = 0;

        this.openRouteController.shipmentSetUnLoadForm(this._shipment.shipmentId, this._unLoadFormId).subscribe(data => {
            // this.valueChange.next(data);
        });
    }

    public get unLoadFormId(): number {
        return this._unLoadFormId;
    }

    private _partnerClientId: number;
    public set partnerClientId(partnerClientId: number) {
        if (partnerClientId == this._partnerClientId)
            return;

        this._partnerClientId = partnerClientId;
        if (this._partnerClientId == null)
            this._partnerClientId = 0;

        this.openRouteController.shipmentSetPartnerClientId(this._shipment.shipmentId, this._partnerClientId).subscribe(data => {
            // this.valueChange.next(data);
        });
    }

    public get partnerClientId(): number {
        return this._partnerClientId;
    }

    private _partnerProviderId: number;
    public set partnerProviderId(partnerProviderId: number) {
        if (partnerProviderId == this._partnerProviderId)
            return;

        this._partnerProviderId = partnerProviderId;
        if (this._partnerProviderId == null)
            this._partnerProviderId = 0;

        this.openRouteController.shipmentSetPartnerProviderId(this._shipment.shipmentId, this._partnerProviderId).subscribe(data => {
            // this.valueChange.next(data);
        });
    }

    public get partnerProviderId(): number {
        return this._partnerProviderId;
    }

    public shipmentEmailsPromisedChanged = new Subject<any>();
    public shipmentEmailsInProgressChanged = new Subject<any>();
    public shipmentEmailsDoneChanged = new Subject<any>();
    public shipmentEmailsProviderPromisedChanged = new Subject<any>();
    public shipmentEmailsProviderInProgressChanged = new Subject<any>();
    public shipmentEmailsProviderDoneChanged = new Subject<any>();
    public subscribeEmailsChanged(){
        this.shipmentEmailsPromisedChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsByGeneralStatus(this._shipment.shipmentId, GeneralStatus.promised.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsPromised = value;
                }
            });
        });
        this.shipmentEmailsInProgressChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsByGeneralStatus(this._shipment.shipmentId, GeneralStatus.inprogress.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsInProgress = value;
                }
            });
        });
        this.shipmentEmailsDoneChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsByGeneralStatus(this._shipment.shipmentId, GeneralStatus.done.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsDone = value;
                }
            });
        });

        this.shipmentEmailsProviderPromisedChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsProviderByGeneralStatus(this._shipment.shipmentId, GeneralStatus.promised.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsProviderPromised = value;
                }
            });
        });
        this.shipmentEmailsProviderInProgressChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsProviderByGeneralStatus(this._shipment.shipmentId, GeneralStatus.inprogress.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsProviderInProgress = value;
                }
            });
        });
        this.shipmentEmailsProviderDoneChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
            this.openRouteController.shipmentSetEmailsProviderByGeneralStatus(this._shipment.shipmentId, GeneralStatus.done.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.emailsProviderDone = value;
                }
            });
        });
    }
    // Emails
    private _emailsPromised: string;
    public set emailsPromised(value: string) {
        if (value == this._emailsPromised)
            return;

        this._emailsPromised = value;
        this.shipmentEmailsPromisedChanged.next(value);
    }

    public get emailsPromised(): string {
        return this._emailsPromised;
    }

    private _emailsInProgress: string;
    public set emailsInProgress(value: string) {
        if (value == this._emailsInProgress)
            return;

        this._emailsInProgress = value;
        this.shipmentEmailsInProgressChanged.next(value);
    }

    public get emailsInProgress(): string {
        return this._emailsInProgress;
    }

    private _emailsDone: string;
    public set emailsDone(value: string) {
        if (value == this._emailsDone)
            return;

        this._emailsDone = value;
        this.shipmentEmailsDoneChanged.next(value);
    }

    public get emailsDone(): string {
        return this._emailsDone;
    }

    private _emailsProviderPromised: string;
    public set emailsProviderPromised(value: string) {
        if (value == this._emailsProviderPromised)
            return;

        this._emailsProviderPromised = value;
        this.shipmentEmailsProviderPromisedChanged.next(value);
    }

    public get emailsProviderPromised(): string {
        return this._emailsProviderPromised;
    }

    private _emailsProviderInProgress: string;
    public set emailsProviderInProgress(value: string) {
        if (value == this._emailsProviderInProgress)
            return;

        this._emailsProviderInProgress = value;
        this.shipmentEmailsProviderInProgressChanged.next(value);
    }

    public get emailsProviderInProgress(): string {
        return this._emailsProviderInProgress;
    }

    private _emailsProviderDone: string;
    public set emailsProviderDone(value: string) {
        if (value == this._emailsProviderDone)
            return;

        this._emailsProviderDone = value;
        this.shipmentEmailsProviderDoneChanged.next(value);
    }

    public get emailsProviderDone(): string {
        return this._emailsProviderDone;
    }

    ////CONDICIONS
    public shipmentConditionDateHourChanged = new Subject<DataValueChanged>();
    public shipmentConditionHourIntervalsChanged = new Subject<DataValueChanged>();
    public shipmentConditionDoubleChanged = new Subject<DataValueChanged>();
    public subscribeValuesChanged(){
        this.shipmentConditionDateHourChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(data => {
            switch(data.condition){
                case RouteShipmentConditionTypes.MinDateTime:
                    this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, RouteShipmentConditionTypes.MinDateTime.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.minDateTime = this._minDateTime;
                            this.valueChange.next(result);
                        }else
                            this._minDateTime = this._shipment.minDateTime;
                    });
                    break;
                case RouteShipmentConditionTypes.OriginMinDateTime:
                    this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, RouteShipmentConditionTypes.OriginMinDateTime.toString(), data.value).subscribe(result => {
                        if(result){
                            
                            let distance: number;
                            if (this._shipment.originDateTimeStart) {
                                var d1 = HDateHour.getDate(this._shipment.originDateTimeStart);
                                var d2 = HDateHour.getDate(this._originDateTimeStart);
                                distance = HDate.distance(d1, d2);
                            }

                            this._shipment.originDateTimeStart = this._originDateTimeStart;

                            if (distance && !HDateHour.isNullOrNullValue(this._originDateTimeEnds)) {
                                var date = HDateHour.getDate(this._originDateTimeEnds);
                                var newDate: HDate;
                                newDate = this.addDays(date, distance);
                                this.originDateTimeEnds = HDateHour.buildByHDateByHHour(newDate, HDateHour.getHour(this._originDateTimeEnds), this._originDateTimeEnds.Timezone, this._originDateTimeEnds.TimezoneObserver);
                            }else
                                this.valueChange.next(result);
                        }else
                            this._originDateTimeStart = this._shipment.originDateTimeStart;
                    });
                    break;
                case RouteShipmentConditionTypes.OriginMaxDateTime:
                    this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, RouteShipmentConditionTypes.OriginMaxDateTime.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.originDateTimeEnds = this._originDateTimeEnds;
                            this.valueChange.next(result);
                        }else
                            this._originDateTimeEnds = this._shipment.originDateTimeEnds;
                    });
                    break;
                case RouteShipmentConditionTypes.DestinationMinDateTime:
                    this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, RouteShipmentConditionTypes.DestinationMinDateTime.toString(), data.value).subscribe(result => {
                        if(result){
                            
                            let distance: number;
                            if (this._shipment.destinationDateTimeStart) {
                                var d1 = HDateHour.getDate(this._shipment.destinationDateTimeStart);
                                var d2 = HDateHour.getDate(this._destinationDateTimeStart);
                                distance = HDate.distance(d1, d2);
                            }

                            this._shipment.destinationDateTimeStart = this._destinationDateTimeStart;

                            if (distance && !HDateHour.isNullOrNullValue(this._destinationDateTimeEnds)) {
                                var date = HDateHour.getDate(this._destinationDateTimeEnds);
                                var newDate: HDate;
                                newDate = this.addDays(date, distance);
                                this.destinationDateTimeEnds = HDateHour.buildByHDateByHHour(newDate, HDateHour.getHour(this._destinationDateTimeEnds), this._destinationDateTimeEnds.Timezone, this._destinationDateTimeEnds.TimezoneObserver);
                            }else
                                this.valueChange.next(result);

                        }else
                            this._destinationDateTimeStart = this._shipment.destinationDateTimeStart;
                    });
                    break;
                    case RouteShipmentConditionTypes.DestinationMaxDateTime:
                        this.openRouteController.shipmentSetConditionDateHour(this._shipment.shipmentId, RouteShipmentConditionTypes.DestinationMaxDateTime.toString(), data.value).subscribe(result => {
                            if(result){
                                this._shipment.destinationDateTimeEnds = this._destinationDateTimeEnds;
                                this.valueChange.next(result);
                            }else
                                this._destinationDateTimeEnds = this._shipment.destinationDateTimeEnds;
                        });
                    break;
                }
        });
        this.shipmentConditionHourIntervalsChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(data => {
            switch(data.condition){
                case RouteShipmentConditionTypes.OriginArrivalTimesIntervals:
                    this.openRouteController.shipmentSetConditionHourIntervals(this._shipment.shipmentId, RouteShipmentConditionTypes.OriginArrivalTimesIntervals.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.originTimetable = this._originTimetable;
                            this.valueChange.next(result);
                        }else
                            this._originTimetable = this._shipment.originTimetable;
                    });
                break;
                case RouteShipmentConditionTypes.DestinationArrivalTimesIntervals:
                    this.openRouteController.shipmentSetConditionHourIntervals(this._shipment.shipmentId, RouteShipmentConditionTypes.DestinationArrivalTimesIntervals.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.destinationTimetable = this._destinationTimetable;
                            this.valueChange.next(result);
                        }else
                            this._destinationTimetable = this._shipment.destinationTimetable;
                    });
                break;
            }
        });
        this.shipmentConditionDoubleChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(data => {
            switch(data.condition){
                case RouteShipmentConditionTypes.MinTemperature:
                    this.openRouteController.shipmentSetConditionDouble(this._shipment.shipmentId, RouteShipmentConditionTypes.MinTemperature.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.minTemperature = this._minTemperature;
                        }else
                            this._minTemperature = this._shipment.minTemperature;
                    });
                break;
                case RouteShipmentConditionTypes.MaxTemperature:
                    this.openRouteController.shipmentSetConditionDouble(this._shipment.shipmentId, RouteShipmentConditionTypes.MaxTemperature.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.maxTemperature = this._maxTemperature;
                        }else
                            this._maxTemperature = this._shipment.maxTemperature;
                    });
                break;
                case RouteShipmentConditionTypes.MinHR:
                    this.openRouteController.shipmentSetConditionDouble(this._shipment.shipmentId, RouteShipmentConditionTypes.MinHR.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.minHR = this._minHR;
                        }else
                            this._minHR = this._shipment.minHR;
                    });
                break;
                case RouteShipmentConditionTypes.MaxHR:
                    this.openRouteController.shipmentSetConditionDouble(this._shipment.shipmentId, RouteShipmentConditionTypes.MaxHR.toString(), data.value).subscribe(result => {
                        if(result){
                            this._shipment.maxHR = this._maxHR;
                        }else
                            this._maxHR = this._shipment.maxHR;
                    });
                break;
            }
        });
        
    }
   
   
    private _minDateTime: HDateHour;
    public set minDateTime(value: HDateHour) {
        if (HDateHour.equals(value, this._minDateTime))
            return;

        this._minDateTime = value;
        this.shipmentConditionDateHourChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.MinDateTime, value));    
    }

    public get minDateTime(): HDateHour {
        return this._minDateTime;
    }
    // Origin
   
    private _originDateTimeStart: HDateHour;
    public set originDateTimeStart(value: HDateHour) {
        if (HDateHour.equals(value, this._originDateTimeStart))
            return;

        this._originDateTimeStart = value;
        this.shipmentConditionDateHourChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.OriginMinDateTime, value));    
    }

    public get originDateTimeStart(): HDateHour {
        return this._originDateTimeStart;
    }

    private _originDateTimeEnds: HDateHour;
    public set originDateTimeEnds(value: HDateHour) {
        if (HDateHour.equals(value, this._originDateTimeEnds))
            return;

        this._originDateTimeEnds = value;
        this.shipmentConditionDateHourChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.OriginMaxDateTime, value)); 
    }

    public get originDateTimeEnds(): HDateHour {
        return this._originDateTimeEnds;
    }

    private _originTimetable: HHourIntervals;
    public set originTimetable(value: HHourIntervals) {

        if (HHourIntervals.isNull(value) || HHourIntervals.isEqualsThan(value, this._originTimetable))
            return;

        if (!HHourIntervals.isValidFormat(HHourIntervals.toHourIntervalsString(value)))
            return;

        this._originTimetable = value;
        this.shipmentConditionHourIntervalsChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.OriginArrivalTimesIntervals, value));  
    }

    public get originTimetable(): HHourIntervals {
        return this._originTimetable;
    }

    // Destination
    private _destinationDateTimeStart: HDateHour;
    public set destinationDateTimeStart(value: HDateHour) {
        if (HDateHour.equals(value, this._destinationDateTimeStart))
            return;

        this._destinationDateTimeStart = value;
        this.shipmentConditionDateHourChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.DestinationMinDateTime, value)); 
    }

    public get destinationDateTimeStart(): HDateHour {
        return this._destinationDateTimeStart;
    }

    private _destinationDateTimeEnds: HDateHour;
    public set destinationDateTimeEnds(value: HDateHour) {
        if (HDateHour.equals(value, this._destinationDateTimeEnds))
            return;

        this._destinationDateTimeEnds = value;
        this.shipmentConditionDateHourChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.DestinationMaxDateTime, value)); 
    }

    public get destinationDateTimeEnds(): HDateHour {
        return this._destinationDateTimeEnds;
    }

    private _destinationTimetable: HHourIntervals;
    public set destinationTimetable(value: HHourIntervals) {
        if (HHourIntervals.isNull(value) || HHourIntervals.isEqualsThan(value, this._destinationTimetable))
            return;

        if (!HHourIntervals.isValidFormat(HHourIntervals.toHourIntervalsString(value)))
            return;

        this._destinationTimetable = value;
        this.shipmentConditionHourIntervalsChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.DestinationArrivalTimesIntervals, value)); 
    }

    public get destinationTimetable(): HHourIntervals {
        return this._destinationTimetable;
    }

    // Thermostat mode
    private _thermostatRunMode: HTriState;
    public set thermostatRunMode(value: HTriState) {
        if (!this.thermostatRunMode || value.state != this._thermostatRunMode.state) {
            this._thermostatRunMode = value;
            this.openRouteController.shipmentSetConditionTriState(this._shipment.shipmentId, RouteShipmentConditionTypes.ThermostatRunMode.toString(), value).subscribe(data => {
                if(data){
                    this._shipment.thermostatRunMode = value;
                    this.valueChange.next(data);
                }else
                    this._thermostatRunMode = this._shipment.thermostatRunMode;
            });
        }
    }

    public get thermostatRunMode(): HTriState {
        return this._thermostatRunMode;
    }

    // Temperature
    private _minTemperature: number;
    public set minTemperature(value: number) {
        if (value == this._minTemperature)
            return;

        this._minTemperature = value;
        this.shipmentConditionDoubleChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.MinTemperature, value)); 
    }

    public get minTemperature(): number {
        return this._minTemperature;
    }

    private _maxTemperature: number;
    public set maxTemperature(value: number) {
        if (value == this._maxTemperature)
            return;

        this._maxTemperature = value;
        this.shipmentConditionDoubleChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.MaxTemperature, value)); 
    }

    public get maxTemperature(): number {
        return this._maxTemperature;
    }

    private _isActiveConditionTemp: boolean;
    public set isActiveConditionTemp(value: boolean) {
        if (value == this._isActiveConditionTemp)
            return;

        this._isActiveConditionTemp = value;
        this.openRouteController.shipmentSetIsActiveConditionTemp(this._shipment.shipmentId, value).subscribe(data => {
            if(data){
                this._shipment.isActiveConditionTemp = value;
            }else
                this._isActiveConditionTemp = this._shipment.isActiveConditionTemp;
        });
    }

    public get isActiveConditionTemp(): boolean {
        return this._isActiveConditionTemp;
    }

    // Humidity
    private _minHR: number;
    public set minHR(value: number) {
        if (value == this._minHR)
            return;

        this._minHR = value;
        this.shipmentConditionDoubleChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.MinHR, value)); 
    }

    public get minHR(): number {
        return this._minHR;
    }

    private _maxHR: number;
    public set maxHR(value: number) {
        if (value == this._maxHR)
            return;

        this._maxHR = value;
        this.shipmentConditionDoubleChanged.next(DataValueChanged.build(RouteShipmentConditionTypes.MaxHR, value)); 
    }

    public get maxHR(): number {
        return this._maxHR;
    }

    private _isActiveConditionHR: boolean;
    public set isActiveConditionHR(value: boolean) {
        if (value == this._isActiveConditionHR)
            return;

        this._isActiveConditionHR = value;
        this.openRouteController.shipmentSetIsActiveConditionHR(this._shipment.shipmentId, value).subscribe(data => {
            if(data){
                this._shipment.isActiveConditionHR = value;
            }else
                this._isActiveConditionHR = this._shipment.isActiveConditionHR;
        });
    }

    public get isActiveConditionHR(): boolean {
        return this._isActiveConditionHR;
    }

    public addDays(date: HDate, days: number): HDate {
        let resultdate: Date = HDate.getDate(date);
        resultdate.setDate(resultdate.getDate() + parseInt(days + ""));
        let result = HDate.build(resultdate);
        return result;
    }

    public getMin(value: HDateHour): HDateHour {
        if (!HDateHour.isNullOrNullValue(value))
            return value;
        else if (!HDateHour.isNullOrNullValue(this.originDateTimeStart))
            return this.originDateTimeStart;
        else
            return this.today;
    }
}

export class DataValueChanged {
    public condition: RouteShipmentConditionTypes;
    public value: any;
    public static build(condition: RouteShipmentConditionTypes, value: any): DataValueChanged{
         let r = new DataValueChanged();
         r.condition = condition;
         r.value = value;
         return r;
    }
 }