<div *ngIf="data">
    <shared-route-item [route]="data.routeCard"></shared-route-item>
    <div class="map-title">Ruta hasta el siguiente destino</div>
    <div class="map">
        <shared-map [rutes]="ruta" [latitude]="centerLatitude" [longitude]="centerLatitude" height="400"></shared-map>
    </div>
    <div class="phase-list" *ngIf="getPendingPhases().length > 0">
        <span class="phases-title">{{ 'PHASES.PENDINGARRIVAL' | translate }}</span>
        <shared-phase-item *ngFor="let item of getPendingPhases() | sortBy:'executionOrder'" class="phaseItem" [phase]="item">
            <div class="eta">
                <span *ngIf="item.eta != null">
                    {{ "PHASES.ETA" | translate }}: {{ item.eta | datehour }}
                </span>
                <span *ngIf="item.eta == null">
                    {{ "PHASES.CANNOTCALCULATEETA" | translate }}
                </span>
            </div>
        </shared-phase-item>
    </div>
    <div class="phase-list" *ngIf="getCompletedPhases().length > 0">
        <span class="phases-title">{{ 'PHASES.ARRIVED' | translate }}</span>
        <shared-phase-item *ngFor="let item of getCompletedPhases() | sortBy:'executionOrder'" class="phaseItem" [phase]="item">
            <div *ngIf="item.isLateDelivery" class="laetDelivery">
                {{ 'PHASES.LATEDELIVERY' | translate }}: {{ item.lateDelivery | timedays }}
            </div>
            <div *ngIf="!item.isLateDelivery" class="deliveryAtTime">
                {{ 'PHASES.DELIVERYATTIME' | translate }}
            </div>
        </shared-phase-item>
    </div>
</div>