import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";

import { FileUpDownLoadDto } from "@shared/src/dtos/base/FileUpDownLoadDto";
import { PartnerDetailDto } from "@shared/src/dtos/link/detail/PartnerDetailDto";
import { PartnerListDto } from "@shared/src/dtos/link/list/PartnerListDto";
import { PartnerPairListDto } from "@shared/src/dtos/link/list/PartnerPairListDto";
import { PartnerDto } from "@shared/src/dtos/link/PartnerDto";
import { PartnerInfoDto } from "@shared/src/dtos/link/PartnerInfoDto";
import { PartnerPairDto } from '@shared/src/dtos/link/PartnerPairDto';
import { ActionOnPartners } from '@shared/src/enums/ActionOnPartners';
import { PartnerCategories } from "@shared/src/enums/PartnerCategories";
import { PartnerTypes } from "@shared/src/enums/PartnerTypes";
import { Observable } from "rxjs";
import { BaseController } from "../base.controller";
import { MaintenanceSearchByTextDto } from "../maintenance/_base/MaintenanceSearchByTextDtos";
import { SecurityController } from "../security/security.controller";
import { RouteCardDto } from "@shared/src/public-api";
import { iPartnerDto } from "@shared/src/dtos/link/list/iPartnerDto";

@Injectable({
    providedIn: 'root',
})
export class PartnersController extends BaseController {
    private base = '/links';

    public static FILTERKEYSCARRIER: Array<String> = ['PARTNER.FREIGHTFORWARDER.LINKPERMISSION.FILTER', 'PARTNER.CARRIER.LINKPERMISSION.FILTER'];
    public static FILTERKEYSSHIPPER: Array<String> = ['PARTNER.SHIPPER.LINKPERMISSION.FILTER'];
    public static FILTERKEYSLOGISTICOPERATOR: Array<String> = ['PARTNER.LOGISTICOPERATOR.LINKPERMISSION.FILTER'];
    public static FILTERKEYSDRIVER: Array<String> = ['PARTNER.DRIVER.LINKPERMISSION.FILTER'];
    public static FILTERKEYSSECONDDRIVER: Array<String> = ['PARTNER.DRIVER.LINKPERMISSION.FILTER'];
    public static FILTERKEYSINSPECTOR: Array<String> = ['PARTNER.INSPECTOR.LINKPERMISSION.FILTER'];
    public static FILTERKEYSHEALTHCERTIFIER: Array<String> = ['PARTNER.HEALTHCERTIFIER.LINKPERMISSION.FILTER'];
    public static FILTERKEYSCLIENTPREFERENT: Array<String> = ['PARTNER.CLIENTPREFERENT.LINKPERMISSION.FILTER'];

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public searchPartners(linktype: PartnerTypes, maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<Array<PartnerPairListDto>> {
        return super.post(this.base + "/" + linktype, maintenanceSearchByText, "searchPartners");
    }

    public modifyPartnerCompany(partnerId: number, data: PartnerDetailDto): Observable<PartnerPairDto> {
        return super.post(this.base + "/companies/" + partnerId, data, "modifyPartnerCompany");
    }

    public modifyPartnerDriver(partnerId: number, data: PartnerDetailDto): Observable<PartnerPairDto> {
        return super.post(this.base + "/drivers/" + partnerId, data, "modifyPartnerDriver");
    }

    public getPartnerWithPermissions(partnerId: number): Observable<PartnerPairDto> {
        return super.get(this.base + `/${partnerId}/permissions`);
    }

    public setPartnerAction(action: ActionOnPartners, partnerId: number): Observable<PartnerPairListDto> {
        return super.post(this.base + `/${partnerId}/action`, action, "setPartnerAction");
    }

    public setPartnerPermissionAction(action: ActionOnPartners, partnerId: number, partnerPermissionId: number): Observable<PartnerPairDto> {
        return super.post(this.base + `/${partnerId}/permissions/${partnerPermissionId}/action`, action, "setPartnerPermissionAction");
    }

    public createPartnerCompany(data: PartnerDetailDto): Observable<PartnerDetailDto> {
        return super.post(this.base + `/companies`, data, "createPartnerCompany");
    }

    public createPartnerDriver(data: PartnerDetailDto): Observable<PartnerDetailDto> {
        return super.post(this.base + `/drivers`, data, "createPartnerDriver");
    }

    public sendNotification(category: PartnerCategories, partnerId: number): Observable<Boolean> {
        console.log("entra al controlador");
        return super.post(this.base + "/sendNotification/" + partnerId, category, "sendNotification");
    }

    public getByTenantTargetId(tenantId: number, pcategory: PartnerCategories): Observable<PartnerDetailDto> {
        return super.get(this.base + "/tenanttargetid/" + tenantId + "/" + pcategory);
    }

    public importPartner(body: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.base + "/import", body, "importPartner");
    }

    public update(partnerSettingId: number, accepted: boolean): Observable<Boolean> {
        return this.get(this.base + "/update/" + partnerSettingId + "/" + accepted);
    }

    public sendNotificationToPartners(type: string): Observable<Boolean> {
        return this.getBusy(this.base + "/sendnotificationtopartners/" + type);
    }
    public getEmailsPartners(type: string): Observable<Array<string>> {
        return this.getBusy(this.base + "/getemails/" + type);
    }

    public getPartnerAs(partnerId: number, category: PartnerCategories): Observable<PartnerDto> {
        return this.get(this.base + "/as/" + category + "/" + partnerId);
    }
    public getPartner(partnerId: number): Observable<PartnerListDto> {
        return this.get(this.base + "/" + partnerId);
    }

    public getPartnerInfo(type: PartnerCategories, partnerId: number): Observable<PartnerInfoDto> {
        return this.get(this.base + "/info/" + type + "/" + partnerId);
    }
    public getPartnerInfoRoute(type: PartnerCategories, partnerId: number, routeId: number): Observable<PartnerInfoDto> {
        return this.get(this.base + "/info/" + type + "/" + partnerId + "/" + routeId);
    }

    public getParentInfoRoute(routeId: number): Observable<PartnerInfoDto> {
        return this.get(this.base + "/info/parent/" + routeId);
    }

    public forcedlink(tenanIdToLink: number, partnerCategory: PartnerCategories): Observable<boolean> {
        return this.postBusy(this.base + "/forcedlink", { 'tenanIdToLink': tenanIdToLink, 'partnerCategory': partnerCategory }, "forcedlink");
    }

    public breakPartnerLinkWithTenantIdTarget(partnerId: number, tenantId: number): Observable<boolean> {
        return this.postBusy(this.base + "/break/partnerId/" + partnerId + "/link/tenantId/" + tenantId, "", "forcePartnerLinkWithTenantIdTarget");
    }
    public forcePartnerLinkWithTenantIdTarget(partnerId: number, tenantId: number): Observable<boolean> {
        return this.postBusy(this.base + "/force/partnerId/" + partnerId + "/link/tenantId/" + tenantId, "", "forcePartnerLinkWithTenantIdTarget");
    }
    public forcePartnerLinkWithPartnerIdTarget(partnerId: number, partnerIdTarget: number): Observable<boolean> {
        return this.postBusy(this.base + "/force/partnerId/" + partnerId + "/link/partnerIdTarget/" + partnerIdTarget, "", "forcePartnerLinkWithPartnerIdTarget");
    }

    public getPartnerFromRoute(routeId: number): Observable<iPartnerDto> {
        return this.get(this.base + "/routeId/" + routeId);
    }
}
