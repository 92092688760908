import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from "@angular/router"
import { MatDialog } from '@angular/material/dialog';
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { AlertService, HColor, SharedAsideFactory } from '@shared/src/public-api';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';
import { HLong } from '@shared/src/datatypes/HLong';

@Component({
    selector: 'shared-active-slots-item-phase',
    templateUrl: './shared.active.slots.item.phase.html',
    styleUrls: ['./shared.active.slots.item.phase.scss']
})
export class SharedActiveSlotsItemPhase implements OnInit {

    constructor(protected socketController: SocketController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService,
        @Inject('SharedAsideFactory')
        public sharedAsideFactory: SharedAsideFactory) {
    }

    ngOnInit(): void {
    }

    public onMarkerClick() {
        if (this.phase != null)
            this.sharedAsideFactory.invokeAddressCoordinates(this.phase.addressId, this.phase.formattedAddress, this.phase.longitude, this.phase.latitude);
    }

    public __phase: PhaseCardItemDto;
    @Input()
    public set phase(value: PhaseCardItemDto) {
        if (this.__phase == value)
            return;
        this.__phase = value;
    }
    public get phase(): PhaseCardItemDto {
        return this.__phase;
    }

    public intervals: boolean;
    getSlots() {
        this.intervals = !this.intervals;
    }

    @Input()
    public hideLink: boolean = false;

    public _autoGetSlots: boolean = false;
    @Input()
    public set autoGetSlots(value: boolean) {
        if (this._autoGetSlots == value)
            return;
        this._autoGetSlots = value;
        if (this._autoGetSlots)
            this.getSlots();
    }
    public get autoGetSlots(): boolean {
        return this._autoGetSlots;
    }


    getSlotPickerResource(): string {
        if (this.phase && !HLong.isNullOrNullLong(this.phase.slotId)) {
            return 'ACTIVESLOTS.SLOTPICKER.OPENCHANGESLOTSBUTTON.CAPTION';
        }
        return 'ACTIVESLOTS.SLOTPICKER.OPENASSIGNSLOTSBUTTON.CAPTION';
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }


    @Output() public refreshRoute: EventEmitter<any> = new EventEmitter<any>();

    onRefreshRoute(event: boolean) {
        if (event)
            this.refreshRoute.next(event);
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
