import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccessControlController } from '@shared/src/controllers/accesscontrol/accesscontrol.controller';
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { LoadConsoleController } from '@shared/src/controllers/loadconsole/loadconsole.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { UserSettingController } from '@shared/src/controllers/user/usersetting.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { SetPlateGateDto } from '@shared/src/dtos/accesscontrol/SetPlateGateDto';
import { AccessControlLogDto } from '@shared/src/dtos/accesscontrol/log/AccessControlLogDto';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { LoadConsoleGateItemDto } from '@shared/src/dtos/loadconsole/LoadConsoleGateItemDto';
import { LoadConsolePhaseFilterDto } from '@shared/src/dtos/loadconsole/LoadConsolePhaseFilterDto';
import { LoadConsoleSlotPhaseInfoDto } from '@shared/src/dtos/loadconsole/LoadConsoleSlotPhaseInfoDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { RouteLogDto } from '@shared/src/dtos/route/RouteLogDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { GeneralStatus, HColor, HDate, HDateHour, OpenChangeStatusDto, PhaseController, RouteCardDto, RouteController, SecurityController, ShipmentController } from '@shared/src/public-api';
import { Subscription, timer as observableTimer } from 'rxjs';

@Component({
  selector: 'shared-phase-loadconsole-management',
  templateUrl: './shared.phase.loadconsole.management.component.html',
  styleUrls: ['./shared.phase.loadconsole.management.component.scss']
})
export class SharedPhaseLoadConsoleManagementComponent implements OnInit {

  public useIonic: boolean = false;
  constructor(public router: Router, public phaseController: PhaseController, public shipmentController: ShipmentController,
    public openRouteController: OpenRouteController,
    protected slotsController: SlotsController, protected userSettingController: UserSettingController,
    protected routeController: RouteController, private loadConsoleController: LoadConsoleController, private accessControlController: AccessControlController, protected translateService: TranslateService,
    @Inject('SecurityController') public securityController: SecurityController, @Inject('HomeController') public homeController: HomeController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }

  ngOnInit(): void {

  }


  @Input() public readonly: boolean = false;

  public __phaseInfo: LoadConsolePhaseFilterDto;
  @Input()
  set phaseInfo(value: LoadConsolePhaseFilterDto) {
    if (this.__phaseInfo === value)
      return;
    this.__phaseInfo = value;
    this.loadPhaseData();
  }
  get phaseInfo(): LoadConsolePhaseFilterDto {
    return this.__phaseInfo;
  }

  public __data: LoadConsoleSlotPhaseInfoDto;
  set data(value: LoadConsoleSlotPhaseInfoDto) {
    if (this.__data === value)
      return;
    this.__data = value;
  }
  get data(): LoadConsoleSlotPhaseInfoDto {
    return this.__data;
  }

  public states: StatusTransitionsDto;
  public actions: ActionsStripDto;
  public actionsGates: ActionsStripDto;
  public options: OptionsStripDto;
  public phase: PhaseCardDto;

  private _phaseId: number;
  @Input() public set phaseId(v: number) {
    if (v === this._phaseId)
      return;

    this._phaseId = v;

    if (!this.phaseInfo) {
      this.phaseInfo = LoadConsolePhaseFilterDto.build(0, this._phaseId);
    }
  }

  public get phaseId(): number {
    return this._phaseId;
  }

  public slotInfo: SlotInfoDto;

  public forcedOwnerParticipant: ParticipantDto;
  public route: RouteCardDto;
  public routeId: number;
  public actualOptionId: string = "GENERAL";
  public routeLog: RouteLogDto[];
  public slotLog: SlotLogDto[];

  public getoutofall: boolean;
  public loadPhaseData() {
    this.phaseId = this.phaseInfo.phaseId;
    this.phaseController.getPhase(this.phaseId).subscribe(phase => {
      this.phase = phase;

      if (this.phase) {
        this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.phaseId, ChatParticipantTypes.Phase);
        this.routeId = this.phase.routeId;
        this.routeController.getRoute(this.routeId).subscribe(route => {
          this.route = route;
        });
        this.phaseController.getSlotInfo(this.phaseId).subscribe(data => {
          this.slotInfo = data;
        });
        this.phaseController.getPhaseItemMaintenanceCtrl(this.phaseId).subscribe(data => {
          if (data != null) {
            this.states = data.states;
            this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);
            if (this.options == null)
              this.options = this.buildOptions();
          }
        });
      }
    });
    if (!HLong.isNullOrNullLong(this.phaseInfo.loadConsoleId)) {
      this.loadConsoleController.getPhaseInfo(this.phaseInfo).subscribe(data => {
        this.data = data;
        if (data != null) {
          this.actionsGates = ActionsStripDto.buildFromActionsStripDto(data.actionsGates);
          this.activaTimer();
        }
      });
    }
  }


  public buildOptions(): OptionsStripDto {
    let result = new OptionsStripDto();
    result.add(OptionDto.build("GENERAL", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GENERAL.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GENERAL.HELP", "fa-info-circle", 1, 1, 1, true, false));
    result.add(OptionDto.build("DRIVER", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DRIVER.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DRIVER.HELP", "fa-user-o", 2, 1, 1, false, false));
    if (this.homeController.hasProduct(HardmanProducts.docks))
      result.add(OptionDto.build("DOCKSSLOTS", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DOCKSSLOTS.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.DOCKSSLOTS.HELP", "fa-calendar-check-o", 10, 1, 1, false, false));
    else
      result.add(OptionDto.build("SLOT", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOT.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.SLOT.HELP", "fa-calendar-check-o", 10, 1, 1, false, false));
    result.add(OptionDto.build("INTERNATIONAL", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.INTERNATIONAL.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.INTERNATIONAL.HELP", "", 12, -1, 1, false, false));
    result.add(OptionDto.build("ROUTEHISTORY", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.ROUTEHISTORY.HELP", "", 15, -1, 1, false, false));
    result.add(OptionDto.build("CHAT", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.CHAT.HELP", "", 20, -1, 1, false, false));
    if (this.phase != null && this.phase.isGrouped)
      result.add(OptionDto.build("GROUPED", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.CAPTION", "SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTIONS.GROUPED.HELP", "", 21, -1, 1, false, false));
    result.add(OptionDto.build("OBSERVATIONS", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.TITLE", "SHARED.ROUTE.OBSERVATIONS.COMPONENT.HELPSHORT", "", 30, -1, 1, false, false));

    this.actualOptionId = result.getActualSelected().optionId;
    return result;
  }

  getDayOfWeek(value: HDateHour) {
    if (value == null)
      return "";

    return HDate.getDayOfWeek(HDateHour.getDate(value));
  }

  getGateResource(gate: LoadConsoleGateItemDto) {
    if (gate == null)
      return this.translateService.instant("ACCESSCONTROL.GATE.OUT");

    if (gate.isInsideGate)
      return gate.insideGateResource;
    return gate.outsideGateResource;
  }

  onCallInToDockClick() {
    this.loadConsoleController.setCallInToDock(this.data).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }
  onCancelCallInToDockClick() {
    this.loadConsoleController.exitCallToDock(this.data).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }
  onCallOutToDockClick() {
    this.loadConsoleController.setCallOutToDock(this.data).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }
  onCancelCallOutToDockClick() {
    this.loadConsoleController.setAtToDock(this.data).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }

  public getStyleGateTag(gate: LoadConsoleGateItemDto) {
    if (gate != null && gate.gateKey != "Out") {
      let bcolor = HColor.getColorFromStringTable(this.translateService.instant(gate.gateName), this.homeController);
      return {
        "color": HColor.getWhiteBlackFromColor(bcolor),
        "background-color": bcolor
      };
    }

    return {};
  }

  getGateHeadingResource(gate: LoadConsoleGateItemDto): string {
    if (gate == null)
      return "";

    if (gate.isInsideGate)
      return "GATE.OUT.HEADING";

    return "GATE.IN.HEADING";
  }

  onGateClick(gate: LoadConsoleGateItemDto) {
    if (gate == null)
      return;

    //Si està dins, hem de sortir
    if (gate.isInsideGate) {
      this.accessControlController.exitPlateGate(SetPlateGateDto.build(gate.accessControlId, this.data.plate, this.data.routeId, this.data.phaseId, 0, gate.gateId)).subscribe(data => {
        this.loadPhaseData();
        this.securityController.asideReloadData$.next(true);
      });
    } else {
      this.accessControlController.entryPlateGate(SetPlateGateDto.build(gate.accessControlId, this.data.plate, this.data.routeId, this.data.phaseId, gate.gateId, 0)).subscribe(data => {
        this.loadPhaseData();
        this.securityController.asideReloadData$.next(true);
      });
    }
  }

  onClickActionGates($event: ActionDto) {
    if ($event == null)
      return;
    switch ($event.actionId) {
      case 'GETOUTOFALLGATESANDINIT':
        this.onGetOutOfAllGatesAndInitPhaseClick();
        break;
      case 'GETOUTOFALLGATES':
        this.onGetOutOfAllGatesClick();
        break;
      default:
        this.accessControlController.executeAction(SetPlateGateDto.build(this.data.accessControlId, "", this.data.routeId, this.data.phaseId, 0, 0),
          $event).subscribe(data => {
            this.loadPhaseData();
            this.securityController.asideReloadData$.next(true);
          });
    }
  }
  onGetOutOfAllGatesAndInitPhaseClick() {
    this.accessControlController.exitPlateOfAllGatesAndInitPhase(SetPlateGateDto.build(0, this.data.plate, this.data.routeId, this.data.phaseId, 0, 0)).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }
  onGetOutOfAllGatesClick() {
    this.accessControlController.exitPlateOfAllGates(SetPlateGateDto.build(0, this.data.plate, this.data.routeId, this.data.phaseId, 0, 0)).subscribe(data => {
      this.loadPhaseData();
      this.securityController.asideReloadData$.next(true);
    });
  }

  public selectedOptionId = "";
  onClickOption(option: OptionDto) {
    this.actualOptionId = option.optionId;
    if (option && option.optionId === "SLOTSHISTORY") {
      this.slotsController.getSlotLogsByPhaseId(this.phaseId).subscribe(data => {
        this.slotLog = data;
      });

    } else if (option && option.optionId === "ROUTEHISTORY") {
      // this.routeController.getRouteLogs(this.routeId).subscribe(data => {
      //   this.routeLog = data;
      // });
    } else if (option && option.optionId === "CHAT") {
      this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.routeId, ChatParticipantTypes.Route);
    } else if (option && option.optionId === "GROUPED") {

    }
  }
  onClickAction(action: ActionDto) {
    this.phaseController.executePhaseActions(action, this.phase).subscribe(
      d => {
        this.loadPhaseData();
      }
    );
  }

  onSelectorChangedState(state: GeneralStatus) {
    if (!HLong.isNullOrNullLong(this.phaseId)) {
      this.openRouteController.phaseSetStatus(this.phaseId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
        this.loadPhaseData();
      });
    }
  }

  clickParticipant(participant: ParticipantDto) {
    if (participant == null)
      return;
    this.router.navigateByUrl("/chat/" + participant.participantType + "/" + participant.participantId);
  }

  ngOnDestroy() {
    this.desactivaTimer();
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 60000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(SharedPhaseLoadConsoleManagementComponent.AUTOREFRESHFREQUENCYMILLISECONDS, SharedPhaseLoadConsoleManagementComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.timerSubscription = timer.subscribe(t => {
      this.desactivaTimer();
      this.loadPhaseData();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
