import { GeofenceShapes } from "@shared/src/enums/GeofenceShapes";
import { GeofencePointDto } from "./GeofencePointDto";

export class GeofenceDto {
    public geofenceId: number;
    public shape: GeofenceShapes;
    public radius: number;
    public points: Array<GeofencePointDto> = [];
    public color: string;
}
