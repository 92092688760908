<div *ngIf="phase">
    <enum-selector class="col-30" [enumvalues]="enumBlames" translategroup="PHASETIMES.AGREEMENTS"
        [(ngModel)]="selectedBlame"></enum-selector>

    <div class="grup" *ngIf="data && data.total">
        <div class="titol">
            {{'SHARED.PHASE.ITEM.MAINTENANCE.FIRSTLASTMOMENT.CAPTION'| translate}}
        </div>
        <div class="contingut">
            <table class="hardman">
                <tr>
                    <th>{{ 'PHASETIMES.COMMITMENT_START' | translate}}</th>
                    <td>{{ data.total.commitmentStart | datehourwithsecondsnotz }}</td>
                </tr>
                <tr>
                    <th>{{ 'PHASETIMES.COMMITMENT_END' | translate}}</th>
                    <td>{{ data.total.commitmentEnd | datehourwithsecondsnotz }}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="grup" *ngIf="data && data.total">
        <div class="titol">
            {{'SHARED.PHASE.TIMES.COMPONENT.RESUM.CAPTION'| translate}}
        </div>
        <div class="contingut">
            <table class="hardman">
                <tr class="">
                    <th></th>
                    <th>{{ "PHASETIMES.MY_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.OWNER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.DRIVER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.CARRIER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.LOGISTICOPERATOR_DELAY" | translate }}</th>
                </tr>
                <tr class="">
                    <th>{{ 'PHASETIMES.DELAY' | translate }}</th>
                    <td [ngClass]="{'delay': data.total.myDelay != null && !data.total.myDelay.equals(timeNull)}">
                        {{ data.total.myDelay | time }}
                    </td>
                    <td [ngClass]="{'delay': data.total.ownerDelay != null && !data.total.ownerDelay.equals(timeNull)}">
                        {{ data.total.ownerDelay | time }}
                    </td>
                    <td
                        [ngClass]="{'delay': data.total.driverDelay != null && !data.total.driverDelay.equals(timeNull)}">
                        {{ data.total.driverDelay | time }}
                    </td>
                    <td
                        [ngClass]="{'delay': data.total.carrierDelay != null && !data.total.carrierDelay.equals(timeNull)}">
                        {{ data.total.carrierDelay | time }}
                    </td>
                    <td
                        [ngClass]="{'delay': data.total.logisticOperatorDelay != null && !data.total.logisticOperatorDelay.equals(timeNull)}">
                        {{ data.total.logisticOperatorDelay | time }}
                    </td>
                </tr>

                <tr class="separator-after ">
                    <th>{{ 'PHASETIMES.ANTICIPATION' | translate }}</th>
                    <td
                        [ngClass]="{'anticipation' : data.total.myAnticipation != null && !data.total.myAnticipation.equals(timeNull)}">
                        {{ data.total.myAnticipation | time }}
                    </td>
                    <td>00:00</td>
                    <td
                        [ngClass]="{'anticipation' : data.total.driverAnticipation != null && !data.total.driverAnticipation.equals(timeNull)}">
                        {{ data.total.driverAnticipation | time }}
                    </td>
                    <td
                        [ngClass]="{'anticipation' : data.total.carrierAnticipation != null && !data.total.carrierAnticipation.equals(timeNull)}">
                        {{ data.total.carrierAnticipation | time }}
                    </td>
                    <td
                        [ngClass]="{'anticipation' : data.total.logisticOperatorAnticipation != null && !data.total.logisticOperatorAnticipation.equals(timeNull)}">
                        {{ data.total.logisticOperatorAnticipation | time }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="grup" *ngIf="data && data.total">
        <div class="titol">
            {{'SHARED.PHASE.TIMES.COMPONENT.DETALL.CAPTION'| translate}}
        </div>
        <div class="contingut">

            <table class="hardman">
                <tr class="">
                    <th></th>
                    <th>{{ "PHASETIMES.MY_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.OWNER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.DRIVER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.CARRIER_DELAY" | translate }}</th>
                    <th>{{ "PHASETIMES.LOGISTICOPERATOR_DELAY" | translate }}</th>
                </tr>

                <tr *ngFor="let chunk of data.chunks">
                    <th>{{ chunk.registeredAt | datehourwithsecondsnotz }}</th>
                    <td *ngIf="chunk.isDelay && !chunk.isAnticipation"
                        [ngClass]="{'delay' : chunk.myDelay != null && !chunk.myDelay.equals(timeNull)}">
                        {{ chunk.myDelay | time }}
                    </td>
                    <td *ngIf="chunk.isDelay && !chunk.isAnticipation"
                        [ngClass]="{'delay' : chunk.ownerDelay != null && !chunk.ownerDelay.equals(timeNull)}">
                        {{ chunk.ownerDelay | time }}
                    </td>
                    <td *ngIf="chunk.isDelay && !chunk.isAnticipation"
                        [ngClass]="{'delay' : chunk.driverDelay != null && !chunk.driverDelay.equals(timeNull)}">
                        {{ chunk.driverDelay | time }}
                    </td>
                    <td *ngIf="chunk.isDelay && !chunk.isAnticipation"
                        [ngClass]="{'delay' : chunk.carrierDelay != null && !chunk.carrierDelay.equals(timeNull)}">
                        {{ chunk.carrierDelay | time }}
                    </td>
                    <td *ngIf="chunk.isDelay && !chunk.isAnticipation"
                        [ngClass]="{'delay' : chunk.logisticOperatorDelay != null && !chunk.logisticOperatorDelay.equals(timeNull)}">
                        {{ chunk.logisticOperatorDelay | time }}
                    </td>
                    <td *ngIf="chunk.isAnticipation && !chunk.isDelay"
                        [ngClass]="{'anticipation' : chunk.myAnticipation != null && !chunk.myAnticipation.equals(timeNull)}">
                        {{ chunk.myAnticipation | time }}
                    </td>
                    <td *ngIf="chunk.isAnticipation && !chunk.isDelay">00:00</td>
                    <td *ngIf="chunk.isAnticipation && !chunk.isDelay"
                        [ngClass]="{'anticipation' : chunk.driverAnticipation != null && !chunk.driverAnticipation.equals(timeNull)}">
                        {{ chunk.driverAnticipation | time }}
                    </td>
                    <td *ngIf="chunk.isAnticipation && !chunk.isDelay"
                        [ngClass]="{'anticipation' : chunk.carrierAnticipation != null && !chunk.carrierAnticipation.equals(timeNull)}">
                        {{ chunk.carrierAnticipation | time }}
                    </td>
                    <td *ngIf="chunk.isAnticipation && !chunk.isDelay"
                        [ngClass]="{'anticipation' : chunk.logisticOperatorAnticipation != null && !chunk.logisticOperatorAnticipation.equals(timeNull)}">
                        {{ chunk.logisticOperatorAnticipation | time }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>