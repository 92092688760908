import { Component, Inject, Input, OnInit } from '@angular/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HomeController } from '@shared/src/public-api';
import { iTypologyTime } from '@shared/src/dtos/typology/iTypologyTime';

@Component({
    selector: 'shared-typology-time-box',
    templateUrl: './shared.typology.time.box.html',
    styleUrls: ['./shared.typology.time.box.scss']
})
export class SharedTypologyTimeBox implements OnInit {

    public useIonic: boolean = false;
    constructor(
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('HomeController') public homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();

    }

    ngOnInit(): void {
    }


    public __value: iTypologyTime;
    @Input()
    public set value(value: iTypologyTime) {
        if (this.__value === value)
            return;
        this.__value = value;
    }
    public get value(): iTypologyTime {
        return this.__value;
    }

}
