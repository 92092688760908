import { Component, Input, Output, Injectable, EventEmitter, OnInit, ElementRef, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { HDate } from '@shared/src/datatypes/HDate';
import { CalendarDayDto } from '@shared/src/dtos/base/calendar/CalendarDayDto';
import { CalendarDto } from '@shared/src/dtos/base/calendar/CalendarDto';


@Component({
  selector: 'calendarmonth',
  templateUrl: './calendar.month.html',
  styleUrls: ["./calendar.scss"]
})
export class CalendarMonth<DAY extends CalendarDayDto, CAL extends CalendarDto<DAY>> {

  constructor() {
  }

  ngOnInit() {
  }


  @Input() monthHeight: number = 0;
  @Input() monthWidth: number = 0;
  @Input() monthCount: number = 0;
  @Input() readonly: boolean = false;
  @Output() onDateClick: EventEmitter<DAY> = new EventEmitter();
  @Output() onWeekDayClick: EventEmitter<number> = new EventEmitter();
  @Output() onWeekClick: EventEmitter<number> = new EventEmitter();
  @Output() onMonthClick: EventEmitter<void> = new EventEmitter();

  private _calendar: CAL;
  @Input() set calendar(value: CAL) {
    if (this._calendar === value)
      return;
    this._calendar = value;
  }
  get calendar(): CAL {
    return this._calendar;
  }

  @Input() innerstyle: object;

  @Input() _initialdate: HDate = HDate.buildas(4000, 12, 31);
  @Input() set initialdate(value: HDate) {
    if (this._initialdate === value)
      return;
    if (HDate.equals(this._initialdate, value))
      return;
    this._initialdate = value;
    this.calculateNumSetmanes();
    this.setcalendarweekdays();
  }
  get initialdate(): HDate {
    return this._initialdate;
  }
  @Input() _finaldate: HDate = HDate.buildas(4000, 12, 31);
  @Input() set finaldate(value: HDate) {
    if (this._finaldate === value)
      return;
    if (HDate.equals(this._finaldate, value))
      return;
    this._finaldate = value;
    this.calculateNumSetmanes();
    this.setcalendarweekdays();
  }
  get finaldate(): HDate {
    return this._finaldate;
  }

  getMonthResource() {
    return "MONTHYEAR." + (this.initialdate != null ? this.initialdate.month : "NONE");
  }

  // Sempre contem 6 setmanes, pero si només mostrem un mes, seran les que tingui el mes
  @Input() setmanes: number = 6;


  public getcalendardays(): DAY[] {
    if (this._calendar == null || this._calendar.days == undefined || this._calendar.days == null || HDate.isNullOrNullValue(this.initialdate) || HDate.isNullOrNullValue(this.finaldate))
      return new Array();

    let result = new Array();
    //Tinc que afegir dies en blanc a la part de l'esquerra que son nulls
    //El diumenge ==0 i l'haig de moure al 7
    let i = HDate.getDayOfWeekNumberPerDesplaçar(this.initialdate);
    let j = 0;
    while (i > j) {
      result.push(CalendarDayDto.NULLDAY(HDate.addDaysToHDate(this.initialdate, (-1) * i)));
      i--;
    }
    this._calendar.days.forEach(function (value) {
      if (HDate.inForce(value.onDate, this.initialdate, this.finaldate))
        result.push(value);
    }, this);

    i = 6 - HDate.getDayOfWeekNumberPerDesplaçar(this.finaldate);
    j = 1;
    while (i > 0) {
      result.push(CalendarDayDto.NULLDAY(HDate.addDaysToHDate(this.finaldate, j++)));
      i--;
    }

    return result;
  }


  private calculateNumSetmanes() {
    let hprimerdia = HDate.addDaysToHDate(this.initialdate, (-1) * HDate.getDayOfWeekNumberPerDesplaçar(this.initialdate));
    let primerdia = HDate.getDate(hprimerdia).getTime() / 86400000;
    let hultimdia = HDate.addDaysToHDate(this.finaldate, 6 - HDate.getDayOfWeekNumberPerDesplaçar(this.finaldate));
    let ultimdia = HDate.getDate(hultimdia).getTime() / 86400000;

    let distanciaentredies = (ultimdia - primerdia) + 1;
    this.setmanes = this.monthCount > 1 ? 6 : Math.ceil(distanciaentredies / 7); // Sempre contem 6 setmanes, pero si només mostrem un mes, seran les que tingui el mes
  }

  private calendardays: DAY[] = new Array();
  public setcalendarweekdays() {
    this.calendardays = this.getcalendardays();
  }

  public getcalendardaysofweek(week: number): DAY[] {

    let startday = (week - 1) * 7;
    let finishday = startday + 7;

    let result = new Array<DAY>();

    let i = 0;
    while (i < this.calendardays.length && i < finishday) {
      if (i >= startday)
        result.push(this.calendardays[i]);
      i++;
    }

    return result;
  }
  public getWeekNumber(week: number): number {
    let i = (week - 1) * 7;
    let d: DAY = this.calendardays[i];
    if (d == null || d == undefined)
      return -1;
    return HDate.getWeekNumber(d.onDate);
  }


  public getcalendarweeks(): Array<number> {
    let result = new Array<number>();
    let week = 1;
    while (week <= this.setmanes) {
      result.push(week);
      week++;
    }
    return result;
  }

  /**
   * Calcula l'estil per l'amplada dels dies
   */
  public getStyleTr() {
    if (this.innerstyle != null)
      return this.innerstyle;
    if (this.setmanes == 0)
      return { height: this.monthHeight + "px" };
    return { height: (this.monthHeight / this.setmanes) + 'px' };
  }

  public getclass(day: DAY): string {
    let result = "";

    if (day == null || !HDate.inForce(day.onDate, this.initialdate, this.finaldate))
      result = "greyday";
    else
      result = "regularday";

    return result;
  }

  public dayclick(e) {
    this.onDateClick.next(e);
  }
  public weekdayclick(day: number) {
    this.onWeekDayClick.next(day);
  }
  public weekclick(day: number) {
    this.onWeekClick.next(day);
  }
  public monthclick() {
    this.onMonthClick.next();
  }

  public getClassOfDayCaption(day: DAY): string {
    let result = "";

    if (day == null)
      return;

    result += " calendarday";

    return result;
  }
  public getDayCaption(day: DAY): string {
    if (day == null || day.onDate == null)
      return "-";
    return day.onDate.dayOfMonth.toString();
  }

  public getCalendarDayClass(day: DAY): string {
    return "";
  }
}


