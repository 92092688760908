import { Component, Inject, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { UpdatesService } from 'src/services/updates.service';

@Component({
    selector: 'splash-page',
    templateUrl: './splash.page.html',
    styleUrls: ['./splash.page.scss']
})
export class SplashPage {

    constructor(protected updateService: UpdatesService) { }

    ngOnInit(): void {
        this.updateService.onUpdateProgressChanged.subscribe(progress => {
            this.updateProgress = progress;
            console.log("[AppComponent][SplashPage] progress", progress);
        })
    }

    @Input() public updateProgress: number = 0;

}