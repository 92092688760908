export enum DataTypes {

	// Els mateixos que TenantSettingDataTypes
	String = "String",
	Integer = "Integer",
	Double = "Double",
	Long = "Long",
	Boolean = "Boolean",
	HTriState = "HTriState",
	HDate = "HDate",
	HTime = "HTime",
	HTags = "HTags",
	HHour = "HHour",
	HHourInterval = "HHourInterval",
	Color = "Color",
	StringList = "StringList",
}
