import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { PhaseController } from '@shared/src/controllers/route/phase/phase.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HColor } from '@shared/src/datatypes/HColor';
import { DockDto } from '@shared/src/dtos/address/dock/DockDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OpenChangeStatusDto } from '@shared/src/dtos/open/OpenChangeStatusDto';
import { CompatibleDocksDto } from '@shared/src/dtos/phase/CompatibleDocksDto';
import { PhaseCardDto } from '@shared/src/dtos/phase/PhaseCardDto';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { GeneralStatus } from '@shared/src/enums/GeneralStatus';
import { AlertService, HTriState } from '@shared/src/public-api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'shared-phase-item-maintenance',
    templateUrl: './shared.phase.item.maintenance.component.html',
    styleUrls: ['./shared.phase.item.maintenance.component.scss']
})
export class SharedPhaseItemMaintenanceComponent implements OnInit {

    @Input() public readonly: boolean = false;

    constructor(public router: Router, public phaseController: PhaseController,
        public openRouteController: OpenRouteController,
        @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory,
        @Inject('AlertService') private alertService: AlertService,
        @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        })

        this.docksChangeSubject.pipe(
            debounceTime(1000))
            .subscribe(value => {
                this.docksChange();
            });
    }


    public _phase: PhaseCardDto;
    @Input()
    set phase(value: PhaseCardDto) {
        if (this._phase === value)
            return;

        this._phase = value;
        this.loadData();
    }
    get phase(): PhaseCardDto {
        return this._phase;
    }

    @Input()
    public phases: PhaseCardDto[];


    public states: StatusTransitionsDto;
    public actions: ActionsStripDto;
    public options: OptionsStripDto;

    public forcedOwnerParticipant: ParticipantDto;

    @Output() refreshRoute: EventEmitter<boolean> = new EventEmitter();
    @Output() refreshPhase: EventEmitter<boolean> = new EventEmitter();
    public loadData() {

        this.validateSelectedShipment();

        if (this.phase == null)
            return;

        this.forcedOwnerParticipant = ParticipantDto.buildByParams(this.phase.phaseId, ChatParticipantTypes.Phase);

        this.phaseController.getPhaseItemMaintenanceCtrl(this.phase.phaseId).subscribe(data => {
            if (data != null) {
                if (data.options != null)
                    this.options = OptionsStripDto.buildFromOptionsStripDto(data.options);
                if (data.actions != null)
                    this.actions = ActionsStripDto.buildFromActionsStripDto(data.actions);

                this.states = data.states;
                this.selectedOptionId = OptionsStripDto.arrange(this.options, this.selectedOptionId, data.optionId);
            }
        });
    }

    clickParticipant(participant: ParticipantDto) {
        if (participant == null)
            return;
        this.router.navigateByUrl("/chat/" + participant.participantType + "/" + participant.participantId);
    }

    validateSelectedShipment() {
        if (this.selectedShipment == null)
            return;
        if (this.phase == null)
            this.selectedShipment = null;

        let trobat = false;
        if (this.phase && this.phase.shipments)
            this.phase.shipments.forEach(s => (trobat = (trobat || s.shipmentId === this.selectedShipment.shipmentId)));
        if (!trobat) {
            this.selectedShipment = null;
            this.selectedShipmentChange.next(null);
        }
    }

    onMarkerDoubleClick() {
        this.sharedAsideFactory.invokeAddressCoordinates(this.phase.addressId, this.phase.formattedAddress, this.phase.longitude, this.phase.latitude);
    }

    generateVerificationCode() {
        this.phaseController.generateVerificationCode(this.phase.phaseId).subscribe(e => {
            if (e)
                this.phase.verificationCode = e;
        });
    }

    onClickAction(action: ActionDto) {
        this.phaseController.executePhaseActions(action, this.phase).subscribe(
            d => {
                if (d)
                    this.refreshPhase.next(true);
            }
        );
    }

    tagsChange() {
        if (this.phase != null)
            this.phaseController.setManualTags(this.phase.phaseId, this.phase.manualTags).subscribe(data => {
                this.refreshPhase.next(true);
            });
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option != null)
            this.selectedOptionId = option.optionId;
        else
            this.selectedOptionId = "";
    }

    onSelectorChangedState(state: GeneralStatus) {
        if (this.phase != null) {
            this.phase.generalStatus = state;
            this.openRouteController.phaseSetStatus(this.phase.phaseId, OpenChangeStatusDto.build(state, 0)).subscribe(data => {
                this.refreshPhase.next(true);
                this.refreshRoute.next(true);
            });
        }
    }
    onSlotsChanged() {
        this.refreshPhase.next(true);
        this.refreshRoute.next(true);
    }
    onDockChanged(dock: DockDto) {
        this.refreshPhase.next(true);
        this.refreshRoute.next(true);
    }
    onStrongAddressChanged() {
        this.refreshPhase.next(true);
        this.refreshRoute.next(true);
    }

    private _selectedShipment: ShipmentCardDto = null;
    @Output() selectedShipmentChange = new EventEmitter<ShipmentCardDto>();
    @Input()
    public set selectedShipment(value: ShipmentCardDto) {
        if (this._selectedShipment === value)
            return;
        this._selectedShipment = value;
    }
    public get selectedShipment(): ShipmentCardDto {
        return this._selectedShipment;
    }
    selectShipment(shipment: ShipmentCardDto) {
        this.selectedShipment = shipment;
        this.selectedShipmentChange.next(shipment);
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    ngOnInit(): void {
    }

    copy(text: string) {
        this.alertService.copyToClipboard(text).subscribe();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

    public docksChangeSubject: Subject<boolean> = new Subject<boolean>();
    docksChange() {
        this.phaseController.setCompatibleDocks(this.phase.phaseId, CompatibleDocksDto.build(this.phase.docksSystem, this.phase.docksUser, this.phase.docksUseSystemUser)).subscribe(e => {
            console.log("setCompatibleDocks", e);
        })
    }
}
