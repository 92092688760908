import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RouteListMaintenanceDto } from './RouteListMaintenanceDto';
import { RouteItemMaintenanceDto } from './RouteItemMaintenanceDto';
import{ PhaseItemMaintenanceDto } from '../phase/PhaseItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';

@Injectable()
export class RouteMaintenanceController extends BaseMaintenanceController<RouteListMaintenanceDto, RouteItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "activity/route");
    }

    public getResourceRoot(): string {
        return "ROUTE";
    }

}