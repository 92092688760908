import { HString } from "./HString";
import { HTime } from "./HTime";

export class HHour {
    h: number = 0;
    m: number = 0;
    static HOUR_24: HHour = new HHour(24, 0);
    static HOUR_0: HHour = new HHour(0, 0);

    /**
      * Representa un moment d'un dia determinado. En realitat es un Temps pero
      * limitadt al valor d'un dia (recordant que un dia te 24 hores)
     */

    constructor(hours: number, minutes: number) {
        if (minutes >= 60) {
            hours = hours + Math.floor(minutes / 60);
            minutes = minutes % 60;
        }
        if (hours > 48) {
            hours = 48
            minutes = 0;
        }
        if (hours == 48 && minutes > 0) {
            minutes = 0;
        }
        this.h = hours;
        this.m = minutes;
    }

    static addHours(value: HHour, toadd: number): HHour {
        return new HHour(value.h + toadd, value.m);
    }

    public getHour(): number {
        return this.h;
    }
    public getMinutes(): number {
        return this.m;
    }

    public static NULLVALUE() {
        return new HHour(0, 0);
    }

    public static empty(): HHour {
        let hhour = new HHour(0, 0);
        return hhour;
    }

    public static clone(value: HHour): HHour {
        return new HHour(value.h, value.m);
    }

    public static build(value: any): HHour {
        let hour: number = 0;
        let minute: number = 0;
        if (typeof value === 'string' || value instanceof String) {
            if (value == null || value == undefined || value == '')
                return HHour.empty();

            value = HString.cleanSpaces(value);

            value = this.getFormatHour48FromHour24(value);

            let time: string[] = value.split(":");
            if (!time || time.length != 2) {
                if (value.length == 4) {
                    hour = Number(value.substring(0, 2));
                    minute = Number(value.substring(2, 4));
                }
            } else {
                hour = Number(time[0]);
                minute = Number(time[1]);
            }

        } else if (typeof value === 'object' || value instanceof Date) {
            if (value != null) {
                if (value.h != undefined)
                    hour = value.h;
                if (value.m != undefined)
                    minute = value.m;
                if (value.getHours != undefined)
                    hour = value.getHours();
                if (value.getMinutes != undefined)
                    minute = value.getMinutes();
            }
        }
        let hhour = new HHour(hour, minute);
        return hhour;
    }

    public static buildByHoursAndMinutes(hours: number, minutes: number): HHour {
        return new HHour(hours, minutes);
    }

    public static buildas(h: number, m: number): HHour {
        let result = new HHour(h, m);
        return result;
    }

    public static isValidFormat(value: String): boolean {
        if (!value || value.length == 0)
            return false;

        value = HString.cleanSpaces(value);

        value = this.getFormatHour48FromHour24(value);

        let time: string[] = value.split(":");
        if (!time || time.length != 2) {
            if (value.length != 4)
                return false;

            time = new Array;
            time[0] = value.substring(0, 2);
            time[1] = value.substring(2, 4);

        }

        let hours: string = time[0];
        let minutes: string = time[1];

        if (hours == '--')
            hours = '00';
        if (minutes == '--')
            minutes = '00';

        //els minuts només permetem 2 números
        if (!minutes || minutes.length != 2)
            return false;

        //les hores com a màxim son 2 números (maxim 24 hores)
        if (!hours || hours.length > 2 || hours.length <= 0)
            return false;

        let ihours: number = Number(hours);
        if (isNaN(ihours) || ihours > 48)
            return false;

        let iminutes: number = Number(minutes);
        if (isNaN(iminutes) || iminutes > 59)
            return false;

        if (ihours == 48 && iminutes > 0)
            return false;

        return true;
    }

    public static getFormatHour48FromHour24(value: String): String {
        if (value == null)
            value = HHour.empty().toString();

        //Si no te format del tipus +06:00
        let pos = value.indexOf("+");
        if (pos < 0)
            return value;

        let newvalue = "";

        let tosum: boolean = true;
        for (let i = 0; i < value.length; i++) {
            let t = value.substr(i, 1);

            if (t.trim() == "+")
                continue;

            //Si ja em llegit les hores comprovem si es de 24 hores
            if (tosum && (t.trim() == ":" || (i > 2 && value.length == 5) || (i > 1 && value.length < 5))) // 2 --> +06:00...+6:00...+600
            {
                let dnewvalue = Number.parseInt(newvalue) + 24;
                newvalue = dnewvalue.toString();
                tosum = false;
            }
            newvalue = newvalue.trim() + t.trim();
        }

        return newvalue;
    }

    public static getHourString(value: HHour): string {
        if (value == null)
            value = HHour.empty();

        if (value.h == null)
            value.h = 0;
        if (value.m == null)
            value.m = 0;

        let h = value.h;
        let passday = "";
        if (value.h > 24) {
            h = value.h - 24;
            passday = "+";
        }
        let shour: string = (h < 10 ? "0" + h : "" + h);

        let sminute: string = (value.m < 10 ? "0" + value.m : "" + value.m);
        let time: string = passday + shour + ":" + sminute;
        return time;
    }

    public toString(): string {
        return HHour.getHourString(this);
    }

    public addHours(hours: number): HHour {
        return new HHour(this.h + hours, this.m);
    }

    public addMinutes(minutes: number): HHour {
        return new HHour(this.h, this.m + minutes);
    }

    public addTime(time: HTime): HHour {
        return new HHour(this.h + time.getHours(), this.m + time.getMinutes());
    }

    public minusTime(time: HTime): HHour {
        return new HHour(this.h - time.getHours(), this.m - time.getMinutes());
    }

    public static min(a: HHour, b: HHour): HHour {
        if (a == null)
            return b;
        if (b == null)
            return a;

        if (this.isMinorThan(b, a))
            return b;
        return a;
    }
    public static max(a: HHour, b: HHour): HHour {
        if (a == null)
            return b;
        if (b == null)
            return a;

        if (this.isGreaterThan(b, a))
            return b;
        return a;
    }

    /**
     * Retorna true quan Les hores i els minuts han de ser els mateixos. Per tant representen la mateixa hora
     * @param other 
     */
    public equals(other: HHour): boolean {
        return HHour.isEqualsThan(this, other);
    }

    /**
    * True si valuea és igual valueb. Cap dels dos pot ser null
    * @param valuea 
    * @param valueb 
    */
    public static isEqualsThan(valuea: HHour, valueb: HHour): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.h != valueb.h)
            return false;
        if (valuea.m != valueb.m)
            return false;
        return true;
    }

    /**
     * Retorna true quan jo (this) sòc més petit que other (param)
     * @param other 
     */
    public minorThan(other: HHour): boolean {
        return HHour.isMinorThan(this, other);

    }

    /**
     * True si valuea es més petit que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isMinorThan(valuea: HHour, valueb: HHour): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.h < valueb.h)
            return true;
        if (valuea.h > valueb.h)
            return false;
        if (valuea.m < valueb.m)
            return true;
        return false;
    }


    /**
     * Retorna true quan jo (this) sòc més gran que other (param)
     * @param other 
     */
    public greaterThan(other: HHour): boolean {
        return HHour.isGreaterThan(this, other);
    }


    /**
     * True si valuea es més gran que valueb. Cap dels dos pot ser null
     * @param valuea 
     * @param valueb 
     */
    public static isGreaterThan(valuea: HHour, valueb: HHour): boolean {
        if (!valuea || !valueb)
            return false;
        if (valuea.h > valueb.h)
            return true;
        if (valuea.h < valueb.h)
            return false;
        if (valuea.m > valueb.m)
            return true;
        return false;
    }
    public static isGreaterEqualsThan(valuea: HHour, valueb: HHour): boolean {
        if (HHour.isEqualsThan(valuea, valueb) || HHour.isGreaterThan(valuea, valueb))
            return true;
        return false;
    }

    /**
     * Et dona la distancia en decimal entre dues hores.
     * @param end 
     * @param start 
     */
    public static distance(end: HHour, start: HHour): number {
        let result = 1;

        if (end.h == start.h && end.m == start.m)
            return 0;

        let resulth = end.h - start.h;
        let resultm = end.m >= start.m ? end.m - start.m : (60 - start.m) + end.m;

        result = resulth + (resultm * 1 / 60);

        return result;
    }

    /**
     * Et dona la distancia en decimal entre dues hores.
     * @param end 
     * @param start 
     */
    public static distanceHTime(end: HHour, start: HHour): HTime {
        let result = 1;

        if (end.h == start.h && end.m == start.m)
            return HTime.NULLVALUE();

        let resulth = end.h - start.h;
        let resultm = end.m >= start.m ? end.m - start.m : (60 - start.m) + end.m;

        let hhtime = HTime.buildByHoursAndMinutes(resulth, resultm * 1);

        return hhtime;
    }

    /**
     * Retorna el màxim entre la distancia de hoores i el valor donat, s'utilitza per exemple a l'agenda per evitar que una tsca no ocupi gens.
     * @param end 
     * @param start 
     * @param minvalue 
     */
    public static maxDistance(end: HHour, start: HHour, minvalue: number): number {
        return Math.max(HHour.distance(end, start), minvalue);
    }
    /**
* Retorna true qiuan el timeinterval representa un espai de temps 0
*/
    public static isNull(value: HHour): boolean {
        if (!value)
            return true;
        if (!value.h && !value.m)
            return true;
        return false;
    }

    /**
* Reprsentació a pantalla d'un time interval com a texte
* @param value 
*/
    public static toScreenString(value: HHour): string {
        if (HHour.isNull(value))
            return "00:00";
        return HHour.getHourString(value);
    }


    /**
     * Indica si és null o reprewenta un valor null o neutre
     * @param value 
     */
    public static isNullOrNullValue(value: HHour): boolean {
        if (value == null)
            return true;
        return HHour.isNull(value);
    }

    /** Retorna una cadena que és la hora totalment comprimida */
    public static toSyntaxString(value: HHour): string {
        if (HHour.isNullOrNullValue(value))
            return "0000";
        return HHour.pad((value.h * 100) + value.m, 4);
    }

    public static pad(num, size): string {
        let s = "000000000" + num;
        return s.substr(s.length - size);
    }

    public static now(): HHour {
        var now = new Date();
        return new HHour(now.getHours(), now.getMinutes());
    }

    public static buildFromDecimal(value: number): HHour {
        let result = new HHour(0, 0);
        if (!value)
            return result;

        let h = value / 60;
        let m = value % 60;

        result.addHours(h);
        result.addMinutes(m);

        return result;
    }


    static toHTime(value: HHour): HTime {
        if (value == null)
            return HTime.NULLVALUE();
        return new HTime("", value.h, value.m);
    }



    public getDecimal(): number {
        return this.m + (this.h * 60);
    }
    public static getDecimal(value: HHour): number {
        return value.m + (value.h * 60);
    }
}