import { HardmanActivityTypes } from "@shared/src/enums/HardmanActivityTypes"
import { Languages } from "@shared/src/enums/Languages"
import { Injectable } from "@angular/core";

@Injectable()
export class RegOrganizationDto {
    public organizationTrademark: string;
    public organizationName: string;
    public nif: string;
    public address: string;
    public prefix: string;
    public phone: string;
    public hardmanActivityType: HardmanActivityTypes;
    public language: Languages;
}