import { Component, Input, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TelemetryDto } from '@shared/src/controllers/maintenance/device/telemetry/TelemetryDto';
import { ManualAddressDto } from '@shared/src/controllers/maps/ManualAddressDto';

export default moment;


@Component({
    selector: 'shared-telemetry-item',
    templateUrl: './shared.telemetry.item.html',
    styleUrls: ['./shared.telemetry.item.scss']
})

export class SharedTelemetryItem implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    @Input()
    public telemetry: TelemetryDto;

    @Input()
    public originAddress: ManualAddressDto;
    @Input()
    public destinationAddress: ManualAddressDto;

    public pretty(value) {
        return JSON.stringify(value);
    }
}
