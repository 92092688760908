import { Injectable } from '@angular/core';
import { PartnerMaintenanceController } from './PartnerMaintenanceController';


@Injectable()
export class PartnerShipperMaintenanceController extends
    PartnerMaintenanceController {

    public getResourceRoot(): string {
        return "PARTNER.SHIPPER";
    }

    public buildUrl(uri: string): string {
        return "/system/partner/shipper" + uri;
    }

    public getPermission(): string[] {
        return ["PARTNER.SHIPPER.LINKPERMISSION.FILTER"];
    }
}
