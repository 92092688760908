<div class="container" *ngIf="route">
        <div class="bar">
                <options class="baroptions toolbar" [optionstrip]="options" (clickOption)="onClickOption($event)">
                </options>
                <div class="component-title"> {{'ROUTEPAGE.ITEM.DIVIDED.CAPTION' | translate}} </div>
                <actions class="baractions" *ngIf="!readonly" [actionstrip]="actions"
                        (clickAction)="onClickAction($event)">
                </actions>
        </div>
        <div class="contenidor-interior">
                <div *ngIf="''==selectedOptionId || 'INFO'==selectedOptionId" class="help"
                        [innerHTML]="'SHARED.ROUTE.DIVIDED.COMPONENT.HELP' | translate"></div>
                <shared-route-division class="notitle" *ngIf="''==selectedOptionId || 'INFO'==selectedOptionId"
                        [route]="route" (refreshRoute)="onRefreshRoute()"></shared-route-division>

                <shared-route-division *ngIf="'DIVISION'==selectedOptionId" [route]="route"
                        (refreshRoute)="onRefreshRoute()"></shared-route-division>

                <shared-route-childofdivision *ngIf="selectedOptionId == 'ADDCHILDOFDIVISION'" [viewmode]="'add'"
                        [route]="route" (refreshRoute)="onRefreshRoute()">
                </shared-route-childofdivision>
                <shared-route-childofdivision *ngIf="selectedOptionId == 'DELETECHILDOFDIVISION'" [viewmode]="'delete'"
                        [route]="route" (refreshRoute)="onRefreshRoute()">
                </shared-route-childofdivision>
                <shared-route-childofdivision *ngIf="selectedOptionId == 'COMPACT'" [viewmode]="'compact'"
                        [route]="route" (refreshRoute)="onRefreshRoute()">
                </shared-route-childofdivision>
                <shared-route-childofdivision *ngIf="selectedOptionId == 'DELIVERIES'" [viewmode]="'info'"
                        [route]="route" [isActiveRoutes]="'true'">
                </shared-route-childofdivision>

        </div>
</div>