import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { HColor } from '@shared/src/datatypes/HColor';
import { FilterDto } from './FilterDtos';
import { FilterStripDto } from './FilterStripDtos';


@Component({
  selector: 'shared-filter',
  templateUrl: './shared.filter.component.html',
  styleUrls: ['./shared.filter.component.scss']
})
export class SharedFilterComponent {

  public useIonic: boolean = false;
  constructor(@Inject('HomeController') protected homecontroller: HomeController,
    protected translateService: TranslateService) {
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Output() change = new EventEmitter<FilterDto>();

  private __filterStrip: FilterStripDto;
  @Input()
  public set filterstrip(value: FilterStripDto) {
    if (this.__filterStrip === value)
      return;

    this.__filterStrip = value;

    this.loadFilterStrip();
  }
  public get filterstrip(): FilterStripDto {
    return this.__filterStrip;
  }

  
  public list: Array<FilterDto>;
  private loadFilterStrip() {
    this.list = new Array();
    if (this.__filterStrip == null || this.__filterStrip.list == null) {
      return;
    }

    this.__filterStrip.list.forEach(element => {
      if(!this.showonlyactives || element.filterActive)
        this.list.push(element);
    });
  }

  @Input()
  public externalClass: string = "";

  @Input()
  public applyColor: boolean = false;

  @Input()
  public readonly: boolean = false;

  @Input()
  public showonlyactives: boolean = false;

  public getCaption(value: FilterDto) {
    return value.filterCaption;
  }

  public getClass(value: FilterDto) {
    let result = value.filterClass;
    if (value.filterActive)
      result += " active";
    return result;
  }

  public getStyle(value: FilterDto) {
    if (!this.applyColor)
      return {};

    let bcolor = HColor.getColorFromStringTable(this.translateService.instant(value.filterCaption), this.homecontroller);
    return {
      "color": HColor.getWhiteBlackFromColor(bcolor),
      "background-color": bcolor//,
      //"opacity": value.filterActive ? 1 : 0.5
    };
  }

  click(value: FilterDto) {
    if(this.readonly)
      return;

    if (value != null && this.__filterStrip != null && this.__filterStrip.toggle)
      this.__filterStrip.toggle(value);

    this.change.next(value);
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }
}
