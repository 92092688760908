<div class="encolumnat">
    <h1 class="titol">{{ 'REGISTERCOMPONENT.DRIVERS.TITLE' | translate }}</h1>
    <h2 class="texte" [innerHTML]="'REGISTERCOMPONENT.DRIVERS.SUBTITLE' | translate"></h2>

    <shared-partner-detail [linkType]="linkType" [(formGroup)]="registerFormGroup" [(link)]="newLink"></shared-partner-detail>
    <div class="counter">{{ 'REGISTERCOMPONENT.ADDED_DRIVERS_COUNTER.LABEL' | translate: {value: countlinks } }}</div>

    <div class="button-zone">
        <button mat-flat-button color="primary" class="add plus" (click)="add()" [disabled]="registerFormGroup.invalid || pendigResponse">
            <i class="fa fa-plus"></i><span class="caption">{{'REGISTERCOMPONENT.ADDBUTTON.LABEL' | translate}}</span>
        </button>
        <button mat-flat-button color="primary" class="seguent action" (click)="next()" [disabled]="pendigResponse">{{'REGISTERCOMPONENT.NEXTBUTTON.LABEL' | translate}}</button>
    </div>
</div>
