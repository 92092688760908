<!-- shared.chat.message.html !-->
<div *ngIf="value.isFirstAtDate" class="chatmessage-date">
   <span class="value" *ngIf="isToday(value.sentAtDate)">{{'CHATS.TODAY'| translate}}</span>
   <span class="value" *ngIf="!isToday(value.sentAtDate) && !msgowneryou">{{value.sentAtDate | date}}</span>
   <span class="value" *ngIf="!isToday(value.sentAtDate) && msgowneryou">{{value.sentAtDate | date}}</span>
</div>
<div class="col-sm-12 chatmessage">
   <div class="chatmessage-other" [ngClass]="{'show' : !msgowneryou}">
      <div *ngIf="showAnnotation" class="chatparticipant-other">
         <div *ngIf="value.fromUser != null" class="chatmessage-description">{{value.fromUser.description}}
         </div>
         <div *ngIf="value.fromUser != null" id="clickParticipant" class="chatmessage-annotation link linkMessage"
            (click)="onClickParticipant(value.fromUser)">
            {{value.fromUser.participantAnnotation}}
         </div>
         <span class="chatmessage-hour" *ngIf="value.sentAtHour">{{value.sentAt | datehournosecondsnotzobserver}}</span>
      </div>
      <div class="message">
         {{value.message}}
      </div>
      <span class="chatmessage-hour" *ngIf="value.sentAtHour">{{value.sentAtHour | hour}}</span>
   </div>
   <div class="chatmessage-you"[ngClass]="{'show' : msgowneryou}">
      <span class="chatmessage-message">{{value.message}}</span>

      <span *ngIf="!useIonic && !deleted && !value.received && !value.read && !value.waiting && !value.lost"
         class="chatmessage-noread-icon material-icons">done</span>
      <span *ngIf="!useIonic && !deleted && value.received && !value.read && !value.waiting && !value.lost"
         class="chatmessage-noread-icon material-icons">done_all</span>
      <span *ngIf="!useIonic && !deleted && value.received && value.read && !value.waiting && !value.lost"
         class="chatmessage-read-icon material-icons">done_all</span>
      <span *ngIf="!useIonic && !deleted && !value.received && !value.read && value.waiting && !value.lost"
         class="chatmessage-noread-icon material-icons">schedule</span>
      <span *ngIf="!useIonic && !deleted && !value.received && !value.read && value.lost"
         class="chatmessage-noread-icon material-icons">clear</span>

      <ion-icon *ngIf="useIonic && !deleted && !value.received && !value.read && !value.waiting && !value.lost"
         class="chatmessage-noread-icon" name="checkmark-outline"></ion-icon>
      <ion-icon *ngIf="useIonic && !deleted && value.received && !value.read && !value.waiting && !value.lost"
         class="chatmessage-noread-icon" name="checkmark-done-outline"></ion-icon>
      <ion-icon *ngIf="useIonic && !deleted && value.received && value.read && !value.waiting && !value.lost"
         class="chatmessage-read-icon" name="checkmark-done-outline"></ion-icon>
      <ion-icon *ngIf="useIonic && !deleted && !value.received && !value.read && value.waiting && !value.lost"
         class="chatmessage-noread-icon" name="time-outline"></ion-icon>
      <ion-icon *ngIf="useIonic && !deleted && !value.received && !value.read && value.lost"
         class="chatmessage-noread-icon" name="close-outline"></ion-icon>
      <!-- {{'waiting ' + value.waiting + ', lost ' + value.lost}} -->

      <!-- Per a carregar les icones i que puguin ser utilitzades offline -->
      <ion-icon *ngIf="useIonic" hidden name="time-outline"></ion-icon>
      <ion-icon *ngIf="useIonic" hidden name="close-outline"></ion-icon>

      <span *ngIf="deleted" (click)="doDelete()" class="chatmessage-delete-icon"><i class=" fa fa-trash"
            aria-hidden="true"></i></span>

      <span *ngIf="value.sent" class="chatmessage-you-hour">{{value.sentAtHour | hour}}</span>
   </div>
</div>