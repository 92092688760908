import { Component, Input, OnInit } from '@angular/core';
import { AddressController } from '@shared/src/controllers/address/address.controller';
import { AddressMaintenanceController } from '@shared/src/controllers/maintenance/address/AddressMaintenanceController';
import { AddressDto } from '@shared/src/controllers/maps/AddressDto';
import { HLong } from '@shared/src/datatypes/HLong';
import { HString } from '../../datatypes/HString';
import { MapService, MapPuntDto, MapMarcadorDto } from '@shared/src/services/map/map.service';
import { MapsController } from '../../controllers/maps/maps.controller';

@Component({
  selector: 'shared-map-address-component',
  templateUrl: './shared.map.address.component.html',
  styleUrls: ['./shared.map.address.component.scss']
})

/***
 * Es el componentº que encapsula la visualització d'una adreça
 * origen i destí son dos objectes respectivament del tipus {longitude:2.23432, latitude:2.34323}
 */
export class SharedMapAddressComponent implements OnInit {

  constructor(private addressController: AddressController, private mapsController: MapsController) {
  }

  ngOnInit(): void {
  }

  private _punt: MapPuntDto;
  @Input()
  public set punt(value: MapPuntDto) {
    if (this._punt === value)
      return;
    this._punt = value;
    this.calculate();
  }
  public get punt() {
    return this._punt;
  }

  private _addressId: number;
  @Input()
  public set addressId(value: number) {
    if (this._addressId === value)
      return;
    this._addressId = value;
    this.resolveAddress();
  }
  public get addressId() {
    return this._addressId;
  }

  public address: AddressDto;
  private resolveAddress() {
    if (!HLong.isNullOrNullLong(this._addressId)) {
      this.addressController.getByAddressId(this._addressId).subscribe(value => {
        this.address = value;
        if (value == null)
          this.punt = null;
        else {
          this.punt = MapPuntDto.builder(value.longitude, value.latitude);
          this._formattedAddress = value.formattedAddress;
        }
      });
    }
  }

  private _formattedAddress: string;
  @Input()
  public set formattedAddress(value: string) {
    if (this._formattedAddress === value)
      return;
    this._formattedAddress = value;
    this.resolveFormattedAddress();
  }
  public get formattedAddress() {
    return this._formattedAddress;
  }

  private _forcedLongitude: number;
  @Input()
  public set forcedLongitude(value: number) {
    if (this._forcedLongitude === value)
      return;
    this._forcedLongitude = value;
    this.resolveFormattedAddress();
  }
  public get forcedLongitude() {
    return this._forcedLongitude;
  }
  private _forcedLatitude: number;
  @Input()
  public set forcedLatitude(value: number) {
    if (this._forcedLatitude === value)
      return;
    this._forcedLatitude = value;
    this.resolveFormattedAddress();
  }
  public get forcedLatitude() {
    return this._forcedLatitude;
  }

  private resolveFormattedAddress() {
    if (!HString.isNullOrNullString(this._formattedAddress)) {
      if (this.forcedLongitude != -1 || this.forcedLatitude != -1)
        this.punt = MapPuntDto.builder(this.forcedLongitude, this.forcedLatitude);
      else
        this.mapsController.resolveaddress(this._formattedAddress).subscribe(value => {
          if (value == null)
            this.punt = null;
          else {
            this.punt = MapPuntDto.builder(value.longitude, value.latitude);
          }
        });
    }
  }


  public zoom: number = MapService.DEFAULT_GOOGLEMAPS_ADDRESS_ZOOM;
  public longitude: number = MapService.DEFAULT_LONGITUDE;
  public latitude: number = MapService.DEFAULT_LATITUDE;
  public marcadors: Array<MapMarcadorDto> = new Array<MapMarcadorDto>();
  private calculate() {
    if (this._punt == null) {
      this.longitude = MapService.DEFAULT_LONGITUDE;
      this.latitude = MapService.DEFAULT_LATITUDE;
      this.marcadors = new Array<MapMarcadorDto>();
      return;
    }

    this.longitude = this._punt.longitude;
    this.latitude = this._punt.latitude;
    this.marcadors.push(MapMarcadorDto.builder(this._punt.longitude, this._punt.latitude).adreça());
  }

  public pretty(value: any): string {
    return JSON.stringify(value);
  }


}
