import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Validator, ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { HTime } from '@shared/src/datatypes/HTime';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { TimeValidator } from '../../form/validations/TimeValidator';
import { HomeController } from '@shared/src/controllers/home/home.controller';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TimePickerComponent, multi: true }]
})

export class TimePickerComponent extends ValueAccessorValidatedBase<string, HTime> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormTime = this.fb.group({
      'timevalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return TimeValidator.isValid(value);
  }
  showClearButton(): boolean {
    if (this.readonly)
      return false;

    if (!this.value)
      return false;

    if (this.value == "00:00")
      return false;

    return true;
  }


  public rFormTime: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }


  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Input() public showHelpPoint: boolean = false;
  @Input() public helpresource: string = "";
  @Output() valueChanged: EventEmitter<HTime> = new EventEmitter();

  private _max: string;
  @Input() set max(selectedvalue: HTime) {
    this._max = HTime.getTimeString(selectedvalue);
  }
  get max(): HTime {
    return HTime.build(this._max);
  }

  private _min: string;
  @Input() set min(selectedvalue: HTime) {
    this._min = HTime.getTimeString(selectedvalue);
  }
  get min(): HTime {
    return HTime.build(this._min);
  }

  public identifier = 'time-picker-' + identifier++;

  externalValue(value: string): HTime {
    if (!value)
      return HTime.empty();
    let htime: HTime = HTime.build(value);
    return htime;
  }
  internalValue(value: HTime): string {

    let htime: HTime = HTime.build(value);

    return HTime.getTimeString(htime);
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }

  getClass() {
    if (this.readonly)
      return "readonly";
    return "";
  }

  keyEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;
