import { HardmanRegisterSteps } from "@shared/src/enums/HardmanRegisterSteps"
import { SituacionsEnLesQueEncaixes } from "@shared/src/enums/SituacionsEnLesQueEncaixes"
import { TenantProductDto } from "../tenants/TenantProductDto"
import { RegOrganizationDto } from "./RegOrganizationDto"
import { UserRegisterDto } from "./UserRegisterDto"
import { Injectable } from "@angular/core";
import { Platforms } from '@shared/src/services/platform/Platforms';
import { Solutions } from '@shared/src/services/platform/Solutions';
import { OperatingSystems } from '@shared/src/services/platform/OperatingSystems';
import { Languages } from '@shared/src/enums/Languages';
import { PartnerPendingDto } from '../link/detail/PartnerPendingDto';
import { PartnerDetailDto } from "../link/detail/PartnerDetailDto"
import { TimeZones } from "@shared/src/enums/TimeZones"

@Injectable()
export class UserRegisterWizardDto {
    public token: string;
    public platform: Platforms;
    public solution: Solutions;
    public operatingSystem: OperatingSystems;
    public userId: number;
    public deviceToken: string;
    public geolocationMode: string;
    public joinlinkid: number;
    public registerbyphone: boolean;
    public language: Languages;
    public timeZone: TimeZones;

    public hardmanEntornGeneralClass: string;

    public tenantId: number;
    public userregister: UserRegisterDto = new UserRegisterDto();
    public organization: RegOrganizationDto = new RegOrganizationDto();
    public products: Array<TenantProductDto>;

    public partnerPendings: Array<PartnerPendingDto>;
    public partnerCompanys: Array<PartnerDetailDto>;
    public partnerDrivers: Array<PartnerDetailDto>;

    public situacioEnLaQueEncaixes: SituacionsEnLesQueEncaixes;
    public isDriver: boolean;
    public result: boolean;
    public addconditions: boolean;
    public addorganization: boolean;
    public addproducts: boolean;
    public pas: HardmanRegisterSteps;
    public tornarA: HardmanRegisterSteps;

    public newPartnerCompany: PartnerDetailDto;
    public newPartnerDriver: PartnerDetailDto;
}
