import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/public-api';



/** Control value ha d'estar dins de enumvalues */
export function enumValidator(enumvalues: any, required: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return validate(control, enumvalues, required);
  };
};

export function enumValidatorTranslate(enumvalues: any, translategroup: String, translatesufix: string, translate: TranslateService, required: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return validateTranslate(control, enumvalues, translategroup, translatesufix, translate, required);
  };
};
export function enumValidatorNoTranslate(enumvalues: any, translategroup: String, translatesufix: string, translate: TranslateService, required: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    return validateNOTranslate(control, enumvalues, required);
  };
};

function getInvalidResult(): { [key: string]: any } {
  return {
    validateEnum: false
  }
};

function validate(control: AbstractControl, listitems: any, required: boolean): ValidationErrors | null {
  if (!listitems || !control)
    return getInvalidResult();

  let value = control.value;
  if (!value) {
    if (required)
      return getInvalidResult();
    return null;
  }

  let exists: boolean = value in listitems;
  if (!exists)
    return getInvalidResult();
  return null;
};

function validateNOTranslate(control: AbstractControl, listitems: any, required: boolean): ValidationErrors | null {
  if (!listitems || !control)
    return getInvalidResult();

  let value = control.value;
  if (!value) {
    if (required)
      return getInvalidResult();
    return null;
  }
  //Object.values(listitems)
  //Object.keys(listitems)
  for (var enumMember in listitems)
    if (listitems[enumMember] == value)
      return null;
  return getInvalidResult();
};

function validateTranslate(control: AbstractControl, listitems: any, translategroup: String, translatesufix: string, translate: TranslateService, required: boolean): ValidationErrors | null {
  if (!listitems || !control)
    return getInvalidResult();

  let value = control.value;
  if (!value) {
    if (required)
      return getInvalidResult();
    return null;
  }

  let sufix: string = "";
      if(!HString.isNullOrNullString(translatesufix))
        sufix += "." + translatesufix;

  let exists: boolean = false;
  let translates = [];
  for (var enumMember in listitems) {
    let trans = translate.instant(translategroup.toUpperCase() + "." + enumMember.toString().toUpperCase() + sufix);
    translates.push(trans);
    if (trans == value)
      exists = true;
  }

  if (!exists) {
    exists = value in listitems;
    if (!exists)
      return getInvalidResult();
  }

  return null;
};
