<div *ngIf="value" class="container" [ngClass]="{'draggable' : isValueDraggable}"
    (dragstart)="onDragStart($event, value)" (dragenter)="onDragEnter($event, value)"
    (dragleave)="onDragLeave($event, value)" [draggable]="true">
    <div class="patina" [ngStyle]="getColumnStyle()"></div>

    <div class="title top">
        {{ value.columnHeadingItem }}
    </div>
    <shared-route-item class="card white" *ngIf="value.panelType=='Route' && value.routeCardDTO != null"
        [route]="value.routeCardDTO"></shared-route-item>

    <shared-phase-slot-planning class="card white"
        *ngIf="value.panelType=='Phase' && value.routePhaseDTO != null"
        [disableinvokephase]="true" [canalterelement]="false" [phaseslot]="value.routePhaseDTO">
    </shared-phase-slot-planning>

    <shared-shipment-item class="card white"
        *ngIf="value.panelType=='Shipment' && value.shipmentCardDTO != null"  [shipment]="value.shipmentCardDTO">
    </shared-shipment-item>

    <div class="title right">
        {{ value.columnHeadingItem }}
    </div>
</div>