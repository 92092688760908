<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="route">
    <div class="title">
        {{'SHARED.ROUTE.CHANGESTATUS.COMPONENT.TITLE' | translate}}
    </div>
    <div class="block-help">
        {{'SHARED.ROUTE.CHANGESTATUS.COMPONENT.HELP' | translate}}
    </div>

    <div class="status-container" *ngIf="!fromActiveRoutes && states">
        <shared-state [ngClass]="{'reduce':route.showUserGeneralStatus}" [(value)]="states"
            (stateChange)="onSelectorChangedState($event)" [readonly]="readonly"></shared-state>
    </div>

    <div class="block-help" [ngClass]="{'margintop': !fromActiveRoutes}" *ngIf="route.routePermission == 'Owner'">
        {{'SHARED.ROUTE.CHANGESTATUS.COMPONENT.HELP2' | translate}}
    </div>

    <div class="status-deep-container" *ngIf="route.routePermission == 'Owner'">
        <button-link class="line left" (linkClick)="onSetSuperStatus(promised)">
            <div class="status color generalstatus promised"></div>
            {{ 'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.SUPERSET.PROMISED.CAPTION' | translate}}
        </button-link>
        <button-link class="line left" (linkClick)="onSetSuperStatus(canceled)">
            <div class="status color generalstatus canceled"></div>
            {{ 'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.SUPERSET.CANCELED.CAPTION' | translate}}
        </button-link>
        <button-link class="line left" (linkClick)="onSetSuperStatus(done)">
            <div class="status color generalstatus done"></div>
            {{ 'SHARED.ACTIVE.SLOTS.ITEM.CHANGESTATUS.SUPERSET.DONE.CAPTION' | translate}}
        </button-link>
    </div>
</div>