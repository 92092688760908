import { Moment } from 'moment';
import * as moment_ from 'moment-timezone';
import { HString } from './HString';
import { HDateHour } from '../public-api';
const moment = moment_;

export class HDate {

    public year: number;
    public month: number;
    public dayOfMonth: number;

    public static NULLDATE: HDate = HDate.buildas(4000, 12, 31);

    public HDate() {

    }

    public static nullDate(): HDate {
        let hdate = new HDate();
        hdate.year = 4000;
        hdate.month = 12;
        hdate.dayOfMonth = 31;
        return hdate;
    }

    public static today(): HDate {
        let today = new Date();
        return this.build(today);
    }

    public static getFirstDayOfMonth(value: HDate): HDate {
        if (HDate.isNullOrNullValue(value))
            return HDate.nullDate();
        return HDate.buildas(value.year, value.month, 1);
    }
    public static getLastDayOfMonth(value: HDate): HDate {
        if (HDate.isNullOrNullValue(value))
            return HDate.nullDate();

        let date = HDate.getDate(value);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return HDate.build(lastDay);
    }

    public static getFirstDayOfYear(value: HDate): HDate {
        return HDate.buildas(value.year, 1, 1);
    }
    public static getLastDayOfYear(value: HDate): HDate {
        return HDate.buildas(value.year, 12, 31);
    }

    public static build(value: Date): HDate {
        if (!value)
            return HDate.nullDate();

        let hdate = new HDate();
        hdate.year = value.getFullYear();
        hdate.month = value.getMonth() + 1;
        hdate.dayOfMonth = value.getDate();
        return hdate;
    }

    public static buildFromHDate(value: HDate): HDate {
        if (HDate.isNullOrNullValue(value))
            return HDate.nullDate();

        let hdate = new HDate();
        hdate.year = value.year;
        hdate.month = value.month;
        hdate.dayOfMonth = value.dayOfMonth;
        return hdate;
    }

    public static buildFromMoment(value: Moment): HDate {
        if (!value)
            return HDate.nullDate();

        let hdate = new HDate();
        if (value instanceof Date)
            hdate = HDate.build(value);
        else if (moment.isMoment(value)) {
            hdate.year = value.year();
            hdate.month = value.month() + 1;
            hdate.dayOfMonth = value.date();
        }

        return hdate;
    }

    public static buildas(year: number, month: number, day: number): HDate {
        let result: HDate = new HDate();
        result.year = year;
        result.month = month;
        result.dayOfMonth = day;
        return result;
    }

    public static buildbydate(value: HDate): HDate {
        if (this.isNullOrNullValue(value))
            return this.nullDate();
        let result: HDate = new HDate();
        result.year = value.year;
        result.month = value.month;
        result.dayOfMonth = value.dayOfMonth;
        return result;
    }

    public static isHDate(value: HDate): boolean {
        if (value == null)
            return false;
        if (value.year != undefined && value.month != undefined && value.dayOfMonth != undefined)
            return true;
        return false;
    }

    public static compare(a: HDate, b: HDate) {
        if (this.isGreaterThan(a, b))
            return 1;
        if (this.isMinorThan(a, b))
            return -1;
        return 0;
    }

    public static getMoment(value: HDate): Moment {
        if (value == null)
            value = HDate.nullDate();

        let date = new Date(value.year, value.month - 1, value.dayOfMonth);
        let datemoment: Moment = moment.utc();
        return datemoment.year(value.year).month(value.month - 1).date(value.dayOfMonth).hour(0).minute(0).second(0).millisecond(0);
    }

    public static getDate(value: HDate): Date {
        if (value == null)
            value = HDate.nullDate();

        let date = new Date(value.year, value.month - 1, value.dayOfMonth);
        return date;
    }

    public addDays(days: number): HDate {
        let resultdate: Date = HDate.getDate(this);
        resultdate.setDate(resultdate.getDate() + parseInt(days + ""));
        let result = HDate.build(resultdate);
        return result;
    }

    public static addMonths(date: HDate, months: number): HDate {
        let resultdate: Date = HDate.getDate(date);
        resultdate.setMonth(resultdate.getMonth() + parseInt(months + ""));
        let result = HDate.build(resultdate);
        return result;

    }
    public addMonths(months: number): HDate {
        return HDate.addMonths(this, months);
    }
    public addYears(years: number): HDate {
        let resultdate: Date = HDate.getDate(this);
        resultdate.setFullYear(resultdate.getFullYear() + parseInt(years + ""));
        let result = HDate.build(resultdate);
        return result;
    }

    public static addDaysToHDate(ondate: HDate, numdays: number): HDate {
        let resultdate: Date = HDate.getDate(ondate);
        resultdate.setDate(resultdate.getDate() + parseInt(numdays + ""));
        let result = HDate.build(resultdate);
        return result;
    }



    public static isNullOrNullValue(value: HDate): Boolean {
        if (!value)
            return true;

        if (!value.dayOfMonth || !value.month || !value.year)
            return true;

        if (value.dayOfMonth == this.NULLDATE.dayOfMonth && value.month == this.NULLDATE.month && value.year == this.NULLDATE.year)
            return true;

        return false;
    }

    public static equals(a: HDate, b: HDate): boolean {
        if (a == b)
            return true;


        let aisnull: Boolean = this.isNullOrNullValue(a);
        let bisnull: Boolean = this.isNullOrNullValue(b);

        if (a == b)
            return true;
        if (aisnull || bisnull)
            return false;

        if (a.dayOfMonth == b.dayOfMonth && a.month == b.month && a.year == b.year)
            return true;

        return false;
    }

    public static getDateString(value: HDate): string {
        if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
            return "---";
        let result: string;

        result = HDate.pad(value.dayOfMonth, 2) + "/" + HDate.pad(value.month, 2) + "/" + value.year;
        return result;
    }

    public static getDateStringSorteable(value: HDate): string {
        if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
            return "9999/99/99";
        let result: string;

        result = HDate.pad(value.year, 4) + "/" + HDate.pad(value.month, 2) + "/" + HDate.pad(value.dayOfMonth, 2);
        return result;
    }

    public static fromStringDate(value: string): HDate {
        if (HString.isNullOrNullString(value))
            return HDate.NULLDATE;

        let sdate: string[] = value.split("/");
        let day = Number(sdate[0]);
        let month = Number(sdate[1]);
        let year = Number(sdate[2]);

        let result: HDate = HDate.buildFromParams(year, month, day);

        return result;
    }


    // Rep per parametre un objecte HDate i el retorna en format YYYY-MM-DD
    public static getDateStringRequest(value: HDate): string {
        if (value == null || value.year == 4000 && value.month == 12 && value.dayOfMonth == 31)
            return "";
        let result: string;

        result = HDate.pad(value.year, 4) + "-" + HDate.pad(value.month, 2) + "-" + HDate.pad(value.dayOfMonth, 2);
        return result;
    }

    public static pad(num, size): string {
        var s = "000000000" + num;
        return s.substr(s.length - size);
    }

    public static isValidDate(value: HDate): boolean {
        if (!value)
            return false;

        let d: string = value.dayOfMonth.toString();
        let m: string = value.month.toString();
        let y: string = value.year.toString();

        if (d.length > 2 || value.dayOfMonth > 31 || value.dayOfMonth < 1)
            return false;
        if (m.length > 2 || value.month > 12 || value.month < 1)
            return false;
        if (y.length != 4)
            return false;

        return true;
    }


    /**
     * Retorna true cuando valuea es mayor que valueb
     * @param valuea 
     * @param valueb 
     */
    public static greater(valuea: HDate, valueb: HDate): boolean {
        if (valuea.year > valueb.year)
            return true;
        else if (valuea.year < valueb.year)
            return false;
        if (valuea.month > valueb.month)
            return true;
        else if (valuea.month < valueb.month)
            return false;
        if (valuea.dayOfMonth > valueb.dayOfMonth)
            return true;

        return false;
    }
    /**
     * Funció que calcula la distancia entre dues dates. Entre avui i avui donarà una distància de 0, entre avui i demà una distància de 1 i entre avui i demà passat una distància de 2.
     * Si es posen al revés dona el valor en negatiu.
     * @param valuea 
     * @param valueb 
     */
    public static distance(valuea: HDate, valueb: HDate): number {
        if (HDate.isNullOrNullValue(valuea) || HDate.isNullOrNullValue(valueb)) {
            return 0;
        }

        let changed = HDate.greater(valuea, valueb);
        //Assegurem que b sempre és el gran
        let internala = changed ? valueb : valuea;
        let internalb = changed ? valuea : valueb;

        var days = this.daysBetween(internala, internalb);

        // Round down.
        var result = Math.floor(days);


        if (changed)
            result = (-1) * result;

        // Comento això ja que no ho considero un error, podem voler buscar si la distància és positiva o negativa
        // if (result < 0)
        //     console.error("Atenció el distance no està bé " + result);


        return result;
    }

    public static daysBetween(startDate: HDate, endDate: HDate) {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (this.treatAsUTC(endDate) - this.treatAsUTC(startDate)) / millisecondsPerDay;
    }

    public static treatAsUTC(date: HDate): any {
        var result = new Date(date.year, date.month - 1, date.dayOfMonth, 0, 0, 0);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }

    public toString(): string {
        return HDate.getDateString(this);
    }
    public toStringSorteable(): string {
        return HDate.getDateStringSorteable(this);
    }
    public toStringRequest(): string {
        return HDate.getDateStringRequest(this);
    }

    public static getDayOfWeek(value: HDate): string {
        if (value == null || HDate.isNullOrNullValue(value))
            return "WEEKDAY.NONE";
        switch (HDate.getDate(value).getDay()) {
            case 1:
                return "WEEKDAY.1";
            case 2:
                return "WEEKDAY.2";
            case 3:
                return "WEEKDAY.3";
            case 4:
                return "WEEKDAY.4";
            case 5:
                return "WEEKDAY.5";
            case 6:
                return "WEEKDAY.6";
            case 0:
                return "WEEKDAY.7";
        }
    }
    public static getMontOfYear(value: HDate): string {
        if (value == null || HDate.isNullOrNullValue(value))
            return "MONTHYEAR.NONE";
        switch (HDate.getDate(value).getDay()) {
            case 1:
                return "MONTHYEAR.1";
            case 2:
                return "MONTHYEAR.2";
            case 3:
                return "MONTHYEAR.3";
            case 4:
                return "MONTHYEAR.4";
            case 5:
                return "MONTHYEAR.5";
            case 6:
                return "MONTHYEAR.6";
            case 7:
                return "MONTHYEAR.7";
            case 8:
                return "MONTHYEAR.8";
            case 9:
                return "MONTHYEAR.9";
            case 10:
                return "MONTHYEAR.10";
            case 11:
                return "MONTHYEAR.11";
            case 12:
                return "MONTHYEAR.12";
        }
    }
    public static getDayOfWeekNumber(value: HDate): number {
        if (value == null || HDate.isNullOrNullValue(value))
            return 0;
        return HDate.getDate(value).getDay();
    }
    public static getDayOfWeekNumberPerDesplaçar(value: HDate): number {
        if (value == null || HDate.isNullOrNullValue(value))
            return 0;
        let result = HDate.getDate(value).getDay();
        if (result == 0)
            result = 7;
        return result - 1;
    }


    public static getWeekNumber(value: HDate): number {
        var d = HDate.getDate(value);
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    public static getDayClass(value: HDate): string {
        if (value == null || HDate.isNullOrNullValue(value))
            return "";
        let result = "";

        let tinternal = value == null ? HDate.NULLDATE : value;
        let day = HDate.getDayOfWeekNumber(value);
        switch (day) {
            case 1:
                result = "monday";
                break;
            case 2:
                result = "tuesday";
                break;
            case 3:
                result = "wednesday";
                break;
            case 4:
                result = "thursday";
                break;
            case 5:
                result = "friday";
                break;
            case 6:
                result = "saturday";
                break;
            case 0:
                result = "sunday";
                break;
        }
        if (HDate.isLastMonthDay(value))
            result += " lastmonthday";
        return result;
    }

    public static isLastMonthDay(value: HDate): boolean {
        if (HDate.isNullOrNullValue(value))
            return false;
        if (value.month != HDate.addDaysToHDate(value, 1).month)
            return true;
        return false;
    }
    /**
     * Retorna true quan a es una data válida i és més petita que b
     * @param a 
     * @param b 
     */
    public static isMinorThan(a: HDate, b: HDate): boolean {
        if (HDate.isNullOrNullValue(a) && HDate.isNullOrNullValue(b))
            return false;
        if (HDate.isNullOrNullValue(a))
            return false;
        if (HDate.isNullOrNullValue(b))
            return true;
        if (b.year > a.year)
            return true;
        if (b.year == a.year && b.month > a.month)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth > a.dayOfMonth)
            return true;
        return false;
    }
    /**
     * Retorna true quan a es una data válida i és més petita o igual que b
     * @param a 
     * @param b 
     */
    public static isMinorEqualsThan(a: HDate, b: HDate): boolean {
        if (HDate.isMinorThan(a, b) || HDate.equals(a, b))
            return true;
        return false;
    }

    /**
 * Retorna true quan a es una data válida i és més gran que b
 * @param a 
 * @param b 
 */
    public static isGreaterThan(a: HDate, b: HDate): boolean {
        if (HDate.isNullOrNullValue(a) && HDate.isNullOrNullValue(b))
            return false;
        if (HDate.isNullOrNullValue(a))
            return true;
        if (HDate.isNullOrNullValue(b))
            return false;
        if (b.year < a.year)
            return true;
        if (b.year == a.year && b.month < a.month)
            return true;
        if (b.year == a.year && b.month == a.month && b.dayOfMonth < a.dayOfMonth)
            return true;
        return false;
    }

    /**
* Retorna true quan a es una data válida i és més gran que b o igual
* @param a 
* @param b 
*/
    public static isGreaterEqualsThan(a: HDate, b: HDate): boolean {
        if (HDate.isGreaterThan(a, b))
            return true;
        if (HDate.equals(a, b))
            return true;
        return false;
    }

    /**
     * Retorna true cuando la fecha ondate está dentro del periodo definido por periodstart, periodend
     * @param ondate 
     * @param periodstart 
     * @param periodend 
     */
    public static inForce(ondate: HDate, periodstart: HDate, periodend: HDate): boolean {
        if (ondate == null || periodstart == null || periodend == null)
            return false;
        if (HDate.isNullOrNullValue(ondate) && !HDate.isNullOrNullValue(periodend))
            return false;
        if (HDate.isGreaterThan(periodstart, periodend))
            return false;

        if (HDate.isMinorThan(periodstart, ondate) || HDate.equals(periodstart, ondate))
            if (HDate.isGreaterThan(periodend, ondate) || HDate.equals(periodend, ondate))
                return true;

        return false;
    }

    public static inForcePeriods(datefromA: HDate, datetoA: HDate, datefromB: HDate, datetoB: HDate): boolean {

        if (HDate.isNullOrNullValue(datetoB)
            || (!HDate.isNullOrNullValue(datetoB) && !HDate.isNullOrNullValue(datefromA) && HDate.isMinorEqualsThan(datefromA, datetoB)))
            if (HDate.isNullOrNullValue(datetoA)
                || (!HDate.isNullOrNullValue(datetoA) && !HDate.isNullOrNullValue(datefromB) && HDate.isMinorEqualsThan(datefromB, datetoA)))
                return true;
        return false;

    }


    public static minDate(a: HDate, b: HDate): HDate {
        if (HDate.isMinorEqualsThan(a, b))
            return a;
        return b;
    }

    public static maxDate(a: HDate, b: HDate): HDate {
        if (HDate.isGreaterEqualsThan(a, b))
            return a;
        return b;
    }

    /**
     * Estpa pensada per quan rebem coses del backend i no tenen les funcions, amb el cloneFrom et fas una copia "neta"
     * @param param 
     */
    public static cloneFrom(param: HDate): HDate {
        if (param == null)
            return null;
        return HDate.buildas(param.year, param.month, param.dayOfMonth);
    }


    /**
     * Valida que un periode és correcte interpretant que cap de les dues dates pot ser nula o sense periode. Per exemple per demanar un llistat, etc  (amb data de fi obligatoria)
     * @param dateFrom 
     * @param dateTo 
     */
    public static isValidPeriodNotNullAllowed(dateFrom: HDate, dateTo: HDate) {
        if (HDate.isNullOrNullValue(dateFrom))
            return false;

        if (HDate.isNullOrNullValue(dateTo))
            return false;

        if (!HDate.isGreaterEqualsThan(dateTo, dateFrom))
            return false;

        return true;
    }

    public static toGetString(value: HDate): string {
        if (HDate.isNullOrNullValue(value))
            return "-";
        return HString.pad(value.year, 4) + HString.pad(value.month, 2) + HString.pad(value.dayOfMonth, 2);
    }
    public static toQueryParamString(value: HDate): string {
        if (HDate.isNullOrNullValue(value))
            return "-";
        return HString.pad(value.year, 4) + HString.pad(value.month, 2) + HString.pad(value.dayOfMonth, 2);
    }

    public static fromQueryParamString(value: string): HDate {
        if (HString.isNullOrNullString(value) || value === "-")
            return HDate.today();

        return HDate.buildFromParams(parseInt(value.substring(0, 4), 10), parseInt(value.substring(4, 6), 10), parseInt(value.substring(6, 8), 10));

    }
    static buildFromParams(year: number, month: number, dayOfMonth: number): HDate {
        return HDate.buildas(year, month, dayOfMonth);
    }


    public static getHDate(value: HDateHour): HDate {
        if (value == null)
            return HDate.NULLDATE;
        let result = new HDate();
        result.year = value.year;
        result.month = value.month;
        result.dayOfMonth = value.dayOfMonth;
        return result;
    }

    public static daysInInterval(date1: HDate, date2: HDate): number {
        const days: number = HDate.operatorMinus(date1, date2);
        return Math.abs(days) + 1;
    }

    public static toDate(value: HDate): Date {
        return new Date(value.year, value.month - 1, value.dayOfMonth); // Mesos a JavaScript són zero-indexats
    }

    public static operatorMinus(a: HDate | null, b: HDate | null): number {
        if (HDate.isNullOrNullValue(a) || HDate.isNullOrNullValue(b)) {
            return 0;
        }

        const dateA = HDate.toDate(a as HDate);
        const dateB = HDate.toDate(b as HDate);

        return Math.floor((dateA.getTime() - dateB.getTime()) / (1000 * 60 * 60 * 24));
    }

}
