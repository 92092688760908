import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { HColor } from '@shared/src/datatypes/HColor';
import { HTag } from '@shared/src/datatypes/HTag';
import { HTags } from '@shared/src/datatypes/HTags';
import { AssignedSlotDto } from '@shared/src/dtos/address/slots/AssignedSlotDto';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { ChatsDto } from '@shared/src/dtos/chat/ChatsDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { GeneralStatus } from '@shared/src/public-api';


@Component({
    selector: 'shared-route-item',
    templateUrl: './shared.route.item.component.html',
    styleUrls: ['./shared.route.item.component.scss']
})
export class SharedRouteItemComponent implements OnInit {

    public MAX_TAGS_IN_LIST_ITEM: number = 8;
    public useIonic: boolean = false;
    constructor(@Inject('HomeController') protected homeController: HomeController) {
        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();

        this.homeController.chatsChanged$.subscribe((data: ChatsDto) => {
            if (data && data.chats)
                data.chats.forEach(e => {
                    if (e && this.route)
                        if (e.ownerParticipantType == ChatParticipantTypes.Route && e.ownerParticipantId == this.route.routeId) {
                            if (e.pendingMessagesRead != null)
                                this.route.pendingMessagesRead = e.pendingMessagesRead;
                        }
                });
        });
    }

    public refreshed: boolean = false;
    private _ultim: boolean;
    @Input()
    set ultim(value: boolean) {
        this._ultim = value;

        if (this._ultim && !this.refreshed) {
            this.homeController.chatsChanged$.next(this.homeController.CHATS);
            this.refreshed = true;
        }
    }
    get ultim(): boolean {
        return this._ultim;
    }

    @Input()
    public isActiveRoutes: boolean = false;

    private _route: RouteCardDto;
    @Input()
    set route(value: RouteCardDto) {
        if (this._route === value)
            return;
        this._route = value;
        this.setPendingChilds();
        this.obtainValues();
    }
    get route(): RouteCardDto {
        return this._route;
    }

    public slotInfo: SlotInfoDto;
    public hasChilds: boolean = false;
    showAssignedSlots: boolean;
    firstAssignedSlotDate: String;
    firstAssignedSlotInterval: string;
    tooltipAssignedSlots: string;
    private obtainValues() {
        if (this._route != null) {
            this.slotInfo = SlotInfoDto.buildFromRouteCardDto(this._route);

            this.allTags = this._route.allTags;

            if (this._route.childs != null && this._route.childs != undefined && this._route.childs.length > 0)
                this.hasChilds = true;
        }
        else {
            this.slotInfo = null;
            this.showAssignedSlots = false;
            this.firstAssignedSlotDate = null;
            this.firstAssignedSlotInterval = "";
            this.tooltipAssignedSlots = "";
        }
    }

    public pendingChildsCount: number;
    setPendingChilds() {
        let _pendingChildsCount = 0;
        if (this.route != null && this.route.childs != null) {
            this.route.childs.forEach(element => {
                if (element.generalStatus == GeneralStatus.promised || element.generalStatus == GeneralStatus.inprogress)
                    _pendingChildsCount++;
            });
        }
        this.pendingChildsCount = _pendingChildsCount;
    }

    @Output() clickShowChilds: EventEmitter<boolean> = new EventEmitter();
    onClickShowChilds(e) {
        this.route.showChilds = !this.route.showChilds;
        this.clickShowChilds.next(this.route.showChilds);
        e.preventDefault();
        e.stopPropagation();
    }

    @Input() selected: boolean;

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    public haveTags(tags: HTags): boolean {
        if (HTags.isEmpty(tags))
            return false;
        return true;
    }

    public allTags: HTags;

    ngOnInit(): void {
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }

    public getGeneralClass(): string {
        let result = "";
        if (this.selected)
            result += "selected ";
        result += HTags.convertToStringWithDelimiter(this.route.internalTags, " ");
        return result;
    }
}
