<div class="container component">

    <div class="header">
        <div class="buttonBack" (click)="onClickBack($event)">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <i class="fa fa-times" aria-hidden="true"></i>
        </div>
    </div>

    <div class="block" *ngIf="selectedRouteExpand != null" (dragover)="dragover($event)" (drop)="dropAtZone($event)">

        <shared-trucking-route-card class="routeItem" [route]="selectedRouteExpand"></shared-trucking-route-card>
           
        <div class="linia bar baroptions">
            <options class="slim mixed-text-icon" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
    
        <ng-container *ngIf="actualOptionId==='LOAD'">
            LOAD
            <shared-trucking-route-panel-load class="block-option" [selectedRouteExpand]="selectedRouteExpand">
            </shared-trucking-route-panel-load>
        </ng-container>
        <ng-container *ngIf="actualOptionId==='TRUCK'">
            TRUCK
        </ng-container>
        <ng-container *ngIf="actualOptionId==='ROUTE'">
            ROUTE
        </ng-container>
        <ng-container *ngIf="actualOptionId==='PARTNERS'">
            PARTNERS
        </ng-container>
        <ng-container *ngIf="actualOptionId==='DRIVER'">
            DRIVER
        </ng-container>
        <ng-container *ngIf="actualOptionId==='CHAT'">
            <shared-chat class="block-option" [forcedOwnerParticipant]="forcedOwnerParticipant" [notes]="true"
                (clickParticipant)="clickParticipant($event)">
            </shared-chat>
        </ng-container>
        <ng-container *ngIf="actualOptionId==='DOCUMENT'">
            DOCUMENT
        </ng-container>

    </div>

</div>