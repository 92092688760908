/*
 *ngFor="let c of oneDimArray | sortBy:'property'" ASC
 *ngFor="let c of oneDimArray | sortBy:'-property'" DESC
 *ngFor="let c of arrayOfObjects | sortBy:'property | -property'" ASC i DESC respectivament
*/
import { Pipe, PipeTransform } from '@angular/core';
import { RouteDocumentTypes } from '../enums/RouteDocumentTypes';
import { DocumentViewerTypeDto } from '../dtos/document/struct/DocumentViewerTypeDto';


@Pipe({ name: 'sortDocumentType' })
export class SortDocumentType implements PipeTransform {

    transform(array: Array<DocumentViewerTypeDto>): Array<DocumentViewerTypeDto> {
        if(array == null)
            return array;
        array.sort((a: DocumentViewerTypeDto, b: DocumentViewerTypeDto) => {
            // Si a més gran que b -> 1
            // Si b més gran que a -> -1
            // Si són iguals -> 0
            return SortDocumentType.compareAny(a, b);
        });
        return array;
    }

    public static compareAny(a: DocumentViewerTypeDto, b: DocumentViewerTypeDto): number {
        let aScore = 0;
        let bScore = 0;

        if(a.documentType==RouteDocumentTypes.CMR) aScore += 5;
        if(a.documentType==RouteDocumentTypes.DeliveryNote) aScore += 4;
        if(a.documentType==RouteDocumentTypes.LoadTest) aScore += 3;
        if(a.documentType==RouteDocumentTypes.TripIncident) aScore += 2;
        if(a.documentType==RouteDocumentTypes.UnloadTest) aScore += 1;
        
        if(b.documentType==RouteDocumentTypes.CMR) bScore += 5;
        if(b.documentType==RouteDocumentTypes.DeliveryNote) bScore += 4;
        if(b.documentType==RouteDocumentTypes.LoadTest) bScore += 3;
        if(b.documentType==RouteDocumentTypes.TripIncident) bScore += 2;
        if(b.documentType==RouteDocumentTypes.UnloadTest) bScore += 1;

        if (aScore < bScore) {
            return 1;
        } else if (aScore > bScore) {
            return -1;
        } else {
            return 0;
        }
    }
}
