export enum IncidentTypes {
    EstoyParado = "EstoyParado",
    NoEncuentroDireccion = "NoEncuentroDireccion",
    HePinchado = "HePinchado",
    Motor = "Motor",
    Colision = "Colision",
    RechazanCargaDescarga = "RechazanCargaDescarga",
    Cerrado = "Cerrado",
    Incompleto = "Incompleto",
    Danyado = "Danyado",
    FueraDeCondiciones = "FueraDeCondiciones",
    Retraso = "Retraso",
    Otro = "Otro",
    None = "None"
}
