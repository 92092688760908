import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Router } from '@angular/router';
import { ShipmentListMaintenanceDto } from './ShipmentListMaintenanceDto';
import { ShipmentItemMaintenanceDto } from './ShipmentItemMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { ShipmentLogMaintenanceDto } from './ShipmentLogMaintenanceDto';
import { RouteShipmentConditionDto } from '../../../dtos/route/RouteShipmentConditionDto';
import { ShipmentCardDto } from '@shared/src/public-api';
import { MaintenanceSearchByTextDto } from '../_base/MaintenanceSearchByTextDtos';

@Injectable()
export class ShipmentMaintenanceController extends BaseMaintenanceController<ShipmentListMaintenanceDto, ShipmentItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "activity/shipment");
    }


    public getResourceRoot(): string {
        return "SHIPMENT";
    }

    public getLogsByShipment(shipmentid: number): Observable<ShipmentLogMaintenanceDto[]> {
        return this.get(this.getBaseUrl() + "/getlogsbyshipment/shipmentid/" + shipmentid);
    }

    public getShipmentsByPhaseId(search: MaintenanceSearchByTextDto): Observable<Array<ShipmentCardDto>> {
        return this.post(this.getBaseUrl() + "/shipments", search, "getShipmentsByPhaseId");
    }

    public forceDefaultValues(shipment: ShipmentItemMaintenanceDto, mode: string, filechanged: string): Observable<ShipmentItemMaintenanceDto> {
        return this.post(this.getBaseUrl() + "/forcedefaultvalues/" + mode + "/" + filechanged, shipment, "forceDefaultValues");
    }

}