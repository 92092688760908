import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class PlateEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): PlateEntitySelectorDto{
        let result: PlateEntitySelectorDto = new PlateEntitySelectorDto();
		result.loadData(EntitysSelectors.Plate, "", activeFilterKeys, 0, false);
        return result;
    }

	public getUrlGoToMaintenance(): string {
		return "activity/plate";
	}

	public getUrlBack(): string {
		return "/maintenance/plate";
	}
  
}