import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { HomeController } from '../public-api';
import { TenantResourceItemMaintenanceDto } from '../controllers/maintenance/tenant/TenantResourceItemMaintenanceDto';

@Pipe({
    name: 'translateIn',
    pure: true,
})
export class TranslateInPipe implements PipeTransform {
    constructor(protected translateService: TranslateService) {
        translateService.setDefaultLang('en');
    }


    private static dictionaryCache = new Map<string, any>();
    transform(key: string, langCode: string): any {
        if (key) {
            if (HomeController.RESOURCES != null) {
                for (let i = 0; i < HomeController.RESOURCES.length; i++)
                    if (HomeController.RESOURCES[i].tenantResourceKey === key)
                        return TenantResourceItemMaintenanceDto.getTranslation(HomeController.RESOURCES[i], langCode);
            }
            let dictionary = null;
            if (TranslateInPipe.dictionaryCache.has(langCode))
                dictionary = TranslateInPipe.dictionaryCache.get(langCode);
            else {
                dictionary = this.translateService
                    .getTranslation(langCode);
                TranslateInPipe.dictionaryCache.set(langCode, dictionary);
            }
            return dictionary.pipe(map((translations) => {
                let translation = translations[key];
                if (!translation)
                    translation = key;
                return translation;
            }));
        }
        return key;
    }
}

@Pipe({
    name: 'translateH',
    pure: true,
})
export class TranslateH implements PipeTransform {
    constructor(protected translateService: TranslateService) {
        translateService.setDefaultLang('en');
    }

    transform(key: string, ...args: any[]): any {
        if (key) {
            if (HomeController.RESOURCES != null) {
                for (let i = 0; i < HomeController.RESOURCES.length; i++)
                    if (HomeController.RESOURCES[i].tenantResourceKey === key)
                        return TenantResourceItemMaintenanceDto.getTranslation(HomeController.RESOURCES[i], this.translateService.currentLang);
            }
            return this.translateService.instant(key, ...args);
        }
        return key;
    }
}