import { Injectable } from '@angular/core';

import { NotificationTopicSchemaPartnerSubMaintenanceController } from './NotificationTopicSchemaPartnerSubMaintenanceController';

@Injectable()
export class NotificationTopicSchemaPartnerShipperSubMaintenanceController extends NotificationTopicSchemaPartnerSubMaintenanceController {

    public getResourceRoot(): string {
        return "NOTIFICATIONTOPICSCHEMAPARTNER.SHIPPER";
    }

    public buildUrl(parentId: number, optionParentid: string, uri: string): string {
        return "/system/partner/shipper/" + parentId + "/" + optionParentid + "/submain" + uri;
    }
}