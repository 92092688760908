import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { Observable } from 'rxjs';
import { ListOfMaintenanceDto } from '../ListOfMaintenanceDto';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { MaintenanceSearchByTextDto } from '../_base/MaintenanceSearchByTextDtos';
import { PartnerItemMaintenanceDto } from './PartnerItemMaintenanceDto';
import { PartnerListMaintenanceDto } from './PartnerListMaintenanceDto';


@Injectable()
export class PartnerMaintenanceController extends
    BaseMaintenanceController<PartnerListMaintenanceDto, PartnerItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "partner");
    }

    public buildUrl(uri: string): string {
        return "/system/partner/all" + uri;
    }

    public search(maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<ListOfMaintenanceDto<PartnerListMaintenanceDto>> {

        if (maintenanceSearchByText.activeFilterKeys == null)
            maintenanceSearchByText.activeFilterKeys = [];

        if (this.getPermission() && this.getPermission().length > 0)
            maintenanceSearchByText.activeFilterKeys = maintenanceSearchByText.activeFilterKeys.concat(this.getPermission());

        return this.post(this.getBaseUrl() + "/search", maintenanceSearchByText, "BaseMaintenanceController.Search." + this.baseUrl);
    }

    public getPermission(): Array<string> {
        return [];
    }
}
