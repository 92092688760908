export enum InitialMenuOptions {
    lite = "lite",
    activeroutes = "activeroutes",
    activeslots = "activeslots",
    dailyslots = "dailyslots",
    dailydocks = "dailydocks",
    loadconsole = "loadconsole",
    accesscontrol = "accesscontrol",
    booking = "booking",
    international = "international",
    documentaryreview = "documentaryreview",
    none = "none",
}
