<div class="container" *ngIf="customTableData">
    <div class="baractions">
        <input class="searchbox" placeholder="{{'SEARCH'| translate}}" type=text [(ngModel)]="searchText">
        <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)">
        </actions>

        <div class="grup header" [ngClass]="{'expandedHeader' : expandedHeader}">
            <shared-customtable-column *ngFor="let columnData of getHeader() | sortBy:'order'; let i=index"
                class="value column readonly" [ngClass]="{'deletecolumn': isDeleteColumn(columnData.column)}"
                [readonly]="true" [columnData]="columnData" (changedTableData)="onChangedTableData()"
                (deleteColumnSelected)="onDeleteColumnSelected(columnData.column)"
                (deleteColumn)="onDeleteColumn(columnData.column)"
                (deleteColumnCanceled)="onDeleteColumnSelected(null)">
            </shared-customtable-column>

            <!-- <div *ngIf="!readonly" class="value button-sub-line boto-expand" (click)="onExpandedHeader()">
                <i aria-hidden="true" class="cercle fa fa-chevron-down nomesexpanded"></i>
                <i aria-hidden="true" class="cercle fa fa-chevron-right noexpanded"></i>
            </div> -->
        </div>
    </div>

    <cdk-virtual-scroll-viewport *ngIf="customTableData && customTableData.rows" scrollWindow itemSize="45"
        class="rows-list">
        <div class="line"
            *cdkVirtualFor="let rowData of customTableData.rows | filter:searchText | sortBy:'row'; let i=index"
            [ngClass]="{'showDeleteButton': showDeleteRowConfirmButton(rowData.row), 'readonly': readonly, 'expandedLine': isExpandedLine(rowData), 'deletecolumn': isDeleteColumn(rowData.column)}">
            <div class="first-line">
                <shared-customtable-field *ngFor="let fieldData of rowData.fields | sortBy:'order'; let i=index"
                    class="value field" [readonly]="readonly" [fieldData]="fieldData"
                    (changedTableData)="onChangedTableData()">
                </shared-customtable-field>

            </div>
            <div *ngIf="!readonly" class="value button-sub-line boto-expand" (click)="onExpandedLine(rowData)">
                <i aria-hidden="true" class="cercle fa fa-times-circle todeleteselect noexpanded"
                    (click)="onDeleteRowSelected(rowData.row)"></i>
                <div *ngIf="showDeleteRowConfirmButton(rowData.row)">
                    <i aria-hidden="true" class="cercle fa fa-times-circle tocanceledevent nomesexpanded mb3"
                        (click)="onDeleteRowSelected(null)"></i>
                    <i aria-hidden="true" class="cercle fa fa-trash todeleteevent nomesexpanded"
                        (click)="onDeleteLine(rowData.row)"></i>
                </div>
            </div>
        </div>

        <actions *ngIf="!readonly" class="right" [actionstrip]="actionsAdd" (clickAction)="onClickAction($event)">
        </actions>

    </cdk-virtual-scroll-viewport>

</div>