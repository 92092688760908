import { Component, Input, OnInit } from "@angular/core";
import { HTag } from "@shared/src/datatypes/HTag";
import { RouteShipmentConditionDto } from "@shared/src/dtos/route/RouteShipmentConditionDto";
import { ConditionCommunications } from "@shared/src/enums/ConditionCommunications";

@Component({
    selector: 'shared-route-shipment-condition',
    templateUrl: './shared.route.shipment.condition.component.html',
    styleUrls: ['./shared.route.shipment.condition.component.scss']
})
export class SharedRouteShipmentConditionComponent implements OnInit {

    @Input() showRecipients: boolean = true;

    private _value: RouteShipmentConditionDto;
    @Input()
    public set value(val: RouteShipmentConditionDto) {
        if (this._value === val)
            return;

        this._value = val;
        this.loadInternalProperties();
    }
    public get value(): RouteShipmentConditionDto {
        return this._value;
    }

    public resourceName = "";
    public generalClass = "";
    public tags: Array<HTag>;
    public loadInternalProperties() {
        if (this._value == null)
            return;

        this.resourceName = ("RouteShipmentConditionTypes." + this.value.type.toString()).toUpperCase();
        this.generalClass = this.value.type.toString();
        if (this.value.communicationVia != ConditionCommunications.None)
            this.generalClass += " " + this.value.communicationVia.toString();

        this.tags = new Array<HTag>();
        this.tags.push(new HTag(this.value.communicationVia.toString().toUpperCase()));

        if (this.value.notifyAll)
            this.tags.push(new HTag("notifyAll".toUpperCase()));
        if (this.value.notifyDriver)
            this.tags.push(new HTag("notifyDriver".toUpperCase()));
        if (this.value.notifyOrigin)
            this.tags.push(new HTag("notifyOrigin".toUpperCase()));
        if (this.value.notifyDestination)
            this.tags.push(new HTag("notifyDestination".toUpperCase()));
        if (this.value.notifyLogisticOperator)
            this.tags.push(new HTag("notifyLogisticOperator".toUpperCase()));
        if (this.value.notifyCarrier)
            this.tags.push(new HTag("notifyCarrier".toUpperCase()));
        if (this.value.notifyResponsibleUser)
            this.tags.push(new HTag("notifyResponsibleUser".toUpperCase()));
    }

    ngOnInit(): void {
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }
}
