import { Pipe, PipeTransform } from '@angular/core';
import { HString } from '../datatypes/HString';

@Pipe({
    name: 'filterActiveRoutes'
})
export class FilterActiveRoutesPipe implements PipeTransform {
    transform(items: any[], searchText: string, filterMetadata?: any): any[] {
        if (!items) {
            if (items != null)
                filterMetadata.count = 0;
            filterMetadata.items = [];
            return [];
        }
        if (!searchText) {
            if (filterMetadata != null)
                if (items != null)
                    filterMetadata.count = items.length;
                else
                    filterMetadata.count = 0;

            filterMetadata.items = items;
            return items;
        }
        searchText = searchText.toLowerCase();
        let result = items.filter(it => {
            if ((typeof it) == 'object' || (typeof it) == 'string' || (typeof it) == 'number')
                return this.filterSearchText(it, searchText);
        });

        if (filterMetadata != null) {
            filterMetadata.count = result.length;
            filterMetadata.items = result;

        }
        return result;
    }

    /**
     * Ens pot venir una llista de filtres separats per ;
     */
    private filterSearchText(it: any, searchText: string): boolean {
        let result = true;
        searchText.split(';').forEach(text => {
            if ((typeof it) == 'object') {
                if(!this.objectConte(it, text)){
                    result = false;
                    return;
                }
            }
            else if ((typeof it) == 'string'){
                if(!it.toLowerCase().includes(it, text)){
                    result = false;
                    return;
                }
            }else if ((typeof it) == 'number'){
                if(!it.toString().includes(it, text)){
                    result = false;
                    return;
                }
            }
        });
        return result;
    }

    private objectConte(value: any, searchText: string): boolean {
        let result = false;
        Object.entries(value).forEach(([key, value]) => {
            //Obiem les filles
            if (!result && value != null && !HString.equals(key, "childs")) {
                if ((typeof value) == 'object') {
                    result = result || this.objectConte(value, searchText);
                }
                else if ((typeof value) == 'string')
                    result = result || String(value).toLowerCase().includes(searchText);
                else if ((typeof value) == 'number')
                    result = result || String(value).includes(searchText);
            }
        });
        return result;
    }
}