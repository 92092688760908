import { Component, Input, Output, EventEmitter } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';
import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HourValidator } from '../../form/validations/HourValidator';
import { HHour } from '@shared/src/datatypes/HHour';
import { HomeController } from '@shared/src/controllers/home/home.controller';


@Component({
  selector: 'hour-picker',
  templateUrl: './hour-picker.component.html',
  styleUrls: ['./hour-picker.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: HourPickerComponent, multi: true }]
})

export class HourPickerComponent extends ValueAccessorValidatedBase<string, HHour> {
  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormHour = this.fb.group({
      hourvalidator: ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return HourValidator.isValid(value);
  }
  showClearButton(): boolean {
    if (!this.value)
      return false;

    if (this.value == "00:00")
      return false;

    if(this.readonly)
      return false;

    return true;
  }


  public rFormHour: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if(this.homecontroller.useIonic)
      this.useIonic = this.homecontroller.useIonic();
  }


  @Input() public placeholder: string;
  @Input() public readonly: boolean;
  @Output() valueChanged: EventEmitter<HHour> = new EventEmitter();

  private _max: string;
  @Input() set max(selectedvalue: HHour) {
    this._max = HHour.getHourString(selectedvalue);
  }
  get max(): HHour {
    return HHour.build(this._max);
  }

  private _min: string;
  @Input() set min(selectedvalue: HHour) {
    this._min = HHour.getHourString(selectedvalue);
  }
  get min(): HHour {
    return HHour.build(this._min);
  }

  public identifier = 'hour-picker-' + identifier++;

  externalValue(value: string): HHour {
    if (!value)
      return HHour.empty();
    let hdate: HHour = HHour.build(value);
    return hdate;
  }
  internalValue(value: HHour): string {

    let t: HHour = HHour.build(value);

    return HHour.getHourString(t);
  }

  onChange(e) {
    this.valueChanged.next(this.externalValue(this.value));
  }
  
  keyEnter(event){
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}

let identifier = 0;