import { HTags } from "@shared/src/datatypes/HTags";
import { GlobalTelemetry } from "./GlobalTelemetry";
import { LocalizableObjectDto, LocalizableObjectTypes } from "./LocalizableObjectDto";

export class ClusteredLocatorDto {

    public status: HTags;
    public searchString: string;
    public sTags: string;

    public globalTelemetry: GlobalTelemetry;
    public title: string;
    public description: string;

    public objectHierarchy: Array<LocalizableObjectTypes>;

    public user: Array<LocalizableObjectDto> = [];
    public address: Array<LocalizableObjectDto> = [];
    public device: Array<LocalizableObjectDto> = [];
    public plate: Array<LocalizableObjectDto> = [];
    public route: Array<LocalizableObjectDto> = [];
    public shipment: Array<LocalizableObjectDto> = [];
}