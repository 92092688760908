import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    return KeysPipe.enumToKeyValuePair(value);
  }

  public static enumToKeyValuePair(value: any): any {
    let keys = [];
    if (!value)
      return keys;
    for (var enumMember in value) {
      //Las enumeraciones pueden estar identificadas por "String" o "Number"
      //El pipe debe funcionar en ambos casos
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({ key: enumMember, value: value[enumMember] });
        // Uncomment if you want log
        // console.log("enum member: ", value[enumMember]);
      } else
        keys.push({ key: enumMember, value: enumMember });
    }
    return keys;
  }
}
