import { EventEmitter, Input, Output, Component, OnInit } from "@angular/core";
import { HDateHour } from "@shared/src/datatypes/HDateHour";

@Component({
  selector: "label-datehour",
  templateUrl: "./label-datehour.component.html",
  styleUrls: ["./label-datehour.component.scss"],
})
export class LabelDateHourComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {

  }

  @Input() public placeholder: string;

  @Input() public showLocaleObserver: boolean = false;

  _dateHour: HDateHour;
  @Input() set dateHour(value: HDateHour) {
    if (this._dateHour === value)
      return;
    this._dateHour = value;
    this.setCanShowTimeZoneObserver(value);
  }
  get dateHour(): HDateHour {
    return this._dateHour;
  }

  public canShowTimeZoneObserver: boolean;
  setCanShowTimeZoneObserver(value: HDateHour) {
    let canshow = false;
    if (!HDateHour.isNullOrNullValue(value)) {
      if (value.Timezone != value.TimezoneObserver)
        canshow = true;
    }
    this.canShowTimeZoneObserver = canshow;
  }

  public showTimeZoneObserver: boolean = false;
  onShowTimeZoneObserver() {
    this.showTimeZoneObserver = !this.showTimeZoneObserver;
  }


  getDateHourObserver(): HDateHour {
    if (!HDateHour.isNullOrNullValue(this._dateHour)) {
      return this._dateHour.getDateHourObserver();
    }
    return HDateHour.nullDateHour();
  }
}
