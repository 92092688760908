
import { Component, Input, Inject, OnInit } from '@angular/core';
import { HomeController } from "@shared/src/controllers/home/home.controller";
import { SlotsController } from '@shared/src/controllers/address/slots/slots.controller';
import { SlotLogDto } from '@shared/src/dtos/address/slots/SlotLogDto';



@Component({
    selector: 'shared-slot-log',
    templateUrl: './shared.slot.log.component.html',
    styleUrls: ['./shared.slot.log.component.scss']
})
export class SharedSlotLogComponent implements OnInit {

    public useIonic: boolean = false;

    constructor(@Inject('HomeController') protected homeController: HomeController,
        public slotsController: SlotsController) {

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }


    private __phaseId: number;
    @Input()
    set phaseId(value: number) {
        if (this.__phaseId === value)
            return;
        this.__phaseId = value;
        this.loadSlotLogByPhaseId();
    }
    get phaseId(): number {
        return this.__phaseId;
    }

    ngOnInit(): void { }

    public logs: Array<SlotLogDto>;

    loadSlotLogByPhaseId() {
        if (this.phaseId == null || this.phaseId == 0) {
            this.logs = null;
            return;
        }
        this.slotsController.getSlotLogsByPhaseId(this.phaseId).subscribe(data => {
            this.logs = data;
        });
    }
    pretty(data) {
        return JSON.stringify(data);
    }
}
