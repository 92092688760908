import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseMaintenanceController } from '../_base/BaseMaintenanceController';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { PortAddressListMaintenanceDto } from './PortAddressListMaintenanceDto';
import { PortAddressItemMaintenanceDto } from './PortAddressItemMaintenanceDto';

@Injectable()
export class PortAddressMaintenanceController extends BaseMaintenanceController<PortAddressListMaintenanceDto, PortAddressItemMaintenanceDto> {

    constructor(
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {

        super(http, alertService, translateService, loadingService, router, "portaddress");
    }

    public getResourceRoot(): string {
        return "PORTADDRESS";
    }

    public buildUrl(uri: string): string {
        return "internal/portaddress" + uri;
    }
}