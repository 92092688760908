import { Component, Inject, OnDestroy } from '@angular/core';
import { AccessControlController } from '@shared/src/controllers/accesscontrol/accesscontrol.controller';
import { Subscription, timer as observableTimer } from 'rxjs';
import { SharedAccessControlPlateRouteInfoComponent } from '../plate/shared.accesscontrol.plateroute.info.component';
import { AccessControlStatusDto, AccessControlStatusGateDTO } from '@shared/src/controllers/accesscontrol/AccessControlStatusDto';
import { HColor, HDateHour, HomeController, HTime, SecurityController, SocketController } from '@shared/src/public-api';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'shared-accesscontrol-status',
  templateUrl: './shared.accesscontrol.status.component.html',
  styleUrls: ['./shared.accesscontrol.status.component.scss'],
})
export class SharedAccessControlStatusComponent implements OnDestroy {
  constructor(private accessControlController: AccessControlController,
    private socketController: SocketController,
    private translateService: TranslateService,
    @Inject('HomeController')
    protected homeController: HomeController,
    @Inject('SecurityController')
    protected securityController: SecurityController) {

    this.securityController.getTenant().subscribe(tenant => {
      this.ACTUALINTEREST = "AccessControlStatus:" + this.securityController.STOREDUSERDTO().tenantId;
      console.debug(this.ACTUALINTEREST);
      this.socketController.interested(this.ACTUALINTEREST);
    });
    this.securityController.getStoredUserDto().subscribe(data => {
      this.ACTUALINTEREST = "AccessControlStatus:" + data.tenantId;
      console.debug(this.ACTUALINTEREST);
      this.socketController.interested(this.ACTUALINTEREST);
    })
    this.notificationSubscription = this.socketController.notificationInterestedOn$.subscribe(value => {
      if (value === this.ACTUALINTEREST)
        this.getData();
    });
    this.getData();

  }
  private notificationSubscription: Subscription;
  public actions: ActionsStripDto;
  public searchText: string = "";
  public ACTUALINTEREST: string = "AccessControlStatus";

  ngOnInit() {
    this.activaTimer();
    this.actions = ActionsStripDto.buildDefaultActionsForReports();
  }

  ngOnDestroy() {
    if (this.notificationSubscription)
      this.notificationSubscription.unsubscribe();
  }

  public count = 0;
  public data: Array<AccessControlStatusDto> = [];
  public getData() {
    this.accessControlController.getAccessControlStatus().subscribe(d => {
      this.data = AccessControlStatusDto.wellcome(d, HDateHour.now());
    });

    this.count++;
  }

  private static AUTOREFRESHFREQUENCYMILLISECONDS: number = 30000;
  private timerSubscription: Subscription;
  private activaTimer() {
    this.desactivaTimer();
    let timer = observableTimer(SharedAccessControlStatusComponent.AUTOREFRESHFREQUENCYMILLISECONDS, SharedAccessControlStatusComponent.AUTOREFRESHFREQUENCYMILLISECONDS);
    this.timerSubscription = timer.subscribe(t => {
      this.desactivaTimer();
      this.getData();
    });
  }
  private desactivaTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  onClickAction($event: ActionDto) {
    if ($event.actionId === "GETDATA") {
      this.getData();
    }
  }

  public getClassEndarreriment(hora: HDateHour) {
    if (HDateHour.isMinor(hora, HDateHour.now())) {

    }
  }
  public getEndarreriment(hora: HDateHour): HTime {
    if (HDateHour.isNullOrNullValue(hora))
      return HTime.NULLVALUE();
    return HDateHour.distanceHTime(hora, HDateHour.now());
  }
  public getStyleGateTag(gate: AccessControlStatusGateDTO) {

    if (gate != null && gate.gateKey != "Out") {
      let bcolor = HColor.getColorFromStringTable(this.translateService.instant(gate.gateName), this.homeController);
      return {
        "color": HColor.getWhiteBlackFromColor(bcolor),
        "background-color": bcolor
      };
    }

    return {};
  }

  public computeGeneralClass(value: AccessControlStatusDto): string {
    let result = "";
    if (value.isDelayed)
      result += " isDelayed";
    return result;
  }

  pretty(value) {
    return JSON.stringify(value);
  }
}
