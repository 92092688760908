<!-- input.component.html!-->
<div [formGroup]="rFormInput">
    <div *ngIf="!useIonic">
        <mat-form-field
            [ngClass]="{'input-with-helppoint': showHelpPoint, 'text-align-left': textAlign=='left', 'text-align-center': textAlign=='center', 'text-align-right': textAlign=='right'}">
            <input matInput [id]="identifier" [ngClass]="{'input-readonly':readonly}" [(ngModel)]="value"
                [readonly]="readonly" [type]="actingtype" formControlName="dtpvalidator" [placeholder]="placeholder"
                autocomplete="autocomplete" (change)="onChange($event)" [min]="min" [max]="max" [step]="step"
                (keydown.enter)="keyEnter($event)" [style.direction]="direction" [style.textAlign]="textAlign">

            <mat-icon *ngIf="type==='passwordauto'" matSuffix (click)="togglePassword()">{{actingtype=='password' ?
                'visibility_off' :
                'visibility'}}</mat-icon>
            <helppoint *ngIf="showHelpPoint" class="helppoint-in-input right" [helpresource]="helpresource">
            </helppoint>
        </mat-form-field>
    </div>
    <div *ngIf="useIonic">
        <ion-input *ngIf="!readonly" ionInput [ngClass]="{'error': !rFormInput.valid && !rFormInput.pristine}"
            [id]="identifier" [(ngModel)]="value" [type]="actingtype" formControlName="dtpvalidator" ngDefaultControl
            [placeholder]="placeholder" autocomplete="autocomplete" (ionChange)="onChange($event)">
            <i *ngIf="type==='passwordauto'" class="ionicicona fa fa-eye-slash" aria-hidden="true"
                (click)="togglePassword()"></i>
        </ion-input>
        <ion-input *ngIf="readonly" readonly ionInput [ngClass]="{'error': !rFormInput.valid && !rFormInput.pristine}"
            [id]="identifier" [(ngModel)]="value" [type]="actingtype" formControlName="dtpvalidator"
            [ngClass]="{'input-readonly':readonly}" ngDefaultControl [placeholder]="placeholder"
            autocomplete="autocomplete" (ionChange)="onChange($event)">
            <i *ngIf="type==='passwordauto'" class="ionicicona fa fa-eye-slash" aria-hidden="true"
                (click)="togglePassword()"></i>
        </ion-input>
    </div>
</div>