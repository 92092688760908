<div *ngIf="value">
    <div class="container" [ngClass]="{'ionic' : useIonic, 'panel':opcio=='panel'}">
        <div class="linia inicial" [ngClass]="opcio">
            <button class="link panel" *ngIf="value.showAsidePanel"
                (click)="setOpcio('panel')">{{'SHARED.PANEL.ELEMENT.INFO.COMPONENT.OPTION.PANEL.PROMPT' | translate}}</button>
            <button class="link " [ngClass]="getOpcioObject()" *ngIf="value.showAsideObject"
                (click)="setOpcio(getOpcioObject())">{{ getResourceObject() | translate}}</button>
            <button class="link route" *ngIf="value.showAsideRoutePhase && value.routeId > 0"
                (click)="setOpcio('route')">{{'SHARED.PANEL.ELEMENT.INFO.COMPONENT.OPTION.ROUTE.PROMPT' | translate}}</button>
        </div>
        <ng-container *ngIf="opcio=='panel'">
            <div class="linia primera">
                <shared-panel-column-item class="item-column showcolumn top" [value]="value"></shared-panel-column-item>
            </div>

            <div class="linia block actions-column component">
                <div class="title">{{'SHARED.PANEL.ELEMENT.INFO.COMPONENT.ACTIONS.TITLE' | translate}}</div>
                <button *ngFor="let column of value.toColumns | sortBy:'columnOrder'; let i=index" class="button-column"
                    [ngStyle]="getColumnStyle(column)" (click)="onColumnClick(column)">
                    <div class="text-action">
                        {{ column.panelColumnHeadingItem }}
                    </div>
                </button>

            </div>
            <div class="ultima-linia block">
                <shared-panel-element-log [logs]="value.logs"></shared-panel-element-log>
            </div>
        </ng-container>
    </div>
    <shared-shipment-info-component class="aside shipment" *ngIf="opcio=='shipment' && value.shipmentId > 0" [shipmentId]="value.shipmentId">
    </shared-shipment-info-component>
    <shared-route-info-component class="aside phase" *ngIf="opcio=='phase' && value.phaseId > 0" [phaseId]="value.phaseId">
    </shared-route-info-component>
    <shared-route-info-component class="aside route" *ngIf="opcio=='route' && value.routeId > 0" [routeId]="value.routeId">
    </shared-route-info-component>
</div>