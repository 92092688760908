<div class="field">
    <input-component *ngIf="fieldData.dataType == dtString || fieldData.dataType == dtStringList" name="valueString"
        class="value string nolabel" [(ngModel)]="valueString" [readonly]="readonly"
        (ngModelChange)="onValueChanged($event)"></input-component>
    <input-component *ngIf="fieldData.dataType == dtInteger || fieldData.dataType == dtLong" name="valueNumber"
        class="value integer nolabel" [(ngModel)]="valueNumber" [min]="0" type="number" [readonly]="readonly"
        (ngModelChange)="onValueChanged($event)"></input-component>
    <input-component *ngIf="fieldData.dataType == dtDouble" name="valueNumber" class="value double nolabel"
        [(ngModel)]="valueNumber" [min]="0.0" type="number" [readonly]="readonly"
        (ngModelChange)="onValueChanged($event)"></input-component>

    <boolean-component *ngIf="fieldData.dataType == dtBoolean" name="valueBoolean" class="value double nolabel"
        [(ngModel)]="valueBoolean" [showHelpPoint]=false [readonly]="readonly" (ngModelChange)="onValueChanged($event)">
    </boolean-component>
    <tristate *ngIf="fieldData.dataType == dtHTriState" name="valueTriState" class="value double nolabel"
        [(ngModel)]="valueTriState" [readonly]="readonly" (ngModelChange)="onValueChanged($event)">
    </tristate>
    <date-picker *ngIf="fieldData.dataType == dtHDate" [(ngModel)]="valueHDate" name="valueHDate" [readonly]="readonly"
        (ngModelChange)="onValueChanged($event)">
    </date-picker>
    <time-picker *ngIf="fieldData.dataType == dtHTime" [(ngModel)]="valueHTime" name="valueHTime" [readonly]="readonly"
        (ngModelChange)="onValueChanged($event)">
    </time-picker>
    <hour-picker *ngIf="fieldData.dataType == dtHHour" name="valueHHour" class="value double nolabel"
        [(ngModel)]="valueHHour" [readonly]="readonly" (ngModelChange)="onValueChanged($event)">
    </hour-picker>
    <hourinterval-picker *ngIf="fieldData.dataType == dtHHourInterval" [(ngModel)]="valueHHourInterval"
        name="valueHHourInterval" [readonly]="readonly" (ngModelChange)="onValueChanged($event)">
    </hourinterval-picker>
    <div *ngIf="fieldData.dataType == dtColor" class="color-field">
        <color-picker [(ngModel)]="valueColor" name="backgroundColor" [readonly]="readonly"
            (valueChanged)="onColorChanged($event)">
        </color-picker>
    </div>
    <tags-chips class="tags" *ngIf="fieldData.dataType == dtHTags" [(ngModel)]="valueHTags" name="valueHTags"
        [readonly]="readonly" (ngModelChange)="onValueChanged($event)"></tags-chips>

</div>