
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { OpenChangeManagementModelDto } from '@shared/src/dtos/open/OpenChangeManagementModelDto';
import { iRouteExpandCard } from '@shared/src/dtos/route/iRouteExpandCard';
import { Incoterms } from '@shared/src/enums/Incoterms';
import { ManagementModelObjectParentTypes } from '@shared/src/enums/ManagementModelObjectParentTypes';
import { AlertService, RouteController } from '@shared/src/public-api';



@Component({
    selector: 'shared-route-item-edit-additionaldata',
    templateUrl: './shared.route.item.edit.additionaldata.component.html',
    styleUrls: ['./shared.route.item.edit.additionaldata.component.scss']
})
export class SharedRouteItemEditAdditionalDataComponent implements OnInit {

    public enumIncoterms = Incoterms;
    public useIonic: boolean = false;

    constructor(public openRouteController: OpenRouteController,
        public routeController: RouteController,
        @Inject('HomeController') public homeController: HomeController,
        @Inject('SecurityController') public securityController: SecurityController,
        @Inject('AlertService') public alertService: AlertService) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    @Input() public readonly: boolean = false;
  
    @Input() public showtitle: boolean = false;

    private _route: iRouteExpandCard;
    @Input()
    set route(value: iRouteExpandCard) {
        if (this._route === value)
            return;
        this._route = value;

        this.resetFileds();
    }
    get route(): iRouteExpandCard {
        return this._route;
    }

    resetFileds() {
        if (this._route == null){
            this._managementModelId = 0;
            this._typologyId = 0;
            this._incoterm = Incoterms.none;
            this.typology = "";
          } else{
            this._managementModelId = this._route.managementModelId;
            this._typologyId = this._route.typologyId;
            this._incoterm = this._route.incoterm;
            this.typology = this._route.typology;
            this.managementModel = this._route.managementModel;
          }
    }

    ngOnInit(): void {

    }

    @Output() public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public managementModel: string = null;
    private _managementModelId = null;
    public set managementModelId(managementModelId: number) {
        if (managementModelId == this._managementModelId)
            return;

        this._managementModelId = managementModelId;
        this.openRouteController.setManagementModel(OpenChangeManagementModelDto.build(ManagementModelObjectParentTypes.Route, this._route.routeId, managementModelId)).subscribe(data => {
            if (data)
                this.valueChange.emit(true);
        })
    }
    public get managementModelId(): number {
        return this._managementModelId;
    }

    public typology: string = null;
    private _typologyId: number = null;
    public set typologyId(v: number) {
        if (v == this._typologyId)
            return;

        this._typologyId = v;
        this.openRouteController.setTypology(this._route.routeId, v).subscribe(data => {
            if (data)
                this.valueChange.emit(true);
        });
    }
    public get typologyId(): number {
        return this._typologyId;
    }

    public getResourceIncoterm(): string {
        if(this.incoterm == null)
            return 'INCOTERMS.' + 'NONE';
        return 'INCOTERMS.' + this.incoterm.toUpperCase();
    }

    private _incoterm: Incoterms = null;
    public set incoterm(v: Incoterms) {
        if (v == this._incoterm)
            return;

        this._incoterm = v;
        this.openRouteController.setIncoterm(this._route.routeId, v).subscribe(data => {
            if (data)
                this.valueChange.emit(true);
        });
    }
    public get incoterm(): Incoterms {
        return this._incoterm;
    }

}
