import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { ShipmentCMRController } from '@shared/src/controllers/route/cmr/shipmentcmr.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { ShipmentCMREventDto } from '@shared/src/dtos/route/cmr/shipment/ShipmentCMREventDto';

@Component({
  selector: 'shared-shipment-cmr-edit-events-component',
  templateUrl: './shared.shipment.cmr.edit.events.component.html',
  styleUrls: ['./shared.shipment.cmr.edit.events.component.scss'],
})
export class SharedShipmentCMREditEventsComponent {

  constructor(private shipmentCMRController:ShipmentCMRController,
    @Inject('HomeController') protected homeController: HomeController) {
    
      if (homeController.useIonic) 
        this.useIonic = homeController.useIonic();
  };

  public useIonic: boolean = false;

  @Input() public readonly: boolean;

  private _shipmentId: number;
  @Input()
  public set shipmentId(value: number) {
      if (this._shipmentId === value)
          return;
      this._shipmentId = value; 
      this.loadListEvents();
  }
  public get shipmentId(): number {
      return this._shipmentId;
  }

  public listEvents: ShipmentCMREventDto[];
  public getListEvents(){
    if(this.listEvents == null)
      return new Array();
    return this.listEvents;
  }
  public loadListEvents(){

    if(HLong.isNullOrNullLong(this.shipmentId)){
      this.listEvents = new Array();
      return;
    }

    this.shipmentCMRController.getShipmentCMREvents(this.shipmentId).subscribe(data => {
		  if (data) {
			  this.listEvents = data;
		  }else{
        this.listEvents = new Array();
      }
		});

  }
  
  ngOnInit() {

  }
}
