
import { EntitysSelectors } from "@shared/src/enums/EntitysSelectors";
import { EntitySelectorDto } from "../EntitySelectorDto";

export class UserEntitySelectorDto extends EntitySelectorDto {

	public static new(activeFilterKeys: Array<String>): UserEntitySelectorDto {
		let result: UserEntitySelectorDto = new UserEntitySelectorDto();
		result.loadData(EntitysSelectors.User, "", activeFilterKeys, 0, false);
		return result;
	}

	public getUrlGoToMaintenance(): string {
		return "system/securityandnotifications/user";
	}

	public getUrlBack(): string {
		return "/maintenance/system/securityandnotifications/user";
	}

}