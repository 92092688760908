
import { Component, Input, OnInit, Inject } from '@angular/core';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { ShipmentCardDto } from '@shared/src/dtos/shipment/ShipmentCardDto';
import { HLong } from '@shared/src/datatypes/HLong';
import { FormShipmentTypes } from '@shared/src/enums/FormShipmentTypes';
import { FormQuestionAnswerShipmentFilterDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentFilterDto';
import { FormQuestionAnswerShipmentItemDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentItemDto';
import { FormQuestionAnswerShipmentListDto } from '@shared/src/dtos/form/FormQuestionAnswerShipmentListDto';


@Component({
    selector: 'shared-shipment-item-form-maintenance',
    templateUrl: './shared.shipment.item.form.maintenance.component.html',
    styleUrls: ['./shared.shipment.item.form.maintenance.component.scss']
})
export class SharedShipmentItemFormMaintenanceComponent implements OnInit {

    public readonly: boolean = false;

    constructor(public shipmentController: ShipmentController, @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        });

    }

    ngOnInit(){

    }

    private _shipment: ShipmentCardDto;
    @Input()
    set shipment(value: ShipmentCardDto) {
        if (this._shipment === value)
            return;
        this._shipment = value;

        this.loadData();
        
    }
    get shipment(): ShipmentCardDto {
        return this._shipment;
    }
   
    private _formShipmentType: FormShipmentTypes;
    @Input()
    set formShipmentType(value: FormShipmentTypes) {
        if (this._formShipmentType === value)
            return;
        this._formShipmentType = value;

        this.loadData();
        
    }
    get formShipmentType(): FormShipmentTypes {
        return this._formShipmentType;
    }

    public addressQuestionsAnswers: FormQuestionAnswerShipmentListDto[];
    loadData(){
        if(HLong.isNullOrNullLong(this.shipment.shipmentId) || this.formShipmentType == null || this.formShipmentType == FormShipmentTypes.None)
            return;
        
        let filter = FormQuestionAnswerShipmentFilterDto.build(this.formShipmentType, this.shipment.phaseId, false, false);
    
        this.shipmentController.getFormQuestionsByShipmentId(this.shipment.shipmentId, filter).subscribe(data => {
            this.addressQuestionsAnswers = data;
        });
    }
    
}
