import {
  Component,
  EventEmitter,
  ComponentFactoryResolver,
  Output,
  Input
} from '@angular/core';
import { CalendarDayDto } from '@shared/src/dtos/base/calendar/CalendarDayDto';


@Component({
  selector: 'calendarday',
  templateUrl: "./calendar.day.html",
  styleUrls: ["./calendar.scss"]
})
export class CalendarDay<T extends CalendarDayDto> {

  //@ViewChild('daycontainer', { read: ViewContainerRef }) entry: ViewContainerRef;
  constructor(private resolver: ComponentFactoryResolver) { }

  @Output() onDateClick: EventEmitter<T> = new EventEmitter();

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.createComponent();
  }

  @Input() readonly: boolean = false;
  public style: {};
  private _value: T;
  @Input() set value(value: T) {
    if (this._value === value)
      return;
    this._value = value;

  }
  get value(): T {
    return this._value;
  }

  private _monthWidth: number = 0;
  @Input() set monthWidth(value: number) {
    if (this._monthWidth === value)
      return;
    this._monthWidth = value;
  }
  get monthWidth(): number {
    return this._monthWidth;
  }

  public dayclick(e) {
    this.onDateClick.next(this.value);
  }

  //public componentRef: ComponentRef<any>;
  createComponent() {

    /** Per ara no fem per injeccio, ho fem directe al html
     * Per una banda per rendiment i per una altre perque no es refresca al realitzar una acció sobre el dia
     */
    /*
    if(this.value && this.entry){
      let factory = null;
      this.entry.clear();
      if(this.value.type == "AddressCalendarDay"){
        factory =  this.resolver.resolveComponentFactory(AddressCalendarDay);
        this.componentRef = this.entry.createComponent(factory);
        this.componentRef.instance.value = this.value;
      }
    }
    */
  }
  destroyComponent() {
    //if(this.componentRef != null)
    //  this.componentRef.destroy();
  }
}


