import { iRouteExpandCard } from "../dtos/route/iRouteExpandCard";

export enum RoutePermissionTypes {
	Owner = "Owner",
	Carrier = "Carrier",
	Shipper = "Shipper",
	LogisticOperator = "LogisticOperator",
	Driver = "Driver",
	SecondDriver = "SecondDriver",
	Inspector = "Inspector",
	HealthCertifier = "HealthCertifier",
	ClientPreferent = "ClientPreferent",
	None = "None"
}

export class RoutePermissionTypesRules {

	static canAssignDriverAndPlatesOnRoute(value: iRouteExpandCard): boolean {
		if (value == null)
			return false;

		if (value.isDivided)
			return false;

		if (value.isChildOfGrouped)
			return false;

		return true;
	}

	static showAssignDriverAndPlatesOnRoute(value: iRouteExpandCard): boolean {
		if (value == null)
			return false;

		if (value.isDivided)
			return false;

		if (value.isChildOfGrouped)
			return false;

		return true;
	}

}

