import { Injectable } from '@angular/core';

@Injectable()
export class OpenRouteExworkInfoDto {

    public isExwork: boolean;
    public exworksEmail: Array<string>;

    public static build(isExwork: boolean, exworksEmail: Array<string>): OpenRouteExworkInfoDto {
        const dto = new OpenRouteExworkInfoDto();
        dto.isExwork = isExwork;
        dto.exworksEmail = exworksEmail;
        return dto;
    }
}
