import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { FileUpDownloadController } from '@shared/src/controllers/fileupdownload/fileupdownload.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { ShipmentController } from '@shared/src/controllers/route/shipment/shipment.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { SlotInfoDto } from '@shared/src/dtos/address/slots/SlotInfoDto';
import { OpenResource } from '@shared/src/dtos/open/OpenResource';
import { PhaseCardItemDto } from '@shared/src/dtos/phase/PhaseCardItemDto';
import { AddressTimetableIntervalDto, AlertService, HDate, HHourInterval, PhaseController, SharedSlotPickerComponent } from '@shared/src/public-api';

@Component({
    selector: 'shared-exwork-slotpicker-component',
    templateUrl: './shared.exwork.slotpicker.component.html',
    styleUrls: ['./shared.exwork.slotpicker.component.scss']
})
export class SharedExworkSlotPickerComponent implements OnInit {
    constructor(protected phaseController: PhaseController, protected activatedRoute: ActivatedRoute, public dialog: MatDialog,
        public openRouteController: OpenRouteController, public fileUpDownloadController: FileUpDownloadController, public shipmentController: ShipmentController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {
    }

    ngOnInit(): void {
    }

    public __uuid: string;
    @Input()
    public set uuid(value: string) {
        if (this.__uuid == value)
            return;
        this.__uuid = value;
        this.getInfo();
    }
    public get uuid(): string {
        return this.__uuid;
    }

    public __phase: PhaseCardItemDto;
    @Input()
    public set phase(value: PhaseCardItemDto) {
        if (this.__phase == value)
            return;
        this.__phase = value;
        this.getInfo();
    }
    public get phase(): PhaseCardItemDto {
        return this.__phase;
    }

    @Input()
    public slotInfo: SlotInfoDto;

    public __autoOpen: boolean;
    @Input()
    public set autoOpen(value: boolean) {
        if (this.__autoOpen == value)
            return;
        this.__autoOpen = value;
        this.getInfo();
    }
    public get autoOpen(): boolean {
        return this.__autoOpen;
    }

    @Input()
    public readonly: boolean = false;

    public realDate: HDate;
    public nooptions: boolean = false;
    public showObservacions: boolean = false;
    public canSlotRelease: Boolean = false;
    public realInterval: HHourInterval;
    public loading = false;
    getInfo() {
        if (this.autoOpen)
            this.getSlots();
    }

    getSlots() {
        this.loading = true;
        this.nooptions = false;
        this.canSlotRelease = false;
        if (this.uuid && this.phase) {
            this.openRouteController.getExworkSlotsByPhaseId(this.uuid, this.phase.phaseId).subscribe(data => {
                this.intervals = data;
                this.loading = false;
                this.openRouteController.getExworkCanReleaseSlotsByPhaseId(this.uuid, this.phase.phaseId).subscribe(data => {
                    this.canSlotRelease = data;

                    if (!this.canSlotRelease && this.intervals != null && this.intervals.length == 0)
                        this.nooptions = true;
                });
            });

            if (this.phase != null && this.phase.intervalInfo != null && this.phase.intervalInfo.addressTimetableIntervalId != 0) {
                this.showObservacions = SharedSlotPickerComponent.getShowObservacions(this.phase.intervalInfo);
                if (this.showObservacions) {
                    this.realDate = SharedSlotPickerComponent.getRealDate(this.phase.intervalInfo);
                    this.realInterval = SharedSlotPickerComponent.getRealInterval(this.phase.intervalInfo);
                }
            }
        }
    }
    public getRealIntervalFunc = SharedSlotPickerComponent.getRealInterval;
    public getRealDateFunc = SharedSlotPickerComponent.getRealDate;
    public getShowObservacionsFunc = SharedSlotPickerComponent.getShowObservacions;
    isSelected(addressinterval: AddressTimetableIntervalDto) {
        return addressinterval != null
            && this.slotInfo != null
            && this.slotInfo.addressTimetableIntervalId === addressinterval.addressTimetableIntervalId;
    }
    clickAlliberarSlot() {
        this.intervals = null;
        this.openRouteController.clearExworkPhaseSlot(this.uuid, this.phase.phaseId).subscribe(data => {
            this.slotChanged.next();
        });
    }
    @Output()
    slotChanged: EventEmitter<void> = new EventEmitter();
    onIntervalSelected(item: AddressTimetableIntervalDto) {
        this.intervals = null;

        if (!HLong.isNullOrNullLong(item.addressDaySettingResourceId)) {
            this.openRouteController.setExworkPhaseSlotByResource(this.uuid, this.phase.phaseId, item.addressDaySettingResourceId, OpenResource.build(item)).subscribe(data => {
                this.__autoOpen = false;
                this.slotChanged.next();
            });
        } else if (!HLong.isNullOrNullLong(item.addressTimetableIntervalId)) {
            this.openRouteController.setExworkPhaseSlot(this.uuid, this.phase.phaseId, item.addressTimetableIntervalId).subscribe(data => {
                this.__autoOpen = false;
                this.slotChanged.next();
            });
        }
    }

    private _intervals: Array<AddressTimetableIntervalDto>
    public set intervals(value: Array<AddressTimetableIntervalDto>) {
        if (this._intervals === value)
            return;
        this._intervals = value;
        this.loadIntervals();
    }
    public get intervals(): Array<AddressTimetableIntervalDto> {
        return this._intervals;
    }

    public addressintervals: AddressTimetableIntervalDto[];
    loadIntervals() {
        this.addressintervals = [];

        if (!this.intervals)
            return;

        if (this.intervals == null || this.intervals.length <= 0)
            return;

        this.intervals.forEach(element => {
            if (!HHourInterval.isNullOrNullValue(element.interval))
                this.addressintervals.push(element);
        });

        if (this.phase == null || this.phase.intervalInfo == null)
            return;
        if (HHourInterval.isNullOrNullValue(this.phase.intervalInfo.interval))
            return;
        /*
        * Afegeix l'interval reservat, indicat per slotInfo, quan no està present al llistat d'intervals
        * d'una fase retornats pel back.
        * */
        let isInTheList = false;
        for (const addressinterval of this.addressintervals) {
            if (addressinterval.addressTimetableIntervalId === this.phase.intervalInfo.addressTimetableIntervalId) {
                isInTheList = true;
                break
            }
        }
        if (!isInTheList) {
            this.addressintervals.push(this.phase.intervalInfo);
        }

    }
    /***
         * True si el n-essim element item te una data diferent a l'anterior
         */
    public trencaData(i: number, item: AddressTimetableIntervalDto): boolean {
        if (i == 0)
            return true;
        let prev = this.addressintervals[i - 1];
        return !HDate.equals(item.onDate, prev.onDate);
    }

    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
