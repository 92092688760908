import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { ResponseData } from "../ResponseData";
import { Observable, Subscription } from 'rxjs';
import { api } from '../../environments/environment';
import { timer } from 'rxjs';

/***
 * Serve de log. Funciona com una queue. Els missatges de log s'envien sense necessitat de credencials, etc.
 * Per preservar els logs, en cas de qualsevol problema aquets es desen en una cookie i es recuperen al reiniciar l'aplicació.
 */
@Injectable({
    providedIn: 'root',
})
export class LogController {

    public static APIKEY = null;
    public static USERID: number = 0;
    public static GEOLOCATIONMODE: string = "";
    public static LOGGED: boolean = true;

    private baseurl: string = "/log";
    private _queue: Array<LogDto> = new Array<LogDto>();
    private _localStorage: Storage;

    public static readonly APIKEY_NAME = 'api_key';
    private static readonly COOKIENAME = "logQueue";
    public static readonly LANGUAGE_NAME = 'language';
    public static readonly TIMEZONE_NAME = 'timezone'
    public static readonly GEOLOCATION_INFO_NAME = 'geolocationInfo';
    public static readonly GEOLOCATION_QUEUE_NAME = 'geolocationQueue';

    constructor(protected http: HttpClient) {
        //Ens recuperem. Hi ha Log pendent d'una exeucició anterior?
        this._localStorage = window.localStorage;
        let svalue = this._localStorage.getItem(LogController.COOKIENAME);
        if (svalue != null && svalue != "") {
            try {
                let array = <Array<LogDto>>JSON.parse(svalue);
                this._queue = array;
            }
            catch (error) {
                this.logMessage(LogLevels.Exception, "LogController", "Recuperant cookie " + JSON.stringify(error));
            }
        }

        this.startTimer();
    }

    ngOnDestroy() {
        this.cancelTimer();
    }

    private time: Subscription;
    public startTimer() {
        this.time = timer(10000, 30000).subscribe(time => {
            this.sendNextPending();
        });
    }
    public cancelTimer() {
        if (this.time != null)
            this.time.unsubscribe();
    }

    public cleanQueque() {
        this._queue = new Array<LogDto>();
        this._localStorage.setItem(LogController.COOKIENAME, JSON.stringify(this._queue));
    }
    /***
     * escrius al log un missatge 
     */
    public logMessage(logLevel: LogLevels, classTypeName: string, message: string) {
        this._queue.push(LogDto.buildMessage(logLevel, classTypeName, message));
        this.sendNextPending();
    }

    /***
    * escrius al log un missatge de navegació per l'aplicació
     */
    public navigate(path: string, value: boolean) {
        this._queue.push(LogDto.buildMessage(LogLevels.Navigate, "Navigate." + value, path));
        this.sendNextPending();
    }
    /***
     * Una recursiva. Enviem el següent de la llista si hem pogut enviar l'anterior. 
     * L'únic problma es que n'hi haguessin tant que petes la pila però per ara descarto aquest problema
     */
    public sendNextPending() {
        if (LogController.LOGGED)
            if (this._queue.length > 0) {
                let nextInLine = this._queue[0];
                let message: LogDto = LogDto.build(nextInLine);
                this.put(this.baseurl + '', message).subscribe(data => {
                    if (data) {
                        this._queue.shift(); // shift -> FIFO, pop()-> LIFO. Això està relacioant amb el this_queue[0]. El treiem de la llista quan l'hem enviat.
                        this._localStorage.setItem(LogController.COOKIENAME, JSON.stringify(this._queue));
                        this.sendNextPending();
                    }
                    else {
                        //Aquí desem la llista, per si les mosques!!!!!!!!
                        this._localStorage.setItem(LogController.COOKIENAME, JSON.stringify(this._queue));
                    }
                },
                    error => {
                        //Aquí desem la llista, per si les mosques!!!!!!!!
                        this._localStorage.setItem(LogController.COOKIENAME, JSON.stringify(this._queue));
                    });
            }
    }

    /***
     * Base put
     */
    private put<T>(url: string, body: any): Observable<boolean> {
        let options = { headers: this.getHeader() };
        return this.extractResponse(this.http.put<ResponseData<boolean>>(api(url), body, options));
    }


    protected getHeader(): HttpHeaders {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return headers;
    }

    /***
     * El log ens retorna true quan l'ha pogut desar, false quan no o excepció, etc.
     * Es a dir true tot bé, qualsevol altre cosa xungo
     */
    private extractResponse(respostaBack: Observable<ResponseData<boolean>>): Observable<boolean> {
        return new Observable<boolean>(observable => {
            respostaBack.subscribe(res => {
                if (res == undefined) {
                    console.log(JSON.stringify(res));
                    observable.next(false);
                    observable.complete();
                }
                else {
                    try {
                        if (res.isException) {
                            console.log(JSON.stringify(res));
                            observable.next(false);
                            observable.complete();
                        }
                        else {
                            let result = res.data as boolean;
                            observable.next(result);
                            observable.complete();
                        }
                    }
                    catch {
                        console.log(JSON.stringify(res));
                        observable.next(false);
                        observable.complete();
                    }
                }
            }, error => {
                console.log(JSON.stringify(error));
                observable.next(false);
                observable.complete();
            })
        });
    }
}

export enum LogLevels {
    Info = "Info",
    Navigate = "Navigate",
    Warning = "Warning",
    Error = "Error",
    Exception = "Exception"
}
export class LogDto {
    public moment: number;
    public logLevel: LogLevels;
    public classTypeName: string;
    public message: string;
    public userId: number;
    public apikey: string;

    public static buildMessage(logLevel: LogLevels, classTypeName: string, message: string): LogDto {
        let result = new LogDto();
        result.moment = Date.now();
        result.apikey = LogController.APIKEY;
        result.userId = LogController.USERID;
        result.logLevel = logLevel; 1
        result.message = message;
        result.classTypeName = classTypeName;
        return result;
    }
    public static build(log: LogDto): LogDto {
        let result = new LogDto();
        result.moment = log.moment ? log.moment : 0;
        result.apikey = log.apikey;
        result.userId = +log.userId > 0 ? log.userId : 0;
        result.logLevel = log.logLevel;
        result.message = log.message;
        result.classTypeName = log.classTypeName;
        return result;
    }
}