import { Injectable } from '@angular/core';
import { FileTypes } from '@shared/src/enums/FileTypes';
import { HString } from '@shared/src/public-api';
import { encode } from 'base64-arraybuffer';
import { FileUpDownLoadDto } from '../base/FileUpDownLoadDto';
import { DocumentDetailDto } from './DocumentDetailDto';
import { DocumentListDto } from './DocumentListDto';
import { iDocument } from './iDocument';
import { RouteDocumentTypes } from '@shared/src/enums/RouteDocumentTypes';

@Injectable()
export class DocumentDto extends DocumentListDto implements iDocument {
    
    public documentDetailList: Array<DocumentDetailDto> = new Array();

    public classDocument: string = "";
    
    public opened: boolean;

    public add(documentDetail: DocumentDetailDto) {

        if (this.documentDetailList == null)
            this.documentDetailList = new Array();

        if (documentDetail != null) {
            this.documentDetailList.push(documentDetail);
        }
    }

    public static valideFiles(files: FileList): boolean {
        var type = null;
        for (var i = 0; i < files.length - 1; i++) {
            type = files[i].type;
            if (type != files[i + 1].type)
                return false;
        }

        return true;
    }
    public static buildByFiles(filelist: FileList, fileNameGeneric: string, fileTypeGeneric: FileTypes, documentType: RouteDocumentTypes): Promise<DocumentDto> {

        const res = new Promise<DocumentDto>(resolve => {
            const result = new DocumentDto();
            result.fileName = fileNameGeneric;
            result.documentType = documentType;

            if (filelist != null && filelist.length > 0) {
                let count: number = 0;
                for (var i = 0; i < filelist.length; i++) {
                    var file = filelist[i];

                    this.buildByFileDetail(file, FileUpDownLoadDto.getFileType(file.type)).then((documentDetail) => {
                        result.add(documentDetail);
             
                        result.fileType = fileTypeGeneric;
                        //Si ja hem recorregut tots els fitxers retornem el DocumentDTO
                        count++;
                        if (count == filelist.length)
                            resolve(result);
                    });
                }
            } else
                resolve(result);
        });

        return res;
    };

    public static buildByFilesName(filelist: FileList, fileTypeGeneric: FileTypes): Promise<DocumentDto> {

        const res = new Promise<DocumentDto>(resolve => {
            const result = new DocumentDto();

            if (filelist != null && filelist.length > 0) {
                let count: number = 0;
                for (var i = 0; i < filelist.length; i++) {
                    var file = filelist[i];
                    if(file != null && HString.isNullOrNullString(result.fileName))
                        result.fileName = file.name;

                    this.buildByFileDetail(file, FileUpDownLoadDto.getFileType(file.type)).then((documentDetail) => {
                        result.add(documentDetail);
                        if(HString.isNullOrNullString(result.fileName))
                            result.fileName = documentDetail.fileName;
                        result.fileType = fileTypeGeneric;
                        //Si ja hem recorregut tots els fitxers retornem el DocumentDTO
                        count++;
                        if (count == filelist.length)
                            resolve(result);
                    });
                }
            } else
                resolve(result);
        });

        return res;
    };

    public static buildByFileDetail(file: File, fileType: FileTypes): Promise<DocumentDetailDto> {
        const res = new Promise<DocumentDetailDto>(resolve => {
            const result = new DocumentDetailDto();
            result.fileName = file.name;
            result.fileType = fileType;

            this.getBytesArrayFromFile(file).then((byteArray) => {
                result.fileBytes = byteArray;
                this.getBase64EncodedFileData(file).then((fileString) => {
                    result.fileString = fileString;
                    resolve(result);
                });
            });
        });
        return res;
    }

    public static getBytesArrayFromFile(file: File): Promise<any[]> {
        const res = new Promise<any[]>(resolve => {
            const reader = new FileReader();
            reader.onload = (event: any) => {

                let bytes = 0;
                const byteArray = [];
                const t: any = reader.result;
                const array = new Uint8Array(t);
                for (let i = 0; i < array.length; ++i) {
                    byteArray.push(array[i]);
                    bytes = i;
                }
                resolve(byteArray);
            };
            reader.readAsArrayBuffer(file);
        });
        return res;
    }

    public static getBase64EncodedFileData(file: File): Promise<string> {
        const res = new Promise<string>(resolve => {
            const reader = new FileReader();

            reader.onload = () => {
                const { result } = reader;
                const data = result as ArrayBuffer; // <--- FileReader gives us the ArrayBuffer
                const base64Encoded = encode(data); // <--- convert ArrayBuffer to base64 string

                resolve(base64Encoded);
            };

            reader.readAsArrayBuffer(file);
        });
        return res;
    }
}
