import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { ControlContainer, FormGroup, FormBuilder } from '@angular/forms';
import { Inject, Optional, Host, SkipSelf } from '@angular/core';

import { ValueAccessorValidatedBase } from '../../form/value-accessor-validated';
import { HomeController } from '../../../../controllers/home/home.controller';

@Component({
  selector: 'select-component',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectComponent, multi: true }
  ]
})

export class SelectComponent extends ValueAccessorValidatedBase<string, string> {

  createForm(validators: ValidatorFn[], asyncvalidators: AsyncValidatorFn[]) {
    this.rFormInput = this.fb.group({
      'dtpvalidator': ['', validators, asyncvalidators]
    });
  }

  isValid(value: string): boolean {
    return true;
  }

  rFormInput: FormGroup;
  public useIonic: boolean = false;
  constructor(
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorFn[],
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorFn[],
    private fb: FormBuilder,
    @Inject('HomeController') protected homecontroller: HomeController
  ) {
    super(controlContainer, validators, asyncValidators);
    if (homecontroller.useIonic)
      this.useIonic = homecontroller.useIonic();
  }

  @Input() public label: string;
  @Input() public helpresource: string;
  @Input() public color: string = 'primary';
  @Input() public readonly: boolean;
  @Input() public showHelpPoint: boolean;
  @Input() public autocomplete: string = "off";
  @Input() public type: string = "text";
  @Input() public name: string = null;
  @Input() public options: Array<any> = [];
  @Input() public optionLabel: string = null;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  public identifier = 'select-' + identifier++;

  externalValue(value: any): any {
    return value;
  }
  internalValue(value: any): any {
    return value;
  }

  onChange(e) {
    if (e && e.detail) this.valueChanged.next(this.externalValue(e.detail.value));
  }

}

let identifier = 0;