import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BookingController } from '@shared/src/controllers/booking/booking.controller';
import { ManagementModelController } from '@shared/src/controllers/managementmodel/managementmodel.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { BookingDto } from '@shared/src/dtos/booking/BookingDto';
import { BookingSlotDto } from '@shared/src/dtos/booking/BookingSlotDto';
import { ParticipantDto } from '@shared/src/dtos/chat/participant/ParticipantDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RouteDocumentaryReviewObjectDto } from '@shared/src/dtos/documentaryreview/RouteDocumentaryReviewObjectDto';
import { ChatParticipantTypes } from '@shared/src/enums/ChatParticipantTypes';
import { HardmanProducts } from '@shared/src/enums/HardmanProducts';
import { RoutePermissionTypesRules } from '@shared/src/enums/RoutePermissionTypes';
import { AlertService, HomeController, PhaseCardDto, PhaseController, RouteCardDto, RouteController, SharedAsideFactory } from '@shared/src/public-api';

@Component({
  selector: 'shared-booking-route',
  templateUrl: './shared.booking.route.component.html',
  styleUrls: ['./shared.booking.route.component.scss']
})
export class SharedBookingRouteSelectorComponent implements OnInit {

  constructor(protected fb: FormBuilder,
    protected bookingController: BookingController,
    protected routeController: RouteController,
    protected openRouteController: OpenRouteController,
    protected phaseController: PhaseController,
    protected managementModelController: ManagementModelController,
    @Inject("HomeController") protected homeController: HomeController,
    @Inject("AlertService") protected alertService: AlertService,
    @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory) {

    this.hascarrier = this.homeController.hasProduct(HardmanProducts.carrier);
    this.hasshipper = this.homeController.hasProduct(HardmanProducts.shipper);
  }

  ngOnInit(): void {
   
  }

  // Funcionament del component
  @Input() public readonly: boolean = false;
  public hascarrier: boolean = false;
  public hasshipper: boolean = false;
  public hasInspector: boolean = false;
  public hasHealthCertifier: boolean = false;

  // Tabs
  public selectedOptionId: string;
  public options: OptionsStripDto;

  public getOptions() {
    if (!this._route)
      return;

    this.routeController.getBookingOptions(this._route.routeId).subscribe(e => {
      if (e) {
        this.options = OptionsStripDto.buildFromOptionsStripDto(e);
        if (this.selectedOptionId)
          OptionsStripDto.selectById(this.options, this.selectedOptionId);
        this.onClickOption(this.options.getActualSelected());
      }
    })
  }

  public onClickOption(item: OptionDto) {
    if (!this._route)
      return;

    this.selectedOptionId = this.options.getActualSelected().optionId;

    switch (item.optionId) {
      case "SLOTS":
        this.getSlotPhases();
        break;
      case "DOC":
        break;
      case "DRIVER":
        this.getBooking();
        this.getBookingSlot();
        break;
      case "LOG":
        break;
      case "CHAT":
        break;
      case "MANAGEMENTMODEL":
        break;
      default:
        break;
    }
  }

  // Ruta
  public chatOwner: ParticipantDto;
  @Output() public onRouteChanged: EventEmitter<RouteCardDto> = new EventEmitter<RouteCardDto>();
  @Output() public onRouteRefresh: EventEmitter<RouteCardDto> = new EventEmitter<RouteCardDto>();

  private _route: RouteCardDto;
  @Input() public set route(v: RouteCardDto) {
    if (v && this._route && JSON.stringify(this._route) == JSON.stringify(v))
      return;

    this._route = v;

    if (this._route && this._route.routeId) {
      if (!this._route.routePermission) {
        this.reloadRoute();
      } else {
        this.calculateSettings();
      }
      this.getOptions();
    }

  }

  public get route(): RouteCardDto {
    return this._route;
  }

  public async reloadRoute() {
    return new Promise(resolve => {
      if (!this._route)
        resolve(false);
      this.routeController.getRoute(this._route.routeId).subscribe(e => {
        if (e && e.routeId)
          this._route = e;
        this.calculateSettings();
        resolve(true);
      })
    })
  }

  public calculateSettings() {
    if (!this._route)
      return;
    this.getOptions();
    this.chatOwner = ParticipantDto.buildByParams(this._route.routeId, ChatParticipantTypes.Route);
    this.canAssignDriverAndPlates = RoutePermissionTypesRules.canAssignDriverAndPlatesOnRoute(this._route);
    this.showAssignDriverAndPlates = RoutePermissionTypesRules.showAssignDriverAndPlatesOnRoute(this._route);

  }

  // Fases
  public slotPhases: Array<PhaseCardDto> = [];

  public getSlotPhases() {
    if (!this._route)
      return;

    this.slotPhases = [];

    this.phaseController.getSlotPhasesByRoute(this._route.routeId).subscribe(e => {
      if (e)
        this.slotPhases = e;
    });
  }

  // Slots
  public async onSlotsChanged(item: PhaseCardDto, index: number) {
    this.phaseController.getPhase(item.phaseId).subscribe(e => {
      if (e)
        this.slotPhases[index] = e;
    });

    await this.reloadRoute();
    this.onRouteChanged.next(this._route);
  }

  //Partners i matrícules
  public canAssignDriverAndPlates: boolean = true;
  public showAssignDriverAndPlates: boolean = true;


  // Booking slot
  public bookingSlot: BookingSlotDto;
  public getBookingSlot() {
    this.bookingController.getBookingSlot(this._route.routeId).subscribe(e => {
      if (e && e.bookingSlotId)
        this.bookingSlot = e;
    })
  }

  // Booking
  public booking: BookingDto;
  public getBooking() {
    this.bookingController.getBooking(this._route.bookingId).subscribe(e => {
      if (e && e.bookingId)
        this.booking = e;
    })
  }

  //ManagementModel
  public onRouteDocumentaryReviewUpdated(routeDocumentaryReview: RouteDocumentaryReviewObjectDto) {
    if (routeDocumentaryReview == null || !routeDocumentaryReview.routeId) {
      return;
    }

    this.reloadRoute();
    this.onRouteRefresh.next(this._route);
  }


  onMarkerClick(phase: PhaseCardDto) {
    this.sharedAsideFactory.invokeAddressCoordinates(phase.addressId, phase.formattedAddress, phase.longitude, phase.latitude);
  }

  // Debug
  pretty(val: any) {
    return JSON.stringify(val);
  }
}
