import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'shared-imageslider-dialog',
  templateUrl: 'shared.imageslider.dialog.html',
  styleUrls: ['./shared.imageslider.dialog.scss']
})
export class SharedImageSliderDialog {

  constructor(private mdDialogRef: MatDialogRef<SharedImageSliderDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {

  }
  
  onClose() {
    this.mdDialogRef.close();
  }

}
