export enum ChatParticipantTypes {
	User = "User",
	Route = "Route",
	Shipment = "Shipment",
	Phase = "Phase",
	System = "System",
	Support = "Support",
	Group = "Group",
    None = "None"
}
