<div *ngIf="value" class="container" [ngClass]="generalClass">
        <div class="elem type" matTooltip="{{value.type}}">
                {{resourceName | translate}} <span class="code only-hardman-user"><br> {{value.type}}</span></div>
        <div class="elem value datetimeValue">
            <datehour-picker class="col-20" name="datetimeValue" [(ngModel)]="datetimeValue" [readonly]="readonly">
            </datehour-picker>
        </div>
        <div class="elem value intervalValue">
            <hourintervals-picker class="col-20" name="intervalValue" [(ngModel)]="intervalValue" [readonly]="readonly">
            </hourintervals-picker>
        </div>
        <div class="elem value decimalValue">
            <input-component class="col-5" name="decimalValue" type="number" [min]="0" [max]="100" [step]="0.1" autocomplete="off" 
                [(ngModel)]="value.decimalValue" [readonly]="readonly">
            </input-component>
        </div>
        <div class="elem value triStateValue">
            <tristate name="triStateValue" [(ngModel)]="triStateValue" [readonly]="readonly">
            </tristate>
        </div>
</div>