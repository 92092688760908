import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { OpenRouteChangeDriverInfoDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoDto';
import { OpenRouteChangePlatesDto } from '@shared/src/dtos/open/OpenRouteChangePlatesDto';
import { OpenChangeStatusDto } from '@shared/src/dtos/open/OpenChangeStatusDto';
import { OpenDocument } from '@shared/src/dtos/open/OpenDocument';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { OpenRouteChangeAdditionalActorDto } from '@shared/src/dtos/open/OpenRouteChangeAdditionalActorDto';
import { OpenRouteChangeDriverInfoAndPlatesDto } from '@shared/src/dtos/open/OpenRouteChangeDriverInfoAndPlatesDto';
import { OpenShipmentDocuments } from '@shared/src/dtos/open/OpenShipmentDocuments';
import { GeolocationController } from '../geolocalization/geolocation.controller';
import { HomeController } from '../home/home.controller';
import { HTags } from '@shared/src/datatypes/HTags';
import { TrackingShipmentDto } from '@shared/src/dtos/tracking/TrackingShipmentDto';
import { AddressTimetableIntervalDto, GeneralStatus } from '@shared/src/public-api';
import { OpenUuidExwork } from './OpenUuidExwork';
import { OpenRouteExworkInfoDto } from '@shared/src/dtos/open/OpenRouteExworkInfoDto';
import { MaintenanceSearchByTextDto } from '../maintenance/_base/MaintenanceSearchByTextDtos';
import { PanelStructCtrlDto } from '@shared/src/dtos/panel/PanelStructCtrlDto';
import { SetElementColumnDto } from '@shared/src/dtos/panel/column/element/SetElementColumnDto';
import { PanelElementInfoDto } from '@shared/src/dtos/panel/column/element/PanelElementInfoDto';
import { PanelElementFilterDto } from '@shared/src/dtos/panel/column/element/PanelElementFilterDto';


@Injectable({
    providedIn: 'root',
})
export class OpenPanelController extends BaseController {
    private base = '/public/panel'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router,
        @Inject('HomeController') protected homeController: HomeController
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    
    public panelStruct(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<PanelStructCtrlDto> {
        if(busy)
            return this.postBusy(this.base + "/struct", maintenanceSearchByText, "panelStruct");
        return this.post(this.base + "/struct", maintenanceSearchByText, "panelStruct");
    }

    public changeElementColumn(dto: SetElementColumnDto): Observable<Boolean> {
        return this.post(this.base + "/changeelementcolumn", dto, "changeElementColumn");
    }

    public getPanelElementInfo(dto: PanelElementFilterDto): Observable<PanelElementInfoDto> {
        return this.post(this.base + "/getpanelelementinfo", dto, "getPanelElementInfo");
    }

}
