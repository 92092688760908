<div class="box-times" *ngIf="value">

    <div class="box box-documentation">
        <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCUMENTATION' | translate}}</div>
        <div class="box-time doc star">
            <div class="value-time">{{ value.duradaAnteriorDocumentacio | time }}</div>
        </div>

        <div class="box box-dock">
            <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.DOCK' | translate}}</div>
            <div class="box-time star">
                <div class="value-time">{{ value.duradaAnteriorMoll | time }}</div>
            </div>

            <div class="box box-forklift">
                <div class="toplabel-box">{{'TYPOLOGYTIME.BOX.FORKLIFT' | translate}}</div>
                <div class="box-time star forklift">
                    <div class="value-time">{{ value.duradaCarretiller | time }}</div>
                </div>
            </div>

            <div class="box-time end">
                <div class="value-time">{{ value.duradaPosteriorMoll | time }}</div>
            </div>

        </div>

        <div class="box-time doc end">
            <div class="value-time">{{ value.duradaPosteriorDocumentacio | time }}</div>
        </div>
    </div>
    
</div>