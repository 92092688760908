<ng-container *ngIf="phase">
    <div class="info-phase">
        <div class="line">
            <div class="tag load" *ngIf="phase.isLoad">{{'PHASE.ISLOAD'| translate}}</div>
            <div class="tag unload" *ngIf="phase.isUnload">{{'PHASE.ISUNLOAD'| translate}}</div>
            <div class="tag unloadload" *ngIf="phase.isUnloadLoad">{{'PHASE.ISUNLOADLOAD'| translate}}</div>
        </div>
        <div class="line">
            <shared-tags-strip-component class="tags" [uniqueString]="phase.addressSkin"></shared-tags-strip-component>
            <span class="formatted-address">{{phase.formattedAddress}}</span>
        </div>
        <shared-tags-strip-component class="tags" [tags]="phase.userTags"></shared-tags-strip-component>

    </div>

    <form class="col-x" [formGroup]="rForm">
        <datehour-picker class="col-20" name="arrivedAt"
            placeholder="{{'PHASE.CHANGEDATES.ARRIVEDAT.CAPTION' | translate}}" [(ngModel)]="phase.arrivedAt"
            formControlName="arrivedAt">
        </datehour-picker>
        <datehour-picker class="col-20" name="completedAt"
            placeholder="{{'PHASE.CHANGEDATES.COMPLETEDAT.CAPTION' | translate}}" [(ngModel)]="phase.completedAt"
            formControlName="completedAt">
        </datehour-picker>
    </form>
    <div class="col-x">
        <button class="action button-form" (click)="save()"
            [ngClass]="getsavebuttonclass()">{{ 'MAINTENANCE.ACCEPT' | translate }}</button>
    </div>
</ng-container>