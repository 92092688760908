<div class="container" [ngClass]="{'ionic' : useIonic}" *ngIf="route">
    <div *ngIf="showtitle" class="title">
        {{'SHARED.ROUTE.EDITADDITIONALDATA.COMPONENT.TITLE' | translate}}
    </div>
    <div *ngIf="showtitle" class="block-help">
        {{'SHARED.ROUTE.EDITADDITIONALDATA.COMPONENT.HELP' | translate}}
    </div>

    <div class="info-data">
        <div class="info-readonly" *ngIf="route.isGrouped">
            <div class="col-x modelgestio" *ngIf="route.canChangedManagementModel">
                <span class="placeholder">{{'ROUTE.MANAGEMENTMODEL.CAPTION' | translate}}:</span><span class="value">{{ managementModel }}</span>
            </div>
            <div class="col-x typology" *ngIf="route.routePermission == 'Owner'">
                <span class="placeholder">{{'ROUTE.TYPOLOGY.CAPTION' | translate}}:</span><span class="value">{{ typology }}</span>
            </div>
            <div class="col-x incoterm" *ngIf="route.routePermission == 'Owner'">
                <span class="placeholder">{{'ROUTE.INCOTERM.CAPTION' | translate}}:</span><span class="value">{{ getResourceIncoterm() | translate }}</span>
            </div>
        </div>
        <div *ngIf="!route.isGrouped">
            <entity-selector *ngIf="route.canChangedManagementModel" class="col-x modelgestio" [(ngModel)]="managementModelId"
                [readonly]="readonly" [entitySelector]="'ManagementModel'"
                placeholder="{{'ROUTE.MANAGEMENTMODEL.CAPTION' | translate}}" name="managementModelId">
            </entity-selector>

            <entity-selector *ngIf="route.routePermission == 'Owner' && !route.isGrouped" class="col-x typology" [(ngModel)]="typologyId"
                [readonly]="readonly" [entitySelector]="'Typology'" placeholder="{{'ROUTE.TYPOLOGY.CAPTION' | translate}}"
                name="typologyId">
            </entity-selector>

            <enum-selector *ngIf="route.routePermission == 'Owner'" class="col-x incoterm" [(ngModel)]="incoterm"
                [readonly]="readonly" [enumvalues]="enumIncoterms" placeholder="{{'ROUTE.INCOTERM.CAPTION' | translate}}"
                name="language" [translategroup]="'INCOTERMS'">
            </enum-selector>
        </div>
    </div>

</div>